export const root = {
  position: 'relative',
  textAlign: 'center',
  padding: '0 20px 150px 20px;',
  color: '#000',
  marginTop: 70,
  '@media(min-width: 768px)': {
    marginTop: 117
  }
};
export const slideWrap = {
  paddingBottom: 32,
  marginBottom: 32,
  marginLeft: 4,
  '& .slick-slide>div': {
    padding: '0',
    marginBottom: 56,
    '@media(min-width: 768px)': {
      marginBottom: 0
    }
  }
};

export const headerWrap = {
  width: 300,
  margin: 'auto',
  '@media(min-width: 1024px)': {
    width: 415
  },
  '@media(min-width: 1440px)': {
    width: 600
  }
};

export const cityWrap = {
  img: {
    borderRadius: '50%'
  },
  width: 232,
  position: 'relative',
  height: 178,
  margin: 'auto',
  transform: 'translateX(16px)',
  '@media(min-width: 1440px)': {
    height: 80
  },
  '@media(min-width: 1920px)': {
    width: 300,
    height: 100
  }
};

export const city1Styles = {
  position: 'absolute',
  top: 81,
  left: 0,
  height: 48,
  width: 48,
  '@media(min-width: 768px)': {
    left: 180
  },
  '@media(min-width: 1024px)': {
    left: 300
  },
  '@media(min-width: 1440px)': {
    left: 700,
    top: 72,
    height: 62,
    width: 62
  },
  '@media(min-width: 1920px)': {
    left: 920,
    height: 90,
    width: 90,
    top: 170
  }
};

export const city2Styles = {
  position: 'absolute',
  top: 39,
  right: 0,
  height: 84,
  width: 84,
  '@media(min-width: 768px)': {
    left: 370,
    top: 85
  },
  '@media(min-width: 1024px)': {
    left: 450,
    height: 94,
    width: 94
  },
  '@media(min-width: 1440px)': {
    top: -50,
    left: 550
  },
  '@media(min-width: 1920px)': {
    height: 136,
    width: 136,
    left: 850,
    top: -70
  }
};
export const city2Wrap = {
  position: 'relative',
  img: {
    borderRadius: '50%'
  },
  '@media(min-width: 1440px)': {
    left: 150
  }
};
export const city3Styles = {
  position: 'absolute',
  top: 15,
  right: 50,
  height: 48,
  width: 48,
  '@media(min-width: 768px)': {
    width: 108,
    height: 108,
    left: 190,
    top: -20
  },
  '@media(min-width: 1024px)': {
    width: 103,
    height: 103
  },
  '@media(min-width: 1440px)': {
    width: 126,
    height: 126,
    left: -80,
    top: -100
  },
  '@media(min-width: 1920px)': {
    width: 176,
    height: 176,
    top: -150,
    left: -40
  }
};
export const city4Styles = {
  position: 'absolute',
  top: -50,
  left: 40,
  height: 75,
  width: 75,
  '@media(min-width: 768px)': {
    width: 56,
    height: 56
  },
  '@media(min-width: 1024px)': {
    width: 62,
    height: 62
  },
  '@media(min-width: 1440px)': {
    width: 88,
    height: 88,
    top: -550,
    left: -80
  },
  '@media(min-width: 1920px)': {
    width: 103,
    height: 103,
    top: -600,
    left: -80
  }
};
export const info = {
  fontSize: 14,
  lineHeight: 2,
  color: '#000',
  marginTop: 32,
  position: 'relative',
  '@media(min-width: 768px)': {
    maxWidth: 630,
    margin: '40px auto 0'
  },
  '@media(min-width: 1024px)': {
    maxWidth: 715,
    fontSize: 16
  },
  '@media(min-width: 1440px)': {
    maxWidth: 890,
    fontSize: 18,
    lineHeight: 1.7
  }
};

export const subHeader = {
  fontSize: 18,
  marginBottom: 42,
  lineHeight: 1.56,
  '@media(min-width: 768px)': {
    maxWidth: 476,
    margin: '64px auto 0'
  },
  '@media(min-width: 1024px)': {
    maxWidth: 510,
    margin: '56px auto 0',
    fontSize: 20
  },
  '@media(min-width: 1440px)': {
    fontSize: 24,
    maxWidth: 661,
    lineHeight: 1.6,
    margin: '96px auto 0'
  }
};
export const innerWrap = {
  '@media(min-width: 768px)': {
    display: 'flex',
    flexDirection: 'column-reverse'
  }
};
