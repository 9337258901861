/** @jsx jsx */
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import { SpecsLogo } from '../ui/icons';
import {
  mainWrap,
  footerWrap,
  sectionWrap,
  menuSection,
  logoWrap,
  finePrintWrap,
  finePrintInner,
  copyright,
  openMenuStyles,
  contacter,
} from './footerStyles';

function Link({ to, children }) {
  return <a href={`https://www.madisonspecs.com/${to}.asp.html`}>{children}</a>;
}

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <div css={mainWrap}>
      <div css={footerWrap}>
        <div css={logoWrap}>
          <SpecsLogo />
        </div>
        <div css={menuSection}>
          <div>
            <div css={sectionWrap}>How it works</div>
            <div css={openMenuStyles}>
              <NavLink to='/about-cost-seg'>What is Cost Seg</NavLink>
              <NavLink to='/smart-tour'>The Smart Tour</NavLink>
              <NavLink to='/process'>Your SPECS Experience</NavLink>
            </div>
          </div>
          <div>
            <div css={sectionWrap}>Who We Are</div>
            <div css={openMenuStyles}>
              <Link to='aboutus/overview'>About Madison SPECS</Link>
              <Link to='aboutus/personnel'>Meet Your Team</Link>
            </div>
          </div>
          <div>
            <div css={sectionWrap}>What We've Done</div>
            <div css={openMenuStyles}>
              <NavLink to='/case-studies/multi-family'>Case Studies</NavLink>
              <NavLink to='/map'>Footprint</NavLink>
            </div>
          </div>
          <div>
            <div css={sectionWrap}>Help Hub</div>
            <div css={openMenuStyles}>
              <NavLink to='/faq'>FAQs</NavLink>
              <NavLink to='/glossary'>Glossary</NavLink>
            </div>
          </div>
          <div css={[sectionWrap, contacter]}>
            <NavLink to='/contact-us'>Contact Us</NavLink>
            <a style={{ marginTop: 24 }} href='tel:1-888-773-2773'>
              (888) 773-2773
            </a>
            <a href='mailto:info@madisonspecs.com'>info@madisonspecs.com</a>
          </div>
        </div>
      </div>

      <div css={{ paddingLeft: 40, paddingRight: 40, borderTop: '1px solid white' }}>
        <div css={finePrintWrap}>
          <div css={finePrintInner}>
            <div css={copyright}>© {year} by Madison SPECS</div>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <NavLink to='/privacy-page'>Privacy Policy</NavLink>
              <div
                css={{
                  color: '#fff',
                  marginLeft: 5,
                  marginRight: 5,
                  fontWeight: 500,
                }}
              >
                |
              </div>
              <NavLink to='/'>Site Map</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
