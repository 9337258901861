import styled from '@emotion/styled';

export const Wrapper = styled.div`
  text-align: center;
  padding: 117px 40px 0px 40px;
  margin: 0 auto;
  max-width: 415px;
  @media (min-width: 768px) {
    max-width: 100%;
    padding-left: 73px;
  }
  @media (min-width: 1024px) {
    padding-left: 96px;
  }
  @media (min-width: 1440px) {
    padding: 165px 40px 90px 140px;
  }
  @media (min-width: 1920px) {
    padding: 165px 40px 0px 400px;
    padding-left: 220px;
  }
`;

export const InnerWrap = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 260px 326px;
    grid-column-gap: 50px;
    grid-template-areas:
      'header para-one'
      'image para-one';
  }
  @media (min-width: 1024px) {
    grid-column-gap: 32px;
    grid-template-columns: 390px 404px;
    grid-template-areas:
      'image header'
      'image para-one';
  }
  @media (min-width: 1440px) {
    grid-template-columns: 500px 600px;
    grid-column-gap: 100px;
  }
  @media (min-width: 1920px) {
    grid-template-columns: 555px 640px;
    grid-column-gap: 237px;
    svg {
      max-width: 560px;
      width: 100%;
    }
  }
`;

export const HeaderOne = styled.div`
  color: #0827c7;
  font-size: 24px;
  text-align: center;
  grid-area: header;
  @media (min-width: 768px) {
    text-align: left;
    font-size: 32px;
  }
  @media (min-width: 1024px) {
    padding-left: 0;
  }
  @media (min-width: 1440px) {
    align-self: flex-end;
    font-size: 48px;
    br {
      display: none;
    }
  }
  @media (min-width: 1920px) {
    margin-top: 100px;
  }
`;

export const imgWrap = {
  gridArea: 'image',
  maxWidth: 375,
  marginTop: 40,
  '@media(min-width: 1024px)': {
    maxWidth: '100%',
  },
  '@media(min-width: 1440px)': {
    marginTop: 0,
  },
};
export const ParagraphOne = styled.div`
  text-align: center;
  line-height: 2;
  font-size: 14px;
  grid-area: para-one;
  @media (min-width: 768px) {
    max-width: 326px;
    text-align: left;
    div:last-of-type {
      margin-top: 24px;
    }
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    max-width: 410px;
    margin-top: 40px;
    div:last-of-type {
      margin-top: 32px;
    }
  }
  @media (min-width: 1440px) {
    font-size: 16px;
    max-width: 100%;
    margin-top: 64px;
  }
`;

export const InnerWrapTwo = styled.div`
  text-align: center;
  line-height: 2;
  font-size: 14px;
  padding-top: 50px;
  @media (min-width: 768px) {
    text-align: left;
    div {
      padding-top: 0;
    }
  }
  span {
    font-weight: bold;
  }
  div {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-auto-columns: 240px 316px;
    grid-column-gap: 72px;
    grid-template-areas:
      'a b'
      'c b'
      'd d';
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    grid-auto-columns: 340px 420px;
    grid-column-gap: 100px;
    div {
      padding-top: 0;
      padding-bottom: 0;
    }
    margin-bottom: 90px;
  }
  @media (min-width: 1440px) {
    svg {
      max-width: 600px;
    }
    div {
      padding-top: 20px;
    }
    grid-template-areas:
      'a b'
      'c b'
      'd b';
    grid-auto-columns: 600px 450px;
    grid-column-gap: 130px;
  }
  @media (min-width: 1920px) {
    svg {
      max-width: 783px;
    }
    grid-auto-columns: 600px 790px;
  }
`;
export const HeaderTwo = styled.h1`
  color: #0827c7;
  padding-top: 29px;
  font-size: 24px;
  grid-area: 'a';
  line-height: 1;
  padding-bottom: 27px;
  @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (min-width: 1440px) {
    font-size: 48px;
    br {
      display: none;
    }
  }
`;
