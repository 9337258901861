/** @jsx jsx */
import { jsx } from '@emotion/core';

import { useInView } from 'react-intersection-observer';

import { Root, Header, Circle, PlusIconThreeStyles, Newman } from './allStepsStyles';

import { RightBlueArrow } from '../ui/icons';

import SideStepCounter from './SideStepCounter';
import { PlusIcon3 } from './svg/plusIcons';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepSeven from './StepSeven';

export default function Steps({ onSectionClick }) {
  const [refOne, oneInView] = useInView({ threshold: 0.5 });
  const [refTwo, twoInView] = useInView({ threshold: 0.5 });
  const [refThree, threeInView] = useInView({ threshold: 0.5 });
  const [refFour, fourInView] = useInView({ threshold: 0.5 });
  const [refFive, fiveInView] = useInView({ threshold: 0.5 });
  const [refSix, sixInView] = useInView({ threshold: 0.5 });
  const [refSeven, sevenInView] = useInView({ threshold: 0.5 });

  return (
    <Root>
      <Circle onClick={() => onSectionClick('TheBeginning')}>
        <RightBlueArrow />
      </Circle>
      <PlusIconThreeStyles>
        <PlusIcon3 />
      </PlusIconThreeStyles>
      <Header className='syne'>Getting Started</Header>
      <Newman>
        <SideStepCounter
          onSectionClick={onSectionClick}
          oneInView={oneInView}
          twoInView={twoInView}
          threeInView={threeInView}
          fourInView={fourInView}
          fiveInView={fiveInView}
          sixInView={sixInView}
          sevenInView={sevenInView}
        />
        <StepOne refOne={refOne} />
        <StepTwo refTwo={refTwo} />
        <StepThree refThree={refThree} />
        <StepFour refFour={refFour} />
        <StepFive refFive={refFive} />
        <StepSix refSix={refSix} />
        <StepSeven refSeven={refSeven} />
      </Newman>
    </Root>
  );
}
