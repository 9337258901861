/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useMemo } from 'react';

import {
  Root,
  MotifStyles,
  Header,
  SubHeader,
  InnerWrap,
  SearchField,
  SearchIconStyles,
  ImageStyles,
  SearchWrap,
  buttonStyles,
  ResultsWrap,
  results,
  InnerResults,
  clear,
  Message,
  MawWidth,
} from './topSectionStyles';
import { Motif, FaqImage, SearchIcon } from '../ui/icons';
import CallButton from '../ui/CallButton';
import { allQuestions } from './questionsList';
import SearchResult from './SearchResult';

export default function TopSection() {
  const [search, setText] = useState('');
  const searchSpace = (event) => {
    let keyword = event.target.value;
    setText(keyword);
  };

  const items = useMemo(
    () =>
      allQuestions
        .filter((data) => {
          if (!search) {
            return null;
          } else if (
            data.question.toLowerCase().includes(search.toLowerCase()) ||
            data.answer.toLowerCase().includes(search.toLowerCase())
          ) {
            return data;
          }
        })
        .map((data) => <SearchResult question={data.question} answer={data.answer} />),
    [search],
  );
  return (
    <>
      <Root>
        <MawWidth>
          <MotifStyles>
            <Motif />
          </MotifStyles>
          <InnerWrap>
            <div>
              <SubHeader className='syne'>FAQs & Facts</SubHeader>
              <Header className='syne'>What can we help you with?</Header>
            </div>
            <ImageStyles>
              <FaqImage />
            </ImageStyles>
          </InnerWrap>
          <SearchWrap>
            <SearchField
              type='text'
              placeholder='Search for question or thing'
              value={search}
              onChange={(e) => searchSpace(e)}
            />
            <SearchIconStyles>
              <SearchIcon />
            </SearchIconStyles>
            <CallButton text='Search' styles={buttonStyles} />
          </SearchWrap>
          {items.length > 0 ? (
            <ResultsWrap>
              <InnerResults>
                <div css={results}>{items.length} Results</div>
                <div
                  css={clear}
                  onClick={() => {
                    setText('');
                  }}
                >
                  Clear
                </div>
              </InnerResults>
              <div>{items}</div>
              <Message>
                <div>
                  Not finding what you're looking for? Please <a href='tel:8887732773'>call us</a> or email us at{' '}
                  <a href='mailto:info@madisonspecs.com'>info@madisonspecs.com</a> and we'll get back to you straight
                  away.
                </div>
              </Message>
            </ResultsWrap>
          ) : (
            items.length === 0 &&
            search && (
              <Message>
                <p>Sorry, no results</p>
                <div>
                  Not finding what you're looking for? Please <a href='tel:8887732773'>call us</a> or email us at{' '}
                  <a href='mailto:info@madisonspecs.com'>info@madisonspecs.com</a> and we'll get back to you straight
                  away.
                </div>
              </Message>
            )
          )}
        </MawWidth>
      </Root>
    </>
  );
}
