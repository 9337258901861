import React from 'react';

export function PlusIcon1() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='132' height='132' viewBox='0 0 132 132'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(66)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(66) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(66 66)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(66 66) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(0 66)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-124 -651) translate(0 -71) translate(124 494) translate(0 228) translate(0 66) translate(16.5 16.5)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon2() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='198' height='132' viewBox='0 0 198 132'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85) translate(132)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85) translate(132) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85) translate(66 66)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85) translate(66 66) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-1609 -736) translate(0 -71) translate(124 494) translate(0 228) translate(1485 85) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon3() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='132' height='132' viewBox='0 0 132 132'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-269 -1165) translate(0 -71) translate(124 494) translate(0 228) translate(145 514) translate(0 66)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-269 -1165) translate(0 -71) translate(124 494) translate(0 228) translate(145 514) translate(0 66) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-269 -1165) translate(0 -71) translate(124 494) translate(0 228) translate(145 514) translate(66)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-269 -1165) translate(0 -71) translate(124 494) translate(0 228) translate(145 514) translate(66) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon4() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='297' height='263' viewBox='0 0 297 263'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(0 132)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(0 132) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(231 132)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(231 132) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(66 65)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(66 65) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(132 65)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(132 65) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(165)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(165) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(132 197)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-983 -1817) translate(0 1079) translate(0 184) translate(983 554) translate(132 197) translate(16.5 16.5)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon5() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='283' height='347' viewBox='0 0 283 347'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(0 193)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(0 193) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(132 215)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(132 215) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(6 60)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(6 60) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(85 136)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(85 136) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(111)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(111) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(217 281)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1030 -2629) translate(0 1079) translate(0 1067) translate(1030 483) translate(217 281) translate(16.5 16.5)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon6() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='231' height='235' viewBox='0 0 231 235'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(66)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(66) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(165 110)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(165 110) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(0 44)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(0 44) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(66 77)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(66 77) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(132 44)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(132 44) translate(16.5 16.5)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V66H0z'
                    transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(14 169)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                      transform='translate(-1016 -3568) translate(0 1079) translate(0 1992) translate(1016 497) translate(14 169) translate(16.5 16.5)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon7() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='297' height='292' viewBox='0 0 297 292'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(0 146.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(0 146.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(231 146.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(231 146.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(66 71.92)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(66 71.92) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(132 71.92)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(132 71.92) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(165)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(165) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(132 217.973)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -4592) translate(0 1079) translate(0 2992) translate(983 521) translate(132 217.973) translate(16.5 18.257)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon8() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='231' height='322' viewBox='0 0 231 322'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(0 75.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(0 75.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(99 75.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(99 75.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(66 .92)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(66 .92) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(165 248)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(165 248) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(132 146.973)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -5663) translate(0 1079) translate(0 3992) translate(983 592) translate(132 146.973) translate(16.5 18.257)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon9() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='297' height='292' viewBox='0 0 297 292'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(0 146.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(0 146.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(231 146.053)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(231 146.053) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(66 71.92)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(66 71.92) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(132 71.92)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(132 71.92) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(165)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(165) translate(16.5 18.257)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    d='M0 0H66V73.027H0z'
                    transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(132 217.973)'
                  />
                  <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                    <path
                      d='M16.5 0c1.45 0 2.637 1.241 2.742 2.816l.008.227v12.17h11c1.519 0 2.75 1.363 2.75 3.044 0 1.604-1.122 2.918-2.545 3.034l-.205.008h-11v12.172c0 1.68-1.231 3.042-2.75 3.042-1.45 0-2.637-1.241-2.742-2.815l-.008-.227V21.299h-11C1.231 21.3 0 19.937 0 18.257c0-1.604 1.122-2.919 2.545-3.035l.205-.008 11-.001V3.043C13.75 1.363 14.981 0 16.5 0z'
                      transform='translate(-983 -6592) translate(0 1079) translate(0 4992) translate(983 521) translate(132 217.973) translate(16.5 18.257)'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon10() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='120' height='166' viewBox='0 0 120 166'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-1079 -7622) translate(0 1079) translate(0 5991) translate(167 552) translate(912)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-1079 -7622) translate(0 1079) translate(0 5991) translate(167 552) translate(912) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-1079 -7622) translate(0 1079) translate(0 5991) translate(167 552) translate(912) translate(54 100)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-1079 -7622) translate(0 1079) translate(0 5991) translate(167 552) translate(912) translate(54 100) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PlusIcon11() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='163' height='167' viewBox='0 0 163 167'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-167 -7852) translate(0 1079) translate(0 5991) translate(167 552) translate(0 230) translate(0 101)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-167 -7852) translate(0 1079) translate(0 5991) translate(167 552) translate(0 230) translate(0 101) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d='M0 0H66V66H0z'
                      transform='translate(-167 -7852) translate(0 1079) translate(0 5991) translate(167 552) translate(0 230) translate(97)'
                    />
                    <g fill='#55DCAC' strokeLinecap='round' strokeLinejoin='round'>
                      <path
                        d='M16.5 0c1.45 0 2.637 1.122 2.742 2.545l.008.205v11h11c1.519 0 2.75 1.231 2.75 2.75 0 1.45-1.122 2.637-2.545 2.742l-.205.008h-11v11c0 1.519-1.231 2.75-2.75 2.75-1.45 0-2.637-1.122-2.742-2.545l-.008-.205v-11h-11C1.231 19.25 0 18.019 0 16.5c0-1.45 1.122-2.637 2.545-2.742l.205-.008h11v-11C13.75 1.231 14.981 0 16.5 0z'
                        transform='translate(-167 -7852) translate(0 1079) translate(0 5991) translate(167 552) translate(0 230) translate(97) translate(16.5 16.5)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function Motif() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='295' height='585' viewBox='0 0 295 585'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#FFF'>
          <g>
            <g>
              <path
                d='M1253 1317.065h282.948l-25.988 32.7-.146.19v56.62h-218.243v-56.41h-28.203v84.814l274.648.021v-146.361h-272.6l26.008-33.121.147-.19v-56.093h218.243v57.546h28.202v-85.972h-26.155l36.139-33.31v-133.044l-246.207-121.633V850H1253v467.065zm171.963-176.766V1003l71.037 35.49V1174h-108l36.963-33.7zm-131.28-.203l12.97-.189-1.843-189.72-.021-5.187L1376 980.15V1173h-108l25.682-32.904z'
                transform='translate(-1253 -7920) translate(0 1079) translate(0 5991)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
