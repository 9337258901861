import styled from '@emotion/styled';

export const Root = styled.div`
  padding: 0 24px;
  margin-bottom: -120px;
  z-index: 7;
  position: relative;
  @media (min-width: 768px) {
    margin-bottom: -169px;
    padding: 0 32px;
  }
  @media (min-width: 1440px) {
    margin-bottom: -240px;
  }
`;

export const InnerWrap = styled.div`
  box-shadow: 0px 16px 32px rgba(19, 21, 50, 0.1);
  @media (min-width: 768px) {
    display: flex;
    max-width: 704px;
    margin: 0 auto;
    > div {
      width: 50%;
      height: 352px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1440px) {
    max-width: 1200px;
    > div {
      height: 480px;
    }
  }
`;

export const MapWrap = styled.div`
  background: #f6f6f6;
  padding: 40px 0px 16px;
  position: relative;
  /* box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1); */
  .map-styles {
    height: auto;
    left: -24px;
    position: relative;
    width: calc(100% + 16px);
    z-index: 5;
  }
  @media (min-width: 768px) {
    display: flex;
    .map-styles {
      max-width: 343px;
    }
  }
  @media (min-width: 1024px) {
    .map-styles {
      max-width: 465px;
      left: -20px;
      width: calc(100% + 24px);
    }
  }
  @media (min-width: 1440px) {
    .map-styles {
      max-width: 592px;
      top: 22px;
      left: -80px;
    }
  }
`;

export const MapHeader = styled.div`
  font-family: 'Syne';
  font-size: 16px;
  font-weight: 300;
  left: 37px;
  line-height: 24px;
  max-width: 100px;
  position: absolute;
  top: 35px;
  z-index: 10;
  div {
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 8px;
  }
  @media (min-width: 768px) {
    top: 74px;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
    top: 64px;
    left: 80px;
    div {
      font-size: 88px;
      line-height: 88px;
      margin-bottom: 20px;
    }
  }
`;

export const GreenSearchBox = styled.div`
  background: #55dcac;
  color: #fff;
  padding: 48px 16px;
  box-shadow: 0px 16px 32px rgba(19, 21, 50, 0.1);
  p {
    font-family: Syne;
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    padding-right: 48px;
    padding-left: 48px;
  }
  @media (min-width: 1440px) {
    justify-content: flex-start;
    padding-top: 96px;
    p {
      font-size: 32px;
      line-height: 38px;
    }
  }
`;

export const SearchWrap = styled.div`
  color: #000;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
  > div {
    display: flex;
    position: relative;
  }
  .no-show-mobile {
    /* display: none; */
    border: 1px solid red;
  }
  input {
    box-shadow: 0px 21px 40px rgba(85, 110, 97, 0.388576);
    ::placeholder {
      font-size: 12px;
      color: #a9a9a9;
    }
  }
  button {
    background-color: #0827c7;
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 21px 40px rgba(19, 21, 50, 0.388576);
  }
  @media (min-width: 768px) {
    > div {
      button {
        margin-left: 0;
        margin-top: 24px;
      }
      .dropdown-label {
        font-size: 12px;
        line-height: 12px;
        align-items: center;
        display: flex;
      }
    }
  }
  @media (min-width: 1024px) {
    max-width: 100%;
  }
  @media (min-width: 1440px) {
    margin-top: 56px;
    > div {
      display: flex;
      .search-field-wrap {
        max-width: 302px;
      }
      button {
        margin-left: 24px;
        margin-top: 0;
      }
    }
  }
`;
