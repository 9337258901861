import styled from '@emotion/styled';
import React from 'react';

import motif from './tablet-motif.svg';

export const Responsive = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: relative;
  }
  @media (min-width: 1100px) {
    padding-top: 56px;

  }
`;

export const Root = styled.div`
  background-image: url('${motif}');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  margin: 0px auto;
  max-width: 1340px;
  padding-bottom: 48px;
  padding-top: 65px;
  position: relative;
  width: 100%;
  .cat_title {
    color: #000;
    text-align: center;
    font-family: 'syne';
    font-size: 55px;
    font-style: normal;
    line-height: 65px; 
    max-width: 520px;
    margin: 0 auto;
    width: 100%;
  }
  .green {
    .answer_override {
      div {
        color: #59C09E;
      }
      :before {
        background-color: #59C09E;
      }
    }
    .question-list-item {
      :hover {
        color: #59C09E;
        svg {
          path {
            fill: #59C09E;
          }
        }
      }
    }
    .active {
      color: #59C09E;
      ::before {
        background-image: linear-gradient(90deg, rgba(89, 192, 158, 0.20) 3.65%, rgba(89, 192, 158, 0.00) 74.72%);
      }
    }
  }
  .orange {
    .answer_override {
      div {
        color: #EDB944;
      }
      :before {
        background-color: #EDB944;
      }
    }
    .question-list-item {
      :hover {
        color: #EDB944;
        svg {
          path {
            fill: #EDB944;
          }
        }
      }
    }
    .active {
      color: #EDB944;
      ::before {
        background-image: linear-gradient(90deg, rgba(237, 185, 68, 0.20) 3.65%, rgba(237, 185, 68, 0.00) 74.72%);
      }
    }
    .cat_title {
      max-width: 800px;
    }
  }
  .blue {
    .cat_title {
      border: 1px solid red;
    }
  }

  @media (min-width: 1024px) {
    display: block;
  }
  @media (min-width: 1440px) {
    background-color: #F2F2F2;
    background-image: url('https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/glossary/background-motif_yksz9o.svg');
    background-position: center;  
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin: 0px auto;
    max-width: 1340px;
    padding-bottom: 64px;
    padding-left: 115px;
    padding-right: 115px;
    padding-top: 72px;
    position: relative;
    width: 100%;    
    .cat_title {
      color: #000;
      text-align: center;
      font-family: 'syne';
      font-size: 84px;
      font-style: normal;
      line-height: 90px; 
      max-width: 798px;
      width: 100%;
    }
    .orange {
      .cat_title {
        max-width: 800px;
      }
    }
  }
`;

export const CircleWrap = styled.div`
  align-items: center;
    background-color: ${(props) => {
      console.log(props.color, 'foo');
      switch (props.color) {
        case 'green':
          return '#59C09E';
        case 'orange':
          return '#EDB944';
        default:
          return '#0318C6'; // Default color
      }
    }};
    border-radius: 50%;
    display: flex;
    height: 78px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -39px;
    transform: translateX(-50%);
    width: 78px;
    svg {
      width: auto;
      height: 42px;
      path {
        fill: #fff;
      }
    }
    @media (min-width: 1440px) {
            /* background-color: #0318C6; */
      border-radius: 50%;
      width: 100px;
      height: 100px;
      position: relative;
      top: -50px;
      left: 115px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path {
          fill: #fff;
        }
      }
    }
`;

export const TabsWrap = styled.div`
  border-radius: 40px;
  margin: 0 auto;
  background-color: #E0E0F3;
  position: relative;
  z-index: 1;
  height: 40px;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  ::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 50px;
    top: -5px;
    transition: left 0.15s ease-in-out;
    left: ${(props) => {
      switch (props.selectedIndex) {
        case 0:
          return '-11px';
        case 1:
          return '161px';
        case 2:
          return '327px';
        default:
          return '-11px';
      }
    }};
    border-radius: 45px;
    background-color: #0318c6;
    z-index: 2;
    display: block;
  }
  @media (min-width: 1024px) {
    display: flex;
    display: inline-flex;
  }
  @media (min-width: 1100px) {
      top: 38px;
  }
  @media (min-width: 1440px) {
    border-radius: 40px;
    ::before {
      content: '';
      width: 180px;
      height: 50px;
      top: -5px;
      left: ${(props) => {
        switch (props.selectedIndex) {
          case 0:
            return '0px';
          case 1:
            return '180px';
          case 2:
            return '360px';
          default:
            return '0'; // Default left position
        }
      }};  
    }
  }
`;

export const Tabber = styled.div`
  cursor: pointer;
  padding: 0px 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selectedCategory === props.index ? 'white' : 'rgba(156, 156, 156, 0.50)'};
  font-size: 24px;
  font-weight: 300;
  position: relative;
  z-index: 3;
  transition: color 0.2s ease-in-out;
  padding-bottom: 2px;
  @media (min-width: 1440px) {
    padding: 0px 50px;
    height: 40px;
    font-size: 20px;
    width: 180px;
  }
`;

export const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  padding-bottom: 56px;
  @media (min-width: 1440px) {
    margin-top: 60px;
  }
`;

export const AnswerIllustration = styled.img`
  position: absolute;
  bottom: 30px;
  right: -40px;
  height: 303px;
  width: auto;
  @media (min-width: 1440px) {
    bottom: -100px;
    right: 75px;
    height: 330px;
  }
`;

export const QuestionListWrap = styled.ul`
  font-size: 17px;
  line-height: 21px;
  font-weight: 700;
  color: #9C9C9C;
  width: 298px;
  .active {
    color: #0318C6;
    position: relative;
    cursor: default;
    svg {
      path {
        fill: currentColor
      }
    }
    ::before {
      background-image: linear-gradient(90deg, rgba(3, 24, 198, 0.20) 3.65%, rgba(3, 24, 198, 0.00) 74.72%);
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 1;
      height: 100%;
      left: 0px;
    }
  }
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 0;
    padding-left: 57px;
    gap: 20px;
    :hover {
      color: #0318C6;
      svg {
        path {
          fill: #0318C6;
        }
      }
    }
    svg {
      flex: 0 0 20px;
      height: auto;
    }
    div {
      max-width: 144px;
    }
  }
  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    max-width: 460px;
    .active {
      color: #0318C6;
      position: relative;
      svg {
        path {
          fill: currentColor
        }
      }
      ::before {
        background-image: linear-gradient(90deg, rgba(3, 24, 198, 0.20) 3.65%, rgba(3, 24, 198, 0.00) 74.72%);
        content: '';
        position: absolute;
        width: 554px;
        top: 0;
        z-index: 1;
        height: 100%;
        left: -115px;
      }
    }
    li {
      div {
        max-width: 330px;
      }
    }
  }
`;

export const SelectedAnswer = styled.div`
  border-left: 0.5px solid #9C9C9C;
  height: 100%;
  margin-top: 0;
  max-width: 505px;
  min-height: 543px;
  padding-left: 20px;
  padding-right: 32px;
  padding-top: 8px;
  position: relative;
  ::before {
    border-radius: 50%;
    background-color: #0318C6;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -10px;
    left: -10px;
    z-index: 5;
    content: '';
  }
  div {
    color: #0318C6;
    font-family: 'syne';
    font-size: 30px;
    line-height: normal;
  }
  p {
    display: block;
    color: #000;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 20px;
    position: relative;
    z-index: 10;
  }
  @media (min-width: 1440px) {
    max-width: 554px;
    padding-left: 36px;
    padding-top: 8px;
    min-height: 352px;
    padding-right: 0;
    width: 100%;
    ::before {
      border-radius: 50%;
      background-color: #0318C6;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -10px;
      left: -10px;
      z-index: 5;
      content: '';
    }
    div {
      font-size: 33px;
    }
  }
`;
