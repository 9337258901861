import React from 'react';

export default function FaqsPdf() {
  return (
    <div>
      <img
        src="https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/Specs_Faq_fi89sx.pdf"
        alt=""
        style={{ margin: '40px auto' }}
      />
    </div>
  );
}
