import styled from '@emotion/styled';

export const Root = styled.div`
  display: none;
  @media (min-width: 1024px) {
    -webkit-transform: translate3d(0, 0, 0);
    display: flex;
    position: absolute;
    right: -195px;
    top: 0;
    z-index: 950;
    overflow: unset;
    height: 100%;
  }
`;

export const StepCounterWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  align-content: center;
  justify-content: center;
  height: 100vh;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`;

export const StepIndexWrap = styled.a`
  align-items: center;
  display: flex;
  margin-top: 16px;
  width: 220px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  :first-of-type {
    margin-top: 0;
  }
  div {
    transition: all 0.15s;
    align-items: center;
    background-color: #fff;
    background-color: ${(props) => props.current && '#55dcac'};
    background-color: ${(props) => props.currentBlue && '#0b2ac7'};
    border-radius: 50%;
    border: ${(props) => (props.current || props.currentBlue ? 'none' : '1px solid #e7ebef')};
    box-shadow: 0 16px 24px -15px rgba(210, 227, 219, 0.91);
    box-shadow: ${(props) => props.current && ' 0 16px 24px -15px rgba(124, 217, 175, 0.91)'};
    box-shadow: ${(props) => props.currentBlue && ' 0 16px 24px -15px rgba(11, 42, 199, 0.5);'};
    color: white;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
  }
  p {
    transition: all 0.15s;
    font-size: 12px;
    margin-left: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    color: ${(props) => props.current && '#55dcac'};
    color: ${(props) => props.currentBlue && '#0b2ac7'};
  }
  svg {
    height: auto;
    max-width: ${(props) => props.maxWidth || ''};
  }
  circle,
  path,
  rect {
    fill: ${(props) => (props.current || props.currentBlue ? '#fff' : '#c4c9cf')};
    stroke: #fff;
    stroke: ${(props) => props.current && '#55dcac'};
    stroke: ${(props) => props.currentBlue && '#0b2ac7'};
  }
`;

export const noOverflow = {
  html: {
    overflowY: 'unset',
    overflow: 'unset',
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
};
