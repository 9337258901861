/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { BackgroundImage, PlusIconNineStyles, bubble, PageWrap } from './allStepsStyles';
import { PlusIcon9 } from './svg/plusIcons';
import Step from './Step';
import { AccountantInput } from './svg/largeIllustrations';
import { fadeIn, fadeOut } from './fade';
import { NumeroSix } from './svg/NumberCircle';
import { StandardImage } from '../ui/Image.jsx';

export default function StepOne({ sixInView, refSix }) {
  const sectionRefSix = useRef(null);

  const intersection = useIntersection(sectionRefSix, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });
  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInSix') : fadeOut('.fadeInSix');
  return (
    <PageWrap ref={sectionRefSix}>
      <BackgroundImage>
        <StandardImage className='fadeInSix' image='specs/background6_ykmzro.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroSix />}
        blue
        currentRef={refSix}
        illustraion={<AccountantInput />}
        header='Accountant Input'
        subText='We are in touch with your (internal and/or outside) accountant to corroborate and clarify any questions related to your specific tax situation, and any special tax treatments associated with the property that might affect the outcome of the cost segregation study'
      />
      <PlusIconNineStyles>
        <PlusIcon9 />
      </PlusIconNineStyles>
    </PageWrap>
  );
}
