import React from "react";
import { saveTrackingCookies } from "./seo-tracking";
import { useEffect } from "react";
import Routes from "./Routes";
import { AddressContextProvider } from "./components/map/Address";

const IndexPage = () => {
  useEffect(() => {
    saveTrackingCookies();
  }, []);

  return (
    <AddressContextProvider>
      <Routes />
    </AddressContextProvider>
  );
};

export default IndexPage;
