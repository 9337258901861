/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Root } from './storyStyles';

import { BuildingThree, Rings, PlusBunch } from './illustrations';

export default function SectionFive({ study }) {
  return (
    <Root>
      <PlusBunch className='plus-bunch' />
      <Rings />
      {study.storyIllustration ? (
        <img
          src={study.storyIllustration}
          alt=''
          className='building-illustration'
          css={{ maxWidth: '71.5%', margin: '38px auto 0', display: 'block' }}
        />
      ) : (
        <BuildingThree
          className='building-illustration'
          css={{ maxWidth: '71.5%', margin: '38px auto 0', display: 'block' }}
        />
      )}
      <div className='text-section'>
        <div className='section-title'>
          Every Study <br />
          Has a Story
        </div>
        <div className='section-sub-title'>{study.storySubHeader}</div>
        <p className='section-text'>{study.story}</p>
        <div className='story-author'>- {study.storyAuthor}</div>
      </div>
    </Root>
  );
}
