export const content = {
  color: '#000',
  padding: '66px 24px 50px',
  position: 'relative',
  h1: {
    maxWidth: 212,
    position: 'absolute',
    top: -62,
    zIndex: '30',
  },
  '@media(min-width: 768px)': {
    paddingTop: 28,
    paddingLeft: 40,
    h1: {
      maxWidth: 265,
      top: -38,
      '@media(min-width: 1024px)': { maxWidth: 317 },
      '@media(min-width: 1260px)': { maxWidth: 423, top: -40 },
      '@media(min-width: 1440px)': { left: 80 },
      '@media(min-width: 1920px)': { left: 80 },
    },
  },
  '@media(min-width: 1920px)': {
    maxWidth: 1489,
    marginLeft: 280,
    paddingBottom: 0,
  },
};

export const sectionOneWrap = {
  position: 'relative',
  '@media(min-width: 768px)': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  '@media(min-width: 1260px)': {
    margin: '0 auto',
  },
  '@media(min-width: 1440px)': {
    marginTop: 64,
    marginLeft: 40,
    display: 'flex',
  },
};

export const buildingStyles = {
  margin: 'auto',
  maxWidth: 313,
  position: 'relative',
  width: '100%',
  '@media(min-width: 768px)': {
    margin: 0,
    marginTop: 22,
    maxWidth: 363,
    overflow: 'visible',
  },
  '@media(min-width: 1024px)': {
    marginRight: 60,
    maxWidth: 413,
  },
  '@media(min-width: 1260px)': { maxWidth: 530 },
  '@media(min-width: 1440px)': {
    top: -45,
    left: -19,
    margin: 0,
    maxWidth: 620,
    position: 'relative',
    width: '100%',
  },
  '@media(min-width: 1920px)': {
    left: 100,
    maxWidth: 775,
    top: -45,
  },
};

export const buildingTextS = {
  maxWidth: 375,
  width: '100%',
  margin: '0 auto',
  'p:first-of-type': {
    marginTop: 68,
    fontSize: 18,
    lineHeight: '1.56',
  },
  'p:last-of-type': {
    lineHeight: '2',
    marginTop: 40,
  },
  '@media(min-width: 768px)': {
    maxWidth: 327,
    marginLeft: 0,
    marginRight: 0,
    'p:first-of-type': { paddingRight: 13 },
  },
  '@media(min-width: 1024px)': {
    maxWidth: 427,
    'p:first-of-type': { fontSize: 20 },
    'p:last-of-type': {
      fontSize: 16,
    },
  },
  '@media(min-width: 1260px)': {
    maxWidth: 520,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 568,
    'p:first-of-type': {
      marginLeft: 0,
      fontSize: 24,
      lineHeight: '1.67',
    },
    'p:last-of-type': {
      fontSize: 18,
      lineHeight: 1.7,
      marginTop: 64,
      width: 480,
    },
  },
  '@media(min-width: 1920px)': {
    maxWidth: 563,
    marginTop: 78,
    'p:first-of-type': { marginTop: 0 },
    'p:last-of-type': { marginTop: 50 },
  },
};

export const sectionTwoWrap = {
  paddingTop: 64,
  '& .non-structural-text-wrap': { lineHeight: 2, marginTop: 81 },
  '& .floorIllus-image': {
    margin: 'auto',
    width: '100%',
    maxWidth: 256,
  },
  '@media(min-width: 768px)': {
    alignItems: 'flex-end',
    display: 'flex',
    paddingTop: 0,
    '& .floorIllus-image': { maxWidth: 277, margin: '0 76px 0 24px' },
    '& .non-structural-text-wrap': {
      marginTop: 0,
      maxWidth: 311,
      position: 'relative',
      top: -16,
    },
  },
  '@media(min-width: 1024px)': {
    '& .floorIllus-image': { maxWidth: 296, margin: '40px 125px 0 64px' },
    '& .non-structural-text-wrap': { fontSize: 16, maxWidth: 435 },
  },
  '@media(min-width: 1260px)': {
    '& .floorIllus-image': {
      maxWidth: 400,
    },
    '& .non-structural-text-wrap': {
      maxWidth: 492,
      fontSize: 18,
      lineHeight: 1.78,
    },
  },
  '@media(min-width: 1440px)': {
    alignItems: 'flex-start',
    '& .floorIllus-image': { maxWidth: 416, margin: '0px 170px 0 120px' },
    '& .non-structural-text-wrap': { marginTop: 126 },
  },
  '@media(min-width: 1920px)': {
    '& .floorIllus-image': { maxWidth: 416, margin: '0px 170px 0 120px', position: 'relative', top: -60 },
    '& .non-structural-text-wrap': { marginTop: 40 },
  },
};

export const treeLgStyles = {
  left: 5,
  position: 'absolute',
  top: 210,
  '@media(min-width: 768px)': {
    top: 300,
    left: 50,
  },
  '@media(min-width: 1024px)': {
    height: 'auto',
    left: 85,
    top: 360,
    width: 30,
  },
  '@media(min-width: 1260px)': {
    top: 405,
    left: 74,
  },
  '@media(min-width: 1440px)': {
    height: 'auto',
    left: 49,
    maxWidth: 39,
    top: 535,
    width: '100%',
  },
  '@media(min-width: 1920px)': {
    maxWidth: 50,
    top: 174,
    left: -75,
  },
};

export const treeSmStyles = {
  position: 'absolute',
  right: 29,
  top: 238,
  '@media(min-width: 768px)': {
    top: 300,
  },
  '@media(min-width: 1024px)': {
    height: 30,
    left: 380,
    top: 340,
    width: 18,
  },
  '@media(min-width: 1260px)': {
    left: 486,
    top: 380,
  },
  '@media(min-width: 1440px)': {
    left: 527,
    top: 457,
    width: '100%',
    maxWidth: 20,
    height: 'auto',
  },
  '@media(min-width: 1920px)': {
    maxWidth: 26,
    top: 682,
    left: 645,
  },
};
