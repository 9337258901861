export const cSBasics = [
  {
    question: 'What is Cost Segregation?',
    answer:
      'Cost Segregation is the IRS approved process of accelerating depreciation on a Real Estate asset by separating non-structural personal assets (five year depreciable life) and land improvements (15 year depreciable life) from real property assets (27.5 or 39 year depreciable life).',
  },
  {
    question: 'What types of properties qualify for cost segregation?',
    answer:
      'Virtually every type of property used for business or investment qualifies for a Cost Segregation study.',
  },
  {
    question: 'How much should I expect to save with a cost segregation study?',
    answer:
      'That will depend on the amounts of assets that can be moved into shorter life schedules, as well as your personal tax rate.',
  },
  {
    question: 'Why should I use your company?',
    answer: 'Great question. Check out our About Us page and you’ll understand why.',
  },
  {
    question: 'Can I do it myself?',
    answer:
      'Technically yes....... if you are an accountant and engineer and trained in the specific expertise of performing cost seg studies. In our experience, however, we have seen that the IRS prefers third party independent studies. This will lessen the chance of an IRS Challenge, and in case of an IRS inquiry we fully stand behind our studies.',
  },
];

export const cSPricing = [
  {
    question: 'How much does a cost segregation study cost?',
    answer:
      'While every property is priced individually and every property type has its own norm, the typical cost seg study will cost in the $4,000-11,000 range. Fees for a cost segregation study are fixed, and directly based on the size and complexity of the property being assessed. The more complex the property, the longer it will take to allocate all of its components into the proper depreciable periods, and to ultimately produce the actual study.The flip side to the higher cost of a larger and/or more complex property is that the direct result is a higher percentage of faster depreciable assets, increasing your tax savings and your immediate cash flow. ',
  },
  {
    question: 'Why can’t you charge me a percentage of the savings that you find?',
    answer:
      'The IRS specifically discourages contingency pricing which gives the cost segregation company a reason to unjustifiably push the limits on acceptable reallocation of assets in order to drive up the findings, and ultimately their fees.',
  },
  {
    question: 'What happens if you perform the cost segregation study and don’t find any savings?',
    answer:
      'Madison SPECS does an up-front, no-cost feasibility analysis before any actual study is performed. Using our experience over thousands of studies of every property type, we give an estimate of the savings that can be expected through the full study. If we do not feel there is enough value in our service, we will tell you so before you spend a nickel.',
  },
  {
    question: 'How long does it take to get the study completed?',
    answer:
      'The time it takes to get a cost segregation study performed usually depends on the complexity of the property, as well as how quickly we get the information from you and/or your accountant. Our 20 engineers are strategically located around the country and are always just “around the corner” from you and your property. This allows us to work with your necessary timelinesto ensure that you get all study documentation back before you need it.',
  },
  {
    question: 'When is the best time for a cost segregation study?',
    answer:
      'It is best to perform a cost segregation study prior to the filing of the initial depreciation schedule on an asset. This will be immediately after the purchase of a newly acquired building, or once a construction project is completed and placed in service.',
  },
  {
    question: 'Can I use cost segregation on buildings I placed in service in the past?',
    answer:
      'Absolutely.  “Missed” depreciation for past purchases, construction, expansions, and renovations can be recouped later via a cost segregation study. In such cases, there is no need to amend already filed tax returns. “Catch–up” depreciation can be taken in one year by filing Form 3115 (Change in Accounting Method). While, as a general rule, it is usually worthwhile to have a cost segregation study done up to five years after purchasing or renovating a property, every situation is different and you should discuss with your Tax Advisor and cost seg specialist if it makes sense for you.',
  },
  {
    question: 'My property is 20 years old. Is cost segregation still beneficial?',
    answer:
      'The actual age of a property is irrelevant to whether you can receive a gain by performing a cost segregation study on it. What is relevant is how long ago you purchased the property. It is typically worthwhile to get a cost seg study done up to five years after purchasing it, although several factors can cause that number to increase or decrease. We would be happy to discuss your specific situation to help you make an informed decision, and run a no-cost feasibility analysis to show the actual savings that cost seg study would provide.',
  },
];

export const cSAccountants = [
  {
    question: 'Doesn’t my accountant already do cost segregation for me?',
    answer:
      'Most probably not. While your CPA is highly qualified in his line of work, cost segregation is a niche practice that requires qualified engineers, and very specific expertise in the application of their findings, to produce the quality study that will get the best possible results and be able to pass the scrutiny of the IRS. Most accountants either outsource the work, or, more often, will refer the client to a company that specializes in cost segregation, like Madison SPECS.',
  },
  {
    question: 'How do I know my CPA will agree with the results of the study?',
    answer:
      'Madison SPECS has worked with thousands of CPAs over the years. We value the input of theCPA and understand that, ultimately, a client’s accountant has the most comprehensive understanding of his tax situation. That’s why we work hand-in-hand with your accountant to make sure we are on the same page, and sends a draft of the completed study to you and your accountant to review beforewe finalize and prepare for insertion into your tax return.',
  },
];

export const cSTaxQuestions = [
  {
    question: 'Will cost segregation put me at risk of an IRS audit?',
    answer:
      'Cost segregation is a 100% IRS approved method of depreciating investment real estate. In our experience, as long as a recognized, qualified firm that follows the IRS Cost Segregation Audit Technique Guide performs the study, there is no additional risk of an audit.In the few instances when our clients had their returns audited for other reasons, our cost seg studies came back 100% returned with no change.',
  },
  {
    question:
      'What is the impact of a cost segregation study if I sell my property? Won’t I get hit with Recapture Tax?',
    answer:
      'All investment properties are subject to Recapture Tax upon its sale, which taxes all the depreciation that was taken as a tax deduction over the years of ownership of the property. When selling a property that had a cost segregation study performed on it, there will be the additional depreciation that will now get taxed, in addition to the typical depreciation amounts. That being said, recapture taxes are significantly lower than the top Federal and State Income Taxes that were shielded over years of ownership due to the accelerated depreciation from the cost segregation study. Additionally, the time value of money from having the cash in hand prior to selling the property also makes the performance of the cost seg study well worth it.',
  },
  {
    question: 'This property was part of a 1031 Exchange, can we still do Cost Segregation?',
    answer:
      'Yes. However, there are multiple options when depreciating the new property, which will ultimately affect the value of a cost segregation study. It is imperative that you discuss with your tax advisor and a cost segregation specialist to determine the best way forward.  ',
  },
];

export const allQuestions = cSBasics.concat(cSPricing, cSAccountants, cSTaxQuestions);
