import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { blue, green, yellow } from './questionsList';
import { Lightbulb, ThumbsUp, AwardIcon } from './icons';
import { MobileRoot, Responsive, TabsWrap, Tabber } from './categoryMobileStyles';

export default function CategoriesSections() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [storedSelectedCategory, setStoredSelectedCategory] = useState(selectedCategory);

  const handleTabClick = (index) => {
    setSelectedCategory(index);
    setStoredSelectedCategory(index);
  };

  const categoryData = [
    {
      title: "I'm new to cost segregation",
      headerTitle: 'newbie',
      icon: <Lightbulb />,
      questionList: blue,
      className: 'blue',
    },
    {
      title: "I've done this once before",
      headerTitle: 'novice',
      icon: <ThumbsUp />,
      questionList: green,
      className: 'green',
    },
    {
      title: 'I’m well-versed in cost segregation',
      headerTitle: 'expert',
      icon: <AwardIcon />,
      questionList: yellow,
      className: 'orange',
    },
  ];

  return (
    <Responsive id='tabsToMobile'>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 50 }}>
        <TabsWrap selectedIndex={storedSelectedCategory}>
          {categoryData.map((category, index) => (
            <Tabber
              key={index}
              index={index}
              selectedCategory={selectedCategory}
              onClick={() => handleTabClick(index)}
              className='syne'
            >
              {category.headerTitle}
            </Tabber>
          ))}
        </TabsWrap>
      </div>
      <CategorySection
        questionList={categoryData[selectedCategory].questionList}
        className={categoryData[selectedCategory].className}
        title={categoryData[selectedCategory].title}
        icon={categoryData[selectedCategory].icon}
        selected={selectedCategory}
        // headerTitle={categoryData[selectedCategory].headerTitle}
      />
    </Responsive>
  );
}

function CategorySection({ title, icon, questionList, styles, className, selected }) {
  const [selectedQuestion, setSelectedQuestion] = useState(questionList[0]);

  useEffect(() => {
    setSelectedQuestion(questionList[0]);
  }, [questionList]);

  const OptionLabel = ({ svg, title }) => (
    <div className='optionlabel'>
      <div>{svg}</div>
      <div>{title}</div>
    </div>
  );

  return (
    <MobileRoot style={styles} tabIndex='0' selected={selected} color={className}>
      {/* <div className='icon_cirle'>{icon}</div> */}
      <div className='cat_title'>{title}</div>
      <Select
        options={questionList}
        onChange={(e) => setSelectedQuestion(e)}
        className='select'
        classNamePrefix='select'
        // menuIsOpen={true}
        isSearchable={false}
        value={
          selectedQuestion
            ? {
                value: selectedQuestion.value,
                label: <OptionLabel svg={selectedQuestion.svg} title={selectedQuestion.title} />,
              }
            : null
        }
      />
      <div className={className}>
        {selectedQuestion && (
          <div className='answer_wrap'>
            <p>{selectedQuestion.description}</p>
          </div>
        )}
        {selectedQuestion && (
          <img
            src={`https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/glossary/${selectedQuestion.image}.png`}
            alt='category illustration'
            className='answer_illustration'
          />
        )}
      </div>
    </MobileRoot>
  );
}
