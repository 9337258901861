export const root = {
  position: 'relative',
  textAlign: 'center',
  padding: '0 20px 105px 20px;',
  color: '#000',
  '@media(min-width: 768px)': {
    padding: '0 50px 125px 0px;'
  },
  '@media(min-width: 1024px)': {
    padding: '0 0px 125px 0px;'
  }
};

export const headerWrap = {
  width: 240,
  margin: 'auto',
  '@media(min-width: 1024px)': {
    width: 320
  },
  '@media(min-width: 1440px)': {
    width: 480
  }
};

export const subHeader = {
  fontSize: 18,
  marginBottom: 42,
  marginTop: 40,
  lineHeight: 1.56,
  textAlign: 'left',
  '@media(min-width: 768px)': {
    maxWidth: 595,
    textAlign: 'center',
    margin: '40px auto 0',
    marginBottom: 64
  },
  '@media(min-width: 1024px)': {
    fontSize: 20,
    lineHeight: 1.4
  },
  '@media(min-width: 1440px)': {
    display: 'none'
  }
};
export const outerWrap = {
  '@media(min-width: 768px)': {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  '@media(min-width: 1440px)': {
    justifyContent: 'center'
  }
};
export const innerWrap = {
  '@media(min-width: 768px)': {
    maxWidth: 335
  },
  '@media(min-width: 1024px)': {
    maxWidth: 440,
    marginRight: 56
  },
  '@media(min-width: 1440px)': {
    marginLeft: 50,
    maxWidth: 600
  }
};
export const firstInfo = {
  '@media(max-width: 1439px)': {
    display: 'none'
  },
  '@media(min-width: 1440px)': {
    fontSize: 24,
    width: 486,
    lineHeight: 1.6,
    textAlign: 'left',
    marginTop: 120,
    marginBottom: 56
  }
};
export const info = {
  fontSize: 14,
  lineHeight: 2,
  color: '#000',
  textAlign: 'left',
  '@media(min-width: 1024px)': {
    fontSize: 16,
    maxWidth: 424
  },
  '@media(min-width: 1440px)': {
    fontSize: 18,
    lineHeight: 1.7,
    maxWidth: 560
  }
};
export const secondInfo = {
  fontSize: 14,
  lineHeight: 2,
  color: '#000',
  textAlign: 'left',
  marginTop: 32,
  '@media(min-width: 1024px)': {
    fontSize: 16,
    maxWidth: 424
  },
  '@media(min-width: 1440px)': {
    maxWidth: 500
  }
};
export const engineerWrap = {
  maxWidth: 327,
  margin: 'auto',
  marginTop: 98,
  position: 'relative',
  '& > svg': {
    position: 'relative',
    zIndex: 1,
    width: 327,
    '@media(min-width: 1024px)': {
      width: 434
    },
    '@media(min-width: 1440px)': {
      width: 500
    }
  },
  '@media(min-width: 768px)': {
    marginTop: 60
  },
  '@media(min-width: 1024px)': {
    maxWidth: 434,
    marginTop: 65
  },
  '@media(min-width: 1440px)': {
    margin: 0,
    maxWidth: 676,
    marginTop: 100
  }
};

export const circlesBlocksStyles = {
  position: 'absolute',
  top: -30,
  left: 14,
  '@media(min-width: 768px)': {
    top: -50
  },
  '@media(min-width: 1024px)': {
    svg: { width: 97, height: 154 }
  },
  '@media(min-width: 1440px)': {
    top: -30
  }
};
