import styled from '@emotion/styled';

export const QuestionWrap = styled.div`
  background-color: ${props => (props.isOpen ? '#ffffff' : '#fafafa')};
  border: solid 1px #e7e7eb;
  box-shadow: 0 2px 4px 0 #f0f0f2;
  cursor: pointer;
  margin-bottom: 16px;
  padding: 36px 32px 32px 32px;
  position: relative;
  width: 502px;
  width: 100%;
  z-index: 500;
  :hover {
    background-color: #fff;
  }
`;
export const QuestionStyles = styled.h1`
  font-size: 24px;
  line-height: 1.5;
`;
export const ParStyles = styled.p`
  line-height: 2;
  max-width: 247px;
  padding-top: 26px;
  max-width: 568px;
  @media (min-width: 1024px) {
    padding-left: 80px;
  }
`;

export const ClosedQuestion = styled.div`
  display: flex;
  align-items: center;
`;

export const MotifWrap = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  width: 175px;
  height: 168px;
  z-index: 1;
  right: 37px;
  transition: all 0.2s;
  opacity: ${props => (props.isOpen ? '1' : '0')};
`;
export const plusMinus = {
  cursor: 'pointer',
  width: 56,
  height: 56,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    position: 'absolute',
    width: 24,
    height: 24,
    transition: 'all .2s',
    ':last-of-type': {
      transform: 'rotate(-90deg)',
      transition: 'all .2s'
    }
  },
  '&:hover': {
    content: '""',
    backgroundColor: 'rgba(7, 39, 199, 0.05)',
    zIndex: 10
  }
};
export const openIcon = {
  svg: {
    ':last-of-type': {
      transform: 'rotate(0)',
      transition: 'all .2s'
    },
    ':first-of-type': {
      transform: 'rotate(180deg)',
      transition: 'all .2s'
    }
  }
};
