/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import CallButton from '../components/ui/CallButton';
import { ReactComponent as Illus } from '../components/ui/svg/404.svg';
import { Motif } from '../components/ui/icons';
import '../styles/404.css';

export default function FourOhFour() {
  return (
    <Fragment>
      <Helmet>
        <title>404 Page | Specs</title>
        <meta name='description' content='404' />
      </Helmet>
      <div style={{ backgroundColor: '#f2f2f2', position: 'relative' }}>
        <div className='motifOne'>
          <Motif />
        </div>
        <div className='rootS'>
          <div className='textwrap'>
            <h1 className='page-header__title'>Ooooops?</h1>
            <p className='page-header__title mt2'>This page isn’t available</p>
            <span className='message'>The page you are looking for couldn’t be found</span>
          </div>
          <div className='illustWrap'>
            <Illus />
          </div>
          <CallButton to='/' text='Go To Homepage' styles={{ margin: '24px auto' }} />
        </div>
      </div>
    </Fragment>
  );
}
