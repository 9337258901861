/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Helmet } from 'react-helmet';
import { SearchSectionWrap } from '../components/map/TopSection';
import MapSection from '../components/map/MapSection';
import PropertyCards from '../components/map/PropertyCards';
import InfoTiles from '../components/map/InfoTiles';
import Testimonial from '../components/home/Testimonial';
import { testimonialOne } from '../components/home/testamonialsData';
import Steps from '../components/map/Steps';
import BottomSection from '../components/map/BottomSection';


export default function Map() {
  return (
    <div>
      <Helmet>
        <title>Madison Specs | Map</title>
        <meta name='description' content='Our Footprint' />
      </Helmet>
      <SearchSectionWrap />
      {/* React snap is incapable of rendering the MapSection for some reason. Therefore,
      if we are currently being rendered from react snap, we skip the MapSection.
      
      This fixes the build, however there are still issues. The server side rendered version
      of this page is all messed up since it's missing the MapSection. Therefore, if someone
      goes directly to madisonspecs.com/map they get a messed up page. If however, they go to
      any other page, and then click on Our Footprint, it works fine because then it's loading
      the /map page using react, NOT ser
      */}
      <MapSection />
      <PropertyCards />
      <InfoTiles />
      <Testimonial testimonials={testimonialOne} />
      <Steps />
      <BottomSection />
    </div>
  );
}
