import styled from '@emotion/styled';

export const Root = styled.div`
  padding: 166px 32px 64px;
  text-align: center;
  position: relative;
  > svg {
    position: absolute;
    max-width: 262px;
    bottom: -205px;
    left: 14px;
  }
  @media (min-width: 768px) {
    padding: 240px 121px 64px 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    max-width: 768px;
    margin: 0 auto;
    > svg {
      max-width: 362px;
      left: 62px;
      bottom: -152px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
    padding: 248px 185px 72px 180px;
    > svg {
      max-width: 375px;
      left: 96px;
      bottom: -100px;
    }
  }
  @media (min-width: 1440px) {
    padding: 348px 280px 128px;
    max-width: 1440px;
    > svg {
      max-width: 600px;
      left: 120px;
    }
  }
  @media (min-width: 1920px) {
    > svg {
      left: -60px;
      bottom: 2px;
    }
  }
`;

export const TextWrap = styled.div`
  position: relative;
  z-index: 5;
  p {
    font-family: 'Syne', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    max-width: 300px;
  }
  span {
    font-weight: 300;
    line-height: 24px;
    margin-top: 8px;
    display: block;
  }
  @media (min-width: 768px) {
    max-width: 305px;
    p {
      max-width: 260px;
    }
    span {
      margin-top: 16px;
      br {
        display: none;
      }
    }
  }
  @media (min-width: 1024px) {
    max-width: 400px;
    p {
      font-size: 32px;
      line-height: 39px;
      max-width: 332px;
    }
    span {
      margin-top: 8px;
    }
  }
  @media (min-width: 1440px) {
    max-width: 470px;
    p br {
      display: initial;
    }
    span {
      margin-top: 16px;
      font-size: 18px;
      line-height: 32px;
    }
  }
`;

export const Button = styled.div`
  align-items: center;
  background: #0827c7;
  border-radius: 8px;
  box-shadow: 0px 21px 40px rgba(19, 21, 50, 0.388576);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 48px;
  justify-content: space-between;
  margin: 48px auto 0;
  padding: 0 24px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.2 s;
  width: 187px;
  z-index: 5;
  span {
    display: none;
  }
  @media (min-width: 768px) {
    height: 56px;
    margin: 0px;
    font-size: 14px;
    width: 183px;
  }
  @media (min-width: 1440px) {
    height: 64px;
    width: 270px;
    span {
      display: inline-block;
    }
  }
`;
