/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import { useEffect, useState } from 'react';

import {
  FeasabilitySmall,
  AccountantInputSmall,
  DocumentGatheringSmall,
  TheBeginningSmall,
  ProposalSmall,
  SiteVisitSmall,
  StudyDeliveredSmall,
} from './svg/small-illustrations';

import { Root, StepCounterWrap, StepIndexWrap, noOverflow } from './sideStepStyles';

const clickSections = {
  theBeginning: 'TheBeginning',
  feasibilityAnalysis: 'FeasibilityAnalysis',
  proposal: 'Proposal',
  documentGathering: 'DocumentGathering',
  siteVisit: 'SiteVisit',
  accountantInput: 'AccountantInput',
  studyDelivered: 'StudyDelivered',
};

export default function SideStepCounter({
  onSectionClick,
  oneInView,
  twoInView,
  threeInView,
  fourInView,
  fiveInView,
  sixInView,
  sevenInView,
}) {
  const [current, setCurrent] = useState('TheBeginning');
  const currentSection = (str) => {
    setCurrentBlue('');
    onSectionClick(str);
    setCurrent(str);
  };
  const [currentBlue, setCurrentBlue] = useState('');
  const currentBlueSection = (str) => {
    setCurrent('');
    onSectionClick(str);
    setCurrentBlue(str);
  };

  useEffect(() => {
    setCurrentBlue('');
  }, [oneInView, twoInView, threeInView]);

  useEffect(() => {
    setCurrent('');
  }, [fourInView, fiveInView, sixInView, sevenInView]);

  return (
    <Root>
      <Global styles={noOverflow} />
      <StepCounterWrap>
        <StepIndexWrap
          current={'TheBeginning' === current || oneInView}
          maxWidth='18px'
          onClick={() => currentSection(clickSections.theBeginning)}
        >
          <div>
            <TheBeginningSmall />
          </div>
          <p>The Beginning</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='18px'
          current={twoInView || 'FeasibilityAnalysis' === current}
          onClick={() => currentSection(clickSections.feasibilityAnalysis)}
        >
          <div>
            <FeasabilitySmall />
          </div>
          <p>Feasibility Analysis</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='12px'
          current={threeInView || 'Proposal' === current}
          onClick={() => currentSection(clickSections.proposal)}
        >
          <div>
            <ProposalSmall />
          </div>
          <p>Proposal</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='14px'
          currentBlue={fourInView || 'DocumentGathering' === currentBlue}
          onClick={() => currentBlueSection(clickSections.documentGathering)}
        >
          <div>
            <DocumentGatheringSmall />
          </div>
          <p>Document Gathering</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='22px'
          currentBlue={fiveInView || 'SiteVisit' === currentBlue}
          onClick={() => currentBlueSection(clickSections.siteVisit)}
        >
          <div>
            <SiteVisitSmall />
          </div>
          <p>Site Visit</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='17px'
          currentBlue={sixInView || 'AccountantInput' === currentBlue || sixInView}
          onClick={() => currentBlueSection(clickSections.accountantInput)}
        >
          <div>
            <AccountantInputSmall />
          </div>
          <p>Accountant Input</p>
        </StepIndexWrap>
        <StepIndexWrap
          maxWidth='14px'
          currentBlue={sevenInView || 'StudyDelivered' === currentBlue || sevenInView}
          onClick={() => currentBlueSection(clickSections.studyDelivered)}
        >
          <div>
            <StudyDeliveredSmall />
          </div>
          <p>Study Delivered</p>
        </StepIndexWrap>
      </StepCounterWrap>
    </Root>
  );
}
