import styled from '@emotion/styled';

export const Root = styled.div`
  background-color: ${(props) => (props.isOpen ? '#ffffff' : '#fafafa')};
  cursor: pointer;
  position: relative;
  z-index: 500;
  border: solid 1px #e7e7eb;
  box-shadow: 0 2px 4px 0 #f0f0f2;
  margin-top: 8px;
  padding-left: 48px;
  padding: 16px;
  transition: all 0.2s;
  width: 100%;
  :hover {
    background-color: #fff;
    transition: all 0.2s;
    .succos {
      background-color: rgba(7, 39, 199, 0.05);
    }
  }
  @media (min-width: 768px) {
    padding: ${(props) => (props.isOpen ? '16px 16px 16px 48px' : '16px 16px 16px 48px')};
  }
`;
export const QuestionStyles = styled.h1`
  font-size: 16px;
  line-height: 1.5;
`;
export const ParStyles = styled.p`
  font-size: 14px;
  line-height: 2;
  max-width: 247px;
  max-width: 568px;
  padding-top: 26px;
`;

export const ClosedQuestion = styled.div`
  align-items: center;
  display: flex;
`;

export const MotifWrap = styled.div`
  height: 168px;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  position: absolute;
  right: 16px;
  right: 37px;
  top: 0;
  transition: all 0.2s;
  width: 175px;
  z-index: 1;
`;
export const plusMinus = {
  alignItems: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  left: 8,
  position: 'absolute',
  top: 10,
  width: 32,
  svg: {
    height: 2,
    position: 'absolute',
    transition: 'all .2s',
    width: 16,
    ':last-of-type': {
      transform: 'rotate(-90deg)',
      transition: 'all .2s',
    },
  },
};
export const openIcon = {
  svg: {
    ':last-of-type': {
      transform: 'rotate(0)',
      transition: 'all .2s',
    },
    ':first-of-type': {
      transform: 'rotate(180deg)',
      transition: 'all .2s',
    },
  },
};
