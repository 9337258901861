/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const textWrap = {
  fontSize: 15,
  lineHeight: 1.5,
  margin: '0 auto 30px',
  maxWidth: 824,
  padding: '0 20px',
  textAlign: 'justify',
  span: {
    display: 'block',
    fontSize: 16,
    fontWeight: 500,
    margin: '40px 0 20px',
  },
  '@media(min-width: 768px)': {
    padding: '0 32px',
    marginBottom: 100,
  },
};

export default function Security() {
  return (
    <Fragment>
      <Helmet>
        <title>Madison SPECS | Privacy</title>
        <meta name='description' content='Privacy Policy' />
      </Helmet>
      <div css={{ margin: '50px auto', fontSize: 50, textAlign: 'center' }}>Madison SPECS Privacy Policy</div>
      <div css={textWrap}>
        This website is created and maintained by Madison SPECS, LLC an entity doing business in the United States of
        America (together with its employees, advisors, affiliates and related companies, collectively hereinafter
        referred to as “SPECS”). This notice provides SPECS’ (“we” “us”) privacy policy regarding the nature, purpose,
        use, and sharing of any Personally Identifiable Information (PII) collected on this website. Our privacy policy
        explains our information practices when a visitor to the website (“you”) provides PII through this website. PII
        may include your name, email, phone numbers, or other information that identifies you personally. We do not
        require you to register or provide personal information to visit this website.
        <br />
        <span>Personally Identifiable Information</span>
        As a general rule, we do not collect PII about you when you visit this website, unless you choose to provide
        such information to us. You may submit your name, email address and phone number for the limited purpose of
        providing us a method to reach out to you for services that you may wish. Submitting PII through the website is
        voluntary. By doing so, you are giving us your permission to use the information for the stated purpose. If you
        choose to provide us with PII, through such methods as completing a web form, we will use that information
        solely for the stated purpose. <br />
        Categories of information we collect on this website are further described below.
        <br />
        <span>Security</span>
        We take the security of all PII very seriously. We take precautions to maintain the security, confidentiality,
        and integrity of the information we collect on this site. We work to protect PII stored on our servers from
        unauthorized access using commercially available computer security products. We also employ various security
        technologies to protect the information stored on our systems.
        <span>Tracking Utilities</span>
        SPECS has designed features on this website to allow users to anonymously use the site without revealing their
        identities. The only information we may collect online is from cookies, pixel tags or similar devices, which
        provide limited data, such as the date, time and areas of the site that were visited, interactions on the site,
        and the Web path/domain from where the visitor came. SPECS uses this information only to recognize the user as a
        unique visitor and hold information during the visit to the site. Tracking utilities identified are not tied to
        PII.
        <br />
        <br />
        This general information allows SPECS to serve visitors better by continually improving this Web site and making
        it more convenient. In certain cases, users can choose not to provide us with the information by setting the
        browser to refuse to accept cookies, but if so, that may disable access to certain portions of the site.
        <span>Legal Disclaimer</span>
        Although we do not collect or share your PII except as previously described, we reserve the right to disclose
        your PII as permitted by law or when we believe that disclosure is necessary to protect our rights and/or to
        comply with a judicial proceeding, court order, or legal process served on our web site. At certain places on
        this site, live "links" to other Internet addresses may be accessed (“Linked Sites”). Except where otherwise
        specified, such Linked Sites contain information created, published, maintained, or otherwise posted by
        institutions or organizations independent of Madison SPECS. Madison SPECS does not endorse, approve, certify or
        control these Linked Sites and does not guarantee the accuracy, completeness, efficacy, timeliness or correct
        sequencing of information that they contain. Madison SPECS Privacy Policy does not apply to these Linked Sites.
        The privacy policies of these Linked Sites may differ from Madison SPECS, and you should review their policies
        before accessing them and/or submitting information.
        <span> Accurate and Updated Information</span>
        You have the option to change or modify information previously provided to us, or to have any information you
        have previously submitted, removed or modified, by emailing us at info@madisonspecs.com. In the event that you
        receive communications from us, you may opt out from receiving future communications by choosing the
        “unsubscribe” tab at the bottom of emails you receive from us.
        <span>Changes to the Privacy Policy</span>If we make significant changes to this Privacy Policy, we will post
        those changes to the Privacy Policy page and other places we deem appropriate so that you are aware of what
        information we collect, how we use it, and under what circumstances, if any, we disclose it.
      </div>
    </Fragment>
  );
}
