/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

import { Featured } from './propertyCardsStyles';
import { StarIcon, XIcon } from '../ui/icons';
import CallButton from '../ui/CallButton';

import industrialImg from '../ui/svg/map/warehouse.svg';
import multifamilyImg from '../ui/svg/map/multifamily-nursing-home.svg';
import nursingHomeImg from '../ui/svg/map/nursing-home.svg';
import officeImg from '../ui/svg/map/office.svg';
import retailImg from '../ui/svg/map/Retail_2.svg';
import specialtyImg from '../ui/svg/map/medical-office.svg';
import warehouse from '../ui/svg/map/warehouse.svg';
import skilledNursing from '../ui/svg/map/skilled-nursing-facility.svg';

import {
  CardWrap,
  XIconWrap,
  HeaderOne,
  FeaturedSection,
  cbOverrides,
  blackStyles,
  labelStyles,
  infoStyles,
  CardIllus,
  mobileIllus,
} from './completedStudiesStyles';

function formatMoney(amount, decimalCount = 0, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export default function CompletedStudies({
  propertyDescription,
  propertyValue,
  squareFootage,
  nearbyDeals,
  address,
  valueOfReclassifiedAssets,
  depreciableAssets,
  filter,
  miles,
  refresh,
}) {
  function getImage(desc) {
    switch (desc) {
      case 'Hotel':
        return skilledNursing;
      case 'Industrial':
        return industrialImg;
      case 'Multifamily':
        return multifamilyImg;
      case 'Nursing Homes/Senior Living':
        return nursingHomeImg;
      case 'Office':
        return officeImg;
      case 'Retail':
        return retailImg;
      case 'Specialty':
        return specialtyImg;
      default:
        return warehouse;
    }
  }
  return (
    <CardWrap>
      <Global
        styles={{
          '.smart-tour-wrap.smart-tour-wrap': {
            display: 'none',
          },
          '.prop-card-wrap.prop-card-wrap': {
            height: 0,
            overflow: 'hidden',
            marginTop: 72,
          },
        }}
      />
      <XIconWrap onClick={() => refresh()}>
        <XIcon css={{ cursor: 'pointer !important' }} />
      </XIconWrap>
      <HeaderOne>
        <span css={blackStyles}>We have completed </span>
        {/* <br /> */}
        {nearbyDeals.length}{' '}
        {filter === 'All Property Types' ? '' : filter === 'Nursing Homes/Senior Living' ? 'Nursing Homes' : filter}
        <span> Cost Seg Studies</span>
        {/* <br /> */}
        <span css={blackStyles}> within {miles} miles of </span>
        {/* <br /> */}
        {address}
      </HeaderOne>
      <FeaturedSection>
        <Featured>
          <StarIcon />
          <span>Featured</span>
        </Featured>
        <div css={{ padding: '16px 24px 24px' }}>
          <div css={labelStyles}>PROPERTY DESCRIPTION</div>
          <div css={infoStyles}>
            {squareFootage > 0 && formatMoney(squareFootage) + ` sf`} {propertyDescription}
          </div>
          <div css={labelStyles}>PROPERTY VALUE</div>
          <div css={infoStyles} className='syne'>
            ${formatMoney(propertyValue)}
          </div>
          <div css={labelStyles}>VALUE OF RECLASSIFIED ASSETS</div>
          <div css={{ fontSize: 32, color: '#0827c7' }} className='syne'>
            ${formatMoney(valueOfReclassifiedAssets)}
          </div>
          <div css={{ fontWeight: 'bold', color: '#0827c7', paddingTop: 6 }}>
            {depreciableAssets}% of depreciable assets
          </div>
        </div>
        <CardIllus src={getImage(propertyDescription)} alt='card illustration' />
      </FeaturedSection>
      <CallButton text='GET YOUR FREE QUOTE' to='/contact-us' secondary styles={cbOverrides} />
      <div css={mobileIllus}>
        <img src={getImage(propertyDescription)} alt='mobile illus' />
      </div>
    </CardWrap>
  );
}
