/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Motif } from '../ui/icons';
import { MainTourIllus } from './large-illustrations';
import { Wrapper, MotifSmall, MotifStyles, imageStyles, InnerWrap } from './sectionOneStyles';

export default function SectionOne() {
  return (
    <Wrapper>
      <InnerWrap>
        <MotifSmall>
          <Motif />
        </MotifSmall>
        <h1 className='syne'>The Madison SPECS Smart Tour</h1>
        <p>
          The Madison SPECS Smart Tour is the innovative solution to producing a first class cost segregation study,
          while maintaining the highest level of safety.
        </p>
        <div css={imageStyles}>
          <MainTourIllus />
        </div>
        <MotifStyles>
          <Motif />
        </MotifStyles>
      </InnerWrap>
    </Wrapper>
  );
}
