import styled from '@emotion/styled';

export const Root = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const OuterTopic = styled.div`
  align-items: center;
  background-color: #ffffff;
  box-shadow: ${props =>
    props.isOpen
      ? '0 8px 16px 0 rgba(223, 223, 229, 0.35)'
      : '0 17px 46px -7px rgba(8, 39, 199, 0.15)'};
  border-bottom: ${props =>
    props.isOpen ? 'solid 1px #e7e7eb' : '1px solid #f4f4f4'};
  padding: 16px 32px 16px 32px;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s;
  position: relative;
  z-index: 40;
  @media (min-width: 768px) {
    font-size: 14px;
    letter-spacing: 2px;
    padding: 17px 63px 16px 65px;
    text-transform: uppercase;
  }
`;
export const InnerWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const iconStyles = {
  alignItems: 'center',
  borderRadius: '50%',
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  marginRight: 16,
  width: 40
};
export const CollapseOpen = styled.div`
  transition: all 0.2s;
  background-color: #f4f4f4;
  background-image: linear-gradient(
      to bottom,
      rgba(216, 216, 216, 0.39),
      rgba(216, 216, 216, 0.39)
    ),
    linear-gradient(
      to bottom,
      rgba(216, 216, 216, 0.19),
      rgba(216, 216, 216, 0.19)
    );
  background-blend-mode: color, color-dodge;
  padding-top: 32px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ClosedQuestion = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;
export const plusIcon = {
  // position: 'absolute',
  // top: 26,
  // left: 16,
};
export const minusIcon = {
  // position: 'absolute',
  // top: 35,
  // left: 16,
};
export const QuestionWrap = styled.div`
  background-color: #ffffff;
  border: solid 1px #e7e7eb;
  box-shadow: 0 8px 16px 0 rgba(223, 223, 229, 0.35);
  padding: 24px 24px 32px 16px;
  position: relative;
  width: 100%;
  z-index: 500;
  @media (min-width: 768px) {
    padding: 20px 32px 20px 24px;
  }
`;
export const QuestionStyles = styled.h1`
  font-size: 16px;
  line-height: 1.5;
`;
export const ParStyles = styled.p`
  line-height: 2;
  padding-top: 26px;
  padding-left: 32px;
`;

export const MotifWrap = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  width: 175px;
  height: 168px;
  z-index: 1;
  @media (min-width: 768px) {
    right: 37px;
  }
`;

export const plusMinus = {
  cursor: 'pointer',
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    position: 'absolute',
    width: 16,
    height: 16,
    transition: 'all .2s',
    ':last-of-type': {
      transform: 'rotate(-90deg)',
      transition: 'all .2s'
    }
  }
};
export const openIcon = {
  svg: {
    ':last-of-type': {
      transform: 'rotate(0)',
      transition: 'all .2s'
    },
    ':first-of-type': {
      transform: 'rotate(180deg)',
      transition: 'all .2s'
    }
  }
};
