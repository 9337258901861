/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Motif } from '../ui/icons';
import { PlusIcon1, PlusIcon2 } from './svg/plusIcons';
import Image from '../ui/Image.jsx';

import {
  Root,
  motifStyles,
  TextWrap,
  HeroImage,
  motif2Styles,
  PlusIconOneStyles,
  PlusIconTwoStyles,
} from './sectionOneStyles';

export default function SectionOne() {
  return (
    <Root>
      <PlusIconOneStyles>
        <PlusIcon1 />
      </PlusIconOneStyles>
      <div css={motifStyles}>
        <Motif />
      </div>
      <TextWrap>
        <h1>The Specs Process</h1>
        <div>
          <p>
            We know that getting a detailed,
            <br /> professional cost segregation study with
            <br /> the highest level of accelerated depreciation allowed is what you want.
          </p>
          <p className='bold'>At Madison SPECS, our promise to you is the best experience getting there.</p>
        </div>
      </TextWrap>
      <HeroImage>
        <Image className='banner-hero-mobile' image='specs/full-hero-mobile-min_yiqsfr.png' alt='hero-mobile' />
        <Image className='banner-hero-tab' image='specs/top-grafik_3x-min_uoftuf.png' alt='hero' />
        <Image className='banner-hero-tab-land' image='specs/hero-tablet-min_ilinrv.png' alt='hero' />
        <Image className='banner-hero-desktop' image='specs/hero-desktop-min_uvbydl.png' alt='hero' />
      </HeroImage>
      <div css={motif2Styles}>
        <Motif />
      </div>
      <PlusIconTwoStyles>
        <PlusIcon2 />
      </PlusIconTwoStyles>
    </Root>
  );
}
