/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import { MinusIcon, BlueMotif } from '../ui/icons';
import {
  QuestionWrap,
  ClosedQuestion,
  QuestionStyles,
  MotifWrap,
  ParStyles,
  plusMinus,
  openIcon
} from './questionCardStyle';

export default function QuestionCard({ question, par, key }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <QuestionWrap isOpen={isOpen} onClick={() => setOpen(!isOpen)} key={key}>
      <ClosedQuestion>
        <div css={[plusMinus, isOpen && openIcon]}>
          <MinusIcon />
          <MinusIcon />
        </div>
        <div
          css={{
            paddingLeft: 24,
            width: 'calc(100% - 56px)',
            '@media(min-width: 1440px)': {
              maxWidth: 588
            }
          }}
        >
          <QuestionStyles className='syne'>{question}</QuestionStyles>
        </div>
      </ClosedQuestion>
      <Collapse isOpened={isOpen}>
        <MotifWrap isOpen={isOpen}>
          <BlueMotif />
        </MotifWrap>
        <ParStyles>{par}</ParStyles>
      </Collapse>
    </QuestionWrap>
  );
}
