import * as React from 'react';

export function NumeroOne(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 2.2 5.2'
      enableBackground='new 0 0 2.2 5.2'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path fill='#FFFFFF' d='M2.2,0v5.2h-1V0.9H0V0H2.2z' />
    </svg>
  );
}

export function NumeroTwo(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 4.5 5.3'
      enableBackground='new 0 0 4.5 5.3'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path
        fill='#FFFFFF'
        d='M4.4,4.4v0.9H0.1V4.4h0c3-2,3.2-2.3,3.2-2.8c0-0.4-0.3-0.7-1.1-0.7C1.5,0.9,1,1.2,1,1.6H0C0,0.7,0.8,0,2.2,0
	c1.3,0,2.2,0.5,2.2,1.5c0,0.9-1.1,1.6-2.2,2.2l-1,0.6H4.4z'
      />
    </svg>
  );
}

export function NumeroThree(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 4.6 5.4'
      enableBackground='new 0 0 4.6 5.4'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path
        fill='#FFFFFF'
        d='M4.5,3.9c0,0.9-0.9,1.5-2.3,1.5C0.9,5.4,0,4.7,0,3.7h1c0,0.5,0.6,0.8,1.2,0.8c0.8,0,1.2-0.3,1.2-0.8
	c0-0.5-0.5-0.8-1.4-0.8H1.4V2.3h0.7c0.9,0,1.3-0.3,1.3-0.7c0-0.4-0.4-0.7-1.1-0.7c-0.7,0-1.2,0.3-1.2,0.7h-1C0,0.6,1,0,2.2,0
	c1.4,0,2.2,0.6,2.2,1.4c0,0.8-0.7,1.1-1.6,1.2C3.8,2.7,4.5,3,4.5,3.9z'
      />
    </svg>
  );
}

export function NumeroFour(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 5 5.2'
      enableBackground='new 0 0 5 5.2'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path fill='#FFFFFF' d='M5,4.1H4.1v1.1H3V4.1H0V3.3L3,0h1v3.3H5V4.1z M3,3.3v-2l-1.8,2H3z' />
    </svg>
  );
}

export function NumeroFive(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 4.6 5.3'
      enableBackground='new 0 0 4.6 5.3'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path
        fill='#FFFFFF'
        d='M4.6,3.6c0,1.1-0.9,1.7-2.2,1.7C1.1,5.3,0.1,4.7,0,3.7h1c0.1,0.5,0.7,0.8,1.3,0.8c0.6,0,1.2-0.3,1.2-0.9
	S3,2.7,2.4,2.7c-0.5,0-1.1,0.1-1.3,0.5L0.2,3l0.2-3h3.7v0.9H1.4L1.2,2.5c0.3-0.4,0.9-0.6,1.5-0.6C3.8,1.9,4.6,2.5,4.6,3.6z'
      />
    </svg>
  );
}

export function NumeroSix(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 5 5.3'
      enableBackground='new 0 0 5 5.3'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path
        fill='#FFFFFF'
        d='M5,3.5c0,1.1-0.9,1.8-2.4,1.8C1,5.3,0,4.7,0,3.6C0,3.3,0.1,3,0.3,2.7C0.4,2.5,2.1,0,2.1,0h1.3L1.6,2.2
   C2,2,2.4,1.8,2.9,1.8C4.2,1.8,5,2.5,5,3.5z M2.6,2.6C1.7,2.6,1.1,3,1.1,3.5c0,0.6,0.6,0.9,1.5,0.9c0.8,0,1.4-0.3,1.4-0.9
   C3.9,2.9,3.4,2.6,2.6,2.6z'
      />
    </svg>
  );
}

export function NumeroSeven(props) {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 4.6 5.2'
      enableBackground='new 0 0 4.6 5.2'
      xmlSpace='preserve'
      height='100%'
      width='100%'
    >
      <path fill='#FFFFFF' d='M4.6,0v0.9L2.1,5.2H0.8l2.6-4.3H0V0H4.6z' />
    </svg>
  );
}
