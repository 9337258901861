import React from 'react';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';

import SectionOne from '../components/home/SectionOne';
import Clients from '../components/home/Clients';
import History from '../components/home/History';
import {
  testimonialOne,
  testimonialTwo,
  testimonialThree,
  testimonialFour,
} from '../components/home/testamonialsData';
import { CallButtonLink } from '../components/ui/CallButton';
import Map from '../components/home/Map';

import { nameFinder, subdomain } from '../components/ui/salePeeps';

const BenefitsMobile = loadable(() => import('../components/home/BenefitsMobile'));
const Benefits = loadable(() => import('../components/home/Benefits'));
const Explanation = loadable(() => import('../components/home/Explanation'));
const Savings = loadable(() => import('../components/home/Savings'));
const Testimonial = loadable(() => import('../components/home/Testimonial'));
const HelpYou = loadable(() => import('../components/home/HelpYou'));
const ChooseSpecs = loadable(() => import('../components/home/ChooseSpecs'));
const ProtectionMobile = loadable(() => import('../components/home/ProtectionMobile'));
const Protection = loadable(() => import('../components/home/Protection'));
const Reduce = loadable(() => import('../components/home/ReduceTaxBurden'));
const CallButton = loadable(() => import('../components/ui/CallButton'));
const Accountants = loadable(() => import('../components/home/Accountants'));
const LastSection = loadable(() => import('../components/home/LastSection'));

const IndexPage = () => (
  <div>
    <Helmet>
      <title>Madison SPECS</title>
      <meta
        name='description'
        content='Reduce Taxes and Increase Cash Flow with Cost Segregation'
      />
    </Helmet>
    <SectionOne />
    <Clients />
    <History />
    <BenefitsMobile />
    <Benefits />
    <Explanation />
    <Savings />
    <Testimonial testimonials={testimonialOne} />
    <HelpYou />
    <Testimonial testimonials={testimonialTwo} />
    <Map />
    <div
      style={{
        backgroundImage: 'linear-gradient(to bottom, #ffffff, #f2f2f2, #fafafa 75%, #ffffff)',
      }}
    >
      <ChooseSpecs />
      <ProtectionMobile />
      <Protection />
    </div>
    <Testimonial testimonials={testimonialThree} />
    {nameFinder() ? (
      <CallButtonLink
        blank
        text='Schedule A Call'
        link={
          subdomain === 'yonahweiss'
            ? 'https://calendly.com/yonahweiss/cost-segregation-call?month=2021-11'
            : 'https://calendly.com/costsegcowboy'
        }
        styles={{ margin: 'auto' }}
      />
    ) : (
      <CallButton text='Schedule A Call' to='/contact-us' styles={{ margin: 'auto' }} />
    )}
    <Reduce />
    <Accountants />
    <Testimonial testimonials={testimonialFour} />
    <LastSection />
  </div>
);

export default IndexPage;
