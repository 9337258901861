/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import {
  DetachedProperty,
  Shop,
  WheelchairTwo,
  Nurse,
  OfficeBuilding,
  MedicalCenter,
  Shopping,
  GardenStyle,
  Hotel,
  Motel,
  Hospital,
  Industrial,
  Banquet,
  Restaurant,
  Storage,
  CarWash,
  Communities,
  Plant,
  Recreational,
  BankIcon,
  EntertainmentFacilityIcon,
  TechnologyIcon,
  WarehouseIcon,
  AutoDealershipIcon,
} from '../ui/svg/about-us/icons';
import { slideWrapper, circleWrap } from './slideStyles';

export function SlideOne() {
  return (
    <div css={slideWrapper}>
      <Bullet icon={() => <DetachedProperty />} text='Apartment Buildings' />
      <Bullet icon={() => <Shop />} text='Retail Properties / Strip Malls' />
      <Bullet icon={() => <WheelchairTwo />} text='Assisted Living Facilities' />
      <Bullet icon={() => <Nurse />} text='Nursing Homes' />
      <Bullet icon={() => <OfficeBuilding />} text='Office Buildings' />
      <Bullet icon={() => <MedicalCenter />} text='Medical' />
    </div>
  );
}

export function SlideTwo() {
  return (
    <div css={slideWrapper}>
      <Bullet icon={() => <Shopping />} text='Shopping Centers' />
      <Bullet icon={() => <GardenStyle />} text='Garden Style Apartments' />
      <Bullet icon={() => <Hotel />} text='Hotels' />
      <Bullet icon={() => <Motel />} text='Motels' />
      <Bullet icon={() => <Hospital />} text='Hospitals' />
      <Bullet icon={() => <Industrial />} text='Industrial Facilities' />
    </div>
  );
}

export function SlideThree() {
  return (
    <div css={slideWrapper}>
      <Bullet icon={() => <Restaurant />} text='Restaurants' />
      <Bullet icon={() => <Banquet />} text='Banquet Halls' />
      <Bullet icon={() => <Storage />} text='Self-Storage Facilities' />
      <Bullet icon={() => <CarWash />} text='Car Washes' />
      <Bullet icon={() => <Communities />} text='Planned communities' />
      <Bullet icon={() => <Plant />} text='Manufacturing Plants' />
    </div>
  );
}

export function SlideFour() {
  return (
    <div css={slideWrapper}>
      <Bullet icon={() => <Recreational />} text='Recreational Facilities' />
      <Bullet icon={() => <WarehouseIcon style={{ maxWidth: 70, top: 0, left: -2 }} />} text='Warehouses' />
      <Bullet icon={() => <BankIcon style={{ maxWidth: 70, top: 0, left: -2 }} />} text='Banks' />
      <Bullet icon={() => <AutoDealershipIcon style={{ maxWidth: 70, top: 0, left: -2 }} />} text='Auto Dealerships' />
      <Bullet icon={() => <TechnologyIcon style={{ maxWidth: 70, top: 0, left: -2 }} />} text='Technology Centers' />
      <Bullet
        icon={() => <EntertainmentFacilityIcon style={{ maxWidth: 70, top: 0, left: -2 }} />}
        text='Entertainment Facilities'
      />
      {/* <Bullet icon={() => <Shop />} text='Strip Malls' /> */}
    </div>
  );
}

function Bullet({ icon, text }) {
  return (
    <div css={circleWrap}>
      <div>{icon()}</div>
      <p>{text}</p>
    </div>
  );
}
