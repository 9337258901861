import React from 'react';
import { Root } from '../components/ourMission/styles';

export default function OurMission() {
  return (
    <Root>
      <section className='mission-section'>
        <h1>Our Mission</h1>
        <p className='personQuote'>
          Through building Madison SPECS, I'm working to build access to high-quality cost
          segregation for all.
        </p>
        <div className='nameAndTitle'>
          <p>Eli Loebenbeg</p>
          <p>CEO</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/eli-low_j4uoji.png'
          alt='Founder'
        />
        <div className='sectionText'>
          <p>
            Every real estate investor should have access to the highest-level cost segregation
            service at competitive rates. That is my mission.
          </p>
          <p>
            Overseeing the cost seg department at one of the largest national accounting firms
            provided me with knowledge and experience. Partnering with Madison 16 years ago gave me
            access to their deep infrastructure and resources built around their specialized
            services for the commercial real estate community. Together, we were able to accomplish
            the SPECS vision.
          </p>
        </div>
      </section>

      <section className='clients-section'>
        <p className='personQuote'>
          Our clients are our everything, and my goal is for them to be thrilled with our work.
        </p>
        <div className='nameAndTitle'>
          <p>Moshe Hildeshaim</p>
          <p>COO</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/yit-gold_xvzu4t.png'
          alt='Client'
        />
        <div className='sectionText'>
          <p>
            Over our 16-year history, we have not stopped listening to our clients and responding to
            their needs and concerns. We are constantly fine-tuning our process and looking for ways
            to increase efficiency, lower turnaround times and create a turnkey product for
            investors and accountants. This starts at the first call from a business development rep
            and doesn't end until the final study is completed and delivered.
          </p>
          <p>
            As our business has doubled and tripled over the years, we have scaled ahead of the
            curve at all levels of the organization to make sure nothing falls through the cracks.
            Complete customer satisfaction is our focus and our goal.
          </p>
        </div>
      </section>

      <section className='expertise-section'>
        <h2>Our Expertise</h2>
        <p className='personQuote'>
          No detail is too small when it comes to finding depreciable assets for our clients.
        </p>
        <div className='nameAndTitle'>
          <p>Ephraim Baruch</p>
          <p>Cost Segregation Expert</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/ephr-bar_vei5bh.png'
          alt='Expertise'
        />
        <div className='sectionText'>
          <p>
            Clients are always curious what exactly we do when examining a property. Once they see
            how we go through every space, inside and out, to find areas we can depreciate, they
            understand why we're the experts.
          </p>
          <p>
            Our years of training and experience in the field allows us to find those small details
            that make all the difference in maximizing the tax savings we can. These types of things
            require specialists, like our engineers, who know how to spot them.
          </p>
        </div>
      </section>

      <section className='technology-section'>
        <h2>Technology</h2>
        <p className='personQuote'>
          We're building the technology that's keeping us on top of our game and ahead of the curve.
        </p>
        <div className='nameAndTitle'>
          <p>Sruly Davidowitz</p>
          <p>Product Lead</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/chuk-tov_gramca.png'
          alt='Technology'
        />
        <div className='sectionText'>
          <p>
            When dealing with a service that intersects engineering, accounting and relationships,
            having the right technology to tie it all together is critical. Relying on yesterday's
            best practices just doesn't cut it in today's world. We have built our platform to be on
            top of the game today, with an eye to staying ahead in the future.
          </p>
        </div>
      </section>

      <section className='team-section'>
        <h2>Team</h2>
        <p className='personQuote'>
          When I think about what makes SPECS so special, our great team is top of mind.
        </p>
        <div className='nameAndTitle'>
          <p>Tziviah Waidenbaum</p>
          <p>Director of HR</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/tzi-waid_u3xleq.png'
          alt='Team'
        />
        <div className='sectionText'>
          <p>
            It is our great people who have built this company and given us such a respected name in
            the industry. Even more than their expertise, each employee is proud to be part of the
            team, and they work together to produce the best product possible…and have fun at the
            same time. That feeling truly comes through and we hear it all the time in feedback from
            our clients.
          </p>
        </div>
      </section>

      <section className='relationships-section'>
        <h2>Relationships</h2>
        <p className='personQuote'>
          Our reps hate being called salespeople. We're relationship builders. And really good ones,
          I might add.
        </p>
        <div className='nameAndTitle'>
          <p>Yitz Goldman</p>
          <p>Business Development Rep</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/yit-gold_xvzu4t.png'
          alt='Relationships'
        />
        <div className='sectionText'>
          <p>
            Our business development reps love working for SPECS because they don't have to sell
            anything. They really feel that they are providing value to every client, and they are
            motivated to deliver this value to every prospect they contact.
          </p>
          <p>
            Our clients know that we are looking out for their best interests, and this allows
            strong relationships to be built. The reps' faith in the process and operational staff
            behind them gives them the full confidence to hand these relationships over to the team
            to perform the cost seg study.
          </p>
        </div>
      </section>

      <section className='cpas-section'>
        <h2>CPAs</h2>
        <p className='personQuote'>
          Our CPA relationships appreciate that we talk their language. We deal with them like
          partners and they love working with us.
        </p>
        <div className='nameAndTitle'>
          <p>Chuck Tover</p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/chuk-tov_gramca.png'
          alt='CPAs'
        />
        <div className='sectionText'>
          <p>
            There's nothing better than talking with an accountant and seeing the trust build up
            during our conversation as they come to understand our deep knowledge and vast
            experience performing cost seg studies. It's that trust, and our seamless collaboration
            with them and their staff, that has created a network of devoted clients and referral
            sources to their own clients and colleagues in the industry.
          </p>
        </div>
      </section>

      <section className='madison-section'>
        <h2>Madison</h2>
        <p className='personQuote'>
          Watching SPECS' growth from the ground up has been a classic Madison success story.
        </p>
        <div className='nameAndTitle'>
          <p>Moshe Becker</p>
          <p>Director of Operations </p>
        </div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/mosh-beck_xuzxxn.png'
          alt='Madison'
        />
        <div className='sectionText'>
          <p>
            Having started my Madison career with SPECS, I was able to witness firsthand the success
            of the Madison model of partnering with subject matter experts and, together, creating
            industry leading products for the commercial real estate market. Each service
            complements the others to service our clients' every need.
          </p>
        </div>
      </section>
    </Root>
  );
}
