/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Root, TextWrap, Button } from './bottomSectionStyles';

export default function BottomSection() {
  return (
    <div css={{ backgroundColor: '#f2f2f2' }}>
      <Root>
        <Motive />
        <TextWrap>
          <p>Ready to improve your cash flow?</p>
          <span>
            Find out how much with a no cost
            <br /> feasibility analysis today.
          </span>
        </TextWrap>
        <Button>
          <div>
            Schedule Call <span>Now</span>
          </div>
          <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10.7843 0.0885912C10.5894 -0.0464049 10.32 -0.0271197 10.1464 0.146447L10.0886 0.215695C9.9536 0.410563 9.97288 0.679987 10.1464 0.853553L14.293 5H0.5L0.410124 5.00806C0.176875 5.05039 0 5.25454 0 5.5C0 5.77614 0.223858 6 0.5 6H14.293L10.1464 10.1464L10.0886 10.2157C9.9536 10.4106 9.97288 10.68 10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536L15.8536 5.85355C15.8747 5.83239 15.894 5.80933 15.9111 5.78466L15.9114 5.78431C15.932 5.75462 15.949 5.7232 15.9624 5.69065C15.9847 5.63641 15.9971 5.57904 15.9995 5.52132C15.9999 5.51422 16 5.50711 16 5.5M15.9622 5.30892C15.9378 5.24983 15.9016 5.19447 15.8536 5.14645L10.8536 0.146447L10.7843 0.0885912M15.9622 5.30892C15.9855 5.3653 15.9988 5.42688 15.9999 5.49144L15.9622 5.30892Z'
              fill='white'
            />
          </svg>
        </Button>
      </Root>
    </div>
  );
}

function Motive() {
  return (
    <svg
      width='600'
      height='600'
      viewBox='0 0 600 600'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 979.896H575.488L522.631 1046.47L522.334 1046.86V1162.13H78.4487V1047.28H21.0882V1219.96L579.694 1220V922.024H25.2521L78.1512 854.592L78.4487 854.207V740.006H522.334V857.164H579.694V682.134H526.498L600 614.317V343.455L99.2394 95.8218V-11H0V979.896ZM350.296 619.613V602.618V341L495 413.019V688H275L350.296 619.613ZM83.0779 618.892L109.381 618.508L105.642 231.58L105.6 221L250 292.689V686H31L83.0779 618.892Z'
        fill='white'
      />
    </svg>
  );
}
