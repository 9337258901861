import React from "react";

import { StepperWrap } from "./styles";

export default function Stepper({ step }) {
  return (
    <StepperWrap>
      <StepCircle number={1} complete={true} />
      <ProgressBar inProgress={step === 1} complete={step > 1} />
      <StepCircle number={2} complete={step > 1} />
      <ProgressBar inProgress={step === 2} complete={step > 2} />
      <StepCircle number={3} complete={step > 2} />
      <ProgressBar inProgress={step === 3} complete={step > 3} />
      <StepCircle number={4} complete={step > 3} />
    </StepperWrap>
  );
}

function StepCircle({ complete, number, ...props }) {
  function numberSelector(number) {
    if (number === 1) {
      return "M23.3319 23.2191C24.3288 22.8344 25.2994 22.3446 26.2439 21.75C27.1883 21.1379 28.054 20.3771 28.8411 19.4676H30.5725V37.6479H28.1327V22.8518C27.9229 23.0442 27.6605 23.2454 27.3457 23.4552C27.0484 23.6651 26.7161 23.8662 26.3488 24.0586C25.999 24.251 25.623 24.4346 25.2207 24.6095C24.836 24.7844 24.4599 24.9331 24.0927 25.0555L23.3319 23.2191Z";
    }
    if (number === 2) {
      return "M34.1108 23.1921C34.1108 23.8218 33.9796 24.4339 33.7172 25.0285C33.4724 25.6057 33.1401 26.1828 32.7203 26.76C32.3006 27.3197 31.8284 27.8793 31.3037 28.439C30.779 28.9812 30.2543 29.5146 29.7296 30.0393C29.4323 30.3366 29.0825 30.6864 28.6803 31.0886C28.2955 31.4909 27.9282 31.9106 27.5784 32.3479C27.2286 32.7851 26.9313 33.2049 26.6865 33.6071C26.4591 34.0094 26.3454 34.3592 26.3454 34.6565H34.7141V36.729H23.6958C23.6783 36.6241 23.6695 36.5191 23.6695 36.4142C23.6695 36.3093 23.6695 36.2131 23.6695 36.1256C23.6695 35.3036 23.8007 34.5428 24.0631 33.8432C24.3429 33.1262 24.7014 32.4528 25.1387 31.8232C25.5759 31.1936 26.0656 30.5989 26.6078 30.0393C27.1499 29.4796 27.6834 28.9374 28.2081 28.4128C28.6453 27.993 29.0563 27.582 29.4411 27.1797C29.8433 26.76 30.1931 26.349 30.4904 25.9467C30.8052 25.527 31.0501 25.0985 31.225 24.6613C31.4174 24.224 31.5136 23.7605 31.5136 23.2708C31.5136 22.7462 31.4261 22.3002 31.2512 21.9329C31.0938 21.5656 30.8752 21.2595 30.5954 21.0147C30.3155 20.7698 29.9832 20.5949 29.5985 20.49C29.2312 20.3676 28.8377 20.3064 28.4179 20.3064C27.9107 20.3064 27.4473 20.3763 27.0275 20.5162C26.6078 20.6562 26.2317 20.8223 25.8994 21.0147C25.5671 21.1896 25.2873 21.382 25.06 21.5919C24.8326 21.7842 24.6577 21.9329 24.5353 22.0378L23.3285 20.3064C23.4859 20.1315 23.722 19.9216 24.0368 19.6768C24.3516 19.4144 24.7277 19.1696 25.1649 18.9422C25.6021 18.7148 26.0918 18.5224 26.634 18.365C27.1762 18.2076 27.7621 18.1289 28.3917 18.1289C30.2981 18.1289 31.7234 18.5749 32.6679 19.4669C33.6298 20.3414 34.1108 21.5831 34.1108 23.1921Z";
    }
    if (number === 3) {
      return "M25.7551 34.9451C27.3117 34.9451 28.4223 34.639 29.0869 34.0269C29.7689 33.4147 30.11 32.5927 30.11 31.5609C30.11 30.8963 29.9701 30.3366 29.6902 29.8819C29.4104 29.4271 29.0431 29.0686 28.5884 28.8063C28.1337 28.5264 27.6003 28.334 26.9881 28.2291C26.3935 28.1067 25.7813 28.0455 25.1517 28.0455H24.5221V26.0517H25.3878C25.8251 26.0517 26.271 26.0079 26.7258 25.9205C27.198 25.8331 27.6265 25.6756 28.0113 25.4483C28.396 25.2209 28.7108 24.9149 28.9557 24.5301C29.2005 24.1278 29.323 23.6294 29.323 23.0347C29.323 22.545 29.2355 22.1253 29.0606 21.7755C28.8857 21.4257 28.6496 21.1371 28.3523 20.9098C28.0725 20.6824 27.7402 20.525 27.3554 20.4375C26.9881 20.3326 26.5946 20.2801 26.1749 20.2801C25.3354 20.2801 24.6183 20.4026 24.0236 20.6474C23.4465 20.8923 22.9568 21.1459 22.5545 21.4082L21.6101 19.5456C21.82 19.4057 22.0823 19.257 22.3971 19.0996C22.7294 18.9247 23.0967 18.7673 23.499 18.6274C23.9012 18.4875 24.3297 18.3738 24.7844 18.2863C25.2567 18.1814 25.7464 18.1289 26.2536 18.1289C27.2155 18.1289 28.0462 18.2514 28.7458 18.4962C29.4454 18.7236 30.0225 19.0559 30.4773 19.4931C30.932 19.9129 31.273 20.4201 31.5004 21.0147C31.7278 21.5918 31.8415 22.2215 31.8415 22.9036C31.8415 23.848 31.5704 24.6613 31.0282 25.3433C30.486 26.0079 29.8302 26.5151 29.0606 26.8649C29.5328 27.0048 29.9876 27.206 30.4248 27.4683C30.862 27.7307 31.2381 28.063 31.5529 28.4652C31.8852 28.8675 32.1475 29.3309 32.3399 29.8556C32.5498 30.3803 32.6547 30.9662 32.6547 31.6133C32.6547 32.4004 32.5148 33.1349 32.235 33.817C31.9726 34.4816 31.5529 35.0588 30.9757 35.5485C30.4161 36.0382 29.7077 36.4229 28.8507 36.7028C27.9938 36.9826 26.9794 37.1225 25.8076 37.1225C25.3529 37.1225 24.8806 37.0875 24.3909 37.0176C23.9187 36.9476 23.4727 36.8602 23.053 36.7552C22.6332 36.6503 22.266 36.5454 21.9511 36.4404C21.6363 36.3355 21.409 36.248 21.2691 36.1781L21.7413 34.0531C22.0386 34.193 22.5283 34.3767 23.2104 34.604C23.91 34.8314 24.7582 34.9451 25.7551 34.9451Z";
    }
    if (number === 4) {
      return "M21.483 30.4328C21.8153 29.6633 22.27 28.7713 22.8471 27.7569C23.4243 26.725 24.0714 25.6669 24.7885 24.5826C25.523 23.4982 26.3013 22.4313 27.1233 21.382C27.9628 20.3326 28.8023 19.3882 29.6418 18.5487H31.9767V30.0917H34.1279V32.0855H31.9767V36.729H29.6156V32.0855H21.483V30.4328ZM29.6156 21.4344C29.0909 21.9941 28.5575 22.6237 28.0153 23.3233C27.4906 24.0054 26.9747 24.7225 26.4675 25.4745C25.9603 26.2266 25.4793 26.9961 25.0246 27.7831C24.5873 28.5702 24.1938 29.3397 23.844 30.0917H29.6156V21.4344Z";
    } else return null;
  }
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-circle"
      {...props}
    >
      <circle
        cx="28.8109"
        cy="28.1803"
        r="27.8739"
        fill={complete ? "#0927C7" : "#EFF0F7"}
        style={{ transition: "all 0.1s ease-in-out .3s" }}
      />
      <path
        d={numberSelector(number)}
        fill={complete ? "#fff" : "#6F6C90"}
        style={{ transition: "fill 0.1s ease-in-out .3s" }}
      />
    </svg>
  );
}

function ProgressBar({ inProgress, complete }) {
  function status(inProgress, complete) {
    if (inProgress) {
      return "50%";
    }
    if (complete) {
      return "100%";
    }
    return "0%";
  }
  return (
    <svg
      width="162"
      height="11"
      viewBox="0 0 162 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="progress_bar"
    >
      <rect x="0.198242" y="0.44165" width="160.833" height="9.83784" rx="4.91892" fill="#EFF0F7" />
      <rect
        x="0.198242"
        y="0.44165"
        width={status(inProgress, complete)}
        height="9.83784"
        rx="4.91892"
        fill="#0927C7"
        style={{
          transition: complete ? "width 0.3s ease-out" : "width 0.3s ease-out .4s",
        }}
      />
      {/* <rect x='0.198242' y='0.44165' width='161' height='10' rx='5' fill='#0927C7' /> */}
    </svg>
  );
}
