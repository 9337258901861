/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Image from '../ui/Image';

import {
  Root,
  gradient,
  BulletWrap,
  motifS,
  header,
  subHeader,
  textSection,
  innerPart,
  BulletsWrap,
  plusStyles,
  plusStyles2,
  plusGreenStyles,
  imageCircleWrap,
  plusWrap,
  plus3Styles,
  plus4Styles,
  imageStyles,
  plusGreenWrap,
  plusGreenStyles2,
  imageBulletWrap,
  greenFourStyles,
  recentTaxWrap,
  circleDocStyles,
  ribbonStyles,
  bigRibbonStyles,
  greenPlus2Styles,
  recentTaxInfo,
  recentTaxInfoWrap,
  bonusInfo,
  outerWrap,
  innerWrap,
  buttetThreePlus,
} from './whyDoCSStyles';
import { GreenPlus2, Motif, Ribbon, BigRibbon } from '../ui/icons';
import {
  ChartLap,
  CashFlow,
  PathAudit,
  Refund,
  Plus1,
  PlusGreen,
  ChartPie,
  CirclesDocSm,
  PlusGroupThree,
  PlusGroupFour,
  GreenGroupFour,
  Plus3,
  Plus4,
} from '../ui/svg/about-us/icons';

export default function () {
  return (
    <>
      <Root>
        <div css={gradient} />
        <p className='section-header__title'>Why do cost segregation?</p>
        <div css={motifS}>
          <Motif />
        </div>
        <div css={header}>
          <p css={subHeader} className='syne'>
            By performing a cost segregation study on an investment property, or property held for business, the owners
            are able to:
          </p>
        </div>
        <div css={plusWrap}>
          <div>
            <Plus1 />
          </div>
        </div>
        <div css={plus3Styles}>
          <div>
            <Plus3 />
          </div>
        </div>
        <BulletsWrap>
          <Bullet icon={<ChartLap />} text='Accelerate income tax depreciation deductions' />
          <Bullet
            img='refund_y3trlu.svg'
            icon={<Refund />}
            text='Recover missed accelerated depreciation deductions from previous years '
          />
          <Bullet img='cash-flow_ubuzeq.svg' text='Improve cash flow' icon={<CashFlow />} />
          <div css={buttetThreePlus}>
            <Bullet img='path-audit_q7oxqw.svg' text='Create an audit trail' icon={<PathAudit />} />
            <div css={plusStyles}>
              <PlusGroupThree />
            </div>
            <div css={plusStyles2}>
              <PlusGroupFour />
            </div>
          </div>
        </BulletsWrap>
        <div css={innerPart}>
          <div css={textSection}>
            <div>
              While tax savings vary, the positive benefits of a Cost Segregation study can be quite significant based
              on calculations involving the individual’s overall tax situation and the specific assets in any given
              property.
            </div>
            <p>
              Having handled thousands of projects and studies, we estimate that approximately $275,000 in Net Present
              Value tax savings can be generated for each $1 million of reclassified assets. And, because many states
              follow the federal rules regarding depreciation, you can also save money on state tax returns.
            </p>
          </div>
          <div css={plusGreenWrap}>
            <PlusGreen css={plusGreenStyles} />
            <Plus4 css={plus4Styles} />
            <div css={imageStyles}>
              <Image image='specs/about/group-5_3x_lvm8vx.png' />
            </div>
            <div css={imageBulletWrap}>
              <ImageBullet img='path-audit_q7oxqw.svg' icon={<ChartPie />} />
            </div>
          </div>
        </div>
      </Root>
      <div css={recentTaxWrap}>
        <CirclesDocSm css={circleDocStyles} />
        <div css={outerWrap}>
          <div css={greenFourStyles}>
            <GreenGroupFour />
          </div>
          <div css={innerWrap}>
            <div css={recentTaxInfoWrap}>
              <Ribbon css={ribbonStyles} />
              <BigRibbon css={bigRibbonStyles} />
              <PlusGreen css={plusGreenStyles2} />
              <div css={recentTaxInfo}>
                Recent tax law changes under the Tax Cuts and Jobs Act of 2017 (TCJA) have given an additional boost to
                cost segregation.
              </div>
              <div css={bonusInfo}>
                Bonus depreciation (the federal tax incentive that allows a business to immediately deduct a large
                percentage of the purchase price of eligible assets) was increased from 50% to 100%, essentially
                allowing real estate investors to immediately expense anything less than 20 year property. A cost
                segregation study produced by Madison SPECS will separate any costs that qualify under the new bonus
                depreciation rules.
              </div>
            </div>
            <GreenPlus2 css={greenPlus2Styles} />
          </div>
        </div>
      </div>
    </>
  );
}

function Bullet({ icon, text }) {
  return (
    <BulletWrap>
      <div>
        <div>{icon}</div>
      </div>
      <p>{text}</p>
    </BulletWrap>
  );
}

function ImageBullet({ icon }) {
  return <div css={imageCircleWrap}>{icon}</div>;
}
