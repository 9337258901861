/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import { NavLink } from 'react-router-dom';

import { RightArrow } from '../ui/icons';

export const mainWrap = {
  alignItems: 'center',
  backgroundColor: '#0827c7',
  borderRadius: '8px',
  boxShadow: '0 21px 40px -15px rgba(19, 21, 50, 0.39)',
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 600,
  height: 48,
  justifyContent: 'space-between',
  marginTop: 32,
  minWidth: 150,
  padding: '0 24px',
  position: 'relative',
  textAlign: 'center',
  textTransform: 'uppercase',
  transition: 'all .2s',
  width: 210,
  zIndex: 100,
  '@media(min-width: 1360px)': {
    height: 64,
    fontSize: 14,
  },
  '@media(min-width: 1440px)': {
    minWidth: 240,
  },
  '&:hover': {
    backgroundColor: '#000e56',
    transition: 'all .2s',
  },
  '&:active': {
    color: '#fff',
  },
};

export const buttonStyles = {
  fontFamily: 'sans-serif',
  justifyContent: 'space-between',
  marginTop: 32,
  position: 'relative',
  width: 187,
  zIndex: 100,
  '@media(min-width: 768px)': {
    marginTop: 56,
  },
  '@media(min-width: 1260px)': {
    marginTop: 104,
  },
};

export const secondaryStyles = {
  backgroundColor: '#55dcac',
  '&:hover': {
    backgroundColor: '#41ba8e',
  },
};

export default function CallButton({ text, styles, to, secondary, onClick, blank, ...props }) {
  return (
    <Fragment>
      {to ? (
        <NavLink
          to={to}
          css={[mainWrap, secondary && secondaryStyles, styles]}
          onClick={onClick}
          {...props}
          target={blank && '_blank'}
        >
          {text} <RightArrow />
        </NavLink>
      ) : (
        <button css={[mainWrap, secondary && secondaryStyles, styles]} onClick={onClick} {...props}>
          {text} <RightArrow />
        </button>
      )}
    </Fragment>
  );
}

export function CallButtonLink({ text, styles, link, blank, secondary, onClick, ...props }) {
  return (
    <a
      href={link}
      css={[mainWrap, secondary && secondaryStyles, styles]}
      target={blank && '_blank'}
      onClick={onClick}
      {...props}
    >
      {text} <RightArrow />
    </a>
  );
}
