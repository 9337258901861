/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

export const Root = styled.div`
  background-color: #fff;
  margin: 25px auto 33px;
  overflow: auto;
  padding: 10px 24px 10px 20%;
  @media (min-width: 768px) {
    margin: 128px auto 60px;
    padding: 32px 0px 32px;
  }
  @media (min-width: 1024px) {
    justify-content: center;
    margin-bottom: 30px;
    padding: 32px 0px;
    position: relative;
    top: 0;
  }
  @media (min-width: 1440px) {
    justify-content: center;
    padding: 0;
  }
  @media (min-width: 1920px) {
    overflow: unset;
  }
`;

export const CirclesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
    width: 1232px;
  }
  @media (min-width: 1024px) {
    max-width: 846px;
    width: 100%;
    padding: 0px;
    margin: 0 auto;
  }
  @media (min-width: 1440px) {
    max-width: 1100px;
  }
`;

// ----------- HEADER STYLES ------------- //

export const HeaderText = styled.div`
  @media (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    max-width: 1100px;
    margin: 0 auto 50px;
    display: flex;
    padding-left: 22px;
    div {
      color: #55dcac;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 354px;
      :last-of-type {
        color: #0b2ac7;
      }
    }
  }
`;

// ----------- STEP STYLES ------------- //

export const Step = styled.a`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 52px;
  position: relative;
  text-align: center;
  width: 92px;
  :last-of-type {
    margin-right: 0px;
  }
  p {
    display: block;
    font-size: 11px;
    font-weight: 600;
    max-width: 200px;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: #727272;
    @media (max-width: 767px) {
      color: #727272;
      color: ${(props) => props.current && '#55dcac'};
      color: ${(props) => props.currentBlue && '#0b2ac7'};
    }
    @media (min-width: 768px) {
      display: inline-flex;
    }
  }
  .dashed-line_steps {
    position: absolute;
    left: -75px;
    top: 25px;
    width: 100px;
    z-index: 50;
    height: 2px;
  }
  @media (min-width: 768px) {
    margin-right: 0;
    .dashed-line_steps {
      top: 34px;
      z-index: 50;
      left: -85px;
    }
  }
  @media (min-width: 1920px) {
    width: 127px;
    margin-right: 50px;
    span {
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const StepsText = styled.div`
  @media (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    color: #c0c0c0;
    font-size: 12px;
    font-weight: 400;
    left: -36px;
    position: absolute;
    text-transform: uppercase;
    top: 30px;
  }
`;
export const IconCircle = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 16px 24px -15px rgba(210, 227, 219, 0.91);
  box-shadow: ${(props) => props.current && ' 0 16px 24px -15px rgba(124, 217, 175, 0.91)'};
  box-shadow: ${(props) => props.currentBlue && ' 0 16px 24px -15px rgba(11, 42, 199, 0.5);'};
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  margin: 0 auto 10px;
  position: relative;
  max-width: 100%;
  z-index: 100;
  border: solid 1px #e7ebef;
  padding: 15px;
  background-color: #ffffff;
  background-color: ${(props) => props.current && '#55dcac'};
  background-color: ${(props) => props.currentBlue && '#0b2ac7'};
  circle,
  path,
  rect {
    fill: ${(props) => (props.current || props.currentBlue ? '#fff' : '#c4c9cf')};
    stroke: #fff;
    stroke: ${(props) => props.current && '#55dcac'};
    stroke: ${(props) => props.currentBlue && '#0b2ac7'};
  }
  @media (min-width: 768px) {
    align-items: center;
    background: #55dcac;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 auto 32px;
    position: relative;
    width: 40px;
    z-index: 100;
    height: 70px;
    width: 70px;
    box-shadow: none;
    ${(props) => props.blue && `background: #0b2ac7;`}
    circle,
  path,
  rect {
      fill: #fff;
      stroke: #55dcac;
      stroke: ${(props) => props.blue && '#0b2ac7'};
    }
  }
  svg,
  g,
  path {
    height: auto;
  }
`;

export const numberStyles = {
  '@media(max-width: 767px)': { display: 'none !important' },
  '@media(min-width: 768px)': { marginRight: 5, display: 'inline' },
};

export const noOverflow = {
  overflowY: 'unset',
  overflow: 'unset',
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '::-webkit-scrollbar': {
    display: 'none',
  },
};
export function DashedLine({ green, styles, ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='2' viewBox='0 0 93 2' {...props} css={styles}>
      <g fill='none' fillRule='evenodd' strokeDasharray='1 9' strokeLinecap='square'>
        <g stroke={green ? '#55dcac' : '#0C2AC7'} strokeWidth='2'>
          <path d='M1339 824L1435 824' transform='translate(-1338 -823)' />
        </g>
      </g>
    </svg>
  );
}
