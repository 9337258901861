/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Wrapper, Header, SubHeader, StepWrapper, StepNumber, StepHeader, StepText, OuterWrap } from './processStyles';

export default function Process() {
  return (
    <Wrapper>
      <Header className='syne'>The Smart Tour Process</Header>
      <SubHeader>Smarter Cost Seg</SubHeader>
      <OuterWrap>
        <Step
          number='1'
          header='Connect'
          text='SPECS coordinates with the property management  staff to collect necessary documents and information about the property.'
        />
        <Step
          number='2'
          header='Create'
          text='Our engineer, with the assistance of an on-site employee, creates an all-encompassing digital Smart Tour. SPECS tax experts then use this information to reassign property assets into shorter life categories to accelerate depreciation.'
        />
        <Step
          number='3'
          header='Complete'
          text='The completed study is delivered to you and your tax provider for seamless insertion into your tax return,  resulting in lower upfront taxes.'
        />
      </OuterWrap>
    </Wrapper>
  );
}

function Step({ number, header, text }) {
  return (
    <StepWrapper>
      <StepNumber className='syne'>
        <div>
          <span>{number}</span>
        </div>
      </StepNumber>
      <StepHeader className='syne'>{header}</StepHeader>
      <StepText>{text}</StepText>
    </StepWrapper>
  );
}
