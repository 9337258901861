import styled from '@emotion/styled';

export const CardWrap = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 21px 45px -9px rgba(19, 21, 50, 0.09);
  margin-left: 24px;
  max-width: 90vw;
  padding: 28px 24px 0px;
  position: relative;
  top: -120px;
  width: 100%;
  z-index: 210;
  transition: all .2s;
  @media (min-width: 768px) {
    /* left: 40px; */
    max-width: 439px;
    padding: 24px 32px;
    position: absolute;
    top: 40px;
    margin-left: 0;
    left: 40px;
  }
  @media (min-width: 1024px) {
    padding: 40px 32px 28px;
    max-width: 511px;
  }
  @media (min-width: 1440px) {
    padding: 56px 56px 32px;
    max-width: 623px;
    top: 72px;
  }
  @media (min-width: 1920px) {
    padding: 72px 72px 48px;
    max-width: 655px;
  }
`;

export const XIconWrap = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px -9px rgba(19, 21, 50, 0.72);
  color: #b5b5b5;
  cursor: pointer !important;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -40px;
  width: 32px;
  @media (min-width: 768px) {
    background-color: #fff;
    width: 32px;
    height: 32px;
    top: 0px;
    right: -40px;
  }
  @media (min-width: 1024px) {
    right: 16px;
    top: 16px;
    box-shadow: none;
    background-color: #f2f2f2;
  }
  @media (min-width: 1440px) {
    width: 48px;
    height: 48px;
  }
`;

export const HeaderOne = styled.p`
  color: #0827c7;
  font-family: Syne;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25;
  font-size: 20px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) {
    padding-right: 24px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
  }
`;

export const FeaturedSection = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 24px 0 16px;
  padding: 0 1px 0px 0;
  position: relative;
  @media (min-width: 1440px) {
    margin-top: 40px;
  }
  @media (min-width: 1920px) {
    margin: 48px 0px 25px;
    padding: 0 1px 0px 0;
  }
`;

export const cbOverrides = {
  cursor: 'pointer !important',
  fontSize: 14,
  lineHeight: '16px',
  padding: '20px 32px',
  minWidth: '264px !important',
  boxShadow: 'none',
  marginTop: 16,
  svg: {
    marginLeft: 16,
  },
  '@media (min-width: 1440px)': {
    height: 56,
  },
};

export const blackStyles = {
  color: '#000',
};

export const labelStyles = {
  color: '#b5b5b5',
  fontFamily: 'Ubuntu',
  marginBottom: 4,
  fontWeight: 500,
  fontSize: 10,
  '@media(min-width: 1024px)': {
    fontSize: 12,
    marginTop: 24,
  },
};

export const infoStyles = { fontFamily: 'Syne', fontSize: 24, marginBottom: 16 };

export const CardIllus = styled.img`
  display: none;
  @media (min-width: 768px) {
    bottom: -50px;
    display: block;
    height: 100%;
    max-height: 160px;
    position: absolute;
    right: -110px;
    width: auto;
  }
  @media (min-width: 1024px) {
      bottom: -44px;
      max-height: 211px;
  }
  @media (min-width: 1440px) {
    max-height: 260px;
    right: -130px;
    bottom: -60px;
  }
  @media (min-width: 1920px) {
    bottom: -52px;
    right: -154px;
  }
`;

export const mobileIllus = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: 24,
  position: 'relative',
  '&:before': {
    backgroundColor: '#f9f9f9',
    bottom: -0,
    content: '""',
    height: 114,
    left: -24,
    position: 'absolute',
    right: -24,
    width: 'calc(100% + 48px)',
  },
  img: {
    maxWidth: 250,
    position: 'relative',
    zIndex: 10,
  },
  '@media(min-width: 768px)': {
    display: 'none',
  },
};
