/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { LeftArrow, RightBlueArrow } from '../ui/icons';
import { mainWrap, nextArrowStyles, prevArrowStyles, arrowCircleStyles, outerWrap } from './WhatTypeSliderStyles';

function NextArrow({ className, style, onClick, css }) {
  return (
    <div className={className} style={({ ...style }, arrowCircleStyles)} css={css} onClick={onClick}>
      <RightBlueArrow />
    </div>
  );
}

function PrevArrow({ className, style, onClick, css }) {
  return (
    <div className={className} style={({ ...style }, arrowCircleStyles)} css={css} onClick={onClick}>
      <LeftArrow />
    </div>
  );
}

function Slide({ content, key }) {
  return (
    <div css={mainWrap} key={key}>
      <div>{content}</div>
    </div>
  );
}

class SimpleSlider extends Component {
  render() {
    var settings = {
      infinite: true,
      pauseOnHover: false,
      dots: true,
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 1000,
      nextArrow: <NextArrow css={nextArrowStyles} />,
      prevArrow: <PrevArrow css={prevArrowStyles} />,
    };
    const { list } = this.props;
    return (
      <Slider {...settings}>
        {list.map((l) => (
          <Slide content={l.slide} key={l} />
        ))}
      </Slider>
    );
  }
}

export default function WhatTypeSlider({ list }) {
  return (
    <div css={outerWrap}>
      <SimpleSlider list={list} />
    </div>
  );
}
