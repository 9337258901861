import React from 'react';
import styled from '@emotion/styled';

export const Root = styled.div`
  font-family: 'Ubuntu';
  margin: 0px auto 72px;
  max-width: 1440px;
  padding: 0 24px;
  width: 100%;
  scroll-behavior: smooth;
  font-size: 13px;
  * {
    scroll-behavior: smooth;

  }
  .MuiFormLabel-root {
    font-weight: 600;
    color: #000;
    font-family: 'Ubuntu';
  }
  .Mui-focused {
    color: #000;
  }
  .MuiFormGroup-row {
    /* position: absolute;
    top: 20px; */
  }
  .MuiInputLabel-root {
    left: 10px;
    top: 1px;
  }
  .MuiInputLabel-root[data-shrink='true'] {
    left: 0;
  }
  .MuiInputLabel-shrink {
    color: #000 !important;
  }
  .MuiInputLabel-shrink.Mui-focused {
    color: #1975d2 !important;
    left: 0;
  }
  .select_ {
    font-family: 'Ubuntu';
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    font-weight: 400;
    height: 36px;
    .MuiOutlinedInput-notchedOutline {
      border-color: #e1e3e6;
      border-width: 1.7px;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      background-color: #fff;
      border-color: #e1e3e6;
      border-radius: 8px;
      border-width: 1.7px;
      font-family: 'Ubuntu';
      height: 40px;
      padding-left: 10px;
      font-size: 15px;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1.7px solid #e1e3e6;
  }
  .MuiFormLabel-root {
    color: #C4C4C4;
    font-size: 14px;
    font-weight: 400;
  }
  .custom_labels.custom_labels {
    color: #c4c4c4;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Ubuntu';
  }
  .dropzone-title {
    color: #818087;
    font-family: 'Ubuntu';
    font-size: 14px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  .MuiSelect-select {
    align-items: center;
    display: flex;
  }
  @media (min-width: 768px) {
    padding: 0 40px;
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 116px;
    .select_ {
      border-radius: 13px;
      height: 40px;
    }
    .MuiFormControl-root {
      .MuiInputBase-root {
        border-radius: 13px;
      }
    }
  }
`;

export const dateFieldHacks = {
  marginTop: '20px',
  '.MuiInputLabel-root': {
    lineHeight: '1',
    marginRight: '33px',
    marginTop: '-2px',
    whiteSpace: 'pre-line',
  },
  '.MuiInputLabel-shrink': {
    lineHeight: '1.4375em',
    marginRight: '0px',
    marginTop: 0,
    whiteSpace: 'pre',
  },
};

export const StepperWrap = styled.div`
  align-items: center;
  border-bottom: 1px solid #d9dbe9;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  .progress_bar {
    width: 100%;
  }
  .svg-circle {
    height: 32px;
    flex: 0 0 32px;
  }
  @media (min-width: 768px) {
    gap: 16px;
    .svg-circle {
      height: 40px;
      flex: 0 0 40px;
    }
  }
`;

export function EditIcon() {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_424_672)'>
        <path
          d='M5.28955 15.0833L7.64622 14.61L20.3729 1.88667L18.4862 0L5.75955 12.7267L5.28955 15.0833ZM19.4295 1.88667L18.4129 2.90333L17.4696 1.96L18.4862 0.943333L19.4295 1.88667ZM6.37288 13.0567L16.9996 2.43L17.9429 3.37333L7.31622 14L6.13622 14.2367L6.37288 13.0567Z'
          fill='#0927C7'
        />
        <path
          d='M15.7061 19.3333H1.03947V4.66667H12.5461L13.2128 4H0.372803V20H16.3728V7.16L15.7061 7.82667V19.3333Z'
          fill='#0927C7'
        />
      </g>
      <defs>
        <clipPath id='clip0_424_672'>
          <rect width='20' height='20' fill='white' transform='translate(0.372803)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeleteIcon({ ...props }) {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_424_675)'>
        <path
          d='M17.9999 1.69492H11.8982V0H6.4745V1.69492H0.372803V2.37288H2.09145L2.74568 20H15.627L16.2813 2.37288H17.9999V1.69492ZM7.15246 0.677966H11.2203V1.69492H7.15246V0.677966ZM14.9728 19.322H3.39992L2.7728 2.37288H15.6033L14.9762 19.322H14.9728Z'
          fill='#9C9C9C'
        />
        <path d='M9.52538 5.42383H8.84741V16.2713H9.52538V5.42383Z' fill='#9C9C9C' />
        <path
          d='M12.2385 5.41132L11.8994 16.2603L12.577 16.2814L12.9161 5.4325L12.2385 5.41132Z'
          fill='#9C9C9C'
        />
        <path
          d='M6.13589 5.41193L5.45825 5.43311L5.7973 16.282L6.47493 16.2609L6.13589 5.41193Z'
          fill='#9C9C9C'
        />
      </g>
      <defs>
        <clipPath id='clip0_424_675'>
          <rect width='17.6271' height='20' fill='white' transform='translate(0.372803)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export const StepHeader = styled.div`
  font-family: 'Syne';
  font-style: normal;
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const IndividualStepWrap = styled.div`
  padding-top: 40px;
  @media (min-width: 1024px) {
    padding-top: 20px;
  }
`;

export const FormWrap = styled.form`
  background-color: #fff;
  background-color: rgba(239, 239, 239, 0.3);
  border-radius: 42px;
  margin: 0 auto;
  max-width: 770px;
  padding: 24px 20px 32px;
  scroll-margin-top: 24px;
  
  .section_header {
    color: #818087;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .react-datepicker__input-container {
    background-color: transparent;
    width: 100%;
    border: 1px solid #ccc;
    border: 1px solid red;
    input {
      background-color: transparent;
      width: 100%;
    }
  }
  .react-datepicker {
    .react-datepicker__triangle {
      ::before,
      ::after {
        left: -40px;
      }
    }
  }
  .purchase_price-row {
    position: relative;
    .MuiFormGroup-row {
      /* position: absolute; */
      /* top: 20px; */
    }
    .MuiFormLabel-root {
      color: #c4c4c4;
      font-size: 15px;
    }
  }
  .comments-field {
    background-color: #fff;
    border-radius: 13.1171px;
    border: 1.63964px solid #e1e3e6;
    color: #000;
    padding: 16px;
    resize: none;
    width: 100%;
    :focus {
      border-color: #0827c7;
    }
    ::placeholder {
      color: #ccc;
    }
  }
  @media (min-width: 600px) {
    .purchase_price-row {
      span {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 768px) {
    padding: 40px 40px 32px;
  }
  @media (min-width: 1024px) {
    border-radius: 42px;
    padding: 40px 54px;
    .section_header {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .submit_button {
      padding: 0 80px;
    }
  }
`;

export const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 20px;
`;

export const MyTextField = styled.input`
  border-radius: 8px;
  border: 1.7px solid #e1e3e6;
  box-sizing: border-box;
  font-size: 14px;
  height: 36px;
  padding: 2px 16px;
  width: 100%;
  :focus {
    border-color: #0827c7;
  }
  ::placeholder {
    color: #ccc;
  }
  @media (min-width: 1024px) {
    border-radius: 13px;
    font-size: 15px;
    height: 40px;
    padding: 12px 24px;
  }
`;

export const DropzoneWrap = styled.div`
  /* border: 1px solid red; */
`;

export const SubmitButtonStlye = styled.button`
  align-items: center;
  background-color: #0827c7;
  border-radius: 9.838px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  padding: 0px 20px;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  svg {
    margin-top: 4px;
    max-width: 15px;
  }
  :hover {
    background-color: rgb(0, 14, 86);
  }
  :disabled {
    background-color: #ccc;
    pointer-events: none;
  }
  @media (min-width: 768px) {
    width: auto;
  }
`;

export const PreviousPropertyWrap = styled.div`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 16px;
  .previous_header {
    font-size: 16px;
    font-weight: 400;
  }
  .section_header {
    margin-bottom: 0;
  }
  .action-buttons {
    align-items: center;
    display: inline-flex;
    text-transform: uppercase;
    div {
      font-size: 12px;
      cursor: pointer;
      color: #E00000;
      transition: all 0.15s ease-in-out;
      :hover {
        color: #FF0000;
      }
      svg {
        transition: all 0.15s ease-in-out;
        width: 20px;
        height: 20px;
      }
      :hover {
        text-decoration: underline;
      }
      :first-of-type {
        color: #0827c7;
        margin-right: 16px;
        :hover {
          color: #0a3dd8;
        }
      }
    }
  }
  .data_wrap {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    > div {
      width: 47%;
      :first-of-type {
        line-height: 1.4;
      }
    }
  }
  .table_wrap {
    text-align: right;
    justify-content: flex-end;
    div {
      line-height: 1.4;
      :nth-of-type(odd) {
        margin-top: 16px;
        font-weight: 500;
        :first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  @media (min-width: 768px) {
    border: 1.64px solid  #E1E3E6;
    margin-top: 0;
    padding: 24px;
    padding-top: 20px;
    .previous_header {
      font-size: 18px;
      font-weight: 400;
    }
    .action-buttons {
      font-size: 15px;
    }
    .data_wrap {
      gap: 24px;
      font-size: 16px;
    }
    .table_wrap {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 184px auto;
      margin-top: -16px;
      br {
        display: none;
      }
      div {
        margin-top: 16px;
        :nth-of-type(odd) {
          margin-top: 16px;
          :first-of-type {
            margin-top: 16px;
          }
        }
      }
    }
  }
`;

export const SecondaryButtonStyle = styled(SubmitButtonStlye)`
  background-color: rgba(197, 197, 199, 0.28);
  color: #818087;
  :hover {
    background-color: rgba(197, 197, 199, 0.5);
  }
  :disabled {
    background-color: rgba(197, 197, 199, 0.28);
    pointer-events: none;
  }
`;

export function SecondaryButton({ text, ...props }) {
  return (
    <SubmitButtonStlye {...props}>
      <span>{text}</span>
      <RightArrow />
    </SubmitButtonStlye>
  );
}

export function SubmitButton({ text, noArrow, ...props }) {
  return (
    <SubmitButtonStlye {...props} className='submit_button'>
      <span className={noArrow ? '' : 'mr1'}>{text}</span>
      {!noArrow && <RightArrow />}
    </SubmitButtonStlye>
  );
}

export const PreviousNextWrap = styled.div`

  margin-top: 54px;
  .prev-direction {
    align-items: center;
    color: #9C9C9C;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    font-weight: 400;
    gap: 12px;
    span {
      transition: all 0.2s ease-in-out;
    }
    svg {
      height: 18px;
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
      width: 18px;
      path {
        fill: currentColor;
      }
    }
    :hover {
      color: #0827c7;
    }
  }
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 1024px) {
    margin-top: 40px;
  }
`;

export const RightArrow = () => (
  <svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.94735 14.2912L18.9169 14.2912L14.9457 19.057C14.5587 19.5205 14.6221 20.2102 15.0856 20.5961C15.5502 20.9831 16.2388 20.9197 16.6247 20.4562L22.0902 13.8976C22.1328 13.8463 22.1546 13.7861 22.1863 13.7293C22.2126 13.6834 22.2443 13.644 22.264 13.5938C22.3131 13.4681 22.3427 13.3358 22.3427 13.2024C22.3427 13.2013 22.3437 13.1992 22.3437 13.1981C22.3437 13.197 22.3427 13.1948 22.3427 13.1937C22.3427 13.0603 22.3131 12.9281 22.264 12.8024C22.2443 12.7521 22.2126 12.7127 22.1863 12.6668C22.1546 12.61 22.1328 12.5499 22.0902 12.4985L16.6247 5.93992C16.4083 5.68086 16.0978 5.54641 15.7852 5.54641C15.5382 5.54641 15.29 5.62948 15.0856 5.8C14.6221 6.18587 14.5587 6.87561 14.9457 7.33908L18.9169 12.105L5.94735 12.105C5.34397 12.105 4.85426 12.5947 4.85426 13.1981C4.85426 13.8014 5.34397 14.2912 5.94735 14.2912Z'
      fill='white'
    />
  </svg>
);

export function PeopleIcon({ ...props }) {
  return (
    <svg width='33' height='23' viewBox='0 0 33 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_143_346)'>
        <path
          d='M22.4982 22.9856C19.5955 22.9856 16.6928 22.9856 13.7892 22.9856C12.5791 22.9856 11.9937 22.4186 12.0152 21.2738C12.0948 17.19 15.4096 13.5575 19.6564 13.0497C21.8135 12.7922 24.0081 12.725 26.1549 13.187C30.0925 14.0348 33.028 17.6305 32.9999 21.4864C32.9933 22.3603 32.3555 22.9748 31.417 22.9829C30.1178 22.9936 28.8187 22.9856 27.5196 22.9856C25.8458 22.9856 24.172 22.9856 22.4982 22.9856Z'
          fill='#0927C7'
        />
        <path
          d='M9.00402 11.4898C5.67704 11.4853 3.00759 8.89703 3.01883 5.68616C3.03007 2.55424 5.76228 -0.0169614 9.06022 8.42669e-05C12.3291 0.0171299 15.0332 2.62422 15.0136 5.73909C14.9929 8.93292 12.3169 11.4934 9.00402 11.4898Z'
          fill='#0927C7'
        />
        <path
          d='M16.5093 5.72203C16.5074 2.57038 19.2359 -0.0151843 22.5488 6.71281e-05C25.8046 0.0153185 28.4862 2.58383 28.5031 5.70409C28.5209 8.89253 25.8205 11.4933 22.4954 11.4898C19.1806 11.4862 16.5121 8.91406 16.5093 5.72203Z'
          fill='#0927C7'
        />
        <path
          d='M12.5015 13.4275C9.90042 16.1414 8.62658 19.2401 9.17358 22.9847C9.11551 22.9847 8.98251 22.9847 8.8495 22.9847C6.56595 22.9847 4.28147 22.9489 1.99979 22.9982C0.677243 23.0269 -0.0374197 22.4178 0.00098289 21.0604C0.0843446 18.1052 1.33945 15.7879 3.9246 14.1614C5.07949 13.4347 6.36551 13.0328 7.74894 12.9592C9.22416 12.8811 10.6984 12.8435 12.1306 13.2992C12.2486 13.3369 12.3647 13.38 12.5015 13.4275Z'
          fill='#0927C7'
        />
      </g>
      <defs>
        <clipPath id='clip0_143_346'>
          <rect width='33' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function QuestionCircle({ ...props }) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='9' cy='9' r='9' fill='#818087' />
      <path
        d='M8.61037 5.03537C7.95037 5.03537 7.30537 5.20537 6.67537 5.54537L6.28537 4.46537C6.62537 4.28537 7.00537 4.14037 7.42537 4.03037C7.85537 3.92037 8.31037 3.86537 8.79037 3.86537C9.36037 3.86537 9.82537 3.94537 10.1854 4.10537C10.5554 4.25537 10.8504 4.45037 11.0704 4.69037C11.2904 4.93037 11.4404 5.20037 11.5204 5.50037C11.6104 5.79037 11.6554 6.07037 11.6554 6.34037C11.6554 6.68037 11.5904 6.98537 11.4604 7.25537C11.3304 7.51537 11.1704 7.76037 10.9804 7.99037C10.7904 8.22037 10.5804 8.44037 10.3504 8.65037C10.1204 8.86037 9.91037 9.08037 9.72037 9.31037C9.53037 9.53037 9.37037 9.77537 9.24037 10.0454C9.11037 10.3054 9.04537 10.6004 9.04537 10.9304C9.04537 10.9804 9.04537 11.0354 9.04537 11.0954C9.04537 11.1554 9.05037 11.2104 9.06037 11.2604H7.89037C7.85037 11.0604 7.83037 10.8504 7.83037 10.6304C7.83037 10.3104 7.88537 10.0204 7.99537 9.76037C8.11537 9.50037 8.26537 9.26037 8.44537 9.04037C8.62537 8.81037 8.81537 8.60037 9.01537 8.41037C9.21537 8.21037 9.40537 8.01037 9.58537 7.81037C9.76537 7.61037 9.91037 7.40037 10.0204 7.18037C10.1404 6.96037 10.2004 6.72037 10.2004 6.46037C10.2004 6.04037 10.0654 5.70037 9.79537 5.44037C9.52537 5.17037 9.13037 5.03537 8.61037 5.03537ZM9.55537 13.6904C9.55537 13.9604 9.46537 14.1954 9.28537 14.3954C9.10537 14.5954 8.86037 14.6954 8.55037 14.6954C8.24037 14.6954 7.99537 14.5954 7.81537 14.3954C7.63537 14.1954 7.54537 13.9604 7.54537 13.6904C7.54537 13.4204 7.63537 13.1854 7.81537 12.9854C7.99537 12.7854 8.24037 12.6854 8.55037 12.6854C8.86037 12.6854 9.10537 12.7854 9.28537 12.9854C9.46537 13.1854 9.55537 13.4204 9.55537 13.6904Z'
        fill='white'
      />
    </svg>
  );
}

export const AddContactSection = styled.div`
  background-color: #fff;
  border-radius: 13px;
  border: 1.6px solid #e1e3e6;
  margin: 20px 0;
  padding: 20px 22px;
  .contact-header {
    font-size: 15px;
    display: flex;
    font-family: Ubuntu;
    color: #606060;
    font-weight: 700;
  }
  .contact_row {
    align-items: center;
    border-top: 1px solid #e1e3e6;
    font-size: 13px;
    font-weight: 500;
    font-family: Ubuntu;
    color: #c4c4c4;
    margin-top: 14px;
    padding-top: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    :first-of-type {
      border-top: none;
      margin-top: 8px;
      padding-top: 4px;
    }
    > div:first-of-type{
      order: 1;
    }
    > div:nth-of-type(2) {
      font-weight: 400;
      order: 3;
      margin-top: 12px;
    }
    > div:nth-of-type(3) {
      order: 2;
    }
    svg {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  .add_contact {
    border-top: 1.64px solid #D9DBE9;
    font-size: 13px;
    justify-content: center;
    line-height: 1.4;
    margin-bottom: 0px;
    padding-top: 12px;
  }
  @media (min-width: 768px) {
    padding: 20px 28px 18px;
    .add_contact {
      font-size: 15px;
    }
    .contact_row {
      border-top: none;
      font-size: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr 60px;
      margin-top: 0;
      > div:first-of-type{
        order: 1;
      }
      > div:nth-of-type(2) {
        font-weight: 400;
        order: 2;
        margin-top: 12px;
      }
      > div:nth-of-type(3) {
        order: 3;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const AddContactButtonWrap = styled.div`
  align-items: center;
  color: #0927c7;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  gap: 14px;
  line-height: 39px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-decoration: underline;
  position: relative;
  span {
    cursor: pointer;
  }
  :hover {
    color: blue;
  }
  > svg {
    cursor: pointer;
    margin-right: 32px;
  }
  .question_mark {
    :hover {
      + .tooltip {
        display: block;
      }
    }
  }
  .tooltip {
    background-color: #818087;
    border-radius: 13px;
    color: #fff;
    display: none;
    font-size: 12px;
    justify-content: center;
    line-height: 1.4;
    padding: 12px 8px;
    position: absolute;
    top: 40px;
    /* white-space: nowrap; */
    width: 180px;
    text-align: center;
    ::before {
      border-bottom: 12px solid #818087;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      content: "";
      left: 50%;
      position: absolute;
      top: -12px;
      transform: translateX(-50%); 
    }
  }
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    .tooltip {
      padding: 12px 20px;
      white-space: nowrap;
      width: auto;
    }
  }
`;

export const AddContactModal = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  padding: 20px;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  z-index: 30000000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  > div {
    background-color: rgba(239, 239, 239, 1);
    border-radius: 42px;
    padding: 54px 54px 40px;
    position: relative;
    width: 570px;
    button {
      margin-top: 40px;
      width: 100%;
    }
    .section_header {
      text-align: center;
      width: 100%;
    }
    .close_icon {
      color: #B7B7B7;
      cursor: pointer;
      position: absolute;
      right: 32px;
      top: 32px;
      :hover {
        color: #333;
      }
    }
  }
  @media (min-width: 1024px) {
    > div {
      .close_icon {
        right: 40px;
        top: 32px;
      }
    }
  }
`;

export const UploadedFilesWrap = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 16px;
  .row {
    align-items: center;
    display: flex;
    font-weight: 400;
    gap: 12px;
    padding: 8px 0px;
    position: relative;
    svg {
      max-width: 14px;
      cursor: pointer;
      :hover {
        path {
          fill: #0927c7;
        }
      }
    }
  }
`;

export const AddPropButtonWrap = styled.div`
  margin-bottom: 56px;
  margin-top: 48px;
  width: 100%;
  > button {
    width: 100%;
    color: #000;
    background: rgba(197, 197, 199, 0.5);
    transition: all 0.2s ease-in-out;
    :hover {
      background-color: #0927c7;
      color: #fff;
    }
    :disabled {
      color: #fff;
      background-color: #ccc;
    }
  }
`;
