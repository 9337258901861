/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import React from 'react';

import { MenuIcon, XIcon, DownCaret, GlossaryIcon } from '../ui/icons';
import logoImg from '../../images/logo.jpg';
import { NavLink } from 'react-router-dom';
import {
  SmartTourIcon,
  CostSegIcon,
  SpecsExperienceIcon,
  AboutMadisonSpecsIcon,
  MeetYourTeamIcon,
  CaseStudiesIcon,
  OurFootprintIcon,
  FAQsIcon,
} from '../ui/icons';
import { wrapS, menuS, icon, sectionWrap } from './mobileHeaderStyles';
import CallButton from '../ui/CallButton';
import SiteLink from './SiteLink';
import TransitionModal from './TransitionModal';

import { myCircle } from './headerStyles';

export default class MobileHeader extends React.Component {
  state = {
    menuExpandedStates: [false, false, false, false, false, false, false, false, false],
    menuOpen: false,
    showExitModal: false,
    exitLink: '',
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.mobileMenuOpen !== this.props.mobileMenuOpen) {
      const { menuExpandedStates } = this.state;
      this.setState({
        menuExpandedStates: menuExpandedStates.map((_) => false),
      });
    }
  };

  toggleMenuExpansion = (index) => {
    let { menuExpandedStates } = this.state;
    if (menuExpandedStates[index]) {
      //if one being clicked is already open, set all to false so that it gets closed
      menuExpandedStates = menuExpandedStates.map((_) => false);
    } else {
      //if a different one is being clicked, set only THAT one to be true so that all others close
      menuExpandedStates = this.state.menuExpandedStates.map((_, i) => i === index);
    }
    this.setState({ menuExpandedStates });
  };

  onLinkClick = (location) => this.setState({ showExitModal: true, exitLink: location });

  render() {
    const [oneExpanded, twoExpanded, threeExpanded, fourExpanded] = this.state.menuExpandedStates;

    const { menuOpen, showExitModal, exitLink } = this.state;

    return (
      <div className='relative'>
        {menuOpen && (
          <Global
            styles={css`
              body {
                height: 100vh;
                overflow: hidden;
              }
            `}
          />
        )}
        {showExitModal && <TransitionModal onClick={() => (window.location.href = exitLink)} />}
        <div css={wrapS}>
          <NavLink to='/'>
            <img src={logoImg} alt='' />
          </NavLink>
          {menuOpen ? (
            <XIcon css={icon} onClick={() => this.setState({ menuOpen: false })} />
          ) : (
            <MenuIcon css={icon} onClick={() => this.setState({ menuOpen: true })} />
          )}
        </div>
        {menuOpen && (
          <div css={menuS}>
            <div>
              <Section
                sectionName='How It Works'
                isOpen={oneExpanded}
                handleOpen={() => this.toggleMenuExpansion(0)}
                height='360px'
              >
                <NavLink onLinkClick={this.onLinkClick} to='/about-cost-seg'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <CostSegIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>What is Cost Seg</div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        Learn more about the IRS-approved tax bill reduction
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to='/smart-tour'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <SmartTourIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>The Smart Tour</div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        The safer, faster way to get your study done
                      </div>
                    </div>
                  </div>
                </NavLink>{' '}
                <NavLink to='/process'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <SpecsExperienceIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>Your Specs Experience</div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        What will your cost seg journey look like?
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Section>
            </div>
            <div>
              <Section
                sectionName='Who We Are'
                isOpen={twoExpanded}
                handleOpen={() => this.toggleMenuExpansion(1)}
                height='256px'
              >
                <SiteLink onLinkClick={this.onLinkClick} to='aboutus/overview'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <AboutMadisonSpecsIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>About Madison Specs </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        Our mission, our values, and our foundation
                      </div>
                    </div>
                  </div>{' '}
                </SiteLink>
                <SiteLink onLinkClick={this.onLinkClick} to='aboutus/personnel'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <MeetYourTeamIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>Meet your team </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        Get to know your experts at SPECS{' '}
                      </div>
                    </div>
                  </div>
                </SiteLink>
                {/* <NavLink to='/about-cost-seg'>About Cost Seg</NavLink>
                <a href='/#savings-scroll' onClick={() => this.setState({ menuOpen: false })}>
                  Eligibility
                </a>
                <NavLink to='/faq'>FAQs</NavLink> */}
              </Section>
            </div>
            <div>
              <Section
                sectionName="What We've Done"
                isOpen={threeExpanded}
                handleOpen={() => this.toggleMenuExpansion(2)}
                height='246px'
              >
                <NavLink to='/case-studies/multi-family'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <CaseStudiesIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>Case Studies </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        How much did a property like yours save?{' '}
                      </div>
                    </div>
                  </div>
                </NavLink>{' '}
                <NavLink to='/map'>
                  {' '}
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <OurFootprintIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>Our Footprint </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                          width: '85%',
                        }}
                      >
                        Our studies span the country
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Section>
            </div>
            <div>
              <Section
                sectionName='Help Hub'
                isOpen={fourExpanded}
                handleOpen={() => this.toggleMenuExpansion(3)}
                height='246px'
              >
                <NavLink to='/faq'>
                  {' '}
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                      <FAQsIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>FAQs </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                        }}
                      >
                        Get your answers here
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to='/glossary'>
                  <div className='wrapper' style={{ display: 'flex' }}>
                    <div css={myCircle} style={{ marginRight: '20px' }}>
                      <GlossaryIcon />
                    </div>
                    <div className='text' style={{ lineHeight: '1.5' }}>
                      <div style={{ color: 'black', fontSize: '13px' }}>Glossary </div>
                      <div
                        style={{
                          textTransform: 'none',
                          fontWeight: '500',
                          fontSize: '11px',
                          marginTop: '5px',
                        }}
                      >
                        Know your terms, understand the process
                      </div>
                    </div>
                  </div>
                </NavLink>
                {/* <SiteLink onLinkClick={this.onLinkClick} to='resources/costsegregationglossarya'>
                  Cost Seg Glossary
                </SiteLink>
                <NavLink to='/case-studies/multi-family'>Case Studies</NavLink> */}
              </Section>
            </div>
            <div>
              <HalfSection sectionName='Contact' link='/contact-us'></HalfSection>
            </div>
            <CallButton
              text='Get A Quote'
              to='/custom-quote'              
              blank={true}
              styles={{
                backgroundColor: '#55dcac',
                margin: '32px auto 0',
                borderRadius: 8,
                height: 40,
                width: 160,
                padding: '0 24px',
                '&:hover': { backgroundColor: '#55dccf' },
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const menuItemWrap = {
  backgroundColor: '#fbfbfb',
  height: 0,
  overflow: 'hidden',
  transition: 'all .2s',
  display: 'flex',
  alignItems: 'center',

  a: {
    color: '#929292',
    fontWeight: 600,
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 0,
    marginLeft: 16,
    marginRight: 16,
    ':first-of-type': {
      // paddingTop: 16,
    },
  },
};

const innerItemWrap = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
  justifyContent: 'center',
  width: '100%',
};

function Section({ sectionName, link, isOpen, handleOpen, children, height }) {
  return (
    <div css={{}}>
      <div css={sectionWrap} onClick={handleOpen}>
        <div>{sectionName}</div>
        <DownCaret css={[{ transition: 'all .2s' }, isOpen && { transform: 'rotate(-180deg)' }]} />
      </div>
      <div
        css={[
          menuItemWrap,
          isOpen && {
            height,
            transition: 'all .2s',
            borderBottom: '1px solid #f2f2f2',
          },
        ]}
      >
        <div css={innerItemWrap}>{children}</div>
      </div>
    </div>
  );
}
function HalfSection({ sectionName, link }) {
  return (
    <div>
      <div css={sectionWrap}>
        <NavLink to={link}>{sectionName}</NavLink>
      </div>
    </div>
  );
}
