/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import {
  circlesBlocksStyles,
  engineerWrap,
  headerWrap,
  info,
  root,
  subHeader,
  innerWrap,
  outerWrap,
  secondInfo,
  firstInfo,
} from './whoCanPerformStyles';
import { ReactComponent as Engineer } from '../ui/svg/about-us/engineer.svg';
import { ReactComponent as CirclesBlocks } from '../ui/svg/about-us/circles-blocks-sm.svg';

export default function () {
  return (
    <div className='tac' css={root}>
      <div className='section-header__title'>
        <div css={headerWrap}>Who can perform cost segregation?</div>
      </div>
      <div css={subHeader}>
        SPECS’ cost segregation studies are performed by accounting and engineering experts with experience in
        performing a thorough analysis of real estate.
      </div>
      <div css={outerWrap}>
        <div css={innerWrap}>
          <div css={firstInfo}>
            SPECS’ cost segregation studies are performed by accounting and engineering experts with experience in
            performing a thorough analysis of real estate.
          </div>
          <div css={info}>
            Their identifying and reclassifying eligible assets to shorter recovery periods is the key to the lowering
            of tax liability from the resulting accelerated depreciation on those assets.
          </div>
          <div css={secondInfo}>
            Following FASB (Financial Accounting Standards Board) guidelines, they identify those assets, assess their
            value and determine the resulting tax deductions for depreciation. Upon completion, documentation is
            provided to support the results that are filed with the asset’s depreciation schedule.
          </div>
        </div>
        <div css={engineerWrap}>
          <Engineer />
          <div css={circlesBlocksStyles}>
            <CirclesBlocks />
          </div>
        </div>
      </div>
    </div>
  );
}
