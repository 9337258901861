/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import { MinusIcon, BlueMotif } from '../ui/icons';
import {
  Root,
  ClosedQuestion,
  QuestionStyles,
  MotifWrap,
  ParStyles,
  plusMinus,
  openIcon
} from './searchResultStyles';

export default function SearchResult({ question, answer }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <Root isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
      <div css={[plusMinus, isOpen && openIcon]} className='succos'>
        <MinusIcon />
        <MinusIcon />
      </div>
      <ClosedQuestion>
        <QuestionStyles className='syne'>{question}</QuestionStyles>
      </ClosedQuestion>
      <Collapse isOpened={isOpen}>
        <MotifWrap isOpen={isOpen}>
          <BlueMotif />
        </MotifWrap>
        <ParStyles>{answer}</ParStyles>
      </Collapse>
    </Root>
  );
}
