import React from 'react';

export function FeasabilitySmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 29 29'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='#FFF'>
        <path
          d='M25.84 12.92C25.84 5.784 20.056 0 12.92 0S0 5.784 0 12.92s5.784 12.92 12.92 12.92V12.92h12.92z'
          transform='translate(.08 .08)'
        />
        <path d='M27.84 14.92H14.92v12.92c7.133-.006 12.914-5.787 12.92-12.92z' transform='translate(.08 .08)' />
      </g>
    </svg>
  );
}

export function AccountantInputSmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 26 26'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='none' fillRule='evenodd' transform='translate(.92 .92)'>
        <rect width='24.15' height='24.15' fill='#FFF' fillRule='nonzero' rx='3' />
        <path stroke='#0C2AC7' d='M6.38 18.01L6.38 14.13M12.08 18.01L12.08 11.69M17.78 18.01L17.78 6.15' />
      </g>
    </svg>
  );
}
export function DocumentGatheringSmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 23 28'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M3.82 19.08V5.36H2.29C1.025 5.36 0 6.385 0 7.65v16.17c0 1.266 1.024 2.294 2.29 2.3h11.48c1.27 0 2.3-1.03 2.3-2.3v-1.53h-9c-.858.01-1.685-.323-2.296-.926-.61-.603-.954-1.426-.954-2.284z'
          transform='translate(.29 .48)'
        />
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M19.13 0H7.65c-1.27 0-2.3 1.03-2.3 2.3v16.16c0 1.27 1.03 2.3 2.3 2.3h11.48c1.266-.006 2.29-1.034 2.29-2.3V2.3c0-1.266-1.024-2.294-2.29-2.3z'
          transform='translate(.29 .48)'
        />
        <path stroke='#55DCAC' d='M9.36 5.93L17.29 5.93M9.36 9.69L13.39 9.69' transform='translate(.29 .48)' />
      </g>
    </svg>
  );
}
export function TheBeginningSmall({ maxWidth, ...props }) {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z'
        fill='white'
      />
      <path
        d='M13 15.2C14.215 15.2 15.2 14.215 15.2 13C15.2 11.785 14.215 10.8 13 10.8C11.785 10.8 10.8 11.785 10.8 13C10.8 14.215 11.785 15.2 13 15.2Z'
        fill='#55DCAC'
      />
      <path d='M13 13L19.6 5.89999' stroke='#55DCAC' strokeWidth='1.0062' />
    </svg>
  );
}

export function ProposalSmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 18 24'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='#FFF'>
        <path d='M9 9c4.35 0 9-1.35 9-4.5S13.35 0 9 0 0 1.35 0 4.5 4.65 9 9 9zM0 9.75V12c0 3.15 4.65 4.5 9 4.5s9-1.35 9-4.5V9.75C15.9 11.1 12.75 12 9 12s-6.9-.9-9-2.25zM0 17.25v2.25C0 22.65 4.65 24 9 24s9-1.35 9-4.5v-2.25c-2.1 1.5-5.25 2.25-9 2.25s-6.9-.9-9-2.25z' />
      </g>
    </svg>
  );
}

export function SiteVisitSmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 33 31'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='#FFF'>
        <path
          d='M9.28 9.41c-.052.314-.079.632-.08.95 0 3.629 2.941 6.57 6.57 6.57 3.629 0 6.57-2.941 6.57-6.57-.001-.318-.028-.636-.08-.95H9.28zM19.73 1v2.63h-1.7V.29c-.474-.138-.96-.235-1.45-.29v3.6h-1.71V0c-.491.051-.977.148-1.45.29v3.3h-1.7V1c-2.6 1.465-4.208 4.216-4.21 7.2h16.52c-.022-3.002-1.667-5.757-4.3-7.2zM7.51 20.38C3.742 22.61 1.034 26.266 0 30.52h7.51V20.38zM9.13 23.78h13.3v-4.25c-4.23-1.9-9.07-1.9-13.3 0v4.25zM31.54 30.52c-1.036-4.245-3.735-7.895-7.49-10.13v10.13h7.49z'
          transform='translate(.66)'
        />
      </g>
    </svg>
  );
}

export function StudyDeliveredSmall({ maxWidth, ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 23 28'
      {...props}
      style={{ maxWidth: maxWidth }}
    >
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M3.82 19.08V5.36H2.29C1.025 5.36 0 6.385 0 7.65v16.17c0 1.266 1.024 2.294 2.29 2.3h11.48c1.27 0 2.3-1.03 2.3-2.3v-1.53h-9c-.858.01-1.685-.323-2.296-.926-.61-.603-.954-1.426-.954-2.284z'
          transform='translate(.29 .48)'
        />
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M19.13 0H7.65c-1.27 0-2.3 1.03-2.3 2.3v16.16c0 1.27 1.03 2.3 2.3 2.3h11.48c1.266-.006 2.29-1.034 2.29-2.3V2.3c0-1.266-1.024-2.294-2.29-2.3z'
          transform='translate(.29 .48)'
        />
        <path
          stroke='#0C2AC7'
          d='M8.03 14.2s1.23-4.41 3.29-2.2c4.75 5.08 2.29-6.27 8.56-6.19'
          transform='translate(.29 .48)'
        />
      </g>
    </svg>
  );
}
