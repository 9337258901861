import styled from '@emotion/styled';

export const Root = styled.div`
  padding: 24px 24px 32px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  .plus-three {
    position: absolute;
    display: none;
  }
  .more-section-header {
    font-family: 'Syne';
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 32px;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    padding: 64px 32px 72px;
    .more-section-header {
      margin-bottom: 72px;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    padding: 72px 32px 132px;
    .more-section-header {
      font-size: 40px;
    }
  }
  @media (min-width: 1440px) {
    padding: 80px 121px 144px;
    max-width: 1440px;
    .plus-three {
      display: block;
      left: 60px;
      bottom: -70px;
      width: 138px;
    }
  }
  @media (min-width: 1920px) {
    .plus-three {
      left: -74px;
    }
  }
`;

export const GraphWrap = styled.div`
  .comp-graph {
    display: block;
    height: auto;
    margin: 30px auto 0;
    max-width: 468px;
    width: 100%;
  }
  .recharts-surface {
    overflow: visible;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 320px);
    .comp-graph {
      max-width: 473px;
      margin-left: 60px;
    }
    .more-section-header {
      margin-bottom: 98px;
    }
  }
  @media (min-width: 1440px) {
    width: calc(100% - 640px);
    width: 50%;
    .comp-graph {
      margin-left: 0;
    }
  }
`;
