import React, { useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { TextField } from '@mui/material';

import { AddressWrap } from './addressAutoStlyes';
import { XIcon } from '../ui/icons';

let googleApiClient;

async function getGoogleMapsApiClient() {
  if (googleApiClient) {
    return googleApiClient;
  }
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY_TWO || '',
    version: 'weekly',
    libraries: ['places'],
  });
  googleApiClient = await loader.importLibrary('places');
  return googleApiClient;
}

export default function AddressAutocomplete({ setAddress, onNoResultsFound, label }) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const sessionTokenRef = useRef();

  const timeoutRef = useRef();
  const handleChange = (event) => {
    const newValue = event.target.value;

    setValue(newValue);
    onNoResultsFound(event);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!newValue || newValue.trim().length <= 1) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      const google = await getGoogleMapsApiClient();

      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new google.AutocompleteSessionToken();
      }

      new google.AutocompleteService().getPlacePredictions(
        {
          input: newValue,
          sessionToken: sessionTokenRef.current,
          componentRestrictions: { country: 'US' },
          types: ['address'],
        },
        (predictions, status) => {
          if (status === google.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          if (status !== google.PlacesServiceStatus.OK || !predictions) {
            onNoResultsFound(event);
            return;
          }
          setSuggestions(predictions);
        },
      );
    }, 350);
  };

  const handleSuggestionSelected = async (suggestion) => {
    setSuggestions([]);

    const google = await getGoogleMapsApiClient();

    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    new google.PlacesService(
      document.getElementById('googlemaps-attribution-container'),
    ).getDetails(
      {
        placeId: suggestion.place_id,
        fields: ['address_components', 'name'],
        sessionToken,
      },
      (place, status) => {
        if (status === google.PlacesServiceStatus.OK) {
          setValue(place.name);

          let address = {
            addressLine1: null,
            city: null,
            state: null,
            zip: null,
          };

          for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                address.addressLine1 = `${component.long_name}`;
                break;
              }

              case 'route': {
                address.addressLine1 += ` ${component.short_name}`;
                break;
              }

              case 'postal_code': {
                address.zip = `${component.long_name}`;
                break;
              }

              case 'locality':
              case 'sublocality_level_1': {
                address.city = component.long_name;
                break;
              }

              case 'administrative_area_level_1': {
                address.state = component.short_name;
                break;
              }

              default:
                break;
            }
          }

          setAddress(address);
        }
      },
    );
  };

  return (
    <AddressWrap>
      <TextField
        label={label}
        onChange={handleChange}
        value={value}
        size='small'
        name='addressLine1'
      />
      {suggestions.length > 0 && (
        <div className='dropdown_wrap'>
          <ul role='listbox'>
            <XIcon onClick={() => setSuggestions([])} />
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                tabIndex={0}
                role='option'
                aria-selected='false'
                onClick={() => handleSuggestionSelected(suggestion)}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div id='googlemaps-attribution-container'></div>
    </AddressWrap>
  );
}
