/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import LazyLoad from 'react-lazyload';

import {
  city1Styles,
  city2Styles,
  cityWrap,
  headerWrap,
  info,
  root,
  subHeader,
  innerWrap,
  city3Styles,
  city4Styles,
  city2Wrap,
  slideWrap,
} from './whatTypeStyles';
import city1 from '../../images/city1.png';
import city2 from '../../images/city2.png';
import city3 from '../../images/city3.png';
import city4 from '../../images/city4.png';

import WhatTypeSlider from './WhatTypeSlider';
import { SlideOne, SlideTwo, SlideThree, SlideFour } from './Slides';
export default function () {
  return (
    <div className='tac' css={root}>
      <div className='section-header__title'>
        <div css={headerWrap}>What type of property is a candidate for cost segregation?</div>
      </div>
      <div css={info}>
        Almost all investment properties, or properties held for business, are eligible for Cost Segregation. However,
        each property has its own unique characteristics that affect the percentages of depreciation you can accelerate.
        At Madison SPECS we can help you identify what property types in your portfolio are the best candidates.
      </div>
      <div css={innerWrap}>
        <div css={cityWrap}>
          <LazyLoad height={200}>
            <img css={city1Styles} src={city1} alt='' />
          </LazyLoad>
          <LazyLoad height={200}>
            <img css={city2Styles} src={city2} alt='' />
          </LazyLoad>
        </div>
        <div css={subHeader}>
          Below is a list of some of the property types which bring higher amounts of accelerated depreciation.
        </div>
      </div>
      <div css={slideWrap}>
        <WhatTypeSlider
          list={[{ slide: <SlideOne /> }, { slide: <SlideTwo /> }, { slide: <SlideThree /> }, { slide: <SlideFour /> }]}
        />
      </div>
      <div css={city2Wrap}>
        <LazyLoad height={200}>
          <img css={city3Styles} src={city3} alt='' />
        </LazyLoad>
        <LazyLoad height={200}>
          <img css={city4Styles} src={city4} alt='' />
        </LazyLoad>
      </div>
    </div>
  );
}
