/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Root, OuterWrap, iconWrapper, numberStyles, textStyles } from './infoTilesStyles';
import { SearchPaperIcon, HouseIcon, HourGlassIconBlue } from '../ui/icons';

export default function InfoTiles() {
  return (
    <Root>
      <Tile
        icon={<SearchPaperIcon />}
        number='30,263'
        text='cost segregation
studies'
      />
      <Tile
        blue
        icon={<HouseIcon />}
        number='$200K'
        text='average tax write-off
        per $1M in reclassified
        assets'
      />
      <Tile
        icon={<HourGlassIconBlue />}
        number='100'
        text='years combined
        industry experience'
      />
    </Root>
  );
}

function Tile({ icon, number, text, blue }) {
  return (
    <OuterWrap css={blue && { backgroundColor: '#0827c7' }}>
      <div
        css={[
          {
            backgroundColor: '#57e5b2',
            height: 44,
            '@media(min-width: 1024px)': {
              position: 'absolute',
              left: 0,
              top: 0,
              width: 64,
              height: '100%',
              borderRadius: '8px 0 0 8px',
            },
            '@media(min-width: 1440px)': {
              width: 78,
            },
          },
          blue && { backgroundColor: '#1d3de0' },
        ]}
      />
      <div css={iconWrapper}>{icon}</div>
      <div className='syne' css={numberStyles}>
        {number}
      </div>
      <div css={textStyles}>{text}</div>
    </OuterWrap>
  );
}
