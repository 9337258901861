import styled from '@emotion/styled';

// const foo = '../ui/svg/glossaryBgcImage.svg';
import foo from '../ui/svg/glossaryBgcImage.svg';

export const MobileRoot = styled.div`
  background-image: url(${foo});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${(props) => (props.selected === true ? 'white' : 'black')}; 
  margin-bottom: 100px;
  margin-top: 0px;
  padding: 56px 22px 150px;
  position: relative;
  :first-of-type {
    margin-top: 0px;
  }
  .select__option {
    color: #0318C6;
    color: red;


    :focus {
      border: 1px solid red;
    }
  }
  .select__control {
    border-radius: 7px;
    height: 48px;
    border: none;
    padding: 0 8px;
  }
  .select__single-value {
    font-size: 18px;
    line-height: 19px;
    font-family: Syne;
  }
  .select__indicator-separator {
    display: none;
  }
  .select {
    margin-top: 32px;
  }
  .cat_title {
    font-size: 30px;
    line-height: 40px;
    font-family: Syne;
    text-align: center;
  }
  .optionlabel {
    display: flex;
    align-items: center;
    gap: 14px;
    color: #0318C6;
    svg {
      width: 18px;
      height: auto;
      path {
        fill: currentColor
      }
    }
  }
  .select__option {
    font-size: 18px;
    line-height: 21px;
    font-family: Syne;
    color: #444;
    :hover {
      color: #0318C6;
    }
  }
  .select__option--is-selected {
    color: #0318C6;
    background-color: #eee;
  }
  .answer_illustration {
    position: absolute;
    bottom: -60px;
    right: -20px;
    /* max-width: 330px; */
    height: 210px;
    width: auto;
  }
  .icon_cirle {
    position: absolute;
    top: -24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 50%;
    transform: translateX(50%);
    background-color: ${(props) => {
      switch (props.color) {
        case 'green':
          return '#59C09E';
        case 'orange':
          return '#EDB944';
        default:
          return '#0318C6';
      }
    }};
  
    svg {
      width: 24px;
      height: auto;
    }
  }
  .answer_wrap {
    font-size: 15px;
    line-height: 23px;
    margin-top: 32px;
  }
  :first-of-type {
    margin-top: 0px;
  }

  .optionlabel,
  .select__option,
  .select__option--is-selected {
    color: ${(props) => {
      switch (props.color) {
        case 'green':
          return '#59C09E';
        case 'orange':
          return '#EDB944';
        default:
          return '#0318C6';
      }
    }};
  }

  @media (min-width: 768px) {
    min-height: 600px;
  }
  `;

export const Responsive = styled.div`
  position: relative;
  padding-bottom: 80px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const AbolutePlacement = styled.div`
  border: 1px solid red;
`;

export const TabsWrap = styled.div`
  background-color: #E0E0F3;
  border-radius: 40px;
  display: inline-flex;
  margin: 0px auto -22px;
  position: relative;
  z-index: 1;
  ::before {
    background-color: #0318c6;
    border-radius: 45px;
    content: '';
    display: inline-flex;
    height: 44px;
    left: ${(props) => {
      switch (props.selectedIndex) {
        case 0:
          return '-4px';
        case 1:
          return '89px';
        case 2:
          return '178px';
        default:
          return '-4px';
      }
    }};
    position: absolute;
    top: -4px;
    transition: left 0.15s ease-in-out;
    width: 105px;
    z-index: 2;
  }
  @media (min-width: 768px) {
    border-radius: 40px;
    ::before {
      content: '';
      width: 160px;
      height: 56px;
      top: -4px;
      left: ${(props) => {
        switch (props.selectedIndex) {
          case 0:
            return '-8px';
          case 1:
            return '134px';
          case 2:
            return '267px';
          default:
            return '-8px';
        }
      }};  
    }
  }
`;

export const Tabber = styled.div`
  align-items: center;
  color: ${(props) =>
    props.selectedCategory === props.index ? 'white' : 'rgba(156, 156, 156, 0.50)'};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  height: 36px;
  justify-content: center;
  padding-bottom: 2px;
  padding: 0px 16px;
  position: relative;
  transition: color 0.2s ease-in-out;
  z-index: 3;
  @media (min-width: 768px) {
    padding: 0px 32px;
    height: 48px;
    font-size: 20px;
    /* border: 1px solid red; */
    /* border-left: none;
    :first-of-type {
      border-left: 1px solid red;
    } */
  }
`;
