/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import Header, { categories } from '../components/case-studies/Header';
import StorySection from '../components/case-studies/StorySection';
import CaseStudy from '../components/case-studies/CaseStudy';
import SearchSection from '../components/case-studies/SearchSection';
import Pagination from '../components/case-studies/Pagination';
import BottomSection from '../components/case-studies/BottomSection';

export default function CaseStudies() {
  const [category, setCategory] = useState(categories.multiFamily);
  const { pathname } = useLocation();

  return (
    <div css={{ fontWeight: 'normal' }}>
      <Global
        styles={{
          footer: {
            position: 'relative',
            zIndex: 2,
          },
        }}
      />
      <Header setCategory={setCategory} />
      <CaseStudy category={category} />
      <Pagination />
      {(pathname === '/case-studies' || pathname === '/case-studies/') && <Redirect to='/case-studies/multi-family' />}
      <SearchSection />
      <BottomSection />
    </div>
  );
}
