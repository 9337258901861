/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { Root, ZoomIn, ZoomOut, mapWrapStyles, mapOptions, pinStyles } from './mapSectionStyles';
import { AddressContext } from './Address';
import CompletedStudies from './CompletedStudies';
import { MinusIcon, PlusIcon } from '../ui/icons';

const Pin = () => (
  <div style={pinStyles}>
    <img src={process.env.PUBLIC_URL + '/map-images/pin.png'} width={89} alt='pin location' />
  </div>
);

export default function MapSection() {
  const {
    addressSearched,
    defaultCenter,
    center,
    zoom,
    nearbyDeals,
    featuredDeal,
    locations,
    filter,
    miles,
    setMap,
    refresh,
    map,
    search,
  } = useContext(AddressContext);

  useEffect(() => {
    search();
  }, [window.google]);

  function setGoogleMapRef(map, maps) {
    setMap(map);
    let markers =
      locations &&
      locations.map((location) => {
        return new maps.Marker({ position: location });
      });
    new MarkerClusterer(map, markers, {
      imagePath: process.env.PUBLIC_URL + '/map-images/m',
      gridSize: 50,
      minimumClusterSize: 1,
    });
  }

  return (
    <Root>
      <ZoomIn onClick={() => map.setZoom(map.getZoom() + 1)}>
        <PlusIcon />
      </ZoomIn>
      <ZoomOut onClick={() => map.setZoom(map.getZoom() - 1)}>
        <MinusIcon />
      </ZoomOut>
      <div style={{ height: '100vh', width: '100%' }} css={mapWrapStyles}>
        <GoogleMapReact
          key={filter}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY,
          }}
          defaultCenter={defaultCenter}
          center={center}
          zoom={zoom}
          defaultZoom={5}
          onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals
        >
          {addressSearched && <Pin lat={center.lat} lng={center.lng} />}
        </GoogleMapReact>
      </div>
      {featuredDeal && addressSearched && (
        <CompletedStudies
          nearbyDeals={nearbyDeals}
          address={addressSearched}
          propertyDescription={featuredDeal.propertyType}
          propertyValue={featuredDeal.totalCosts}
          squareFootage={featuredDeal.squareFootage}
          valueOfReclassifiedAssets={featuredDeal.valueOfReclassifiedAssets}
          depreciableAssets={featuredDeal.depreciableAssets}
          filter={filter !== 'All' ? filter : ''}
          miles={miles}
          refresh={refresh}
        />
      )}
    </Root>
  );
}
