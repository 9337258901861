import React, { useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import axios from 'axios';

import { Root, FormWrap } from './styles';
import PropertyInformation from './PropertyInformation';
import Banner from './Banner';
import ContactInformation from './ContactInformation';
import PropertyDetails from './PropertyDetails';
import AdditionalInformation from './AdditionalInformation';
import FormCompleted from './FormCompleted';
import Stepper from './Steppers';
import Footer from './Footer';

export default function CustomQuote() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { salesRepId } = useParams();

  const {
    source,
    sourceChoice,
    medium,
    campaign,
    content,
    device,
    keyword,
    adgroup,
    identifier,
  } = Object.fromEntries(queryParams);

  const [request, setRequest] = useImmer({
    url: window.location.href,
    salesRepId,
    source,
    sourceChoice: parseInt(sourceChoice),
    medium,
    campaign,
    content,
    device,
    keyword,
    adgroup,
    identifier,
  });

  const [step, setStep] = useState(1);
  const formRef = useRef(null);

  const handleNextStep = () => {
    formRef.current.scrollIntoView();
    if (step < 5) {
      setStep(step + 1);
    } else {
      console.log('form is done yay!!!');
    }

    if (step === 4) {
      axios.post(
        'https://specs-feasibility-form.azurewebsites.net/api/SendFeasibilityAnalysisRequest',
        request
      );
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <>
      <Root>
        <Banner stepOne={step === 1} />
        <FormWrap ref={formRef}>
          {step !== 5 && <Stepper step={step} />}
          {step === 1 && (
            <ContactInformation request={request} setRequest={setRequest} nextStep={handleNextStep} prevStep={handlePrevStep} />
          )}
          {step === 2 && (
            <PropertyInformation request={request} setRequest={setRequest} nextStep={handleNextStep} prevStep={handlePrevStep} />
          )}
          {step === 3 && <PropertyDetails request={request} setRequest={setRequest} nextStep={handleNextStep} prevStep={handlePrevStep} />}
          {step === 4 && (
            <AdditionalInformation request={request} setRequest={setRequest} nextStep={handleNextStep} prevStep={handlePrevStep} />
          )}
          {step === 5 && <FormCompleted />}
        </FormWrap>
      </Root>
      <Footer />
    </>
  );
}
