import styled from '@emotion/styled';

export const Root = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
  @media (min-width: 1024px) {
    margin: 107px 85px;
    position: relative;
  }
  @media (min-width: 1440px) {
    margin: 141px 90px;
    margin-right: 120px;
  }
  @media (min-width: 1920px) {
    max-width: 100%;
    margin: 188px 360px;
  }
`;
export const imageStyles = {
  position: 'absolute',
  zIndex: 1,
  top: '-107px',
  width: '100%',
  minWidth: 1024,
  marginLeft: '-90px',
  height: 999,
  '@media(min-width: 1440px)': {
    minWidth: 1440,
  },
  '@media(min-width: 1920px)': {
    minWidth: 1920,
    marginLeft: -350,
    top: -218,
  },
};
export const Sidebar = styled.div`
  position: sticky;
  top: 50px;
  display: inline-block;
  vertical-align: top;
  z-index: 500;
`;
export const Header = styled.h1`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #b9b9b9;
  text-transform: uppercase;
  margin-bottom: 32px;
  padding-top: 30px;
`;
export const innerWrap = {
  marginLeft: 40,
  position: 'relative',
  '&:before': {
    content: '""',
    borderLeft: 'solid 1px rgba(181, 181, 181, 0.15)',
    position: 'absolute',
    left: -41,
    top: 16,
    bottom: 16,
  },
};
export const TopicStyles = styled.div`
  cursor: pointer;
  color: ${(props) => (props.inView ? 'blue' : 'black')};
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  font-size: 20px;
  position: relative;
  z-index: 50;
  transition: all 0.2s;
  :hover {
    transition: all 0.2s;
    color: blue;
    ::before {
      transition: all 0.2s;
      background-color: blue;
    }
  }
  ::before {
    transition: all 0.2s;
    content: '';
    height: 9px;
    width: 9px;
    position: absolute;
    border-radius: 50%;
    background-color: ${(props) => (props.inView ? 'blue' : '#b5b5b5')};
    left: -45px;
  }
`;
export const iconStyles = {
  borderRadius: '50%',
  height: 40,
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 28,
};
export const TopicBottom = styled.h1`
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
`;
