import React from 'react';

export const mainWrap = {
  backgroundColor: '#000e56',
  height: '100%',
  position: 'relative',
  zIndex: 500,
  '@media(max-width: 767px)': {
    display: 'none',
  },
};

export const footerWrap = {
  color: 'white',
  fontWeight: 500,
  padding: 40,
  maxWidth: 1450,
  margin: '0 auto',
  '@media(min-width: 1024px)': {
    padding: '102px 40px',
  },
  '@media(min-width: 1260px)': {
    padding: '102px 0px',
  },
};

export const logoWrap = {
  lineHeight: 1,
  maxWidth: 1024,
  margin: '0 auto',
  svg: {
    height: 'auto',
    width: 143,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1450,
    padding: '0 40px',
    svg: {
      height: 'auto',
      width: 176,
    },
  },
};

export const menuSection = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: 32,
  marginTop: 32,
  width: 660,
  maxWidth: 1220,
  '> div': { width: 202 },
  '@media(min-width: 1024px)': {
    justifyContent: 'flex-start',
    gap: 54,
    width: '100%',
    maxWidth: '1024px',
    margin: '62px auto 0',
    '> div': { width: 'auto' },
  },
  '@media(min-width: 1260px)': {
    width: '100%',
    maxWidth: 1450,
    padding: '0 40px',
    gap: 98,
  },
};

export const sectionWrap = {
  cursor: 'default',
  fontSize: 20,
  fontWeight: 500,
  textTransform: 'uppercase',
};

export const finePrintWrap = {
  backgroundColor: '#000e56',
  margin: '0 auto',
  '@media(min-width: 1024px)': {
    maxWidth: 1024,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1370,
  },
};

export const finePrintInner = {
  alignItems: 'center',
  display: 'flex',
  height: 100,
  justifyContent: 'space-between',
  padding: '24px 0',
  maxWidth: 1024,
  margin: '0 auto',
  a: {
    color: 'white',
    fontSize: 15,
    fontWeight: 300,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1370,
  },
};
export const copyright = {
  color: '#fff',
  fontSize: 15,
  fontWeight: 300,
};
export const openMenuStyles = {
  marginTop: 24,
  a: {
    color: '#fff',
    display: 'flex',
    flexWrap: 'none',
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.5,
    marginTop: 16,
    ':first-of-type': {
      marginTop: 0,
    },
    '&:hover': {
      color: '#fff',
    },
  },
};

export const contacter = {
  display: 'flex',
  flexDirection: 'column',
  a: {
    color: '#fff',
    fontSize: '15px',
    lineHeight: 1.5,
    fontWeight: 300,
    marginTop: 20,
    textTransform: 'lowercase',
  },
  'a:first-of-type': {
    marginTop: 0,
    fontSize: '20px',
    textTransform: 'uppercase',
    fontWeight: 500,
    lineHeight: 1,
  },
};
