/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { BackgroundImage, PlusIconSevenStyles, bubble, PageWrap } from './allStepsStyles';
import { PlusIcon7 } from './svg/plusIcons';
import Step from './Step';
import { DocumentGathering } from './svg/largeIllustrations';
import { fadeIn, fadeOut } from './fade';
import { NumeroFour } from './svg/NumberCircle';
import { StandardImage } from '../ui/Image.jsx';

export default function StepOne({ refFour }) {
  const sectionRefFour = useRef(null);

  const intersection = useIntersection(sectionRefFour, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });
  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInFour') : fadeOut('.fadeInFour');
  return (
    <PageWrap ref={sectionRefFour}>
      <BackgroundImage>
        <StandardImage className='fadeInFour' image='specs/background4_zq15lr.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroFour />}
        blue
        currentRef={refFour}
        illustraion={<DocumentGathering />}
        header='Document Gathering'
        subText='After signing the proposal, we get in touch with you (or your support staff) to gather any additional documentation that we will need to perform the study. These may include depreciation schedules, more detailed property descriptions, construction schedules and others. You have a business to run and get back to so we’ll make this an easy and painless experience.'
      />
      <PlusIconSevenStyles>
        <PlusIcon7 />
      </PlusIconSevenStyles>
    </PageWrap>
  );
}
