import React from "react";
import styled from "@emotion/styled";

export const Root = styled.div`
  background-color: #fff;
  max-width: 770px;
  margin: 16px auto 40px;
  position: relative;
  .form-header_wrap {
    color: #0827c7;
    font-family: Syne;
    font-size: 20px;
    line-height: 1.3;
    max-width: 556px;
    position: relative;
    width: 100%;
    z-index: 2;
    margin: 0 auto 16px;
  }
  .sub-text {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    margin-top: 40px;
    margin-bottom: -20px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    .form-header_wrap {
      display: flex;
      font-size: 30px;
      justify-content: center;
      margin-bottom: 20px;
      max-width: 556px;
      text-align: center;
    }
    .sub-text {
      font-size: 20px;
      line-height: 1.5;
    }
  }
`;

export default function Banner({ stepOne }) {
  return (
    <Root>
      <div className="form-header_wrap">Cost Segregation No-Cost Feasibility Analysis Request Form</div>
      {stepOne && <div className="sub-text">Let's get started:</div>}
    </Root>
  );
}
