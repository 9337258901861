import React, { useEffect, useState } from 'react';
import { getDistance } from 'geolib';
import { locations } from './deals';
import { useHistory } from 'react-router-dom';

const AddressContext = React.createContext({});

function AddressContextProvider({ children }) {
  let history = useHistory();
  const initialAddress = history ? history.location.pathname.split('/')[3] : null;
  const initialFilter = history
    ? history.location.pathname.split('/')[5]
    : window.innerWidth < 767
    ? 'All'
    : 'All Property Types';
  const [state, setState] = useState({
    address: initialAddress,
    addressSearched: initialAddress,
    previousCenter: {},
    zoom: null,
    nearbyDeals: [],
    defaultCenter: {
      lat: 37.0902,
      lng: -95.7129,
    },
    center: {
      lat: 37.0902,
      lng: -95.7129,
    },
    featuredDeal: {},
    filter: initialFilter,
    filteredLocations: [...locations],
    miles: 0,
    map: null,
  });

  function getZoom(miles) {
    if (miles < 20) {
      return 10;
    } else if (miles < 50) {
      return 9;
    } else if (miles < 100) {
      return 7;
    } else if (miles < 250) {
      return 6;
    } else if (miles < 500) {
      return 5;
    } else {
      return 4;
    }
  }

  function getNearbyDeals() {
    let metersLimit = 16093.4;
    let deals = [];
    const filteredLocations = !['All Property Types', 'All'].includes(state.filter)
      ? locations.filter((l) => l.propertyType === state.filter)
      : [...locations];
    while (deals.length < 5) {
      deals = [];
      // eslint-disable-next-line no-loop-func
      filteredLocations.forEach((l) => {
        const distance = getDistance(
          {
            latitude: state.center.lat,
            longitude: state.center.lng,
          },
          {
            latitude: l.lat,
            longitude: l.lng,
          },
        );
        if (distance <= metersLimit) {
          deals.push(l);
        }
      });
      metersLimit += 16093.4;
    }
    const featuredDeal =
      deals.length > 0
        ? deals.reduce((prev, current) =>
            prev.depreciableAssets > current.depreciableAssets ? prev : current,
          )
        : {};
    const miles = parseInt(metersLimit * 0.000621371);
    setState((state) => ({
      ...state,
      nearbyDeals: deals,
      featuredDeal,
      filteredLocations: filteredLocations,
      miles: miles,
      zoom: state.addressSearched ? getZoom(miles) : window.innerWidth > 500 ? 5 : 3,
    }));
  }

  useEffect(() => {
    getNearbyDeals();
  }, [state.center, state.filter]);

  useEffect(() => {
    if (window.innerWidth > 500 && state.center.lat !== 37.0902 && state.center.lng !== -95.7129) {
      state.map && state.map.panBy(-250, 0);
    }
  }, [state.center, state.map, state.zoom]);

  const refresh = (filter) => {
    setState((state) => ({
      ...state,
      address: '',
      addressSearched: '',
      previousCenter: {},
      zoom: null,
      nearbyDeals: [],
      defaultCenter: {
        lat: 37.0902,
        lng: -95.7129,
      },
      center: {
        lat: 37.0902,
        lng: -95.7129,
      },
      featuredDeal: {},
      filter: filter || 'All Property Types',
      filteredLocations: [...locations],
      miles: 0,
    }));
  };

  const search = () => {
    console.log(history);
    if (state.address === '') {
      refresh(state.filter);
    } else {
      if (window.google && window.google.maps.Geocoder) {
        console.log(history);
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: state.address }, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            var lat = results[0].geometry.location.lat();
            var lng = results[0].geometry.location.lng();
            if (state.previousCenter.lat !== lat && state.previousCenter.lng !== lng) {
              setState((state) => ({
                ...state,
                center: { lat, lng },
                zoom: 10,
                addressSearched: results[0].formatted_address.replace(', USA', ''),
                previousCenter: { lat, lng },
              }));
            }
          }
        });
      }
    }
  };

  return (
    <AddressContext.Provider
      value={{
        address: state.address,
        locations: state.filteredLocations
          .filter((l) => l.lat !== '')
          .map((l) => ({ lat: l.lat, lng: l.lng })),
        addressSearched: state.addressSearched,
        setAddress: (address) => setState((state) => ({ ...state, address: address })),
        previousCenter: state.previousCenter,
        setFilter: (type) => setState((state) => ({ ...state, filter: type })),
        filter: state.filter,
        defaultCenter: state.defaultCenter,
        center: state.center,
        zoom: state.zoom,
        search: search,
        refresh: refresh,
        nearbyDeals: state.nearbyDeals,
        getNearbyDeals: getNearbyDeals,
        featuredDeal: state.featuredDeal,
        miles: state.miles,
        setMap: (map) => setState((state) => ({ ...state, map: map })),
        map: state.map,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export { AddressContextProvider, AddressContext };
