/** @jsx jsx */
import { jsx } from '@emotion/core';
import CallButton from '../ui/CallButton';
import {
  wrapper,
  header,
  innerHeader,
  motifStyles,
  outerWrap,
  buttonsWrap,
  arrowStyles,
} from './bottomSectionStyles';
import { Motif, DownArrowBlue } from '../ui/icons';

export default function LastSection() {
  const buttonText = <div>Schedule Call</div>;
  return (
    <div css={wrapper}>
      <div css={motifStyles}>
        <Motif />
      </div>
      <div css={arrowStyles}>
        <DownArrowBlue />
      </div>
      <div css={outerWrap}>
        <div>
          <h1 className='syne' css={header}>
            Ready to improve your cash flow?
          </h1>
          <p css={innerHeader}>
            Find out how much with a no-cost feasibility analysis today.
          </p>
        </div>
        <div css={buttonsWrap}>
          <CallButton text={buttonText} to='/contact-us' />
        </div>
      </div>
    </div>
  );
}
