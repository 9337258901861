export const wrapper = {
  alignItems: 'center',
  backgroundColor: '#f4f4f4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '24px 50px',
  paddingRight: 18,
  position: 'relative',
  '@media(min-width: 768px)': {
    paddingRight: 50,
    paddingBottom: 38
  },
  '@media(min-width: 1024px)': {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 228
  },
  '@media(min-width: 1260px)': {
    height: 352,
    justifyContent: 'center'
  },
  '@media(min-width: 1440px)': {
    height: 316
  }
};
export const outerWrap = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: 32,
  position: 'relative',
  textAlign: 'left',
  zIndex: 50,
  '@media(min-width: 768px)': {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    width: '100%'
  },
  '@media(min-width: 1024px)': {
    paddingLeft: 32,
    alignItems: 'center'
  },
  '@media(min-width: 1440px)': {
    justifyContent: 'flex-start'
  }
};
export const header = {
  fontSize: 29,
  marginTop: 40,
  textAlign: 'left',
  '@media(min-width: 768px)': {
    fontSize: 32,
    textAlign: 'left',
    width: 320
  },
  '@media(min-width: 1024px)': {
    fontSize: 32,
    maxWidth: 385,
    paddingLeft: 0,
    marginTop: 64
  },
  '@media(min-width: 1260px)': {
    marginLeft: 75,
    maxWidth: 387
  },
  '@media(min-width: 1440px)': {
    marginLeft: 0
  }
};
export const innerHeader = {
  fontSize: 18,
  lineHeight: 1.71,
  marginTop: 20,
  maxWidth: 282,
  textAlign: 'left',
  '@media(min-width: 768px)': {
    paddingTop: 5
  },
  '@media(min-width: 1024px)': {
    maxWidth: 560,
    width: 490,
    paddingLeft: 0,
    paddingTop: 12
  },
  '@media(min-width: 1260px)': {
    fontSize: 18,
    marginLeft: 75,
    marginTop: 16
  },
  '@media(min-width: 1440px)': {
    marginLeft: 0
  }
};
export const motifStyles = {
  svg: { transform: 'rotate(180deg)' },
  height: '100%',
  left: 0,
  maxWidth: 326,
  path: { fillOpacity: '1' },
  position: 'absolute',
  top: 60,
  width: '100%',
  '@media(min-width: 768px)': {
    left: 14,
    maxWidth: 287,
    top: 34
  },
  '@media(min-width: 1024px)': {
    left: 104,
    top: -1
  },
  '@media(min-width: 1260px)': {
    left: 170,
    maxWidth: 507
  },
  '@media(min-width: 1440px)': {
    left: 150,
    width: 507,
    height: 420
  },
  '@media(min-width: 1920px)': {
    left: 226
  }
};
export const buttonsWrap = {
  a: { width: 270, height: 64, fontSize: 14 },
  marginTop: 32,
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'center',
    // width: 398,
    marginTop: 0
  },
  '@media(min-width: 1024px)': {
    marginTop: 30,
    width: '100%'
  },
  '@media(min-width: 1260px)': {
    a: {
      paddingRight: 40,
      width: 282
    }
  },
  '@media(min-width: 1440px)': {
    marginLeft: 80,
    paddingLeft: 0,
    width: 270
  },
  '@media(min-width: 1920px)': {
    marginLeft: 130
  }
};
