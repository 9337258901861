import React from 'react';
import styled from '@emotion/styled';

export const Root = styled.div`
  background-color: #fff;
  margin: 8px auto 32px;
  position: relative;
  max-width: 1126px;
  @media (min-width: 1024px) {
    margin-bottom: 60px;
    .form-header_wrap {
      display: flex;
      font-size: 30px;
      justify-content: center;
      margin-bottom: 20px;
      max-width: 556px;
      text-align: center;
      margin-top: 0;
    }
  }
`;

const TopSection = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  .form-header_wrap {
    color: #0827c7;
    font-family: Syne;
    font-size: 30px;
    line-height: 1.3;
    margin: 40px auto 16px;
    max-width: 230px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
  }
  img {
    width: 100%;
    max-width: 280px;
    height: auto;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    gap: 80px;
    align-items: center;
    flex-direction: row;
    .form-header_wrap {
      display: flex;
      font-size: 58px;
      justify-content: center;
      margin-bottom: 20px;
      max-width: 556px;
      max-width: 100%;
      text-align: left;
    }
      img {
        width: 100%;
        height: auto;
        max-width: unset;
    }
  }
`;

export default function Banner() {
  return (
    <Root>
      <TopSection>
        <div className='form-header_wrap'>Questionnaire Form 3115</div>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/header-illus_tyzf3l.png'
          alt='header illustration'
        />
      </TopSection>
    </Root>
  );
}
