import gsap from 'gsap';

export const fadeIn = (element) => {
  gsap.to(element, {
    opacity: 1,
    y: -0,
    delay: 0.1,
    ease: 'power4.out',
    duration: 2,
    transform: 'scale(1)',
  });
};
export const fadeOut = (element) => {
  gsap.to(element, {
    opacity: 0,
    duration: 0.5,
    y: -10,
    transform: 'scale(0.60)',
    ease: 'power4.out',
  });
};
