export const mainWrap = {
  padding: '24px',
  paddingTop: 74,
  position: 'relative',
  textAlign: 'center',
  zIndex: '5',
  '@media(min-width: 768px)': {
    alignItems: 'center',
    display: 'flex',
    margin: 'auto',
    maxWidth: 768,
    padding: 0,
  },
  '@media(min-width: 1024px)': {
    height: 297,
    maxWidth: 948,
  },
  '@media(min-width: 1440px)': {
    height: 347,
    maxWidth: 1100,
    '&:before': {
      width: 414,
    },
  },
};

export const testimonialTextWrap = {
  lineHeight: 2,
  position: 'relative',
  '&:before': {
    backgroundColor: '#fbfbfb',
    bottom: -84,
    content: '""',
    left: -16,
    position: 'absolute',
    right: -16,
    top: -50,
    zIndex: '-1',
  },
  '@media(min-width: 768px)': {
    paddingBottom: 45,
    paddingLeft: 80,
    paddingTop: 55,
    width: 'calc(100% - 155px)',
    '> *': { maxWidth: 433 },
    '&:before': {
      bottom: 0,
      left: 0,
      right: 170,
      top: 0,
    },
  },
  '@media(min-width: 1024px)': {
    padding: '50px 0 50px 55px',
    width: 'calc(100% - 315px)',
    '> *': { maxWidth: 472 },
  },
  '@media(min-width: 1440px)': {
    padding: '55px 0 56px 94px',
    width: 'calc(100% - 355px)',
    '> *': { maxWidth: 562 },
    '&:before': {
      right: 300,
    },
  },
};

export const quoteIcon = {
  color: '#efefef',
  display: 'block',
  fontFamily: 'Anton, sans-serif',
  fontSize: 256,
  left: '50%',
  lineHeight: '0.23',
  position: 'absolute',
  top: -164,
  transform: 'translate(-50%)',
  '@media(min-width: 768px)': {
    left: 24,
    top: -110,
    transform: 'translate(0)',
  },
  '@media(min-width: 1024px)': {
    top: -112,
  },
};

export const innerContent = {
  padding: '0 8px',
  '@media(min-width: 768px)': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 48,
    width: '100%',
    '& .profile-section': {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      span: { lineHeight: '1.71' },
    },
  },
  '@media(min-width: 1024px)': {
    '& .profile-section': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      textAlign: 'right',
      img: { marginLeft: 32 },
      p: { fontSize: 16, marginBottom: 8 },
      span: { fontSize: 14 },
    },
  },
  '@media(min-width: 1440px)': {
    '& .profile-section': {
      img: {
        width: 144,
        height: 144,
      },
    },
  },
};

export const exeprtStyle = {
  fontWeight: 600,
  fontSize: 18,
  marginBottom: 10,
  marginTop: 25,
  '@media(min-width: 768px)': {
    marginTop: 0,
  },
  '@media(min-width: 1024px)': {
    marginBottom: 16,
  },
  '@media(min-width: 1440px)': {
    fontSize: 24,
    lineHeight: 2,
  },
};

export const profileImageStyle = {
  width: 104,
  height: 104,
  margin: '32px auto 20px',
  borderRadius: '50%',
  '@media(min-width: 768px)': {
    marginTop: 0,
  },
};

export const arrowStyle ={
    '@media(max-width: 1919px)': {
      display: 'none !important'
    },
}
