export const mainWrap = {
  color: 'white',
  textAlign: 'center',
  position: 'relative',
  '@media(min-width: 768px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
};
export const motifStyles = {
  width: 142,
  position: 'absolute',
  top: 0,
  right: 24,
  path: { fill: '#0827c7' },
  zIndex: 50,
  '@media(min-width: 1440px)': {
    width: 358,
  },
};
export const mapSection = {
  backgroundColor: '#0827c7',
  padding: '32px 0px',
  position: 'relative',
  span: {
    fontSize: 96,
    lineHeight: '1',
    '@media(min-width: 1260px)': {
      fontSize: 128,
    },
  },
  p: { fontSize: 16, marginTop: 16 },
  '& .fifty-states': {
    position: 'absolute',
    top: 25,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '@media(min-width: 768px)': {
    width: '50%',
    height: 447,
    display: 'inline-flex',
    alignItems: 'center',
    '& .fifty-states': { top: 47 },
  },
  '@media(min-width: 1260px)': {
    '& .fifty-states': { top: 130 },
    height: 500,
    img: {
      paddingRight: 20,
      maxHeight: 560,
    },
  },
  '@media(min-width: 1440px)': {
    padding: '20px 0',
    '& .fifty-states': { top: 200 },
    height: 600,
  },
};

export const textSection = {
  backgroundColor: '#203fde',
  // height: 335,
  padding: '33px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  span: {
    textAlign: 'left',
    fontSize: 18,
    lineHeight: '1.56',
    display: 'block',
    '@media(min-width: 1024px)': {
      maxWidth: 406,
      marginTop: 30,
    },
    '@media(min-width: 1260px)': {
      fontSize: 20,
      maxWidth: 519,
      paddingLeft: 50,
      marginTop: 180,
    },
    '@media(min-width: 1440px)': {
      maxWidth: 600,
      marginTop: 220,
      fontSize: 24,
    },
  },
  '@media(min-width: 768px)': {
    width: '50%',
    height: 447,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '@media(min-width: 1024px)': {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  '@media(min-width: 1260px)': {
    height: 500,
  },
  '@media(min-width: 1440px)': {
    height: 600,
  },
};
export const header = {
  marginBottom: 33,
  fontSize: 24,
  zIndex: 50,
  position: 'relative',
  '@media(min-width: 768px)': {
    maxWidth: 280,
    textAlign: 'center',
    alignSelf: 'center',
  },
  '@media(min-width: 1024px)': {
    fontSize: 32,
    maxWidth: 362,
    textAlign: 'left',
  },
  '@media(min-width: 1260px)': {
    fontSize: 40,
    maxWidth: 563,
    marginTop: 50,
    position: 'absolute',
    left: -64,
  },
  '@media(min-width: 1440px)': {
    fontSize: 48,
    left: -75,
  },
};
