/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Root, AssetItemWrap, itemsWrap } from './topAssetsStyles';

export default function SectionThree({ assets }) {
  return (
    <Root>
      <div className='more-section-header'>Top Assets</div>
      <div css={itemsWrap}>
        {assets.map((asset) => (
          <AssetItem asset={asset} />
        ))}
      </div>
    </Root>
  );
}

function AssetItem({ asset }) {
  const { icon, title, value } = asset;
  return (
    <AssetItemWrap>
      <div className='green-circle'>
        {icon || (
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M16 0H2C0.900024 0 0 0.899994 0 2V16C0 17.1 0.900024 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.899994 17.1 0 16 0ZM16 16H2V11.42L2.98999 12.41L6.98999 8.41L10.99 12.41L14.99 8.42001L16 9.42999V16ZM14.99 5.57999L16 6.59V2H2V8.59L2.98999 9.59L6.98999 5.59L10.99 9.59L14.99 5.57999Z'
              fill='#fff'
            />
          </svg>
        )}
      </div>
      <div className='text-wrap'>
        <div>{title}</div>
        <div>{value}</div>
      </div>
    </AssetItemWrap>
  );
}
