import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { toBase64 } from '../../utils';

const Root = styled.div`
  border: 1px solid #f2f2f2;
  transition: border 0.15s ease-in-out;
  cursor: pointer;
  .upload_button {
    margin-bottom: 12px;
    span {
      color: #0927c7;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
      font-family: Ubuntu, 'Ubuntu', sans-serif;
      border-bottom: 1px solid blue;
      display: inline-block;
    }
  }
  .subtitle {
    color: #757575;
    font-size: 13px;
  }
  .helperText {
    color: #c4c4c4;
    text-align: center;
    font-size: 15px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 1.46em;
    margin-bottom: 14px;
    max-width: 420px;
  }
`;

const baseStyle = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '1.63964px solid #E1E3E6',
  borderRadius: 13,
  color: '#bdbdbd',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  outline: 'none',
  padding: '24px',
  textAlign: 'center',
  minHeight: '96px',
  transition: 'border .24s ease-in-out',
  '&:hover': {
    border: '1.63964px solid #2196f3',
  },
};

const focusedStyle = {
  border: '1.63964px solid #2196f3',
};

const acceptStyle = {
  border: '1.63964px solid #00e676',
};

const rejectStyle = {
  border: '1.63964px solid #ff1744',
};

export default function DropzoneFile({
  helperText,
  files,
  setFiles,
  maxFiles,
  isSingleFile,
}) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const newFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          let base64 = await toBase64(file);
          return { fileName: file.path, fileId: base64 };
        })
      );
      if (isSingleFile) {
        setFiles(newFiles[0]);
      } else {
        const allFilesUpToMax = files.concat(newFiles).slice(0, maxFiles ?? 1);
        setFiles([...allFilesUpToMax]);
      }
    },
    [files] // [files, setFiles, maxFiles, isSingleFile],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        'application/pdf': ['.pdf'],
      },
      maxFiles: isSingleFile ? 1 : maxFiles,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Root>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div>
          {helperText && <div className='helperText'>{helperText}</div>}
          <div className='upload_button'>
            <span>Upload</span>
          </div>
          <p className='subtitle'>{`or drag${
            maxFiles !== 1 && !isSingleFile ? ` up to ${maxFiles}` : ''
          } file${
            maxFiles !== 1 && !isSingleFile ? 's' : ''
          } in pdf format here`}</p>
        </div>
      </div>
    </Root>
  );
}
