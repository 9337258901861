/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Root,
  Header,
  innerWrap,
  TopicBottom,
  iconStyles,
  Sidebar,
  TopicStyles,
} from './topicBottomSectionStyles';

import { cSBasics, cSPricing, cSAccountants, cSTaxQuestions } from './questionsList';
import { CoinsIconFaq, RefundIcon, PeopleIcon, PaperIcon, MinusIcon, BlueMotif } from '../ui/icons';
import QuestionCard from './QuestionCard';
const clickSections = {
  costSegBasics: 'CostSegBasics',
  costSegPricingandTiming: 'CostSegPricingandTiming',
  accountantsandCostSeg: 'AccountantsandCostSeg',
  costSegandTaxQuestions: 'CostSegandTaxQuestions',
};

export default function TopicBottomSection({ onSectionClick }) {
  const [refOne, oneInView] = useInView({ threshold: 0.4 });
  const [refTwo, twoInView] = useInView({ threshold: 0.4 });
  const [refThree, threeInView] = useInView({ threshold: 0.4 });
  const [refFour, fourInView] = useInView({ threshold: 0.4 });
  const [current, setCurrent] = useState('CostSegBasics');
  const currentSection = (str) => {
    onSectionClick(str);
    setCurrent(str);
  };

  return (
    <Root>
      <Global styles={{ 'html, body': { overflow: 'unset' } }} />
      <Sidebar>
        <Header>Topics</Header>
        <div css={innerWrap}>
          <TopicStyles
            onClick={() => currentSection(clickSections.costSegBasics)}
            inView={oneInView}
            current={'CostSegBasics' === current}
          >
            Cost Seg Basics
          </TopicStyles>
          <TopicStyles
            onClick={() => currentSection(clickSections.costSegPricingandTiming)}
            inView={twoInView}
            current={'CostSegPricingandTiming' === current}
          >
            Cost Seg Pricing and Timing
          </TopicStyles>
          <TopicStyles
            onClick={() => currentSection(clickSections.accountantsandCostSeg)}
            inView={threeInView}
            current={'AccountantsandCostSeg' === current}
          >
            Accountants and Cost Seg
          </TopicStyles>
          <TopicStyles
            onClick={() => currentSection(clickSections.costSegandTaxQuestions)}
            inView={fourInView}
            current={'CostSegandTaxQuestions' === current}
          >
            Cost Seg and Tax Questions
          </TopicStyles>
        </div>
      </Sidebar>
      <div
        css={{
          display: 'inline-block',
          verticalAlign: 'top',
          paddingLeft: 64,
          width: 'calc(100% - 290px)',
          '@media(min-width: 1440px)': {
            paddingLeft: 130,
          },
        }}
      >
        <div css={{ marginBottom: 34 }} ref={refOne}>
          <TopicExpanded icon={<CoinsIconFaq />} topic='Cost Seg Basics' color='#0727c7' />
          {cSBasics.map((i, index) => (
            <QuestionCard question={i.question} par={i.answer} key={index} />
          ))}
        </div>
        <div css={{ marginBottom: 34 }} ref={refTwo}>
          <TopicExpanded
            icon={<RefundIcon />}
            topic='Cost Seg Pricing and Timing'
            color='#55dcac'
          />
          {cSPricing.map((i, index) => (
            <QuestionCard question={i.question} par={i.answer} key={index} />
          ))}
        </div>
        <div css={{ marginBottom: 34 }} ref={refThree}>
          <TopicExpanded icon={<PeopleIcon />} topic='Accountants and Cost Seg' color='#f74720' />
          {cSAccountants.map((i, index) => (
            <QuestionCard question={i.question} par={i.answer} key={index} />
          ))}
        </div>
        <div css={{ marginBottom: 34 }} ref={refFour}>
          <TopicExpanded icon={<PaperIcon />} topic='Cost Seg and Tax Questions' color='#fcbd2c' />
          {cSTaxQuestions.map((i, index) => (
            <QuestionCard question={i.question} par={i.answer} key={index} />
          ))}
        </div>
      </div>
    </Root>
  );
}
function Topic({ topic, onClick, inView }) {
  return <TopicStyles onClick={onClick}>{topic}</TopicStyles>;
}

function TopicExpanded({ icon, topic, color }) {
  return (
    <div
      id={topic.split(' ').join('')}
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 32,
        paddingTop: 30,
      }}
    >
      <div css={[iconStyles, { backgroundColor: color }]}>{icon}</div>
      <TopicBottom>{topic}</TopicBottom>
    </div>
  );
}
