/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { BackgroundImage, PlusIconSixStyles, bubble, PageWrap } from './allStepsStyles';
import { PlusIcon6 } from './svg/plusIcons';
import Step from './Step';
import { Proposal } from './svg/largeIllustrations';
import { fadeIn, fadeOut } from './fade';
import { NumeroThree } from './svg/NumberCircle';
import { StandardImage } from '../ui/Image.jsx';

export default function StepOne({ threeInView, refThree }) {
  const sectionRefThree = useRef(null);

  const intersection = useIntersection(sectionRefThree, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInThree') : fadeOut('.fadeInThree');
  return (
    <PageWrap ref={sectionRefThree}>
      <BackgroundImage>
        <StandardImage className='fadeInThree' image='specs/background3_dgusod.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroThree />}
        currentRef={refThree}
        illustraion={<Proposal />}
        header='Proposal'
        subText='Together with the feasibility analysis, you’ll receive a formal proposal to have SPECS perform a full cost segregation study. This includes the pricing and the timing to have the study completed.'
      />
      <PlusIconSixStyles>
        <PlusIcon6 />
      </PlusIconSixStyles>
    </PageWrap>
  );
}
