/** @jsx jsx */
import { jsx } from '@emotion/core';
import { categories } from './Header';
import { useHistory, useLocation } from 'react-router-dom';

import { Root, Button } from './paginationStyles';

const pathToCategory = {
  'golf-course': 'Golf Course',
  'multi-family': 'Multifamily',
  'nursing-home': 'Nursing Home',
  office: 'Office',
  retail: 'Retail',
  medical: 'Medical',
  warehouse: 'Warehouse',
  'distribution-center': 'Distribution Center',
  storage: 'Storage',
  restaurant: 'Restaurant',
  'auto-dealer': 'Auto Dealership',
  bank: 'Bank',
};

const categoryToPath = {
  'Golf Course': 'golf-course',
  Multifamily: 'multi-family',
  'Nursing Home': 'nursing-home',
  Office: 'office',
  Retail: 'retail',
  Medical: 'medical',
  Warehouse: 'warehouse',
  'Distribution Center': 'distribution-center',
  Storage: 'storage',
  Restaurant: 'restaurant',
  'Auto Dealership': 'auto-dealer',
  Bank: 'bank',
};

export default function Pagination() {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[2];
  const category = pathToCategory[currentPath];
  const categoriesArr = Object.values(categories);
  const currentIndex = categoriesArr.findIndex((cat) => cat === category);
  const prevCategory =
    categoriesArr[currentIndex - 1] ?? categoriesArr[Object.values(categories).length - 1];
  const nextCategory = categoriesArr[currentIndex + 1] ?? categoriesArr[0];

  const history = useHistory();

  return (
    <Root>
      <Button
        show={currentIndex > 0}
        onClick={() => {
          history.push(`/case-studies/${categoryToPath[prevCategory]}`);
          window.scrollTo(0, 500);
        }}
      >
        <div>
          <Arrow />
          <span>{prevCategory}</span>
          <span></span>
        </div>
        <span className='prev-next'>Prev</span>
      </Button>
      <Button
        show={currentIndex < categoriesArr.length - 1}
        onClick={() => {
          history.push(`/case-studies/${categoryToPath[nextCategory]}`);
          window.scrollTo(0, 500);
        }}
      >
        <span className='prev-next'>Next</span>
        <div>
          <span></span>
          <span>{nextCategory}</span>
          <Arrow />
        </div>
      </Button>
    </Root>
  );
}

function Arrow() {
  return (
    <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7843 0.0885912C10.5894 -0.0464049 10.32 -0.0271197 10.1464 0.146447L10.0886 0.215695C9.9536 0.410563 9.97288 0.679987 10.1464 0.853553L14.293 5H0.5L0.410124 5.00806C0.176875 5.05039 0 5.25454 0 5.5C0 5.77614 0.223858 6 0.5 6H14.293L10.1464 10.1464L10.0886 10.2157C9.9536 10.4106 9.97288 10.68 10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536L15.8536 5.85355C15.8747 5.83239 15.894 5.80933 15.9111 5.78466L15.9114 5.78431C15.932 5.75462 15.949 5.7232 15.9624 5.69065C15.9847 5.63641 15.9971 5.57904 15.9995 5.52132C15.9999 5.51422 16 5.50711 16 5.5M15.9622 5.30892C15.9378 5.24983 15.9016 5.19447 15.8536 5.14645L10.8536 0.146447L10.7843 0.0885912M15.9622 5.30892C15.9855 5.3653 15.9988 5.42688 15.9999 5.49144L15.9622 5.30892Z'
        fill='white'
      />
    </svg>
  );
}
