import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-image: linear-gradient(
    34deg,
    rgba(229, 229, 250, 0) 43%,
    #c6c6f3 129%
  );
  position: relative;
  padding: 30px 65px 42px 65px;
  background-color: #f2f2f2;
  @media (min-width: 768px) {
    padding: 30px 98px 42px 132px;
  }
  @media (min-width: 1024px) {
    margin: 185px 107px 0px 96px;
    padding: 37px 72px 55px 77px;
  }
  @media (min-width: 1440px) {
    margin: 185px 130px 0px 150px;
    padding: 37px 72px 72px 92px;
  }
  @media (min-width: 1920px) {
    margin: 200px 179px 0px 212px;
  }
`;
export const Header = styled.h1`
  color: #0827c7;
  position: absolute;
  top: -12px;
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 32px;
    top: -20px;
  }
  @media (min-width: 1440px) {
    font-size: 48px;
    top: -25px;
  }
`;
export const SubHeader = styled.h2`
  color: #55dcac;
  font-size: 22px;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 25px;
  }
  @media (min-width: 1440px) {
    font-size: 30px;
  }
`;
export const imageStyles = {
  paddingTop: 11,
  '@media (min-width: 768px)': {
    maxWidth: 240,
    order: 2,
    display: 'inline'
  },
  '@media (min-width: 1024px)': {
    maxWidth: 290
  },
  '@media (min-width: 1440px)': {
    maxWidth: 450,
    order: 3,
    marginTop: -80
  },
  '@media (min-width: 1920px)': {
    maxWidth: 580,
    marginTop: -140
  }
};

export const ContentWrap = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (min-width: 1440px) {
    justify-content: space-between;
  }
`;
export const ParagraphOne = styled.div`
  line-height: 2;
  padding-top: 45px;
  order: 1;
  @media (min-width: 768px) {
    display: inline;
    width: 230px;
    margin-right: 60px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    width: 315px;
  }
  @media (min-width: 1440px) {
    width: 524px;
    padding-top: 61px;
  }
  @media (min-width: 1920px) {
    width: 624px;
  }
`;

export const ParagraphTwo = styled.div`
  padding-top: 36px;
  line-height: 2;
  @media (min-width: 768px) {
    order: 3;
    width: 630px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
  }
  @media (min-width: 1440px) {
    order: 2;
    width: 524px;
  }
  @media (min-width: 1920px) {
    width: 700px;
  }
`;
