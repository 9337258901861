import styled from '@emotion/styled';

export const Root = styled.div`
  padding: 32px 24px 40px;
  font-weight: 300;
  margin: 0 auto;
  position: relative;
  .ring-a-ding {
    display: block;
    left: 50%;
    height: auto;
    max-width: 71%;
    max-width: 282px;
    position: absolute;
    top: 0px;
    transform: translateX(-50%);
    width: 100%;
    z-index: 0;
  }
  .story-author {
    font-weight: 500;
    margin-top: 6px;
    color: #57e5b2;
  }
  .building-illustration {
    max-width: 71.5%;
    width: 100%;
    margin: 0 auto;
    display: block;
    height: auto;
    position: relative;
    z-index: 8;
  }
  .section-title {
    font-family: 'Syne';
    font-size: 32px;
    font-weight: 300;
    line-height: 1.4;
    margin-top: 34px;
  }
  .section-sub-title {
    color: #0827c7;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 16px;
  }
  .text-section {
    position: relative;
    z-index: 8;
  }
  .section-text {
    font-size: 16px;
    line-height: 32px;
    margin-top: 16px;
  }
  .plus-bunch {
    position: absolute;
    display: none;
  }
  @media (min-width: 540px) {
    .section-title br {
      display: none;
    }
  }
  @media (min-width: 768px) {
    padding: 84px 32px 40px;
    .ring-a-ding {
      max-width: 544px;
    }
    .section-title {
      font-size: 40px;
      margin-top: 44px;
    }
    .section-sub-title {
      font-size: 24px;
      margin-top: 40px;
    }
    .building-illustration {
      max-width: 369px;
    }
    .plus-bunch {
      display: block;
      right: 54px;
      top: -145px;
      width: 216px;
    }
  }
  @media (min-width: 1024px) {
    padding: 96px 32px;
    max-width: 1024px;
    display: flex;
    flex-direction: row-reverse;
    .building-illustration {
      max-width: 385px;
    }
    .text-section {
      max-width: 472px;
    }
    .section-title {
      margin-top: 0;
    }
    .section-text {
      margin-top: 20px;
    }
    .ring-a-ding {
      max-width: 748px;
    }
    .plus-bunch {
      width: 234px;
      right: 90px;
      bottom: -178px;
    }
  }
  @media (min-width: 1440px) {
    padding: 139px 118px 80px;
    max-width: 1440px;
    .ring-a-ding {
      max-width: 1061px;
    }
    .text-section {
      max-width: 588px;
    }
    .building-illustration {
      max-width: 510px;
      margin-top: 104px;
    }
    .plus-bunch {
      right: 306px;
      bottom: -146px;
    }
  }
  @media (min-width: 1920px) {
    max-width: 1450px;
    .section-sub-title {
      margin-top: 96px;
    }
    .building-illustration {
      max-width: 520px;
    }
  }
`;
