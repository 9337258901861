export const wrapS = {
  alignItems: "center",
  backgroundColor: "#fff",
  display: "flex",
  height: 64,
  img: { maxWidth: 90 },
  justifyContent: "space-between",
  padding: "0 24px",
  position: "relative",
  zIndex: "500",
  "@media(min-width: 1023px)": {
    display: "none",
  },
};

export const menuS = {
  backgroundColor: "#fbfbfb",
  bottom: 0,
  height: "calc(100vh - 64px)",
  left: 0,
  position: "absolute",
  right: 0,
  top: 64,
  zIndex: "10000",
  " div": {
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    // backgroundColor: "white",
  },
  ".wrapper": {
    width: "100%",
    backgroundColor: "white",
    padding: "26px",
  },
};
export const icon = {
  cursor: "pointer",
};
export const sectionWrap = {
  backgroundColor: "#fbfbfb",
  borderBottom: "1px solid #f2f2f2",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  padding: "16px 24px",
  position: "relative",
  width: "100%",
};
