import React, { useState, useEffect } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';

import {
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  RadioGroup,
  Select,
  FormLabel,
} from '@mui/material';
import {
  IndividualStepWrap,
  StepHeader,
  SubmitButton,
  PreviousPropertyWrap,
  PreviousNextWrap,
  RightArrow,
  dateFieldHacks,
  DeleteIcon,
  UploadedFilesWrap,
} from './styles';
import { allStates } from './mockData';
import DropzoneFile from './Dropzone';
import AddressAutocomplete from './AddressAutocomplete';

const newPropertyTemplate = {
  name: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zip: null,
  placedInService: null,
  purchasePrice: null,
  is1031Exchange: null,
  newBasis: null,
  depreciationSchedule: null,
};

export default function PropertyInformation({ request, setRequest, nextStep, prevStep }) {
  const [newProperty, setNewProperty] = useState(newPropertyTemplate);
  const [files, setFiles] = useState([]);
  const [address, setAddress] = useState();

  const onNewPropertyFieldUpdate = (event) => {
    let { name, value } = event.target;
    if (value === '') {
      value = null;
    }
    setNewProperty({ ...newProperty, [name]: value });
  };

  useEffect(() => {
    setNewProperty((prevProperty) => ({
      ...prevProperty,
      depreciationSchedule: files.length >= 1 ? files[0] : null,
    }));
  }, [files]);

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (address == null) return;
    setNewProperty({
      ...newProperty,
      addressLine1: address.addressLine1,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });
  }, [address]);

  const on1031ExchangeUpdate = (event) => {
    const is1031Exchange = event.target.value === 'true';
    setNewProperty({
      ...newProperty,
      is1031Exchange: is1031Exchange,
    });
  };

  const onDateUpdate = (newDate) => {
    if (new Date(newDate) instanceof Date && !isNaN(newDate))
      setNewProperty({
        ...newProperty,
        placedInService: new Date(newDate),
      });
  };

  const onDeleteProperty = (index) => {
    setRequest((draft) => {
      draft.properties = draft.properties.filter((_, i) => i !== index);
    });
  };

  const isPreviousTaxYear = (pisDate) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const oct15ThisYear = new Date(currentYear, 9, 15);
  
    // after Oct 15, the previous year is an old tax year
    if (today > oct15ThisYear && pisDate.getFullYear() < currentYear) {
      return true;
    }
    // until Oct 15, the year before the previous year is an old tax year
    if (pisDate.getFullYear() < currentYear - 1) {
      return true;
    }
    // otherwise consider it initial tax year
    return false;
  }  

  const missingRequiredFields =
    !newProperty.addressLine1 || !newProperty.placedInService || !newProperty.purchasePrice || (isPreviousTaxYear(newProperty.placedInService) && newProperty.depreciationSchedule == null);

  const handleSaveProperty = () => {
    if (newProperty === newPropertyTemplate || missingRequiredFields) return;
    setRequest((draft) => {
      draft.properties = draft.properties || [];
      draft.properties.push(newProperty);
    });
  };

  return (
    <>
      <IndividualStepWrap>
        <StepHeader>Property Information</StepHeader>
        {request?.properties?.map((p, i) => (
          <PreviousProperty key={i} index={i} property={p} onDeleteProperty={onDeleteProperty} />
        ))}

        <div className='section_header'>
          Property {request?.properties?.length > 0 ? request?.properties?.length + 1 : 1}
        </div>
        <Grid container spacing={{ xs: 2, sm: 2.5 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              label='Name of Property (LLC)'
              value={newProperty.name ?? ''}
              name='name'
              onChange={onNewPropertyFieldUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressAutocomplete
              label='Address Line 1 *'
              setAddress={setAddress}
              onNoResultsFound={onNewPropertyFieldUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              label='Address Line 2'
              value={newProperty.addressLine2 ?? ''}
              name='addressLine2'
              onChange={onNewPropertyFieldUpdate}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              size='small'
              label='City'
              value={newProperty.city ?? ''}
              name='city'
              onChange={onNewPropertyFieldUpdate}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>State</InputLabel>
              <Select
                className='select_'
                value={newProperty.state ?? ''}
                label='State'
                name='state'
                onChange={onNewPropertyFieldUpdate}
                size='small'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
              >
                {allStates.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              size='small'
              label='Zip Code'
              value={newProperty.zip ?? ''}
              name='zip'
              onChange={onNewPropertyFieldUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={dateFieldHacks}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { size: 'small' } }}
                label='Placed in Service Date - Anticipated Purchase Date *'
                value={newProperty.placedInService ? dayjs(newProperty.placedInService) : ''}
                onChange={onDateUpdate}
                sx={
                  !newProperty.placedInService && {
                    '& .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e1e3e6',
                    },
                  }
                }
                textField={(params) => <TextField size='small' {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: '20px' }}>
            <NumericFormat
              value={newProperty.purchasePrice ?? ''}
              valueIsNumericString={true}
              onValueChange={(values) => {
                const { floatValue } = values;
                setNewProperty({ ...newProperty, purchasePrice: floatValue });
              }}
              customInput={TextField}
              thousandSeparator={true}
              prefix='$'
              fullWidth
              size='small'
              label='Purchase Price *'
            />
          </Grid>
          {newProperty.placedInService && isPreviousTaxYear(newProperty.placedInService) && (
            <Grid item xs={12}>
              <div className='dropzone-title mb1 mt3'>
                The property was purchased in a previous tax year. Please upload Depreciation
                Schedule. *
              </div>
              {files.length < 1 && <DropzoneFile files={files} setFiles={setFiles} maxFiles={1} />}
              {files.length >= 1 && (
                <UploadedFilesWrap>
                  {files.map((file, index) => (
                    <div key={index} className='row'>
                      <DeleteIcon onClick={() => removeFile(file)} />
                      <div>{file.fileName}</div>
                    </div>
                  ))}
                </UploadedFilesWrap>
              )}
            </Grid>
          )}

          <Grid item xs={12} sm={6} className='purchase_price-row' sx={{ marginTop: '32px' }}>
            <FormControl fullWidth>
              <FormLabel>Is this a 1031 Exchange?</FormLabel>
              <RadioGroup
                size='small'
                row
                value={newProperty.is1031Exchange ?? ''}
                onChange={on1031ExchangeUpdate}
              >
                <FormControlLabel value='true' control={<Radio />} label='Yes' />
                <FormControlLabel value='false' control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
          </Grid>
          {newProperty.is1031Exchange && (
            <Grid item xs={12}>
              <NumericFormat
                value={newProperty.newBasis ?? ''}
                valueIsNumericString={true}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setNewProperty({ ...newProperty, newBasis: floatValue });
                }}
                customInput={TextField}
                thousandSeparator={true}
                prefix='$'
                fullWidth
                size='small'
                label='Please provide a new basis from your 1031 Exchange'
              />
            </Grid>
          )}
        </Grid>
        <PreviousNextWrap>
          <div
            className='prev-direction'
            onClick={() => {
              handleSaveProperty();
              prevStep();
            }}
          >
            <RightArrow />
            <span>Previous</span>
          </div>
          <SubmitButton
            text='Continue'
            disabled={missingRequiredFields && (!request?.properties || request?.properties.length == 0)}
            onClick={() => {
              handleSaveProperty();
              nextStep();
            }}
          />
        </PreviousNextWrap>
      </IndividualStepWrap>
    </>
  );
}

function PreviousProperty({ index, property, onDeleteProperty }) {
  return (
    <PreviousPropertyWrap>
      <div className='df jcsb aifs mb2'>
        <div className='section_header'>Property {index + 1}</div>
        <div className='action-buttons'>
          <div onClick={() => onDeleteProperty(index)}>
            <DeleteIcon />
          </div>
        </div>
      </div>
      <div className='data_wrap'>
        <div>
          {(property.name ||
            property.addressLine1 ||
            property.addressLine2 ||
            property.city ||
            property.zip) && (
            <>
              <div className='bold fs-16'>Address</div>
              <div>{property.name}</div>
              <div>{property.addressLine1}</div>
              <div>{property.addressLine2}</div>
              <div>{[property.city, property.state, property.zip].join(' ')}</div>
            </>
          )}
        </div>

        <div className='table_wrap'>
          {property.placedInService && (
            <>
              <div>
                Placed in
                <br />
                Service Date:
              </div>
              <div>{property.placedInService.toLocaleDateString()}</div>
            </>
          )}
          {property.purchasePrice && (
            <>
              <div>Purchase Price:</div>
              <div>${property.purchasePrice}</div>
            </>
          )}
          {property.is1031Exchange && (
            <>
              <div>1031 Exhange:</div>
              <div>{property.is1031Exchange ? 'Yes' : 'No'}</div>
            </>
          )}
          {property.newBasis && (
            <>
              <div>New Basis:</div>
              <div>${property.newBasis}</div>
            </>
          )}
        </div>
      </div>
    </PreviousPropertyWrap>
  );
}
