export const root = {
  position: 'relative',
  textAlign: 'center',
  margin: '0px 24px 0',
  padding: '0 20px',
  backgroundColor: '#f2f2f2',
  paddingTop: 60,
  color: '#000',
  marginBottom: 119,
  '& .section-header__title': {
    position: 'absolute',
    top: -16,
    width: 220,
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#000',
    zIndex: 5000,
    '@media(min-width: 1024px)': {
      width: 314
    },
    '@media(min-width: 1440px)': {
      width: 435,
      top: -30
    }
  },
  '@media(min-width: 768px)': {
    margin: '0px 40px 0',
    paddingLeft: 32
  },
  '@media(min-width: 1024px)': {
    margin: '0px 56px 0'
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 120
  },
  '@media(min-width: 1440px)': {
    margin: '0px 77px 0'
  }
};

export const gradient = {
  backgroundImage:
    'linear-gradient(63deg, rgba(229, 229, 250, 0) 63%, #c6c6f3 116%)',
  height: '100%',
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0
};

export const motifS = {
  position: 'absolute',
  width: 147,
  height: 148,
  top: 0,
  right: 16,
  '@media(min-width: 768px)': {
    width: 184,
    height: 168,
    right: 23
  },
  '@media(min-width: 1440px)': {
    path: { fillOpacity: '1' },
    width: 301,
    height: 260
  },
  '@media(min-width: 1920px)': {
    path: { fillOpacity: '1' },
    width: 361,
    height: 301,
    right: 77
  }
};
export const innerWrap = {
  '@media(min-width: 768px)': {
    display: 'flex'
  },
  '@media(min-width: 1440px)': {
    justifyContent: 'center'
  }
};
export const info = {
  fontSize: 14,
  lineHeight: 2,
  color: '#000',
  marginTop: 32,
  textAlign: 'left',
  '@media(min-width: 768px)': {
    maxWidth: 279,
    paddingBottom: 55
  },
  '@media(min-width: 1024px)': {
    fontSize: 16,
    maxWidth: 397,
    marginTop: 64
  },
  '@media(min-width: 1260px)': {
    fontSize: 20,
    maxWidth: 430
  },
  '@media(min-width: 1440px)': {
    maxWidth: 565,
    fontSize: 18
  }
};
export const imageStyles = {
  width: 249,
  margin: 'auto',
  transform: 'translateY(52px)',
  marginTop: -20,
  '@media(min-width: 768px)': {
    width: 295,
    marginLeft: 55
  },
  '@media(min-width: 1024px)': {
    width: 339,
    marginBottom: 10
  },
  '@media(min-width: 1260px)': {
    width: 430,
    marginLeft: 100
  },
  '@media(min-width: 1440px)': {
    margin: 0,
    marginLeft: 96,
    width: 525,
    marginTop: 95
  },
  '@media(min-width: 1920px)': {
    width: 554
  }
};
export const subHeader = {
  fontSize: 18,
  marginBottom: 42,
  marginTop: 40,
  lineHeight: 1.56,
  textAlign: 'left',
  zIndex: 3000,
  position: 'relative',
  '@media(min-width: 768px)': {
    textAlign: 'center',
    maxWidth: 577,
    margin: '0 auto'
  },
  '@media(min-width: 1024px)': {
    marginTop: 32,
    maxWidth: 800,
    fontSize: 20,
    lineHeight: 1.4
  },
  '@media(min-width: 1440px)': {
    display: 'none'
  }
};
export const subHeader2 = {
  '@media(max-width: 1439px)': {
    display: 'none'
  },
  '@media(min-width: 1440px)': {
    width: 533,
    fontSize: 24,
    lineHeight: 1.6,
    textAlign: 'left',
    marginTop: 80
  }
};
