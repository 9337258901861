import React from 'react';
import styled from '@emotion/styled';

export const AddressWrap = styled.div`
  position: relative;
  .dropdown_wrap {
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    left: 0;
    padding: 12px 0;
    position: absolute;
    top: calc(100% + 8px);
    z-index: 100;
    width: 100%;
    ul {
      list-style: none;
      position: relative;
      > svg {
        position: absolute;
        z-index: 121;
        top: 3px;
        right: 16px;
        cursor: pointer;
        width: 14px;
        height: 14px;
        :hover {
          fill: #0927c7;
        }
      }
    }
    li {
      cursor: pointer;
      line-height: 1.4;
      transition: all 0.1s;
      font-weight: 400;
      font-size: 15px;
      margin: 0.25rem 0;       
      padding: 4px 16px;
      :hover {
        background-color: #f3f4f8;
        background-color: rgb(243, 244, 248);
        color: #0927c7;
      }
    }
  }
`;
