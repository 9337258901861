/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom';

import {
  Root,
  Header,
  Par,
  MotifStyles,
  SearchWrap,
  SearchField,
  SearchIconStyles,
  buttonStyles,
  SearchSection,
  InnerWrap,
  AllProperties,
  MotifBig,
  DropdownWrap,
  Option,
} from './topSectionStyles';
import { MotifMap, SearchIcon, DownCaret, Motif, RightArrow } from '../ui/icons';
import CallButton from '../ui/CallButton';
import { AddressContext } from './Address';

export default function TopSection({ orStyles, customButton, children }) {
  const { address, setAddress, search, setFilter, filter } = useContext(AddressContext);
  // const history = useHistory();
  const handleKeyDown = function (e) {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = () => {
    search();
  };
  const [dropdownOpen, setOpen] = useState(false);
  const changeFilter = (filter) => {
    setFilter(filter);
    setOpen(!dropdownOpen);
  };

  return (
    <SearchWrap customButton={customButton} css={orStyles}>
      <div
        css={{ position: 'relative', color: '#000', display: 'flex', alignItems: 'center' }}
        className='search-and-dropdown'
      >
        <SearchField
          type='text'
          placeholder='Search your area'
          value={address || ''}
          onChange={(e) => setAddress(e.target.value)}
          onKeyDown={handleKeyDown}
          className='search-field-wrap'
        />
        <SearchIconStyles>
          <SearchIcon />
        </SearchIconStyles>
        <AllProperties>
          <div
            css={{ display: 'flex', cursor: 'pointer' }}
            className='dropdown-label'
            onClick={() => setOpen(!dropdownOpen)}
          >
            {filter}
            <DownCaret className='ml1' />
          </div>
          <DropdownWrap dropdownOpen={dropdownOpen}>
            <Option onClick={() => changeFilter('All Property Types')}>
              All <span className='no-show-mobile'>Property Types</span>
            </Option>
            <Option onClick={() => changeFilter('Hotel')}>Hotel</Option>
            <Option onClick={() => changeFilter('Industrial')}>Industrial</Option>
            <Option onClick={() => changeFilter('Multifamily')}>Multifamily</Option>
            <Option onClick={() => changeFilter('Nursing Homes/Senior Living')}>
              Nursing Homes/Senior Living
            </Option>
            <Option onClick={() => changeFilter('Office')}>Office</Option>
            <Option onClick={() => changeFilter('Retail')}>Retail</Option>
            <Option onClick={() => changeFilter('Specialty')}>Specialty</Option>
          </DropdownWrap>
        </AllProperties>
      </div>
      {customButton ? (
        <div
          onClick={handleSearch}
          css={{
            alignItems: 'center',
            backgroundColor: '#0827C7',
            borderRadius: '0 6px 6px 0',
            display: 'flex',
            height: 48,
            justifyContent: 'center',
            width: 64,
            color: '#fff',
            textTransform: 'uppercase',
            transition: 'all .2s',
            fontWeight: 'bold',
            cursor: 'pointer',
            div: { display: 'none' },
            '&:hover': {
              backgroundColor: '#000e56',
            },
            '@media(min-width: 1440px)': {
              width: 176,
              height: 64,
              borderRadius: 8,
              boxShadow: '0px 21px 40px rgba(19, 21, 50, 0.388576)',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              padding: '0 40px',
              div: {
                display: 'block',
              },
            },
          }}
        >
          <RightArrow /> <div>Search</div>
        </div>
      ) : (
        <CallButton
          text='Search'
          styles={buttonStyles}
          onClick={handleSearch}
          className='submit-prop-search'
        />
      )}
    </SearchWrap>
  );
}

export function SearchSectionWrap({ children }) {
  return (
    <Root>
      <MotifBig>
        <Motif />
      </MotifBig>
      <MotifStyles>
        <MotifMap />
      </MotifStyles>
      <InnerWrap>
        <Header className='syne'>Cost Segregation in Every Single State</Header>
        <Par>
          Our experience runs as wide as it does deep. Having performed studies on every property
          type and in all 50 states, our team of in-house engineers can say “been there seen that”.
        </Par>
      </InnerWrap>
      <SearchSection>
        <TopSection />
      </SearchSection>
    </Root>
  );
}
