/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Slider from 'react-slick';

import Image from '../../components/ui/Image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const outerWrap = {
  marginBottom: 66,
  padding: '16px 0',
  '@media(min-width: 1024px)': {
    marginLeft: 48,
    marginTop: 48,
  },
  '@media(min-width: 1260px)': {
    marginTop: 56,
  },
};
const imageStyles = {
  opacity: 0.4,
  maxWidth: 1200,
  margin: 'auto',
  img: {
    maxHeight: 60,
    margin: 'auto',
  },
  '@media(min-width: 768px)': {
    img: {
      maxHeight: 75,
    },
  },
};

export const clientsList = [
  {
    img: 'Nightingale_logo_grvzoe.svg',
    name: 'Nightingale',
  },
  {
    img: 'P3_Logo_id7xix.svg',
    name: 'P3 Properties',
  },
  {
    img: 'Crea_RGB_Logo_mejl9e.svg',
    name: 'CREA',
  },
  {
    img: 'Shelbourne_b4qlaa.svg',
    name: 'Shelbourne',
  },
  {
    img: 'AmboProperties_Logo_nkvifp.svg',
    name: 'AMBO Properties',
  },
  {
    img: 'White_Eagle_PG_Logo_v2n41v.png',
    name: 'White Eagle Property Group',
  },
];

const carouselItems = clientsList.map((i) => (
  <div key={i}>
    <Image image={`specs/clients/${i.img}`} alt={i.name} />
  </div>
));

export default function Clients() {
  return (
    <div css={{ position: 'relative', padding: 24 }}>
      <div css={outerWrap}>
        <div css={imageStyles}>
          <SimpleSlider />
        </div>
      </div>
    </div>
  );
}

class SimpleSlider extends Component {
  render() {
    var settings = {
      arrows: false,
      autoplay: true,
      infinite: true,
      pauseOnHover: false,
      slidesToScroll: 6,
      slidesToShow: 6,
      speed: 1500,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 768,
          settings: { slidesToShow: 3, slidesToScroll: 3 },
        },
      ],
    };
    return <Slider {...settings}>{carouselItems}</Slider>;
  }
}
