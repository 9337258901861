/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Switch, Route } from 'react-router-dom';

import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';

import {
  multifamily,
  nursingHome,
  office,
  warehouse,
  distributionCenter,
  storage,
  restaurant,
  autoDealer,
  bank,
  retail,
  medical,
  golfCourse,
} from './studies';
import StorySection from './StorySection';

export default function CaseStudy({ category, setCategory }) {
  return (
    <Switch>
      <Route path={`/case-studies/golf-course`}>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {golfCourse?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path={`/case-studies/multi-family`}>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {multifamily?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/retail' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {retail?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/nursing-home' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {nursingHome?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/office' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {office?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/warehouse' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {warehouse?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/medical' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {medical?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/distribution-center' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {distributionCenter?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/storage' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {storage?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/restaurant' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {restaurant?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/auto-dealer' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {autoDealer?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
      <Route path='/case-studies/bank' exact>
        <div css={{ position: 'relative', zIndex: 2 }}>
          {bank?.map((study, index) => (
            <div key={index} id={index + 1}>
              <SectionOne study={study} index={index} />
              <SectionTwo study={study} />
              {study.story && <StorySection study={study} />}
            </div>
          ))}
        </div>
      </Route>
    </Switch>
  );
}
