/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import CallButton, { CallButtonLink } from '../ui/CallButton';
import {
  wrapper,
  header,
  innerHeader,
  motifStyles,
  outerWrap,
  buttonsWrap,
} from './lastSectionStyles';
import { Motif } from '../ui/icons';
import { nameFinder, subdomain } from '../ui/salePeeps';

export default function LastSection({ overRides, motifOverRides }) {
  const buttonText = (
    <div
      css={{
        '@media(max-width: 769px)': { span: { display: 'none' } },
      }}
    >
      Schedule A Call <span>Now</span>
    </div>
  );
  return (
    <div css={[wrapper, overRides]}>
      <div css={[motifStyles, motifOverRides]}>
        <Motif />
      </div>
      <div css={outerWrap} className='lastSectionOuterWrap'>
        <div
          css={{
            '@media(min-width: 1440px)': {
              marginLeft: 250,
            },
            '@media(min-width: 1920px)': {
              marginLeft: 450,
            },
          }}
        >
          <p className='syne' css={header}>
            Ready to improve your cash flow?
          </p>
          <p css={innerHeader}>Find out how much with a no-cost feasibility analysis today.</p>
        </div>
        <div css={buttonsWrap}>
          {nameFinder() ? (
            <CallButtonLink
              blank
              text={buttonText}
              link={
                subdomain === 'yonahweiss'
                  ? 'https://calendly.com/yonahweiss/cost-segregation-call?month=2021-11'
                  : subdomain === 'brianbigham'
                  ? 'https://calendly.com/costsegcowboy'
                  : subdomain === 'edenmarkowitz'
                  ? 'https://calendly.com/edenmarkowitz/30min?month=2024-09'
                  : 'https://calendly.com/specs-with-isaac-weinberger/15min'
              }
            />
          ) : (
            <CallButton text={buttonText} to='/contact-us' />
          )}
        </div>
      </div>
    </div>
  );
}
