export const wrapS = {
  padding: '48px 24px 42px',
  backgroundImage: 'linear-gradient(67deg, rgba(229, 229, 250, 0) 20%, #c6c6f3 190%)',
  backgroundColor: '#f2f2f2',
  position: 'relative',
  h1: {
    position: 'relative',
    zIndex: '5',
    fontSize: 32,
    lineHeight: '1.38',
    '@media(min-width: 768px)': {
      width: 400,
      lineHeight: 1.1,
      fontSize: 40,
    },
    '@media(min-width: 1024px)': {
      fontSize: 49,
      width: 500,
    },
    '@media(min-width: 1440px)': {
      width: 640,
      fontSize: 64,
      lineHeight: 1.2,
    },
  },
  p: {
    marginTop: 16,
    fontSize: 18,
    lineHeight: '1.33',
    maxWidth: 255,
    position: 'relative',
    zIndex: 50,
    '@media(min-width: 768px)': {
      marginTop: 22,
    },
    '@media(min-width: 1024px)': {
      maxWidth: 456,
      marginTop: 14,
    },
    '@media(min-width: 1440px)': {
      fontSize: 24,
      maxWidth: 505,
    },
  },
  '@media(min-width: 768px)': {
    backgroundImage: 'linear-gradient(60deg, rgba(229, 229, 250, 0) 54%, #c6c6f3 119%)',
    padding: '56px 0 115px 56px',
  },
  '@media(min-width: 1024px)': {
    backgroundImage: 'linear-gradient(49deg, rgba(229, 229, 250, 0) 55%, #c6c6f3 137%);',
    minHeight: 531,
    padding: '80px 0 136px 96px',
  },
  '@media(min-width: 1440px)': {
    backgroundImage: 'linear-gradient(43deg, rgba(229, 229, 250, 0) 58%, #c6c6f3 132%)',
    padding: '100px 0 190px 0',
  },
  '@media(min-width: 1650px)': {
    padding: '120px 0 224px 0',
    height: 800,
  },
};

export const buttonStyles = {
  marginTop: 32,
  borderRadius: 8,
  justifyContent: 'center',
  maxWidth: 150,
  svg: {
    transform: 'rotate(90deg)',
  },
  '@media(min-width: 768px)': {
    marginTop: 56,
  },
  '@media(min-width: 1440px)': {
    marginTop: 104,
  },
};

export const outerWrapper = {
  '@media(min-width: 1440px)': {
    maxWidth: 1172,
    margin: '0 auto',
  },
};

export const motifWrap = {
  position: 'absolute',
  right: 24,
  width: '100%',
  top: 0,
  maxWidth: 167,
  '@media(min-width: 768px)': {
    maxWidth: 200,
    top: -20,
    right: 40,
  },
  '@media(min-width: 1024px)': {
    right: 56,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 398,
    top: -74,
  },
};

export const imageWrap = {
  marginTop: 42,
  '@media(min-width: 768px)': {
    maxWidth: 366,
    position: 'absolute',
    right: 20,
    top: 75,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 456,
    right: 36,
    top: 120,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 520,
    right: 85,
    top: 52,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 600,
    right: 110,
    top: 130,
  },
  '@media(min-width: 1650px)': {
    maxWidth: 700,
    right: 140,
  },
  '@media(min-width: 1900px)': {
    maxWidth: 784,
  },
};

export const motifWrap2 = {
  '@media(max-width: 768px)': {
    display: 'none',
  },
  '@media(min-width: 768px)': {
    bottom: -4,
    color: 'white',
    display: 'inline',
    left: 0,
    position: 'absolute',
    zIndex: 0,
    width: 367,
    svg: { transform: 'rotate(180deg)' },
    path: { fillOpacity: '1' },
  },
  '@media(min-width: 1024px)': {
    width: 440,
    left: 57,
    bottom: -75,
  },
  '@media(min-width: 1440px)': {
    left: 125,
    width: 600,
  },
  '@media(min-width: 1650px)': {
    left: 125,
    width: 708,
  },
};
export const circles = {
  '@media(max-width: 1439px)': {
    display: 'none',
  },
  '@media(min-width: 1440px)': {
    display: 'inline',
    position: 'absolute',
    left: 24,
    top: 317,
  },
};

export const circleStyles = {
  borderRadius: '50%',
  width: 9,
  height: 9,
  backgroundColor: '#cccccc',
  marginBottom: 64,
};
export const selectedCircleStyles = {
  borderRadius: '50%',
  width: 9,
  height: 9,
  backgroundColor: '#0827c7',
  marginBottom: 64,
};
