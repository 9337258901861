/** @jsx jsx */
import { jsx } from '@emotion/core';

import { PlainImage } from '../ui/Image';
import { Wrapper, Header, ContentWrap, SubHeader, imageStyles, ParagraphTwo, ParagraphOne } from './smartKitStyles';
import { OutOfBox } from './large-illustrations';

export default function SmartKit() {
  return (
    <Wrapper>
      <Header className='syne'>The Smart Kit</Header>
      <SubHeader>Simply Smart</SubHeader>
      <ContentWrap>
        <OutOfBox css={imageStyles} />
        <ParagraphOne>
          The Madison SPECS Smart Tour Kit makes it easy for the on-site employee, and creates enthusiasm to complete
          the tour.
        </ParagraphOne>
        <ParagraphTwo>
          The Kit is sent in advance of the Smart Tour and includes all the necessary components to be used, as well as
          clear step-by-step instructions for the employee to follow.
        </ParagraphTwo>
      </ContentWrap>
    </Wrapper>
  );
}
