/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import {
  ArrowIcon,
  TzedakaIcon,
  CoinClockIcon,
  StackedCoinsIcon,
  LocationPinIcon,
  MultifamilyIcon,
  BuildingsIcon,
  DollarReturnIcon,
  SquareFootageIcon,
  UnitsIcon,
  PlusThree,
  PlusTwo,
} from './illustrations';
import {
  InnerTile,
  Header,
  StudyNumber,
  HeaderText,
  Motif,
  TileSection,
  Tile,
  amountStyles,
  InfoBulletsWrap,
  percentStyles,
  ThreePlusCluster,
  TwoPlusCluster,
  backgroundGradient,
  maxWidth,
} from './oneStyles';
import { HouseGraphIcon } from './svg/assetIcons';

const buildingImage = (image) =>
  `https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs-svg/${image}.svg`;
export default function SectionOne({ study, index }) {
  const { title, city, propertyType, buildings, purchasePrice, squareFeet, units, image } = study;
  return (
    <div css={{ backgroundColor: '#fff', position: 'relative', zIndex: 5 }}>
      <div css={{ backgroundColor: 'rgba(229, 229, 250, 0.3)' }}>
        <div css={backgroundGradient}>
          <div css={maxWidth}>
            <Header>
              <Motif
                width='358'
                height='322'
                viewBox='0 0 358 322'
                fill='none'
                xmlns='http://www.w3.org/2000/Motif'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M357.927 -432.8H20.3625L50.8358 -472.783L51.0073 -473.014V-542.244H306.919V-473.272H339.989V-576.974L17.9374 -577V-398.044H337.589L307.091 -357.546L306.919 -357.314L288.982 -288.729L105.382 -299.544L51.0073 -359.091H17.9374V-253.972H0V-213.243L0 128.858L289.08 283.263V321.984L358 321.984L357.927 -432.8ZM166.186 -233.142L148.182 -232.913L148.182 117.403L74.266 79.485V-273.219H196.138L166.186 -233.142ZM287.294 -232.791L287.125 -232.56L287.125 184.519L287.149 190.893L213.277 151.007L213.277 -268.626L335.149 -273.219L287.294 -232.791Z'
                  fill='white'
                />
              </Motif>
              <ThreePlusCluster>
                <PlusThree />
              </ThreePlusCluster>
              <TwoPlusCluster>
                <PlusTwo />
              </TwoPlusCluster>
              <StudyNumber>Case Study {index + 1}</StudyNumber>
              <HeaderText>{title}</HeaderText>
              {/* <BuildingTwo className='building-illus' /> */}
              <img className='building-illus' src={buildingImage(image)} alt={image} />
            </Header>
            <TileSection>
              <SampleTile
                amount={study.fiveYearPercent}
                description={
                  <Fragment>
                    5 Year Assets <br /> Reallocated
                  </Fragment>
                }
                percent={true}
                icon={<ArrowIcon />}
              />
              <SampleTile
                amount={study.fifteenYearPercent}
                description={
                  <Fragment>
                    15 Year Assets <br />
                    Reallocated
                  </Fragment>
                }
                icon={<CoinClockIcon />}
                percent={true}
              />
              {study.tileThree ? (
                <SampleTile
                  amount={study.sixYearSaving}
                  percent={study.tileThreePercent}
                  description={
                    <Fragment>
                      Total Reclass
                    </Fragment>
                  }
                  icon={<HouseGraphIcon />}
                />
              ) : (
                <SampleTile
                  amount={study.sixYearSaving}
                  description={
                    <Fragment>
                      First 6 Year <br />
                      Savings
                    </Fragment>
                  }
                  icon={<TzedakaIcon />}
                />
              )}
              <SampleTile
                amount={study.afterBonus}
                description={
                  <Fragment>
                    First Year Bonus <br />
                    Savings
                  </Fragment>
                }
                icon={<StackedCoinsIcon />}
              />
            </TileSection>
          </div>
        </div>
      </div>
      <div css={maxWidth}>
        <InfoBulletsWrap>
          {city && <InfoBullet icon={<LocationPinIcon />} value={city} />}
          {propertyType && <InfoBullet icon={<MultifamilyIcon />} value={propertyType} />}
          {buildings && <InfoBullet icon={<BuildingsIcon />} value={buildings} />}
          {purchasePrice && <InfoBullet icon={<DollarReturnIcon />} value={purchasePrice} />}
          {squareFeet &&  <InfoBullet icon={<SquareFootageIcon />} value={squareFeet} />}
          {units && <InfoBullet icon={<UnitsIcon />} value={units} />}
        </InfoBulletsWrap>
      </div>
    </div>
  );
}

function SampleTile({ description, amount, icon, percent }) {
  return (
    <Tile>
      <InnerTile>
        <div className='icon-circle'>{icon}</div>
        <div css={[amountStyles, percent && percentStyles]}>{amount}</div>
        <div className='tile-title'>{description}</div>
      </InnerTile>
    </Tile>
  );
}

function InfoBullet({ icon, value }) {
  return (
    <div css={!value && { span: { display: 'none' } }}>
      <span>{icon}</span>
      <span>{value}</span>
    </div>
  );
}
