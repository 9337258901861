import styled from '@emotion/styled';

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 85px;
  @media (min-width: 1024px) {
    padding-top: 124px;
  }
`;
export const Header = styled.h1`
  color: #0827c7;
  font-size: 24px;
  max-width: 246px;
  margin: auto;
  @media (min-width: 768px) {
    font-size: 32px;
    max-width: 768px;
  }
  @media (min-width: 1920px) {
    font-size: 48px;
    max-width: 1440px;
  }
`;
export const SubHeader = styled.h2`
  color: #55dcac;
  font-size: 22px;
  padding-top: 10px;
  @media (min-width: 768px) {
    font-size: 23px;
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    font-size: 25px;
    margin: auto;
  }
  @media (min-width: 1920px) {
    font-size: 30px;
  }
`;
export const OuterWrap = styled.div`
  margin-top: 95px;
  @media (min-width: 1440px) {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 150px auto;
  }
  @media (min-width: 1920px) {
    max-width: 100%;
    margin: 0;
    justify-content: center;
  }
`;
export const StepWrapper = styled.div`
  background-color: rgba(228, 228, 228, 0.56);
  border-radius: 30.1px;
  box-shadow: 0 12px 32px -5px rgba(0, 0, 0, 0.05);
  margin: 100px auto;
  padding-bottom: 50px;
  position: relative;
  width: 283px;
  @media (min-width: 768px) {
    margin-bottom: 78px;
    margin-top: 60px;
    padding-bottom: 75px;
    padding-left: 115px;
    width: 570px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 78px;
    margin-top: 60px;
    padding-bottom: 75px;
    padding-left: 115px;
    width: 745px;
  }
  @media (min-width: 1440px) {
    margin: 0;
    padding: 90px 50px 47px 50px;
    width: 300px;
    :nth-of-type(2) {
      width: 500px;
    }
  }
  @media (min-width: 1920px) {
    margin: 187px 0 218px 63px;
    padding: 108px 73px 47px;
    width: 404px;
    :first-of-type {
      margin-left: 0;
    }
    :nth-of-type(2) {
      width: 588px;
    }
    :nth-of-type(3) {
      padding: 108px 60px 47px;
    }
  }
`;
export const StepNumber = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 1px dotted #979797;
  color: #fff;
  display: flex;
  font-size: 52px;
  height: 121px;
  justify-content: center;
  left: 50%;
  padding: 20px;
  position: absolute;
  top: -60px;
  transform: translateX(-50%);
  width: 121px;
  @media (min-width: 768px) {
    font-size: 67px;
    height: 155px;
    left: -75px;
    padding: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 155px;
  }
  @media (min-width: 1440px) {
    font-size: 74px;
    height: 172px;
    left: 50%;
    padding: 25px;
    top: -80px;
    transform: translateX(-50%);
    width: 172px;
  }
  div {
    align-items: center;
    background-color: #55dcac;
    border-radius: 50%;
    box-shadow: 0 17px 21px -11px rgba(124, 217, 175, 0.91);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  span {
    position: absolute;
    top: calc(50% - 10px);
    transform: translateY(-50%);
  }
`;
export const StepHeader = styled.h1`
  font-size: 24px;
  color: #0827c7;
  text-align: center;
  padding-top: 92px;
  @media (min-width: 768px) {
    font-size: 30px;
    text-align: left;
    padding-top: 50px;
  }
  @media (min-width: 768px) {
    font-size: 32px;
    padding-top: 43px;
  }
  @media (min-width: 1440px) {
    text-align: center;
  }
  @media (min-width: 1920px) {
    font-size: 48px;
    text-align: center;
  }
`;
export const StepText = styled.div`
  font-size: 11px;
  line-height: 2;
  text-align: center;
  padding-top: 23px;
  max-width: 196px;
  margin: auto;
  @media (min-width: 768px) {
    font-size: 14px;
    max-width: 415px;
    text-align: left;
    margin: 0;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    padding-top: 33px;
    max-width: 574px;
  }
  @media (min-width: 1440px) {
    text-align: center;
  }
  @media (min-width: 1920px) {
    font-size: 16px;
    padding-top: 21px;
    text-align: center;
    margin: auto;
  }
`;
