/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

export default function NoOverflow() {
  return (
    <Global
      styles={{
        body: { overflow: 'hidden' },
        html: { overflow: 'hidden' },
      }}
    />
  );
}

export function BackgroundOverlay({ children }) {
  return (
    <div
      css={{
        backgroundColor: 'rgba(60, 60, 60, 0.5)',
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 5000,
        '@media(min-width: 768px)': {
          display: 'none',
        },
      }}
    >
      {children}
    </div>
  );
}
