import styled from '@emotion/styled';

export const Root = styled.div`
  margin-top: 80px;
  font-weight: 300;
  @media (min-width: 768px) {
    margin-top: 40px;
  }
  @media (min-width: 1024px) {
    width: 320px;
    margin-top: 0;
    .more-section-header {
      margin-bottom: 50px;
    }
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const AssetItemWrap = styled.div`
  align-items: center;
  display: flex;
  font-weight: normal;
  margin-top: 24px;
  :first-of-type {
    margin-top: 0;
  }
  .greenish {
    max-width: 24px;
    width: 24px;
    height: auto;
    border-radius: 0px;
  }
  .green-circle {
    align-items: center;
    background-color: #55DCAC;
    box-shadow: 0px 24px 30px -15px rgba(124, 217, 175, 0.908599);
    border-radius: 50%;
    display: flex;
    height: 48px;
    flex: 0 0 48px;
    justify-content: center;
    margin-right: 40px;
    .greenish {
      max-width: 32px;
      width: 32px;
      height: auto;
    }
    svg {
      width: 100%;
      height: 100%;
      max-width: 48px;
      max-height: 48px;
    }
  }
  .text-wrap {
    font-size: 14px;
    font-weight: 300;
    > div:last-of-type {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.33;
    }
  }
  @media (min-width: 768px) {
    display: inline-flex;
    margin-top: 48px;
    width: 50%;
    :nth-of-type(1),
    :nth-of-type(2) {
      margin-top: 0;
    }
    .green-circle {
      height: 56px;
      flex: 0 0 56px;
      .greenish {
        max-width: 36px;
        width: 36px;
        height: auto;
      }
      svg {
        max-width: 56px;
        max-height: 56px;
      }
    }
    .text-wrap {
      font-size: 16px;
      > div:last-of-type {
        margin-top: 4px;
      }
    }
  }
  @media (min-width: 1024px) {
    width: 100%;
    :nth-of-type(2) {
      margin-top: 48px;
    }
  }
  @media (min-width: 1440px) {
    width: 48%;
    margin-top: 72px;
    :nth-of-type(1) {
      margin-top: 48px;
    }
    .green-circle {
      height: 64px;
      margin-right: 24px;
      flex: 0 0 64px;
      .greenish {
        max-width: 40px;
        width: 40px;
        height: auto;
      }
      svg {
        max-width: 64px;
        max-height: 64px;
      }
    }
    .text-wrap {
      font-size: 16px;
      > div:last-of-type {
        margin-top: 8px;
      }
      > div:first-of-type {
        margin-top: 2px;
        font-size: 14px;
      }
    }
  }
`;

export const itemsWrap = {
  '@media(min-width: 1440px)': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
};
