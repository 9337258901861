import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Root = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  bottom: 40px;
  box-shadow: -7px 6px 26px rgba(166, 174, 185, 0.4);
  display: ${(props) => (props.noShow ? 'none' : 'flex')};
  flex-direction: column;
  left: 50%;
  min-height: 98px;
  padding: 18px 16px 20px;
  position: fixed;
  text-align: center;
  transform: translateX(-49%);
  width: 244px;
  z-index: 120;
  .customSocialCircle.customSocialCircle {
    color: #fff;
    background-color: #2867b2;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 14px;
      height: auto;
      path {
        stroke: #fff;
        fill: transparent;
        stroke-width: 2;
      }
    }
  }
  .profile-img {
    position: absolute;
    left: -40px;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    top: 11px;
    border: 3px solid #fff;
  }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
  .title {
    font-size: 12px;
    margin-top: 2px;
  }
  .icons {
    margin: 12px auto 20px;
    display: flex;
    justify-content: center;
    a {
      margin-right: 8px;
      display: flex;
      align-items: center;
      svg {
        width: auto;
        height: 22px;
      }
      img {
        height: 22px;
        width: auto;
        border-radius: 50%;
      }
      :last-of-type {
        margin-right: 0;
      }
    }
  }
  .help {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #56dbac;
    text-transform: uppercase;
    display: flex;
    margin-top: 10px;
    align-items: center;
    svg {
      transform: rotate(90deg);
      position: absolute;
      right: 16px;
    }
  }
  .x-close {
    position: absolute;
    top: -7px;
    right: -6px;
    z-index: 5000;
    cursor: pointer;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const LargeCard = styled(Root)`
  top: 150px;
  bottom: unset;
  padding: 36px 40px 30px;
  display: ${(props) => props.hide && 'none'};
  .help {
    flex-direction: column;
    margin-bottom: 24px;
    margin-top: 0;
    div:last-of-type {
      font-weight: 300;
      font-size: 10px;
      line-height: 13px;
      color: #000;
      margin-top: 6px;
      max-width: 160px;
    }
  }
  .profile-img {
    left: unset;
    top: -58px;
    width: 88px;
    height: 88px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopCard = styled(LargeCard)`
  display: none;
  @media (min-width: 768px) {
    bottom: 100px;
    display: ${(props) => (props.noShow ? 'none' : 'flex')};
    left: unset;
    padding: 36px 24px 30px;
    right: 22px;
    top: unset;
    transform: translate(0);
    width: 208px;
    .help {
      margin-bottom: 4px;
    }
    .hey {
      font-size: 12px;
      line-height: 129.4%;
      margin-bottom: 24px;
    }
    .arrow-wrap {
      margin-top: ${(props) => (props.expanded ? '16px' : '20px')};
      transition: all 0.35s;
      svg {
        transition: all 0.35s;
        transform: ${(props) => props.expanded && 'rotate(180deg)'};
        :hover {
          path {
            fill: #000e56;
          }
        }
      }
    }
  }
`;

export const Collapser = styled.div`
  height: ${(props) => (props.expanded ? '240px' : '0px')};
  overflow: hidden;
  transition: all 0.35s;
  border-radius: 0 0 5px 5px;
`;

export const Button = styled.a`
  background: #0827c7;
  box-shadow: -6px 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 18px;
  height: 48px;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.035em;
  transition: all 0.1s;
  :last-of-type {
    margin-bottom: 0;
  }
  :hover {
    background-color: #000e56;
  }
`;
export const NavLinkButton = styled(NavLink)`
  background: #0827c7;
  box-shadow: -6px 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 18px;
  height: 48px;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.035em;
  transition: all 0.1s;
  :last-of-type {
    margin-bottom: 0;
  }
  :hover {
    background-color: #000e56;
  }
`;

export function EmailIcon({ ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
      <polyline points='22,6 12,13 2,6'></polyline>
    </svg>
  );
}
