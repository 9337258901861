import qs from 'query-string';
import Cookies from 'js-cookie';

const paramsToTrack = ['keyword', 'utm_source', 'utm_medium', 'utm_campaign'];

const saveTrackingCookies = () => {
  const queryParms = qs.parse(window.location.search);
  const paramsForCookie = Object.keys(queryParms).filter((k) => paramsToTrack.includes(k.toLowerCase()));
  const cookieObj = paramsForCookie.reduce((obj, key) => {
    obj[key] = queryParms[key];
    return obj;
  }, {});

  Object.keys(cookieObj).forEach((k) => Cookies.set(k, cookieObj[k]));
};

const getTrackingStringForEmail = () => {
  const trackingObj = paramsToTrack.reduce((obj, key) => {
    const value = Cookies.get(key);
    if (value) {
      obj[key] = value;
    }
    return obj;
  }, {});

  const lineBreak = '<br />';
  const trackingString = Object.keys(trackingObj).map(key => `${key}: ${trackingObj[key]}`).join(lineBreak);
  return `${lineBreak}${lineBreak} Tracking Cookies: ${lineBreak}${trackingString}`;
}

export { saveTrackingCookies, getTrackingStringForEmail };