/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { SafetyIcon, TimeIcon, PersonIcon, CarIcon, VideoIcon } from '../ui/icons';

const wrapper = {
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: 375,
  flexWrap: 'wrap',
  marginBottom: 79,
  '@media(min-width: 768px)': {
    maxWidth: 768,
    marginLeft: 30,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 1024,
    marginLeft: 0,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 1440,
  },
  '@media(min-width: 1920px)': {
    maxWidth: 1920,
  },
};
export default function BlueBox() {
  return (
    <div css={wrapper}>
      <Box
        styles={{
          svg: {
            maxWidth: 29,
            '@media(min-width: 768px)': { maxWidth: 35 },
            '@media(min-width: 1024px)': { maxWidth: 46 },
            '@media(min-width: 1440px)': { maxWidth: 52 },
          },
        }}
        icon={<SafetyIcon />}
        text='Maximum safety through completely remote interaction'
      />
      <Box
        icon={<TimeIcon />}
        text={
          <div>
            Faster <br />
            turn-around <br />
            times
          </div>
        }
        styles={{
          svg: {
            maxWidth: 30,
            '@media(min-width: 768px)': { maxWidth: 38 },
            '@media(min-width: 1024px)': { maxWidth: 50 },
            '@media(min-width: 1440px)': { maxWidth: 59 },
          },
        }}
      />
      <Box
        icon={<PersonIcon />}
        text={
          <div>
            No intrusive <br />
            visits to tenants <br />
            from strangers
          </div>
        }
        styles={{
          svg: {
            maxWidth: 34,
            '@media(min-width: 768px)': { maxWidth: 42 },
            '@media(min-width: 1024px)': { maxWidth: 53 },
            '@media(min-width: 1440px)': { maxWidth: 61 },
          },
        }}
      />
      <Box
        icon={<CarIcon />}
        text={
          <div>
            Reduced travel expenses
            <br />
            <br />
          </div>
        }
        styles={{
          svg: {
            maxWidth: 39,
            '@media(min-width: 768px)': { maxWidth: 49 },
            '@media(min-width: 1024px)': { maxWidth: 62 },
            '@media(min-width: 1440px)': { maxWidth: 79 },
          },
        }}
      />
      <Box
        styles={{
          svg: {
            maxWidth: 34,
            '@media(min-width: 768px)': { maxWidth: 42 },
            '@media(min-width: 1024px)': { maxWidth: 55 },
            '@media(min-width: 1440px)': { maxWidth: 61 },
          },
        }}
        icon={<VideoIcon />}
        text={
          <div>
            Video trail <br />
            creates additional <br />
            IRS backup
          </div>
        }
      />
    </div>
  );
}
const blueWrapper = {
  alignItems: 'center',
  backgroundColor: '#0827c7',
  color: '#fff',
  display: 'flex',
  fontWeight: 500,
  height: 74,
  marginBottom: 6,
  padding: '17px 16px 19px 21px',
  width: 284,
  ':nth-of-type(even)': {
    backgroundColor: '#304ee9',
  },
  '@media(min-width: 768px)': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 15,
    height: 216,
    justifyContent: 'space-between',
    lineHeight: 1.47,
    marginBottom: 20,
    marginRight: 24,
    padding: '40px 24px 42px',
    textAlign: 'center',
    width: 194,
  },
  '@media(min-width: 1024px)': {
    fontSize: 20,
    height: 281,
    width: 252,
    paddingTop: 50,
    paddingBottom: 56,
  },
  '@media(max-width: 767px)': {
    br: {
      display: 'none',
    },
  },
  '@media(min-width: 1920px)': {
    fontSize: 24,
    height: 312,
    width: 280,
    paddingBottom: 42,
    paddingTop: 59,
  },
};
const textStyles = {
  lineHeight: 1.35,
  maxWidth: 220,
  width: 'calc(100% - 50px)',
  '@media(min-width: 768px)': {
    paddingTop: 24,
    width: '100%',
    maxWidth: '100%',
  },
  '@media(min-width: 1024px)': {
    paddingTop: 30,
  },
  '@media(min-width: 1920px)': {
    lineHeight: '1.67',
  },
};
function Box({ icon, text, styles }) {
  return (
    <div css={[blueWrapper, styles]}>
      <div
        css={{
          width: 50,
          '@media(min-width: 768px)': { width: '100%' },
        }}
      >
        {icon}
      </div>
      <div css={textStyles}>{text}</div>
    </div>
  );
}
