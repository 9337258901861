export const slideWrapper = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: 32,
  gridRowGap: 40,
  overflow: 'hidden',
  '@media(min-width: 768px)': {
    maxWidth: 768,
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  '@media(min-width: 1024px)': {
    gridColumnGap: 48,
    gridRowGap: 96,
    maxWidth: 1024,
    paddingBottom: 32,
    padding: '32px 0px',
  },
  '@media(min-width: 1260px)': {},
  '@media(min-width: 1440px)': {},
  '@media(min-width: 1920px)': {},
};

export const circleWrap = {
  display: 'inline-flex',
  flex: 'auto',
  fontSize: 18,
  fontWeight: '900',
  lineHeight: 1.56,
  position: 'relative',
  alignItems: 'center',
  '@media(min-width: 768px)': {},
  '@media(min-width: 1024px)': {
    alignItems: 'center',
  },
  p: {
    paddingLeft: 12,
    color: '#000',
    fontFamily: 'Ubuntu',
    fontWeight: 300,
    fontSize: 14,
    textAlign: 'left',
    width: 'calc(100% - 48px)',
    '@media(min-width: 1024px)': {
      fontSize: 16,
      width: 'calc(100% - 64px)',
      lineHeight: '1.25',
      paddingLeft: 24,
    },
  },
  '> div': {
    alignItems: 'center',
    backgroundColor: '#55dcac',
    borderRadius: '50%',
    boxShadow: '0 24px 30px -15px rgba(124, 217, 175, 0.91)',
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    width: 48,
    '@media(min-width: 1024px)': {
      height: 64,
      width: 64,
      svg: { position: 'absolute', left: 20, top: 20 },
    },
  },
};
