import React from 'react';
import {
  ArrowIcon,
  RotateArrowIcon,
  CoinStackIcon,
  MoneyGlassIcon,
  HatIcon,
  HouseIcon,
  LocationIcon,
  GraphGlassIcon,
  TorsoIcon,
  WorldIcon,
  BadgeIcon,
  BarGraphIcon,
  CoinArrowIcon,
  MoneyBagIcon,
  HouseGraphIcon,
  BriefcaseIcon,
} from './icons';
export const blue = [
  {
    title: 'Description',
    label: 'Description',
    value: 'description',
    image: 'Group_eteshw',
    svg: <ArrowIcon />,
    description:
      'A term used in accounting, economics and finance that permits the cost of an asset to be spread over the span of several years. It refers to the reduction in value of an asset due to usage, passage of time, wear and tear, technological outdating or obsolescence, depletion, inadequacy, rot, rust, decay or other such factors.',
  },
  {
    title: 'Accelerated Depreciation',
    label: 'Accelerated Depreciation',
    value: 'acceleratedDepreciation',
    image: 'graph-arrow_ux0h5d',
    svg: <RotateArrowIcon />,
    description:
      'A method of depreciation that allows an owner of a property to take faster write-offs than under the straight line method. In essence, this method provides for greater deductions and reduced income tax liability during the early years of an investment.',
  },
  {
    value: 'costSegregation',
    title: 'Cost Segregation',
    label: 'Cost Segregation',
    image: '/buildings_quotes_auijvd',
    svg: <CoinStackIcon />,
    description:
      'The process of evaluating a commercial or residential rental property and identifying and reclassifying eligible non-structural assets from longer recovery periods (typically 27.5-years or 39-years) to shorter recovery periods (typically five, seven or 15-years). Accelerating the depreciation of these personal property assets can result in a reduction of current tax liability thereby generating increased cash flow.',
  },
  {
    title: 'Cost Segregation Eligibility',
    label: 'Cost Segregation Eligibility',
    value: 'costSegregationEligibility',
    image: '/building-crane_jfzous',
    svg: <MoneyGlassIcon />,
    description: `The eligibility requirements for a cost segregation study include: investment property or property used for the operation of a business (whether a commercial or residential rental property) acquired or placed in service since 1986; an existing property that has been renovated, expanded or restored; or an existing property that has had leasehold improvements performed. Additionally, since the fundamental concept of cost  segregation is to accelerate tax deductions for  depreciation, thereby reducing income tax liability,  the property owner needs to be an income tax payer.`,
  },
  {
    title: 'Cost Segregation Study',
    label: 'Cost Segregation Study',
    value: 'costSegregationStudy',
    image: '/worker-man_fbya2s',
    svg: <HatIcon />,
    description:
      'A tax and engineering analysis of any real estate holdings including leasehold improvements. It is designed to accelerate the tax deductions for depreciation by reclassifying eligible fixed assets to shorter recovery periods. A cost segregation study identifies and segregates those assets, assesses their value and determines the resulting accelerated depreciation. Relevant IRS guidelines are adhered to and thorough documentation provided.',
  },
  {
    title: 'Eligible Assets',
    label: 'Eligible Assets',
    value: 'eligibleAssets',
    image: '/transparent-building_zodwhk',
    svg: <HouseIcon />,
    description:
      'Eligible assets for cost segregation studies are generally assets, fixtures or related elements that are either unnecessary for the operation of the building itself or are temporary structures. They can include certain electrical/mechanical systems; redundant HVAC systems; furniture; decorative lighting or moldings; floor coverings; communication systems; distribution panels and wiring; and millwork, among many other assets. Certain land improvements, such as parking, paving and landscaping, would also be eligible as shorter-lived assets.',
  },
  {
    title: 'Eligible Property',
    label: 'Eligible Property',
    value: 'eligibleProperty',
    image: '/sim-city_ol08jd',
    svg: <LocationIcon />,
    description:
      'Real property eligible for cost segregation includes buildings that have been purchased, constructed, expanded or remodeled after 1986. All types of properties qualify for cost segregation. The average percentage that can be reallocated to shorter depreciation varies by type, with, for example, 25-50% average reallocation for hotels and 20-35% for medical/dental properties. A study is typically cost-effective for buildings purchased or remodeled at a cost greater than $500,000, though there are exceptions.',
  },
  {
    title: 'Site Inspection',
    label: 'Site Inspection',
    value: 'siteInspection',
    image: '/cute-three-story-building_nrtpvy',
    svg: <GraphGlassIcon />,
    description:
      'A well-documented cost segregation study begins with an in-depth tour of the real estate by an engineer, who identifies and confirms each fixed asset on a property that is eligible for accelerated depreciation (not including intangibles). An extensive photo record is taken of the property during the study and key personnel are interviewed.',
  },
];

export const green = [
  {
    title: 'Audit Trail',
    label: 'Audit Trail',
    value: 'auditTrail',
    image: '/icon_2_1_fjor0g',
    svg: <CoinArrowIcon />,
    description:
      'A properly documented cost segregation study creates a detailed and accurate audit trail that helps resolve IRS inquiries at the earliest stages. Improper documentation of cost and asset classifications can lead to an unfavorable audit adjustment.',
  },
  {
    title: 'Soft Costs',
    label: 'Soft Costs',
    value: 'softCosts',
    image: '/money-graphs_ptoeqa',
    svg: <TorsoIcon />,
    description: `A cost segregation study allocates "soft costs", such as architect and engineering fees, to all components of the building.`,
  },
  {
    title: 'Value Allocation',
    label: 'Value Allocation',
    value: 'valueAllocation',
    image: '/men-graphs_elel03',
    svg: <WorldIcon />,
    description:
      'The process whereby cost specialists determine the value of all assets eligible for cost segregation, using the most widely accepted sources of construction cost information, such as the services of RS Means.',
  },
  {
    title: 'Net Present Value (NPV)',
    label: 'Net Present Value (NPV)',
    value: 'netPresentValue',
    image: '/paper-magnify_pervl0',
    svg: <BadgeIcon />,
    description:
      'This is a calculation used to determine the return on investment of a purchase, project or study over its lifetime. NPV calculations are often used during an analysis of the net cash flow benefits of a cost segregation study. For many clients, the NPV savings generated by the study equals an amount large enough to make the cost of the analysis a worthwhile investment.',
  },
  {
    title: 'Tax Analysis',
    label: 'Tax Analysis',
    value: 'taxAnalysis',
    image: '/man-at-desk_g4xzpe',
    svg: <BarGraphIcon />,
    description:
      'In the cost segregation process, accounting and tax experts compile relevant tax law in order to support the eligible asset reclassification. Additionally, these consultants research, prepare and complete the appropriate schedules which will be incorporated into your tax return by your CPA.',
  },
];

export const yellow = [
  {
    title: `Modified Accelerated Cost Recovery System (MACRS)`,
    label: 'Modified Accelerated Cost Recovery System (MACRS)',
    value: 'modifiedAcceleratedCostRecoverySystem',
    image: '/Group_36_bvtycv',
    svg: <CoinArrowIcon />,
    description: `This is the current method of accelerated asset depreciation required by the United States income tax code. Under MACRS, all assets are divided into classes which dictate the number of years over which an asset's cost will be recovered. MACRS allows for more depreciation towards the beginning of the life of the capital asset (such as double declining balance), allowing the tax deductible depreciation expense to be taken sooner, thus increasing the Net Present Value of the capital purchase and allowing a company to retain more income early in the depreciation cycle. In essence, this is a more heavily "front loaded" tax benefit.`,
  },
  {
    title: 'Retroactivity',
    label: 'Retroactivity',
    value: 'retroactivity',
    image: '/spin-building_d4rj8d',
    svg: <MoneyBagIcon />,
    description:
      'Since 1996, taxpayers can capture immediate retroactive savings on property added since 1987. Previous rules, which provided a four-year catch-up period for retroactive savings, have been amended to allow taxpayers to take the entire amount of the adjustment in the year the cost segregation is completed. This opportunity to recapture unrecognized depreciation in one year presents an the chancev to perform retroactive cost segregation analyses on older properties to increase cash flow in the current year.',
  },
  {
    title: 'Real Estate Professional Status',
    label: 'Real Estate Professional Status',
    value: 'realEstateProfessionalStatus',
    image: '/clark-kent_mdtkk0',
    svg: <HouseGraphIcon />,
    description: `Income and expenses flowing from real estate are by default considered passive activity. Generally, passive losses generated from real estate (such as those generated by doing a cost segregation study) can only be used to offset passive income. However, if you qualify as a real estate professional, your real estate activity will now be considered active and can be used to offset active income. While a discussion with your CPA will clarify whether you can qualify as a real estate professional, the general guidelines are:
More than 50% of your working hours are spent in real estate
You spend a minimum of 750 hours annually in real estate
You materially participate in the rental property`,
  },
  {
    title: 'Business Interest Limitation 163(j)',
    label: 'Business Interest Limitation 163(j)',
    image: '/myMac_ferii1',
    svg: <BriefcaseIcon />,
    description:
      'Under the Tax Cut and Jobs Act, a new limitation was enacted on how much interest expense some taxpayers can take. In order to avoid this limitation, taxpayers are able to opt out of the 163(j) by electing to be treated as “real property trade or business.” This would remove any limitations on the interest deductions that they can take. However, it will require the taxpayer to use the ADS method (which is a slightly slower method) of depreciation on their real property.',
  },
];
