/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef } from 'react';
import { useIntersection } from 'react-use';

import { BackgroundImage, PlusIconFourStyles, bubble, PageWrap } from './allStepsStyles';
import { fadeIn, fadeOut } from './fade';
import { PlusIcon4 } from './svg/plusIcons';
import Step from './Step';
import { GettingStarted } from './svg/largeIllustrations';
import { NumeroOne } from './svg/NumberCircle';
import { StandardImage } from '../ui/Image.jsx';

export default function StepOne({ refOne }) {
  const sectionRef = useRef(null);

  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });
  const isVisible = intersection && intersection.intersectionRatio > 0.4;
  isVisible ? fadeIn('.fadeInOne') : fadeOut('.fadeInOne');

  return (
    <PageWrap ref={sectionRef}>
      <BackgroundImage>
        <StandardImage className='fadeInOne' image='/specs/background1_fhmnta.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroOne />}
        currentRef={refOne}
        // forwardedRef={sectionRef}
        illustraion={<GettingStarted />}
        header='The Beginning'
        subText='Just the basics at this point. In fact, we often already have all the information that we need to get
        started from public sources, and will just need your confirmation.'
      />
      <PlusIconFourStyles>
        <PlusIcon4 />
      </PlusIconFourStyles>
    </PageWrap>
  );
}
