/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Motif } from '../ui/icons';
import { gradient, info, motifS, root, subHeader, innerWrap, imageStyles, subHeader2 } from './whenToDoStyles';
import { ReactComponent as WhenToDoCS } from '../ui/svg/about-us/when-to-do-cs.svg';

export default function () {
  return (
    <div className='tac' css={root}>
      <div css={gradient} />
      <p className='section-header__title'>When to do cost segregation?</p>
      <div css={motifS}>
        <Motif />
      </div>
      <p css={subHeader}>
        Cost segregation is best performed prior to the filing of the initial depreciation schedule on an asset. This is
        immediately after the purchase of a newly acquired building, or once a construction project is completed and
        placed in service.
      </p>
      <div css={innerWrap}>
        <div>
          <div css={subHeader2}>
            Cost segregation is best performed prior to the filing of the initial depreciation schedule on an asset.
            This is immediately after the purchase of a newly acquired building, or once a construction project is
            completed and placed in service.
          </div>
          <div css={info}>
            However, missed depreciation for past purchases, construction, expansions, and renovations can be recouped
            later via a cost segregation study. In such cases, there is no need to amend already filed tax returns.
            “Catch–up” depreciation can be taken in one year by filing Form 3115 (Change in Accounting Method).
          </div>
        </div>
        <div css={imageStyles}>
          <WhenToDoCS />
        </div>
      </div>
    </div>
  );
}
