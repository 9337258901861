import React from 'react';

import {
  Root,
  HeaderWrap,
  HeaderDesign,
  DownDiscWrap,
  DownDisc,
} from '../components/glossary/styles';
import { AnotherMotif } from '../components/glossary/icons';
import CategoriesSections from '../components/glossary/CategoryDesktop';
import CategoryMobile from '../components/glossary/CategoryMobile';
import LastSection from '../components/home/LastSection';

const linkTo = window.innerWidth < 1023 ? '#tabsToMobile' : '#tabsToDesktop';

export default function Glossary() {
  return (
    <>
      <HeaderDesign>
        <AnotherMotif />
      </HeaderDesign>
      <Root>
        <HeaderWrap>
          <div>
            <h1 className='syne'>The Educated Consumer's Dictionary</h1>
            <div className='sub_header'>
              Cost segregation can sound complicated, but once you know some common terms, you’ll be
              much better prepared to understand the process.
            </div>
            <div>
              <div className='scrollText'>Scroll to broaden your cost seg knowledge</div>
              <DownDiscWrap>
                <a href={linkTo}>
                  <DownDisc />
                </a>
              </DownDiscWrap>
            </div>
          </div>
          <PageIllus className='gloassry-main-img' />
        </HeaderWrap>
        <div>
          <CategoriesSections />
          <CategoryMobile />
        </div>
      </Root>
      <LastSection />
    </>
  );
}

function PageIllus({ ...props }) {
  return (
    <svg
      fill='none'
      height='719'
      viewBox='0 0 708 719'
      width='708'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <linearGradient id='a'>
        <stop offset='0' stopColor='#365cf5' />
        <stop offset='.07' stopColor='#4f70f6' />
        <stop offset='.25' stopColor='#849bf9' />
        <stop offset='.42' stopColor='#b0bffb' />
        <stop offset='.58' stopColor='#d2dbfd' />
        <stop offset='.74' stopColor='#ebeffe' />
        <stop offset='.88' stopColor='#fafbff' />
        <stop offset='.99' stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='b'
        gradientUnits='userSpaceOnUse'
        x1='78.3237'
        x2='133.646'
        xlinkHref='#a'
        y1='626.402'
        y2='712.89'
      />
      <linearGradient
        id='c'
        gradientUnits='userSpaceOnUse'
        x1='538.843'
        x2='593.811'
        xlinkHref='#a'
        y1='618.112'
        y2='698.609'
      />
      <linearGradient id='d'>
        <stop offset='0' stopColor='#fff' />
        <stop offset='1' stopColor='#dde5fc' />
      </linearGradient>
      <linearGradient
        id='e'
        gradientUnits='userSpaceOnUse'
        x1='259.719'
        x2='439.354'
        xlinkHref='#d'
        y1='164.054'
        y2='164.054'
      />
      <linearGradient
        id='f'
        gradientUnits='userSpaceOnUse'
        x1='287.546'
        x2='390.537'
        xlinkHref='#d'
        y1='409.483'
        y2='409.483'
      />
      <linearGradient
        id='g'
        gradientUnits='userSpaceOnUse'
        x1='136.784'
        x2='239.774'
        xlinkHref='#d'
        y1='402.952'
        y2='402.952'
      />
      <linearGradient
        id='h'
        gradientUnits='userSpaceOnUse'
        x1='486.08'
        x2='589.071'
        xlinkHref='#d'
        y1='151.515'
        y2='151.515'
      />
      <linearGradient
        id='i'
        gradientUnits='userSpaceOnUse'
        x1='360.837'
        x2='329.69'
        y1='169.758'
        y2='115.948'
      >
        <stop offset='0' stopColor='#365cf5' />
        <stop offset='.09' stopColor='#3e63f6' />
        <stop offset='.43' stopColor='#567af8' />
        <stop offset='.74' stopColor='#6588fa' />
        <stop offset='.99' stopColor='#6a8dfa' />
      </linearGradient>
      <linearGradient
        id='j'
        gradientUnits='userSpaceOnUse'
        x1='174.67'
        x2='217.782'
        xlinkHref='#d'
        y1='255.747'
        y2='255.747'
      />
      <linearGradient
        id='k'
        gradientUnits='userSpaceOnUse'
        x1='476.456'
        x2='519.569'
        xlinkHref='#d'
        y1='35.4406'
        y2='35.4406'
      />
      <linearGradient
        id='l'
        gradientUnits='userSpaceOnUse'
        x1='579.011'
        x2='600.568'
        xlinkHref='#d'
        y1='278.735'
        y2='278.735'
      />
      <clipPath id='m'>
        <path d='m0 0h708v719h-708z' />
      </clipPath>
      <g clipPath='url(#m)'>
        <path
          d='m269.691 693.53-205.1532-107.367-20.2933 9.622c-6.3144 3.004-10.4079 9.361-10.5385 16.371-.1307 7.01 3.658 13.54 9.8853 16.806l170.0977 89.994 56.002-25.47z'
          fill='url(#b)'
          opacity='.15'
        />
        <path
          d='m708 686.433-203.717-106.757-37.059 18.286 201.365 106.67z'
          fill='url(#c)'
          opacity='.15'
        />
        <path
          d='m446.452 272.684-5.4-6.618-7.882-7.402s-.74-2.96 2.047-2.699c2.046.218 8.883 2.656 12.28 3.962 1.307.479 2.439 1.437 3.136 2.699l3.962 6.705-8.187 3.396z'
          fill='#f4c9c6'
        />
        <path
          d='m537.38 592.084s2.874 20.507-1.263 26.341-18.508 9.318-16.766 1.045c1.742-8.272 3.179-28.605 3.179-28.605z'
          fill='#545470'
        />
        <path
          d='m506.33 578.805.915 13.323-10.016 4.789-2.831-1.35s-13.151 8.839-27.827 2.7v-2.83s21.817-7.184 26.303-14.977c4.485-7.794 13.456-1.655 13.456-1.655z'
          fill='#545470'
        />
        <path
          d='m539.426 430.512v90.212s3.702 44.105-1.393 69.967c0 0-9.45 11.146-19.03 0l1.045-56.992-6.315-53.901s-1.393 28.822 0 39.489c1.394 10.667-5.226 59.605-5.226 59.605-5.791 2.134-12.019 2.612-18.072 1.437v-80.59l-2.395-76.324s18.769 8.403 51.343 7.097z'
          fill='#e7c840'
        />
        <path
          d='m541.343 431.687s-20.773 11.277-36.276 3.048c-7.969-4.267-13.02-6.879-16.025-8.403-2.308-1.175-3.615-3.657-3.266-6.182 1.263-9.666 1.698-33.046 1.785-44.018.131-13.367-8.579-51.594 18.726-53.117 27.304-1.524 44.07 19.853 44.07 19.853l-7.142 47.327-1.829 41.492z'
          fill='#48cba3'
        />
        <path
          d='m506.374 334.857s-.087-.087-.174-.174c-2.483-2.743-.741-9.666-.741-9.666l-2.134-.609c-9.885-3.57-3.875-22.205-3.875-22.205s-.654-3.701 1.306-14.411c3.092-17.111 25.432-15.892 33.619-2.7 5.661 9.143-5.923 27.909-5.923 27.909l-2.438 12.539s-8.144 10.144-19.64 9.317z'
          fill='#f4c9c6'
        />
        <path
          d='m493.701 342.085-35.143-22.597-13.543-43.321s4.006-8.882 12.542-4.746l18.377 39.925 26.477 12.017-8.753 18.722z'
          fill='#48cba3'
        />
        <path
          d='m514.996 296.848s-13.195-2.177-14.937-19.244c0 0 16.636-10.798 30.092-.349 0 0 18.812 10.624-.566 38.489l-3.049-.871s3.136-13.236-.217-12.583c-3.354.697-3.528 4.615-3.528 4.615l-2.134-.609s4.137-7.707-5.661-9.448z'
          fill='#545470'
        />
        <path
          d='m548.005 339.995c2.657.566 23.037 40.317 14.633 64.785-5.139 14.804-14.328 27.909-26.434 37.792l-7.447-7.227 18.769-37.879s-23.036-62.609.436-57.428z'
          fill='#48cba3'
        />
        <path d='m528.975 435.388-2.613 3.788 7.882 8.142 1.699-4.049z' fill='#f4c9c6' />
        <path
          d='m435.478 22.292v184.3c0 10.319-5.4 19.897-14.24 25.165l-146.016 86.991c-3.919 2.351-8.797 2.394-12.759.087-3.963-2.264-6.402-6.487-6.402-11.103v-184.212c0-10.319 5.4-19.898 14.24-25.1657l146.016-86.9906c3.919-2.35107 8.753-2.3946 12.716-.1306s6.401 6.4873 6.445 11.0589z'
          fill='#ccd5e2'
        />
        <path
          d='m437.873 22.2484v184.3436c0 10.318-5.4 19.897-14.24 25.165l-146.016 86.991c-3.919 2.351-8.797 2.351-12.759.087-3.963-2.264-6.402-6.487-6.402-11.103v-184.212c0-10.363 5.444-19.898 14.284-25.1658l146.016-87.0341c3.919-2.30754 8.753-2.35108 12.716-.0871 3.962 2.2641 6.401 6.4438 6.401 11.0154z'
          fill='url(#e)'
        />
        <path
          d='m387.619 327.934v106.235c0 5.965-3.092 11.451-8.144 14.499l-83.742 50.243c-2.264 1.35-5.051 1.394-7.316.044-2.264-1.306-3.658-3.744-3.658-6.4v-106.322c0-5.965 3.092-11.451 8.187-14.499l83.742-50.2c2.265-1.35 5.052-1.393 7.316-.043 2.265 1.306 3.658 3.788 3.658 6.443z'
          fill='#ccd5e2'
        />
        <path
          d='m390.014 327.891v106.322c0 5.964-3.092 11.494-8.187 14.498l-83.742 50.2c-2.265 1.35-5.052 1.394-7.316.087-2.265-1.306-3.658-3.744-3.658-6.4v-106.322c0-5.964 3.091-11.494 8.187-14.498l83.742-50.2c2.264-1.35 5.052-1.35 7.316-.044 2.265 1.306 3.658 3.745 3.658 6.4z'
          fill='url(#f)'
        />
        <path
          d='m236.856 320.707v106.322c0 5.965-3.091 11.45-8.143 14.498l-83.742 50.2c-2.265 1.35-5.052 1.394-7.316.088-2.265-1.307-3.658-3.745-3.658-6.401v-106.321c0-5.965 3.091-11.495 8.187-14.542l83.742-50.201c2.264-1.349 5.051-1.349 7.272 0 2.221 1.35 3.658 3.745 3.658 6.357z'
          fill='#ccd5e2'
        />
        <path
          d='m239.252 320.707v106.322c0 5.965-3.092 11.494-8.187 14.542l-83.743 50.2c-2.264 1.35-5.051 1.393-7.316.087-2.264-1.306-3.658-3.744-3.658-6.4v-106.322c0-5.965 3.092-11.494 8.187-14.542l83.743-50.2c2.264-1.35 5.051-1.393 7.316-.087 2.264 1.306 3.658 3.744 3.658 6.4z'
          fill='url(#g)'
        />
        <path
          d='m588.592 69.5315v106.3215c0 5.965-3.092 11.451-8.144 14.499l-83.742 50.2c-2.264 1.35-5.051 1.393-7.316.087-2.264-1.306-3.658-3.744-3.658-6.4v-106.278c0-5.965 3.092-11.495 8.187-14.499l83.742-50.2001c2.265-1.3497 5.052-1.3497 7.316-.0435 2.265 1.3062 3.658 3.7443 3.658 6.4002z'
          fill='#ccd5e2'
        />
        <path
          d='m588.592 69.5316v106.3214c0 5.965-3.092 11.495-8.187 14.499l-83.742 50.2c-2.265 1.35-5.052 1.35-7.316.044-2.265-1.307-3.659-3.745-3.659-6.401v-106.278c0-5.965 3.092-11.494 8.187-14.498l83.743-50.2006c2.264-1.3497 5.051-1.3932 7.316-.087 2.264 1.3061 3.658 3.7443 3.658 6.4002z'
          fill='url(#h)'
        />
        <path
          d='m279.925 220.089v-63.393s9.668-3.613 24.082 23.511c14.589 27.343 25.04-68.704 57.832-44.671 32.791 24.034 20.249-55.9905 54.478-73.319v76.977l-136.392 80.851z'
          fill='url(#i)'
        />
        <path
          d='m413.922 165.273c.74-.435 1.742-.217 2.177.566.436.697.218 1.611-.435 2.134l-.131.087-135.564 80.503c-.741.436-1.742.218-2.178-.566-.435-.696-.217-1.611.436-2.133l.13-.087z'
          fill='#a9b3ce'
        />
        <path
          d='m354.044 229.885c.783-.435 1.785-.218 2.22.566.436.74.218 1.654-.435 2.133l-.131.087-75.729 44.628c-.784.435-1.786.217-2.221-.566-.436-.74-.218-1.655.435-2.134l.131-.087z'
          fill='#a9b3ce'
        />
        <path
          d='m224.924 349.486c.741-.479 1.699-.218 2.134.566.436.74.218 1.655-.435 2.134l-.131.087-25.606 15.848c-.74.479-1.698.217-2.134-.566-.435-.74-.218-1.655.436-2.134l.13-.087z'
          fill='#a9b3ce'
        />
        <path d='m523.967 181.6-23.908 14.542v-11.755l23.908-14.542z' fill='#f64621' />
        <g fill='#48cba3'>
          <path d='m579.012 148.075-23.908 14.586v-23.642l23.908-14.629z' />
          <path d='m550.313 164.62-23.951 14.804v-45.02l23.951-14.803z' />
          <path d='m384.179 351.663c.609 0 1.045.523 1.045 1.089s-.523 1.045-1.089 1.045c-4.529-.131-7.534 1.959-9.668 6.269l-.217.435c-1.394 3.005-2.221 6.314-3.441 13.062l-.827 4.746c-1.263 7.14-2.221 10.71-3.919 14.15-2.483 5.007-6.184 7.75-11.54 7.88h-.392c-4.137 0-6.924 2.003-9.015 6.009l-.218.479c-1.437 2.917-2.308 6.226-3.527 12.67l-.653 3.265c-1.698 8.664-2.874 12.409-5.4 15.63-3.614 4.572-9.102 5.312-17.071 1.786l-.435-.175c-14.676-6.661-22.21-3.004-25.04 7.228-.915 3.309-1.176 6.792-1.089 9.796v.871s.044.218.044.261c.043.566-.392 1.089-.958 1.176-.567.087-1.089-.392-1.176-.958v-.261s0-.262 0-.392c-.174-3.353.087-7.271 1.132-11.016 3.135-11.363 11.932-15.717 27.522-8.795l.479.218c7.36 3.353 11.932 2.787 14.937-1.001 2.352-2.961 3.44-6.705 5.182-15.761l.436-2.264c1.35-6.966 2.308-10.406 3.963-13.671 2.351-4.528 5.704-7.01 10.538-7.141h.349c4.703 0 7.795-2.22 10.016-6.705l.217-.479c1.437-3.091 2.308-6.531 3.528-13.105l.827-4.789c1.307-7.271 2.178-10.667 3.832-14.02 2.439-4.92 6.053-7.532 11.279-7.488h.349z' />
          <path d='m191.392 364.246v43.93c0 2.482-1.306 4.746-3.44 6.009l-35.056 20.768c-.958.566-2.09.566-3.048 0-.958-.523-1.524-1.568-1.524-2.656v-43.931c0-2.482 1.306-4.746 3.44-6.008l35.056-20.725c.958-.566 2.09-.566 3.048 0 .958.523 1.524 1.568 1.524 2.656z' />
        </g>
        <path
          d='m171.448 382.75v3.352c.74-.305 1.524-.435 2.308-.435.653 0 1.263.218 1.742.566.522.435.958.958 1.175 1.611.262.827.392 1.741.392 2.612l-4.224 2.264c.131-.871-.13-1.741-.74-2.438-.479-.348-1.176-.305-1.916.087-.436.218-.828.522-1.176.827-.305.305-.566.61-.784 1.002-.218.348-.348.696-.435 1.088s-.131.74-.131 1.132c0 .348 0 .653.131.958.087.261.261.479.479.61.305.13.609.217.958.217.479 0 1.001 0 1.48-.043.871-.087 1.699-.131 2.57-.131.696 0 1.35.131 2.003.392.566.261 1.001.697 1.306 1.263.349.74.523 1.567.479 2.394 0 .915-.13 1.829-.392 2.7-.261.87-.653 1.741-1.175 2.525-.523.827-1.133 1.567-1.829 2.264-.697.696-1.525 1.35-2.396 1.916v3.134l-2.308 1.263v-3.091c-.74.305-1.524.566-2.308.653-.696.087-1.393 0-2.003-.261-.609-.349-1.132-.828-1.393-1.481-.392-.914-.566-1.915-.523-2.96l4.224-2.264c0 .566.087 1.175.262 1.698.13.348.391.609.74.783.305.131.697.174 1.001.044.392-.087.784-.261 1.176-.436.436-.217.828-.522 1.176-.827s.61-.696.871-1.088c.218-.305.348-.653.479-1.002.131-.391.174-.783.174-1.175 0-.348-.043-.74-.174-1.089-.087-.261-.261-.479-.523-.609-.304-.131-.609-.218-.958-.218-.479 0-.958 0-1.393.087-.871.131-1.742.174-2.613.174-.697 0-1.394-.087-2.003-.391-.566-.262-1.002-.697-1.307-1.263-.348-.74-.522-1.568-.479-2.395 0-.914.131-1.785.392-2.656.262-.87.653-1.741 1.176-2.525 1.045-1.698 2.482-3.135 4.181-4.223v-3.309l2.351-1.306z'
          fill='#fff'
        />
        <path
          d='m224.924 366.205c.741-.479 1.699-.218 2.134.566.436.74.218 1.654-.435 2.133l-.131.087-25.606 15.849c-.74.479-1.698.217-2.134-.566-.435-.741-.218-1.655.436-2.134l.13-.087z'
          fill='#a9b3ce'
        />
        <path
          d='m210.466 392.546c.784-.479 1.786-.218 2.265.61.435.783.218 1.785-.436 2.307l-.13.087-13.456 8.49c-.784.479-1.786.218-2.265-.609-.435-.784-.218-1.785.436-2.308l.13-.087z'
          fill='#a9b3ce'
        />
        <path
          d='m68.2829 337.731s8.4918-2.221 12.4111 3.265c3.9193 5.529 10.1902 58.255 10.1902 58.255s-.4355 40.491-16.7659 40.883c0 0-22.3835-96.918-5.8354-102.403z'
          fill='#e8edfd'
        />
        <path
          d='m29.6996 596.917s-2.8742 20.507 1.2629 26.298c4.137 5.747 18.5078 9.317 16.7659 1.045-1.7419-8.273-3.179-28.605-3.179-28.605z'
          fill='#545470'
        />
        <path
          d='m60.7056 585.989-.9145 13.323 10.016 4.789 2.8306-1.349s13.1079 8.838 27.7833 2.699v-2.83s-21.8608-7.184-26.3462-14.977c-4.4855-7.794-13.4128-1.655-13.4128-1.655z'
          fill='#545470'
        />
        <path
          d='m27.6093 435.388v91.475s-3.7016 44.715 1.3935 70.925c0 0 9.4934 11.32 19.0739 0l-1.0451-57.776 6.2273-54.685s1.3936 29.171 0 40.099c-1.3935 10.929 5.2258 60.476 5.2258 60.476 5.7918 2.177 12.0192 2.656 18.0723 1.437v-81.766l2.3951-77.412s-18.7255 8.49-51.3428 7.184z'
          fill='#e7c840'
        />
        <path
          d='m25.5625 436.477s20.4239 11.32 35.6221 3.047c7.8386-4.266 12.8031-6.879 15.7208-8.403 2.2645-1.175 3.5273-3.657 3.179-6.139-1.2629-9.622-3.7452-33.35.5661-43.408 5.487-12.844 6.0967-52.203-20.7288-53.727-26.8254-1.524-43.2429 19.854-43.2429 19.854l7.0112 47.283 1.829 41.536z'
          fill='#e8edfd'
        />
        <path
          d='m17.8981 347.179c-2.9612.653-24.99641 44.583-15.54654 72.056 5.8354 16.632 16.19974 31.348 29.91734 42.451l8.4047-8.142-21.2078-42.537s24.9965-69.576-1.5677-63.828z'
          fill='#e8edfd'
        />
        <path
          d='m63.8411 339.603s.0871-.087.1306-.174c2.3951-2.83.4355-9.71.4355-9.71l2.0903-.653c9.5805-3.875 3.179-22.379 3.179-22.379s.5226-3.744-1.6984-14.454c-3.5274-17.068-25.4754-15.239-33.1399-1.742-5.2692 9.361 6.6629 27.821 6.6629 27.821l2.787 12.539s8.3176 9.927 19.553 8.752z'
          fill='#f4c9c6'
        />
        <path d='m42.1978 454.502 3.2661 3.831-9.8418 8.142-2.1339-4.093z' fill='#f4c9c6' />
        <path
          d='m64.5814 285.615s-2.3952 3.178-8.8402 1.959c-4.8338-.914-11.9757 5.181-10.1467 11.451.9581 3.265 1.742 6.792 1.742 6.792l-2.221.696s-1.5242-4.528-3.8757-3.57c-2.3516.958 4.3983 13.715-1.2194 15.282 0 0-16.2869-21.682-4.8773-33.481 11.4095-11.799 26.869-7.358 29.4383.958z'
          fill='#545470'
        />
        <path
          d='m57.2654 307.732-6.6629 1.611-1.2628-5.137 9.3627-2.308.5662 2.395c.1741.74.0871 1.48-.3049 2.133-.3919.653-1.0016 1.089-1.7419 1.263z'
          stroke='#545470'
          strokeWidth='.55'
        />
        <path
          d='m69.328 304.772-6.6628 1.611-1.2629-5.138 9.3628-2.307.6097 2.394c.1742.74.0435 1.48-.3048 2.134-.3484.653-1.0016 1.088-1.742 1.262z'
          stroke='#545470'
          strokeWidth='.55'
        />
        <path d='m62.2298 301.071-2.3951.653' stroke='#545470' strokeWidth='.55' />
        <path d='m50.2541 305.642-4.7902 1.132' stroke='#545470' strokeWidth='.55' />
        <path
          d='m217.739 223.006v42.494c0 2.394-1.307 4.615-3.44 5.79l-35.056 20.115c-.958.523-2.091.566-3.049 0-.958-.522-1.524-1.48-1.524-2.569v-42.537c0-2.395 1.307-4.571 3.44-5.791l35.056-20.071c.958-.522 2.091-.566 3.049 0 .958.522 1.524 1.48 1.524 2.569z'
          fill='url(#j)'
        />
        <path
          d='m519.22 2.91711v42.53739c0 2.3947-1.306 4.6151-3.44 5.7907l-35.056 20.0714c-.958.5225-2.09.566-3.048 0-.958-.5225-1.525-1.4803-1.525-2.5688v-42.4503c0-2.3947 1.307-4.5716 3.441-5.7907l35.012-20.11494c.958-.522466 2.091-.566005 3.049 0 .958.522466 1.524 1.48032 1.524 2.56879z'
          fill='url(#k)'
        />
        <path
          d='m600.568 262.191v21.29c0 1.176-.654 2.308-1.699 2.874l-17.506 10.057c-.479.261-1.045.261-1.524 0s-.784-.74-.784-1.262v-21.247c0-1.176.653-2.308 1.698-2.917l17.507-10.058c.479-.261 1.045-.261 1.524 0s.784.74.784 1.263z'
          fill='url(#l)'
        />
        <path
          d='m451.634 378.265-16.679 10.406c-1.175.74-1.872 2.046-1.872 3.483v22.031c0 .74.392 1.437 1.001 1.785.61.348 1.394.348 2.004 0l16.678-10.406c1.176-.74 1.873-2.046 1.873-3.483v-21.987c0-.74-.348-1.437-.958-1.829s-1.394-.392-2.003 0zm1.481.958c.305.174.479.523.479.871v22.031c0 1.045-.523 2.002-1.394 2.525l-16.679 10.406c-.304.174-.653.174-.914 0-.261-.175-.479-.479-.479-.828v-22.074c0-1.045.522-2.003 1.35-2.525l16.679-10.406c.261-.174.566-.174.827-.043h.131z'
          fill='#a9b3ce'
        />
        <path
          d='m116.665 215.604-16.6793 10.406c-1.1758.74-1.8725 2.046-1.8725 3.483v22.031c0 .74.3919 1.437 1.0016 1.785.6096.348 1.3502.348 1.9592 0l16.679-10.406c1.176-.74 1.873-2.046 1.873-3.483v-22.031c0-.74-.392-1.436-1.002-1.828s-1.393-.349-2.003 0zm1.48.915c.305.174.479.522.479.87v22.031c0 1.045-.522 2.003-1.393 2.525l-16.679 10.406c-.305.174-.6534.174-.9147 0-.3048-.174-.479-.479-.479-.827v-22.031c0-1.045.5226-2.003 1.3497-2.525l16.679-10.406c.261-.174.566-.174.827-.087h.131z'
          fill='#a9b3ce'
        />
      </g>
    </svg>
  );
}
