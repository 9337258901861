/** @jsx jsx */
import { jsx } from '@emotion/core';
import Cookies from 'js-cookie';

export default function SiteLink({ to, children, onLinkClick, styles }) {
  return (
    <a
      css={styles}
      href={`/${to}.asp.html`}
      onClick={(e) => {
        if (!Cookies.get('displayed-exit-modal')) {
          e.preventDefault();
          Cookies.set('displayed-exit-modal');
          onLinkClick && onLinkClick(`/${to}.asp.html`);
        }
      }}
    >
      {children}
    </a>
  );
}
