import React from 'react';
import styled from '@emotion/styled';

export const MainWrap = styled.div`
  background-color: #000e56;
  height: 100%;
  position: relative;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const FooterWrap = styled.div`
  color: white;
  font-weight: 500;
  padding: 50px 36px;
  a {
    color: white;
  }
`;

export const LogoWrap = styled.div`
  font-weight: 600;
  line-height: 1;
  max-width: 176px;
  width: 100%;
  height: auto;
  svg {
    width: 100%;
    height: auto;
    max-width: 176px;
  }
  `;

export const SectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  font-size: 14px;
  position: relative;
  svg {
    width: 16px;
    height: auto;
  }
`;

export const MenuSection = styled.div`
  margin-top: 32px;
  > div {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const FinePrintWrap = styled.div`
  background-color: #000e56;
  border-top: 1px solid #fff;
  border-top: 0.5px solid #fff;
  padding: 48px 20px 80px;
`;

export const FinePrintInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: 129px; */
  justify-content: center;
  a {
    color: white;
    font-size: 13px;
    font-weight: 300;
  }
`;

export const Copyright = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  margin-top: 16px;
`;

export const MenuItemWrap = styled.div`
  background-color: #000e56;
  width: 100%;
  height: ${({ isOpen, height }) => (isOpen ? height : 0)};
  overflow: hidden;
  transition: all 0.2s;
  transition: all 0.2s;
  
  a {
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 300;
    line-height: 2.29;
    margin-bottom: 10px;
    display: block;
  }
`;

export const InnerItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;

export function AnotherSpecsLogo() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='177'
      height='40'
      viewBox='0 0 177 40'
      fill='none'
    >
      <path
        d='M42.832 38.5831C43.8938 38.9371 45.4866 39.114 47.2564 39.114C49.7341 39.114 51.5038 38.7601 52.7427 37.8752C53.9815 36.9903 54.6894 35.9285 54.6894 34.3357C54.6894 33.0968 54.1585 32.035 53.4506 31.1501C52.5657 30.4422 51.1499 29.9113 49.2031 29.5573L46.0176 29.0264C44.9557 28.8494 44.2478 28.6724 43.7169 28.3185C43.3629 27.9645 43.009 27.6106 43.009 27.0796C43.009 26.3717 43.3629 26.0178 44.0708 25.6638C44.7787 25.3099 45.6636 25.1329 47.0794 25.1329C48.1413 25.1329 49.0262 25.3099 49.7341 25.6638C50.442 26.0178 50.7959 26.5487 51.1499 27.2566L54.3355 26.9027C53.9815 25.4869 53.2736 24.425 52.0348 23.7171C50.7959 23.0092 49.2031 22.6552 47.0794 22.6552C45.4866 22.6552 44.2478 22.8322 43.009 23.1862C41.9471 23.5401 41.0622 24.071 40.5313 24.7789C40.0004 25.4869 39.6464 26.1948 39.6464 27.2566C39.6464 28.6724 40.1773 29.7343 41.0622 30.4422C42.1241 31.1501 43.5399 31.681 45.8406 32.035L48.4952 32.3889C49.3801 32.5659 50.088 32.7429 50.6189 33.0968C51.1499 33.4508 51.3269 33.8047 51.3269 34.5127C51.3269 35.2206 50.9729 35.7515 50.265 36.1054C49.5571 36.4594 48.6722 36.6364 47.2564 36.6364C45.8406 36.6364 44.6017 36.4594 43.8938 35.9285C43.1859 35.3975 42.655 34.6896 42.478 33.6278H39.1155C39.2925 34.8666 39.6464 35.9285 40.1773 36.6364C40.8852 37.6982 41.7701 38.2292 42.832 38.5831Z'
        fill='white'
      />
      <path
        d='M163.895 38.5831C164.957 38.9371 166.55 39.114 168.32 39.114C170.797 39.114 172.567 38.7601 173.806 37.8752C175.045 36.9903 175.753 35.9285 175.753 34.3357C175.753 33.0968 175.222 32.035 174.514 31.1501C173.629 30.4422 172.213 29.9113 170.266 29.5573L167.081 29.0264C166.019 28.8494 165.311 28.6724 164.78 28.3185C164.426 27.9645 164.072 27.6106 164.072 27.0796C164.072 26.3717 164.426 26.0178 165.134 25.6638C165.842 25.3099 166.727 25.1329 168.143 25.1329C169.204 25.1329 170.089 25.3099 170.797 25.6638C171.505 26.0178 171.859 26.5487 172.213 27.2566L175.399 26.9027C175.045 25.4869 174.337 24.425 173.098 23.7171C171.859 23.0092 170.266 22.6552 168.143 22.6552C166.55 22.6552 165.311 22.8322 164.072 23.1862C163.01 23.5401 162.125 24.071 161.594 24.7789C161.063 25.4869 160.71 26.1948 160.71 27.2566C160.71 28.6724 161.24 29.7343 162.125 30.4422C163.187 31.1501 164.603 31.681 166.904 32.035L169.558 32.3889C170.443 32.5659 171.151 32.7429 171.682 33.0968C172.213 33.4508 172.39 33.8047 172.39 34.5127C172.39 35.2206 172.036 35.7515 171.328 36.1054C170.62 36.4594 169.735 36.6364 168.32 36.6364C166.904 36.6364 165.665 36.4594 164.957 35.9285C164.249 35.3975 163.718 34.6896 163.541 33.6278H160.179C160.356 34.8666 160.71 35.9285 161.24 36.6364C161.948 37.6982 162.833 38.2292 163.895 38.5831Z'
        fill='white'
      />
      <path
        d='M83.0084 24.2478C84.2472 25.1327 84.7782 26.5485 84.7782 28.3183C84.7782 30.0881 84.2472 31.5039 83.0084 32.3888C81.7696 33.2736 80.3538 33.6276 78.407 33.6276H71.8589V38.9369H68.4963V23.009H78.407C80.3538 23.009 81.7696 23.363 83.0084 24.2478ZM80.7077 30.442C81.2386 29.9111 81.4156 29.3802 81.4156 28.4953C81.4156 27.6104 81.2386 26.9025 80.7077 26.5485C80.1768 26.0176 79.4689 25.8406 78.584 25.8406H71.6819V31.1499H78.584C79.4689 31.1499 80.1768 30.796 80.7077 30.442Z'
        fill='white'
      />
      <path
        d='M114.513 36.1052V38.9368H98.762V23.0089H114.336V25.6636H102.125V29.911H112.212L110.619 32.0347H102.125V36.1052H114.513Z'
        fill='white'
      />
      <path
        d='M144.247 37.6975C142.655 38.7594 140.531 39.2903 137.876 39.2903C135.93 39.2903 134.16 38.9364 132.567 38.4054C130.974 37.6975 129.913 36.8127 129.028 35.5738C128.143 34.335 127.789 32.7422 127.789 30.9725C127.789 29.2027 128.143 27.61 129.028 26.3711C129.913 25.1323 130.974 24.2474 132.567 23.5395C134.16 22.8316 135.93 22.6547 137.876 22.6547C140.531 22.6547 142.655 23.1856 144.247 24.2474C145.84 25.3093 146.725 26.9021 146.902 28.8488H143.717C143.363 27.7869 142.655 26.9021 141.77 26.3711C140.885 25.8402 139.469 25.4863 137.876 25.4863C135.753 25.4863 134.16 26.0172 132.921 26.9021C131.682 27.7869 131.151 29.2027 131.151 30.9725C131.151 32.7422 131.682 34.158 132.921 35.0429C134.16 35.9278 135.753 36.4587 137.876 36.4587C139.469 36.4587 140.708 36.1048 141.77 35.5738C142.655 35.0429 143.363 34.158 143.717 33.0962H146.902C146.725 35.2199 145.84 36.6357 144.247 37.6975Z'
        fill='white'
      />
      <path
        d='M39.1155 15.0434C41.4163 15.0434 41.7702 14.8664 41.7702 12.7426V2.47749H39.1155V1.76955H44.425L50.0885 12.5656L55.752 1.76955H61.0616V2.47749H58.4068V15.0434H60.8846V15.7513H53.0972V15.0434H55.752V3.36241L49.5576 15.9283H49.2036L42.4782 3.71638V12.7426C42.4782 13.4505 42.6552 14.1585 42.8321 14.5124C43.1861 14.8664 43.7171 15.0434 44.602 15.0434C44.779 15.0434 44.956 15.0434 45.1329 15.0434V15.7513H38.9385V15.0434H39.1155Z'
        fill='white'
      />
      <path
        d='M75.0439 10.0875L72.2123 4.42432L69.2037 10.0875H75.0439ZM63.8945 15.0428C65.4873 15.0428 65.8412 14.8658 66.7261 13.2731L73.2742 1.23877L80.3532 15.2198H82.2999V15.9277H75.0439V15.0428H77.3446L75.2209 10.7954H68.6728L67.257 13.45C67.0801 13.981 67.0801 13.981 67.0801 14.1579C67.0801 14.5119 67.257 14.8658 67.611 15.0428C67.788 15.0428 67.9649 15.2198 68.4959 15.2198H69.5577V15.9277H63.7175V15.0428H63.8945Z'
        fill='white'
      />
      <path
        d='M93.4513 15.043C93.8053 15.043 94.6902 14.8661 95.3981 14.8661C96.8139 14.5121 97.5218 14.1582 98.2297 13.0963C98.9376 12.0344 99.2916 10.6186 99.2916 9.02584C99.2916 6.54817 98.5837 4.60143 97.1678 3.53957C96.4599 3.00864 95.2211 2.65469 93.4513 2.65469H90.6197V15.22H93.4513V15.043ZM85.3104 15.043H87.9651V2.47771H85.3104V1.76981H94.8672C96.6369 1.76981 97.8758 2.12376 99.1146 2.65469C101.061 3.71655 102.3 6.01724 102.3 8.49491C102.3 11.3265 100.707 13.9812 98.4067 14.8661C97.1678 15.397 95.929 15.574 94.1592 15.574H85.4874V15.043H85.3104Z'
        fill='white'
      />
      <path
        d='M106.196 15.0434H108.851V2.47789H106.196V1.76997H114.514V2.47789H111.682V15.0434H114.514V15.7513H106.196V15.0434Z'
        fill='white'
      />
      <path
        d='M119.824 10.4418H120.355C120.532 11.3267 120.709 11.8576 121.063 12.5655C122.125 14.3353 124.603 15.5741 127.258 15.5741C129.912 15.5741 131.682 14.5123 131.682 12.7425C131.682 12.0346 131.328 11.3267 130.797 10.9727C130.62 10.7957 130.443 10.6188 130.266 10.6188C129.381 10.2648 127.788 10.0878 126.019 10.0878C123.718 9.91084 121.948 9.37991 120.886 8.49502C120.001 7.7871 119.647 6.90221 119.647 5.84035C119.647 4.6015 120.355 3.36265 121.417 2.47776C122.302 1.76985 123.895 1.41589 125.665 1.41589C127.611 1.41589 129.027 1.76985 130.974 2.83172L132.213 1.76985H132.744V7.07919H132.213C131.682 3.89359 129.381 2.12381 125.665 2.12381C123.01 2.12381 121.417 3.0087 121.417 4.6015C121.417 5.13243 121.594 5.48639 121.948 5.84035C122.656 6.72524 123.895 7.07919 126.727 7.25617C129.204 7.43315 130.974 7.96408 132.036 8.84897C132.921 9.73386 133.275 10.6188 133.275 11.6806C133.275 12.9195 132.744 14.1583 131.505 14.8662C130.443 15.5741 128.85 15.9281 126.904 15.9281C124.957 15.9281 123.895 15.5741 121.594 14.5123L120.355 15.7511H119.824V10.4418Z'
        fill='white'
      />
      <path
        d='M140.709 8.49491C140.709 10.7956 141.24 12.3884 142.125 13.6272C143.187 14.8661 144.603 15.574 146.018 15.574C149.204 15.574 151.151 12.9193 151.151 8.49491C151.151 6.37119 150.797 4.77841 149.912 3.53957C149.027 2.47771 147.611 1.76981 146.018 1.76981C142.833 1.94678 140.709 4.42445 140.709 8.49491ZM151.328 3.18562C152.921 4.60143 153.982 6.72515 153.982 8.67189C153.982 12.9193 150.443 16.1049 145.842 16.1049C141.24 16.1049 137.878 12.9193 137.878 8.67189C137.878 4.60143 141.417 1.23888 146.018 1.23888C148.142 1.23888 149.912 1.94678 151.328 3.18562Z'
        fill='white'
      />
      <path
        d='M158.055 15.0431H158.586C160.178 15.0431 160.709 14.5122 160.709 12.9194V2.47787H158.055V1.76997H163.895L174.336 12.0345V4.07065C174.336 2.65485 173.982 2.47787 171.682 2.47787V1.76997H176.991V2.47787C176.814 2.47787 176.814 2.47787 176.637 2.47787C176.106 2.47787 175.575 2.65485 175.398 2.83182C175.044 3.18577 175.044 3.36275 175.044 4.24762V16.4589H174.513L161.417 3.36275V12.9194C161.417 14.6891 161.771 15.0431 163.541 15.0431H164.072V15.751H158.055V15.0431Z'
        fill='white'
      />
      <path d='M23.8938 0H0V40H26.5487V2.65487C26.5487 1.23894 25.3097 0 23.8938 0Z' fill='white' />
      <path
        d='M7.96475 19.6459V9.3805V9.20351L12.5665 11.5044V21.2389H6.54883L7.96475 19.6459ZM13.9824 19.6459V12.0354L18.7612 14.3362V21.2389H12.7435L13.9824 19.6459ZM6.54883 28.3185H20.0001L18.7612 29.9114V32.7432H7.96475V29.9114H6.54883V33.9822H20.0001V26.9025H6.54883L7.78776 25.3096V22.6548H18.5842V25.4866H20.0001V21.2389H18.7612L20.0001 19.6459V13.4513L7.96475 7.43361V6.01768H6.54883V28.3185Z'
        fill='#0927C7'
      />
    </svg>
  );
}
