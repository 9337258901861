/** @jsx jsx */
import { jsx, keyframes, Global } from '@emotion/core';

const overlay = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  zIndex: '10000000',
};

const floaterOne = keyframes`
    from { transform: rotate(0deg) }
    to  { transform: rotate(360deg) }
`;

const spinnerS = {
  animation: `${floaterOne} 1s infinite linear`,
  zIndex: '20000',
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '50%',
  left: '45%',
};

export default function() {
  return (
    <div css={overlay}>
      <Global
        styles={{
          body: { overflow: 'hidden' },
          html: { overflow: 'hidden' },
        }}
      />
      <svg css={spinnerS} xmlns='https://www.w3.org/2000/svg' width='56' height='55' viewBox='0 0 56 55'>
        <g fill='none' fillRule='evenodd'>
          <path
            fill='#fbfbfb'
            fillRule='nonzero'
            d='M22.638 54.287C7.816 51.312-1.822 36.831 1.153 22.01 4.13 7.186 18.61-2.452 33.432.524c14.822 2.975 24.46 17.455 21.485 32.278C51.94 47.624 37.46 57.263 22.638 54.287zM32.42 5.564C20.376 3.146 8.61 10.977 6.194 23.021 3.776 35.065 11.607 46.829 23.65 49.247c12.044 2.418 23.809-5.413 26.226-17.457C52.294 19.747 44.463 7.982 32.42 5.564z'
            opacity='.4'
          />
          <path
            fill='#0827C7'
            d='M54.917 32.802l-5.04-1.012C52.293 19.747 44.462 7.982 32.42 5.564l1.012-5.04c14.822 2.975 24.46 17.455 21.485 32.278z'
          />
        </g>
      </svg>
    </div>
  );
}
