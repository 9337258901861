/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { Events, scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';

import SectionOne from '../components/process/SectionOne';
import TopStepper from '../components/process/TopStepper';
import AllSteps from '../components/process/AllSteps';

export default class ProcessPage extends Component {
  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }
  onSectionClick(section) {
    scroller.scrollTo(section, {
      duration: 1700,
      delay: 0,
      smooth: 'easeInOutQuad',
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Madison SPECS | Process</title>
          <meta name='description' content='Seven Simple Steps to a Professional Cost Segregation Study' />
        </Helmet>
        <SectionOne />
        <TopStepper onSectionClick={this.onSectionClick} />
        <AllSteps onSectionClick={this.onSectionClick} />
      </div>
    );
  }
}
