/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Root, MapWrap, MapHeader, GreenSearchBox, SearchWrap, InnerWrap } from './searchSectionStyles';

import TopSection from '../map/TopSection';

import fgdfgd from '../../images/map-with-gradient.png';

export default function SearchSection() {
  return (
    <Root>
      <InnerWrap>
        <MapWrap>
          <MapHeader>
            Cost segregation in all<div>50</div>States
          </MapHeader>
          {/* <MapTwoIllus className='map-styles' /> */}
          {/* <MapIllus className='map-styles' /> */}
          <div className='map-styles'>
            <img src={fgdfgd} alt='' />
          </div>
        </MapWrap>
        <GreenSearchBox>
          <p>
            Explore Studies
            <br />
            we’ve done in your
            <br />
            area
          </p>
          <SearchWrap>
            <TopSection
              customButton={true}
              orStyles={{
                display: 'flex',
                width: '100%',
                '.search-and-dropdown': {
                  width: 'calc(100% - 64px)',
                  input: {
                    width: '100%',
                    display: 'block',
                  },
                },
                'input.search-field-wrap': {
                  borderRadius: '6px 0 0 6px',
                  height: 48,
                  maxWidth: '100%',
                },
                '@media(min-width: 1440px)': {
                  '.search-and-dropdown': {
                    maxWidth: 302,
                    marginRight: 24,
                    height: 64,
                    borderRadius: 8,
                    input: {
                      height: '100%',
                      borderRadius: 8,
                    },
                  },
                },
              }}
            />
          </SearchWrap>
        </GreenSearchBox>
      </InnerWrap>
    </Root>
  );
}
