export const locations = [
  {
    lat: 39.1045785,
    lng: -94.58952939999999,
    squareFootage: "SquareFootage",
    propertyType: "Specialty",
    totalCosts: "TotalCosts",
    valueOfReclassifiedAssets: null,
    depreciableAssets: null,
  },
  {
    lat: 30.4862944,
    lng: -87.19213599999999,
    squareFootage: "52675",
    propertyType: "Multifamily",
    totalCosts: "2698000",
    valueOfReclassifiedAssets: 817144,
    depreciableAssets: 36,
  },
  {
    lat: 37.183792,
    lng: -82.4319405,
    squareFootage: "49560",
    propertyType: "Retail",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 785293,
    depreciableAssets: 26,
  },
  {
    lat: 34.1799855,
    lng: -118.4601175,
    squareFootage: "7900",
    propertyType: "Multifamily",
    totalCosts: "2070000",
    valueOfReclassifiedAssets: 366726,
    depreciableAssets: 27,
  },
  {
    lat: 40.0251254,
    lng: -76.8490328,
    squareFootage: "139104",
    propertyType: "Multifamily",
    totalCosts: "16250000",
    valueOfReclassifiedAssets: 5100492,
    depreciableAssets: 37,
  },
  {
    lat: 31.786568,
    lng: -106.4435319,
    squareFootage: "57954",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 1655665,
    depreciableAssets: 31,
  },
  {
    lat: 31.3021309,
    lng: -84.981754,
    squareFootage: "66624",
    propertyType: "Retail",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 547127,
    depreciableAssets: 22,
  },
  {
    lat: 34.0258687,
    lng: -80.95947509999999,
    squareFootage: "134316",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1121806,
    depreciableAssets: 27,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "23672",
    propertyType: "Multifamily",
    totalCosts: "3960000",
    valueOfReclassifiedAssets: 625834,
    depreciableAssets: 23,
  },
  {
    lat: 33.0067086,
    lng: -96.99609249999999,
    squareFootage: "23087",
    propertyType: "Multifamily",
    totalCosts: "2235000",
    valueOfReclassifiedAssets: 403291,
    depreciableAssets: 20,
  },
  {
    lat: 40.58681139999999,
    lng: -74.69858909999999,
    squareFootage: "88506",
    propertyType: "Office",
    totalCosts: "6338405",
    valueOfReclassifiedAssets: 1203717,
    depreciableAssets: 24,
  },
  {
    lat: 29.4773092,
    lng: -95.5302337,
    squareFootage: "49509",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5912898",
    valueOfReclassifiedAssets: 1884261,
    depreciableAssets: 37,
  },
  {
    lat: 34.002058,
    lng: -118.2818967,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2973335",
    valueOfReclassifiedAssets: 2689109,
    depreciableAssets: 21,
  },
  {
    lat: 29.5682752,
    lng: -98.2322355,
    squareFootage: "21658",
    propertyType: "Retail",
    totalCosts: "3954135",
    valueOfReclassifiedAssets: 682917,
    depreciableAssets: 24,
  },
  {
    lat: 34.0127473,
    lng: -117.1617685,
    squareFootage: "146220",
    propertyType: "Multifamily",
    totalCosts: "36750000",
    valueOfReclassifiedAssets: 11274803,
    depreciableAssets: 36,
  },
  {
    lat: 34.2783099,
    lng: -83.8791074,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11873868",
    valueOfReclassifiedAssets: 9397758,
    depreciableAssets: 36,
  },
  {
    lat: 30.5251284,
    lng: -87.4009165,
    squareFootage: "40269",
    propertyType: "Multifamily",
    totalCosts: "1406000",
    valueOfReclassifiedAssets: 363642,
    depreciableAssets: 30,
  },
  {
    lat: 34.13224690000001,
    lng: -118.2117257,
    squareFootage: "10808",
    propertyType: "Multifamily",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 980416,
    depreciableAssets: 35,
  },
  {
    lat: 33.8019475,
    lng: -84.24368489999999,
    squareFootage: "377392",
    propertyType: "Multifamily",
    totalCosts: "18800000",
    valueOfReclassifiedAssets: 4313831,
    depreciableAssets: 27,
  },
  {
    lat: 29.64645269999999,
    lng: -95.4935119,
    squareFootage: "44316",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5399750",
    valueOfReclassifiedAssets: 1693115,
    depreciableAssets: 37,
  },
  {
    lat: 35.7833682,
    lng: -89.5120084,
    squareFootage: "58594",
    propertyType: "Multifamily",
    totalCosts: "1920000",
    valueOfReclassifiedAssets: 439703,
    depreciableAssets: 27,
  },
  {
    lat: 36.3659215,
    lng: -88.8455037,
    squareFootage: "93968",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 700199,
    depreciableAssets: 26,
  },
  {
    lat: 40.5518645,
    lng: -83.12200740000002,
    squareFootage: "10617",
    propertyType: "Retail",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 651465,
    depreciableAssets: 39,
  },
  {
    lat: 34.9487162,
    lng: -85.2437841,
    squareFootage: "7500",
    propertyType: "Multifamily",
    totalCosts: "7375000",
    valueOfReclassifiedAssets: 2289270,
    depreciableAssets: 37,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "107691",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 4282491,
    depreciableAssets: 33,
  },
  {
    lat: 39.5598101,
    lng: -84.21734029999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1201967",
    valueOfReclassifiedAssets: 1019025,
    depreciableAssets: 44,
  },
  {
    lat: 47.8140773,
    lng: -122.2718563,
    squareFootage: "11932",
    propertyType: "Industrial",
    totalCosts: "1676080",
    valueOfReclassifiedAssets: 1485882,
    depreciableAssets: 38,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10791473",
    valueOfReclassifiedAssets: 10230190,
    depreciableAssets: 7,
  },
  {
    lat: 44.9956414,
    lng: -93.258095,
    squareFootage: "6028",
    propertyType: "Multifamily",
    totalCosts: "794385",
    valueOfReclassifiedAssets: 111306,
    depreciableAssets: 20,
  },
  {
    lat: 29.8760239,
    lng: -95.5249863,
    squareFootage: "54368",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5749706",
    valueOfReclassifiedAssets: 1868756,
    depreciableAssets: 40,
  },
  {
    lat: 40.8007661,
    lng: -73.7330753,
    squareFootage: "11856",
    propertyType: "Retail",
    totalCosts: "3353380",
    valueOfReclassifiedAssets: 476078,
    depreciableAssets: 18,
  },
  {
    lat: 34.1142529,
    lng: -84.63744840000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 2297472,
    depreciableAssets: 68,
  },
  {
    lat: 35.0849752,
    lng: -80.5216184,
    squareFootage: "50826",
    propertyType: "Multifamily",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 956502,
    depreciableAssets: 41,
  },
  {
    lat: 39.9175385,
    lng: -75.1136721,
    squareFootage: "147200",
    propertyType: "Multifamily",
    totalCosts: "4890104",
    valueOfReclassifiedAssets: 934922,
    depreciableAssets: 22,
  },
  {
    lat: 30.5960565,
    lng: -88.19741479999999,
    squareFootage: "104376",
    propertyType: "Multifamily",
    totalCosts: "5750000",
    valueOfReclassifiedAssets: 1711453,
    depreciableAssets: 35,
  },
  {
    lat: 39.7252496,
    lng: -74.8390162,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1380718",
    valueOfReclassifiedAssets: 1349295,
    depreciableAssets: 29,
  },
  {
    lat: 29.5269768,
    lng: -98.61057029999999,
    squareFootage: "34946",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3510061",
    valueOfReclassifiedAssets: 1130088,
    depreciableAssets: 38,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "1900",
    propertyType: "Retail",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 2167500,
    depreciableAssets: 100,
  },
  {
    lat: 35.7595731,
    lng: -79.01929969999999,
    squareFootage: "228952",
    propertyType: "Multifamily",
    totalCosts: "10150000",
    valueOfReclassifiedAssets: 2644000,
    depreciableAssets: 31,
  },
  {
    lat: 29.9334606,
    lng: -96.8779926,
    squareFootage: "21145",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2127753",
    valueOfReclassifiedAssets: 573701,
    depreciableAssets: 31,
  },
  {
    lat: 30.9220263,
    lng: -87.7369607,
    squareFootage: "70307",
    propertyType: "Multifamily",
    totalCosts: "2128000",
    valueOfReclassifiedAssets: 501662,
    depreciableAssets: 28,
  },
  {
    lat: 39.2724771,
    lng: -76.745195,
    squareFootage: "51318",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19500000",
    valueOfReclassifiedAssets: 5330435,
    depreciableAssets: 30,
  },
  {
    lat: 40.7573053,
    lng: -73.9550857,
    squareFootage: "45115",
    propertyType: "Industrial",
    totalCosts: "50775000",
    valueOfReclassifiedAssets: 4984731,
    depreciableAssets: 11,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "62372",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5456683",
    valueOfReclassifiedAssets: 1400785,
    depreciableAssets: 30,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "26290",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1328837,
    depreciableAssets: 18,
  },
  {
    lat: 40.6996994,
    lng: -74.3997976,
    squareFootage: "34457",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11772167",
    valueOfReclassifiedAssets: 3537801,
    depreciableAssets: 35,
  },
  {
    lat: 40.8721452,
    lng: -73.92128579999999,
    squareFootage: "21186",
    propertyType: "Multifamily",
    totalCosts: "931920",
    valueOfReclassifiedAssets: 805709,
    depreciableAssets: 17,
  },
  {
    lat: 32.081259,
    lng: -81.0809848,
    squareFootage: "50720",
    propertyType: "Hotel",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 6841430,
    depreciableAssets: 37,
  },
  {
    lat: 30.4580512,
    lng: -87.21703950000001,
    squareFootage: "98778",
    propertyType: "Multifamily",
    totalCosts: "4620000",
    valueOfReclassifiedAssets: 1091250,
    depreciableAssets: 28,
  },
  {
    lat: 36.2962095,
    lng: -95.33116520000002,
    squareFootage: "83532",
    propertyType: "Multifamily",
    totalCosts: "4225000",
    valueOfReclassifiedAssets: 1135640,
    depreciableAssets: 27,
  },
  {
    lat: 33.8331717,
    lng: -118.2906661,
    squareFootage: "43290",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6827663",
    valueOfReclassifiedAssets: 5016335,
    depreciableAssets: 33,
  },
  {
    lat: 40.7383636,
    lng: -74.197388,
    squareFootage: "109248",
    propertyType: "Multifamily",
    totalCosts: "6275000",
    valueOfReclassifiedAssets: 1126302,
    depreciableAssets: 20,
  },
  {
    lat: 35.8509953,
    lng: -80.8765205,
    squareFootage: "45800",
    propertyType: "Retail",
    totalCosts: "3950000",
    valueOfReclassifiedAssets: 1359846,
    depreciableAssets: 41,
  },
  {
    lat: 39.5116069,
    lng: -76.6990172,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8205312",
    valueOfReclassifiedAssets: 7124030,
    depreciableAssets: 16,
  },
  {
    lat: 32.7522671,
    lng: -97.7905758,
    squareFootage: "36624",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3843149",
    valueOfReclassifiedAssets: 1358849,
    depreciableAssets: 41,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "89790",
    propertyType: "Multifamily",
    totalCosts: "1773837",
    valueOfReclassifiedAssets: 1611287,
    depreciableAssets: 10,
  },
  {
    lat: 32.7811446,
    lng: -97.0175879,
    squareFootage: "8054",
    propertyType: "Retail",
    totalCosts: "2107667",
    valueOfReclassifiedAssets: 724993,
    depreciableAssets: 36,
  },
  {
    lat: 32.4389231,
    lng: -99.7790227,
    squareFootage: "114420",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1720164,
    depreciableAssets: 32,
  },
  {
    lat: 40.1697469,
    lng: -74.82146929999999,
    squareFootage: "85894",
    propertyType: "Multifamily",
    totalCosts: "3791567",
    valueOfReclassifiedAssets: 1085686,
    depreciableAssets: 34,
  },
  {
    lat: 37.1996252,
    lng: -95.77212039999999,
    squareFootage: "65517",
    propertyType: "Retail",
    totalCosts: "1599092",
    valueOfReclassifiedAssets: 1345338,
    depreciableAssets: 29,
  },
  {
    lat: 33.9167378,
    lng: -118.1298234,
    squareFootage: "38130",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3622463",
    valueOfReclassifiedAssets: 2683471,
    depreciableAssets: 30,
  },
  {
    lat: 33.9167378,
    lng: -118.1298234,
    squareFootage: "36206",
    propertyType: "Multifamily",
    totalCosts: "12575000",
    valueOfReclassifiedAssets: 3791923,
    depreciableAssets: 36,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "67000",
    propertyType: "Multifamily",
    totalCosts: "1011892",
    valueOfReclassifiedAssets: 890385,
    depreciableAssets: 13,
  },
  {
    lat: 44.8735964,
    lng: -93.2835137,
    squareFootage: "59027",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4845000",
    valueOfReclassifiedAssets: 1073838,
    depreciableAssets: 26,
  },
  {
    lat: 34.2024698,
    lng: -118.8741429,
    squareFootage: "28858",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10400000",
    valueOfReclassifiedAssets: 2862898,
    depreciableAssets: 32,
  },
  {
    lat: 40.1594511,
    lng: -76.6181655,
    squareFootage: "36000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "100000",
    valueOfReclassifiedAssets: 4387,
    depreciableAssets: 5,
  },
  {
    lat: 40.6966538,
    lng: -73.83308439999999,
    squareFootage: "15408",
    propertyType: "Retail",
    totalCosts: "2449173",
    valueOfReclassifiedAssets: 2312122,
    depreciableAssets: 14,
  },
  {
    lat: 39.0703574,
    lng: -94.5676009,
    squareFootage: "15600",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 330751,
    depreciableAssets: 22,
  },
  {
    lat: 26.0197012,
    lng: -80.1819268,
    squareFootage: "6048",
    propertyType: "Retail",
    totalCosts: "1785000",
    valueOfReclassifiedAssets: 599018,
    depreciableAssets: 40,
  },
  {
    lat: 33.6832497,
    lng: -117.8340735,
    squareFootage: "12125",
    propertyType: "Industrial",
    totalCosts: "1820000",
    valueOfReclassifiedAssets: 72462,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "3780",
    propertyType: "Office",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 549477,
    depreciableAssets: 23,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "100829",
    propertyType: "Multifamily",
    totalCosts: "2906913",
    valueOfReclassifiedAssets: 2648357,
    depreciableAssets: 10,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "141889",
    propertyType: "Multifamily",
    totalCosts: "9732033",
    valueOfReclassifiedAssets: 3170673,
    depreciableAssets: 34,
  },
  {
    lat: 33.6496252,
    lng: -117.9190418,
    squareFootage: "2145",
    propertyType: "Industrial",
    totalCosts: "1525000",
    valueOfReclassifiedAssets: 267086,
    depreciableAssets: 21,
  },
  {
    lat: 37.8336281,
    lng: -122.2029832,
    squareFootage: "20069",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6150000",
    valueOfReclassifiedAssets: 1456454,
    depreciableAssets: 28,
  },
  {
    lat: 32.8012869,
    lng: -96.9584922,
    squareFootage: "61692",
    propertyType: "Multifamily",
    totalCosts: "3660094",
    valueOfReclassifiedAssets: 831254,
    depreciableAssets: 27,
  },
  {
    lat: 37.3290122,
    lng: -121.9160211,
    squareFootage: "103920",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 2702749,
    depreciableAssets: 14,
  },
  {
    lat: 40.1594511,
    lng: -76.6181655,
    squareFootage: "36000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2535585",
    valueOfReclassifiedAssets: 581617,
    depreciableAssets: 25,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6312339",
    valueOfReclassifiedAssets: 5598666,
    depreciableAssets: 12,
  },
  {
    lat: 31.0089127,
    lng: -97.7199454,
    squareFootage: "135734",
    propertyType: "Multifamily",
    totalCosts: "6450000",
    valueOfReclassifiedAssets: 1573505,
    depreciableAssets: 29,
  },
  {
    lat: 41.3683407,
    lng: -82.10764139999999,
    squareFootage: "2909",
    propertyType: "Specialty",
    totalCosts: "1841851",
    valueOfReclassifiedAssets: 798861,
    depreciableAssets: 51,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "71694",
    propertyType: "Multifamily",
    totalCosts: "2453284",
    valueOfReclassifiedAssets: 2190331,
    depreciableAssets: 12,
  },
  {
    lat: 33.7971137,
    lng: -84.3804888,
    squareFootage: "320900",
    propertyType: "Multifamily",
    totalCosts: "40541333",
    valueOfReclassifiedAssets: 9287765,
    depreciableAssets: 32,
  },
  {
    lat: 27.8028475,
    lng: -97.4002872,
    squareFootage: "269496",
    propertyType: "Office",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 1827328,
    depreciableAssets: 21,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "553349",
    propertyType: "Office",
    totalCosts: "78500000",
    valueOfReclassifiedAssets: 11242165,
    depreciableAssets: 17,
  },
  {
    lat: 33.087882,
    lng: -96.0891032,
    squareFootage: "47272",
    propertyType: "Multifamily",
    totalCosts: "3024176",
    valueOfReclassifiedAssets: 959241,
    depreciableAssets: 37,
  },
  {
    lat: 39.2632524,
    lng: -76.4910093,
    squareFootage: "50000",
    propertyType: "Multifamily",
    totalCosts: "5525000",
    valueOfReclassifiedAssets: 1206326,
    depreciableAssets: 26,
  },
  {
    lat: 40.2284948,
    lng: -75.30623260000002,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2800000",
    valueOfReclassifiedAssets: 1088150,
    depreciableAssets: 0,
  },
  {
    lat: 35.0648849,
    lng: -97.9374948,
    squareFootage: "39664",
    propertyType: "Multifamily",
    totalCosts: "2175000",
    valueOfReclassifiedAssets: 655747,
    depreciableAssets: 35,
  },
  {
    lat: 32.8493511,
    lng: -96.5890166,
    squareFootage: "125132",
    propertyType: "Multifamily",
    totalCosts: "14400000",
    valueOfReclassifiedAssets: 4798434,
    depreciableAssets: 33,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "35520",
    propertyType: "Multifamily",
    totalCosts: "3606000",
    valueOfReclassifiedAssets: 884504,
    depreciableAssets: 28,
  },
  {
    lat: 33.4660406,
    lng: -112.0118669,
    squareFootage: "35449",
    propertyType: "Hotel",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 736677,
    depreciableAssets: 26,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "119174",
    propertyType: "Multifamily",
    totalCosts: "12912100",
    valueOfReclassifiedAssets: 2888589,
    depreciableAssets: 25,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "131200",
    propertyType: "Multifamily",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 1010293,
    depreciableAssets: 35,
  },
  {
    lat: 34.2048586,
    lng: -118.5739621,
    squareFootage: "25030",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6390000",
    valueOfReclassifiedAssets: 2148111,
    depreciableAssets: 40,
  },
  {
    lat: 35.3515611,
    lng: -119.0576298,
    squareFootage: "51960",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 2422285,
    depreciableAssets: 32,
  },
  {
    lat: 32.8012869,
    lng: -96.9584922,
    squareFootage: "22284",
    propertyType: "Multifamily",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 446709,
    depreciableAssets: 30,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "158760",
    propertyType: "Industrial",
    totalCosts: "14857599",
    valueOfReclassifiedAssets: 1400417,
    depreciableAssets: 13,
  },
  {
    lat: 39.6751269,
    lng: -104.9680914,
    squareFootage: "21674",
    propertyType: "Multifamily",
    totalCosts: "904132",
    valueOfReclassifiedAssets: 188588,
    depreciableAssets: 25,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "131660",
    propertyType: "Multifamily",
    totalCosts: "8550000",
    valueOfReclassifiedAssets: 2271969,
    depreciableAssets: 28,
  },
  {
    lat: 40.5749261,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1409181",
    valueOfReclassifiedAssets: 1304752,
    depreciableAssets: 12,
  },
  {
    lat: 40.2284948,
    lng: -75.30623260000002,
    squareFootage: "51418",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21500000",
    valueOfReclassifiedAssets: 5460860,
    depreciableAssets: 29,
  },
  {
    lat: 42.0467637,
    lng: -88.0460894,
    squareFootage: "87969",
    propertyType: "Multifamily",
    totalCosts: "8400000",
    valueOfReclassifiedAssets: 2059176,
    depreciableAssets: 27,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "237326297",
    valueOfReclassifiedAssets: 211460939,
    depreciableAssets: 11,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "59660",
    propertyType: "Retail",
    totalCosts: "4221829",
    valueOfReclassifiedAssets: 982356,
    depreciableAssets: 27,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "42453",
    propertyType: "Multifamily",
    totalCosts: "115950000",
    valueOfReclassifiedAssets: 14051242,
    depreciableAssets: 14,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "53104",
    propertyType: "Multifamily",
    totalCosts: "3464092",
    valueOfReclassifiedAssets: 2938621,
    depreciableAssets: 16,
  },
  {
    lat: 39.9795244,
    lng: -75.145782,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17816961",
    valueOfReclassifiedAssets: 15366963,
    depreciableAssets: 15,
  },
  {
    lat: 32.3611059,
    lng: -99.91298450000001,
    squareFootage: "61400",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 716974,
    depreciableAssets: 25,
  },
  {
    lat: 34.6946278,
    lng: -118.1473771,
    squareFootage: "32527",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1044737,
    depreciableAssets: 19,
  },
  {
    lat: 35.8774409,
    lng: -78.5547283,
    squareFootage: "19472",
    propertyType: "Retail",
    totalCosts: "5419975",
    valueOfReclassifiedAssets: 1357426,
    depreciableAssets: 29,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "49054",
    propertyType: "Multifamily",
    totalCosts: "3298534",
    valueOfReclassifiedAssets: 2939698,
    depreciableAssets: 20,
  },
  {
    lat: 34.2253022,
    lng: -77.8710285,
    squareFootage: "194296",
    propertyType: "Multifamily",
    totalCosts: "16444804",
    valueOfReclassifiedAssets: 4732376,
    depreciableAssets: 32,
  },
  {
    lat: 35.9722081,
    lng: -79.04755589999999,
    squareFootage: "35707",
    propertyType: "Office",
    totalCosts: "1974543",
    valueOfReclassifiedAssets: 492817,
    depreciableAssets: 30,
  },
  {
    lat: 37.2920644,
    lng: -81.22985299999999,
    squareFootage: "105606",
    propertyType: "Retail",
    totalCosts: "2191741",
    valueOfReclassifiedAssets: 335028,
    depreciableAssets: 15,
  },
  {
    lat: 38.0270044,
    lng: -122.5485873,
    squareFootage: "43690",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8123344",
    valueOfReclassifiedAssets: 1772690,
    depreciableAssets: 27,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "49054",
    propertyType: "Multifamily",
    totalCosts: "1860458",
    valueOfReclassifiedAssets: 1658066,
    depreciableAssets: 20,
  },
  {
    lat: 33.901022,
    lng: -118.3169714,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6013820",
    valueOfReclassifiedAssets: 5268363,
    depreciableAssets: 22,
  },
  {
    lat: 36.4533112,
    lng: -97.9374948,
    squareFootage: "59300",
    propertyType: "Multifamily",
    totalCosts: "2907121",
    valueOfReclassifiedAssets: 830619,
    depreciableAssets: 31,
  },
  {
    lat: 34.7896102,
    lng: -86.4794359,
    squareFootage: "11000",
    propertyType: "Retail",
    totalCosts: "1624100",
    valueOfReclassifiedAssets: 444982,
    depreciableAssets: 33,
  },
  {
    lat: 40.477779,
    lng: -88.9892448,
    squareFootage: "3600",
    propertyType: "Retail",
    totalCosts: "1816229",
    valueOfReclassifiedAssets: 575425,
    depreciableAssets: 37,
  },
  {
    lat: 41.1091091,
    lng: -74.0828839,
    squareFootage: "15494",
    propertyType: "Multifamily",
    totalCosts: "2265886",
    valueOfReclassifiedAssets: 1988746,
    depreciableAssets: 18,
  },
  {
    lat: 26.1889227,
    lng: -80.1763467,
    squareFootage: "82778",
    propertyType: "Industrial",
    totalCosts: "9051800",
    valueOfReclassifiedAssets: 1536214,
    depreciableAssets: 27,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "32272",
    propertyType: "Multifamily",
    totalCosts: "8549599",
    valueOfReclassifiedAssets: 6952037,
    depreciableAssets: 20,
  },
  {
    lat: 36.105232,
    lng: -80.2042436,
    squareFootage: "55000",
    propertyType: "Office",
    totalCosts: "6401359",
    valueOfReclassifiedAssets: 1100872,
    depreciableAssets: 20,
  },
  {
    lat: 38.5558236,
    lng: -105.3131185,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2013578",
    valueOfReclassifiedAssets: 1746052,
    depreciableAssets: 22,
  },
  {
    lat: 40.9111613,
    lng: -74.1621634,
    squareFootage: "11406",
    propertyType: "Multifamily",
    totalCosts: "1348439",
    valueOfReclassifiedAssets: 138624,
    depreciableAssets: 16,
  },
  {
    lat: 37.9727529,
    lng: -121.3255688,
    squareFootage: "42315",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 1579034,
    depreciableAssets: 25,
  },
  {
    lat: 30.008721,
    lng: -90.0653341,
    squareFootage: "58200",
    propertyType: "Office",
    totalCosts: "29167000",
    valueOfReclassifiedAssets: 4584910,
    depreciableAssets: 18,
  },
  {
    lat: 40.72557219999999,
    lng: -73.8624893,
    squareFootage: "5894",
    propertyType: "Specialty",
    totalCosts: "6550000",
    valueOfReclassifiedAssets: 1875419,
    depreciableAssets: 34,
  },
  {
    lat: 36.4533112,
    lng: -97.9374948,
    squareFootage: "80450",
    propertyType: "Multifamily",
    totalCosts: "4387313",
    valueOfReclassifiedAssets: 1152416,
    depreciableAssets: 27,
  },
  {
    lat: 41.1091091,
    lng: -74.0828839,
    squareFootage: "15494",
    propertyType: "Multifamily",
    totalCosts: "2265886",
    valueOfReclassifiedAssets: 1988383,
    depreciableAssets: 19,
  },
  {
    lat: 42.4121386,
    lng: -73.6653939,
    squareFootage: "100559",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "26504586",
    valueOfReclassifiedAssets: 6390569,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "435000",
    valueOfReclassifiedAssets: 344700,
    depreciableAssets: 39,
  },
  {
    lat: 39.0488574,
    lng: -77.58486649999999,
    squareFootage: "260553",
    propertyType: "Retail",
    totalCosts: "175000000",
    valueOfReclassifiedAssets: 35225714,
    depreciableAssets: 23,
  },
  {
    lat: 42.3548561,
    lng: -71.0661193,
    squareFootage: "81000",
    propertyType: "Office",
    totalCosts: "29000000",
    valueOfReclassifiedAssets: 2288467,
    depreciableAssets: 10,
  },
  {
    lat: 32.7432998,
    lng: -96.78687939999999,
    squareFootage: "16400",
    propertyType: "Multifamily",
    totalCosts: "739657",
    valueOfReclassifiedAssets: 188785,
    depreciableAssets: 29,
  },
  {
    lat: 34.2037124,
    lng: -118.4571974,
    squareFootage: "14788",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3700000",
    valueOfReclassifiedAssets: 948849,
    depreciableAssets: 30,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "13700",
    propertyType: "Office",
    totalCosts: "2589023",
    valueOfReclassifiedAssets: 415844,
    depreciableAssets: 20,
  },
  {
    lat: 34.8083898,
    lng: -82.33433749999999,
    squareFootage: "18688",
    propertyType: "Office",
    totalCosts: "4282438",
    valueOfReclassifiedAssets: 856057,
    depreciableAssets: 24,
  },
  {
    lat: 40.7235275,
    lng: -74.197388,
    squareFootage: "187479",
    propertyType: "Office",
    totalCosts: "9150000",
    valueOfReclassifiedAssets: 901144,
    depreciableAssets: 12,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "14830",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6022136",
    valueOfReclassifiedAssets: 2053541,
    depreciableAssets: 42,
  },
  {
    lat: 26.1865098,
    lng: -80.22655449999999,
    squareFootage: "31390",
    propertyType: "Office",
    totalCosts: "5025000",
    valueOfReclassifiedAssets: 1603361,
    depreciableAssets: 37,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "186102",
    valueOfReclassifiedAssets: 51958,
    depreciableAssets: 0,
  },
  {
    lat: 40.9111613,
    lng: -74.1621634,
    squareFootage: "18972",
    propertyType: "Multifamily",
    totalCosts: "2071616",
    valueOfReclassifiedAssets: 201696,
    depreciableAssets: 15,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "77847",
    propertyType: "Multifamily",
    totalCosts: "1014047",
    valueOfReclassifiedAssets: 849571,
    depreciableAssets: 16,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "75066687",
    valueOfReclassifiedAssets: 64767828,
    depreciableAssets: 15,
  },
  {
    lat: 38.9860548,
    lng: -76.745195,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "854009",
    valueOfReclassifiedAssets: 812811,
    depreciableAssets: 18,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "1600",
    propertyType: "Office",
    totalCosts: "1927800",
    valueOfReclassifiedAssets: 1638630,
    depreciableAssets: 100,
  },
  {
    lat: 40.8597746,
    lng: -74.12986839999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1575000",
    valueOfReclassifiedAssets: 1222031,
    depreciableAssets: 0,
  },
  {
    lat: 32.8276924,
    lng: -96.679851,
    squareFootage: "68705",
    propertyType: "Multifamily",
    totalCosts: "4373335",
    valueOfReclassifiedAssets: 1026445,
    depreciableAssets: 27,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "46765",
    propertyType: "Multifamily",
    totalCosts: "3431094",
    valueOfReclassifiedAssets: 3031197,
    depreciableAssets: 22,
  },
  {
    lat: 42.7773453,
    lng: -71.4714928,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7537097",
    valueOfReclassifiedAssets: 5787157,
    depreciableAssets: 34,
  },
  {
    lat: 41.620939,
    lng: -83.75178509999999,
    squareFootage: "60771",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11009547",
    valueOfReclassifiedAssets: 3041170,
    depreciableAssets: 28,
  },
  {
    lat: 32.9458766,
    lng: -97.27607599999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3409340",
    valueOfReclassifiedAssets: 984764,
    depreciableAssets: 37,
  },
  {
    lat: 30.2193439,
    lng: -93.80448310000001,
    squareFootage: "120327",
    propertyType: "Multifamily",
    totalCosts: "9600000",
    valueOfReclassifiedAssets: 2251297,
    depreciableAssets: 27,
  },
  {
    lat: 40.3038147,
    lng: -74.0887576,
    squareFootage: "45410",
    propertyType: "Office",
    totalCosts: "10062881",
    valueOfReclassifiedAssets: 1481484,
    depreciableAssets: 18,
  },
  {
    lat: 41.0648622,
    lng: -72.4376499,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1912500",
    valueOfReclassifiedAssets: 476528,
    depreciableAssets: 29,
  },
  {
    lat: 33.058024,
    lng: -96.9799755,
    squareFootage: "79867",
    propertyType: "Office",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 901271,
    depreciableAssets: 26,
  },
  {
    lat: 40.4430064,
    lng: -74.4818698,
    squareFootage: "360210",
    propertyType: "Industrial",
    totalCosts: "18991182",
    valueOfReclassifiedAssets: 2263530,
    depreciableAssets: 14,
  },
  {
    lat: 38.3048885,
    lng: -77.4816693,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2288744",
    valueOfReclassifiedAssets: 1631167,
    depreciableAssets: 29,
  },
  {
    lat: 32.8601513,
    lng: -97.3641377,
    squareFootage: "61380",
    propertyType: "Industrial",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 226853,
    depreciableAssets: 19,
  },
  {
    lat: 42.2164077,
    lng: -83.2720082,
    squareFootage: "83540",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19625766",
    valueOfReclassifiedAssets: 5212177,
    depreciableAssets: 27,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "1011521",
    propertyType: "Industrial",
    totalCosts: "48920343",
    valueOfReclassifiedAssets: 7930767,
    depreciableAssets: 16,
  },
  {
    lat: 34.7566841,
    lng: -111.7376178,
    squareFootage: "116186",
    propertyType: "Retail",
    totalCosts: "9319889",
    valueOfReclassifiedAssets: 2032232,
    depreciableAssets: 26,
  },
  {
    lat: 39.9693,
    lng: -86.16500239999999,
    squareFootage: "31741",
    propertyType: "Office",
    totalCosts: "1830000",
    valueOfReclassifiedAssets: 1598404,
    depreciableAssets: 19,
  },
  {
    lat: 33.3762931,
    lng: -112.0357137,
    squareFootage: "94920",
    propertyType: "Multifamily",
    totalCosts: "10750000",
    valueOfReclassifiedAssets: 2700040,
    depreciableAssets: 30,
  },
  {
    lat: 35.4053803,
    lng: -88.6916315,
    squareFootage: "38886",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10038164",
    valueOfReclassifiedAssets: 4239403,
    depreciableAssets: 43,
  },
  {
    lat: 37.0127518,
    lng: -121.5598345,
    squareFootage: "44103",
    propertyType: "Industrial",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1039931,
    depreciableAssets: 32,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "44000",
    propertyType: "Multifamily",
    totalCosts: "3379492",
    valueOfReclassifiedAssets: 2834424,
    depreciableAssets: 16,
  },
  {
    lat: 41.9995834,
    lng: -71.51304449999999,
    squareFootage: "128970",
    propertyType: "Multifamily",
    totalCosts: "7168385",
    valueOfReclassifiedAssets: 2260302,
    depreciableAssets: 33,
  },
  {
    lat: 40.684934,
    lng: -74.3646122,
    squareFootage: "20000",
    propertyType: "Office",
    totalCosts: "3204681",
    valueOfReclassifiedAssets: 555600,
    depreciableAssets: 22,
  },
  {
    lat: 34.2386799,
    lng: -118.4805569,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6203798",
    valueOfReclassifiedAssets: 5458496,
    depreciableAssets: 14,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "21495",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "417370",
    valueOfReclassifiedAssets: 111313,
    depreciableAssets: 33,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "14830",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6022136",
    valueOfReclassifiedAssets: 2053541,
    depreciableAssets: 42,
  },
  {
    lat: 42.277306,
    lng: -83.26398119999999,
    squareFootage: "93425",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18611019",
    valueOfReclassifiedAssets: 5377016,
    depreciableAssets: 30,
  },
  {
    lat: 40.9087956,
    lng: -73.83308439999999,
    squareFootage: "47694",
    propertyType: "Industrial",
    totalCosts: "2825000",
    valueOfReclassifiedAssets: 329057,
    depreciableAssets: 14,
  },
  {
    lat: 35.0603204,
    lng: -89.64399240000002,
    squareFootage: "124992",
    propertyType: "Industrial",
    totalCosts: "6959367",
    valueOfReclassifiedAssets: 773987,
    depreciableAssets: 17,
  },
  {
    lat: 39.9693,
    lng: -86.16500239999999,
    squareFootage: "31741",
    propertyType: "Office",
    totalCosts: "3170000",
    valueOfReclassifiedAssets: 2768819,
    depreciableAssets: 19,
  },
  {
    lat: 40.8288377,
    lng: -73.9374517,
    squareFootage: "7700",
    propertyType: "Multifamily",
    totalCosts: "3650000",
    valueOfReclassifiedAssets: 551410,
    depreciableAssets: 18,
  },
  {
    lat: 36.5128173,
    lng: -87.2419299,
    squareFootage: "37700",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7685360",
    valueOfReclassifiedAssets: 2642116,
    depreciableAssets: 34,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "81600",
    propertyType: "Multifamily",
    totalCosts: "4165500",
    valueOfReclassifiedAssets: 3678376,
    depreciableAssets: 13,
  },
  {
    lat: 32.3512837,
    lng: -86.2412543,
    squareFootage: "5295",
    propertyType: "Office",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 334443,
    depreciableAssets: 32,
  },
  {
    lat: 41.6323583,
    lng: -85.0025539,
    squareFootage: "33860",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6916619",
    valueOfReclassifiedAssets: 2363815,
    depreciableAssets: 37,
  },
  {
    lat: 42.4812462,
    lng: -83.030767,
    squareFootage: "69949",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21812652",
    valueOfReclassifiedAssets: 5678342,
    depreciableAssets: 26,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "16320",
    propertyType: "Multifamily",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 253183,
    depreciableAssets: 22,
  },
  {
    lat: 35.2258075,
    lng: -89.9159785,
    squareFootage: "133107",
    propertyType: "Multifamily",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 459440,
    depreciableAssets: 24,
  },
  {
    lat: 41.1940762,
    lng: -76.02758589999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1024565",
    valueOfReclassifiedAssets: 943310,
    depreciableAssets: 40,
  },
  {
    lat: 34.6946278,
    lng: -118.1473771,
    squareFootage: "703952",
    propertyType: "Multifamily",
    totalCosts: "132000000",
    valueOfReclassifiedAssets: 34514136,
    depreciableAssets: 31,
  },
  {
    lat: 34.8505552,
    lng: -82.39399999999999,
    squareFootage: "231753",
    propertyType: "Multifamily",
    totalCosts: "21883663",
    valueOfReclassifiedAssets: 3675881,
    depreciableAssets: 19,
  },
  {
    lat: 34.0224471,
    lng: -118.2000277,
    squareFootage: "56175",
    propertyType: "Industrial",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 955482,
    depreciableAssets: 14,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "72735",
    propertyType: "Multifamily",
    totalCosts: "4626450",
    valueOfReclassifiedAssets: 4010828,
    depreciableAssets: 14,
  },
  {
    lat: 40.773694,
    lng: -74.0270745,
    squareFootage: "21540",
    propertyType: "Multifamily",
    totalCosts: "5356379",
    valueOfReclassifiedAssets: 845846,
    depreciableAssets: 19,
  },
  {
    lat: 32.2875944,
    lng: -101.4339148,
    squareFootage: "71149",
    propertyType: "Multifamily",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 982175,
    depreciableAssets: 32,
  },
  {
    lat: 35.005666,
    lng: -89.84113819999999,
    squareFootage: "658341",
    propertyType: "Industrial",
    totalCosts: "12796650",
    valueOfReclassifiedAssets: 1653078,
    depreciableAssets: 13,
  },
  {
    lat: 43.0373723,
    lng: -88.3919026,
    squareFootage: "11800",
    propertyType: "Office",
    totalCosts: "2295000",
    valueOfReclassifiedAssets: 606888,
    depreciableAssets: 30,
  },
  {
    lat: 38.692341,
    lng: -121.2282958,
    squareFootage: "162344",
    propertyType: "Retail",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 4347893,
    depreciableAssets: 22,
  },
  {
    lat: 37.1224466,
    lng: -76.4447397,
    squareFootage: "26820",
    propertyType: "Retail",
    totalCosts: "4503019",
    valueOfReclassifiedAssets: 945703,
    depreciableAssets: 30,
  },
  {
    lat: 35.2263714,
    lng: -80.7990185,
    squareFootage: "80200",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 2047226,
    depreciableAssets: 34,
  },
  {
    lat: 39.4579485,
    lng: -85.6743082,
    squareFootage: "30946",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5867203",
    valueOfReclassifiedAssets: 1930504,
    depreciableAssets: 35,
  },
  {
    lat: 36.3091869,
    lng: -82.49693309999999,
    squareFootage: "29177",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5282372",
    valueOfReclassifiedAssets: 2033899,
    depreciableAssets: 39,
  },
  {
    lat: 41.7976163,
    lng: -87.9775787,
    squareFootage: "61600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7674890",
    valueOfReclassifiedAssets: 1742548,
    depreciableAssets: 25,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "59400",
    propertyType: "Multifamily",
    totalCosts: "4116725",
    valueOfReclassifiedAssets: 3512628,
    depreciableAssets: 16,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "40916",
    propertyType: "Multifamily",
    totalCosts: "3967664",
    valueOfReclassifiedAssets: 3326280,
    depreciableAssets: 16,
  },
  {
    lat: 40.8924932,
    lng: -74.0123851,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1573208",
    valueOfReclassifiedAssets: 850984,
    depreciableAssets: 61,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "37300",
    propertyType: "Multifamily",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 640592,
    depreciableAssets: 29,
  },
  {
    lat: 34.0224471,
    lng: -118.2000277,
    squareFootage: "39033",
    propertyType: "Industrial",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 645323,
    depreciableAssets: 14,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 6963520,
    depreciableAssets: 13,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "104865",
    propertyType: "Office",
    totalCosts: "39857500",
    valueOfReclassifiedAssets: 5348351,
    depreciableAssets: 15,
  },
  {
    lat: 35.2368232,
    lng: -90.0186555,
    squareFootage: "93364",
    propertyType: "Multifamily",
    totalCosts: "1596000",
    valueOfReclassifiedAssets: 377559,
    depreciableAssets: 28,
  },
  {
    lat: 41.8394458,
    lng: -72.7333604,
    squareFootage: "259878",
    propertyType: "Multifamily",
    totalCosts: "53325000",
    valueOfReclassifiedAssets: 11425928,
    depreciableAssets: 25,
  },
  {
    lat: 40.7218318,
    lng: -74.0447003,
    squareFootage: "7400",
    propertyType: "Multifamily",
    totalCosts: "36000000",
    valueOfReclassifiedAssets: 4750943,
    depreciableAssets: 16,
  },
  {
    lat: 32.7747949,
    lng: -97.5138947,
    squareFootage: "83000",
    propertyType: "Multifamily",
    totalCosts: "5050000",
    valueOfReclassifiedAssets: 1748185,
    depreciableAssets: 41,
  },
  {
    lat: 33.5282033,
    lng: -112.2205183,
    squareFootage: "120880",
    propertyType: "Multifamily",
    totalCosts: "11635000",
    valueOfReclassifiedAssets: 3730322,
    depreciableAssets: 37,
  },
  {
    lat: 30.0118752,
    lng: -95.4463322,
    squareFootage: "119650",
    propertyType: "Multifamily",
    totalCosts: "10520000",
    valueOfReclassifiedAssets: 3149668,
    depreciableAssets: 34,
  },
  {
    lat: 40.2981184,
    lng: -86.5400674,
    squareFootage: "42120",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1439366",
    valueOfReclassifiedAssets: 368800,
    depreciableAssets: 28,
  },
  {
    lat: 40.9111613,
    lng: -74.1621634,
    squareFootage: "14019",
    propertyType: "Multifamily",
    totalCosts: "1660754",
    valueOfReclassifiedAssets: 189433,
    depreciableAssets: 19,
  },
  {
    lat: 33.7344959,
    lng: -84.1118458,
    squareFootage: "284474",
    propertyType: "Multifamily",
    totalCosts: "32000000",
    valueOfReclassifiedAssets: 10343029,
    depreciableAssets: 36,
  },
  {
    lat: 33.2288218,
    lng: -86.7919009,
    squareFootage: "87284",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9200000",
    valueOfReclassifiedAssets: 2364433,
    depreciableAssets: 30,
  },
  {
    lat: 38.9191485,
    lng: -77.0362967,
    squareFootage: "7578",
    propertyType: "Multifamily",
    totalCosts: "2081308",
    valueOfReclassifiedAssets: 327065,
    depreciableAssets: 19,
  },
  {
    lat: 28.2356313,
    lng: -82.7455027,
    squareFootage: "19397",
    propertyType: "Office",
    totalCosts: "1439202",
    valueOfReclassifiedAssets: 305301,
    depreciableAssets: 25,
  },
  {
    lat: 38.9795743,
    lng: -94.7142588,
    squareFootage: "135804",
    propertyType: "Industrial",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 555751,
    depreciableAssets: 19,
  },
  {
    lat: 40.6189813,
    lng: -74.3176876,
    squareFootage: "106289",
    propertyType: "Office",
    totalCosts: "15728517",
    valueOfReclassifiedAssets: 2548710,
    depreciableAssets: 19,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13580817",
    valueOfReclassifiedAssets: 11643421,
    depreciableAssets: 15,
  },
  {
    lat: 41.9085895,
    lng: -79.67266320000002,
    squareFootage: "110812",
    propertyType: "Retail",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 888099,
    depreciableAssets: 28,
  },
  {
    lat: 32.7918963,
    lng: -83.6029116,
    squareFootage: "55980",
    propertyType: "Office",
    totalCosts: "2612403",
    valueOfReclassifiedAssets: 335739,
    depreciableAssets: 15,
  },
  {
    lat: 41.5132913,
    lng: -87.962884,
    squareFootage: "36025",
    propertyType: "Retail",
    totalCosts: "4520000",
    valueOfReclassifiedAssets: 1268525,
    depreciableAssets: 33,
  },
  {
    lat: 32.5704036,
    lng: -96.76010570000001,
    squareFootage: "334920",
    propertyType: "Multifamily",
    totalCosts: "36500000",
    valueOfReclassifiedAssets: 9978388,
    depreciableAssets: 32,
  },
  {
    lat: 42.5792379,
    lng: -71.3052383,
    squareFootage: "12472",
    propertyType: "Office",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 177189,
    depreciableAssets: 23,
  },
  {
    lat: 41.0521864,
    lng: -85.2411958,
    squareFootage: "45177",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5014292",
    valueOfReclassifiedAssets: 1705469,
    depreciableAssets: 34,
  },
  {
    lat: 33.4165524,
    lng: -86.681253,
    squareFootage: "6000",
    propertyType: "Retail",
    totalCosts: "948000",
    valueOfReclassifiedAssets: 179632,
    depreciableAssets: 22,
  },
  {
    lat: 38.3177376,
    lng: -75.55096019999999,
    squareFootage: "132422",
    propertyType: "Multifamily",
    totalCosts: "15750000",
    valueOfReclassifiedAssets: 4256067,
    depreciableAssets: 32,
  },
  {
    lat: 41.1876338,
    lng: -74.0828839,
    squareFootage: "44173",
    propertyType: "Industrial",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1451650,
    depreciableAssets: 26,
  },
  {
    lat: 31.5923712,
    lng: -97.0713572,
    squareFootage: "102480",
    propertyType: "Multifamily",
    totalCosts: "10024000",
    valueOfReclassifiedAssets: 3272188,
    depreciableAssets: 36,
  },
  {
    lat: 33.5479366,
    lng: -101.9176916,
    squareFootage: "70165",
    propertyType: "Multifamily",
    totalCosts: "2941000",
    valueOfReclassifiedAssets: 628474,
    depreciableAssets: 25,
  },
  {
    lat: 40.7989068,
    lng: -74.1885825,
    squareFootage: "450000",
    propertyType: "Multifamily",
    totalCosts: "96673760",
    valueOfReclassifiedAssets: 13030482,
    depreciableAssets: 16,
  },
  {
    lat: 45.5478224,
    lng: -122.7288206,
    squareFootage: "43976",
    propertyType: "Office",
    totalCosts: "5663910",
    valueOfReclassifiedAssets: 463222,
    depreciableAssets: 10,
  },
  {
    lat: 32.8276924,
    lng: -96.679851,
    squareFootage: "67530",
    propertyType: "Multifamily",
    totalCosts: "4523000",
    valueOfReclassifiedAssets: 1113617,
    depreciableAssets: 29,
  },
  {
    lat: 39.9721714,
    lng: -82.8694464,
    squareFootage: "4045",
    propertyType: "Retail",
    totalCosts: "1550000",
    valueOfReclassifiedAssets: 548622,
    depreciableAssets: 41,
  },
  {
    lat: 33.5388471,
    lng: -111.9641728,
    squareFootage: "7316",
    propertyType: "Multifamily",
    totalCosts: "2425000",
    valueOfReclassifiedAssets: 691977,
    depreciableAssets: 41,
  },
  {
    lat: 36.0765176,
    lng: -115.1533426,
    squareFootage: "27616",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1525277,
    depreciableAssets: 36,
  },
  {
    lat: 40.6980708,
    lng: -73.8948295,
    squareFootage: "20880",
    propertyType: "Multifamily",
    totalCosts: "15955000",
    valueOfReclassifiedAssets: 720738,
    depreciableAssets: 5,
  },
  {
    lat: 36.6159085,
    lng: -82.1659243,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11749315",
    valueOfReclassifiedAssets: 10020798,
    depreciableAssets: 19,
  },
  {
    lat: 37.6107909,
    lng: -121.1022942,
    squareFootage: "241636",
    propertyType: "Industrial",
    totalCosts: "4005228",
    valueOfReclassifiedAssets: 660850,
    depreciableAssets: 20,
  },
  {
    lat: 33.9873389,
    lng: -81.0368211,
    squareFootage: "528489",
    propertyType: "Office",
    totalCosts: "38408003",
    valueOfReclassifiedAssets: 30453657,
    depreciableAssets: 22,
  },
  {
    lat: 43.0073961,
    lng: -71.49523719999999,
    squareFootage: "22830",
    propertyType: "Office",
    totalCosts: "1225000",
    valueOfReclassifiedAssets: 275632,
    depreciableAssets: 25,
  },
  {
    lat: 32.7891446,
    lng: -97.1736385,
    squareFootage: "58700",
    propertyType: "Multifamily",
    totalCosts: "3828035",
    valueOfReclassifiedAssets: 1159017,
    depreciableAssets: 35,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "117600",
    propertyType: "Multifamily",
    totalCosts: "14153185",
    valueOfReclassifiedAssets: 1136941,
    depreciableAssets: 20,
  },
  {
    lat: 34.965225,
    lng: -81.5596427,
    squareFootage: "79646",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1120087",
    valueOfReclassifiedAssets: 864100,
    depreciableAssets: 32,
  },
  {
    lat: 26.2632309,
    lng: -81.7239979,
    squareFootage: "59833",
    propertyType: "Retail",
    totalCosts: "4285714",
    valueOfReclassifiedAssets: 942254,
    depreciableAssets: 26,
  },
  {
    lat: 25.7667897,
    lng: -80.20703280000001,
    squareFootage: "9754",
    propertyType: "Multifamily",
    totalCosts: "1175000",
    valueOfReclassifiedAssets: 246042,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2158509",
    valueOfReclassifiedAssets: 1687185,
    depreciableAssets: 25,
  },
  {
    lat: 40.7476654,
    lng: -73.6389038,
    squareFootage: "36948",
    propertyType: "Multifamily",
    totalCosts: "13150000",
    valueOfReclassifiedAssets: 1284683,
    depreciableAssets: 11,
  },
  {
    lat: 39.2963369,
    lng: -76.62105389999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9108020",
    valueOfReclassifiedAssets: 6739105,
    depreciableAssets: 27,
  },
  {
    lat: 36.0091467,
    lng: -83.97971729999999,
    squareFootage: "10411",
    propertyType: "Specialty",
    totalCosts: "4270000",
    valueOfReclassifiedAssets: 1308462,
    depreciableAssets: 36,
  },
  {
    lat: 36.0357492,
    lng: -115.1533426,
    squareFootage: "51566",
    propertyType: "Retail",
    totalCosts: "8925000",
    valueOfReclassifiedAssets: 1943214,
    depreciableAssets: 26,
  },
  {
    lat: 47.2019421,
    lng: -122.4780244,
    squareFootage: "68124",
    propertyType: "Office",
    totalCosts: "6072853",
    valueOfReclassifiedAssets: 900198,
    depreciableAssets: 19,
  },
  {
    lat: 42.6303002,
    lng: -71.2992993,
    squareFootage: "24860",
    propertyType: "Office",
    totalCosts: "846000",
    valueOfReclassifiedAssets: 210181,
    depreciableAssets: 27,
  },
  {
    lat: 41.1983543,
    lng: -73.712479,
    squareFootage: "3669",
    propertyType: "Specialty",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 2207926,
    depreciableAssets: 44,
  },
  {
    lat: 32.9418531,
    lng: -96.8002704,
    squareFootage: "170621",
    propertyType: "Multifamily",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 3317365,
    depreciableAssets: 39,
  },
  {
    lat: 40.1313166,
    lng: -87.6285099,
    squareFootage: "59136",
    propertyType: "Multifamily",
    totalCosts: "7976537",
    valueOfReclassifiedAssets: 916514,
    depreciableAssets: 12,
  },
  {
    lat: 34.92624910000001,
    lng: -82.4644445,
    squareFootage: "96625",
    propertyType: "Multifamily",
    totalCosts: "4611794",
    valueOfReclassifiedAssets: 1223064,
    depreciableAssets: 29,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "61600",
    propertyType: "Multifamily",
    totalCosts: "3414000",
    valueOfReclassifiedAssets: 698616,
    depreciableAssets: 24,
  },
  {
    lat: 42.7768318,
    lng: -71.2161428,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17923611",
    valueOfReclassifiedAssets: 15913016,
    depreciableAssets: 20,
  },
  {
    lat: 33.4306545,
    lng: -86.7919009,
    squareFootage: "139880",
    propertyType: "Multifamily",
    totalCosts: "9113625",
    valueOfReclassifiedAssets: 1942327,
    depreciableAssets: 33,
  },
  {
    lat: 38.1248882,
    lng: -84.5012435,
    squareFootage: "167241",
    propertyType: "Office",
    totalCosts: "16858917",
    valueOfReclassifiedAssets: 15064620,
    depreciableAssets: 21,
  },
  {
    lat: 40.76074300000001,
    lng: -73.7977928,
    squareFootage: "38599",
    propertyType: "Multifamily",
    totalCosts: "12750000",
    valueOfReclassifiedAssets: 1528053,
    depreciableAssets: 14,
  },
  {
    lat: 34.965225,
    lng: -81.5596427,
    squareFootage: "79646",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4480349",
    valueOfReclassifiedAssets: 3456404,
    depreciableAssets: 32,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "422480",
    propertyType: "Multifamily",
    totalCosts: "33367282",
    valueOfReclassifiedAssets: 7943350,
    depreciableAssets: 29,
  },
  {
    lat: 39.4835647,
    lng: -119.7310213,
    squareFootage: "78240",
    propertyType: "Office",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 1093525,
    depreciableAssets: 18,
  },
  {
    lat: 43.0073961,
    lng: -71.49523719999999,
    squareFootage: "15209",
    propertyType: "Retail",
    totalCosts: "1313000",
    valueOfReclassifiedAssets: 325875,
    depreciableAssets: 28,
  },
  {
    lat: 38.3176139,
    lng: -75.2245693,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "23324310",
    valueOfReclassifiedAssets: 22181687,
    depreciableAssets: 24,
  },
  {
    lat: 42.0646736,
    lng: -87.93838219999999,
    squareFootage: "70700",
    propertyType: "Multifamily",
    totalCosts: "16038425",
    valueOfReclassifiedAssets: 1403811,
    depreciableAssets: 9,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "117952",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2589828,
    depreciableAssets: 34,
  },
  {
    lat: 39.9518394,
    lng: -82.7886395,
    squareFootage: "59005",
    propertyType: "Retail",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 2212349,
    depreciableAssets: 26,
  },
  {
    lat: 33.2244703,
    lng: -97.1036396,
    squareFootage: "39400",
    propertyType: "Multifamily",
    totalCosts: "3240000",
    valueOfReclassifiedAssets: 995041,
    depreciableAssets: 34,
  },
  {
    lat: 41.9202827,
    lng: -88.29970639999999,
    squareFootage: "48450",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1103569,
    depreciableAssets: 31,
  },
  {
    lat: 32.2556612,
    lng: -95.3207069,
    squareFootage: "14126",
    propertyType: "Retail",
    totalCosts: "2465230",
    valueOfReclassifiedAssets: 953063,
    depreciableAssets: 42,
  },
  {
    lat: 39.167568,
    lng: -76.5892774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1606480",
    valueOfReclassifiedAssets: 1196211,
    depreciableAssets: 0,
  },
  {
    lat: 30.4649282,
    lng: -84.38574419999999,
    squareFootage: "20400",
    propertyType: "Multifamily",
    totalCosts: "1810000",
    valueOfReclassifiedAssets: 503117,
    depreciableAssets: 33,
  },
  {
    lat: 35.6944147,
    lng: -83.46435509999999,
    squareFootage: "29907",
    propertyType: "Multifamily",
    totalCosts: "1127519",
    valueOfReclassifiedAssets: 1053118,
    depreciableAssets: 21,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6700000",
    valueOfReclassifiedAssets: 3107714,
    depreciableAssets: 0,
  },
  {
    lat: 45.0155505,
    lng: -93.0435224,
    squareFootage: "11032",
    propertyType: "Retail",
    totalCosts: "4969711",
    valueOfReclassifiedAssets: 777707,
    depreciableAssets: 23,
  },
  {
    lat: 40.87347,
    lng: -73.8272029,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9606605",
    valueOfReclassifiedAssets: 4885855,
    depreciableAssets: 66,
  },
  {
    lat: 34.1599885,
    lng: -119.1274334,
    squareFootage: "23906",
    propertyType: "Multifamily",
    totalCosts: "4450000",
    valueOfReclassifiedAssets: 1005885,
    depreciableAssets: 27,
  },
  {
    lat: 39.2081349,
    lng: -75.45777749999999,
    squareFootage: "91277",
    propertyType: "Multifamily",
    totalCosts: "20400000",
    valueOfReclassifiedAssets: 4633865,
    depreciableAssets: 27,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "28240",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 407413,
    depreciableAssets: 30,
  },
  {
    lat: 35.6396304,
    lng: -80.38813329999999,
    squareFootage: "30720",
    propertyType: "Multifamily",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 421308,
    depreciableAssets: 28,
  },
  {
    lat: 40.804574,
    lng: -96.6157192,
    squareFootage: "90080",
    propertyType: "Multifamily",
    totalCosts: "7960000",
    valueOfReclassifiedAssets: 1516105,
    depreciableAssets: 23,
  },
  {
    lat: 42.0646736,
    lng: -87.93838219999999,
    squareFootage: "63050",
    propertyType: "Multifamily",
    totalCosts: "15227141",
    valueOfReclassifiedAssets: 1801490,
    depreciableAssets: 13,
  },
  {
    lat: 25.7667897,
    lng: -80.20703280000001,
    squareFootage: "51774",
    propertyType: "Multifamily",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 2044526,
    depreciableAssets: 23,
  },
  {
    lat: 32.7918963,
    lng: -83.6029116,
    squareFootage: "101500",
    propertyType: "Office",
    totalCosts: "2612403",
    valueOfReclassifiedAssets: 295395,
    depreciableAssets: 14,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "132822",
    propertyType: "Multifamily",
    totalCosts: "5600000",
    valueOfReclassifiedAssets: 956953,
    depreciableAssets: 20,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "135773",
    propertyType: "Industrial",
    totalCosts: "19973200",
    valueOfReclassifiedAssets: 506636,
    depreciableAssets: 0,
  },
  {
    lat: 37.83382450000001,
    lng: -85.9202327,
    squareFootage: "46950",
    propertyType: "Multifamily",
    totalCosts: "1995000",
    valueOfReclassifiedAssets: 452875,
    depreciableAssets: 25,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "47842",
    propertyType: "Multifamily",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1396603,
    depreciableAssets: 30,
  },
  {
    lat: 32.0563741,
    lng: -81.0478643,
    squareFootage: "126808",
    propertyType: "Multifamily",
    totalCosts: "10325000",
    valueOfReclassifiedAssets: 2586908,
    depreciableAssets: 30,
  },
  {
    lat: 25.7667897,
    lng: -80.20703280000001,
    squareFootage: "5511",
    propertyType: "Multifamily",
    totalCosts: "1225000",
    valueOfReclassifiedAssets: 331422,
    depreciableAssets: 30,
  },
  {
    lat: 45.5461766,
    lng: -73.6582883,
    squareFootage: "25254",
    propertyType: "Retail",
    totalCosts: "5912502",
    valueOfReclassifiedAssets: 1566245,
    depreciableAssets: 36,
  },
  {
    lat: 40.5895272,
    lng: -73.6389038,
    squareFootage: "39",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16174575",
    valueOfReclassifiedAssets: 2870132,
    depreciableAssets: 21,
  },
  {
    lat: 37.7877522,
    lng: -122.4382307,
    squareFootage: "16639",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7972614",
    valueOfReclassifiedAssets: 1936393,
    depreciableAssets: 30,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8906261",
    valueOfReclassifiedAssets: 8217495,
    depreciableAssets: 9,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "14664",
    propertyType: "Office",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 8670000,
    depreciableAssets: 100,
  },
  {
    lat: 34.1985119,
    lng: -118.4980744,
    squareFootage: "22288",
    propertyType: "Industrial",
    totalCosts: "3275000",
    valueOfReclassifiedAssets: 239610,
    depreciableAssets: 11,
  },
  {
    lat: 40.6749343,
    lng: -73.8713099,
    squareFootage: "9881",
    propertyType: "Retail",
    totalCosts: "3899018",
    valueOfReclassifiedAssets: 1092462,
    depreciableAssets: 31,
  },
  {
    lat: 41.9003099,
    lng: -87.63338710000001,
    squareFootage: "185220",
    propertyType: "Multifamily",
    totalCosts: "55293966",
    valueOfReclassifiedAssets: 4350449,
    depreciableAssets: 9,
  },
  {
    lat: 43.9959887,
    lng: -75.92309759999999,
    squareFootage: "70350",
    propertyType: "Multifamily",
    totalCosts: "3719886",
    valueOfReclassifiedAssets: 1019701,
    depreciableAssets: 32,
  },
  {
    lat: 41.8101721,
    lng: -73.90070899999999,
    squareFootage: "220076",
    propertyType: "Multifamily",
    totalCosts: "32800000",
    valueOfReclassifiedAssets: 7181390,
    depreciableAssets: 26,
  },
  {
    lat: 35.2010926,
    lng: -81.04234290000001,
    squareFootage: "169120",
    propertyType: "Multifamily",
    totalCosts: "16720000",
    valueOfReclassifiedAssets: 4583865,
    depreciableAssets: 32,
  },
  {
    lat: 33.4767802,
    lng: -117.1970557,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "678542",
    valueOfReclassifiedAssets: 548092,
    depreciableAssets: 0,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "135773",
    propertyType: "Industrial",
    totalCosts: "6026800",
    valueOfReclassifiedAssets: 152875,
    depreciableAssets: 0,
  },
  {
    lat: 26.2632309,
    lng: -81.7239979,
    squareFootage: "59833",
    propertyType: "Retail",
    totalCosts: "8530183",
    valueOfReclassifiedAssets: 1875438,
    depreciableAssets: 26,
  },
  {
    lat: 35.6944147,
    lng: -83.46435509999999,
    squareFootage: "59827",
    propertyType: "Multifamily",
    totalCosts: "2162000",
    valueOfReclassifiedAssets: 1898289,
    depreciableAssets: 25,
  },
  {
    lat: 34.187499,
    lng: -80.8322428,
    squareFootage: "258094",
    propertyType: "Multifamily",
    totalCosts: "25500000",
    valueOfReclassifiedAssets: 9768853,
    depreciableAssets: 39,
  },
  {
    lat: 41.23403690000001,
    lng: -96.11559319999999,
    squareFootage: "59084",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13092366",
    valueOfReclassifiedAssets: 2392295,
    depreciableAssets: 22,
  },
  {
    lat: 32.886946,
    lng: -83.4856913,
    squareFootage: "31340",
    propertyType: "Retail",
    totalCosts: "1552549",
    valueOfReclassifiedAssets: 425303,
    depreciableAssets: 29,
  },
  {
    lat: 40.8786991,
    lng: -73.4062342,
    squareFootage: "30184",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2566852,
    depreciableAssets: 27,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "128668",
    propertyType: "Multifamily",
    totalCosts: "7125000",
    valueOfReclassifiedAssets: 1742625,
    depreciableAssets: 28,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "14720",
    propertyType: "Multifamily",
    totalCosts: "2004032",
    valueOfReclassifiedAssets: 227444,
    depreciableAssets: 13,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "117600",
    propertyType: "Multifamily",
    totalCosts: "13600000",
    valueOfReclassifiedAssets: 4067177,
    depreciableAssets: 32,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "4570",
    propertyType: "Retail",
    totalCosts: "1715460",
    valueOfReclassifiedAssets: 436438,
    depreciableAssets: 28,
  },
  {
    lat: 40.684934,
    lng: -74.3646122,
    squareFootage: "106712",
    propertyType: "Office",
    totalCosts: "15170922",
    valueOfReclassifiedAssets: 1925682,
    depreciableAssets: 15,
  },
  {
    lat: 37.161924,
    lng: -79.6277523,
    squareFootage: "10400",
    propertyType: "Hotel",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 4529426,
    depreciableAssets: 64,
  },
  {
    lat: 41.9013087,
    lng: -87.68276759999999,
    squareFootage: "73700",
    propertyType: "Multifamily",
    totalCosts: "36207132",
    valueOfReclassifiedAssets: 3727656,
    depreciableAssets: 11,
  },
  {
    lat: 40.56829,
    lng: -74.41738769999999,
    squareFootage: "142164",
    propertyType: "Office",
    totalCosts: "15592500",
    valueOfReclassifiedAssets: 3034487,
    depreciableAssets: 23,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1640361",
    valueOfReclassifiedAssets: 1198184,
    depreciableAssets: 0,
  },
  {
    lat: 42.5159385,
    lng: -83.53900019999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13275000",
    valueOfReclassifiedAssets: 11069754,
    depreciableAssets: 33,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "7715",
    propertyType: "Retail",
    totalCosts: "3675986",
    valueOfReclassifiedAssets: 697050,
    depreciableAssets: 21,
  },
  {
    lat: 40.773694,
    lng: -74.0270745,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25582706",
    valueOfReclassifiedAssets: 21753680,
    depreciableAssets: 15,
  },
  {
    lat: 39.6751269,
    lng: -104.9680914,
    squareFootage: "21674",
    propertyType: "Multifamily",
    totalCosts: "4480691",
    valueOfReclassifiedAssets: 889047,
    depreciableAssets: 25,
  },
  {
    lat: 34.9838584,
    lng: -82.33433749999999,
    squareFootage: "46163",
    propertyType: "Multifamily",
    totalCosts: "4025000",
    valueOfReclassifiedAssets: 1087466,
    depreciableAssets: 32,
  },
  {
    lat: 38.9457197,
    lng: -77.2979782,
    squareFootage: "40833",
    propertyType: "Retail",
    totalCosts: "44250000",
    valueOfReclassifiedAssets: 4605457,
    depreciableAssets: 20,
  },
  {
    lat: 40.6986257,
    lng: -73.6241855,
    squareFootage: "124831",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1458031,
    depreciableAssets: 17,
  },
  {
    lat: 41.0887754,
    lng: -74.1827119,
    squareFootage: "88491",
    propertyType: "Office",
    totalCosts: "11544000",
    valueOfReclassifiedAssets: 2452417,
    depreciableAssets: 25,
  },
  {
    lat: 38.3060189,
    lng: -104.5422225,
    squareFootage: "131492",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1813571,
    depreciableAssets: 23,
  },
  {
    lat: 33.8092255,
    lng: -84.2805478,
    squareFootage: "254850",
    propertyType: "Multifamily",
    totalCosts: "34250000",
    valueOfReclassifiedAssets: 9293601,
    depreciableAssets: 32,
  },
  {
    lat: 40.477779,
    lng: -88.9892448,
    squareFootage: "3600",
    propertyType: "Retail",
    totalCosts: "1816229",
    valueOfReclassifiedAssets: 575425,
    depreciableAssets: 37,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "6956",
    propertyType: "Multifamily",
    totalCosts: "2325000",
    valueOfReclassifiedAssets: 157474,
    depreciableAssets: 8,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "10000",
    propertyType: "Retail",
    totalCosts: "5146381",
    valueOfReclassifiedAssets: 871271,
    depreciableAssets: 19,
  },
  {
    lat: 40.4031697,
    lng: -74.22967129999999,
    squareFootage: "378918",
    propertyType: "Multifamily",
    totalCosts: "74000000",
    valueOfReclassifiedAssets: 16287688,
    depreciableAssets: 26,
  },
  {
    lat: 38.9071042,
    lng: -94.8728093,
    squareFootage: "38587",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7049865",
    valueOfReclassifiedAssets: 1709015,
    depreciableAssets: 30,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "211450",
    propertyType: "Industrial",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 2280535,
    depreciableAssets: 12,
  },
  {
    lat: 40.8464274,
    lng: -79.6614376,
    squareFootage: "13752",
    propertyType: "Multifamily",
    totalCosts: "1125000",
    valueOfReclassifiedAssets: 541194,
    depreciableAssets: 57,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "49452",
    propertyType: "Retail",
    totalCosts: "7428316",
    valueOfReclassifiedAssets: 6563568,
    depreciableAssets: 19,
  },
  {
    lat: 39.9559288,
    lng: -75.1574567,
    squareFootage: "9000",
    propertyType: "Retail",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 284057,
    depreciableAssets: 11,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "8300",
    propertyType: "Retail",
    totalCosts: "3675986",
    valueOfReclassifiedAssets: 687181,
    depreciableAssets: 20,
  },
  {
    lat: 28.4750405,
    lng: -81.2849169,
    squareFootage: "18470",
    propertyType: "Retail",
    totalCosts: "23600000",
    valueOfReclassifiedAssets: 5036130,
    depreciableAssets: 29,
  },
  {
    lat: 32.0632399,
    lng: -84.16461629999999,
    squareFootage: "45234",
    propertyType: "Retail",
    totalCosts: "1447078",
    valueOfReclassifiedAssets: 453536,
    depreciableAssets: 38,
  },
  {
    lat: 39.7312095,
    lng: -104.9826965,
    squareFootage: "23478",
    propertyType: "Multifamily",
    totalCosts: "2688289",
    valueOfReclassifiedAssets: 620835,
    depreciableAssets: 31,
  },
  {
    lat: 32.5135255,
    lng: -96.7708138,
    squareFootage: "38400",
    propertyType: "Multifamily",
    totalCosts: "4700000",
    valueOfReclassifiedAssets: 1440723,
    depreciableAssets: 36,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "6285",
    propertyType: "Retail",
    totalCosts: "3038815",
    valueOfReclassifiedAssets: 957247,
    depreciableAssets: 35,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "30000",
    propertyType: "Multifamily",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 756887,
    depreciableAssets: 9,
  },
  {
    lat: 40.3145207,
    lng: -88.14865700000001,
    squareFootage: "421449",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1460201,
    depreciableAssets: 25,
  },
  {
    lat: 37.5044419,
    lng: -77.46446069999999,
    squareFootage: "203256",
    propertyType: "Multifamily",
    totalCosts: "17500000",
    valueOfReclassifiedAssets: 3337168,
    depreciableAssets: 21,
  },
  {
    lat: 40.575405,
    lng: -74.505312,
    squareFootage: "241054",
    propertyType: "Industrial",
    totalCosts: "17400000",
    valueOfReclassifiedAssets: 1626418,
    depreciableAssets: 11,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "109943",
    propertyType: "Multifamily",
    totalCosts: "11350000",
    valueOfReclassifiedAssets: 3284342,
    depreciableAssets: 34,
  },
  {
    lat: 38.6272793,
    lng: -121.3198491,
    squareFootage: "35646",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "490000",
    valueOfReclassifiedAssets: 397287,
    depreciableAssets: 26,
  },
  {
    lat: 34.6741181,
    lng: -92.3517526,
    squareFootage: "20808",
    propertyType: "Office",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 680643,
    depreciableAssets: 28,
  },
  {
    lat: 36.0612353,
    lng: -115.2896852,
    squareFootage: "42008",
    propertyType: "Specialty",
    totalCosts: "10250000",
    valueOfReclassifiedAssets: 2668836,
    depreciableAssets: 33,
  },
  {
    lat: 40.8300731,
    lng: -73.86836970000002,
    squareFootage: "6260",
    propertyType: "Retail",
    totalCosts: "2328125",
    valueOfReclassifiedAssets: 546105,
    depreciableAssets: 26,
  },
  {
    lat: 35.578861,
    lng: -97.5788975,
    squareFootage: "131882",
    propertyType: "Retail",
    totalCosts: "7845391",
    valueOfReclassifiedAssets: 1555128,
    depreciableAssets: 23,
  },
  {
    lat: 32.368714,
    lng: -95.3207069,
    squareFootage: "23499",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 938372,
    depreciableAssets: 37,
  },
  {
    lat: 29.9456874,
    lng: -81.73494199999999,
    squareFootage: "55273",
    propertyType: "Retail",
    totalCosts: "7850000",
    valueOfReclassifiedAssets: 2110710,
    depreciableAssets: 31,
  },
  {
    lat: 42.5295344,
    lng: -71.4893013,
    squareFootage: "100994",
    propertyType: "Office",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1237869,
    depreciableAssets: 30,
  },
  {
    lat: 33.445974,
    lng: -111.8032005,
    squareFootage: "64350",
    propertyType: "Multifamily",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1941437,
    depreciableAssets: 32,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "203547",
    propertyType: "Retail",
    totalCosts: "56361599",
    valueOfReclassifiedAssets: 9976502,
    depreciableAssets: 21,
  },
  {
    lat: 29.53896599999999,
    lng: -98.42390549999999,
    squareFootage: "259472",
    propertyType: "Multifamily",
    totalCosts: "18052000",
    valueOfReclassifiedAssets: 5150656,
    depreciableAssets: 34,
  },
  {
    lat: 40.1763409,
    lng: -75.2128996,
    squareFootage: "61402",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10087658",
    valueOfReclassifiedAssets: 3380917,
    depreciableAssets: 39,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "1960",
    propertyType: "Retail",
    totalCosts: "931250",
    valueOfReclassifiedAssets: 167233,
    depreciableAssets: 20,
  },
  {
    lat: 39.1045785,
    lng: -94.58952939999999,
    squareFootage: "20705",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2530566",
    valueOfReclassifiedAssets: 742288,
    depreciableAssets: 32,
  },
  {
    lat: 33.9167378,
    lng: -118.1298234,
    squareFootage: "36206",
    propertyType: "Multifamily",
    totalCosts: "12575000",
    valueOfReclassifiedAssets: 3960955,
    depreciableAssets: 37,
  },
  {
    lat: 26.1862754,
    lng: -97.76340189999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7383910",
    valueOfReclassifiedAssets: 6346488,
    depreciableAssets: 31,
  },
  {
    lat: 40.8300731,
    lng: -73.86836970000002,
    squareFootage: "4429",
    propertyType: "Retail",
    totalCosts: "4068091",
    valueOfReclassifiedAssets: 1362580,
    depreciableAssets: 38,
  },
  {
    lat: 38.9457197,
    lng: -77.2979782,
    squareFootage: "1000",
    propertyType: "Specialty",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 1948800,
    depreciableAssets: 40,
  },
  {
    lat: 33.6364503,
    lng: -112.0118669,
    squareFootage: "5250",
    propertyType: "Multifamily",
    totalCosts: "660000",
    valueOfReclassifiedAssets: 129595,
    depreciableAssets: 23,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "15520",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 978207,
    depreciableAssets: 15,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "318389",
    propertyType: "Industrial",
    totalCosts: "29000000",
    valueOfReclassifiedAssets: 3228134,
    depreciableAssets: 13,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10928533",
    valueOfReclassifiedAssets: 9444775,
    depreciableAssets: 23,
  },
  {
    lat: 32.7049103,
    lng: -96.8726295,
    squareFootage: "176120",
    propertyType: "Multifamily",
    totalCosts: "12625000",
    valueOfReclassifiedAssets: 2879876,
    depreciableAssets: 27,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "1378",
    propertyType: "Retail",
    totalCosts: "1715460",
    valueOfReclassifiedAssets: 243224,
    depreciableAssets: 16,
  },
  {
    lat: 29.7002646,
    lng: -95.587986,
    squareFootage: "217272",
    propertyType: "Multifamily",
    totalCosts: "16404507",
    valueOfReclassifiedAssets: 4900393,
    depreciableAssets: 34,
  },
  {
    lat: 41.98250650000001,
    lng: -73.9888797,
    squareFootage: "116744",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "37315951",
    valueOfReclassifiedAssets: 14263608,
    depreciableAssets: 21,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "3782",
    propertyType: "Retail",
    totalCosts: "2205592",
    valueOfReclassifiedAssets: 468061,
    depreciableAssets: 24,
  },
  {
    lat: 33.3990327,
    lng: -111.7972384,
    squareFootage: "70042",
    propertyType: "Specialty",
    totalCosts: "8825000",
    valueOfReclassifiedAssets: 3024274,
    depreciableAssets: 40,
  },
  {
    lat: 32.6901244,
    lng: -96.937016,
    squareFootage: "206820",
    propertyType: "Multifamily",
    totalCosts: "12906530",
    valueOfReclassifiedAssets: 3565077,
    depreciableAssets: 29,
  },
  {
    lat: 42.2743768,
    lng: -85.21012689999999,
    squareFootage: "81376",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1767919,
    depreciableAssets: 32,
  },
  {
    lat: 27.791959,
    lng: -82.723924,
    squareFootage: "90109",
    propertyType: "Retail",
    totalCosts: "19200000",
    valueOfReclassifiedAssets: 5516016,
    depreciableAssets: 34,
  },
  {
    lat: 39.9559288,
    lng: -75.1574567,
    squareFootage: "8778",
    propertyType: "Multifamily",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 480881,
    depreciableAssets: 18,
  },
  {
    lat: 41.24118199999999,
    lng: -77.0010648,
    squareFootage: "83145",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10339849",
    valueOfReclassifiedAssets: 2251325,
    depreciableAssets: 25,
  },
  {
    lat: 35.5424793,
    lng: -97.5518056,
    squareFootage: "50426",
    propertyType: "Industrial",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 248059,
    depreciableAssets: 18,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "31298",
    propertyType: "Multifamily",
    totalCosts: "4675298",
    valueOfReclassifiedAssets: 4183193,
    depreciableAssets: 12,
  },
  {
    lat: 30.3378989,
    lng: -81.5925427,
    squareFootage: "70567",
    propertyType: "Multifamily",
    totalCosts: "3450000",
    valueOfReclassifiedAssets: 969185,
    depreciableAssets: 33,
  },
  {
    lat: 32.8162802,
    lng: -97.1898025,
    squareFootage: "86620",
    propertyType: "Multifamily",
    totalCosts: "5658682",
    valueOfReclassifiedAssets: 1557178,
    depreciableAssets: 28,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "258719",
    propertyType: "Multifamily",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 2124286,
    depreciableAssets: 31,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "4945",
    propertyType: "Retail",
    totalCosts: "1617434",
    valueOfReclassifiedAssets: 314729,
    depreciableAssets: 22,
  },
  {
    lat: 40.9180569,
    lng: -73.8977693,
    squareFootage: "7480",
    propertyType: "Retail",
    totalCosts: "2401644",
    valueOfReclassifiedAssets: 469014,
    depreciableAssets: 21,
  },
  {
    lat: 40.7282702,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "27824847",
    valueOfReclassifiedAssets: 24480831,
    depreciableAssets: 14,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "3622",
    propertyType: "Multifamily",
    totalCosts: "1069523",
    valueOfReclassifiedAssets: 155781,
    depreciableAssets: 16,
  },
  {
    lat: 32.6901244,
    lng: -96.937016,
    squareFootage: "206820",
    propertyType: "Multifamily",
    totalCosts: "3993470",
    valueOfReclassifiedAssets: 1103088,
    depreciableAssets: 22,
  },
  {
    lat: 32.0632399,
    lng: -84.16461629999999,
    squareFootage: "101460",
    propertyType: "Retail",
    totalCosts: "1667287",
    valueOfReclassifiedAssets: 320682,
    depreciableAssets: 20,
  },
  {
    lat: 38.6299452,
    lng: -90.6962853,
    squareFootage: "53928",
    propertyType: "Retail",
    totalCosts: "9050000",
    valueOfReclassifiedAssets: 1645781,
    depreciableAssets: 22,
  },
  {
    lat: 40.2884785,
    lng: -76.8201976,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "54252705",
    valueOfReclassifiedAssets: 9760318,
    depreciableAssets: 21,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "21495",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "417370",
    valueOfReclassifiedAssets: 111313,
    depreciableAssets: 33,
  },
  {
    lat: 39.167568,
    lng: -76.5892774,
    squareFootage: "56060",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 310560,
    depreciableAssets: 12,
  },
  {
    lat: 42.3612206,
    lng: -82.98778659999999,
    squareFootage: "58400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14405987",
    valueOfReclassifiedAssets: 3533042,
    depreciableAssets: 25,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "235256",
    propertyType: "Multifamily",
    totalCosts: "12610080",
    valueOfReclassifiedAssets: 3339890,
    depreciableAssets: 32,
  },
  {
    lat: 39.07935579999999,
    lng: -86.438974,
    squareFootage: "163096",
    propertyType: "Multifamily",
    totalCosts: "13625000",
    valueOfReclassifiedAssets: 2608859,
    depreciableAssets: 23,
  },
  {
    lat: 40.0635817,
    lng: -75.07863569999999,
    squareFootage: "11727",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4539446",
    valueOfReclassifiedAssets: 1161647,
    depreciableAssets: 30,
  },
  {
    lat: 41.4339301,
    lng: -81.5322149,
    squareFootage: "36866",
    propertyType: "Industrial",
    totalCosts: "1015000",
    valueOfReclassifiedAssets: 289120,
    depreciableAssets: 34,
  },
  {
    lat: 38.9016401,
    lng: -77.4587239,
    squareFootage: "68737",
    propertyType: "Hotel",
    totalCosts: "9200000",
    valueOfReclassifiedAssets: 2514935,
    depreciableAssets: 32,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "6750",
    propertyType: "Retail",
    totalCosts: "2058552",
    valueOfReclassifiedAssets: 400787,
    depreciableAssets: 22,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "145789",
    propertyType: "Office",
    totalCosts: "26081108",
    valueOfReclassifiedAssets: 2818903,
    depreciableAssets: 12,
  },
  {
    lat: 34.9944873,
    lng: -81.0533854,
    squareFootage: "84014",
    propertyType: "Multifamily",
    totalCosts: "1893648",
    valueOfReclassifiedAssets: 463455,
    depreciableAssets: 28,
  },
  {
    lat: 36.1585967,
    lng: -94.4593791,
    squareFootage: "40085",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 2905060,
    depreciableAssets: 36,
  },
  {
    lat: 32.5700066,
    lng: -99.6786894,
    squareFootage: "117472",
    propertyType: "Multifamily",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1398000,
    depreciableAssets: 31,
  },
  {
    lat: 38.9457197,
    lng: -77.2979782,
    squareFootage: "95635",
    propertyType: "Office",
    totalCosts: "11194526",
    valueOfReclassifiedAssets: 1614464,
    depreciableAssets: 17,
  },
  {
    lat: 38.7994436,
    lng: -90.20571079999999,
    squareFootage: "61620",
    propertyType: "Multifamily",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 314406,
    depreciableAssets: 23,
  },
  {
    lat: 33.4960213,
    lng: -84.3804888,
    squareFootage: "191569",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 3325066,
    depreciableAssets: 32,
  },
  {
    lat: 30.3378989,
    lng: -81.5925427,
    squareFootage: "20000",
    propertyType: "Multifamily",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 347001,
    depreciableAssets: 33,
  },
  {
    lat: 39.9793657,
    lng: -75.1253492,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11461737",
    valueOfReclassifiedAssets: 10143316,
    depreciableAssets: 15,
  },
  {
    lat: 35.45897739999999,
    lng: -97.4056928,
    squareFootage: "36365",
    propertyType: "Retail",
    totalCosts: "3550000",
    valueOfReclassifiedAssets: 862614,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "964319",
    valueOfReclassifiedAssets: 622264,
    depreciableAssets: 36,
  },
  {
    lat: 40.0703334,
    lng: -75.2070644,
    squareFootage: "94514",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15253333",
    valueOfReclassifiedAssets: 3407152,
    depreciableAssets: 26,
  },
  {
    lat: 35.3459327,
    lng: -97.47059349999999,
    squareFootage: "5763",
    propertyType: "Specialty",
    totalCosts: "1202000",
    valueOfReclassifiedAssets: 450267,
    depreciableAssets: 44,
  },
  {
    lat: 35.0074096,
    lng: -81.22985299999999,
    squareFootage: "69975",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "65000000",
    valueOfReclassifiedAssets: 14233477,
    depreciableAssets: 25,
  },
  {
    lat: 41.7962928,
    lng: -88.19741479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3817279",
    valueOfReclassifiedAssets: 3142430,
    depreciableAssets: 22,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "25200",
    propertyType: "Hotel",
    totalCosts: "8935427",
    valueOfReclassifiedAssets: 7700113,
    depreciableAssets: 14,
  },
  {
    lat: 26.1514248,
    lng: -80.22655449999999,
    squareFootage: "106792",
    propertyType: "Multifamily",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 1346517,
    depreciableAssets: 18,
  },
  {
    lat: 40.6407094,
    lng: -74.3851379,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6521915",
    valueOfReclassifiedAssets: 5870662,
    depreciableAssets: 12,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "93000",
    propertyType: "Multifamily",
    totalCosts: "4575950",
    valueOfReclassifiedAssets: 4077762,
    depreciableAssets: 12,
  },
  {
    lat: 40.4430064,
    lng: -74.4818698,
    squareFootage: "360210",
    propertyType: "Industrial",
    totalCosts: "19340956",
    valueOfReclassifiedAssets: 1511090,
    depreciableAssets: 8,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5077883",
    valueOfReclassifiedAssets: 3938118,
    depreciableAssets: 24,
  },
  {
    lat: 41.4339301,
    lng: -81.5322149,
    squareFootage: "38393",
    propertyType: "Industrial",
    totalCosts: "1177500",
    valueOfReclassifiedAssets: 259862,
    depreciableAssets: 26,
  },
  {
    lat: 42.2527785,
    lng: -71.1299976,
    squareFootage: "1900",
    propertyType: "Multifamily",
    totalCosts: "530000",
    valueOfReclassifiedAssets: 119004,
    depreciableAssets: 27,
  },
  {
    lat: 38.8628755,
    lng: -76.97006689999999,
    squareFootage: "22073",
    propertyType: "Retail",
    totalCosts: "13480000",
    valueOfReclassifiedAssets: 1633822,
    depreciableAssets: 14,
  },
  {
    lat: 37.7997808,
    lng: -81.22985299999999,
    squareFootage: "28160",
    propertyType: "Retail",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 649181,
    depreciableAssets: 34,
  },
  {
    lat: 31.5343579,
    lng: -97.19519129999999,
    squareFootage: "150000",
    propertyType: "Multifamily",
    totalCosts: "4866710",
    valueOfReclassifiedAssets: 1108824,
    depreciableAssets: 28,
  },
  {
    lat: 40.7177351,
    lng: -111.8985922,
    squareFootage: "147545",
    propertyType: "Industrial",
    totalCosts: "3724000",
    valueOfReclassifiedAssets: 276826,
    depreciableAssets: 9,
  },
  {
    lat: 33.9327331,
    lng: -84.0695961,
    squareFootage: "237489",
    propertyType: "Multifamily",
    totalCosts: "9700000",
    valueOfReclassifiedAssets: 2115080,
    depreciableAssets: 26,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "14500",
    propertyType: "Retail",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 558123,
    depreciableAssets: 11,
  },
  {
    lat: 27.7696063,
    lng: -82.6321376,
    squareFootage: "414520",
    propertyType: "Multifamily",
    totalCosts: "107625000",
    valueOfReclassifiedAssets: 22811283,
    depreciableAssets: 23,
  },
  {
    lat: 41.7962928,
    lng: -88.19741479999999,
    squareFootage: "125000",
    propertyType: "Multifamily",
    totalCosts: "12998005",
    valueOfReclassifiedAssets: 2334720,
    depreciableAssets: 20,
  },
  {
    lat: 40.4175762,
    lng: -86.8897655,
    squareFootage: "68693",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1062852,
    depreciableAssets: 19,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "85314",
    propertyType: "Multifamily",
    totalCosts: "4853000",
    valueOfReclassifiedAssets: 4186370,
    depreciableAssets: 15,
  },
  {
    lat: 28.5141606,
    lng: -81.444379,
    squareFootage: "8524",
    propertyType: "Retail",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 3499653,
    depreciableAssets: 59,
  },
  {
    lat: 33.3664005,
    lng: -91.07777229999999,
    squareFootage: "64232",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 837493,
    depreciableAssets: 34,
  },
  {
    lat: 34.1058378,
    lng: -80.92077370000001,
    squareFootage: "189480",
    propertyType: "Retail",
    totalCosts: "16250010",
    valueOfReclassifiedAssets: 4395931,
    depreciableAssets: 41,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "12736",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 489010,
    depreciableAssets: 19,
  },
  {
    lat: 26.1527657,
    lng: -81.7404134,
    squareFootage: "17143",
    propertyType: "Retail",
    totalCosts: "7100000",
    valueOfReclassifiedAssets: 3056851,
    depreciableAssets: 51,
  },
  {
    lat: 42.3652917,
    lng: -71.0646337,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1095372",
    valueOfReclassifiedAssets: 805163,
    depreciableAssets: 0,
  },
  {
    lat: 42.2527785,
    lng: -71.1299976,
    squareFootage: "8192",
    propertyType: "Industrial",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 243045,
    depreciableAssets: 14,
  },
  {
    lat: 33.449373,
    lng: -86.8671989,
    squareFootage: "345443",
    propertyType: "Multifamily",
    totalCosts: "43700000",
    valueOfReclassifiedAssets: 11420096,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1001813",
    valueOfReclassifiedAssets: 918644,
    depreciableAssets: 49,
  },
  {
    lat: 40.7793504,
    lng: -74.511172,
    squareFootage: "36317",
    propertyType: "Office",
    totalCosts: "10550000",
    valueOfReclassifiedAssets: 2386245,
    depreciableAssets: 27,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1159766",
    valueOfReclassifiedAssets: 961680,
    depreciableAssets: 23,
  },
  {
    lat: 40.287932,
    lng: -75.9347113,
    squareFootage: "73120",
    propertyType: "Retail",
    totalCosts: "10125000",
    valueOfReclassifiedAssets: 2226233,
    depreciableAssets: 26,
  },
  {
    lat: 39.7622516,
    lng: -75.11951069999999,
    squareFootage: "64513",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1045654",
    valueOfReclassifiedAssets: 835601,
    depreciableAssets: 25,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "77105",
    propertyType: "Multifamily",
    totalCosts: "4673938",
    valueOfReclassifiedAssets: 4069354,
    depreciableAssets: 14,
  },
  {
    lat: 34.9944873,
    lng: -81.0533854,
    squareFootage: "53125",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "97500000",
    valueOfReclassifiedAssets: 25169032,
    depreciableAssets: 28,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "121766",
    propertyType: "Multifamily",
    totalCosts: "2672707",
    valueOfReclassifiedAssets: 709202,
    depreciableAssets: 31,
  },
  {
    lat: 34.9037259,
    lng: -81.0092062,
    squareFootage: "68200",
    propertyType: "Multifamily",
    totalCosts: "6167792",
    valueOfReclassifiedAssets: 1454492,
    depreciableAssets: 28,
  },
  {
    lat: 34.0596468,
    lng: -118.3826713,
    squareFootage: "64400",
    propertyType: "Office",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 1144394,
    depreciableAssets: 19,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "27049",
    propertyType: "Multifamily",
    totalCosts: "18270951",
    valueOfReclassifiedAssets: 5918119,
    depreciableAssets: 38,
  },
  {
    lat: 30.0483999,
    lng: -89.9574873,
    squareFootage: "443876",
    propertyType: "Multifamily",
    totalCosts: "37521363",
    valueOfReclassifiedAssets: 8524863,
    depreciableAssets: 25,
  },
  {
    lat: 38.9467949,
    lng: -78.1792434,
    squareFootage: "118370",
    propertyType: "Multifamily",
    totalCosts: "9550000",
    valueOfReclassifiedAssets: 2585378,
    depreciableAssets: 28,
  },
  {
    lat: 32.8493511,
    lng: -96.5890166,
    squareFootage: "40768",
    propertyType: "Multifamily",
    totalCosts: "3279172",
    valueOfReclassifiedAssets: 923412,
    depreciableAssets: 34,
  },
  {
    lat: 38.9658112,
    lng: -77.4816693,
    squareFootage: "19031",
    propertyType: "Industrial",
    totalCosts: "3996959",
    valueOfReclassifiedAssets: 974415,
    depreciableAssets: 38,
  },
  {
    lat: 33.5114334,
    lng: -112.0685027,
    squareFootage: "3094",
    propertyType: "Multifamily",
    totalCosts: "717460",
    valueOfReclassifiedAssets: 186134,
    depreciableAssets: 32,
  },
  {
    lat: 40.4175762,
    lng: -86.8897655,
    squareFootage: "28992",
    propertyType: "Multifamily",
    totalCosts: "1722000",
    valueOfReclassifiedAssets: 305118,
    depreciableAssets: 21,
  },
  {
    lat: 42.2527785,
    lng: -71.1299976,
    squareFootage: "2109",
    propertyType: "Specialty",
    totalCosts: "405000",
    valueOfReclassifiedAssets: 96583,
    depreciableAssets: 27,
  },
  {
    lat: 40.74133339999999,
    lng: -73.90364869999999,
    squareFootage: "4920",
    propertyType: "Multifamily",
    totalCosts: "1860000",
    valueOfReclassifiedAssets: 226884,
    depreciableAssets: 14,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "31955",
    propertyType: "Multifamily",
    totalCosts: "1679250",
    valueOfReclassifiedAssets: 1409496,
    depreciableAssets: 17,
  },
  {
    lat: 43.80012989999999,
    lng: -73.0910171,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2515030",
    valueOfReclassifiedAssets: 2109419,
    depreciableAssets: 20,
  },
  {
    lat: 35.6264403,
    lng: -97.5138947,
    squareFootage: "96152",
    propertyType: "Multifamily",
    totalCosts: "6166665",
    valueOfReclassifiedAssets: 1380480,
    depreciableAssets: 25,
  },
  {
    lat: 32.470217,
    lng: -84.9505398,
    squareFootage: "236744",
    propertyType: "Multifamily",
    totalCosts: "9800000",
    valueOfReclassifiedAssets: 2610956,
    depreciableAssets: 31,
  },
  {
    lat: 33.1900838,
    lng: -86.6006249,
    squareFootage: "48155",
    propertyType: "Multifamily",
    totalCosts: "1475000",
    valueOfReclassifiedAssets: 379947,
    depreciableAssets: 30,
  },
  {
    lat: 40.0388765,
    lng: -82.8748301,
    squareFootage: "15000",
    propertyType: "Office",
    totalCosts: "2154911",
    valueOfReclassifiedAssets: 391145,
    depreciableAssets: 20,
  },
  {
    lat: 44.28092729999999,
    lng: -72.61950759999999,
    squareFootage: "91000",
    propertyType: "Office",
    totalCosts: "6438263",
    valueOfReclassifiedAssets: 889152,
    depreciableAssets: 14,
  },
  {
    lat: 38.2191978,
    lng: -85.6177891,
    squareFootage: "322100",
    propertyType: "Multifamily",
    totalCosts: "16251003",
    valueOfReclassifiedAssets: 3188909,
    depreciableAssets: 23,
  },
  {
    lat: 33.6301328,
    lng: -117.8721676,
    squareFootage: "21508",
    propertyType: "Specialty",
    totalCosts: "2500750",
    valueOfReclassifiedAssets: 265480,
    depreciableAssets: 14,
  },
  {
    lat: 39.220735,
    lng: -94.5727598,
    squareFootage: "78172",
    propertyType: "Multifamily",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 1275652,
    depreciableAssets: 28,
  },
  {
    lat: 38.8646537,
    lng: -94.6347041,
    squareFootage: "5010",
    propertyType: "Specialty",
    totalCosts: "4287500",
    valueOfReclassifiedAssets: 2145689,
    depreciableAssets: 32,
  },
  {
    lat: 38.9930421,
    lng: -94.68120859999999,
    squareFootage: "18170",
    propertyType: "Retail",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 494803,
    depreciableAssets: 29,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "9200",
    propertyType: "Multifamily",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 750387,
    depreciableAssets: 13,
  },
  {
    lat: 38.9658112,
    lng: -77.4816693,
    squareFootage: "20700",
    propertyType: "Industrial",
    totalCosts: "4167903",
    valueOfReclassifiedAssets: 3886593,
    depreciableAssets: 34,
  },
  {
    lat: 43.1770344,
    lng: -77.55047789999999,
    squareFootage: "131926",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 2823433,
    depreciableAssets: 22,
  },
  {
    lat: 30.6435655,
    lng: -87.5791287,
    squareFootage: "38721",
    propertyType: "Multifamily",
    totalCosts: "1925000",
    valueOfReclassifiedAssets: 534785,
    depreciableAssets: 33,
  },
  {
    lat: 42.2527785,
    lng: -71.1299976,
    squareFootage: "4444",
    propertyType: "Multifamily",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 841934,
    depreciableAssets: 24,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "59507",
    propertyType: "Multifamily",
    totalCosts: "18540951",
    valueOfReclassifiedAssets: 5918119,
    depreciableAssets: 38,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "78746",
    propertyType: "Industrial",
    totalCosts: "6474323",
    valueOfReclassifiedAssets: 1686454,
    depreciableAssets: 31,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "35828",
    propertyType: "Multifamily",
    totalCosts: "1951200",
    valueOfReclassifiedAssets: 1684556,
    depreciableAssets: 15,
  },
  {
    lat: 42.7765383,
    lng: -73.9065883,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5964756",
    valueOfReclassifiedAssets: 4949738,
    depreciableAssets: 23,
  },
  {
    lat: 43.168856,
    lng: -77.6936948,
    squareFootage: "124124",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1388490,
    depreciableAssets: 24,
  },
  {
    lat: 30.3640657,
    lng: -88.5226886,
    squareFootage: "229152",
    propertyType: "Multifamily",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 2709147,
    depreciableAssets: 30,
  },
  {
    lat: 41.8642989,
    lng: -87.8205864,
    squareFootage: "48555",
    propertyType: "Multifamily",
    totalCosts: "4775000",
    valueOfReclassifiedAssets: 697510,
    depreciableAssets: 17,
  },
  {
    lat: 42.0955139,
    lng: -88.314212,
    squareFootage: "15000",
    propertyType: "Office",
    totalCosts: "1385700",
    valueOfReclassifiedAssets: 170200,
    depreciableAssets: 14,
  },
  {
    lat: 34.0434783,
    lng: -118.2519314,
    squareFootage: "41023",
    propertyType: "Office",
    totalCosts: "7465848",
    valueOfReclassifiedAssets: 609064,
    depreciableAssets: 11,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "29100",
    propertyType: "Multifamily",
    totalCosts: "3130189",
    valueOfReclassifiedAssets: 318962,
    depreciableAssets: 11,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "58108",
    propertyType: "Office",
    totalCosts: "7689107",
    valueOfReclassifiedAssets: 666748,
    depreciableAssets: 10,
  },
  {
    lat: 39.220735,
    lng: -94.5727598,
    squareFootage: "45840",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 550817,
    depreciableAssets: 27,
  },
  {
    lat: 41.7606321,
    lng: -88.1437782,
    squareFootage: "34637",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4927066",
    valueOfReclassifiedAssets: 1293014,
    depreciableAssets: 29,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "121766",
    propertyType: "Multifamily",
    totalCosts: "11337354",
    valueOfReclassifiedAssets: 3008364,
    depreciableAssets: 31,
  },
  {
    lat: 41.2123169,
    lng: -95.956825,
    squareFootage: "93124",
    propertyType: "Multifamily",
    totalCosts: "6882137",
    valueOfReclassifiedAssets: 1095970,
    depreciableAssets: 29,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "11510",
    propertyType: "Multifamily",
    totalCosts: "4857992",
    valueOfReclassifiedAssets: 550598,
    depreciableAssets: 14,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "51644",
    propertyType: "Industrial",
    totalCosts: "5700182",
    valueOfReclassifiedAssets: 232557,
    depreciableAssets: 5,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "9148",
    propertyType: "Multifamily",
    totalCosts: "2509283",
    valueOfReclassifiedAssets: 331613,
    depreciableAssets: 22,
  },
  {
    lat: 41.820267,
    lng: -87.603826,
    squareFootage: "67066",
    propertyType: "Multifamily",
    totalCosts: "16862894",
    valueOfReclassifiedAssets: 2397621,
    depreciableAssets: 15,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "12374",
    propertyType: "Multifamily",
    totalCosts: "12900000",
    valueOfReclassifiedAssets: 3029575,
    depreciableAssets: 28,
  },
  {
    lat: 35.9980929,
    lng: -94.08999109999999,
    squareFootage: "117970",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 2061392,
    depreciableAssets: 34,
  },
  {
    lat: 44.8109158,
    lng: -73.0862585,
    squareFootage: "21628",
    propertyType: "Multifamily",
    totalCosts: "460000",
    valueOfReclassifiedAssets: 51962,
    depreciableAssets: 13,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "41714397",
    valueOfReclassifiedAssets: 36893529,
    depreciableAssets: 13,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "27670",
    propertyType: "Multifamily",
    totalCosts: "9063864",
    valueOfReclassifiedAssets: 727267,
    depreciableAssets: 11,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "24145",
    propertyType: "Multifamily",
    totalCosts: "4676494",
    valueOfReclassifiedAssets: 650412,
    depreciableAssets: 16,
  },
  {
    lat: 33.7597692,
    lng: -84.17516479999999,
    squareFootage: "336640",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 3996178,
    depreciableAssets: 37,
  },
  {
    lat: 42.6284995,
    lng: -73.9712488,
    squareFootage: "36402",
    propertyType: "Retail",
    totalCosts: "3426076",
    valueOfReclassifiedAssets: 755360,
    depreciableAssets: 26,
  },
  {
    lat: 41.6012434,
    lng: -87.603826,
    squareFootage: "65492",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10398044",
    valueOfReclassifiedAssets: 2814681,
    depreciableAssets: 30,
  },
  {
    lat: 39.816412,
    lng: -84.0061661,
    squareFootage: "32815",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5452473",
    valueOfReclassifiedAssets: 2772891,
    depreciableAssets: 33,
  },
  {
    lat: 30.8242537,
    lng: -88.08030509999999,
    squareFootage: "150060",
    propertyType: "Multifamily",
    totalCosts: "8125000",
    valueOfReclassifiedAssets: 2188316,
    depreciableAssets: 32,
  },
  {
    lat: 30.0297551,
    lng: -90.0125178,
    squareFootage: "177104",
    propertyType: "Multifamily",
    totalCosts: "11876337",
    valueOfReclassifiedAssets: 2578698,
    depreciableAssets: 24,
  },
  {
    lat: 42.1621397,
    lng: -88.14865700000001,
    squareFootage: "30000",
    propertyType: "Industrial",
    totalCosts: "1786550",
    valueOfReclassifiedAssets: 258403,
    depreciableAssets: 17,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "29448",
    propertyType: "Multifamily",
    totalCosts: "1863900",
    valueOfReclassifiedAssets: 1553046,
    depreciableAssets: 18,
  },
  {
    lat: 42.2610481,
    lng: -88.9509626,
    squareFootage: "49050",
    propertyType: "Multifamily",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 494079,
    depreciableAssets: 22,
  },
  {
    lat: 38.912068,
    lng: -77.0190228,
    squareFootage: "12800",
    propertyType: "Industrial",
    totalCosts: "14250000",
    valueOfReclassifiedAssets: 950995,
    depreciableAssets: 10,
  },
  {
    lat: 34.0434783,
    lng: -118.2519314,
    squareFootage: "119556",
    propertyType: "Industrial",
    totalCosts: "5976900",
    valueOfReclassifiedAssets: 333304,
    depreciableAssets: 8,
  },
  {
    lat: 40.6166766,
    lng: -73.8272029,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7895476",
    valueOfReclassifiedAssets: 6871570,
    depreciableAssets: 15,
  },
  {
    lat: 33.4306545,
    lng: -86.7919009,
    squareFootage: "134944",
    propertyType: "Multifamily",
    totalCosts: "8788000",
    valueOfReclassifiedAssets: 2494744,
    depreciableAssets: 34,
  },
  {
    lat: 34.2801972,
    lng: -118.4980744,
    squareFootage: "26816",
    propertyType: "Multifamily",
    totalCosts: "7483894",
    valueOfReclassifiedAssets: 1456901,
    depreciableAssets: 29,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "18150",
    propertyType: "Multifamily",
    totalCosts: "3506226",
    valueOfReclassifiedAssets: 510279,
    depreciableAssets: 16,
  },
  {
    lat: 44.9343827,
    lng: -93.1127413,
    squareFootage: "44509",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11178076",
    valueOfReclassifiedAssets: 2314863,
    depreciableAssets: 23,
  },
  {
    lat: 32.4352998,
    lng: -90.1588618,
    squareFootage: "71316",
    propertyType: "Retail",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 924515,
    depreciableAssets: 31,
  },
  {
    lat: 40.7275043,
    lng: -73.9800645,
    squareFootage: "13405",
    propertyType: "Multifamily",
    totalCosts: "1495547",
    valueOfReclassifiedAssets: 321794,
    depreciableAssets: 27,
  },
  {
    lat: 35.1338614,
    lng: -106.6409198,
    squareFootage: "52465",
    propertyType: "Office",
    totalCosts: "4261708",
    valueOfReclassifiedAssets: 837233,
    depreciableAssets: 22,
  },
  {
    lat: 35.1338614,
    lng: -106.6409198,
    squareFootage: "52465",
    propertyType: "Office",
    totalCosts: "2051933",
    valueOfReclassifiedAssets: 403112,
    depreciableAssets: 22,
  },
  {
    lat: 34.1662848,
    lng: -118.5447787,
    squareFootage: "23090",
    propertyType: "Office",
    totalCosts: "5081034",
    valueOfReclassifiedAssets: 436117,
    depreciableAssets: 14,
  },
  {
    lat: 38.8500753,
    lng: -83.91089680000002,
    squareFootage: "26360",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6859562",
    valueOfReclassifiedAssets: 3616717,
    depreciableAssets: 37,
  },
  {
    lat: 33.9964889,
    lng: -81.08650349999999,
    squareFootage: "319116",
    propertyType: "Multifamily",
    totalCosts: "19300000",
    valueOfReclassifiedAssets: 5945842,
    depreciableAssets: 36,
  },
  {
    lat: 34.2035088,
    lng: -118.5389414,
    squareFootage: "24000",
    propertyType: "Multifamily",
    totalCosts: "9725000",
    valueOfReclassifiedAssets: 1206096,
    depreciableAssets: 14,
  },
  {
    lat: 41.9320859,
    lng: -88.0020589,
    squareFootage: "141621",
    propertyType: "Multifamily",
    totalCosts: "8621109",
    valueOfReclassifiedAssets: 7289130,
    depreciableAssets: 20,
  },
  {
    lat: 41.6882735,
    lng: -71.51898,
    squareFootage: "45574",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10485000",
    valueOfReclassifiedAssets: 6685000,
    depreciableAssets: 28,
  },
  {
    lat: 42.2020445,
    lng: -71.014118,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1355224",
    valueOfReclassifiedAssets: 1182411,
    depreciableAssets: 60,
  },
  {
    lat: 40.2658873,
    lng: -74.5346103,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2978639",
    valueOfReclassifiedAssets: 1356326,
    depreciableAssets: 54,
  },
  {
    lat: 32.824634,
    lng: -96.6317461,
    squareFootage: "263797",
    propertyType: "Multifamily",
    totalCosts: "15020524",
    valueOfReclassifiedAssets: 4075605,
    depreciableAssets: 29,
  },
  {
    lat: 27.2752758,
    lng: -82.5564559,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "569000",
    valueOfReclassifiedAssets: 215619,
    depreciableAssets: 44,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "18972",
    propertyType: "Industrial",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 936776,
    depreciableAssets: 26,
  },
  {
    lat: 42.446414,
    lng: -85.58179109999999,
    squareFootage: "62320",
    propertyType: "Retail",
    totalCosts: "2716963",
    valueOfReclassifiedAssets: 505487,
    depreciableAssets: 21,
  },
  {
    lat: 33.029838,
    lng: -85.14793639999999,
    squareFootage: "50652",
    propertyType: "Multifamily",
    totalCosts: "1440000",
    valueOfReclassifiedAssets: 357851,
    depreciableAssets: 29,
  },
  {
    lat: 34.1568319,
    lng: -117.8428651,
    squareFootage: "48784",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4640000",
    valueOfReclassifiedAssets: 3585797,
    depreciableAssets: 31,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "22120",
    propertyType: "Multifamily",
    totalCosts: "3676712",
    valueOfReclassifiedAssets: 413654,
    depreciableAssets: 13,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "117600",
    propertyType: "Multifamily",
    totalCosts: "14153185",
    valueOfReclassifiedAssets: 10970804,
    depreciableAssets: 30,
  },
  {
    lat: 33.90777509999999,
    lng: -118.0830047,
    squareFootage: "23497",
    propertyType: "Multifamily",
    totalCosts: "9145000",
    valueOfReclassifiedAssets: 2233659,
    depreciableAssets: 29,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "8252",
    propertyType: "Multifamily",
    totalCosts: "6850000",
    valueOfReclassifiedAssets: 1342318,
    depreciableAssets: 23,
  },
  {
    lat: 39.5116069,
    lng: -76.6990172,
    squareFootage: "255968",
    propertyType: "Office",
    totalCosts: "19000000",
    valueOfReclassifiedAssets: 3563083,
    depreciableAssets: 22,
  },
  {
    lat: 40.8144014,
    lng: -73.4772682,
    squareFootage: "37628",
    propertyType: "Office",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 1163490,
    depreciableAssets: 17,
  },
  {
    lat: 34.1583323,
    lng: -118.5155901,
    squareFootage: "29936",
    propertyType: "Multifamily",
    totalCosts: "9325000",
    valueOfReclassifiedAssets: 997345,
    depreciableAssets: 20,
  },
  {
    lat: 37.1794866,
    lng: -94.5108862,
    squareFootage: "246543",
    propertyType: "Multifamily",
    totalCosts: "11935000",
    valueOfReclassifiedAssets: 2955299,
    depreciableAssets: 29,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "44658",
    propertyType: "Multifamily",
    totalCosts: "3022725",
    valueOfReclassifiedAssets: 2633538,
    depreciableAssets: 14,
  },
  {
    lat: 40.0958615,
    lng: -75.1720488,
    squareFootage: "63993",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10844232",
    valueOfReclassifiedAssets: 2118999,
    depreciableAssets: 23,
  },
  {
    lat: 42.4688673,
    lng: -83.0683519,
    squareFootage: "17800",
    propertyType: "Industrial",
    totalCosts: "690000",
    valueOfReclassifiedAssets: 115728,
    depreciableAssets: 20,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "12349",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3094332,
    depreciableAssets: 30,
  },
  {
    lat: 47.1227391,
    lng: -93.47166999999999,
    squareFootage: "45505",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5897693",
    valueOfReclassifiedAssets: 1464605,
    depreciableAssets: 28,
  },
  {
    lat: 41.9995834,
    lng: -71.51304449999999,
    squareFootage: "128970",
    propertyType: "Multifamily",
    totalCosts: "14100000",
    valueOfReclassifiedAssets: 4046282,
    depreciableAssets: 34,
  },
  {
    lat: 42.6292743,
    lng: -87.8746146,
    squareFootage: "49528",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1208354,
    depreciableAssets: 22,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "13945",
    propertyType: "Multifamily",
    totalCosts: "32664823",
    valueOfReclassifiedAssets: 3350163,
    depreciableAssets: 12,
  },
  {
    lat: 40.7658303,
    lng: -89.59690599999999,
    squareFootage: "32540",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1892557,
    depreciableAssets: 23,
  },
  {
    lat: 42.4609656,
    lng: -83.18097759999999,
    squareFootage: "12810",
    propertyType: "Industrial",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 84633,
    depreciableAssets: 15,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "58683",
    propertyType: "Industrial",
    totalCosts: "3619725",
    valueOfReclassifiedAssets: 289767,
    depreciableAssets: 10,
  },
  {
    lat: 45.6254031,
    lng: -122.5192895,
    squareFootage: "28380",
    propertyType: "Office",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 901143,
    depreciableAssets: 17,
  },
  {
    lat: 41.3681574,
    lng: -81.86068209999999,
    squareFootage: "140935",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14523197",
    valueOfReclassifiedAssets: 6781070,
    depreciableAssets: 17,
  },
  {
    lat: 41.8785329,
    lng: -72.6742455,
    squareFootage: "44260",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "194710754",
    valueOfReclassifiedAssets: 50481192,
    depreciableAssets: 30,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "67643",
    propertyType: "Industrial",
    totalCosts: "3073000",
    valueOfReclassifiedAssets: 778518,
    depreciableAssets: 30,
  },
  {
    lat: 40.7680056,
    lng: -74.2326058,
    squareFootage: "78690",
    propertyType: "Multifamily",
    totalCosts: "4950000",
    valueOfReclassifiedAssets: 794026,
    depreciableAssets: 17,
  },
  {
    lat: 39.2804946,
    lng: -94.6347041,
    squareFootage: "86815",
    propertyType: "Multifamily",
    totalCosts: "4785629",
    valueOfReclassifiedAssets: 1137602,
    depreciableAssets: 26,
  },
  {
    lat: 30.0297551,
    lng: -90.0125178,
    squareFootage: "455711",
    propertyType: "Multifamily",
    totalCosts: "26344774",
    valueOfReclassifiedAssets: 5959108,
    depreciableAssets: 25,
  },
  {
    lat: 39.4922669,
    lng: -77.9053182,
    squareFootage: "197310",
    propertyType: "Multifamily",
    totalCosts: "13200000",
    valueOfReclassifiedAssets: 2438882,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5250",
    propertyType: "Retail",
    totalCosts: "1769474",
    valueOfReclassifiedAssets: 1680340,
    depreciableAssets: 5,
  },
  {
    lat: 32.4352998,
    lng: -90.1588618,
    squareFootage: "42184",
    propertyType: "Retail",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 774296,
    depreciableAssets: 36,
  },
  {
    lat: 44.9872983,
    lng: -92.68025139999999,
    squareFootage: "52250",
    propertyType: "Multifamily",
    totalCosts: "2968059",
    valueOfReclassifiedAssets: 552182,
    depreciableAssets: 22,
  },
  {
    lat: 43.0833196,
    lng: -89.3724769,
    squareFootage: "13985",
    propertyType: "Industrial",
    totalCosts: "10750000",
    valueOfReclassifiedAssets: 2843036,
    depreciableAssets: 32,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "62700",
    propertyType: "Multifamily",
    totalCosts: "3433650",
    valueOfReclassifiedAssets: 3031371,
    depreciableAssets: 13,
  },
  {
    lat: 42.3316674,
    lng: -89.1515476,
    squareFootage: "147000",
    propertyType: "Multifamily",
    totalCosts: "4834715",
    valueOfReclassifiedAssets: 866333,
    depreciableAssets: 21,
  },
  {
    lat: 30.4069394,
    lng: -88.7686382,
    squareFootage: "89604",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1543174,
    depreciableAssets: 37,
  },
  {
    lat: 29.422658,
    lng: -98.48699049999999,
    squareFootage: "2017",
    propertyType: "Hotel",
    totalCosts: "20850000",
    valueOfReclassifiedAssets: 3880449,
    depreciableAssets: 22,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "12096",
    propertyType: "Multifamily",
    totalCosts: "6850000",
    valueOfReclassifiedAssets: 1325231,
    depreciableAssets: 23,
  },
  {
    lat: 40.7758652,
    lng: -72.9814877,
    squareFootage: "36380",
    propertyType: "Office",
    totalCosts: "6350000",
    valueOfReclassifiedAssets: 1199731,
    depreciableAssets: 21,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "19949",
    propertyType: "Office",
    totalCosts: "21068469",
    valueOfReclassifiedAssets: 1923791,
    depreciableAssets: 11,
  },
  {
    lat: 34.1658804,
    lng: -118.3637264,
    squareFootage: "25186",
    propertyType: "Multifamily",
    totalCosts: "6786337",
    valueOfReclassifiedAssets: 1030846,
    depreciableAssets: 21,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "113998",
    propertyType: "Office",
    totalCosts: "19450000",
    valueOfReclassifiedAssets: 3496025,
    depreciableAssets: 21,
  },
  {
    lat: 41.5908094,
    lng: -73.3944466,
    squareFootage: "51277",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1840448,
    depreciableAssets: 21,
  },
  {
    lat: 42.4658351,
    lng: -83.3468784,
    squareFootage: "130827",
    propertyType: "Industrial",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 770097,
    depreciableAssets: 27,
  },
  {
    lat: 39.3177867,
    lng: -86.7818523,
    squareFootage: "37884",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6207079",
    valueOfReclassifiedAssets: 1987256,
    depreciableAssets: 34,
  },
  {
    lat: 47.1227391,
    lng: -93.47166999999999,
    squareFootage: "56234",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4539609",
    valueOfReclassifiedAssets: 1055629,
    depreciableAssets: 26,
  },
  {
    lat: 33.029838,
    lng: -85.14793639999999,
    squareFootage: "24580",
    propertyType: "Multifamily",
    totalCosts: "1225000",
    valueOfReclassifiedAssets: 311329,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "100576",
    propertyType: "Multifamily",
    totalCosts: "53292627",
    valueOfReclassifiedAssets: 6491406,
    depreciableAssets: 14,
  },
  {
    lat: 40.6303421,
    lng: -74.3997976,
    squareFootage: "84500",
    propertyType: "Multifamily",
    totalCosts: "5968916",
    valueOfReclassifiedAssets: 1026079,
    depreciableAssets: 18,
  },
  {
    lat: 35.12905569999999,
    lng: -80.9539475,
    squareFootage: "258734",
    propertyType: "Multifamily",
    totalCosts: "31650000",
    valueOfReclassifiedAssets: 7230152,
    depreciableAssets: 27,
  },
  {
    lat: 39.7909447,
    lng: -84.21207,
    squareFootage: "35032",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 518535,
    depreciableAssets: 34,
  },
  {
    lat: 40.7960005,
    lng: -73.6830521,
    squareFootage: "71250",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5747783",
    valueOfReclassifiedAssets: 4522433,
    depreciableAssets: 24,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "2500",
    propertyType: "Multifamily",
    totalCosts: "891305",
    valueOfReclassifiedAssets: 135373,
    depreciableAssets: 17,
  },
  {
    lat: 41.4339301,
    lng: -81.5322149,
    squareFootage: "90652",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9315191",
    valueOfReclassifiedAssets: 4581153,
    depreciableAssets: 26,
  },
  {
    lat: 40.8955467,
    lng: -74.156292,
    squareFootage: "36915",
    propertyType: "Industrial",
    totalCosts: "3112114",
    valueOfReclassifiedAssets: 278876,
    depreciableAssets: 12,
  },
  {
    lat: 42.5183235,
    lng: -83.15953999999999,
    squareFootage: "134628",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1140090,
    depreciableAssets: 27,
  },
  {
    lat: 34.187499,
    lng: -80.8322428,
    squareFootage: "276748",
    propertyType: "Multifamily",
    totalCosts: "25750000",
    valueOfReclassifiedAssets: 9927339,
    depreciableAssets: 39,
  },
  {
    lat: 47.3666507,
    lng: -122.197612,
    squareFootage: "5546",
    propertyType: "Retail",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 271629,
    depreciableAssets: 29,
  },
  {
    lat: 40.2854984,
    lng: -75.2829046,
    squareFootage: "288484",
    propertyType: "Industrial",
    totalCosts: "15274233",
    valueOfReclassifiedAssets: 1624945,
    depreciableAssets: 12,
  },
  {
    lat: 43.037971,
    lng: -75.9985693,
    squareFootage: "80464",
    propertyType: "Multifamily",
    totalCosts: "3009690",
    valueOfReclassifiedAssets: 2615747,
    depreciableAssets: 19,
  },
  {
    lat: 33.9963181,
    lng: -84.0061661,
    squareFootage: "50000",
    propertyType: "Industrial",
    totalCosts: "603723",
    valueOfReclassifiedAssets: 548991,
    depreciableAssets: 21,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "21000",
    propertyType: "Multifamily",
    totalCosts: "5318716",
    valueOfReclassifiedAssets: 756042,
    depreciableAssets: 15,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8581945",
    valueOfReclassifiedAssets: 8209315,
    depreciableAssets: 9,
  },
  {
    lat: 28.3430219,
    lng: -81.5815776,
    squareFootage: "8461",
    propertyType: "Multifamily",
    totalCosts: "2425000",
    valueOfReclassifiedAssets: 842949,
    depreciableAssets: 41,
  },
  {
    lat: 32.7433629,
    lng: -96.8887202,
    squareFootage: "308670",
    propertyType: "Multifamily",
    totalCosts: "22450000",
    valueOfReclassifiedAssets: 5543873,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "72008",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15503274",
    valueOfReclassifiedAssets: 3985992,
    depreciableAssets: 29,
  },
  {
    lat: 32.6702606,
    lng: -103.4049445,
    squareFootage: "10332",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 206733,
    depreciableAssets: 35,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "68250",
    propertyType: "Multifamily",
    totalCosts: "3380700",
    valueOfReclassifiedAssets: 3009034,
    depreciableAssets: 12,
  },
  {
    lat: 30.4580512,
    lng: -87.21703950000001,
    squareFootage: "90952",
    propertyType: "Multifamily",
    totalCosts: "4730000",
    valueOfReclassifiedAssets: 1143036,
    depreciableAssets: 28,
  },
  {
    lat: 40.4350547,
    lng: -86.8772298,
    squareFootage: "97904",
    propertyType: "Retail",
    totalCosts: "5321401",
    valueOfReclassifiedAssets: 4553207,
    depreciableAssets: 100,
  },
  {
    lat: 32.5989444,
    lng: -96.85654269999999,
    squareFootage: "139200",
    propertyType: "Multifamily",
    totalCosts: "17280000",
    valueOfReclassifiedAssets: 4504307,
    depreciableAssets: 31,
  },
  {
    lat: 33.1376528,
    lng: -96.85654269999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10950000",
    valueOfReclassifiedAssets: 2767303,
    depreciableAssets: 36,
  },
  {
    lat: 44.0923096,
    lng: -87.7172616,
    squareFootage: "90187",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9448000",
    valueOfReclassifiedAssets: 1930878,
    depreciableAssets: 24,
  },
  {
    lat: 30.0087342,
    lng: -89.9720133,
    squareFootage: "342228",
    propertyType: "Multifamily",
    totalCosts: "21144544",
    valueOfReclassifiedAssets: 4587827,
    depreciableAssets: 24,
  },
  {
    lat: 35.9530958,
    lng: -78.9571069,
    squareFootage: "45408",
    propertyType: "Multifamily",
    totalCosts: "4170000",
    valueOfReclassifiedAssets: 991489,
    depreciableAssets: 28,
  },
  {
    lat: 34.0745742,
    lng: -117.6962824,
    squareFootage: "46201",
    propertyType: "Retail",
    totalCosts: "1996803",
    valueOfReclassifiedAssets: 1740829,
    depreciableAssets: 28,
  },
  {
    lat: 43.0165459,
    lng: -88.29476749999999,
    squareFootage: "55789",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1311067,
    depreciableAssets: 30,
  },
  {
    lat: 26.6211772,
    lng: -80.09260139999999,
    squareFootage: "72848",
    propertyType: "Multifamily",
    totalCosts: "5450125",
    valueOfReclassifiedAssets: 1256815,
    depreciableAssets: 28,
  },
  {
    lat: 40.0856743,
    lng: -75.1516194,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "39648870",
    valueOfReclassifiedAssets: 37782881,
    depreciableAssets: 10,
  },
  {
    lat: 41.1263566,
    lng: -81.3509416,
    squareFootage: "29826",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4251643",
    valueOfReclassifiedAssets: 2329068,
    depreciableAssets: 40,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "26420",
    propertyType: "Multifamily",
    totalCosts: "5444633",
    valueOfReclassifiedAssets: 685682,
    depreciableAssets: 15,
  },
  {
    lat: 30.6809949,
    lng: -88.1047287,
    squareFootage: "125166",
    propertyType: "Office",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 621422,
    depreciableAssets: 17,
  },
  {
    lat: 40.6303421,
    lng: -74.3997976,
    squareFootage: "25000",
    propertyType: "Multifamily",
    totalCosts: "5300000",
    valueOfReclassifiedAssets: 1004378,
    depreciableAssets: 21,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "5000",
    propertyType: "Multifamily",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 301064,
    depreciableAssets: 22,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "22710",
    propertyType: "Multifamily",
    totalCosts: "7326623",
    valueOfReclassifiedAssets: 604819,
    depreciableAssets: 9,
  },
  {
    lat: 40.6216016,
    lng: -74.22967129999999,
    squareFootage: "30000",
    propertyType: "Industrial",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 535709,
    depreciableAssets: 18,
  },
  {
    lat: 38.3778505,
    lng: -82.2800546,
    squareFootage: "30000",
    propertyType: "Retail",
    totalCosts: "4070000",
    valueOfReclassifiedAssets: 1019691,
    depreciableAssets: 29,
  },
  {
    lat: 29.7246822,
    lng: -95.5564777,
    squareFootage: "274388",
    propertyType: "Multifamily",
    totalCosts: "27317500",
    valueOfReclassifiedAssets: 3311132,
    depreciableAssets: 15,
  },
  {
    lat: 34.1971476,
    lng: -118.824722,
    squareFootage: "63274",
    propertyType: "Retail",
    totalCosts: "2814180",
    valueOfReclassifiedAssets: 481176,
    depreciableAssets: 27,
  },
  {
    lat: 42.562608,
    lng: -83.0039075,
    squareFootage: "35312",
    propertyType: "Industrial",
    totalCosts: "1760000",
    valueOfReclassifiedAssets: 231759,
    depreciableAssets: 15,
  },
  {
    lat: 42.2541047,
    lng: -73.6506776,
    squareFootage: "58841",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4554973",
    valueOfReclassifiedAssets: 957097,
    depreciableAssets: 23,
  },
  {
    lat: 41.1123302,
    lng: -72.36663,
    squareFootage: "5000",
    propertyType: "Retail",
    totalCosts: "1685000",
    valueOfReclassifiedAssets: 438835,
    depreciableAssets: 31,
  },
  {
    lat: 41.1395518,
    lng: -73.712479,
    squareFootage: "43499",
    propertyType: "Office",
    totalCosts: "6144604",
    valueOfReclassifiedAssets: 1041203,
    depreciableAssets: 19,
  },
  {
    lat: 29.9824128,
    lng: -95.4620545,
    squareFootage: "236292",
    propertyType: "Multifamily",
    totalCosts: "15400000",
    valueOfReclassifiedAssets: 4187949,
    depreciableAssets: 32,
  },
  {
    lat: 39.8833139,
    lng: -95.2475518,
    squareFootage: "14789",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 3033222,
    depreciableAssets: 23,
  },
  {
    lat: 29.54656779999999,
    lng: -98.3033694,
    squareFootage: "53900",
    propertyType: "Multifamily",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1224148,
    depreciableAssets: 28,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "39927",
    propertyType: "Office",
    totalCosts: "995000",
    valueOfReclassifiedAssets: 169678,
    depreciableAssets: 19,
  },
  {
    lat: 36.0091763,
    lng: -80.0199562,
    squareFootage: "84048",
    propertyType: "Multifamily",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 1186065,
    depreciableAssets: 33,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "199771",
    propertyType: "Multifamily",
    totalCosts: "16525000",
    valueOfReclassifiedAssets: 4496597,
    depreciableAssets: 32,
  },
  {
    lat: 38.7952436,
    lng: -94.5418141,
    squareFootage: "22500",
    propertyType: "Specialty",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 547576,
    depreciableAssets: 49,
  },
  {
    lat: 34.1903021,
    lng: -119.2262667,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "915923",
    valueOfReclassifiedAssets: 724006,
    depreciableAssets: 23,
  },
  {
    lat: 35.7418297,
    lng: -78.713608,
    squareFootage: "49430",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 976986,
    depreciableAssets: 28,
  },
  {
    lat: 41.3559092,
    lng: -83.12200740000002,
    squareFootage: "72940",
    propertyType: "Multifamily",
    totalCosts: "2852340",
    valueOfReclassifiedAssets: 758520,
    depreciableAssets: 27,
  },
  {
    lat: 43.0100971,
    lng: -78.2248291,
    squareFootage: "136728",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15714494",
    valueOfReclassifiedAssets: 2480423,
    depreciableAssets: 17,
  },
  {
    lat: 38.9119463,
    lng: -94.4136213,
    squareFootage: "33878",
    propertyType: "Multifamily",
    totalCosts: "2161000",
    valueOfReclassifiedAssets: 592819,
    depreciableAssets: 31,
  },
  {
    lat: 32.6350855,
    lng: -96.9155468,
    squareFootage: "108640",
    propertyType: "Multifamily",
    totalCosts: "9625000",
    valueOfReclassifiedAssets: 2529489,
    depreciableAssets: 31,
  },
  {
    lat: 34.296676,
    lng: -118.2292712,
    squareFootage: "21446",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1088577,
    depreciableAssets: 33,
  },
  {
    lat: 35.2258075,
    lng: -89.9159785,
    squareFootage: "189016",
    propertyType: "Multifamily",
    totalCosts: "8760000",
    valueOfReclassifiedAssets: 2324835,
    depreciableAssets: 32,
  },
  {
    lat: 34.0656464,
    lng: -117.9424743,
    squareFootage: "142526",
    propertyType: "Multifamily",
    totalCosts: "52000000",
    valueOfReclassifiedAssets: 13678110,
    depreciableAssets: 31,
  },
  {
    lat: 47.3661299,
    lng: -122.1123384,
    squareFootage: "24248",
    propertyType: "Specialty",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 960650,
    depreciableAssets: 24,
  },
  {
    lat: 42.10422740000001,
    lng: -71.26663289999999,
    squareFootage: "67980",
    propertyType: "Multifamily",
    totalCosts: "10087240",
    valueOfReclassifiedAssets: 1642990,
    depreciableAssets: 17,
  },
  {
    lat: 28.3430219,
    lng: -81.5815776,
    squareFootage: "8880",
    propertyType: "Multifamily",
    totalCosts: "3080000",
    valueOfReclassifiedAssets: 1089426,
    depreciableAssets: 42,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "15060",
    propertyType: "Multifamily",
    totalCosts: "8530523",
    valueOfReclassifiedAssets: 1131427,
    depreciableAssets: 14,
  },
  {
    lat: 33.3822079,
    lng: -84.4592701,
    squareFootage: "112160",
    propertyType: "Multifamily",
    totalCosts: "11400000",
    valueOfReclassifiedAssets: 3065395,
    depreciableAssets: 32,
  },
  {
    lat: 33.5273381,
    lng: -117.1147095,
    squareFootage: "82663",
    propertyType: "Office",
    totalCosts: "3924361",
    valueOfReclassifiedAssets: 880027,
    depreciableAssets: 28,
  },
  {
    lat: 40.9046961,
    lng: -74.6693186,
    squareFootage: "66700",
    propertyType: "Multifamily",
    totalCosts: "10031288",
    valueOfReclassifiedAssets: 8397487,
    depreciableAssets: 23,
  },
  {
    lat: 41.5214566,
    lng: -81.6418656,
    squareFootage: "89546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 3061674,
    depreciableAssets: 21,
  },
  {
    lat: 42.00925840000001,
    lng: -71.2280234,
    squareFootage: "42574",
    propertyType: "Office",
    totalCosts: "3550000",
    valueOfReclassifiedAssets: 508205,
    depreciableAssets: 16,
  },
  {
    lat: 41.9511782,
    lng: -74.006509,
    squareFootage: "10267",
    propertyType: "Multifamily",
    totalCosts: "1125000",
    valueOfReclassifiedAssets: 133677,
    depreciableAssets: 14,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "34470",
    propertyType: "Multifamily",
    totalCosts: "2349000",
    valueOfReclassifiedAssets: 300649,
    depreciableAssets: 15,
  },
  {
    lat: 33.9290987,
    lng: -84.46976629999999,
    squareFootage: "147615",
    propertyType: "Industrial",
    totalCosts: "19780319",
    valueOfReclassifiedAssets: 5092266,
    depreciableAssets: 30,
  },
  {
    lat: 40.8562383,
    lng: -73.96537150000002,
    squareFootage: "112500",
    propertyType: "Multifamily",
    totalCosts: "34319245",
    valueOfReclassifiedAssets: 4769523,
    depreciableAssets: 16,
  },
  {
    lat: 30.0483999,
    lng: -89.9574873,
    squareFootage: "335600",
    propertyType: "Multifamily",
    totalCosts: "20943439",
    valueOfReclassifiedAssets: 4412973,
    depreciableAssets: 24,
  },
  {
    lat: 33.7646686,
    lng: -84.7837717,
    squareFootage: "43282",
    propertyType: "Multifamily",
    totalCosts: "2460000",
    valueOfReclassifiedAssets: 581153,
    depreciableAssets: 28,
  },
  {
    lat: 42.9196358,
    lng: -89.2277026,
    squareFootage: "77212",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4960000",
    valueOfReclassifiedAssets: 950225,
    depreciableAssets: 22,
  },
  {
    lat: 33.7168761,
    lng: -117.9600466,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13867942",
    valueOfReclassifiedAssets: 13518394,
    depreciableAssets: 26,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "35000",
    propertyType: "Specialty",
    totalCosts: "12700000",
    valueOfReclassifiedAssets: 850310,
    depreciableAssets: 8,
  },
  {
    lat: 33.8409696,
    lng: -118.3520389,
    squareFootage: "10150",
    propertyType: "Multifamily",
    totalCosts: "2377467",
    valueOfReclassifiedAssets: 311291,
    depreciableAssets: 22,
  },
  {
    lat: 40.2518754,
    lng: -74.7629661,
    squareFootage: "20910",
    propertyType: "Office",
    totalCosts: "1530240",
    valueOfReclassifiedAssets: 234859,
    depreciableAssets: 19,
  },
  {
    lat: 27.4481958,
    lng: -82.5131717,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5503175",
    valueOfReclassifiedAssets: 4677699,
    depreciableAssets: 100,
  },
  {
    lat: 34.447771,
    lng: -86.9223432,
    squareFootage: "26250",
    propertyType: "Multifamily",
    totalCosts: "913000",
    valueOfReclassifiedAssets: 271998,
    depreciableAssets: 35,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "362847",
    propertyType: "Multifamily",
    totalCosts: "42000000",
    valueOfReclassifiedAssets: 10857563,
    depreciableAssets: 30,
  },
  {
    lat: 40.692165,
    lng: -73.6477342,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1238493",
    valueOfReclassifiedAssets: 256554,
    depreciableAssets: 23,
  },
  {
    lat: 41.3681574,
    lng: -81.86068209999999,
    squareFootage: "140935",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14709901",
    valueOfReclassifiedAssets: 6808703,
    depreciableAssets: 17,
  },
  {
    lat: 46.86957839999999,
    lng: -122.1407692,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1155000",
    valueOfReclassifiedAssets: 433144,
    depreciableAssets: 44,
  },
  {
    lat: 38.9953837,
    lng: -74.8390162,
    squareFootage: "9750",
    propertyType: "Retail",
    totalCosts: "543962",
    valueOfReclassifiedAssets: 51115,
    depreciableAssets: 12,
  },
  {
    lat: 32.0302037,
    lng: -96.5143087,
    squareFootage: "81475",
    propertyType: "Industrial",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1585311,
    depreciableAssets: 31,
  },
  {
    lat: 36.0357492,
    lng: -115.1533426,
    squareFootage: "85134",
    propertyType: "Retail",
    totalCosts: "7170963",
    valueOfReclassifiedAssets: 2381335,
    depreciableAssets: 45,
  },
  {
    lat: 43.0515702,
    lng: -77.53901270000001,
    squareFootage: "45600",
    propertyType: "Multifamily",
    totalCosts: "4180000",
    valueOfReclassifiedAssets: 627915,
    depreciableAssets: 18,
  },
  {
    lat: 32.6711817,
    lng: -96.8726295,
    squareFootage: "320000",
    propertyType: "Multifamily",
    totalCosts: "28500000",
    valueOfReclassifiedAssets: 8738137,
    depreciableAssets: 34,
  },
  {
    lat: 33.5273381,
    lng: -117.1147095,
    squareFootage: "139111",
    propertyType: "Retail",
    totalCosts: "21400690",
    valueOfReclassifiedAssets: 17627562,
    depreciableAssets: 38,
  },
  {
    lat: 40.0532987,
    lng: -75.23040379999999,
    squareFootage: "193228",
    propertyType: "Multifamily",
    totalCosts: "29124681",
    valueOfReclassifiedAssets: 8380567,
    depreciableAssets: 33,
  },
  {
    lat: 44.5431988,
    lng: -88.0607565,
    squareFootage: "34537",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 517650,
    depreciableAssets: 29,
  },
  {
    lat: 40.9425715,
    lng: -72.3133529,
    squareFootage: "38785",
    propertyType: "Industrial",
    totalCosts: "24865581",
    valueOfReclassifiedAssets: 7027390,
    depreciableAssets: 32,
  },
  {
    lat: 33.8020217,
    lng: -118.1678543,
    squareFootage: "59644",
    propertyType: "Retail",
    totalCosts: "27287007",
    valueOfReclassifiedAssets: 25037401,
    depreciableAssets: 40,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19923392",
    valueOfReclassifiedAssets: 16814961,
    depreciableAssets: 17,
  },
  {
    lat: 34.0895991,
    lng: -118.0947106,
    squareFootage: "2612",
    propertyType: "Office",
    totalCosts: "1106000",
    valueOfReclassifiedAssets: 131707,
    depreciableAssets: 0,
  },
  {
    lat: 40.4175762,
    lng: -86.8897655,
    squareFootage: "60924",
    propertyType: "Multifamily",
    totalCosts: "2378000",
    valueOfReclassifiedAssets: 340513,
    depreciableAssets: 17,
  },
  {
    lat: 30.0087342,
    lng: -89.9720133,
    squareFootage: "240168",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1969210,
    depreciableAssets: 22,
  },
  {
    lat: 41.9094458,
    lng: -73.96831019999999,
    squareFootage: "148433",
    propertyType: "Multifamily",
    totalCosts: "17333334",
    valueOfReclassifiedAssets: 4851282,
    depreciableAssets: 33,
  },
  {
    lat: 41.9511782,
    lng: -74.006509,
    squareFootage: "195624",
    propertyType: "Multifamily",
    totalCosts: "12125000",
    valueOfReclassifiedAssets: 1808402,
    depreciableAssets: 17,
  },
  {
    lat: 34.00974600000001,
    lng: -118.4659576,
    squareFootage: "25323",
    propertyType: "Office",
    totalCosts: "6933369",
    valueOfReclassifiedAssets: 783421,
    depreciableAssets: 15,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "51740",
    propertyType: "Industrial",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 414674,
    depreciableAssets: 16,
  },
  {
    lat: 41.6541814,
    lng: -83.5443287,
    squareFootage: "112180",
    propertyType: "Multifamily",
    totalCosts: "5502425",
    valueOfReclassifiedAssets: 1446956,
    depreciableAssets: 27,
  },
  {
    lat: 40.3879889,
    lng: -74.3352858,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "21953451",
    valueOfReclassifiedAssets: 3004411,
    depreciableAssets: 16,
  },
  {
    lat: 39.9645049,
    lng: -86.0223611,
    squareFootage: "55542",
    propertyType: "Industrial",
    totalCosts: "3747590",
    valueOfReclassifiedAssets: 614639,
    depreciableAssets: 24,
  },
  {
    lat: 28.3430219,
    lng: -81.5815776,
    squareFootage: "10795",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 846833,
    depreciableAssets: 41,
  },
  {
    lat: 40.1373404,
    lng: -75.3761952,
    squareFootage: "140100",
    propertyType: "Office",
    totalCosts: "19505000",
    valueOfReclassifiedAssets: 4195283,
    depreciableAssets: 25,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "34470",
    propertyType: "Multifamily",
    totalCosts: "2001000",
    valueOfReclassifiedAssets: 256108,
    depreciableAssets: 15,
  },
  {
    lat: 41.44780009999999,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "921379",
    valueOfReclassifiedAssets: 720020,
    depreciableAssets: 23,
  },
  {
    lat: 41.4339301,
    lng: -81.5322149,
    squareFootage: "90652",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9492109",
    valueOfReclassifiedAssets: 4617878,
    depreciableAssets: 26,
  },
  {
    lat: 38.9457197,
    lng: -77.2979782,
    squareFootage: "95635",
    propertyType: "Office",
    totalCosts: "11194526",
    valueOfReclassifiedAssets: 1279186,
    depreciableAssets: 17,
  },
  {
    lat: 34.7091114,
    lng: -86.761749,
    squareFootage: "46315",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 691105,
    depreciableAssets: 33,
  },
  {
    lat: 34.92624910000001,
    lng: -82.4644445,
    squareFootage: "91700",
    propertyType: "Multifamily",
    totalCosts: "6018129",
    valueOfReclassifiedAssets: 2037408,
    depreciableAssets: 38,
  },
  {
    lat: 32.8312481,
    lng: -80.08701549999999,
    squareFootage: "225984",
    propertyType: "Multifamily",
    totalCosts: "36100000",
    valueOfReclassifiedAssets: 11305262,
    depreciableAssets: 37,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "890744",
    valueOfReclassifiedAssets: 662012,
    depreciableAssets: 70,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "27447",
    propertyType: "Office",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 665678,
    depreciableAssets: 15,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "276260",
    propertyType: "Multifamily",
    totalCosts: "3111479",
    valueOfReclassifiedAssets: 686886,
    depreciableAssets: 30,
  },
  {
    lat: 42.3691884,
    lng: -83.3682539,
    squareFootage: "24000",
    propertyType: "Industrial",
    totalCosts: "1367281",
    valueOfReclassifiedAssets: 245521,
    depreciableAssets: 22,
  },
  {
    lat: 33.8409696,
    lng: -118.3520389,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "309223",
    valueOfReclassifiedAssets: 141101,
    depreciableAssets: 63,
  },
  {
    lat: 32.2546522,
    lng: -110.9447027,
    squareFootage: "44120",
    propertyType: "Multifamily",
    totalCosts: "3035000",
    valueOfReclassifiedAssets: 879513,
    depreciableAssets: 35,
  },
  {
    lat: 34.1427587,
    lng: -118.1386005,
    squareFootage: "6300",
    propertyType: "Office",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 2771161,
    depreciableAssets: 29,
  },
  {
    lat: 34.1971476,
    lng: -118.824722,
    squareFootage: "63274",
    propertyType: "Retail",
    totalCosts: "1576116",
    valueOfReclassifiedAssets: 310948,
    depreciableAssets: 27,
  },
  {
    lat: 32.8721517,
    lng: -96.99609249999999,
    squareFootage: "278600",
    propertyType: "Multifamily",
    totalCosts: "31850000",
    valueOfReclassifiedAssets: 8849911,
    depreciableAssets: 31,
  },
  {
    lat: 41.5364934,
    lng: -81.57609450000001,
    squareFootage: "52203",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9588689",
    valueOfReclassifiedAssets: 4792982,
    depreciableAssets: 25,
  },
  {
    lat: 34.1232517,
    lng: -118.0830047,
    squareFootage: "14569",
    propertyType: "Multifamily",
    totalCosts: "3820000",
    valueOfReclassifiedAssets: 713345,
    depreciableAssets: 28,
  },
  {
    lat: 25.8728864,
    lng: -81.6692532,
    squareFootage: "24858",
    propertyType: "Industrial",
    totalCosts: "2442305",
    valueOfReclassifiedAssets: 415362,
    depreciableAssets: 24,
  },
  {
    lat: 41.9472058,
    lng: -87.74680710000001,
    squareFootage: "414822",
    propertyType: "Industrial",
    totalCosts: "34249739",
    valueOfReclassifiedAssets: 4289694,
    depreciableAssets: 15,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "87164",
    propertyType: "Retail",
    totalCosts: "10900000",
    valueOfReclassifiedAssets: 2108806,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "12682",
    propertyType: "Retail",
    totalCosts: "875000",
    valueOfReclassifiedAssets: 113524,
    depreciableAssets: 18,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "30792",
    propertyType: "Industrial",
    totalCosts: "7180541",
    valueOfReclassifiedAssets: 632053,
    depreciableAssets: 11,
  },
  {
    lat: 35.56714230000001,
    lng: -82.5437614,
    squareFootage: "102924",
    propertyType: "Industrial",
    totalCosts: "12746221",
    valueOfReclassifiedAssets: 2897904,
    depreciableAssets: 27,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "7110",
    propertyType: "Multifamily",
    totalCosts: "1621979",
    valueOfReclassifiedAssets: 113129,
    depreciableAssets: 11,
  },
  {
    lat: 40.3548206,
    lng: -76.5719414,
    squareFootage: "36383",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 527932,
    depreciableAssets: 23,
  },
  {
    lat: 41.9511782,
    lng: -74.006509,
    squareFootage: "6994",
    propertyType: "Office",
    totalCosts: "704829",
    valueOfReclassifiedAssets: 119622,
    depreciableAssets: 20,
  },
  {
    lat: 40.7164495,
    lng: -80.1205256,
    squareFootage: "27220",
    propertyType: "Retail",
    totalCosts: "26500000",
    valueOfReclassifiedAssets: 5840700,
    depreciableAssets: 26,
  },
  {
    lat: 41.6056621,
    lng: -93.80702529999999,
    squareFootage: "2855",
    propertyType: "Specialty",
    totalCosts: "1497863",
    valueOfReclassifiedAssets: 520840,
    depreciableAssets: 41,
  },
  {
    lat: 34.2328937,
    lng: -119.179765,
    squareFootage: "20673",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 1488333,
    depreciableAssets: 33,
  },
  {
    lat: 36.1030036,
    lng: -86.87221459999999,
    squareFootage: "5274",
    propertyType: "Specialty",
    totalCosts: "3202191",
    valueOfReclassifiedAssets: 705688,
    depreciableAssets: 33,
  },
  {
    lat: 39.3614334,
    lng: -76.5892774,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "4794607",
    valueOfReclassifiedAssets: 1200650,
    depreciableAssets: 29,
  },
  {
    lat: 41.7008069,
    lng: -83.4910242,
    squareFootage: "133715",
    propertyType: "Multifamily",
    totalCosts: "7477706",
    valueOfReclassifiedAssets: 2377327,
    depreciableAssets: 33,
  },
  {
    lat: 32.3395031,
    lng: -110.9864294,
    squareFootage: "141820",
    propertyType: "Multifamily",
    totalCosts: "15900000",
    valueOfReclassifiedAssets: 5200287,
    depreciableAssets: 33,
  },
  {
    lat: 45.4053517,
    lng: -122.9907521,
    squareFootage: "264464",
    propertyType: "Retail",
    totalCosts: "32586390",
    valueOfReclassifiedAssets: 29057025,
    depreciableAssets: 29,
  },
  {
    lat: 34.0543797,
    lng: -118.2672801,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7035741",
    valueOfReclassifiedAssets: 6200112,
    depreciableAssets: 14,
  },
  {
    lat: 38.9953837,
    lng: -74.8390162,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "688809",
    valueOfReclassifiedAssets: 473875,
    depreciableAssets: 32,
  },
  {
    lat: 45.516964,
    lng: -122.6732897,
    squareFootage: "88175",
    propertyType: "Office",
    totalCosts: "11245754",
    valueOfReclassifiedAssets: 1454107,
    depreciableAssets: 15,
  },
  {
    lat: 41.9511782,
    lng: -74.006509,
    squareFootage: "208803",
    propertyType: "Multifamily",
    totalCosts: "26666666",
    valueOfReclassifiedAssets: 7091860,
    depreciableAssets: 31,
  },
  {
    lat: 39.5123182,
    lng: -76.18996609999999,
    squareFootage: "97885",
    propertyType: "Multifamily",
    totalCosts: "9501520",
    valueOfReclassifiedAssets: 2126816,
    depreciableAssets: 26,
  },
  {
    lat: 41.47019890000001,
    lng: -87.32149079999999,
    squareFootage: "31410",
    propertyType: "Industrial",
    totalCosts: "1508168",
    valueOfReclassifiedAssets: 284901,
    depreciableAssets: 24,
  },
  {
    lat: 34.2252697,
    lng: -118.442596,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8929121",
    valueOfReclassifiedAssets: 7887087,
    depreciableAssets: 13,
  },
  {
    lat: 34.0880507,
    lng: -118.2965121,
    squareFootage: "5554",
    propertyType: "Multifamily",
    totalCosts: "2401258",
    valueOfReclassifiedAssets: 468436,
    depreciableAssets: 22,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "34470",
    propertyType: "Multifamily",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 556757,
    depreciableAssets: 15,
  },
  {
    lat: 34.2453437,
    lng: -82.1387233,
    squareFootage: "104099",
    propertyType: "Multifamily",
    totalCosts: "4136112",
    valueOfReclassifiedAssets: 1464608,
    depreciableAssets: 36,
  },
  {
    lat: 32.7759515,
    lng: -97.4002872,
    squareFootage: "58000",
    propertyType: "Multifamily",
    totalCosts: "5180000",
    valueOfReclassifiedAssets: 1435739,
    depreciableAssets: 31,
  },
  {
    lat: 41.1263566,
    lng: -81.3509416,
    squareFootage: "29826",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4332391",
    valueOfReclassifiedAssets: 2354251,
    depreciableAssets: 40,
  },
  {
    lat: 41.3862816,
    lng: -74.347017,
    squareFootage: "144392",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10465153",
    valueOfReclassifiedAssets: 2037419,
    depreciableAssets: 21,
  },
  {
    lat: 34.1531191,
    lng: -118.2614332,
    squareFootage: "28383",
    propertyType: "Multifamily",
    totalCosts: "5720000",
    valueOfReclassifiedAssets: 815332,
    depreciableAssets: 21,
  },
  {
    lat: 34.0434783,
    lng: -118.2519314,
    squareFootage: "107396",
    propertyType: "Office",
    totalCosts: "13333882",
    valueOfReclassifiedAssets: 1837148,
    depreciableAssets: 15,
  },
  {
    lat: 34.2864053,
    lng: -118.3111262,
    squareFootage: "34690",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7792156",
    valueOfReclassifiedAssets: 1767281,
    depreciableAssets: 26,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "3886",
    propertyType: "Specialty",
    totalCosts: "1530000",
    valueOfReclassifiedAssets: 449548,
    depreciableAssets: 40,
  },
  {
    lat: 32.799475,
    lng: -96.78687939999999,
    squareFootage: "39746",
    propertyType: "Multifamily",
    totalCosts: "1024675",
    valueOfReclassifiedAssets: 242875,
    depreciableAssets: 28,
  },
  {
    lat: 27.9006925,
    lng: -82.68614459999999,
    squareFootage: "60000",
    propertyType: "Specialty",
    totalCosts: "4539493",
    valueOfReclassifiedAssets: 1128891,
    depreciableAssets: 27,
  },
  {
    lat: 38.9963501,
    lng: -76.97006689999999,
    squareFootage: "206725",
    propertyType: "Multifamily",
    totalCosts: "10021",
    valueOfReclassifiedAssets: 1263,
    depreciableAssets: 14,
  },
  {
    lat: 44.4812116,
    lng: -88.01674059999999,
    squareFootage: "23529",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 589132,
    depreciableAssets: 33,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "167600",
    propertyType: "Multifamily",
    totalCosts: "13839956",
    valueOfReclassifiedAssets: 4078944,
    depreciableAssets: 33,
  },
  {
    lat: 33.8409696,
    lng: -118.3520389,
    squareFootage: "5075",
    propertyType: "Multifamily",
    totalCosts: "1545089",
    valueOfReclassifiedAssets: 236325,
    depreciableAssets: 17,
  },
  {
    lat: 34.1421988,
    lng: -77.91674569999999,
    squareFootage: "220755",
    propertyType: "Industrial",
    totalCosts: "26400715",
    valueOfReclassifiedAssets: 4466102,
    depreciableAssets: 19,
  },
  {
    lat: 36.3790421,
    lng: -79.6951103,
    squareFootage: "452000",
    propertyType: "Industrial",
    totalCosts: "5950000",
    valueOfReclassifiedAssets: 564898,
    depreciableAssets: 11,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "59100",
    propertyType: "Multifamily",
    totalCosts: "15700000",
    valueOfReclassifiedAssets: 1820434,
    depreciableAssets: 14,
  },
  {
    lat: 33.9488147,
    lng: -84.53794549999999,
    squareFootage: "65540",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1553669,
    depreciableAssets: 30,
  },
  {
    lat: 28.3430219,
    lng: -81.5815776,
    squareFootage: "4100",
    propertyType: "Multifamily",
    totalCosts: "850000",
    valueOfReclassifiedAssets: 323585,
    depreciableAssets: 45,
  },
  {
    lat: 41.5339129,
    lng: -80.8322428,
    squareFootage: "32000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1207550",
    valueOfReclassifiedAssets: 354644,
    depreciableAssets: 30,
  },
  {
    lat: 38.5824933,
    lng: -121.4941738,
    squareFootage: "22940",
    propertyType: "Retail",
    totalCosts: "1070395",
    valueOfReclassifiedAssets: 176139,
    depreciableAssets: 21,
  },
  {
    lat: 34.99474410000001,
    lng: -85.2256635,
    squareFootage: "144481",
    propertyType: "Hotel",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 285741,
    depreciableAssets: 24,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6085028",
    valueOfReclassifiedAssets: 2634746,
    depreciableAssets: 57,
  },
  {
    lat: 34.296676,
    lng: -118.2292712,
    squareFootage: "18179",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1871511,
    depreciableAssets: 41,
  },
  {
    lat: 43.0439776,
    lng: -87.8991514,
    squareFootage: "9489",
    propertyType: "Multifamily",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 549899,
    depreciableAssets: 30,
  },
  {
    lat: 29.4854054,
    lng: -98.4513272,
    squareFootage: "2019",
    propertyType: "Office",
    totalCosts: "25415000",
    valueOfReclassifiedAssets: 3235507,
    depreciableAssets: 14,
  },
  {
    lat: 40.32457309999999,
    lng: -74.0593872,
    squareFootage: "61914",
    propertyType: "Office",
    totalCosts: "8052364",
    valueOfReclassifiedAssets: 1390616,
    depreciableAssets: 21,
  },
  {
    lat: 35.6544684,
    lng: -78.5887951,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4007918",
    valueOfReclassifiedAssets: 2962895,
    depreciableAssets: 49,
  },
  {
    lat: 40.4861552,
    lng: -74.45185,
    squareFootage: "187000",
    propertyType: "Industrial",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 1244494,
    depreciableAssets: 10,
  },
  {
    lat: 39.3614334,
    lng: -76.5892774,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "5224633",
    valueOfReclassifiedAssets: 1308335,
    depreciableAssets: 29,
  },
  {
    lat: 40.6440775,
    lng: -73.5888575,
    squareFootage: "40156",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 1237535,
    depreciableAssets: 22,
  },
  {
    lat: 44.8109158,
    lng: -73.0862585,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2457979",
    valueOfReclassifiedAssets: 1972792,
    depreciableAssets: 22,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "276260",
    propertyType: "Multifamily",
    totalCosts: "8750214",
    valueOfReclassifiedAssets: 1931684,
    depreciableAssets: 30,
  },
  {
    lat: 42.4836453,
    lng: -71.4418101,
    squareFootage: "66825",
    propertyType: "Office",
    totalCosts: "2715157",
    valueOfReclassifiedAssets: 610932,
    depreciableAssets: 26,
  },
  {
    lat: 41.6670429,
    lng: -83.5523205,
    squareFootage: "115370",
    propertyType: "Multifamily",
    totalCosts: "5252424",
    valueOfReclassifiedAssets: 1262850,
    depreciableAssets: 24,
  },
  {
    lat: 41.5214566,
    lng: -81.6418656,
    squareFootage: "89546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7642445",
    valueOfReclassifiedAssets: 3087088,
    depreciableAssets: 21,
  },
  {
    lat: 41.9647407,
    lng: -70.9665682,
    squareFootage: "289392",
    propertyType: "Office",
    totalCosts: "6183137",
    valueOfReclassifiedAssets: 892112,
    depreciableAssets: 18,
  },
  {
    lat: 42.50007189999999,
    lng: -82.9394002,
    squareFootage: "537220",
    propertyType: "Retail",
    totalCosts: "23382741",
    valueOfReclassifiedAssets: 4583633,
    depreciableAssets: 23,
  },
  {
    lat: 32.799475,
    lng: -96.78687939999999,
    squareFootage: "39746",
    propertyType: "Multifamily",
    totalCosts: "2312850",
    valueOfReclassifiedAssets: 548205,
    depreciableAssets: 28,
  },
  {
    lat: 41.7087701,
    lng: -87.74680710000001,
    squareFootage: "117152",
    propertyType: "Multifamily",
    totalCosts: "7616000",
    valueOfReclassifiedAssets: 1889297,
    depreciableAssets: 30,
  },
  {
    lat: 45.4874111,
    lng: -122.6875541,
    squareFootage: "54955",
    propertyType: "Office",
    totalCosts: "9408528",
    valueOfReclassifiedAssets: 1651161,
    depreciableAssets: 29,
  },
  {
    lat: 38.7874699,
    lng: -90.6298922,
    squareFootage: "24222",
    propertyType: "Industrial",
    totalCosts: "1218236",
    valueOfReclassifiedAssets: 205419,
    depreciableAssets: 21,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "20538380",
    valueOfReclassifiedAssets: 19129004,
    depreciableAssets: 7,
  },
  {
    lat: 32.2917352,
    lng: -97.5030669,
    squareFootage: "140812",
    propertyType: "Multifamily",
    totalCosts: "8153000",
    valueOfReclassifiedAssets: 1919246,
    depreciableAssets: 28,
  },
  {
    lat: 42.0660499,
    lng: -88.01674059999999,
    squareFootage: "41497",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1629862,
    depreciableAssets: 30,
  },
  {
    lat: 34.99474410000001,
    lng: -85.2256635,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3618000",
    valueOfReclassifiedAssets: 2390287,
    depreciableAssets: 38,
  },
  {
    lat: 34.097004,
    lng: -117.0676398,
    squareFootage: "16660",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1375200,
    depreciableAssets: 40,
  },
  {
    lat: 32.5145763,
    lng: -90.13077969999999,
    squareFootage: "41108",
    propertyType: "Retail",
    totalCosts: "5300000",
    valueOfReclassifiedAssets: 1942275,
    depreciableAssets: 43,
  },
  {
    lat: 41.0887754,
    lng: -74.1827119,
    squareFootage: "99541",
    propertyType: "Office",
    totalCosts: "5076594",
    valueOfReclassifiedAssets: 928229,
    depreciableAssets: 20,
  },
  {
    lat: 40.8300731,
    lng: -73.86836970000002,
    squareFootage: "15615",
    propertyType: "Multifamily",
    totalCosts: "150000",
    valueOfReclassifiedAssets: 124950,
    depreciableAssets: 19,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "20222",
    propertyType: "Retail",
    totalCosts: "6398684",
    valueOfReclassifiedAssets: 947952,
    depreciableAssets: 19,
  },
  {
    lat: 34.1421988,
    lng: -77.91674569999999,
    squareFootage: "20900",
    propertyType: "Industrial",
    totalCosts: "3164554",
    valueOfReclassifiedAssets: 895633,
    depreciableAssets: 34,
  },
  {
    lat: 36.5591854,
    lng: -82.36688699999999,
    squareFootage: "99560",
    propertyType: "Multifamily",
    totalCosts: "6540000",
    valueOfReclassifiedAssets: 1585476,
    depreciableAssets: 28,
  },
  {
    lat: 34.1527251,
    lng: -118.2117257,
    squareFootage: "33000",
    propertyType: "Multifamily",
    totalCosts: "8850000",
    valueOfReclassifiedAssets: 1627244,
    depreciableAssets: 27,
  },
  {
    lat: 42.328245,
    lng: -83.0495622,
    squareFootage: "73390",
    propertyType: "Office",
    totalCosts: "5185825",
    valueOfReclassifiedAssets: 4515047,
    depreciableAssets: 15,
  },
  {
    lat: 40.8630363,
    lng: -73.8972251,
    squareFootage: "2823",
    propertyType: "Industrial",
    totalCosts: "7559835",
    valueOfReclassifiedAssets: 722989,
    depreciableAssets: 12,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6085028",
    valueOfReclassifiedAssets: 2634746,
    depreciableAssets: 57,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "329433",
    propertyType: "Multifamily",
    totalCosts: "28500000",
    valueOfReclassifiedAssets: 8330609,
    depreciableAssets: 32,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "3980",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 102863,
    depreciableAssets: 8,
  },
  {
    lat: 33.9425519,
    lng: -80.9539475,
    squareFootage: "114660",
    propertyType: "Multifamily",
    totalCosts: "5074133",
    valueOfReclassifiedAssets: 1431665,
    depreciableAssets: 31,
  },
  {
    lat: 38.8095735,
    lng: -90.3465971,
    squareFootage: "147747",
    propertyType: "Retail",
    totalCosts: "16575000",
    valueOfReclassifiedAssets: 4183284,
    depreciableAssets: 31,
  },
  {
    lat: 41.9480522,
    lng: -83.4003037,
    squareFootage: "27000",
    propertyType: "Retail",
    totalCosts: "1416832",
    valueOfReclassifiedAssets: 225510,
    depreciableAssets: 20,
  },
  {
    lat: 38.9049922,
    lng: -75.6440842,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1032132",
    valueOfReclassifiedAssets: 1009791,
    depreciableAssets: 14,
  },
  {
    lat: 41.83353169999999,
    lng: -71.3230549,
    squareFootage: "63880",
    propertyType: "Industrial",
    totalCosts: "4125647",
    valueOfReclassifiedAssets: 311799,
    depreciableAssets: 9,
  },
  {
    lat: 37.0538822,
    lng: -76.39845369999999,
    squareFootage: "62856",
    propertyType: "Office",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1115566,
    depreciableAssets: 15,
  },
  {
    lat: 35.6544684,
    lng: -78.5887951,
    squareFootage: "61965",
    propertyType: "Industrial",
    totalCosts: "1565089",
    valueOfReclassifiedAssets: 1469732,
    depreciableAssets: 38,
  },
  {
    lat: 35.9530958,
    lng: -78.9571069,
    squareFootage: "323370",
    propertyType: "Multifamily",
    totalCosts: "49275000",
    valueOfReclassifiedAssets: 13523942,
    depreciableAssets: 33,
  },
  {
    lat: 36.40516909999999,
    lng: -86.438974,
    squareFootage: "498893",
    propertyType: "Industrial",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1072206,
    depreciableAssets: 28,
  },
  {
    lat: 28.3430219,
    lng: -81.5815776,
    squareFootage: "4922",
    propertyType: "Multifamily",
    totalCosts: "823000",
    valueOfReclassifiedAssets: 171248,
    depreciableAssets: 25,
  },
  {
    lat: 32.8695051,
    lng: -96.78955739999999,
    squareFootage: "39746",
    propertyType: "Multifamily",
    totalCosts: "912475",
    valueOfReclassifiedAssets: 216280,
    depreciableAssets: 28,
  },
  {
    lat: 34.215743,
    lng: -118.446358,
    squareFootage: "22405",
    propertyType: "Multifamily",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 614293,
    depreciableAssets: 28,
  },
  {
    lat: 33.1837961,
    lng: -97.1305536,
    squareFootage: "80248",
    propertyType: "Multifamily",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 2165203,
    depreciableAssets: 29,
  },
  {
    lat: 26.3003637,
    lng: -80.1819268,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10318535",
    valueOfReclassifiedAssets: 9680575,
    depreciableAssets: 28,
  },
  {
    lat: 34.0655627,
    lng: -118.3096648,
    squareFootage: "16518",
    propertyType: "Multifamily",
    totalCosts: "3130000",
    valueOfReclassifiedAssets: 479836,
    depreciableAssets: 23,
  },
  {
    lat: 34.00684500000001,
    lng: -83.3468784,
    squareFootage: "9658",
    propertyType: "Office",
    totalCosts: "6253554",
    valueOfReclassifiedAssets: 2464978,
    depreciableAssets: 46,
  },
  {
    lat: 44.0229305,
    lng: -73.1450146,
    squareFootage: "40968",
    propertyType: "Office",
    totalCosts: "3082841",
    valueOfReclassifiedAssets: 2446653,
    depreciableAssets: 25,
  },
  {
    lat: 42.8846539,
    lng: -85.55091639999999,
    squareFootage: "27574",
    propertyType: "Industrial",
    totalCosts: "1414128",
    valueOfReclassifiedAssets: 282047,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "917611",
    valueOfReclassifiedAssets: 702251,
    depreciableAssets: 25,
  },
  {
    lat: 38.8814089,
    lng: -75.78365889999999,
    squareFootage: "99971",
    propertyType: "Retail",
    totalCosts: "9459479",
    valueOfReclassifiedAssets: 1653514,
    depreciableAssets: 22,
  },
  {
    lat: 40.7658303,
    lng: -89.59690599999999,
    squareFootage: "98044",
    propertyType: "Retail",
    totalCosts: "9024770",
    valueOfReclassifiedAssets: 7666536,
    depreciableAssets: 35,
  },
  {
    lat: 38.9963501,
    lng: -76.97006689999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5706933",
    valueOfReclassifiedAssets: 4293583,
    depreciableAssets: 14,
  },
  {
    lat: 39.3614334,
    lng: -76.5892774,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "23635",
    valueOfReclassifiedAssets: 5919,
    depreciableAssets: 29,
  },
  {
    lat: 33.8331717,
    lng: -118.2906661,
    squareFootage: "59739",
    propertyType: "Office",
    totalCosts: "21600000",
    valueOfReclassifiedAssets: 1978164,
    depreciableAssets: 15,
  },
  {
    lat: 28.291625,
    lng: -80.7879407,
    squareFootage: "186682",
    propertyType: "Industrial",
    totalCosts: "5170889",
    valueOfReclassifiedAssets: 1582446,
    depreciableAssets: 39,
  },
  {
    lat: 34.3023653,
    lng: -77.9852853,
    squareFootage: "87030",
    propertyType: "Industrial",
    totalCosts: "9528297",
    valueOfReclassifiedAssets: 2130482,
    depreciableAssets: 25,
  },
  {
    lat: 28.6626293,
    lng: -81.4114142,
    squareFootage: "164448",
    propertyType: "Multifamily",
    totalCosts: "17900000",
    valueOfReclassifiedAssets: 4655843,
    depreciableAssets: 34,
  },
  {
    lat: 40.8300731,
    lng: -73.86836970000002,
    squareFootage: "15615",
    propertyType: "Multifamily",
    totalCosts: "2954390",
    valueOfReclassifiedAssets: 487960,
    depreciableAssets: 19,
  },
  {
    lat: 40.8798669,
    lng: -74.0799469,
    squareFootage: "4227",
    propertyType: "Multifamily",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 163739,
    depreciableAssets: 18,
  },
  {
    lat: 41.5993157,
    lng: -83.6295222,
    squareFootage: "84590",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 1136413,
    depreciableAssets: 26,
  },
  {
    lat: 34.2413938,
    lng: -118.5506158,
    squareFootage: "9372",
    propertyType: "Specialty",
    totalCosts: "4475000",
    valueOfReclassifiedAssets: 1491549,
    depreciableAssets: 40,
  },
  {
    lat: 42.2638905,
    lng: -71.6080009,
    squareFootage: "7113",
    propertyType: "Multifamily",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 144316,
    depreciableAssets: 21,
  },
  {
    lat: 34.0512519,
    lng: -84.7211074,
    squareFootage: "48100",
    propertyType: "Office",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 1350980,
    depreciableAssets: 28,
  },
  {
    lat: 39.0362552,
    lng: -94.57533939999999,
    squareFootage: "325070",
    propertyType: "Multifamily",
    totalCosts: "17563961",
    valueOfReclassifiedAssets: 3935468,
    depreciableAssets: 26,
  },
  {
    lat: 37.3290122,
    lng: -121.9160211,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "925000",
    valueOfReclassifiedAssets: 376722,
    depreciableAssets: 47,
  },
  {
    lat: 40.6845194,
    lng: -89.5403279,
    squareFootage: "43723",
    propertyType: "Hotel",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 1260946,
    depreciableAssets: 26,
  },
  {
    lat: 39.1654029,
    lng: -84.6165146,
    squareFootage: "101830",
    propertyType: "Multifamily",
    totalCosts: "4953476",
    valueOfReclassifiedAssets: 1177915,
    depreciableAssets: 28,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13647866",
    valueOfReclassifiedAssets: 12010812,
    depreciableAssets: 12,
  },
  {
    lat: 40.6815619,
    lng: -73.9785952,
    squareFootage: "4356",
    propertyType: "Multifamily",
    totalCosts: "1773612",
    valueOfReclassifiedAssets: 322661,
    depreciableAssets: 25,
  },
  {
    lat: 33.8955185,
    lng: -84.5903367,
    squareFootage: "60800",
    propertyType: "Multifamily",
    totalCosts: "5050000",
    valueOfReclassifiedAssets: 1165456,
    depreciableAssets: 27,
  },
  {
    lat: 40.7349144,
    lng: -73.7213063,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 298117,
    depreciableAssets: 39,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "241380",
    propertyType: "Hotel",
    totalCosts: "20325000",
    valueOfReclassifiedAssets: 3861964,
    depreciableAssets: 22,
  },
  {
    lat: 35.0729762,
    lng: -106.6173415,
    squareFootage: "51556",
    propertyType: "Multifamily",
    totalCosts: "1928377",
    valueOfReclassifiedAssets: 495409,
    depreciableAssets: 27,
  },
  {
    lat: 33.6832497,
    lng: -117.8340735,
    squareFootage: "75405",
    propertyType: "Office",
    totalCosts: "14644804",
    valueOfReclassifiedAssets: 1099872,
    depreciableAssets: 12,
  },
  {
    lat: 38.9049922,
    lng: -75.6440842,
    squareFootage: "97646",
    propertyType: "Retail",
    totalCosts: "2746691",
    valueOfReclassifiedAssets: 516311,
    depreciableAssets: 25,
  },
  {
    lat: 31.8964712,
    lng: -106.4288088,
    squareFootage: "269200",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 3337241,
    depreciableAssets: 27,
  },
  {
    lat: 44.0229305,
    lng: -73.1450146,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2302717",
    valueOfReclassifiedAssets: 1437142,
    depreciableAssets: 0,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "94400",
    propertyType: "Multifamily",
    totalCosts: "3275609",
    valueOfReclassifiedAssets: 809480,
    depreciableAssets: 32,
  },
  {
    lat: 37.3290122,
    lng: -121.9160211,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1330000",
    valueOfReclassifiedAssets: 398732,
    depreciableAssets: 35,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "22281",
    propertyType: "Multifamily",
    totalCosts: "6720000",
    valueOfReclassifiedAssets: 760143,
    depreciableAssets: 13,
  },
  {
    lat: 34.2577465,
    lng: -78.0880129,
    squareFootage: "79590",
    propertyType: "Industrial",
    totalCosts: "5896476",
    valueOfReclassifiedAssets: 1300062,
    depreciableAssets: 26,
  },
  {
    lat: 42.1744247,
    lng: -71.6732681,
    squareFootage: "13747",
    propertyType: "Multifamily",
    totalCosts: "1075000",
    valueOfReclassifiedAssets: 159013,
    depreciableAssets: 17,
  },
  {
    lat: 41.4889702,
    lng: -83.01465259999999,
    squareFootage: "29448",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4748928",
    valueOfReclassifiedAssets: 2583260,
    depreciableAssets: 41,
  },
  {
    lat: 34.1423899,
    lng: -118.4571974,
    squareFootage: "10155",
    propertyType: "Specialty",
    totalCosts: "2620759",
    valueOfReclassifiedAssets: 508922,
    depreciableAssets: 26,
  },
  {
    lat: 35.0074096,
    lng: -81.22985299999999,
    squareFootage: "69975",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "46000000",
    valueOfReclassifiedAssets: 10365814,
    depreciableAssets: 25,
  },
  {
    lat: 40.5183163,
    lng: -88.9701082,
    squareFootage: "35259",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 619529,
    depreciableAssets: 28,
  },
  {
    lat: 28.288056,
    lng: -81.8224362,
    squareFootage: "650808",
    propertyType: "Industrial",
    totalCosts: "40633056",
    valueOfReclassifiedAssets: 5346984,
    depreciableAssets: 14,
  },
  {
    lat: 36.8488728,
    lng: -76.291355,
    squareFootage: "403276",
    propertyType: "Office",
    totalCosts: "62661821",
    valueOfReclassifiedAssets: 7927320,
    depreciableAssets: 15,
  },
  {
    lat: 40.4853645,
    lng: -105.2428853,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6600000",
    valueOfReclassifiedAssets: 5610000,
    depreciableAssets: 100,
  },
  {
    lat: 40.923473,
    lng: -74.1445487,
    squareFootage: "73836",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "22251497",
    valueOfReclassifiedAssets: 16324723,
    depreciableAssets: 29,
  },
  {
    lat: 32.6623667,
    lng: -97.4219122,
    squareFootage: "6568",
    propertyType: "Specialty",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 2396961,
    depreciableAssets: 52,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "32104",
    propertyType: "Multifamily",
    totalCosts: "3475000",
    valueOfReclassifiedAssets: 467960,
    depreciableAssets: 15,
  },
  {
    lat: 29.240991,
    lng: -81.06994619999999,
    squareFootage: "22000",
    propertyType: "Office",
    totalCosts: "11425000",
    valueOfReclassifiedAssets: 3250841,
    depreciableAssets: 34,
  },
  {
    lat: 40.6749343,
    lng: -73.8713099,
    squareFootage: "14651",
    propertyType: "Multifamily",
    totalCosts: "968123",
    valueOfReclassifiedAssets: 130275,
    depreciableAssets: 17,
  },
  {
    lat: 33.5494383,
    lng: -84.20679919999999,
    squareFootage: "2017",
    propertyType: "Retail",
    totalCosts: "11600000",
    valueOfReclassifiedAssets: 2021949,
    depreciableAssets: 23,
  },
  {
    lat: 41.6882735,
    lng: -71.51898,
    squareFootage: "45574",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10485000",
    valueOfReclassifiedAssets: 2805027,
    depreciableAssets: 31,
  },
  {
    lat: 34.5363567,
    lng: -86.9223432,
    squareFootage: "54258",
    propertyType: "Multifamily",
    totalCosts: "2470000",
    valueOfReclassifiedAssets: 627892,
    depreciableAssets: 30,
  },
  {
    lat: 38.3836812,
    lng: -77.53901270000001,
    squareFootage: "40631",
    propertyType: "Retail",
    totalCosts: "1847154",
    valueOfReclassifiedAssets: 1604526,
    depreciableAssets: 30,
  },
  {
    lat: 28.8736713,
    lng: -82.3595994,
    squareFootage: "142196",
    propertyType: "Retail",
    totalCosts: "15813232",
    valueOfReclassifiedAssets: 3650363,
    depreciableAssets: 27,
  },
  {
    lat: 42.5586572,
    lng: -83.18097759999999,
    squareFootage: "496330",
    propertyType: "Office",
    totalCosts: "24691200",
    valueOfReclassifiedAssets: 4131703,
    depreciableAssets: 20,
  },
  {
    lat: 35.4002721,
    lng: -80.6548882,
    squareFootage: "64725",
    propertyType: "Industrial",
    totalCosts: "5324735",
    valueOfReclassifiedAssets: 726745,
    depreciableAssets: 16,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "155274",
    propertyType: "Specialty",
    totalCosts: "12805000",
    valueOfReclassifiedAssets: 3007193,
    depreciableAssets: 27,
  },
  {
    lat: 44.28092729999999,
    lng: -72.61950759999999,
    squareFootage: "21657",
    propertyType: "Office",
    totalCosts: "1228925",
    valueOfReclassifiedAssets: 179400,
    depreciableAssets: 17,
  },
  {
    lat: 37.3290122,
    lng: -121.9160211,
    squareFootage: "4274",
    propertyType: "Retail",
    totalCosts: "1875000",
    valueOfReclassifiedAssets: 135890,
    depreciableAssets: 13,
  },
  {
    lat: 37.5044419,
    lng: -77.46446069999999,
    squareFootage: "262640",
    propertyType: "Multifamily",
    totalCosts: "20200000",
    valueOfReclassifiedAssets: 4277287,
    depreciableAssets: 25,
  },
  {
    lat: 32.3560371,
    lng: -99.8348146,
    squareFootage: "191646",
    propertyType: "Multifamily",
    totalCosts: "16250000",
    valueOfReclassifiedAssets: 3826288,
    depreciableAssets: 28,
  },
  {
    lat: 44.0229305,
    lng: -73.1450146,
    squareFootage: "116000",
    propertyType: "Industrial",
    totalCosts: "1253534",
    valueOfReclassifiedAssets: 199228,
    depreciableAssets: 18,
  },
  {
    lat: 41.5721298,
    lng: -81.521241,
    squareFootage: "88000",
    propertyType: "Multifamily",
    totalCosts: "4028889",
    valueOfReclassifiedAssets: 659267,
    depreciableAssets: 19,
  },
  {
    lat: 34.7521938,
    lng: -82.669947,
    squareFootage: "320000",
    propertyType: "Industrial",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 450662,
    depreciableAssets: 19,
  },
  {
    lat: 40.5127082,
    lng: -89.9626799,
    squareFootage: "30789",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 625986,
    depreciableAssets: 35,
  },
  {
    lat: 61.19114399999999,
    lng: -149.8902415,
    squareFootage: "27386",
    propertyType: "Office",
    totalCosts: "3512527",
    valueOfReclassifiedAssets: 659648,
    depreciableAssets: 21,
  },
  {
    lat: 32.9533477,
    lng: -97.1467072,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 460500,
    depreciableAssets: 49,
  },
  {
    lat: 30.243028,
    lng: -81.6309078,
    squareFootage: "130292",
    propertyType: "Multifamily",
    totalCosts: "12070000",
    valueOfReclassifiedAssets: 3498741,
    depreciableAssets: 34,
  },
  {
    lat: 39.0795392,
    lng: -94.5985613,
    squareFootage: "36648",
    propertyType: "Multifamily",
    totalCosts: "18030360",
    valueOfReclassifiedAssets: 2232028,
    depreciableAssets: 14,
  },
  {
    lat: 32.23063640000001,
    lng: -80.7325287,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "8991720",
    valueOfReclassifiedAssets: 2538043,
    depreciableAssets: 32,
  },
  {
    lat: 34.2985355,
    lng: -119.2204548,
    squareFootage: "14245",
    propertyType: "Specialty",
    totalCosts: "1805000",
    valueOfReclassifiedAssets: 458718,
    depreciableAssets: 31,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "4800",
    propertyType: "Multifamily",
    totalCosts: "3170000",
    valueOfReclassifiedAssets: 763835,
    depreciableAssets: 28,
  },
  {
    lat: 40.2397291,
    lng: -84.5012435,
    squareFootage: "41081",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12312035",
    valueOfReclassifiedAssets: 6540526,
    depreciableAssets: 33,
  },
  {
    lat: 26.7619563,
    lng: -80.1037721,
    squareFootage: "65770",
    propertyType: "Office",
    totalCosts: "22425506",
    valueOfReclassifiedAssets: 6367122,
    depreciableAssets: 33,
  },
  {
    lat: 42.5586572,
    lng: -83.18097759999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "195932",
    valueOfReclassifiedAssets: 112170,
    depreciableAssets: 0,
  },
  {
    lat: 41.5721298,
    lng: -81.521241,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "620000",
    valueOfReclassifiedAssets: 318344,
    depreciableAssets: 54,
  },
  {
    lat: 39.7622516,
    lng: -75.11951069999999,
    squareFootage: "64513",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6227134",
    valueOfReclassifiedAssets: 4976214,
    depreciableAssets: 25,
  },
  {
    lat: 29.8963788,
    lng: -95.16406049999999,
    squareFootage: "373558",
    propertyType: "Multifamily",
    totalCosts: "41000000",
    valueOfReclassifiedAssets: 8610212,
    depreciableAssets: 25,
  },
  {
    lat: 41.4766201,
    lng: -81.50477719999999,
    squareFootage: "163018",
    propertyType: "Office",
    totalCosts: "6571904",
    valueOfReclassifiedAssets: 5072390,
    depreciableAssets: 29,
  },
  {
    lat: 34.761354,
    lng: -87.0824045,
    squareFootage: "92261",
    propertyType: "Multifamily",
    totalCosts: "3310000",
    valueOfReclassifiedAssets: 922298,
    depreciableAssets: 33,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "5500",
    propertyType: "Multifamily",
    totalCosts: "2771058",
    valueOfReclassifiedAssets: 2100317,
    depreciableAssets: 24,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "94400",
    propertyType: "Multifamily",
    totalCosts: "1164771",
    valueOfReclassifiedAssets: 287842,
    depreciableAssets: 32,
  },
  {
    lat: 35.9530958,
    lng: -78.9571069,
    squareFootage: "44172",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1017597,
    depreciableAssets: 26,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "3900",
    propertyType: "Multifamily",
    totalCosts: "446304",
    valueOfReclassifiedAssets: 60327,
    depreciableAssets: 17,
  },
  {
    lat: 41.7493082,
    lng: -81.0533854,
    squareFootage: "50451",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3898600",
    valueOfReclassifiedAssets: 1145017,
    depreciableAssets: 32,
  },
  {
    lat: 34.2453437,
    lng: -82.1387233,
    squareFootage: "115319",
    propertyType: "Industrial",
    totalCosts: "920000",
    valueOfReclassifiedAssets: 242536,
    depreciableAssets: 31,
  },
  {
    lat: 40.7231264,
    lng: -74.1416127,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1089092",
    valueOfReclassifiedAssets: 983143,
    depreciableAssets: 11,
  },
  {
    lat: 40.7658303,
    lng: -89.59690599999999,
    squareFootage: "54299",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 897658,
    depreciableAssets: 34,
  },
  {
    lat: 40.7470968,
    lng: -73.4828374,
    squareFootage: "16961",
    propertyType: "Retail",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1196092,
    depreciableAssets: 23,
  },
  {
    lat: 39.10436869999999,
    lng: -76.8836261,
    squareFootage: "594493",
    propertyType: "Multifamily",
    totalCosts: "122169587",
    valueOfReclassifiedAssets: 21455313,
    depreciableAssets: 21,
  },
  {
    lat: 39.9475712,
    lng: -83.7730237,
    squareFootage: "42243",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10817002",
    valueOfReclassifiedAssets: 5578859,
    depreciableAssets: 33,
  },
  {
    lat: 32.4545928,
    lng: -93.70797449999999,
    squareFootage: "352571",
    propertyType: "Multifamily",
    totalCosts: "18600000",
    valueOfReclassifiedAssets: 5613717,
    depreciableAssets: 35,
  },
  {
    lat: 29.3916291,
    lng: -98.56660769999999,
    squareFootage: "326568",
    propertyType: "Multifamily",
    totalCosts: "11800000",
    valueOfReclassifiedAssets: 2980170,
    depreciableAssets: 30,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "13945",
    propertyType: "Multifamily",
    totalCosts: "1933358",
    valueOfReclassifiedAssets: 191334,
    depreciableAssets: 11,
  },
  {
    lat: 27.9363828,
    lng: -82.37773349999999,
    squareFootage: "165535",
    propertyType: "Industrial",
    totalCosts: "5100000",
    valueOfReclassifiedAssets: 1304184,
    depreciableAssets: 33,
  },
  {
    lat: 38.4319863,
    lng: -77.99670429999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11328181",
    valueOfReclassifiedAssets: 8079987,
    depreciableAssets: 35,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "97620",
    propertyType: "Multifamily",
    totalCosts: "6847200",
    valueOfReclassifiedAssets: 666812,
    depreciableAssets: 11,
  },
  {
    lat: 29.702465,
    lng: -95.53548160000001,
    squareFootage: "216721",
    propertyType: "Office",
    totalCosts: "11382114",
    valueOfReclassifiedAssets: 2136844,
    depreciableAssets: 22,
  },
  {
    lat: 40.7231264,
    lng: -74.1416127,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6686696",
    valueOfReclassifiedAssets: 5457058,
    depreciableAssets: 19,
  },
  {
    lat: 39.5137274,
    lng: -121.5568104,
    squareFootage: "10580",
    propertyType: "Multifamily",
    totalCosts: "767557",
    valueOfReclassifiedAssets: 215098,
    depreciableAssets: 36,
  },
  {
    lat: 41.90729530000001,
    lng: -80.5660657,
    squareFootage: "36000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2436300",
    valueOfReclassifiedAssets: 761651,
    depreciableAssets: 32,
  },
  {
    lat: 42.1272261,
    lng: -87.84515270000001,
    squareFootage: "65401",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1458810,
    depreciableAssets: 27,
  },
  {
    lat: 36.1055775,
    lng: -83.4430118,
    squareFootage: "486315",
    propertyType: "Industrial",
    totalCosts: "10350000",
    valueOfReclassifiedAssets: 2408214,
    depreciableAssets: 28,
  },
  {
    lat: 28.7571267,
    lng: -81.3509416,
    squareFootage: "48054",
    propertyType: "Industrial",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 987253,
    depreciableAssets: 29,
  },
  {
    lat: 40.8432396,
    lng: -72.6505957,
    squareFootage: "75900",
    propertyType: "Industrial",
    totalCosts: "12237046",
    valueOfReclassifiedAssets: 1931407,
    depreciableAssets: 21,
  },
  {
    lat: 29.9456874,
    lng: -81.73494199999999,
    squareFootage: "90704",
    propertyType: "Retail",
    totalCosts: "5800000",
    valueOfReclassifiedAssets: 1590355,
    depreciableAssets: 33,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "13945",
    propertyType: "Multifamily",
    totalCosts: "2017578",
    valueOfReclassifiedAssets: 206927,
    depreciableAssets: 12,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "4112",
    propertyType: "Multifamily",
    totalCosts: "2078425",
    valueOfReclassifiedAssets: 1788431,
    depreciableAssets: 15,
  },
  {
    lat: 42.5586572,
    lng: -83.18097759999999,
    squareFootage: "496330",
    propertyType: "Office",
    totalCosts: "35413710",
    valueOfReclassifiedAssets: 5925956,
    depreciableAssets: 20,
  },
  {
    lat: 40.7135097,
    lng: -73.9859414,
    squareFootage: "7425",
    propertyType: "Multifamily",
    totalCosts: "6252106",
    valueOfReclassifiedAssets: 5640881,
    depreciableAssets: 10,
  },
  {
    lat: 39.0026879,
    lng: -76.9354979,
    squareFootage: "281120",
    propertyType: "Multifamily",
    totalCosts: "63085080",
    valueOfReclassifiedAssets: 15689446,
    depreciableAssets: 29,
  },
  {
    lat: 35.21566809999999,
    lng: -81.2408689,
    squareFootage: "61814",
    propertyType: "Multifamily",
    totalCosts: "4850000",
    valueOfReclassifiedAssets: 1394575,
    depreciableAssets: 33,
  },
  {
    lat: 28.5417016,
    lng: -81.2408689,
    squareFootage: "13789",
    propertyType: "Retail",
    totalCosts: "2950000",
    valueOfReclassifiedAssets: 805226,
    depreciableAssets: 37,
  },
  {
    lat: 42.339904,
    lng: -71.0898892,
    squareFootage: "12145",
    propertyType: "Industrial",
    totalCosts: "12152938",
    valueOfReclassifiedAssets: 1309450,
    depreciableAssets: 17,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "23255",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9010844",
    valueOfReclassifiedAssets: 2456104,
    depreciableAssets: 32,
  },
  {
    lat: 38.8910932,
    lng: -76.94702199999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25027743",
    valueOfReclassifiedAssets: 20365469,
    depreciableAssets: 25,
  },
  {
    lat: 29.702465,
    lng: -95.53548160000001,
    squareFootage: "216721",
    propertyType: "Office",
    totalCosts: "45528455",
    valueOfReclassifiedAssets: 8547376,
    depreciableAssets: 22,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "20092",
    propertyType: "Multifamily",
    totalCosts: "1268000",
    valueOfReclassifiedAssets: 233416,
    depreciableAssets: 22,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "38796",
    propertyType: "Multifamily",
    totalCosts: "4597422",
    valueOfReclassifiedAssets: 468209,
    depreciableAssets: 11,
  },
  {
    lat: 39.7622516,
    lng: -75.11951069999999,
    squareFootage: "64513",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1375719",
    valueOfReclassifiedAssets: 1099362,
    depreciableAssets: 25,
  },
  {
    lat: 26.142147,
    lng: -80.1763467,
    squareFootage: "89950",
    propertyType: "Industrial",
    totalCosts: "11010770",
    valueOfReclassifiedAssets: 2189859,
    depreciableAssets: 25,
  },
  {
    lat: 40.9370006,
    lng: -74.1181234,
    squareFootage: "62867",
    propertyType: "Industrial",
    totalCosts: "6816686",
    valueOfReclassifiedAssets: 292254,
    depreciableAssets: 23,
  },
  {
    lat: 42.5586572,
    lng: -83.18097759999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "160308",
    valueOfReclassifiedAssets: 91775,
    depreciableAssets: 0,
  },
  {
    lat: 40.6815619,
    lng: -73.9785952,
    squareFootage: "6480",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1208688,
    depreciableAssets: 29,
  },
  {
    lat: 39.0841668,
    lng: -86.6006249,
    squareFootage: "41752",
    propertyType: "Multifamily",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 853402,
    depreciableAssets: 15,
  },
  {
    lat: 40.7496608,
    lng: -75.0260667,
    squareFootage: "65000",
    propertyType: "Retail",
    totalCosts: "3894000",
    valueOfReclassifiedAssets: 770424,
    depreciableAssets: 24,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "23900",
    propertyType: "Multifamily",
    totalCosts: "2470000",
    valueOfReclassifiedAssets: 594191,
    depreciableAssets: 29,
  },
  {
    lat: 39.0575219,
    lng: -94.6295394,
    squareFootage: "67275",
    propertyType: "Multifamily",
    totalCosts: "7409949",
    valueOfReclassifiedAssets: 767568,
    depreciableAssets: 12,
  },
  {
    lat: 32.8958284,
    lng: -96.8726295,
    squareFootage: "195972",
    propertyType: "Multifamily",
    totalCosts: "21300000",
    valueOfReclassifiedAssets: 5285734,
    depreciableAssets: 27,
  },
  {
    lat: 32.8296623,
    lng: -96.8511814,
    squareFootage: "419617",
    propertyType: "Multifamily",
    totalCosts: "5880507",
    valueOfReclassifiedAssets: 1353874,
    depreciableAssets: 26,
  },
  {
    lat: 36.025609,
    lng: -78.9853813,
    squareFootage: "337385",
    propertyType: "Multifamily",
    totalCosts: "31958000",
    valueOfReclassifiedAssets: 6550175,
    depreciableAssets: 25,
  },
  {
    lat: 30.36173479999999,
    lng: -81.69663059999999,
    squareFootage: "202630",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 830092,
    depreciableAssets: 18,
  },
  {
    lat: 32.8112934,
    lng: -97.27607599999999,
    squareFootage: "99880",
    propertyType: "Multifamily",
    totalCosts: "8910000",
    valueOfReclassifiedAssets: 2002844,
    depreciableAssets: 26,
  },
  {
    lat: 25.839203,
    lng: -80.2823063,
    squareFootage: "128950",
    propertyType: "Industrial",
    totalCosts: "16612967",
    valueOfReclassifiedAssets: 2885133,
    depreciableAssets: 21,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "21500",
    propertyType: "Multifamily",
    totalCosts: "11700000",
    valueOfReclassifiedAssets: 1552817,
    depreciableAssets: 15,
  },
  {
    lat: 32.7759515,
    lng: -97.4002872,
    squareFootage: "58000",
    propertyType: "Multifamily",
    totalCosts: "952030",
    valueOfReclassifiedAssets: 263874,
    depreciableAssets: 31,
  },
  {
    lat: 28.0482439,
    lng: -81.9916586,
    squareFootage: "113308",
    propertyType: "Industrial",
    totalCosts: "6850000",
    valueOfReclassifiedAssets: 1065575,
    depreciableAssets: 18,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1318256",
    valueOfReclassifiedAssets: 1016362,
    depreciableAssets: 24,
  },
  {
    lat: 40.7496608,
    lng: -75.0260667,
    squareFootage: "16415",
    propertyType: "Retail",
    totalCosts: "6165500",
    valueOfReclassifiedAssets: 1373452,
    depreciableAssets: 28,
  },
  {
    lat: 36.1683449,
    lng: -115.2659777,
    squareFootage: "87709",
    propertyType: "Office",
    totalCosts: "27550000",
    valueOfReclassifiedAssets: 3866611,
    depreciableAssets: 17,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "325900",
    propertyType: "Multifamily",
    totalCosts: "20750000",
    valueOfReclassifiedAssets: 4495937,
    depreciableAssets: 26,
  },
  {
    lat: 28.6777437,
    lng: -81.19679579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1730937",
    valueOfReclassifiedAssets: 1092513,
    depreciableAssets: 0,
  },
  {
    lat: 43.6920701,
    lng: -70.44625789999999,
    squareFootage: "48502",
    propertyType: "Industrial",
    totalCosts: "5063827",
    valueOfReclassifiedAssets: 1018539,
    depreciableAssets: 22,
  },
  {
    lat: 33.9327331,
    lng: -84.0695961,
    squareFootage: "73071",
    propertyType: "Retail",
    totalCosts: "9178644",
    valueOfReclassifiedAssets: 2669368,
    depreciableAssets: 35,
  },
  {
    lat: 27.8219579,
    lng: -82.7401087,
    squareFootage: "63144",
    propertyType: "Multifamily",
    totalCosts: "10725000",
    valueOfReclassifiedAssets: 2290845,
    depreciableAssets: 25,
  },
  {
    lat: 30.3476884,
    lng: -81.7458845,
    squareFootage: "101078",
    propertyType: "Industrial",
    totalCosts: "4700000",
    valueOfReclassifiedAssets: 632042,
    depreciableAssets: 14,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1288462",
    valueOfReclassifiedAssets: 1006818,
    depreciableAssets: 22,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "167600",
    propertyType: "Multifamily",
    totalCosts: "2543149",
    valueOfReclassifiedAssets: 749523,
    depreciableAssets: 33,
  },
  {
    lat: 40.7496608,
    lng: -75.0260667,
    squareFootage: "25000",
    propertyType: "Office",
    totalCosts: "6165500",
    valueOfReclassifiedAssets: 1007278,
    depreciableAssets: 20,
  },
  {
    lat: 36.8643566,
    lng: -75.9985693,
    squareFootage: "75570",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 1980401,
    depreciableAssets: 29,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "381216",
    propertyType: "Industrial",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 2144756,
    depreciableAssets: 14,
  },
  {
    lat: 33.7695269,
    lng: -84.26475219999999,
    squareFootage: "156256",
    propertyType: "Multifamily",
    totalCosts: "12564801",
    valueOfReclassifiedAssets: 2854856,
    depreciableAssets: 30,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1254678",
    valueOfReclassifiedAssets: 939133,
    depreciableAssets: 0,
  },
  {
    lat: 40.8140369,
    lng: -73.9374517,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5007450",
    valueOfReclassifiedAssets: 4245268,
    depreciableAssets: 16,
  },
  {
    lat: 40.4057525,
    lng: -76.6643728,
    squareFootage: "154447",
    propertyType: "Hotel",
    totalCosts: "7950000",
    valueOfReclassifiedAssets: 2796407,
    depreciableAssets: 41,
  },
  {
    lat: 26.2286939,
    lng: -80.1596041,
    squareFootage: "40360",
    propertyType: "Industrial",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1320351,
    depreciableAssets: 29,
  },
  {
    lat: 34.0470832,
    lng: -118.2965121,
    squareFootage: "12850",
    propertyType: "Multifamily",
    totalCosts: "2725000",
    valueOfReclassifiedAssets: 118009,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5250",
    propertyType: "Retail",
    totalCosts: "1843416",
    valueOfReclassifiedAssets: 1750557,
    depreciableAssets: 5,
  },
  {
    lat: 38.8111447,
    lng: -76.6528225,
    squareFootage: "35800",
    propertyType: "Industrial",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 463763,
    depreciableAssets: 16,
  },
  {
    lat: 32.8296623,
    lng: -96.8511814,
    squareFootage: "419617",
    propertyType: "Multifamily",
    totalCosts: "32000000",
    valueOfReclassifiedAssets: 7367385,
    depreciableAssets: 26,
  },
  {
    lat: 40.6238902,
    lng: -75.3936808,
    squareFootage: "61701",
    propertyType: "Multifamily",
    totalCosts: "6512500",
    valueOfReclassifiedAssets: 5344676,
    depreciableAssets: 20,
  },
  {
    lat: 33.5213067,
    lng: -84.3226488,
    squareFootage: "53025",
    propertyType: "Hotel",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 1429465,
    depreciableAssets: 31,
  },
  {
    lat: 30.4580512,
    lng: -87.21703950000001,
    squareFootage: "2019",
    propertyType: "Industrial",
    totalCosts: "8544212",
    valueOfReclassifiedAssets: 1049796,
    depreciableAssets: 14,
  },
  {
    lat: 34.081032,
    lng: -118.2117257,
    squareFootage: "11379",
    propertyType: "Multifamily",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 431120,
    depreciableAssets: 19,
  },
  {
    lat: 34.0390107,
    lng: -118.2672801,
    squareFootage: "60206",
    propertyType: "Office",
    totalCosts: "30583048",
    valueOfReclassifiedAssets: 25377881,
    depreciableAssets: 19,
  },
  {
    lat: 27.6072268,
    lng: -82.5402275,
    squareFootage: "55234",
    propertyType: "Industrial",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1580914,
    depreciableAssets: 26,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1761228",
    valueOfReclassifiedAssets: 1348268,
    depreciableAssets: 29,
  },
  {
    lat: 40.6238902,
    lng: -75.3936808,
    squareFootage: "61701",
    propertyType: "Multifamily",
    totalCosts: "6512500",
    valueOfReclassifiedAssets: 5344676,
    depreciableAssets: 20,
  },
  {
    lat: 34.296676,
    lng: -118.2292712,
    squareFootage: "9608",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 109988,
    depreciableAssets: 19,
  },
  {
    lat: 28.5775287,
    lng: -81.4388858,
    squareFootage: "34370",
    propertyType: "Office",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1360768,
    depreciableAssets: 31,
  },
  {
    lat: 34.0390107,
    lng: -118.2672801,
    squareFootage: "51380",
    propertyType: "Office",
    totalCosts: "5603242",
    valueOfReclassifiedAssets: 963833,
    depreciableAssets: 20,
  },
  {
    lat: 38.2191978,
    lng: -85.6177891,
    squareFootage: "28800",
    propertyType: "Multifamily",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 358951,
    depreciableAssets: 26,
  },
  {
    lat: 34.0745742,
    lng: -117.6962824,
    squareFootage: "46201",
    propertyType: "Retail",
    totalCosts: "3993606",
    valueOfReclassifiedAssets: 3481657,
    depreciableAssets: 28,
  },
  {
    lat: 40.659437,
    lng: -75.3995089,
    squareFootage: "15900",
    propertyType: "Multifamily",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 197500,
    depreciableAssets: 20,
  },
  {
    lat: 40.0618676,
    lng: -75.0465123,
    squareFootage: "10100",
    propertyType: "Retail",
    totalCosts: "8625671",
    valueOfReclassifiedAssets: 2105189,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5337718",
    valueOfReclassifiedAssets: 1561535,
    depreciableAssets: 34,
  },
  {
    lat: 38.8231324,
    lng: -77.0737149,
    squareFootage: "81745",
    propertyType: "Office",
    totalCosts: "18750000",
    valueOfReclassifiedAssets: 1613963,
    depreciableAssets: 16,
  },
  {
    lat: 33.8733037,
    lng: -117.9307584,
    squareFootage: "69853",
    propertyType: "Specialty",
    totalCosts: "33054770",
    valueOfReclassifiedAssets: 26981434,
    depreciableAssets: 31,
  },
  {
    lat: 38.9451658,
    lng: -77.0622028,
    squareFootage: "7740",
    propertyType: "Specialty",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 344823,
    depreciableAssets: 15,
  },
  {
    lat: 35.2091855,
    lng: -89.7989767,
    squareFootage: "48200",
    propertyType: "Retail",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 1786050,
    depreciableAssets: 36,
  },
  {
    lat: 39.1438746,
    lng: -77.1887747,
    squareFootage: "71329",
    propertyType: "Retail",
    totalCosts: "12442491",
    valueOfReclassifiedAssets: 10064052,
    depreciableAssets: 35,
  },
  {
    lat: 35.1376546,
    lng: -89.7661527,
    squareFootage: "53939",
    propertyType: "Retail",
    totalCosts: "5465000",
    valueOfReclassifiedAssets: 1468402,
    depreciableAssets: 32,
  },
  {
    lat: 30.0297551,
    lng: -90.0125178,
    squareFootage: "80364",
    propertyType: "Multifamily",
    totalCosts: "3575000",
    valueOfReclassifiedAssets: 599659,
    depreciableAssets: 20,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "15620",
    propertyType: "Industrial",
    totalCosts: "1700100",
    valueOfReclassifiedAssets: 408238,
    depreciableAssets: 28,
  },
  {
    lat: 28.291625,
    lng: -80.7879407,
    squareFootage: "66637",
    propertyType: "Retail",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1698419,
    depreciableAssets: 31,
  },
  {
    lat: 25.8487631,
    lng: -80.20703280000001,
    squareFootage: "35368",
    propertyType: "Retail",
    totalCosts: "3819336",
    valueOfReclassifiedAssets: 847744,
    depreciableAssets: 36,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "473391",
    propertyType: "Hotel",
    totalCosts: "217500000",
    valueOfReclassifiedAssets: 38827004,
    depreciableAssets: 21,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "99024",
    propertyType: "Office",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 2708050,
    depreciableAssets: 11,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "415993",
    propertyType: "Office",
    totalCosts: "250000000",
    valueOfReclassifiedAssets: 16039287,
    depreciableAssets: 7,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "7200",
    propertyType: "Office",
    totalCosts: "18000000",
    valueOfReclassifiedAssets: 2343254,
    depreciableAssets: 14,
  },
  {
    lat: 42.00925840000001,
    lng: -71.2280234,
    squareFootage: "58752",
    propertyType: "Office",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1016182,
    depreciableAssets: 23,
  },
  {
    lat: 33.5563145,
    lng: -117.77252,
    squareFootage: "9143",
    propertyType: "Specialty",
    totalCosts: "7944000",
    valueOfReclassifiedAssets: 469262,
    depreciableAssets: 15,
  },
  {
    lat: 33.599184,
    lng: -117.8721676,
    squareFootage: "20293",
    propertyType: "Specialty",
    totalCosts: "13640963",
    valueOfReclassifiedAssets: 1094798,
    depreciableAssets: 21,
  },
  {
    lat: 41.1270095,
    lng: -73.3590802,
    squareFootage: "35402",
    propertyType: "Office",
    totalCosts: "17933243",
    valueOfReclassifiedAssets: 3045732,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "17568",
    propertyType: "Retail",
    totalCosts: "5305946",
    valueOfReclassifiedAssets: 4775351,
    depreciableAssets: 100,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13729947",
    valueOfReclassifiedAssets: 12191220,
    depreciableAssets: 11,
  },
  {
    lat: 40.3179397,
    lng: -74.0123851,
    squareFootage: "107657",
    propertyType: "Office",
    totalCosts: "2733300",
    valueOfReclassifiedAssets: 615144,
    depreciableAssets: 26,
  },
  {
    lat: 40.3206949,
    lng: -74.7922202,
    squareFootage: "122163",
    propertyType: "Office",
    totalCosts: "73974534",
    valueOfReclassifiedAssets: 21346438,
    depreciableAssets: 29,
  },
  {
    lat: 40.1457,
    lng: -74.9793243,
    squareFootage: "109250",
    propertyType: "Office",
    totalCosts: "13578287",
    valueOfReclassifiedAssets: 3242838,
    depreciableAssets: 28,
  },
  {
    lat: 40.8085015,
    lng: -73.0346244,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3736226",
    valueOfReclassifiedAssets: 2525370,
    depreciableAssets: 36,
  },
  {
    lat: 21.3433967,
    lng: -157.8390944,
    squareFootage: "36468",
    propertyType: "Multifamily",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 6393265,
    depreciableAssets: 16,
  },
  {
    lat: 21.4513696,
    lng: -157.8450558,
    squareFootage: "35257",
    propertyType: "Multifamily",
    totalCosts: "10352500",
    valueOfReclassifiedAssets: 8949864,
    depreciableAssets: 18,
  },
  {
    lat: 41.0411194,
    lng: -73.541744,
    squareFootage: "438834",
    propertyType: "Multifamily",
    totalCosts: "67000000",
    valueOfReclassifiedAssets: 10025555,
    depreciableAssets: 17,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148575",
    propertyType: "Multifamily",
    totalCosts: "10196726",
    valueOfReclassifiedAssets: 1807932,
    depreciableAssets: 21,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148575",
    propertyType: "Multifamily",
    totalCosts: "3059018",
    valueOfReclassifiedAssets: 542380,
    depreciableAssets: 21,
  },
  {
    lat: 40.09944249999999,
    lng: -74.9325683,
    squareFootage: "1161447",
    propertyType: "Multifamily",
    totalCosts: "151500000",
    valueOfReclassifiedAssets: 40451890,
    depreciableAssets: 31,
  },
  {
    lat: 40.1697469,
    lng: -74.82146929999999,
    squareFootage: "1143424",
    propertyType: "Multifamily",
    totalCosts: "19500000",
    valueOfReclassifiedAssets: 5311636,
    depreciableAssets: 32,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "127360",
    propertyType: "Multifamily",
    totalCosts: "10862907",
    valueOfReclassifiedAssets: 2323898,
    depreciableAssets: 25,
  },
  {
    lat: 39.7961255,
    lng: -75.5393155,
    squareFootage: "376058",
    propertyType: "Multifamily",
    totalCosts: "52400000",
    valueOfReclassifiedAssets: 11618424,
    depreciableAssets: 26,
  },
  {
    lat: 41.5814163,
    lng: -74.9910112,
    squareFootage: "47771",
    propertyType: "Multifamily",
    totalCosts: "6150000",
    valueOfReclassifiedAssets: 1279967,
    depreciableAssets: 25,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148575",
    propertyType: "Multifamily",
    totalCosts: "7137708",
    valueOfReclassifiedAssets: 1265552,
    depreciableAssets: 21,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "127360",
    propertyType: "Multifamily",
    totalCosts: "3258872",
    valueOfReclassifiedAssets: 697169,
    depreciableAssets: 25,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "127360",
    propertyType: "Multifamily",
    totalCosts: "10862907",
    valueOfReclassifiedAssets: 2323898,
    depreciableAssets: 25,
  },
  {
    lat: 41.0411194,
    lng: -73.541744,
    squareFootage: "438834",
    propertyType: "Multifamily",
    totalCosts: "67000000",
    valueOfReclassifiedAssets: 9942506,
    depreciableAssets: 17,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148575",
    propertyType: "Multifamily",
    totalCosts: "10196726",
    valueOfReclassifiedAssets: 1807932,
    depreciableAssets: 21,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "285125",
    propertyType: "Multifamily",
    totalCosts: "56500000",
    valueOfReclassifiedAssets: 12775153,
    depreciableAssets: 27,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "88200",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 1318408,
    depreciableAssets: 25,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "248136",
    propertyType: "Multifamily",
    totalCosts: "12250000",
    valueOfReclassifiedAssets: 3266078,
    depreciableAssets: 31,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148575",
    propertyType: "Multifamily",
    totalCosts: "3059018",
    valueOfReclassifiedAssets: 542380,
    depreciableAssets: 21,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "127360",
    propertyType: "Multifamily",
    totalCosts: "7604035",
    valueOfReclassifiedAssets: 1626729,
    depreciableAssets: 25,
  },
  {
    lat: 39.7878214,
    lng: -74.3352858,
    squareFootage: "344911",
    propertyType: "Multifamily",
    totalCosts: "56100000",
    valueOfReclassifiedAssets: 13720215,
    depreciableAssets: 29,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "127360",
    propertyType: "Multifamily",
    totalCosts: "3258872",
    valueOfReclassifiedAssets: 697169,
    depreciableAssets: 25,
  },
  {
    lat: 39.9358189,
    lng: -74.90918529999999,
    squareFootage: "207648",
    propertyType: "Multifamily",
    totalCosts: "39000000",
    valueOfReclassifiedAssets: 8542259,
    depreciableAssets: 26,
  },
  {
    lat: 38.5890819,
    lng: -121.4084731,
    squareFootage: "87526",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16541500",
    valueOfReclassifiedAssets: 4507044,
    depreciableAssets: 32,
  },
  {
    lat: 34.1199318,
    lng: -84.0378894,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6352372",
    valueOfReclassifiedAssets: 5779275,
    depreciableAssets: 17,
  },
  {
    lat: 34.6228095,
    lng: -86.8421125,
    squareFootage: "143543",
    propertyType: "Retail",
    totalCosts: "13817770",
    valueOfReclassifiedAssets: 12346124,
    depreciableAssets: 28,
  },
  {
    lat: 37.7537708,
    lng: -121.7879244,
    squareFootage: "219613",
    propertyType: "Office",
    totalCosts: "59000000",
    valueOfReclassifiedAssets: 10626572,
    depreciableAssets: 21,
  },
  {
    lat: 36.1165487,
    lng: -115.0881146,
    squareFootage: "127656",
    propertyType: "Multifamily",
    totalCosts: "9980150",
    valueOfReclassifiedAssets: 2636833,
    depreciableAssets: 31,
  },
  {
    lat: 36.3193563,
    lng: -115.0228736,
    squareFootage: "187140",
    propertyType: "Multifamily",
    totalCosts: "17744490",
    valueOfReclassifiedAssets: 4371373,
    depreciableAssets: 29,
  },
  {
    lat: 29.7253361,
    lng: -95.67735119999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "857030",
    valueOfReclassifiedAssets: 237082,
    depreciableAssets: 79,
  },
  {
    lat: 26.7152999,
    lng: -80.1037721,
    squareFootage: "357537",
    propertyType: "Retail",
    totalCosts: "42000000",
    valueOfReclassifiedAssets: 10135233,
    depreciableAssets: 28,
  },
  {
    lat: 40.6046106,
    lng: -73.9982756,
    squareFootage: "134388",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 5502312,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12331873",
    valueOfReclassifiedAssets: 3121495,
    depreciableAssets: 30,
  },
  {
    lat: 39.9726732,
    lng: -104.8104424,
    squareFootage: "100768",
    propertyType: "Multifamily",
    totalCosts: "12175000",
    valueOfReclassifiedAssets: 3081787,
    depreciableAssets: 30,
  },
  {
    lat: 29.9997656,
    lng: -95.1744903,
    squareFootage: "65580",
    propertyType: "Retail",
    totalCosts: "14870000",
    valueOfReclassifiedAssets: 3446099,
    depreciableAssets: 29,
  },
  {
    lat: 40.01616,
    lng: -83.1756188,
    squareFootage: "103600",
    propertyType: "Industrial",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 213761,
    depreciableAssets: 16,
  },
  {
    lat: 40.01616,
    lng: -83.1756188,
    squareFootage: "103600",
    propertyType: "Industrial",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 371171,
    depreciableAssets: 17,
  },
  {
    lat: 39.9721714,
    lng: -82.8694464,
    squareFootage: "112500",
    propertyType: "Office",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 424524,
    depreciableAssets: 25,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "60080",
    propertyType: "Industrial",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 214384,
    depreciableAssets: 18,
  },
  {
    lat: 34.0235914,
    lng: -84.59557319999999,
    squareFootage: "11400",
    propertyType: "Retail",
    totalCosts: "1540737",
    valueOfReclassifiedAssets: 1078060,
    depreciableAssets: 44,
  },
  {
    lat: 29.6790149,
    lng: -95.11194069999999,
    squareFootage: "8429",
    propertyType: "Retail",
    totalCosts: "1023215",
    valueOfReclassifiedAssets: 809828,
    depreciableAssets: 43,
  },
  {
    lat: 32.9618763,
    lng: -96.99609249999999,
    squareFootage: "10959",
    propertyType: "Retail",
    totalCosts: "2847218",
    valueOfReclassifiedAssets: 2237564,
    depreciableAssets: 49,
  },
  {
    lat: 41.1604286,
    lng: -73.99475629999999,
    squareFootage: "33696",
    propertyType: "Office",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 836554,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "97530",
    propertyType: "Multifamily",
    totalCosts: "41000000",
    valueOfReclassifiedAssets: 8129278,
    depreciableAssets: 25,
  },
  {
    lat: 40.9749755,
    lng: -73.69482339999999,
    squareFootage: "97530",
    propertyType: "Multifamily",
    totalCosts: "41000000",
    valueOfReclassifiedAssets: 8637358,
    depreciableAssets: 25,
  },
  {
    lat: 37.7712165,
    lng: -122.2824021,
    squareFootage: "178900",
    propertyType: "Retail",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1213239,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "58680",
    propertyType: "Retail",
    totalCosts: "2970395",
    valueOfReclassifiedAssets: 728483,
    depreciableAssets: 30,
  },
  {
    lat: 37.6413234,
    lng: -95.435853,
    squareFootage: "15447",
    propertyType: "Retail",
    totalCosts: "1535000",
    valueOfReclassifiedAssets: 370614,
    depreciableAssets: 29,
  },
  {
    lat: 39.0096652,
    lng: -94.7846938,
    squareFootage: "13430",
    propertyType: "Retail",
    totalCosts: "3219000",
    valueOfReclassifiedAssets: 928810,
    depreciableAssets: 34,
  },
  {
    lat: 32.6901244,
    lng: -96.937016,
    squareFootage: "202520",
    propertyType: "Multifamily",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 1393682,
    depreciableAssets: 30,
  },
  {
    lat: 36.147526,
    lng: -95.85121,
    squareFootage: "294500",
    propertyType: "Multifamily",
    totalCosts: "12350000",
    valueOfReclassifiedAssets: 3333464,
    depreciableAssets: 32,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "53180",
    propertyType: "Multifamily",
    totalCosts: "1905255",
    valueOfReclassifiedAssets: 594607,
    depreciableAssets: 37,
  },
  {
    lat: 34.5166906,
    lng: -92.97755579999999,
    squareFootage: "69948",
    propertyType: "Multifamily",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 690110,
    depreciableAssets: 38,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "48072",
    propertyType: "Multifamily",
    totalCosts: "1311868",
    valueOfReclassifiedAssets: 295769,
    depreciableAssets: 26,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "53180",
    propertyType: "Multifamily",
    totalCosts: "1830841",
    valueOfReclassifiedAssets: 552300,
    depreciableAssets: 36,
  },
  {
    lat: 29.9905486,
    lng: -95.4044268,
    squareFootage: "229628",
    propertyType: "Multifamily",
    totalCosts: "13320000",
    valueOfReclassifiedAssets: 3690765,
    depreciableAssets: 32,
  },
  {
    lat: 35.5094529,
    lng: -97.64396110000001,
    squareFootage: "141920",
    propertyType: "Multifamily",
    totalCosts: "4029810",
    valueOfReclassifiedAssets: 941669,
    depreciableAssets: 28,
  },
  {
    lat: 32.265915,
    lng: -86.2260131,
    squareFootage: "184240",
    propertyType: "Multifamily",
    totalCosts: "3550000",
    valueOfReclassifiedAssets: 618281,
    depreciableAssets: 21,
  },
  {
    lat: 40.1287659,
    lng: -85.7461548,
    squareFootage: "120792",
    propertyType: "Multifamily",
    totalCosts: "2230000",
    valueOfReclassifiedAssets: 592963,
    depreciableAssets: 32,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "261450",
    propertyType: "Multifamily",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 958008,
    depreciableAssets: 28,
  },
  {
    lat: 40.0441439,
    lng: -82.9609096,
    squareFootage: "71000",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 469720,
    depreciableAssets: 28,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "78435",
    propertyType: "Multifamily",
    totalCosts: "3178750",
    valueOfReclassifiedAssets: 792933,
    depreciableAssets: 30,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "45403",
    propertyType: "Multifamily",
    totalCosts: "1825750",
    valueOfReclassifiedAssets: 402287,
    depreciableAssets: 26,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "178756",
    propertyType: "Multifamily",
    totalCosts: "3864706",
    valueOfReclassifiedAssets: 1006193,
    depreciableAssets: 31,
  },
  {
    lat: 31.1168297,
    lng: -97.725376,
    squareFootage: "179100",
    propertyType: "Multifamily",
    totalCosts: "9542000",
    valueOfReclassifiedAssets: 2620194,
    depreciableAssets: 32,
  },
  {
    lat: 27.9263478,
    lng: -82.5131717,
    squareFootage: "274704",
    propertyType: "Multifamily",
    totalCosts: "7470000",
    valueOfReclassifiedAssets: 1596351,
    depreciableAssets: 25,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3124250",
    valueOfReclassifiedAssets: 683807,
    depreciableAssets: 26,
  },
  {
    lat: 32.7030883,
    lng: -96.80294889999999,
    squareFootage: "361851",
    propertyType: "Multifamily",
    totalCosts: "15050000",
    valueOfReclassifiedAssets: 3654630,
    depreciableAssets: 28,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "42412",
    propertyType: "Multifamily",
    totalCosts: "2127250",
    valueOfReclassifiedAssets: 481843,
    depreciableAssets: 27,
  },
  {
    lat: 36.1964847,
    lng: -95.8459341,
    squareFootage: "139314",
    propertyType: "Multifamily",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 897239,
    depreciableAssets: 27,
  },
  {
    lat: 35.3922378,
    lng: -97.4273195,
    squareFootage: "78000",
    propertyType: "Multifamily",
    totalCosts: "1051063",
    valueOfReclassifiedAssets: 287549,
    depreciableAssets: 33,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2164000",
    valueOfReclassifiedAssets: 634901,
    depreciableAssets: 34,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "160334",
    propertyType: "Multifamily",
    totalCosts: "6850000",
    valueOfReclassifiedAssets: 1778201,
    depreciableAssets: 30,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "74146",
    propertyType: "Multifamily",
    totalCosts: "1367037",
    valueOfReclassifiedAssets: 243136,
    depreciableAssets: 21,
  },
  {
    lat: 35.2258075,
    lng: -89.9159785,
    squareFootage: "306248",
    propertyType: "Multifamily",
    totalCosts: "4680890",
    valueOfReclassifiedAssets: 1237956,
    depreciableAssets: 31,
  },
  {
    lat: 39.5781244,
    lng: -74.5697617,
    squareFootage: "25229",
    propertyType: "Retail",
    totalCosts: "2187385",
    valueOfReclassifiedAssets: 1873006,
    depreciableAssets: 32,
  },
  {
    lat: 33.620038,
    lng: -84.5327038,
    squareFootage: "97310",
    propertyType: "Multifamily",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 1136005,
    depreciableAssets: 29,
  },
  {
    lat: 33.5213067,
    lng: -84.3226488,
    squareFootage: "347725",
    propertyType: "Multifamily",
    totalCosts: "16614640",
    valueOfReclassifiedAssets: 3856055,
    depreciableAssets: 29,
  },
  {
    lat: 33.7234034,
    lng: -84.47501369999999,
    squareFootage: "108218",
    propertyType: "Multifamily",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 1199818,
    depreciableAssets: 28,
  },
  {
    lat: 33.7344959,
    lng: -84.1118458,
    squareFootage: "283045",
    propertyType: "Multifamily",
    totalCosts: "12320000",
    valueOfReclassifiedAssets: 3104156,
    depreciableAssets: 31,
  },
  {
    lat: 33.7234034,
    lng: -84.47501369999999,
    squareFootage: "222778",
    propertyType: "Multifamily",
    totalCosts: "8703550",
    valueOfReclassifiedAssets: 1715450,
    depreciableAssets: 25,
  },
  {
    lat: 41.3055951,
    lng: -81.614468,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12526103",
    valueOfReclassifiedAssets: 10081403,
    depreciableAssets: 27,
  },
  {
    lat: 32.3822577,
    lng: -86.1914604,
    squareFootage: "168280",
    propertyType: "Multifamily",
    totalCosts: "2686614",
    valueOfReclassifiedAssets: 662138,
    depreciableAssets: 29,
  },
  {
    lat: 35.7972575,
    lng: -86.438974,
    squareFootage: "3383",
    propertyType: "Specialty",
    totalCosts: "1744040",
    valueOfReclassifiedAssets: 326493,
    depreciableAssets: 46,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "55025",
    propertyType: "Retail",
    totalCosts: "6147495",
    valueOfReclassifiedAssets: 1073503,
    depreciableAssets: 23,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "13905",
    propertyType: "Retail",
    totalCosts: "4313910",
    valueOfReclassifiedAssets: 772382,
    depreciableAssets: 37,
  },
  {
    lat: 40.2618819,
    lng: -75.7255192,
    squareFootage: "13023",
    propertyType: "Retail",
    totalCosts: "4897804",
    valueOfReclassifiedAssets: 713332,
    depreciableAssets: 19,
  },
  {
    lat: 40.95117210000001,
    lng: -76.0217831,
    squareFootage: "11988",
    propertyType: "Retail",
    totalCosts: "1140389",
    valueOfReclassifiedAssets: 167292,
    depreciableAssets: 20,
  },
  {
    lat: 29.5857075,
    lng: -98.4129396,
    squareFootage: "12996",
    propertyType: "Retail",
    totalCosts: "4164258",
    valueOfReclassifiedAssets: 2427554,
    depreciableAssets: 100,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "53673",
    propertyType: "Retail",
    totalCosts: "4736308",
    valueOfReclassifiedAssets: 516216,
    depreciableAssets: 24,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "82580",
    propertyType: "Industrial",
    totalCosts: "5107716",
    valueOfReclassifiedAssets: 820680,
    depreciableAssets: 21,
  },
  {
    lat: 33.9963181,
    lng: -84.0061661,
    squareFootage: "4358",
    propertyType: "Industrial",
    totalCosts: "1174421",
    valueOfReclassifiedAssets: 383493,
    depreciableAssets: 39,
  },
  {
    lat: 41.8345051,
    lng: -87.8205864,
    squareFootage: "29925",
    propertyType: "Retail",
    totalCosts: "4322964",
    valueOfReclassifiedAssets: 728467,
    depreciableAssets: 19,
  },
  {
    lat: 27.5059582,
    lng: -81.3949264,
    squareFootage: "11175",
    propertyType: "Retail",
    totalCosts: "2399719",
    valueOfReclassifiedAssets: 441587,
    depreciableAssets: 28,
  },
  {
    lat: 36.2153701,
    lng: -114.9872821,
    squareFootage: "17250",
    propertyType: "Retail",
    totalCosts: "3246054",
    valueOfReclassifiedAssets: 466055,
    depreciableAssets: 22,
  },
  {
    lat: 29.2784215,
    lng: -94.8365094,
    squareFootage: "7500",
    propertyType: "Office",
    totalCosts: "2610022",
    valueOfReclassifiedAssets: 638649,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "7225",
    propertyType: "Retail",
    totalCosts: "4177859",
    valueOfReclassifiedAssets: 801583,
    depreciableAssets: 29,
  },
  {
    lat: 42.026064,
    lng: -87.7271123,
    squareFootage: "74500",
    propertyType: "Retail",
    totalCosts: "12781566",
    valueOfReclassifiedAssets: 1546656,
    depreciableAssets: 15,
  },
  {
    lat: 33.5636878,
    lng: -112.0595603,
    squareFootage: "3361",
    propertyType: "Specialty",
    totalCosts: "2043631",
    valueOfReclassifiedAssets: 587883,
    depreciableAssets: 49,
  },
  {
    lat: 29.7431508,
    lng: -95.38871999999999,
    squareFootage: "10908",
    propertyType: "Retail",
    totalCosts: "2930431",
    valueOfReclassifiedAssets: 380450,
    depreciableAssets: 35,
  },
  {
    lat: 32.7863301,
    lng: -96.79625279999999,
    squareFootage: "17250",
    propertyType: "Retail",
    totalCosts: "3246054",
    valueOfReclassifiedAssets: 466055,
    depreciableAssets: 22,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "37365",
    propertyType: "Specialty",
    totalCosts: "11755641",
    valueOfReclassifiedAssets: 2518051,
    depreciableAssets: 26,
  },
  {
    lat: 35.6544684,
    lng: -78.5887951,
    squareFootage: "10200",
    propertyType: "Retail",
    totalCosts: "2822321",
    valueOfReclassifiedAssets: 579606,
    depreciableAssets: 35,
  },
  {
    lat: 35.4172882,
    lng: -80.8765205,
    squareFootage: "10172",
    propertyType: "Retail",
    totalCosts: "1745694",
    valueOfReclassifiedAssets: 1736667,
    depreciableAssets: 23,
  },
  {
    lat: 33.6587324,
    lng: -112.3456985,
    squareFootage: "4086",
    propertyType: "Specialty",
    totalCosts: "2019181",
    valueOfReclassifiedAssets: 575509,
    depreciableAssets: 50,
  },
  {
    lat: 35.8409242,
    lng: -78.62285039999999,
    squareFootage: "3984",
    propertyType: "Retail",
    totalCosts: "2148257",
    valueOfReclassifiedAssets: 601950,
    depreciableAssets: 35,
  },
  {
    lat: 37.5334766,
    lng: -77.6879696,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "3940406",
    valueOfReclassifiedAssets: 1096087,
    depreciableAssets: 37,
  },
  {
    lat: 40.773694,
    lng: -74.0270745,
    squareFootage: "25412",
    propertyType: "Retail",
    totalCosts: "5675525",
    valueOfReclassifiedAssets: 207289,
    depreciableAssets: 13,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "3354216",
    valueOfReclassifiedAssets: 543445,
    depreciableAssets: 24,
  },
  {
    lat: 40.1313817,
    lng: -75.1370254,
    squareFootage: "2904",
    propertyType: "Specialty",
    totalCosts: "2987718",
    valueOfReclassifiedAssets: 996350,
    depreciableAssets: 42,
  },
  {
    lat: 43.0347064,
    lng: -76.1261969,
    squareFootage: "36423",
    propertyType: "Retail",
    totalCosts: "2441342",
    valueOfReclassifiedAssets: 665732,
    depreciableAssets: 33,
  },
  {
    lat: 28.1095304,
    lng: -80.5827272,
    squareFootage: "15688",
    propertyType: "Retail",
    totalCosts: "5339902",
    valueOfReclassifiedAssets: 1067035,
    depreciableAssets: 34,
  },
  {
    lat: 35.6544684,
    lng: -78.5887951,
    squareFootage: "10128",
    propertyType: "Retail",
    totalCosts: "1852346",
    valueOfReclassifiedAssets: 1844486,
    depreciableAssets: 0,
  },
  {
    lat: 33.5528023,
    lng: -84.4015075,
    squareFootage: "10722",
    propertyType: "Retail",
    totalCosts: "2679104",
    valueOfReclassifiedAssets: 526980,
    depreciableAssets: 28,
  },
  {
    lat: 27.966866,
    lng: -80.7879407,
    squareFootage: "45100",
    propertyType: "Specialty",
    totalCosts: "10638966",
    valueOfReclassifiedAssets: 2692230,
    depreciableAssets: 32,
  },
  {
    lat: 38.605823,
    lng: -87.5198011,
    squareFootage: "6005",
    propertyType: "Industrial",
    totalCosts: "1659000",
    valueOfReclassifiedAssets: 320958,
    depreciableAssets: 43,
  },
  {
    lat: 40.7383636,
    lng: -74.197388,
    squareFootage: "10740",
    propertyType: "Retail",
    totalCosts: "3927380",
    valueOfReclassifiedAssets: 812419,
    depreciableAssets: 27,
  },
  {
    lat: 30.2745944,
    lng: -81.4114142,
    squareFootage: "14408",
    propertyType: "Retail",
    totalCosts: "3634051",
    valueOfReclassifiedAssets: 375987,
    depreciableAssets: 34,
  },
  {
    lat: 38.605823,
    lng: -87.5198011,
    squareFootage: "6005",
    propertyType: "Industrial",
    totalCosts: "1693650",
    valueOfReclassifiedAssets: 327677,
    depreciableAssets: 43,
  },
  {
    lat: 42.9674726,
    lng: -78.7986181,
    squareFootage: "8165",
    propertyType: "Specialty",
    totalCosts: "2275129",
    valueOfReclassifiedAssets: 343359,
    depreciableAssets: 32,
  },
  {
    lat: 27.0600849,
    lng: -82.2528975,
    squareFootage: "4930",
    propertyType: "Specialty",
    totalCosts: "2123429",
    valueOfReclassifiedAssets: 365032,
    depreciableAssets: 46,
  },
  {
    lat: 41.8383772,
    lng: -88.3530747,
    squareFootage: "42500",
    propertyType: "Retail",
    totalCosts: "4833670",
    valueOfReclassifiedAssets: 281064,
    depreciableAssets: 22,
  },
  {
    lat: 35.96321330000001,
    lng: -86.5299673,
    squareFootage: "4184",
    propertyType: "Specialty",
    totalCosts: "2418055",
    valueOfReclassifiedAssets: 384028,
    depreciableAssets: 44,
  },
  {
    lat: 41.6931543,
    lng: -87.7123355,
    squareFootage: "15414",
    propertyType: "Retail",
    totalCosts: "1389231",
    valueOfReclassifiedAssets: 279935,
    depreciableAssets: 26,
  },
  {
    lat: 41.6931543,
    lng: -87.7123355,
    squareFootage: "15414",
    propertyType: "Retail",
    totalCosts: "4499817",
    valueOfReclassifiedAssets: 906730,
    depreciableAssets: 26,
  },
  {
    lat: 35.7936065,
    lng: -86.317392,
    squareFootage: "3383",
    propertyType: "Specialty",
    totalCosts: "1688334",
    valueOfReclassifiedAssets: 434075,
    depreciableAssets: 48,
  },
  {
    lat: 37.3084425,
    lng: -95.26844369999999,
    squareFootage: "7833",
    propertyType: "Specialty",
    totalCosts: "3924498",
    valueOfReclassifiedAssets: 684725,
    depreciableAssets: 36,
  },
  {
    lat: 35.0285822,
    lng: -78.9627624,
    squareFootage: "46553",
    propertyType: "Retail",
    totalCosts: "5264797",
    valueOfReclassifiedAssets: 840902,
    depreciableAssets: 25,
  },
  {
    lat: 29.7995924,
    lng: -95.4201377,
    squareFootage: "340540",
    propertyType: "Multifamily",
    totalCosts: "27100000",
    valueOfReclassifiedAssets: 7536936,
    depreciableAssets: 33,
  },
  {
    lat: 41.0322939,
    lng: -73.7683784,
    squareFootage: "906698",
    propertyType: "Office",
    totalCosts: "147000000",
    valueOfReclassifiedAssets: 16056355,
    depreciableAssets: 13,
  },
  {
    lat: 42.3612206,
    lng: -82.98778659999999,
    squareFootage: "594105",
    propertyType: "Multifamily",
    totalCosts: "37750000",
    valueOfReclassifiedAssets: 4849757,
    depreciableAssets: 15,
  },
  {
    lat: 40.1110194,
    lng: -75.2887369,
    squareFootage: "52898",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4319005",
    valueOfReclassifiedAssets: 3821054,
    depreciableAssets: 17,
  },
  {
    lat: 39.9311729,
    lng: -75.0260667,
    squareFootage: "63721",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14370000",
    valueOfReclassifiedAssets: 2744259,
    depreciableAssets: 21,
  },
  {
    lat: 40.56829,
    lng: -74.41738769999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "688125",
    valueOfReclassifiedAssets: 281345,
    depreciableAssets: 61,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "4091",
    propertyType: "Multifamily",
    totalCosts: "6982500",
    valueOfReclassifiedAssets: 1151083,
    depreciableAssets: 20,
  },
  {
    lat: 34.8030992,
    lng: -92.24827049999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5601089",
    valueOfReclassifiedAssets: 5021525,
    depreciableAssets: 20,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "36758",
    propertyType: "Multifamily",
    totalCosts: "5470332",
    valueOfReclassifiedAssets: 646434,
    depreciableAssets: 13,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "36758",
    propertyType: "Multifamily",
    totalCosts: "4325880",
    valueOfReclassifiedAssets: 511501,
    depreciableAssets: 13,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "25980",
    propertyType: "Multifamily",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 792229,
    depreciableAssets: 15,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "27725",
    propertyType: "Multifamily",
    totalCosts: "1475000",
    valueOfReclassifiedAssets: 1263942,
    depreciableAssets: 15,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "23835",
    propertyType: "Multifamily",
    totalCosts: "2444240",
    valueOfReclassifiedAssets: 2073002,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "157182",
    valueOfReclassifiedAssets: 53787,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "694984",
    valueOfReclassifiedAssets: 536063,
    depreciableAssets: 29,
  },
  {
    lat: 38.3674615,
    lng: -90.6488505,
    squareFootage: "42960",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4524650",
    valueOfReclassifiedAssets: 1081441,
    depreciableAssets: 28,
  },
  {
    lat: 38.7102377,
    lng: -90.3042778,
    squareFootage: "40199",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4909973",
    valueOfReclassifiedAssets: 836035,
    depreciableAssets: 29,
  },
  {
    lat: 38.8405221,
    lng: -90.46910969999999,
    squareFootage: "208",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17334431",
    valueOfReclassifiedAssets: 6012540,
    depreciableAssets: 23,
  },
  {
    lat: 37.3902956,
    lng: -121.8961047,
    squareFootage: "188303",
    propertyType: "Office",
    totalCosts: "18900000",
    valueOfReclassifiedAssets: 4333806,
    depreciableAssets: 27,
  },
  {
    lat: 37.0279431,
    lng: -76.3116235,
    squareFootage: "301400",
    propertyType: "Multifamily",
    totalCosts: "8850000",
    valueOfReclassifiedAssets: 1504988,
    depreciableAssets: 20,
  },
  {
    lat: 37.0279431,
    lng: -76.3116235,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1830891",
    valueOfReclassifiedAssets: 893908,
    depreciableAssets: 66,
  },
  {
    lat: 39.5835785,
    lng: -104.8571368,
    squareFootage: "143958",
    propertyType: "Office",
    totalCosts: "13121894",
    valueOfReclassifiedAssets: 2253534,
    depreciableAssets: 24,
  },
  {
    lat: 37.3902956,
    lng: -121.8961047,
    squareFootage: "188303",
    propertyType: "Office",
    totalCosts: "19437070",
    valueOfReclassifiedAssets: 4456957,
    depreciableAssets: 27,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "15850",
    propertyType: "Office",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 6548940,
    depreciableAssets: 13,
  },
  {
    lat: 42.36606159999999,
    lng: -71.0482911,
    squareFootage: "26034",
    propertyType: "Office",
    totalCosts: "9459113",
    valueOfReclassifiedAssets: 780761,
    depreciableAssets: 11,
  },
  {
    lat: 37.0279431,
    lng: -76.3116235,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "588239",
    valueOfReclassifiedAssets: 287200,
    depreciableAssets: 66,
  },
  {
    lat: 42.3652917,
    lng: -71.0646337,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3287500",
    valueOfReclassifiedAssets: 1946395,
    depreciableAssets: 70,
  },
  {
    lat: 42.4000656,
    lng: -71.0319478,
    squareFootage: "24276",
    propertyType: "Multifamily",
    totalCosts: "4950000",
    valueOfReclassifiedAssets: 814409,
    depreciableAssets: 20,
  },
  {
    lat: 40.6560436,
    lng: -74.0079781,
    squareFootage: "15900",
    propertyType: "Industrial",
    totalCosts: "2278264",
    valueOfReclassifiedAssets: 262157,
    depreciableAssets: 13,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "58292",
    propertyType: "Office",
    totalCosts: "4116347",
    valueOfReclassifiedAssets: 623395,
    depreciableAssets: 17,
  },
  {
    lat: 28.37512,
    lng: -80.7380716,
    squareFootage: "82524",
    propertyType: "Retail",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1342398,
    depreciableAssets: 31,
  },
  {
    lat: 28.1543081,
    lng: -80.6437906,
    squareFootage: "45523",
    propertyType: "Retail",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1637800,
    depreciableAssets: 37,
  },
  {
    lat: 28.1988979,
    lng: -80.7879407,
    squareFootage: "80000",
    propertyType: "Office",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 1870170,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "38419",
    propertyType: "Retail",
    totalCosts: "3303720",
    valueOfReclassifiedAssets: 2762103,
    depreciableAssets: 33,
  },
  {
    lat: 28.2274477,
    lng: -81.97529949999999,
    squareFootage: "80625",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1804297,
    depreciableAssets: 34,
  },
  {
    lat: 27.3980151,
    lng: -80.37143259999999,
    squareFootage: "103500",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1335106,
    depreciableAssets: 35,
  },
  {
    lat: 40.2284948,
    lng: -75.30623260000002,
    squareFootage: "14820",
    propertyType: "Retail",
    totalCosts: "6524307",
    valueOfReclassifiedAssets: 1254621,
    depreciableAssets: 23,
  },
  {
    lat: 42.9561485,
    lng: -71.4418101,
    squareFootage: "63598",
    propertyType: "Multifamily",
    totalCosts: "4485000",
    valueOfReclassifiedAssets: 733936,
    depreciableAssets: 19,
  },
  {
    lat: 42.9996568,
    lng: -70.9784562,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9365000",
    valueOfReclassifiedAssets: 7736226,
    depreciableAssets: 23,
  },
  {
    lat: 26.1351973,
    lng: -80.1177335,
    squareFootage: "5315",
    propertyType: "Retail",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 253446,
    depreciableAssets: 18,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "20175",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3755559",
    valueOfReclassifiedAssets: 2675723,
    depreciableAssets: 33,
  },
  {
    lat: 34.16423960000001,
    lng: -118.0830047,
    squareFootage: "30550",
    propertyType: "Retail",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1539984,
    depreciableAssets: 32,
  },
  {
    lat: 40.0508979,
    lng: -74.1357407,
    squareFootage: "7500",
    propertyType: "Retail",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 234401,
    depreciableAssets: 19,
  },
  {
    lat: 40.0151729,
    lng: -74.0593872,
    squareFootage: "3325",
    propertyType: "Retail",
    totalCosts: "781625",
    valueOfReclassifiedAssets: 112840,
    depreciableAssets: 17,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5836777",
    valueOfReclassifiedAssets: 4427213,
    depreciableAssets: 0,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1078146",
    valueOfReclassifiedAssets: 469225,
    depreciableAssets: 0,
  },
  {
    lat: 41.7246539,
    lng: -87.5494745,
    squareFootage: "12200",
    propertyType: "Retail",
    totalCosts: "1265000",
    valueOfReclassifiedAssets: 160154,
    depreciableAssets: 15,
  },
  {
    lat: 41.7458012,
    lng: -87.60876379999999,
    squareFootage: "7889",
    propertyType: "Retail",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 333612,
    depreciableAssets: 27,
  },
  {
    lat: 39.9518394,
    lng: -82.7886395,
    squareFootage: "9920",
    propertyType: "Retail",
    totalCosts: "815000",
    valueOfReclassifiedAssets: 236229,
    depreciableAssets: 34,
  },
  {
    lat: 39.1822152,
    lng: -84.42251859999999,
    squareFootage: "9408",
    propertyType: "Retail",
    totalCosts: "1320000",
    valueOfReclassifiedAssets: 345701,
    depreciableAssets: 31,
  },
  {
    lat: 39.7912709,
    lng: -84.3226488,
    squareFootage: "10722",
    propertyType: "Retail",
    totalCosts: "744129",
    valueOfReclassifiedAssets: 632195,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "1200",
    propertyType: "Retail",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 360053,
    depreciableAssets: 50,
  },
  {
    lat: 26.4023755,
    lng: -80.2209773,
    squareFootage: "60008",
    propertyType: "Retail",
    totalCosts: "6399133",
    valueOfReclassifiedAssets: 1209017,
    depreciableAssets: 22,
  },
  {
    lat: 26.4023755,
    lng: -80.2209773,
    squareFootage: "60008",
    propertyType: "Retail",
    totalCosts: "1443934",
    valueOfReclassifiedAssets: 272809,
    depreciableAssets: 22,
  },
  {
    lat: 41.870115,
    lng: -88.1535352,
    squareFootage: "35225",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7550000",
    valueOfReclassifiedAssets: 1673557,
    depreciableAssets: 26,
  },
  {
    lat: 40.0552842,
    lng: -88.2509857,
    squareFootage: "76620",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15762000",
    valueOfReclassifiedAssets: 6264272,
    depreciableAssets: 25,
  },
  {
    lat: 26.3767589,
    lng: -80.1037721,
    squareFootage: "3435",
    propertyType: "Specialty",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1718462,
    depreciableAssets: 40,
  },
  {
    lat: 36.05826630000001,
    lng: -95.7932008,
    squareFootage: "27000",
    propertyType: "Retail",
    totalCosts: "9778000",
    valueOfReclassifiedAssets: 2207407,
    depreciableAssets: 26,
  },
  {
    lat: 26.5588224,
    lng: -81.8716058,
    squareFootage: "6210",
    propertyType: "Specialty",
    totalCosts: "4200500",
    valueOfReclassifiedAssets: 1308423,
    depreciableAssets: 35,
  },
  {
    lat: 36.0564654,
    lng: -80.33245140000001,
    squareFootage: "55000",
    propertyType: "Retail",
    totalCosts: "7627737",
    valueOfReclassifiedAssets: 2161773,
    depreciableAssets: 33,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "6922",
    propertyType: "Retail",
    totalCosts: "2772700",
    valueOfReclassifiedAssets: 575251,
    depreciableAssets: 24,
  },
  {
    lat: 30.4805441,
    lng: -84.21734029999999,
    squareFootage: "7508",
    propertyType: "Specialty",
    totalCosts: "4427295",
    valueOfReclassifiedAssets: 982510,
    depreciableAssets: 26,
  },
  {
    lat: 34.8920374,
    lng: -82.269193,
    squareFootage: "12996",
    propertyType: "Office",
    totalCosts: "4199500",
    valueOfReclassifiedAssets: 3412943,
    depreciableAssets: 37,
  },
  {
    lat: 28.6659654,
    lng: -81.3729372,
    squareFootage: "2948",
    propertyType: "Specialty",
    totalCosts: "5600000",
    valueOfReclassifiedAssets: 2210345,
    depreciableAssets: 46,
  },
  {
    lat: 35.9722081,
    lng: -79.04755589999999,
    squareFootage: "27350",
    propertyType: "Office",
    totalCosts: "12767574",
    valueOfReclassifiedAssets: 2342025,
    depreciableAssets: 22,
  },
  {
    lat: 36.0564654,
    lng: -80.33245140000001,
    squareFootage: "55000",
    propertyType: "Retail",
    totalCosts: "7627737",
    valueOfReclassifiedAssets: 2161773,
    depreciableAssets: 33,
  },
  {
    lat: 27.4081935,
    lng: -82.52940650000001,
    squareFootage: "6675",
    propertyType: "Specialty",
    totalCosts: "4669057",
    valueOfReclassifiedAssets: 1036786,
    depreciableAssets: 26,
  },
  {
    lat: 43.1067452,
    lng: -88.49849909999999,
    squareFootage: "8153",
    propertyType: "Industrial",
    totalCosts: "3510500",
    valueOfReclassifiedAssets: 764685,
    depreciableAssets: 26,
  },
  {
    lat: 33.8676889,
    lng: -84.4645184,
    squareFootage: "7429",
    propertyType: "Office",
    totalCosts: "7065000",
    valueOfReclassifiedAssets: 1599279,
    depreciableAssets: 27,
  },
  {
    lat: 35.9722081,
    lng: -79.04755589999999,
    squareFootage: "14981",
    propertyType: "Retail",
    totalCosts: "8876313",
    valueOfReclassifiedAssets: 2070025,
    depreciableAssets: 27,
  },
  {
    lat: 33.9873389,
    lng: -81.0368211,
    squareFootage: "15050",
    propertyType: "Retail",
    totalCosts: "5983500",
    valueOfReclassifiedAssets: 949764,
    depreciableAssets: 18,
  },
  {
    lat: 38.2718515,
    lng: -85.6177891,
    squareFootage: "4300",
    propertyType: "Specialty",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1625539,
    depreciableAssets: 45,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "30338106",
    valueOfReclassifiedAssets: 22214370,
    depreciableAssets: 0,
  },
  {
    lat: 38.8845491,
    lng: -76.88939069999999,
    squareFootage: "196978",
    propertyType: "Multifamily",
    totalCosts: "18600000",
    valueOfReclassifiedAssets: 4268182,
    depreciableAssets: 27,
  },
  {
    lat: 39.0019157,
    lng: -76.8836261,
    squareFootage: "671368",
    propertyType: "Multifamily",
    totalCosts: "82250000",
    valueOfReclassifiedAssets: 20343268,
    depreciableAssets: 30,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "45000",
    propertyType: "Multifamily",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 243197,
    depreciableAssets: 17,
  },
  {
    lat: 38.9321713,
    lng: -76.94702199999999,
    squareFootage: "75750",
    propertyType: "Multifamily",
    totalCosts: "7128979",
    valueOfReclassifiedAssets: 1512110,
    depreciableAssets: 25,
  },
  {
    lat: 39.0554966,
    lng: -76.8144298,
    squareFootage: "1223199",
    propertyType: "Multifamily",
    totalCosts: "136000000",
    valueOfReclassifiedAssets: 32633107,
    depreciableAssets: 28,
  },
  {
    lat: 38.9963501,
    lng: -76.97006689999999,
    squareFootage: "206725",
    propertyType: "Multifamily",
    totalCosts: "15143500",
    valueOfReclassifiedAssets: 1897750,
    depreciableAssets: 14,
  },
  {
    lat: 39.0554966,
    lng: -76.8144298,
    squareFootage: "225449",
    propertyType: "Multifamily",
    totalCosts: "29750000",
    valueOfReclassifiedAssets: 5971940,
    depreciableAssets: 23,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "370815",
    propertyType: "Multifamily",
    totalCosts: "33000000",
    valueOfReclassifiedAssets: 4009384,
    depreciableAssets: 13,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "446570",
    propertyType: "Multifamily",
    totalCosts: "49350000",
    valueOfReclassifiedAssets: 12084160,
    depreciableAssets: 29,
  },
  {
    lat: 26.237305,
    lng: -80.11494139999999,
    squareFootage: "60834",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 1252892,
    depreciableAssets: 32,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "10920",
    propertyType: "Industrial",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 151019,
    depreciableAssets: 12,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "24164",
    propertyType: "Industrial",
    totalCosts: "4075000",
    valueOfReclassifiedAssets: 841753,
    depreciableAssets: 24,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "116812",
    propertyType: "Industrial",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 2125241,
    depreciableAssets: 37,
  },
  {
    lat: 29.4981168,
    lng: -98.5226706,
    squareFootage: "183312",
    propertyType: "Multifamily",
    totalCosts: "18200000",
    valueOfReclassifiedAssets: 4493762,
    depreciableAssets: 33,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "17378",
    propertyType: "Office",
    totalCosts: "2352183",
    valueOfReclassifiedAssets: 2182398,
    depreciableAssets: 7,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "7605",
    propertyType: "Multifamily",
    totalCosts: "3251815",
    valueOfReclassifiedAssets: 2447122,
    depreciableAssets: 27,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "4095",
    propertyType: "Multifamily",
    totalCosts: "1682069",
    valueOfReclassifiedAssets: 1315408,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "780000",
    valueOfReclassifiedAssets: 429000,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11435000",
    valueOfReclassifiedAssets: 1195619,
    depreciableAssets: 12,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "34435000",
    valueOfReclassifiedAssets: 4583267,
    depreciableAssets: 16,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "63012",
    propertyType: "Multifamily",
    totalCosts: "9996288",
    valueOfReclassifiedAssets: 1141003,
    depreciableAssets: 13,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "44130",
    propertyType: "Multifamily",
    totalCosts: "7108471",
    valueOfReclassifiedAssets: 857903,
    depreciableAssets: 14,
  },
  {
    lat: 40.8874882,
    lng: -73.977126,
    squareFootage: "40627",
    propertyType: "Office",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 6204002,
    depreciableAssets: 14,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "45850",
    propertyType: "Multifamily",
    totalCosts: "8145123",
    valueOfReclassifiedAssets: 991591,
    depreciableAssets: 14,
  },
  {
    lat: 40.7388319,
    lng: -73.98153370000001,
    squareFootage: "81500",
    propertyType: "Multifamily",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 2949569,
    depreciableAssets: 12,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13350000",
    valueOfReclassifiedAssets: 1956812,
    depreciableAssets: 17,
  },
  {
    lat: 26.3343894,
    lng: -80.2671381,
    squareFootage: "89043",
    propertyType: "Office",
    totalCosts: "20200000",
    valueOfReclassifiedAssets: 4661167,
    depreciableAssets: 27,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "33392",
    propertyType: "Office",
    totalCosts: "2560000",
    valueOfReclassifiedAssets: 272016,
    depreciableAssets: 13,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "447072",
    propertyType: "Office",
    totalCosts: "16400000",
    valueOfReclassifiedAssets: 3795640,
    depreciableAssets: 28,
  },
  {
    lat: 40.9196897,
    lng: -73.7889689,
    squareFootage: "17715",
    propertyType: "Industrial",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 570373,
    depreciableAssets: 26,
  },
  {
    lat: 32.7891591,
    lng: -96.82170169999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2552788",
    valueOfReclassifiedAssets: 2294725,
    depreciableAssets: 22,
  },
  {
    lat: 32.7891591,
    lng: -96.82170169999999,
    squareFootage: "15600",
    propertyType: "Industrial",
    totalCosts: "2066557",
    valueOfReclassifiedAssets: 157137,
    depreciableAssets: 12,
  },
  {
    lat: 40.8786991,
    lng: -73.4062342,
    squareFootage: "12368",
    propertyType: "Office",
    totalCosts: "2502315",
    valueOfReclassifiedAssets: 679079,
    depreciableAssets: 29,
  },
  {
    lat: 41.4896627,
    lng: -73.9712488,
    squareFootage: "42900",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6400000",
    valueOfReclassifiedAssets: 1878902,
    depreciableAssets: 34,
  },
  {
    lat: 41.2916113,
    lng: -73.91834639999999,
    squareFootage: "21883",
    propertyType: "Office",
    totalCosts: "1126121",
    valueOfReclassifiedAssets: 155611,
    depreciableAssets: 16,
  },
  {
    lat: 41.56254149999999,
    lng: -87.663045,
    squareFootage: "191365",
    propertyType: "Retail",
    totalCosts: "32300064",
    valueOfReclassifiedAssets: 6472494,
    depreciableAssets: 23,
  },
  {
    lat: 40.7218318,
    lng: -74.0447003,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2079893",
    valueOfReclassifiedAssets: 1603154,
    depreciableAssets: 24,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "85526",
    propertyType: "Retail",
    totalCosts: "7318483",
    valueOfReclassifiedAssets: 1196716,
    depreciableAssets: 32,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "13722",
    propertyType: "Retail",
    totalCosts: "4724183",
    valueOfReclassifiedAssets: 1134438,
    depreciableAssets: 30,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "6804",
    propertyType: "Specialty",
    totalCosts: "986855",
    valueOfReclassifiedAssets: 277902,
    depreciableAssets: 31,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "251106",
    propertyType: "Retail",
    totalCosts: "30508228",
    valueOfReclassifiedAssets: 3455393,
    depreciableAssets: 17,
  },
  {
    lat: 45.4053517,
    lng: -122.9907521,
    squareFootage: "264464",
    propertyType: "Retail",
    totalCosts: "40458554",
    valueOfReclassifiedAssets: 9555302,
    depreciableAssets: 29,
  },
  {
    lat: 45.4053517,
    lng: -122.9907521,
    squareFootage: "264464",
    propertyType: "Retail",
    totalCosts: "40458554",
    valueOfReclassifiedAssets: 9555302,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "59644",
    propertyType: "Retail",
    totalCosts: "867309",
    valueOfReclassifiedAssets: 266317,
    depreciableAssets: 40,
  },
  {
    lat: 32.8673097,
    lng: -97.0821162,
    squareFootage: "208483",
    propertyType: "Retail",
    totalCosts: "21953372",
    valueOfReclassifiedAssets: 21107834,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "69853",
    propertyType: "Specialty",
    totalCosts: "47115074",
    valueOfReclassifiedAssets: 10339595,
    depreciableAssets: 31,
  },
  {
    lat: 33.8020217,
    lng: -118.1678543,
    squareFootage: "59644",
    propertyType: "Retail",
    totalCosts: "34731116",
    valueOfReclassifiedAssets: 10663751,
    depreciableAssets: 40,
  },
  {
    lat: 39.1438746,
    lng: -77.1887747,
    squareFootage: "71329",
    propertyType: "Retail",
    totalCosts: "15017680",
    valueOfReclassifiedAssets: 4311806,
    depreciableAssets: 35,
  },
  {
    lat: 33.8020217,
    lng: -118.1678543,
    squareFootage: "59644",
    propertyType: "Retail",
    totalCosts: "35598425",
    valueOfReclassifiedAssets: 10930067,
    depreciableAssets: 40,
  },
  {
    lat: 39.1438746,
    lng: -77.1887747,
    squareFootage: "71329",
    propertyType: "Retail",
    totalCosts: "15017680",
    valueOfReclassifiedAssets: 4311806,
    depreciableAssets: 35,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "405987",
    propertyType: "Retail",
    totalCosts: "67684810",
    valueOfReclassifiedAssets: 16058317,
    depreciableAssets: 32,
  },
  {
    lat: 40.4004712,
    lng: -79.9024779,
    squareFootage: "8359",
    propertyType: "Specialty",
    totalCosts: "4010414",
    valueOfReclassifiedAssets: 1242642,
    depreciableAssets: 49,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1420000",
    valueOfReclassifiedAssets: 402153,
    depreciableAssets: 32,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "347538",
    propertyType: "Multifamily",
    totalCosts: "3207447",
    valueOfReclassifiedAssets: 586401,
    depreciableAssets: 21,
  },
  {
    lat: 41.046167,
    lng: -81.7568253,
    squareFootage: "85666",
    propertyType: "Specialty",
    totalCosts: "5565000",
    valueOfReclassifiedAssets: 1503129,
    depreciableAssets: 28,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "347538",
    propertyType: "Multifamily",
    totalCosts: "13898939",
    valueOfReclassifiedAssets: 2541071,
    depreciableAssets: 21,
  },
  {
    lat: 41.4292232,
    lng: -81.61529259999999,
    squareFootage: "4020",
    propertyType: "Retail",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 320452,
    depreciableAssets: 21,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29286712",
    valueOfReclassifiedAssets: 25819263,
    depreciableAssets: 13,
  },
  {
    lat: 33.755711,
    lng: -84.3883717,
    squareFootage: "123370",
    propertyType: "Office",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1258586,
    depreciableAssets: 15,
  },
  {
    lat: 36.1433174,
    lng: -95.9911897,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "55743883",
    valueOfReclassifiedAssets: 49051449,
    depreciableAssets: 13,
  },
  {
    lat: 40.6311567,
    lng: -74.1386767,
    squareFootage: "115030",
    propertyType: "Multifamily",
    totalCosts: "11252272",
    valueOfReclassifiedAssets: 2102993,
    depreciableAssets: 22,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "84073",
    propertyType: "Hotel",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2808604,
    depreciableAssets: 30,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "44295",
    propertyType: "Multifamily",
    totalCosts: "7100000",
    valueOfReclassifiedAssets: 555962,
    depreciableAssets: 10,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "54000",
    propertyType: "Multifamily",
    totalCosts: "6708000",
    valueOfReclassifiedAssets: 6130509,
    depreciableAssets: 10,
  },
  {
    lat: 41.3844409,
    lng: -72.86336349999999,
    squareFootage: "196645",
    propertyType: "Retail",
    totalCosts: "32596686",
    valueOfReclassifiedAssets: 30020406,
    depreciableAssets: 20,
  },
  {
    lat: 41.4334745,
    lng: -74.4760088,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1192935",
    valueOfReclassifiedAssets: 899300,
    depreciableAssets: 27,
  },
  {
    lat: 41.89136999999999,
    lng: -87.94328370000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "981690",
    valueOfReclassifiedAssets: 198953,
    depreciableAssets: 80,
  },
  {
    lat: 41.0529585,
    lng: -73.53879909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "738641",
    valueOfReclassifiedAssets: 694416,
    depreciableAssets: 12,
  },
  {
    lat: 43.0481645,
    lng: -76.1473156,
    squareFootage: "303825",
    propertyType: "Office",
    totalCosts: "2493736",
    valueOfReclassifiedAssets: 2195898,
    depreciableAssets: 13,
  },
  {
    lat: 41.4811257,
    lng: -90.1869644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4236694",
    valueOfReclassifiedAssets: 3201776,
    depreciableAssets: 29,
  },
  {
    lat: 42.1292854,
    lng: -75.841775,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6987985",
    valueOfReclassifiedAssets: 5895099,
    depreciableAssets: 49,
  },
  {
    lat: 41.3576886,
    lng: -77.5160788,
    squareFootage: "139600",
    propertyType: "Multifamily",
    totalCosts: "7162611",
    valueOfReclassifiedAssets: 1054627,
    depreciableAssets: 18,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "192280",
    propertyType: "Multifamily",
    totalCosts: "2409127",
    valueOfReclassifiedAssets: 1829997,
    depreciableAssets: 32,
  },
  {
    lat: 41.0529585,
    lng: -73.53879909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1260336",
    valueOfReclassifiedAssets: 596079,
    depreciableAssets: 53,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "1359100",
    propertyType: "Office",
    totalCosts: "481093581",
    valueOfReclassifiedAssets: 7533680,
    depreciableAssets: 1,
  },
  {
    lat: 39.407567,
    lng: -76.6354952,
    squareFootage: "37774",
    propertyType: "Office",
    totalCosts: "1890000",
    valueOfReclassifiedAssets: 175469,
    depreciableAssets: 14,
  },
  {
    lat: 41.6648036,
    lng: -87.79108910000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1346888",
    valueOfReclassifiedAssets: 536932,
    depreciableAssets: 65,
  },
  {
    lat: 36.6795393,
    lng: -82.2365981,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7206534",
    valueOfReclassifiedAssets: 5944362,
    depreciableAssets: 25,
  },
  {
    lat: 40.7758541,
    lng: -74.16509909999999,
    squareFootage: "51328",
    propertyType: "Multifamily",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 870512,
    depreciableAssets: 19,
  },
  {
    lat: 40.6046106,
    lng: -73.9982756,
    squareFootage: "64900",
    propertyType: "Multifamily",
    totalCosts: "11800000",
    valueOfReclassifiedAssets: 1388228,
    depreciableAssets: 14,
  },
  {
    lat: 40.04524420000001,
    lng: -75.63826569999999,
    squareFootage: "152123",
    propertyType: "Retail",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 3997732,
    depreciableAssets: 20,
  },
  {
    lat: 42.1805732,
    lng: -85.57150150000001,
    squareFootage: "69666",
    propertyType: "Industrial",
    totalCosts: "4450000",
    valueOfReclassifiedAssets: 4115200,
    depreciableAssets: 100,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "192280",
    propertyType: "Multifamily",
    totalCosts: "1395889",
    valueOfReclassifiedAssets: 1060332,
    depreciableAssets: 32,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "347538",
    propertyType: "Multifamily",
    totalCosts: "17106386",
    valueOfReclassifiedAssets: 3127472,
    depreciableAssets: 21,
  },
  {
    lat: 42.6742723,
    lng: -83.2398943,
    squareFootage: "42480",
    propertyType: "Industrial",
    totalCosts: "3400000",
    valueOfReclassifiedAssets: 2959880,
    depreciableAssets: 100,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "192280",
    propertyType: "Multifamily",
    totalCosts: "349596",
    valueOfReclassifiedAssets: 265556,
    depreciableAssets: 32,
  },
  {
    lat: 36.6795393,
    lng: -82.2365981,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18962455",
    valueOfReclassifiedAssets: 15641317,
    depreciableAssets: 25,
  },
  {
    lat: 42.0301291,
    lng: -87.68276759999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "867866",
    valueOfReclassifiedAssets: 228909,
    depreciableAssets: 0,
  },
  {
    lat: 40.7245083,
    lng: -73.4298073,
    squareFootage: "16000",
    propertyType: "Industrial",
    totalCosts: "1925000",
    valueOfReclassifiedAssets: 361973,
    depreciableAssets: 24,
  },
  {
    lat: 42.4424515,
    lng: -87.84515270000001,
    squareFootage: "85238",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13676000",
    valueOfReclassifiedAssets: 2803021,
    depreciableAssets: 20,
  },
  {
    lat: 33.9742906,
    lng: -118.3520389,
    squareFootage: "41500",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1545352,
    depreciableAssets: 35,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "39000",
    propertyType: "Multifamily",
    totalCosts: "4605300",
    valueOfReclassifiedAssets: 4198256,
    depreciableAssets: 10,
  },
  {
    lat: 33.5106684,
    lng: -112.029752,
    squareFootage: "71544",
    propertyType: "Multifamily",
    totalCosts: "6104700",
    valueOfReclassifiedAssets: 5599705,
    depreciableAssets: 9,
  },
  {
    lat: 40.7793504,
    lng: -74.511172,
    squareFootage: "114626",
    propertyType: "Office",
    totalCosts: "34755000",
    valueOfReclassifiedAssets: 7823321,
    depreciableAssets: 26,
  },
  {
    lat: 40.7793504,
    lng: -74.511172,
    squareFootage: "114626",
    propertyType: "Office",
    totalCosts: "34755000",
    valueOfReclassifiedAssets: 7823322,
    depreciableAssets: 26,
  },
  {
    lat: 33.6134652,
    lng: -84.38574419999999,
    squareFootage: "28977",
    propertyType: "Multifamily",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1577797,
    depreciableAssets: 42,
  },
  {
    lat: 37.701103,
    lng: -97.27607599999999,
    squareFootage: "31707",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 335525,
    depreciableAssets: 38,
  },
  {
    lat: 39.0442537,
    lng: -95.6405374,
    squareFootage: "22877",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 723393,
    depreciableAssets: 36,
  },
  {
    lat: 38.6603432,
    lng: -96.8779926,
    squareFootage: "36940",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 473400,
    depreciableAssets: 25,
  },
  {
    lat: 38.8619254,
    lng: -97.654811,
    squareFootage: "17903",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 765077,
    depreciableAssets: 36,
  },
  {
    lat: 36.7017377,
    lng: -95.8775964,
    squareFootage: "45592",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1216564,
    depreciableAssets: 34,
  },
  {
    lat: 36.7017377,
    lng: -95.8775964,
    squareFootage: "44000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1295967,
    depreciableAssets: 27,
  },
  {
    lat: 32.3185311,
    lng: -96.62106109999999,
    squareFootage: "36742",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1806955,
    depreciableAssets: 40,
  },
  {
    lat: 35.6108066,
    lng: -82.4102628,
    squareFootage: "36286",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4550000",
    valueOfReclassifiedAssets: 1307135,
    depreciableAssets: 34,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "33095",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6230000",
    valueOfReclassifiedAssets: 1674347,
    depreciableAssets: 31,
  },
  {
    lat: 36.7017377,
    lng: -95.8775964,
    squareFootage: "45592",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4340000",
    valueOfReclassifiedAssets: 1246641,
    depreciableAssets: 34,
  },
  {
    lat: 35.669371,
    lng: -95.26844369999999,
    squareFootage: "37572",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4990000",
    valueOfReclassifiedAssets: 1405780,
    depreciableAssets: 33,
  },
  {
    lat: 34.9346833,
    lng: -95.7710158,
    squareFootage: "30658",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3830000",
    valueOfReclassifiedAssets: 1113215,
    depreciableAssets: 34,
  },
  {
    lat: 29.1987957,
    lng: -81.04234290000001,
    squareFootage: "59994",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 451387,
    depreciableAssets: 15,
  },
  {
    lat: 32.3855324,
    lng: -86.2514126,
    squareFootage: "28788",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1316158,
    depreciableAssets: 31,
  },
  {
    lat: 41.6881608,
    lng: -72.7865518,
    squareFootage: "57600",
    propertyType: "Multifamily",
    totalCosts: "4526721",
    valueOfReclassifiedAssets: 955014,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "59994",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 536085,
    depreciableAssets: 18,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "59994",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 581107,
    depreciableAssets: 20,
  },
  {
    lat: 35.7810086,
    lng: -95.2057909,
    squareFootage: "31626",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1460000",
    valueOfReclassifiedAssets: 398122,
    depreciableAssets: 32,
  },
  {
    lat: 41.743111,
    lng: -72.70380469999999,
    squareFootage: "43956",
    propertyType: "Multifamily",
    totalCosts: "3510618",
    valueOfReclassifiedAssets: 639158,
    depreciableAssets: 23,
  },
  {
    lat: 33.6301328,
    lng: -117.8721676,
    squareFootage: "15498",
    propertyType: "Office",
    totalCosts: "3874500",
    valueOfReclassifiedAssets: 2905875,
    depreciableAssets: 100,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "46846",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 574746,
    depreciableAssets: 12,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "56584",
    propertyType: "Multifamily",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 1435063,
    depreciableAssets: 16,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "47680",
    propertyType: "Multifamily",
    totalCosts: "14162500",
    valueOfReclassifiedAssets: 1979694,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "54375",
    propertyType: "Multifamily",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 1594169,
    depreciableAssets: 12,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "47680",
    propertyType: "Multifamily",
    totalCosts: "14162500",
    valueOfReclassifiedAssets: 1979694,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "54375",
    propertyType: "Multifamily",
    totalCosts: "15337515",
    valueOfReclassifiedAssets: 1636369,
    depreciableAssets: 12,
  },
  {
    lat: 40.7659246,
    lng: -73.86836970000002,
    squareFootage: "56584",
    propertyType: "Multifamily",
    totalCosts: "8879691",
    valueOfReclassifiedAssets: 7624763,
    depreciableAssets: 16,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "202418",
    propertyType: "Multifamily",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 2316089,
    depreciableAssets: 11,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "51520",
    propertyType: "Multifamily",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 877304,
    depreciableAssets: 14,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "43250",
    propertyType: "Multifamily",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 644349,
    depreciableAssets: 0,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "63108",
    propertyType: "Multifamily",
    totalCosts: "7518072",
    valueOfReclassifiedAssets: 669114,
    depreciableAssets: 0,
  },
  {
    lat: 28.064362,
    lng: -81.5815776,
    squareFootage: "118848",
    propertyType: "Retail",
    totalCosts: "7615000",
    valueOfReclassifiedAssets: 1291385,
    depreciableAssets: 20,
  },
  {
    lat: 26.6530817,
    lng: -80.1316927,
    squareFootage: "129826",
    propertyType: "Retail",
    totalCosts: "14400000",
    valueOfReclassifiedAssets: 4426267,
    depreciableAssets: 35,
  },
  {
    lat: 41.0853864,
    lng: -73.6771662,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10721000",
    valueOfReclassifiedAssets: 8598469,
    depreciableAssets: 30,
  },
  {
    lat: 25.7667897,
    lng: -80.20703280000001,
    squareFootage: "13715",
    propertyType: "Multifamily",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 514251,
    depreciableAssets: 22,
  },
  {
    lat: 25.7667897,
    lng: -80.20703280000001,
    squareFootage: "26180",
    propertyType: "Multifamily",
    totalCosts: "3970000",
    valueOfReclassifiedAssets: 639866,
    depreciableAssets: 18,
  },
  {
    lat: 25.7686714,
    lng: -80.2349196,
    squareFootage: "16200",
    propertyType: "Multifamily",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 463795,
    depreciableAssets: 20,
  },
  {
    lat: 25.7686714,
    lng: -80.2349196,
    squareFootage: "13612",
    propertyType: "Multifamily",
    totalCosts: "2012500",
    valueOfReclassifiedAssets: 334946,
    depreciableAssets: 19,
  },
  {
    lat: 40.8476284,
    lng: -73.8360251,
    squareFootage: "112000",
    propertyType: "Multifamily",
    totalCosts: "24800000",
    valueOfReclassifiedAssets: 2472695,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "10000",
    propertyType: "Office",
    totalCosts: "1865000",
    valueOfReclassifiedAssets: 308903,
    depreciableAssets: 18,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "47539",
    propertyType: "Multifamily",
    totalCosts: "7066667",
    valueOfReclassifiedAssets: 863850,
    depreciableAssets: 14,
  },
  {
    lat: 33.620038,
    lng: -84.5327038,
    squareFootage: "262432",
    propertyType: "Multifamily",
    totalCosts: "11532000",
    valueOfReclassifiedAssets: 3072131,
    depreciableAssets: 30,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "194096",
    propertyType: "Multifamily",
    totalCosts: "25600000",
    valueOfReclassifiedAssets: 1718462,
    depreciableAssets: 7,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "61664",
    propertyType: "Multifamily",
    totalCosts: "13320000",
    valueOfReclassifiedAssets: 1802022,
    depreciableAssets: 15,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "7867",
    propertyType: "Multifamily",
    totalCosts: "1385000",
    valueOfReclassifiedAssets: 294702,
    depreciableAssets: 24,
  },
  {
    lat: 25.7686714,
    lng: -80.2349196,
    squareFootage: "5546",
    propertyType: "Multifamily",
    totalCosts: "750000",
    valueOfReclassifiedAssets: 174919,
    depreciableAssets: 27,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11562",
    propertyType: "Specialty",
    totalCosts: "1751734",
    valueOfReclassifiedAssets: 453850,
    depreciableAssets: 30,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "9346",
    propertyType: "Specialty",
    totalCosts: "1282623",
    valueOfReclassifiedAssets: 282634,
    depreciableAssets: 26,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1539540",
    valueOfReclassifiedAssets: 460094,
    depreciableAssets: 34,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "21977",
    propertyType: "Specialty",
    totalCosts: "2445276",
    valueOfReclassifiedAssets: 330877,
    depreciableAssets: 16,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "6142",
    propertyType: "Specialty",
    totalCosts: "1282895",
    valueOfReclassifiedAssets: 370692,
    depreciableAssets: 33,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "13969",
    propertyType: "Specialty",
    totalCosts: "2613699",
    valueOfReclassifiedAssets: 651921,
    depreciableAssets: 29,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11459",
    propertyType: "Specialty",
    totalCosts: "1282739",
    valueOfReclassifiedAssets: 306736,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "10000",
    propertyType: "Office",
    totalCosts: "1865000",
    valueOfReclassifiedAssets: 300323,
    depreciableAssets: 18,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "16151",
    propertyType: "Specialty",
    totalCosts: "2669309",
    valueOfReclassifiedAssets: 496355,
    depreciableAssets: 22,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11544",
    propertyType: "Specialty",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 370436,
    depreciableAssets: 28,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "8958",
    propertyType: "Specialty",
    totalCosts: "1539540",
    valueOfReclassifiedAssets: 268218,
    depreciableAssets: 20,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "171966",
    propertyType: "Multifamily",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 3168463,
    depreciableAssets: 26,
  },
  {
    lat: 39.9021937,
    lng: -74.9968543,
    squareFootage: "897984",
    propertyType: "Multifamily",
    totalCosts: "20476238",
    valueOfReclassifiedAssets: 3393572,
    depreciableAssets: 19,
  },
  {
    lat: 33.7994803,
    lng: -84.1909841,
    squareFootage: "295210",
    propertyType: "Multifamily",
    totalCosts: "13900000",
    valueOfReclassifiedAssets: 4126684,
    depreciableAssets: 33,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "84036",
    propertyType: "Multifamily",
    totalCosts: "15613333",
    valueOfReclassifiedAssets: 3252563,
    depreciableAssets: 23,
  },
  {
    lat: 41.47019890000001,
    lng: -87.32149079999999,
    squareFootage: "24115",
    propertyType: "Multifamily",
    totalCosts: "10600000",
    valueOfReclassifiedAssets: 1383528,
    depreciableAssets: 15,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11634",
    propertyType: "Specialty",
    totalCosts: "1700573",
    valueOfReclassifiedAssets: 340585,
    depreciableAssets: 23,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "10613",
    propertyType: "Specialty",
    totalCosts: "1539540",
    valueOfReclassifiedAssets: 372923,
    depreciableAssets: 28,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11517",
    propertyType: "Specialty",
    totalCosts: "1765637",
    valueOfReclassifiedAssets: 407992,
    depreciableAssets: 27,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "202418",
    propertyType: "Multifamily",
    totalCosts: "38100000",
    valueOfReclassifiedAssets: 5172268,
    depreciableAssets: 15,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "133200",
    propertyType: "Multifamily",
    totalCosts: "18825000",
    valueOfReclassifiedAssets: 2452652,
    depreciableAssets: 14,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "50750",
    propertyType: "Multifamily",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 2723861,
    depreciableAssets: 13,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "7786",
    propertyType: "Specialty",
    totalCosts: "1340000",
    valueOfReclassifiedAssets: 345508,
    depreciableAssets: 29,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "15847",
    propertyType: "Specialty",
    totalCosts: "2307839",
    valueOfReclassifiedAssets: 439101,
    depreciableAssets: 21,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "7360",
    propertyType: "Specialty",
    totalCosts: "1095529",
    valueOfReclassifiedAssets: 248900,
    depreciableAssets: 26,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "14938",
    propertyType: "Specialty",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 438415,
    depreciableAssets: 29,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "12453",
    propertyType: "Specialty",
    totalCosts: "1539540",
    valueOfReclassifiedAssets: 376403,
    depreciableAssets: 28,
  },
  {
    lat: 25.7686714,
    lng: -80.2349196,
    squareFootage: "45802",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 667969,
    depreciableAssets: 17,
  },
  {
    lat: 33.7597692,
    lng: -84.17516479999999,
    squareFootage: "822939",
    propertyType: "Multifamily",
    totalCosts: "49000000",
    valueOfReclassifiedAssets: 13466418,
    depreciableAssets: 31,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "13747",
    propertyType: "Specialty",
    totalCosts: "1340000",
    valueOfReclassifiedAssets: 247352,
    depreciableAssets: 21,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11517",
    propertyType: "Specialty",
    totalCosts: "1765637",
    valueOfReclassifiedAssets: 401820,
    depreciableAssets: 26,
  },
  {
    lat: 25.7686714,
    lng: -80.2349196,
    squareFootage: "9038",
    propertyType: "Multifamily",
    totalCosts: "1960000",
    valueOfReclassifiedAssets: 359587,
    depreciableAssets: 21,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "11088",
    propertyType: "Specialty",
    totalCosts: "1613502",
    valueOfReclassifiedAssets: 292195,
    depreciableAssets: 21,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "6142",
    propertyType: "Specialty",
    totalCosts: "806354",
    valueOfReclassifiedAssets: 168969,
    depreciableAssets: 24,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "12318",
    propertyType: "Specialty",
    totalCosts: "2445276",
    valueOfReclassifiedAssets: 404483,
    depreciableAssets: 19,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "10752",
    propertyType: "Specialty",
    totalCosts: "1473681",
    valueOfReclassifiedAssets: 338363,
    depreciableAssets: 26,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "14002",
    propertyType: "Specialty",
    totalCosts: "1539540",
    valueOfReclassifiedAssets: 362576,
    depreciableAssets: 27,
  },
  {
    lat: 41.9403795,
    lng: -87.65318049999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1001273",
    valueOfReclassifiedAssets: 856630,
    depreciableAssets: 100,
  },
  {
    lat: 41.9403795,
    lng: -87.65318049999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12995916",
    valueOfReclassifiedAssets: 493836,
    depreciableAssets: 5,
  },
  {
    lat: 41.9403795,
    lng: -87.65318049999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25702811",
    valueOfReclassifiedAssets: 21989807,
    depreciableAssets: 100,
  },
  {
    lat: 41.6305694,
    lng: -87.82550069999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3258838",
    valueOfReclassifiedAssets: 2969484,
    depreciableAssets: 0,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "12940095",
    valueOfReclassifiedAssets: 10866900,
    depreciableAssets: 21,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "11761359",
    valueOfReclassifiedAssets: 2133250,
    depreciableAssets: 21,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "34631956",
    valueOfReclassifiedAssets: 6281471,
    depreciableAssets: 21,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "4703753",
    valueOfReclassifiedAssets: 853157,
    depreciableAssets: 21,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "13374409",
    valueOfReclassifiedAssets: 2425823,
    depreciableAssets: 21,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "27196",
    propertyType: "Retail",
    totalCosts: "13373936",
    valueOfReclassifiedAssets: 2425736,
    depreciableAssets: 21,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "168555",
    propertyType: "Multifamily",
    totalCosts: "36359460",
    valueOfReclassifiedAssets: 3645701,
    depreciableAssets: 0,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "168555",
    propertyType: "Multifamily",
    totalCosts: "1992937",
    valueOfReclassifiedAssets: 199828,
    depreciableAssets: 0,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "168555",
    propertyType: "Multifamily",
    totalCosts: "48343500",
    valueOfReclassifiedAssets: 4847321,
    depreciableAssets: 0,
  },
  {
    lat: 34.00637469999999,
    lng: -118.2234229,
    squareFootage: "74140",
    propertyType: "Industrial",
    totalCosts: "2094872",
    valueOfReclassifiedAssets: 1849306,
    depreciableAssets: 15,
  },
  {
    lat: 36.0633189,
    lng: -115.0525302,
    squareFootage: "33750",
    propertyType: "Retail",
    totalCosts: "2916166",
    valueOfReclassifiedAssets: 2546514,
    depreciableAssets: 29,
  },
  {
    lat: 36.135635,
    lng: -115.2304131,
    squareFootage: "42376",
    propertyType: "Retail",
    totalCosts: "3262285",
    valueOfReclassifiedAssets: 564100,
    depreciableAssets: 22,
  },
  {
    lat: 43.01563729999999,
    lng: -89.4318454,
    squareFootage: "66704",
    propertyType: "Retail",
    totalCosts: "8637155",
    valueOfReclassifiedAssets: 2357276,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "61213",
    propertyType: "Retail",
    totalCosts: "8829021",
    valueOfReclassifiedAssets: 1838542,
    depreciableAssets: 24,
  },
  {
    lat: 43.17139479999999,
    lng: -78.6795836,
    squareFootage: "62832",
    propertyType: "Retail",
    totalCosts: "6086266",
    valueOfReclassifiedAssets: 1341726,
    depreciableAssets: 26,
  },
  {
    lat: 35.3301529,
    lng: -80.7325287,
    squareFootage: "129598",
    propertyType: "Retail",
    totalCosts: "28886347",
    valueOfReclassifiedAssets: 6170901,
    depreciableAssets: 25,
  },
  {
    lat: 43.1272254,
    lng: -87.94328370000001,
    squareFootage: "62100",
    propertyType: "Retail",
    totalCosts: "13335180",
    valueOfReclassifiedAssets: 4814644,
    depreciableAssets: 43,
  },
  {
    lat: 40.2074012,
    lng: -75.6906241,
    squareFootage: "215610",
    propertyType: "Retail",
    totalCosts: "29172124",
    valueOfReclassifiedAssets: 4703071,
    depreciableAssets: 19,
  },
  {
    lat: 41.0897282,
    lng: -75.1311874,
    squareFootage: "106616",
    propertyType: "Retail",
    totalCosts: "24029780",
    valueOfReclassifiedAssets: 5071800,
    depreciableAssets: 25,
  },
  {
    lat: 36.7954578,
    lng: -97.286868,
    squareFootage: "36340",
    propertyType: "Hotel",
    totalCosts: "4227699",
    valueOfReclassifiedAssets: 1107944,
    depreciableAssets: 29,
  },
  {
    lat: 28.3031034,
    lng: -82.7023383,
    squareFootage: "91000",
    propertyType: "Retail",
    totalCosts: "9750000",
    valueOfReclassifiedAssets: 2520476,
    depreciableAssets: 31,
  },
  {
    lat: 28.3031034,
    lng: -82.7023383,
    squareFootage: "91000",
    propertyType: "Retail",
    totalCosts: "10125000",
    valueOfReclassifiedAssets: 2617417,
    depreciableAssets: 31,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "689000",
    propertyType: "Industrial",
    totalCosts: "22618415",
    valueOfReclassifiedAssets: 4108621,
    depreciableAssets: 21,
  },
  {
    lat: 35.0771847,
    lng: -114.5599015,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 635568,
    depreciableAssets: 35,
  },
  {
    lat: 39.287866,
    lng: -76.59216649999999,
    squareFootage: "5040",
    propertyType: "Retail",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 443135,
    depreciableAssets: 41,
  },
  {
    lat: 35.3826611,
    lng: -86.4592091,
    squareFootage: "3100",
    propertyType: "Retail",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 517211,
    depreciableAssets: 49,
  },
  {
    lat: 40.8663111,
    lng: -73.6241855,
    squareFootage: "26166",
    propertyType: "Retail",
    totalCosts: "1520000",
    valueOfReclassifiedAssets: 342208,
    depreciableAssets: 27,
  },
  {
    lat: 40.8663111,
    lng: -73.6241855,
    squareFootage: "26166",
    propertyType: "Retail",
    totalCosts: "840000",
    valueOfReclassifiedAssets: 189115,
    depreciableAssets: 27,
  },
  {
    lat: 34.9730414,
    lng: -81.8333656,
    squareFootage: "4468",
    propertyType: "Retail",
    totalCosts: "1975000",
    valueOfReclassifiedAssets: 664836,
    depreciableAssets: 0,
  },
  {
    lat: 39.4787827,
    lng: -75.03775019999999,
    squareFootage: "10779",
    propertyType: "Specialty",
    totalCosts: "4133332",
    valueOfReclassifiedAssets: 786791,
    depreciableAssets: 22,
  },
  {
    lat: 40.9541697,
    lng: -74.0652616,
    squareFootage: "3375",
    propertyType: "Specialty",
    totalCosts: "3501000",
    valueOfReclassifiedAssets: 1410283,
    depreciableAssets: 48,
  },
  {
    lat: 41.1524413,
    lng: -81.8631302,
    squareFootage: "6187",
    propertyType: "Retail",
    totalCosts: "1591500",
    valueOfReclassifiedAssets: 368892,
    depreciableAssets: 27,
  },
  {
    lat: 41.434595,
    lng: -81.7458845,
    squareFootage: "7558",
    propertyType: "Specialty",
    totalCosts: "2350000",
    valueOfReclassifiedAssets: 1997500,
    depreciableAssets: 100,
  },
  {
    lat: 36.2942119,
    lng: -88.28504199999999,
    squareFootage: "2000",
    propertyType: "Retail",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 177658,
    depreciableAssets: 26,
  },
  {
    lat: 39.4787827,
    lng: -75.03775019999999,
    squareFootage: "10779",
    propertyType: "Specialty",
    totalCosts: "2906668",
    valueOfReclassifiedAssets: 553292,
    depreciableAssets: 22,
  },
  {
    lat: 41.7425112,
    lng: -72.147537,
    squareFootage: "3038",
    propertyType: "Specialty",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 830999,
    depreciableAssets: 39,
  },
  {
    lat: 36.5289692,
    lng: -84.56414699999999,
    squareFootage: "2000",
    propertyType: "Retail",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 185882,
    depreciableAssets: 27,
  },
  {
    lat: 42.9980102,
    lng: -88.0460894,
    squareFootage: "24196",
    propertyType: "Retail",
    totalCosts: "2950000",
    valueOfReclassifiedAssets: 672221,
    depreciableAssets: 27,
  },
  {
    lat: 42.0870521,
    lng: -71.4061876,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "3940308",
    valueOfReclassifiedAssets: 605700,
    depreciableAssets: 18,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "9396",
    propertyType: "Multifamily",
    totalCosts: "11725253",
    valueOfReclassifiedAssets: 1517932,
    depreciableAssets: 15,
  },
  {
    lat: 40.7084274,
    lng: -74.0173484,
    squareFootage: "3564",
    propertyType: "Multifamily",
    totalCosts: "4321042",
    valueOfReclassifiedAssets: 702311,
    depreciableAssets: 20,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "3564",
    propertyType: "Multifamily",
    totalCosts: "4321042",
    valueOfReclassifiedAssets: 702311,
    depreciableAssets: 20,
  },
  {
    lat: 39.2963369,
    lng: -76.62105389999999,
    squareFootage: "13929",
    propertyType: "Retail",
    totalCosts: "1284148",
    valueOfReclassifiedAssets: 376867,
    depreciableAssets: 34,
  },
  {
    lat: 41.7185486,
    lng: -74.006509,
    squareFootage: "78256",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2247232",
    valueOfReclassifiedAssets: 480677,
    depreciableAssets: 26,
  },
  {
    lat: 40.7432865,
    lng: -74.2326058,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 171241,
    depreciableAssets: 17,
  },
  {
    lat: 40.7432865,
    lng: -74.2326058,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "850000",
    valueOfReclassifiedAssets: 121295,
    depreciableAssets: 17,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "55782",
    propertyType: "Multifamily",
    totalCosts: "2209200",
    valueOfReclassifiedAssets: 2025313,
    depreciableAssets: 9,
  },
  {
    lat: 40.8153846,
    lng: -73.21165839999999,
    squareFootage: "87945",
    propertyType: "Office",
    totalCosts: "18000000",
    valueOfReclassifiedAssets: 3519523,
    depreciableAssets: 23,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "14400",
    propertyType: "Multifamily",
    totalCosts: "937622",
    valueOfReclassifiedAssets: 825613,
    depreciableAssets: 14,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "30335",
    propertyType: "Multifamily",
    totalCosts: "1522712",
    valueOfReclassifiedAssets: 1368531,
    depreciableAssets: 11,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "19036",
    propertyType: "Multifamily",
    totalCosts: "684800",
    valueOfReclassifiedAssets: 593794,
    depreciableAssets: 15,
  },
  {
    lat: 41.9995834,
    lng: -71.51304449999999,
    squareFootage: "222965",
    propertyType: "Retail",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2071117,
    depreciableAssets: 27,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "43723",
    propertyType: "Multifamily",
    totalCosts: "1832384",
    valueOfReclassifiedAssets: 1692791,
    depreciableAssets: 9,
  },
  {
    lat: 40.8357683,
    lng: -73.28243309999999,
    squareFootage: "55000",
    propertyType: "Industrial",
    totalCosts: "4116290",
    valueOfReclassifiedAssets: 3645367,
    depreciableAssets: 20,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "63390",
    propertyType: "Multifamily",
    totalCosts: "4452884",
    valueOfReclassifiedAssets: 3866944,
    depreciableAssets: 17,
  },
  {
    lat: 40.8153846,
    lng: -73.21165839999999,
    squareFootage: "87945",
    propertyType: "Office",
    totalCosts: "16017708",
    valueOfReclassifiedAssets: 3131927,
    depreciableAssets: 23,
  },
  {
    lat: 40.8153846,
    lng: -73.21165839999999,
    squareFootage: "110407",
    propertyType: "Office",
    totalCosts: "47000000",
    valueOfReclassifiedAssets: 10415178,
    depreciableAssets: 26,
  },
  {
    lat: 40.8153846,
    lng: -73.21165839999999,
    squareFootage: "110407",
    propertyType: "Office",
    totalCosts: "46135000",
    valueOfReclassifiedAssets: 10223495,
    depreciableAssets: 26,
  },
  {
    lat: 32.3730312,
    lng: -90.1261013,
    squareFootage: "162376",
    propertyType: "Retail",
    totalCosts: "11700000",
    valueOfReclassifiedAssets: 2083353,
    depreciableAssets: 21,
  },
  {
    lat: 40.8153846,
    lng: -73.21165839999999,
    squareFootage: "110407",
    propertyType: "Office",
    totalCosts: "45042174",
    valueOfReclassifiedAssets: 9981325,
    depreciableAssets: 26,
  },
  {
    lat: 41.2726012,
    lng: -75.9056753,
    squareFootage: "279691",
    propertyType: "Retail",
    totalCosts: "32337000",
    valueOfReclassifiedAssets: 6451686,
    depreciableAssets: 23,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "42289",
    propertyType: "Retail",
    totalCosts: "82850000",
    valueOfReclassifiedAssets: 9914099,
    depreciableAssets: 15,
  },
  {
    lat: 40.6616787,
    lng: -73.8477874,
    squareFootage: "37000",
    propertyType: "Retail",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 5618608,
    depreciableAssets: 28,
  },
  {
    lat: 39.4452108,
    lng: -76.6528225,
    squareFootage: "56000",
    propertyType: "Industrial",
    totalCosts: "6617280",
    valueOfReclassifiedAssets: 1005960,
    depreciableAssets: 18,
  },
  {
    lat: 33.3897576,
    lng: -111.9343636,
    squareFootage: "182360",
    propertyType: "Multifamily",
    totalCosts: "41810000",
    valueOfReclassifiedAssets: 10721027,
    depreciableAssets: 31,
  },
  {
    lat: 38.647459,
    lng: -90.25730999999999,
    squareFootage: "214356",
    propertyType: "Multifamily",
    totalCosts: "79700000",
    valueOfReclassifiedAssets: 16527904,
    depreciableAssets: 23,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "242974",
    propertyType: "Multifamily",
    totalCosts: "34535000",
    valueOfReclassifiedAssets: 11720276,
    depreciableAssets: 40,
  },
  {
    lat: 26.7153474,
    lng: -80.0533253,
    squareFootage: "293509",
    propertyType: "Multifamily",
    totalCosts: "56104500",
    valueOfReclassifiedAssets: 20625914,
    depreciableAssets: 43,
  },
  {
    lat: 39.66564899999999,
    lng: -104.8863279,
    squareFootage: "336100",
    propertyType: "Multifamily",
    totalCosts: "67896000",
    valueOfReclassifiedAssets: 15651667,
    depreciableAssets: 28,
  },
  {
    lat: 30.5184228,
    lng: -97.70908539999999,
    squareFootage: "716099",
    propertyType: "Multifamily",
    totalCosts: "74000000",
    valueOfReclassifiedAssets: 20710598,
    depreciableAssets: 31,
  },
  {
    lat: 39.1940851,
    lng: -84.4540213,
    squareFootage: "83716",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10140000",
    valueOfReclassifiedAssets: 2554527,
    depreciableAssets: 30,
  },
  {
    lat: 32.7180396,
    lng: -83.6986568,
    squareFootage: "36929",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 1099490,
    depreciableAssets: 34,
  },
  {
    lat: 40.2767073,
    lng: -80.1651854,
    squareFootage: "55906",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8341240",
    valueOfReclassifiedAssets: 2503184,
    depreciableAssets: 32,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "294804",
    propertyType: "Multifamily",
    totalCosts: "28359644",
    valueOfReclassifiedAssets: 5430085,
    depreciableAssets: 23,
  },
  {
    lat: 36.8274757,
    lng: -76.3116235,
    squareFootage: "91535",
    propertyType: "Multifamily",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 1330135,
    depreciableAssets: 23,
  },
  {
    lat: 36.849734,
    lng: -76.097201,
    squareFootage: "418700",
    propertyType: "Multifamily",
    totalCosts: "60750000",
    valueOfReclassifiedAssets: 17650673,
    depreciableAssets: 35,
  },
  {
    lat: 32.8965009,
    lng: -96.5463156,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1516482",
    valueOfReclassifiedAssets: 1486186,
    depreciableAssets: 37,
  },
  {
    lat: 33.1376528,
    lng: -96.85654269999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1741478",
    valueOfReclassifiedAssets: 1448438,
    depreciableAssets: 0,
  },
  {
    lat: 32.9533477,
    lng: -97.1467072,
    squareFootage: "36096",
    propertyType: "Retail",
    totalCosts: "854249",
    valueOfReclassifiedAssets: 226994,
    depreciableAssets: 38,
  },
  {
    lat: 29.8942825,
    lng: -97.9810845,
    squareFootage: "12000",
    propertyType: "Retail",
    totalCosts: "1330302",
    valueOfReclassifiedAssets: 250497,
    depreciableAssets: 29,
  },
  {
    lat: 38.8984667,
    lng: -94.70188999999999,
    squareFootage: "29759",
    propertyType: "Retail",
    totalCosts: "8214562",
    valueOfReclassifiedAssets: 7018247,
    depreciableAssets: 30,
  },
  {
    lat: 30.3889868,
    lng: -97.6710889,
    squareFootage: "323533",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 7049144,
    depreciableAssets: 38,
  },
  {
    lat: 36.1165487,
    lng: -115.0881146,
    squareFootage: "87089",
    propertyType: "Industrial",
    totalCosts: "4428112",
    valueOfReclassifiedAssets: 518687,
    depreciableAssets: 13,
  },
  {
    lat: 40.6208604,
    lng: -74.511172,
    squareFootage: "120528",
    propertyType: "Office",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2037210,
    depreciableAssets: 22,
  },
  {
    lat: 41.054992,
    lng: -74.04763779999999,
    squareFootage: "104854",
    propertyType: "Office",
    totalCosts: "8055833",
    valueOfReclassifiedAssets: 1735448,
    depreciableAssets: 25,
  },
  {
    lat: 40.5971482,
    lng: -74.6283316,
    squareFootage: "199279",
    propertyType: "Office",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1781941,
    depreciableAssets: 20,
  },
  {
    lat: 27.9552692,
    lng: -82.4563199,
    squareFootage: "35102",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 925790,
    depreciableAssets: 33,
  },
  {
    lat: 40.3686632,
    lng: -78.64554749999999,
    squareFootage: "29700",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2340000",
    valueOfReclassifiedAssets: 636673,
    depreciableAssets: 32,
  },
  {
    lat: 40.7623187,
    lng: -74.4232507,
    squareFootage: "30867",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 1682305,
    depreciableAssets: 23,
  },
  {
    lat: 39.0839994,
    lng: -77.1527813,
    squareFootage: "129560",
    propertyType: "Office",
    totalCosts: "3766000",
    valueOfReclassifiedAssets: 489070,
    depreciableAssets: 15,
  },
  {
    lat: 35.72228630000001,
    lng: -86.8822445,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "26759419",
    valueOfReclassifiedAssets: 21937602,
    depreciableAssets: 26,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "56836",
    propertyType: "Multifamily",
    totalCosts: "16575000",
    valueOfReclassifiedAssets: 3620188,
    depreciableAssets: 26,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "101174",
    propertyType: "Multifamily",
    totalCosts: "26500000",
    valueOfReclassifiedAssets: 4810375,
    depreciableAssets: 21,
  },
  {
    lat: 39.42052959999999,
    lng: -76.79135579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "35707570",
    valueOfReclassifiedAssets: 30234757,
    depreciableAssets: 18,
  },
  {
    lat: 28.470872,
    lng: -80.6992633,
    squareFootage: "14099",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5827362",
    valueOfReclassifiedAssets: 2694435,
    depreciableAssets: 51,
  },
  {
    lat: 28.5760375,
    lng: -82.5943072,
    squareFootage: "72160",
    propertyType: "Retail",
    totalCosts: "17250000",
    valueOfReclassifiedAssets: 3253635,
    depreciableAssets: 22,
  },
  {
    lat: 31.8586994,
    lng: -106.5466221,
    squareFootage: "192200",
    propertyType: "Multifamily",
    totalCosts: "15800000",
    valueOfReclassifiedAssets: 4453541,
    depreciableAssets: 33,
  },
  {
    lat: 33.9346756,
    lng: -84.59557319999999,
    squareFootage: "177617",
    propertyType: "Multifamily",
    totalCosts: "17050000",
    valueOfReclassifiedAssets: 3642808,
    depreciableAssets: 25,
  },
  {
    lat: 25.7616792,
    lng: -80.34915989999999,
    squareFootage: "650000",
    propertyType: "Office",
    totalCosts: "67500000",
    valueOfReclassifiedAssets: 13715210,
    depreciableAssets: 23,
  },
  {
    lat: 25.7616792,
    lng: -80.34915989999999,
    squareFootage: "650000",
    propertyType: "Office",
    totalCosts: "57500000",
    valueOfReclassifiedAssets: 11385064,
    depreciableAssets: 23,
  },
  {
    lat: 28.5513952,
    lng: -81.30142839999999,
    squareFootage: "109398",
    propertyType: "Retail",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1351394,
    depreciableAssets: 26,
  },
  {
    lat: 35.7482425,
    lng: -78.5547283,
    squareFootage: "56454",
    propertyType: "Retail",
    totalCosts: "8050000",
    valueOfReclassifiedAssets: 2445050,
    depreciableAssets: 36,
  },
  {
    lat: 34.76242939999999,
    lng: -86.6913222,
    squareFootage: "168212",
    propertyType: "Office",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 3481539,
    depreciableAssets: 25,
  },
  {
    lat: 35.9989632,
    lng: -78.5887951,
    squareFootage: "117013",
    propertyType: "Retail",
    totalCosts: "21676149",
    valueOfReclassifiedAssets: 8268452,
    depreciableAssets: 45,
  },
  {
    lat: 36.1155274,
    lng: -79.7512033,
    squareFootage: "151371",
    propertyType: "Retail",
    totalCosts: "5115326",
    valueOfReclassifiedAssets: 4353981,
    depreciableAssets: 23,
  },
  {
    lat: 30.3658304,
    lng: -81.49379929999999,
    squareFootage: "218646",
    propertyType: "Retail",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1033795,
    depreciableAssets: 25,
  },
  {
    lat: 36.127028,
    lng: -79.8856824,
    squareFootage: "52953",
    propertyType: "Office",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 1159071,
    depreciableAssets: 23,
  },
  {
    lat: 37.5213394,
    lng: -77.57340479999999,
    squareFootage: "103917",
    propertyType: "Retail",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 2039146,
    depreciableAssets: 27,
  },
  {
    lat: 28.4588856,
    lng: -81.3949264,
    squareFootage: "132084",
    propertyType: "Retail",
    totalCosts: "6707244",
    valueOfReclassifiedAssets: 5757196,
    depreciableAssets: 32,
  },
  {
    lat: 36.0373556,
    lng: -79.59405439999999,
    squareFootage: "348141",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 764706,
    depreciableAssets: 18,
  },
  {
    lat: 36.0373556,
    lng: -79.59405439999999,
    squareFootage: "348141",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 764706,
    depreciableAssets: 18,
  },
  {
    lat: 40.5230137,
    lng: -74.3411515,
    squareFootage: "269863",
    propertyType: "Industrial",
    totalCosts: "11320000",
    valueOfReclassifiedAssets: 1176033,
    depreciableAssets: 12,
  },
  {
    lat: 42.5287528,
    lng: -90.7722974,
    squareFootage: "136894",
    propertyType: "Retail",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 2227755,
    depreciableAssets: 23,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "34275",
    propertyType: "Multifamily",
    totalCosts: "1983710",
    valueOfReclassifiedAssets: 343898,
    depreciableAssets: 19,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "43360",
    propertyType: "Multifamily",
    totalCosts: "2949953",
    valueOfReclassifiedAssets: 531701,
    depreciableAssets: 20,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "48890",
    propertyType: "Multifamily",
    totalCosts: "3869574",
    valueOfReclassifiedAssets: 746224,
    depreciableAssets: 21,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "220211",
    valueOfReclassifiedAssets: 120898,
    depreciableAssets: 0,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "249958",
    valueOfReclassifiedAssets: 194125,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "186400",
    valueOfReclassifiedAssets: 115782,
    depreciableAssets: 0,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "610376",
    valueOfReclassifiedAssets: 334414,
    depreciableAssets: 46,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "515893",
    valueOfReclassifiedAssets: 241113,
    depreciableAssets: 0,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "614063",
    valueOfReclassifiedAssets: 386103,
    depreciableAssets: 44,
  },
  {
    lat: 40.8050442,
    lng: -73.8830701,
    squareFootage: "65137",
    propertyType: "Multifamily",
    totalCosts: "13200000",
    valueOfReclassifiedAssets: 1342986,
    depreciableAssets: 12,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "116413",
    propertyType: "Multifamily",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 1745682,
    depreciableAssets: 24,
  },
  {
    lat: 40.29470269999999,
    lng: -73.9888797,
    squareFootage: "234164",
    propertyType: "Multifamily",
    totalCosts: "54650000",
    valueOfReclassifiedAssets: 11288752,
    depreciableAssets: 24,
  },
  {
    lat: 40.699863,
    lng: -74.32941989999999,
    squareFootage: "347700",
    propertyType: "Multifamily",
    totalCosts: "83500000",
    valueOfReclassifiedAssets: 15524976,
    depreciableAssets: 22,
  },
  {
    lat: 40.8625518,
    lng: -74.3440842,
    squareFootage: "626232",
    propertyType: "Multifamily",
    totalCosts: "136329690",
    valueOfReclassifiedAssets: 41860995,
    depreciableAssets: 36,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "148320",
    propertyType: "Multifamily",
    totalCosts: "10900000",
    valueOfReclassifiedAssets: 2115446,
    depreciableAssets: 23,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "86534",
    propertyType: "Multifamily",
    totalCosts: "8520000",
    valueOfReclassifiedAssets: 1932376,
    depreciableAssets: 27,
  },
  {
    lat: 40.2450268,
    lng: -74.7863698,
    squareFootage: "304000",
    propertyType: "Multifamily",
    totalCosts: "28091938",
    valueOfReclassifiedAssets: 4470224,
    depreciableAssets: 19,
  },
  {
    lat: 40.8798669,
    lng: -74.0799469,
    squareFootage: "429530",
    propertyType: "Multifamily",
    totalCosts: "100000000",
    valueOfReclassifiedAssets: 21648092,
    depreciableAssets: 25,
  },
  {
    lat: 38.8327423,
    lng: -77.2060241,
    squareFootage: "377368",
    propertyType: "Multifamily",
    totalCosts: "65335280",
    valueOfReclassifiedAssets: 13911884,
    depreciableAssets: 29,
  },
  {
    lat: 40.744052,
    lng: -74.0270745,
    squareFootage: "114000",
    propertyType: "Multifamily",
    totalCosts: "12947553",
    valueOfReclassifiedAssets: 1950636,
    depreciableAssets: 16,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "13583063",
    valueOfReclassifiedAssets: 1027183,
    depreciableAssets: 8,
  },
  {
    lat: 37.0517234,
    lng: -76.3347838,
    squareFootage: "251350",
    propertyType: "Multifamily",
    totalCosts: "15015762",
    valueOfReclassifiedAssets: 4523012,
    depreciableAssets: 32,
  },
  {
    lat: 39.8781725,
    lng: -75.2362381,
    squareFootage: "65440",
    propertyType: "Multifamily",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 1773044,
    depreciableAssets: 21,
  },
  {
    lat: 30.2737366,
    lng: -97.68194299999999,
    squareFootage: "116572",
    propertyType: "Multifamily",
    totalCosts: "6694347",
    valueOfReclassifiedAssets: 2098667,
    depreciableAssets: 37,
  },
  {
    lat: 41.6466255,
    lng: -87.6285099,
    squareFootage: "274738",
    propertyType: "Multifamily",
    totalCosts: "24653391",
    valueOfReclassifiedAssets: 5481441,
    depreciableAssets: 24,
  },
  {
    lat: 37.293176,
    lng: -79.7960523,
    squareFootage: "78476",
    propertyType: "Multifamily",
    totalCosts: "4120181",
    valueOfReclassifiedAssets: 1089067,
    depreciableAssets: 28,
  },
  {
    lat: 39.4787827,
    lng: -75.03775019999999,
    squareFootage: "99471",
    propertyType: "Multifamily",
    totalCosts: "5008397",
    valueOfReclassifiedAssets: 877323,
    depreciableAssets: 18,
  },
  {
    lat: 29.643475,
    lng: -95.42537569999999,
    squareFootage: "258043",
    propertyType: "Multifamily",
    totalCosts: "2227741",
    valueOfReclassifiedAssets: 587934,
    depreciableAssets: 28,
  },
  {
    lat: 39.9162835,
    lng: -82.8748301,
    squareFootage: "156106",
    propertyType: "Multifamily",
    totalCosts: "7684317",
    valueOfReclassifiedAssets: 1495472,
    depreciableAssets: 20,
  },
  {
    lat: 40.3730698,
    lng: -79.8492812,
    squareFootage: "155860",
    propertyType: "Multifamily",
    totalCosts: "2307938",
    valueOfReclassifiedAssets: 1920327,
    depreciableAssets: 21,
  },
  {
    lat: 39.0345609,
    lng: -94.55212739999999,
    squareFootage: "85806",
    propertyType: "Multifamily",
    totalCosts: "2096802",
    valueOfReclassifiedAssets: 371846,
    depreciableAssets: 19,
  },
  {
    lat: 40.4586216,
    lng: -79.8184684,
    squareFootage: "86990",
    propertyType: "Multifamily",
    totalCosts: "4813607",
    valueOfReclassifiedAssets: 4291115,
    depreciableAssets: 13,
  },
  {
    lat: 40.1733277,
    lng: -105.1142011,
    squareFootage: "100016",
    propertyType: "Multifamily",
    totalCosts: "5075636",
    valueOfReclassifiedAssets: 1507434,
    depreciableAssets: 31,
  },
  {
    lat: 30.4881915,
    lng: -91.1640968,
    squareFootage: "149650",
    propertyType: "Multifamily",
    totalCosts: "9617842",
    valueOfReclassifiedAssets: 3383606,
    depreciableAssets: 37,
  },
  {
    lat: 38.1830462,
    lng: -85.7820416,
    squareFootage: "170619",
    propertyType: "Multifamily",
    totalCosts: "7786445",
    valueOfReclassifiedAssets: 6429528,
    depreciableAssets: 19,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "80700",
    propertyType: "Multifamily",
    totalCosts: "10328419",
    valueOfReclassifiedAssets: 1216168,
    depreciableAssets: 13,
  },
  {
    lat: 39.68016739999999,
    lng: -86.1242881,
    squareFootage: "75390",
    propertyType: "Multifamily",
    totalCosts: "3012938",
    valueOfReclassifiedAssets: 532664,
    depreciableAssets: 19,
  },
  {
    lat: 39.1040382,
    lng: -94.57018029999999,
    squareFootage: "231091",
    propertyType: "Multifamily",
    totalCosts: "7184140",
    valueOfReclassifiedAssets: 1612548,
    depreciableAssets: 23,
  },
  {
    lat: 36.6313237,
    lng: -79.4028639,
    squareFootage: "73946",
    propertyType: "Multifamily",
    totalCosts: "3053195",
    valueOfReclassifiedAssets: 2392287,
    depreciableAssets: 25,
  },
  {
    lat: 41.6882735,
    lng: -71.51898,
    squareFootage: "172566",
    propertyType: "Multifamily",
    totalCosts: "8327670",
    valueOfReclassifiedAssets: 7347924,
    depreciableAssets: 13,
  },
  {
    lat: 45.78888569999999,
    lng: -108.5908853,
    squareFootage: "87816",
    propertyType: "Specialty",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 3376345,
    depreciableAssets: 39,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "13884599",
    valueOfReclassifiedAssets: 1049986,
    depreciableAssets: 8,
  },
  {
    lat: 40.3730698,
    lng: -79.8492812,
    squareFootage: "140400",
    propertyType: "Multifamily",
    totalCosts: "3485595",
    valueOfReclassifiedAssets: 2820880,
    depreciableAssets: 25,
  },
  {
    lat: 41.820267,
    lng: -87.603826,
    squareFootage: "90357",
    propertyType: "Multifamily",
    totalCosts: "5879516",
    valueOfReclassifiedAssets: 851155,
    depreciableAssets: 17,
  },
  {
    lat: 42.4000656,
    lng: -71.0319478,
    squareFootage: "101880",
    propertyType: "Multifamily",
    totalCosts: "9747659",
    valueOfReclassifiedAssets: 1465103,
    depreciableAssets: 17,
  },
  {
    lat: 40.4088514,
    lng: -80.11494139999999,
    squareFootage: "163164",
    propertyType: "Multifamily",
    totalCosts: "6714690",
    valueOfReclassifiedAssets: 948910,
    depreciableAssets: 14,
  },
  {
    lat: 41.8131441,
    lng: -71.4596201,
    squareFootage: "65130",
    propertyType: "Multifamily",
    totalCosts: "4768715",
    valueOfReclassifiedAssets: 1086458,
    depreciableAssets: 24,
  },
  {
    lat: 39.670937,
    lng: -75.7080727,
    squareFootage: "159400",
    propertyType: "Multifamily",
    totalCosts: "11794093",
    valueOfReclassifiedAssets: 2994171,
    depreciableAssets: 26,
  },
  {
    lat: 28.5585152,
    lng: -96.62106109999999,
    squareFootage: "275100",
    propertyType: "Industrial",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 1967530,
    depreciableAssets: 15,
  },
  {
    lat: 38.518893,
    lng: -121.6511258,
    squareFootage: "277270",
    propertyType: "Industrial",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 2423650,
    depreciableAssets: 18,
  },
  {
    lat: 42.9297842,
    lng: -74.1474846,
    squareFootage: "227246",
    propertyType: "Industrial",
    totalCosts: "9491037",
    valueOfReclassifiedAssets: 1409130,
    depreciableAssets: 16,
  },
  {
    lat: 28.5585152,
    lng: -96.62106109999999,
    squareFootage: "275100",
    propertyType: "Industrial",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 1468261,
    depreciableAssets: 13,
  },
  {
    lat: 45.0935798,
    lng: -93.2641955,
    squareFootage: "274587",
    propertyType: "Industrial",
    totalCosts: "16402988",
    valueOfReclassifiedAssets: 1689100,
    depreciableAssets: 15,
  },
  {
    lat: 43.0324123,
    lng: -89.3843206,
    squareFootage: "135617",
    propertyType: "Industrial",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 1564951,
    depreciableAssets: 16,
  },
  {
    lat: 27.8186842,
    lng: -99.6786894,
    squareFootage: "261614",
    propertyType: "Industrial",
    totalCosts: "71500000",
    valueOfReclassifiedAssets: 9635511,
    depreciableAssets: 16,
  },
  {
    lat: 41.7258401,
    lng: -85.3342973,
    squareFootage: "347517",
    propertyType: "Industrial",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1098842,
    depreciableAssets: 17,
  },
  {
    lat: 47.8772694,
    lng: -97.1580776,
    squareFootage: "85000",
    propertyType: "Industrial",
    totalCosts: "4951845",
    valueOfReclassifiedAssets: 922132,
    depreciableAssets: 21,
  },
  {
    lat: 38.1891095,
    lng: -85.6588999,
    squareFootage: "100000",
    propertyType: "Industrial",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1331898,
    depreciableAssets: 22,
  },
  {
    lat: 39.6203775,
    lng: -75.7487779,
    squareFootage: "183265",
    propertyType: "Industrial",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 1872558,
    depreciableAssets: 21,
  },
  {
    lat: 38.1495841,
    lng: -85.8793252,
    squareFootage: "240000",
    propertyType: "Industrial",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 2889374,
    depreciableAssets: 17,
  },
  {
    lat: 40.298702,
    lng: -83.06772440000002,
    squareFootage: "123850",
    propertyType: "Industrial",
    totalCosts: "5475000",
    valueOfReclassifiedAssets: 571862,
    depreciableAssets: 13,
  },
  {
    lat: 40.298702,
    lng: -83.06772440000002,
    squareFootage: "362260",
    propertyType: "Industrial",
    totalCosts: "8700000",
    valueOfReclassifiedAssets: 670082,
    depreciableAssets: 9,
  },
  {
    lat: 38.7884682,
    lng: -90.403094,
    squareFootage: "90533",
    propertyType: "Industrial",
    totalCosts: "3395000",
    valueOfReclassifiedAssets: 443553,
    depreciableAssets: 15,
  },
  {
    lat: 38.7884682,
    lng: -90.403094,
    squareFootage: "120000",
    propertyType: "Industrial",
    totalCosts: "2050000",
    valueOfReclassifiedAssets: 204578,
    depreciableAssets: 12,
  },
  {
    lat: 38.7950192,
    lng: -91.0394635,
    squareFootage: "130000",
    propertyType: "Industrial",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 295146,
    depreciableAssets: 13,
  },
  {
    lat: 38.7415569,
    lng: -90.3418927,
    squareFootage: "38776",
    propertyType: "Industrial",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 455783,
    depreciableAssets: 18,
  },
  {
    lat: 42.9561414,
    lng: -72.3074326,
    squareFootage: "35086",
    propertyType: "Industrial",
    totalCosts: "1877000",
    valueOfReclassifiedAssets: 259762,
    depreciableAssets: 17,
  },
  {
    lat: 42.9561414,
    lng: -72.3074326,
    squareFootage: "59420",
    propertyType: "Industrial",
    totalCosts: "4097000",
    valueOfReclassifiedAssets: 527856,
    depreciableAssets: 15,
  },
  {
    lat: 41.7458012,
    lng: -87.60876379999999,
    squareFootage: "68000",
    propertyType: "Multifamily",
    totalCosts: "20550000",
    valueOfReclassifiedAssets: 10885372,
    depreciableAssets: 27,
  },
  {
    lat: 29.8166771,
    lng: -95.9621106,
    squareFootage: "37370",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4060606",
    valueOfReclassifiedAssets: 2321338,
    depreciableAssets: 41,
  },
  {
    lat: 29.702465,
    lng: -95.53548160000001,
    squareFootage: "45273",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 2013503,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 3268846,
    depreciableAssets: 27,
  },
  {
    lat: 42.2473619,
    lng: -89.0800227,
    squareFootage: "68250",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 5688220,
    depreciableAssets: 27,
  },
  {
    lat: 38.27806950000001,
    lng: -94.35651299999999,
    squareFootage: "27640",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 1327321,
    depreciableAssets: 36,
  },
  {
    lat: 29.1218718,
    lng: -98.1775638,
    squareFootage: "45782",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3878788",
    valueOfReclassifiedAssets: 2289352,
    depreciableAssets: 42,
  },
  {
    lat: 29.542044,
    lng: -98.555621,
    squareFootage: "39312",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 2030182,
    depreciableAssets: 28,
  },
  {
    lat: 29.96018789999999,
    lng: -95.5587992,
    squareFootage: "61561",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 1999006,
    depreciableAssets: 27,
  },
  {
    lat: 41.3469288,
    lng: -72.9047136,
    squareFootage: "25925",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 2860363,
    depreciableAssets: 41,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "29115",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 2807804,
    depreciableAssets: 28,
  },
  {
    lat: 28.9324595,
    lng: -97.0283383,
    squareFootage: "37650",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3696970",
    valueOfReclassifiedAssets: 2167559,
    depreciableAssets: 41,
  },
  {
    lat: 32.7049103,
    lng: -96.8726295,
    squareFootage: "61331",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6650000",
    valueOfReclassifiedAssets: 3535278,
    depreciableAssets: 34,
  },
  {
    lat: 41.6433349,
    lng: -87.7123355,
    squareFootage: "66913",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 3199025,
    depreciableAssets: 27,
  },
  {
    lat: 38.9530417,
    lng: -94.6398693,
    squareFootage: "104785",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 3522021,
    depreciableAssets: 28,
  },
  {
    lat: 30.185302,
    lng: -96.89944949999999,
    squareFootage: "27515",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2666667",
    valueOfReclassifiedAssets: 1620209,
    depreciableAssets: 41,
  },
  {
    lat: 32.7049103,
    lng: -96.8726295,
    squareFootage: "61331",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5652500",
    valueOfReclassifiedAssets: 3004986,
    depreciableAssets: 34,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "29115",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 2807804,
    depreciableAssets: 28,
  },
  {
    lat: 29.5450583,
    lng: -95.1379946,
    squareFootage: "32471",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 2064827,
    depreciableAssets: 27,
  },
  {
    lat: 41.7597921,
    lng: -87.56430399999999,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "11350000",
    valueOfReclassifiedAssets: 6082197,
    depreciableAssets: 27,
  },
  {
    lat: 31.7953957,
    lng: -94.17904820000001,
    squareFootage: "28544",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 1512017,
    depreciableAssets: 35,
  },
  {
    lat: 41.6433349,
    lng: -87.7123355,
    squareFootage: "66913",
    propertyType: "Multifamily",
    totalCosts: "11350000",
    valueOfReclassifiedAssets: 5952285,
    depreciableAssets: 27,
  },
  {
    lat: 30.6588174,
    lng: -97.0498442,
    squareFootage: "38065",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3636364",
    valueOfReclassifiedAssets: 2148575,
    depreciableAssets: 41,
  },
  {
    lat: 29.2300189,
    lng: -97.9157099,
    squareFootage: "13065",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2060606",
    valueOfReclassifiedAssets: 1216965,
    depreciableAssets: 42,
  },
  {
    lat: 29.7253361,
    lng: -95.67735119999999,
    squareFootage: "45257",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 2036650,
    depreciableAssets: 27,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "29115",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4972500",
    valueOfReclassifiedAssets: 2386633,
    depreciableAssets: 28,
  },
  {
    lat: 32.7049103,
    lng: -96.8726295,
    squareFootage: "61331",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6650000",
    valueOfReclassifiedAssets: 3535278,
    depreciableAssets: 34,
  },
  {
    lat: 31.7953957,
    lng: -94.17904820000001,
    squareFootage: "28544",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2422500",
    valueOfReclassifiedAssets: 1285214,
    depreciableAssets: 35,
  },
  {
    lat: 26.931397,
    lng: -80.109357,
    squareFootage: "33689",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4853822",
    valueOfReclassifiedAssets: 1595374,
    depreciableAssets: 39,
  },
  {
    lat: 40.9026612,
    lng: -73.17036279999999,
    squareFootage: "86842",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17400933",
    valueOfReclassifiedAssets: 14017397,
    depreciableAssets: 24,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "48362",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "27813450",
    valueOfReclassifiedAssets: 7776591,
    depreciableAssets: 33,
  },
  {
    lat: 40.60906540000001,
    lng: -79.74138959999999,
    squareFootage: "28560",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1012896,
    depreciableAssets: 23,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "58345",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16340000",
    valueOfReclassifiedAssets: 5031496,
    depreciableAssets: 37,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "69336",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 4548579,
    depreciableAssets: 24,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "129776",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "25250000",
    valueOfReclassifiedAssets: 4963682,
    depreciableAssets: 22,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "58345",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16340000",
    valueOfReclassifiedAssets: 6360601,
    depreciableAssets: 37,
  },
  {
    lat: 40.4327086,
    lng: -79.9248669,
    squareFootage: "70000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16754964",
    valueOfReclassifiedAssets: 2441647,
    depreciableAssets: 16,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3565275",
    valueOfReclassifiedAssets: 2717812,
    depreciableAssets: 26,
  },
  {
    lat: 28.0563834,
    lng: -82.4427769,
    squareFootage: "67486",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "32059497",
    valueOfReclassifiedAssets: 9648976,
    depreciableAssets: 35,
  },
  {
    lat: 36.1482458,
    lng: -86.73660819999999,
    squareFootage: "80959",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21660000",
    valueOfReclassifiedAssets: 6149403,
    depreciableAssets: 33,
  },
  {
    lat: 41.0125894,
    lng: -73.6830521,
    squareFootage: "47795",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "22400000",
    valueOfReclassifiedAssets: 5426213,
    depreciableAssets: 29,
  },
  {
    lat: 36.1482458,
    lng: -86.73660819999999,
    squareFootage: "80959",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21660000",
    valueOfReclassifiedAssets: 8707637,
    depreciableAssets: 33,
  },
  {
    lat: 39.6779549,
    lng: -83.91089680000002,
    squareFootage: "35400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 2802038,
    depreciableAssets: 44,
  },
  {
    lat: 40.6608095,
    lng: -73.6359602,
    squareFootage: "66372",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7800000",
    valueOfReclassifiedAssets: 1270131,
    depreciableAssets: 18,
  },
  {
    lat: 41.483411,
    lng: -73.7242486,
    squareFootage: "47670",
    propertyType: "Specialty",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 174052,
    depreciableAssets: 12,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1445778",
    valueOfReclassifiedAssets: 1284731,
    depreciableAssets: 0,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "22866013",
    valueOfReclassifiedAssets: 20315012,
    depreciableAssets: 12,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "12500",
    propertyType: "Multifamily",
    totalCosts: "15172500",
    valueOfReclassifiedAssets: 2573172,
    depreciableAssets: 20,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "16500",
    propertyType: "Multifamily",
    totalCosts: "5425000",
    valueOfReclassifiedAssets: 540035,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3022547",
    valueOfReclassifiedAssets: 2260252,
    depreciableAssets: 27,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12051634",
    valueOfReclassifiedAssets: 1276861,
    depreciableAssets: 12,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "16500",
    propertyType: "Multifamily",
    totalCosts: "5425000",
    valueOfReclassifiedAssets: 540035,
    depreciableAssets: 11,
  },
  {
    lat: 40.8022594,
    lng: -73.9154069,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "37820909",
    valueOfReclassifiedAssets: 34357798,
    depreciableAssets: 11,
  },
  {
    lat: 39.0595709,
    lng: -76.71633589999999,
    squareFootage: "4874",
    propertyType: "Office",
    totalCosts: "1940000",
    valueOfReclassifiedAssets: 446249,
    depreciableAssets: 27,
  },
  {
    lat: 33.0067086,
    lng: -96.99609249999999,
    squareFootage: "170739",
    propertyType: "Retail",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 9282713,
    depreciableAssets: 35,
  },
  {
    lat: 39.2635174,
    lng: -76.6239423,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14721446",
    valueOfReclassifiedAssets: 1161902,
    depreciableAssets: 9,
  },
  {
    lat: 39.2635174,
    lng: -76.6239423,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "33220809",
    valueOfReclassifiedAssets: 3862850,
    depreciableAssets: 12,
  },
  {
    lat: 39.407567,
    lng: -76.6354952,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25000619",
    valueOfReclassifiedAssets: 3184356,
    depreciableAssets: 16,
  },
  {
    lat: 36.1426113,
    lng: -80.33245140000001,
    squareFootage: "213179",
    propertyType: "Multifamily",
    totalCosts: "9400000",
    valueOfReclassifiedAssets: 1688741,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "64604",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 537834,
    depreciableAssets: 21,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "114600",
    propertyType: "Multifamily",
    totalCosts: "5085060",
    valueOfReclassifiedAssets: 957134,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "153498",
    propertyType: "Multifamily",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 1451657,
    depreciableAssets: 22,
  },
  {
    lat: 35.1743999,
    lng: -80.9041817,
    squareFootage: "360908",
    propertyType: "Multifamily",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 6123196,
    depreciableAssets: 34,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "19640",
    propertyType: "Multifamily",
    totalCosts: "1440000",
    valueOfReclassifiedAssets: 299736,
    depreciableAssets: 24,
  },
  {
    lat: 35.1743999,
    lng: -80.9041817,
    squareFootage: "360908",
    propertyType: "Multifamily",
    totalCosts: "20882798",
    valueOfReclassifiedAssets: 6094144,
    depreciableAssets: 34,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "144162",
    propertyType: "Multifamily",
    totalCosts: "7725000",
    valueOfReclassifiedAssets: 1704048,
    depreciableAssets: 30,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "40625",
    propertyType: "Industrial",
    totalCosts: "3624536",
    valueOfReclassifiedAssets: 1617964,
    depreciableAssets: 52,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "40625",
    propertyType: "Industrial",
    totalCosts: "3680239",
    valueOfReclassifiedAssets: 1647132,
    depreciableAssets: 52,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "32285",
    propertyType: "Industrial",
    totalCosts: "3226381",
    valueOfReclassifiedAssets: 644366,
    depreciableAssets: 24,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "63000",
    propertyType: "Industrial",
    totalCosts: "6362407",
    valueOfReclassifiedAssets: 1326111,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "28075",
    propertyType: "Industrial",
    totalCosts: "2505798",
    valueOfReclassifiedAssets: 704023,
    depreciableAssets: 33,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "37500",
    propertyType: "Industrial",
    totalCosts: "3346018",
    valueOfReclassifiedAssets: 636513,
    depreciableAssets: 23,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "28075",
    propertyType: "Industrial",
    totalCosts: "2505798",
    valueOfReclassifiedAssets: 713035,
    depreciableAssets: 33,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "51000",
    propertyType: "Industrial",
    totalCosts: "6425520",
    valueOfReclassifiedAssets: 1474568,
    depreciableAssets: 27,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "45000",
    propertyType: "Industrial",
    totalCosts: "4014467",
    valueOfReclassifiedAssets: 754640,
    depreciableAssets: 22,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "40792",
    propertyType: "Industrial",
    totalCosts: "4116234",
    valueOfReclassifiedAssets: 878727,
    depreciableAssets: 25,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "5351167",
    valueOfReclassifiedAssets: 1263156,
    depreciableAssets: 28,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "83600",
    propertyType: "Industrial",
    totalCosts: "8038646",
    valueOfReclassifiedAssets: 1784197,
    depreciableAssets: 26,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "42300",
    propertyType: "Industrial",
    totalCosts: "6990787",
    valueOfReclassifiedAssets: 1479285,
    depreciableAssets: 25,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "30000",
    propertyType: "Specialty",
    totalCosts: "2718696",
    valueOfReclassifiedAssets: 533565,
    depreciableAssets: 23,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "27400",
    propertyType: "Industrial",
    totalCosts: "1977142",
    valueOfReclassifiedAssets: 657264,
    depreciableAssets: 39,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "37600",
    propertyType: "Industrial",
    totalCosts: "3354728",
    valueOfReclassifiedAssets: 940395,
    depreciableAssets: 32,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "45000",
    propertyType: "Industrial",
    totalCosts: "4014467",
    valueOfReclassifiedAssets: 597887,
    depreciableAssets: 18,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "35625",
    propertyType: "Industrial",
    totalCosts: "3595407",
    valueOfReclassifiedAssets: 641716,
    depreciableAssets: 21,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "30000",
    propertyType: "Specialty",
    totalCosts: "2677562",
    valueOfReclassifiedAssets: 524120,
    depreciableAssets: 23,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "26000",
    propertyType: "Industrial",
    totalCosts: "3290191",
    valueOfReclassifiedAssets: 705482,
    depreciableAssets: 25,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "74400",
    propertyType: "Industrial",
    totalCosts: "4490029",
    valueOfReclassifiedAssets: 4062897,
    depreciableAssets: 22,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "108118",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21179564",
    valueOfReclassifiedAssets: 17606530,
    depreciableAssets: 24,
  },
  {
    lat: 39.937645,
    lng: -75.1136721,
    squareFootage: "49026",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10122694",
    valueOfReclassifiedAssets: 9059354,
    depreciableAssets: 14,
  },
  {
    lat: 39.937645,
    lng: -75.1136721,
    squareFootage: "55857",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12628099",
    valueOfReclassifiedAssets: 5554092,
    depreciableAssets: 20,
  },
  {
    lat: 41.3052226,
    lng: -72.92686259999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "39635159",
    valueOfReclassifiedAssets: 34094096,
    depreciableAssets: 15,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "3808",
    propertyType: "Multifamily",
    totalCosts: "1905000",
    valueOfReclassifiedAssets: 226152,
    depreciableAssets: 14,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "6084",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 314996,
    depreciableAssets: 13,
  },
  {
    lat: 40.7633726,
    lng: -73.9109977,
    squareFootage: "5028",
    propertyType: "Multifamily",
    totalCosts: "1999796",
    valueOfReclassifiedAssets: 191576,
    depreciableAssets: 12,
  },
  {
    lat: 40.7633726,
    lng: -73.9109977,
    squareFootage: "2400",
    propertyType: "Multifamily",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 105498,
    depreciableAssets: 15,
  },
  {
    lat: 40.7787902,
    lng: -73.9065883,
    squareFootage: "3991",
    propertyType: "Multifamily",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 168350,
    depreciableAssets: 11,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "51474",
    propertyType: "Multifamily",
    totalCosts: "2515571",
    valueOfReclassifiedAssets: 2023700,
    depreciableAssets: 23,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "51474",
    propertyType: "Multifamily",
    totalCosts: "1115216",
    valueOfReclassifiedAssets: 897157,
    depreciableAssets: 23,
  },
  {
    lat: 37.2913946,
    lng: -77.3898602,
    squareFootage: "105920",
    propertyType: "Multifamily",
    totalCosts: "6150000",
    valueOfReclassifiedAssets: 1592844,
    depreciableAssets: 30,
  },
  {
    lat: 40.0205975,
    lng: -74.7922202,
    squareFootage: "56924",
    propertyType: "Multifamily",
    totalCosts: "17300000",
    valueOfReclassifiedAssets: 2910475,
    depreciableAssets: 24,
  },
  {
    lat: 39.5943167,
    lng: -75.70225669999999,
    squareFootage: "260950",
    propertyType: "Multifamily",
    totalCosts: "43645000",
    valueOfReclassifiedAssets: 8933246,
    depreciableAssets: 23,
  },
  {
    lat: 41.5091257,
    lng: -81.6089873,
    squareFootage: "25565",
    propertyType: "Office",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 604709,
    depreciableAssets: 25,
  },
  {
    lat: 38.9627306,
    lng: -76.854799,
    squareFootage: "132312",
    propertyType: "Office",
    totalCosts: "6186785",
    valueOfReclassifiedAssets: 1419054,
    depreciableAssets: 27,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "17490",
    propertyType: "Multifamily",
    totalCosts: "1166299",
    valueOfReclassifiedAssets: 1020975,
    depreciableAssets: 18,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "19644",
    propertyType: "Multifamily",
    totalCosts: "1278500",
    valueOfReclassifiedAssets: 1104286,
    depreciableAssets: 16,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "14784",
    propertyType: "Multifamily",
    totalCosts: "3035269",
    valueOfReclassifiedAssets: 2667018,
    depreciableAssets: 12,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "153952",
    propertyType: "Multifamily",
    totalCosts: "7583453",
    valueOfReclassifiedAssets: 6792763,
    depreciableAssets: 12,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "14784",
    propertyType: "Multifamily",
    totalCosts: "2168050",
    valueOfReclassifiedAssets: 1906768,
    depreciableAssets: 12,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "57022",
    propertyType: "Multifamily",
    totalCosts: "4645023",
    valueOfReclassifiedAssets: 518512,
    depreciableAssets: 12,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "59590",
    propertyType: "Multifamily",
    totalCosts: "4961300",
    valueOfReclassifiedAssets: 693279,
    depreciableAssets: 16,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "35930",
    propertyType: "Multifamily",
    totalCosts: "2941124",
    valueOfReclassifiedAssets: 2504695,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "33696",
    propertyType: "Multifamily",
    totalCosts: "1870154",
    valueOfReclassifiedAssets: 1603763,
    depreciableAssets: 16,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "42060",
    propertyType: "Multifamily",
    totalCosts: "3233926",
    valueOfReclassifiedAssets: 411597,
    depreciableAssets: 14,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "21931",
    propertyType: "Multifamily",
    totalCosts: "1863182",
    valueOfReclassifiedAssets: 1626291,
    depreciableAssets: 14,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "34836",
    propertyType: "Multifamily",
    totalCosts: "9030748",
    valueOfReclassifiedAssets: 1111682,
    depreciableAssets: 14,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "13993",
    propertyType: "Multifamily",
    totalCosts: "2209663",
    valueOfReclassifiedAssets: 279413,
    depreciableAssets: 14,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "33201",
    propertyType: "Multifamily",
    totalCosts: "2654309",
    valueOfReclassifiedAssets: 368698,
    depreciableAssets: 16,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "27900",
    propertyType: "Multifamily",
    totalCosts: "2007195",
    valueOfReclassifiedAssets: 1748154,
    depreciableAssets: 15,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "26780",
    propertyType: "Multifamily",
    totalCosts: "5220089",
    valueOfReclassifiedAssets: 665022,
    depreciableAssets: 14,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "22400",
    propertyType: "Multifamily",
    totalCosts: "6121523",
    valueOfReclassifiedAssets: 778797,
    depreciableAssets: 14,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "24500",
    propertyType: "Office",
    totalCosts: "5470610",
    valueOfReclassifiedAssets: 631967,
    depreciableAssets: 13,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5964635",
    valueOfReclassifiedAssets: 640977,
    depreciableAssets: 12,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "85218",
    propertyType: "Multifamily",
    totalCosts: "4167870",
    valueOfReclassifiedAssets: 537431,
    depreciableAssets: 14,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "39876",
    propertyType: "Multifamily",
    totalCosts: "3178628",
    valueOfReclassifiedAssets: 455625,
    depreciableAssets: 16,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "15060",
    propertyType: "Multifamily",
    totalCosts: "8530523",
    valueOfReclassifiedAssets: 1131427,
    depreciableAssets: 14,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "55000",
    propertyType: "Multifamily",
    totalCosts: "7125973",
    valueOfReclassifiedAssets: 715269,
    depreciableAssets: 11,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "18800",
    propertyType: "Multifamily",
    totalCosts: "3179989",
    valueOfReclassifiedAssets: 2858778,
    depreciableAssets: 11,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "15820",
    propertyType: "Multifamily",
    totalCosts: "1080105",
    valueOfReclassifiedAssets: 912749,
    depreciableAssets: 16,
  },
  {
    lat: 40.8285039,
    lng: -73.8918897,
    squareFootage: "7000",
    propertyType: "Office",
    totalCosts: "1463107",
    valueOfReclassifiedAssets: 1248769,
    depreciableAssets: 16,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "30200",
    propertyType: "Multifamily",
    totalCosts: "4316551",
    valueOfReclassifiedAssets: 392472,
    depreciableAssets: 10,
  },
  {
    lat: 40.8050442,
    lng: -73.8830701,
    squareFootage: "25000",
    propertyType: "Multifamily",
    totalCosts: "4141949",
    valueOfReclassifiedAssets: 650960,
    depreciableAssets: 18,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "27000",
    propertyType: "Multifamily",
    totalCosts: "5723409",
    valueOfReclassifiedAssets: 599958,
    depreciableAssets: 12,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "54723",
    propertyType: "Multifamily",
    totalCosts: "4623894",
    valueOfReclassifiedAssets: 618902,
    depreciableAssets: 15,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "43796",
    propertyType: "Multifamily",
    totalCosts: "3876000",
    valueOfReclassifiedAssets: 3264291,
    depreciableAssets: 17,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "18800",
    propertyType: "Multifamily",
    totalCosts: "4047259",
    valueOfReclassifiedAssets: 3641139,
    depreciableAssets: 11,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "71000",
    propertyType: "Multifamily",
    totalCosts: "4067881",
    valueOfReclassifiedAssets: 3650819,
    depreciableAssets: 18,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "15060",
    propertyType: "Multifamily",
    totalCosts: "8530523",
    valueOfReclassifiedAssets: 1131427,
    depreciableAssets: 14,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "38875",
    propertyType: "Multifamily",
    totalCosts: "7132396",
    valueOfReclassifiedAssets: 910044,
    depreciableAssets: 14,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "28219",
    propertyType: "Multifamily",
    totalCosts: "7507208",
    valueOfReclassifiedAssets: 832390,
    depreciableAssets: 12,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "52150",
    propertyType: "Multifamily",
    totalCosts: "3412500",
    valueOfReclassifiedAssets: 2994518,
    depreciableAssets: 14,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "65000",
    propertyType: "Multifamily",
    totalCosts: "4181453",
    valueOfReclassifiedAssets: 3753106,
    depreciableAssets: 14,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "15760",
    propertyType: "Multifamily",
    totalCosts: "3468879",
    valueOfReclassifiedAssets: 3019067,
    depreciableAssets: 14,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "41005",
    propertyType: "Multifamily",
    totalCosts: "2446561",
    valueOfReclassifiedAssets: 371955,
    depreciableAssets: 17,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "23032",
    propertyType: "Multifamily",
    totalCosts: "3523694",
    valueOfReclassifiedAssets: 409781,
    depreciableAssets: 12,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "25500",
    propertyType: "Multifamily",
    totalCosts: "5469310",
    valueOfReclassifiedAssets: 945571,
    depreciableAssets: 19,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "17800",
    propertyType: "Multifamily",
    totalCosts: "3116962",
    valueOfReclassifiedAssets: 2803322,
    depreciableAssets: 11,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "45548",
    propertyType: "Multifamily",
    totalCosts: "3358500",
    valueOfReclassifiedAssets: 368621,
    depreciableAssets: 12,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "19570",
    propertyType: "Multifamily",
    totalCosts: "5619311",
    valueOfReclassifiedAssets: 896176,
    depreciableAssets: 17,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "17800",
    propertyType: "Multifamily",
    totalCosts: "3116962",
    valueOfReclassifiedAssets: 2805508,
    depreciableAssets: 11,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11856451",
    valueOfReclassifiedAssets: 11626419,
    depreciableAssets: 15,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11856451",
    valueOfReclassifiedAssets: 11526906,
    depreciableAssets: 17,
  },
  {
    lat: 40.9411487,
    lng: -74.2531465,
    squareFootage: "72195",
    propertyType: "Office",
    totalCosts: "10750000",
    valueOfReclassifiedAssets: 2323035,
    depreciableAssets: 26,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "7524",
    propertyType: "Multifamily",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 904339,
    depreciableAssets: 30,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "7524",
    propertyType: "Multifamily",
    totalCosts: "3503050",
    valueOfReclassifiedAssets: 877931,
    depreciableAssets: 30,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "8000",
    propertyType: "Office",
    totalCosts: "7258860",
    valueOfReclassifiedAssets: 902740,
    depreciableAssets: 16,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "12836",
    propertyType: "Multifamily",
    totalCosts: "4507347",
    valueOfReclassifiedAssets: 1275285,
    depreciableAssets: 34,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "12836",
    propertyType: "Multifamily",
    totalCosts: "4519845",
    valueOfReclassifiedAssets: 1278821,
    depreciableAssets: 34,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "13719",
    propertyType: "Multifamily",
    totalCosts: "5703351",
    valueOfReclassifiedAssets: 1384505,
    depreciableAssets: 28,
  },
  {
    lat: 37.0586714,
    lng: -95.7036608,
    squareFootage: "11585",
    propertyType: "Office",
    totalCosts: "5324757",
    valueOfReclassifiedAssets: 1422984,
    depreciableAssets: 34,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "7524",
    propertyType: "Multifamily",
    totalCosts: "3503400",
    valueOfReclassifiedAssets: 905217,
    depreciableAssets: 30,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "8000",
    propertyType: "Office",
    totalCosts: "7258860",
    valueOfReclassifiedAssets: 1124636,
    depreciableAssets: 19,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 877167,
    depreciableAssets: 30,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "3750",
    propertyType: "Office",
    totalCosts: "4253717",
    valueOfReclassifiedAssets: 398869,
    depreciableAssets: 12,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "13719",
    propertyType: "Multifamily",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 1383691,
    depreciableAssets: 28,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "3750",
    propertyType: "Office",
    totalCosts: "4253717",
    valueOfReclassifiedAssets: 499464,
    depreciableAssets: 14,
  },
  {
    lat: 32.9436607,
    lng: -117.2088167,
    squareFootage: "40550",
    propertyType: "Multifamily",
    totalCosts: "4194130",
    valueOfReclassifiedAssets: 3487686,
    depreciableAssets: 27,
  },
  {
    lat: 33.105162,
    lng: -117.2970026,
    squareFootage: "2016",
    propertyType: "Office",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 494227,
    depreciableAssets: 16,
  },
  {
    lat: 32.7742488,
    lng: -117.1411815,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17615491",
    valueOfReclassifiedAssets: 15027929,
    depreciableAssets: 24,
  },
  {
    lat: 32.9436607,
    lng: -117.2088167,
    squareFootage: "40550",
    propertyType: "Multifamily",
    totalCosts: "4194130",
    valueOfReclassifiedAssets: 3487686,
    depreciableAssets: 27,
  },
  {
    lat: 32.9657005,
    lng: -117.1147095,
    squareFootage: "80000",
    propertyType: "Multifamily",
    totalCosts: "5004215",
    valueOfReclassifiedAssets: 4036955,
    depreciableAssets: 27,
  },
  {
    lat: 33.6301328,
    lng: -117.8721676,
    squareFootage: "16810",
    propertyType: "Office",
    totalCosts: "4202500",
    valueOfReclassifiedAssets: 3151875,
    depreciableAssets: 100,
  },
  {
    lat: 38.6940006,
    lng: -121.3026883,
    squareFootage: "133318",
    propertyType: "Retail",
    totalCosts: "14750000",
    valueOfReclassifiedAssets: 4563553,
    depreciableAssets: 37,
  },
  {
    lat: 36.2305398,
    lng: -86.7718017,
    squareFootage: "275268",
    propertyType: "Multifamily",
    totalCosts: "12730590",
    valueOfReclassifiedAssets: 2137037,
    depreciableAssets: 24,
  },
  {
    lat: 40.6551078,
    lng: -75.4973742,
    squareFootage: "337144",
    propertyType: "Multifamily",
    totalCosts: "18250000",
    valueOfReclassifiedAssets: 3248820,
    depreciableAssets: 20,
  },
  {
    lat: 39.8525918,
    lng: -75.07863569999999,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 2723066,
    depreciableAssets: 20,
  },
  {
    lat: 39.8525918,
    lng: -75.07863569999999,
    squareFootage: "494406",
    propertyType: "Multifamily",
    totalCosts: "12968779",
    valueOfReclassifiedAssets: 2278377,
    depreciableAssets: 20,
  },
  {
    lat: 39.8525918,
    lng: -75.07863569999999,
    squareFootage: "494406",
    propertyType: "Multifamily",
    totalCosts: "13086054",
    valueOfReclassifiedAssets: 2298980,
    depreciableAssets: 20,
  },
  {
    lat: 32.8477269,
    lng: -97.1305536,
    squareFootage: "218188",
    propertyType: "Multifamily",
    totalCosts: "17300000",
    valueOfReclassifiedAssets: 5011170,
    depreciableAssets: 31,
  },
  {
    lat: 30.196311,
    lng: -97.730807,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 1440951,
    depreciableAssets: 59,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "145865",
    propertyType: "Multifamily",
    totalCosts: "172800000",
    valueOfReclassifiedAssets: 34058845,
    depreciableAssets: 23,
  },
  {
    lat: 40.7084274,
    lng: -74.0173484,
    squareFootage: "168952",
    propertyType: "Multifamily",
    totalCosts: "28535923",
    valueOfReclassifiedAssets: 24465300,
    depreciableAssets: 14,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "250090",
    propertyType: "Multifamily",
    totalCosts: "103000000",
    valueOfReclassifiedAssets: 11853163,
    depreciableAssets: 15,
  },
  {
    lat: 40.7084274,
    lng: -74.0173484,
    squareFootage: "168952",
    propertyType: "Multifamily",
    totalCosts: "472663",
    valueOfReclassifiedAssets: 405238,
    depreciableAssets: 14,
  },
  {
    lat: 40.7769059,
    lng: -73.9800645,
    squareFootage: "82230",
    propertyType: "Multifamily",
    totalCosts: "79000000",
    valueOfReclassifiedAssets: 11305822,
    depreciableAssets: 16,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "35927399",
    valueOfReclassifiedAssets: 29966813,
    depreciableAssets: 20,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "40029708",
    valueOfReclassifiedAssets: 33388523,
    depreciableAssets: 20,
  },
  {
    lat: 26.1218038,
    lng: -80.2209773,
    squareFootage: "38228",
    propertyType: "Office",
    totalCosts: "2026000",
    valueOfReclassifiedAssets: 457767,
    depreciableAssets: 26,
  },
  {
    lat: 26.208305,
    lng: -80.2685032,
    squareFootage: "7593",
    propertyType: "Office",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 324480,
    depreciableAssets: 34,
  },
  {
    lat: 26.208305,
    lng: -80.2685032,
    squareFootage: "42067",
    propertyType: "Office",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 872994,
    depreciableAssets: 0,
  },
  {
    lat: 38.9052763,
    lng: -76.98158769999999,
    squareFootage: "25542",
    propertyType: "Office",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 944788,
    depreciableAssets: 15,
  },
  {
    lat: 42.2872433,
    lng: -72.4021423,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1236324",
    valueOfReclassifiedAssets: 1163960,
    depreciableAssets: 25,
  },
  {
    lat: 39.1095952,
    lng: -84.42251859999999,
    squareFootage: "25094",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1439277,
    depreciableAssets: 32,
  },
  {
    lat: 34.2184226,
    lng: -111.3321974,
    squareFootage: "75813",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3226178",
    valueOfReclassifiedAssets: 774771,
    depreciableAssets: 25,
  },
  {
    lat: 31.9293086,
    lng: -95.26844369999999,
    squareFootage: "33587",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 1067210,
    depreciableAssets: 33,
  },
  {
    lat: 33.8065036,
    lng: -118.1912538,
    squareFootage: "12067",
    propertyType: "Multifamily",
    totalCosts: "2630000",
    valueOfReclassifiedAssets: 622782,
    depreciableAssets: 28,
  },
  {
    lat: 29.0034265,
    lng: -81.0533854,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11990362",
    valueOfReclassifiedAssets: 10516053,
    depreciableAssets: 20,
  },
  {
    lat: 39.013101,
    lng: -95.85121,
    squareFootage: "58740",
    propertyType: "Multifamily",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 966977,
    depreciableAssets: 26,
  },
  {
    lat: 39.013101,
    lng: -95.85121,
    squareFootage: "58740",
    propertyType: "Multifamily",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 966977,
    depreciableAssets: 26,
  },
  {
    lat: 41.9395567,
    lng: -87.70071089999999,
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "2329999",
    valueOfReclassifiedAssets: 607208,
    depreciableAssets: 27,
  },
  {
    lat: 40.4509149,
    lng: -79.9024779,
    squareFootage: "60000",
    propertyType: "Multifamily",
    totalCosts: "4947025",
    valueOfReclassifiedAssets: 1014887,
    depreciableAssets: 21,
  },
  {
    lat: 40.4509149,
    lng: -79.9024779,
    squareFootage: "60000",
    propertyType: "Multifamily",
    totalCosts: "4947025",
    valueOfReclassifiedAssets: 1014887,
    depreciableAssets: 21,
  },
  {
    lat: 41.8668281,
    lng: -71.4358733,
    squareFootage: "42500",
    propertyType: "Multifamily",
    totalCosts: "3267482",
    valueOfReclassifiedAssets: 688041,
    depreciableAssets: 22,
  },
  {
    lat: 40.4509149,
    lng: -79.9024779,
    squareFootage: "60000",
    propertyType: "Multifamily",
    totalCosts: "4947025",
    valueOfReclassifiedAssets: 1014887,
    depreciableAssets: 21,
  },
  {
    lat: 39.916922,
    lng: -79.7624177,
    squareFootage: "86000",
    propertyType: "Multifamily",
    totalCosts: "3881025",
    valueOfReclassifiedAssets: 581956,
    depreciableAssets: 16,
  },
  {
    lat: 39.916922,
    lng: -79.7624177,
    squareFootage: "86000",
    propertyType: "Multifamily",
    totalCosts: "3881025",
    valueOfReclassifiedAssets: 581956,
    depreciableAssets: 16,
  },
  {
    lat: 42.8060277,
    lng: -73.6182979,
    squareFootage: "58980",
    propertyType: "Multifamily",
    totalCosts: "4077500",
    valueOfReclassifiedAssets: 959583,
    depreciableAssets: 26,
  },
  {
    lat: 33.3990327,
    lng: -111.7972384,
    squareFootage: "88156",
    propertyType: "Multifamily",
    totalCosts: "3116775",
    valueOfReclassifiedAssets: 603630,
    depreciableAssets: 22,
  },
  {
    lat: 33.3990327,
    lng: -111.7972384,
    squareFootage: "88156",
    propertyType: "Multifamily",
    totalCosts: "3116775",
    valueOfReclassifiedAssets: 603630,
    depreciableAssets: 22,
  },
  {
    lat: 41.9395567,
    lng: -87.70071089999999,
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "2329999",
    valueOfReclassifiedAssets: 607208,
    depreciableAssets: 27,
  },
  {
    lat: 41.6009257,
    lng: -86.2767995,
    squareFootage: "107622",
    propertyType: "Multifamily",
    totalCosts: "5469500",
    valueOfReclassifiedAssets: 1821258,
    depreciableAssets: 37,
  },
  {
    lat: 41.8668281,
    lng: -71.4358733,
    squareFootage: "42500",
    propertyType: "Multifamily",
    totalCosts: "3267482",
    valueOfReclassifiedAssets: 688041,
    depreciableAssets: 22,
  },
  {
    lat: 36.9722392,
    lng: -76.4215988,
    squareFootage: "128970",
    propertyType: "Multifamily",
    totalCosts: "7800000",
    valueOfReclassifiedAssets: 1479458,
    depreciableAssets: 20,
  },
  {
    lat: 42.8060277,
    lng: -73.6182979,
    squareFootage: "58980",
    propertyType: "Multifamily",
    totalCosts: "3969446",
    valueOfReclassifiedAssets: 3109898,
    depreciableAssets: 26,
  },
  {
    lat: 41.1400096,
    lng: -104.8201078,
    squareFootage: "69600",
    propertyType: "Multifamily",
    totalCosts: "4846953",
    valueOfReclassifiedAssets: 4002739,
    depreciableAssets: 24,
  },
  {
    lat: 39.916922,
    lng: -79.7624177,
    squareFootage: "86000",
    propertyType: "Multifamily",
    totalCosts: "3881025",
    valueOfReclassifiedAssets: 581956,
    depreciableAssets: 16,
  },
  {
    lat: 41.9395567,
    lng: -87.70071089999999,
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "2329999",
    valueOfReclassifiedAssets: 607208,
    depreciableAssets: 27,
  },
  {
    lat: 39.9741059,
    lng: -74.1592278,
    squareFootage: "98870",
    propertyType: "Multifamily",
    totalCosts: "16500000",
    valueOfReclassifiedAssets: 3375245,
    depreciableAssets: 22,
  },
  {
    lat: 41.8668281,
    lng: -71.4358733,
    squareFootage: "42500",
    propertyType: "Multifamily",
    totalCosts: "3267482",
    valueOfReclassifiedAssets: 688041,
    depreciableAssets: 22,
  },
  {
    lat: 41.1400096,
    lng: -104.8201078,
    squareFootage: "69600",
    propertyType: "Multifamily",
    totalCosts: "4846953",
    valueOfReclassifiedAssets: 4002739,
    depreciableAssets: 24,
  },
  {
    lat: 34.3579404,
    lng: -118.0654445,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "4952074",
    valueOfReclassifiedAssets: 1922492,
    depreciableAssets: 40,
  },
  {
    lat: 40.30220569999999,
    lng: -79.53786339999999,
    squareFootage: "98208",
    propertyType: "Multifamily",
    totalCosts: "6725000",
    valueOfReclassifiedAssets: 1048555,
    depreciableAssets: 17,
  },
  {
    lat: 40.2912966,
    lng: -74.72200169999999,
    squareFootage: "142704",
    propertyType: "Multifamily",
    totalCosts: "24065716",
    valueOfReclassifiedAssets: 4729716,
    depreciableAssets: 20,
  },
  {
    lat: 40.9509762,
    lng: -73.0995528,
    squareFootage: "256560",
    propertyType: "Multifamily",
    totalCosts: "28752334",
    valueOfReclassifiedAssets: 7097843,
    depreciableAssets: 26,
  },
  {
    lat: 39.1680808,
    lng: -84.238417,
    squareFootage: "73359",
    propertyType: "Multifamily",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 1258440,
    depreciableAssets: 31,
  },
  {
    lat: 41.32989300000001,
    lng: -73.8272029,
    squareFootage: "22280",
    propertyType: "Multifamily",
    totalCosts: "11516968",
    valueOfReclassifiedAssets: 9061193,
    depreciableAssets: 27,
  },
  {
    lat: 34.3579404,
    lng: -118.0654445,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "4952074",
    valueOfReclassifiedAssets: 1922492,
    depreciableAssets: 40,
  },
  {
    lat: 41.8268401,
    lng: -71.51304449999999,
    squareFootage: "55745",
    propertyType: "Multifamily",
    totalCosts: "6460000",
    valueOfReclassifiedAssets: 1665042,
    depreciableAssets: 27,
  },
  {
    lat: 42.8060277,
    lng: -73.6182979,
    squareFootage: "58980",
    propertyType: "Multifamily",
    totalCosts: "3969446",
    valueOfReclassifiedAssets: 3109898,
    depreciableAssets: 26,
  },
  {
    lat: 40.9509762,
    lng: -73.0995528,
    squareFootage: "256560",
    propertyType: "Multifamily",
    totalCosts: "28752334",
    valueOfReclassifiedAssets: 7097843,
    depreciableAssets: 26,
  },
  {
    lat: 44.2455449,
    lng: -88.3530747,
    squareFootage: "93264",
    propertyType: "Multifamily",
    totalCosts: "4015765",
    valueOfReclassifiedAssets: 3074751,
    depreciableAssets: 37,
  },
  {
    lat: 40.3509675,
    lng: -79.9136731,
    squareFootage: "140793",
    propertyType: "Multifamily",
    totalCosts: "11100000",
    valueOfReclassifiedAssets: 3176753,
    depreciableAssets: 30,
  },
  {
    lat: 44.2455449,
    lng: -88.3530747,
    squareFootage: "93264",
    propertyType: "Multifamily",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 1442773,
    depreciableAssets: 37,
  },
  {
    lat: 34.3579404,
    lng: -118.0654445,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "4952074",
    valueOfReclassifiedAssets: 1922492,
    depreciableAssets: 40,
  },
  {
    lat: 44.2837065,
    lng: -88.37249299999999,
    squareFootage: "93264",
    propertyType: "Multifamily",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 1442773,
    depreciableAssets: 37,
  },
  {
    lat: 44.2837065,
    lng: -88.37249299999999,
    squareFootage: "93264",
    propertyType: "Multifamily",
    totalCosts: "4015765",
    valueOfReclassifiedAssets: 3074751,
    depreciableAssets: 37,
  },
  {
    lat: 41.32989300000001,
    lng: -73.8272029,
    squareFootage: "22280",
    propertyType: "Multifamily",
    totalCosts: "11700000",
    valueOfReclassifiedAssets: 4635753,
    depreciableAssets: 44,
  },
  {
    lat: 39.1031971,
    lng: -84.5064881,
    squareFootage: "61300",
    propertyType: "Multifamily",
    totalCosts: "4151290",
    valueOfReclassifiedAssets: 1321432,
    depreciableAssets: 35,
  },
  {
    lat: 44.2455449,
    lng: -88.3530747,
    squareFootage: "93264",
    propertyType: "Multifamily",
    totalCosts: "4015765",
    valueOfReclassifiedAssets: 3074751,
    depreciableAssets: 37,
  },
  {
    lat: 41.32989300000001,
    lng: -73.8272029,
    squareFootage: "22280",
    propertyType: "Multifamily",
    totalCosts: "11516968",
    valueOfReclassifiedAssets: 9061193,
    depreciableAssets: 27,
  },
  {
    lat: 27.5004431,
    lng: -82.50775929999999,
    squareFootage: "38862",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 9243858,
    depreciableAssets: 35,
  },
  {
    lat: 27.5004431,
    lng: -82.50775929999999,
    squareFootage: "38862",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 9243858,
    depreciableAssets: 35,
  },
  {
    lat: 39.9021937,
    lng: -74.9968543,
    squareFootage: "38570",
    propertyType: "Specialty",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 3010821,
    depreciableAssets: 28,
  },
  {
    lat: 39.9021937,
    lng: -74.9968543,
    squareFootage: "38570",
    propertyType: "Specialty",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 3010821,
    depreciableAssets: 28,
  },
  {
    lat: 41.6151426,
    lng: -80.1428584,
    squareFootage: "43766",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "26811175",
    valueOfReclassifiedAssets: 13748317,
    depreciableAssets: 27,
  },
  {
    lat: 40.7680563,
    lng: -77.8481628,
    squareFootage: "55793",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18403489",
    valueOfReclassifiedAssets: 2329548,
    depreciableAssets: 24,
  },
  {
    lat: 40.2180742,
    lng: -75.07279559999999,
    squareFootage: "71000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20197847",
    valueOfReclassifiedAssets: 15103392,
    depreciableAssets: 24,
  },
  {
    lat: 40.3398705,
    lng: -77.77953699999999,
    squareFootage: "49590",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11303666",
    valueOfReclassifiedAssets: 2508156,
    depreciableAssets: 41,
  },
  {
    lat: 41.0305956,
    lng: -74.29422079999999,
    squareFootage: "89227",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "31011922",
    valueOfReclassifiedAssets: 22531891,
    depreciableAssets: 26,
  },
  {
    lat: 40.5087323,
    lng: -77.9510209,
    squareFootage: "35282",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7380080",
    valueOfReclassifiedAssets: 3959999,
    depreciableAssets: 26,
  },
  {
    lat: 28.5970072,
    lng: -82.34518899999999,
    squareFootage: "36479",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 4593935,
    depreciableAssets: 38,
  },
  {
    lat: 40.0729157,
    lng: -75.1691305,
    squareFootage: "47476",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21392889",
    valueOfReclassifiedAssets: 11251521,
    depreciableAssets: 28,
  },
  {
    lat: 41.2680199,
    lng: -76.8951551,
    squareFootage: "54834",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14386484",
    valueOfReclassifiedAssets: 7764634,
    depreciableAssets: 35,
  },
  {
    lat: 36.2726958,
    lng: -95.8353837,
    squareFootage: "80200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11141996",
    valueOfReclassifiedAssets: 3199023,
    depreciableAssets: 34,
  },
  {
    lat: 33.4662881,
    lng: -117.6317494,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "203740570",
    valueOfReclassifiedAssets: 38026799,
    depreciableAssets: 20,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "5457",
    propertyType: "Multifamily",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 491264,
    depreciableAssets: 16,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "59200",
    propertyType: "Multifamily",
    totalCosts: "11595623",
    valueOfReclassifiedAssets: 9423123,
    depreciableAssets: 19,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "59200",
    propertyType: "Multifamily",
    totalCosts: "11595623",
    valueOfReclassifiedAssets: 9544689,
    depreciableAssets: 18,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "39578",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1275262,
    depreciableAssets: 17,
  },
  {
    lat: 29.752554,
    lng: -95.37040089999999,
    squareFootage: "42857",
    propertyType: "Multifamily",
    totalCosts: "38500000",
    valueOfReclassifiedAssets: 3700128,
    depreciableAssets: 11,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "43200",
    propertyType: "Multifamily",
    totalCosts: "5288028",
    valueOfReclassifiedAssets: 4844485,
    depreciableAssets: 9,
  },
  {
    lat: 33.6364503,
    lng: -112.0118669,
    squareFootage: "313842",
    propertyType: "Multifamily",
    totalCosts: "7158468",
    valueOfReclassifiedAssets: 1655640,
    depreciableAssets: 33,
  },
  {
    lat: 33.6364503,
    lng: -112.0118669,
    squareFootage: "313842",
    propertyType: "Multifamily",
    totalCosts: "21709054",
    valueOfReclassifiedAssets: 5278508,
    depreciableAssets: 33,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "73132",
    propertyType: "Industrial",
    totalCosts: "10447473",
    valueOfReclassifiedAssets: 9324021,
    depreciableAssets: 11,
  },
  {
    lat: 40.7851185,
    lng: -73.7271909,
    squareFootage: "25695",
    propertyType: "Office",
    totalCosts: "5320000",
    valueOfReclassifiedAssets: 4419809,
    depreciableAssets: 20,
  },
  {
    lat: 41.0214474,
    lng: -73.8124984,
    squareFootage: "46562",
    propertyType: "Office",
    totalCosts: "1554270",
    valueOfReclassifiedAssets: 274444,
    depreciableAssets: 21,
  },
  {
    lat: 41.0214474,
    lng: -73.8124984,
    squareFootage: "46562",
    propertyType: "Office",
    totalCosts: "5800000",
    valueOfReclassifiedAssets: 1024131,
    depreciableAssets: 21,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3363888",
    valueOfReclassifiedAssets: 2338827,
    depreciableAssets: 35,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "60919",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20875000",
    valueOfReclassifiedAssets: 4092254,
    depreciableAssets: 23,
  },
  {
    lat: 40.8144014,
    lng: -73.4772682,
    squareFootage: "309960",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "67750000",
    valueOfReclassifiedAssets: 8345482,
    depreciableAssets: 15,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "120284",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 3619312,
    depreciableAssets: 14,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "174788",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "25600000",
    valueOfReclassifiedAssets: 20597139,
    depreciableAssets: 22,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "235848",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "110000000",
    valueOfReclassifiedAssets: 17120087,
    depreciableAssets: 19,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "93408",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "28180000",
    valueOfReclassifiedAssets: 3849706,
    depreciableAssets: 16,
  },
  {
    lat: 40.7612957,
    lng: -73.7330753,
    squareFootage: "36225",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 423142,
    depreciableAssets: 16,
  },
  {
    lat: 32.7269669,
    lng: -117.1647094,
    squareFootage: "122640",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1148891,
    depreciableAssets: 16,
  },
  {
    lat: 21.3866667,
    lng: -158.0091667,
    squareFootage: "66591",
    propertyType: "Multifamily",
    totalCosts: "5600000",
    valueOfReclassifiedAssets: 5087747,
    depreciableAssets: 11,
  },
  {
    lat: 33.1467885,
    lng: -117.0087877,
    squareFootage: "61800",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2747192,
    depreciableAssets: 36,
  },
  {
    lat: 33.1467885,
    lng: -117.0087877,
    squareFootage: "61800",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2406038,
    depreciableAssets: 36,
  },
  {
    lat: 38.1052454,
    lng: -122.2483726,
    squareFootage: "154000",
    propertyType: "Multifamily",
    totalCosts: "21150000",
    valueOfReclassifiedAssets: 5340013,
    depreciableAssets: 30,
  },
  {
    lat: 42.3654231,
    lng: -71.05497679999999,
    squareFootage: "3450",
    propertyType: "Multifamily",
    totalCosts: "2268111",
    valueOfReclassifiedAssets: 305804,
    depreciableAssets: 15,
  },
  {
    lat: 41.573119,
    lng: -87.6926257,
    squareFootage: "30205",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5062043",
    valueOfReclassifiedAssets: 1113085,
    depreciableAssets: 24,
  },
  {
    lat: 42.0361546,
    lng: -88.314212,
    squareFootage: "39602",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5111852",
    valueOfReclassifiedAssets: 1618460,
    depreciableAssets: 30,
  },
  {
    lat: 41.1213147,
    lng: -87.913867,
    squareFootage: "35359",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5861428",
    valueOfReclassifiedAssets: 2497028,
    depreciableAssets: 30,
  },
  {
    lat: 42.08057429999999,
    lng: -87.7320368,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4573800",
    valueOfReclassifiedAssets: 1101743,
    depreciableAssets: 26,
  },
  {
    lat: 41.9013087,
    lng: -87.68276759999999,
    squareFootage: "43400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4965000",
    valueOfReclassifiedAssets: 984561,
    depreciableAssets: 22,
  },
  {
    lat: 37.2258011,
    lng: -88.6916315,
    squareFootage: "31200",
    propertyType: "Multifamily",
    totalCosts: "4375000",
    valueOfReclassifiedAssets: 978891,
    depreciableAssets: 24,
  },
  {
    lat: 42.08057429999999,
    lng: -87.7320368,
    squareFootage: "34472",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4573800",
    valueOfReclassifiedAssets: 1405162,
    depreciableAssets: 26,
  },
  {
    lat: 45.68944949999999,
    lng: -122.5829013,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 2053316,
    depreciableAssets: 56,
  },
  {
    lat: 34.0083231,
    lng: -118.3491169,
    squareFootage: "6506",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 132933,
    depreciableAssets: 24,
  },
  {
    lat: 34.0083231,
    lng: -118.3491169,
    squareFootage: "8686",
    propertyType: "Multifamily",
    totalCosts: "1745000",
    valueOfReclassifiedAssets: 431781,
    depreciableAssets: 29,
  },
  {
    lat: 34.0083231,
    lng: -118.3491169,
    squareFootage: "17680",
    propertyType: "Multifamily",
    totalCosts: "1625000",
    valueOfReclassifiedAssets: 405154,
    depreciableAssets: 30,
  },
  {
    lat: 34.0083231,
    lng: -118.3491169,
    squareFootage: "18754",
    propertyType: "Multifamily",
    totalCosts: "1825000",
    valueOfReclassifiedAssets: 440334,
    depreciableAssets: 28,
  },
  {
    lat: 35.080412,
    lng: -80.8875861,
    squareFootage: "214920",
    propertyType: "Multifamily",
    totalCosts: "28000000",
    valueOfReclassifiedAssets: 9337958,
    depreciableAssets: 39,
  },
  {
    lat: 35.890231,
    lng: -78.9175088,
    squareFootage: "230647",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 6115465,
    depreciableAssets: 33,
  },
  {
    lat: 35.7850545,
    lng: -78.68194489999999,
    squareFootage: "165492",
    propertyType: "Multifamily",
    totalCosts: "25600000",
    valueOfReclassifiedAssets: 9426854,
    depreciableAssets: 43,
  },
  {
    lat: 32.2107257,
    lng: -110.8850949,
    squareFootage: "166300",
    propertyType: "Multifamily",
    totalCosts: "13869616",
    valueOfReclassifiedAssets: 3905930,
    depreciableAssets: 33,
  },
  {
    lat: 32.1772542,
    lng: -110.7956878,
    squareFootage: "42026",
    propertyType: "Multifamily",
    totalCosts: "5111159",
    valueOfReclassifiedAssets: 1609267,
    depreciableAssets: 37,
  },
  {
    lat: 33.4306545,
    lng: -86.7919009,
    squareFootage: "251370",
    propertyType: "Multifamily",
    totalCosts: "17750000",
    valueOfReclassifiedAssets: 3650126,
    depreciableAssets: 24,
  },
  {
    lat: 33.4165524,
    lng: -86.681253,
    squareFootage: "249300",
    propertyType: "Multifamily",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 5767156,
    depreciableAssets: 29,
  },
  {
    lat: 35.7698044,
    lng: -78.78162189999999,
    squareFootage: "164376",
    propertyType: "Multifamily",
    totalCosts: "20750000",
    valueOfReclassifiedAssets: 6356213,
    depreciableAssets: 36,
  },
  {
    lat: 32.2107257,
    lng: -110.8850949,
    squareFootage: "200560",
    propertyType: "Multifamily",
    totalCosts: "15461212",
    valueOfReclassifiedAssets: 4337591,
    depreciableAssets: 33,
  },
  {
    lat: 32.1922127,
    lng: -110.9923904,
    squareFootage: "170980",
    propertyType: "Multifamily",
    totalCosts: "11558014",
    valueOfReclassifiedAssets: 2996277,
    depreciableAssets: 31,
  },
  {
    lat: 36.1051434,
    lng: -79.2676668,
    squareFootage: "232522",
    propertyType: "Multifamily",
    totalCosts: "17500000",
    valueOfReclassifiedAssets: 3994473,
    depreciableAssets: 26,
  },
  {
    lat: 35.1105564,
    lng: -80.7103532,
    squareFootage: "215032",
    propertyType: "Multifamily",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 6598098,
    depreciableAssets: 25,
  },
  {
    lat: 35.2263714,
    lng: -80.7990185,
    squareFootage: "304290",
    propertyType: "Multifamily",
    totalCosts: "17500000",
    valueOfReclassifiedAssets: 4690790,
    depreciableAssets: 32,
  },
  {
    lat: 36.4722803,
    lng: -87.3612205,
    squareFootage: "26764",
    propertyType: "Retail",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1060048,
    depreciableAssets: 29,
  },
  {
    lat: 40.1059233,
    lng: -88.2120315,
    squareFootage: "99000",
    propertyType: "Multifamily",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 1901349,
    depreciableAssets: 34,
  },
  {
    lat: 40.7505132,
    lng: -89.56391190000001,
    squareFootage: "28408",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5808383",
    valueOfReclassifiedAssets: 1239024,
    depreciableAssets: 37,
  },
  {
    lat: 41.3336504,
    lng: -88.6916315,
    squareFootage: "29030",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4356288",
    valueOfReclassifiedAssets: 869190,
    depreciableAssets: 33,
  },
  {
    lat: 41.39982089999999,
    lng: -89.436391,
    squareFootage: "27600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7490009",
    valueOfReclassifiedAssets: 1641558,
    depreciableAssets: 37,
  },
  {
    lat: 41.3336504,
    lng: -88.6916315,
    squareFootage: "29030",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4377278",
    valueOfReclassifiedAssets: 869190,
    depreciableAssets: 33,
  },
  {
    lat: 40.7505132,
    lng: -89.56391190000001,
    squareFootage: "28408",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5836371",
    valueOfReclassifiedAssets: 1239024,
    depreciableAssets: 36,
  },
  {
    lat: 41.39982089999999,
    lng: -89.436391,
    squareFootage: "27600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7454092",
    valueOfReclassifiedAssets: 1641558,
    depreciableAssets: 37,
  },
  {
    lat: 40.613095,
    lng: -89.4553088,
    squareFootage: "44187",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9390220",
    valueOfReclassifiedAssets: 1954907,
    depreciableAssets: 34,
  },
  {
    lat: 40.1059233,
    lng: -88.2120315,
    squareFootage: "99000",
    propertyType: "Multifamily",
    totalCosts: "5869046",
    valueOfReclassifiedAssets: 1890812,
    depreciableAssets: 34,
  },
  {
    lat: 40.613095,
    lng: -89.4553088,
    squareFootage: "30215",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3581836",
    valueOfReclassifiedAssets: 683353,
    depreciableAssets: 36,
  },
  {
    lat: 39.82640809999999,
    lng: -89.6157473,
    squareFootage: "62000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17909182",
    valueOfReclassifiedAssets: 3107178,
    depreciableAssets: 26,
  },
  {
    lat: 40.613095,
    lng: -89.4553088,
    squareFootage: "44187",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9435466",
    valueOfReclassifiedAssets: 1954907,
    depreciableAssets: 34,
  },
  {
    lat: 40.613095,
    lng: -89.4553088,
    squareFootage: "30215",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3599095",
    valueOfReclassifiedAssets: 683353,
    depreciableAssets: 35,
  },
  {
    lat: 35.5005999,
    lng: -86.761749,
    squareFootage: "874714",
    propertyType: "Industrial",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 551380,
    depreciableAssets: 15,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "708746",
    valueOfReclassifiedAssets: 553491,
    depreciableAssets: 0,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "95005",
    propertyType: "Office",
    totalCosts: "12942028",
    valueOfReclassifiedAssets: 12230566,
    depreciableAssets: 0,
  },
  {
    lat: 40.0532987,
    lng: -75.23040379999999,
    squareFootage: "38000",
    propertyType: "Office",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 1050546,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "47930",
    propertyType: "Multifamily",
    totalCosts: "7787000",
    valueOfReclassifiedAssets: 1206770,
    depreciableAssets: 17,
  },
  {
    lat: 40.0532987,
    lng: -75.23040379999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3459522",
    valueOfReclassifiedAssets: 2560820,
    depreciableAssets: 28,
  },
  {
    lat: 40.8053731,
    lng: -74.0241367,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5853917",
    valueOfReclassifiedAssets: 3879295,
    depreciableAssets: 37,
  },
  {
    lat: 41.6906085,
    lng: -81.3179364,
    squareFootage: "448508",
    propertyType: "Industrial",
    totalCosts: "6855363",
    valueOfReclassifiedAssets: 6535761,
    depreciableAssets: 9,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "54300",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 1284573,
    depreciableAssets: 14,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "53500",
    propertyType: "Multifamily",
    totalCosts: "1320000",
    valueOfReclassifiedAssets: 1129512,
    depreciableAssets: 14,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "52360",
    propertyType: "Multifamily",
    totalCosts: "1280000",
    valueOfReclassifiedAssets: 1096811,
    depreciableAssets: 14,
  },
  {
    lat: 40.6208604,
    lng: -74.511172,
    squareFootage: "105293",
    propertyType: "Office",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1766394,
    depreciableAssets: 18,
  },
  {
    lat: 40.8550327,
    lng: -74.8507131,
    squareFootage: "75502",
    propertyType: "Office",
    totalCosts: "2120974",
    valueOfReclassifiedAssets: 297692,
    depreciableAssets: 16,
  },
  {
    lat: 40.8550327,
    lng: -74.8507131,
    squareFootage: "55940",
    propertyType: "Office",
    totalCosts: "4807542",
    valueOfReclassifiedAssets: 764921,
    depreciableAssets: 18,
  },
  {
    lat: 40.5230137,
    lng: -74.3411515,
    squareFootage: "117899",
    propertyType: "Industrial",
    totalCosts: "6103065",
    valueOfReclassifiedAssets: 589711,
    depreciableAssets: 12,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "15000",
    propertyType: "Office",
    totalCosts: "7350000",
    valueOfReclassifiedAssets: 171943,
    depreciableAssets: 2,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "7200",
    propertyType: "Retail",
    totalCosts: "8750000",
    valueOfReclassifiedAssets: 590128,
    depreciableAssets: 8,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "11705",
    propertyType: "Multifamily",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 318237,
    depreciableAssets: 11,
  },
  {
    lat: 33.8540061,
    lng: -118.2585096,
    squareFootage: "34141",
    propertyType: "Office",
    totalCosts: "2437298",
    valueOfReclassifiedAssets: 521518,
    depreciableAssets: 26,
  },
  {
    lat: 39.1511679,
    lng: -76.6239423,
    squareFootage: "82802",
    propertyType: "Industrial",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 322745,
    depreciableAssets: 18,
  },
  {
    lat: 39.3877502,
    lng: -76.488118,
    squareFootage: "134492",
    propertyType: "Office",
    totalCosts: "7564813",
    valueOfReclassifiedAssets: 1078461,
    depreciableAssets: 17,
  },
  {
    lat: 40.5749261,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "664288",
    valueOfReclassifiedAssets: 441053,
    depreciableAssets: 0,
  },
  {
    lat: 40.0508979,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2504689",
    valueOfReclassifiedAssets: 610738,
    depreciableAssets: 23,
  },
  {
    lat: 40.0508979,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2604899",
    valueOfReclassifiedAssets: 2311041,
    depreciableAssets: 23,
  },
  {
    lat: 40.0508979,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2714294",
    valueOfReclassifiedAssets: 2300517,
    depreciableAssets: 27,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "5940",
    propertyType: "Multifamily",
    totalCosts: "3583326",
    valueOfReclassifiedAssets: 554545,
    depreciableAssets: 18,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "5940",
    propertyType: "Multifamily",
    totalCosts: "3583326",
    valueOfReclassifiedAssets: 554545,
    depreciableAssets: 18,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "5940",
    propertyType: "Multifamily",
    totalCosts: "3583326",
    valueOfReclassifiedAssets: 554545,
    depreciableAssets: 18,
  },
  {
    lat: 40.7383636,
    lng: -74.197388,
    squareFootage: "11116",
    propertyType: "Multifamily",
    totalCosts: "830000",
    valueOfReclassifiedAssets: 95601,
    depreciableAssets: 14,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5165664",
    valueOfReclassifiedAssets: 3977906,
    depreciableAssets: 0,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1239885",
    valueOfReclassifiedAssets: 693122,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "222630",
    propertyType: "Retail",
    totalCosts: "16923000",
    valueOfReclassifiedAssets: 2909855,
    depreciableAssets: 21,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "99238",
    propertyType: "Retail",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1878192,
    depreciableAssets: 34,
  },
  {
    lat: 28.8887495,
    lng: -81.2408689,
    squareFootage: "80567",
    propertyType: "Retail",
    totalCosts: "5464159",
    valueOfReclassifiedAssets: 1250021,
    depreciableAssets: 34,
  },
  {
    lat: 35.890231,
    lng: -78.9175088,
    squareFootage: "85602",
    propertyType: "Retail",
    totalCosts: "10655166",
    valueOfReclassifiedAssets: 2192568,
    depreciableAssets: 27,
  },
  {
    lat: 41.68389380000001,
    lng: -81.22985299999999,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "2230000",
    valueOfReclassifiedAssets: 1895500,
    depreciableAssets: 100,
  },
  {
    lat: 39.9029533,
    lng: -86.0223611,
    squareFootage: "12900",
    propertyType: "Retail",
    totalCosts: "6271358",
    valueOfReclassifiedAssets: 5330654,
    depreciableAssets: 100,
  },
  {
    lat: 35.2258075,
    lng: -89.9159785,
    squareFootage: "13225",
    propertyType: "Retail",
    totalCosts: "4175485",
    valueOfReclassifiedAssets: 3549162,
    depreciableAssets: 100,
  },
  {
    lat: 42.8683658,
    lng: -85.63320929999999,
    squareFootage: "10862",
    propertyType: "Retail",
    totalCosts: "1460000",
    valueOfReclassifiedAssets: 1241000,
    depreciableAssets: 100,
  },
  {
    lat: 28.7571267,
    lng: -81.3509416,
    squareFootage: "107400",
    propertyType: "Retail",
    totalCosts: "9400000",
    valueOfReclassifiedAssets: 2663040,
    depreciableAssets: 33,
  },
  {
    lat: 33.8922687,
    lng: -118.3520389,
    squareFootage: "15050",
    propertyType: "Retail",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 4250000,
    depreciableAssets: 100,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "9975",
    propertyType: "Multifamily",
    totalCosts: "691709",
    valueOfReclassifiedAssets: 507866,
    depreciableAssets: 35,
  },
  {
    lat: 44.8320996,
    lng: -92.91759549999999,
    squareFootage: "111984",
    propertyType: "Retail",
    totalCosts: "12700000",
    valueOfReclassifiedAssets: 1554384,
    depreciableAssets: 15,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "63926",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1364379,
    depreciableAssets: 32,
  },
  {
    lat: 46.54777139999999,
    lng: -87.39562409999999,
    squareFootage: "252444",
    propertyType: "Retail",
    totalCosts: "10495000",
    valueOfReclassifiedAssets: 1630478,
    depreciableAssets: 18,
  },
  {
    lat: 39.8667532,
    lng: -75.05235340000002,
    squareFootage: "102552",
    propertyType: "Retail",
    totalCosts: "5575000",
    valueOfReclassifiedAssets: 621827,
    depreciableAssets: 14,
  },
  {
    lat: 30.5547139,
    lng: -84.0801613,
    squareFootage: "104778",
    propertyType: "Retail",
    totalCosts: "5175000",
    valueOfReclassifiedAssets: 5171384,
    depreciableAssets: 0,
  },
  {
    lat: 40.7699016,
    lng: -73.8272029,
    squareFootage: "101640",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "54567000",
    valueOfReclassifiedAssets: 8961697,
    depreciableAssets: 21,
  },
  {
    lat: 40.66761049999999,
    lng: -73.4121277,
    squareFootage: "118000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "57698750",
    valueOfReclassifiedAssets: 45190568,
    depreciableAssets: 22,
  },
  {
    lat: 40.7699016,
    lng: -73.8272029,
    squareFootage: "101640",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "46921500",
    valueOfReclassifiedAssets: 8669310,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "855943",
    valueOfReclassifiedAssets: 773852,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1104493",
    valueOfReclassifiedAssets: 938935,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1105643",
    valueOfReclassifiedAssets: 939912,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "855943",
    valueOfReclassifiedAssets: 773852,
    depreciableAssets: 0,
  },
  {
    lat: 40.7250632,
    lng: -73.9976946,
    squareFootage: "5474",
    propertyType: "Multifamily",
    totalCosts: "8050000",
    valueOfReclassifiedAssets: 932485,
    depreciableAssets: 14,
  },
  {
    lat: 37.5464259,
    lng: -77.46446069999999,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 2483241,
    depreciableAssets: 19,
  },
  {
    lat: 34.1058378,
    lng: -80.92077370000001,
    squareFootage: "132246",
    propertyType: "Multifamily",
    totalCosts: "4965300",
    valueOfReclassifiedAssets: 1538753,
    depreciableAssets: 36,
  },
  {
    lat: 38.7250374,
    lng: -90.554149,
    squareFootage: "214430",
    propertyType: "Multifamily",
    totalCosts: "14750700",
    valueOfReclassifiedAssets: 4250894,
    depreciableAssets: 34,
  },
  {
    lat: 39.220735,
    lng: -94.5727598,
    squareFootage: "68410",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 421050,
    depreciableAssets: 19,
  },
  {
    lat: 36.1155274,
    lng: -79.7512033,
    squareFootage: "98792",
    propertyType: "Multifamily",
    totalCosts: "3392100",
    valueOfReclassifiedAssets: 792307,
    depreciableAssets: 27,
  },
  {
    lat: 39.220735,
    lng: -94.5727598,
    squareFootage: "118760",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 839703,
    depreciableAssets: 21,
  },
  {
    lat: 39.220735,
    lng: -94.5727598,
    squareFootage: "90810",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 728468,
    depreciableAssets: 20,
  },
  {
    lat: 36.0175027,
    lng: -79.8632834,
    squareFootage: "119860",
    propertyType: "Multifamily",
    totalCosts: "3320000",
    valueOfReclassifiedAssets: 910223,
    depreciableAssets: 32,
  },
  {
    lat: 39.2182916,
    lng: -94.6347041,
    squareFootage: "167910",
    propertyType: "Multifamily",
    totalCosts: "8250300",
    valueOfReclassifiedAssets: 2063730,
    depreciableAssets: 30,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "132744",
    propertyType: "Multifamily",
    totalCosts: "4545000",
    valueOfReclassifiedAssets: 1452893,
    depreciableAssets: 38,
  },
  {
    lat: 36.0175027,
    lng: -79.8632834,
    squareFootage: "125460",
    propertyType: "Multifamily",
    totalCosts: "3255000",
    valueOfReclassifiedAssets: 852468,
    depreciableAssets: 31,
  },
  {
    lat: 36.0175027,
    lng: -79.8632834,
    squareFootage: "126110",
    propertyType: "Multifamily",
    totalCosts: "3425098",
    valueOfReclassifiedAssets: 980480,
    depreciableAssets: 33,
  },
  {
    lat: 34.2232129,
    lng: -79.6277523,
    squareFootage: "40000",
    propertyType: "Multifamily",
    totalCosts: "5670000",
    valueOfReclassifiedAssets: 1983277,
    depreciableAssets: 41,
  },
  {
    lat: 35.1633874,
    lng: -101.900579,
    squareFootage: "186750",
    propertyType: "Multifamily",
    totalCosts: "5550300",
    valueOfReclassifiedAssets: 1517871,
    depreciableAssets: 31,
  },
  {
    lat: 36.1046869,
    lng: -79.8128649,
    squareFootage: "118824",
    propertyType: "Multifamily",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 585831,
    depreciableAssets: 26,
  },
  {
    lat: 35.1633874,
    lng: -101.900579,
    squareFootage: "113200",
    propertyType: "Multifamily",
    totalCosts: "3800700",
    valueOfReclassifiedAssets: 1217407,
    depreciableAssets: 36,
  },
  {
    lat: 39.9029533,
    lng: -86.0223611,
    squareFootage: "456900",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 2429212,
    depreciableAssets: 31,
  },
  {
    lat: 29.1987957,
    lng: -81.04234290000001,
    squareFootage: "63949",
    propertyType: "Specialty",
    totalCosts: "9802555",
    valueOfReclassifiedAssets: 2249603,
    depreciableAssets: 28,
  },
  {
    lat: 42.9297842,
    lng: -74.1474846,
    squareFootage: "81444",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1450958,
    depreciableAssets: 21,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9636258",
    valueOfReclassifiedAssets: 7894728,
    depreciableAssets: 21,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "21688",
    propertyType: "Multifamily",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 255731,
    depreciableAssets: 18,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "34172",
    propertyType: "Multifamily",
    totalCosts: "1574267",
    valueOfReclassifiedAssets: 1398358,
    depreciableAssets: 13,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2155841",
    valueOfReclassifiedAssets: 1117127,
    depreciableAssets: 49,
  },
  {
    lat: 39.9021937,
    lng: -74.9968543,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1053650",
    valueOfReclassifiedAssets: 779370,
    depreciableAssets: 27,
  },
  {
    lat: 40.30220569999999,
    lng: -79.53786339999999,
    squareFootage: "82889",
    propertyType: "Office",
    totalCosts: "13750000",
    valueOfReclassifiedAssets: 1470129,
    depreciableAssets: 13,
  },
  {
    lat: 46.5926188,
    lng: -120.5363432,
    squareFootage: "80792",
    propertyType: "Office",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1023680,
    depreciableAssets: 17,
  },
  {
    lat: 37.8643754,
    lng: -81.986206,
    squareFootage: "12000",
    propertyType: "Office",
    totalCosts: "2950000",
    valueOfReclassifiedAssets: 483324,
    depreciableAssets: 19,
  },
  {
    lat: 33.7994803,
    lng: -84.1909841,
    squareFootage: "112000",
    propertyType: "Office",
    totalCosts: "18050000",
    valueOfReclassifiedAssets: 4668845,
    depreciableAssets: 29,
  },
  {
    lat: 36.0765176,
    lng: -115.1533426,
    squareFootage: "117591",
    propertyType: "Office",
    totalCosts: "35163788",
    valueOfReclassifiedAssets: 6646064,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "117591",
    propertyType: "Office",
    totalCosts: "33500000",
    valueOfReclassifiedAssets: 5631371,
    depreciableAssets: 19,
  },
  {
    lat: 41.2164036,
    lng: -75.9142437,
    squareFootage: "6921",
    propertyType: "Office",
    totalCosts: "2190000",
    valueOfReclassifiedAssets: 530396,
    depreciableAssets: 28,
  },
  {
    lat: 34.2253022,
    lng: -77.8710285,
    squareFootage: "52539",
    propertyType: "Office",
    totalCosts: "9927347",
    valueOfReclassifiedAssets: 2080251,
    depreciableAssets: 25,
  },
  {
    lat: 35.9073934,
    lng: -84.0061661,
    squareFootage: "82616",
    propertyType: "Specialty",
    totalCosts: "20442858",
    valueOfReclassifiedAssets: 1884985,
    depreciableAssets: 11,
  },
  {
    lat: 31.8078487,
    lng: -106.4081983,
    squareFootage: "15293",
    propertyType: "Office",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 511915,
    depreciableAssets: 20,
  },
  {
    lat: 32.4800392,
    lng: -87.0424393,
    squareFootage: "14684",
    propertyType: "Office",
    totalCosts: "2075000",
    valueOfReclassifiedAssets: 542475,
    depreciableAssets: 27,
  },
  {
    lat: 35.1338614,
    lng: -106.6409198,
    squareFootage: "18355",
    propertyType: "Office",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 1050652,
    depreciableAssets: 31,
  },
  {
    lat: 30.2293526,
    lng: -93.2131232,
    squareFootage: "61338",
    propertyType: "Office",
    totalCosts: "8855238",
    valueOfReclassifiedAssets: 1276247,
    depreciableAssets: 17,
  },
  {
    lat: 44.74786659999999,
    lng: -73.44167209999999,
    squareFootage: "64607",
    propertyType: "Industrial",
    totalCosts: "4396750",
    valueOfReclassifiedAssets: 1427140,
    depreciableAssets: 36,
  },
  {
    lat: 40.811639,
    lng: -75.737149,
    squareFootage: "7341",
    propertyType: "Office",
    totalCosts: "1985000",
    valueOfReclassifiedAssets: 453159,
    depreciableAssets: 27,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "55725",
    propertyType: "Multifamily",
    totalCosts: "25000000",
    valueOfReclassifiedAssets: 3277911,
    depreciableAssets: 15,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "6040",
    propertyType: "Multifamily",
    totalCosts: "2225000",
    valueOfReclassifiedAssets: 344386,
    depreciableAssets: 18,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "6040",
    propertyType: "Multifamily",
    totalCosts: "1675000",
    valueOfReclassifiedAssets: 242109,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "9865",
    propertyType: "Multifamily",
    totalCosts: "5925000",
    valueOfReclassifiedAssets: 1069855,
    depreciableAssets: 21,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "17615",
    propertyType: "Multifamily",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 985720,
    depreciableAssets: 16,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "9865",
    propertyType: "Multifamily",
    totalCosts: "5375000",
    valueOfReclassifiedAssets: 970058,
    depreciableAssets: 21,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "20985",
    propertyType: "Multifamily",
    totalCosts: "10625000",
    valueOfReclassifiedAssets: 1486178,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "20785",
    propertyType: "Multifamily",
    totalCosts: "10825000",
    valueOfReclassifiedAssets: 1543443,
    depreciableAssets: 17,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "5325000",
    valueOfReclassifiedAssets: 1255913,
    depreciableAssets: 27,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "6592",
    propertyType: "Multifamily",
    totalCosts: "3425000",
    valueOfReclassifiedAssets: 677018,
    depreciableAssets: 23,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "17123",
    propertyType: "Multifamily",
    totalCosts: "8725000",
    valueOfReclassifiedAssets: 1470000,
    depreciableAssets: 19,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "21750",
    propertyType: "Multifamily",
    totalCosts: "10250000",
    valueOfReclassifiedAssets: 1883310,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "20243",
    propertyType: "Multifamily",
    totalCosts: "12125000",
    valueOfReclassifiedAssets: 1679649,
    depreciableAssets: 17,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "22332",
    propertyType: "Multifamily",
    totalCosts: "9325000",
    valueOfReclassifiedAssets: 1300889,
    depreciableAssets: 16,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "10850000",
    valueOfReclassifiedAssets: 1771303,
    depreciableAssets: 19,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "7142",
    propertyType: "Multifamily",
    totalCosts: "3450000",
    valueOfReclassifiedAssets: 641652,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "6040",
    propertyType: "Multifamily",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 378446,
    depreciableAssets: 18,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "16523",
    propertyType: "Multifamily",
    totalCosts: "9250000",
    valueOfReclassifiedAssets: 1739735,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "15330",
    propertyType: "Multifamily",
    totalCosts: "8825000",
    valueOfReclassifiedAssets: 1682458,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "20243",
    propertyType: "Multifamily",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1597516,
    depreciableAssets: 17,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "5050000",
    valueOfReclassifiedAssets: 1116830,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "21620",
    propertyType: "Multifamily",
    totalCosts: "8925000",
    valueOfReclassifiedAssets: 1232078,
    depreciableAssets: 16,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "24170",
    propertyType: "Multifamily",
    totalCosts: "14275000",
    valueOfReclassifiedAssets: 1892309,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "19224",
    propertyType: "Multifamily",
    totalCosts: "10875000",
    valueOfReclassifiedAssets: 1388570,
    depreciableAssets: 15,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "15246",
    propertyType: "Multifamily",
    totalCosts: "5925000",
    valueOfReclassifiedAssets: 911573,
    depreciableAssets: 18,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 630668,
    depreciableAssets: 23,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "10684",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 885628,
    depreciableAssets: 23,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "14546",
    propertyType: "Multifamily",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 1198663,
    depreciableAssets: 20,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "24170",
    propertyType: "Multifamily",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 1981518,
    depreciableAssets: 16,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "20910",
    propertyType: "Multifamily",
    totalCosts: "7850000",
    valueOfReclassifiedAssets: 1100721,
    depreciableAssets: 17,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "15417",
    propertyType: "Multifamily",
    totalCosts: "8550000",
    valueOfReclassifiedAssets: 1548078,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "11384",
    propertyType: "Multifamily",
    totalCosts: "5525000",
    valueOfReclassifiedAssets: 869848,
    depreciableAssets: 19,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "26341",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 1733379,
    depreciableAssets: 17,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "9975",
    propertyType: "Multifamily",
    totalCosts: "5175000",
    valueOfReclassifiedAssets: 952609,
    depreciableAssets: 21,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1160697,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "12770",
    propertyType: "Multifamily",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 983095,
    depreciableAssets: 20,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "17156",
    propertyType: "Multifamily",
    totalCosts: "9150000",
    valueOfReclassifiedAssets: 1521143,
    depreciableAssets: 19,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "8975",
    propertyType: "Multifamily",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 754140,
    depreciableAssets: 15,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "21192",
    propertyType: "Multifamily",
    totalCosts: "9575000",
    valueOfReclassifiedAssets: 1606177,
    depreciableAssets: 19,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "21024",
    propertyType: "Multifamily",
    totalCosts: "11050000",
    valueOfReclassifiedAssets: 2016057,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "17178",
    propertyType: "Multifamily",
    totalCosts: "6850000",
    valueOfReclassifiedAssets: 970106,
    depreciableAssets: 17,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "8140",
    propertyType: "Multifamily",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 746179,
    depreciableAssets: 20,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "15660",
    propertyType: "Multifamily",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 1273644,
    depreciableAssets: 17,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "6040",
    propertyType: "Multifamily",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 359966,
    depreciableAssets: 19,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "15588",
    propertyType: "Multifamily",
    totalCosts: "7525000",
    valueOfReclassifiedAssets: 1074607,
    depreciableAssets: 17,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "25059",
    propertyType: "Hotel",
    totalCosts: "21600000",
    valueOfReclassifiedAssets: 3322861,
    depreciableAssets: 21,
  },
  {
    lat: 36.6313237,
    lng: -79.4028639,
    squareFootage: "202578",
    propertyType: "Retail",
    totalCosts: "22050000",
    valueOfReclassifiedAssets: 5321355,
    depreciableAssets: 28,
  },
  {
    lat: 41.328758,
    lng: -75.8824422,
    squareFootage: "226894",
    propertyType: "Retail",
    totalCosts: "19050000",
    valueOfReclassifiedAssets: 4540629,
    depreciableAssets: 28,
  },
  {
    lat: 33.4551831,
    lng: -81.7568253,
    squareFootage: "28900",
    propertyType: "Office",
    totalCosts: "2750510",
    valueOfReclassifiedAssets: 605198,
    depreciableAssets: 28,
  },
  {
    lat: 41.434595,
    lng: -81.7458845,
    squareFootage: "58642",
    propertyType: "Office",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 787415,
    depreciableAssets: 24,
  },
  {
    lat: 38.7492726,
    lng: -77.57340479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12139333",
    valueOfReclassifiedAssets: 2716893,
    depreciableAssets: 30,
  },
  {
    lat: 38.7492726,
    lng: -77.57340479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7595057",
    valueOfReclassifiedAssets: 2367297,
    depreciableAssets: 37,
  },
  {
    lat: 41.7470143,
    lng: -87.6679765,
    squareFootage: "1180400",
    propertyType: "Multifamily",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 3501174,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3604000",
    valueOfReclassifiedAssets: 2416048,
    depreciableAssets: 0,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "100000",
    propertyType: "Industrial",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 409748,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14938220",
    valueOfReclassifiedAssets: 10176811,
    depreciableAssets: 34,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "19130",
    propertyType: "Multifamily",
    totalCosts: "10463811",
    valueOfReclassifiedAssets: 1034792,
    depreciableAssets: 11,
  },
  {
    lat: 40.8476284,
    lng: -73.8360251,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6879113",
    valueOfReclassifiedAssets: 5262890,
    depreciableAssets: 27,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "450441",
    propertyType: "Multifamily",
    totalCosts: "32192037",
    valueOfReclassifiedAssets: 4674277,
    depreciableAssets: 16,
  },
  {
    lat: 40.8262186,
    lng: -73.8242621,
    squareFootage: "15175",
    propertyType: "Multifamily",
    totalCosts: "4086652",
    valueOfReclassifiedAssets: 461287,
    depreciableAssets: 12,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "65048",
    propertyType: "Multifamily",
    totalCosts: "10608167",
    valueOfReclassifiedAssets: 796122,
    depreciableAssets: 8,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "36465",
    propertyType: "Multifamily",
    totalCosts: "21780575",
    valueOfReclassifiedAssets: 2322974,
    depreciableAssets: 12,
  },
  {
    lat: 39.0041504,
    lng: -77.03917539999999,
    squareFootage: "48488",
    propertyType: "Specialty",
    totalCosts: "9641235",
    valueOfReclassifiedAssets: 8588484,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "107000",
    propertyType: "Office",
    totalCosts: "8600000",
    valueOfReclassifiedAssets: 1963750,
    depreciableAssets: 27,
  },
  {
    lat: 41.0887754,
    lng: -74.1827119,
    squareFootage: "38027",
    propertyType: "Retail",
    totalCosts: "5127922",
    valueOfReclassifiedAssets: 4324733,
    depreciableAssets: 26,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "14100",
    propertyType: "Office",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 1000488,
    depreciableAssets: 37,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "55000",
    propertyType: "Office",
    totalCosts: "8159385",
    valueOfReclassifiedAssets: 1461954,
    depreciableAssets: 20,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "55000",
    propertyType: "Office",
    totalCosts: "8159385",
    valueOfReclassifiedAssets: 1461954,
    depreciableAssets: 20,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "94846",
    propertyType: "Industrial",
    totalCosts: "22600000",
    valueOfReclassifiedAssets: 3560133,
    depreciableAssets: 18,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "6083",
    propertyType: "Multifamily",
    totalCosts: "1102075",
    valueOfReclassifiedAssets: 141553,
    depreciableAssets: 15,
  },
  {
    lat: 40.7036716,
    lng: -74.6458987,
    squareFootage: "67500",
    propertyType: "Office",
    totalCosts: "4692911",
    valueOfReclassifiedAssets: 961195,
    depreciableAssets: 23,
  },
  {
    lat: 40.744052,
    lng: -74.0270745,
    squareFootage: "11600",
    propertyType: "Multifamily",
    totalCosts: "1894919",
    valueOfReclassifiedAssets: 218851,
    depreciableAssets: 13,
  },
  {
    lat: 41.1091091,
    lng: -74.0828839,
    squareFootage: "58250",
    propertyType: "Industrial",
    totalCosts: "13620884",
    valueOfReclassifiedAssets: 12090996,
    depreciableAssets: 19,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "19600",
    propertyType: "Multifamily",
    totalCosts: "18745689",
    valueOfReclassifiedAssets: 2491192,
    depreciableAssets: 16,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "193375",
    propertyType: "Office",
    totalCosts: "30750000",
    valueOfReclassifiedAssets: 6192587,
    depreciableAssets: 20,
  },
  {
    lat: 40.6867556,
    lng: -74.5580454,
    squareFootage: "51000",
    propertyType: "Office",
    totalCosts: "5037830",
    valueOfReclassifiedAssets: 1031877,
    depreciableAssets: 23,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "9175",
    propertyType: "Multifamily",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 818239,
    depreciableAssets: 10,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "3700",
    propertyType: "Retail",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 5928838,
    depreciableAssets: 0,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "4800",
    propertyType: "Office",
    totalCosts: "1540000",
    valueOfReclassifiedAssets: 1274424,
    depreciableAssets: 18,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "12500",
    propertyType: "Office",
    totalCosts: "2065000",
    valueOfReclassifiedAssets: 1853453,
    depreciableAssets: 0,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "21316",
    propertyType: "Office",
    totalCosts: "13140000",
    valueOfReclassifiedAssets: 11496606,
    depreciableAssets: 0,
  },
  {
    lat: 40.9383349,
    lng: -73.83308439999999,
    squareFootage: "45101",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23912175",
    valueOfReclassifiedAssets: 14925000,
    depreciableAssets: 38,
  },
  {
    lat: 40.9383349,
    lng: -73.83308439999999,
    squareFootage: "45101",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23715000",
    valueOfReclassifiedAssets: 7534795,
    depreciableAssets: 38,
  },
  {
    lat: 40.9383349,
    lng: -73.83308439999999,
    squareFootage: "45101",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23812175",
    valueOfReclassifiedAssets: 14925000,
    depreciableAssets: 38,
  },
  {
    lat: 40.8196071,
    lng: -74.95594799999999,
    squareFootage: "94403",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15600000",
    valueOfReclassifiedAssets: 5178030,
    depreciableAssets: 37,
  },
  {
    lat: 35.0772572,
    lng: -89.84581999999999,
    squareFootage: "15400",
    propertyType: "Retail",
    totalCosts: "2149068",
    valueOfReclassifiedAssets: 584871,
    depreciableAssets: 34,
  },
  {
    lat: 32.9464268,
    lng: -97.0713572,
    squareFootage: "133520",
    propertyType: "Office",
    totalCosts: "12290393",
    valueOfReclassifiedAssets: 2393400,
    depreciableAssets: 24,
  },
  {
    lat: 45.05278269999999,
    lng: -93.3811952,
    squareFootage: "17400",
    propertyType: "Retail",
    totalCosts: "2622404",
    valueOfReclassifiedAssets: 851687,
    depreciableAssets: 40,
  },
  {
    lat: 35.1433749,
    lng: -89.9673469,
    squareFootage: "135409",
    propertyType: "Office",
    totalCosts: "9593690",
    valueOfReclassifiedAssets: 1684767,
    depreciableAssets: 21,
  },
  {
    lat: 35.1323787,
    lng: -90.004663,
    squareFootage: "63310",
    propertyType: "Retail",
    totalCosts: "5262896",
    valueOfReclassifiedAssets: 1177314,
    depreciableAssets: 28,
  },
  {
    lat: 35.1323787,
    lng: -90.004663,
    squareFootage: "63310",
    propertyType: "Retail",
    totalCosts: "5262896",
    valueOfReclassifiedAssets: 1177314,
    depreciableAssets: 28,
  },
  {
    lat: 35.0772572,
    lng: -89.84581999999999,
    squareFootage: "134593",
    propertyType: "Office",
    totalCosts: "10433065",
    valueOfReclassifiedAssets: 1628423,
    depreciableAssets: 18,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "35400",
    propertyType: "Industrial",
    totalCosts: "13675369",
    valueOfReclassifiedAssets: 1957893,
    depreciableAssets: 18,
  },
  {
    lat: 33.93975229999999,
    lng: -84.21734029999999,
    squareFootage: "84031",
    propertyType: "Retail",
    totalCosts: "6993445",
    valueOfReclassifiedAssets: 1119923,
    depreciableAssets: 20,
  },
  {
    lat: 29.7503969,
    lng: -95.4935119,
    squareFootage: "181400",
    propertyType: "Office",
    totalCosts: "23585970",
    valueOfReclassifiedAssets: 3397961,
    depreciableAssets: 18,
  },
  {
    lat: 35.1816448,
    lng: -89.7661527,
    squareFootage: "136457",
    propertyType: "Retail",
    totalCosts: "10083889",
    valueOfReclassifiedAssets: 2714303,
    depreciableAssets: 34,
  },
  {
    lat: 35.9657059,
    lng: -78.8042828,
    squareFootage: "2015",
    propertyType: "Multifamily",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 1516483,
    depreciableAssets: 31,
  },
  {
    lat: 35.7178049,
    lng: -78.9175088,
    squareFootage: "61148",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 1022445,
    depreciableAssets: 36,
  },
  {
    lat: 40.5377063,
    lng: -74.8507131,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "238750",
    valueOfReclassifiedAssets: 76104,
    depreciableAssets: 0,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "465225",
    valueOfReclassifiedAssets: 212916,
    depreciableAssets: 0,
  },
  {
    lat: 40.0207099,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "853024",
    valueOfReclassifiedAssets: 137356,
    depreciableAssets: 92,
  },
  {
    lat: 39.0476107,
    lng: -77.44093989999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1112367",
    valueOfReclassifiedAssets: 400499,
    depreciableAssets: 0,
  },
  {
    lat: 40.6517521,
    lng: -74.21499779999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1431636",
    valueOfReclassifiedAssets: 722353,
    depreciableAssets: 56,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2677593",
    valueOfReclassifiedAssets: 1405669,
    depreciableAssets: 0,
  },
  {
    lat: 39.7891108,
    lng: -75.0552739,
    squareFootage: "226460",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "37070000",
    valueOfReclassifiedAssets: 6479636,
    depreciableAssets: 19,
  },
  {
    lat: 39.0476107,
    lng: -77.44093989999999,
    squareFootage: "143156",
    propertyType: "Office",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 2399151,
    depreciableAssets: 19,
  },
  {
    lat: 40.2912966,
    lng: -74.72200169999999,
    squareFootage: "53194",
    propertyType: "Retail",
    totalCosts: "3009109",
    valueOfReclassifiedAssets: 2490367,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13998329",
    valueOfReclassifiedAssets: 10798113,
    depreciableAssets: 31,
  },
  {
    lat: 48.3465618,
    lng: -102.9789899,
    squareFootage: "95949",
    propertyType: "Multifamily",
    totalCosts: "13217513",
    valueOfReclassifiedAssets: 3405251,
    depreciableAssets: 27,
  },
  {
    lat: 48.2931122,
    lng: -103.7750537,
    squareFootage: "147141",
    propertyType: "Multifamily",
    totalCosts: "24526790",
    valueOfReclassifiedAssets: 5329503,
    depreciableAssets: 24,
  },
  {
    lat: 47.7633248,
    lng: -103.1392835,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7037188",
    valueOfReclassifiedAssets: 5518797,
    depreciableAssets: 31,
  },
  {
    lat: 41.629789,
    lng: -94.0491077,
    squareFootage: "14748",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 520068,
    depreciableAssets: 37,
  },
  {
    lat: 35.45897739999999,
    lng: -97.4056928,
    squareFootage: "44464",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4980375",
    valueOfReclassifiedAssets: 1296380,
    depreciableAssets: 30,
  },
  {
    lat: 35.4213103,
    lng: -97.57347829999999,
    squareFootage: "92222",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6269625",
    valueOfReclassifiedAssets: 1698315,
    depreciableAssets: 32,
  },
  {
    lat: 41.5346558,
    lng: -93.61164939999999,
    squareFootage: "25412",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12650000",
    valueOfReclassifiedAssets: 2471909,
    depreciableAssets: 22,
  },
  {
    lat: 33.6301328,
    lng: -117.8721676,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2740744",
    valueOfReclassifiedAssets: 1627467,
    depreciableAssets: 54,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "5850",
    propertyType: "Office",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 388475,
    depreciableAssets: 15,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "6900",
    propertyType: "Retail",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 219680,
    depreciableAssets: 9,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "1505",
    propertyType: "Specialty",
    totalCosts: "1567000",
    valueOfReclassifiedAssets: 504466,
    depreciableAssets: 38,
  },
  {
    lat: 40.7836826,
    lng: -73.4298073,
    squareFootage: "2368",
    propertyType: "Office",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 437206,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 1955000,
    depreciableAssets: 100,
  },
  {
    lat: 40.6303421,
    lng: -74.3997976,
    squareFootage: "2800",
    propertyType: "Retail",
    totalCosts: "2516667",
    valueOfReclassifiedAssets: 902534,
    depreciableAssets: 42,
  },
  {
    lat: 40.7989068,
    lng: -74.1885825,
    squareFootage: "8700",
    propertyType: "Retail",
    totalCosts: "3450000",
    valueOfReclassifiedAssets: 1078701,
    depreciableAssets: 37,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "4960",
    propertyType: "Retail",
    totalCosts: "1625000",
    valueOfReclassifiedAssets: 300668,
    depreciableAssets: 22,
  },
  {
    lat: 40.7284018,
    lng: -73.7874983,
    squareFootage: "12518",
    propertyType: "Office",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 1016254,
    depreciableAssets: 21,
  },
  {
    lat: 39.009186,
    lng: -94.25897379999999,
    squareFootage: "125500",
    propertyType: "Multifamily",
    totalCosts: "7360000",
    valueOfReclassifiedAssets: 6003381,
    depreciableAssets: 27,
  },
  {
    lat: 39.0608919,
    lng: -94.4079213,
    squareFootage: "223463",
    propertyType: "Multifamily",
    totalCosts: "7101584",
    valueOfReclassifiedAssets: 5540922,
    depreciableAssets: 28,
  },
  {
    lat: 43.03149560000001,
    lng: -85.58179109999999,
    squareFootage: "190820",
    propertyType: "Multifamily",
    totalCosts: "9850000",
    valueOfReclassifiedAssets: 7349352,
    depreciableAssets: 31,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "10300",
    propertyType: "Specialty",
    totalCosts: "1189700",
    valueOfReclassifiedAssets: 858299,
    depreciableAssets: 0,
  },
  {
    lat: 41.0998327,
    lng: -80.75469799999999,
    squareFootage: "15000",
    propertyType: "Industrial",
    totalCosts: "468730",
    valueOfReclassifiedAssets: 69262,
    depreciableAssets: 18,
  },
  {
    lat: 40.8133905,
    lng: -79.8520818,
    squareFootage: "18000",
    propertyType: "Industrial",
    totalCosts: "526125",
    valueOfReclassifiedAssets: 163591,
    depreciableAssets: 32,
  },
  {
    lat: 41.1071733,
    lng: -80.5993854,
    squareFootage: "18000",
    propertyType: "Industrial",
    totalCosts: "573955",
    valueOfReclassifiedAssets: 108156,
    depreciableAssets: 22,
  },
  {
    lat: 36.3699231,
    lng: -94.30515419999999,
    squareFootage: "19800",
    propertyType: "Industrial",
    totalCosts: "688746",
    valueOfReclassifiedAssets: 167367,
    depreciableAssets: 31,
  },
  {
    lat: 40.98128270000001,
    lng: -80.2878794,
    squareFootage: "24000",
    propertyType: "Industrial",
    totalCosts: "717400",
    valueOfReclassifiedAssets: 200615,
    depreciableAssets: 33,
  },
  {
    lat: 41.1407956,
    lng: -74.5580454,
    squareFootage: "22800",
    propertyType: "Industrial",
    totalCosts: "6851681",
    valueOfReclassifiedAssets: 1873880,
    depreciableAssets: 33,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "64780",
    propertyType: "Industrial",
    totalCosts: "6994900",
    valueOfReclassifiedAssets: 1800321,
    depreciableAssets: 30,
  },
  {
    lat: 42.2129167,
    lng: -83.7305392,
    squareFootage: "76244",
    propertyType: "Industrial",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 148778,
    depreciableAssets: 26,
  },
  {
    lat: 42.24758629999999,
    lng: -83.8260884,
    squareFootage: "57316",
    propertyType: "Industrial",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 238936,
    depreciableAssets: 26,
  },
  {
    lat: 45.5266975,
    lng: -122.6880503,
    squareFootage: "23128",
    propertyType: "Industrial",
    totalCosts: "5300000",
    valueOfReclassifiedAssets: 3797349,
    depreciableAssets: 44,
  },
  {
    lat: 30.8866166,
    lng: -86.21584969999999,
    squareFootage: "44000",
    propertyType: "Retail",
    totalCosts: "5880000",
    valueOfReclassifiedAssets: 1576302,
    depreciableAssets: 30,
  },
  {
    lat: 30.5927334,
    lng: -81.8224362,
    squareFootage: "105439",
    propertyType: "Retail",
    totalCosts: "5350000",
    valueOfReclassifiedAssets: 1674199,
    depreciableAssets: 37,
  },
  {
    lat: 28.4750405,
    lng: -81.2849169,
    squareFootage: "56000",
    propertyType: "Retail",
    totalCosts: "5316599",
    valueOfReclassifiedAssets: 4119711,
    depreciableAssets: 35,
  },
  {
    lat: 40.851833,
    lng: -74.0211989,
    squareFootage: "127000",
    propertyType: "Office",
    totalCosts: "8069190",
    valueOfReclassifiedAssets: 396801,
    depreciableAssets: 6,
  },
  {
    lat: 47.3165043,
    lng: -122.3223975,
    squareFootage: "105150",
    propertyType: "Industrial",
    totalCosts: "16350000",
    valueOfReclassifiedAssets: 2038643,
    depreciableAssets: 14,
  },
  {
    lat: 41.8804637,
    lng: -70.645473,
    squareFootage: "119598",
    propertyType: "Retail",
    totalCosts: "1461812",
    valueOfReclassifiedAssets: 1351867,
    depreciableAssets: 48,
  },
  {
    lat: 41.8804637,
    lng: -70.645473,
    squareFootage: "119598",
    propertyType: "Retail",
    totalCosts: "1270344",
    valueOfReclassifiedAssets: 1174800,
    depreciableAssets: 48,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "147336",
    propertyType: "Office",
    totalCosts: "8885052",
    valueOfReclassifiedAssets: 7365003,
    depreciableAssets: 23,
  },
  {
    lat: 42.36606159999999,
    lng: -71.0482911,
    squareFootage: "264462",
    propertyType: "Industrial",
    totalCosts: "170000000",
    valueOfReclassifiedAssets: 9748404,
    depreciableAssets: 7,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "403429",
    propertyType: "Office",
    totalCosts: "50049094",
    valueOfReclassifiedAssets: 7449714,
    depreciableAssets: 17,
  },
  {
    lat: 30.4270699,
    lng: -97.8014482,
    squareFootage: "196410",
    propertyType: "Office",
    totalCosts: "28500000",
    valueOfReclassifiedAssets: 3585477,
    depreciableAssets: 14,
  },
  {
    lat: 30.4270699,
    lng: -97.8014482,
    squareFootage: "213612",
    propertyType: "Office",
    totalCosts: "48000000",
    valueOfReclassifiedAssets: 4767448,
    depreciableAssets: 0,
  },
  {
    lat: 40.1627412,
    lng: -80.254435,
    squareFootage: "1051641",
    propertyType: "Office",
    totalCosts: "202402494",
    valueOfReclassifiedAssets: 30253188,
    depreciableAssets: 16,
  },
  {
    lat: 39.554861,
    lng: -76.9873477,
    squareFootage: "151125",
    propertyType: "Industrial",
    totalCosts: "8750000",
    valueOfReclassifiedAssets: 1775124,
    depreciableAssets: 24,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 213216,
    depreciableAssets: 22,
  },
  {
    lat: 41.0133808,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15438320",
    valueOfReclassifiedAssets: 12983181,
    depreciableAssets: 29,
  },
  {
    lat: 41.0133808,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4106772",
    valueOfReclassifiedAssets: 3356890,
    depreciableAssets: 32,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "245517",
    propertyType: "Multifamily",
    totalCosts: "12382200",
    valueOfReclassifiedAssets: 3530738,
    depreciableAssets: 34,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "245517",
    propertyType: "Multifamily",
    totalCosts: "12360000",
    valueOfReclassifiedAssets: 3524408,
    depreciableAssets: 34,
  },
  {
    lat: 28.0473767,
    lng: -82.3611066,
    squareFootage: "40720",
    propertyType: "Multifamily",
    totalCosts: "27629828",
    valueOfReclassifiedAssets: 7813428,
    depreciableAssets: 30,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "49000",
    propertyType: "Multifamily",
    totalCosts: "8250000",
    valueOfReclassifiedAssets: 1003002,
    depreciableAssets: 13,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "20000",
    propertyType: "Industrial",
    totalCosts: "3104000",
    valueOfReclassifiedAssets: 131364,
    depreciableAssets: 5,
  },
  {
    lat: 40.6007956,
    lng: -73.9065883,
    squareFootage: "6600",
    propertyType: "Industrial",
    totalCosts: "1729000",
    valueOfReclassifiedAssets: 191971,
    depreciableAssets: 11,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1272800",
    valueOfReclassifiedAssets: 772848,
    depreciableAssets: 0,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "156500",
    propertyType: "Specialty",
    totalCosts: "4501000",
    valueOfReclassifiedAssets: 533676,
    depreciableAssets: 13,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "16900",
    propertyType: "Office",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 529417,
    depreciableAssets: 13,
  },
  {
    lat: 39.6268957,
    lng: -77.7337628,
    squareFootage: "55339",
    propertyType: "Retail",
    totalCosts: "4780672",
    valueOfReclassifiedAssets: 1376487,
    depreciableAssets: 33,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "31760",
    propertyType: "Industrial",
    totalCosts: "8102935",
    valueOfReclassifiedAssets: 314762,
    depreciableAssets: 5,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "14664",
    propertyType: "Multifamily",
    totalCosts: "6340417",
    valueOfReclassifiedAssets: 487252,
    depreciableAssets: 10,
  },
  {
    lat: 40.6749343,
    lng: -73.8713099,
    squareFootage: "60180",
    propertyType: "Office",
    totalCosts: "19015805",
    valueOfReclassifiedAssets: 1893916,
    depreciableAssets: 11,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "10038",
    propertyType: "Office",
    totalCosts: "7238858",
    valueOfReclassifiedAssets: 385921,
    depreciableAssets: 6,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "8975",
    propertyType: "Multifamily",
    totalCosts: "8176004",
    valueOfReclassifiedAssets: 922358,
    depreciableAssets: 14,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "8600",
    propertyType: "Multifamily",
    totalCosts: "6148134",
    valueOfReclassifiedAssets: 696129,
    depreciableAssets: 14,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "86360",
    propertyType: "Multifamily",
    totalCosts: "31642865",
    valueOfReclassifiedAssets: 3014337,
    depreciableAssets: 11,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "40890",
    propertyType: "Multifamily",
    totalCosts: "10929631",
    valueOfReclassifiedAssets: 1025601,
    depreciableAssets: 11,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "2018",
    propertyType: "Multifamily",
    totalCosts: "44621114",
    valueOfReclassifiedAssets: 5809975,
    depreciableAssets: 14,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "116142",
    propertyType: "Multifamily",
    totalCosts: "21928077",
    valueOfReclassifiedAssets: 2120340,
    depreciableAssets: 11,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "27267",
    propertyType: "Multifamily",
    totalCosts: "8555219",
    valueOfReclassifiedAssets: 899081,
    depreciableAssets: 11,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "75432",
    propertyType: "Multifamily",
    totalCosts: "38250000",
    valueOfReclassifiedAssets: 4780607,
    depreciableAssets: 14,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "25144",
    propertyType: "Multifamily",
    totalCosts: "13323157",
    valueOfReclassifiedAssets: 1642089,
    depreciableAssets: 15,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "155536",
    propertyType: "Multifamily",
    totalCosts: "20440535",
    valueOfReclassifiedAssets: 4890646,
    depreciableAssets: 30,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "21248",
    propertyType: "Multifamily",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 197586,
    depreciableAssets: 8,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "15436",
    propertyType: "Multifamily",
    totalCosts: "2220000",
    valueOfReclassifiedAssets: 197436,
    depreciableAssets: 11,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "16255",
    propertyType: "Office",
    totalCosts: "4250000",
    valueOfReclassifiedAssets: 3612500,
    depreciableAssets: 100,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12735484",
    valueOfReclassifiedAssets: 11786886,
    depreciableAssets: 8,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "17012",
    propertyType: "Office",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 3400000,
    depreciableAssets: 100,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "7880",
    propertyType: "Specialty",
    totalCosts: "10232768",
    valueOfReclassifiedAssets: 5216823,
    depreciableAssets: 55,
  },
  {
    lat: 41.1796788,
    lng: -80.75469799999999,
    squareFootage: "28668",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1689058",
    valueOfReclassifiedAssets: 663928,
    depreciableAssets: 43,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "78676",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "47621732",
    valueOfReclassifiedAssets: 13388795,
    depreciableAssets: 30,
  },
  {
    lat: 40.6966538,
    lng: -73.83308439999999,
    squareFootage: "33350",
    propertyType: "Retail",
    totalCosts: "5545500",
    valueOfReclassifiedAssets: 1248022,
    depreciableAssets: 24,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "162482",
    propertyType: "Multifamily",
    totalCosts: "15800000",
    valueOfReclassifiedAssets: 3769975,
    depreciableAssets: 28,
  },
  {
    lat: 34.0390107,
    lng: -118.2672801,
    squareFootage: "18132",
    propertyType: "Specialty",
    totalCosts: "1806875",
    valueOfReclassifiedAssets: 1293932,
    depreciableAssets: 30,
  },
  {
    lat: 34.0895186,
    lng: -118.3315838,
    squareFootage: "15273",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 265720,
    depreciableAssets: 30,
  },
  {
    lat: 34.050751,
    lng: -118.3841777,
    squareFootage: "6227",
    propertyType: "Multifamily",
    totalCosts: "1060047",
    valueOfReclassifiedAssets: 187314,
    depreciableAssets: 30,
  },
  {
    lat: 34.050751,
    lng: -118.3841777,
    squareFootage: "7247",
    propertyType: "Specialty",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 943602,
    depreciableAssets: 30,
  },
  {
    lat: 34.0578814,
    lng: -118.3096648,
    squareFootage: "36925",
    propertyType: "Multifamily",
    totalCosts: "1296186",
    valueOfReclassifiedAssets: 939812,
    depreciableAssets: 30,
  },
  {
    lat: 34.0578814,
    lng: -118.3096648,
    squareFootage: "36925",
    propertyType: "Multifamily",
    totalCosts: "821324",
    valueOfReclassifiedAssets: 595509,
    depreciableAssets: 30,
  },
  {
    lat: 34.0324632,
    lng: -118.395863,
    squareFootage: "22470",
    propertyType: "Multifamily",
    totalCosts: "7310000",
    valueOfReclassifiedAssets: 1202507,
    depreciableAssets: 21,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "185422",
    propertyType: "Multifamily",
    totalCosts: "13519725",
    valueOfReclassifiedAssets: 3222256,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "185422",
    propertyType: "Multifamily",
    totalCosts: "13519725",
    valueOfReclassifiedAssets: 3222256,
    depreciableAssets: 28,
  },
  {
    lat: 34.0578814,
    lng: -118.3096648,
    squareFootage: "36925",
    propertyType: "Multifamily",
    totalCosts: "8376000",
    valueOfReclassifiedAssets: 1571730,
    depreciableAssets: 30,
  },
  {
    lat: 34.050751,
    lng: -118.3841777,
    squareFootage: "7236",
    propertyType: "Office",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 742605,
    depreciableAssets: 21,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "101195",
    propertyType: "Multifamily",
    totalCosts: "3650000",
    valueOfReclassifiedAssets: 536907,
    depreciableAssets: 31,
  },
  {
    lat: 34.0655627,
    lng: -118.3096648,
    squareFootage: "15930",
    propertyType: "Multifamily",
    totalCosts: "1181327",
    valueOfReclassifiedAssets: 843057,
    depreciableAssets: 35,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "70220",
    propertyType: "Multifamily",
    totalCosts: "6917957",
    valueOfReclassifiedAssets: 681168,
    depreciableAssets: 11,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3420805",
    valueOfReclassifiedAssets: 3026362,
    depreciableAssets: 29,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14511318",
    valueOfReclassifiedAssets: 11208209,
    depreciableAssets: 29,
  },
  {
    lat: 38.7999723,
    lng: -77.0506896,
    squareFootage: "19028",
    propertyType: "Office",
    totalCosts: "4850000",
    valueOfReclassifiedAssets: 313006,
    depreciableAssets: 8,
  },
  {
    lat: 42.1753663,
    lng: -72.5737187,
    squareFootage: "23165",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 995707,
    depreciableAssets: 30,
  },
  {
    lat: 42.3488239,
    lng: -83.025396,
    squareFootage: "21105",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 784601,
    depreciableAssets: 23,
  },
  {
    lat: 42.1753663,
    lng: -72.5737187,
    squareFootage: "23165",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 931050,
    depreciableAssets: 30,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "44652",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 2064330,
    depreciableAssets: 25,
  },
  {
    lat: 42.1318779,
    lng: -72.7570024,
    squareFootage: "18426",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 1396331,
    depreciableAssets: 36,
  },
  {
    lat: 42.1318779,
    lng: -72.7570024,
    squareFootage: "18426",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4230000",
    valueOfReclassifiedAssets: 1284017,
    depreciableAssets: 36,
  },
  {
    lat: 41.5399755,
    lng: -72.9934468,
    squareFootage: "61085",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6535000",
    valueOfReclassifiedAssets: 1404294,
    depreciableAssets: 23,
  },
  {
    lat: 41.5399755,
    lng: -72.9934468,
    squareFootage: "61085",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1166848,
    depreciableAssets: 23,
  },
  {
    lat: 37.7726402,
    lng: -122.4099154,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "411971",
    valueOfReclassifiedAssets: 386454,
    depreciableAssets: 0,
  },
  {
    lat: 37.7726402,
    lng: -122.4099154,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "516615",
    valueOfReclassifiedAssets: 463395,
    depreciableAssets: 93,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2229056",
    valueOfReclassifiedAssets: 945983,
    depreciableAssets: 0,
  },
  {
    lat: 34.1980902,
    lng: -79.8520818,
    squareFootage: "110575",
    propertyType: "Retail",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 1301655,
    depreciableAssets: 18,
  },
  {
    lat: 32.3029712,
    lng: -88.59517509999999,
    squareFootage: "206597",
    propertyType: "Retail",
    totalCosts: "25728000",
    valueOfReclassifiedAssets: 6995939,
    depreciableAssets: 32,
  },
  {
    lat: 31.98581129999999,
    lng: -81.08650349999999,
    squareFootage: "193150",
    propertyType: "Retail",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 4918222,
    depreciableAssets: 24,
  },
  {
    lat: 34.7974004,
    lng: -79.17742679999999,
    squareFootage: "27400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4267138",
    valueOfReclassifiedAssets: 1277788,
    depreciableAssets: 31,
  },
  {
    lat: 35.6544684,
    lng: -78.5887951,
    squareFootage: "38150",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4070863",
    valueOfReclassifiedAssets: 1024028,
    depreciableAssets: 31,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "810771",
    valueOfReclassifiedAssets: 687953,
    depreciableAssets: 57,
  },
  {
    lat: 35.970208,
    lng: -88.4210006,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "838395",
    valueOfReclassifiedAssets: 815868,
    depreciableAssets: 26,
  },
  {
    lat: 35.140547,
    lng: -88.57585739999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "755833",
    valueOfReclassifiedAssets: 733330,
    depreciableAssets: 25,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "910781",
    valueOfReclassifiedAssets: 661149,
    depreciableAssets: 35,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "753051",
    valueOfReclassifiedAssets: 644971,
    depreciableAssets: 46,
  },
  {
    lat: 35.8449956,
    lng: -88.9222277,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "803526",
    valueOfReclassifiedAssets: 744531,
    depreciableAssets: 27,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3490145",
    valueOfReclassifiedAssets: 3204546,
    depreciableAssets: 26,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1246575",
    valueOfReclassifiedAssets: 898223,
    depreciableAssets: 34,
  },
  {
    lat: 35.5821649,
    lng: -88.8455037,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1156535",
    valueOfReclassifiedAssets: 975337,
    depreciableAssets: 29,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2408076",
    valueOfReclassifiedAssets: 2027301,
    depreciableAssets: 35,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2306678",
    valueOfReclassifiedAssets: 2192355,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3484644",
    valueOfReclassifiedAssets: 3039381,
    depreciableAssets: 25,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "103689",
    valueOfReclassifiedAssets: 78812,
    depreciableAssets: 0,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "559927",
    valueOfReclassifiedAssets: 490749,
    depreciableAssets: 24,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "681828",
    valueOfReclassifiedAssets: 609129,
    depreciableAssets: 31,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "201423",
    valueOfReclassifiedAssets: 137258,
    depreciableAssets: 0,
  },
  {
    lat: 36.1731365,
    lng: -94.0491077,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1451070",
    valueOfReclassifiedAssets: 1298676,
    depreciableAssets: 28,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1888551",
    valueOfReclassifiedAssets: 1672071,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "991388",
    valueOfReclassifiedAssets: 863980,
    depreciableAssets: 33,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2238075",
    valueOfReclassifiedAssets: 437365,
    depreciableAssets: 29,
  },
  {
    lat: 42.20217299999999,
    lng: -73.3472901,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2765449",
    valueOfReclassifiedAssets: 2264375,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "19014",
    propertyType: "Hotel",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 1090179,
    depreciableAssets: 34,
  },
  {
    lat: 42.46345030000001,
    lng: -73.3001233,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15274336",
    valueOfReclassifiedAssets: 5007968,
    depreciableAssets: 36,
  },
  {
    lat: 38.91469720000001,
    lng: -77.3898602,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2073941",
    valueOfReclassifiedAssets: 1871066,
    depreciableAssets: 22,
  },
  {
    lat: 38.6805859,
    lng: -77.3209555,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4603832",
    valueOfReclassifiedAssets: 4443700,
    depreciableAssets: 46,
  },
  {
    lat: 32.9559018,
    lng: -96.6958939,
    squareFootage: "73929",
    propertyType: "Office",
    totalCosts: "8016152",
    valueOfReclassifiedAssets: 1447848,
    depreciableAssets: 21,
  },
  {
    lat: 30.2205397,
    lng: -81.5537192,
    squareFootage: "344000",
    propertyType: "Office",
    totalCosts: "22320517",
    valueOfReclassifiedAssets: 5404830,
    depreciableAssets: 29,
  },
  {
    lat: 28.65391649999999,
    lng: -81.54867279999999,
    squareFootage: "69022",
    propertyType: "Retail",
    totalCosts: "4071903",
    valueOfReclassifiedAssets: 3658011,
    depreciableAssets: 29,
  },
  {
    lat: 35.5587725,
    lng: -97.6385368,
    squareFootage: "125394",
    propertyType: "Retail",
    totalCosts: "9990568",
    valueOfReclassifiedAssets: 2611226,
    depreciableAssets: 31,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "50472",
    propertyType: "Industrial",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 429460,
    depreciableAssets: 16,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "50472",
    propertyType: "Industrial",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 753968,
    depreciableAssets: 16,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "605187",
    valueOfReclassifiedAssets: 199940,
    depreciableAssets: 36,
  },
  {
    lat: 31.0691358,
    lng: -97.6656625,
    squareFootage: "31900",
    propertyType: "Industrial",
    totalCosts: "1175000",
    valueOfReclassifiedAssets: 526222,
    depreciableAssets: 50,
  },
  {
    lat: 37.0517234,
    lng: -76.3347838,
    squareFootage: "123050",
    propertyType: "Multifamily",
    totalCosts: "5903811",
    valueOfReclassifiedAssets: 1441806,
    depreciableAssets: 29,
  },
  {
    lat: 39.554861,
    lng: -76.9873477,
    squareFootage: "96279",
    propertyType: "Multifamily",
    totalCosts: "11787750",
    valueOfReclassifiedAssets: 2858302,
    depreciableAssets: 28,
  },
  {
    lat: 37.0517234,
    lng: -76.3347838,
    squareFootage: "239876",
    propertyType: "Multifamily",
    totalCosts: "18155660",
    valueOfReclassifiedAssets: 3239974,
    depreciableAssets: 21,
  },
  {
    lat: 36.903547,
    lng: -76.37530459999999,
    squareFootage: "96260",
    propertyType: "Multifamily",
    totalCosts: "4079260",
    valueOfReclassifiedAssets: 993390,
    depreciableAssets: 29,
  },
  {
    lat: 36.903547,
    lng: -76.37530459999999,
    squareFootage: "96260",
    propertyType: "Multifamily",
    totalCosts: "2067117",
    valueOfReclassifiedAssets: 503389,
    depreciableAssets: 29,
  },
  {
    lat: 37.2062002,
    lng: -76.5777203,
    squareFootage: "109120",
    propertyType: "Multifamily",
    totalCosts: "10725000",
    valueOfReclassifiedAssets: 2841258,
    depreciableAssets: 32,
  },
  {
    lat: 39.10436869999999,
    lng: -76.8836261,
    squareFootage: "201704",
    propertyType: "Multifamily",
    totalCosts: "29036676",
    valueOfReclassifiedAssets: 5646633,
    depreciableAssets: 24,
  },
  {
    lat: 36.903547,
    lng: -76.37530459999999,
    squareFootage: "96260",
    propertyType: "Multifamily",
    totalCosts: "259222",
    valueOfReclassifiedAssets: 63126,
    depreciableAssets: 29,
  },
  {
    lat: 42.5899487,
    lng: -71.4536835,
    squareFootage: "179148",
    propertyType: "Multifamily",
    totalCosts: "11638667",
    valueOfReclassifiedAssets: 3293280,
    depreciableAssets: 33,
  },
  {
    lat: 38.95903810000001,
    lng: -76.9124465,
    squareFootage: "101285",
    propertyType: "Multifamily",
    totalCosts: "7652279",
    valueOfReclassifiedAssets: 1060090,
    depreciableAssets: 16,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "158710",
    propertyType: "Multifamily",
    totalCosts: "12677035",
    valueOfReclassifiedAssets: 3090089,
    depreciableAssets: 29,
  },
  {
    lat: 38.853725,
    lng: -76.88939069999999,
    squareFootage: "70000",
    propertyType: "Multifamily",
    totalCosts: "4228985",
    valueOfReclassifiedAssets: 712168,
    depreciableAssets: 20,
  },
  {
    lat: 36.849734,
    lng: -76.097201,
    squareFootage: "180000",
    propertyType: "Multifamily",
    totalCosts: "17423801",
    valueOfReclassifiedAssets: 4322517,
    depreciableAssets: 29,
  },
  {
    lat: 39.3729916,
    lng: -77.4587239,
    squareFootage: "57873",
    propertyType: "Multifamily",
    totalCosts: "8173951",
    valueOfReclassifiedAssets: 2198790,
    depreciableAssets: 33,
  },
  {
    lat: 39.9412882,
    lng: -75.21873459999999,
    squareFootage: "52980",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3911722",
    valueOfReclassifiedAssets: 830184,
    depreciableAssets: 24,
  },
  {
    lat: 26.17785,
    lng: -80.2711588,
    squareFootage: "3114",
    propertyType: "Specialty",
    totalCosts: "3050000",
    valueOfReclassifiedAssets: 876148,
    depreciableAssets: 38,
  },
  {
    lat: 26.7619563,
    lng: -80.1037721,
    squareFootage: "49024",
    propertyType: "Industrial",
    totalCosts: "2418789",
    valueOfReclassifiedAssets: 382903,
    depreciableAssets: 18,
  },
  {
    lat: 25.6085924,
    lng: -80.3380213,
    squareFootage: "53020",
    propertyType: "Industrial",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 786626,
    depreciableAssets: 27,
  },
  {
    lat: 26.17785,
    lng: -80.2711588,
    squareFootage: "33687",
    propertyType: "Retail",
    totalCosts: "3527398",
    valueOfReclassifiedAssets: 1191626,
    depreciableAssets: 40,
  },
  {
    lat: 26.2153627,
    lng: -80.2209773,
    squareFootage: "0",
    propertyType: "Industrial",
    totalCosts: "8232700",
    valueOfReclassifiedAssets: 1353980,
    depreciableAssets: 19,
  },
  {
    lat: 25.6085924,
    lng: -80.3380213,
    squareFootage: "57000",
    propertyType: "Industrial",
    totalCosts: "3703162",
    valueOfReclassifiedAssets: 778322,
    depreciableAssets: 24,
  },
  {
    lat: 26.0197012,
    lng: -80.1819268,
    squareFootage: "57815",
    propertyType: "Office",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1127014,
    depreciableAssets: 29,
  },
  {
    lat: 25.5232393,
    lng: -80.3769999,
    squareFootage: "29321",
    propertyType: "Industrial",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 588237,
    depreciableAssets: 31,
  },
  {
    lat: 25.5232393,
    lng: -80.3769999,
    squareFootage: "36000",
    propertyType: "Industrial",
    totalCosts: "2798905",
    valueOfReclassifiedAssets: 369917,
    depreciableAssets: 16,
  },
  {
    lat: 26.1865098,
    lng: -80.22655449999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3213085",
    valueOfReclassifiedAssets: 831352,
    depreciableAssets: 30,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "26206",
    propertyType: "Office",
    totalCosts: "474215",
    valueOfReclassifiedAssets: 278247,
    depreciableAssets: 0,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "26206",
    propertyType: "Office",
    totalCosts: "306845",
    valueOfReclassifiedAssets: 180042,
    depreciableAssets: 0,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "26206",
    propertyType: "Office",
    totalCosts: "83685",
    valueOfReclassifiedAssets: 49102,
    depreciableAssets: 0,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "26206",
    propertyType: "Office",
    totalCosts: "566971",
    valueOfReclassifiedAssets: 332672,
    depreciableAssets: 0,
  },
  {
    lat: 40.6433507,
    lng: -73.7889689,
    squareFootage: "233210",
    propertyType: "Hotel",
    totalCosts: "60050000",
    valueOfReclassifiedAssets: 6742770,
    depreciableAssets: 13,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "26206",
    propertyType: "Office",
    totalCosts: "1115800",
    valueOfReclassifiedAssets: 654698,
    depreciableAssets: 0,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "234552",
    propertyType: "Multifamily",
    totalCosts: "4300238",
    valueOfReclassifiedAssets: 3100235,
    depreciableAssets: 36,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "234552",
    propertyType: "Multifamily",
    totalCosts: "3089713",
    valueOfReclassifiedAssets: 2227513,
    depreciableAssets: 36,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "31228249",
    valueOfReclassifiedAssets: 17376455,
    depreciableAssets: 0,
  },
  {
    lat: 33.7946333,
    lng: -84.44877199999999,
    squareFootage: "6700",
    propertyType: "Retail",
    totalCosts: "790000",
    valueOfReclassifiedAssets: 192639,
    depreciableAssets: 30,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "9014",
    propertyType: "Retail",
    totalCosts: "856065",
    valueOfReclassifiedAssets: 183131,
    depreciableAssets: 25,
  },
  {
    lat: 29.647247,
    lng: -82.4156829,
    squareFootage: "6480",
    propertyType: "Industrial",
    totalCosts: "355000",
    valueOfReclassifiedAssets: 55197,
    depreciableAssets: 20,
  },
  {
    lat: 39.2963369,
    lng: -76.62105389999999,
    squareFootage: "370700",
    propertyType: "Office",
    totalCosts: "68200000",
    valueOfReclassifiedAssets: 2394279,
    depreciableAssets: 0,
  },
  {
    lat: 42.3421605,
    lng: -71.1240559,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1607675",
    valueOfReclassifiedAssets: 1317440,
    depreciableAssets: 21,
  },
  {
    lat: 42.3421605,
    lng: -71.1240559,
    squareFootage: "4060",
    propertyType: "Multifamily",
    totalCosts: "950000",
    valueOfReclassifiedAssets: 73179,
    depreciableAssets: 9,
  },
  {
    lat: 42.3613114,
    lng: -71.0482911,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1221111",
    valueOfReclassifiedAssets: 904465,
    depreciableAssets: 0,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29072856",
    valueOfReclassifiedAssets: 17241668,
    depreciableAssets: 96,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "92221",
    propertyType: "Industrial",
    totalCosts: "43556000",
    valueOfReclassifiedAssets: 4116324,
    depreciableAssets: 11,
  },
  {
    lat: 40.8285039,
    lng: -73.8918897,
    squareFootage: "19783",
    propertyType: "Industrial",
    totalCosts: "3700000",
    valueOfReclassifiedAssets: 787418,
    depreciableAssets: 25,
  },
  {
    lat: 40.6815619,
    lng: -73.9785952,
    squareFootage: "36000",
    propertyType: "Office",
    totalCosts: "68000000",
    valueOfReclassifiedAssets: 9207650,
    depreciableAssets: 16,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "92600",
    propertyType: "Multifamily",
    totalCosts: "2013134",
    valueOfReclassifiedAssets: 1743948,
    depreciableAssets: 17,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "96180",
    propertyType: "Multifamily",
    totalCosts: "4811705",
    valueOfReclassifiedAssets: 4219918,
    depreciableAssets: 13,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "44085",
    propertyType: "Multifamily",
    totalCosts: "1878470",
    valueOfReclassifiedAssets: 1707452,
    depreciableAssets: 10,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "335500",
    propertyType: "Multifamily",
    totalCosts: "42391184",
    valueOfReclassifiedAssets: 4399080,
    depreciableAssets: 13,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "163600",
    propertyType: "Multifamily",
    totalCosts: "13430698",
    valueOfReclassifiedAssets: 11540036,
    depreciableAssets: 16,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "60900",
    propertyType: "Multifamily",
    totalCosts: "3403098",
    valueOfReclassifiedAssets: 2987339,
    depreciableAssets: 13,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "239712",
    propertyType: "Multifamily",
    totalCosts: "14523478",
    valueOfReclassifiedAssets: 12677522,
    depreciableAssets: 16,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "102950",
    propertyType: "Multifamily",
    totalCosts: "2991034",
    valueOfReclassifiedAssets: 2617836,
    depreciableAssets: 14,
  },
  {
    lat: 40.2187428,
    lng: -74.76735459999999,
    squareFootage: "99292",
    propertyType: "Office",
    totalCosts: "1304771",
    valueOfReclassifiedAssets: 137558,
    depreciableAssets: 12,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "65000",
    propertyType: "Office",
    totalCosts: "7104511",
    valueOfReclassifiedAssets: 442353,
    depreciableAssets: 7,
  },
  {
    lat: 40.2187428,
    lng: -74.76735459999999,
    squareFootage: "99292",
    propertyType: "Office",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 136967,
    depreciableAssets: 12,
  },
  {
    lat: 40.2187428,
    lng: -74.76735459999999,
    squareFootage: "99292",
    propertyType: "Office",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 136967,
    depreciableAssets: 12,
  },
  {
    lat: 40.6723782,
    lng: -74.2208673,
    squareFootage: "24000",
    propertyType: "Office",
    totalCosts: "1850070",
    valueOfReclassifiedAssets: 251155,
    depreciableAssets: 17,
  },
  {
    lat: 40.6723782,
    lng: -74.2208673,
    squareFootage: "24000",
    propertyType: "Office",
    totalCosts: "1850070",
    valueOfReclassifiedAssets: 251155,
    depreciableAssets: 17,
  },
  {
    lat: 40.8053731,
    lng: -74.0241367,
    squareFootage: "69155",
    propertyType: "Industrial",
    totalCosts: "3395730",
    valueOfReclassifiedAssets: 344234,
    depreciableAssets: 13,
  },
  {
    lat: 40.923473,
    lng: -74.1445487,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 165691,
    depreciableAssets: 15,
  },
  {
    lat: 40.8053731,
    lng: -74.0241367,
    squareFootage: "69155",
    propertyType: "Industrial",
    totalCosts: "4667978",
    valueOfReclassifiedAssets: 503737,
    depreciableAssets: 13,
  },
  {
    lat: 41.49215179999999,
    lng: -74.3352858,
    squareFootage: "241128",
    propertyType: "Industrial",
    totalCosts: "1218168",
    valueOfReclassifiedAssets: 276017,
    depreciableAssets: 29,
  },
  {
    lat: 40.2388262,
    lng: -76.9685042,
    squareFootage: "84960",
    propertyType: "Office",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 1181293,
    depreciableAssets: 18,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "30000",
    propertyType: "Office",
    totalCosts: "3282324",
    valueOfReclassifiedAssets: 326643,
    depreciableAssets: 13,
  },
  {
    lat: 37.21874930000001,
    lng: -86.317392,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6151765",
    valueOfReclassifiedAssets: 2013339,
    depreciableAssets: 39,
  },
  {
    lat: 37.1184213,
    lng: -86.5400674,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1794265",
    valueOfReclassifiedAssets: 655935,
    depreciableAssets: 43,
  },
  {
    lat: 37.7327263,
    lng: -87.1821709,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5126470",
    valueOfReclassifiedAssets: 1369188,
    depreciableAssets: 32,
  },
  {
    lat: 37.3346228,
    lng: -87.48020679999999,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3040929",
    valueOfReclassifiedAssets: 1037454,
    depreciableAssets: 40,
  },
  {
    lat: 38.7585465,
    lng: -84.8255097,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1992883,
    depreciableAssets: 47,
  },
  {
    lat: 39.6841663,
    lng: -86.2463337,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4310896",
    valueOfReclassifiedAssets: 1353558,
    depreciableAssets: 37,
  },
  {
    lat: 38.1891095,
    lng: -85.6588999,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4462360",
    valueOfReclassifiedAssets: 1635251,
    depreciableAssets: 43,
  },
  {
    lat: 38.9803309,
    lng: -84.6583745,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 2222667,
    depreciableAssets: 44,
  },
  {
    lat: 37.9161194,
    lng: -86.8822445,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5138122",
    valueOfReclassifiedAssets: 1807363,
    depreciableAssets: 41,
  },
  {
    lat: 39.6982719,
    lng: -82.1931149,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3750000",
    valueOfReclassifiedAssets: 1212746,
    depreciableAssets: 38,
  },
  {
    lat: 41.5214385,
    lng: -83.57096399999999,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5312888",
    valueOfReclassifiedAssets: 1909561,
    depreciableAssets: 42,
  },
  {
    lat: 39.7337228,
    lng: -84.406761,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4858496",
    valueOfReclassifiedAssets: 1603234,
    depreciableAssets: 39,
  },
  {
    lat: 39.2648972,
    lng: -74.6107628,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8202353",
    valueOfReclassifiedAssets: 2711225,
    depreciableAssets: 39,
  },
  {
    lat: 38.1422302,
    lng: -85.6743082,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9250949",
    valueOfReclassifiedAssets: 2781814,
    depreciableAssets: 36,
  },
  {
    lat: 36.8516273,
    lng: -86.59053709999999,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1677754",
    valueOfReclassifiedAssets: 642851,
    depreciableAssets: 45,
  },
  {
    lat: 36.88064,
    lng: -86.4794359,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 963638,
    depreciableAssets: 41,
  },
  {
    lat: 37.7030051,
    lng: -85.8647201,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10557419",
    valueOfReclassifiedAssets: 3080261,
    depreciableAssets: 34,
  },
  {
    lat: 41.2650829,
    lng: -84.3962535,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3750000",
    valueOfReclassifiedAssets: 1176405,
    depreciableAssets: 37,
  },
  {
    lat: 41.7008069,
    lng: -83.4910242,
    squareFootage: "0",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6105160",
    valueOfReclassifiedAssets: 1979328,
    depreciableAssets: 38,
  },
  {
    lat: 32.67942,
    lng: -97.0283383,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1762219",
    valueOfReclassifiedAssets: 1471464,
    depreciableAssets: 33,
  },
  {
    lat: 40.7250632,
    lng: -73.9976946,
    squareFootage: "2428",
    propertyType: "Retail",
    totalCosts: "7180971",
    valueOfReclassifiedAssets: 805587,
    depreciableAssets: 13,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "786608",
    valueOfReclassifiedAssets: 543414,
    depreciableAssets: 0,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "13500",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 2837485,
    depreciableAssets: 21,
  },
  {
    lat: 40.7686973,
    lng: -73.9918181,
    squareFootage: "13800",
    propertyType: "Multifamily",
    totalCosts: "25500000",
    valueOfReclassifiedAssets: 5141211,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "290650",
    propertyType: "Multifamily",
    totalCosts: "24500000",
    valueOfReclassifiedAssets: 5997654,
    depreciableAssets: 28,
  },
  {
    lat: 34.583875,
    lng: -82.79941939999999,
    squareFootage: "261483",
    propertyType: "Multifamily",
    totalCosts: "30250000",
    valueOfReclassifiedAssets: 7451546,
    depreciableAssets: 29,
  },
  {
    lat: 34.9037259,
    lng: -81.0092062,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "41250000",
    valueOfReclassifiedAssets: 11129067,
    depreciableAssets: 31,
  },
  {
    lat: 34.9037259,
    lng: -81.0092062,
    squareFootage: "217170",
    propertyType: "Multifamily",
    totalCosts: "29600000",
    valueOfReclassifiedAssets: 6896196,
    depreciableAssets: 27,
  },
  {
    lat: 42.6663024,
    lng: -84.5536678,
    squareFootage: "72722",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3733993",
    valueOfReclassifiedAssets: 857483,
    depreciableAssets: 27,
  },
  {
    lat: 43.4157871,
    lng: -83.8685072,
    squareFootage: "56879",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2331327",
    valueOfReclassifiedAssets: 523513,
    depreciableAssets: 26,
  },
  {
    lat: 42.2675415,
    lng: -84.406761,
    squareFootage: "31824",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2347537",
    valueOfReclassifiedAssets: 696239,
    depreciableAssets: 35,
  },
  {
    lat: 34.2264431,
    lng: -84.41726659999999,
    squareFootage: "4022",
    propertyType: "Retail",
    totalCosts: "1287620",
    valueOfReclassifiedAssets: 1168871,
    depreciableAssets: 33,
  },
  {
    lat: 34.2264431,
    lng: -84.41726659999999,
    squareFootage: "4022",
    propertyType: "Retail",
    totalCosts: "1287620",
    valueOfReclassifiedAssets: 1168871,
    depreciableAssets: 33,
  },
  {
    lat: 35.4393566,
    lng: -82.4536115,
    squareFootage: "9100",
    propertyType: "Retail",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 427407,
    depreciableAssets: 32,
  },
  {
    lat: 33.5494383,
    lng: -84.20679919999999,
    squareFootage: "9014",
    propertyType: "Retail",
    totalCosts: "910000",
    valueOfReclassifiedAssets: 243823,
    depreciableAssets: 30,
  },
  {
    lat: 35.15900680000001,
    lng: -106.5760857,
    squareFootage: "120276",
    propertyType: "Multifamily",
    totalCosts: "11800000",
    valueOfReclassifiedAssets: 3721550,
    depreciableAssets: 37,
  },
  {
    lat: 41.89332539999999,
    lng: -87.7812524,
    squareFootage: "10400",
    propertyType: "Multifamily",
    totalCosts: "2025000",
    valueOfReclassifiedAssets: 364439,
    depreciableAssets: 21,
  },
  {
    lat: 41.8940762,
    lng: -87.8205864,
    squareFootage: "20800",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 617134,
    depreciableAssets: 21,
  },
  {
    lat: 41.9577761,
    lng: -87.6556468,
    squareFootage: "18850",
    propertyType: "Multifamily",
    totalCosts: "6418827",
    valueOfReclassifiedAssets: 1694424,
    depreciableAssets: 31,
  },
  {
    lat: 40.7164495,
    lng: -80.1205256,
    squareFootage: "23904",
    propertyType: "Retail",
    totalCosts: "3980645",
    valueOfReclassifiedAssets: 984868,
    depreciableAssets: 29,
  },
  {
    lat: 41.1693588,
    lng: -73.78308609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "498241",
    valueOfReclassifiedAssets: 468590,
    depreciableAssets: 6,
  },
  {
    lat: 41.1693588,
    lng: -73.78308609999999,
    squareFootage: "7114",
    propertyType: "Retail",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 588420,
    depreciableAssets: 22,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "52944",
    propertyType: "Multifamily",
    totalCosts: "16250000",
    valueOfReclassifiedAssets: 2735618,
    depreciableAssets: 20,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "17965",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 905359,
    depreciableAssets: 19,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14033333",
    valueOfReclassifiedAssets: 1678271,
    depreciableAssets: 14,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "15141",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 774901,
    depreciableAssets: 18,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "52290",
    propertyType: "Multifamily",
    totalCosts: "14033333",
    valueOfReclassifiedAssets: 1632043,
    depreciableAssets: 14,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "38330",
    propertyType: "Office",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 935249,
    depreciableAssets: 10,
  },
  {
    lat: 40.7366138,
    lng: -74.0094471,
    squareFootage: "58150",
    propertyType: "Multifamily",
    totalCosts: "18250000",
    valueOfReclassifiedAssets: 2415901,
    depreciableAssets: 15,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "42112",
    propertyType: "Specialty",
    totalCosts: "10975000",
    valueOfReclassifiedAssets: 627870,
    depreciableAssets: 6,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "21675",
    propertyType: "Multifamily",
    totalCosts: "4975000",
    valueOfReclassifiedAssets: 754036,
    depreciableAssets: 18,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "52290",
    propertyType: "Multifamily",
    totalCosts: "14033333",
    valueOfReclassifiedAssets: 1627642,
    depreciableAssets: 14,
  },
  {
    lat: 40.7366138,
    lng: -74.0094471,
    squareFootage: "17526",
    propertyType: "Multifamily",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 761011,
    depreciableAssets: 17,
  },
  {
    lat: 32.7433629,
    lng: -96.8887202,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "3516983",
    valueOfReclassifiedAssets: 807903,
    depreciableAssets: 27,
  },
  {
    lat: 32.3185311,
    lng: -96.62106109999999,
    squareFootage: "50956",
    propertyType: "Multifamily",
    totalCosts: "1854907",
    valueOfReclassifiedAssets: 459985,
    depreciableAssets: 27,
  },
  {
    lat: 43.499977,
    lng: -80.1896399,
    squareFootage: "41500",
    propertyType: "Industrial",
    totalCosts: "3063501",
    valueOfReclassifiedAssets: 704450,
    depreciableAssets: 27,
  },
  {
    lat: 40.699863,
    lng: -74.32941989999999,
    squareFootage: "44495",
    propertyType: "Industrial",
    totalCosts: "2805275",
    valueOfReclassifiedAssets: 524968,
    depreciableAssets: 22,
  },
  {
    lat: 40.699863,
    lng: -74.32941989999999,
    squareFootage: "44495",
    propertyType: "Industrial",
    totalCosts: "2762298",
    valueOfReclassifiedAssets: 516926,
    depreciableAssets: 22,
  },
  {
    lat: 43.499977,
    lng: -80.1896399,
    squareFootage: "41500",
    propertyType: "Industrial",
    totalCosts: "2945498",
    valueOfReclassifiedAssets: 677315,
    depreciableAssets: 27,
  },
  {
    lat: 32.8778842,
    lng: -96.64952199999999,
    squareFootage: "75674",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 809776,
    depreciableAssets: 25,
  },
  {
    lat: 35.12905569999999,
    lng: -80.9539475,
    squareFootage: "107635",
    propertyType: "Industrial",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1081188,
    depreciableAssets: 24,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "14708",
    propertyType: "Multifamily",
    totalCosts: "2851986",
    valueOfReclassifiedAssets: 223666,
    depreciableAssets: 9,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "14708",
    propertyType: "Multifamily",
    totalCosts: "2851236",
    valueOfReclassifiedAssets: 223606,
    depreciableAssets: 9,
  },
  {
    lat: 39.9021937,
    lng: -74.9968543,
    squareFootage: "85757",
    propertyType: "Industrial",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 1567188,
    depreciableAssets: 43,
  },
  {
    lat: 35.2421103,
    lng: -118.9761519,
    squareFootage: "171615",
    propertyType: "Office",
    totalCosts: "19728196",
    valueOfReclassifiedAssets: 3949055,
    depreciableAssets: 23,
  },
  {
    lat: 35.2421103,
    lng: -118.9761519,
    squareFootage: "171615",
    propertyType: "Office",
    totalCosts: "13152130",
    valueOfReclassifiedAssets: 2632704,
    depreciableAssets: 23,
  },
  {
    lat: 39.9602601,
    lng: -83.0092803,
    squareFootage: "78915",
    propertyType: "Office",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 1325702,
    depreciableAssets: 13,
  },
  {
    lat: 41.8133244,
    lng: -88.3385053,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5229922",
    valueOfReclassifiedAssets: 3457943,
    depreciableAssets: 41,
  },
  {
    lat: 29.9335594,
    lng: -90.07000509999999,
    squareFootage: "172935",
    propertyType: "Industrial",
    totalCosts: "27551000",
    valueOfReclassifiedAssets: 1510208,
    depreciableAssets: 6,
  },
  {
    lat: 45.5064511,
    lng: -122.7756216,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "124678808",
    valueOfReclassifiedAssets: 13901038,
    depreciableAssets: 13,
  },
  {
    lat: 35.16628600000001,
    lng: -120.6487508,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "33439755",
    valueOfReclassifiedAssets: 23811868,
    depreciableAssets: 38,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "886000",
    valueOfReclassifiedAssets: 173901,
    depreciableAssets: 23,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "886000",
    valueOfReclassifiedAssets: 187200,
    depreciableAssets: 25,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "886000",
    valueOfReclassifiedAssets: 123758,
    depreciableAssets: 16,
  },
  {
    lat: 32.23063640000001,
    lng: -80.7325287,
    squareFootage: "53004",
    propertyType: "Hotel",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1792965,
    depreciableAssets: 32,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 190438,
    depreciableAssets: 16,
  },
  {
    lat: 32.164761,
    lng: -80.75469799999999,
    squareFootage: "93643",
    propertyType: "Hotel",
    totalCosts: "8400000",
    valueOfReclassifiedAssets: 1867361,
    depreciableAssets: 26,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "884000",
    valueOfReclassifiedAssets: 157270,
    depreciableAssets: 20,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1456220",
    valueOfReclassifiedAssets: 644147,
    depreciableAssets: 0,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "886000",
    valueOfReclassifiedAssets: 167850,
    depreciableAssets: 22,
  },
  {
    lat: 34.00684500000001,
    lng: -83.3468784,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2510711",
    valueOfReclassifiedAssets: 1927646,
    depreciableAssets: 26,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "936000",
    valueOfReclassifiedAssets: 173976,
    depreciableAssets: 22,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1425000",
    valueOfReclassifiedAssets: 197991,
    depreciableAssets: 16,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "886000",
    valueOfReclassifiedAssets: 109882,
    depreciableAssets: 15,
  },
  {
    lat: 38.5499634,
    lng: -121.3884671,
    squareFootage: "36128",
    propertyType: "Multifamily",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 726810,
    depreciableAssets: 31,
  },
  {
    lat: 36.8353312,
    lng: -119.8930904,
    squareFootage: "103088",
    propertyType: "Multifamily",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 1753827,
    depreciableAssets: 30,
  },
  {
    lat: 36.8179331,
    lng: -119.7599761,
    squareFootage: "346318",
    propertyType: "Multifamily",
    totalCosts: "15200000",
    valueOfReclassifiedAssets: 3672213,
    depreciableAssets: 28,
  },
  {
    lat: 36.789028,
    lng: -119.829443,
    squareFootage: "53046",
    propertyType: "Multifamily",
    totalCosts: "3826000",
    valueOfReclassifiedAssets: 1012788,
    depreciableAssets: 32,
  },
  {
    lat: 38.5499634,
    lng: -121.3884671,
    squareFootage: "46300",
    propertyType: "Multifamily",
    totalCosts: "2424500",
    valueOfReclassifiedAssets: 1950433,
    depreciableAssets: 27,
  },
  {
    lat: 36.8353312,
    lng: -119.8930904,
    squareFootage: "76338",
    propertyType: "Multifamily",
    totalCosts: "4225000",
    valueOfReclassifiedAssets: 902726,
    depreciableAssets: 25,
  },
  {
    lat: 38.5499634,
    lng: -121.3884671,
    squareFootage: "38724",
    propertyType: "Multifamily",
    totalCosts: "2131446",
    valueOfReclassifiedAssets: 1729536,
    depreciableAssets: 26,
  },
  {
    lat: 38.5499634,
    lng: -121.3884671,
    squareFootage: "47848",
    propertyType: "Multifamily",
    totalCosts: "2570156",
    valueOfReclassifiedAssets: 2082552,
    depreciableAssets: 25,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "188280",
    propertyType: "Multifamily",
    totalCosts: "23500000",
    valueOfReclassifiedAssets: 6145195,
    depreciableAssets: 28,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "79478",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 3036622,
    depreciableAssets: 23,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "96983",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1243360,
    depreciableAssets: 17,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "321225",
    propertyType: "Multifamily",
    totalCosts: "30446672",
    valueOfReclassifiedAssets: 7328741,
    depreciableAssets: 26,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "151842",
    propertyType: "Multifamily",
    totalCosts: "23400000",
    valueOfReclassifiedAssets: 6445420,
    depreciableAssets: 30,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "37203",
    propertyType: "Specialty",
    totalCosts: "5095364",
    valueOfReclassifiedAssets: 4232526,
    depreciableAssets: 0,
  },
  {
    lat: 41.5399755,
    lng: -72.9934468,
    squareFootage: "81118",
    propertyType: "Multifamily",
    totalCosts: "3676144",
    valueOfReclassifiedAssets: 2764845,
    depreciableAssets: 29,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "23290",
    propertyType: "Multifamily",
    totalCosts: "1785726",
    valueOfReclassifiedAssets: 1338157,
    depreciableAssets: 33,
  },
  {
    lat: 41.5399755,
    lng: -72.9934468,
    squareFootage: "76269",
    propertyType: "Multifamily",
    totalCosts: "2866890",
    valueOfReclassifiedAssets: 1852681,
    depreciableAssets: 44,
  },
  {
    lat: 39.3877502,
    lng: -76.488118,
    squareFootage: "479339",
    propertyType: "Multifamily",
    totalCosts: "48500000",
    valueOfReclassifiedAssets: 10678841,
    depreciableAssets: 26,
  },
  {
    lat: 39.0751243,
    lng: -76.6297189,
    squareFootage: "358643",
    propertyType: "Multifamily",
    totalCosts: "58795205",
    valueOfReclassifiedAssets: 14843500,
    depreciableAssets: 30,
  },
  {
    lat: 38.8178384,
    lng: -76.9354979,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "9650000",
    valueOfReclassifiedAssets: 2028591,
    depreciableAssets: 24,
  },
  {
    lat: 38.8178384,
    lng: -76.9354979,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "9650000",
    valueOfReclassifiedAssets: 2028591,
    depreciableAssets: 24,
  },
  {
    lat: 38.5945886,
    lng: -94.8520636,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2476761",
    valueOfReclassifiedAssets: 2250618,
    depreciableAssets: 16,
  },
  {
    lat: 38.5945886,
    lng: -94.8520636,
    squareFootage: "41080",
    propertyType: "Multifamily",
    totalCosts: "3400000",
    valueOfReclassifiedAssets: 985396,
    depreciableAssets: 34,
  },
  {
    lat: 40.16254470000001,
    lng: -82.89636039999999,
    squareFootage: "110598",
    propertyType: "Office",
    totalCosts: "14620000",
    valueOfReclassifiedAssets: 2170233,
    depreciableAssets: 18,
  },
  {
    lat: 41.135887,
    lng: -73.7889689,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11077671",
    valueOfReclassifiedAssets: 10699147,
    depreciableAssets: 11,
  },
  {
    lat: 34.2253022,
    lng: -77.8710285,
    squareFootage: "156960",
    propertyType: "Multifamily",
    totalCosts: "12900000",
    valueOfReclassifiedAssets: 3170078,
    depreciableAssets: 29,
  },
  {
    lat: 34.2253022,
    lng: -77.8710285,
    squareFootage: "195450",
    propertyType: "Multifamily",
    totalCosts: "12400000",
    valueOfReclassifiedAssets: 2344386,
    depreciableAssets: 23,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "339976",
    propertyType: "Multifamily",
    totalCosts: "30784123",
    valueOfReclassifiedAssets: 7784058,
    depreciableAssets: 30,
  },
  {
    lat: 33.9425519,
    lng: -80.9539475,
    squareFootage: "236478",
    propertyType: "Multifamily",
    totalCosts: "7325000",
    valueOfReclassifiedAssets: 1766353,
    depreciableAssets: 29,
  },
  {
    lat: 36.0896801,
    lng: -79.95284509999999,
    squareFootage: "238526",
    propertyType: "Multifamily",
    totalCosts: "9850000",
    valueOfReclassifiedAssets: 2166561,
    depreciableAssets: 26,
  },
  {
    lat: 35.8150476,
    lng: -78.57744079999999,
    squareFootage: "79190",
    propertyType: "Multifamily",
    totalCosts: "5030000",
    valueOfReclassifiedAssets: 1415441,
    depreciableAssets: 33,
  },
  {
    lat: 35.9530958,
    lng: -78.9571069,
    squareFootage: "129520",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1554219,
    depreciableAssets: 29,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "256009",
    propertyType: "Multifamily",
    totalCosts: "19525000",
    valueOfReclassifiedAssets: 5313452,
    depreciableAssets: 32,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "87400",
    propertyType: "Office",
    totalCosts: "1881250",
    valueOfReclassifiedAssets: 144388,
    depreciableAssets: 9,
  },
  {
    lat: 35.2916363,
    lng: -80.72698539999999,
    squareFootage: "254880",
    propertyType: "Multifamily",
    totalCosts: "17400000",
    valueOfReclassifiedAssets: 4050315,
    depreciableAssets: 27,
  },
  {
    lat: 34.3023653,
    lng: -77.9852853,
    squareFootage: "172720",
    propertyType: "Multifamily",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 3262670,
    depreciableAssets: 27,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "67600",
    propertyType: "Industrial",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 102246,
    depreciableAssets: 4,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "56066",
    propertyType: "Office",
    totalCosts: "66500000",
    valueOfReclassifiedAssets: 11264376,
    depreciableAssets: 20,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "314900",
    propertyType: "Office",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 1020346,
    depreciableAssets: 0,
  },
  {
    lat: 35.8409242,
    lng: -78.62285039999999,
    squareFootage: "149372",
    propertyType: "Office",
    totalCosts: "12400000",
    valueOfReclassifiedAssets: 2637017,
    depreciableAssets: 25,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "36940",
    propertyType: "Office",
    totalCosts: "806250",
    valueOfReclassifiedAssets: 42184,
    depreciableAssets: 6,
  },
  {
    lat: 40.9238588,
    lng: -74.30595459999999,
    squareFootage: "59000",
    propertyType: "Industrial",
    totalCosts: "2492000",
    valueOfReclassifiedAssets: 2147757,
    depreciableAssets: 27,
  },
  {
    lat: 33.4796083,
    lng: -86.73660819999999,
    squareFootage: "122228",
    propertyType: "Office",
    totalCosts: "14885040",
    valueOfReclassifiedAssets: 2845013,
    depreciableAssets: 21,
  },
  {
    lat: 33.4165524,
    lng: -86.681253,
    squareFootage: "128501",
    propertyType: "Office",
    totalCosts: "16524139",
    valueOfReclassifiedAssets: 3183387,
    depreciableAssets: 22,
  },
  {
    lat: 33.9873389,
    lng: -81.0368211,
    squareFootage: "333516",
    propertyType: "Office",
    totalCosts: "64840490",
    valueOfReclassifiedAssets: 6192612,
    depreciableAssets: 11,
  },
  {
    lat: 33.4165524,
    lng: -86.681253,
    squareFootage: "126044",
    propertyType: "Office",
    totalCosts: "13180582",
    valueOfReclassifiedAssets: 2850507,
    depreciableAssets: 24,
  },
  {
    lat: 36.0676041,
    lng: -79.7568107,
    squareFootage: "325771",
    propertyType: "Office",
    totalCosts: "74515293",
    valueOfReclassifiedAssets: 9190160,
    depreciableAssets: 14,
  },
  {
    lat: 32.6095477,
    lng: -97.8286368,
    squareFootage: "555545",
    propertyType: "Office",
    totalCosts: "74515293",
    valueOfReclassifiedAssets: 10651730,
    depreciableAssets: 16,
  },
  {
    lat: 29.9503649,
    lng: -90.071027,
    squareFootage: "1256971",
    propertyType: "Office",
    totalCosts: "126203223",
    valueOfReclassifiedAssets: 17244860,
    depreciableAssets: 16,
  },
  {
    lat: 41.5139193,
    lng: -81.6747295,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "48007529",
    valueOfReclassifiedAssets: 4099150,
    depreciableAssets: 9,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "336700",
    propertyType: "Office",
    totalCosts: "35400000",
    valueOfReclassifiedAssets: 2915513,
    depreciableAssets: 9,
  },
  {
    lat: 34.0295114,
    lng: -118.2848199,
    squareFootage: "951897",
    propertyType: "Office",
    totalCosts: "147500000",
    valueOfReclassifiedAssets: 19751174,
    depreciableAssets: 15,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2221544",
    valueOfReclassifiedAssets: 333530,
    depreciableAssets: 18,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "16750",
    propertyType: "Office",
    totalCosts: "10808050",
    valueOfReclassifiedAssets: 1191380,
    depreciableAssets: 13,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "10750",
    propertyType: "Specialty",
    totalCosts: "6835499",
    valueOfReclassifiedAssets: 1563315,
    depreciableAssets: 27,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "10750",
    propertyType: "Specialty",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 1612095,
    depreciableAssets: 27,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "4140",
    propertyType: "Office",
    totalCosts: "1897710",
    valueOfReclassifiedAssets: 289617,
    depreciableAssets: 17,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "29425",
    propertyType: "Office",
    totalCosts: "33600000",
    valueOfReclassifiedAssets: 1346058,
    depreciableAssets: 6,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "35272",
    propertyType: "Office",
    totalCosts: "20473938",
    valueOfReclassifiedAssets: 1561333,
    depreciableAssets: 9,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "120036",
    propertyType: "Multifamily",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 2857937,
    depreciableAssets: 31,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "120036",
    propertyType: "Multifamily",
    totalCosts: "10893865",
    valueOfReclassifiedAssets: 2882776,
    depreciableAssets: 31,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "25988",
    propertyType: "Multifamily",
    totalCosts: "3225000",
    valueOfReclassifiedAssets: 441688,
    depreciableAssets: 16,
  },
  {
    lat: 32.6920499,
    lng: -117.1911749,
    squareFootage: "25000",
    propertyType: "Office",
    totalCosts: "6750000",
    valueOfReclassifiedAssets: 501892,
    depreciableAssets: 8,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 2562204,
    depreciableAssets: 30,
  },
  {
    lat: 32.67942,
    lng: -97.0283383,
    squareFootage: "121476",
    propertyType: "Multifamily",
    totalCosts: "6239956",
    valueOfReclassifiedAssets: 1634028,
    depreciableAssets: 29,
  },
  {
    lat: 32.67942,
    lng: -97.0283383,
    squareFootage: "121476",
    propertyType: "Multifamily",
    totalCosts: "6239956",
    valueOfReclassifiedAssets: 1634028,
    depreciableAssets: 29,
  },
  {
    lat: 32.9985237,
    lng: -96.84046,
    squareFootage: "117611",
    propertyType: "Multifamily",
    totalCosts: "13497069",
    valueOfReclassifiedAssets: 3940765,
    depreciableAssets: 34,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "48704",
    propertyType: "Hotel",
    totalCosts: "5654386",
    valueOfReclassifiedAssets: 833584,
    depreciableAssets: 17,
  },
  {
    lat: 34.1366259,
    lng: -118.3987842,
    squareFootage: "38676",
    propertyType: "Office",
    totalCosts: "3821530",
    valueOfReclassifiedAssets: 1061221,
    depreciableAssets: 33,
  },
  {
    lat: 40.7793504,
    lng: -74.511172,
    squareFootage: "15053",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 113456,
    depreciableAssets: 13,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "12500",
    propertyType: "Retail",
    totalCosts: "1305410",
    valueOfReclassifiedAssets: 165698,
    depreciableAssets: 21,
  },
  {
    lat: 40.8844007,
    lng: -74.30595459999999,
    squareFootage: "21000",
    propertyType: "Retail",
    totalCosts: "1751245",
    valueOfReclassifiedAssets: 174907,
    depreciableAssets: 21,
  },
  {
    lat: 40.8537087,
    lng: -74.6576091,
    squareFootage: "7467",
    propertyType: "Retail",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 295636,
    depreciableAssets: 31,
  },
  {
    lat: 40.9906067,
    lng: -74.5756196,
    squareFootage: "30647",
    propertyType: "Office",
    totalCosts: "3097825",
    valueOfReclassifiedAssets: 472795,
    depreciableAssets: 26,
  },
  {
    lat: 40.890298,
    lng: -74.74541119999999,
    squareFootage: "20000",
    propertyType: "Office",
    totalCosts: "1089560",
    valueOfReclassifiedAssets: 173946,
    depreciableAssets: 26,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "59805",
    propertyType: "Multifamily",
    totalCosts: "6940949",
    valueOfReclassifiedAssets: 6188278,
    depreciableAssets: 11,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1650349",
    valueOfReclassifiedAssets: 469438,
    depreciableAssets: 40,
  },
  {
    lat: 40.7686973,
    lng: -73.9918181,
    squareFootage: "9250",
    propertyType: "Hotel",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 480085,
    depreciableAssets: 14,
  },
  {
    lat: 40.74133339999999,
    lng: -73.90364869999999,
    squareFootage: "26468",
    propertyType: "Multifamily",
    totalCosts: "8400000",
    valueOfReclassifiedAssets: 1137715,
    depreciableAssets: 16,
  },
  {
    lat: 42.08351529999999,
    lng: -70.99034379999999,
    squareFootage: "43296",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12082592",
    valueOfReclassifiedAssets: 3929094,
    depreciableAssets: 25,
  },
  {
    lat: 40.2672473,
    lng: -75.6091696,
    squareFootage: "2015",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4902417",
    valueOfReclassifiedAssets: 2734688,
    depreciableAssets: 53,
  },
  {
    lat: 32.3243856,
    lng: -95.29979589999999,
    squareFootage: "74461",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7202316",
    valueOfReclassifiedAssets: 2901732,
    depreciableAssets: 45,
  },
  {
    lat: 41.4898568,
    lng: -90.5730713,
    squareFootage: "137000",
    propertyType: "Multifamily",
    totalCosts: "5800000",
    valueOfReclassifiedAssets: 804267,
    depreciableAssets: 16,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "213940",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 3066318,
    depreciableAssets: 29,
  },
  {
    lat: 35.3447622,
    lng: -94.3667907,
    squareFootage: "39570",
    propertyType: "Industrial",
    totalCosts: "2448750",
    valueOfReclassifiedAssets: 420174,
    depreciableAssets: 32,
  },
  {
    lat: 30.1217279,
    lng: -91.9294681,
    squareFootage: "14020",
    propertyType: "Industrial",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 506331,
    depreciableAssets: 39,
  },
  {
    lat: 39.0609319,
    lng: -95.72471689999999,
    squareFootage: "66192",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 587521,
    depreciableAssets: 30,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "184392",
    propertyType: "Multifamily",
    totalCosts: "7330341",
    valueOfReclassifiedAssets: 5345078,
    depreciableAssets: 37,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "186059",
    propertyType: "Multifamily",
    totalCosts: "10230000",
    valueOfReclassifiedAssets: 2893069,
    depreciableAssets: 34,
  },
  {
    lat: 35.1323787,
    lng: -90.004663,
    squareFootage: "81509",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 2090271,
    depreciableAssets: 29,
  },
  {
    lat: 35.1516237,
    lng: -90.0349866,
    squareFootage: "201421",
    propertyType: "Multifamily",
    totalCosts: "9750000",
    valueOfReclassifiedAssets: 1763606,
    depreciableAssets: 21,
  },
  {
    lat: 35.9073934,
    lng: -84.0061661,
    squareFootage: "61418",
    propertyType: "Multifamily",
    totalCosts: "3442500",
    valueOfReclassifiedAssets: 2470599,
    depreciableAssets: 36,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "274104",
    propertyType: "Multifamily",
    totalCosts: "13662264",
    valueOfReclassifiedAssets: 3130454,
    depreciableAssets: 27,
  },
  {
    lat: 36.2722491,
    lng: -86.7114545,
    squareFootage: "274104",
    propertyType: "Multifamily",
    totalCosts: "6274321",
    valueOfReclassifiedAssets: 1145903,
    depreciableAssets: 22,
  },
  {
    lat: 35.1323787,
    lng: -90.004663,
    squareFootage: "81509",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 2090271,
    depreciableAssets: 29,
  },
  {
    lat: 39.0401557,
    lng: -95.72471689999999,
    squareFootage: "86730",
    propertyType: "Multifamily",
    totalCosts: "3193750",
    valueOfReclassifiedAssets: 953799,
    depreciableAssets: 35,
  },
  {
    lat: 40.2403959,
    lng: -85.45818589999999,
    squareFootage: "86770",
    propertyType: "Multifamily",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 1211713,
    depreciableAssets: 21,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "200876",
    propertyType: "Multifamily",
    totalCosts: "7419863",
    valueOfReclassifiedAssets: 5554481,
    depreciableAssets: 37,
  },
  {
    lat: 35.0772572,
    lng: -89.84581999999999,
    squareFootage: "526914",
    propertyType: "Multifamily",
    totalCosts: "19057000",
    valueOfReclassifiedAssets: 14470924,
    depreciableAssets: 31,
  },
  {
    lat: 39.0577566,
    lng: -94.4902774,
    squareFootage: "474264",
    propertyType: "Multifamily",
    totalCosts: "20650000",
    valueOfReclassifiedAssets: 4893555,
    depreciableAssets: 28,
  },
  {
    lat: 39.0401557,
    lng: -95.72471689999999,
    squareFootage: "86730",
    propertyType: "Multifamily",
    totalCosts: "3637800",
    valueOfReclassifiedAssets: 1086413,
    depreciableAssets: 35,
  },
  {
    lat: 35.9073934,
    lng: -84.0061661,
    squareFootage: "277136",
    propertyType: "Multifamily",
    totalCosts: "13812734",
    valueOfReclassifiedAssets: 9874919,
    depreciableAssets: 36,
  },
  {
    lat: 35.8246705,
    lng: -84.0907246,
    squareFootage: "80640",
    propertyType: "Multifamily",
    totalCosts: "4527100",
    valueOfReclassifiedAssets: 3507674,
    depreciableAssets: 47,
  },
  {
    lat: 36.2722491,
    lng: -86.7114545,
    squareFootage: "126491",
    propertyType: "Multifamily",
    totalCosts: "6770000",
    valueOfReclassifiedAssets: 1294393,
    depreciableAssets: 22,
  },
  {
    lat: 35.9858908,
    lng: -84.2805478,
    squareFootage: "200877",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2564599,
    depreciableAssets: 33,
  },
  {
    lat: 35.041539,
    lng: -85.1583064,
    squareFootage: "210587",
    propertyType: "Multifamily",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 4525490,
    depreciableAssets: 29,
  },
  {
    lat: 41.9901263,
    lng: -87.663045,
    squareFootage: "43896",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5107548",
    valueOfReclassifiedAssets: 4127514,
    depreciableAssets: 21,
  },
  {
    lat: 42.3410008,
    lng: -89.0847949,
    squareFootage: "43200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 2382693,
    depreciableAssets: 25,
  },
  {
    lat: 41.9013087,
    lng: -87.68276759999999,
    squareFootage: "59109",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9955651",
    valueOfReclassifiedAssets: 1803436,
    depreciableAssets: 22,
  },
  {
    lat: 42.2610481,
    lng: -88.9509626,
    squareFootage: "60175",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16047261",
    valueOfReclassifiedAssets: 3356699,
    depreciableAssets: 25,
  },
  {
    lat: 42.2610481,
    lng: -88.9509626,
    squareFootage: "60175",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13978631",
    valueOfReclassifiedAssets: 2923992,
    depreciableAssets: 25,
  },
  {
    lat: 42.0361546,
    lng: -88.314212,
    squareFootage: "63770",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16321026",
    valueOfReclassifiedAssets: 3517071,
    depreciableAssets: 25,
  },
  {
    lat: 42.0361546,
    lng: -88.314212,
    squareFootage: "63770",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16382396",
    valueOfReclassifiedAssets: 3530295,
    depreciableAssets: 25,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "29529",
    propertyType: "Multifamily",
    totalCosts: "6196411",
    valueOfReclassifiedAssets: 5658860,
    depreciableAssets: 9,
  },
  {
    lat: 42.0708162,
    lng: -83.2465794,
    squareFootage: "147136",
    propertyType: "Multifamily",
    totalCosts: "910000",
    valueOfReclassifiedAssets: 275269,
    depreciableAssets: 35,
  },
  {
    lat: 42.3612206,
    lng: -82.98778659999999,
    squareFootage: "46969",
    propertyType: "Multifamily",
    totalCosts: "1150490",
    valueOfReclassifiedAssets: 236898,
    depreciableAssets: 25,
  },
  {
    lat: 42.4224755,
    lng: -83.1916937,
    squareFootage: "68730",
    propertyType: "Multifamily",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 407127,
    depreciableAssets: 30,
  },
  {
    lat: 42.3552033,
    lng: -83.2131206,
    squareFootage: "254716",
    propertyType: "Multifamily",
    totalCosts: "7717500",
    valueOfReclassifiedAssets: 2631392,
    depreciableAssets: 40,
  },
  {
    lat: 42.0708162,
    lng: -83.2465794,
    squareFootage: "147136",
    propertyType: "Multifamily",
    totalCosts: "3640000",
    valueOfReclassifiedAssets: 1101074,
    depreciableAssets: 35,
  },
  {
    lat: 42.3664506,
    lng: -83.0737195,
    squareFootage: "192000",
    propertyType: "Multifamily",
    totalCosts: "7350870",
    valueOfReclassifiedAssets: 892186,
    depreciableAssets: 15,
  },
  {
    lat: 42.328245,
    lng: -83.0495622,
    squareFootage: "268000",
    propertyType: "Office",
    totalCosts: "34750000",
    valueOfReclassifiedAssets: 3319169,
    depreciableAssets: 11,
  },
  {
    lat: 41.8925085,
    lng: -87.61616959999999,
    squareFootage: "95100",
    propertyType: "Retail",
    totalCosts: "117000000",
    valueOfReclassifiedAssets: 19347090,
    depreciableAssets: 20,
  },
  {
    lat: 40.1296424,
    lng: -83.025396,
    squareFootage: "182487",
    propertyType: "Office",
    totalCosts: "13400000",
    valueOfReclassifiedAssets: 3200446,
    depreciableAssets: 28,
  },
  {
    lat: 40.1296424,
    lng: -83.025396,
    squareFootage: "141072",
    propertyType: "Office",
    totalCosts: "8875000",
    valueOfReclassifiedAssets: 2645817,
    depreciableAssets: 35,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4841507",
    valueOfReclassifiedAssets: 4722993,
    depreciableAssets: 3,
  },
  {
    lat: 42.339904,
    lng: -71.0898892,
    squareFootage: "9302",
    propertyType: "Office",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 1142590,
    depreciableAssets: 12,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "9850",
    propertyType: "Multifamily",
    totalCosts: "14900000",
    valueOfReclassifiedAssets: 1607360,
    depreciableAssets: 13,
  },
  {
    lat: 40.5749261,
    lng: -73.9859414,
    squareFootage: "10000",
    propertyType: "Retail",
    totalCosts: "9600000",
    valueOfReclassifiedAssets: 812157,
    depreciableAssets: 11,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "11880",
    propertyType: "Multifamily",
    totalCosts: "5814226",
    valueOfReclassifiedAssets: 4979858,
    depreciableAssets: 14,
  },
  {
    lat: 40.7728432,
    lng: -73.9558204,
    squareFootage: "4400",
    propertyType: "Multifamily",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 3262098,
    depreciableAssets: 17,
  },
  {
    lat: 41.1270095,
    lng: -73.3590802,
    squareFootage: "18834",
    propertyType: "Retail",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1934584,
    depreciableAssets: 20,
  },
  {
    lat: 33.9812131,
    lng: -84.15406589999999,
    squareFootage: "361716",
    propertyType: "Retail",
    totalCosts: "17010000",
    valueOfReclassifiedAssets: 3299841,
    depreciableAssets: 23,
  },
  {
    lat: 39.30969839999999,
    lng: -76.6701475,
    squareFootage: "49920",
    propertyType: "Multifamily",
    totalCosts: "5500506",
    valueOfReclassifiedAssets: 1163251,
    depreciableAssets: 25,
  },
  {
    lat: 40.024885,
    lng: -74.3264869,
    squareFootage: "205055",
    propertyType: "Multifamily",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 3116467,
    depreciableAssets: 25,
  },
  {
    lat: 39.30969839999999,
    lng: -76.6701475,
    squareFootage: "180740",
    propertyType: "Multifamily",
    totalCosts: "14517957",
    valueOfReclassifiedAssets: 2474673,
    depreciableAssets: 21,
  },
  {
    lat: 40.024885,
    lng: -74.3264869,
    squareFootage: "205055",
    propertyType: "Multifamily",
    totalCosts: "7922575",
    valueOfReclassifiedAssets: 1763603,
    depreciableAssets: 25,
  },
  {
    lat: 29.702465,
    lng: -95.53548160000001,
    squareFootage: "145765",
    propertyType: "Office",
    totalCosts: "6400000",
    valueOfReclassifiedAssets: 953968,
    depreciableAssets: 18,
  },
  {
    lat: 40.0618676,
    lng: -75.0465123,
    squareFootage: "275000",
    propertyType: "Multifamily",
    totalCosts: "9144049",
    valueOfReclassifiedAssets: 7269354,
    depreciableAssets: 25,
  },
  {
    lat: 40.0618676,
    lng: -75.0465123,
    squareFootage: "275000",
    propertyType: "Multifamily",
    totalCosts: "8672622",
    valueOfReclassifiedAssets: 6894578,
    depreciableAssets: 25,
  },
  {
    lat: 43.0619897,
    lng: -76.1348944,
    squareFootage: "72912",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "31363218",
    valueOfReclassifiedAssets: 22797738,
    depreciableAssets: 29,
  },
  {
    lat: 40.9013998,
    lng: -79.9416549,
    squareFootage: "150000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14687261",
    valueOfReclassifiedAssets: 11839004,
    depreciableAssets: 25,
  },
  {
    lat: 36.8730255,
    lng: -83.9003022,
    squareFootage: "34000",
    propertyType: "Office",
    totalCosts: "2457869",
    valueOfReclassifiedAssets: 729818,
    depreciableAssets: 34,
  },
  {
    lat: 43.1438143,
    lng: -89.35114949999999,
    squareFootage: "21842",
    propertyType: "Retail",
    totalCosts: "3018750",
    valueOfReclassifiedAssets: 704884,
    depreciableAssets: 28,
  },
  {
    lat: 45.5140983,
    lng: -122.4228701,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1250343,
    depreciableAssets: 28,
  },
  {
    lat: 45.5143706,
    lng: -122.5045791,
    squareFootage: "126471",
    propertyType: "Multifamily",
    totalCosts: "16230000",
    valueOfReclassifiedAssets: 4557966,
    depreciableAssets: 35,
  },
  {
    lat: 45.5140983,
    lng: -122.4228701,
    squareFootage: "221890",
    propertyType: "Multifamily",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 8445559,
    depreciableAssets: 34,
  },
  {
    lat: 45.5140983,
    lng: -122.4228701,
    squareFootage: "95000",
    propertyType: "Multifamily",
    totalCosts: "11100000",
    valueOfReclassifiedAssets: 2696327,
    depreciableAssets: 31,
  },
  {
    lat: 45.5539123,
    lng: -122.4463614,
    squareFootage: "69230",
    propertyType: "Multifamily",
    totalCosts: "7742000",
    valueOfReclassifiedAssets: 2135487,
    depreciableAssets: 34,
  },
  {
    lat: 41.4020757,
    lng: -74.0417628,
    squareFootage: "198533",
    propertyType: "Multifamily",
    totalCosts: "27600000",
    valueOfReclassifiedAssets: 5621996,
    depreciableAssets: 25,
  },
  {
    lat: 45.5539123,
    lng: -122.4463614,
    squareFootage: "153038",
    propertyType: "Multifamily",
    totalCosts: "20800000",
    valueOfReclassifiedAssets: 5472345,
    depreciableAssets: 33,
  },
  {
    lat: 42.645278,
    lng: -83.41098339999999,
    squareFootage: "220337",
    propertyType: "Multifamily",
    totalCosts: "10520000",
    valueOfReclassifiedAssets: 2155097,
    depreciableAssets: 26,
  },
  {
    lat: 41.5214566,
    lng: -81.6418656,
    squareFootage: "23890",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1950000",
    valueOfReclassifiedAssets: 457438,
    depreciableAssets: 27,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6440822",
    valueOfReclassifiedAssets: 5428578,
    depreciableAssets: 17,
  },
  {
    lat: 38.9732233,
    lng: -76.94702199999999,
    squareFootage: "191976",
    propertyType: "Multifamily",
    totalCosts: "3976333",
    valueOfReclassifiedAssets: 1096625,
    depreciableAssets: 30,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "424510",
    propertyType: "Multifamily",
    totalCosts: "3238055",
    valueOfReclassifiedAssets: 597627,
    depreciableAssets: 28,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "36667",
    propertyType: "Multifamily",
    totalCosts: "3885000",
    valueOfReclassifiedAssets: 651072,
    depreciableAssets: 20,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "424510",
    propertyType: "Multifamily",
    totalCosts: "3442856",
    valueOfReclassifiedAssets: 589885,
    depreciableAssets: 28,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "6440",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1579039,
    depreciableAssets: 32,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "81000",
    propertyType: "Multifamily",
    totalCosts: "7929229",
    valueOfReclassifiedAssets: 707215,
    depreciableAssets: 10,
  },
  {
    lat: 40.8874882,
    lng: -73.977126,
    squareFootage: "7172",
    propertyType: "Retail",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1198085,
    depreciableAssets: 27,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "830910",
    propertyType: "Multifamily",
    totalCosts: "3330605",
    valueOfReclassifiedAssets: 794998,
    depreciableAssets: 30,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "81000",
    propertyType: "Multifamily",
    totalCosts: "7054229",
    valueOfReclassifiedAssets: 6350636,
    depreciableAssets: 10,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5227489",
    valueOfReclassifiedAssets: 799366,
    depreciableAssets: 18,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "61068",
    propertyType: "Multifamily",
    totalCosts: "8095803",
    valueOfReclassifiedAssets: 862743,
    depreciableAssets: 12,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "830910",
    propertyType: "Multifamily",
    totalCosts: "4123916",
    valueOfReclassifiedAssets: 866106,
    depreciableAssets: 30,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "96310",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 1521891,
    depreciableAssets: 14,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "29868",
    propertyType: "Multifamily",
    totalCosts: "1995000",
    valueOfReclassifiedAssets: 305561,
    depreciableAssets: 18,
  },
  {
    lat: 40.6184845,
    lng: -73.7536695,
    squareFootage: "151621",
    propertyType: "Multifamily",
    totalCosts: "24500000",
    valueOfReclassifiedAssets: 4750044,
    depreciableAssets: 23,
  },
  {
    lat: 26.0195493,
    lng: -80.22352269999999,
    squareFootage: "125804",
    propertyType: "Retail",
    totalCosts: "21250000",
    valueOfReclassifiedAssets: 5794884,
    depreciableAssets: 32,
  },
  {
    lat: 40.672917,
    lng: -73.6683371,
    squareFootage: "41600",
    propertyType: "Retail",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 796599,
    depreciableAssets: 31,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "830910",
    propertyType: "Multifamily",
    totalCosts: "6025950",
    valueOfReclassifiedAssets: 1448810,
    depreciableAssets: 30,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "72608",
    propertyType: "Industrial",
    totalCosts: "26300000",
    valueOfReclassifiedAssets: 1946447,
    depreciableAssets: 9,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "73806",
    propertyType: "Multifamily",
    totalCosts: "9400000",
    valueOfReclassifiedAssets: 1179954,
    depreciableAssets: 14,
  },
  {
    lat: 26.0195493,
    lng: -80.22352269999999,
    squareFootage: "125804",
    propertyType: "Retail",
    totalCosts: "21250000",
    valueOfReclassifiedAssets: 5454008,
    depreciableAssets: 32,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "270600",
    propertyType: "Multifamily",
    totalCosts: "5048024",
    valueOfReclassifiedAssets: 1244676,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "73716",
    propertyType: "Multifamily",
    totalCosts: "33476600",
    valueOfReclassifiedAssets: 6168423,
    depreciableAssets: 21,
  },
  {
    lat: 39.3485401,
    lng: -76.5661622,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "1882739",
    valueOfReclassifiedAssets: 437990,
    depreciableAssets: 34,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "8850",
    propertyType: "Retail",
    totalCosts: "8974263",
    valueOfReclassifiedAssets: 1670221,
    depreciableAssets: 23,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "574397",
    propertyType: "Multifamily",
    totalCosts: "6915375",
    valueOfReclassifiedAssets: 1711869,
    depreciableAssets: 32,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "3851",
    propertyType: "Office",
    totalCosts: "4270007",
    valueOfReclassifiedAssets: 3430007,
    depreciableAssets: 100,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8782273",
    valueOfReclassifiedAssets: 8082477,
    depreciableAssets: 10,
  },
  {
    lat: 31.2198201,
    lng: -81.64734399999999,
    squareFootage: "75595",
    propertyType: "Retail",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 1179941,
    depreciableAssets: 34,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "10120",
    propertyType: "Multifamily",
    totalCosts: "9750000",
    valueOfReclassifiedAssets: 1604348,
    depreciableAssets: 19,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "6545",
    propertyType: "Retail",
    totalCosts: "7800000",
    valueOfReclassifiedAssets: 706414,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "574397",
    propertyType: "Multifamily",
    totalCosts: "2682448",
    valueOfReclassifiedAssets: 620173,
    depreciableAssets: 32,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3190000",
    valueOfReclassifiedAssets: 2880978,
    depreciableAssets: 10,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "22590423",
    valueOfReclassifiedAssets: 20463537,
    depreciableAssets: 13,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "24108",
    propertyType: "Multifamily",
    totalCosts: "22400000",
    valueOfReclassifiedAssets: 4773022,
    depreciableAssets: 25,
  },
  {
    lat: 40.7728432,
    lng: -73.9558204,
    squareFootage: "9540",
    propertyType: "Multifamily",
    totalCosts: "6575000",
    valueOfReclassifiedAssets: 1153514,
    depreciableAssets: 21,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "1009410",
    propertyType: "Multifamily",
    totalCosts: "4311791",
    valueOfReclassifiedAssets: 1103402,
    depreciableAssets: 32,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15542625",
    valueOfReclassifiedAssets: 2584811,
    depreciableAssets: 17,
  },
  {
    lat: 41.0901834,
    lng: -73.541744,
    squareFootage: "342000",
    propertyType: "Hotel",
    totalCosts: "17354640",
    valueOfReclassifiedAssets: 13500229,
    depreciableAssets: 25,
  },
  {
    lat: 40.7231264,
    lng: -74.1416127,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8129402",
    valueOfReclassifiedAssets: 6599110,
    depreciableAssets: 24,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "1009410",
    propertyType: "Multifamily",
    totalCosts: "2705773",
    valueOfReclassifiedAssets: 665651,
    depreciableAssets: 32,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4845195",
    valueOfReclassifiedAssets: 3111323,
    depreciableAssets: 0,
  },
  {
    lat: 40.7987048,
    lng: -73.6506776,
    squareFootage: "17352",
    propertyType: "Hotel",
    totalCosts: "11350000",
    valueOfReclassifiedAssets: 3527652,
    depreciableAssets: 37,
  },
  {
    lat: 40.7224092,
    lng: -73.9095279,
    squareFootage: "20906",
    propertyType: "Multifamily",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 2034572,
    depreciableAssets: 32,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "31135",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 1485194,
    depreciableAssets: 14,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "1009410",
    propertyType: "Multifamily",
    totalCosts: "2844770",
    valueOfReclassifiedAssets: 669437,
    depreciableAssets: 32,
  },
  {
    lat: 40.7506343,
    lng: -73.8477874,
    squareFootage: "46590",
    propertyType: "Hotel",
    totalCosts: "21800000",
    valueOfReclassifiedAssets: 4519957,
    depreciableAssets: 26,
  },
  {
    lat: 41.0901834,
    lng: -73.541744,
    squareFootage: "342000",
    propertyType: "Hotel",
    totalCosts: "3796872",
    valueOfReclassifiedAssets: 2953599,
    depreciableAssets: 25,
  },
  {
    lat: 40.5749261,
    lng: -73.9859414,
    squareFootage: "18371",
    propertyType: "Multifamily",
    totalCosts: "68000000",
    valueOfReclassifiedAssets: 61200000,
    depreciableAssets: 100,
  },
  {
    lat: 40.6560436,
    lng: -74.0079781,
    squareFootage: "18433",
    propertyType: "Hotel",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1027559,
    depreciableAssets: 20,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "1009410",
    propertyType: "Multifamily",
    totalCosts: "4810260",
    valueOfReclassifiedAssets: 1242610,
    depreciableAssets: 32,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "15300",
    propertyType: "Office",
    totalCosts: "15021374",
    valueOfReclassifiedAssets: 1729445,
    depreciableAssets: 13,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8825",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 875841,
    depreciableAssets: 21,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7540",
    propertyType: "Multifamily",
    totalCosts: "4554000",
    valueOfReclassifiedAssets: 617555,
    depreciableAssets: 16,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "90000",
    propertyType: "Multifamily",
    totalCosts: "14694213",
    valueOfReclassifiedAssets: 1606353,
    depreciableAssets: 12,
  },
  {
    lat: 30.012642,
    lng: -90.1922645,
    squareFootage: "13570",
    propertyType: "Retail",
    totalCosts: "9250000",
    valueOfReclassifiedAssets: 2875574,
    depreciableAssets: 36,
  },
  {
    lat: 40.9505752,
    lng: -73.7301331,
    squareFootage: "5893",
    propertyType: "Specialty",
    totalCosts: "5945282",
    valueOfReclassifiedAssets: 777029,
    depreciableAssets: 14,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "3213",
    propertyType: "Retail",
    totalCosts: "6852979",
    valueOfReclassifiedAssets: 5502979,
    depreciableAssets: 100,
  },
  {
    lat: 40.7452159,
    lng: -73.5711912,
    squareFootage: "15335",
    propertyType: "Retail",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1643702,
    depreciableAssets: 26,
  },
  {
    lat: 40.7245678,
    lng: -73.8457658,
    squareFootage: "48100",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 3109087,
    depreciableAssets: 25,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "39534",
    propertyType: "Multifamily",
    totalCosts: "11450000",
    valueOfReclassifiedAssets: 1965995,
    depreciableAssets: 21,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "2773",
    propertyType: "Multifamily",
    totalCosts: "5449161",
    valueOfReclassifiedAssets: 894626,
    depreciableAssets: 18,
  },
  {
    lat: 41.9755524,
    lng: -87.803382,
    squareFootage: "15330",
    propertyType: "Retail",
    totalCosts: "8150000",
    valueOfReclassifiedAssets: 1708617,
    depreciableAssets: 25,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "90000",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 1473479,
    depreciableAssets: 12,
  },
  {
    lat: 40.9196897,
    lng: -73.7889689,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1508664",
    valueOfReclassifiedAssets: 898870,
    depreciableAssets: 0,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "55556",
    propertyType: "Office",
    totalCosts: "6854615",
    valueOfReclassifiedAssets: 395798,
    depreciableAssets: 7,
  },
  {
    lat: 41.8703314,
    lng: -87.62357420000001,
    squareFootage: "78881",
    propertyType: "Office",
    totalCosts: "10842652",
    valueOfReclassifiedAssets: 576409,
    depreciableAssets: 6,
  },
  {
    lat: 40.8844007,
    lng: -74.30595459999999,
    squareFootage: "38098",
    propertyType: "Multifamily",
    totalCosts: "2653358",
    valueOfReclassifiedAssets: 335136,
    depreciableAssets: 16,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "20448",
    propertyType: "Multifamily",
    totalCosts: "11700000",
    valueOfReclassifiedAssets: 1434788,
    depreciableAssets: 14,
  },
  {
    lat: 42.6568958,
    lng: -73.7463147,
    squareFootage: "14952",
    propertyType: "Office",
    totalCosts: "13787541",
    valueOfReclassifiedAssets: 2030499,
    depreciableAssets: 16,
  },
  {
    lat: 40.8721452,
    lng: -73.92128579999999,
    squareFootage: "17350",
    propertyType: "Multifamily",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 733362,
    depreciableAssets: 20,
  },
  {
    lat: 39.407567,
    lng: -76.6354952,
    squareFootage: "8207",
    propertyType: "Retail",
    totalCosts: "12100000",
    valueOfReclassifiedAssets: 2884632,
    depreciableAssets: 26,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1848499",
    valueOfReclassifiedAssets: 1295075,
    depreciableAssets: 31,
  },
  {
    lat: 39.1347147,
    lng: -94.5160396,
    squareFootage: "110268",
    propertyType: "Office",
    totalCosts: "24954799",
    valueOfReclassifiedAssets: 8959933,
    depreciableAssets: 45,
  },
  {
    lat: 42.6568958,
    lng: -73.7463147,
    squareFootage: "7500",
    propertyType: "Office",
    totalCosts: "25078753",
    valueOfReclassifiedAssets: 6025446,
    depreciableAssets: 27,
  },
  {
    lat: 38.0083802,
    lng: -121.7993168,
    squareFootage: "245760",
    propertyType: "Retail",
    totalCosts: "12768505",
    valueOfReclassifiedAssets: 3571143,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "119922",
    propertyType: "Industrial",
    totalCosts: "7938677",
    valueOfReclassifiedAssets: 1294908,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "20200",
    propertyType: "Multifamily",
    totalCosts: "20518698",
    valueOfReclassifiedAssets: 1614573,
    depreciableAssets: 9,
  },
  {
    lat: 40.6483744,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "369288",
    valueOfReclassifiedAssets: 259752,
    depreciableAssets: 33,
  },
  {
    lat: 38.4326732,
    lng: -95.2057909,
    squareFootage: "30960",
    propertyType: "Multifamily",
    totalCosts: "17476000",
    valueOfReclassifiedAssets: 2268133,
    depreciableAssets: 17,
  },
  {
    lat: 32.9117167,
    lng: -96.9907197,
    squareFootage: "456454",
    propertyType: "Multifamily",
    totalCosts: "54603131",
    valueOfReclassifiedAssets: 13363840,
    depreciableAssets: 27,
  },
  {
    lat: 40.6483744,
    lng: -73.9800645,
    squareFootage: "21840",
    propertyType: "Multifamily",
    totalCosts: "5164192",
    valueOfReclassifiedAssets: 468772,
    depreciableAssets: 11,
  },
  {
    lat: 37.31317,
    lng: -122.0723816,
    squareFootage: "100352",
    propertyType: "Office",
    totalCosts: "66865000",
    valueOfReclassifiedAssets: 6571755,
    depreciableAssets: 14,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "30126",
    propertyType: "Industrial",
    totalCosts: "21608215",
    valueOfReclassifiedAssets: 860454,
    depreciableAssets: 5,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "56634",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 2049906,
    depreciableAssets: 15,
  },
  {
    lat: 33.87967,
    lng: -78.97407249999999,
    squareFootage: "62528",
    propertyType: "Retail",
    totalCosts: "3187084",
    valueOfReclassifiedAssets: 2341330,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "3200",
    propertyType: "Retail",
    totalCosts: "21249236",
    valueOfReclassifiedAssets: 3109763,
    depreciableAssets: 19,
  },
  {
    lat: 40.699863,
    lng: -74.32941989999999,
    squareFootage: "122677",
    propertyType: "Industrial",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 1611404,
    depreciableAssets: 18,
  },
  {
    lat: 38.4326732,
    lng: -95.2057909,
    squareFootage: "30960",
    propertyType: "Multifamily",
    totalCosts: "17476000",
    valueOfReclassifiedAssets: 2382517,
    depreciableAssets: 17,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "1859",
    propertyType: "Specialty",
    totalCosts: "5038181",
    valueOfReclassifiedAssets: 4282454,
    depreciableAssets: 100,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "3213",
    propertyType: "Retail",
    totalCosts: "5502979",
    valueOfReclassifiedAssets: 5266525,
    depreciableAssets: 0,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "2018",
    propertyType: "Multifamily",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 2310690,
    depreciableAssets: 26,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "22000",
    propertyType: "Multifamily",
    totalCosts: "2107000",
    valueOfReclassifiedAssets: 376099,
    depreciableAssets: 21,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "56634",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 1817873,
    depreciableAssets: 15,
  },
  {
    lat: 41.1668743,
    lng: -111.9880199,
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "69566668",
    valueOfReclassifiedAssets: 15938977,
    depreciableAssets: 27,
  },
  {
    lat: 30.5547139,
    lng: -84.0801613,
    squareFootage: "84064",
    propertyType: "Retail",
    totalCosts: "6971766",
    valueOfReclassifiedAssets: 2478600,
    depreciableAssets: 42,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "18040",
    propertyType: "Office",
    totalCosts: "6298500",
    valueOfReclassifiedAssets: 249765,
    depreciableAssets: 5,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "50000",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 1719446,
    depreciableAssets: 15,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5100",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1515866,
    depreciableAssets: 18,
  },
  {
    lat: 41.7665502,
    lng: -72.6757236,
    squareFootage: "314762",
    propertyType: "Office",
    totalCosts: "49000000",
    valueOfReclassifiedAssets: 6206112,
    depreciableAssets: 15,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "3851",
    propertyType: "Office",
    totalCosts: "3430007",
    valueOfReclassifiedAssets: 3283300,
    depreciableAssets: 0,
  },
  {
    lat: 40.6440775,
    lng: -73.5888575,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1768560",
    valueOfReclassifiedAssets: 1353640,
    depreciableAssets: 0,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "56634",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 1724931,
    depreciableAssets: 15,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "29520",
    propertyType: "Multifamily",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 875637,
    depreciableAssets: 10,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "211879",
    propertyType: "Office",
    totalCosts: "10458192",
    valueOfReclassifiedAssets: 1413373,
    depreciableAssets: 22,
  },
  {
    lat: 33.1945063,
    lng: -111.4514369,
    squareFootage: "33256",
    propertyType: "Retail",
    totalCosts: "4195006",
    valueOfReclassifiedAssets: 1651604,
    depreciableAssets: 46,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "18040",
    propertyType: "Office",
    totalCosts: "6451500",
    valueOfReclassifiedAssets: 255832,
    depreciableAssets: 5,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "5184",
    propertyType: "Multifamily",
    totalCosts: "675000",
    valueOfReclassifiedAssets: 87816,
    depreciableAssets: 15,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "2018",
    propertyType: "Multifamily",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 2310690,
    depreciableAssets: 26,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "30000",
    propertyType: "Industrial",
    totalCosts: "4558167",
    valueOfReclassifiedAssets: 329378,
    depreciableAssets: 8,
  },
  {
    lat: 45.53043659999999,
    lng: -122.371366,
    squareFootage: "164235",
    propertyType: "Retail",
    totalCosts: "29090736",
    valueOfReclassifiedAssets: 8057215,
    depreciableAssets: 33,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "25160",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1258434,
    depreciableAssets: 20,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "29520",
    propertyType: "Multifamily",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 895172,
    depreciableAssets: 10,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "9500",
    propertyType: "Multifamily",
    totalCosts: "743589",
    valueOfReclassifiedAssets: 80170,
    depreciableAssets: 12,
  },
  {
    lat: 40.7732937,
    lng: -73.9286341,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15299918",
    valueOfReclassifiedAssets: 13231840,
    depreciableAssets: 17,
  },
  {
    lat: 33.6991526,
    lng: -116.9793554,
    squareFootage: "58276",
    propertyType: "Retail",
    totalCosts: "4911428",
    valueOfReclassifiedAssets: 1035208,
    depreciableAssets: 25,
  },
  {
    lat: 40.8721452,
    lng: -73.92128579999999,
    squareFootage: "113075",
    propertyType: "Multifamily",
    totalCosts: "63600000",
    valueOfReclassifiedAssets: 8304281,
    depreciableAssets: 16,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "211879",
    propertyType: "Office",
    totalCosts: "4053322",
    valueOfReclassifiedAssets: 762141,
    depreciableAssets: 22,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11706481",
    valueOfReclassifiedAssets: 9159441,
    depreciableAssets: 22,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "5794",
    propertyType: "Multifamily",
    totalCosts: "4550000",
    valueOfReclassifiedAssets: 317033,
    depreciableAssets: 8,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "25160",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1275908,
    depreciableAssets: 20,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "29520",
    propertyType: "Multifamily",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 895172,
    depreciableAssets: 10,
  },
  {
    lat: 40.7135097,
    lng: -73.9859414,
    squareFootage: "10230",
    propertyType: "Multifamily",
    totalCosts: "5060000",
    valueOfReclassifiedAssets: 684989,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "13715",
    propertyType: "Multifamily",
    totalCosts: "16200000",
    valueOfReclassifiedAssets: 4296573,
    depreciableAssets: 31,
  },
  {
    lat: 37.0193161,
    lng: -94.5005808,
    squareFootage: "120933",
    propertyType: "Office",
    totalCosts: "16064157",
    valueOfReclassifiedAssets: 3073476,
    depreciableAssets: 26,
  },
  {
    lat: 34.958476,
    lng: -85.2825938,
    squareFootage: "48245",
    propertyType: "Multifamily",
    totalCosts: "3330000",
    valueOfReclassifiedAssets: 1092248,
    depreciableAssets: 35,
  },
  {
    lat: 39.2053078,
    lng: -86.4794359,
    squareFootage: "21274",
    propertyType: "Multifamily",
    totalCosts: "2825000",
    valueOfReclassifiedAssets: 752514,
    depreciableAssets: 31,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "8700",
    propertyType: "Multifamily",
    totalCosts: "2274000",
    valueOfReclassifiedAssets: 274783,
    depreciableAssets: 15,
  },
  {
    lat: 33.380425,
    lng: -81.97529949999999,
    squareFootage: "323120",
    propertyType: "Retail",
    totalCosts: "8165601",
    valueOfReclassifiedAssets: 1661300,
    depreciableAssets: 24,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "25160",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1207801,
    depreciableAssets: 20,
  },
  {
    lat: 40.7070784,
    lng: -74.956058,
    squareFootage: "296826",
    propertyType: "Office",
    totalCosts: "19900000",
    valueOfReclassifiedAssets: 5490372,
    depreciableAssets: 29,
  },
  {
    lat: 46.73708509999999,
    lng: -92.1659686,
    squareFootage: "43856",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 999575,
    depreciableAssets: 23,
  },
  {
    lat: 40.7250632,
    lng: -73.9976946,
    squareFootage: "3200",
    propertyType: "Retail",
    totalCosts: "20451703",
    valueOfReclassifiedAssets: 2949208,
    depreciableAssets: 18,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "15596",
    propertyType: "Multifamily",
    totalCosts: "15800000",
    valueOfReclassifiedAssets: 1502277,
    depreciableAssets: 12,
  },
  {
    lat: 33.9253024,
    lng: -84.38574419999999,
    squareFootage: "668067",
    propertyType: "Multifamily",
    totalCosts: "89300000",
    valueOfReclassifiedAssets: 12214634,
    depreciableAssets: 19,
  },
  {
    lat: 39.2183952,
    lng: -86.5703554,
    squareFootage: "9684",
    propertyType: "Multifamily",
    totalCosts: "975000",
    valueOfReclassifiedAssets: 241296,
    depreciableAssets: 29,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "8800",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 268372,
    depreciableAssets: 15,
  },
  {
    lat: 32.495281,
    lng: -85.01295089999999,
    squareFootage: "173292",
    propertyType: "Multifamily",
    totalCosts: "2859465",
    valueOfReclassifiedAssets: 885515,
    depreciableAssets: 36,
  },
  {
    lat: 45.09643699999999,
    lng: -94.55066099999999,
    squareFootage: "47450",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1310198,
    depreciableAssets: 32,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "148287",
    propertyType: "Retail",
    totalCosts: "19565838",
    valueOfReclassifiedAssets: 5876994,
    depreciableAssets: 35,
  },
  {
    lat: 40.7930239,
    lng: -74.3235539,
    squareFootage: "14480",
    propertyType: "Industrial",
    totalCosts: "1325000",
    valueOfReclassifiedAssets: 296024,
    depreciableAssets: 27,
  },
  {
    lat: 41.4932198,
    lng: -81.46085599999999,
    squareFootage: "343044",
    propertyType: "Office",
    totalCosts: "60000000",
    valueOfReclassifiedAssets: 12888443,
    depreciableAssets: 28,
  },
  {
    lat: 39.2053078,
    lng: -86.4794359,
    squareFootage: "125364",
    propertyType: "Multifamily",
    totalCosts: "13320000",
    valueOfReclassifiedAssets: 3854337,
    depreciableAssets: 34,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "24144",
    propertyType: "Multifamily",
    totalCosts: "14100000",
    valueOfReclassifiedAssets: 1647820,
    depreciableAssets: 15,
  },
  {
    lat: 33.1632842,
    lng: -96.5890166,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "45400000",
    valueOfReclassifiedAssets: 13077882,
    depreciableAssets: 34,
  },
  {
    lat: 40.8721452,
    lng: -73.92128579999999,
    squareFootage: "113075",
    propertyType: "Multifamily",
    totalCosts: "63600000",
    valueOfReclassifiedAssets: 8304281,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24598199",
    valueOfReclassifiedAssets: 19994870,
    depreciableAssets: 20,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "63680",
    propertyType: "Office",
    totalCosts: "539715",
    valueOfReclassifiedAssets: 69293,
    depreciableAssets: 15,
  },
  {
    lat: 44.1705644,
    lng: -92.1646722,
    squareFootage: "24500",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1254030,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "58371",
    propertyType: "Multifamily",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 2514781,
    depreciableAssets: 26,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "127986",
    propertyType: "Retail",
    totalCosts: "10549872",
    valueOfReclassifiedAssets: 2386840,
    depreciableAssets: 27,
  },
  {
    lat: 40.04524420000001,
    lng: -75.63826569999999,
    squareFootage: "440315",
    propertyType: "Multifamily",
    totalCosts: "101000000",
    valueOfReclassifiedAssets: 27532355,
    depreciableAssets: 36,
  },
  {
    lat: 34.958476,
    lng: -85.2825938,
    squareFootage: "48245",
    propertyType: "Multifamily",
    totalCosts: "2664000",
    valueOfReclassifiedAssets: 873798,
    depreciableAssets: 35,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "175000",
    propertyType: "Multifamily",
    totalCosts: "60250000",
    valueOfReclassifiedAssets: 11268776,
    depreciableAssets: 22,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "14880",
    propertyType: "Multifamily",
    totalCosts: "16300000",
    valueOfReclassifiedAssets: 3162738,
    depreciableAssets: 24,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "250486",
    propertyType: "Office",
    totalCosts: "295027000",
    valueOfReclassifiedAssets: 22946204,
    depreciableAssets: 10,
  },
  {
    lat: 32.0302037,
    lng: -96.5143087,
    squareFootage: "1453620",
    propertyType: "Industrial",
    totalCosts: "13706996",
    valueOfReclassifiedAssets: 2196105,
    depreciableAssets: 18,
  },
  {
    lat: 35.6450428,
    lng: -77.4242972,
    squareFootage: "400314",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 2924583,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "41501009",
    valueOfReclassifiedAssets: 29264903,
    depreciableAssets: 29,
  },
  {
    lat: 43.7013054,
    lng: -87.7271123,
    squareFootage: "88780",
    propertyType: "Retail",
    totalCosts: "4053728",
    valueOfReclassifiedAssets: 1158856,
    depreciableAssets: 34,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "2082",
    propertyType: "Multifamily",
    totalCosts: "4975000",
    valueOfReclassifiedAssets: 3800451,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "33870",
    propertyType: "Multifamily",
    totalCosts: "4475000",
    valueOfReclassifiedAssets: 1020521,
    depreciableAssets: 27,
  },
  {
    lat: 34.801177,
    lng: -103.1880043,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2358984",
    valueOfReclassifiedAssets: 1966581,
    depreciableAssets: 0,
  },
  {
    lat: 40.8721452,
    lng: -73.92128579999999,
    squareFootage: "17350",
    propertyType: "Multifamily",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 670419,
    depreciableAssets: 20,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "26332",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2780000",
    valueOfReclassifiedAssets: 751987,
    depreciableAssets: 35,
  },
  {
    lat: 40.6772802,
    lng: -74.0094471,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12242098",
    valueOfReclassifiedAssets: 11530147,
    depreciableAssets: 8,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "8600",
    propertyType: "Multifamily",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 1463015,
    depreciableAssets: 18,
  },
  {
    lat: 42.47198969999999,
    lng: -83.4536844,
    squareFootage: "44916",
    propertyType: "Industrial",
    totalCosts: "2310000",
    valueOfReclassifiedAssets: 603103,
    depreciableAssets: 29,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "63680",
    propertyType: "Office",
    totalCosts: "169127",
    valueOfReclassifiedAssets: 21714,
    depreciableAssets: 15,
  },
  {
    lat: 40.7388319,
    lng: -73.98153370000001,
    squareFootage: "2305",
    propertyType: "Multifamily",
    totalCosts: "5975000",
    valueOfReclassifiedAssets: 4375809,
    depreciableAssets: 0,
  },
  {
    lat: 39.0455425,
    lng: -76.90668289999999,
    squareFootage: "58033",
    propertyType: "Industrial",
    totalCosts: "90750000",
    valueOfReclassifiedAssets: 19322308,
    depreciableAssets: 23,
  },
  {
    lat: 40.7989068,
    lng: -74.1885825,
    squareFootage: "97823",
    propertyType: "Office",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 6092467,
    depreciableAssets: 23,
  },
  {
    lat: 46.7534028,
    lng: -92.0681618,
    squareFootage: "74816",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7071800",
    valueOfReclassifiedAssets: 5476189,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "186835",
    propertyType: "Industrial",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1412040,
    depreciableAssets: 26,
  },
  {
    lat: 31.5726497,
    lng: -97.11978669999999,
    squareFootage: "173492",
    propertyType: "Multifamily",
    totalCosts: "14150591",
    valueOfReclassifiedAssets: 3905682,
    depreciableAssets: 33,
  },
  {
    lat: 35.6450428,
    lng: -77.4242972,
    squareFootage: "469934",
    propertyType: "Multifamily",
    totalCosts: "17800000",
    valueOfReclassifiedAssets: 4338322,
    depreciableAssets: 29,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 1177956,
    depreciableAssets: 17,
  },
  {
    lat: 35.6733387,
    lng: -77.9053182,
    squareFootage: "127200",
    propertyType: "Industrial",
    totalCosts: "2340000",
    valueOfReclassifiedAssets: 2203396,
    depreciableAssets: 15,
  },
  {
    lat: 33.7946333,
    lng: -84.44877199999999,
    squareFootage: "295790",
    propertyType: "Multifamily",
    totalCosts: "57500000",
    valueOfReclassifiedAssets: 13716993,
    depreciableAssets: 26,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "9897",
    propertyType: "Multifamily",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 779961,
    depreciableAssets: 16,
  },
  {
    lat: 42.9734553,
    lng: -78.713608,
    squareFootage: "22736",
    propertyType: "Industrial",
    totalCosts: "17622289",
    valueOfReclassifiedAssets: 2733425,
    depreciableAssets: 18,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1735553",
    valueOfReclassifiedAssets: 1229962,
    depreciableAssets: 36,
  },
  {
    lat: 35.6450428,
    lng: -77.4242972,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17800000",
    valueOfReclassifiedAssets: 4695596,
    depreciableAssets: 29,
  },
  {
    lat: 40.9342921,
    lng: -73.7536695,
    squareFootage: "1122",
    propertyType: "Multifamily",
    totalCosts: "2420000",
    valueOfReclassifiedAssets: 1819560,
    depreciableAssets: 0,
  },
  {
    lat: 37.31317,
    lng: -122.0723816,
    squareFootage: "88580",
    propertyType: "Office",
    totalCosts: "66250000",
    valueOfReclassifiedAssets: 7618590,
    depreciableAssets: 23,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "64000",
    propertyType: "Office",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 2643430,
    depreciableAssets: 16,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "532325",
    propertyType: "Office",
    totalCosts: "60000000",
    valueOfReclassifiedAssets: 10496269,
    depreciableAssets: 21,
  },
  {
    lat: 35.4023416,
    lng: -79.1322744,
    squareFootage: "339266",
    propertyType: "Industrial",
    totalCosts: "1102500",
    valueOfReclassifiedAssets: 1061417,
    depreciableAssets: 17,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "103000",
    propertyType: "Multifamily",
    totalCosts: "17307622",
    valueOfReclassifiedAssets: 14858194,
    depreciableAssets: 15,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "5884",
    propertyType: "Retail",
    totalCosts: "21500000",
    valueOfReclassifiedAssets: 2995925,
    depreciableAssets: 18,
  },
  {
    lat: 35.1323787,
    lng: -90.004663,
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9284286",
    valueOfReclassifiedAssets: 2533849,
    depreciableAssets: 29,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1870000",
    valueOfReclassifiedAssets: 399318,
    depreciableAssets: 30,
  },
  {
    lat: 39.9029533,
    lng: -86.0223611,
    squareFootage: "104906",
    propertyType: "Retail",
    totalCosts: "6911903",
    valueOfReclassifiedAssets: 1981977,
    depreciableAssets: 34,
  },
  {
    lat: 32.3835021,
    lng: -81.8005724,
    squareFootage: "248586",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2635778,
    depreciableAssets: 28,
  },
  {
    lat: 34.0820025,
    lng: -83.94266979999999,
    squareFootage: "57631",
    propertyType: "Multifamily",
    totalCosts: "55750000",
    valueOfReclassifiedAssets: 10334174,
    depreciableAssets: 22,
  },
  {
    lat: 44.0923096,
    lng: -87.7172616,
    squareFootage: "112941",
    propertyType: "Retail",
    totalCosts: "6068948",
    valueOfReclassifiedAssets: 1535113,
    depreciableAssets: 29,
  },
  {
    lat: 33.0067086,
    lng: -96.99609249999999,
    squareFootage: "291408",
    propertyType: "Multifamily",
    totalCosts: "32200000",
    valueOfReclassifiedAssets: 8409202,
    depreciableAssets: 28,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1870000",
    valueOfReclassifiedAssets: 399318,
    depreciableAssets: 30,
  },
  {
    lat: 35.4023416,
    lng: -79.1322744,
    squareFootage: "339266",
    propertyType: "Industrial",
    totalCosts: "388650",
    valueOfReclassifiedAssets: 374167,
    depreciableAssets: 17,
  },
  {
    lat: 41.09912629999999,
    lng: -74.0123851,
    squareFootage: "608842",
    propertyType: "Multifamily",
    totalCosts: "147000000",
    valueOfReclassifiedAssets: 42431475,
    depreciableAssets: 31,
  },
  {
    lat: 39.1733025,
    lng: -77.1772737,
    squareFootage: "82247",
    propertyType: "Industrial",
    totalCosts: "5674815",
    valueOfReclassifiedAssets: 719323,
    depreciableAssets: 15,
  },
  {
    lat: 35.6450428,
    lng: -77.4242972,
    squareFootage: "400314",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 2702061,
    depreciableAssets: 28,
  },
  {
    lat: 42.9674954,
    lng: -85.95089229999999,
    squareFootage: "439675",
    propertyType: "Multifamily",
    totalCosts: "52000000",
    valueOfReclassifiedAssets: 10055212,
    depreciableAssets: 23,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "68000",
    propertyType: "Office",
    totalCosts: "20469323",
    valueOfReclassifiedAssets: 2872099,
    depreciableAssets: 16,
  },
  {
    lat: 45.7805621,
    lng: -108.5067071,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 3686104,
    depreciableAssets: 20,
  },
  {
    lat: 40.8208151,
    lng: -76.2131474,
    squareFootage: "64775",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6660000",
    valueOfReclassifiedAssets: 1468813,
    depreciableAssets: 24,
  },
  {
    lat: 40.09944249999999,
    lng: -74.9325683,
    squareFootage: "148920",
    propertyType: "Office",
    totalCosts: "11333333",
    valueOfReclassifiedAssets: 8337260,
    depreciableAssets: 21,
  },
  {
    lat: 42.22225,
    lng: -83.3966271,
    squareFootage: "664625",
    propertyType: "Industrial",
    totalCosts: "3564213",
    valueOfReclassifiedAssets: 912489,
    depreciableAssets: 31,
  },
  {
    lat: 27.8512042,
    lng: -82.6321376,
    squareFootage: "463979",
    propertyType: "Multifamily",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 4967730,
    depreciableAssets: 23,
  },
  {
    lat: 27.4399922,
    lng: -82.57808779999999,
    squareFootage: "207157",
    propertyType: "Multifamily",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 3521671,
    depreciableAssets: 26,
  },
  {
    lat: 39.9311729,
    lng: -75.0260667,
    squareFootage: "98000",
    propertyType: "Office",
    totalCosts: "3473234",
    valueOfReclassifiedAssets: 726901,
    depreciableAssets: 24,
  },
  {
    lat: 42.2427186,
    lng: -83.2184763,
    squareFootage: "613000",
    propertyType: "Industrial",
    totalCosts: "12151367",
    valueOfReclassifiedAssets: 1191669,
    depreciableAssets: 11,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "103000",
    propertyType: "Multifamily",
    totalCosts: "17307622",
    valueOfReclassifiedAssets: 14858194,
    depreciableAssets: 15,
  },
  {
    lat: 42.22225,
    lng: -83.3966271,
    squareFootage: "77508",
    propertyType: "Industrial",
    totalCosts: "2340000",
    valueOfReclassifiedAssets: 2124605,
    depreciableAssets: 30,
  },
  {
    lat: 40.8208151,
    lng: -76.2131474,
    squareFootage: "64775",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6784322",
    valueOfReclassifiedAssets: 1498877,
    depreciableAssets: 24,
  },
  {
    lat: 40.7826039,
    lng: -73.9506774,
    squareFootage: "279945",
    propertyType: "Multifamily",
    totalCosts: "92769301",
    valueOfReclassifiedAssets: 17055153,
    depreciableAssets: 21,
  },
  {
    lat: 42.40212280000001,
    lng: -83.6029116,
    squareFootage: "14242",
    propertyType: "Retail",
    totalCosts: "2809180",
    valueOfReclassifiedAssets: 968743,
    depreciableAssets: 41,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "23000",
    propertyType: "Multifamily",
    totalCosts: "6440125",
    valueOfReclassifiedAssets: 495212,
    depreciableAssets: 9,
  },
  {
    lat: 33.7717008,
    lng: -84.3726049,
    squareFootage: "76848",
    propertyType: "Office",
    totalCosts: "5582890",
    valueOfReclassifiedAssets: 685708,
    depreciableAssets: 14,
  },
  {
    lat: 38.0223462,
    lng: -87.5741877,
    squareFootage: "173310",
    propertyType: "Multifamily",
    totalCosts: "6750000",
    valueOfReclassifiedAssets: 1714775,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "306194",
    propertyType: "Office",
    totalCosts: "98500000",
    valueOfReclassifiedAssets: 21368009,
    depreciableAssets: 24,
  },
  {
    lat: 40.782392,
    lng: -74.0711359,
    squareFootage: "249130",
    propertyType: "Hotel",
    totalCosts: "360131",
    valueOfReclassifiedAssets: 249602,
    depreciableAssets: 34,
  },
  {
    lat: 35.89872340000001,
    lng: -86.96240859999999,
    squareFootage: "54984",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 796342,
    depreciableAssets: 32,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "63180",
    propertyType: "Office",
    totalCosts: "3671504",
    valueOfReclassifiedAssets: 290615,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "470164",
    valueOfReclassifiedAssets: 389179,
    depreciableAssets: 0,
  },
  {
    lat: 28.1537767,
    lng: -82.4156829,
    squareFootage: "164856",
    propertyType: "Multifamily",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 4011863,
    depreciableAssets: 30,
  },
  {
    lat: 42.86433539999999,
    lng: -87.913867,
    squareFootage: "94140",
    propertyType: "Retail",
    totalCosts: "7087991",
    valueOfReclassifiedAssets: 2314875,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "225711",
    valueOfReclassifiedAssets: 161736,
    depreciableAssets: 0,
  },
  {
    lat: 41.3884635,
    lng: -81.65829939999999,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "20517087",
    valueOfReclassifiedAssets: 3981214,
    depreciableAssets: 23,
  },
  {
    lat: 42.33827549999999,
    lng: -83.1756188,
    squareFootage: "157225",
    propertyType: "Retail",
    totalCosts: "20955704",
    valueOfReclassifiedAssets: 6461939,
    depreciableAssets: 36,
  },
  {
    lat: 40.782392,
    lng: -74.0711359,
    squareFootage: "249130",
    propertyType: "Hotel",
    totalCosts: "336116",
    valueOfReclassifiedAssets: 232958,
    depreciableAssets: 34,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "107175",
    propertyType: "Multifamily",
    totalCosts: "27626477",
    valueOfReclassifiedAssets: 2654354,
    depreciableAssets: 12,
  },
  {
    lat: 36.1258713,
    lng: -95.85121,
    squareFootage: "28946",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2490438",
    valueOfReclassifiedAssets: 824421,
    depreciableAssets: 39,
  },
  {
    lat: 45.5064511,
    lng: -122.7756216,
    squareFootage: "406160",
    propertyType: "Multifamily",
    totalCosts: "24350000",
    valueOfReclassifiedAssets: 4769840,
    depreciableAssets: 25,
  },
  {
    lat: 28.6235111,
    lng: -81.4246931,
    squareFootage: "296276",
    propertyType: "Multifamily",
    totalCosts: "44350000",
    valueOfReclassifiedAssets: 9562914,
    depreciableAssets: 25,
  },
  {
    lat: 34.9838584,
    lng: -82.33433749999999,
    squareFootage: "28952",
    propertyType: "Retail",
    totalCosts: "3825733",
    valueOfReclassifiedAssets: 1201469,
    depreciableAssets: 37,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "281230",
    propertyType: "Office",
    totalCosts: "15563504",
    valueOfReclassifiedAssets: 2966132,
    depreciableAssets: 23,
  },
  {
    lat: 41.0901834,
    lng: -73.541744,
    squareFootage: "342000",
    propertyType: "Hotel",
    totalCosts: "7450218",
    valueOfReclassifiedAssets: 6818747,
    depreciableAssets: 13,
  },
  {
    lat: 36.1258713,
    lng: -95.85121,
    squareFootage: "28946",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2490438",
    valueOfReclassifiedAssets: 824421,
    depreciableAssets: 39,
  },
  {
    lat: 37.5410261,
    lng: -77.43864289999999,
    squareFootage: "88100",
    propertyType: "Multifamily",
    totalCosts: "6828231",
    valueOfReclassifiedAssets: 779698,
    depreciableAssets: 13,
  },
  {
    lat: 28.01574,
    lng: -82.5510469,
    squareFootage: "322016",
    propertyType: "Multifamily",
    totalCosts: "25650000",
    valueOfReclassifiedAssets: 5648422,
    depreciableAssets: 25,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8530026",
    valueOfReclassifiedAssets: 7137163,
    depreciableAssets: 17,
  },
  {
    lat: 39.0455425,
    lng: -76.90668289999999,
    squareFootage: "79984",
    propertyType: "Industrial",
    totalCosts: "5198578",
    valueOfReclassifiedAssets: 665143,
    depreciableAssets: 15,
  },
  {
    lat: 45.5912511,
    lng: -73.4312688,
    squareFootage: "66669",
    propertyType: "Office",
    totalCosts: "12508825",
    valueOfReclassifiedAssets: 2131302,
    depreciableAssets: 20,
  },
  {
    lat: 41.0901834,
    lng: -73.541744,
    squareFootage: "342000",
    propertyType: "Hotel",
    totalCosts: "1709053",
    valueOfReclassifiedAssets: 1545165,
    depreciableAssets: 15,
  },
  {
    lat: 35.45897739999999,
    lng: -97.4056928,
    squareFootage: "29938",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6137151",
    valueOfReclassifiedAssets: 2369930,
    depreciableAssets: 45,
  },
  {
    lat: 34.0386656,
    lng: -118.4221519,
    squareFootage: "11859",
    propertyType: "Specialty",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2411565,
    depreciableAssets: 34,
  },
  {
    lat: 37.5621276,
    lng: -77.3668965,
    squareFootage: "60000",
    propertyType: "Multifamily",
    totalCosts: "7829786",
    valueOfReclassifiedAssets: 1481770,
    depreciableAssets: 23,
  },
  {
    lat: 41.1865502,
    lng: -73.195196,
    squareFootage: "233802",
    propertyType: "Industrial",
    totalCosts: "21373111",
    valueOfReclassifiedAssets: 18281973,
    depreciableAssets: 15,
  },
  {
    lat: 39.98560690000001,
    lng: -81.909826,
    squareFootage: "422881",
    propertyType: "Retail",
    totalCosts: "32309297",
    valueOfReclassifiedAssets: 10988200,
    depreciableAssets: 40,
  },
  {
    lat: 35.4355991,
    lng: -97.51930929999999,
    squareFootage: "27116",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4802988",
    valueOfReclassifiedAssets: 1817385,
    depreciableAssets: 44,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "8760",
    propertyType: "Multifamily",
    totalCosts: "6350000",
    valueOfReclassifiedAssets: 1104414,
    depreciableAssets: 21,
  },
  {
    lat: 42.7795584,
    lng: -71.51304449999999,
    squareFootage: "511550",
    propertyType: "Multifamily",
    totalCosts: "66933277",
    valueOfReclassifiedAssets: 14444018,
    depreciableAssets: 26,
  },
  {
    lat: 28.5542151,
    lng: -81.34544170000001,
    squareFootage: "582899",
    propertyType: "Multifamily",
    totalCosts: "97100000",
    valueOfReclassifiedAssets: 20804360,
    depreciableAssets: 27,
  },
  {
    lat: 37.5464259,
    lng: -77.46446069999999,
    squareFootage: "92930",
    propertyType: "Multifamily",
    totalCosts: "8360030",
    valueOfReclassifiedAssets: 1266778,
    depreciableAssets: 19,
  },
  {
    lat: 46.7534028,
    lng: -92.0681618,
    squareFootage: "74816",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6616074",
    valueOfReclassifiedAssets: 5123289,
    depreciableAssets: 28,
  },
  {
    lat: 42.2280802,
    lng: -87.962884,
    squareFootage: "352416",
    propertyType: "Multifamily",
    totalCosts: "94000000",
    valueOfReclassifiedAssets: 18854713,
    depreciableAssets: 25,
  },
  {
    lat: 42.5964176,
    lng: -82.9985343,
    squareFootage: "247286",
    propertyType: "Office",
    totalCosts: "24157456",
    valueOfReclassifiedAssets: 7188668,
    depreciableAssets: 35,
  },
  {
    lat: 35.38163,
    lng: -78.9061921,
    squareFootage: "105898",
    propertyType: "Multifamily",
    totalCosts: "8100000",
    valueOfReclassifiedAssets: 2009225,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "372385",
    valueOfReclassifiedAssets: 327106,
    depreciableAssets: 0,
  },
  {
    lat: 37.5464259,
    lng: -77.46446069999999,
    squareFootage: "92930",
    propertyType: "Multifamily",
    totalCosts: "8360031",
    valueOfReclassifiedAssets: 1394811,
    depreciableAssets: 19,
  },
  {
    lat: 35.285623,
    lng: -77.4931403,
    squareFootage: "2018",
    propertyType: "Retail",
    totalCosts: "2320061",
    valueOfReclassifiedAssets: 624707,
    depreciableAssets: 32,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "31818",
    propertyType: "Multifamily",
    totalCosts: "17052000",
    valueOfReclassifiedAssets: 2367951,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "375446",
    valueOfReclassifiedAssets: 290309,
    depreciableAssets: 0,
  },
  {
    lat: 38.557323,
    lng: -90.5352356,
    squareFootage: "52640",
    propertyType: "Retail",
    totalCosts: "7532222",
    valueOfReclassifiedAssets: 2100065,
    depreciableAssets: 33,
  },
  {
    lat: 34.958476,
    lng: -85.2825938,
    squareFootage: "48245",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 2357732,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "296178",
    valueOfReclassifiedAssets: 225752,
    depreciableAssets: 0,
  },
  {
    lat: 46.7534028,
    lng: -92.0681618,
    squareFootage: "74816",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7071800",
    valueOfReclassifiedAssets: 5476189,
    depreciableAssets: 28,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9440",
    propertyType: "Multifamily",
    totalCosts: "5243000",
    valueOfReclassifiedAssets: 851769,
    depreciableAssets: 19,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "275460",
    propertyType: "Multifamily",
    totalCosts: "12400000",
    valueOfReclassifiedAssets: 2826215,
    depreciableAssets: 26,
  },
  {
    lat: 34.958476,
    lng: -85.2825938,
    squareFootage: "48245",
    propertyType: "Multifamily",
    totalCosts: "6660000",
    valueOfReclassifiedAssets: 2184495,
    depreciableAssets: 35,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "6025",
    propertyType: "Multifamily",
    totalCosts: "2050000",
    valueOfReclassifiedAssets: 242471,
    depreciableAssets: 14,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "275460",
    propertyType: "Multifamily",
    totalCosts: "12700467",
    valueOfReclassifiedAssets: 2894697,
    depreciableAssets: 26,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "275460",
    propertyType: "Multifamily",
    totalCosts: "12700467",
    valueOfReclassifiedAssets: 2894697,
    depreciableAssets: 26,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "26332",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2580829",
    valueOfReclassifiedAssets: 1724493,
    depreciableAssets: 39,
  },
  {
    lat: 32.5621719,
    lng: -84.9921549,
    squareFootage: "275460",
    propertyType: "Multifamily",
    totalCosts: "10795397",
    valueOfReclassifiedAssets: 2383868,
    depreciableAssets: 26,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "7516",
    propertyType: "Multifamily",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 285660,
    depreciableAssets: 9,
  },
  {
    lat: 43.5973497,
    lng: -95.5827334,
    squareFootage: "26332",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2780000",
    valueOfReclassifiedAssets: 751987,
    depreciableAssets: 35,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "12215",
    propertyType: "Multifamily",
    totalCosts: "7595000",
    valueOfReclassifiedAssets: 1279488,
    depreciableAssets: 19,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "7516",
    propertyType: "Multifamily",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 285660,
    depreciableAssets: 9,
  },
  {
    lat: 39.3403944,
    lng: -76.49679189999999,
    squareFootage: "268424",
    propertyType: "Multifamily",
    totalCosts: "4251940",
    valueOfReclassifiedAssets: 954376,
    depreciableAssets: 30,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "103000",
    propertyType: "Multifamily",
    totalCosts: "16515172",
    valueOfReclassifiedAssets: 14177894,
    depreciableAssets: 15,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "224786",
    propertyType: "Multifamily",
    totalCosts: "3190150",
    valueOfReclassifiedAssets: 677759,
    depreciableAssets: 33,
  },
  {
    lat: 39.3909858,
    lng: -76.5372625,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "4278504",
    valueOfReclassifiedAssets: 1122689,
    depreciableAssets: 34,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29286712",
    valueOfReclassifiedAssets: 25819263,
    depreciableAssets: 13,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1517479",
    valueOfReclassifiedAssets: 723086,
    depreciableAssets: 60,
  },
  {
    lat: 34.1672881,
    lng: -118.153228,
    squareFootage: "9910",
    propertyType: "Retail",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 296371,
    depreciableAssets: 20,
  },
  {
    lat: 41.44780009999999,
    lng: -74.0887576,
    squareFootage: "170000",
    propertyType: "Industrial",
    totalCosts: "2080000",
    valueOfReclassifiedAssets: 189028,
    depreciableAssets: 13,
  },
  {
    lat: 40.7593718,
    lng: -74.1181234,
    squareFootage: "120367",
    propertyType: "Office",
    totalCosts: "15651000",
    valueOfReclassifiedAssets: 1779213,
    depreciableAssets: 13,
  },
  {
    lat: 33.6288538,
    lng: -86.2767995,
    squareFootage: "49440",
    propertyType: "Hotel",
    totalCosts: "6309570",
    valueOfReclassifiedAssets: 1593579,
    depreciableAssets: 30,
  },
  {
    lat: 30.44151849999999,
    lng: -87.2518824,
    squareFootage: "57600",
    propertyType: "Industrial",
    totalCosts: "7990000",
    valueOfReclassifiedAssets: 2219911,
    depreciableAssets: 32,
  },
  {
    lat: 33.514162,
    lng: -112.1251372,
    squareFootage: "47175",
    propertyType: "Industrial",
    totalCosts: "1526430",
    valueOfReclassifiedAssets: 474944,
    depreciableAssets: 37,
  },
  {
    lat: 35.4318117,
    lng: -111.3798928,
    squareFootage: "15175",
    propertyType: "Industrial",
    totalCosts: "873681",
    valueOfReclassifiedAssets: 363379,
    depreciableAssets: 49,
  },
  {
    lat: 32.7521702,
    lng: -111.6720346,
    squareFootage: "56443",
    propertyType: "Industrial",
    totalCosts: "763230",
    valueOfReclassifiedAssets: 266087,
    depreciableAssets: 41,
  },
  {
    lat: 37.3178131,
    lng: -120.6429991,
    squareFootage: "74400",
    propertyType: "Industrial",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 555475,
    depreciableAssets: 32,
  },
  {
    lat: 32.1485656,
    lng: -110.9625854,
    squareFootage: "37300",
    propertyType: "Industrial",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 591177,
    depreciableAssets: 38,
  },
  {
    lat: 32.1701178,
    lng: -110.9476831,
    squareFootage: "31133",
    propertyType: "Industrial",
    totalCosts: "1795000",
    valueOfReclassifiedAssets: 595100,
    depreciableAssets: 39,
  },
  {
    lat: 35.1207927,
    lng: -112.1430215,
    squareFootage: "43375",
    propertyType: "Industrial",
    totalCosts: "2497259",
    valueOfReclassifiedAssets: 637666,
    depreciableAssets: 30,
  },
  {
    lat: 31.4421596,
    lng: -109.9020509,
    squareFootage: "30230",
    propertyType: "Industrial",
    totalCosts: "1740453",
    valueOfReclassifiedAssets: 538124,
    depreciableAssets: 36,
  },
  {
    lat: 32.0787726,
    lng: -110.9268201,
    squareFootage: "49950",
    propertyType: "Industrial",
    totalCosts: "1127356",
    valueOfReclassifiedAssets: 452318,
    depreciableAssets: 47,
  },
  {
    lat: 33.3934373,
    lng: -111.5647165,
    squareFootage: "64860",
    propertyType: "Industrial",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 954244,
    depreciableAssets: 33,
  },
  {
    lat: 32.1701178,
    lng: -110.9476831,
    squareFootage: "49237",
    propertyType: "Industrial",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 713913,
    depreciableAssets: 34,
  },
  {
    lat: 36.0651915,
    lng: -119.0167603,
    squareFootage: "97095",
    propertyType: "Industrial",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 1325600,
    depreciableAssets: 38,
  },
  {
    lat: 30.616559,
    lng: -96.31197429999999,
    squareFootage: "33573",
    propertyType: "Retail",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 945116,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5000",
    propertyType: "Industrial",
    totalCosts: "3095196",
    valueOfReclassifiedAssets: 1719297,
    depreciableAssets: 66,
  },
  {
    lat: 40.3925365,
    lng: -74.1181234,
    squareFootage: "17340",
    propertyType: "Industrial",
    totalCosts: "4522957",
    valueOfReclassifiedAssets: 2337806,
    depreciableAssets: 60,
  },
  {
    lat: 42.1883307,
    lng: -76.0043731,
    squareFootage: "83866",
    propertyType: "Retail",
    totalCosts: "10810000",
    valueOfReclassifiedAssets: 2575396,
    depreciableAssets: 28,
  },
  {
    lat: 28.0964047,
    lng: -81.9207423,
    squareFootage: "75084",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19638759",
    valueOfReclassifiedAssets: 5396337,
    depreciableAssets: 30,
  },
  {
    lat: 26.6105319,
    lng: -81.0754657,
    squareFootage: "48849",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10748780",
    valueOfReclassifiedAssets: 3485173,
    depreciableAssets: 35,
  },
  {
    lat: 27.8512042,
    lng: -82.6321376,
    squareFootage: "23412",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9462461",
    valueOfReclassifiedAssets: 3106423,
    depreciableAssets: 36,
  },
  {
    lat: 39.341965,
    lng: -84.406761,
    squareFootage: "339084",
    propertyType: "Multifamily",
    totalCosts: "35250000",
    valueOfReclassifiedAssets: 9624217,
    depreciableAssets: 32,
  },
  {
    lat: 42.0470275,
    lng: -88.38219889999999,
    squareFootage: "52305",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15200000",
    valueOfReclassifiedAssets: 2899956,
    depreciableAssets: 21,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18388146",
    valueOfReclassifiedAssets: 15720198,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14628269",
    valueOfReclassifiedAssets: 12505844,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "124559869",
    valueOfReclassifiedAssets: 106487395,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10667528",
    valueOfReclassifiedAssets: 9119769,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7026130",
    valueOfReclassifiedAssets: 6006705,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "222708671",
    valueOfReclassifiedAssets: 190395723,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3119557",
    valueOfReclassifiedAssets: 2666939,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "314795",
    valueOfReclassifiedAssets: 269121,
    depreciableAssets: 17,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13071799",
    valueOfReclassifiedAssets: 11092337,
    depreciableAssets: 17,
  },
  {
    lat: 38.0792424,
    lng: -100.7097867,
    squareFootage: "102648",
    propertyType: "Retail",
    totalCosts: "5950000",
    valueOfReclassifiedAssets: 5079969,
    depreciableAssets: 33,
  },
  {
    lat: 44.9666491,
    lng: -97.09551689999999,
    squareFootage: "303008",
    propertyType: "Retail",
    totalCosts: "5240252",
    valueOfReclassifiedAssets: 3815595,
    depreciableAssets: 39,
  },
  {
    lat: 47.5796497,
    lng: -94.91875689999999,
    squareFootage: "373448",
    propertyType: "Retail",
    totalCosts: "4395347",
    valueOfReclassifiedAssets: 3802767,
    depreciableAssets: 25,
  },
  {
    lat: 39.7558119,
    lng: -104.9680914,
    squareFootage: "228768",
    propertyType: "Retail",
    totalCosts: "9052650",
    valueOfReclassifiedAssets: 7357083,
    depreciableAssets: 32,
  },
  {
    lat: 39.7461315,
    lng: -121.8448745,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11644109",
    valueOfReclassifiedAssets: 9507435,
    depreciableAssets: 24,
  },
  {
    lat: 33.514162,
    lng: -112.1251372,
    squareFootage: "177108",
    propertyType: "Multifamily",
    totalCosts: "11300000",
    valueOfReclassifiedAssets: 3842512,
    depreciableAssets: 40,
  },
  {
    lat: 29.53896599999999,
    lng: -98.42390549999999,
    squareFootage: "310953",
    propertyType: "Multifamily",
    totalCosts: "17962915",
    valueOfReclassifiedAssets: 3896374,
    depreciableAssets: 24,
  },
  {
    lat: 29.580333,
    lng: -98.5418899,
    squareFootage: "96704",
    propertyType: "Multifamily",
    totalCosts: "7469538",
    valueOfReclassifiedAssets: 1756765,
    depreciableAssets: 29,
  },
  {
    lat: 29.5269768,
    lng: -98.61057029999999,
    squareFootage: "141120",
    propertyType: "Multifamily",
    totalCosts: "9762553",
    valueOfReclassifiedAssets: 2560031,
    depreciableAssets: 29,
  },
  {
    lat: 29.5269768,
    lng: -98.61057029999999,
    squareFootage: "268685",
    propertyType: "Multifamily",
    totalCosts: "17180702",
    valueOfReclassifiedAssets: 5705696,
    depreciableAssets: 37,
  },
  {
    lat: 33.3897576,
    lng: -111.9343636,
    squareFootage: "156280",
    propertyType: "Multifamily",
    totalCosts: "12350000",
    valueOfReclassifiedAssets: 3246672,
    depreciableAssets: 31,
  },
  {
    lat: 30.3870686,
    lng: -97.70365609999999,
    squareFootage: "76880",
    propertyType: "Multifamily",
    totalCosts: "6159740",
    valueOfReclassifiedAssets: 1840445,
    depreciableAssets: 33,
  },
  {
    lat: 30.3870686,
    lng: -97.70365609999999,
    squareFootage: "87097",
    propertyType: "Multifamily",
    totalCosts: "5916753",
    valueOfReclassifiedAssets: 1815217,
    depreciableAssets: 34,
  },
  {
    lat: 33.5973469,
    lng: -112.1072528,
    squareFootage: "270636",
    propertyType: "Multifamily",
    totalCosts: "21729419",
    valueOfReclassifiedAssets: 6936898,
    depreciableAssets: 38,
  },
  {
    lat: 30.3870686,
    lng: -97.70365609999999,
    squareFootage: "110024",
    propertyType: "Multifamily",
    totalCosts: "8797585",
    valueOfReclassifiedAssets: 2444502,
    depreciableAssets: 31,
  },
  {
    lat: 29.702465,
    lng: -95.53548160000001,
    squareFootage: "489565",
    propertyType: "Multifamily",
    totalCosts: "20499865",
    valueOfReclassifiedAssets: 5751408,
    depreciableAssets: 31,
  },
  {
    lat: 36.5421738,
    lng: -82.10606829999999,
    squareFootage: "40180",
    propertyType: "Multifamily",
    totalCosts: "1073559",
    valueOfReclassifiedAssets: 788507,
    depreciableAssets: 0,
  },
  {
    lat: 36.5421738,
    lng: -82.10606829999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "42418590",
    valueOfReclassifiedAssets: 38985956,
    depreciableAssets: 37,
  },
  {
    lat: 35.9613014,
    lng: -83.1863358,
    squareFootage: "40180",
    propertyType: "Multifamily",
    totalCosts: "985041",
    valueOfReclassifiedAssets: 724320,
    depreciableAssets: 0,
  },
  {
    lat: 36.5421738,
    lng: -82.10606829999999,
    squareFootage: "100000",
    propertyType: "Retail",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 14395603,
    depreciableAssets: 49,
  },
  {
    lat: 40.8811561,
    lng: -74.1416127,
    squareFootage: "62300",
    propertyType: "Multifamily",
    totalCosts: "9150000",
    valueOfReclassifiedAssets: 1961339,
    depreciableAssets: 26,
  },
  {
    lat: 40.8811561,
    lng: -74.1416127,
    squareFootage: "62300",
    propertyType: "Multifamily",
    totalCosts: "9150000",
    valueOfReclassifiedAssets: 1961339,
    depreciableAssets: 26,
  },
  {
    lat: 40.7127753,
    lng: -74.0059728,
    squareFootage: "30750",
    propertyType: "Multifamily",
    totalCosts: "2821441",
    valueOfReclassifiedAssets: 2384314,
    depreciableAssets: 16,
  },
  {
    lat: 40.6986257,
    lng: -73.6241855,
    squareFootage: "21540",
    propertyType: "Multifamily",
    totalCosts: "3402772",
    valueOfReclassifiedAssets: 2447807,
    depreciableAssets: 30,
  },
  {
    lat: 43.6297084,
    lng: -70.2807827,
    squareFootage: "167877",
    propertyType: "Multifamily",
    totalCosts: "21450000",
    valueOfReclassifiedAssets: 5736285,
    depreciableAssets: 30,
  },
  {
    lat: 43.6297084,
    lng: -70.2807827,
    squareFootage: "418000",
    propertyType: "Multifamily",
    totalCosts: "66050000",
    valueOfReclassifiedAssets: 18294152,
    depreciableAssets: 31,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14097235",
    valueOfReclassifiedAssets: 12687084,
    depreciableAssets: 12,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17164587",
    valueOfReclassifiedAssets: 14697964,
    depreciableAssets: 15,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "32309293",
    valueOfReclassifiedAssets: 27515476,
    depreciableAssets: 16,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "21871727",
    valueOfReclassifiedAssets: 19599229,
    depreciableAssets: 11,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "21700152",
    valueOfReclassifiedAssets: 19445481,
    depreciableAssets: 11,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11979606",
    valueOfReclassifiedAssets: 10651005,
    depreciableAssets: 13,
  },
  {
    lat: 40.7275043,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "34324068",
    valueOfReclassifiedAssets: 29253077,
    depreciableAssets: 16,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "58248",
    propertyType: "Multifamily",
    totalCosts: "9399211",
    valueOfReclassifiedAssets: 1236272,
    depreciableAssets: 14,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "83946",
    propertyType: "Multifamily",
    totalCosts: "13388265",
    valueOfReclassifiedAssets: 1596260,
    depreciableAssets: 16,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "58248",
    propertyType: "Multifamily",
    totalCosts: "9399211",
    valueOfReclassifiedAssets: 475254,
    depreciableAssets: 14,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "58248",
    propertyType: "Multifamily",
    totalCosts: "9399211",
    valueOfReclassifiedAssets: 1236272,
    depreciableAssets: 14,
  },
  {
    lat: 40.4359539,
    lng: -74.197388,
    squareFootage: "54000",
    propertyType: "Multifamily",
    totalCosts: "8533997",
    valueOfReclassifiedAssets: 1565818,
    depreciableAssets: 23,
  },
  {
    lat: 40.8955467,
    lng: -74.156292,
    squareFootage: "44550",
    propertyType: "Multifamily",
    totalCosts: "3480000",
    valueOfReclassifiedAssets: 585294,
    depreciableAssets: 21,
  },
  {
    lat: 40.923473,
    lng: -74.1445487,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "7490930",
    valueOfReclassifiedAssets: 6230886,
    depreciableAssets: 20,
  },
  {
    lat: 40.9203906,
    lng: -74.00063279999999,
    squareFootage: "75012",
    propertyType: "Multifamily",
    totalCosts: "2974848",
    valueOfReclassifiedAssets: 410232,
    depreciableAssets: 17,
  },
  {
    lat: 40.923473,
    lng: -74.1445487,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "7796683",
    valueOfReclassifiedAssets: 6485208,
    depreciableAssets: 20,
  },
  {
    lat: 40.4333407,
    lng: -74.41152459999999,
    squareFootage: "285000",
    propertyType: "Multifamily",
    totalCosts: "35111333",
    valueOfReclassifiedAssets: 7312405,
    depreciableAssets: 26,
  },
  {
    lat: 40.4333407,
    lng: -74.41152459999999,
    squareFootage: "285000",
    propertyType: "Multifamily",
    totalCosts: "35111333",
    valueOfReclassifiedAssets: 7312405,
    depreciableAssets: 26,
  },
  {
    lat: 39.9741059,
    lng: -74.1592278,
    squareFootage: "309944",
    propertyType: "Multifamily",
    totalCosts: "35613784",
    valueOfReclassifiedAssets: 4823077,
    depreciableAssets: 21,
  },
  {
    lat: 39.9741059,
    lng: -74.1592278,
    squareFootage: "309944",
    propertyType: "Multifamily",
    totalCosts: "35613824",
    valueOfReclassifiedAssets: 5518327,
    depreciableAssets: 21,
  },
  {
    lat: 41.9194214,
    lng: -88.13401909999999,
    squareFootage: "107.504",
    propertyType: "Retail",
    totalCosts: "1724062",
    valueOfReclassifiedAssets: 1537653,
    depreciableAssets: 27,
  },
  {
    lat: 41.9194214,
    lng: -88.13401909999999,
    squareFootage: "107.504",
    propertyType: "Retail",
    totalCosts: "1724062",
    valueOfReclassifiedAssets: 1537653,
    depreciableAssets: 27,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "6500",
    propertyType: "Multifamily",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 158271,
    depreciableAssets: 14,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "3600",
    propertyType: "Multifamily",
    totalCosts: "1440000",
    valueOfReclassifiedAssets: 190194,
    depreciableAssets: 16,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "25292",
    propertyType: "Multifamily",
    totalCosts: "4550000",
    valueOfReclassifiedAssets: 466331,
    depreciableAssets: 12,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "18810",
    propertyType: "Multifamily",
    totalCosts: "2055780",
    valueOfReclassifiedAssets: 1847115,
    depreciableAssets: 0,
  },
  {
    lat: 40.6815619,
    lng: -73.9785952,
    squareFootage: "7020",
    propertyType: "Multifamily",
    totalCosts: "2087895",
    valueOfReclassifiedAssets: 1866066,
    depreciableAssets: 12,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "12659",
    propertyType: "Multifamily",
    totalCosts: "1631260",
    valueOfReclassifiedAssets: 1421372,
    depreciableAssets: 14,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "17875",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 1298521,
    depreciableAssets: 10,
  },
  {
    lat: 39.4457756,
    lng: -83.804868,
    squareFootage: "153976",
    propertyType: "Retail",
    totalCosts: "6675000",
    valueOfReclassifiedAssets: 2389019,
    depreciableAssets: 40,
  },
  {
    lat: 42.2190542,
    lng: -71.97569899999999,
    squareFootage: "53103",
    propertyType: "Retail",
    totalCosts: "6471763",
    valueOfReclassifiedAssets: 1896842,
    depreciableAssets: 34,
  },
  {
    lat: 33.8226751,
    lng: -87.29167129999999,
    squareFootage: "79562",
    propertyType: "Retail",
    totalCosts: "2304121",
    valueOfReclassifiedAssets: 2069153,
    depreciableAssets: 20,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "10000",
    propertyType: "Multifamily",
    totalCosts: "2855770",
    valueOfReclassifiedAssets: 2752090,
    depreciableAssets: 100,
  },
  {
    lat: 40.0635817,
    lng: -75.07863569999999,
    squareFootage: "29204",
    propertyType: "Retail",
    totalCosts: "4128160",
    valueOfReclassifiedAssets: 739828,
    depreciableAssets: 20,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "77589",
    propertyType: "Retail",
    totalCosts: "16900000",
    valueOfReclassifiedAssets: 4818623,
    depreciableAssets: 33,
  },
  {
    lat: 40.9567531,
    lng: -74.1885825,
    squareFootage: "14720",
    propertyType: "Retail",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 3030901,
    depreciableAssets: 42,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9619073",
    valueOfReclassifiedAssets: 8533245,
    depreciableAssets: 11,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19693447",
    valueOfReclassifiedAssets: 17591986,
    depreciableAssets: 11,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8557273",
    valueOfReclassifiedAssets: 7471445,
    depreciableAssets: 13,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16862932",
    valueOfReclassifiedAssets: 15276531,
    depreciableAssets: 9,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14626999",
    valueOfReclassifiedAssets: 13081658,
    depreciableAssets: 11,
  },
  {
    lat: 32.7660707,
    lng: -97.18441399999999,
    squareFootage: "291879",
    propertyType: "Multifamily",
    totalCosts: "24927576",
    valueOfReclassifiedAssets: 7817981,
    depreciableAssets: 32,
  },
  {
    lat: 38.9875145,
    lng: -77.0737149,
    squareFootage: "204819",
    propertyType: "Industrial",
    totalCosts: "29000000",
    valueOfReclassifiedAssets: 4488570,
    depreciableAssets: 18,
  },
  {
    lat: 26.2778269,
    lng: -80.109357,
    squareFootage: "79379",
    propertyType: "Office",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1007471,
    depreciableAssets: 21,
  },
  {
    lat: 41.3512901,
    lng: -72.1534606,
    squareFootage: "20531",
    propertyType: "Retail",
    totalCosts: "5800000",
    valueOfReclassifiedAssets: 1275189,
    depreciableAssets: 26,
  },
  {
    lat: 39.7017383,
    lng: -75.11951069999999,
    squareFootage: "297100",
    propertyType: "Multifamily",
    totalCosts: "19100000",
    valueOfReclassifiedAssets: 4370184,
    depreciableAssets: 27,
  },
  {
    lat: 33.9870228,
    lng: -118.0947106,
    squareFootage: "15832",
    propertyType: "Industrial",
    totalCosts: "1585000",
    valueOfReclassifiedAssets: 209483,
    depreciableAssets: 16,
  },
  {
    lat: 30.670725,
    lng: -94.7691586,
    squareFootage: "108604",
    propertyType: "Multifamily",
    totalCosts: "8100000",
    valueOfReclassifiedAssets: 2377270,
    depreciableAssets: 34,
  },
  {
    lat: 40.90412329999999,
    lng: -74.0946311,
    squareFootage: "76550",
    propertyType: "Industrial",
    totalCosts: "5100000",
    valueOfReclassifiedAssets: 580476,
    depreciableAssets: 14,
  },
  {
    lat: 33.96672119999999,
    lng: -118.1766294,
    squareFootage: "23200",
    propertyType: "Retail",
    totalCosts: "4575000",
    valueOfReclassifiedAssets: 752888,
    depreciableAssets: 20,
  },
  {
    lat: 39.6261313,
    lng: -75.8766333,
    squareFootage: "2520",
    propertyType: "Specialty",
    totalCosts: "3017000",
    valueOfReclassifiedAssets: 1124442,
    depreciableAssets: 43,
  },
  {
    lat: 26.6064003,
    lng: -80.1316927,
    squareFootage: "15662",
    propertyType: "Office",
    totalCosts: "3420000",
    valueOfReclassifiedAssets: 1406603,
    depreciableAssets: 48,
  },
  {
    lat: 40.9528921,
    lng: -74.03882519999999,
    squareFootage: "3360",
    propertyType: "Retail",
    totalCosts: "657325",
    valueOfReclassifiedAssets: 64774,
    depreciableAssets: 12,
  },
  {
    lat: 40.9528921,
    lng: -74.03882519999999,
    squareFootage: "3600",
    propertyType: "Multifamily",
    totalCosts: "1260610",
    valueOfReclassifiedAssets: 230905,
    depreciableAssets: 22,
  },
  {
    lat: 41.2231225,
    lng: -111.9737732,
    squareFootage: "6677",
    propertyType: "Specialty",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 1067229,
    depreciableAssets: 49,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "151050",
    propertyType: "Multifamily",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 4186515,
    depreciableAssets: 35,
  },
  {
    lat: 37.1120848,
    lng: -76.5372625,
    squareFootage: "217425",
    propertyType: "Multifamily",
    totalCosts: "19200000",
    valueOfReclassifiedAssets: 5205833,
    depreciableAssets: 30,
  },
  {
    lat: 42.0636938,
    lng: -76.01598,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "27051958",
    valueOfReclassifiedAssets: 5603101,
    depreciableAssets: 22,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "2984",
    propertyType: "Multifamily",
    totalCosts: "1113145",
    valueOfReclassifiedAssets: 143716,
    depreciableAssets: 15,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "4305",
    propertyType: "Retail",
    totalCosts: "1625000",
    valueOfReclassifiedAssets: 261321,
    depreciableAssets: 19,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 458342,
    depreciableAssets: 26,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "3750",
    propertyType: "Multifamily",
    totalCosts: "700000",
    valueOfReclassifiedAssets: 103170,
    depreciableAssets: 18,
  },
  {
    lat: 40.7245678,
    lng: -73.8457658,
    squareFootage: "4200",
    propertyType: "Office",
    totalCosts: "1616000",
    valueOfReclassifiedAssets: 79125,
    depreciableAssets: 5,
  },
  {
    lat: 40.6483744,
    lng: -73.9800645,
    squareFootage: "5496",
    propertyType: "Retail",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 501190,
    depreciableAssets: 27,
  },
  {
    lat: 40.8690303,
    lng: -73.8477874,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 4037500,
    depreciableAssets: 100,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "1952",
    propertyType: "Multifamily",
    totalCosts: "488722",
    valueOfReclassifiedAssets: 60476,
    depreciableAssets: 15,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "2600",
    propertyType: "Multifamily",
    totalCosts: "698133",
    valueOfReclassifiedAssets: 70408,
    depreciableAssets: 12,
  },
  {
    lat: 40.6980708,
    lng: -73.8948295,
    squareFootage: "8856",
    propertyType: "Multifamily",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 344474,
    depreciableAssets: 19,
  },
  {
    lat: 40.0508979,
    lng: -74.1357407,
    squareFootage: "9216",
    propertyType: "Office",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 201054,
    depreciableAssets: 21,
  },
  {
    lat: 42.4274971,
    lng: -71.10920120000002,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "24750000",
    valueOfReclassifiedAssets: 7062311,
    depreciableAssets: 35,
  },
  {
    lat: 42.2020445,
    lng: -71.014118,
    squareFootage: "90006",
    propertyType: "Office",
    totalCosts: "15600000",
    valueOfReclassifiedAssets: 3740841,
    depreciableAssets: 30,
  },
  {
    lat: 40.2303787,
    lng: -74.27661859999999,
    squareFootage: "114450",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17875010",
    valueOfReclassifiedAssets: 9085367,
    depreciableAssets: 27,
  },
  {
    lat: 38.786556,
    lng: -90.7818089,
    squareFootage: "361624",
    propertyType: "Multifamily",
    totalCosts: "27355493",
    valueOfReclassifiedAssets: 7501806,
    depreciableAssets: 30,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "152280",
    propertyType: "Multifamily",
    totalCosts: "15221745",
    valueOfReclassifiedAssets: 3487916,
    depreciableAssets: 26,
  },
  {
    lat: 39.206658,
    lng: -76.8836261,
    squareFootage: "224000",
    propertyType: "Multifamily",
    totalCosts: "38385556",
    valueOfReclassifiedAssets: 9162516,
    depreciableAssets: 28,
  },
  {
    lat: 38.7855623,
    lng: -77.5275463,
    squareFootage: "155000",
    propertyType: "Multifamily",
    totalCosts: "36098333",
    valueOfReclassifiedAssets: 10187828,
    depreciableAssets: 33,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "237253",
    propertyType: "Multifamily",
    totalCosts: "26773608",
    valueOfReclassifiedAssets: 6038279,
    depreciableAssets: 25,
  },
  {
    lat: 39.047958,
    lng: -77.4816693,
    squareFootage: "232101",
    propertyType: "Multifamily",
    totalCosts: "47733333",
    valueOfReclassifiedAssets: 12344593,
    depreciableAssets: 30,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "565626",
    propertyType: "Multifamily",
    totalCosts: "47120686",
    valueOfReclassifiedAssets: 9221720,
    depreciableAssets: 21,
  },
  {
    lat: 38.8629803,
    lng: -77.4816693,
    squareFootage: "290000",
    propertyType: "Multifamily",
    totalCosts: "55987222",
    valueOfReclassifiedAssets: 12882882,
    depreciableAssets: 27,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2725460",
    valueOfReclassifiedAssets: 1345022,
    depreciableAssets: 55,
  },
  {
    lat: 39.0527088,
    lng: -77.3841197,
    squareFootage: "350000",
    propertyType: "Multifamily",
    totalCosts: "70605556",
    valueOfReclassifiedAssets: 18255508,
    depreciableAssets: 30,
  },
  {
    lat: 29.77261379999999,
    lng: -95.514493,
    squareFootage: "650000",
    propertyType: "Multifamily",
    totalCosts: "86153501",
    valueOfReclassifiedAssets: 21413509,
    depreciableAssets: 29,
  },
  {
    lat: 41.7568116,
    lng: -88.21690269999999,
    squareFootage: "298291",
    propertyType: "Multifamily",
    totalCosts: "37150000",
    valueOfReclassifiedAssets: 7370340,
    depreciableAssets: 23,
  },
  {
    lat: 38.8629803,
    lng: -77.4816693,
    squareFootage: "288860",
    propertyType: "Multifamily",
    totalCosts: "53600556",
    valueOfReclassifiedAssets: 13944652,
    depreciableAssets: 31,
  },
  {
    lat: 39.167568,
    lng: -76.5892774,
    squareFootage: "290925",
    propertyType: "Multifamily",
    totalCosts: "32000000",
    valueOfReclassifiedAssets: 6381227,
    depreciableAssets: 23,
  },
  {
    lat: 38.6679123,
    lng: -77.2060241,
    squareFootage: "245000",
    propertyType: "Multifamily",
    totalCosts: "55589444",
    valueOfReclassifiedAssets: 15021817,
    depreciableAssets: 31,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "565626",
    propertyType: "Multifamily",
    totalCosts: "46655432",
    valueOfReclassifiedAssets: 9119860,
    depreciableAssets: 21,
  },
  {
    lat: 33.2831586,
    lng: -84.2489525,
    squareFootage: "340000",
    propertyType: "Multifamily",
    totalCosts: "50086970",
    valueOfReclassifiedAssets: 13766291,
    depreciableAssets: 32,
  },
  {
    lat: 39.2994145,
    lng: -76.60372219999999,
    squareFootage: "176158",
    propertyType: "Multifamily",
    totalCosts: "19003088",
    valueOfReclassifiedAssets: 2322895,
    depreciableAssets: 0,
  },
  {
    lat: 38.786556,
    lng: -90.7818089,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1450306",
    valueOfReclassifiedAssets: 340451,
    depreciableAssets: 87,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "730758",
    valueOfReclassifiedAssets: 228455,
    depreciableAssets: 87,
  },
  {
    lat: 33.81842,
    lng: -84.3594626,
    squareFootage: "510449",
    propertyType: "Multifamily",
    totalCosts: "71000000",
    valueOfReclassifiedAssets: 12155465,
    depreciableAssets: 20,
  },
  {
    lat: 33.7946333,
    lng: -84.44877199999999,
    squareFootage: "560756",
    propertyType: "Multifamily",
    totalCosts: "68250000",
    valueOfReclassifiedAssets: 10707631,
    depreciableAssets: 18,
  },
  {
    lat: 38.6624944,
    lng: -90.4785495,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1046940",
    valueOfReclassifiedAssets: 328669,
    depreciableAssets: 81,
  },
  {
    lat: 33.8676889,
    lng: -84.4645184,
    squareFootage: "516372",
    propertyType: "Multifamily",
    totalCosts: "74750000",
    valueOfReclassifiedAssets: 13927951,
    depreciableAssets: 22,
  },
  {
    lat: 33.7946333,
    lng: -84.44877199999999,
    squareFootage: "560756",
    propertyType: "Multifamily",
    totalCosts: "68250000",
    valueOfReclassifiedAssets: 11875452,
    depreciableAssets: 20,
  },
  {
    lat: 33.8676889,
    lng: -84.4645184,
    squareFootage: "510449",
    propertyType: "Multifamily",
    totalCosts: "74750000",
    valueOfReclassifiedAssets: 14042322,
    depreciableAssets: 22,
  },
  {
    lat: 42.0955139,
    lng: -88.314212,
    squareFootage: "429614",
    propertyType: "Multifamily",
    totalCosts: "57100000",
    valueOfReclassifiedAssets: 11989082,
    depreciableAssets: 24,
  },
  {
    lat: 42.569557,
    lng: -70.94279139999999,
    squareFootage: "29122",
    propertyType: "Retail",
    totalCosts: "4125000",
    valueOfReclassifiedAssets: 691066,
    depreciableAssets: 21,
  },
  {
    lat: 42.6007246,
    lng: -71.014118,
    squareFootage: "39350",
    propertyType: "Industrial",
    totalCosts: "1760000",
    valueOfReclassifiedAssets: 1547182,
    depreciableAssets: 22,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "346605",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 4866207,
    depreciableAssets: 34,
  },
  {
    lat: 35.6204185,
    lng: -106.1345705,
    squareFootage: "76404",
    propertyType: "Multifamily",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 2457523,
    depreciableAssets: 40,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "36974",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 1034008,
    depreciableAssets: 45,
  },
  {
    lat: 42.9734553,
    lng: -78.713608,
    squareFootage: "77683",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 644186,
    depreciableAssets: 17,
  },
  {
    lat: 43.2229834,
    lng: -78.24761459999999,
    squareFootage: "44923",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7050000",
    valueOfReclassifiedAssets: 2920077,
    depreciableAssets: 46,
  },
  {
    lat: 40.6989853,
    lng: -80.38813329999999,
    squareFootage: "240773",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "33500000",
    valueOfReclassifiedAssets: 7139177,
    depreciableAssets: 24,
  },
  {
    lat: 40.6989853,
    lng: -80.38813329999999,
    squareFootage: "240773",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "29383702",
    valueOfReclassifiedAssets: 6164484,
    depreciableAssets: 24,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "41840",
    propertyType: "Industrial",
    totalCosts: "4450327",
    valueOfReclassifiedAssets: 1753708,
    depreciableAssets: 47,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "41840",
    propertyType: "Industrial",
    totalCosts: "4450327",
    valueOfReclassifiedAssets: 1753708,
    depreciableAssets: 47,
  },
  {
    lat: 39.1850643,
    lng: -75.5975296,
    squareFootage: "170696",
    propertyType: "Office",
    totalCosts: "12019471",
    valueOfReclassifiedAssets: 10294990,
    depreciableAssets: 24,
  },
  {
    lat: 39.1850643,
    lng: -75.5975296,
    squareFootage: "170696",
    propertyType: "Office",
    totalCosts: "12019471",
    valueOfReclassifiedAssets: 11018299,
    depreciableAssets: 18,
  },
  {
    lat: 39.0246739,
    lng: -94.6708709,
    squareFootage: "457194",
    propertyType: "Multifamily",
    totalCosts: "28000000",
    valueOfReclassifiedAssets: 6944197,
    depreciableAssets: 29,
  },
  {
    lat: 38.9328362,
    lng: -94.4902774,
    squareFootage: "156392",
    propertyType: "Multifamily",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 962537,
    depreciableAssets: 22,
  },
  {
    lat: 38.9546734,
    lng: -94.5985613,
    squareFootage: "383162",
    propertyType: "Multifamily",
    totalCosts: "73500000",
    valueOfReclassifiedAssets: 15981449,
    depreciableAssets: 26,
  },
  {
    lat: 32.9309279,
    lng: -96.7949137,
    squareFootage: "147916",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 856935,
    depreciableAssets: 14,
  },
  {
    lat: 32.706669,
    lng: -96.84046,
    squareFootage: "193640",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1252520,
    depreciableAssets: 23,
  },
  {
    lat: 32.9418531,
    lng: -96.8002704,
    squareFootage: "220154",
    propertyType: "Multifamily",
    totalCosts: "14874600",
    valueOfReclassifiedAssets: 4561698,
    depreciableAssets: 32,
  },
  {
    lat: 32.8621631,
    lng: -96.8726295,
    squareFootage: "110798",
    propertyType: "Multifamily",
    totalCosts: "1424500",
    valueOfReclassifiedAssets: 1049309,
    depreciableAssets: 35,
  },
  {
    lat: 32.7030883,
    lng: -96.80294889999999,
    squareFootage: "73197",
    propertyType: "Multifamily",
    totalCosts: "1224080",
    valueOfReclassifiedAssets: 358713,
    depreciableAssets: 34,
  },
  {
    lat: 32.7030883,
    lng: -96.80294889999999,
    squareFootage: "138468",
    propertyType: "Multifamily",
    totalCosts: "2281240",
    valueOfReclassifiedAssets: 687587,
    depreciableAssets: 34,
  },
  {
    lat: 32.7030883,
    lng: -96.80294889999999,
    squareFootage: "122722",
    propertyType: "Multifamily",
    totalCosts: "2058680",
    valueOfReclassifiedAssets: 522023,
    depreciableAssets: 29,
  },
  {
    lat: 32.706669,
    lng: -96.84046,
    squareFootage: "70212",
    propertyType: "Multifamily",
    totalCosts: "1364410",
    valueOfReclassifiedAssets: 385577,
    depreciableAssets: 33,
  },
  {
    lat: 33.5321111,
    lng: -112.256285,
    squareFootage: "51116",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1455655,
    depreciableAssets: 38,
  },
  {
    lat: 33.82353,
    lng: -117.9659037,
    squareFootage: "26966",
    propertyType: "Multifamily",
    totalCosts: "8020000",
    valueOfReclassifiedAssets: 2280839,
    depreciableAssets: 34,
  },
  {
    lat: 33.8065036,
    lng: -118.1912538,
    squareFootage: "9444",
    propertyType: "Multifamily",
    totalCosts: "1716375",
    valueOfReclassifiedAssets: 308671,
    depreciableAssets: 22,
  },
  {
    lat: 33.78659,
    lng: -117.8750976,
    squareFootage: "5585",
    propertyType: "Office",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 326088,
    depreciableAssets: 25,
  },
  {
    lat: 33.9870228,
    lng: -118.0947106,
    squareFootage: "96360",
    propertyType: "Multifamily",
    totalCosts: "9795000",
    valueOfReclassifiedAssets: 7350415,
    depreciableAssets: 33,
  },
  {
    lat: 33.8124094,
    lng: -117.9192679,
    squareFootage: "38625",
    propertyType: "Multifamily",
    totalCosts: "8200000",
    valueOfReclassifiedAssets: 1618971,
    depreciableAssets: 29,
  },
  {
    lat: 33.4767802,
    lng: -117.1970557,
    squareFootage: "64256",
    propertyType: "Office",
    totalCosts: "3851050",
    valueOfReclassifiedAssets: 3577451,
    depreciableAssets: 18,
  },
  {
    lat: 33.9167378,
    lng: -118.1298234,
    squareFootage: "44580",
    propertyType: "Multifamily",
    totalCosts: "9745234",
    valueOfReclassifiedAssets: 1919400,
    depreciableAssets: 29,
  },
  {
    lat: 34.1129907,
    lng: -118.754782,
    squareFootage: "26600",
    propertyType: "Office",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 773189,
    depreciableAssets: 25,
  },
  {
    lat: 40.7231906,
    lng: -73.1880618,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2773095",
    valueOfReclassifiedAssets: 1683288,
    depreciableAssets: 0,
  },
  {
    lat: 40.8002863,
    lng: -72.7865518,
    squareFootage: "42146",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 1128233,
    depreciableAssets: 25,
  },
  {
    lat: 40.7231906,
    lng: -73.1880618,
    squareFootage: "74288",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 5231325,
    depreciableAssets: 22,
  },
  {
    lat: 40.8902916,
    lng: -72.9578676,
    squareFootage: "34977",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "22400000",
    valueOfReclassifiedAssets: 7480997,
    depreciableAssets: 39,
  },
  {
    lat: 38.6299452,
    lng: -90.6962853,
    squareFootage: "33148",
    propertyType: "Office",
    totalCosts: "3425000",
    valueOfReclassifiedAssets: 836206,
    depreciableAssets: 29,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "116340",
    propertyType: "Multifamily",
    totalCosts: "8900000",
    valueOfReclassifiedAssets: 2591530,
    depreciableAssets: 34,
  },
  {
    lat: 34.2783099,
    lng: -83.8791074,
    squareFootage: "194003",
    propertyType: "Multifamily",
    totalCosts: "14250000",
    valueOfReclassifiedAssets: 3522639,
    depreciableAssets: 29,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "298996",
    propertyType: "Multifamily",
    totalCosts: "16550000",
    valueOfReclassifiedAssets: 3740504,
    depreciableAssets: 26,
  },
  {
    lat: 32.3822577,
    lng: -86.1914604,
    squareFootage: "182100",
    propertyType: "Multifamily",
    totalCosts: "11400000",
    valueOfReclassifiedAssets: 2459538,
    depreciableAssets: 22,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "14300",
    propertyType: "Multifamily",
    totalCosts: "8550000",
    valueOfReclassifiedAssets: 1666704,
    depreciableAssets: 23,
  },
  {
    lat: 37.0193161,
    lng: -94.5005808,
    squareFootage: "98841",
    propertyType: "Office",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 2275860,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "214679",
    propertyType: "Office",
    totalCosts: "42100000",
    valueOfReclassifiedAssets: 8329858,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "114004",
    propertyType: "Office",
    totalCosts: "14627641",
    valueOfReclassifiedAssets: 3349117,
    depreciableAssets: 27,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "114004",
    propertyType: "Office",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 2747498,
    depreciableAssets: 27,
  },
  {
    lat: 39.9493801,
    lng: -75.145782,
    squareFootage: "226222",
    propertyType: "Office",
    totalCosts: "15203026",
    valueOfReclassifiedAssets: 1684040,
    depreciableAssets: 13,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "221208",
    propertyType: "Multifamily",
    totalCosts: "33650000",
    valueOfReclassifiedAssets: 3617529,
    depreciableAssets: 13,
  },
  {
    lat: 41.4334745,
    lng: -74.4760088,
    squareFootage: "391065",
    propertyType: "Specialty",
    totalCosts: "3302996",
    valueOfReclassifiedAssets: 2959555,
    depreciableAssets: 13,
  },
  {
    lat: 40.8971509,
    lng: -73.9065883,
    squareFootage: "24650",
    propertyType: "Multifamily",
    totalCosts: "2445600",
    valueOfReclassifiedAssets: 2264806,
    depreciableAssets: 9,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "4500",
    propertyType: "Multifamily",
    totalCosts: "1640000",
    valueOfReclassifiedAssets: 79236,
    depreciableAssets: 5,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "244000",
    propertyType: "Industrial",
    totalCosts: "23536000",
    valueOfReclassifiedAssets: 5455844,
    depreciableAssets: 27,
  },
  {
    lat: 42.026064,
    lng: -87.7271123,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1033804",
    valueOfReclassifiedAssets: 708271,
    depreciableAssets: 0,
  },
  {
    lat: 29.58857609999999,
    lng: -98.2760021,
    squareFootage: "101347",
    propertyType: "Industrial",
    totalCosts: "23500000",
    valueOfReclassifiedAssets: 9838400,
    depreciableAssets: 0,
  },
  {
    lat: 41.0229118,
    lng: -73.99475629999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2011146",
    valueOfReclassifiedAssets: 1637353,
    depreciableAssets: 33,
  },
  {
    lat: 32.7229925,
    lng: -97.15209259999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "748614",
    valueOfReclassifiedAssets: 591344,
    depreciableAssets: 29,
  },
  {
    lat: 32.7229925,
    lng: -97.15209259999999,
    squareFootage: "117072",
    propertyType: "Multifamily",
    totalCosts: "5858324",
    valueOfReclassifiedAssets: 1543693,
    depreciableAssets: 29,
  },
  {
    lat: 32.5989444,
    lng: -96.85654269999999,
    squareFootage: "83920",
    propertyType: "Multifamily",
    totalCosts: "8050000",
    valueOfReclassifiedAssets: 2751872,
    depreciableAssets: 40,
  },
  {
    lat: 32.6149346,
    lng: -96.55698819999999,
    squareFootage: "90000",
    propertyType: "Multifamily",
    totalCosts: "8050000",
    valueOfReclassifiedAssets: 2426501,
    depreciableAssets: 36,
  },
  {
    lat: 32.6874358,
    lng: -97.18441399999999,
    squareFootage: "57500",
    propertyType: "Multifamily",
    totalCosts: "2041325",
    valueOfReclassifiedAssets: 462055,
    depreciableAssets: 26,
  },
  {
    lat: 32.824634,
    lng: -96.6317461,
    squareFootage: "102307",
    propertyType: "Multifamily",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 2125468,
    depreciableAssets: 30,
  },
  {
    lat: 40.1014036,
    lng: -82.89636039999999,
    squareFootage: "113628",
    propertyType: "Multifamily",
    totalCosts: "6839600",
    valueOfReclassifiedAssets: 1356208,
    depreciableAssets: 25,
  },
  {
    lat: 32.7811446,
    lng: -97.0175879,
    squareFootage: "94262",
    propertyType: "Multifamily",
    totalCosts: "6915000",
    valueOfReclassifiedAssets: 1612110,
    depreciableAssets: 27,
  },
  {
    lat: 29.5269768,
    lng: -98.61057029999999,
    squareFootage: "111406",
    propertyType: "Multifamily",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 1817617,
    depreciableAssets: 27,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "60981",
    propertyType: "Multifamily",
    totalCosts: "4250000",
    valueOfReclassifiedAssets: 1242310,
    depreciableAssets: 34,
  },
  {
    lat: 30.5842644,
    lng: -97.1574784,
    squareFootage: "194553",
    propertyType: "Multifamily",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 10832388,
    depreciableAssets: 30,
  },
  {
    lat: 42.4986723,
    lng: -83.2398943,
    squareFootage: "127204",
    propertyType: "Multifamily",
    totalCosts: "5350000",
    valueOfReclassifiedAssets: 4398348,
    depreciableAssets: 29,
  },
  {
    lat: 42.4344566,
    lng: -83.6561215,
    squareFootage: "162000",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 8953393,
    depreciableAssets: 35,
  },
  {
    lat: 42.6742723,
    lng: -83.2398943,
    squareFootage: "385220",
    propertyType: "Multifamily",
    totalCosts: "16250000",
    valueOfReclassifiedAssets: 13582001,
    depreciableAssets: 23,
  },
  {
    lat: 33.0787106,
    lng: -96.91018059999999,
    squareFootage: "20331",
    propertyType: "Retail",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 1405708,
    depreciableAssets: 28,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16707495",
    valueOfReclassifiedAssets: 4154588,
    depreciableAssets: 25,
  },
  {
    lat: 41.9508814,
    lng: -87.94328370000001,
    squareFootage: "24912",
    propertyType: "Industrial",
    totalCosts: "1473150",
    valueOfReclassifiedAssets: 125242,
    depreciableAssets: 11,
  },
  {
    lat: 39.9616798,
    lng: -85.9406745,
    squareFootage: "25538",
    propertyType: "Retail",
    totalCosts: "2937314",
    valueOfReclassifiedAssets: 721566,
    depreciableAssets: 34,
  },
  {
    lat: 39.9616798,
    lng: -85.9406745,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2937314",
    valueOfReclassifiedAssets: 785921,
    depreciableAssets: 34,
  },
  {
    lat: 37.440438,
    lng: -77.3094674,
    squareFootage: "21708",
    propertyType: "Retail",
    totalCosts: "2060418",
    valueOfReclassifiedAssets: 511216,
    depreciableAssets: 31,
  },
  {
    lat: 39.206658,
    lng: -76.8836261,
    squareFootage: "341900",
    propertyType: "Multifamily",
    totalCosts: "2516471",
    valueOfReclassifiedAssets: 527440,
    depreciableAssets: 28,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "399804",
    propertyType: "Multifamily",
    totalCosts: "18172653",
    valueOfReclassifiedAssets: 6120113,
    depreciableAssets: 37,
  },
  {
    lat: 42.3652656,
    lng: -87.92367469999999,
    squareFootage: "262072",
    propertyType: "Multifamily",
    totalCosts: "14397661",
    valueOfReclassifiedAssets: 3868105,
    depreciableAssets: 29,
  },
  {
    lat: 39.206658,
    lng: -76.8836261,
    squareFootage: "341900",
    propertyType: "Multifamily",
    totalCosts: "20772194",
    valueOfReclassifiedAssets: 4353749,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "262072",
    propertyType: "Multifamily",
    totalCosts: "9833115",
    valueOfReclassifiedAssets: 2641785,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "262072",
    propertyType: "Multifamily",
    totalCosts: "18697119",
    valueOfReclassifiedAssets: 5012848,
    depreciableAssets: 29,
  },
  {
    lat: 28.2621197,
    lng: -82.37773349999999,
    squareFootage: "10872",
    propertyType: "Retail",
    totalCosts: "2200679",
    valueOfReclassifiedAssets: 526132,
    depreciableAssets: 36,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "66662",
    propertyType: "Specialty",
    totalCosts: "21006225",
    valueOfReclassifiedAssets: 1482052,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5028",
    propertyType: "Office",
    totalCosts: "10499652",
    valueOfReclassifiedAssets: 1128436,
    depreciableAssets: 0,
  },
  {
    lat: 41.3683407,
    lng: -82.10764139999999,
    squareFootage: "224337",
    propertyType: "Retail",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 6262765,
    depreciableAssets: 36,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10087169",
    valueOfReclassifiedAssets: 9232577,
    depreciableAssets: 19,
  },
  {
    lat: 38.8372336,
    lng: -77.01614359999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3223370",
    valueOfReclassifiedAssets: 2588921,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2206778",
    valueOfReclassifiedAssets: 1709165,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6620333",
    valueOfReclassifiedAssets: 5127497,
    depreciableAssets: 28,
  },
  {
    lat: 38.8372336,
    lng: -77.01614359999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7015543",
    valueOfReclassifiedAssets: 5634688,
    depreciableAssets: 33,
  },
  {
    lat: 39.2713976,
    lng: -76.5603828,
    squareFootage: "81875",
    propertyType: "Industrial",
    totalCosts: "3659347",
    valueOfReclassifiedAssets: 380622,
    depreciableAssets: 17,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "4100",
    propertyType: "Office",
    totalCosts: "350000",
    valueOfReclassifiedAssets: 82391,
    depreciableAssets: 27,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2258643",
    valueOfReclassifiedAssets: 1882792,
    depreciableAssets: 26,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "3886",
    propertyType: "Office",
    totalCosts: "400000",
    valueOfReclassifiedAssets: 86565,
    depreciableAssets: 25,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "7700",
    propertyType: "Office",
    totalCosts: "500000",
    valueOfReclassifiedAssets: 75529,
    depreciableAssets: 17,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "8276",
    propertyType: "Industrial",
    totalCosts: "475602",
    valueOfReclassifiedAssets: 142829,
    depreciableAssets: 40,
  },
  {
    lat: 43.80012989999999,
    lng: -73.0910171,
    squareFootage: "133930",
    propertyType: "Industrial",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 122806,
    depreciableAssets: 14,
  },
  {
    lat: 44.8109158,
    lng: -73.0862585,
    squareFootage: "196000",
    propertyType: "Industrial",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 643457,
    depreciableAssets: 24,
  },
  {
    lat: 44.2019646,
    lng: -72.50846779999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2586721",
    valueOfReclassifiedAssets: 2405930,
    depreciableAssets: 28,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "10000",
    propertyType: "Industrial",
    totalCosts: "1918000",
    valueOfReclassifiedAssets: 691136,
    depreciableAssets: 43,
  },
  {
    lat: 42.6325727,
    lng: -71.3408707,
    squareFootage: "167973",
    propertyType: "Multifamily",
    totalCosts: "14766318",
    valueOfReclassifiedAssets: 2302145,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "142970",
    propertyType: "Office",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 2659563,
    depreciableAssets: 22,
  },
  {
    lat: 40.28873859999999,
    lng: -74.1944529,
    squareFootage: "4248",
    propertyType: "Office",
    totalCosts: "2860000",
    valueOfReclassifiedAssets: 1094081,
    depreciableAssets: 45,
  },
  {
    lat: 40.28873859999999,
    lng: -74.1944529,
    squareFootage: "12339",
    propertyType: "Office",
    totalCosts: "600000",
    valueOfReclassifiedAssets: 237900,
    depreciableAssets: 47,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "34899",
    propertyType: "Retail",
    totalCosts: "2525854",
    valueOfReclassifiedAssets: 1970618,
    depreciableAssets: 29,
  },
  {
    lat: 40.9074337,
    lng: -74.1181234,
    squareFootage: "25000",
    propertyType: "Retail",
    totalCosts: "1435709",
    valueOfReclassifiedAssets: 1050822,
    depreciableAssets: 37,
  },
  {
    lat: 41.2455339,
    lng: -74.0417628,
    squareFootage: "41854",
    propertyType: "Retail",
    totalCosts: "6457484",
    valueOfReclassifiedAssets: 4399914,
    depreciableAssets: 43,
  },
  {
    lat: 41.0084281,
    lng: -73.9447994,
    squareFootage: "20827",
    propertyType: "Office",
    totalCosts: "1025754",
    valueOfReclassifiedAssets: 948501,
    depreciableAssets: 13,
  },
  {
    lat: 41.0084281,
    lng: -73.9447994,
    squareFootage: "44000",
    propertyType: "Office",
    totalCosts: "5976752",
    valueOfReclassifiedAssets: 1452070,
    depreciableAssets: 31,
  },
  {
    lat: 34.296676,
    lng: -118.2292712,
    squareFootage: "18179",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1871511,
    depreciableAssets: 41,
  },
  {
    lat: 40.0033209,
    lng: -76.23632479999999,
    squareFootage: "62322",
    propertyType: "Retail",
    totalCosts: "10300000",
    valueOfReclassifiedAssets: 2567868,
    depreciableAssets: 30,
  },
  {
    lat: 39.282939,
    lng: -94.5727598,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10998744",
    valueOfReclassifiedAssets: 9410257,
    depreciableAssets: 27,
  },
  {
    lat: 39.1551963,
    lng: -76.79135579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2565204",
    valueOfReclassifiedAssets: 1895885,
    depreciableAssets: 38,
  },
  {
    lat: 39.1551963,
    lng: -76.79135579999999,
    squareFootage: "123856",
    propertyType: "Industrial",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 1536109,
    depreciableAssets: 35,
  },
  {
    lat: 34.2710376,
    lng: -118.61481,
    squareFootage: "47168",
    propertyType: "Retail",
    totalCosts: "30061380",
    valueOfReclassifiedAssets: 7668454,
    depreciableAssets: 30,
  },
  {
    lat: 40.403733,
    lng: -75.9405178,
    squareFootage: "150762",
    propertyType: "Retail",
    totalCosts: "10450000",
    valueOfReclassifiedAssets: 2810550,
    depreciableAssets: 31,
  },
  {
    lat: 42.1229099,
    lng: -71.10920120000002,
    squareFootage: "73294",
    propertyType: "Retail",
    totalCosts: "13750000",
    valueOfReclassifiedAssets: 3690664,
    depreciableAssets: 31,
  },
  {
    lat: 40.2672473,
    lng: -75.6091696,
    squareFootage: "93482",
    propertyType: "Retail",
    totalCosts: "16100000",
    valueOfReclassifiedAssets: 4773612,
    depreciableAssets: 35,
  },
  {
    lat: 42.4836453,
    lng: -71.4418101,
    squareFootage: "74876",
    propertyType: "Retail",
    totalCosts: "16463431",
    valueOfReclassifiedAssets: 4364021,
    depreciableAssets: 31,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "90000",
    propertyType: "Office",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 758026,
    depreciableAssets: 12,
  },
  {
    lat: 31.9685988,
    lng: -99.9018131,
    squareFootage: "54740",
    propertyType: "Industrial",
    totalCosts: "3716517",
    valueOfReclassifiedAssets: 752380,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "54740",
    propertyType: "Industrial",
    totalCosts: "3721034",
    valueOfReclassifiedAssets: 753295,
    depreciableAssets: 24,
  },
  {
    lat: 25.9868744,
    lng: -80.2153998,
    squareFootage: "78354",
    propertyType: "Specialty",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 2604571,
    depreciableAssets: 31,
  },
  {
    lat: 29.8054835,
    lng: -95.5564777,
    squareFootage: "62210",
    propertyType: "Office",
    totalCosts: "5519815",
    valueOfReclassifiedAssets: 1056710,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "101552",
    propertyType: "Retail",
    totalCosts: "8551234",
    valueOfReclassifiedAssets: 1819331,
    depreciableAssets: 25,
  },
  {
    lat: 29.8054835,
    lng: -95.5564777,
    squareFootage: "62210",
    propertyType: "Office",
    totalCosts: "5524331",
    valueOfReclassifiedAssets: 1057574,
    depreciableAssets: 23,
  },
  {
    lat: 29.7457034,
    lng: -95.7405139,
    squareFootage: "101552",
    propertyType: "Retail",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1808430,
    depreciableAssets: 25,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6915675",
    valueOfReclassifiedAssets: 6084517,
    depreciableAssets: 12,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "90000",
    propertyType: "Office",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 873923,
    depreciableAssets: 15,
  },
  {
    lat: 40.6517773,
    lng: -74.1797766,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8414700",
    valueOfReclassifiedAssets: 3591398,
    depreciableAssets: 58,
  },
  {
    lat: 40.9632898,
    lng: -74.30595459999999,
    squareFootage: "15698",
    propertyType: "Industrial",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 267645,
    depreciableAssets: 25,
  },
  {
    lat: 25.9502474,
    lng: -80.1930859,
    squareFootage: "130828",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 1737302,
    depreciableAssets: 19,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "69176",
    propertyType: "Multifamily",
    totalCosts: "507513",
    valueOfReclassifiedAssets: 74119,
    depreciableAssets: 20,
  },
  {
    lat: 41.87848510000001,
    lng: -87.627893,
    squareFootage: "558388",
    propertyType: "Office",
    totalCosts: "94254767",
    valueOfReclassifiedAssets: 17081655,
    depreciableAssets: 21,
  },
  {
    lat: 32.824634,
    lng: -96.6317461,
    squareFootage: "84944",
    propertyType: "Multifamily",
    totalCosts: "5346059",
    valueOfReclassifiedAssets: 3887024,
    depreciableAssets: 32,
  },
  {
    lat: 32.6477436,
    lng: -97.38407289999999,
    squareFootage: "112758",
    propertyType: "Multifamily",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1867348,
    depreciableAssets: 31,
  },
  {
    lat: 33.5829386,
    lng: -84.3279093,
    squareFootage: "270816",
    propertyType: "Multifamily",
    totalCosts: "12430000",
    valueOfReclassifiedAssets: 2861405,
    depreciableAssets: 27,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2295115",
    valueOfReclassifiedAssets: 1068881,
    depreciableAssets: 56,
  },
  {
    lat: 37.5464259,
    lng: -77.46446069999999,
    squareFootage: "82400",
    propertyType: "Multifamily",
    totalCosts: "11524507",
    valueOfReclassifiedAssets: 3220838,
    depreciableAssets: 30,
  },
  {
    lat: 41.89596299999999,
    lng: -72.35479149999999,
    squareFootage: "70000",
    propertyType: "Multifamily",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 1701320,
    depreciableAssets: 28,
  },
  {
    lat: 41.58028410000001,
    lng: -72.9047136,
    squareFootage: "37222",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 811552,
    depreciableAssets: 23,
  },
  {
    lat: 41.3757828,
    lng: -72.9342451,
    squareFootage: "405000",
    propertyType: "Multifamily",
    totalCosts: "63100000",
    valueOfReclassifiedAssets: 15346872,
    depreciableAssets: 29,
  },
  {
    lat: 41.5658733,
    lng: -73.0493825,
    squareFootage: "30700",
    propertyType: "Multifamily",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 612444,
    depreciableAssets: 33,
  },
  {
    lat: 41.5658733,
    lng: -73.0493825,
    squareFootage: "27000",
    propertyType: "Multifamily",
    totalCosts: "1795000",
    valueOfReclassifiedAssets: 1274177,
    depreciableAssets: 22,
  },
  {
    lat: 41.7370975,
    lng: -72.72744949999999,
    squareFootage: "163375",
    propertyType: "Multifamily",
    totalCosts: "12800000",
    valueOfReclassifiedAssets: 2266092,
    depreciableAssets: 21,
  },
  {
    lat: 41.7133607,
    lng: -72.20676809999999,
    squareFootage: "22824",
    propertyType: "Multifamily",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 504629,
    depreciableAssets: 34,
  },
  {
    lat: 41.8078488,
    lng: -72.224535,
    squareFootage: "53632",
    propertyType: "Multifamily",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 1238160,
    depreciableAssets: 31,
  },
  {
    lat: 41.5528933,
    lng: -73.0759446,
    squareFootage: "85126",
    propertyType: "Multifamily",
    totalCosts: "2917591",
    valueOfReclassifiedAssets: 720604,
    depreciableAssets: 28,
  },
  {
    lat: 41.5399755,
    lng: -72.9934468,
    squareFootage: "127232",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 935607,
    depreciableAssets: 23,
  },
  {
    lat: 32.8528678,
    lng: -97.30090679999999,
    squareFootage: "86034",
    propertyType: "Office",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1691596,
    depreciableAssets: 26,
  },
  {
    lat: 41.3844409,
    lng: -72.86336349999999,
    squareFootage: "128937",
    propertyType: "Multifamily",
    totalCosts: "14400000",
    valueOfReclassifiedAssets: 3196568,
    depreciableAssets: 24,
  },
  {
    lat: 41.5460323,
    lng: -72.6505957,
    squareFootage: "2015",
    propertyType: "Multifamily",
    totalCosts: "16400000",
    valueOfReclassifiedAssets: 5187162,
    depreciableAssets: 35,
  },
  {
    lat: 41.730201,
    lng: -72.8397317,
    squareFootage: "69004",
    propertyType: "Multifamily",
    totalCosts: "9700000",
    valueOfReclassifiedAssets: 2635843,
    depreciableAssets: 30,
  },
  {
    lat: 27.3350021,
    lng: -82.537412,
    squareFootage: "907237",
    propertyType: "Industrial",
    totalCosts: "51000000",
    valueOfReclassifiedAssets: 9854619,
    depreciableAssets: 23,
  },
  {
    lat: 33.78659,
    lng: -117.8750976,
    squareFootage: "18768",
    propertyType: "Retail",
    totalCosts: "6727100",
    valueOfReclassifiedAssets: 2204889,
    depreciableAssets: 37,
  },
  {
    lat: 33.8724552,
    lng: -118.0595907,
    squareFootage: "67907",
    propertyType: "Retail",
    totalCosts: "8614059",
    valueOfReclassifiedAssets: 5923936,
    depreciableAssets: 43,
  },
  {
    lat: 39.9759847,
    lng: -75.2537397,
    squareFootage: "88156",
    propertyType: "Multifamily",
    totalCosts: "10700000",
    valueOfReclassifiedAssets: 2030623,
    depreciableAssets: 23,
  },
  {
    lat: 39.9600168,
    lng: -75.22748659999999,
    squareFootage: "34000",
    propertyType: "Multifamily",
    totalCosts: "3330000",
    valueOfReclassifiedAssets: 579619,
    depreciableAssets: 20,
  },
  {
    lat: 29.8390871,
    lng: -95.15363269999999,
    squareFootage: "82488",
    propertyType: "Industrial",
    totalCosts: "12321424",
    valueOfReclassifiedAssets: 6009402,
    depreciableAssets: 53,
  },
  {
    lat: 43.0802842,
    lng: -71.4536835,
    squareFootage: "164136",
    propertyType: "Industrial",
    totalCosts: "31657123",
    valueOfReclassifiedAssets: 5087623,
    depreciableAssets: 19,
  },
  {
    lat: 40.7972384,
    lng: -74.1063776,
    squareFootage: "135464",
    propertyType: "Office",
    totalCosts: "21425000",
    valueOfReclassifiedAssets: 4633087,
    depreciableAssets: 25,
  },
  {
    lat: 40.7972384,
    lng: -74.1063776,
    squareFootage: "291110",
    propertyType: "Office",
    totalCosts: "29602933",
    valueOfReclassifiedAssets: 5531457,
    depreciableAssets: 22,
  },
  {
    lat: 38.1495841,
    lng: -85.8793252,
    squareFootage: "300462",
    propertyType: "Industrial",
    totalCosts: "21367325",
    valueOfReclassifiedAssets: 4191680,
    depreciableAssets: 23,
  },
  {
    lat: 38.1495841,
    lng: -85.8793252,
    squareFootage: "300462",
    propertyType: "Industrial",
    totalCosts: "21367325",
    valueOfReclassifiedAssets: 4191680,
    depreciableAssets: 23,
  },
  {
    lat: 43.0802842,
    lng: -71.4536835,
    squareFootage: "164136",
    propertyType: "Industrial",
    totalCosts: "31657123",
    valueOfReclassifiedAssets: 5087623,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "20745",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4897173",
    valueOfReclassifiedAssets: 1525655,
    depreciableAssets: 32,
  },
  {
    lat: 40.843339,
    lng: -74.1533563,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1199162",
    valueOfReclassifiedAssets: 842942,
    depreciableAssets: 31,
  },
  {
    lat: 37.1953893,
    lng: -93.3892894,
    squareFootage: "71539",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2663691,
    depreciableAssets: 26,
  },
  {
    lat: 41.5460323,
    lng: -72.6505957,
    squareFootage: "81368",
    propertyType: "Office",
    totalCosts: "2583479",
    valueOfReclassifiedAssets: 2030314,
    depreciableAssets: 32,
  },
  {
    lat: 41.8386218,
    lng: -72.82200639999999,
    squareFootage: "42212",
    propertyType: "Office",
    totalCosts: "821923",
    valueOfReclassifiedAssets: 631211,
    depreciableAssets: 31,
  },
  {
    lat: 41.8785329,
    lng: -72.6742455,
    squareFootage: "162886",
    propertyType: "Office",
    totalCosts: "3787172",
    valueOfReclassifiedAssets: 3168100,
    depreciableAssets: 24,
  },
  {
    lat: 42.1805732,
    lng: -85.57150150000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "679000",
    valueOfReclassifiedAssets: 573209,
    depreciableAssets: 59,
  },
  {
    lat: 29.3455351,
    lng: -98.56660769999999,
    squareFootage: "89850",
    propertyType: "Retail",
    totalCosts: "10225000",
    valueOfReclassifiedAssets: 2357182,
    depreciableAssets: 27,
  },
  {
    lat: 29.61721229999999,
    lng: -98.7260927,
    squareFootage: "49654",
    propertyType: "Retail",
    totalCosts: "10493199",
    valueOfReclassifiedAssets: 2183109,
    depreciableAssets: 24,
  },
  {
    lat: 29.5516834,
    lng: -98.49522280000001,
    squareFootage: "179400",
    propertyType: "Office",
    totalCosts: "42683919",
    valueOfReclassifiedAssets: 6289803,
    depreciableAssets: 16,
  },
  {
    lat: 39.0751243,
    lng: -76.6297189,
    squareFootage: "8736",
    propertyType: "Office",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 615263,
    depreciableAssets: 32,
  },
  {
    lat: 38.9218512,
    lng: -76.7221082,
    squareFootage: "12600",
    propertyType: "Office",
    totalCosts: "4075000",
    valueOfReclassifiedAssets: 1241860,
    depreciableAssets: 36,
  },
  {
    lat: 41.0887754,
    lng: -74.1827119,
    squareFootage: "54000",
    propertyType: "Industrial",
    totalCosts: "4573948",
    valueOfReclassifiedAssets: 505471,
    depreciableAssets: 13,
  },
  {
    lat: 41.2945721,
    lng: -95.9145568,
    squareFootage: "54000",
    propertyType: "Hotel",
    totalCosts: "10689000",
    valueOfReclassifiedAssets: 2536256,
    depreciableAssets: 28,
  },
  {
    lat: 41.2945721,
    lng: -95.9145568,
    squareFootage: "51990",
    propertyType: "Hotel",
    totalCosts: "10475000",
    valueOfReclassifiedAssets: 2533413,
    depreciableAssets: 26,
  },
  {
    lat: 29.58857609999999,
    lng: -98.2760021,
    squareFootage: "49013",
    propertyType: "Hotel",
    totalCosts: "7090686",
    valueOfReclassifiedAssets: 1788777,
    depreciableAssets: 29,
  },
  {
    lat: 41.8659365,
    lng: -87.9089623,
    squareFootage: "77445",
    propertyType: "Hotel",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 2048521,
    depreciableAssets: 23,
  },
  {
    lat: 41.2945721,
    lng: -95.9145568,
    squareFootage: "60000",
    propertyType: "Hotel",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 825933,
    depreciableAssets: 22,
  },
  {
    lat: 39.7158773,
    lng: -105.2311827,
    squareFootage: "49183",
    propertyType: "Office",
    totalCosts: "2448000",
    valueOfReclassifiedAssets: 554147,
    depreciableAssets: 27,
  },
  {
    lat: 33.6071829,
    lng: -111.9403254,
    squareFootage: "29806",
    propertyType: "Office",
    totalCosts: "1152000",
    valueOfReclassifiedAssets: 352422,
    depreciableAssets: 36,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3753790",
    valueOfReclassifiedAssets: 1545777,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1793383",
    valueOfReclassifiedAssets: 1219299,
    depreciableAssets: 0,
  },
  {
    lat: 41.8817767,
    lng: -87.6371461,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1439665",
    valueOfReclassifiedAssets: 642596,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "704962",
    valueOfReclassifiedAssets: 93386,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1227536",
    valueOfReclassifiedAssets: 707796,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15806636",
    valueOfReclassifiedAssets: 10958358,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2055868",
    valueOfReclassifiedAssets: 727109,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1400041",
    valueOfReclassifiedAssets: 480010,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "822701",
    valueOfReclassifiedAssets: 0,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3030591",
    valueOfReclassifiedAssets: 1056966,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "145130",
    valueOfReclassifiedAssets: 39159,
    depreciableAssets: 0,
  },
  {
    lat: 41.9003099,
    lng: -87.63338710000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "891450",
    valueOfReclassifiedAssets: 417442,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "931302",
    valueOfReclassifiedAssets: 441444,
    depreciableAssets: 0,
  },
  {
    lat: 41.8817767,
    lng: -87.6371461,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "756460",
    valueOfReclassifiedAssets: 464145,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "1111925",
    propertyType: "Office",
    totalCosts: "353489717",
    valueOfReclassifiedAssets: 40531453,
    depreciableAssets: 13,
  },
  {
    lat: 41.8074687,
    lng: -87.70740889999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4156096",
    valueOfReclassifiedAssets: 2335995,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "856200",
    valueOfReclassifiedAssets: 528437,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "87171",
    valueOfReclassifiedAssets: 51491,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1912582",
    valueOfReclassifiedAssets: 1078340,
    depreciableAssets: 0,
  },
  {
    lat: 41.8817767,
    lng: -87.6371461,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "560318",
    valueOfReclassifiedAssets: 546104,
    depreciableAssets: 0,
  },
  {
    lat: 41.8819283,
    lng: -87.6445473,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10745805",
    valueOfReclassifiedAssets: 7251726,
    depreciableAssets: 35,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "373539",
    valueOfReclassifiedAssets: 364907,
    depreciableAssets: 100,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1770851",
    valueOfReclassifiedAssets: 1622717,
    depreciableAssets: 100,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "604331",
    valueOfReclassifiedAssets: 392650,
    depreciableAssets: 38,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "671915",
    valueOfReclassifiedAssets: 341379,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "919747",
    valueOfReclassifiedAssets: 797324,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "213120",
    valueOfReclassifiedAssets: 209930,
    depreciableAssets: 0,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "108252",
    valueOfReclassifiedAssets: 78077,
    depreciableAssets: 0,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "477871",
    valueOfReclassifiedAssets: 0,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "789535",
    valueOfReclassifiedAssets: 649188,
    depreciableAssets: 21,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "280997",
    valueOfReclassifiedAssets: 259511,
    depreciableAssets: 0,
  },
  {
    lat: 40.222434,
    lng: -74.7483371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "316096",
    valueOfReclassifiedAssets: 31859,
    depreciableAssets: 0,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "451679",
    valueOfReclassifiedAssets: 320028,
    depreciableAssets: 0,
  },
  {
    lat: 40.2482028,
    lng: -74.69858909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1338356",
    valueOfReclassifiedAssets: 1136233,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1968766",
    valueOfReclassifiedAssets: 1718747,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "792755",
    valueOfReclassifiedAssets: 746172,
    depreciableAssets: 0,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "457302",
    valueOfReclassifiedAssets: 139530,
    depreciableAssets: 70,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "956431",
    valueOfReclassifiedAssets: 858731,
    depreciableAssets: 14,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "102108",
    propertyType: "Industrial",
    totalCosts: "4594860",
    valueOfReclassifiedAssets: 340761,
    depreciableAssets: 8,
  },
  {
    lat: 40.5934061,
    lng: -111.9641728,
    squareFootage: "18000",
    propertyType: "Industrial",
    totalCosts: "4189198",
    valueOfReclassifiedAssets: 1422054,
    depreciableAssets: 40,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "108240",
    propertyType: "Multifamily",
    totalCosts: "5198821",
    valueOfReclassifiedAssets: 1137610,
    depreciableAssets: 30,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "217630",
    propertyType: "Multifamily",
    totalCosts: "9166000",
    valueOfReclassifiedAssets: 1952975,
    depreciableAssets: 26,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "93680",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1229560,
    depreciableAssets: 30,
  },
  {
    lat: 33.9052071,
    lng: -83.3148018,
    squareFootage: "248611",
    propertyType: "Multifamily",
    totalCosts: "13450000",
    valueOfReclassifiedAssets: 3281547,
    depreciableAssets: 28,
  },
  {
    lat: 34.9208336,
    lng: -82.37773349999999,
    squareFootage: "310240",
    propertyType: "Multifamily",
    totalCosts: "16750000",
    valueOfReclassifiedAssets: 3468920,
    depreciableAssets: 28,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "268422",
    propertyType: "Multifamily",
    totalCosts: "9819000",
    valueOfReclassifiedAssets: 2077145,
    depreciableAssets: 26,
  },
  {
    lat: 29.6813519,
    lng: -95.2005729,
    squareFootage: "86664",
    propertyType: "Multifamily",
    totalCosts: "4110000",
    valueOfReclassifiedAssets: 1162876,
    depreciableAssets: 34,
  },
  {
    lat: 29.6526666,
    lng: -95.1953554,
    squareFootage: "151248",
    propertyType: "Multifamily",
    totalCosts: "7170000",
    valueOfReclassifiedAssets: 2360264,
    depreciableAssets: 39,
  },
  {
    lat: 29.71601119999999,
    lng: -95.2005729,
    squareFootage: "142644",
    propertyType: "Multifamily",
    totalCosts: "7820000",
    valueOfReclassifiedAssets: 2765208,
    depreciableAssets: 42,
  },
  {
    lat: 25.9369686,
    lng: -81.5596427,
    squareFootage: "31000",
    propertyType: "Industrial",
    totalCosts: "2484616",
    valueOfReclassifiedAssets: 2379255,
    depreciableAssets: 21,
  },
  {
    lat: 42.3279674,
    lng: -88.0411993,
    squareFootage: "73650",
    propertyType: "Multifamily",
    totalCosts: "5006302",
    valueOfReclassifiedAssets: 1004591,
    depreciableAssets: 23,
  },
  {
    lat: 38.9396261,
    lng: -76.9902276,
    squareFootage: "72512",
    propertyType: "Industrial",
    totalCosts: "13102855",
    valueOfReclassifiedAssets: 12108247,
    depreciableAssets: 20,
  },
  {
    lat: 39.2112091,
    lng: -76.745195,
    squareFootage: "89275",
    propertyType: "Retail",
    totalCosts: "12949814",
    valueOfReclassifiedAssets: 4254543,
    depreciableAssets: 36,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "279585",
    propertyType: "Retail",
    totalCosts: "28600000",
    valueOfReclassifiedAssets: 8162004,
    depreciableAssets: 32,
  },
  {
    lat: 39.2112091,
    lng: -76.745195,
    squareFootage: "73529",
    propertyType: "Retail",
    totalCosts: "11391715",
    valueOfReclassifiedAssets: 3221155,
    depreciableAssets: 32,
  },
  {
    lat: 38.44666309999999,
    lng: -77.355413,
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "20287927",
    valueOfReclassifiedAssets: 17171233,
    depreciableAssets: 32,
  },
  {
    lat: 41.8703314,
    lng: -87.62357420000001,
    squareFootage: "61100",
    propertyType: "Industrial",
    totalCosts: "6430000",
    valueOfReclassifiedAssets: 527487,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "12232",
    propertyType: "Office",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 684868,
    depreciableAssets: 25,
  },
  {
    lat: 40.0388765,
    lng: -82.8748301,
    squareFootage: "36198",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1314139,
    depreciableAssets: 29,
  },
  {
    lat: 41.1740315,
    lng: -81.521241,
    squareFootage: "61138",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9122220",
    valueOfReclassifiedAssets: 2587882,
    depreciableAssets: 31,
  },
  {
    lat: 39.9705946,
    lng: -82.9689738,
    squareFootage: "55446",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9110000",
    valueOfReclassifiedAssets: 2365423,
    depreciableAssets: 31,
  },
  {
    lat: 41.1740315,
    lng: -81.521241,
    squareFootage: "61138",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2553210,
    depreciableAssets: 31,
  },
  {
    lat: 42.2427186,
    lng: -83.2184763,
    squareFootage: "109010",
    propertyType: "Multifamily",
    totalCosts: "14650000",
    valueOfReclassifiedAssets: 2009862,
    depreciableAssets: 16,
  },
  {
    lat: 32.8997559,
    lng: -95.2893433,
    squareFootage: "29691",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5644859",
    valueOfReclassifiedAssets: 1821883,
    depreciableAssets: 36,
  },
  {
    lat: 31.5168633,
    lng: -94.11044489999999,
    squareFootage: "30500",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3863966",
    valueOfReclassifiedAssets: 997336,
    depreciableAssets: 29,
  },
  {
    lat: 39.9705946,
    lng: -82.9689738,
    squareFootage: "55446",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9178853",
    valueOfReclassifiedAssets: 2383301,
    depreciableAssets: 31,
  },
  {
    lat: 40.0388765,
    lng: -82.8748301,
    squareFootage: "36198",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5070042",
    valueOfReclassifiedAssets: 1332548,
    depreciableAssets: 29,
  },
  {
    lat: 42.2427186,
    lng: -83.2184763,
    squareFootage: "109010",
    propertyType: "Multifamily",
    totalCosts: "14463558",
    valueOfReclassifiedAssets: 1984379,
    depreciableAssets: 16,
  },
  {
    lat: 33.1872424,
    lng: -92.5792482,
    squareFootage: "63005",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5059480",
    valueOfReclassifiedAssets: 1386390,
    depreciableAssets: 31,
  },
  {
    lat: 32.640619,
    lng: -97.2167511,
    squareFootage: "12582",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5331756",
    valueOfReclassifiedAssets: 2092036,
    depreciableAssets: 44,
  },
  {
    lat: 47.4475151,
    lng: -122.3567244,
    squareFootage: "23000",
    propertyType: "Multifamily",
    totalCosts: "1819476",
    valueOfReclassifiedAssets: 424355,
    depreciableAssets: 29,
  },
  {
    lat: 47.4475151,
    lng: -122.3567244,
    squareFootage: "19222",
    propertyType: "Multifamily",
    totalCosts: "1408901",
    valueOfReclassifiedAssets: 370844,
    depreciableAssets: 31,
  },
  {
    lat: 47.2945159,
    lng: -122.2192678,
    squareFootage: "24480",
    propertyType: "Multifamily",
    totalCosts: "1252906",
    valueOfReclassifiedAssets: 221978,
    depreciableAssets: 19,
  },
  {
    lat: 47.49427379999999,
    lng: -122.2081419,
    squareFootage: "55500",
    propertyType: "Multifamily",
    totalCosts: "3334733",
    valueOfReclassifiedAssets: 2758922,
    depreciableAssets: 18,
  },
  {
    lat: 47.3165043,
    lng: -122.3223975,
    squareFootage: "14048",
    propertyType: "Office",
    totalCosts: "599746",
    valueOfReclassifiedAssets: 103958,
    depreciableAssets: 24,
  },
  {
    lat: 47.4475151,
    lng: -122.3567244,
    squareFootage: "28248",
    propertyType: "Office",
    totalCosts: "1183199",
    valueOfReclassifiedAssets: 185719,
    depreciableAssets: 26,
  },
  {
    lat: 47.7170204,
    lng: -122.3009337,
    squareFootage: "34145",
    propertyType: "Office",
    totalCosts: "3494883",
    valueOfReclassifiedAssets: 614723,
    depreciableAssets: 23,
  },
  {
    lat: 40.9583569,
    lng: -74.12986839999999,
    squareFootage: "103349",
    propertyType: "Office",
    totalCosts: "12150000",
    valueOfReclassifiedAssets: 2622112,
    depreciableAssets: 26,
  },
  {
    lat: 33.9025669,
    lng: -84.25421949999999,
    squareFootage: "9002",
    propertyType: "Retail",
    totalCosts: "2124661",
    valueOfReclassifiedAssets: 443774,
    depreciableAssets: 25,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "12925",
    propertyType: "Specialty",
    totalCosts: "18300000",
    valueOfReclassifiedAssets: 16470000,
    depreciableAssets: 100,
  },
  {
    lat: 33.755711,
    lng: -84.3883717,
    squareFootage: "77691",
    propertyType: "Office",
    totalCosts: "10250000",
    valueOfReclassifiedAssets: 1532370,
    depreciableAssets: 18,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3683495",
    valueOfReclassifiedAssets: 1976824,
    depreciableAssets: 0,
  },
  {
    lat: 38.8931743,
    lng: -77.0251409,
    squareFootage: "196920",
    propertyType: "Office",
    totalCosts: "135000000",
    valueOfReclassifiedAssets: 11375104,
    depreciableAssets: 11,
  },
  {
    lat: 34.0126379,
    lng: -118.495155,
    squareFootage: "11250",
    propertyType: "Retail",
    totalCosts: "58000000",
    valueOfReclassifiedAssets: 3558479,
    depreciableAssets: 0,
  },
  {
    lat: 41.9203468,
    lng: -87.6433139,
    squareFootage: "100775",
    propertyType: "Hotel",
    totalCosts: "73000000",
    valueOfReclassifiedAssets: 17404345,
    depreciableAssets: 28,
  },
  {
    lat: 38.8951382,
    lng: -77.0226217,
    squareFootage: "187470",
    propertyType: "Office",
    totalCosts: "83500000",
    valueOfReclassifiedAssets: 10234533,
    depreciableAssets: 14,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "378596",
    propertyType: "Hotel",
    totalCosts: "70469100",
    valueOfReclassifiedAssets: 8207520,
    depreciableAssets: 13,
  },
  {
    lat: 37.7786871,
    lng: -122.4212424,
    squareFootage: "46024",
    propertyType: "Office",
    totalCosts: "46631995",
    valueOfReclassifiedAssets: 5850184,
    depreciableAssets: 14,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "7580",
    propertyType: "Office",
    totalCosts: "25250000",
    valueOfReclassifiedAssets: 2510173,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "20962",
    propertyType: "Hotel",
    totalCosts: "3630900",
    valueOfReclassifiedAssets: 855331,
    depreciableAssets: 28,
  },
  {
    lat: 38.908229,
    lng: -77.03053899999999,
    squareFootage: "171920",
    propertyType: "Office",
    totalCosts: "104300000",
    valueOfReclassifiedAssets: 8971416,
    depreciableAssets: 10,
  },
  {
    lat: 36.128561,
    lng: -115.1711298,
    squareFootage: "103162",
    propertyType: "Retail",
    totalCosts: "139500000",
    valueOfReclassifiedAssets: 13026072,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "25400",
    propertyType: "Office",
    totalCosts: "33832170",
    valueOfReclassifiedAssets: 3709589,
    depreciableAssets: 16,
  },
  {
    lat: 34.2570374,
    lng: -118.4279933,
    squareFootage: "18950",
    propertyType: "Industrial",
    totalCosts: "3002437",
    valueOfReclassifiedAssets: 163669,
    depreciableAssets: 8,
  },
  {
    lat: 34.1103407,
    lng: -118.2585096,
    squareFootage: "69125",
    propertyType: "Industrial",
    totalCosts: "10015484",
    valueOfReclassifiedAssets: 684359,
    depreciableAssets: 16,
  },
  {
    lat: 34.2570374,
    lng: -118.4279933,
    squareFootage: "18950",
    propertyType: "Industrial",
    totalCosts: "3002437",
    valueOfReclassifiedAssets: 139303,
    depreciableAssets: 15,
  },
  {
    lat: 33.4040693,
    lng: -112.029752,
    squareFootage: "36230",
    propertyType: "Industrial",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 318045,
    depreciableAssets: 15,
  },
  {
    lat: 34.2389818,
    lng: -118.2351192,
    squareFootage: "14563",
    propertyType: "Retail",
    totalCosts: "4869981",
    valueOfReclassifiedAssets: 781727,
    depreciableAssets: 38,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "73504",
    propertyType: "Industrial",
    totalCosts: "2502249",
    valueOfReclassifiedAssets: 2315677,
    depreciableAssets: 19,
  },
  {
    lat: 39.853124,
    lng: -84.1224036,
    squareFootage: "104000",
    propertyType: "Industrial",
    totalCosts: "3111410",
    valueOfReclassifiedAssets: 536664,
    depreciableAssets: 21,
  },
  {
    lat: 33.9167378,
    lng: -118.1298234,
    squareFootage: "21112",
    propertyType: "Multifamily",
    totalCosts: "1647293",
    valueOfReclassifiedAssets: 1365050,
    depreciableAssets: 33,
  },
  {
    lat: 41.1935626,
    lng: -73.2470486,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2854033",
    valueOfReclassifiedAssets: 1474295,
    depreciableAssets: 0,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3031698",
    valueOfReclassifiedAssets: 2296369,
    depreciableAssets: 42,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2567637",
    valueOfReclassifiedAssets: 1911100,
    depreciableAssets: 27,
  },
  {
    lat: 41.7929916,
    lng: -72.7451817,
    squareFootage: "163422",
    propertyType: "Specialty",
    totalCosts: "13280000",
    valueOfReclassifiedAssets: 2785525,
    depreciableAssets: 24,
  },
  {
    lat: 41.1935626,
    lng: -73.2470486,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3734153",
    valueOfReclassifiedAssets: 2630605,
    depreciableAssets: 31,
  },
  {
    lat: 41.1935626,
    lng: -73.2470486,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12663480",
    valueOfReclassifiedAssets: 9084462,
    depreciableAssets: 36,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1966815",
    valueOfReclassifiedAssets: 600529,
    depreciableAssets: 70,
  },
  {
    lat: 43.6153549,
    lng: -72.92243289999999,
    squareFootage: "47544",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4450384",
    valueOfReclassifiedAssets: 3613206,
    depreciableAssets: 23,
  },
  {
    lat: 40.6651384,
    lng: -73.52996399999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19539792",
    valueOfReclassifiedAssets: 15034643,
    depreciableAssets: 28,
  },
  {
    lat: 42.8503275,
    lng: -72.561284,
    squareFootage: "51288",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4465602",
    valueOfReclassifiedAssets: 3691999,
    depreciableAssets: 22,
  },
  {
    lat: 40.8144014,
    lng: -73.4772682,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2428184",
    valueOfReclassifiedAssets: 1552673,
    depreciableAssets: 40,
  },
  {
    lat: 26.6211772,
    lng: -80.09260139999999,
    squareFootage: "201671",
    propertyType: "Multifamily",
    totalCosts: "12900000",
    valueOfReclassifiedAssets: 3150337,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "34458",
    propertyType: "Office",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 898132,
    depreciableAssets: 19,
  },
  {
    lat: 26.5254562,
    lng: -80.0590804,
    squareFootage: "20881",
    propertyType: "Office",
    totalCosts: "1971000",
    valueOfReclassifiedAssets: 1750444,
    depreciableAssets: 27,
  },
  {
    lat: 26.7152999,
    lng: -80.1037721,
    squareFootage: "25143",
    propertyType: "Office",
    totalCosts: "2865000",
    valueOfReclassifiedAssets: 822186,
    depreciableAssets: 32,
  },
  {
    lat: 25.8535635,
    lng: -80.1484407,
    squareFootage: "21336",
    propertyType: "Multifamily",
    totalCosts: "1558000",
    valueOfReclassifiedAssets: 1397683,
    depreciableAssets: 16,
  },
  {
    lat: 26.0195493,
    lng: -80.22352269999999,
    squareFootage: "37321",
    propertyType: "Office",
    totalCosts: "6050910",
    valueOfReclassifiedAssets: 5601455,
    depreciableAssets: 18,
  },
  {
    lat: 39.7713425,
    lng: -86.15737089999999,
    squareFootage: "74035",
    propertyType: "Office",
    totalCosts: "3224728",
    valueOfReclassifiedAssets: 409254,
    depreciableAssets: 16,
  },
  {
    lat: 39.9358823,
    lng: -86.1624587,
    squareFootage: "81182",
    propertyType: "Office",
    totalCosts: "5085000",
    valueOfReclassifiedAssets: 4362019,
    depreciableAssets: 21,
  },
  {
    lat: 26.411026,
    lng: -80.09260139999999,
    squareFootage: "42455",
    propertyType: "Office",
    totalCosts: "7450000",
    valueOfReclassifiedAssets: 1414441,
    depreciableAssets: 25,
  },
  {
    lat: 39.9358823,
    lng: -86.1624587,
    squareFootage: "68010",
    propertyType: "Office",
    totalCosts: "2790000",
    valueOfReclassifiedAssets: 2390696,
    depreciableAssets: 21,
  },
  {
    lat: 39.9358823,
    lng: -86.1624587,
    squareFootage: "30716",
    propertyType: "Office",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 789185,
    depreciableAssets: 29,
  },
  {
    lat: 39.7713425,
    lng: -86.15737089999999,
    squareFootage: "105300",
    propertyType: "Office",
    totalCosts: "9610000",
    valueOfReclassifiedAssets: 1073766,
    depreciableAssets: 12,
  },
  {
    lat: 25.8897406,
    lng: -80.20703280000001,
    squareFootage: "80000",
    propertyType: "Office",
    totalCosts: "40000000",
    valueOfReclassifiedAssets: 8890162,
    depreciableAssets: 26,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "71600",
    propertyType: "Multifamily",
    totalCosts: "29317158",
    valueOfReclassifiedAssets: 24295159,
    depreciableAssets: 19,
  },
  {
    lat: 41.7888066,
    lng: -72.67128939999999,
    squareFootage: "39900",
    propertyType: "Retail",
    totalCosts: "3190681",
    valueOfReclassifiedAssets: 2607595,
    depreciableAssets: 26,
  },
  {
    lat: 26.5386936,
    lng: -80.0814292,
    squareFootage: "10024",
    propertyType: "Office",
    totalCosts: "5215635",
    valueOfReclassifiedAssets: 1168803,
    depreciableAssets: 28,
  },
  {
    lat: 41.54304640000001,
    lng: -73.6653939,
    squareFootage: "61248",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9337260",
    valueOfReclassifiedAssets: 1760878,
    depreciableAssets: 22,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "24200",
    propertyType: "Office",
    totalCosts: "4743224",
    valueOfReclassifiedAssets: 4251679,
    depreciableAssets: 19,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "24200",
    propertyType: "Office",
    totalCosts: "5678489",
    valueOfReclassifiedAssets: 930213,
    depreciableAssets: 19,
  },
  {
    lat: 33.723039,
    lng: -84.3436881,
    squareFootage: "164920",
    propertyType: "Multifamily",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 584984,
    depreciableAssets: 30,
  },
  {
    lat: 34.8287425,
    lng: -87.6482474,
    squareFootage: "74140",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12508988",
    valueOfReclassifiedAssets: 10093029,
    depreciableAssets: 27,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "35445",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15050000",
    valueOfReclassifiedAssets: 3063187,
    depreciableAssets: 26,
  },
  {
    lat: 30.111156,
    lng: -82.5834947,
    squareFootage: "36096",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10395009",
    valueOfReclassifiedAssets: 7378657,
    depreciableAssets: 36,
  },
  {
    lat: 28.657897,
    lng: -82.1931149,
    squareFootage: "28480",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6930006",
    valueOfReclassifiedAssets: 5017820,
    depreciableAssets: 38,
  },
  {
    lat: 35.9641189,
    lng: -83.92016559999999,
    squareFootage: "38015",
    propertyType: "Retail",
    totalCosts: "2402125",
    valueOfReclassifiedAssets: 1950927,
    depreciableAssets: 21,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "40926",
    propertyType: "Office",
    totalCosts: "6022600",
    valueOfReclassifiedAssets: 610079,
    depreciableAssets: 13,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "139887",
    propertyType: "Office",
    totalCosts: "5739351",
    valueOfReclassifiedAssets: 4886975,
    depreciableAssets: 20,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "139887",
    propertyType: "Office",
    totalCosts: "68200325",
    valueOfReclassifiedAssets: 58071602,
    depreciableAssets: 20,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "296000",
    propertyType: "Office",
    totalCosts: "6779739",
    valueOfReclassifiedAssets: 681879,
    depreciableAssets: 11,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "934216",
    propertyType: "Office",
    totalCosts: "5244010",
    valueOfReclassifiedAssets: 873612,
    depreciableAssets: 19,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "13780",
    propertyType: "Retail",
    totalCosts: "25314436",
    valueOfReclassifiedAssets: 20620401,
    depreciableAssets: 21,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "934216",
    propertyType: "Office",
    totalCosts: "164312322",
    valueOfReclassifiedAssets: 27373197,
    depreciableAssets: 19,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "296000",
    propertyType: "Office",
    totalCosts: "2264721",
    valueOfReclassifiedAssets: 227780,
    depreciableAssets: 11,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "257530",
    propertyType: "Retail",
    totalCosts: "20253880",
    valueOfReclassifiedAssets: 3588922,
    depreciableAssets: 19,
  },
  {
    lat: 38.5631758,
    lng: -76.0788519,
    squareFootage: "62118",
    propertyType: "Retail",
    totalCosts: "5200108",
    valueOfReclassifiedAssets: 1001074,
    depreciableAssets: 22,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "164616",
    propertyType: "Office",
    totalCosts: "81962809",
    valueOfReclassifiedAssets: 73145279,
    depreciableAssets: 11,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "296000",
    propertyType: "Office",
    totalCosts: "14759468",
    valueOfReclassifiedAssets: 1483645,
    depreciableAssets: 11,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "31626",
    propertyType: "Retail",
    totalCosts: "7576734",
    valueOfReclassifiedAssets: 1477938,
    depreciableAssets: 22,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "296000",
    propertyType: "Office",
    totalCosts: "8881920",
    valueOfReclassifiedAssets: 918937,
    depreciableAssets: 11,
  },
  {
    lat: 39.1045785,
    lng: -94.58952939999999,
    squareFootage: "656575",
    propertyType: "Office",
    totalCosts: "24131544",
    valueOfReclassifiedAssets: 1538178,
    depreciableAssets: 0,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "686503",
    propertyType: "Office",
    totalCosts: "100258143",
    valueOfReclassifiedAssets: 9660708,
    depreciableAssets: 11,
  },
  {
    lat: 38.8095735,
    lng: -90.3465971,
    squareFootage: "91168",
    propertyType: "Retail",
    totalCosts: "8972269",
    valueOfReclassifiedAssets: 1307046,
    depreciableAssets: 16,
  },
  {
    lat: 30.224947,
    lng: -90.9247501,
    squareFootage: "78936",
    propertyType: "Retail",
    totalCosts: "6581156",
    valueOfReclassifiedAssets: 976620,
    depreciableAssets: 17,
  },
  {
    lat: 26.2286939,
    lng: -80.1596041,
    squareFootage: "58260",
    propertyType: "Retail",
    totalCosts: "10840665",
    valueOfReclassifiedAssets: 2118753,
    depreciableAssets: 22,
  },
  {
    lat: 39.9556241,
    lng: -75.1647529,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "320388873",
    valueOfReclassifiedAssets: 53843601,
    depreciableAssets: 19,
  },
  {
    lat: 28.6553497,
    lng: -81.3426916,
    squareFootage: "108632",
    propertyType: "Retail",
    totalCosts: "6823904",
    valueOfReclassifiedAssets: 1334959,
    depreciableAssets: 22,
  },
  {
    lat: 36.105232,
    lng: -80.2042436,
    squareFootage: "461857",
    propertyType: "Office",
    totalCosts: "40317681",
    valueOfReclassifiedAssets: 2338210,
    depreciableAssets: 0,
  },
  {
    lat: 40.7555263,
    lng: -73.9784116,
    squareFootage: "87000",
    propertyType: "Office",
    totalCosts: "42431153",
    valueOfReclassifiedAssets: 38114299,
    depreciableAssets: 0,
  },
  {
    lat: 36.105232,
    lng: -80.2042436,
    squareFootage: "461857",
    propertyType: "Office",
    totalCosts: "2616353",
    valueOfReclassifiedAssets: 150950,
    depreciableAssets: 0,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "2015",
    propertyType: "Office",
    totalCosts: "2980828",
    valueOfReclassifiedAssets: 129015,
    depreciableAssets: 5,
  },
  {
    lat: 44.9754804,
    lng: -93.269686,
    squareFootage: "136222",
    propertyType: "Office",
    totalCosts: "29380901",
    valueOfReclassifiedAssets: 3539710,
    depreciableAssets: 13,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "139887",
    propertyType: "Office",
    totalCosts: "7010265",
    valueOfReclassifiedAssets: 5969141,
    depreciableAssets: 20,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "2015",
    propertyType: "Office",
    totalCosts: "13528459",
    valueOfReclassifiedAssets: 588246,
    depreciableAssets: 5,
  },
  {
    lat: 30.3658304,
    lng: -81.49379929999999,
    squareFootage: "66200",
    propertyType: "Retail",
    totalCosts: "10084715",
    valueOfReclassifiedAssets: 3449245,
    depreciableAssets: 38,
  },
  {
    lat: 39.9556241,
    lng: -75.1647529,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "324488873",
    valueOfReclassifiedAssets: 54611221,
    depreciableAssets: 19,
  },
  {
    lat: 44.9513188,
    lng: -93.0901841,
    squareFootage: "961161",
    propertyType: "Office",
    totalCosts: "22699319",
    valueOfReclassifiedAssets: 1922798,
    depreciableAssets: 0,
  },
  {
    lat: 42.8976471,
    lng: -71.2715443,
    squareFootage: "208805",
    propertyType: "Retail",
    totalCosts: "3195722",
    valueOfReclassifiedAssets: 741195,
    depreciableAssets: 25,
  },
  {
    lat: 26.3042864,
    lng: -80.15402259999999,
    squareFootage: "140312",
    propertyType: "Retail",
    totalCosts: "20322853",
    valueOfReclassifiedAssets: 6090814,
    depreciableAssets: 33,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "2015",
    propertyType: "Office",
    totalCosts: "18185959",
    valueOfReclassifiedAssets: 787118,
    depreciableAssets: 5,
  },
  {
    lat: 42.8976471,
    lng: -71.2715443,
    squareFootage: "208805",
    propertyType: "Retail",
    totalCosts: "9587165",
    valueOfReclassifiedAssets: 2223585,
    depreciableAssets: 25,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "2015",
    propertyType: "Office",
    totalCosts: "38833242",
    valueOfReclassifiedAssets: 1673348,
    depreciableAssets: 5,
  },
  {
    lat: 39.7713425,
    lng: -86.15737089999999,
    squareFootage: "705292",
    propertyType: "Office",
    totalCosts: "51180818",
    valueOfReclassifiedAssets: 6771043,
    depreciableAssets: 14,
  },
  {
    lat: 34.0716538,
    lng: -84.3068644,
    squareFootage: "109985",
    propertyType: "Retail",
    totalCosts: "11618441",
    valueOfReclassifiedAssets: 2947644,
    depreciableAssets: 28,
  },
  {
    lat: 40.6216016,
    lng: -74.22967129999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 1737108,
    depreciableAssets: 13,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 3639561,
    depreciableAssets: 20,
  },
  {
    lat: 40.7680056,
    lng: -74.2326058,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 4618940,
    depreciableAssets: 15,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 5595535,
    depreciableAssets: 32,
  },
  {
    lat: 40.7956149,
    lng: -74.16509909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1650040",
    valueOfReclassifiedAssets: 1477305,
    depreciableAssets: 37,
  },
  {
    lat: 40.4223122,
    lng: -74.95594799999999,
    squareFootage: "7022",
    propertyType: "Office",
    totalCosts: "775000",
    valueOfReclassifiedAssets: 153998,
    depreciableAssets: 23,
  },
  {
    lat: 40.4223122,
    lng: -74.95594799999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "167588",
    valueOfReclassifiedAssets: 72171,
    depreciableAssets: 0,
  },
  {
    lat: 42.9024331,
    lng: -76.5719414,
    squareFootage: "65331",
    propertyType: "Retail",
    totalCosts: "8695928",
    valueOfReclassifiedAssets: 3296390,
    depreciableAssets: 42,
  },
  {
    lat: 28.5513952,
    lng: -81.30142839999999,
    squareFootage: "165658",
    propertyType: "Multifamily",
    totalCosts: "12137626",
    valueOfReclassifiedAssets: 9405228,
    depreciableAssets: 30,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "27550",
    propertyType: "Multifamily",
    totalCosts: "1450000",
    valueOfReclassifiedAssets: 179521,
    depreciableAssets: 14,
  },
  {
    lat: 40.7758541,
    lng: -74.16509909999999,
    squareFootage: "17068",
    propertyType: "Multifamily",
    totalCosts: "1162500",
    valueOfReclassifiedAssets: 102445,
    depreciableAssets: 10,
  },
  {
    lat: 40.7132136,
    lng: -74.21206289999999,
    squareFootage: "29200",
    propertyType: "Multifamily",
    totalCosts: "951750",
    valueOfReclassifiedAssets: 845599,
    depreciableAssets: 12,
  },
  {
    lat: 41.7034769,
    lng: -71.46555649999999,
    squareFootage: "80000",
    propertyType: "Hotel",
    totalCosts: "14276748",
    valueOfReclassifiedAssets: 2827337,
    depreciableAssets: 23,
  },
  {
    lat: 32.8916766,
    lng: -96.9477532,
    squareFootage: "98970",
    propertyType: "Hotel",
    totalCosts: "36621147",
    valueOfReclassifiedAssets: 9108817,
    depreciableAssets: 30,
  },
  {
    lat: 33.0787152,
    lng: -96.8083063,
    squareFootage: "85925",
    propertyType: "Hotel",
    totalCosts: "30508656",
    valueOfReclassifiedAssets: 7936121,
    depreciableAssets: 30,
  },
  {
    lat: 40.43982159999999,
    lng: -104.6821048,
    squareFootage: "32076",
    propertyType: "Multifamily",
    totalCosts: "2475000",
    valueOfReclassifiedAssets: 685140,
    depreciableAssets: 33,
  },
  {
    lat: 40.1733277,
    lng: -105.1142011,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 649828,
    depreciableAssets: 26,
  },
  {
    lat: 40.1733277,
    lng: -105.1142011,
    squareFootage: "25744",
    propertyType: "Multifamily",
    totalCosts: "2381358",
    valueOfReclassifiedAssets: 753740,
    depreciableAssets: 40,
  },
  {
    lat: 43.0165459,
    lng: -88.29476749999999,
    squareFootage: "152000",
    propertyType: "Office",
    totalCosts: "65000000",
    valueOfReclassifiedAssets: 13815575,
    depreciableAssets: 22,
  },
  {
    lat: 40.0097883,
    lng: -83.0683519,
    squareFootage: "298657",
    propertyType: "Multifamily",
    totalCosts: "32800000",
    valueOfReclassifiedAssets: 6219906,
    depreciableAssets: 23,
  },
  {
    lat: 40.1296424,
    lng: -83.025396,
    squareFootage: "120084",
    propertyType: "Multifamily",
    totalCosts: "14300000",
    valueOfReclassifiedAssets: 2742084,
    depreciableAssets: 23,
  },
  {
    lat: 39.898703,
    lng: -82.7562895,
    squareFootage: "337818",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 3493903,
    depreciableAssets: 19,
  },
  {
    lat: 43.6153549,
    lng: -72.92243289999999,
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "5825000",
    valueOfReclassifiedAssets: 1548346,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "418062",
    propertyType: "Multifamily",
    totalCosts: "34500000",
    valueOfReclassifiedAssets: 7872718,
    depreciableAssets: 27,
  },
  {
    lat: 39.0397435,
    lng: -95.67998159999999,
    squareFootage: "161874",
    propertyType: "Multifamily",
    totalCosts: "11600000",
    valueOfReclassifiedAssets: 2984169,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "418062",
    propertyType: "Multifamily",
    totalCosts: "28310199",
    valueOfReclassifiedAssets: 6323418,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "418062",
    propertyType: "Multifamily",
    totalCosts: "31600000",
    valueOfReclassifiedAssets: 7210953,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "418062",
    propertyType: "Multifamily",
    totalCosts: "31707808",
    valueOfReclassifiedAssets: 7235554,
    depreciableAssets: 27,
  },
  {
    lat: 37.3084425,
    lng: -95.26844369999999,
    squareFootage: "62740",
    propertyType: "Multifamily",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 544757,
    depreciableAssets: 22,
  },
  {
    lat: 39.07935579999999,
    lng: -86.438974,
    squareFootage: "115080",
    propertyType: "Multifamily",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 4543130,
    depreciableAssets: 36,
  },
  {
    lat: 39.2183952,
    lng: -86.5703554,
    squareFootage: "195429",
    propertyType: "Multifamily",
    totalCosts: "16250000",
    valueOfReclassifiedAssets: 4625518,
    depreciableAssets: 34,
  },
  {
    lat: 37.5793536,
    lng: -122.3164207,
    squareFootage: "8586",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1117613,
    depreciableAssets: 29,
  },
  {
    lat: 37.4634447,
    lng: -122.2256803,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "197649",
    valueOfReclassifiedAssets: 109400,
    depreciableAssets: 0,
  },
  {
    lat: 37.4634447,
    lng: -122.2256803,
    squareFootage: "15624",
    propertyType: "Multifamily",
    totalCosts: "4725000",
    valueOfReclassifiedAssets: 866350,
    depreciableAssets: 32,
  },
  {
    lat: 37.5793536,
    lng: -122.3164207,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "205680",
    valueOfReclassifiedAssets: 61804,
    depreciableAssets: 0,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "13280",
    propertyType: "Multifamily",
    totalCosts: "6704481",
    valueOfReclassifiedAssets: 755036,
    depreciableAssets: 13,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "55590",
    propertyType: "Multifamily",
    totalCosts: "16715055",
    valueOfReclassifiedAssets: 1122980,
    depreciableAssets: 8,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "6800",
    propertyType: "Multifamily",
    totalCosts: "2461886",
    valueOfReclassifiedAssets: 329890,
    depreciableAssets: 16,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7450",
    propertyType: "Multifamily",
    totalCosts: "3262678",
    valueOfReclassifiedAssets: 278601,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "8760",
    propertyType: "Specialty",
    totalCosts: "3745427",
    valueOfReclassifiedAssets: 318907,
    depreciableAssets: 0,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "44622",
    propertyType: "Multifamily",
    totalCosts: "15619691",
    valueOfReclassifiedAssets: 1673113,
    depreciableAssets: 13,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "6100",
    propertyType: "Multifamily",
    totalCosts: "2779879",
    valueOfReclassifiedAssets: 289850,
    depreciableAssets: 12,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "42186",
    propertyType: "Multifamily",
    totalCosts: "28101173",
    valueOfReclassifiedAssets: 2732597,
    depreciableAssets: 0,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "34514",
    propertyType: "Multifamily",
    totalCosts: "16682065",
    valueOfReclassifiedAssets: 1433691,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7475",
    propertyType: "Multifamily",
    totalCosts: "2512583",
    valueOfReclassifiedAssets: 213865,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "14268",
    propertyType: "Multifamily",
    totalCosts: "7515976",
    valueOfReclassifiedAssets: 878351,
    depreciableAssets: 14,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "24312",
    propertyType: "Multifamily",
    totalCosts: "13337156",
    valueOfReclassifiedAssets: 834870,
    depreciableAssets: 7,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "27810",
    propertyType: "Multifamily",
    totalCosts: "8166618",
    valueOfReclassifiedAssets: 956311,
    depreciableAssets: 13,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9105",
    propertyType: "Multifamily",
    totalCosts: "3814844",
    valueOfReclassifiedAssets: 465817,
    depreciableAssets: 14,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7475",
    propertyType: "Multifamily",
    totalCosts: "2726586",
    valueOfReclassifiedAssets: 232080,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "31818",
    propertyType: "Multifamily",
    totalCosts: "9410205",
    valueOfReclassifiedAssets: 786918,
    depreciableAssets: 9,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "14268",
    propertyType: "Multifamily",
    totalCosts: "3572426",
    valueOfReclassifiedAssets: 435252,
    depreciableAssets: 15,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "22584",
    propertyType: "Multifamily",
    totalCosts: "6584672",
    valueOfReclassifiedAssets: 617094,
    depreciableAssets: 11,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "47730",
    propertyType: "Multifamily",
    totalCosts: "18320003",
    valueOfReclassifiedAssets: 1238187,
    depreciableAssets: 0,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "21072",
    propertyType: "Multifamily",
    totalCosts: "7903019",
    valueOfReclassifiedAssets: 1638422,
    depreciableAssets: 24,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9960",
    propertyType: "Multifamily",
    totalCosts: "3659513",
    valueOfReclassifiedAssets: 394835,
    depreciableAssets: 13,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7500",
    propertyType: "Multifamily",
    totalCosts: "3943737",
    valueOfReclassifiedAssets: 787485,
    depreciableAssets: 24,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8825",
    propertyType: "Multifamily",
    totalCosts: "4352438",
    valueOfReclassifiedAssets: 594700,
    depreciableAssets: 16,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "35676",
    propertyType: "Multifamily",
    totalCosts: "11700859",
    valueOfReclassifiedAssets: 1084900,
    depreciableAssets: 11,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "22584",
    propertyType: "Multifamily",
    totalCosts: "7121255",
    valueOfReclassifiedAssets: 680835,
    depreciableAssets: 11,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "20970",
    propertyType: "Multifamily",
    totalCosts: "7444521",
    valueOfReclassifiedAssets: 599756,
    depreciableAssets: 10,
  },
  {
    lat: 40.09944249999999,
    lng: -74.9325683,
    squareFootage: "323677",
    propertyType: "Multifamily",
    totalCosts: "27288539",
    valueOfReclassifiedAssets: 4792140,
    depreciableAssets: 21,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "10745",
    propertyType: "Multifamily",
    totalCosts: "4346943",
    valueOfReclassifiedAssets: 365614,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9962",
    propertyType: "Multifamily",
    totalCosts: "7366600",
    valueOfReclassifiedAssets: 1574934,
    depreciableAssets: 25,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "44160",
    propertyType: "Multifamily",
    totalCosts: "15487906",
    valueOfReclassifiedAssets: 1072043,
    depreciableAssets: 9,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7500",
    propertyType: "Multifamily",
    totalCosts: "4658963",
    valueOfReclassifiedAssets: 838534,
    depreciableAssets: 21,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7500",
    propertyType: "Multifamily",
    totalCosts: "3432863",
    valueOfReclassifiedAssets: 611368,
    depreciableAssets: 21,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "17130",
    propertyType: "Multifamily",
    totalCosts: "4280960",
    valueOfReclassifiedAssets: 407776,
    depreciableAssets: 12,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "20500",
    propertyType: "Multifamily",
    totalCosts: "6836314",
    valueOfReclassifiedAssets: 524718,
    depreciableAssets: 9,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "31026",
    propertyType: "Multifamily",
    totalCosts: "18711425",
    valueOfReclassifiedAssets: 2570782,
    depreciableAssets: 16,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9440",
    propertyType: "Multifamily",
    totalCosts: "3986277",
    valueOfReclassifiedAssets: 382425,
    depreciableAssets: 11,
  },
  {
    lat: 33.7241323,
    lng: -118.2643567,
    squareFootage: "134640",
    propertyType: "Multifamily",
    totalCosts: "21779668",
    valueOfReclassifiedAssets: 4246107,
    depreciableAssets: 23,
  },
  {
    lat: 41.3576886,
    lng: -77.5160788,
    squareFootage: "74140",
    propertyType: "Multifamily",
    totalCosts: "3636801",
    valueOfReclassifiedAssets: 3123529,
    depreciableAssets: 18,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "9824",
    propertyType: "Multifamily",
    totalCosts: "5374188",
    valueOfReclassifiedAssets: 965985,
    depreciableAssets: 21,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "11390",
    propertyType: "Multifamily",
    totalCosts: "4656392",
    valueOfReclassifiedAssets: 501395,
    depreciableAssets: 13,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "21840",
    propertyType: "Multifamily",
    totalCosts: "11319849",
    valueOfReclassifiedAssets: 1495695,
    depreciableAssets: 16,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "9700",
    propertyType: "Multifamily",
    totalCosts: "3813077",
    valueOfReclassifiedAssets: 589608,
    depreciableAssets: 18,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "20970",
    propertyType: "Multifamily",
    totalCosts: "5676074",
    valueOfReclassifiedAssets: 456284,
    depreciableAssets: 10,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "15120",
    propertyType: "Multifamily",
    totalCosts: "5885063",
    valueOfReclassifiedAssets: 1223194,
    depreciableAssets: 24,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "4173077",
    valueOfReclassifiedAssets: 561267,
    depreciableAssets: 15,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "9700",
    propertyType: "Multifamily",
    totalCosts: "3948077",
    valueOfReclassifiedAssets: 582723,
    depreciableAssets: 17,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "22780",
    propertyType: "Multifamily",
    totalCosts: "10442899",
    valueOfReclassifiedAssets: 1017032,
    depreciableAssets: 11,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "12805",
    propertyType: "Multifamily",
    totalCosts: "6469882",
    valueOfReclassifiedAssets: 903543,
    depreciableAssets: 17,
  },
  {
    lat: 40.12707229999999,
    lng: -75.3353875,
    squareFootage: "150211",
    propertyType: "Multifamily",
    totalCosts: "12375105",
    valueOfReclassifiedAssets: 1173225,
    depreciableAssets: 11,
  },
  {
    lat: 38.6272793,
    lng: -121.3198491,
    squareFootage: "76550",
    propertyType: "Multifamily",
    totalCosts: "9078220",
    valueOfReclassifiedAssets: 2184220,
    depreciableAssets: 28,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "7540",
    propertyType: "Multifamily",
    totalCosts: "5246469",
    valueOfReclassifiedAssets: 1108752,
    depreciableAssets: 25,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "3393077",
    valueOfReclassifiedAssets: 463583,
    depreciableAssets: 16,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "9700",
    propertyType: "Multifamily",
    totalCosts: "3573077",
    valueOfReclassifiedAssets: 424899,
    depreciableAssets: 14,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "3521577",
    valueOfReclassifiedAssets: 474270,
    depreciableAssets: 15,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "9496",
    propertyType: "Multifamily",
    totalCosts: "3662756",
    valueOfReclassifiedAssets: 446100,
    depreciableAssets: 14,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "20412",
    propertyType: "Multifamily",
    totalCosts: "7522137",
    valueOfReclassifiedAssets: 766340,
    depreciableAssets: 13,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8040",
    propertyType: "Multifamily",
    totalCosts: "3882323",
    valueOfReclassifiedAssets: 742886,
    depreciableAssets: 22,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8280",
    propertyType: "Multifamily",
    totalCosts: "2153077",
    valueOfReclassifiedAssets: 284775,
    depreciableAssets: 16,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "12215",
    propertyType: "Multifamily",
    totalCosts: "5885063",
    valueOfReclassifiedAssets: 923837,
    depreciableAssets: 19,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8040",
    propertyType: "Multifamily",
    totalCosts: "2776577",
    valueOfReclassifiedAssets: 380003,
    depreciableAssets: 16,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8700",
    propertyType: "Multifamily",
    totalCosts: "2499070",
    valueOfReclassifiedAssets: 317393,
    depreciableAssets: 14,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "11955",
    propertyType: "Multifamily",
    totalCosts: "6978702",
    valueOfReclassifiedAssets: 733351,
    depreciableAssets: 12,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "8280",
    propertyType: "Multifamily",
    totalCosts: "3148297",
    valueOfReclassifiedAssets: 417277,
    depreciableAssets: 16,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "4173077",
    valueOfReclassifiedAssets: 561267,
    depreciableAssets: 15,
  },
  {
    lat: 26.2422984,
    lng: -81.7677645,
    squareFootage: "50010",
    propertyType: "Retail",
    totalCosts: "10655738",
    valueOfReclassifiedAssets: 4461315,
    depreciableAssets: 49,
  },
  {
    lat: 40.7245083,
    lng: -73.4298073,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1529427",
    valueOfReclassifiedAssets: 1057008,
    depreciableAssets: 0,
  },
  {
    lat: 38.9797389,
    lng: -77.004626,
    squareFootage: "261151",
    propertyType: "Multifamily",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 3141148,
    depreciableAssets: 19,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "21148",
    propertyType: "Multifamily",
    totalCosts: "6540000",
    valueOfReclassifiedAssets: 784254,
    depreciableAssets: 14,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "12393",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 238506,
    depreciableAssets: 11,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "4350",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 433604,
    depreciableAssets: 16,
  },
  {
    lat: 37.5021585,
    lng: -122.2086579,
    squareFootage: "14000",
    propertyType: "Office",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 628715,
    depreciableAssets: 17,
  },
  {
    lat: 40.67103789999999,
    lng: -73.706594,
    squareFootage: "11860",
    propertyType: "Office",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 224607,
    depreciableAssets: 19,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "89464",
    propertyType: "Office",
    totalCosts: "8978367",
    valueOfReclassifiedAssets: 1533277,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6350",
    propertyType: "Specialty",
    totalCosts: "3595000",
    valueOfReclassifiedAssets: 1205388,
    depreciableAssets: 39,
  },
  {
    lat: 39.2396095,
    lng: -76.68169619999999,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "2730484",
    valueOfReclassifiedAssets: 525491,
    depreciableAssets: 22,
  },
  {
    lat: 39.9721149,
    lng: -76.6528225,
    squareFootage: "10722",
    propertyType: "Retail",
    totalCosts: "3723578",
    valueOfReclassifiedAssets: 882928,
    depreciableAssets: 28,
  },
  {
    lat: 40.2713328,
    lng: -76.6297189,
    squareFootage: "2015",
    propertyType: "Retail",
    totalCosts: "3625720",
    valueOfReclassifiedAssets: 1181838,
    depreciableAssets: 39,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "11625",
    propertyType: "Specialty",
    totalCosts: "2993268",
    valueOfReclassifiedAssets: 567686,
    depreciableAssets: 22,
  },
  {
    lat: 40.3509675,
    lng: -79.9136731,
    squareFootage: "157916",
    propertyType: "Retail",
    totalCosts: "11782641",
    valueOfReclassifiedAssets: 2247925,
    depreciableAssets: 22,
  },
  {
    lat: 40.3208847,
    lng: -76.8836261,
    squareFootage: "116264",
    propertyType: "Retail",
    totalCosts: "48050600",
    valueOfReclassifiedAssets: 13833647,
    depreciableAssets: 34,
  },
  {
    lat: 40.37846,
    lng: -76.4331698,
    squareFootage: "94426",
    propertyType: "Retail",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 1708462,
    depreciableAssets: 17,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "43710",
    propertyType: "Office",
    totalCosts: "6217877",
    valueOfReclassifiedAssets: 1084255,
    depreciableAssets: 20,
  },
  {
    lat: 41.97181399999999,
    lng: -71.3468091,
    squareFootage: "105444",
    propertyType: "Retail",
    totalCosts: "26250000",
    valueOfReclassifiedAssets: 3729767,
    depreciableAssets: 17,
  },
  {
    lat: 39.9721149,
    lng: -76.6528225,
    squareFootage: "177280",
    propertyType: "Retail",
    totalCosts: "16235283",
    valueOfReclassifiedAssets: 3180527,
    depreciableAssets: 23,
  },
  {
    lat: 40.2631879,
    lng: -77.17152279999999,
    squareFootage: "27441",
    propertyType: "Retail",
    totalCosts: "3670464",
    valueOfReclassifiedAssets: 833058,
    depreciableAssets: 27,
  },
  {
    lat: 42.2254318,
    lng: -71.5486562,
    squareFootage: "31473",
    propertyType: "Retail",
    totalCosts: "5881481",
    valueOfReclassifiedAssets: 2058520,
    depreciableAssets: 42,
  },
  {
    lat: 39.919617,
    lng: -76.7221082,
    squareFootage: "355640",
    propertyType: "Retail",
    totalCosts: "48465162",
    valueOfReclassifiedAssets: 10651948,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "32799",
    propertyType: "Retail",
    totalCosts: "5524683",
    valueOfReclassifiedAssets: 1487792,
    depreciableAssets: 32,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "30190",
    propertyType: "Office",
    totalCosts: "5652736",
    valueOfReclassifiedAssets: 4332184,
    depreciableAssets: 33,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "7500",
    propertyType: "Retail",
    totalCosts: "2142178",
    valueOfReclassifiedAssets: 721852,
    depreciableAssets: 39,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "12976",
    propertyType: "Office",
    totalCosts: "3399455",
    valueOfReclassifiedAssets: 741816,
    depreciableAssets: 25,
  },
  {
    lat: 39.9591553,
    lng: -76.736538,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "3565705",
    valueOfReclassifiedAssets: 580203,
    depreciableAssets: 19,
  },
  {
    lat: 36.2726958,
    lng: -95.8353837,
    squareFootage: "6350",
    propertyType: "Specialty",
    totalCosts: "3595000",
    valueOfReclassifiedAssets: 2930653,
    depreciableAssets: 39,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "55607",
    propertyType: "Retail",
    totalCosts: "17738116",
    valueOfReclassifiedAssets: 7020870,
    depreciableAssets: 47,
  },
  {
    lat: 40.005718,
    lng: -76.76827750000001,
    squareFootage: "35549",
    propertyType: "Retail",
    totalCosts: "8087775",
    valueOfReclassifiedAssets: 1255381,
    depreciableAssets: 18,
  },
  {
    lat: 40.287932,
    lng: -75.9347113,
    squareFootage: "96316",
    propertyType: "Retail",
    totalCosts: "20867008",
    valueOfReclassifiedAssets: 4460384,
    depreciableAssets: 25,
  },
  {
    lat: 40.1170277,
    lng: -76.514138,
    squareFootage: "43565",
    propertyType: "Retail",
    totalCosts: "21510576",
    valueOfReclassifiedAssets: 8883514,
    depreciableAssets: 49,
  },
  {
    lat: 44.8504122,
    lng: -68.86186169999999,
    squareFootage: "127440",
    propertyType: "Retail",
    totalCosts: "14890095",
    valueOfReclassifiedAssets: 13547983,
    depreciableAssets: 28,
  },
  {
    lat: 40.0033209,
    lng: -76.23632479999999,
    squareFootage: "29680",
    propertyType: "Retail",
    totalCosts: "4634591",
    valueOfReclassifiedAssets: 1287404,
    depreciableAssets: 33,
  },
  {
    lat: 39.7526223,
    lng: -76.9412601,
    squareFootage: "145255",
    propertyType: "Retail",
    totalCosts: "29862428",
    valueOfReclassifiedAssets: 7884822,
    depreciableAssets: 31,
  },
  {
    lat: 39.013101,
    lng: -95.85121,
    squareFootage: "4200",
    propertyType: "Retail",
    totalCosts: "931469",
    valueOfReclassifiedAssets: 791748,
    depreciableAssets: 100,
  },
  {
    lat: 40.782392,
    lng: -74.0711359,
    squareFootage: "25108",
    propertyType: "Retail",
    totalCosts: "7350000",
    valueOfReclassifiedAssets: 1237580,
    depreciableAssets: 20,
  },
  {
    lat: 39.2724771,
    lng: -76.745195,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "5736252",
    valueOfReclassifiedAssets: 923403,
    depreciableAssets: 19,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "13729",
    propertyType: "Office",
    totalCosts: "3472270",
    valueOfReclassifiedAssets: 741956,
    depreciableAssets: 25,
  },
  {
    lat: 38.0468188,
    lng: -84.4946871,
    squareFootage: "10125",
    propertyType: "Retail",
    totalCosts: "3872917",
    valueOfReclassifiedAssets: 988422,
    depreciableAssets: 30,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "53332",
    propertyType: "Specialty",
    totalCosts: "8165669",
    valueOfReclassifiedAssets: 1920109,
    depreciableAssets: 28,
  },
  {
    lat: 39.8859416,
    lng: -76.606611,
    squareFootage: "151132",
    propertyType: "Retail",
    totalCosts: "31276544",
    valueOfReclassifiedAssets: 8275505,
    depreciableAssets: 31,
  },
  {
    lat: 42.0749668,
    lng: -72.03368569999999,
    squareFootage: "140818",
    propertyType: "Retail",
    totalCosts: "10375000",
    valueOfReclassifiedAssets: 1307897,
    depreciableAssets: 15,
  },
  {
    lat: 40.0144597,
    lng: -76.3289941,
    squareFootage: "11430",
    propertyType: "Office",
    totalCosts: "3395729",
    valueOfReclassifiedAssets: 846826,
    depreciableAssets: 30,
  },
  {
    lat: 40.407977,
    lng: -75.8940592,
    squareFootage: "123754",
    propertyType: "Retail",
    totalCosts: "23531469",
    valueOfReclassifiedAssets: 5460781,
    depreciableAssets: 28,
  },
  {
    lat: 40.0720931,
    lng: -76.30583279999999,
    squareFootage: "30500",
    propertyType: "Office",
    totalCosts: "7333968",
    valueOfReclassifiedAssets: 1134046,
    depreciableAssets: 18,
  },
  {
    lat: 38.8172392,
    lng: -75.5975296,
    squareFootage: "9900",
    propertyType: "Retail",
    totalCosts: "1216262",
    valueOfReclassifiedAssets: 345134,
    depreciableAssets: 29,
  },
  {
    lat: 40.88306850000001,
    lng: -75.36453689999999,
    squareFootage: "9026",
    propertyType: "Retail",
    totalCosts: "1495000",
    valueOfReclassifiedAssets: 444047,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "19378",
    propertyType: "Specialty",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 904094,
    depreciableAssets: 35,
  },
  {
    lat: 39.8008256,
    lng: -74.9150313,
    squareFootage: "28000",
    propertyType: "Retail",
    totalCosts: "1462500",
    valueOfReclassifiedAssets: 290046,
    depreciableAssets: 21,
  },
  {
    lat: 40.728439,
    lng: -75.1662121,
    squareFootage: "10000",
    propertyType: "Specialty",
    totalCosts: "1687500",
    valueOfReclassifiedAssets: 425743,
    depreciableAssets: 27,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11775198",
    valueOfReclassifiedAssets: 10248565,
    depreciableAssets: 14,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "51440",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 5515503,
    depreciableAssets: 29,
  },
  {
    lat: 40.6986257,
    lng: -73.6241855,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "33542783",
    valueOfReclassifiedAssets: 25683163,
    depreciableAssets: 26,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "51440",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 5209086,
    depreciableAssets: 29,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "9840",
    propertyType: "Multifamily",
    totalCosts: "2582366",
    valueOfReclassifiedAssets: 2210367,
    depreciableAssets: 14,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "10153",
    propertyType: "Multifamily",
    totalCosts: "2582366",
    valueOfReclassifiedAssets: 2221501,
    depreciableAssets: 0,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10393403",
    valueOfReclassifiedAssets: 9045917,
    depreciableAssets: 14,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "18940",
    propertyType: "Multifamily",
    totalCosts: "6460000",
    valueOfReclassifiedAssets: 5940474,
    depreciableAssets: 8,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "30080",
    propertyType: "Office",
    totalCosts: "4965354",
    valueOfReclassifiedAssets: 4580541,
    depreciableAssets: 9,
  },
  {
    lat: 40.5952146,
    lng: -74.1827119,
    squareFootage: "2018",
    propertyType: "Hotel",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1782956,
    depreciableAssets: 25,
  },
  {
    lat: 40.5952146,
    lng: -74.1827119,
    squareFootage: "35000",
    propertyType: "Hotel",
    totalCosts: "7937394",
    valueOfReclassifiedAssets: 1875525,
    depreciableAssets: 27,
  },
  {
    lat: 27.2823268,
    lng: -82.4698603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19024250",
    valueOfReclassifiedAssets: 15314720,
    depreciableAssets: 25,
  },
  {
    lat: 30.5327462,
    lng: -87.2120599,
    squareFootage: "154267",
    propertyType: "Multifamily",
    totalCosts: "10227606",
    valueOfReclassifiedAssets: 2454144,
    depreciableAssets: 29,
  },
  {
    lat: 39.0145932,
    lng: -76.68169619999999,
    squareFootage: "7284",
    propertyType: "Specialty",
    totalCosts: "1675000",
    valueOfReclassifiedAssets: 523954,
    depreciableAssets: 37,
  },
  {
    lat: 38.9374808,
    lng: -77.0852258,
    squareFootage: "40100",
    propertyType: "Office",
    totalCosts: "15775000",
    valueOfReclassifiedAssets: 2332752,
    depreciableAssets: 17,
  },
  {
    lat: 38.9374808,
    lng: -77.0852258,
    squareFootage: "38396",
    propertyType: "Office",
    totalCosts: "14475000",
    valueOfReclassifiedAssets: 2271503,
    depreciableAssets: 18,
  },
  {
    lat: 37.4664427,
    lng: -77.46283249999999,
    squareFootage: "210700",
    propertyType: "Multifamily",
    totalCosts: "8554145",
    valueOfReclassifiedAssets: 6674957,
    depreciableAssets: 28,
  },
  {
    lat: 38.9080786,
    lng: -77.04061469999999,
    squareFootage: "39299",
    propertyType: "Office",
    totalCosts: "14750000",
    valueOfReclassifiedAssets: 2926712,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9980894",
    valueOfReclassifiedAssets: 9343270,
    depreciableAssets: 34,
  },
  {
    lat: 28.6092911,
    lng: -81.30142839999999,
    squareFootage: "129291",
    propertyType: "Multifamily",
    totalCosts: "10249213",
    valueOfReclassifiedAssets: 3099578,
    depreciableAssets: 37,
  },
  {
    lat: 38.9627306,
    lng: -76.854799,
    squareFootage: "120000",
    propertyType: "Office",
    totalCosts: "6392024",
    valueOfReclassifiedAssets: 5219871,
    depreciableAssets: 27,
  },
  {
    lat: 30.2205397,
    lng: -81.5537192,
    squareFootage: "206358",
    propertyType: "Multifamily",
    totalCosts: "9659465",
    valueOfReclassifiedAssets: 2469327,
    depreciableAssets: 34,
  },
  {
    lat: 38.9658112,
    lng: -77.4816693,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5155140",
    valueOfReclassifiedAssets: 4979589,
    depreciableAssets: 30,
  },
  {
    lat: 38.9191485,
    lng: -77.0362967,
    squareFootage: "8877",
    propertyType: "Multifamily",
    totalCosts: "2091203",
    valueOfReclassifiedAssets: 280675,
    depreciableAssets: 19,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "43578",
    propertyType: "Multifamily",
    totalCosts: "2772657",
    valueOfReclassifiedAssets: 643093,
    depreciableAssets: 26,
  },
  {
    lat: 38.8831372,
    lng: -76.9902276,
    squareFootage: "202241",
    propertyType: "Multifamily",
    totalCosts: "57000000",
    valueOfReclassifiedAssets: 47457320,
    depreciableAssets: 17,
  },
  {
    lat: 39.5038205,
    lng: -84.3939224,
    squareFootage: "13521",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4296679",
    valueOfReclassifiedAssets: 1413330,
    depreciableAssets: 43,
  },
  {
    lat: 38.9339046,
    lng: -77.03053899999999,
    squareFootage: "35755",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 789278,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "540834",
    valueOfReclassifiedAssets: 463338,
    depreciableAssets: 28,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "28320",
    propertyType: "Multifamily",
    totalCosts: "1752007",
    valueOfReclassifiedAssets: 428326,
    depreciableAssets: 29,
  },
  {
    lat: 38.7492726,
    lng: -77.57340479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7911319",
    valueOfReclassifiedAssets: 6870492,
    depreciableAssets: 28,
  },
  {
    lat: 37.2062002,
    lng: -76.5777203,
    squareFootage: "109120",
    propertyType: "Multifamily",
    totalCosts: "5494953",
    valueOfReclassifiedAssets: 1470459,
    depreciableAssets: 32,
  },
  {
    lat: 37.4664427,
    lng: -77.46283249999999,
    squareFootage: "210700",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 3125014,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "572878",
    valueOfReclassifiedAssets: 416669,
    depreciableAssets: 34,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "36600",
    propertyType: "Multifamily",
    totalCosts: "1890911",
    valueOfReclassifiedAssets: 438638,
    depreciableAssets: 30,
  },
  {
    lat: 37.0672839,
    lng: -76.514138,
    squareFootage: "28320",
    propertyType: "Multifamily",
    totalCosts: "1752007",
    valueOfReclassifiedAssets: 428326,
    depreciableAssets: 29,
  },
  {
    lat: 39.167568,
    lng: -76.5892774,
    squareFootage: "117300",
    propertyType: "Multifamily",
    totalCosts: "12340000",
    valueOfReclassifiedAssets: 2951034,
    depreciableAssets: 28,
  },
  {
    lat: 39.186097,
    lng: -77.20027449999999,
    squareFootage: "154434",
    propertyType: "Multifamily",
    totalCosts: "32717407",
    valueOfReclassifiedAssets: 6284519,
    depreciableAssets: 29,
  },
  {
    lat: 39.9493801,
    lng: -75.145782,
    squareFootage: "257000",
    propertyType: "Hotel",
    totalCosts: "95500000",
    valueOfReclassifiedAssets: 22669055,
    depreciableAssets: 28,
  },
  {
    lat: 39.10436869999999,
    lng: -76.8836261,
    squareFootage: "202904",
    propertyType: "Multifamily",
    totalCosts: "27000000",
    valueOfReclassifiedAssets: 5179007,
    depreciableAssets: 22,
  },
  {
    lat: 38.9052763,
    lng: -76.98158769999999,
    squareFootage: "47212",
    propertyType: "Multifamily",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 1177739,
    depreciableAssets: 19,
  },
  {
    lat: 38.8682133,
    lng: -76.79135579999999,
    squareFootage: "76639",
    propertyType: "Specialty",
    totalCosts: "16500000",
    valueOfReclassifiedAssets: 3745770,
    depreciableAssets: 26,
  },
  {
    lat: 38.9732233,
    lng: -76.94702199999999,
    squareFootage: "30089",
    propertyType: "Retail",
    totalCosts: "12300000",
    valueOfReclassifiedAssets: 2714460,
    depreciableAssets: 36,
  },
  {
    lat: 45.0196219,
    lng: -93.2355522,
    squareFootage: "45900",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 929759,
    depreciableAssets: 29,
  },
  {
    lat: 45.0167268,
    lng: -93.3364004,
    squareFootage: "75777",
    propertyType: "Multifamily",
    totalCosts: "4440000",
    valueOfReclassifiedAssets: 1016321,
    depreciableAssets: 27,
  },
  {
    lat: 45.05278269999999,
    lng: -93.3811952,
    squareFootage: "39465",
    propertyType: "Multifamily",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 714491,
    depreciableAssets: 23,
  },
  {
    lat: 44.9095374,
    lng: -93.1679257,
    squareFootage: "15684",
    propertyType: "Multifamily",
    totalCosts: "1168000",
    valueOfReclassifiedAssets: 951440,
    depreciableAssets: 20,
  },
  {
    lat: 39.9583587,
    lng: -75.1953934,
    squareFootage: "30000",
    propertyType: "Multifamily",
    totalCosts: "5300000",
    valueOfReclassifiedAssets: 626744,
    depreciableAssets: 14,
  },
  {
    lat: 39.9412882,
    lng: -75.21873459999999,
    squareFootage: "180075",
    propertyType: "Multifamily",
    totalCosts: "30434000",
    valueOfReclassifiedAssets: 2996499,
    depreciableAssets: 12,
  },
  {
    lat: 39.9583587,
    lng: -75.1953934,
    squareFootage: "132048",
    propertyType: "Multifamily",
    totalCosts: "40500000",
    valueOfReclassifiedAssets: 5239315,
    depreciableAssets: 15,
  },
  {
    lat: 40.0358382,
    lng: -75.174734,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29955819",
    valueOfReclassifiedAssets: 22819806,
    depreciableAssets: 31,
  },
  {
    lat: 40.8053731,
    lng: -74.0241367,
    squareFootage: "674990",
    propertyType: "Multifamily",
    totalCosts: "153349505",
    valueOfReclassifiedAssets: 20198788,
    depreciableAssets: 15,
  },
  {
    lat: 39.9915379,
    lng: -75.21873459999999,
    squareFootage: "729665",
    propertyType: "Multifamily",
    totalCosts: "52892617",
    valueOfReclassifiedAssets: 44055443,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2862585",
    valueOfReclassifiedAssets: 2243189,
    depreciableAssets: 25,
  },
  {
    lat: 39.9556241,
    lng: -75.1647529,
    squareFootage: "320705",
    propertyType: "Office",
    totalCosts: "24679836",
    valueOfReclassifiedAssets: 23112016,
    depreciableAssets: 0,
  },
  {
    lat: 40.0358382,
    lng: -75.174734,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24329104",
    valueOfReclassifiedAssets: 18533475,
    depreciableAssets: 31,
  },
  {
    lat: 41.7805099,
    lng: -87.603826,
    squareFootage: "24996",
    propertyType: "Multifamily",
    totalCosts: "3348000",
    valueOfReclassifiedAssets: 653071,
    depreciableAssets: 23,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "14235",
    propertyType: "Multifamily",
    totalCosts: "1190000",
    valueOfReclassifiedAssets: 160633,
    depreciableAssets: 16,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "16978",
    propertyType: "Multifamily",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 327077,
    depreciableAssets: 18,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "41856",
    propertyType: "Multifamily",
    totalCosts: "4468000",
    valueOfReclassifiedAssets: 844176,
    depreciableAssets: 22,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "16543",
    propertyType: "Multifamily",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 1356766,
    depreciableAssets: 25,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "16543",
    propertyType: "Multifamily",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 1368903,
    depreciableAssets: 25,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "13539",
    propertyType: "Multifamily",
    totalCosts: "1199000",
    valueOfReclassifiedAssets: 170241,
    depreciableAssets: 16,
  },
  {
    lat: 41.9711068,
    lng: -87.70248169999999,
    squareFootage: "26292",
    propertyType: "Multifamily",
    totalCosts: "4215000",
    valueOfReclassifiedAssets: 712673,
    depreciableAssets: 20,
  },
  {
    lat: 41.9711068,
    lng: -87.70248169999999,
    squareFootage: "14064",
    propertyType: "Multifamily",
    totalCosts: "2651000",
    valueOfReclassifiedAssets: 434802,
    depreciableAssets: 19,
  },
  {
    lat: 41.7805099,
    lng: -87.603826,
    squareFootage: "72940",
    propertyType: "Multifamily",
    totalCosts: "7337000",
    valueOfReclassifiedAssets: 1666668,
    depreciableAssets: 27,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "30750",
    propertyType: "Multifamily",
    totalCosts: "3399363",
    valueOfReclassifiedAssets: 2952275,
    depreciableAssets: 14,
  },
  {
    lat: 41.8492122,
    lng: -87.6713199,
    squareFootage: "32400",
    propertyType: "Office",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 619736,
    depreciableAssets: 14,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "35156",
    propertyType: "Multifamily",
    totalCosts: "3175000",
    valueOfReclassifiedAssets: 555660,
    depreciableAssets: 20,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "47866",
    propertyType: "Multifamily",
    totalCosts: "4701000",
    valueOfReclassifiedAssets: 664593,
    depreciableAssets: 16,
  },
  {
    lat: 41.9464283,
    lng: -87.70740889999999,
    squareFootage: "53252",
    propertyType: "Multifamily",
    totalCosts: "4835000",
    valueOfReclassifiedAssets: 762291,
    depreciableAssets: 18,
  },
  {
    lat: 41.9711068,
    lng: -87.70248169999999,
    squareFootage: "22720",
    propertyType: "Multifamily",
    totalCosts: "3260000",
    valueOfReclassifiedAssets: 417436,
    depreciableAssets: 15,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "46479",
    propertyType: "Multifamily",
    totalCosts: "4379000",
    valueOfReclassifiedAssets: 552348,
    depreciableAssets: 14,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "27450",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 353620,
    depreciableAssets: 17,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "35000",
    propertyType: "Multifamily",
    totalCosts: "2728915",
    valueOfReclassifiedAssets: 2417800,
    depreciableAssets: 14,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "44044",
    propertyType: "Multifamily",
    totalCosts: "4523000",
    valueOfReclassifiedAssets: 797484,
    depreciableAssets: 20,
  },
  {
    lat: 41.9711068,
    lng: -87.70248169999999,
    squareFootage: "32425",
    propertyType: "Multifamily",
    totalCosts: "5362000",
    valueOfReclassifiedAssets: 885762,
    depreciableAssets: 19,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "36976",
    propertyType: "Multifamily",
    totalCosts: "3483000",
    valueOfReclassifiedAssets: 452987,
    depreciableAssets: 16,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "41856",
    propertyType: "Multifamily",
    totalCosts: "4468000",
    valueOfReclassifiedAssets: 841649,
    depreciableAssets: 22,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "29847",
    propertyType: "Multifamily",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 1129941,
    depreciableAssets: 21,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "39105",
    propertyType: "Multifamily",
    totalCosts: "5096000",
    valueOfReclassifiedAssets: 947955,
    depreciableAssets: 22,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "27150",
    propertyType: "Multifamily",
    totalCosts: "3380000",
    valueOfReclassifiedAssets: 441171,
    depreciableAssets: 16,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "65706",
    propertyType: "Multifamily",
    totalCosts: "7064000",
    valueOfReclassifiedAssets: 1286523,
    depreciableAssets: 22,
  },
  {
    lat: 41.9703133,
    lng: -87.663045,
    squareFootage: "17019",
    propertyType: "Multifamily",
    totalCosts: "2636000",
    valueOfReclassifiedAssets: 381528,
    depreciableAssets: 17,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "59535",
    propertyType: "Multifamily",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 1045611,
    depreciableAssets: 15,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "30819",
    propertyType: "Multifamily",
    totalCosts: "2821000",
    valueOfReclassifiedAssets: 355376,
    depreciableAssets: 15,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "36976",
    propertyType: "Multifamily",
    totalCosts: "3520000",
    valueOfReclassifiedAssets: 457799,
    depreciableAssets: 16,
  },
  {
    lat: 41.9711068,
    lng: -87.70248169999999,
    squareFootage: "23796",
    propertyType: "Multifamily",
    totalCosts: "4692000",
    valueOfReclassifiedAssets: 605340,
    depreciableAssets: 15,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "35288",
    propertyType: "Multifamily",
    totalCosts: "4562000",
    valueOfReclassifiedAssets: 660148,
    depreciableAssets: 17,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "39852",
    propertyType: "Multifamily",
    totalCosts: "3964000",
    valueOfReclassifiedAssets: 507849,
    depreciableAssets: 15,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "16543",
    propertyType: "Multifamily",
    totalCosts: "6300000",
    valueOfReclassifiedAssets: 1609240,
    depreciableAssets: 30,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "17964",
    propertyType: "Multifamily",
    totalCosts: "1570000",
    valueOfReclassifiedAssets: 220353,
    depreciableAssets: 16,
  },
  {
    lat: 41.8003921,
    lng: -87.603826,
    squareFootage: "14502",
    propertyType: "Multifamily",
    totalCosts: "1278000",
    valueOfReclassifiedAssets: 190648,
    depreciableAssets: 18,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "349140",
    propertyType: "Multifamily",
    totalCosts: "36000000",
    valueOfReclassifiedAssets: 6723621,
    depreciableAssets: 20,
  },
  {
    lat: 40.7758541,
    lng: -74.16509909999999,
    squareFootage: "577600",
    propertyType: "Multifamily",
    totalCosts: "51520000",
    valueOfReclassifiedAssets: 6608436,
    depreciableAssets: 14,
  },
  {
    lat: 35.0060941,
    lng: -77.7223162,
    squareFootage: "31078",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 840754,
    depreciableAssets: 28,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "31162665",
    valueOfReclassifiedAssets: 25814165,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "66020",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23526000",
    valueOfReclassifiedAssets: 5238716,
    depreciableAssets: 26,
  },
  {
    lat: 39.1140535,
    lng: -94.77433649999999,
    squareFootage: "189864",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2040944,
    depreciableAssets: 26,
  },
  {
    lat: 39.0704366,
    lng: -94.6967189,
    squareFootage: "114672",
    propertyType: "Multifamily",
    totalCosts: "5185000",
    valueOfReclassifiedAssets: 1041940,
    depreciableAssets: 23,
  },
  {
    lat: 39.7443294,
    lng: -89.7286056,
    squareFootage: "229836",
    propertyType: "Multifamily",
    totalCosts: "11050000",
    valueOfReclassifiedAssets: 3809862,
    depreciableAssets: 41,
  },
  {
    lat: 32.9087285,
    lng: -96.7386946,
    squareFootage: "982671",
    propertyType: "Multifamily",
    totalCosts: "56000000",
    valueOfReclassifiedAssets: 16566610,
    depreciableAssets: 35,
  },
  {
    lat: 33.1128019,
    lng: -96.6958939,
    squareFootage: "51800",
    propertyType: "Hotel",
    totalCosts: "5780471",
    valueOfReclassifiedAssets: 4535113,
    depreciableAssets: 30,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "401301",
    valueOfReclassifiedAssets: 368485,
    depreciableAssets: 17,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "431756",
    valueOfReclassifiedAssets: 398439,
    depreciableAssets: 24,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "11500",
    propertyType: "Office",
    totalCosts: "1515265",
    valueOfReclassifiedAssets: 1420066,
    depreciableAssets: 13,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "34992",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1617841,
    depreciableAssets: 36,
  },
  {
    lat: 45.4796668,
    lng: -122.5146061,
    squareFootage: "58280",
    propertyType: "Multifamily",
    totalCosts: "7100000",
    valueOfReclassifiedAssets: 2051741,
    depreciableAssets: 32,
  },
  {
    lat: 45.6373126,
    lng: -118.8123938,
    squareFootage: "24950",
    propertyType: "Multifamily",
    totalCosts: "1674419",
    valueOfReclassifiedAssets: 530391,
    depreciableAssets: 37,
  },
  {
    lat: 45.6373126,
    lng: -118.8123938,
    squareFootage: "65850",
    propertyType: "Multifamily",
    totalCosts: "4790698",
    valueOfReclassifiedAssets: 1813712,
    depreciableAssets: 45,
  },
  {
    lat: 45.6373126,
    lng: -118.8123938,
    squareFootage: "24200",
    propertyType: "Multifamily",
    totalCosts: "1534884",
    valueOfReclassifiedAssets: 719165,
    depreciableAssets: 55,
  },
  {
    lat: 39.4953787,
    lng: -119.8236554,
    squareFootage: "9696",
    propertyType: "Multifamily",
    totalCosts: "1485000",
    valueOfReclassifiedAssets: 435003,
    depreciableAssets: 35,
  },
  {
    lat: 39.4953787,
    lng: -119.8236554,
    squareFootage: "44348",
    propertyType: "Multifamily",
    totalCosts: "5620000",
    valueOfReclassifiedAssets: 1480306,
    depreciableAssets: 31,
  },
  {
    lat: 38.8628755,
    lng: -76.97006689999999,
    squareFootage: "886116",
    propertyType: "Multifamily",
    totalCosts: "55000000",
    valueOfReclassifiedAssets: 6216624,
    depreciableAssets: 14,
  },
  {
    lat: 36.0613546,
    lng: -95.9515399,
    squareFootage: "216107",
    propertyType: "Multifamily",
    totalCosts: "2575000",
    valueOfReclassifiedAssets: 658756,
    depreciableAssets: 27,
  },
  {
    lat: 36.0613546,
    lng: -95.9515399,
    squareFootage: "216107",
    propertyType: "Multifamily",
    totalCosts: "2575000",
    valueOfReclassifiedAssets: 658756,
    depreciableAssets: 27,
  },
  {
    lat: 34.167305,
    lng: -118.3987842,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1677924",
    valueOfReclassifiedAssets: 1279364,
    depreciableAssets: 26,
  },
  {
    lat: 34.167305,
    lng: -118.3987842,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1557052",
    valueOfReclassifiedAssets: 1148507,
    depreciableAssets: 29,
  },
  {
    lat: 35.4516124,
    lng: -118.7897558,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4918635",
    valueOfReclassifiedAssets: 3376596,
    depreciableAssets: 44,
  },
  {
    lat: 38.370187,
    lng: -120.734995,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6334133",
    valueOfReclassifiedAssets: 4282993,
    depreciableAssets: 42,
  },
  {
    lat: 34.0031792,
    lng: -118.3082034,
    squareFootage: "11234",
    propertyType: "Multifamily",
    totalCosts: "1579796",
    valueOfReclassifiedAssets: 277862,
    depreciableAssets: 25,
  },
  {
    lat: 34.0031792,
    lng: -118.3082034,
    squareFootage: "11580",
    propertyType: "Multifamily",
    totalCosts: "1696818",
    valueOfReclassifiedAssets: 290198,
    depreciableAssets: 24,
  },
  {
    lat: 34.0470832,
    lng: -118.2965121,
    squareFootage: "9690",
    propertyType: "Multifamily",
    totalCosts: "1511533",
    valueOfReclassifiedAssets: 231278,
    depreciableAssets: 25,
  },
  {
    lat: 34.167305,
    lng: -118.3987842,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1587324",
    valueOfReclassifiedAssets: 1171145,
    depreciableAssets: 27,
  },
  {
    lat: 37.7538236,
    lng: -77.4931403,
    squareFootage: "44688",
    propertyType: "Industrial",
    totalCosts: "3074888",
    valueOfReclassifiedAssets: 312838,
    depreciableAssets: 11,
  },
  {
    lat: 42.1099506,
    lng: -87.9824758,
    squareFootage: "61040",
    propertyType: "Industrial",
    totalCosts: "4631430",
    valueOfReclassifiedAssets: 1079208,
    depreciableAssets: 26,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "62329",
    propertyType: "Industrial",
    totalCosts: "2883398",
    valueOfReclassifiedAssets: 576579,
    depreciableAssets: 25,
  },
  {
    lat: 36.0564654,
    lng: -80.33245140000001,
    squareFootage: "38320",
    propertyType: "Industrial",
    totalCosts: "5670129",
    valueOfReclassifiedAssets: 2026415,
    depreciableAssets: 42,
  },
  {
    lat: 37.7538236,
    lng: -77.4931403,
    squareFootage: "44688",
    propertyType: "Industrial",
    totalCosts: "2744888",
    valueOfReclassifiedAssets: 275228,
    depreciableAssets: 11,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "62329",
    propertyType: "Industrial",
    totalCosts: "2883398",
    valueOfReclassifiedAssets: 579296,
    depreciableAssets: 26,
  },
  {
    lat: 39.9573936,
    lng: -75.2654063,
    squareFootage: "38793",
    propertyType: "Industrial",
    totalCosts: "2195028",
    valueOfReclassifiedAssets: 248771,
    depreciableAssets: 12,
  },
  {
    lat: 40.0149445,
    lng: -75.0903154,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4446619",
    valueOfReclassifiedAssets: 1069803,
    depreciableAssets: 25,
  },
  {
    lat: 39.9793657,
    lng: -75.1253492,
    squareFootage: "47400",
    propertyType: "Industrial",
    totalCosts: "5391239",
    valueOfReclassifiedAssets: 1001990,
    depreciableAssets: 21,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "70895",
    propertyType: "Industrial",
    totalCosts: "6085587",
    valueOfReclassifiedAssets: 1299241,
    depreciableAssets: 25,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "70895",
    propertyType: "Industrial",
    totalCosts: "6085587",
    valueOfReclassifiedAssets: 1304527,
    depreciableAssets: 25,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "60250",
    propertyType: "Industrial",
    totalCosts: "3696402",
    valueOfReclassifiedAssets: 3280953,
    depreciableAssets: 31,
  },
  {
    lat: 39.8702951,
    lng: -75.1370254,
    squareFootage: "122270",
    propertyType: "Industrial",
    totalCosts: "10046554",
    valueOfReclassifiedAssets: 3006848,
    depreciableAssets: 32,
  },
  {
    lat: 33.9272136,
    lng: -78.77028949999999,
    squareFootage: "115710",
    propertyType: "Industrial",
    totalCosts: "7518591",
    valueOfReclassifiedAssets: 6606100,
    depreciableAssets: 37,
  },
  {
    lat: 33.8563886,
    lng: -78.60014819999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8253539",
    valueOfReclassifiedAssets: 7411681,
    depreciableAssets: 30,
  },
  {
    lat: 37.7538236,
    lng: -77.4931403,
    squareFootage: "44688",
    propertyType: "Industrial",
    totalCosts: "3156964",
    valueOfReclassifiedAssets: 322192,
    depreciableAssets: 11,
  },
  {
    lat: 43.2743852,
    lng: -70.83577919999999,
    squareFootage: "75900",
    propertyType: "Industrial",
    totalCosts: "5553625",
    valueOfReclassifiedAssets: 995419,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "60250",
    propertyType: "Industrial",
    totalCosts: "3696402",
    valueOfReclassifiedAssets: 3278189,
    depreciableAssets: 31,
  },
  {
    lat: 37.62423,
    lng: -76.3405733,
    squareFootage: "15417",
    propertyType: "Industrial",
    totalCosts: "367724",
    valueOfReclassifiedAssets: 68271,
    depreciableAssets: 39,
  },
  {
    lat: 37.0098187,
    lng: -76.4273844,
    squareFootage: "47550",
    propertyType: "Industrial",
    totalCosts: "3551876",
    valueOfReclassifiedAssets: 476252,
    depreciableAssets: 21,
  },
  {
    lat: 43.2566897,
    lng: -70.89523369999999,
    squareFootage: "103350",
    propertyType: "Industrial",
    totalCosts: "8002156",
    valueOfReclassifiedAssets: 2413129,
    depreciableAssets: 33,
  },
  {
    lat: 37.6164453,
    lng: -77.4816693,
    squareFootage: "50768",
    propertyType: "Industrial",
    totalCosts: "5655158",
    valueOfReclassifiedAssets: 1168173,
    depreciableAssets: 22,
  },
  {
    lat: 42.46345030000001,
    lng: -73.3001233,
    squareFootage: "55890",
    propertyType: "Industrial",
    totalCosts: "5541414",
    valueOfReclassifiedAssets: 896006,
    depreciableAssets: 19,
  },
  {
    lat: 40.11873509999999,
    lng: -74.7395591,
    squareFootage: "80700",
    propertyType: "Industrial",
    totalCosts: "10039735",
    valueOfReclassifiedAssets: 2604337,
    depreciableAssets: 27,
  },
  {
    lat: 42.722661,
    lng: -73.8301437,
    squareFootage: "53606",
    propertyType: "Industrial",
    totalCosts: "6021889",
    valueOfReclassifiedAssets: 802079,
    depreciableAssets: 16,
  },
  {
    lat: 40.1101584,
    lng: -88.36945949999999,
    squareFootage: "36845",
    propertyType: "Industrial",
    totalCosts: "1421658",
    valueOfReclassifiedAssets: 296359,
    depreciableAssets: 24,
  },
  {
    lat: 40.1539406,
    lng: -88.3433623,
    squareFootage: "58190",
    propertyType: "Industrial",
    totalCosts: "3112161",
    valueOfReclassifiedAssets: 809490,
    depreciableAssets: 31,
  },
  {
    lat: 36.8032821,
    lng: -76.4215988,
    squareFootage: "54559",
    propertyType: "Industrial",
    totalCosts: "3401320",
    valueOfReclassifiedAssets: 501913,
    depreciableAssets: 16,
  },
  {
    lat: 43.22978639999999,
    lng: -70.6335763,
    squareFootage: "63175",
    propertyType: "Industrial",
    totalCosts: "5741494",
    valueOfReclassifiedAssets: 1273153,
    depreciableAssets: 24,
  },
  {
    lat: 42.8649909,
    lng: -73.95949399999999,
    squareFootage: "4222",
    propertyType: "Industrial",
    totalCosts: "5159690",
    valueOfReclassifiedAssets: 824479,
    depreciableAssets: 18,
  },
  {
    lat: 36.8032821,
    lng: -76.4215988,
    squareFootage: "54559",
    propertyType: "Industrial",
    totalCosts: "3401320",
    valueOfReclassifiedAssets: 501791,
    depreciableAssets: 16,
  },
  {
    lat: 37.3790223,
    lng: -76.7567368,
    squareFootage: "61390",
    propertyType: "Industrial",
    totalCosts: "4399706",
    valueOfReclassifiedAssets: 551373,
    depreciableAssets: 16,
  },
  {
    lat: 43.3157605,
    lng: -70.645473,
    squareFootage: "80668",
    propertyType: "Industrial",
    totalCosts: "9309532",
    valueOfReclassifiedAssets: 2276052,
    depreciableAssets: 26,
  },
  {
    lat: 40.6365451,
    lng: -111.4991334,
    squareFootage: "21968",
    propertyType: "Industrial",
    totalCosts: "2194512",
    valueOfReclassifiedAssets: 326412,
    depreciableAssets: 22,
  },
  {
    lat: 42.7552682,
    lng: -73.9242251,
    squareFootage: "79500",
    propertyType: "Industrial",
    totalCosts: "6588981",
    valueOfReclassifiedAssets: 979497,
    depreciableAssets: 20,
  },
  {
    lat: 40.11873509999999,
    lng: -74.7395591,
    squareFootage: "80700",
    propertyType: "Industrial",
    totalCosts: "10241056",
    valueOfReclassifiedAssets: 2662545,
    depreciableAssets: 27,
  },
  {
    lat: 42.1300574,
    lng: -72.4376499,
    squareFootage: "36650",
    propertyType: "Industrial",
    totalCosts: "3644011",
    valueOfReclassifiedAssets: 636285,
    depreciableAssets: 21,
  },
  {
    lat: 37.5334766,
    lng: -77.6879696,
    squareFootage: "140540",
    propertyType: "Industrial",
    totalCosts: "18804809",
    valueOfReclassifiedAssets: 4732591,
    depreciableAssets: 26,
  },
  {
    lat: 42.7851766,
    lng: -73.7360175,
    squareFootage: "49350",
    propertyType: "Industrial",
    totalCosts: "5426807",
    valueOfReclassifiedAssets: 869685,
    depreciableAssets: 20,
  },
  {
    lat: 40.8085015,
    lng: -73.0346244,
    squareFootage: "84000",
    propertyType: "Industrial",
    totalCosts: "11626450",
    valueOfReclassifiedAssets: 2279014,
    depreciableAssets: 22,
  },
  {
    lat: 40.1166102,
    lng: -88.2412504,
    squareFootage: "39800",
    propertyType: "Industrial",
    totalCosts: "2026224",
    valueOfReclassifiedAssets: 534197,
    depreciableAssets: 34,
  },
  {
    lat: 39.6471106,
    lng: -106.6527099,
    squareFootage: "44020",
    propertyType: "Industrial",
    totalCosts: "7577684",
    valueOfReclassifiedAssets: 2075662,
    depreciableAssets: 32,
  },
  {
    lat: 44.1114611,
    lng: -70.2556579,
    squareFootage: "59375",
    propertyType: "Industrial",
    totalCosts: "5922744",
    valueOfReclassifiedAssets: 1313129,
    depreciableAssets: 25,
  },
  {
    lat: 43.415378,
    lng: -70.7356619,
    squareFootage: "87400",
    propertyType: "Industrial",
    totalCosts: "7187324",
    valueOfReclassifiedAssets: 1710062,
    depreciableAssets: 25,
  },
  {
    lat: 42.7478224,
    lng: -73.69188059999999,
    squareFootage: "43850",
    propertyType: "Industrial",
    totalCosts: "3731846",
    valueOfReclassifiedAssets: 516849,
    depreciableAssets: 17,
  },
  {
    lat: 42.1300574,
    lng: -72.4376499,
    squareFootage: "73125",
    propertyType: "Industrial",
    totalCosts: "7669278",
    valueOfReclassifiedAssets: 1572429,
    depreciableAssets: 23,
  },
  {
    lat: 40.1539406,
    lng: -88.3433623,
    squareFootage: "156937",
    propertyType: "Industrial",
    totalCosts: "7202483",
    valueOfReclassifiedAssets: 1728663,
    depreciableAssets: 26,
  },
  {
    lat: 34.0198844,
    lng: -84.5117321,
    squareFootage: "65855",
    propertyType: "Industrial",
    totalCosts: "3381274",
    valueOfReclassifiedAssets: 369030,
    depreciableAssets: 16,
  },
  {
    lat: 25.8728864,
    lng: -81.6692532,
    squareFootage: "55800",
    propertyType: "Industrial",
    totalCosts: "8134806",
    valueOfReclassifiedAssets: 629161,
    depreciableAssets: 14,
  },
  {
    lat: 42.4326041,
    lng: -71.0557196,
    squareFootage: "65390",
    propertyType: "Industrial",
    totalCosts: "6066319",
    valueOfReclassifiedAssets: 509250,
    depreciableAssets: 21,
  },
  {
    lat: 34.8083898,
    lng: -82.33433749999999,
    squareFootage: "42300",
    propertyType: "Industrial",
    totalCosts: "2928417",
    valueOfReclassifiedAssets: 416178,
    depreciableAssets: 17,
  },
  {
    lat: 42.6705383,
    lng: -71.3000493,
    squareFootage: "47950",
    propertyType: "Industrial",
    totalCosts: "8121850",
    valueOfReclassifiedAssets: 1753707,
    depreciableAssets: 24,
  },
  {
    lat: 25.8728864,
    lng: -81.6692532,
    squareFootage: "55800",
    propertyType: "Industrial",
    totalCosts: "8134806",
    valueOfReclassifiedAssets: 628903,
    depreciableAssets: 14,
  },
  {
    lat: 41.5908094,
    lng: -73.3944466,
    squareFootage: "44000",
    propertyType: "Industrial",
    totalCosts: "6599348",
    valueOfReclassifiedAssets: 1509610,
    depreciableAssets: 24,
  },
  {
    lat: 37.0098187,
    lng: -76.4273844,
    squareFootage: "47550",
    propertyType: "Industrial",
    totalCosts: "3551876",
    valueOfReclassifiedAssets: 476783,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "63450",
    propertyType: "Industrial",
    totalCosts: "9282492",
    valueOfReclassifiedAssets: 1727968,
    depreciableAssets: 20,
  },
  {
    lat: 39.2800675,
    lng: -78.2248291,
    squareFootage: "56662",
    propertyType: "Industrial",
    totalCosts: "4553219",
    valueOfReclassifiedAssets: 888353,
    depreciableAssets: 22,
  },
  {
    lat: 42.6705383,
    lng: -71.3000493,
    squareFootage: "55890",
    propertyType: "Industrial",
    totalCosts: "4809630",
    valueOfReclassifiedAssets: 1350184,
    depreciableAssets: 31,
  },
  {
    lat: 40.6521638,
    lng: -74.9267229,
    squareFootage: "36648",
    propertyType: "Industrial",
    totalCosts: "6043957",
    valueOfReclassifiedAssets: 994487,
    depreciableAssets: 18,
  },
  {
    lat: 36.8653194,
    lng: -76.0507949,
    squareFootage: "409.856",
    propertyType: "Industrial",
    totalCosts: "30235720",
    valueOfReclassifiedAssets: 4664432,
    depreciableAssets: 19,
  },
  {
    lat: 43.3920334,
    lng: -70.4438795,
    squareFootage: "61400",
    propertyType: "Industrial",
    totalCosts: "6037658",
    valueOfReclassifiedAssets: 1053060,
    depreciableAssets: 19,
  },
  {
    lat: 40.4458448,
    lng: -75.36453689999999,
    squareFootage: "70556",
    propertyType: "Industrial",
    totalCosts: "8831751",
    valueOfReclassifiedAssets: 1351496,
    depreciableAssets: 18,
  },
  {
    lat: 42.2527785,
    lng: -71.1299976,
    squareFootage: "40000",
    propertyType: "Industrial",
    totalCosts: "10229676",
    valueOfReclassifiedAssets: 2778041,
    depreciableAssets: 31,
  },
  {
    lat: 25.8728864,
    lng: -81.6692532,
    squareFootage: "55800",
    propertyType: "Industrial",
    totalCosts: "8280247",
    valueOfReclassifiedAssets: 648819,
    depreciableAssets: 14,
  },
  {
    lat: 33.9957883,
    lng: -84.46976629999999,
    squareFootage: "90500",
    propertyType: "Industrial",
    totalCosts: "9507775",
    valueOfReclassifiedAssets: 1809840,
    depreciableAssets: 24,
  },
  {
    lat: 41.33031,
    lng: -81.68020539999999,
    squareFootage: "57567",
    propertyType: "Industrial",
    totalCosts: "4038036",
    valueOfReclassifiedAssets: 1090068,
    depreciableAssets: 29,
  },
  {
    lat: 43.2743852,
    lng: -70.83577919999999,
    squareFootage: "75900",
    propertyType: "Industrial",
    totalCosts: "5553625",
    valueOfReclassifiedAssets: 995509,
    depreciableAssets: 21,
  },
  {
    lat: 42.3377967,
    lng: -71.0705763,
    squareFootage: "53312",
    propertyType: "Office",
    totalCosts: "11886794",
    valueOfReclassifiedAssets: 333519,
    depreciableAssets: 10,
  },
  {
    lat: 43.3920334,
    lng: -70.4438795,
    squareFootage: "61400",
    propertyType: "Industrial",
    totalCosts: "6037658",
    valueOfReclassifiedAssets: 1052918,
    depreciableAssets: 19,
  },
  {
    lat: 34.0512519,
    lng: -84.7211074,
    squareFootage: "64256",
    propertyType: "Industrial",
    totalCosts: "4427825",
    valueOfReclassifiedAssets: 650946,
    depreciableAssets: 18,
  },
  {
    lat: 42.3377967,
    lng: -71.0705763,
    squareFootage: "36484",
    propertyType: "Industrial",
    totalCosts: "15199666",
    valueOfReclassifiedAssets: 1867845,
    depreciableAssets: 23,
  },
  {
    lat: 33.4551831,
    lng: -81.7568253,
    squareFootage: "75350",
    propertyType: "Industrial",
    totalCosts: "4200503",
    valueOfReclassifiedAssets: 947250,
    depreciableAssets: 29,
  },
  {
    lat: 42.3808757,
    lng: -71.1002881,
    squareFootage: "54148",
    propertyType: "Industrial",
    totalCosts: "11260593",
    valueOfReclassifiedAssets: 1221725,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "3204867",
    valueOfReclassifiedAssets: 421920,
    depreciableAssets: 28,
  },
  {
    lat: 34.1577811,
    lng: -84.87763919999999,
    squareFootage: "52615",
    propertyType: "Industrial",
    totalCosts: "3902860",
    valueOfReclassifiedAssets: 1009481,
    depreciableAssets: 30,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "49550",
    propertyType: "Industrial",
    totalCosts: "2157917",
    valueOfReclassifiedAssets: 495106,
    depreciableAssets: 30,
  },
  {
    lat: 40.728439,
    lng: -75.1662121,
    squareFootage: "5400",
    propertyType: "Industrial",
    totalCosts: "6710013",
    valueOfReclassifiedAssets: 1155471,
    depreciableAssets: 20,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "65646",
    propertyType: "Industrial",
    totalCosts: "9280897",
    valueOfReclassifiedAssets: 1701874,
    depreciableAssets: 20,
  },
  {
    lat: 40.1101584,
    lng: -88.36945949999999,
    squareFootage: "36845",
    propertyType: "Industrial",
    totalCosts: "1442758",
    valueOfReclassifiedAssets: 301395,
    depreciableAssets: 24,
  },
  {
    lat: 41.6962336,
    lng: -71.59613279999999,
    squareFootage: "92100",
    propertyType: "Industrial",
    totalCosts: "8620442",
    valueOfReclassifiedAssets: 1215577,
    depreciableAssets: 21,
  },
  {
    lat: 36.0985705,
    lng: -94.1571128,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4619662",
    valueOfReclassifiedAssets: 918100,
    depreciableAssets: 22,
  },
  {
    lat: 41.0326881,
    lng: -80.6659842,
    squareFootage: "101200",
    propertyType: "Industrial",
    totalCosts: "4699336",
    valueOfReclassifiedAssets: 1214784,
    depreciableAssets: 30,
  },
  {
    lat: 34.15854789999999,
    lng: -80.8875861,
    squareFootage: "49550",
    propertyType: "Industrial",
    totalCosts: "2157917",
    valueOfReclassifiedAssets: 490790,
    depreciableAssets: 30,
  },
  {
    lat: 41.6926548,
    lng: -72.7215385,
    squareFootage: "91200",
    propertyType: "Industrial",
    totalCosts: "12058059",
    valueOfReclassifiedAssets: 2512432,
    depreciableAssets: 22,
  },
  {
    lat: 39.2632524,
    lng: -76.4910093,
    squareFootage: "48200",
    propertyType: "Industrial",
    totalCosts: "7525953",
    valueOfReclassifiedAssets: 1711148,
    depreciableAssets: 26,
  },
  {
    lat: 36.7836161,
    lng: -76.07399989999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6612305",
    valueOfReclassifiedAssets: 693641,
    depreciableAssets: 12,
  },
  {
    lat: 42.3364553,
    lng: -71.0349193,
    squareFootage: "41900",
    propertyType: "Industrial",
    totalCosts: "5960493",
    valueOfReclassifiedAssets: 549641,
    depreciableAssets: 15,
  },
  {
    lat: 40.1166102,
    lng: -88.2412504,
    squareFootage: "39800",
    propertyType: "Industrial",
    totalCosts: "2056130",
    valueOfReclassifiedAssets: 544397,
    depreciableAssets: 34,
  },
  {
    lat: 26.7153474,
    lng: -80.0533253,
    squareFootage: "92302",
    propertyType: "Industrial",
    totalCosts: "15262626",
    valueOfReclassifiedAssets: 4498107,
    depreciableAssets: 31,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "56793",
    propertyType: "Industrial",
    totalCosts: "4538637",
    valueOfReclassifiedAssets: 657263,
    depreciableAssets: 19,
  },
  {
    lat: 40.1744339,
    lng: -74.8565613,
    squareFootage: "43211",
    propertyType: "Industrial",
    totalCosts: "5020295",
    valueOfReclassifiedAssets: 1109419,
    depreciableAssets: 29,
  },
  {
    lat: 33.9272136,
    lng: -78.77028949999999,
    squareFootage: "115710",
    propertyType: "Industrial",
    totalCosts: "7518591",
    valueOfReclassifiedAssets: 6598740,
    depreciableAssets: 37,
  },
  {
    lat: 34.9944873,
    lng: -81.0533854,
    squareFootage: "89106",
    propertyType: "Industrial",
    totalCosts: "7345155",
    valueOfReclassifiedAssets: 2756215,
    depreciableAssets: 44,
  },
  {
    lat: 43.6083314,
    lng: -70.3976368,
    squareFootage: "54025",
    propertyType: "Industrial",
    totalCosts: "5017349",
    valueOfReclassifiedAssets: 868821,
    depreciableAssets: 20,
  },
  {
    lat: 33.0843712,
    lng: -80.110762,
    squareFootage: "112551",
    propertyType: "Industrial",
    totalCosts: "12316386",
    valueOfReclassifiedAssets: 2972041,
    depreciableAssets: 27,
  },
  {
    lat: 33.5857518,
    lng: -81.71305219999999,
    squareFootage: "73440",
    propertyType: "Industrial",
    totalCosts: "6603458",
    valueOfReclassifiedAssets: 1708499,
    depreciableAssets: 31,
  },
  {
    lat: 34.1142529,
    lng: -84.63744840000001,
    squareFootage: "70250",
    propertyType: "Industrial",
    totalCosts: "4783042",
    valueOfReclassifiedAssets: 784895,
    depreciableAssets: 19,
  },
  {
    lat: 26.0517448,
    lng: -80.13727569999999,
    squareFootage: "65706",
    propertyType: "Industrial",
    totalCosts: "6739807",
    valueOfReclassifiedAssets: 1213761,
    depreciableAssets: 19,
  },
  {
    lat: 40.8382522,
    lng: -73.8566087,
    squareFootage: "95498",
    propertyType: "Industrial",
    totalCosts: "38958699",
    valueOfReclassifiedAssets: 5930661,
    depreciableAssets: 22,
  },
  {
    lat: 33.9800711,
    lng: -84.87763919999999,
    squareFootage: "81550",
    propertyType: "Industrial",
    totalCosts: "9362511",
    valueOfReclassifiedAssets: 2463860,
    depreciableAssets: 29,
  },
  {
    lat: 40.6999925,
    lng: -112.0118669,
    squareFootage: "118335",
    propertyType: "Industrial",
    totalCosts: "7147375",
    valueOfReclassifiedAssets: 885064,
    depreciableAssets: 14,
  },
  {
    lat: 33.0843712,
    lng: -80.110762,
    squareFootage: "98533",
    propertyType: "Industrial",
    totalCosts: "8471643",
    valueOfReclassifiedAssets: 1554647,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "131283",
    propertyType: "Industrial",
    totalCosts: "20499077",
    valueOfReclassifiedAssets: 4911680,
    depreciableAssets: 27,
  },
  {
    lat: 33.8563886,
    lng: -78.60014819999999,
    squareFootage: "85233",
    propertyType: "Industrial",
    totalCosts: "8253539",
    valueOfReclassifiedAssets: 7408339,
    depreciableAssets: 30,
  },
  {
    lat: 26.0517448,
    lng: -80.13727569999999,
    squareFootage: "65706",
    propertyType: "Industrial",
    totalCosts: "6739807",
    valueOfReclassifiedAssets: 1213460,
    depreciableAssets: 19,
  },
  {
    lat: 33.4551831,
    lng: -81.7568253,
    squareFootage: "75350",
    propertyType: "Industrial",
    totalCosts: "4200503",
    valueOfReclassifiedAssets: 950322,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "53660",
    propertyType: "Industrial",
    totalCosts: "3676098",
    valueOfReclassifiedAssets: 528130,
    depreciableAssets: 23,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "75825",
    propertyType: "Industrial",
    totalCosts: "6591274",
    valueOfReclassifiedAssets: 468245,
    depreciableAssets: 10,
  },
  {
    lat: 40.4960215,
    lng: -111.8687825,
    squareFootage: "148314",
    propertyType: "Retail",
    totalCosts: "16328410",
    valueOfReclassifiedAssets: 2005733,
    depreciableAssets: 15,
  },
  {
    lat: 36.0097372,
    lng: -80.2878794,
    squareFootage: "81150",
    propertyType: "Industrial",
    totalCosts: "6195979",
    valueOfReclassifiedAssets: 1064594,
    depreciableAssets: 19,
  },
  {
    lat: 43.0324123,
    lng: -89.3843206,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3654364",
    valueOfReclassifiedAssets: 554557,
    depreciableAssets: 24,
  },
  {
    lat: 33.969189,
    lng: -78.39559860000001,
    squareFootage: "144560",
    propertyType: "Industrial",
    totalCosts: "9953187",
    valueOfReclassifiedAssets: 2169049,
    depreciableAssets: 24,
  },
  {
    lat: 40.5054763,
    lng: -74.3939339,
    squareFootage: "124549",
    propertyType: "Industrial",
    totalCosts: "21461122",
    valueOfReclassifiedAssets: 4532140,
    depreciableAssets: 24,
  },
  {
    lat: 36.8653194,
    lng: -76.0507949,
    squareFootage: "56560",
    propertyType: "Industrial",
    totalCosts: "3672079",
    valueOfReclassifiedAssets: 628013,
    depreciableAssets: 23,
  },
  {
    lat: 34.0033581,
    lng: -78.1792434,
    squareFootage: "101525",
    propertyType: "Industrial",
    totalCosts: "7308579",
    valueOfReclassifiedAssets: 1962365,
    depreciableAssets: 29,
  },
  {
    lat: 40.2303787,
    lng: -74.27661859999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12168242",
    valueOfReclassifiedAssets: 2896196,
    depreciableAssets: 27,
  },
  {
    lat: 40.7245083,
    lng: -73.4298073,
    squareFootage: "33168",
    propertyType: "Industrial",
    totalCosts: "5758461",
    valueOfReclassifiedAssets: 1339726,
    depreciableAssets: 33,
  },
  {
    lat: 40.86882629999999,
    lng: -73.0759446,
    squareFootage: "70845",
    propertyType: "Industrial",
    totalCosts: "11146019",
    valueOfReclassifiedAssets: 2221980,
    depreciableAssets: 23,
  },
  {
    lat: 39.7017383,
    lng: -75.11951069999999,
    squareFootage: "37050",
    propertyType: "Industrial",
    totalCosts: "3089414",
    valueOfReclassifiedAssets: 560956,
    depreciableAssets: 29,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "79766",
    propertyType: "Industrial",
    totalCosts: "52882866",
    valueOfReclassifiedAssets: 4272771,
    depreciableAssets: 10,
  },
  {
    lat: 41.5905625,
    lng: -71.2517835,
    squareFootage: "45815",
    propertyType: "Industrial",
    totalCosts: "7411456",
    valueOfReclassifiedAssets: 1413821,
    depreciableAssets: 20,
  },
  {
    lat: 40.4430064,
    lng: -74.4818698,
    squareFootage: "68000",
    propertyType: "Industrial",
    totalCosts: "10672734",
    valueOfReclassifiedAssets: 2434926,
    depreciableAssets: 24,
  },
  {
    lat: 43.5417803,
    lng: -70.44503499999999,
    squareFootage: "34450",
    propertyType: "Industrial",
    totalCosts: "4011606",
    valueOfReclassifiedAssets: 1046498,
    depreciableAssets: 31,
  },
  {
    lat: 39.8282551,
    lng: -84.8984775,
    squareFootage: "119680",
    propertyType: "Multifamily",
    totalCosts: "3235831",
    valueOfReclassifiedAssets: 2613650,
    depreciableAssets: 21,
  },
  {
    lat: 39.8282551,
    lng: -84.8984775,
    squareFootage: "119680",
    propertyType: "Multifamily",
    totalCosts: "2835831",
    valueOfReclassifiedAssets: 2290561,
    depreciableAssets: 21,
  },
  {
    lat: 39.8282551,
    lng: -84.8984775,
    squareFootage: "119680",
    propertyType: "Multifamily",
    totalCosts: "3035831",
    valueOfReclassifiedAssets: 531784,
    depreciableAssets: 19,
  },
  {
    lat: 28.4385186,
    lng: -81.6254283,
    squareFootage: "478204",
    propertyType: "Industrial",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 4721688,
    depreciableAssets: 61,
  },
  {
    lat: 34.2184226,
    lng: -111.3321974,
    squareFootage: "81522",
    propertyType: "Retail",
    totalCosts: "5571143",
    valueOfReclassifiedAssets: 1121435,
    depreciableAssets: 24,
  },
  {
    lat: 34.1451995,
    lng: -119.1942983,
    squareFootage: "116002",
    propertyType: "Retail",
    totalCosts: "32414409",
    valueOfReclassifiedAssets: 26974239,
    depreciableAssets: 28,
  },
  {
    lat: 32.6385134,
    lng: -117.0617553,
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "16475228",
    valueOfReclassifiedAssets: 14465981,
    depreciableAssets: 35,
  },
  {
    lat: 41.5380659,
    lng: -73.90070899999999,
    squareFootage: "108474",
    propertyType: "Hotel",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 2473935,
    depreciableAssets: 37,
  },
  {
    lat: 42.6823182,
    lng: -73.8477874,
    squareFootage: "123802",
    propertyType: "Hotel",
    totalCosts: "12330000",
    valueOfReclassifiedAssets: 3235382,
    depreciableAssets: 31,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "202199",
    propertyType: "Multifamily",
    totalCosts: "4643405",
    valueOfReclassifiedAssets: 1160893,
    depreciableAssets: 27,
  },
  {
    lat: 33.2288218,
    lng: -86.7919009,
    squareFootage: "335496",
    propertyType: "Multifamily",
    totalCosts: "19763048",
    valueOfReclassifiedAssets: 16219723,
    depreciableAssets: 25,
  },
  {
    lat: 40.0149445,
    lng: -75.0903154,
    squareFootage: "81272",
    propertyType: "Multifamily",
    totalCosts: "3645000",
    valueOfReclassifiedAssets: 369231,
    depreciableAssets: 12,
  },
  {
    lat: 39.9350642,
    lng: -75.1516194,
    squareFootage: "16738",
    propertyType: "Multifamily",
    totalCosts: "2090000",
    valueOfReclassifiedAssets: 270361,
    depreciableAssets: 15,
  },
  {
    lat: 39.9600168,
    lng: -75.22748659999999,
    squareFootage: "21640",
    propertyType: "Multifamily",
    totalCosts: "487000",
    valueOfReclassifiedAssets: 12061,
    depreciableAssets: 2,
  },
  {
    lat: 40.0397682,
    lng: -75.145782,
    squareFootage: "201130",
    propertyType: "Multifamily",
    totalCosts: "10400000",
    valueOfReclassifiedAssets: 952282,
    depreciableAssets: 11,
  },
  {
    lat: 38.8372336,
    lng: -77.01614359999999,
    squareFootage: "134430",
    propertyType: "Multifamily",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 985488,
    depreciableAssets: 22,
  },
  {
    lat: 39.9573936,
    lng: -75.2654063,
    squareFootage: "96000",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1447910,
    depreciableAssets: 29,
  },
  {
    lat: 40.0149824,
    lng: -75.18663959999999,
    squareFootage: "77135",
    propertyType: "Office",
    totalCosts: "4250000",
    valueOfReclassifiedAssets: 548002,
    depreciableAssets: 15,
  },
  {
    lat: 39.77773690000001,
    lng: -75.4890245,
    squareFootage: "175944",
    propertyType: "Multifamily",
    totalCosts: "4150000",
    valueOfReclassifiedAssets: 573172,
    depreciableAssets: 17,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "379400",
    propertyType: "Multifamily",
    totalCosts: "15875000",
    valueOfReclassifiedAssets: 4073591,
    depreciableAssets: 30,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "412806",
    propertyType: "Multifamily",
    totalCosts: "11600000",
    valueOfReclassifiedAssets: 3017653,
    depreciableAssets: 31,
  },
  {
    lat: 35.0938654,
    lng: -89.99067339999999,
    squareFootage: "65875",
    propertyType: "Multifamily",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 1484929,
    depreciableAssets: 29,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24298503",
    valueOfReclassifiedAssets: 21608776,
    depreciableAssets: 12,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "147146",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1115260,
    depreciableAssets: 23,
  },
  {
    lat: 30.1643797,
    lng: -81.7458845,
    squareFootage: "108342",
    propertyType: "Multifamily",
    totalCosts: "6770346",
    valueOfReclassifiedAssets: 1672275,
    depreciableAssets: 27,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "95570",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 1196910,
    depreciableAssets: 33,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "80240",
    propertyType: "Multifamily",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 1879938,
    depreciableAssets: 34,
  },
  {
    lat: 40.7972384,
    lng: -74.1063776,
    squareFootage: "271907",
    propertyType: "Office",
    totalCosts: "47761675",
    valueOfReclassifiedAssets: 6918786,
    depreciableAssets: 15,
  },
  {
    lat: 41.0813932,
    lng: -73.8536683,
    squareFootage: "122200",
    propertyType: "Hotel",
    totalCosts: "14678065",
    valueOfReclassifiedAssets: 12929646,
    depreciableAssets: 33,
  },
  {
    lat: 40.7972384,
    lng: -74.1063776,
    squareFootage: "271907",
    propertyType: "Office",
    totalCosts: "47761675",
    valueOfReclassifiedAssets: 6918786,
    depreciableAssets: 15,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "33028000",
    valueOfReclassifiedAssets: 4840998,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "4836",
    propertyType: "Multifamily",
    totalCosts: "1515000",
    valueOfReclassifiedAssets: 180337,
    depreciableAssets: 14,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "4836",
    propertyType: "Multifamily",
    totalCosts: "1425000",
    valueOfReclassifiedAssets: 182564,
    depreciableAssets: 15,
  },
  {
    lat: 41.6623145,
    lng: -72.6505957,
    squareFootage: "9600",
    propertyType: "Office",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 407899,
    depreciableAssets: 30,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "156644",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 2048621,
    depreciableAssets: 11,
  },
  {
    lat: 37.3976686,
    lng: -77.5963271,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "271051",
    valueOfReclassifiedAssets: 210278,
    depreciableAssets: 26,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "289726",
    propertyType: "Multifamily",
    totalCosts: "17165000",
    valueOfReclassifiedAssets: 4791946,
    depreciableAssets: 33,
  },
  {
    lat: 37.6115093,
    lng: -77.4357739,
    squareFootage: "289726",
    propertyType: "Multifamily",
    totalCosts: "17165000",
    valueOfReclassifiedAssets: 4791946,
    depreciableAssets: 33,
  },
  {
    lat: 37.3976686,
    lng: -77.5963271,
    squareFootage: "255790",
    propertyType: "Multifamily",
    totalCosts: "28000000",
    valueOfReclassifiedAssets: 7400410,
    depreciableAssets: 32,
  },
  {
    lat: 47.6120759,
    lng: -122.1112721,
    squareFootage: "67408",
    propertyType: "Office",
    totalCosts: "2546671",
    valueOfReclassifiedAssets: 2052423,
    depreciableAssets: 29,
  },
  {
    lat: 47.6120759,
    lng: -122.1112721,
    squareFootage: "67408",
    propertyType: "Office",
    totalCosts: "3012861",
    valueOfReclassifiedAssets: 2428137,
    depreciableAssets: 29,
  },
  {
    lat: 47.6105427,
    lng: -122.142426,
    squareFootage: "27527",
    propertyType: "Retail",
    totalCosts: "5040001",
    valueOfReclassifiedAssets: 4391268,
    depreciableAssets: 23,
  },
  {
    lat: 47.6120759,
    lng: -122.1112721,
    squareFootage: "66827",
    propertyType: "Office",
    totalCosts: "2557654",
    valueOfReclassifiedAssets: 2192986,
    depreciableAssets: 25,
  },
  {
    lat: 47.6120759,
    lng: -122.1112721,
    squareFootage: "66827",
    propertyType: "Office",
    totalCosts: "1897772",
    valueOfReclassifiedAssets: 1627189,
    depreciableAssets: 25,
  },
  {
    lat: 47.6105427,
    lng: -122.142426,
    squareFootage: "18889",
    propertyType: "Industrial",
    totalCosts: "4889868",
    valueOfReclassifiedAssets: 4521126,
    depreciableAssets: 21,
  },
  {
    lat: 47.6120759,
    lng: -122.1112721,
    squareFootage: "66827",
    propertyType: "Office",
    totalCosts: "1113889",
    valueOfReclassifiedAssets: 955072,
    depreciableAssets: 25,
  },
  {
    lat: 44.4626923,
    lng: -84.6479124,
    squareFootage: "33370",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3325574",
    valueOfReclassifiedAssets: 1056046,
    depreciableAssets: 35,
  },
  {
    lat: 41.9720786,
    lng: -87.7517295,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11681458",
    valueOfReclassifiedAssets: 7443613,
    depreciableAssets: 41,
  },
  {
    lat: 42.1970343,
    lng: -88.0935013,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1748759",
    valueOfReclassifiedAssets: 1232691,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "61574",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12045470",
    valueOfReclassifiedAssets: 4671296,
    depreciableAssets: 43,
  },
  {
    lat: 38.5812063,
    lng: -94.6657027,
    squareFootage: "22180",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2520000",
    valueOfReclassifiedAssets: 790593,
    depreciableAssets: 37,
  },
  {
    lat: 38.9254358,
    lng: -94.5469705,
    squareFootage: "69452",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5254888",
    valueOfReclassifiedAssets: 1466947,
    depreciableAssets: 33,
  },
  {
    lat: 47.1470492,
    lng: -88.5893195,
    squareFootage: "24522",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2251210",
    valueOfReclassifiedAssets: 672297,
    depreciableAssets: 33,
  },
  {
    lat: 41.8403395,
    lng: -87.6137011,
    squareFootage: "67940",
    propertyType: "Multifamily",
    totalCosts: "16500000",
    valueOfReclassifiedAssets: 6504529,
    depreciableAssets: 23,
  },
  {
    lat: 38.9071042,
    lng: -94.8728093,
    squareFootage: "38587",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7295321",
    valueOfReclassifiedAssets: 2469785,
    depreciableAssets: 37,
  },
  {
    lat: 39.8140227,
    lng: -94.9558696,
    squareFootage: "19104",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2552000",
    valueOfReclassifiedAssets: 729698,
    depreciableAssets: 34,
  },
  {
    lat: 42.1970343,
    lng: -88.0935013,
    squareFootage: "43658",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14823766",
    valueOfReclassifiedAssets: 4314749,
    depreciableAssets: 32,
  },
  {
    lat: 37.0441957,
    lng: -113.5490415,
    squareFootage: "2017",
    propertyType: "Specialty",
    totalCosts: "9143889",
    valueOfReclassifiedAssets: 2433420,
    depreciableAssets: 29,
  },
  {
    lat: 41.23403690000001,
    lng: -96.11559319999999,
    squareFootage: "59084",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8250025",
    valueOfReclassifiedAssets: 2276413,
    depreciableAssets: 30,
  },
  {
    lat: 42.1857312,
    lng: -87.80584019999999,
    squareFootage: "65991",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19000000",
    valueOfReclassifiedAssets: 4203293,
    depreciableAssets: 24,
  },
  {
    lat: 38.8817227,
    lng: -94.5985613,
    squareFootage: "56010",
    propertyType: "Multifamily",
    totalCosts: "13358972",
    valueOfReclassifiedAssets: 3544677,
    depreciableAssets: 27,
  },
  {
    lat: 40.14568990000001,
    lng: -86.2361744,
    squareFootage: "27400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3331732",
    valueOfReclassifiedAssets: 854629,
    depreciableAssets: 29,
  },
  {
    lat: 39.8833139,
    lng: -95.2475518,
    squareFootage: "14786",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1980000",
    valueOfReclassifiedAssets: 493613,
    depreciableAssets: 29,
  },
  {
    lat: 41.7228282,
    lng: -87.70248169999999,
    squareFootage: "82212",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13200000",
    valueOfReclassifiedAssets: 3507914,
    depreciableAssets: 31,
  },
  {
    lat: 39.0632021,
    lng: -94.3462373,
    squareFootage: "61095",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11251379",
    valueOfReclassifiedAssets: 3081809,
    depreciableAssets: 32,
  },
  {
    lat: 38.4326732,
    lng: -95.2057909,
    squareFootage: "27000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1616000",
    valueOfReclassifiedAssets: 474068,
    depreciableAssets: 35,
  },
  {
    lat: 39.0063606,
    lng: -94.7381013,
    squareFootage: "53900",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1444704,
    depreciableAssets: 23,
  },
  {
    lat: 42.0421545,
    lng: -87.79108910000001,
    squareFootage: "30740",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6600000",
    valueOfReclassifiedAssets: 2731793,
    depreciableAssets: 20,
  },
  {
    lat: 41.5349127,
    lng: -90.5588787,
    squareFootage: "36500",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4746951",
    valueOfReclassifiedAssets: 1392658,
    depreciableAssets: 35,
  },
  {
    lat: 37.8355754,
    lng: -113.1441558,
    squareFootage: "2017",
    propertyType: "Specialty",
    totalCosts: "9143889",
    valueOfReclassifiedAssets: 2920729,
    depreciableAssets: 35,
  },
  {
    lat: 41.7228282,
    lng: -87.70248169999999,
    squareFootage: "82212",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 5637719,
    depreciableAssets: 31,
  },
  {
    lat: 46.4547129,
    lng: -87.7001071,
    squareFootage: "34400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3683738",
    valueOfReclassifiedAssets: 1084518,
    depreciableAssets: 32,
  },
  {
    lat: 37.7071863,
    lng: -97.4327273,
    squareFootage: "50000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7856000",
    valueOfReclassifiedAssets: 2461817,
    depreciableAssets: 37,
  },
  {
    lat: 41.6099344,
    lng: -93.596457,
    squareFootage: "40936",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3903049",
    valueOfReclassifiedAssets: 801327,
    depreciableAssets: 24,
  },
  {
    lat: 39.1024788,
    lng: -94.4079213,
    squareFootage: "73070",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12351631",
    valueOfReclassifiedAssets: 3538163,
    depreciableAssets: 34,
  },
  {
    lat: 42.0341144,
    lng: -87.759112,
    squareFootage: "35600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11167498",
    valueOfReclassifiedAssets: 8464132,
    depreciableAssets: 28,
  },
  {
    lat: 39.0100292,
    lng: -85.62292959999999,
    squareFootage: "43229",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7688611",
    valueOfReclassifiedAssets: 1989384,
    depreciableAssets: 29,
  },
  {
    lat: 42.1272261,
    lng: -87.84515270000001,
    squareFootage: "31463",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 1835621,
    depreciableAssets: 32,
  },
  {
    lat: 39.1476313,
    lng: -95.9621106,
    squareFootage: "27349",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2256000",
    valueOfReclassifiedAssets: 545486,
    depreciableAssets: 29,
  },
  {
    lat: 39.7184481,
    lng: -94.2538452,
    squareFootage: "37216",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4612955",
    valueOfReclassifiedAssets: 1354405,
    depreciableAssets: 35,
  },
  {
    lat: 39.5267931,
    lng: -104.670443,
    squareFootage: "66758",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13486223",
    valueOfReclassifiedAssets: 2451050,
    depreciableAssets: 20,
  },
  {
    lat: 41.9003099,
    lng: -87.63338710000001,
    squareFootage: "151200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "35750000",
    valueOfReclassifiedAssets: 10765542,
    depreciableAssets: 19,
  },
  {
    lat: 38.7984122,
    lng: -94.4593791,
    squareFootage: "78327",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5501147",
    valueOfReclassifiedAssets: 1310817,
    depreciableAssets: 28,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "71850",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20025000",
    valueOfReclassifiedAssets: 3322051,
    depreciableAssets: 18,
  },
  {
    lat: 38.94506680000001,
    lng: -94.5779191,
    squareFootage: "25368",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1241463,
    depreciableAssets: 27,
  },
  {
    lat: 41.9003099,
    lng: -87.63338710000001,
    squareFootage: "151200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "35750000",
    valueOfReclassifiedAssets: 6172301,
    depreciableAssets: 19,
  },
  {
    lat: 42.4424515,
    lng: -87.84515270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1589138",
    valueOfReclassifiedAssets: 1186387,
    depreciableAssets: 27,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "25593",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5247000",
    valueOfReclassifiedAssets: 1301968,
    depreciableAssets: 27,
  },
  {
    lat: 42.0099321,
    lng: -87.663045,
    squareFootage: "71850",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20572397",
    valueOfReclassifiedAssets: 3421286,
    depreciableAssets: 18,
  },
  {
    lat: 40.96734989999999,
    lng: -85.1375645,
    squareFootage: "19870",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2562891",
    valueOfReclassifiedAssets: 853629,
    depreciableAssets: 37,
  },
  {
    lat: 42.0421545,
    lng: -87.79108910000001,
    squareFootage: "92175",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16900000",
    valueOfReclassifiedAssets: 4334059,
    depreciableAssets: 29,
  },
  {
    lat: 39.9350959,
    lng: -84.1224036,
    squareFootage: "68534",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15940000",
    valueOfReclassifiedAssets: 5578231,
    depreciableAssets: 37,
  },
  {
    lat: 38.76571819999999,
    lng: -77.4811474,
    squareFootage: "93047",
    propertyType: "Retail",
    totalCosts: "32250000",
    valueOfReclassifiedAssets: 6944368,
    depreciableAssets: 25,
  },
  {
    lat: 38.76571819999999,
    lng: -77.4811474,
    squareFootage: "132870",
    propertyType: "Retail",
    totalCosts: "43200000",
    valueOfReclassifiedAssets: 12374041,
    depreciableAssets: 34,
  },
  {
    lat: 38.76571819999999,
    lng: -77.4811474,
    squareFootage: "93027",
    propertyType: "Retail",
    totalCosts: "33525000",
    valueOfReclassifiedAssets: 7994365,
    depreciableAssets: 28,
  },
  {
    lat: 38.6805859,
    lng: -77.3209555,
    squareFootage: "85896",
    propertyType: "Retail",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 2903772,
    depreciableAssets: 26,
  },
  {
    lat: 38.9860548,
    lng: -76.745195,
    squareFootage: "189920",
    propertyType: "Retail",
    totalCosts: "49100000",
    valueOfReclassifiedAssets: 14033103,
    depreciableAssets: 34,
  },
  {
    lat: 39.0533891,
    lng: -76.9758274,
    squareFootage: "41261",
    propertyType: "Retail",
    totalCosts: "27880000",
    valueOfReclassifiedAssets: 9933918,
    depreciableAssets: 42,
  },
  {
    lat: 39.6268957,
    lng: -77.7337628,
    squareFootage: "73600",
    propertyType: "Office",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 1933638,
    depreciableAssets: 24,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "52076",
    propertyType: "Multifamily",
    totalCosts: "1028723",
    valueOfReclassifiedAssets: 858520,
    depreciableAssets: 26,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "52076",
    propertyType: "Multifamily",
    totalCosts: "3738989",
    valueOfReclassifiedAssets: 3120369,
    depreciableAssets: 26,
  },
  {
    lat: 39.9008411,
    lng: -85.9611082,
    squareFootage: "60604",
    propertyType: "Retail",
    totalCosts: "8962119",
    valueOfReclassifiedAssets: 2626240,
    depreciableAssets: 34,
  },
  {
    lat: 40.155426,
    lng: -74.8828754,
    squareFootage: "25622",
    propertyType: "Retail",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 577443,
    depreciableAssets: 33,
  },
  {
    lat: 40.09944249999999,
    lng: -74.9325683,
    squareFootage: "66451",
    propertyType: "Retail",
    totalCosts: "7883948",
    valueOfReclassifiedAssets: 2246120,
    depreciableAssets: 33,
  },
  {
    lat: 39.8923742,
    lng: -75.3266416,
    squareFootage: "13430",
    propertyType: "Office",
    totalCosts: "2764240",
    valueOfReclassifiedAssets: 2462700,
    depreciableAssets: 22,
  },
  {
    lat: 35.7178049,
    lng: -78.9175088,
    squareFootage: "22500",
    propertyType: "Retail",
    totalCosts: "3078865",
    valueOfReclassifiedAssets: 812351,
    depreciableAssets: 33,
  },
  {
    lat: 39.7379842,
    lng: -86.15991489999999,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 1389411,
    depreciableAssets: 16,
  },
  {
    lat: 39.3541593,
    lng: -85.96668460000001,
    squareFootage: "85062",
    propertyType: "Retail",
    totalCosts: "11694379",
    valueOfReclassifiedAssets: 2608403,
    depreciableAssets: 26,
  },
  {
    lat: 39.9645049,
    lng: -86.0223611,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3854295",
    valueOfReclassifiedAssets: 1234890,
    depreciableAssets: 37,
  },
  {
    lat: 41.1740315,
    lng: -81.521241,
    squareFootage: "76358",
    propertyType: "Retail",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2830860,
    depreciableAssets: 32,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "70177",
    propertyType: "Retail",
    totalCosts: "10720000",
    valueOfReclassifiedAssets: 3776668,
    depreciableAssets: 41,
  },
  {
    lat: 37.52938450000001,
    lng: -82.5185837,
    squareFootage: "65759",
    propertyType: "Retail",
    totalCosts: "9680104",
    valueOfReclassifiedAssets: 2977085,
    depreciableAssets: 39,
  },
  {
    lat: 37.52938450000001,
    lng: -82.5185837,
    squareFootage: "79003",
    propertyType: "Retail",
    totalCosts: "6083683",
    valueOfReclassifiedAssets: 5461212,
    depreciableAssets: 0,
  },
  {
    lat: 37.52938450000001,
    lng: -82.5185837,
    squareFootage: "79003",
    propertyType: "Retail",
    totalCosts: "6083683",
    valueOfReclassifiedAssets: 5461212,
    depreciableAssets: 0,
  },
  {
    lat: 37.52938450000001,
    lng: -82.5185837,
    squareFootage: "65759",
    propertyType: "Retail",
    totalCosts: "9680104",
    valueOfReclassifiedAssets: 2977085,
    depreciableAssets: 39,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "70177",
    propertyType: "Retail",
    totalCosts: "10720000",
    valueOfReclassifiedAssets: 3776668,
    depreciableAssets: 41,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "120765",
    propertyType: "Retail",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 3023269,
    depreciableAssets: 24,
  },
  {
    lat: 33.9041552,
    lng: -117.9307584,
    squareFootage: "87467",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11103777",
    valueOfReclassifiedAssets: 2392299,
    depreciableAssets: 25,
  },
  {
    lat: 32.8324351,
    lng: -116.8792553,
    squareFootage: "28645",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 1325102,
    depreciableAssets: 33,
  },
  {
    lat: 34.167305,
    lng: -118.3987842,
    squareFootage: "54427",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14875000",
    valueOfReclassifiedAssets: 3442505,
    depreciableAssets: 27,
  },
  {
    lat: 40.8134774,
    lng: -124.1460963,
    squareFootage: "26388",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1987684",
    valueOfReclassifiedAssets: 503010,
    depreciableAssets: 30,
  },
  {
    lat: 37.6063621,
    lng: -122.1178261,
    squareFootage: "26698",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7059991",
    valueOfReclassifiedAssets: 2438370,
    depreciableAssets: 41,
  },
  {
    lat: 40.7306343,
    lng: -124.082682,
    squareFootage: "25980",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6100366",
    valueOfReclassifiedAssets: 1386213,
    depreciableAssets: 26,
  },
  {
    lat: 40.60718050000001,
    lng: -124.1132746,
    squareFootage: "34705",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7290427",
    valueOfReclassifiedAssets: 1988990,
    depreciableAssets: 32,
  },
  {
    lat: 40.8134774,
    lng: -124.1460963,
    squareFootage: "19629",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1204044",
    valueOfReclassifiedAssets: 382462,
    depreciableAssets: 37,
  },
  {
    lat: 34.1175895,
    lng: -118.188329,
    squareFootage: "13550",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3700000",
    valueOfReclassifiedAssets: 1151060,
    depreciableAssets: 36,
  },
  {
    lat: 33.8922687,
    lng: -118.3520389,
    squareFootage: "16729",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3009364",
    valueOfReclassifiedAssets: 907553,
    depreciableAssets: 35,
  },
  {
    lat: 40.7306343,
    lng: -124.082682,
    squareFootage: "26221",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1986379",
    valueOfReclassifiedAssets: 618074,
    depreciableAssets: 36,
  },
  {
    lat: 34.1103407,
    lng: -118.2585096,
    squareFootage: "28350",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10170000",
    valueOfReclassifiedAssets: 2018237,
    depreciableAssets: 23,
  },
  {
    lat: 37.8321173,
    lng: -122.2625529,
    squareFootage: "29424",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5542341.02",
    valueOfReclassifiedAssets: 1579182,
    depreciableAssets: 34,
  },
  {
    lat: 34.1657707,
    lng: -118.1181199,
    squareFootage: "11107",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 719553,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "68375",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1176459,
    depreciableAssets: 22,
  },
  {
    lat: 33.9041552,
    lng: -117.9307584,
    squareFootage: "87467",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2477665,
    depreciableAssets: 25,
  },
  {
    lat: 37.5685247,
    lng: -122.367428,
    squareFootage: "80233",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16068157",
    valueOfReclassifiedAssets: 3067673,
    depreciableAssets: 22,
  },
  {
    lat: 32.7657318,
    lng: -117.199996,
    squareFootage: "30571",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11142262",
    valueOfReclassifiedAssets: 1814000,
    depreciableAssets: 19,
  },
  {
    lat: 36.1295416,
    lng: -115.1325897,
    squareFootage: "31338",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1191511,
    depreciableAssets: 26,
  },
  {
    lat: 34.0238187,
    lng: -118.153228,
    squareFootage: "27372",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5950000",
    valueOfReclassifiedAssets: 1187396,
    depreciableAssets: 24,
  },
  {
    lat: 34.0837958,
    lng: -118.1181199,
    squareFootage: "24422",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5579869",
    valueOfReclassifiedAssets: 1868545,
    depreciableAssets: 39,
  },
  {
    lat: 34.1657707,
    lng: -118.1181199,
    squareFootage: "7920",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 488376,
    depreciableAssets: 24,
  },
  {
    lat: 33.82353,
    lng: -117.9659037,
    squareFootage: "37288",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 4595070,
    depreciableAssets: 41,
  },
  {
    lat: 34.1232517,
    lng: -118.0830047,
    squareFootage: "22939",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7877462",
    valueOfReclassifiedAssets: 2109816,
    depreciableAssets: 31,
  },
  {
    lat: 39.7461315,
    lng: -121.8448745,
    squareFootage: "28980",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1929195,
    depreciableAssets: 33,
  },
  {
    lat: 38.7424319,
    lng: -121.2511908,
    squareFootage: "21941",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5460376",
    valueOfReclassifiedAssets: 1752522,
    depreciableAssets: 38,
  },
  {
    lat: 37.7347438,
    lng: -122.2143323,
    squareFootage: "18116",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1287048,
    depreciableAssets: 30,
  },
  {
    lat: 32.799475,
    lng: -96.78687939999999,
    squareFootage: "62252",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 2483947,
    depreciableAssets: 23,
  },
  {
    lat: 34.1175895,
    lng: -118.188329,
    squareFootage: "22542",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5350109",
    valueOfReclassifiedAssets: 1548310,
    depreciableAssets: 34,
  },
  {
    lat: 37.9759027,
    lng: -122.3447611,
    squareFootage: "27620",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6016048",
    valueOfReclassifiedAssets: 1884749,
    depreciableAssets: 37,
  },
  {
    lat: 34.1232517,
    lng: -118.0830047,
    squareFootage: "20724",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8192560",
    valueOfReclassifiedAssets: 2760393,
    depreciableAssets: 40,
  },
  {
    lat: 37.8015517,
    lng: -122.2143323,
    squareFootage: "25078",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9480000",
    valueOfReclassifiedAssets: 2224329,
    depreciableAssets: 28,
  },
  {
    lat: 37.9650627,
    lng: -122.503327,
    squareFootage: "16217",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 784956,
    depreciableAssets: 29,
  },
  {
    lat: 34.06173649999999,
    lng: -118.0830047,
    squareFootage: "24500",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 1308318,
    depreciableAssets: 40,
  },
  {
    lat: 34.0567006,
    lng: -84.3436881,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6163900",
    valueOfReclassifiedAssets: 4894694,
    depreciableAssets: 29,
  },
  {
    lat: 29.58857609999999,
    lng: -98.2760021,
    squareFootage: "261141",
    propertyType: "Multifamily",
    totalCosts: "37850000",
    valueOfReclassifiedAssets: 10810008,
    depreciableAssets: 34,
  },
  {
    lat: 29.7049806,
    lng: -98.06834300000001,
    squareFootage: "146950",
    propertyType: "Multifamily",
    totalCosts: "26850000",
    valueOfReclassifiedAssets: 8161762,
    depreciableAssets: 36,
  },
  {
    lat: 41.8101721,
    lng: -73.90070899999999,
    squareFootage: "56480",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 690464,
    depreciableAssets: 23,
  },
  {
    lat: 37.4664427,
    lng: -77.46283249999999,
    squareFootage: "57830",
    propertyType: "Retail",
    totalCosts: "4430000",
    valueOfReclassifiedAssets: 1138724,
    depreciableAssets: 30,
  },
  {
    lat: 32.8166692,
    lng: -96.8833562,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "1171443",
    valueOfReclassifiedAssets: 192578,
    depreciableAssets: 19,
  },
  {
    lat: 32.8166692,
    lng: -96.8833562,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "3533170",
    valueOfReclassifiedAssets: 580832,
    depreciableAssets: 19,
  },
  {
    lat: 32.8166692,
    lng: -96.8833562,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "11792400",
    valueOfReclassifiedAssets: 1938600,
    depreciableAssets: 19,
  },
  {
    lat: 40.2631879,
    lng: -77.17152279999999,
    squareFootage: "173078",
    propertyType: "Retail",
    totalCosts: "30431070",
    valueOfReclassifiedAssets: 28347667,
    depreciableAssets: 0,
  },
  {
    lat: 37.5834425,
    lng: -77.4185584,
    squareFootage: "78493",
    propertyType: "Retail",
    totalCosts: "8007732",
    valueOfReclassifiedAssets: 1937105,
    depreciableAssets: 27,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2738417",
    valueOfReclassifiedAssets: 2215371,
    depreciableAssets: 0,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3626235",
    valueOfReclassifiedAssets: 3048004,
    depreciableAssets: 0,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6180161",
    valueOfReclassifiedAssets: 5561089,
    depreciableAssets: 11,
  },
  {
    lat: 32.590481,
    lng: -83.624201,
    squareFootage: "502867",
    propertyType: "Multifamily",
    totalCosts: "22531875",
    valueOfReclassifiedAssets: 5136910,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1590000",
    valueOfReclassifiedAssets: 402192,
    depreciableAssets: 30,
  },
  {
    lat: 30.4587407,
    lng: -90.13077969999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1590375",
    valueOfReclassifiedAssets: 1030221,
    depreciableAssets: 69,
  },
  {
    lat: 35.89872340000001,
    lng: -86.96240859999999,
    squareFootage: "2381",
    propertyType: "Specialty",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 455497,
    depreciableAssets: 39,
  },
  {
    lat: 30.3790174,
    lng: -86.3427458,
    squareFootage: "2441",
    propertyType: "Multifamily",
    totalCosts: "515000",
    valueOfReclassifiedAssets: 134484,
    depreciableAssets: 31,
  },
  {
    lat: 30.40029639999999,
    lng: -86.4895463,
    squareFootage: "2572",
    propertyType: "Multifamily",
    totalCosts: "812700",
    valueOfReclassifiedAssets: 206346,
    depreciableAssets: 26,
  },
  {
    lat: 29.8897474,
    lng: -84.6688348,
    squareFootage: "14430",
    propertyType: "Retail",
    totalCosts: "3360000",
    valueOfReclassifiedAssets: 564545,
    depreciableAssets: 0,
  },
  {
    lat: 30.5380847,
    lng: -90.5920027,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1387293",
    valueOfReclassifiedAssets: 1166334,
    depreciableAssets: 36,
  },
  {
    lat: 30.40029639999999,
    lng: -86.4895463,
    squareFootage: "2613",
    propertyType: "Multifamily",
    totalCosts: "924486",
    valueOfReclassifiedAssets: 258169,
    depreciableAssets: 29,
  },
  {
    lat: 30.40029639999999,
    lng: -86.4895463,
    squareFootage: "3200",
    propertyType: "Multifamily",
    totalCosts: "805000",
    valueOfReclassifiedAssets: 204590,
    depreciableAssets: 27,
  },
  {
    lat: 27.4872771,
    lng: -82.5889012,
    squareFootage: "86466",
    propertyType: "Multifamily",
    totalCosts: "4348537",
    valueOfReclassifiedAssets: 1538995,
    depreciableAssets: 37,
  },
  {
    lat: 38.0434058,
    lng: -121.2969674,
    squareFootage: "41922",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8908445",
    valueOfReclassifiedAssets: 1794519,
    depreciableAssets: 23,
  },
  {
    lat: 37.8655663,
    lng: -122.0666997,
    squareFootage: "48791",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16974020",
    valueOfReclassifiedAssets: 3199883,
    depreciableAssets: 21,
  },
  {
    lat: 42.7189172,
    lng: -71.4893013,
    squareFootage: "91467",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21070332",
    valueOfReclassifiedAssets: 4411754,
    depreciableAssets: 24,
  },
  {
    lat: 36.8400852,
    lng: -76.1493891,
    squareFootage: "256759",
    propertyType: "Multifamily",
    totalCosts: "19070098",
    valueOfReclassifiedAssets: 4432847,
    depreciableAssets: 26,
  },
  {
    lat: 37.7929789,
    lng: -122.4212424,
    squareFootage: "55700",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16417797",
    valueOfReclassifiedAssets: 2525789,
    depreciableAssets: 18,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "29930",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9585671",
    valueOfReclassifiedAssets: 1482830,
    depreciableAssets: 19,
  },
  {
    lat: 42.2638905,
    lng: -71.6080009,
    squareFootage: "34961",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2155172",
    valueOfReclassifiedAssets: 433695,
    depreciableAssets: 23,
  },
  {
    lat: 41.7212425,
    lng: -70.1515342,
    squareFootage: "58500",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3321362",
    valueOfReclassifiedAssets: 676752,
    depreciableAssets: 23,
  },
  {
    lat: 37.7877522,
    lng: -122.4382307,
    squareFootage: "30736",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11393821",
    valueOfReclassifiedAssets: 1565729,
    depreciableAssets: 16,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "36160",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9398261",
    valueOfReclassifiedAssets: 1175513,
    depreciableAssets: 14,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "16043",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7146312",
    valueOfReclassifiedAssets: 1197109,
    depreciableAssets: 20,
  },
  {
    lat: 36.6623815,
    lng: -121.6454222,
    squareFootage: "45657",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "28296086",
    valueOfReclassifiedAssets: 5370774,
    depreciableAssets: 21,
  },
  {
    lat: 42.4312595,
    lng: -83.483215,
    squareFootage: "33814",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8718337",
    valueOfReclassifiedAssets: 2201910,
    depreciableAssets: 28,
  },
  {
    lat: 37.6536947,
    lng: -121.6739371,
    squareFootage: "20213",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8625247",
    valueOfReclassifiedAssets: 1524751,
    depreciableAssets: 20,
  },
  {
    lat: 30.4332061,
    lng: -97.60057859999999,
    squareFootage: "348714",
    propertyType: "Multifamily",
    totalCosts: "43866434",
    valueOfReclassifiedAssets: 11758609,
    depreciableAssets: 34,
  },
  {
    lat: 40.5747108,
    lng: -74.6458987,
    squareFootage: "296524",
    propertyType: "Multifamily",
    totalCosts: "56337625",
    valueOfReclassifiedAssets: 9663896,
    depreciableAssets: 21,
  },
  {
    lat: 40.5747108,
    lng: -74.6458987,
    squareFootage: "296524",
    propertyType: "Multifamily",
    totalCosts: "56737625",
    valueOfReclassifiedAssets: 9732510,
    depreciableAssets: 21,
  },
  {
    lat: 30.4332061,
    lng: -97.60057859999999,
    squareFootage: "348714",
    propertyType: "Multifamily",
    totalCosts: "3176535",
    valueOfReclassifiedAssets: 851485,
    depreciableAssets: 34,
  },
  {
    lat: 39.7380371,
    lng: -105.0265195,
    squareFootage: "541341",
    propertyType: "Multifamily",
    totalCosts: "102670959",
    valueOfReclassifiedAssets: 12512724,
    depreciableAssets: 15,
  },
  {
    lat: 29.422658,
    lng: -98.48699049999999,
    squareFootage: "294183",
    propertyType: "Multifamily",
    totalCosts: "74336160",
    valueOfReclassifiedAssets: 11580711,
    depreciableAssets: 20,
  },
  {
    lat: 39.6849237,
    lng: -105.1609838,
    squareFootage: "164198",
    propertyType: "Multifamily",
    totalCosts: "38558452",
    valueOfReclassifiedAssets: 11240200,
    depreciableAssets: 37,
  },
  {
    lat: 30.2259488,
    lng: -97.7145152,
    squareFootage: "342605",
    propertyType: "Multifamily",
    totalCosts: "38055235",
    valueOfReclassifiedAssets: 8736927,
    depreciableAssets: 29,
  },
  {
    lat: 39.8182494,
    lng: -105.0674317,
    squareFootage: "300600",
    propertyType: "Multifamily",
    totalCosts: "65370095",
    valueOfReclassifiedAssets: 16575690,
    depreciableAssets: 32,
  },
  {
    lat: 30.2259488,
    lng: -97.7145152,
    squareFootage: "342605",
    propertyType: "Multifamily",
    totalCosts: "38500000",
    valueOfReclassifiedAssets: 9454199,
    depreciableAssets: 29,
  },
  {
    lat: 33.69856499999999,
    lng: -84.54318669999999,
    squareFootage: "434737",
    propertyType: "Multifamily",
    totalCosts: "32205612",
    valueOfReclassifiedAssets: 4606436,
    depreciableAssets: 19,
  },
  {
    lat: 40.6311567,
    lng: -74.1386767,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1201903",
    valueOfReclassifiedAssets: 1184280,
    depreciableAssets: 5,
  },
  {
    lat: 30.4332061,
    lng: -97.60057859999999,
    squareFootage: "78000",
    propertyType: "Multifamily",
    totalCosts: "7031001",
    valueOfReclassifiedAssets: 1758866,
    depreciableAssets: 30,
  },
  {
    lat: 38.9359831,
    lng: -76.4910093,
    squareFootage: "532373",
    propertyType: "Multifamily",
    totalCosts: "105000000",
    valueOfReclassifiedAssets: 25080970,
    depreciableAssets: 28,
  },
  {
    lat: 26.7153474,
    lng: -80.0533253,
    squareFootage: "245263",
    propertyType: "Multifamily",
    totalCosts: "63727139",
    valueOfReclassifiedAssets: 11820032,
    depreciableAssets: 23,
  },
  {
    lat: 27.6856656,
    lng: -97.41109879999999,
    squareFootage: "194927",
    propertyType: "Multifamily",
    totalCosts: "22736123",
    valueOfReclassifiedAssets: 4957586,
    depreciableAssets: 26,
  },
  {
    lat: 41.6931543,
    lng: -87.7123355,
    squareFootage: "393244",
    propertyType: "Multifamily",
    totalCosts: "29712566",
    valueOfReclassifiedAssets: 4549238,
    depreciableAssets: 20,
  },
  {
    lat: 30.1274614,
    lng: -97.730807,
    squareFootage: "400000",
    propertyType: "Multifamily",
    totalCosts: "52611627",
    valueOfReclassifiedAssets: 16974057,
    depreciableAssets: 35,
  },
  {
    lat: 30.4332061,
    lng: -97.60057859999999,
    squareFootage: "356580",
    propertyType: "Multifamily",
    totalCosts: "26895900",
    valueOfReclassifiedAssets: 6306747,
    depreciableAssets: 28,
  },
  {
    lat: 38.9359831,
    lng: -76.4910093,
    squareFootage: "532373",
    propertyType: "Multifamily",
    totalCosts: "107024014",
    valueOfReclassifiedAssets: 24060648,
    depreciableAssets: 28,
  },
  {
    lat: 27.6474296,
    lng: -97.3624598,
    squareFootage: "270000",
    propertyType: "Multifamily",
    totalCosts: "36754120",
    valueOfReclassifiedAssets: 10146575,
    depreciableAssets: 30,
  },
  {
    lat: 27.6856656,
    lng: -97.41109879999999,
    squareFootage: "194927",
    propertyType: "Multifamily",
    totalCosts: "22636123",
    valueOfReclassifiedAssets: 4935781,
    depreciableAssets: 26,
  },
  {
    lat: 37.1120848,
    lng: -76.5372625,
    squareFootage: "312697",
    propertyType: "Multifamily",
    totalCosts: "31312288",
    valueOfReclassifiedAssets: 7046041,
    depreciableAssets: 26,
  },
  {
    lat: 37.1120848,
    lng: -76.5372625,
    squareFootage: "312697",
    propertyType: "Multifamily",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 6975769,
    depreciableAssets: 26,
  },
  {
    lat: 37.1120848,
    lng: -76.5372625,
    squareFootage: "312697",
    propertyType: "Multifamily",
    totalCosts: "31312288",
    valueOfReclassifiedAssets: 7046041,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "22500",
    propertyType: "Office",
    totalCosts: "4003360",
    valueOfReclassifiedAssets: 729027,
    depreciableAssets: 23,
  },
  {
    lat: 28.5107872,
    lng: -81.4828198,
    squareFootage: "21426",
    propertyType: "Office",
    totalCosts: "1519700",
    valueOfReclassifiedAssets: 407550,
    depreciableAssets: 31,
  },
  {
    lat: 28.5107872,
    lng: -81.4828198,
    squareFootage: "21426",
    propertyType: "Office",
    totalCosts: "3030300",
    valueOfReclassifiedAssets: 812660,
    depreciableAssets: 31,
  },
  {
    lat: 27.905178,
    lng: -82.7158302,
    squareFootage: "59514",
    propertyType: "Office",
    totalCosts: "10150000",
    valueOfReclassifiedAssets: 2358094,
    depreciableAssets: 28,
  },
  {
    lat: 45.1308865,
    lng: -122.8348938,
    squareFootage: "22500",
    propertyType: "Office",
    totalCosts: "4003360",
    valueOfReclassifiedAssets: 729027,
    depreciableAssets: 23,
  },
  {
    lat: 40.1145913,
    lng: -85.37562489999999,
    squareFootage: "32176",
    propertyType: "Retail",
    totalCosts: "2165150",
    valueOfReclassifiedAssets: 1527677,
    depreciableAssets: 42,
  },
  {
    lat: 33.81842,
    lng: -84.3594626,
    squareFootage: "64198",
    propertyType: "Office",
    totalCosts: "7278766",
    valueOfReclassifiedAssets: 1572743,
    depreciableAssets: 25,
  },
  {
    lat: 35.9989632,
    lng: -78.5887951,
    squareFootage: "78993",
    propertyType: "Retail",
    totalCosts: "14034178",
    valueOfReclassifiedAssets: 5541909,
    depreciableAssets: 47,
  },
  {
    lat: 41.0543785,
    lng: -92.4208713,
    squareFootage: "68849",
    propertyType: "Office",
    totalCosts: "7904079",
    valueOfReclassifiedAssets: 6693899,
    depreciableAssets: 24,
  },
  {
    lat: 45.1308865,
    lng: -122.8348938,
    squareFootage: "22500",
    propertyType: "Office",
    totalCosts: "1493511",
    valueOfReclassifiedAssets: 1270947,
    depreciableAssets: 23,
  },
  {
    lat: 29.0620369,
    lng: -95.4882679,
    squareFootage: "1000",
    propertyType: "Retail",
    totalCosts: "2563155",
    valueOfReclassifiedAssets: 668782,
    depreciableAssets: 37,
  },
  {
    lat: 39.0135831,
    lng: -95.63002329999999,
    squareFootage: "17090",
    propertyType: "Industrial",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 168230,
    depreciableAssets: 13,
  },
  {
    lat: 33.5349253,
    lng: -112.1847509,
    squareFootage: "72660",
    propertyType: "Multifamily",
    totalCosts: "11750000",
    valueOfReclassifiedAssets: 2926224,
    depreciableAssets: 29,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "3875",
    propertyType: "Specialty",
    totalCosts: "817590",
    valueOfReclassifiedAssets: 559072,
    depreciableAssets: 0,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "20000",
    propertyType: "Office",
    totalCosts: "3233368",
    valueOfReclassifiedAssets: 980998,
    depreciableAssets: 41,
  },
  {
    lat: 40.9238588,
    lng: -74.30595459999999,
    squareFootage: "189083",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "66550000",
    valueOfReclassifiedAssets: 12829523,
    depreciableAssets: 14,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11966870",
    valueOfReclassifiedAssets: 9905529,
    depreciableAssets: 18,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "91769",
    propertyType: "Retail",
    totalCosts: "33500000",
    valueOfReclassifiedAssets: 5716144,
    depreciableAssets: 21,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "48000",
    propertyType: "Office",
    totalCosts: "11670578",
    valueOfReclassifiedAssets: 10474033,
    depreciableAssets: 11,
  },
  {
    lat: 41.1567039,
    lng: -81.6254283,
    squareFootage: "510231",
    propertyType: "Retail",
    totalCosts: "32500000",
    valueOfReclassifiedAssets: 4828137,
    depreciableAssets: 17,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "76212",
    propertyType: "Multifamily",
    totalCosts: "5973058",
    valueOfReclassifiedAssets: 950073,
    depreciableAssets: 18,
  },
  {
    lat: 41.5254822,
    lng: -72.7688226,
    squareFootage: "54983",
    propertyType: "Retail",
    totalCosts: "44500000",
    valueOfReclassifiedAssets: 9362301,
    depreciableAssets: 25,
  },
  {
    lat: 40.6812166,
    lng: -73.7772032,
    squareFootage: "85900",
    propertyType: "Hotel",
    totalCosts: "13451094",
    valueOfReclassifiedAssets: 11069244,
    depreciableAssets: 22,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "38150",
    propertyType: "Multifamily",
    totalCosts: "6644559",
    valueOfReclassifiedAssets: 1030604,
    depreciableAssets: 18,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "48166",
    propertyType: "Multifamily",
    totalCosts: "11956476",
    valueOfReclassifiedAssets: 1404152,
    depreciableAssets: 12,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "36416",
    propertyType: "Multifamily",
    totalCosts: "9347815",
    valueOfReclassifiedAssets: 1565039,
    depreciableAssets: 17,
  },
  {
    lat: 41.3561962,
    lng: -87.5988876,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6729837",
    valueOfReclassifiedAssets: 5761950,
    depreciableAssets: 19,
  },
  {
    lat: 40.9097631,
    lng: -88.59517509999999,
    squareFootage: "40216",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 2082626,
    depreciableAssets: 39,
  },
  {
    lat: 43.1666739,
    lng: -87.9824758,
    squareFootage: "73176",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5029544",
    valueOfReclassifiedAssets: 1204120,
    depreciableAssets: 25,
  },
  {
    lat: 30.346791,
    lng: -81.6418656,
    squareFootage: "36634",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2535143",
    valueOfReclassifiedAssets: 1034264,
    depreciableAssets: 44,
  },
  {
    lat: 41.6729486,
    lng: -87.962884,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7681901",
    valueOfReclassifiedAssets: 6546126,
    depreciableAssets: 19,
  },
  {
    lat: 38.94506680000001,
    lng: -94.5779191,
    squareFootage: "58069",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1700722,
    depreciableAssets: 25,
  },
  {
    lat: 41.4362974,
    lng: -87.5988876,
    squareFootage: "87105",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 5366414,
    depreciableAssets: 27,
  },
  {
    lat: 32.2540946,
    lng: -110.8850949,
    squareFootage: "135937",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7912261",
    valueOfReclassifiedAssets: 1278007,
    depreciableAssets: 17,
  },
  {
    lat: 32.2540946,
    lng: -110.8850949,
    squareFootage: "109977",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12750000",
    valueOfReclassifiedAssets: 3674715,
    depreciableAssets: 24,
  },
  {
    lat: 38.1116209,
    lng: -97.9374948,
    squareFootage: "37304",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2438176",
    valueOfReclassifiedAssets: 2099508,
    depreciableAssets: 25,
  },
  {
    lat: 30.346791,
    lng: -81.6418656,
    squareFootage: "36634",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2535143",
    valueOfReclassifiedAssets: 1034264,
    depreciableAssets: 44,
  },
  {
    lat: 30.346791,
    lng: -81.6418656,
    squareFootage: "36634",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2535143",
    valueOfReclassifiedAssets: 1034264,
    depreciableAssets: 44,
  },
  {
    lat: 44.2837065,
    lng: -88.37249299999999,
    squareFootage: "71093",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 1352655,
    depreciableAssets: 30,
  },
  {
    lat: 41.4362974,
    lng: -87.5988876,
    squareFootage: "87105",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 5366414,
    depreciableAssets: 27,
  },
  {
    lat: 40.1029399,
    lng: -90.563609,
    squareFootage: "46354",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1721027",
    valueOfReclassifiedAssets: 489243,
    depreciableAssets: 32,
  },
  {
    lat: 40.9692854,
    lng: -74.74541119999999,
    squareFootage: "45048",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18346153",
    valueOfReclassifiedAssets: 6524705,
    depreciableAssets: 39,
  },
  {
    lat: 41.5443613,
    lng: -87.50990569999999,
    squareFootage: "100988",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 2539465,
    depreciableAssets: 24,
  },
  {
    lat: 40.6569929,
    lng: -74.30595459999999,
    squareFootage: "75000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10530049",
    valueOfReclassifiedAssets: 2919165,
    depreciableAssets: 27,
  },
  {
    lat: 39.0841668,
    lng: -86.6006249,
    squareFootage: "296160",
    propertyType: "Multifamily",
    totalCosts: "54425000",
    valueOfReclassifiedAssets: 13611578,
    depreciableAssets: 30,
  },
  {
    lat: 40.5747108,
    lng: -74.6458987,
    squareFootage: "56030",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8055056",
    valueOfReclassifiedAssets: 2296132,
    depreciableAssets: 28,
  },
  {
    lat: 41.5443613,
    lng: -87.50990569999999,
    squareFootage: "100988",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 3153866,
    depreciableAssets: 24,
  },
  {
    lat: 42.2751923,
    lng: -89.6628111,
    squareFootage: "52235",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3046801",
    valueOfReclassifiedAssets: 635590,
    depreciableAssets: 22,
  },
  {
    lat: 40.1059233,
    lng: -88.2120315,
    squareFootage: "399312",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23072760",
    valueOfReclassifiedAssets: 4861881,
    depreciableAssets: 22,
  },
  {
    lat: 41.47019890000001,
    lng: -87.32149079999999,
    squareFootage: "37620",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4640000",
    valueOfReclassifiedAssets: 1342916,
    depreciableAssets: 31,
  },
  {
    lat: 41.1009158,
    lng: -74.69858909999999,
    squareFootage: "121260",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "62653847",
    valueOfReclassifiedAssets: 18642249,
    depreciableAssets: 33,
  },
  {
    lat: 40.4370815,
    lng: -86.1039189,
    squareFootage: "33684",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5310219",
    valueOfReclassifiedAssets: 1591142,
    depreciableAssets: 31,
  },
  {
    lat: 42.3463503,
    lng: -71.1626756,
    squareFootage: "9752",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1998000",
    valueOfReclassifiedAssets: 1196383,
    depreciableAssets: 51,
  },
  {
    lat: 38.7994436,
    lng: -90.20571079999999,
    squareFootage: "169745",
    propertyType: "Multifamily",
    totalCosts: "12750791",
    valueOfReclassifiedAssets: 3722013,
    depreciableAssets: 31,
  },
  {
    lat: 41.18767769999999,
    lng: -87.2419299,
    squareFootage: "37095",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4120000",
    valueOfReclassifiedAssets: 962568,
    depreciableAssets: 30,
  },
  {
    lat: 42.3463503,
    lng: -71.1626756,
    squareFootage: "9752",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2014062",
    valueOfReclassifiedAssets: 948558,
    depreciableAssets: 51,
  },
  {
    lat: 42.638141,
    lng: -87.82550069999999,
    squareFootage: "28483",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4528934",
    valueOfReclassifiedAssets: 1298167,
    depreciableAssets: 30,
  },
  {
    lat: 41.5812093,
    lng: -87.3711477,
    squareFootage: "53610",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8240000",
    valueOfReclassifiedAssets: 2148823,
    depreciableAssets: 31,
  },
  {
    lat: 42.638141,
    lng: -87.82550069999999,
    squareFootage: "28483",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4528934",
    valueOfReclassifiedAssets: 1298167,
    depreciableAssets: 30,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "6632",
    propertyType: "Multifamily",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 303492,
    depreciableAssets: 17,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "4125",
    propertyType: "Multifamily",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 204671,
    depreciableAssets: 18,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1004008",
    valueOfReclassifiedAssets: 801863,
    depreciableAssets: 23,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "4125",
    propertyType: "Multifamily",
    totalCosts: "1774000",
    valueOfReclassifiedAssets: 208478,
    depreciableAssets: 13,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "153715",
    propertyType: "Retail",
    totalCosts: "32671216",
    valueOfReclassifiedAssets: 7560817,
    depreciableAssets: 34,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "13357",
    propertyType: "Specialty",
    totalCosts: "5908856",
    valueOfReclassifiedAssets: 768557,
    depreciableAssets: 15,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "16603",
    propertyType: "Specialty",
    totalCosts: "11460711",
    valueOfReclassifiedAssets: 2816459,
    depreciableAssets: 27,
  },
  {
    lat: 40.6274394,
    lng: -74.0946311,
    squareFootage: "8927",
    propertyType: "Office",
    totalCosts: "1665581",
    valueOfReclassifiedAssets: 1532815,
    depreciableAssets: 13,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "8193",
    propertyType: "Retail",
    totalCosts: "1161716",
    valueOfReclassifiedAssets: 837692,
    depreciableAssets: 28,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "8193",
    propertyType: "Retail",
    totalCosts: "1098004",
    valueOfReclassifiedAssets: 278254,
    depreciableAssets: 28,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "14145",
    propertyType: "Retail",
    totalCosts: "1998283",
    valueOfReclassifiedAssets: 1905716,
    depreciableAssets: 5,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "14145",
    propertyType: "Retail",
    totalCosts: "732649",
    valueOfReclassifiedAssets: 25223,
    depreciableAssets: 5,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "14145",
    propertyType: "Retail",
    totalCosts: "2121891",
    valueOfReclassifiedAssets: 89597,
    depreciableAssets: 5,
  },
  {
    lat: 40.6655101,
    lng: -73.8918897,
    squareFootage: "8193",
    propertyType: "Retail",
    totalCosts: "379120",
    valueOfReclassifiedAssets: 78334,
    depreciableAssets: 28,
  },
  {
    lat: 40.21145610000001,
    lng: -74.0079781,
    squareFootage: "130000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18250000",
    valueOfReclassifiedAssets: 14586031,
    depreciableAssets: 23,
  },
  {
    lat: 40.21145610000001,
    lng: -74.0079781,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "164000",
    valueOfReclassifiedAssets: 26492,
    depreciableAssets: 0,
  },
  {
    lat: 40.21145610000001,
    lng: -74.0079781,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "132000",
    valueOfReclassifiedAssets: 18253,
    depreciableAssets: 0,
  },
  {
    lat: 40.21145610000001,
    lng: -74.0079781,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "172500",
    valueOfReclassifiedAssets: 38087,
    depreciableAssets: 0,
  },
  {
    lat: 39.1511679,
    lng: -76.6239423,
    squareFootage: "459606",
    propertyType: "Multifamily",
    totalCosts: "76250000",
    valueOfReclassifiedAssets: 17767781,
    depreciableAssets: 26,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "369304",
    propertyType: "Multifamily",
    totalCosts: "64590876",
    valueOfReclassifiedAssets: 11075446,
    depreciableAssets: 20,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "369304",
    propertyType: "Multifamily",
    totalCosts: "63200000",
    valueOfReclassifiedAssets: 10836951,
    depreciableAssets: 20,
  },
  {
    lat: 30.3424249,
    lng: -81.84429340000001,
    squareFootage: "64736",
    propertyType: "Retail",
    totalCosts: "3377885",
    valueOfReclassifiedAssets: 764767,
    depreciableAssets: 29,
  },
  {
    lat: 30.4312586,
    lng: -81.8005724,
    squareFootage: "72728",
    propertyType: "Retail",
    totalCosts: "8094017",
    valueOfReclassifiedAssets: 1799909,
    depreciableAssets: 30,
  },
  {
    lat: 30.3476884,
    lng: -81.7458845,
    squareFootage: "81467",
    propertyType: "Retail",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1398432,
    depreciableAssets: 33,
  },
  {
    lat: 30.2018746,
    lng: -81.614468,
    squareFootage: "68846",
    propertyType: "Retail",
    totalCosts: "5691246",
    valueOfReclassifiedAssets: 1051870,
    depreciableAssets: 23,
  },
  {
    lat: 27.7254716,
    lng: -82.3560389,
    squareFootage: "75100",
    propertyType: "Retail",
    totalCosts: "5174058",
    valueOfReclassifiedAssets: 1099147,
    depreciableAssets: 28,
  },
  {
    lat: 30.3476884,
    lng: -81.7458845,
    squareFootage: "81467",
    propertyType: "Retail",
    totalCosts: "5375410",
    valueOfReclassifiedAssets: 1445736,
    depreciableAssets: 33,
  },
  {
    lat: 26.5838179,
    lng: -80.0590804,
    squareFootage: "164980",
    propertyType: "Retail",
    totalCosts: "10513096",
    valueOfReclassifiedAssets: 2530541,
    depreciableAssets: 33,
  },
  {
    lat: 40.29470269999999,
    lng: -73.9888797,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 162700,
    depreciableAssets: 23,
  },
  {
    lat: 41.0050736,
    lng: -72.5559746,
    squareFootage: "11900",
    propertyType: "Office",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 363468,
    depreciableAssets: 25,
  },
  {
    lat: 26.7619563,
    lng: -80.1037721,
    squareFootage: "2400",
    propertyType: "Retail",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 1329619,
    depreciableAssets: 58,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "2329999",
    valueOfReclassifiedAssets: 607208,
    depreciableAssets: 27,
  },
  {
    lat: 38.8682133,
    lng: -76.79135579999999,
    squareFootage: "149795",
    propertyType: "Office",
    totalCosts: "17784904",
    valueOfReclassifiedAssets: 3069487,
    depreciableAssets: 21,
  },
  {
    lat: 39.167568,
    lng: -76.5892774,
    squareFootage: "123750",
    propertyType: "Multifamily",
    totalCosts: "16550000",
    valueOfReclassifiedAssets: 4129200,
    depreciableAssets: 32,
  },
  {
    lat: 36.025609,
    lng: -78.9853813,
    squareFootage: "103900",
    propertyType: "Multifamily",
    totalCosts: "8350000",
    valueOfReclassifiedAssets: 2511942,
    depreciableAssets: 35,
  },
  {
    lat: 35.2277973,
    lng: -81.1085744,
    squareFootage: "273600",
    propertyType: "Multifamily",
    totalCosts: "13190015",
    valueOfReclassifiedAssets: 4060695,
    depreciableAssets: 36,
  },
  {
    lat: 33.828105,
    lng: -84.3279093,
    squareFootage: "155461",
    propertyType: "Multifamily",
    totalCosts: "11520000",
    valueOfReclassifiedAssets: 2251840,
    depreciableAssets: 23,
  },
  {
    lat: 36.0775432,
    lng: -86.7315785,
    squareFootage: "197787",
    propertyType: "Multifamily",
    totalCosts: "18000000",
    valueOfReclassifiedAssets: 5360122,
    depreciableAssets: 35,
  },
  {
    lat: 33.8992049,
    lng: -84.3016021,
    squareFootage: "281642",
    propertyType: "Multifamily",
    totalCosts: "18400000",
    valueOfReclassifiedAssets: 4315615,
    depreciableAssets: 28,
  },
  {
    lat: 35.5873834,
    lng: -82.49147909999999,
    squareFootage: "101861",
    propertyType: "Multifamily",
    totalCosts: "14800000",
    valueOfReclassifiedAssets: 4803293,
    depreciableAssets: 38,
  },
  {
    lat: 35.2413572,
    lng: -80.7103532,
    squareFootage: "219628",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 3648609,
    depreciableAssets: 34,
  },
  {
    lat: 33.828105,
    lng: -84.3279093,
    squareFootage: "150976",
    propertyType: "Multifamily",
    totalCosts: "12200000",
    valueOfReclassifiedAssets: 2583279,
    depreciableAssets: 25,
  },
  {
    lat: 35.8150476,
    lng: -78.57744079999999,
    squareFootage: "47022",
    propertyType: "Multifamily",
    totalCosts: "3175000",
    valueOfReclassifiedAssets: 708674,
    depreciableAssets: 26,
  },
  {
    lat: 33.7709887,
    lng: -84.29633919999999,
    squareFootage: "195944",
    propertyType: "Multifamily",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 4636620,
    depreciableAssets: 29,
  },
  {
    lat: 33.7972391,
    lng: -84.0273168,
    squareFootage: "117606",
    propertyType: "Multifamily",
    totalCosts: "6550000",
    valueOfReclassifiedAssets: 1994618,
    depreciableAssets: 36,
  },
  {
    lat: 33.8507937,
    lng: -84.21734029999999,
    squareFootage: "241380",
    propertyType: "Multifamily",
    totalCosts: "13450000",
    valueOfReclassifiedAssets: 2506318,
    depreciableAssets: 21,
  },
  {
    lat: 37.31317,
    lng: -122.0723816,
    squareFootage: "118200",
    propertyType: "Multifamily",
    totalCosts: "52910000",
    valueOfReclassifiedAssets: 17869384,
    depreciableAssets: 39,
  },
  {
    lat: 41.98250650000001,
    lng: -73.9888797,
    squareFootage: "198029",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "32309013",
    valueOfReclassifiedAssets: 27236442,
    depreciableAssets: 22,
  },
  {
    lat: 41.45930389999999,
    lng: -72.7806422,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2156201",
    valueOfReclassifiedAssets: 2071516,
    depreciableAssets: 42,
  },
  {
    lat: 41.0598251,
    lng: -74.0123851,
    squareFootage: "75600",
    propertyType: "Multifamily",
    totalCosts: "13141478",
    valueOfReclassifiedAssets: 2281707,
    depreciableAssets: 21,
  },
  {
    lat: 40.9541697,
    lng: -74.0652616,
    squareFootage: "6370",
    propertyType: "Retail",
    totalCosts: "2416487",
    valueOfReclassifiedAssets: 300646,
    depreciableAssets: 19,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3383971",
    valueOfReclassifiedAssets: 1787446,
    depreciableAssets: 0,
  },
  {
    lat: 41.0598251,
    lng: -74.0123851,
    squareFootage: "215619",
    propertyType: "Multifamily",
    totalCosts: "35160447",
    valueOfReclassifiedAssets: 7894144,
    depreciableAssets: 28,
  },
  {
    lat: 40.3635408,
    lng: -74.0887576,
    squareFootage: "4400",
    propertyType: "Office",
    totalCosts: "1338464",
    valueOfReclassifiedAssets: 1196003,
    depreciableAssets: 17,
  },
  {
    lat: 38.9963501,
    lng: -76.97006689999999,
    squareFootage: "548620",
    propertyType: "Multifamily",
    totalCosts: "13128471",
    valueOfReclassifiedAssets: 11340236,
    depreciableAssets: 17,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 4758798,
    depreciableAssets: 14,
  },
  {
    lat: 40.8038325,
    lng: -112.0237903,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2847613",
    valueOfReclassifiedAssets: 2523313,
    depreciableAssets: 23,
  },
  {
    lat: 40.7445526,
    lng: -74.1533563,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7676891",
    valueOfReclassifiedAssets: 6062609,
    depreciableAssets: 30,
  },
  {
    lat: 40.7218318,
    lng: -74.0447003,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29210699",
    valueOfReclassifiedAssets: 26281490,
    depreciableAssets: 11,
  },
  {
    lat: 40.9541697,
    lng: -74.0652616,
    squareFootage: "6370",
    propertyType: "Retail",
    totalCosts: "2416487",
    valueOfReclassifiedAssets: 300646,
    depreciableAssets: 19,
  },
  {
    lat: 40.9111613,
    lng: -74.1621634,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1934800",
    valueOfReclassifiedAssets: 1522658,
    depreciableAssets: 22,
  },
  {
    lat: 38.9963501,
    lng: -76.97006689999999,
    squareFootage: "548620",
    propertyType: "Multifamily",
    totalCosts: "1908227",
    valueOfReclassifiedAssets: 1648306,
    depreciableAssets: 17,
  },
  {
    lat: 40.3038147,
    lng: -74.0887576,
    squareFootage: "85226",
    propertyType: "Multifamily",
    totalCosts: "3354509",
    valueOfReclassifiedAssets: 2845137,
    depreciableAssets: 22,
  },
  {
    lat: 40.7218318,
    lng: -74.0447003,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25718257",
    valueOfReclassifiedAssets: 21614811,
    depreciableAssets: 18,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "64000",
    propertyType: "Multifamily",
    totalCosts: "1386104",
    valueOfReclassifiedAssets: 1078552,
    depreciableAssets: 29,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "280000",
    propertyType: "Multifamily",
    totalCosts: "40655346",
    valueOfReclassifiedAssets: 7891230,
    depreciableAssets: 27,
  },
  {
    lat: 40.1101484,
    lng: -74.8624092,
    squareFootage: "373680",
    propertyType: "Multifamily",
    totalCosts: "11927240",
    valueOfReclassifiedAssets: 3236366,
    depreciableAssets: 31,
  },
  {
    lat: 40.9694387,
    lng: -73.9301037,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1072250",
    valueOfReclassifiedAssets: 889132,
    depreciableAssets: 23,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "24196636",
    valueOfReclassifiedAssets: 10173065,
    depreciableAssets: 27,
  },
  {
    lat: 40.4333407,
    lng: -74.41152459999999,
    squareFootage: "10531",
    propertyType: "Retail",
    totalCosts: "2294198",
    valueOfReclassifiedAssets: 2149405,
    depreciableAssets: 35,
  },
  {
    lat: 32.2165669,
    lng: -110.8195292,
    squareFootage: "217382",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 3604123,
    depreciableAssets: 29,
  },
  {
    lat: 32.5704036,
    lng: -96.76010570000001,
    squareFootage: "331920",
    propertyType: "Multifamily",
    totalCosts: "23300000",
    valueOfReclassifiedAssets: 4724897,
    depreciableAssets: 24,
  },
  {
    lat: 37.4308503,
    lng: -121.9529992,
    squareFootage: "141392",
    propertyType: "Multifamily",
    totalCosts: "12800000",
    valueOfReclassifiedAssets: 4231929,
    depreciableAssets: 39,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "264784",
    propertyType: "Multifamily",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 7315220,
    depreciableAssets: 42,
  },
  {
    lat: 30.154741,
    lng: -92.037116,
    squareFootage: "247100",
    propertyType: "Multifamily",
    totalCosts: "22550000",
    valueOfReclassifiedAssets: 5301268,
    depreciableAssets: 27,
  },
  {
    lat: 30.3291747,
    lng: -87.39099569999999,
    squareFootage: "126272",
    propertyType: "Multifamily",
    totalCosts: "8900000",
    valueOfReclassifiedAssets: 2189590,
    depreciableAssets: 29,
  },
  {
    lat: 30.5327462,
    lng: -87.2120599,
    squareFootage: "373310",
    propertyType: "Multifamily",
    totalCosts: "28850000",
    valueOfReclassifiedAssets: 7738780,
    depreciableAssets: 32,
  },
  {
    lat: 42.6081355,
    lng: -83.11127979999999,
    squareFootage: "105000",
    propertyType: "Office",
    totalCosts: "6550316",
    valueOfReclassifiedAssets: 1199809,
    depreciableAssets: 20,
  },
  {
    lat: 40.7452159,
    lng: -73.5711912,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5515141",
    valueOfReclassifiedAssets: 3500409,
    depreciableAssets: 43,
  },
  {
    lat: 32.9221152,
    lng: -80.0367259,
    squareFootage: "77578",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1307745,
    depreciableAssets: 30,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "57564",
    propertyType: "Multifamily",
    totalCosts: "3132000",
    valueOfReclassifiedAssets: 714742,
    depreciableAssets: 27,
  },
  {
    lat: 33.9812131,
    lng: -84.15406589999999,
    squareFootage: "226105",
    propertyType: "Multifamily",
    totalCosts: "16892000",
    valueOfReclassifiedAssets: 4704272,
    depreciableAssets: 33,
  },
  {
    lat: 33.8215697,
    lng: -84.5536678,
    squareFootage: "290024",
    propertyType: "Multifamily",
    totalCosts: "22794000",
    valueOfReclassifiedAssets: 6629697,
    depreciableAssets: 34,
  },
  {
    lat: 33.7646686,
    lng: -84.7837717,
    squareFootage: "202191",
    propertyType: "Multifamily",
    totalCosts: "10648257",
    valueOfReclassifiedAssets: 2650041,
    depreciableAssets: 29,
  },
  {
    lat: 33.8507937,
    lng: -84.21734029999999,
    squareFootage: "239250",
    propertyType: "Multifamily",
    totalCosts: "13150000",
    valueOfReclassifiedAssets: 2268752,
    depreciableAssets: 20,
  },
  {
    lat: 33.8484195,
    lng: -84.2858121,
    squareFootage: "378923",
    propertyType: "Multifamily",
    totalCosts: "24368305",
    valueOfReclassifiedAssets: 20278673,
    depreciableAssets: 23,
  },
  {
    lat: 33.9102261,
    lng: -84.1804384,
    squareFootage: "451217",
    propertyType: "Multifamily",
    totalCosts: "20185000",
    valueOfReclassifiedAssets: 3478782,
    depreciableAssets: 20,
  },
  {
    lat: 33.8019475,
    lng: -84.24368489999999,
    squareFootage: "52650",
    propertyType: "Multifamily",
    totalCosts: "2297370",
    valueOfReclassifiedAssets: 1803200,
    depreciableAssets: 32,
  },
  {
    lat: 33.9812131,
    lng: -84.15406589999999,
    squareFootage: "68378",
    propertyType: "Multifamily",
    totalCosts: "5112000",
    valueOfReclassifiedAssets: 1395665,
    depreciableAssets: 32,
  },
  {
    lat: 33.9448436,
    lng: -83.4323375,
    squareFootage: "202400",
    propertyType: "Multifamily",
    totalCosts: "10018063",
    valueOfReclassifiedAssets: 2127624,
    depreciableAssets: 25,
  },
  {
    lat: 32.9221152,
    lng: -80.0367259,
    squareFootage: "72300",
    propertyType: "Multifamily",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 2490599,
    depreciableAssets: 31,
  },
  {
    lat: 33.9957883,
    lng: -84.46976629999999,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "52000000",
    valueOfReclassifiedAssets: 12569228,
    depreciableAssets: 28,
  },
  {
    lat: 33.2831586,
    lng: -84.2489525,
    squareFootage: "117182",
    propertyType: "Multifamily",
    totalCosts: "6163026",
    valueOfReclassifiedAssets: 1654747,
    depreciableAssets: 32,
  },
  {
    lat: 34.2041173,
    lng: -84.17516479999999,
    squareFootage: "37696",
    propertyType: "Multifamily",
    totalCosts: "4135000",
    valueOfReclassifiedAssets: 1504324,
    depreciableAssets: 43,
  },
  {
    lat: 33.8019475,
    lng: -84.24368489999999,
    squareFootage: "364015",
    propertyType: "Multifamily",
    totalCosts: "17526000",
    valueOfReclassifiedAssets: 4080098,
    depreciableAssets: 27,
  },
  {
    lat: 33.7344959,
    lng: -84.1118458,
    squareFootage: "168538",
    propertyType: "Multifamily",
    totalCosts: "7656000",
    valueOfReclassifiedAssets: 1988096,
    depreciableAssets: 31,
  },
  {
    lat: 33.8680523,
    lng: -84.1118458,
    squareFootage: "286512",
    propertyType: "Multifamily",
    totalCosts: "22400000",
    valueOfReclassifiedAssets: 6684986,
    depreciableAssets: 35,
  },
  {
    lat: 33.8680523,
    lng: -84.1118458,
    squareFootage: "133272",
    propertyType: "Multifamily",
    totalCosts: "7063940",
    valueOfReclassifiedAssets: 5316458,
    depreciableAssets: 37,
  },
  {
    lat: 33.8992049,
    lng: -84.3016021,
    squareFootage: "220767",
    propertyType: "Multifamily",
    totalCosts: "14289000",
    valueOfReclassifiedAssets: 2903011,
    depreciableAssets: 24,
  },
  {
    lat: 33.8507937,
    lng: -84.21734029999999,
    squareFootage: "239250",
    propertyType: "Multifamily",
    totalCosts: "9850057",
    valueOfReclassifiedAssets: 8363337,
    depreciableAssets: 20,
  },
  {
    lat: 34.8742328,
    lng: -82.33433749999999,
    squareFootage: "129010",
    propertyType: "Multifamily",
    totalCosts: "5228241",
    valueOfReclassifiedAssets: 1738090,
    depreciableAssets: 39,
  },
  {
    lat: 34.00684500000001,
    lng: -83.3468784,
    squareFootage: "159896",
    propertyType: "Multifamily",
    totalCosts: "10250000",
    valueOfReclassifiedAssets: 3497044,
    depreciableAssets: 40,
  },
  {
    lat: 34.1805506,
    lng: -83.4856913,
    squareFootage: "164760",
    propertyType: "Retail",
    totalCosts: "6059000",
    valueOfReclassifiedAssets: 1640371,
    depreciableAssets: 32,
  },
  {
    lat: 33.2831586,
    lng: -84.2489525,
    squareFootage: "90300",
    propertyType: "Multifamily",
    totalCosts: "3711428",
    valueOfReclassifiedAssets: 852763,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "68674",
    propertyType: "Multifamily",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 1290229,
    depreciableAssets: 29,
  },
  {
    lat: 33.8484195,
    lng: -84.2858121,
    squareFootage: "378923",
    propertyType: "Multifamily",
    totalCosts: "28616000",
    valueOfReclassifiedAssets: 5573736,
    depreciableAssets: 23,
  },
  {
    lat: 36.1857974,
    lng: -79.8184684,
    squareFootage: "2018",
    propertyType: "Multifamily",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 1117385,
    depreciableAssets: 40,
  },
  {
    lat: 33.296831,
    lng: -82.06250519999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6115867",
    valueOfReclassifiedAssets: 1525702,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "196170",
    propertyType: "Multifamily",
    totalCosts: "10534000",
    valueOfReclassifiedAssets: 2647074,
    depreciableAssets: 29,
  },
  {
    lat: 33.7344959,
    lng: -84.1118458,
    squareFootage: "136008",
    propertyType: "Multifamily",
    totalCosts: "8114743",
    valueOfReclassifiedAssets: 2195910,
    depreciableAssets: 32,
  },
  {
    lat: 33.9488147,
    lng: -84.53794549999999,
    squareFootage: "103281",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 907392,
    depreciableAssets: 24,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "147800",
    propertyType: "Multifamily",
    totalCosts: "6659102",
    valueOfReclassifiedAssets: 5427507,
    depreciableAssets: 24,
  },
  {
    lat: 33.029838,
    lng: -85.14793639999999,
    squareFootage: "150880",
    propertyType: "Multifamily",
    totalCosts: "8575000",
    valueOfReclassifiedAssets: 2126590,
    depreciableAssets: 29,
  },
  {
    lat: 33.4988059,
    lng: -84.0801613,
    squareFootage: "60650",
    propertyType: "Multifamily",
    totalCosts: "3075000",
    valueOfReclassifiedAssets: 845966,
    depreciableAssets: 32,
  },
  {
    lat: 33.9508571,
    lng: -83.985008,
    squareFootage: "223068",
    propertyType: "Multifamily",
    totalCosts: "14910000",
    valueOfReclassifiedAssets: 3085568,
    depreciableAssets: 24,
  },
  {
    lat: 39.7158773,
    lng: -105.2311827,
    squareFootage: "670513",
    propertyType: "Multifamily",
    totalCosts: "44000000",
    valueOfReclassifiedAssets: 7346968,
    depreciableAssets: 19,
  },
  {
    lat: 33.296831,
    lng: -82.06250519999999,
    squareFootage: "139012",
    propertyType: "Multifamily",
    totalCosts: "7316478",
    valueOfReclassifiedAssets: 1747025,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "121200",
    propertyType: "Multifamily",
    totalCosts: "1660573",
    valueOfReclassifiedAssets: 441490,
    depreciableAssets: 31,
  },
  {
    lat: 33.9488147,
    lng: -84.53794549999999,
    squareFootage: "261500",
    propertyType: "Multifamily",
    totalCosts: "13420000",
    valueOfReclassifiedAssets: 3156256,
    depreciableAssets: 28,
  },
  {
    lat: 33.2831586,
    lng: -84.2489525,
    squareFootage: "59392",
    propertyType: "Multifamily",
    totalCosts: "3733906",
    valueOfReclassifiedAssets: 960413,
    depreciableAssets: 30,
  },
  {
    lat: 34.319379,
    lng: -83.81547909999999,
    squareFootage: "93300",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1070762,
    depreciableAssets: 25,
  },
  {
    lat: 33.936899,
    lng: -84.2752831,
    squareFootage: "294893",
    propertyType: "Multifamily",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 3500967,
    depreciableAssets: 21,
  },
  {
    lat: 33.7695269,
    lng: -84.26475219999999,
    squareFootage: "155494",
    propertyType: "Multifamily",
    totalCosts: "9616000",
    valueOfReclassifiedAssets: 2382316,
    depreciableAssets: 29,
  },
  {
    lat: 33.029838,
    lng: -85.14793639999999,
    squareFootage: "153630",
    propertyType: "Multifamily",
    totalCosts: "9175000",
    valueOfReclassifiedAssets: 2297448,
    depreciableAssets: 30,
  },
  {
    lat: 30.3918615,
    lng: -89.0322699,
    squareFootage: "94756",
    propertyType: "Multifamily",
    totalCosts: "5996578",
    valueOfReclassifiedAssets: 1306740,
    depreciableAssets: 25,
  },
  {
    lat: 33.8019475,
    lng: -84.24368489999999,
    squareFootage: "477692",
    propertyType: "Multifamily",
    totalCosts: "20292988",
    valueOfReclassifiedAssets: 17339658,
    depreciableAssets: 20,
  },
  {
    lat: 33.6447519,
    lng: -117.6845508,
    squareFootage: "280943",
    propertyType: "Retail",
    totalCosts: "122559164",
    valueOfReclassifiedAssets: 29216691,
    depreciableAssets: 30,
  },
  {
    lat: 39.3668892,
    lng: -74.54046939999999,
    squareFootage: "13146",
    propertyType: "Office",
    totalCosts: "3135745",
    valueOfReclassifiedAssets: 716594,
    depreciableAssets: 27,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "46008",
    propertyType: "Multifamily",
    totalCosts: "4199000",
    valueOfReclassifiedAssets: 3536710,
    depreciableAssets: 17,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "44000",
    propertyType: "Multifamily",
    totalCosts: "5452520",
    valueOfReclassifiedAssets: 4612158,
    depreciableAssets: 16,
  },
  {
    lat: 45.5507889,
    lng: -122.5057403,
    squareFootage: "54680",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 929611,
    depreciableAssets: 24,
  },
  {
    lat: 45.5161585,
    lng: -122.5566479,
    squareFootage: "34500",
    propertyType: "Multifamily",
    totalCosts: "2162978",
    valueOfReclassifiedAssets: 1754063,
    depreciableAssets: 24,
  },
  {
    lat: 28.0600145,
    lng: -80.621591,
    squareFootage: "93776",
    propertyType: "Multifamily",
    totalCosts: "4626018",
    valueOfReclassifiedAssets: 1036017,
    depreciableAssets: 32,
  },
  {
    lat: 32.3029712,
    lng: -88.59517509999999,
    squareFootage: "466670",
    propertyType: "Industrial",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 1306099,
    depreciableAssets: 12,
  },
  {
    lat: 29.9853746,
    lng: -90.2526163,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17402093",
    valueOfReclassifiedAssets: 2864069,
    depreciableAssets: 20,
  },
  {
    lat: 38.7699175,
    lng: -90.4667501,
    squareFootage: "200000",
    propertyType: "Industrial",
    totalCosts: "10033854",
    valueOfReclassifiedAssets: 1633224,
    depreciableAssets: 17,
  },
  {
    lat: 38.7699175,
    lng: -90.4667501,
    squareFootage: "170000",
    propertyType: "Industrial",
    totalCosts: "8720035",
    valueOfReclassifiedAssets: 1562357,
    depreciableAssets: 19,
  },
  {
    lat: 38.767401,
    lng: -90.44080369999999,
    squareFootage: "556287",
    propertyType: "Industrial",
    totalCosts: "20502144",
    valueOfReclassifiedAssets: 3193459,
    depreciableAssets: 18,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "10146",
    propertyType: "Retail",
    totalCosts: "1055600",
    valueOfReclassifiedAssets: 903271,
    depreciableAssets: 21,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "855353",
    valueOfReclassifiedAssets: 675775,
    depreciableAssets: 30,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "15540",
    propertyType: "Industrial",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 356369,
    depreciableAssets: 19,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "4070",
    propertyType: "Specialty",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 381774,
    depreciableAssets: 36,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "93500",
    propertyType: "Multifamily",
    totalCosts: "6400000",
    valueOfReclassifiedAssets: 1529316,
    depreciableAssets: 27,
  },
  {
    lat: 41.6757457,
    lng: -72.86336349999999,
    squareFootage: "20060",
    propertyType: "Retail",
    totalCosts: "5567015",
    valueOfReclassifiedAssets: 1179467,
    depreciableAssets: 24,
  },
  {
    lat: 41.71738500000001,
    lng: -74.7688173,
    squareFootage: "130966",
    propertyType: "Retail",
    totalCosts: "8856309",
    valueOfReclassifiedAssets: 1775805,
    depreciableAssets: 21,
  },
  {
    lat: 41.1339818,
    lng: -73.4062342,
    squareFootage: "35778",
    propertyType: "Office",
    totalCosts: "6553099",
    valueOfReclassifiedAssets: 1386664,
    depreciableAssets: 31,
  },
  {
    lat: 41.1339818,
    lng: -73.4062342,
    squareFootage: "20174",
    propertyType: "Retail",
    totalCosts: "10550000",
    valueOfReclassifiedAssets: 1578114,
    depreciableAssets: 17,
  },
  {
    lat: 40.7881249,
    lng: -73.19396119999999,
    squareFootage: "57591",
    propertyType: "Retail",
    totalCosts: "8865117",
    valueOfReclassifiedAssets: 8373786,
    depreciableAssets: 46,
  },
  {
    lat: 37.0362625,
    lng: -76.4736602,
    squareFootage: "434216",
    propertyType: "Multifamily",
    totalCosts: "17415000",
    valueOfReclassifiedAssets: 5262932,
    depreciableAssets: 36,
  },
  {
    lat: 36.9138353,
    lng: -76.2826675,
    squareFootage: "247568",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 2160331,
    depreciableAssets: 24,
  },
  {
    lat: 37.0538822,
    lng: -76.39845369999999,
    squareFootage: "280330",
    propertyType: "Multifamily",
    totalCosts: "11800000",
    valueOfReclassifiedAssets: 2983959,
    depreciableAssets: 30,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "125852",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1742761,
    depreciableAssets: 30,
  },
  {
    lat: 36.8400852,
    lng: -76.1493891,
    squareFootage: "257500",
    propertyType: "Multifamily",
    totalCosts: "18000000",
    valueOfReclassifiedAssets: 4541830,
    depreciableAssets: 30,
  },
  {
    lat: 37.5044419,
    lng: -77.46446069999999,
    squareFootage: "93712",
    propertyType: "Multifamily",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 1627318,
    depreciableAssets: 30,
  },
  {
    lat: 41.7606321,
    lng: -88.1437782,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "27366082",
    valueOfReclassifiedAssets: 20476223,
    depreciableAssets: 29,
  },
  {
    lat: 37.9461207,
    lng: -122.0212337,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 509130,
    depreciableAssets: 15,
  },
  {
    lat: 26.6608604,
    lng: -80.1596041,
    squareFootage: "4635",
    propertyType: "Office",
    totalCosts: "1237662",
    valueOfReclassifiedAssets: 889427,
    depreciableAssets: 32,
  },
  {
    lat: 29.6451476,
    lng: -95.5249863,
    squareFootage: "2017",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1939250",
    valueOfReclassifiedAssets: 703030,
    depreciableAssets: 43,
  },
  {
    lat: 26.1514248,
    lng: -80.22655449999999,
    squareFootage: "3105",
    propertyType: "Office",
    totalCosts: "500000",
    valueOfReclassifiedAssets: 110717,
    depreciableAssets: 44,
  },
  {
    lat: 35.5843376,
    lng: -78.8000278,
    squareFootage: "107689",
    propertyType: "Retail",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 2119171,
    depreciableAssets: 34,
  },
  {
    lat: 36.3301314,
    lng: -80.7990185,
    squareFootage: "30900",
    propertyType: "Retail",
    totalCosts: "3626395",
    valueOfReclassifiedAssets: 3016328,
    depreciableAssets: 44,
  },
  {
    lat: 33.7070405,
    lng: -78.8948741,
    squareFootage: "47640",
    propertyType: "Retail",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 792224,
    depreciableAssets: 21,
  },
  {
    lat: 34.1920258,
    lng: -79.2676668,
    squareFootage: "24016",
    propertyType: "Retail",
    totalCosts: "2332000",
    valueOfReclassifiedAssets: 1974270,
    depreciableAssets: 41,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5084",
    propertyType: "Specialty",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 611445,
    depreciableAssets: 48,
  },
  {
    lat: 34.06170609999999,
    lng: -118.2789735,
    squareFootage: "24304",
    propertyType: "Office",
    totalCosts: "1245581",
    valueOfReclassifiedAssets: 1104747,
    depreciableAssets: 12,
  },
  {
    lat: 34.06170609999999,
    lng: -118.2789735,
    squareFootage: "24304",
    propertyType: "Office",
    totalCosts: "1245581",
    valueOfReclassifiedAssets: 1082124,
    depreciableAssets: 14,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "30000",
    propertyType: "Multifamily",
    totalCosts: "2825000",
    valueOfReclassifiedAssets: 245402,
    depreciableAssets: 10,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "7950",
    propertyType: "Multifamily",
    totalCosts: "2975000",
    valueOfReclassifiedAssets: 305788,
    depreciableAssets: 12,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "60133",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 699014,
    depreciableAssets: 15,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "29035",
    propertyType: "Multifamily",
    totalCosts: "2844431",
    valueOfReclassifiedAssets: 2306478,
    depreciableAssets: 0,
  },
  {
    lat: 39.4787827,
    lng: -75.03775019999999,
    squareFootage: "32800",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 813141,
    depreciableAssets: 29,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "48750",
    propertyType: "Multifamily",
    totalCosts: "5240000",
    valueOfReclassifiedAssets: 654673,
    depreciableAssets: 14,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "45160",
    propertyType: "Multifamily",
    totalCosts: "5525000",
    valueOfReclassifiedAssets: 4996900,
    depreciableAssets: 12,
  },
  {
    lat: 40.7235275,
    lng: -74.197388,
    squareFootage: "8000",
    propertyType: "Multifamily",
    totalCosts: "1583993",
    valueOfReclassifiedAssets: 136802,
    depreciableAssets: 10,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "48272",
    propertyType: "Multifamily",
    totalCosts: "5675000",
    valueOfReclassifiedAssets: 674151,
    depreciableAssets: 14,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "6408",
    propertyType: "Multifamily",
    totalCosts: "2348888",
    valueOfReclassifiedAssets: 272197,
    depreciableAssets: 13,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "52764",
    propertyType: "Multifamily",
    totalCosts: "6240000",
    valueOfReclassifiedAssets: 438991,
    depreciableAssets: 9,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "95730",
    propertyType: "Multifamily",
    totalCosts: "21650000",
    valueOfReclassifiedAssets: 2311584,
    depreciableAssets: 13,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "35000",
    propertyType: "Multifamily",
    totalCosts: "3365000",
    valueOfReclassifiedAssets: 424430,
    depreciableAssets: 15,
  },
  {
    lat: 40.7235275,
    lng: -74.197388,
    squareFootage: "14332",
    propertyType: "Multifamily",
    totalCosts: "1540000",
    valueOfReclassifiedAssets: 140791,
    depreciableAssets: 10,
  },
  {
    lat: 40.6669059,
    lng: -74.20407449999999,
    squareFootage: "33600",
    propertyType: "Multifamily",
    totalCosts: "3742776",
    valueOfReclassifiedAssets: 313126,
    depreciableAssets: 9,
  },
  {
    lat: 40.6385229,
    lng: -74.0153231,
    squareFootage: "41024",
    propertyType: "Multifamily",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 1000104,
    depreciableAssets: 15,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "61000",
    propertyType: "Multifamily",
    totalCosts: "12350000",
    valueOfReclassifiedAssets: 1671936,
    depreciableAssets: 16,
  },
  {
    lat: 40.7235275,
    lng: -74.197388,
    squareFootage: "8000",
    propertyType: "Multifamily",
    totalCosts: "1497000",
    valueOfReclassifiedAssets: 136802,
    depreciableAssets: 11,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "46996",
    propertyType: "Multifamily",
    totalCosts: "3301000",
    valueOfReclassifiedAssets: 416361,
    depreciableAssets: 15,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "35292",
    propertyType: "Multifamily",
    totalCosts: "6501341",
    valueOfReclassifiedAssets: 696530,
    depreciableAssets: 13,
  },
  {
    lat: 40.83102239999999,
    lng: -73.9095279,
    squareFootage: "6408",
    propertyType: "Multifamily",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 312886,
    depreciableAssets: 13,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "11000",
    propertyType: "Multifamily",
    totalCosts: "2183429",
    valueOfReclassifiedAssets: 310178,
    depreciableAssets: 16,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "36000",
    propertyType: "Multifamily",
    totalCosts: "10013312",
    valueOfReclassifiedAssets: 889651,
    depreciableAssets: 10,
  },
  {
    lat: 33.5651587,
    lng: -112.1370601,
    squareFootage: "240366",
    propertyType: "Multifamily",
    totalCosts: "22600000",
    valueOfReclassifiedAssets: 6489990,
    depreciableAssets: 34,
  },
  {
    lat: 37.7877522,
    lng: -122.4382307,
    squareFootage: "15505",
    propertyType: "Multifamily",
    totalCosts: "15550000",
    valueOfReclassifiedAssets: 3149164,
    depreciableAssets: 24,
  },
  {
    lat: 32.799475,
    lng: -96.78687939999999,
    squareFootage: "16300",
    propertyType: "Industrial",
    totalCosts: "715293",
    valueOfReclassifiedAssets: 100486,
    depreciableAssets: 17,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "3095",
    propertyType: "Specialty",
    totalCosts: "873885",
    valueOfReclassifiedAssets: 746665,
    depreciableAssets: 35,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "3095",
    propertyType: "Specialty",
    totalCosts: "1006764",
    valueOfReclassifiedAssets: 871924,
    depreciableAssets: 39,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "4900",
    propertyType: "Retail",
    totalCosts: "1156182",
    valueOfReclassifiedAssets: 1074300,
    depreciableAssets: 13,
  },
  {
    lat: 34.0274622,
    lng: -117.9307584,
    squareFootage: "54774",
    propertyType: "Industrial",
    totalCosts: "2005071",
    valueOfReclassifiedAssets: 333422,
    depreciableAssets: 21,
  },
  {
    lat: 44.978175,
    lng: -93.2157342,
    squareFootage: "11396",
    propertyType: "Multifamily",
    totalCosts: "4691090",
    valueOfReclassifiedAssets: 666384,
    depreciableAssets: 35,
  },
  {
    lat: 34.0236878,
    lng: -118.3900204,
    squareFootage: "35671",
    propertyType: "Industrial",
    totalCosts: "2239538",
    valueOfReclassifiedAssets: 2113622,
    depreciableAssets: 8,
  },
  {
    lat: 34.2115828,
    lng: -118.2321952,
    squareFootage: "72924",
    propertyType: "Multifamily",
    totalCosts: "6580810",
    valueOfReclassifiedAssets: 4910097,
    depreciableAssets: 30,
  },
  {
    lat: 33.1044628,
    lng: -96.642433,
    squareFootage: "96568",
    propertyType: "Retail",
    totalCosts: "4046406",
    valueOfReclassifiedAssets: 422393,
    depreciableAssets: 14,
  },
  {
    lat: 42.372065,
    lng: -73.2647416,
    squareFootage: "10600",
    propertyType: "Hotel",
    totalCosts: "1264800",
    valueOfReclassifiedAssets: 933810,
    depreciableAssets: 33,
  },
  {
    lat: 40.1642213,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6767896",
    valueOfReclassifiedAssets: 5601333,
    depreciableAssets: 27,
  },
  {
    lat: 40.1642213,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8831620",
    valueOfReclassifiedAssets: 7363080,
    depreciableAssets: 27,
  },
  {
    lat: 42.1521342,
    lng: -72.6092032,
    squareFootage: "51600",
    propertyType: "Multifamily",
    totalCosts: "6375000",
    valueOfReclassifiedAssets: 1465809,
    depreciableAssets: 27,
  },
  {
    lat: 42.1233113,
    lng: -72.1238412,
    squareFootage: "37980",
    propertyType: "Multifamily",
    totalCosts: "3371000",
    valueOfReclassifiedAssets: 764280,
    depreciableAssets: 27,
  },
  {
    lat: 41.9043315,
    lng: -72.16530759999999,
    squareFootage: "52500",
    propertyType: "Multifamily",
    totalCosts: "3050000",
    valueOfReclassifiedAssets: 651966,
    depreciableAssets: 26,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "24892",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3475000",
    valueOfReclassifiedAssets: 1310280,
    depreciableAssets: 44,
  },
  {
    lat: 33.8806897,
    lng: -84.5064881,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "579880",
    valueOfReclassifiedAssets: 243566,
    depreciableAssets: 67,
  },
  {
    lat: 34.8425818,
    lng: -77.4013403,
    squareFootage: "17000",
    propertyType: "Retail",
    totalCosts: "8785950",
    valueOfReclassifiedAssets: 3422124,
    depreciableAssets: 46,
  },
  {
    lat: 39.0488574,
    lng: -77.58486649999999,
    squareFootage: "4676",
    propertyType: "Specialty",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 882239,
    depreciableAssets: 38,
  },
  {
    lat: 40.5610745,
    lng: -80.0394123,
    squareFootage: "7000",
    propertyType: "Retail",
    totalCosts: "5280000",
    valueOfReclassifiedAssets: 1494476,
    depreciableAssets: 33,
  },
  {
    lat: 37.5803608,
    lng: -77.4874049,
    squareFootage: "69016",
    propertyType: "Multifamily",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 2129866,
    depreciableAssets: 24,
  },
  {
    lat: 32.9186258,
    lng: -97.54638849999999,
    squareFootage: "24464",
    propertyType: "Industrial",
    totalCosts: "1202969",
    valueOfReclassifiedAssets: 281361,
    depreciableAssets: 28,
  },
  {
    lat: 32.7011609,
    lng: -97.3354531,
    squareFootage: "57690",
    propertyType: "Multifamily",
    totalCosts: "4275000",
    valueOfReclassifiedAssets: 1484166,
    depreciableAssets: 36,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "110000",
    propertyType: "Office",
    totalCosts: "36000000",
    valueOfReclassifiedAssets: 1969887,
    depreciableAssets: 6,
  },
  {
    lat: 43.0939533,
    lng: -78.9627624,
    squareFootage: "99692",
    propertyType: "Multifamily",
    totalCosts: "5762000",
    valueOfReclassifiedAssets: 1196782,
    depreciableAssets: 25,
  },
  {
    lat: 30.253501,
    lng: -81.8879884,
    squareFootage: "47980",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12272729",
    valueOfReclassifiedAssets: 2971071,
    depreciableAssets: 27,
  },
  {
    lat: 41.6381659,
    lng: -70.93684689999999,
    squareFootage: "28208",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3210000",
    valueOfReclassifiedAssets: 887687,
    depreciableAssets: 34,
  },
  {
    lat: 27.6445079,
    lng: -80.4048306,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16010000",
    valueOfReclassifiedAssets: 5119455,
    depreciableAssets: 36,
  },
  {
    lat: 27.4391171,
    lng: -80.3769999,
    squareFootage: "31862",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 3867489,
    depreciableAssets: 37,
  },
  {
    lat: 27.9765043,
    lng: -82.74280569999999,
    squareFootage: "58815",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13716560",
    valueOfReclassifiedAssets: 3325932,
    depreciableAssets: 27,
  },
  {
    lat: 28.0776415,
    lng: -81.8005724,
    squareFootage: "41561",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10973248",
    valueOfReclassifiedAssets: 3453283,
    depreciableAssets: 34,
  },
  {
    lat: 34.0236878,
    lng: -118.3900204,
    squareFootage: "7284",
    propertyType: "Multifamily",
    totalCosts: "5458248",
    valueOfReclassifiedAssets: 1007068,
    depreciableAssets: 30,
  },
  {
    lat: 39.0747097,
    lng: -77.1197521,
    squareFootage: "38000",
    propertyType: "Industrial",
    totalCosts: "3611697",
    valueOfReclassifiedAssets: 76976,
    depreciableAssets: 3,
  },
  {
    lat: 38.7240446,
    lng: -77.10824439999999,
    squareFootage: "32064",
    propertyType: "Office",
    totalCosts: "5625000",
    valueOfReclassifiedAssets: 851080,
    depreciableAssets: 18,
  },
  {
    lat: 26.6064003,
    lng: -80.1316927,
    squareFootage: "26340",
    propertyType: "Retail",
    totalCosts: "4080000",
    valueOfReclassifiedAssets: 1388962,
    depreciableAssets: 40,
  },
  {
    lat: 37.5334766,
    lng: -77.6879696,
    squareFootage: "14845",
    propertyType: "Retail",
    totalCosts: "3422500",
    valueOfReclassifiedAssets: 823147,
    depreciableAssets: 28,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "30000",
    propertyType: "Hotel",
    totalCosts: "1665000",
    valueOfReclassifiedAssets: 397932,
    depreciableAssets: 32,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "22859",
    propertyType: "Office",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 593336,
    depreciableAssets: 21,
  },
  {
    lat: 40.67103789999999,
    lng: -73.706594,
    squareFootage: "10000",
    propertyType: "Retail",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 847674,
    depreciableAssets: 22,
  },
  {
    lat: 41.8425225,
    lng: -74.3352858,
    squareFootage: "265105",
    propertyType: "Hotel",
    totalCosts: "24600000",
    valueOfReclassifiedAssets: 7905106,
    depreciableAssets: 35,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "49775",
    propertyType: "Multifamily",
    totalCosts: "50413918",
    valueOfReclassifiedAssets: 12398677,
    depreciableAssets: 27,
  },
  {
    lat: 33.5593454,
    lng: -82.1604849,
    squareFootage: "387586",
    propertyType: "Multifamily",
    totalCosts: "53325000",
    valueOfReclassifiedAssets: 15817369,
    depreciableAssets: 33,
  },
  {
    lat: 34.1595664,
    lng: -77.8710285,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "30250000",
    valueOfReclassifiedAssets: 4686864,
    depreciableAssets: 19,
  },
  {
    lat: 39.0455425,
    lng: -76.90668289999999,
    squareFootage: "72222",
    propertyType: "Industrial",
    totalCosts: "6350000",
    valueOfReclassifiedAssets: 711745,
    depreciableAssets: 16,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "612857",
    valueOfReclassifiedAssets: 278857,
    depreciableAssets: 60,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "358483",
    valueOfReclassifiedAssets: 163114,
    depreciableAssets: 60,
  },
  {
    lat: 32.5967198,
    lng: -116.9028125,
    squareFootage: "274280",
    propertyType: "Multifamily",
    totalCosts: "54040650",
    valueOfReclassifiedAssets: 12809977,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3761261",
    valueOfReclassifiedAssets: 3396548,
    depreciableAssets: 33,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "738536",
    valueOfReclassifiedAssets: 336042,
    depreciableAssets: 60,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2015568",
    valueOfReclassifiedAssets: 917107,
    depreciableAssets: 60,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "820889",
    valueOfReclassifiedAssets: 373514,
    depreciableAssets: 60,
  },
  {
    lat: 41.7956806,
    lng: -71.4269679,
    squareFootage: "82566",
    propertyType: "Multifamily",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2288837,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "504044",
    propertyType: "Multifamily",
    totalCosts: "45048048",
    valueOfReclassifiedAssets: 33647808,
    depreciableAssets: 33,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "713173",
    valueOfReclassifiedAssets: 324502,
    depreciableAssets: 60,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "756603",
    valueOfReclassifiedAssets: 344263,
    depreciableAssets: 60,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "729230",
    valueOfReclassifiedAssets: 331808,
    depreciableAssets: 60,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17555517",
    valueOfReclassifiedAssets: 13702465,
    depreciableAssets: 27,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "236558",
    valueOfReclassifiedAssets: 107637,
    depreciableAssets: 60,
  },
  {
    lat: 35.9805462,
    lng: -86.761749,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3844033",
    valueOfReclassifiedAssets: 1938204,
    depreciableAssets: 56,
  },
  {
    lat: 32.657579,
    lng: -96.9155468,
    squareFootage: "169408",
    propertyType: "Multifamily",
    totalCosts: "15400095",
    valueOfReclassifiedAssets: 4525656,
    depreciableAssets: 33,
  },
  {
    lat: 32.657579,
    lng: -96.9155468,
    squareFootage: "169408",
    propertyType: "Multifamily",
    totalCosts: "15400095",
    valueOfReclassifiedAssets: 4525656,
    depreciableAssets: 33,
  },
  {
    lat: 32.7099886,
    lng: -96.6745042,
    squareFootage: "131628",
    propertyType: "Multifamily",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 2733888,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "131628",
    propertyType: "Multifamily",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 2733888,
    depreciableAssets: 30,
  },
  {
    lat: 32.7099886,
    lng: -96.6745042,
    squareFootage: "217154",
    propertyType: "Multifamily",
    totalCosts: "9750000",
    valueOfReclassifiedAssets: 2739885,
    depreciableAssets: 30,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "46230",
    propertyType: "Multifamily",
    totalCosts: "3766000",
    valueOfReclassifiedAssets: 839614,
    depreciableAssets: 26,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "22264",
    propertyType: "Multifamily",
    totalCosts: "1884000",
    valueOfReclassifiedAssets: 401327,
    depreciableAssets: 25,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "41157",
    propertyType: "Multifamily",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 600258,
    depreciableAssets: 25,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "2675000",
    valueOfReclassifiedAssets: 328727,
    depreciableAssets: 15,
  },
  {
    lat: 40.5230452,
    lng: -79.8632834,
    squareFootage: "20000",
    propertyType: "Office",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 392125,
    depreciableAssets: 7,
  },
  {
    lat: 39.2036992,
    lng: -74.72200169999999,
    squareFootage: "76712",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7101331",
    valueOfReclassifiedAssets: 1695095,
    depreciableAssets: 27,
  },
  {
    lat: 39.4280941,
    lng: -75.27123929999999,
    squareFootage: "129213",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14532640",
    valueOfReclassifiedAssets: 2746093,
    depreciableAssets: 22,
  },
  {
    lat: 39.2074054,
    lng: -76.1088001,
    squareFootage: "44000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4775258",
    valueOfReclassifiedAssets: 1269986,
    depreciableAssets: 29,
  },
  {
    lat: 42.9574195,
    lng: -87.94818459999999,
    squareFootage: "75615",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16150000",
    valueOfReclassifiedAssets: 3907212,
    depreciableAssets: 29,
  },
  {
    lat: 40.6748343,
    lng: -74.4349761,
    squareFootage: "39572",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9443763",
    valueOfReclassifiedAssets: 2813856,
    depreciableAssets: 33,
  },
  {
    lat: 39.3289463,
    lng: -76.63838319999999,
    squareFootage: "46918",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7134172",
    valueOfReclassifiedAssets: 1623847,
    depreciableAssets: 25,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "46680",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11411277",
    valueOfReclassifiedAssets: 2044081,
    depreciableAssets: 20,
  },
  {
    lat: 41.6235809,
    lng: -72.6505957,
    squareFootage: "54000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14400000",
    valueOfReclassifiedAssets: 4923288,
    depreciableAssets: 30,
  },
  {
    lat: 41.6881608,
    lng: -72.7865518,
    squareFootage: "65000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18775000",
    valueOfReclassifiedAssets: 7592770,
    depreciableAssets: 26,
  },
  {
    lat: 41.59033530000001,
    lng: -73.0346244,
    squareFootage: "29005",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5025000",
    valueOfReclassifiedAssets: 2001698,
    depreciableAssets: 32,
  },
  {
    lat: 39.7295378,
    lng: -75.4680092,
    squareFootage: "65410",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7426000",
    valueOfReclassifiedAssets: 1924932,
    depreciableAssets: 29,
  },
  {
    lat: 41.0305956,
    lng: -74.29422079999999,
    squareFootage: "71552",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16200000",
    valueOfReclassifiedAssets: 3228816,
    depreciableAssets: 23,
  },
  {
    lat: 41.1339818,
    lng: -73.4062342,
    squareFootage: "66925",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19800000",
    valueOfReclassifiedAssets: 9249144,
    depreciableAssets: 24,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "46680",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11411277",
    valueOfReclassifiedAssets: 4556273,
    depreciableAssets: 20,
  },
  {
    lat: 32.9117167,
    lng: -96.9907197,
    squareFootage: "129321",
    propertyType: "Office",
    totalCosts: "6199829",
    valueOfReclassifiedAssets: 5443463,
    depreciableAssets: 22,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5656876",
    valueOfReclassifiedAssets: 5190144,
    depreciableAssets: 8,
  },
  {
    lat: 35.2781238,
    lng: -82.669947,
    squareFootage: "41847",
    propertyType: "Retail",
    totalCosts: "6469842",
    valueOfReclassifiedAssets: 865409,
    depreciableAssets: 32,
  },
  {
    lat: 38.6551092,
    lng: -77.09098089999999,
    squareFootage: "122403",
    propertyType: "Retail",
    totalCosts: "9882121",
    valueOfReclassifiedAssets: 1506527,
    depreciableAssets: 31,
  },
  {
    lat: 38.6551092,
    lng: -77.09098089999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1422256",
    valueOfReclassifiedAssets: 1366972,
    depreciableAssets: 4,
  },
  {
    lat: 38.4049904,
    lng: -78.8722341,
    squareFootage: "55860",
    propertyType: "Retail",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 661777,
    depreciableAssets: 26,
  },
  {
    lat: 30.3911214,
    lng: -91.0634024,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1274956",
    valueOfReclassifiedAssets: 1147962,
    depreciableAssets: 68,
  },
  {
    lat: 29.5779003,
    lng: -90.810357,
    squareFootage: "246000",
    propertyType: "Multifamily",
    totalCosts: "27350000",
    valueOfReclassifiedAssets: 7424999,
    depreciableAssets: 32,
  },
  {
    lat: 30.4192451,
    lng: -91.14489809999999,
    squareFootage: "245358",
    propertyType: "Multifamily",
    totalCosts: "24800000",
    valueOfReclassifiedAssets: 7712897,
    depreciableAssets: 35,
  },
  {
    lat: 30.154741,
    lng: -92.037116,
    squareFootage: "298500",
    propertyType: "Multifamily",
    totalCosts: "22600000",
    valueOfReclassifiedAssets: 5672224,
    depreciableAssets: 28,
  },
  {
    lat: 41.1457603,
    lng: -75.8766333,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1869039",
    valueOfReclassifiedAssets: 1669017,
    depreciableAssets: 43,
  },
  {
    lat: 40.9229722,
    lng: -75.40533669999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1778460",
    valueOfReclassifiedAssets: 1600159,
    depreciableAssets: 42,
  },
  {
    lat: 41.5674,
    lng: -75.94632399999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1967905",
    valueOfReclassifiedAssets: 1678511,
    depreciableAssets: 41,
  },
  {
    lat: 30.777092,
    lng: -81.49379929999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6141130",
    valueOfReclassifiedAssets: 4665996,
    depreciableAssets: 39,
  },
  {
    lat: 29.8120151,
    lng: -90.13077969999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8189068",
    valueOfReclassifiedAssets: 6361925,
    depreciableAssets: 30,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7831047",
    valueOfReclassifiedAssets: 7056061,
    depreciableAssets: 0,
  },
  {
    lat: 40.1407113,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10098437",
    valueOfReclassifiedAssets: 9329474,
    depreciableAssets: 29,
  },
  {
    lat: 40.1407113,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12273606",
    valueOfReclassifiedAssets: 11271983,
    depreciableAssets: 35,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12376225",
    valueOfReclassifiedAssets: 11921591,
    depreciableAssets: 18,
  },
  {
    lat: 28.4588856,
    lng: -81.3949264,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5774418",
    valueOfReclassifiedAssets: 4627989,
    depreciableAssets: 22,
  },
  {
    lat: 39.9741059,
    lng: -74.1592278,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4171292",
    valueOfReclassifiedAssets: 3168672,
    depreciableAssets: 26,
  },
  {
    lat: 32.5483579,
    lng: -83.91089680000002,
    squareFootage: "47886",
    propertyType: "Multifamily",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 361907,
    depreciableAssets: 35,
  },
  {
    lat: 39.4717184,
    lng: -84.75244819999999,
    squareFootage: "26136",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 408166,
    depreciableAssets: 28,
  },
  {
    lat: 42.4224283,
    lng: -70.9962875,
    squareFootage: "46590",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5825000",
    valueOfReclassifiedAssets: 1129473,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "56574",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1516934,
    depreciableAssets: 30,
  },
  {
    lat: 42.4148798,
    lng: -71.1745579,
    squareFootage: "36498",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5700000",
    valueOfReclassifiedAssets: 1311275,
    depreciableAssets: 27,
  },
  {
    lat: 43.0756836,
    lng: -76.1493891,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1815686",
    valueOfReclassifiedAssets: 1134882,
    depreciableAssets: 44,
  },
  {
    lat: 43.0513668,
    lng: -76.1841701,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6198002",
    valueOfReclassifiedAssets: 4745411,
    depreciableAssets: 29,
  },
  {
    lat: 43.0513668,
    lng: -76.1841701,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6198002",
    valueOfReclassifiedAssets: 4745411,
    depreciableAssets: 29,
  },
  {
    lat: 43.0347064,
    lng: -76.1261969,
    squareFootage: "33660",
    propertyType: "Multifamily",
    totalCosts: "2315055",
    valueOfReclassifiedAssets: 1927650,
    depreciableAssets: 20,
  },
  {
    lat: 43.0513668,
    lng: -76.1841701,
    squareFootage: "78206",
    propertyType: "Multifamily",
    totalCosts: "4738998",
    valueOfReclassifiedAssets: 3466608,
    depreciableAssets: 33,
  },
  {
    lat: 43.0756836,
    lng: -76.1493891,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1815686",
    valueOfReclassifiedAssets: 1134882,
    depreciableAssets: 44,
  },
  {
    lat: 43.0756836,
    lng: -76.1493891,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1815686",
    valueOfReclassifiedAssets: 1134882,
    depreciableAssets: 44,
  },
  {
    lat: 33.2244703,
    lng: -97.1036396,
    squareFootage: "155943",
    propertyType: "Multifamily",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 4704489,
    depreciableAssets: 38,
  },
  {
    lat: 35.4002721,
    lng: -80.6548882,
    squareFootage: "73144",
    propertyType: "Retail",
    totalCosts: "8338950",
    valueOfReclassifiedAssets: 7481445,
    depreciableAssets: 29,
  },
  {
    lat: 34.0880507,
    lng: -118.2965121,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 400266,
    depreciableAssets: 100,
  },
  {
    lat: 38.5915927,
    lng: -76.8028933,
    squareFootage: "14097",
    propertyType: "Retail",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 387641,
    depreciableAssets: 23,
  },
  {
    lat: 61.58090259999999,
    lng: -149.4415038,
    squareFootage: "4694",
    propertyType: "Retail",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1978661,
    depreciableAssets: 58,
  },
  {
    lat: 39.6268957,
    lng: -77.7337628,
    squareFootage: "42000",
    propertyType: "Retail",
    totalCosts: "2295000",
    valueOfReclassifiedAssets: 2106458,
    depreciableAssets: 25,
  },
  {
    lat: 39.2632524,
    lng: -76.4910093,
    squareFootage: "136200",
    propertyType: "Multifamily",
    totalCosts: "8100000",
    valueOfReclassifiedAssets: 1806550,
    depreciableAssets: 26,
  },
  {
    lat: 34.2037124,
    lng: -118.4571974,
    squareFootage: "18885",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 293688,
    depreciableAssets: 9,
  },
  {
    lat: 34.1036996,
    lng: -117.6610854,
    squareFootage: "37143",
    propertyType: "Industrial",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 700557,
    depreciableAssets: 17,
  },
  {
    lat: 39.3416271,
    lng: -76.68169619999999,
    squareFootage: "26958",
    propertyType: "Multifamily",
    totalCosts: "1075000",
    valueOfReclassifiedAssets: 229988,
    depreciableAssets: 26,
  },
  {
    lat: 39.3252511,
    lng: -76.71633589999999,
    squareFootage: "8232",
    propertyType: "Retail",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 304302,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "105486",
    propertyType: "Industrial",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 238023,
    depreciableAssets: 10,
  },
  {
    lat: 38.2034582,
    lng: -76.39845369999999,
    squareFootage: "23329",
    propertyType: "Retail",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 198150,
    depreciableAssets: 23,
  },
  {
    lat: 60.16960879999999,
    lng: -149.2361888,
    squareFootage: "9473",
    propertyType: "Retail",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 900544,
    depreciableAssets: 62,
  },
  {
    lat: 39.1511679,
    lng: -76.6239423,
    squareFootage: "26565",
    propertyType: "Retail",
    totalCosts: "2160000",
    valueOfReclassifiedAssets: 1923317,
    depreciableAssets: 14,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "2016",
    propertyType: "Retail",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 201783,
    depreciableAssets: 16,
  },
  {
    lat: 48.360905,
    lng: -122.1312492,
    squareFootage: "4367",
    propertyType: "Retail",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 1138374,
    depreciableAssets: 67,
  },
  {
    lat: 37.9727529,
    lng: -121.3255688,
    squareFootage: "28360",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5703743",
    valueOfReclassifiedAssets: 1600790,
    depreciableAssets: 36,
  },
  {
    lat: 34.1366259,
    lng: -118.3987842,
    squareFootage: "1189",
    propertyType: "Industrial",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 2006274,
    depreciableAssets: 71,
  },
  {
    lat: 33.6160293,
    lng: -111.8926303,
    squareFootage: "12753",
    propertyType: "Retail",
    totalCosts: "4205919",
    valueOfReclassifiedAssets: 850688,
    depreciableAssets: 25,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "25059",
    propertyType: "Retail",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 202133,
    depreciableAssets: 16,
  },
  {
    lat: 33.6634859,
    lng: -116.8439147,
    squareFootage: "38604",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1261583,
    depreciableAssets: 37,
  },
  {
    lat: 34.0880507,
    lng: -118.2965121,
    squareFootage: "297",
    propertyType: "Retail",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 1808808,
    depreciableAssets: 93,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "202838",
    propertyType: "Industrial",
    totalCosts: "17233152",
    valueOfReclassifiedAssets: 1364183,
    depreciableAssets: 10,
  },
  {
    lat: 34.2386799,
    lng: -118.4805569,
    squareFootage: "33430",
    propertyType: "Multifamily",
    totalCosts: "3400000",
    valueOfReclassifiedAssets: 2936666,
    depreciableAssets: 18,
  },
  {
    lat: 34.1366259,
    lng: -118.3987842,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 2805000,
    depreciableAssets: 100,
  },
  {
    lat: 32.2540946,
    lng: -110.8850949,
    squareFootage: "12049",
    propertyType: "Retail",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 704950,
    depreciableAssets: 39,
  },
  {
    lat: 34.0880507,
    lng: -118.2965121,
    squareFootage: "297",
    propertyType: "Retail",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 400205,
    depreciableAssets: 93,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "44600",
    propertyType: "Industrial",
    totalCosts: "5620000",
    valueOfReclassifiedAssets: 511947,
    depreciableAssets: 11,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "222404",
    propertyType: "Industrial",
    totalCosts: "10420424",
    valueOfReclassifiedAssets: 737765,
    depreciableAssets: 9,
  },
  {
    lat: 35.344108,
    lng: -119.0285355,
    squareFootage: "33205",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8118000",
    valueOfReclassifiedAssets: 2385127,
    depreciableAssets: 35,
  },
  {
    lat: 34.208195,
    lng: -118.3987842,
    squareFootage: "16300",
    propertyType: "Multifamily",
    totalCosts: "2222458",
    valueOfReclassifiedAssets: 181273,
    depreciableAssets: 10,
  },
  {
    lat: 34.2386799,
    lng: -118.4805569,
    squareFootage: "22240",
    propertyType: "Multifamily",
    totalCosts: "2238292",
    valueOfReclassifiedAssets: 1769537,
    depreciableAssets: 27,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "18500",
    propertyType: "Industrial",
    totalCosts: "3139962",
    valueOfReclassifiedAssets: 539003,
    depreciableAssets: 21,
  },
  {
    lat: 40.068629,
    lng: -75.0026972,
    squareFootage: "280000",
    propertyType: "Industrial",
    totalCosts: "8700000",
    valueOfReclassifiedAssets: 718907,
    depreciableAssets: 10,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "171388",
    propertyType: "Industrial",
    totalCosts: "11818565",
    valueOfReclassifiedAssets: 1049197,
    depreciableAssets: 12,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "63000",
    propertyType: "Retail",
    totalCosts: "6390000",
    valueOfReclassifiedAssets: 5765392,
    depreciableAssets: 22,
  },
  {
    lat: 34.1985119,
    lng: -118.4980744,
    squareFootage: "20389",
    propertyType: "Multifamily",
    totalCosts: "1471223",
    valueOfReclassifiedAssets: 1172492,
    depreciableAssets: 24,
  },
  {
    lat: 39.2724771,
    lng: -76.745195,
    squareFootage: "43000",
    propertyType: "Retail",
    totalCosts: "3596249",
    valueOfReclassifiedAssets: 3190829,
    depreciableAssets: 16,
  },
  {
    lat: 34.1985119,
    lng: -118.4980744,
    squareFootage: "20389",
    propertyType: "Multifamily",
    totalCosts: "2667604",
    valueOfReclassifiedAssets: 2125950,
    depreciableAssets: 24,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "57000",
    propertyType: "Retail",
    totalCosts: "6390000",
    valueOfReclassifiedAssets: 5765392,
    depreciableAssets: 22,
  },
  {
    lat: 33.5039207,
    lng: -117.5613246,
    squareFootage: "8092",
    propertyType: "Retail",
    totalCosts: "478534",
    valueOfReclassifiedAssets: 413601,
    depreciableAssets: 24,
  },
  {
    lat: 33.5039207,
    lng: -117.5613246,
    squareFootage: "16203",
    propertyType: "Office",
    totalCosts: "1181182",
    valueOfReclassifiedAssets: 1091982,
    depreciableAssets: 22,
  },
  {
    lat: 33.5039207,
    lng: -117.5613246,
    squareFootage: "12000",
    propertyType: "Retail",
    totalCosts: "1644000",
    valueOfReclassifiedAssets: 1487297,
    depreciableAssets: 22,
  },
  {
    lat: 33.5039207,
    lng: -117.5613246,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "670296",
    valueOfReclassifiedAssets: 574109,
    depreciableAssets: 24,
  },
  {
    lat: 38.1352484,
    lng: -85.6280697,
    squareFootage: "130100",
    propertyType: "Multifamily",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 2662533,
    depreciableAssets: 20,
  },
  {
    lat: 34.2386799,
    lng: -118.4805569,
    squareFootage: "22240",
    propertyType: "Multifamily",
    totalCosts: "1543118",
    valueOfReclassifiedAssets: 1219950,
    depreciableAssets: 27,
  },
  {
    lat: 33.8469812,
    lng: -117.9541894,
    squareFootage: "26148",
    propertyType: "Office",
    totalCosts: "6914409",
    valueOfReclassifiedAssets: 4616898,
    depreciableAssets: 53,
  },
  {
    lat: 33.8459536,
    lng: -117.7901088,
    squareFootage: "87742",
    propertyType: "Industrial",
    totalCosts: "19541139",
    valueOfReclassifiedAssets: 3660521,
    depreciableAssets: 41,
  },
  {
    lat: 36.1964847,
    lng: -95.8459341,
    squareFootage: "195000",
    propertyType: "Multifamily",
    totalCosts: "6731000",
    valueOfReclassifiedAssets: 1864284,
    depreciableAssets: 33,
  },
  {
    lat: 39.1174086,
    lng: -94.6915484,
    squareFootage: "76856",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 407620,
    depreciableAssets: 27,
  },
  {
    lat: 38.767401,
    lng: -90.44080369999999,
    squareFootage: "251280",
    propertyType: "Multifamily",
    totalCosts: "8700000",
    valueOfReclassifiedAssets: 1800099,
    depreciableAssets: 24,
  },
  {
    lat: 39.7924104,
    lng: -86.2514126,
    squareFootage: "492615",
    propertyType: "Multifamily",
    totalCosts: "19000000",
    valueOfReclassifiedAssets: 5493896,
    depreciableAssets: 34,
  },
  {
    lat: 38.7470186,
    lng: -90.2526163,
    squareFootage: "132645",
    propertyType: "Multifamily",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 845935,
    depreciableAssets: 23,
  },
  {
    lat: 38.7479435,
    lng: -90.2113571,
    squareFootage: "97220",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1074970,
    depreciableAssets: 25,
  },
  {
    lat: 38.6999486,
    lng: -90.2197765,
    squareFootage: "82800",
    propertyType: "Multifamily",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 397947,
    depreciableAssets: 33,
  },
  {
    lat: 38.5635304,
    lng: -90.2479232,
    squareFootage: "258300",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 1872643,
    depreciableAssets: 20,
  },
  {
    lat: 38.9328362,
    lng: -94.4902774,
    squareFootage: "150260",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 920522,
    depreciableAssets: 22,
  },
  {
    lat: 38.88955259999999,
    lng: -94.53150289999999,
    squareFootage: "52374",
    propertyType: "Multifamily",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 912579,
    depreciableAssets: 40,
  },
  {
    lat: 38.7479435,
    lng: -90.2113571,
    squareFootage: "97220",
    propertyType: "Multifamily",
    totalCosts: "1095000",
    valueOfReclassifiedAssets: 235418,
    depreciableAssets: 25,
  },
  {
    lat: 38.7415569,
    lng: -90.3418927,
    squareFootage: "213724",
    propertyType: "Multifamily",
    totalCosts: "6120000",
    valueOfReclassifiedAssets: 1505358,
    depreciableAssets: 29,
  },
  {
    lat: 38.992935,
    lng: -94.55212739999999,
    squareFootage: "213290",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 1137441,
    depreciableAssets: 22,
  },
  {
    lat: 38.88955259999999,
    lng: -94.53150289999999,
    squareFootage: "70400",
    propertyType: "Multifamily",
    totalCosts: "1899427",
    valueOfReclassifiedAssets: 1569161,
    depreciableAssets: 23,
  },
  {
    lat: 44.9091984,
    lng: -93.3439291,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "26081466",
    valueOfReclassifiedAssets: 19792100,
    depreciableAssets: 27,
  },
  {
    lat: 44.851048,
    lng: -93.23950119999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14936226",
    valueOfReclassifiedAssets: 11869212,
    depreciableAssets: 27,
  },
  {
    lat: 44.851048,
    lng: -93.23950119999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2296045",
    valueOfReclassifiedAssets: 2239518,
    depreciableAssets: 8,
  },
  {
    lat: 44.1223003,
    lng: -93.9674371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11331721",
    valueOfReclassifiedAssets: 8398861,
    depreciableAssets: 33,
  },
  {
    lat: 41.0233153,
    lng: -73.7742616,
    squareFootage: "11000",
    propertyType: "Office",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 398074,
    depreciableAssets: 23,
  },
  {
    lat: 41.7165144,
    lng: -73.9242251,
    squareFootage: "235599",
    propertyType: "Retail",
    totalCosts: "27450000",
    valueOfReclassifiedAssets: 9462274,
    depreciableAssets: 40,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "67310",
    propertyType: "Industrial",
    totalCosts: "10250000",
    valueOfReclassifiedAssets: 2951794,
    depreciableAssets: 34,
  },
  {
    lat: 37.0105307,
    lng: -122.1178261,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1399451",
    valueOfReclassifiedAssets: 1051633,
    depreciableAssets: 28,
  },
  {
    lat: 37.0105307,
    lng: -122.1178261,
    squareFootage: "76680",
    propertyType: "Multifamily",
    totalCosts: "37734149",
    valueOfReclassifiedAssets: 8779645,
    depreciableAssets: 28,
  },
  {
    lat: 32.6356124,
    lng: -96.80294889999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3797139",
    valueOfReclassifiedAssets: 3315760,
    depreciableAssets: 35,
  },
  {
    lat: 40.7132136,
    lng: -74.21206289999999,
    squareFootage: "27840",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 594567,
    depreciableAssets: 21,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "15000",
    propertyType: "Multifamily",
    totalCosts: "2800000",
    valueOfReclassifiedAssets: 616105,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "4448",
    propertyType: "Multifamily",
    totalCosts: "617452",
    valueOfReclassifiedAssets: 54628,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "4448",
    propertyType: "Multifamily",
    totalCosts: "619363",
    valueOfReclassifiedAssets: 54797,
    depreciableAssets: 10,
  },
  {
    lat: 26.208305,
    lng: -80.2685032,
    squareFootage: "841",
    propertyType: "Multifamily",
    totalCosts: "77646",
    valueOfReclassifiedAssets: 6795,
    depreciableAssets: 10,
  },
  {
    lat: 26.208305,
    lng: -80.2685032,
    squareFootage: "8313",
    propertyType: "Multifamily",
    totalCosts: "1142735",
    valueOfReclassifiedAssets: 100727,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "2793",
    propertyType: "Multifamily",
    totalCosts: "348946",
    valueOfReclassifiedAssets: 30358,
    depreciableAssets: 10,
  },
  {
    lat: 43.0043693,
    lng: -85.63320929999999,
    squareFootage: "136407",
    propertyType: "Multifamily",
    totalCosts: "6108893",
    valueOfReclassifiedAssets: 4728615,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "126325",
    propertyType: "Multifamily",
    totalCosts: "7259625",
    valueOfReclassifiedAssets: 5397059,
    depreciableAssets: 40,
  },
  {
    lat: 42.7287542,
    lng: -84.63744840000001,
    squareFootage: "251484",
    propertyType: "Multifamily",
    totalCosts: "16625000",
    valueOfReclassifiedAssets: 12608412,
    depreciableAssets: 35,
  },
  {
    lat: 42.9219281,
    lng: -85.50972259999999,
    squareFootage: "217766",
    propertyType: "Multifamily",
    totalCosts: "9515625",
    valueOfReclassifiedAssets: 7185967,
    depreciableAssets: 37,
  },
  {
    lat: 43.0043693,
    lng: -85.63320929999999,
    squareFootage: "188253",
    propertyType: "Multifamily",
    totalCosts: "11991625",
    valueOfReclassifiedAssets: 9141325,
    depreciableAssets: 34,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6345",
    propertyType: "Multifamily",
    totalCosts: "925714",
    valueOfReclassifiedAssets: 81702,
    depreciableAssets: 10,
  },
  {
    lat: 42.9219281,
    lng: -85.50972259999999,
    squareFootage: "135000",
    propertyType: "Multifamily",
    totalCosts: "7121637",
    valueOfReclassifiedAssets: 5410059,
    depreciableAssets: 41,
  },
  {
    lat: 43.0147008,
    lng: -86.1955169,
    squareFootage: "273795",
    propertyType: "Multifamily",
    totalCosts: "13027869",
    valueOfReclassifiedAssets: 10686124,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6345",
    propertyType: "Multifamily",
    totalCosts: "954568",
    valueOfReclassifiedAssets: 84636,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6345",
    propertyType: "Multifamily",
    totalCosts: "925714",
    valueOfReclassifiedAssets: 81702,
    depreciableAssets: 10,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "401438",
    propertyType: "Multifamily",
    totalCosts: "37959354",
    valueOfReclassifiedAssets: 10878197,
    depreciableAssets: 32,
  },
  {
    lat: 42.8522633,
    lng: -85.9917437,
    squareFootage: "141882",
    propertyType: "Multifamily",
    totalCosts: "6586149",
    valueOfReclassifiedAssets: 4989287,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "756",
    propertyType: "Multifamily",
    totalCosts: "117842",
    valueOfReclassifiedAssets: 10852,
    depreciableAssets: 11,
  },
  {
    lat: 43.03149560000001,
    lng: -85.58179109999999,
    squareFootage: "248724",
    propertyType: "Multifamily",
    totalCosts: "7687500",
    valueOfReclassifiedAssets: 6318652,
    depreciableAssets: 29,
  },
  {
    lat: 43.0147008,
    lng: -86.1955169,
    squareFootage: "199210",
    propertyType: "Multifamily",
    totalCosts: "13774483",
    valueOfReclassifiedAssets: 11161564,
    depreciableAssets: 28,
  },
  {
    lat: 42.328245,
    lng: -83.0495622,
    squareFootage: "571831",
    propertyType: "Multifamily",
    totalCosts: "68992929",
    valueOfReclassifiedAssets: 10893675,
    depreciableAssets: 18,
  },
  {
    lat: 43.0147008,
    lng: -86.1955169,
    squareFootage: "126205",
    propertyType: "Multifamily",
    totalCosts: "6267857",
    valueOfReclassifiedAssets: 5163875,
    depreciableAssets: 25,
  },
  {
    lat: 42.8273575,
    lng: -84.4592701,
    squareFootage: "308900",
    propertyType: "Multifamily",
    totalCosts: "30066614",
    valueOfReclassifiedAssets: 8280952,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6627",
    propertyType: "Multifamily",
    totalCosts: "853322",
    valueOfReclassifiedAssets: 74432,
    depreciableAssets: 10,
  },
  {
    lat: 42.698235,
    lng: -84.406761,
    squareFootage: "459339",
    propertyType: "Multifamily",
    totalCosts: "39600099",
    valueOfReclassifiedAssets: 9253840,
    depreciableAssets: 27,
  },
  {
    lat: 42.8846539,
    lng: -85.55091639999999,
    squareFootage: "188991",
    propertyType: "Multifamily",
    totalCosts: "7826407",
    valueOfReclassifiedAssets: 6337611,
    depreciableAssets: 30,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "6627",
    propertyType: "Multifamily",
    totalCosts: "855407",
    valueOfReclassifiedAssets: 74613,
    depreciableAssets: 10,
  },
  {
    lat: 30.24567279999999,
    lng: -97.76883579999999,
    squareFootage: "164742",
    propertyType: "Multifamily",
    totalCosts: "65000000",
    valueOfReclassifiedAssets: 13686317,
    depreciableAssets: 23,
  },
  {
    lat: 42.7287542,
    lng: -84.63744840000001,
    squareFootage: "379204",
    propertyType: "Multifamily",
    totalCosts: "24266308",
    valueOfReclassifiedAssets: 5491635,
    depreciableAssets: 28,
  },
  {
    lat: 39.2182916,
    lng: -94.6347041,
    squareFootage: "497064",
    propertyType: "Multifamily",
    totalCosts: "60822842",
    valueOfReclassifiedAssets: 15825995,
    depreciableAssets: 29,
  },
  {
    lat: 42.5496035,
    lng: -83.11127979999999,
    squareFootage: "0",
    propertyType: "Office",
    totalCosts: "55000000",
    valueOfReclassifiedAssets: 5964516,
    depreciableAssets: 13,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "25425",
    propertyType: "Office",
    totalCosts: "16175000",
    valueOfReclassifiedAssets: 2981679,
    depreciableAssets: 22,
  },
  {
    lat: 40.9411487,
    lng: -74.2531465,
    squareFootage: "84202",
    propertyType: "Retail",
    totalCosts: "28700000",
    valueOfReclassifiedAssets: 8929197,
    depreciableAssets: 37,
  },
  {
    lat: 39.3416271,
    lng: -76.68169619999999,
    squareFootage: "14753",
    propertyType: "Office",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 376148,
    depreciableAssets: 32,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "75000",
    propertyType: "Multifamily",
    totalCosts: "8127636",
    valueOfReclassifiedAssets: 633956,
    depreciableAssets: 9,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "42420",
    propertyType: "Multifamily",
    totalCosts: "6255551",
    valueOfReclassifiedAssets: 676553,
    depreciableAssets: 13,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "43120",
    propertyType: "Multifamily",
    totalCosts: "6053814",
    valueOfReclassifiedAssets: 505751,
    depreciableAssets: 11,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "129600",
    propertyType: "Multifamily",
    totalCosts: "12434387",
    valueOfReclassifiedAssets: 649899,
    depreciableAssets: 7,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "25200",
    propertyType: "Multifamily",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 494070,
    depreciableAssets: 14,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "34395",
    propertyType: "Multifamily",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 2474722,
    depreciableAssets: 14,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "45540",
    propertyType: "Multifamily",
    totalCosts: "6386307",
    valueOfReclassifiedAssets: 545045,
    depreciableAssets: 11,
  },
  {
    lat: 40.7728432,
    lng: -73.9558204,
    squareFootage: "13202",
    propertyType: "Multifamily",
    totalCosts: "8100000",
    valueOfReclassifiedAssets: 1002974,
    depreciableAssets: 15,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "62276",
    propertyType: "Multifamily",
    totalCosts: "9841729",
    valueOfReclassifiedAssets: 963037,
    depreciableAssets: 12,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "59916",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 723161,
    depreciableAssets: 10,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "13668",
    propertyType: "Multifamily",
    totalCosts: "2538230",
    valueOfReclassifiedAssets: 202493,
    depreciableAssets: 10,
  },
  {
    lat: 40.7464969,
    lng: -74.0094471,
    squareFootage: "10524",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 860966,
    depreciableAssets: 16,
  },
  {
    lat: 40.7593941,
    lng: -73.9697795,
    squareFootage: "10275",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 912450,
    depreciableAssets: 16,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "13668",
    propertyType: "Multifamily",
    totalCosts: "2417979",
    valueOfReclassifiedAssets: 202317,
    depreciableAssets: 10,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "146660",
    propertyType: "Multifamily",
    totalCosts: "23152351",
    valueOfReclassifiedAssets: 2028453,
    depreciableAssets: 11,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "43068",
    propertyType: "Multifamily",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 418157,
    depreciableAssets: 10,
  },
  {
    lat: 43.7067561,
    lng: -79.3625718,
    squareFootage: "19500",
    propertyType: "Multifamily",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 622516,
    depreciableAssets: 11,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "85607",
    propertyType: "Multifamily",
    totalCosts: "26000000",
    valueOfReclassifiedAssets: 3191098,
    depreciableAssets: 14,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "22800",
    propertyType: "Multifamily",
    totalCosts: "2964593",
    valueOfReclassifiedAssets: 245637,
    depreciableAssets: 10,
  },
  {
    lat: 40.6560436,
    lng: -74.0079781,
    squareFootage: "33150",
    propertyType: "Multifamily",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 1600707,
    depreciableAssets: 14,
  },
  {
    lat: 40.72557219999999,
    lng: -73.8624893,
    squareFootage: "98000",
    propertyType: "Multifamily",
    totalCosts: "31500000",
    valueOfReclassifiedAssets: 4193460,
    depreciableAssets: 16,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "136157",
    propertyType: "Multifamily",
    totalCosts: "22722498",
    valueOfReclassifiedAssets: 2179424,
    depreciableAssets: 12,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "44580",
    propertyType: "Multifamily",
    totalCosts: "8048323",
    valueOfReclassifiedAssets: 500283,
    depreciableAssets: 8,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "47164",
    propertyType: "Multifamily",
    totalCosts: "7795537",
    valueOfReclassifiedAssets: 639422,
    depreciableAssets: 10,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "51176",
    propertyType: "Multifamily",
    totalCosts: "8156479",
    valueOfReclassifiedAssets: 730150,
    depreciableAssets: 11,
  },
  {
    lat: 40.8971509,
    lng: -73.9065883,
    squareFootage: "63000",
    propertyType: "Multifamily",
    totalCosts: "9591521",
    valueOfReclassifiedAssets: 1124212,
    depreciableAssets: 14,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "55012",
    propertyType: "Multifamily",
    totalCosts: "7728031",
    valueOfReclassifiedAssets: 738144,
    depreciableAssets: 12,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "56000",
    propertyType: "Multifamily",
    totalCosts: "7529719",
    valueOfReclassifiedAssets: 866383,
    depreciableAssets: 15,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "83430",
    propertyType: "Multifamily",
    totalCosts: "29870000",
    valueOfReclassifiedAssets: 4616045,
    depreciableAssets: 18,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "88691",
    propertyType: "Multifamily",
    totalCosts: "9415605",
    valueOfReclassifiedAssets: 845590,
    depreciableAssets: 11,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "12628",
    propertyType: "Multifamily",
    totalCosts: "3178923",
    valueOfReclassifiedAssets: 410022,
    depreciableAssets: 16,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "8800",
    propertyType: "Multifamily",
    totalCosts: "1550082",
    valueOfReclassifiedAssets: 185347,
    depreciableAssets: 15,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "121636",
    propertyType: "Multifamily",
    totalCosts: "17022291",
    valueOfReclassifiedAssets: 1535145,
    depreciableAssets: 12,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "74007",
    propertyType: "Multifamily",
    totalCosts: "27650000",
    valueOfReclassifiedAssets: 4362482,
    depreciableAssets: 19,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "69820",
    propertyType: "Multifamily",
    totalCosts: "11426355",
    valueOfReclassifiedAssets: 1121627,
    depreciableAssets: 12,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "36000",
    propertyType: "Multifamily",
    totalCosts: "4132432",
    valueOfReclassifiedAssets: 467818,
    depreciableAssets: 14,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "142500",
    propertyType: "Multifamily",
    totalCosts: "14109693",
    valueOfReclassifiedAssets: 1300590,
    depreciableAssets: 11,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "61272",
    propertyType: "Multifamily",
    totalCosts: "8653804",
    valueOfReclassifiedAssets: 795073,
    depreciableAssets: 11,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "45350",
    propertyType: "Multifamily",
    totalCosts: "6773367",
    valueOfReclassifiedAssets: 627903,
    depreciableAssets: 11,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "51000",
    propertyType: "Multifamily",
    totalCosts: "7491952",
    valueOfReclassifiedAssets: 794716,
    depreciableAssets: 13,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "51000",
    propertyType: "Multifamily",
    totalCosts: "7316186",
    valueOfReclassifiedAssets: 772076,
    depreciableAssets: 13,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "28572",
    propertyType: "Multifamily",
    totalCosts: "5472043",
    valueOfReclassifiedAssets: 549618,
    depreciableAssets: 13,
  },
  {
    lat: 44.4700299,
    lng: -70.28815159999999,
    squareFootage: "33895",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "500000",
    valueOfReclassifiedAssets: 398280,
    depreciableAssets: 25,
  },
  {
    lat: 35.6631125,
    lng: -78.6795836,
    squareFootage: "108000",
    propertyType: "Multifamily",
    totalCosts: "18596262",
    valueOfReclassifiedAssets: 6452786,
    depreciableAssets: 39,
  },
  {
    lat: 35.7418297,
    lng: -78.713608,
    squareFootage: "44460",
    propertyType: "Multifamily",
    totalCosts: "9107727",
    valueOfReclassifiedAssets: 2919802,
    depreciableAssets: 36,
  },
  {
    lat: 35.7418297,
    lng: -78.713608,
    squareFootage: "285500",
    propertyType: "Multifamily",
    totalCosts: "30173562",
    valueOfReclassifiedAssets: 9667928,
    depreciableAssets: 35,
  },
  {
    lat: 39.6751269,
    lng: -104.9680914,
    squareFootage: "116768",
    propertyType: "Multifamily",
    totalCosts: "25400000",
    valueOfReclassifiedAssets: 4404476,
    depreciableAssets: 21,
  },
  {
    lat: 29.8942825,
    lng: -97.9810845,
    squareFootage: "185364",
    propertyType: "Multifamily",
    totalCosts: "20701076",
    valueOfReclassifiedAssets: 5793551,
    depreciableAssets: 29,
  },
  {
    lat: 35.7418297,
    lng: -78.713608,
    squareFootage: "285500",
    propertyType: "Multifamily",
    totalCosts: "25202500",
    valueOfReclassifiedAssets: 7483950,
    depreciableAssets: 35,
  },
  {
    lat: 35.7418297,
    lng: -78.713608,
    squareFootage: "318353",
    propertyType: "Multifamily",
    totalCosts: "34930438",
    valueOfReclassifiedAssets: 5428154,
    depreciableAssets: 17,
  },
  {
    lat: 35.3301529,
    lng: -80.7325287,
    squareFootage: "242346",
    propertyType: "Multifamily",
    totalCosts: "24170000",
    valueOfReclassifiedAssets: 7317149,
    depreciableAssets: 32,
  },
  {
    lat: 35.3301529,
    lng: -80.7325287,
    squareFootage: "241926",
    propertyType: "Multifamily",
    totalCosts: "38534000",
    valueOfReclassifiedAssets: 14120122,
    depreciableAssets: 39,
  },
  {
    lat: 39.6751269,
    lng: -104.9680914,
    squareFootage: "116768",
    propertyType: "Multifamily",
    totalCosts: "25329358",
    valueOfReclassifiedAssets: 4908237,
    depreciableAssets: 21,
  },
  {
    lat: 29.8942825,
    lng: -97.9810845,
    squareFootage: "185364",
    propertyType: "Multifamily",
    totalCosts: "20800000",
    valueOfReclassifiedAssets: 5199208,
    depreciableAssets: 29,
  },
  {
    lat: 29.5269768,
    lng: -98.61057029999999,
    squareFootage: "149520",
    propertyType: "Multifamily",
    totalCosts: "14108792",
    valueOfReclassifiedAssets: 4307419,
    depreciableAssets: 34,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2943616",
    valueOfReclassifiedAssets: 1615479,
    depreciableAssets: 45,
  },
  {
    lat: 40.3362872,
    lng: -74.43298519999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2629959",
    valueOfReclassifiedAssets: 2553269,
    depreciableAssets: 26,
  },
  {
    lat: 40.709329,
    lng: -74.0131196,
    squareFootage: "66000",
    propertyType: "Multifamily",
    totalCosts: "52850000",
    valueOfReclassifiedAssets: 5574614,
    depreciableAssets: 12,
  },
  {
    lat: 40.7686973,
    lng: -73.9918181,
    squareFootage: "81050",
    propertyType: "Multifamily",
    totalCosts: "72000000",
    valueOfReclassifiedAssets: 13210187,
    depreciableAssets: 21,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "37598",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1728220,
    depreciableAssets: 34,
  },
  {
    lat: 38.8095735,
    lng: -90.3465971,
    squareFootage: "75675",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 2247658,
    depreciableAssets: 33,
  },
  {
    lat: 29.1561929,
    lng: -82.2039882,
    squareFootage: "19399",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3029586",
    valueOfReclassifiedAssets: 873879,
    depreciableAssets: 38,
  },
  {
    lat: 29.560052,
    lng: -98.3636131,
    squareFootage: "55947",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13822905",
    valueOfReclassifiedAssets: 4529220,
    depreciableAssets: 37,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "17158",
    propertyType: "Office",
    totalCosts: "1685000",
    valueOfReclassifiedAssets: 323391,
    depreciableAssets: 23,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "74717",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9925000",
    valueOfReclassifiedAssets: 2126334,
    depreciableAssets: 25,
  },
  {
    lat: 38.9638626,
    lng: -74.90918529999999,
    squareFootage: "80364",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2320000",
    valueOfReclassifiedAssets: 455413,
    depreciableAssets: 24,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "81700",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10821649",
    valueOfReclassifiedAssets: 8832368,
    depreciableAssets: 21,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "18494",
    propertyType: "Specialty",
    totalCosts: "2470445",
    valueOfReclassifiedAssets: 189327,
    depreciableAssets: 9,
  },
  {
    lat: 38.8372336,
    lng: -77.01614359999999,
    squareFootage: "68972",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "30000000",
    valueOfReclassifiedAssets: 8822270,
    depreciableAssets: 29,
  },
  {
    lat: 26.0197012,
    lng: -80.1819268,
    squareFootage: "58249",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6160256",
    valueOfReclassifiedAssets: 1485053,
    depreciableAssets: 33,
  },
  {
    lat: 29.2971256,
    lng: -81.0478643,
    squareFootage: "32044",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3313609",
    valueOfReclassifiedAssets: 765149,
    depreciableAssets: 27,
  },
  {
    lat: 27.4872771,
    lng: -82.5889012,
    squareFootage: "56844",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2082840",
    valueOfReclassifiedAssets: 300743,
    depreciableAssets: 19,
  },
  {
    lat: 28.760539,
    lng: -82.36688699999999,
    squareFootage: "42298",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10975000",
    valueOfReclassifiedAssets: 4051422,
    depreciableAssets: 40,
  },
  {
    lat: 34.1978087,
    lng: -99.32292129999999,
    squareFootage: "54000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7588507",
    valueOfReclassifiedAssets: 5787003,
    depreciableAssets: 32,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2011889",
    valueOfReclassifiedAssets: 1580953,
    depreciableAssets: 29,
  },
  {
    lat: 29.0846585,
    lng: -82.36688699999999,
    squareFootage: "34682",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5301775",
    valueOfReclassifiedAssets: 1489339,
    depreciableAssets: 39,
  },
  {
    lat: 32.3560371,
    lng: -99.8348146,
    squareFootage: "67453",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7900843",
    valueOfReclassifiedAssets: 2200984,
    depreciableAssets: 31,
  },
  {
    lat: 25.9904176,
    lng: -80.3547286,
    squareFootage: "77310",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18620940",
    valueOfReclassifiedAssets: 14522457,
    depreciableAssets: 28,
  },
  {
    lat: 25.9868744,
    lng: -80.2153998,
    squareFootage: "61565",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9339744",
    valueOfReclassifiedAssets: 2834894,
    depreciableAssets: 32,
  },
  {
    lat: 28.0003221,
    lng: -82.5889012,
    squareFootage: "34069",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3944000",
    valueOfReclassifiedAssets: 2912032,
    depreciableAssets: 32,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "14062",
    propertyType: "Office",
    totalCosts: "2552793",
    valueOfReclassifiedAssets: 254344,
    depreciableAssets: 11,
  },
  {
    lat: 33.5373926,
    lng: -99.2785583,
    squareFootage: "23837",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1827500",
    valueOfReclassifiedAssets: 1154199,
    depreciableAssets: 43,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "477619",
    valueOfReclassifiedAssets: 55291,
    depreciableAssets: 14,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "259241",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "57190807",
    valueOfReclassifiedAssets: 9268749,
    depreciableAssets: 19,
  },
  {
    lat: 33.4988372,
    lng: -97.9157099,
    squareFootage: "58727",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7735972",
    valueOfReclassifiedAssets: 5841786,
    depreciableAssets: 33,
  },
  {
    lat: 28.9177956,
    lng: -81.8879884,
    squareFootage: "74600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15547110",
    valueOfReclassifiedAssets: 3979239,
    depreciableAssets: 26,
  },
  {
    lat: 33.8511616,
    lng: -98.544636,
    squareFootage: "89546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16210045",
    valueOfReclassifiedAssets: 4277971,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3102000",
    valueOfReclassifiedAssets: 2594067,
    depreciableAssets: 21,
  },
  {
    lat: 29.0846585,
    lng: -82.36688699999999,
    squareFootage: "18504",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2272189",
    valueOfReclassifiedAssets: 752966,
    depreciableAssets: 43,
  },
  {
    lat: 27.9046811,
    lng: -82.7616822,
    squareFootage: "69659",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 3667767,
    depreciableAssets: 25,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "527028",
    valueOfReclassifiedAssets: 110012,
    depreciableAssets: 24,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "634081",
    valueOfReclassifiedAssets: 145121,
    depreciableAssets: 27,
  },
  {
    lat: 41.7920535,
    lng: -74.74541119999999,
    squareFootage: "61714",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2712000",
    valueOfReclassifiedAssets: 2232862,
    depreciableAssets: 23,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "130000",
    propertyType: "Industrial",
    totalCosts: "4220404",
    valueOfReclassifiedAssets: 3666352,
    depreciableAssets: 24,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "133686",
    propertyType: "Industrial",
    totalCosts: "3644830",
    valueOfReclassifiedAssets: 3183268,
    depreciableAssets: 24,
  },
  {
    lat: 31.7476241,
    lng: -95.26844369999999,
    squareFootage: "36660",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 471465,
    depreciableAssets: 34,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3701013",
    valueOfReclassifiedAssets: 2967336,
    depreciableAssets: 22,
  },
  {
    lat: 33.7489465,
    lng: -84.5484275,
    squareFootage: "454300",
    propertyType: "Industrial",
    totalCosts: "29500000",
    valueOfReclassifiedAssets: 12189741,
    depreciableAssets: 46,
  },
  {
    lat: 34.9803527,
    lng: -81.9425701,
    squareFootage: "572038",
    propertyType: "Industrial",
    totalCosts: "17327872",
    valueOfReclassifiedAssets: 16405588,
    depreciableAssets: 12,
  },
  {
    lat: 27.9852231,
    lng: -82.00801400000002,
    squareFootage: "1016430",
    propertyType: "Industrial",
    totalCosts: "87657507",
    valueOfReclassifiedAssets: 14119331,
    depreciableAssets: 17,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "1060433",
    propertyType: "Industrial",
    totalCosts: "31766675",
    valueOfReclassifiedAssets: 27472526,
    depreciableAssets: 25,
  },
  {
    lat: 36.0104644,
    lng: -80.1651854,
    squareFootage: "526320",
    propertyType: "Industrial",
    totalCosts: "25500000",
    valueOfReclassifiedAssets: 695820,
    depreciableAssets: 0,
  },
  {
    lat: 38.7902545,
    lng: -121.3770336,
    squareFootage: "198744",
    propertyType: "Industrial",
    totalCosts: "26637290",
    valueOfReclassifiedAssets: 6567421,
    depreciableAssets: 31,
  },
  {
    lat: 38.4244019,
    lng: -121.3312882,
    squareFootage: "17340",
    propertyType: "Retail",
    totalCosts: "7850000",
    valueOfReclassifiedAssets: 1211409,
    depreciableAssets: 28,
  },
  {
    lat: 39.9559288,
    lng: -75.1574567,
    squareFootage: "62000",
    propertyType: "Office",
    totalCosts: "7680305",
    valueOfReclassifiedAssets: 599115,
    depreciableAssets: 10,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "99180",
    propertyType: "Multifamily",
    totalCosts: "22001727",
    valueOfReclassifiedAssets: 2655914,
    depreciableAssets: 15,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "83430",
    propertyType: "Multifamily",
    totalCosts: "14755561",
    valueOfReclassifiedAssets: 1585540,
    depreciableAssets: 13,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16501595",
    valueOfReclassifiedAssets: 1375109,
    depreciableAssets: 10,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "161580",
    propertyType: "Multifamily",
    totalCosts: "52940555",
    valueOfReclassifiedAssets: 5998745,
    depreciableAssets: 14,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "46200",
    propertyType: "Multifamily",
    totalCosts: "12240000",
    valueOfReclassifiedAssets: 10288570,
    depreciableAssets: 18,
  },
  {
    lat: 40.7493096,
    lng: -73.8213213,
    squareFootage: "113348",
    propertyType: "Multifamily",
    totalCosts: "32650562",
    valueOfReclassifiedAssets: 2993088,
    depreciableAssets: 11,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "105300",
    propertyType: "Multifamily",
    totalCosts: "25000000",
    valueOfReclassifiedAssets: 3148974,
    depreciableAssets: 15,
  },
  {
    lat: 40.7956149,
    lng: -74.16509909999999,
    squareFootage: "324378",
    propertyType: "Multifamily",
    totalCosts: "35136000",
    valueOfReclassifiedAssets: 29500791,
    depreciableAssets: 19,
  },
  {
    lat: 40.72557219999999,
    lng: -73.8624893,
    squareFootage: "503000",
    propertyType: "Multifamily",
    totalCosts: "85250000",
    valueOfReclassifiedAssets: 10043723,
    depreciableAssets: 0,
  },
  {
    lat: 41.24262,
    lng: -75.87835679999999,
    squareFootage: "54487",
    propertyType: "Multifamily",
    totalCosts: "15800000",
    valueOfReclassifiedAssets: 3351827,
    depreciableAssets: 25,
  },
  {
    lat: 40.0358382,
    lng: -75.174734,
    squareFootage: "425535",
    propertyType: "Multifamily",
    totalCosts: "47250000",
    valueOfReclassifiedAssets: 6149918,
    depreciableAssets: 16,
  },
  {
    lat: 35.9834366,
    lng: -83.7942551,
    squareFootage: "169393",
    propertyType: "Multifamily",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 4155862,
    depreciableAssets: 30,
  },
  {
    lat: 37.7199247,
    lng: -122.1689284,
    squareFootage: "35478",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1509960,
    depreciableAssets: 24,
  },
  {
    lat: 45.4081316,
    lng: -122.8033845,
    squareFootage: "124602",
    propertyType: "Multifamily",
    totalCosts: "28000000",
    valueOfReclassifiedAssets: 9314146,
    depreciableAssets: 48,
  },
  {
    lat: 37.5021585,
    lng: -122.2086579,
    squareFootage: "51701",
    propertyType: "Multifamily",
    totalCosts: "15000000",
    valueOfReclassifiedAssets: 2946229,
    depreciableAssets: 23,
  },
  {
    lat: 37.5042267,
    lng: -121.9643745,
    squareFootage: "88705",
    propertyType: "Multifamily",
    totalCosts: "26500000",
    valueOfReclassifiedAssets: 8313480,
    depreciableAssets: 37,
  },
  {
    lat: 37.6737239,
    lng: -122.1007867,
    squareFootage: "23754",
    propertyType: "Multifamily",
    totalCosts: "4743566",
    valueOfReclassifiedAssets: 3496363,
    depreciableAssets: 32,
  },
  {
    lat: 45.5517361,
    lng: -122.5522483,
    squareFootage: "71200",
    propertyType: "Multifamily",
    totalCosts: "5795636",
    valueOfReclassifiedAssets: 4629988,
    depreciableAssets: 27,
  },
  {
    lat: 45.4081316,
    lng: -122.8033845,
    squareFootage: "124602",
    propertyType: "Multifamily",
    totalCosts: "28000000",
    valueOfReclassifiedAssets: 8648850,
    depreciableAssets: 48,
  },
  {
    lat: 32.7612759,
    lng: -117.0735241,
    squareFootage: "70418",
    propertyType: "Multifamily",
    totalCosts: "6845850",
    valueOfReclassifiedAssets: 5170737,
    depreciableAssets: 27,
  },
  {
    lat: 32.7612759,
    lng: -117.0735241,
    squareFootage: "70418",
    propertyType: "Multifamily",
    totalCosts: "6845850",
    valueOfReclassifiedAssets: 5170737,
    depreciableAssets: 27,
  },
  {
    lat: 34.0615492,
    lng: -118.8363756,
    squareFootage: "16711",
    propertyType: "Office",
    totalCosts: "5750000",
    valueOfReclassifiedAssets: 995531,
    depreciableAssets: 20,
  },
  {
    lat: 37.6737239,
    lng: -122.1007867,
    squareFootage: "23754",
    propertyType: "Multifamily",
    totalCosts: "4743566",
    valueOfReclassifiedAssets: 3496363,
    depreciableAssets: 32,
  },
  {
    lat: 37.5042267,
    lng: -121.9643745,
    squareFootage: "88705",
    propertyType: "Multifamily",
    totalCosts: "26500000",
    valueOfReclassifiedAssets: 6944200,
    depreciableAssets: 37,
  },
  {
    lat: 34.0931603,
    lng: -118.3783347,
    squareFootage: "1830",
    propertyType: "Multifamily",
    totalCosts: "1312329",
    valueOfReclassifiedAssets: 515756,
    depreciableAssets: 50,
  },
  {
    lat: 45.4395124,
    lng: -122.7711944,
    squareFootage: "32130",
    propertyType: "Multifamily",
    totalCosts: "2866243",
    valueOfReclassifiedAssets: 2247780,
    depreciableAssets: 30,
  },
  {
    lat: 37.6737239,
    lng: -122.1007867,
    squareFootage: "23754",
    propertyType: "Multifamily",
    totalCosts: "4743566",
    valueOfReclassifiedAssets: 3496363,
    depreciableAssets: 32,
  },
  {
    lat: 37.6737239,
    lng: -122.1007867,
    squareFootage: "23754",
    propertyType: "Multifamily",
    totalCosts: "4743566",
    valueOfReclassifiedAssets: 3496363,
    depreciableAssets: 32,
  },
  {
    lat: 38.6830879,
    lng: -121.3713164,
    squareFootage: "141440",
    propertyType: "Multifamily",
    totalCosts: "9300000",
    valueOfReclassifiedAssets: 2301771,
    depreciableAssets: 29,
  },
  {
    lat: 34.4400287,
    lng: -118.5914696,
    squareFootage: "26186",
    propertyType: "Retail",
    totalCosts: "6860000",
    valueOfReclassifiedAssets: 1966818,
    depreciableAssets: 33,
  },
  {
    lat: 32.7612759,
    lng: -117.0735241,
    squareFootage: "70418",
    propertyType: "Multifamily",
    totalCosts: "6845850",
    valueOfReclassifiedAssets: 5170737,
    depreciableAssets: 27,
  },
  {
    lat: 45.5517361,
    lng: -122.5522483,
    squareFootage: "71200",
    propertyType: "Multifamily",
    totalCosts: "5795636",
    valueOfReclassifiedAssets: 4629988,
    depreciableAssets: 27,
  },
  {
    lat: 34.06170609999999,
    lng: -118.2789735,
    squareFootage: "21992",
    propertyType: "Multifamily",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 148580,
    depreciableAssets: 11,
  },
  {
    lat: 38.912068,
    lng: -77.0190228,
    squareFootage: "117151",
    propertyType: "Multifamily",
    totalCosts: "32903170",
    valueOfReclassifiedAssets: 10992623,
    depreciableAssets: 40,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "61040",
    propertyType: "Multifamily",
    totalCosts: "6283161",
    valueOfReclassifiedAssets: 1944960,
    depreciableAssets: 37,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "63228",
    propertyType: "Multifamily",
    totalCosts: "12250000",
    valueOfReclassifiedAssets: 4219946,
    depreciableAssets: 40,
  },
  {
    lat: 38.6258567,
    lng: -121.3884671,
    squareFootage: "100100",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1019305,
    depreciableAssets: 25,
  },
  {
    lat: 33.9933257,
    lng: -118.3987842,
    squareFootage: "20043",
    propertyType: "Multifamily",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 1282138,
    depreciableAssets: 22,
  },
  {
    lat: 34.06170609999999,
    lng: -118.2789735,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4123118",
    valueOfReclassifiedAssets: 1167035,
    depreciableAssets: 32,
  },
  {
    lat: 39.7794206,
    lng: -85.7256372,
    squareFootage: "269812",
    propertyType: "Office",
    totalCosts: "45112414",
    valueOfReclassifiedAssets: 11466678,
    depreciableAssets: 30,
  },
  {
    lat: 33.159946,
    lng: -117.2852467,
    squareFootage: "100170",
    propertyType: "Multifamily",
    totalCosts: "20100000",
    valueOfReclassifiedAssets: 6752726,
    depreciableAssets: 39,
  },
  {
    lat: 34.0120229,
    lng: -117.6786847,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19675722",
    valueOfReclassifiedAssets: 15562802,
    depreciableAssets: 34,
  },
  {
    lat: 39.7794206,
    lng: -85.7256372,
    squareFootage: "269812",
    propertyType: "Office",
    totalCosts: "45112414",
    valueOfReclassifiedAssets: 7654189,
    depreciableAssets: 20,
  },
  {
    lat: 34.0745742,
    lng: -117.6962824,
    squareFootage: "77176",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2977827,
    depreciableAssets: 32,
  },
  {
    lat: 34.13566730000001,
    lng: -117.6141457,
    squareFootage: "127200",
    propertyType: "Multifamily",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 4961493,
    depreciableAssets: 34,
  },
  {
    lat: 33.159946,
    lng: -117.2852467,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4177395",
    valueOfReclassifiedAssets: 2754187,
    depreciableAssets: 47,
  },
  {
    lat: 39.7794206,
    lng: -85.7256372,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11577080",
    valueOfReclassifiedAssets: 9265297,
    depreciableAssets: 30,
  },
  {
    lat: 42.1285232,
    lng: -70.91901320000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8493612",
    valueOfReclassifiedAssets: 4217597,
    depreciableAssets: 62,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1929663",
    valueOfReclassifiedAssets: 686436,
    depreciableAssets: 0,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "123372",
    propertyType: "Multifamily",
    totalCosts: "3431072",
    valueOfReclassifiedAssets: 715961,
    depreciableAssets: 33,
  },
  {
    lat: 42.1285232,
    lng: -70.91901320000001,
    squareFootage: "37686",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2385284",
    valueOfReclassifiedAssets: 740894,
    depreciableAssets: 33,
  },
  {
    lat: 39.3614334,
    lng: -76.5892774,
    squareFootage: "206288",
    propertyType: "Multifamily",
    totalCosts: "10409346",
    valueOfReclassifiedAssets: 7820987,
    depreciableAssets: 31,
  },
  {
    lat: 39.4109996,
    lng: -76.5777203,
    squareFootage: "396300",
    propertyType: "Multifamily",
    totalCosts: "17078242",
    valueOfReclassifiedAssets: 13345363,
    depreciableAssets: 29,
  },
  {
    lat: 39.4109996,
    lng: -76.5777203,
    squareFootage: "396300",
    propertyType: "Multifamily",
    totalCosts: "26709794",
    valueOfReclassifiedAssets: 6110806,
    depreciableAssets: 29,
  },
  {
    lat: 39.5123182,
    lng: -76.18996609999999,
    squareFootage: "164496",
    propertyType: "Multifamily",
    totalCosts: "8000956",
    valueOfReclassifiedAssets: 6208759,
    depreciableAssets: 32,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "55075",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9786667",
    valueOfReclassifiedAssets: 5318089,
    depreciableAssets: 33,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "55075",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9786667",
    valueOfReclassifiedAssets: 3947150,
    depreciableAssets: 33,
  },
  {
    lat: 39.36851960000001,
    lng: -74.4408385,
    squareFootage: "79860",
    propertyType: "Multifamily",
    totalCosts: "3916089",
    valueOfReclassifiedAssets: 3077274,
    depreciableAssets: 27,
  },
  {
    lat: 40.5761692,
    lng: -74.22380199999999,
    squareFootage: "66902",
    propertyType: "Multifamily",
    totalCosts: "4707994",
    valueOfReclassifiedAssets: 3804104,
    depreciableAssets: 25,
  },
  {
    lat: 40.3450499,
    lng: -79.84648039999999,
    squareFootage: "20011",
    propertyType: "Retail",
    totalCosts: "314257",
    valueOfReclassifiedAssets: 279630,
    depreciableAssets: 24,
  },
  {
    lat: 34.1629905,
    lng: -118.2935891,
    squareFootage: "108464",
    propertyType: "Multifamily",
    totalCosts: "7281424",
    valueOfReclassifiedAssets: 1344424,
    depreciableAssets: 21,
  },
  {
    lat: 38.4001474,
    lng: -75.632447,
    squareFootage: "145984",
    propertyType: "Multifamily",
    totalCosts: "4053853",
    valueOfReclassifiedAssets: 3239948,
    depreciableAssets: 33,
  },
  {
    lat: 37.0538822,
    lng: -76.39845369999999,
    squareFootage: "535415",
    propertyType: "Multifamily",
    totalCosts: "28583098",
    valueOfReclassifiedAssets: 7614834,
    depreciableAssets: 31,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "84000",
    propertyType: "Multifamily",
    totalCosts: "8765569",
    valueOfReclassifiedAssets: 1722484,
    depreciableAssets: 28,
  },
  {
    lat: 40.4417703,
    lng: -80.08701549999999,
    squareFootage: "357560",
    propertyType: "Multifamily",
    totalCosts: "3936661",
    valueOfReclassifiedAssets: 2943082,
    depreciableAssets: 36,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1015091",
    valueOfReclassifiedAssets: 336255,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "33378",
    propertyType: "Office",
    totalCosts: "2257500",
    valueOfReclassifiedAssets: 352155,
    depreciableAssets: 18,
  },
  {
    lat: 36.1097128,
    lng: -79.6838874,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7323750",
    valueOfReclassifiedAssets: 1064472,
    depreciableAssets: 17,
  },
  {
    lat: 33.449373,
    lng: -86.8671989,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8220000",
    valueOfReclassifiedAssets: 6626727,
    depreciableAssets: 32,
  },
  {
    lat: 39.01771859999999,
    lng: -94.4490836,
    squareFootage: "215942",
    propertyType: "Retail",
    totalCosts: "10155927",
    valueOfReclassifiedAssets: 1941730,
    depreciableAssets: 26,
  },
  {
    lat: 35.8263369,
    lng: -79.0419053,
    squareFootage: "87130",
    propertyType: "Retail",
    totalCosts: "12585206",
    valueOfReclassifiedAssets: 3722814,
    depreciableAssets: 39,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "73519",
    propertyType: "Retail",
    totalCosts: "15354487",
    valueOfReclassifiedAssets: 4390125,
    depreciableAssets: 38,
  },
  {
    lat: 39.9741059,
    lng: -74.1592278,
    squareFootage: "120349",
    propertyType: "Retail",
    totalCosts: "21459865",
    valueOfReclassifiedAssets: 4563590,
    depreciableAssets: 28,
  },
  {
    lat: 39.0747341,
    lng: -94.4542311,
    squareFootage: "200325",
    propertyType: "Multifamily",
    totalCosts: "8525000",
    valueOfReclassifiedAssets: 1995846,
    depreciableAssets: 28,
  },
  {
    lat: 39.1055258,
    lng: -94.4645276,
    squareFootage: "43620",
    propertyType: "Multifamily",
    totalCosts: "2050000",
    valueOfReclassifiedAssets: 490546,
    depreciableAssets: 29,
  },
  {
    lat: 39.0632021,
    lng: -94.3462373,
    squareFootage: "88686",
    propertyType: "Multifamily",
    totalCosts: "3950000",
    valueOfReclassifiedAssets: 911940,
    depreciableAssets: 27,
  },
  {
    lat: 29.7002646,
    lng: -95.587986,
    squareFootage: "239550",
    propertyType: "Multifamily",
    totalCosts: "6568000",
    valueOfReclassifiedAssets: 1187158,
    depreciableAssets: 21,
  },
  {
    lat: 39.0747341,
    lng: -94.4542311,
    squareFootage: "200325",
    propertyType: "Multifamily",
    totalCosts: "5298068",
    valueOfReclassifiedAssets: 1107047,
    depreciableAssets: 28,
  },
  {
    lat: 39.1063108,
    lng: -94.30515419999999,
    squareFootage: "76264",
    propertyType: "Multifamily",
    totalCosts: "3675000",
    valueOfReclassifiedAssets: 910332,
    depreciableAssets: 29,
  },
  {
    lat: 39.20502279999999,
    lng: -94.70706159999999,
    squareFootage: "265154",
    propertyType: "Multifamily",
    totalCosts: "17900000",
    valueOfReclassifiedAssets: 4967145,
    depreciableAssets: 32,
  },
  {
    lat: 29.543368,
    lng: -95.8775964,
    squareFootage: "181556",
    propertyType: "Multifamily",
    totalCosts: "8215000",
    valueOfReclassifiedAssets: 2152841,
    depreciableAssets: 31,
  },
  {
    lat: 29.7002646,
    lng: -95.587986,
    squareFootage: "239550",
    propertyType: "Multifamily",
    totalCosts: "7680000",
    valueOfReclassifiedAssets: 1388150,
    depreciableAssets: 21,
  },
  {
    lat: 29.7002646,
    lng: -95.587986,
    squareFootage: "239550",
    propertyType: "Multifamily",
    totalCosts: "7680000",
    valueOfReclassifiedAssets: 1388150,
    depreciableAssets: 21,
  },
  {
    lat: 33.5784347,
    lng: -117.1617685,
    squareFootage: "380315",
    propertyType: "Retail",
    totalCosts: "57221089",
    valueOfReclassifiedAssets: 12613705,
    depreciableAssets: 25,
  },
  {
    lat: 33.5784347,
    lng: -117.1617685,
    squareFootage: "380315",
    propertyType: "Retail",
    totalCosts: "57221089",
    valueOfReclassifiedAssets: 12613705,
    depreciableAssets: 25,
  },
  {
    lat: 33.5784347,
    lng: -117.1617685,
    squareFootage: "380315",
    propertyType: "Retail",
    totalCosts: "56600000",
    valueOfReclassifiedAssets: 12455497,
    depreciableAssets: 25,
  },
  {
    lat: 29.41040379999999,
    lng: -98.63256109999999,
    squareFootage: "210999",
    propertyType: "Multifamily",
    totalCosts: "5718321",
    valueOfReclassifiedAssets: 4635750,
    depreciableAssets: 25,
  },
  {
    lat: 29.4651184,
    lng: -98.6765618,
    squareFootage: "135170",
    propertyType: "Multifamily",
    totalCosts: "5116392",
    valueOfReclassifiedAssets: 4123747,
    depreciableAssets: 25,
  },
  {
    lat: 29.5516834,
    lng: -98.49522280000001,
    squareFootage: "152760",
    propertyType: "Multifamily",
    totalCosts: "8346232",
    valueOfReclassifiedAssets: 6828619,
    depreciableAssets: 25,
  },
  {
    lat: 29.4981168,
    lng: -98.5226706,
    squareFootage: "194061",
    propertyType: "Multifamily",
    totalCosts: "8346232",
    valueOfReclassifiedAssets: 6703867,
    depreciableAssets: 26,
  },
  {
    lat: 36.5298904,
    lng: -82.5834947,
    squareFootage: "339046",
    propertyType: "Multifamily",
    totalCosts: "33423529",
    valueOfReclassifiedAssets: 9252220,
    depreciableAssets: 31,
  },
  {
    lat: 41.4334745,
    lng: -74.4760088,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15310881",
    valueOfReclassifiedAssets: 5995718,
    depreciableAssets: 44,
  },
  {
    lat: 47.4475151,
    lng: -122.3567244,
    squareFootage: "25661",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 772933,
    depreciableAssets: 29,
  },
  {
    lat: 27.6648274,
    lng: -81.5157535,
    squareFootage: "170390",
    propertyType: "Multifamily",
    totalCosts: "11493415",
    valueOfReclassifiedAssets: 2760231,
    depreciableAssets: 29,
  },
  {
    lat: 27.9552692,
    lng: -82.4563199,
    squareFootage: "222550",
    propertyType: "Multifamily",
    totalCosts: "13328652",
    valueOfReclassifiedAssets: 3420331,
    depreciableAssets: 30,
  },
  {
    lat: 26.5588224,
    lng: -81.8716058,
    squareFootage: "107608",
    propertyType: "Industrial",
    totalCosts: "3970685",
    valueOfReclassifiedAssets: 717226,
    depreciableAssets: 22,
  },
  {
    lat: 28.8821926,
    lng: -81.3179364,
    squareFootage: "61855",
    propertyType: "Industrial",
    totalCosts: "2180408",
    valueOfReclassifiedAssets: 458493,
    depreciableAssets: 25,
  },
  {
    lat: 28.1434318,
    lng: -82.33433749999999,
    squareFootage: "69984",
    propertyType: "Industrial",
    totalCosts: "4881061",
    valueOfReclassifiedAssets: 899216,
    depreciableAssets: 22,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "46861261",
    valueOfReclassifiedAssets: 5173503,
    depreciableAssets: 13,
  },
  {
    lat: 35.9952581,
    lng: -95.97268319999999,
    squareFootage: "132635",
    propertyType: "Multifamily",
    totalCosts: "17950000",
    valueOfReclassifiedAssets: 4335723,
    depreciableAssets: 29,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "34632",
    propertyType: "Office",
    totalCosts: "6380000",
    valueOfReclassifiedAssets: 790517,
    depreciableAssets: 20,
  },
  {
    lat: 42.026064,
    lng: -87.7271123,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1660622",
    valueOfReclassifiedAssets: 924941,
    depreciableAssets: 0,
  },
  {
    lat: 42.4204379,
    lng: -83.2398943,
    squareFootage: "20180",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3090210",
    valueOfReclassifiedAssets: 962320,
    depreciableAssets: 32,
  },
  {
    lat: 42.4312595,
    lng: -83.483215,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4774386",
    valueOfReclassifiedAssets: 2956573,
    depreciableAssets: 43,
  },
  {
    lat: 39.9682609,
    lng: -75.1749671,
    squareFootage: "35900",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5098833",
    valueOfReclassifiedAssets: 1605421,
    depreciableAssets: 37,
  },
  {
    lat: 42.736631,
    lng: -87.86479609999999,
    squareFootage: "35650",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4280000",
    valueOfReclassifiedAssets: 1193598,
    depreciableAssets: 32,
  },
  {
    lat: 42.720287,
    lng: -83.27735899999999,
    squareFootage: "35200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5183592",
    valueOfReclassifiedAssets: 1310648,
    depreciableAssets: 29,
  },
  {
    lat: 44.8245398,
    lng: -85.6640365,
    squareFootage: "38100",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5318000",
    valueOfReclassifiedAssets: 1596890,
    depreciableAssets: 32,
  },
  {
    lat: 42.2728525,
    lng: -83.57096399999999,
    squareFootage: "46240",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5003918",
    valueOfReclassifiedAssets: 1089396,
    depreciableAssets: 25,
  },
  {
    lat: 44.2914755,
    lng: -84.2278796,
    squareFootage: "34000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3933482",
    valueOfReclassifiedAssets: 1046912,
    depreciableAssets: 30,
  },
  {
    lat: 44.43794930000001,
    lng: -84.14351359999999,
    squareFootage: "45800",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6381000",
    valueOfReclassifiedAssets: 1688556,
    depreciableAssets: 27,
  },
  {
    lat: 42.2610481,
    lng: -88.9509626,
    squareFootage: "96609",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11273093",
    valueOfReclassifiedAssets: 2478148,
    depreciableAssets: 26,
  },
  {
    lat: 42.464947,
    lng: -83.0039075,
    squareFootage: "65428",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8737000",
    valueOfReclassifiedAssets: 1925797,
    depreciableAssets: 22,
  },
  {
    lat: 44.95464399999999,
    lng: -93.3847969,
    squareFootage: "74378",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5198667",
    valueOfReclassifiedAssets: 1220722,
    depreciableAssets: 27,
  },
  {
    lat: 43.1095503,
    lng: -88.0411993,
    squareFootage: "23046",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 338246,
    depreciableAssets: 29,
  },
  {
    lat: 41.9755524,
    lng: -87.803382,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1008786",
    valueOfReclassifiedAssets: 96047,
    depreciableAssets: 91,
  },
  {
    lat: 43.1156039,
    lng: -89.5308903,
    squareFootage: "41800",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 2184864,
    depreciableAssets: 39,
  },
  {
    lat: 44.9685475,
    lng: -93.3080409,
    squareFootage: "38802",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2795548",
    valueOfReclassifiedAssets: 629206,
    depreciableAssets: 27,
  },
  {
    lat: 43.1666739,
    lng: -87.9824758,
    squareFootage: "75221",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4327079",
    valueOfReclassifiedAssets: 761669,
    depreciableAssets: 20,
  },
  {
    lat: 45.1255339,
    lng: -93.45091239999999,
    squareFootage: "30335",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4117762",
    valueOfReclassifiedAssets: 836271,
    depreciableAssets: 24,
  },
  {
    lat: 42.6050452,
    lng: -83.38962219999999,
    squareFootage: "30400",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4804000",
    valueOfReclassifiedAssets: 1127264,
    depreciableAssets: 34,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "41780",
    propertyType: "Industrial",
    totalCosts: "3120000",
    valueOfReclassifiedAssets: 2713702,
    depreciableAssets: 34,
  },
  {
    lat: 36.0955555,
    lng: -79.4379412,
    squareFootage: "50400",
    propertyType: "Multifamily",
    totalCosts: "4440000",
    valueOfReclassifiedAssets: 1228331,
    depreciableAssets: 33,
  },
  {
    lat: 32.7762864,
    lng: -96.6958939,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6468882",
    valueOfReclassifiedAssets: 6011088,
    depreciableAssets: 26,
  },
  {
    lat: 32.4588558,
    lng: -97.7199454,
    squareFootage: "42113",
    propertyType: "Retail",
    totalCosts: "2436428",
    valueOfReclassifiedAssets: 2066020,
    depreciableAssets: 23,
  },
  {
    lat: 28.80663839999999,
    lng: -96.99609249999999,
    squareFootage: "126364",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 4730750,
    depreciableAssets: 25,
  },
  {
    lat: 33.1044628,
    lng: -96.642433,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 5181368,
    depreciableAssets: 27,
  },
  {
    lat: 45.0155505,
    lng: -93.0435224,
    squareFootage: "181637",
    propertyType: "Multifamily",
    totalCosts: "14725000",
    valueOfReclassifiedAssets: 2866340,
    depreciableAssets: 23,
  },
  {
    lat: 41.7962928,
    lng: -88.19741479999999,
    squareFootage: "125000",
    propertyType: "Multifamily",
    totalCosts: "12670000",
    valueOfReclassifiedAssets: 2253447,
    depreciableAssets: 20,
  },
  {
    lat: 35.2994482,
    lng: -120.6890055,
    squareFootage: "117960",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 2356444,
    depreciableAssets: 27,
  },
  {
    lat: 46.2138054,
    lng: -119.161041,
    squareFootage: "129600",
    propertyType: "Multifamily",
    totalCosts: "7635000",
    valueOfReclassifiedAssets: 1283935,
    depreciableAssets: 17,
  },
  {
    lat: 33.7321902,
    lng: -84.42251859999999,
    squareFootage: "66250",
    propertyType: "Multifamily",
    totalCosts: "4700000",
    valueOfReclassifiedAssets: 644570,
    depreciableAssets: 16,
  },
  {
    lat: 32.6858257,
    lng: -117.0382158,
    squareFootage: "402400",
    propertyType: "Multifamily",
    totalCosts: "51500000",
    valueOfReclassifiedAssets: 11217538,
    depreciableAssets: 24,
  },
  {
    lat: 45.0155505,
    lng: -93.0435224,
    squareFootage: "181637",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1362606,
    depreciableAssets: 23,
  },
  {
    lat: 33.7645595,
    lng: -117.8809574,
    squareFootage: "150066",
    propertyType: "Multifamily",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 4126864,
    depreciableAssets: 14,
  },
  {
    lat: 35.7919891,
    lng: -83.97971729999999,
    squareFootage: "103316",
    propertyType: "Multifamily",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 1735368,
    depreciableAssets: 29,
  },
  {
    lat: 40.1313166,
    lng: -87.6285099,
    squareFootage: "218336",
    propertyType: "Multifamily",
    totalCosts: "9600000",
    valueOfReclassifiedAssets: 1355861,
    depreciableAssets: 17,
  },
  {
    lat: 41.7962928,
    lng: -88.19741479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3817279",
    valueOfReclassifiedAssets: 2232283,
    depreciableAssets: 47,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "231582",
    propertyType: "Multifamily",
    totalCosts: "9200000",
    valueOfReclassifiedAssets: 7862775,
    depreciableAssets: 16,
  },
  {
    lat: 37.9429522,
    lng: -122.3900901,
    squareFootage: "131650",
    propertyType: "Multifamily",
    totalCosts: "19000000",
    valueOfReclassifiedAssets: 3886124,
    depreciableAssets: 23,
  },
  {
    lat: 21.3866667,
    lng: -158.0091667,
    squareFootage: "49040",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 5884800,
    depreciableAssets: 33,
  },
  {
    lat: 41.66515769999999,
    lng: -72.76291259999999,
    squareFootage: "238323",
    propertyType: "Multifamily",
    totalCosts: "13300000",
    valueOfReclassifiedAssets: 2412512,
    depreciableAssets: 21,
  },
  {
    lat: 34.0578814,
    lng: -118.3096648,
    squareFootage: "116664",
    propertyType: "Multifamily",
    totalCosts: "22500000",
    valueOfReclassifiedAssets: 2811615,
    depreciableAssets: 14,
  },
  {
    lat: 33.723039,
    lng: -84.3436881,
    squareFootage: "146288",
    propertyType: "Multifamily",
    totalCosts: "12600000",
    valueOfReclassifiedAssets: 2257337,
    depreciableAssets: 22,
  },
  {
    lat: 25.8127791,
    lng: -80.2377078,
    squareFootage: "110210",
    propertyType: "Multifamily",
    totalCosts: "14161686",
    valueOfReclassifiedAssets: 3153347,
    depreciableAssets: 28,
  },
  {
    lat: 21.3487298,
    lng: -158.0835221,
    squareFootage: "44060",
    propertyType: "Multifamily",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 2049542,
    depreciableAssets: 22,
  },
  {
    lat: 32.6059744,
    lng: -117.0441009,
    squareFootage: "76646",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 2044005,
    depreciableAssets: 31,
  },
  {
    lat: 41.8396817,
    lng: -71.38837509999999,
    squareFootage: "1464",
    propertyType: "Multifamily",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1519874,
    depreciableAssets: 16,
  },
  {
    lat: 43.3528674,
    lng: -79.8372024,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3453909",
    valueOfReclassifiedAssets: 2297679,
    depreciableAssets: 40,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "86967",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 1596173,
    depreciableAssets: 17,
  },
  {
    lat: 33.7532358,
    lng: -117.7901088,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1991907",
    valueOfReclassifiedAssets: 1404964,
    depreciableAssets: 35,
  },
  {
    lat: 35.0449767,
    lng: -85.31620660000002,
    squareFootage: "94550",
    propertyType: "Multifamily",
    totalCosts: "5600000",
    valueOfReclassifiedAssets: 690850,
    depreciableAssets: 14,
  },
  {
    lat: 34.0348144,
    lng: -117.5848025,
    squareFootage: "46810",
    propertyType: "Industrial",
    totalCosts: "4675000",
    valueOfReclassifiedAssets: 869821,
    depreciableAssets: 22,
  },
  {
    lat: 38.4803833,
    lng: -82.6537455,
    squareFootage: "115145",
    propertyType: "Hotel",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 227605,
    depreciableAssets: 8,
  },
  {
    lat: 38.4803833,
    lng: -82.6537455,
    squareFootage: "122831",
    propertyType: "Retail",
    totalCosts: "8512426",
    valueOfReclassifiedAssets: 7101878,
    depreciableAssets: 32,
  },
  {
    lat: 36.40516909999999,
    lng: -86.438974,
    squareFootage: "0",
    propertyType: "Retail",
    totalCosts: "5383226",
    valueOfReclassifiedAssets: 4389020,
    depreciableAssets: 36,
  },
  {
    lat: 28.2984565,
    lng: -82.6159271,
    squareFootage: "121413",
    propertyType: "Retail",
    totalCosts: "9250000",
    valueOfReclassifiedAssets: 2470406,
    depreciableAssets: 31,
  },
  {
    lat: 33.2045596,
    lng: -97.06059979999999,
    squareFootage: "66235",
    propertyType: "Industrial",
    totalCosts: "2575654",
    valueOfReclassifiedAssets: 461491,
    depreciableAssets: 26,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "44481",
    propertyType: "Office",
    totalCosts: "15779000",
    valueOfReclassifiedAssets: 3245224,
    depreciableAssets: 26,
  },
  {
    lat: 40.2303787,
    lng: -74.27661859999999,
    squareFootage: "59257",
    propertyType: "Office",
    totalCosts: "9762844",
    valueOfReclassifiedAssets: 1631750,
    depreciableAssets: 21,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "47752",
    propertyType: "Office",
    totalCosts: "4585751",
    valueOfReclassifiedAssets: 475207,
    depreciableAssets: 11,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "47752",
    propertyType: "Office",
    totalCosts: "2150000",
    valueOfReclassifiedAssets: 237522,
    depreciableAssets: 11,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "47752",
    propertyType: "Office",
    totalCosts: "4450604",
    valueOfReclassifiedAssets: 461203,
    depreciableAssets: 11,
  },
  {
    lat: 33.6805008,
    lng: -96.7279918,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "6350000",
    valueOfReclassifiedAssets: 1739369,
    depreciableAssets: 32,
  },
  {
    lat: 38.7575167,
    lng: -77.1312586,
    squareFootage: "350352",
    propertyType: "Multifamily",
    totalCosts: "86228500",
    valueOfReclassifiedAssets: 23894051,
    depreciableAssets: 33,
  },
  {
    lat: 34.1252099,
    lng: -81.18577359999999,
    squareFootage: "2016",
    propertyType: "Multifamily",
    totalCosts: "25387717",
    valueOfReclassifiedAssets: 5268225,
    depreciableAssets: 24,
  },
  {
    lat: 38.8327423,
    lng: -77.2060241,
    squareFootage: "1289060",
    propertyType: "Multifamily",
    totalCosts: "236279000",
    valueOfReclassifiedAssets: 55604711,
    depreciableAssets: 27,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "1102179",
    propertyType: "Office",
    totalCosts: "1509150711",
    valueOfReclassifiedAssets: 1350192248,
    depreciableAssets: 11,
  },
  {
    lat: 40.7686973,
    lng: -73.9918181,
    squareFootage: "1790263",
    propertyType: "Office",
    totalCosts: "1650000000",
    valueOfReclassifiedAssets: 126895983,
    depreciableAssets: 10,
  },
  {
    lat: 29.9335594,
    lng: -90.07000509999999,
    squareFootage: "636719",
    propertyType: "Multifamily",
    totalCosts: "137428503",
    valueOfReclassifiedAssets: 30467145,
    depreciableAssets: 25,
  },
  {
    lat: 38.864187,
    lng: -77.2577571,
    squareFootage: "871586",
    propertyType: "Multifamily",
    totalCosts: "165492500",
    valueOfReclassifiedAssets: 133552454,
    depreciableAssets: 26,
  },
  {
    lat: 36.5798297,
    lng: -94.7484518,
    squareFootage: "116119",
    propertyType: "Office",
    totalCosts: "75000000",
    valueOfReclassifiedAssets: 14966257,
    depreciableAssets: 24,
  },
  {
    lat: 38.864187,
    lng: -77.2577571,
    squareFootage: "871586",
    propertyType: "Multifamily",
    totalCosts: "165492500",
    valueOfReclassifiedAssets: 37415474,
    depreciableAssets: 26,
  },
  {
    lat: 33.9989114,
    lng: -81.27390729999999,
    squareFootage: "365425",
    propertyType: "Multifamily",
    totalCosts: "25213750",
    valueOfReclassifiedAssets: 5700688,
    depreciableAssets: 26,
  },
  {
    lat: 40.8562383,
    lng: -73.96537150000002,
    squareFootage: "215036",
    propertyType: "Office",
    totalCosts: "49630297",
    valueOfReclassifiedAssets: 8563937,
    depreciableAssets: 20,
  },
  {
    lat: 33.5857518,
    lng: -81.71305219999999,
    squareFootage: "365425",
    propertyType: "Multifamily",
    totalCosts: "33816013",
    valueOfReclassifiedAssets: 7010824,
    depreciableAssets: 24,
  },
  {
    lat: 34.0723572,
    lng: -81.2188355,
    squareFootage: "449236",
    propertyType: "Multifamily",
    totalCosts: "42800888",
    valueOfReclassifiedAssets: 8534321,
    depreciableAssets: 22,
  },
  {
    lat: 35.0474721,
    lng: -81.9643913,
    squareFootage: "256044",
    propertyType: "Multifamily",
    totalCosts: "28115898",
    valueOfReclassifiedAssets: 5982581,
    depreciableAssets: 24,
  },
  {
    lat: 30.3911214,
    lng: -91.0634024,
    squareFootage: "385903",
    propertyType: "Multifamily",
    totalCosts: "61755099",
    valueOfReclassifiedAssets: 16121964,
    depreciableAssets: 30,
  },
  {
    lat: 37.0193161,
    lng: -94.5005808,
    squareFootage: "113730",
    propertyType: "Office",
    totalCosts: "70000000",
    valueOfReclassifiedAssets: 10564983,
    depreciableAssets: 18,
  },
  {
    lat: 34.1289479,
    lng: -79.6838874,
    squareFootage: "287895",
    propertyType: "Multifamily",
    totalCosts: "44761211",
    valueOfReclassifiedAssets: 10498987,
    depreciableAssets: 27,
  },
  {
    lat: 33.53153440000001,
    lng: -82.0733985,
    squareFootage: "320752",
    propertyType: "Multifamily",
    totalCosts: "40091096",
    valueOfReclassifiedAssets: 11106974,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "114172",
    propertyType: "Office",
    totalCosts: "178144488",
    valueOfReclassifiedAssets: 33211159,
    depreciableAssets: 22,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "248832",
    propertyType: "Multifamily",
    totalCosts: "23619967",
    valueOfReclassifiedAssets: 5016508,
    depreciableAssets: 24,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7263989",
    valueOfReclassifiedAssets: 1452411,
    depreciableAssets: 23,
  },
  {
    lat: 40.7602619,
    lng: -73.9932872,
    squareFootage: "460966",
    propertyType: "Multifamily",
    totalCosts: "361000000",
    valueOfReclassifiedAssets: 29822324,
    depreciableAssets: 0,
  },
  {
    lat: 36.2894696,
    lng: -82.3560389,
    squareFootage: "397037",
    propertyType: "Multifamily",
    totalCosts: "55074629",
    valueOfReclassifiedAssets: 14987312,
    depreciableAssets: 32,
  },
  {
    lat: 37.7485824,
    lng: -122.4184108,
    squareFootage: "2015",
    propertyType: "Multifamily",
    totalCosts: "1457821413",
    valueOfReclassifiedAssets: 300957755,
    depreciableAssets: 26,
  },
  {
    lat: 39.7541032,
    lng: -105.0002242,
    squareFootage: "1439101",
    propertyType: "Hotel",
    totalCosts: "240000000",
    valueOfReclassifiedAssets: 21385249,
    depreciableAssets: 13,
  },
  {
    lat: 39.760071,
    lng: -75.53349279999999,
    squareFootage: "135385",
    propertyType: "Multifamily",
    totalCosts: "10821556",
    valueOfReclassifiedAssets: 3067221,
    depreciableAssets: 33,
  },
  {
    lat: 40.5090949,
    lng: -74.27661859999999,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "4619451",
    valueOfReclassifiedAssets: 1428564,
    depreciableAssets: 36,
  },
  {
    lat: 40.09944249999999,
    lng: -74.9325683,
    squareFootage: "199960",
    propertyType: "Multifamily",
    totalCosts: "17132651",
    valueOfReclassifiedAssets: 4344514,
    depreciableAssets: 28,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "700324",
    valueOfReclassifiedAssets: 502423,
    depreciableAssets: 39,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "216720",
    propertyType: "Multifamily",
    totalCosts: "12300000",
    valueOfReclassifiedAssets: 3601532,
    depreciableAssets: 35,
  },
  {
    lat: 38.7999723,
    lng: -77.0506896,
    squareFootage: "15891",
    propertyType: "Office",
    totalCosts: "4556865",
    valueOfReclassifiedAssets: 4098889,
    depreciableAssets: 13,
  },
  {
    lat: 38.8178384,
    lng: -76.9354979,
    squareFootage: "622525",
    propertyType: "Retail",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 4810255,
    depreciableAssets: 25,
  },
  {
    lat: 38.9191485,
    lng: -77.0362967,
    squareFootage: "13156",
    propertyType: "Multifamily",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 1037964,
    depreciableAssets: 25,
  },
  {
    lat: 38.8628755,
    lng: -76.97006689999999,
    squareFootage: "22073",
    propertyType: "Retail",
    totalCosts: "5793663",
    valueOfReclassifiedAssets: 4973421,
    depreciableAssets: 18,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4114427",
    valueOfReclassifiedAssets: 2837165,
    depreciableAssets: 40,
  },
  {
    lat: 39.4241558,
    lng: -77.3668965,
    squareFootage: "66629",
    propertyType: "Office",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 1326023,
    depreciableAssets: 0,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "164520",
    propertyType: "Multifamily",
    totalCosts: "11223000",
    valueOfReclassifiedAssets: 2291644,
    depreciableAssets: 24,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4017631",
    valueOfReclassifiedAssets: 2586136,
    depreciableAssets: 44,
  },
  {
    lat: 39.8227054,
    lng: -75.11951069999999,
    squareFootage: "433831",
    propertyType: "Multifamily",
    totalCosts: "64600000",
    valueOfReclassifiedAssets: 16968575,
    depreciableAssets: 31,
  },
  {
    lat: 38.4319863,
    lng: -77.99670429999999,
    squareFootage: "95447",
    propertyType: "Retail",
    totalCosts: "10394162",
    valueOfReclassifiedAssets: 2049412,
    depreciableAssets: 23,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "13280",
    propertyType: "Multifamily",
    totalCosts: "11558023",
    valueOfReclassifiedAssets: 2246062,
    depreciableAssets: 21,
  },
  {
    lat: 28.5513952,
    lng: -81.30142839999999,
    squareFootage: "621816",
    propertyType: "Multifamily",
    totalCosts: "64134000",
    valueOfReclassifiedAssets: 13295870,
    depreciableAssets: 25,
  },
  {
    lat: 35.1743999,
    lng: -80.9041817,
    squareFootage: "248800",
    propertyType: "Multifamily",
    totalCosts: "11850000",
    valueOfReclassifiedAssets: 4520085,
    depreciableAssets: 44,
  },
  {
    lat: 28.6820373,
    lng: -81.26289609999999,
    squareFootage: "629190",
    propertyType: "Multifamily",
    totalCosts: "54000000",
    valueOfReclassifiedAssets: 12449627,
    depreciableAssets: 27,
  },
  {
    lat: 32.3730312,
    lng: -90.1261013,
    squareFootage: "175104",
    propertyType: "Multifamily",
    totalCosts: "11774751",
    valueOfReclassifiedAssets: 9163202,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "259878",
    propertyType: "Multifamily",
    totalCosts: "52000000",
    valueOfReclassifiedAssets: 10709582,
    depreciableAssets: 24,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "44748",
    propertyType: "Multifamily",
    totalCosts: "9943255",
    valueOfReclassifiedAssets: 2200819,
    depreciableAssets: 24,
  },
  {
    lat: 36.1410253,
    lng: -86.67118169999999,
    squareFootage: "258594",
    propertyType: "Multifamily",
    totalCosts: "22750000",
    valueOfReclassifiedAssets: 6141515,
    depreciableAssets: 32,
  },
  {
    lat: 28.1543081,
    lng: -80.6437906,
    squareFootage: "107540",
    propertyType: "Multifamily",
    totalCosts: "10055000",
    valueOfReclassifiedAssets: 2154969,
    depreciableAssets: 25,
  },
  {
    lat: 26.2551386,
    lng: -80.2042436,
    squareFootage: "229814",
    propertyType: "Multifamily",
    totalCosts: "36400000",
    valueOfReclassifiedAssets: 8911820,
    depreciableAssets: 29,
  },
  {
    lat: 35.195314,
    lng: -80.7491563,
    squareFootage: "172405",
    propertyType: "Multifamily",
    totalCosts: "16475000",
    valueOfReclassifiedAssets: 3337319,
    depreciableAssets: 24,
  },
  {
    lat: 26.208305,
    lng: -80.2685032,
    squareFootage: "208460",
    propertyType: "Multifamily",
    totalCosts: "27200000",
    valueOfReclassifiedAssets: 5752866,
    depreciableAssets: 25,
  },
  {
    lat: 28.4833031,
    lng: -81.3234382,
    squareFootage: "433516",
    propertyType: "Multifamily",
    totalCosts: "51300000",
    valueOfReclassifiedAssets: 16280561,
    depreciableAssets: 38,
  },
  {
    lat: 27.966866,
    lng: -80.7879407,
    squareFootage: "402780",
    propertyType: "Multifamily",
    totalCosts: "22050000",
    valueOfReclassifiedAssets: 4689020,
    depreciableAssets: 25,
  },
  {
    lat: 28.3887262,
    lng: -81.4773294,
    squareFootage: "326076",
    propertyType: "Multifamily",
    totalCosts: "50650000",
    valueOfReclassifiedAssets: 10344976,
    depreciableAssets: 24,
  },
  {
    lat: 35.9657059,
    lng: -78.8042828,
    squareFootage: "330060",
    propertyType: "Multifamily",
    totalCosts: "41200000",
    valueOfReclassifiedAssets: 11674252,
    depreciableAssets: 34,
  },
  {
    lat: 28.6092911,
    lng: -81.30142839999999,
    squareFootage: "242520",
    propertyType: "Multifamily",
    totalCosts: "28650000",
    valueOfReclassifiedAssets: 7038000,
    depreciableAssets: 29,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "21450",
    propertyType: "Multifamily",
    totalCosts: "13740115",
    valueOfReclassifiedAssets: 2850524,
    depreciableAssets: 22,
  },
  {
    lat: 35.9657059,
    lng: -78.8042828,
    squareFootage: "197920",
    propertyType: "Multifamily",
    totalCosts: "23200000",
    valueOfReclassifiedAssets: 7171024,
    depreciableAssets: 36,
  },
  {
    lat: 28.1543081,
    lng: -80.6437906,
    squareFootage: "386418",
    propertyType: "Multifamily",
    totalCosts: "14219010",
    valueOfReclassifiedAssets: 11034385,
    depreciableAssets: 33,
  },
  {
    lat: 34.0235914,
    lng: -84.59557319999999,
    squareFootage: "504732",
    propertyType: "Multifamily",
    totalCosts: "40797600",
    valueOfReclassifiedAssets: 8527335,
    depreciableAssets: 25,
  },
  {
    lat: 34.0235914,
    lng: -84.59557319999999,
    squareFootage: "296868",
    propertyType: "Multifamily",
    totalCosts: "30402400",
    valueOfReclassifiedAssets: 7110563,
    depreciableAssets: 27,
  },
  {
    lat: 40.0542448,
    lng: -85.95089229999999,
    squareFootage: "380018",
    propertyType: "Multifamily",
    totalCosts: "24475000",
    valueOfReclassifiedAssets: 5342417,
    depreciableAssets: 26,
  },
  {
    lat: 28.5417016,
    lng: -81.2408689,
    squareFootage: "276486",
    propertyType: "Multifamily",
    totalCosts: "20476000",
    valueOfReclassifiedAssets: 4440961,
    depreciableAssets: 26,
  },
  {
    lat: 36.0592679,
    lng: -86.67060769999999,
    squareFootage: "208000",
    propertyType: "Multifamily",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 4022604,
    depreciableAssets: 35,
  },
  {
    lat: 28.0298719,
    lng: -80.6049374,
    squareFootage: "314268",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 4426173,
    depreciableAssets: 24,
  },
  {
    lat: 28.0298719,
    lng: -80.6049374,
    squareFootage: "96510",
    propertyType: "Multifamily",
    totalCosts: "7945000",
    valueOfReclassifiedAssets: 1605321,
    depreciableAssets: 23,
  },
  {
    lat: 40.0223314,
    lng: -74.95594799999999,
    squareFootage: "101573",
    propertyType: "Retail",
    totalCosts: "13736106",
    valueOfReclassifiedAssets: 3570318,
    depreciableAssets: 27,
  },
  {
    lat: 39.8383777,
    lng: -74.9325683,
    squareFootage: "225000",
    propertyType: "Office",
    totalCosts: "19254088",
    valueOfReclassifiedAssets: 3030256,
    depreciableAssets: 19,
  },
  {
    lat: 40.0223314,
    lng: -74.95594799999999,
    squareFootage: "101573",
    propertyType: "Retail",
    totalCosts: "13736106",
    valueOfReclassifiedAssets: 3570318,
    depreciableAssets: 27,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4222555",
    valueOfReclassifiedAssets: 3576588,
    depreciableAssets: 16,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "153909",
    propertyType: "Multifamily",
    totalCosts: "38179548",
    valueOfReclassifiedAssets: 31763304,
    depreciableAssets: 19,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "17030",
    propertyType: "Multifamily",
    totalCosts: "7192618",
    valueOfReclassifiedAssets: 6285337,
    depreciableAssets: 14,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "60133",
    propertyType: "Multifamily",
    totalCosts: "12850514",
    valueOfReclassifiedAssets: 10870229,
    depreciableAssets: 16,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "60755",
    propertyType: "Multifamily",
    totalCosts: "17603385",
    valueOfReclassifiedAssets: 15534954,
    depreciableAssets: 13,
  },
  {
    lat: 41.0983697,
    lng: -95.93040390000002,
    squareFootage: "116946",
    propertyType: "Office",
    totalCosts: "8910287",
    valueOfReclassifiedAssets: 2086442,
    depreciableAssets: 30,
  },
  {
    lat: 41.0983697,
    lng: -95.93040390000002,
    squareFootage: "116946",
    propertyType: "Office",
    totalCosts: "8710287",
    valueOfReclassifiedAssets: 2026584,
    depreciableAssets: 30,
  },
  {
    lat: 40.6311567,
    lng: -74.1386767,
    squareFootage: "2011",
    propertyType: "Multifamily",
    totalCosts: "18168205",
    valueOfReclassifiedAssets: 3533147,
    depreciableAssets: 22,
  },
  {
    lat: 39.9600168,
    lng: -75.22748659999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1201226",
    valueOfReclassifiedAssets: 1090921,
    depreciableAssets: 9,
  },
  {
    lat: 39.9600168,
    lng: -75.22748659999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1377771",
    valueOfReclassifiedAssets: 1251255,
    depreciableAssets: 9,
  },
  {
    lat: 42.2592911,
    lng: -71.46555649999999,
    squareFootage: "90888",
    propertyType: "Retail",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 8434879,
    depreciableAssets: 21,
  },
  {
    lat: 43.4655991,
    lng: -71.46555649999999,
    squareFootage: "10041",
    propertyType: "Office",
    totalCosts: "870000",
    valueOfReclassifiedAssets: 187697,
    depreciableAssets: 25,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "19952",
    propertyType: "Multifamily",
    totalCosts: "4345370",
    valueOfReclassifiedAssets: 576884,
    depreciableAssets: 15,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "10470",
    propertyType: "Multifamily",
    totalCosts: "6585909",
    valueOfReclassifiedAssets: 1536345,
    depreciableAssets: 26,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "13920",
    propertyType: "Multifamily",
    totalCosts: "4378993",
    valueOfReclassifiedAssets: 750775,
    depreciableAssets: 18,
  },
  {
    lat: 40.8138912,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "46568220",
    valueOfReclassifiedAssets: 5223806,
    depreciableAssets: 15,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "27372",
    propertyType: "Multifamily",
    totalCosts: "6275419",
    valueOfReclassifiedAssets: 1107103,
    depreciableAssets: 20,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "35745",
    propertyType: "Multifamily",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 455797,
    depreciableAssets: 15,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "51560",
    propertyType: "Multifamily",
    totalCosts: "18811243",
    valueOfReclassifiedAssets: 2461310,
    depreciableAssets: 16,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "29130",
    propertyType: "Multifamily",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 1096695,
    depreciableAssets: 17,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "117612",
    propertyType: "Multifamily",
    totalCosts: "12700000",
    valueOfReclassifiedAssets: 1794531,
    depreciableAssets: 19,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "10905",
    propertyType: "Multifamily",
    totalCosts: "7463706",
    valueOfReclassifiedAssets: 1249054,
    depreciableAssets: 18,
  },
  {
    lat: 37.9650627,
    lng: -122.503327,
    squareFootage: "6545",
    propertyType: "Retail",
    totalCosts: "1588128",
    valueOfReclassifiedAssets: 199172,
    depreciableAssets: 16,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9957838",
    valueOfReclassifiedAssets: 9361003,
    depreciableAssets: 0,
  },
  {
    lat: 42.9474532,
    lng: -78.8637428,
    squareFootage: "13164",
    propertyType: "Multifamily",
    totalCosts: "915000",
    valueOfReclassifiedAssets: 137676,
    depreciableAssets: 15,
  },
  {
    lat: 42.9474532,
    lng: -78.8637428,
    squareFootage: "29962",
    propertyType: "Multifamily",
    totalCosts: "2032335",
    valueOfReclassifiedAssets: 380343,
    depreciableAssets: 19,
  },
  {
    lat: 42.9149029,
    lng: -78.8750644,
    squareFootage: "41685",
    propertyType: "Multifamily",
    totalCosts: "2033000",
    valueOfReclassifiedAssets: 317991,
    depreciableAssets: 20,
  },
  {
    lat: 36.1155274,
    lng: -79.7512033,
    squareFootage: "151371",
    propertyType: "Retail",
    totalCosts: "4005864",
    valueOfReclassifiedAssets: 437794,
    depreciableAssets: 23,
  },
  {
    lat: 42.91379209999999,
    lng: -78.8637428,
    squareFootage: "57342",
    propertyType: "Multifamily",
    totalCosts: "4275001",
    valueOfReclassifiedAssets: 689351,
    depreciableAssets: 17,
  },
  {
    lat: 42.9334868,
    lng: -78.84392679999999,
    squareFootage: "44810",
    propertyType: "Multifamily",
    totalCosts: "3017665",
    valueOfReclassifiedAssets: 551568,
    depreciableAssets: 19,
  },
  {
    lat: 36.1449378,
    lng: -95.90399440000002,
    squareFootage: "30226",
    propertyType: "Multifamily",
    totalCosts: "862501",
    valueOfReclassifiedAssets: 209586,
    depreciableAssets: 28,
  },
  {
    lat: 42.8943161,
    lng: -78.8736493,
    squareFootage: "170000",
    propertyType: "Office",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 425156,
    depreciableAssets: 12,
  },
  {
    lat: 36.1964847,
    lng: -95.8459341,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1272735,
    depreciableAssets: 33,
  },
  {
    lat: 33.5394261,
    lng: -86.67118169999999,
    squareFootage: "228075",
    propertyType: "Retail",
    totalCosts: "3093750",
    valueOfReclassifiedAssets: 238505,
    depreciableAssets: 15,
  },
  {
    lat: 28.4588856,
    lng: -81.3949264,
    squareFootage: "132084",
    propertyType: "Retail",
    totalCosts: "6258264",
    valueOfReclassifiedAssets: 1199219,
    depreciableAssets: 32,
  },
  {
    lat: 33.5022594,
    lng: -86.74666610000001,
    squareFootage: "300280",
    propertyType: "Retail",
    totalCosts: "5524538",
    valueOfReclassifiedAssets: 631640,
    depreciableAssets: 17,
  },
  {
    lat: 36.1026614,
    lng: -95.88287509999999,
    squareFootage: "475827",
    propertyType: "Multifamily",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 3838134,
    depreciableAssets: 29,
  },
  {
    lat: 42.93975529999999,
    lng: -78.8099472,
    squareFootage: "72000",
    propertyType: "Multifamily",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 818498,
    depreciableAssets: 21,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "4",
    propertyType: "Multifamily",
    totalCosts: "3050640",
    valueOfReclassifiedAssets: 2560967,
    depreciableAssets: 19,
  },
  {
    lat: 27.905178,
    lng: -82.7158302,
    squareFootage: "93946",
    propertyType: "Office",
    totalCosts: "11267977",
    valueOfReclassifiedAssets: 10331794,
    depreciableAssets: 16,
  },
  {
    lat: 40.7769059,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1816567",
    valueOfReclassifiedAssets: 1540196,
    depreciableAssets: 0,
  },
  {
    lat: 32.2165669,
    lng: -110.8195292,
    squareFootage: "127900",
    propertyType: "Multifamily",
    totalCosts: "2951016",
    valueOfReclassifiedAssets: 961563,
    depreciableAssets: 39,
  },
  {
    lat: 43.2236974,
    lng: -77.03341790000002,
    squareFootage: "58768",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7700000",
    valueOfReclassifiedAssets: 2350535,
    depreciableAssets: 31,
  },
  {
    lat: 41.0813932,
    lng: -73.8536683,
    squareFootage: "38594",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3452706,
    depreciableAssets: 31,
  },
  {
    lat: 39.9358189,
    lng: -74.90918529999999,
    squareFootage: "99668",
    propertyType: "Office",
    totalCosts: "11300000",
    valueOfReclassifiedAssets: 1848883,
    depreciableAssets: 19,
  },
  {
    lat: 43.0481645,
    lng: -76.1473156,
    squareFootage: "305000",
    propertyType: "Office",
    totalCosts: "25250000",
    valueOfReclassifiedAssets: 4361817,
    depreciableAssets: 20,
  },
  {
    lat: 39.9358189,
    lng: -74.90918529999999,
    squareFootage: "108152",
    propertyType: "Office",
    totalCosts: "9518300",
    valueOfReclassifiedAssets: 1834106,
    depreciableAssets: 23,
  },
  {
    lat: 43.0481645,
    lng: -76.1473156,
    squareFootage: "316904",
    propertyType: "Office",
    totalCosts: "9450000",
    valueOfReclassifiedAssets: 1609935,
    depreciableAssets: 20,
  },
  {
    lat: 39.9358189,
    lng: -74.90918529999999,
    squareFootage: "186",
    propertyType: "Office",
    totalCosts: "56500000",
    valueOfReclassifiedAssets: 9366435,
    depreciableAssets: 19,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "51000",
    propertyType: "Multifamily",
    totalCosts: "10732500",
    valueOfReclassifiedAssets: 2109326,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "46464",
    propertyType: "Multifamily",
    totalCosts: "6440000",
    valueOfReclassifiedAssets: 647887,
    depreciableAssets: 11,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "79200",
    propertyType: "Multifamily",
    totalCosts: "19386250",
    valueOfReclassifiedAssets: 16727531,
    depreciableAssets: 16,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "47040",
    propertyType: "Multifamily",
    totalCosts: "17839400",
    valueOfReclassifiedAssets: 3016980,
    depreciableAssets: 19,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "55500",
    propertyType: "Multifamily",
    totalCosts: "8532500",
    valueOfReclassifiedAssets: 7381547,
    depreciableAssets: 14,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "88394",
    propertyType: "Multifamily",
    totalCosts: "9270000",
    valueOfReclassifiedAssets: 7836271,
    depreciableAssets: 15,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "20070",
    propertyType: "Multifamily",
    totalCosts: "5633453",
    valueOfReclassifiedAssets: 754516,
    depreciableAssets: 14,
  },
  {
    lat: 40.71700730000001,
    lng: -73.7683784,
    squareFootage: "34340",
    propertyType: "Multifamily",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 584351,
    depreciableAssets: 15,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "88900",
    propertyType: "Multifamily",
    totalCosts: "11900000",
    valueOfReclassifiedAssets: 1793890,
    depreciableAssets: 17,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "51000",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2041793,
    depreciableAssets: 22,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "65000",
    propertyType: "Multifamily",
    totalCosts: "21100000",
    valueOfReclassifiedAssets: 2529425,
    depreciableAssets: 14,
  },
  {
    lat: 40.71700730000001,
    lng: -73.7683784,
    squareFootage: "32000",
    propertyType: "Multifamily",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 401368,
    depreciableAssets: 13,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "27950",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 1989832,
    depreciableAssets: 26,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "62652",
    propertyType: "Multifamily",
    totalCosts: "7740000",
    valueOfReclassifiedAssets: 6559915,
    depreciableAssets: 16,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "62676",
    propertyType: "Multifamily",
    totalCosts: "8671500",
    valueOfReclassifiedAssets: 7475437,
    depreciableAssets: 15,
  },
  {
    lat: 40.737975,
    lng: -73.8801301,
    squareFootage: "187800",
    propertyType: "Multifamily",
    totalCosts: "56500000",
    valueOfReclassifiedAssets: 5307455,
    depreciableAssets: 11,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "27950",
    propertyType: "Multifamily",
    totalCosts: "8732500",
    valueOfReclassifiedAssets: 2044259,
    depreciableAssets: 26,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "58000",
    propertyType: "Multifamily",
    totalCosts: "8856000",
    valueOfReclassifiedAssets: 7234860,
    depreciableAssets: 20,
  },
  {
    lat: 40.7031635,
    lng: -73.8095574,
    squareFootage: "128961",
    propertyType: "Multifamily",
    totalCosts: "24660000",
    valueOfReclassifiedAssets: 20781033,
    depreciableAssets: 16,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "42880",
    propertyType: "Multifamily",
    totalCosts: "7660150",
    valueOfReclassifiedAssets: 910332,
    depreciableAssets: 13,
  },
  {
    lat: 41.1091091,
    lng: -74.0828839,
    squareFootage: "9840",
    propertyType: "Office",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 1530000,
    depreciableAssets: 100,
  },
  {
    lat: 41.1091091,
    lng: -74.0828839,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "355678",
    valueOfReclassifiedAssets: 211399,
    depreciableAssets: 43,
  },
  {
    lat: 43.6440864,
    lng: -79.420717,
    squareFootage: "81271",
    propertyType: "Retail",
    totalCosts: "7136754",
    valueOfReclassifiedAssets: 1765250,
    depreciableAssets: 30,
  },
  {
    lat: 41.0813932,
    lng: -73.8536683,
    squareFootage: "9766",
    propertyType: "Multifamily",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 416352,
    depreciableAssets: 25,
  },
  {
    lat: 40.7836826,
    lng: -73.4298073,
    squareFootage: "408919",
    propertyType: "Office",
    totalCosts: "64792683",
    valueOfReclassifiedAssets: 9033805,
    depreciableAssets: 18,
  },
  {
    lat: 35.1338614,
    lng: -106.6409198,
    squareFootage: "0",
    propertyType: "Multifamily",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 606550,
    depreciableAssets: 90,
  },
  {
    lat: 35.1338614,
    lng: -106.6409198,
    squareFootage: "5329",
    propertyType: "Multifamily",
    totalCosts: "357224",
    valueOfReclassifiedAssets: 182224,
    depreciableAssets: 100,
  },
  {
    lat: 35.4902575,
    lng: -81.27390729999999,
    squareFootage: "267740",
    propertyType: "Multifamily",
    totalCosts: "16092405",
    valueOfReclassifiedAssets: 3911087,
    depreciableAssets: 27,
  },
  {
    lat: 38.8021891,
    lng: -104.7229279,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "5841779",
    valueOfReclassifiedAssets: 982896,
    depreciableAssets: 19,
  },
  {
    lat: 41.0855853,
    lng: -73.4769458,
    squareFootage: "3700",
    propertyType: "Office",
    totalCosts: "1716667",
    valueOfReclassifiedAssets: 234624,
    depreciableAssets: 19,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "12374",
    propertyType: "Multifamily",
    totalCosts: "6464169",
    valueOfReclassifiedAssets: 1428814,
    depreciableAssets: 28,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "12349",
    propertyType: "Multifamily",
    totalCosts: "3503653",
    valueOfReclassifiedAssets: 850311,
    depreciableAssets: 30,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "12349",
    propertyType: "Multifamily",
    totalCosts: "942210",
    valueOfReclassifiedAssets: 228668,
    depreciableAssets: 30,
  },
  {
    lat: 40.6385229,
    lng: -74.0153231,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "755102",
    valueOfReclassifiedAssets: 292575,
    depreciableAssets: 45,
  },
  {
    lat: 31.1168297,
    lng: -97.725376,
    squareFootage: "10086",
    propertyType: "Multifamily",
    totalCosts: "850000",
    valueOfReclassifiedAssets: 196657,
    depreciableAssets: 27,
  },
  {
    lat: 38.8814089,
    lng: -75.78365889999999,
    squareFootage: "43535",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9986871",
    valueOfReclassifiedAssets: 2845241,
    depreciableAssets: 29,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "50000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13945165",
    valueOfReclassifiedAssets: 3948397,
    depreciableAssets: 25,
  },
  {
    lat: 39.5350229,
    lng: -105.1492869,
    squareFootage: "7430",
    propertyType: "Office",
    totalCosts: "9015000",
    valueOfReclassifiedAssets: 2378657,
    depreciableAssets: 31,
  },
  {
    lat: 37.7763747,
    lng: -93.56102600000001,
    squareFootage: "19832",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4475034",
    valueOfReclassifiedAssets: 1561907,
    depreciableAssets: 38,
  },
  {
    lat: 37.6189071,
    lng: -93.1177555,
    squareFootage: "20070",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2878103",
    valueOfReclassifiedAssets: 1071579,
    depreciableAssets: 41,
  },
  {
    lat: 36.6772411,
    lng: -93.8691413,
    squareFootage: "33697",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2674008",
    valueOfReclassifiedAssets: 773279,
    depreciableAssets: 32,
  },
  {
    lat: 38.9752926,
    lng: -94.46967649999999,
    squareFootage: "34160",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "961730",
    valueOfReclassifiedAssets: 271594,
    depreciableAssets: 32,
  },
  {
    lat: 37.9323059,
    lng: -90.9438468,
    squareFootage: "33682",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4549366",
    valueOfReclassifiedAssets: 1452663,
    depreciableAssets: 35,
  },
  {
    lat: 38.6357514,
    lng: -94.33596349999999,
    squareFootage: "40353",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5987963",
    valueOfReclassifiedAssets: 1982382,
    depreciableAssets: 36,
  },
  {
    lat: 37.304634,
    lng: -92.8976254,
    squareFootage: "23383",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2900698",
    valueOfReclassifiedAssets: 1060118,
    depreciableAssets: 40,
  },
  {
    lat: 42.86447709999999,
    lng: -85.6588999,
    squareFootage: "95237",
    propertyType: "Industrial",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 2111777,
    depreciableAssets: 14,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "38423",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5162449",
    valueOfReclassifiedAssets: 1025072,
    depreciableAssets: 22,
  },
  {
    lat: 40.215546,
    lng: -74.6049061,
    squareFootage: "95000",
    propertyType: "Office",
    totalCosts: "978046",
    valueOfReclassifiedAssets: 232135,
    depreciableAssets: 28,
  },
  {
    lat: 37.7909427,
    lng: -122.4084994,
    squareFootage: "10973",
    propertyType: "Office",
    totalCosts: "7069746",
    valueOfReclassifiedAssets: 1845440,
    depreciableAssets: 100,
  },
  {
    lat: 38.2034582,
    lng: -76.39845369999999,
    squareFootage: "327784",
    propertyType: "Multifamily",
    totalCosts: "38500000",
    valueOfReclassifiedAssets: 8750889,
    depreciableAssets: 29,
  },
  {
    lat: 38.5915927,
    lng: -76.8028933,
    squareFootage: "526000",
    propertyType: "Multifamily",
    totalCosts: "115000000",
    valueOfReclassifiedAssets: 23611045,
    depreciableAssets: 25,
  },
  {
    lat: 32.9533477,
    lng: -97.1467072,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1278371",
    valueOfReclassifiedAssets: 480069,
    depreciableAssets: 49,
  },
  {
    lat: 45.26599,
    lng: -111.25312,
    squareFootage: "1649",
    propertyType: "Multifamily",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 2438503,
    depreciableAssets: 0,
  },
  {
    lat: 40.773694,
    lng: -74.0270745,
    squareFootage: "9400",
    propertyType: "Office",
    totalCosts: "5129536",
    valueOfReclassifiedAssets: 631414,
    depreciableAssets: 14,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "9136",
    propertyType: "Multifamily",
    totalCosts: "948686",
    valueOfReclassifiedAssets: 165116,
    depreciableAssets: 22,
  },
  {
    lat: 34.0489277,
    lng: -118.3403506,
    squareFootage: "5630",
    propertyType: "Multifamily",
    totalCosts: "851353",
    valueOfReclassifiedAssets: 157202,
    depreciableAssets: 23,
  },
  {
    lat: 34.002058,
    lng: -118.2818967,
    squareFootage: "4144",
    propertyType: "Multifamily",
    totalCosts: "647235",
    valueOfReclassifiedAssets: 98366,
    depreciableAssets: 19,
  },
  {
    lat: 34.0963058,
    lng: -118.4980744,
    squareFootage: "8744",
    propertyType: "Multifamily",
    totalCosts: "2323017",
    valueOfReclassifiedAssets: 146776,
    depreciableAssets: 18,
  },
  {
    lat: 43.23236379999999,
    lng: -77.4472495,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "647381",
    valueOfReclassifiedAssets: 181918,
    depreciableAssets: 33,
  },
  {
    lat: 41.4963405,
    lng: -81.6719914,
    squareFootage: "33404",
    propertyType: "Office",
    totalCosts: "1329247",
    valueOfReclassifiedAssets: 186452,
    depreciableAssets: 19,
  },
  {
    lat: 47.1808437,
    lng: -119.28281,
    squareFootage: "19200",
    propertyType: "Multifamily",
    totalCosts: "1843543",
    valueOfReclassifiedAssets: 501204,
    depreciableAssets: 30,
  },
  {
    lat: 46.266417,
    lng: -119.8991511,
    squareFootage: "20200",
    propertyType: "Multifamily",
    totalCosts: "1101774",
    valueOfReclassifiedAssets: 296055,
    depreciableAssets: 28,
  },
  {
    lat: 40.6385229,
    lng: -74.0153231,
    squareFootage: "19000",
    propertyType: "Industrial",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 205986,
    depreciableAssets: 4,
  },
  {
    lat: 33.052083,
    lng: -117.2793685,
    squareFootage: "45013",
    propertyType: "Office",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 1914702,
    depreciableAssets: 20,
  },
  {
    lat: 41.7458012,
    lng: -87.60876379999999,
    squareFootage: "12000",
    propertyType: "Multifamily",
    totalCosts: "741484",
    valueOfReclassifiedAssets: 74874,
    depreciableAssets: 17,
  },
  {
    lat: 47.1907035,
    lng: -122.4448476,
    squareFootage: "115200",
    propertyType: "Industrial",
    totalCosts: "2923153",
    valueOfReclassifiedAssets: 256784,
    depreciableAssets: 11,
  },
  {
    lat: 39.2355282,
    lng: -119.9393608,
    squareFootage: "16252",
    propertyType: "Office",
    totalCosts: "3352342",
    valueOfReclassifiedAssets: 792511,
    depreciableAssets: 29,
  },
  {
    lat: 39.3809028,
    lng: -119.9277947,
    squareFootage: "10736",
    propertyType: "Multifamily",
    totalCosts: "1741961",
    valueOfReclassifiedAssets: 684894,
    depreciableAssets: 49,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "22911992",
    valueOfReclassifiedAssets: 20641080,
    depreciableAssets: 11,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "19637",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 574210,
    depreciableAssets: 10,
  },
  {
    lat: 36.1592882,
    lng: -95.99647829999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11361259",
    valueOfReclassifiedAssets: 9302049,
    depreciableAssets: 20,
  },
  {
    lat: 35.8906718,
    lng: -84.3331694,
    squareFootage: "57000",
    propertyType: "Multifamily",
    totalCosts: "2775000",
    valueOfReclassifiedAssets: 2319543,
    depreciableAssets: 23,
  },
  {
    lat: 30.1412524,
    lng: -93.2784825,
    squareFootage: "14490",
    propertyType: "Retail",
    totalCosts: "3394282",
    valueOfReclassifiedAssets: 523561,
    depreciableAssets: 22,
  },
  {
    lat: 40.6216016,
    lng: -74.22967129999999,
    squareFootage: "11000",
    propertyType: "Industrial",
    totalCosts: "1527736",
    valueOfReclassifiedAssets: 251480,
    depreciableAssets: 19,
  },
  {
    lat: 34.412912,
    lng: -84.14351359999999,
    squareFootage: "5500",
    propertyType: "Retail",
    totalCosts: "671842",
    valueOfReclassifiedAssets: 184312,
    depreciableAssets: 36,
  },
  {
    lat: 31.1783587,
    lng: -92.0077316,
    squareFootage: "9100",
    propertyType: "Retail",
    totalCosts: "719652",
    valueOfReclassifiedAssets: 644821,
    depreciableAssets: 29,
  },
  {
    lat: 40.7460879,
    lng: -99.7678687,
    squareFootage: "45576",
    propertyType: "Multifamily",
    totalCosts: "608798",
    valueOfReclassifiedAssets: 506448,
    depreciableAssets: 24,
  },
  {
    lat: 38.6272793,
    lng: -121.3198491,
    squareFootage: "17283",
    propertyType: "Industrial",
    totalCosts: "1251697",
    valueOfReclassifiedAssets: 363380,
    depreciableAssets: 33,
  },
  {
    lat: 40.8285039,
    lng: -73.8918897,
    squareFootage: "3930",
    propertyType: "Multifamily",
    totalCosts: "448264",
    valueOfReclassifiedAssets: 35857,
    depreciableAssets: 10,
  },
  {
    lat: 34.4854334,
    lng: -117.4203974,
    squareFootage: "48341",
    propertyType: "Hotel",
    totalCosts: "3388030",
    valueOfReclassifiedAssets: 737791,
    depreciableAssets: 31,
  },
  {
    lat: 32.8250767,
    lng: -117.2029363,
    squareFootage: "18600",
    propertyType: "Multifamily",
    totalCosts: "3418628",
    valueOfReclassifiedAssets: 371462,
    depreciableAssets: 33,
  },
  {
    lat: 32.96270750000001,
    lng: -96.56766250000001,
    squareFootage: "8716",
    propertyType: "Multifamily",
    totalCosts: "735000",
    valueOfReclassifiedAssets: 164459,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "780000",
    valueOfReclassifiedAssets: 606000,
    depreciableAssets: 0,
  },
  {
    lat: 47.6338217,
    lng: -122.3215448,
    squareFootage: "18265",
    propertyType: "Office",
    totalCosts: "3988487",
    valueOfReclassifiedAssets: 3209049,
    depreciableAssets: 22,
  },
  {
    lat: 39.8383777,
    lng: -74.9325683,
    squareFootage: "50916",
    propertyType: "Office",
    totalCosts: "921018",
    valueOfReclassifiedAssets: 185816,
    depreciableAssets: 23,
  },
  {
    lat: 39.8383777,
    lng: -74.9325683,
    squareFootage: "50916",
    propertyType: "Office",
    totalCosts: "1842036",
    valueOfReclassifiedAssets: 371631,
    depreciableAssets: 23,
  },
  {
    lat: 42.0105286,
    lng: -87.6926257,
    squareFootage: "31667",
    propertyType: "Multifamily",
    totalCosts: "4270000",
    valueOfReclassifiedAssets: 480713,
    depreciableAssets: 13,
  },
  {
    lat: 37.9876914,
    lng: -122.0098642,
    squareFootage: "10299",
    propertyType: "Retail",
    totalCosts: "805004",
    valueOfReclassifiedAssets: 138241,
    depreciableAssets: 25,
  },
  {
    lat: 37.9876914,
    lng: -122.0098642,
    squareFootage: "10299",
    propertyType: "Retail",
    totalCosts: "998588",
    valueOfReclassifiedAssets: 171485,
    depreciableAssets: 25,
  },
  {
    lat: 36.1724885,
    lng: -86.7805961,
    squareFootage: "159288",
    propertyType: "Hotel",
    totalCosts: "103500000",
    valueOfReclassifiedAssets: 15667240,
    depreciableAssets: 18,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "11925",
    propertyType: "Multifamily",
    totalCosts: "1405000",
    valueOfReclassifiedAssets: 212684,
    depreciableAssets: 18,
  },
  {
    lat: 30.2961708,
    lng: -97.73895429999999,
    squareFootage: "53814",
    propertyType: "Multifamily",
    totalCosts: "1586757",
    valueOfReclassifiedAssets: 1325239,
    depreciableAssets: 18,
  },
  {
    lat: 26.1889227,
    lng: -80.1763467,
    squareFootage: "118486",
    propertyType: "Multifamily",
    totalCosts: "13185000",
    valueOfReclassifiedAssets: 2617862,
    depreciableAssets: 21,
  },
  {
    lat: 40.0600713,
    lng: -75.3995089,
    squareFootage: "12390",
    propertyType: "Multifamily",
    totalCosts: "486258",
    valueOfReclassifiedAssets: 95048,
    depreciableAssets: 32,
  },
  {
    lat: 40.0600713,
    lng: -75.3995089,
    squareFootage: "12390",
    propertyType: "Multifamily",
    totalCosts: "853158",
    valueOfReclassifiedAssets: 166766,
    depreciableAssets: 32,
  },
  {
    lat: 39.5401545,
    lng: -119.7483949,
    squareFootage: "17937",
    propertyType: "Multifamily",
    totalCosts: "1462586",
    valueOfReclassifiedAssets: 372406,
    depreciableAssets: 36,
  },
  {
    lat: 42.8976471,
    lng: -71.2715443,
    squareFootage: "82000",
    propertyType: "Industrial",
    totalCosts: "6388709",
    valueOfReclassifiedAssets: 1657350,
    depreciableAssets: 35,
  },
  {
    lat: 42.98234410000001,
    lng: -70.8238874,
    squareFootage: "82240",
    propertyType: "Industrial",
    totalCosts: "8082132",
    valueOfReclassifiedAssets: 1496534,
    depreciableAssets: 22,
  },
  {
    lat: 18.335361,
    lng: -64.95340089999999,
    squareFootage: "118391",
    propertyType: "Industrial",
    totalCosts: "24842024",
    valueOfReclassifiedAssets: 5160051,
    depreciableAssets: 22,
  },
  {
    lat: 38.26435780000001,
    lng: -85.69997889999999,
    squareFootage: "64190",
    propertyType: "Industrial",
    totalCosts: "6386478",
    valueOfReclassifiedAssets: 2211992,
    depreciableAssets: 41,
  },
  {
    lat: 38.26435780000001,
    lng: -85.69997889999999,
    squareFootage: "78404",
    propertyType: "Industrial",
    totalCosts: "6086736",
    valueOfReclassifiedAssets: 1025212,
    depreciableAssets: 24,
  },
  {
    lat: 36.8653194,
    lng: -76.0507949,
    squareFootage: "35325",
    propertyType: "Industrial",
    totalCosts: "3209776",
    valueOfReclassifiedAssets: 652598,
    depreciableAssets: 23,
  },
  {
    lat: 33.4895398,
    lng: -112.256285,
    squareFootage: "80000",
    propertyType: "Industrial",
    totalCosts: "8618937",
    valueOfReclassifiedAssets: 1675979,
    depreciableAssets: 22,
  },
  {
    lat: 35.1642895,
    lng: -76.964306,
    squareFootage: "56840",
    propertyType: "Industrial",
    totalCosts: "6021461",
    valueOfReclassifiedAssets: 1216806,
    depreciableAssets: 21,
  },
  {
    lat: 34.7834102,
    lng: -76.8490328,
    squareFootage: "68574",
    propertyType: "Industrial",
    totalCosts: "9210960",
    valueOfReclassifiedAssets: 2072201,
    depreciableAssets: 23,
  },
  {
    lat: 34.7984709,
    lng: -76.9873477,
    squareFootage: "56400",
    propertyType: "Industrial",
    totalCosts: "11942366",
    valueOfReclassifiedAssets: 2883680,
    depreciableAssets: 25,
  },
  {
    lat: 35.1642895,
    lng: -76.964306,
    squareFootage: "102510",
    propertyType: "Industrial",
    totalCosts: "6804005",
    valueOfReclassifiedAssets: 3544539,
    depreciableAssets: 57,
  },
  {
    lat: 28.80663839999999,
    lng: -96.99609249999999,
    squareFootage: "58820",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 727613,
    depreciableAssets: 32,
  },
  {
    lat: 40.4458448,
    lng: -75.36453689999999,
    squareFootage: "35788",
    propertyType: "Retail",
    totalCosts: "2800000",
    valueOfReclassifiedAssets: 564914,
    depreciableAssets: 24,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "12360",
    propertyType: "Office",
    totalCosts: "2416808",
    valueOfReclassifiedAssets: 271396,
    depreciableAssets: 15,
  },
  {
    lat: 30.4069394,
    lng: -88.7686382,
    squareFootage: "89604",
    propertyType: "Multifamily",
    totalCosts: "4035799",
    valueOfReclassifiedAssets: 1158499,
    depreciableAssets: 34,
  },
  {
    lat: 33.1582404,
    lng: -96.47165799999999,
    squareFootage: "27150",
    propertyType: "Multifamily",
    totalCosts: "2160000",
    valueOfReclassifiedAssets: 487231,
    depreciableAssets: 28,
  },
  {
    lat: 47.4357921,
    lng: -95.4865446,
    squareFootage: "28236",
    propertyType: "Multifamily",
    totalCosts: "995000",
    valueOfReclassifiedAssets: 251857,
    depreciableAssets: 26,
  },
  {
    lat: 40.12707229999999,
    lng: -75.3353875,
    squareFootage: "14040",
    propertyType: "Office",
    totalCosts: "13288298",
    valueOfReclassifiedAssets: 2095149,
    depreciableAssets: 19,
  },
  {
    lat: 33.1376528,
    lng: -96.85654269999999,
    squareFootage: "21372",
    propertyType: "Retail",
    totalCosts: "6350658",
    valueOfReclassifiedAssets: 1327122,
    depreciableAssets: 28,
  },
  {
    lat: 32.8447037,
    lng: -97.47059349999999,
    squareFootage: "16836",
    propertyType: "Retail",
    totalCosts: "9003797",
    valueOfReclassifiedAssets: 2078706,
    depreciableAssets: 31,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "143657",
    propertyType: "Retail",
    totalCosts: "15750000",
    valueOfReclassifiedAssets: 2234037,
    depreciableAssets: 24,
  },
  {
    lat: 37.1451383,
    lng: -80.4326521,
    squareFootage: "152498",
    propertyType: "Retail",
    totalCosts: "24250000",
    valueOfReclassifiedAssets: 3671251,
    depreciableAssets: 21,
  },
  {
    lat: 36.3840171,
    lng: -94.21283369999999,
    squareFootage: "9094",
    propertyType: "Office",
    totalCosts: "3170000",
    valueOfReclassifiedAssets: 447085,
    depreciableAssets: 16,
  },
  {
    lat: 34.9274555,
    lng: -80.7436141,
    squareFootage: "10959",
    propertyType: "Retail",
    totalCosts: "571353",
    valueOfReclassifiedAssets: 172370,
    depreciableAssets: 35,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17468292",
    valueOfReclassifiedAssets: 14123245,
    depreciableAssets: 38,
  },
  {
    lat: 40.8874882,
    lng: -73.977126,
    squareFootage: "182869",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 9724974,
    depreciableAssets: 23,
  },
  {
    lat: 47.6683603,
    lng: -122.3769582,
    squareFootage: "30200",
    propertyType: "Multifamily",
    totalCosts: "4397166",
    valueOfReclassifiedAssets: 747337,
    depreciableAssets: 19,
  },
  {
    lat: 40.3572766,
    lng: -75.9085792,
    squareFootage: "121480",
    propertyType: "Specialty",
    totalCosts: "11300000",
    valueOfReclassifiedAssets: 1860407,
    depreciableAssets: 19,
  },
  {
    lat: 40.3279745,
    lng: -75.9143867,
    squareFootage: "82775",
    propertyType: "Specialty",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 718356,
    depreciableAssets: 12,
  },
  {
    lat: 32.2680738,
    lng: -110.9923904,
    squareFootage: "31500",
    propertyType: "Multifamily",
    totalCosts: "1217500",
    valueOfReclassifiedAssets: 285633,
    depreciableAssets: 28,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "331873",
    propertyType: "Office",
    totalCosts: "93067088",
    valueOfReclassifiedAssets: 14954058,
    depreciableAssets: 19,
  },
  {
    lat: 40.3815302,
    lng: -74.651754,
    squareFootage: "33600",
    propertyType: "Office",
    totalCosts: "203843",
    valueOfReclassifiedAssets: 33411,
    depreciableAssets: 19,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "5200",
    propertyType: "Multifamily",
    totalCosts: "835295",
    valueOfReclassifiedAssets: 716703,
    depreciableAssets: 16,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "58108",
    propertyType: "Office",
    totalCosts: "6358116",
    valueOfReclassifiedAssets: 583440,
    depreciableAssets: 10,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "58108",
    propertyType: "Office",
    totalCosts: "1340293",
    valueOfReclassifiedAssets: 116221,
    depreciableAssets: 10,
  },
  {
    lat: 30.253501,
    lng: -81.8879884,
    squareFootage: "47980",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11042406",
    valueOfReclassifiedAssets: 3620320,
    depreciableAssets: 39,
  },
  {
    lat: 27.9765043,
    lng: -82.74280569999999,
    squareFootage: "57736",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13800000",
    valueOfReclassifiedAssets: 4077379,
    depreciableAssets: 35,
  },
  {
    lat: 27.6445079,
    lng: -80.4048306,
    squareFootage: "32315",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11220000",
    valueOfReclassifiedAssets: 3638711,
    depreciableAssets: 38,
  },
  {
    lat: 27.4391171,
    lng: -80.3769999,
    squareFootage: "31862",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10914000",
    valueOfReclassifiedAssets: 3755854,
    depreciableAssets: 41,
  },
  {
    lat: 28.0776415,
    lng: -81.8005724,
    squareFootage: "41228",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11154000",
    valueOfReclassifiedAssets: 3671802,
    depreciableAssets: 39,
  },
  {
    lat: 33.60361779999999,
    lng: -101.9690458,
    squareFootage: "205734",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2030796,
    depreciableAssets: 27,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "41650",
    propertyType: "Multifamily",
    totalCosts: "6150000",
    valueOfReclassifiedAssets: 1240869,
    depreciableAssets: 24,
  },
  {
    lat: 39.9372847,
    lng: -75.2654063,
    squareFootage: "33000",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 586572,
    depreciableAssets: 22,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "57830560",
    valueOfReclassifiedAssets: 44160533,
    depreciableAssets: 25,
  },
  {
    lat: 37.7822891,
    lng: -122.463708,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14880669",
    valueOfReclassifiedAssets: 12311971,
    depreciableAssets: 25,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2560000",
    valueOfReclassifiedAssets: 2359485,
    depreciableAssets: 10,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7204385",
    valueOfReclassifiedAssets: 6361555,
    depreciableAssets: 13,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2390935",
    valueOfReclassifiedAssets: 2147519,
    depreciableAssets: 92,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1761045",
    valueOfReclassifiedAssets: 1554164,
    depreciableAssets: 79,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "414449",
    valueOfReclassifiedAssets: 374449,
    depreciableAssets: 0,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2066155",
    valueOfReclassifiedAssets: 1924652,
    depreciableAssets: 56,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1892340",
    valueOfReclassifiedAssets: 262802,
    depreciableAssets: 0,
  },
  {
    lat: 38.8560908,
    lng: -104.7812654,
    squareFootage: "300000",
    propertyType: "Office",
    totalCosts: "8350185",
    valueOfReclassifiedAssets: 1186162,
    depreciableAssets: 0,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "14720",
    propertyType: "Multifamily",
    totalCosts: "2004032",
    valueOfReclassifiedAssets: 227444,
    depreciableAssets: 13,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "23733",
    propertyType: "Multifamily",
    totalCosts: "3829750",
    valueOfReclassifiedAssets: 592901,
    depreciableAssets: 17,
  },
  {
    lat: 32.6702606,
    lng: -103.4049445,
    squareFootage: "25587",
    propertyType: "Multifamily",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 196032,
    depreciableAssets: 23,
  },
  {
    lat: 32.6702606,
    lng: -103.4049445,
    squareFootage: "21100",
    propertyType: "Multifamily",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 210349,
    depreciableAssets: 26,
  },
  {
    lat: 32.7030883,
    lng: -96.80294889999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6165659",
    valueOfReclassifiedAssets: 4063855,
    depreciableAssets: 36,
  },
  {
    lat: 38.2818635,
    lng: -76.5372625,
    squareFootage: "6633",
    propertyType: "Specialty",
    totalCosts: "2687500",
    valueOfReclassifiedAssets: 573892,
    depreciableAssets: 25,
  },
  {
    lat: 39.0608919,
    lng: -94.4079213,
    squareFootage: "76384",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1168443,
    depreciableAssets: 28,
  },
  {
    lat: 38.7952436,
    lng: -94.5418141,
    squareFootage: "27412",
    propertyType: "Multifamily",
    totalCosts: "1525000",
    valueOfReclassifiedAssets: 340242,
    depreciableAssets: 27,
  },
  {
    lat: 38.8682133,
    lng: -76.79135579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16272444",
    valueOfReclassifiedAssets: 14383731,
    depreciableAssets: 23,
  },
  {
    lat: 38.8682133,
    lng: -76.79135579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18718792",
    valueOfReclassifiedAssets: 13960475,
    depreciableAssets: 34,
  },
  {
    lat: 35.8509953,
    lng: -80.8765205,
    squareFootage: "104801",
    propertyType: "Multifamily",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1910118,
    depreciableAssets: 31,
  },
  {
    lat: 43.0852217,
    lng: -88.0363086,
    squareFootage: "19983",
    propertyType: "Retail",
    totalCosts: "2800000",
    valueOfReclassifiedAssets: 447593,
    depreciableAssets: 19,
  },
  {
    lat: 35.041539,
    lng: -85.1583064,
    squareFootage: "312456",
    propertyType: "Multifamily",
    totalCosts: "35900000",
    valueOfReclassifiedAssets: 7824299,
    depreciableAssets: 26,
  },
  {
    lat: 30.3755788,
    lng: -89.1801225,
    squareFootage: "251000",
    propertyType: "Multifamily",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 4078679,
    depreciableAssets: 26,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "19426",
    propertyType: "Multifamily",
    totalCosts: "5199734",
    valueOfReclassifiedAssets: 588682,
    depreciableAssets: 13,
  },
  {
    lat: 31.456042,
    lng: -97.0713572,
    squareFootage: "14528",
    propertyType: "Multifamily",
    totalCosts: "1315000",
    valueOfReclassifiedAssets: 364971,
    depreciableAssets: 32,
  },
  {
    lat: 31.456042,
    lng: -97.0713572,
    squareFootage: "13440",
    propertyType: "Multifamily",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 299460,
    depreciableAssets: 33,
  },
  {
    lat: 26.0883682,
    lng: -80.1596041,
    squareFootage: "23106",
    propertyType: "Multifamily",
    totalCosts: "2135739",
    valueOfReclassifiedAssets: 400321,
    depreciableAssets: 21,
  },
  {
    lat: 42.3652917,
    lng: -71.0646337,
    squareFootage: "16593",
    propertyType: "Office",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 665180,
    depreciableAssets: 12,
  },
  {
    lat: 33.7600115,
    lng: -78.8099472,
    squareFootage: "1800",
    propertyType: "Multifamily",
    totalCosts: "456000",
    valueOfReclassifiedAssets: 391348,
    depreciableAssets: 0,
  },
  {
    lat: 33.7600115,
    lng: -78.8099472,
    squareFootage: "2200",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 557489,
    depreciableAssets: 0,
  },
  {
    lat: 35.041539,
    lng: -85.1583064,
    squareFootage: "6152",
    propertyType: "Office",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 686462,
    depreciableAssets: 20,
  },
  {
    lat: 29.7765065,
    lng: -95.4201377,
    squareFootage: "41500",
    propertyType: "Multifamily",
    totalCosts: "4205700",
    valueOfReclassifiedAssets: 550032,
    depreciableAssets: 14,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "24215",
    propertyType: "Multifamily",
    totalCosts: "2780625",
    valueOfReclassifiedAssets: 389935,
    depreciableAssets: 15,
  },
  {
    lat: 44.95464399999999,
    lng: -93.3847969,
    squareFootage: "8018",
    propertyType: "Multifamily",
    totalCosts: "458773",
    valueOfReclassifiedAssets: 65733,
    depreciableAssets: 21,
  },
  {
    lat: 44.8735964,
    lng: -93.2835137,
    squareFootage: "9600",
    propertyType: "Multifamily",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 195032,
    depreciableAssets: 21,
  },
  {
    lat: 39.0488574,
    lng: -77.58486649999999,
    squareFootage: "18922",
    propertyType: "Retail",
    totalCosts: "5243231",
    valueOfReclassifiedAssets: 1082074,
    depreciableAssets: 32,
  },
  {
    lat: 38.8189466,
    lng: -77.0593246,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4168685",
    valueOfReclassifiedAssets: 4090513,
    depreciableAssets: 7,
  },
  {
    lat: 38.8189466,
    lng: -77.0593246,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "68156",
    valueOfReclassifiedAssets: 4602,
    depreciableAssets: 0,
  },
  {
    lat: 39.0129178,
    lng: -77.39560039999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6253115",
    valueOfReclassifiedAssets: 6019033,
    depreciableAssets: 14,
  },
  {
    lat: 42.5785949,
    lng: -70.7763169,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1786043",
    valueOfReclassifiedAssets: 905778,
    depreciableAssets: 79,
  },
  {
    lat: 38.8350215,
    lng: -77.0650809,
    squareFootage: "14225",
    propertyType: "Multifamily",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 1310568,
    depreciableAssets: 28,
  },
  {
    lat: 38.8350215,
    lng: -77.0650809,
    squareFootage: "7335",
    propertyType: "Multifamily",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 459783,
    depreciableAssets: 18,
  },
  {
    lat: 38.8350215,
    lng: -77.0650809,
    squareFootage: "7067",
    propertyType: "Multifamily",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 463792,
    depreciableAssets: 19,
  },
  {
    lat: 38.8350215,
    lng: -77.0650809,
    squareFootage: "7335",
    propertyType: "Multifamily",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 459783,
    depreciableAssets: 18,
  },
  {
    lat: 32.7476492,
    lng: -97.09248989999999,
    squareFootage: "146085",
    propertyType: "Multifamily",
    totalCosts: "18850000",
    valueOfReclassifiedAssets: 5535306,
    depreciableAssets: 34,
  },
  {
    lat: 35.7315398,
    lng: -78.7759559,
    squareFootage: "58000",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1845241,
    depreciableAssets: 25,
  },
  {
    lat: 38.8778926,
    lng: -104.8104424,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 450345,
    depreciableAssets: 12,
  },
  {
    lat: 39.01094,
    lng: -121.6169002,
    squareFootage: "25426",
    propertyType: "Multifamily",
    totalCosts: "1031126",
    valueOfReclassifiedAssets: 181908,
    depreciableAssets: 24,
  },
  {
    lat: 39.01094,
    lng: -121.6169002,
    squareFootage: "25426",
    propertyType: "Multifamily",
    totalCosts: "1142934",
    valueOfReclassifiedAssets: 201878,
    depreciableAssets: 24,
  },
  {
    lat: 33.5106684,
    lng: -112.029752,
    squareFootage: "24900",
    propertyType: "Office",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 2330747,
    depreciableAssets: 16,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "210916",
    propertyType: "Multifamily",
    totalCosts: "6890059",
    valueOfReclassifiedAssets: 837508,
    depreciableAssets: 14,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "210916",
    propertyType: "Multifamily",
    totalCosts: "8247621",
    valueOfReclassifiedAssets: 1769452,
    depreciableAssets: 24,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "210916",
    propertyType: "Multifamily",
    totalCosts: "11958535",
    valueOfReclassifiedAssets: 1453596,
    depreciableAssets: 14,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "210916",
    propertyType: "Multifamily",
    totalCosts: "14269319",
    valueOfReclassifiedAssets: 1734479,
    depreciableAssets: 14,
  },
  {
    lat: 40.811547,
    lng: -73.859549,
    squareFootage: "210916",
    propertyType: "Multifamily",
    totalCosts: "89913796",
    valueOfReclassifiedAssets: 10929292,
    depreciableAssets: 14,
  },
  {
    lat: 39.6527963,
    lng: -79.9472502,
    squareFootage: "10316",
    propertyType: "Multifamily",
    totalCosts: "1775000",
    valueOfReclassifiedAssets: 268073,
    depreciableAssets: 18,
  },
  {
    lat: 39.3729916,
    lng: -77.4587239,
    squareFootage: "96044",
    propertyType: "Office",
    totalCosts: "6221200",
    valueOfReclassifiedAssets: 1098623,
    depreciableAssets: 21,
  },
  {
    lat: 33.5080943,
    lng: -112.0863874,
    squareFootage: "118688",
    propertyType: "Multifamily",
    totalCosts: "10716372",
    valueOfReclassifiedAssets: 2871816,
    depreciableAssets: 32,
  },
  {
    lat: 33.5080943,
    lng: -112.0863874,
    squareFootage: "118688",
    propertyType: "Multifamily",
    totalCosts: "9685135",
    valueOfReclassifiedAssets: 2595461,
    depreciableAssets: 32,
  },
  {
    lat: 40.5183163,
    lng: -88.9701082,
    squareFootage: "206316",
    propertyType: "Multifamily",
    totalCosts: "12900000",
    valueOfReclassifiedAssets: 3089568,
    depreciableAssets: 26,
  },
  {
    lat: 33.4702383,
    lng: -86.80696979999999,
    squareFootage: "26988",
    propertyType: "Multifamily",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 369127,
    depreciableAssets: 26,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "36300",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 870668,
    depreciableAssets: 32,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "5200",
    propertyType: "Retail",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 960648,
    depreciableAssets: 23,
  },
  {
    lat: 32.7229925,
    lng: -97.15209259999999,
    squareFootage: "10000",
    propertyType: "Multifamily",
    totalCosts: "15200000",
    valueOfReclassifiedAssets: 4066376,
    depreciableAssets: 33,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "69376",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 1054752,
    depreciableAssets: 30,
  },
  {
    lat: 29.5857075,
    lng: -98.4129396,
    squareFootage: "189750",
    propertyType: "Multifamily",
    totalCosts: "14350000",
    valueOfReclassifiedAssets: 3774432,
    depreciableAssets: 31,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "13200",
    propertyType: "Multifamily",
    totalCosts: "2811000",
    valueOfReclassifiedAssets: 274364,
    depreciableAssets: 12,
  },
  {
    lat: 40.7245678,
    lng: -73.8457658,
    squareFootage: "20000",
    propertyType: "Multifamily",
    totalCosts: "8200000",
    valueOfReclassifiedAssets: 919262,
    depreciableAssets: 13,
  },
  {
    lat: 33.8469812,
    lng: -117.9541894,
    squareFootage: "22200",
    propertyType: "Retail",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 4331818,
    depreciableAssets: 30,
  },
  {
    lat: 42.4444701,
    lng: -88.6144839,
    squareFootage: "35000",
    propertyType: "Multifamily",
    totalCosts: "2846800",
    valueOfReclassifiedAssets: 862774,
    depreciableAssets: 36,
  },
  {
    lat: 42.2501791,
    lng: -88.8455037,
    squareFootage: "51700",
    propertyType: "Multifamily",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 800122,
    depreciableAssets: 29,
  },
  {
    lat: 42.2501791,
    lng: -88.8455037,
    squareFootage: "23640",
    propertyType: "Multifamily",
    totalCosts: "1190000",
    valueOfReclassifiedAssets: 246917,
    depreciableAssets: 24,
  },
  {
    lat: 34.23015670000001,
    lng: -118.3520389,
    squareFootage: "22050",
    propertyType: "Industrial",
    totalCosts: "3604436",
    valueOfReclassifiedAssets: 229149,
    depreciableAssets: 18,
  },
  {
    lat: 40.1087747,
    lng: -79.0694487,
    squareFootage: "15434",
    propertyType: "Multifamily",
    totalCosts: "6071500",
    valueOfReclassifiedAssets: 1068873,
    depreciableAssets: 27,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "33436",
    propertyType: "Retail",
    totalCosts: "4017012",
    valueOfReclassifiedAssets: 999951,
    depreciableAssets: 30,
  },
  {
    lat: 32.82120099999999,
    lng: -97.1036396,
    squareFootage: "24252",
    propertyType: "Office",
    totalCosts: "1176032",
    valueOfReclassifiedAssets: 285537,
    depreciableAssets: 28,
  },
  {
    lat: 32.9559018,
    lng: -96.6958939,
    squareFootage: "53075",
    propertyType: "Retail",
    totalCosts: "775000",
    valueOfReclassifiedAssets: 159078,
    depreciableAssets: 25,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "43794",
    propertyType: "Retail",
    totalCosts: "1726525",
    valueOfReclassifiedAssets: 461323,
    depreciableAssets: 31,
  },
  {
    lat: 33.1376528,
    lng: -96.85654269999999,
    squareFootage: "55666",
    propertyType: "Retail",
    totalCosts: "3016645",
    valueOfReclassifiedAssets: 620439,
    depreciableAssets: 23,
  },
  {
    lat: 32.9464268,
    lng: -97.0713572,
    squareFootage: "64250",
    propertyType: "Retail",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 559777,
    depreciableAssets: 25,
  },
  {
    lat: 40.4989334,
    lng: -79.8184684,
    squareFootage: "8000",
    propertyType: "Retail",
    totalCosts: "777295",
    valueOfReclassifiedAssets: 705810,
    depreciableAssets: 20,
  },
  {
    lat: 40.9073141,
    lng: -82.669947,
    squareFootage: "9180",
    propertyType: "Retail",
    totalCosts: "950000",
    valueOfReclassifiedAssets: 268260,
    depreciableAssets: 32,
  },
  {
    lat: 34.0965313,
    lng: -117.5848025,
    squareFootage: "267354",
    propertyType: "Office",
    totalCosts: "17075000",
    valueOfReclassifiedAssets: 3018192,
    depreciableAssets: 20,
  },
  {
    lat: 35.2105521,
    lng: -82.11695499999999,
    squareFootage: "70000",
    propertyType: "Office",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 793254,
    depreciableAssets: 17,
  },
  {
    lat: 39.1031971,
    lng: -84.5064881,
    squareFootage: "596215",
    propertyType: "Office",
    totalCosts: "50598888",
    valueOfReclassifiedAssets: 42942187,
    depreciableAssets: 15,
  },
  {
    lat: 31.8964712,
    lng: -106.4288088,
    squareFootage: "98063",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1727812,
    depreciableAssets: 30,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "29300",
    propertyType: "Industrial",
    totalCosts: "8100000",
    valueOfReclassifiedAssets: 2457853,
    depreciableAssets: 36,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4148775",
    valueOfReclassifiedAssets: 2956357,
    depreciableAssets: 50,
  },
  {
    lat: 40.84700100000001,
    lng: -73.69482339999999,
    squareFootage: "47688",
    propertyType: "Industrial",
    totalCosts: "7650000",
    valueOfReclassifiedAssets: 1617513,
    depreciableAssets: 27,
  },
  {
    lat: 38.013412,
    lng: -84.485507,
    squareFootage: "42570",
    propertyType: "Multifamily",
    totalCosts: "1824000",
    valueOfReclassifiedAssets: 392061,
    depreciableAssets: 25,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "19400",
    propertyType: "Retail",
    totalCosts: "4842103",
    valueOfReclassifiedAssets: 917203,
    depreciableAssets: 26,
  },
  {
    lat: 33.9127807,
    lng: -118.3520389,
    squareFootage: "15000",
    propertyType: "Retail",
    totalCosts: "4401336",
    valueOfReclassifiedAssets: 617120,
    depreciableAssets: 23,
  },
  {
    lat: 36.1410253,
    lng: -86.67118169999999,
    squareFootage: "345055",
    propertyType: "Multifamily",
    totalCosts: "45275000",
    valueOfReclassifiedAssets: 9185145,
    depreciableAssets: 24,
  },
  {
    lat: 33.9047862,
    lng: -81.04234290000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1980000",
    valueOfReclassifiedAssets: 788789,
    depreciableAssets: 50,
  },
  {
    lat: 47.6105427,
    lng: -122.142426,
    squareFootage: "73022",
    propertyType: "Office",
    totalCosts: "15150000",
    valueOfReclassifiedAssets: 2875892,
    depreciableAssets: 22,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "17295",
    propertyType: "Office",
    totalCosts: "6806827",
    valueOfReclassifiedAssets: 506933,
    depreciableAssets: 15,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "9472",
    propertyType: "Office",
    totalCosts: "2950000",
    valueOfReclassifiedAssets: 505419,
    depreciableAssets: 20,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "9472",
    propertyType: "Office",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 336553,
    depreciableAssets: 16,
  },
  {
    lat: 47.6688298,
    lng: -122.1923875,
    squareFootage: "11675",
    propertyType: "Retail",
    totalCosts: "2629268",
    valueOfReclassifiedAssets: 468194,
    depreciableAssets: 24,
  },
  {
    lat: 58.3018264,
    lng: -134.4202956,
    squareFootage: "104641",
    propertyType: "Retail",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 3309500,
    depreciableAssets: 35,
  },
  {
    lat: 41.418055,
    lng: -73.4769458,
    squareFootage: "23300",
    propertyType: "Retail",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 986501,
    depreciableAssets: 25,
  },
  {
    lat: 41.0529585,
    lng: -73.53879909999999,
    squareFootage: "165403",
    propertyType: "Multifamily",
    totalCosts: "42000000",
    valueOfReclassifiedAssets: 6031623,
    depreciableAssets: 17,
  },
  {
    lat: 41.3755722,
    lng: -73.45337789999999,
    squareFootage: "506215",
    propertyType: "Multifamily",
    totalCosts: "110371669",
    valueOfReclassifiedAssets: 22192618,
    depreciableAssets: 24,
  },
  {
    lat: 41.0322939,
    lng: -73.7683784,
    squareFootage: "428155",
    propertyType: "Multifamily",
    totalCosts: "146554300",
    valueOfReclassifiedAssets: 14297681,
    depreciableAssets: 11,
  },
  {
    lat: 41.2731243,
    lng: -73.0287209,
    squareFootage: "161670",
    propertyType: "Multifamily",
    totalCosts: "35243717",
    valueOfReclassifiedAssets: 7917687,
    depreciableAssets: 27,
  },
  {
    lat: 39.4109996,
    lng: -76.5777203,
    squareFootage: "236420",
    propertyType: "Multifamily",
    totalCosts: "40021935",
    valueOfReclassifiedAssets: 5685021,
    depreciableAssets: 17,
  },
  {
    lat: 41.4932198,
    lng: -81.46085599999999,
    squareFootage: "949936",
    propertyType: "Multifamily",
    totalCosts: "61279738",
    valueOfReclassifiedAssets: 10181022,
    depreciableAssets: 20,
  },
  {
    lat: 41.0411194,
    lng: -73.541744,
    squareFootage: "242940",
    propertyType: "Multifamily",
    totalCosts: "19129071",
    valueOfReclassifiedAssets: 2659301,
    depreciableAssets: 16,
  },
  {
    lat: 39.8073767,
    lng: -75.0026972,
    squareFootage: "240000",
    propertyType: "Multifamily",
    totalCosts: "28257740",
    valueOfReclassifiedAssets: 5886679,
    depreciableAssets: 24,
  },
  {
    lat: 39.8383777,
    lng: -74.9325683,
    squareFootage: "409243",
    propertyType: "Multifamily",
    totalCosts: "65287904",
    valueOfReclassifiedAssets: 11423874,
    depreciableAssets: 20,
  },
  {
    lat: 32.7918963,
    lng: -83.6029116,
    squareFootage: "55980",
    propertyType: "Office",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 475101,
    depreciableAssets: 15,
  },
  {
    lat: 32.737615,
    lng: -97.38407289999999,
    squareFootage: "75425",
    propertyType: "Multifamily",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 2375825,
    depreciableAssets: 32,
  },
  {
    lat: 32.7320972,
    lng: -96.9907197,
    squareFootage: "98850",
    propertyType: "Multifamily",
    totalCosts: "9325555",
    valueOfReclassifiedAssets: 2769675,
    depreciableAssets: 35,
  },
  {
    lat: 40.6041777,
    lng: -74.2824862,
    squareFootage: "102901",
    propertyType: "Industrial",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 865349,
    depreciableAssets: 12,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "20524260",
    valueOfReclassifiedAssets: 18780591,
    depreciableAssets: 8,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "3091",
    propertyType: "Multifamily",
    totalCosts: "529942",
    valueOfReclassifiedAssets: 442437,
    depreciableAssets: 26,
  },
  {
    lat: 37.7618242,
    lng: -122.3985871,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "198895580",
    valueOfReclassifiedAssets: 175868546,
    depreciableAssets: 13,
  },
  {
    lat: 30.4649282,
    lng: -84.38574419999999,
    squareFootage: "121275",
    propertyType: "Multifamily",
    totalCosts: "4809778",
    valueOfReclassifiedAssets: 1000010,
    depreciableAssets: 24,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "490000",
    valueOfReclassifiedAssets: 137683,
    depreciableAssets: 33,
  },
  {
    lat: 41.2050167,
    lng: -74.24140919999999,
    squareFootage: "26000",
    propertyType: "Retail",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 709524,
    depreciableAssets: 19,
  },
  {
    lat: 40.7275043,
    lng: -73.9800645,
    squareFootage: "13405",
    propertyType: "Multifamily",
    totalCosts: "1495547",
    valueOfReclassifiedAssets: 321794,
    depreciableAssets: 27,
  },
  {
    lat: 40.7275043,
    lng: -73.9800645,
    squareFootage: "13405",
    propertyType: "Multifamily",
    totalCosts: "2243850",
    valueOfReclassifiedAssets: 482804,
    depreciableAssets: 27,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "12349",
    propertyType: "Multifamily",
    totalCosts: "4115161",
    valueOfReclassifiedAssets: 998720,
    depreciableAssets: 30,
  },
  {
    lat: 40.79164069999999,
    lng: -73.9447994,
    squareFootage: "5660",
    propertyType: "Multifamily",
    totalCosts: "663104",
    valueOfReclassifiedAssets: 73926,
    depreciableAssets: 13,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "41613",
    propertyType: "Office",
    totalCosts: "8357454",
    valueOfReclassifiedAssets: 580425,
    depreciableAssets: 8,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "4927",
    propertyType: "Retail",
    totalCosts: "937416",
    valueOfReclassifiedAssets: 115354,
    depreciableAssets: 15,
  },
  {
    lat: 40.6900246,
    lng: -73.8213213,
    squareFootage: "22500",
    propertyType: "Retail",
    totalCosts: "2486501",
    valueOfReclassifiedAssets: 332804,
    depreciableAssets: 16,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "37218",
    propertyType: "Multifamily",
    totalCosts: "4650000",
    valueOfReclassifiedAssets: 692488,
    depreciableAssets: 18,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "12895",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 277802,
    depreciableAssets: 18,
  },
  {
    lat: 40.6238902,
    lng: -75.3936808,
    squareFootage: "9230",
    propertyType: "Multifamily",
    totalCosts: "8008077",
    valueOfReclassifiedAssets: 1409256,
    depreciableAssets: 18,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "4816",
    propertyType: "Retail",
    totalCosts: "416990",
    valueOfReclassifiedAssets: 62911,
    depreciableAssets: 18,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "2280",
    propertyType: "Retail",
    totalCosts: "342214",
    valueOfReclassifiedAssets: 52732,
    depreciableAssets: 19,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "29235",
    propertyType: "Multifamily",
    totalCosts: "1947023",
    valueOfReclassifiedAssets: 278558,
    depreciableAssets: 17,
  },
  {
    lat: 40.8639624,
    lng: -73.93304289999999,
    squareFootage: "19340",
    propertyType: "Multifamily",
    totalCosts: "2757997",
    valueOfReclassifiedAssets: 477748,
    depreciableAssets: 22,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "12895",
    propertyType: "Multifamily",
    totalCosts: "1564609",
    valueOfReclassifiedAssets: 178102,
    depreciableAssets: 14,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "16752",
    propertyType: "Multifamily",
    totalCosts: "1670880",
    valueOfReclassifiedAssets: 196705,
    depreciableAssets: 15,
  },
  {
    lat: 40.7388319,
    lng: -73.98153370000001,
    squareFootage: "14148",
    propertyType: "Multifamily",
    totalCosts: "3142057",
    valueOfReclassifiedAssets: 416162,
    depreciableAssets: 16,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "3186",
    propertyType: "Retail",
    totalCosts: "2065762",
    valueOfReclassifiedAssets: 352337,
    depreciableAssets: 21,
  },
  {
    lat: 40.8022594,
    lng: -73.9154069,
    squareFootage: "7868",
    propertyType: "Retail",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 180629,
    depreciableAssets: 18,
  },
  {
    lat: 40.89907669999999,
    lng: -73.86836970000002,
    squareFootage: "3438",
    propertyType: "Retail",
    totalCosts: "1093231",
    valueOfReclassifiedAssets: 168005,
    depreciableAssets: 19,
  },
  {
    lat: 40.89907669999999,
    lng: -73.86836970000002,
    squareFootage: "2912",
    propertyType: "Retail",
    totalCosts: "1131295",
    valueOfReclassifiedAssets: 181099,
    depreciableAssets: 20,
  },
  {
    lat: 40.841618,
    lng: -73.8801301,
    squareFootage: "4315",
    propertyType: "Retail",
    totalCosts: "2047250",
    valueOfReclassifiedAssets: 209352,
    depreciableAssets: 12,
  },
  {
    lat: 40.8515872,
    lng: -73.9154069,
    squareFootage: "1905",
    propertyType: "Retail",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 139344,
    depreciableAssets: 22,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "10166",
    propertyType: "Retail",
    totalCosts: "2912546",
    valueOfReclassifiedAssets: 408124,
    depreciableAssets: 18,
  },
  {
    lat: 40.6007956,
    lng: -73.9065883,
    squareFootage: "7600",
    propertyType: "Retail",
    totalCosts: "1638305",
    valueOfReclassifiedAssets: 267389,
    depreciableAssets: 21,
  },
  {
    lat: 40.8557765,
    lng: -73.9447994,
    squareFootage: "28788",
    propertyType: "Multifamily",
    totalCosts: "1652148",
    valueOfReclassifiedAssets: 257444,
    depreciableAssets: 19,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "32053",
    propertyType: "Multifamily",
    totalCosts: "5143351",
    valueOfReclassifiedAssets: 543874,
    depreciableAssets: 12,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "4253",
    propertyType: "Retail",
    totalCosts: "1673978",
    valueOfReclassifiedAssets: 154548,
    depreciableAssets: 11,
  },
  {
    lat: 40.7448741,
    lng: -73.91981609999999,
    squareFootage: "918",
    propertyType: "Retail",
    totalCosts: "144983",
    valueOfReclassifiedAssets: 30346,
    depreciableAssets: 26,
  },
  {
    lat: 40.7469886,
    lng: -73.7536695,
    squareFootage: "31225",
    propertyType: "Retail",
    totalCosts: "13661825",
    valueOfReclassifiedAssets: 2490394,
    depreciableAssets: 23,
  },
  {
    lat: 40.6749343,
    lng: -73.8713099,
    squareFootage: "16605",
    propertyType: "Retail",
    totalCosts: "7398331",
    valueOfReclassifiedAssets: 1282045,
    depreciableAssets: 22,
  },
  {
    lat: 40.63871959999999,
    lng: -73.8948295,
    squareFootage: "7560",
    propertyType: "Retail",
    totalCosts: "2336937",
    valueOfReclassifiedAssets: 336342,
    depreciableAssets: 18,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "3699",
    propertyType: "Retail",
    totalCosts: "1605448",
    valueOfReclassifiedAssets: 357847,
    depreciableAssets: 27,
  },
  {
    lat: 40.5749261,
    lng: -73.9859414,
    squareFootage: "23700",
    propertyType: "Retail",
    totalCosts: "8771612",
    valueOfReclassifiedAssets: 1680523,
    depreciableAssets: 24,
  },
  {
    lat: 37.5685247,
    lng: -122.367428,
    squareFootage: "13068",
    propertyType: "Multifamily",
    totalCosts: "7935500",
    valueOfReclassifiedAssets: 977230,
    depreciableAssets: 16,
  },
  {
    lat: 40.103832,
    lng: -83.02002449999999,
    squareFootage: "70215",
    propertyType: "Industrial",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 463489,
    depreciableAssets: 13,
  },
  {
    lat: 40.115674,
    lng: -83.13273319999999,
    squareFootage: "46646",
    propertyType: "Office",
    totalCosts: "2275000",
    valueOfReclassifiedAssets: 493946,
    depreciableAssets: 26,
  },
  {
    lat: 38.9140455,
    lng: -76.8778612,
    squareFootage: "522317",
    propertyType: "Multifamily",
    totalCosts: "65850000",
    valueOfReclassifiedAssets: 15850354,
    depreciableAssets: 28,
  },
  {
    lat: 41.0326881,
    lng: -80.6659842,
    squareFootage: "79267",
    propertyType: "Retail",
    totalCosts: "18800000",
    valueOfReclassifiedAssets: 2694658,
    depreciableAssets: 18,
  },
  {
    lat: 40.4333407,
    lng: -74.41152459999999,
    squareFootage: "417929",
    propertyType: "Office",
    totalCosts: "38000000",
    valueOfReclassifiedAssets: 4412368,
    depreciableAssets: 12,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "4200",
    propertyType: "Retail",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 262163,
    depreciableAssets: 14,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "1576",
    propertyType: "Retail",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1455558,
    depreciableAssets: 38,
  },
  {
    lat: 37.6648693,
    lng: -97.4327273,
    squareFootage: "88351",
    propertyType: "Multifamily",
    totalCosts: "20350000",
    valueOfReclassifiedAssets: 5801713,
    depreciableAssets: 34,
  },
  {
    lat: 39.9428367,
    lng: -82.8909785,
    squareFootage: "182826",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1480263,
    depreciableAssets: 29,
  },
  {
    lat: 39.8070137,
    lng: -86.2260131,
    squareFootage: "232216",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1666996,
    depreciableAssets: 28,
  },
  {
    lat: 32.5917234,
    lng: -93.8451731,
    squareFootage: "42064",
    propertyType: "Multifamily",
    totalCosts: "1086667",
    valueOfReclassifiedAssets: 365561,
    depreciableAssets: 40,
  },
  {
    lat: 32.3803257,
    lng: -93.7333537,
    squareFootage: "160317",
    propertyType: "Multifamily",
    totalCosts: "3280000",
    valueOfReclassifiedAssets: 776548,
    depreciableAssets: 28,
  },
  {
    lat: 32.3803257,
    lng: -93.7333537,
    squareFootage: "86689",
    propertyType: "Multifamily",
    totalCosts: "2573333",
    valueOfReclassifiedAssets: 741187,
    depreciableAssets: 34,
  },
  {
    lat: 32.5917234,
    lng: -93.8451731,
    squareFootage: "148368",
    propertyType: "Multifamily",
    totalCosts: "3060000",
    valueOfReclassifiedAssets: 922738,
    depreciableAssets: 35,
  },
  {
    lat: 34.8511873,
    lng: -92.33202349999999,
    squareFootage: "78536",
    propertyType: "Multifamily",
    totalCosts: "1900000",
    valueOfReclassifiedAssets: 598176,
    depreciableAssets: 37,
  },
  {
    lat: 34.6741181,
    lng: -92.3517526,
    squareFootage: "90511",
    propertyType: "Multifamily",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 491229,
    depreciableAssets: 33,
  },
  {
    lat: 29.3916291,
    lng: -98.56660769999999,
    squareFootage: "326568",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2778125,
    depreciableAssets: 30,
  },
  {
    lat: 34.6741181,
    lng: -92.3517526,
    squareFootage: "305081",
    propertyType: "Multifamily",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2348739,
    depreciableAssets: 24,
  },
  {
    lat: 34.7486563,
    lng: -92.3542193,
    squareFootage: "98496",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 813423,
    depreciableAssets: 18,
  },
  {
    lat: 41.03619,
    lng: -81.4828198,
    squareFootage: "242498",
    propertyType: "Multifamily",
    totalCosts: "9050000",
    valueOfReclassifiedAssets: 2589907,
    depreciableAssets: 34,
  },
  {
    lat: 34.6741181,
    lng: -92.3517526,
    squareFootage: "146714",
    propertyType: "Multifamily",
    totalCosts: "6511250",
    valueOfReclassifiedAssets: 1392537,
    depreciableAssets: 25,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "9472",
    propertyType: "Office",
    totalCosts: "2833649",
    valueOfReclassifiedAssets: 485484,
    depreciableAssets: 20,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "9472",
    propertyType: "Office",
    totalCosts: "2498157",
    valueOfReclassifiedAssets: 323370,
    depreciableAssets: 16,
  },
  {
    lat: 47.4489305,
    lng: -122.2829161,
    squareFootage: "36648",
    propertyType: "Hotel",
    totalCosts: "4763584",
    valueOfReclassifiedAssets: 3400541,
    depreciableAssets: 36,
  },
  {
    lat: 47.4489305,
    lng: -122.2829161,
    squareFootage: "37239",
    propertyType: "Hotel",
    totalCosts: "3067154",
    valueOfReclassifiedAssets: 2279030,
    depreciableAssets: 30,
  },
  {
    lat: 32.9892663,
    lng: -80.109357,
    squareFootage: "329462",
    propertyType: "Multifamily",
    totalCosts: "47250000",
    valueOfReclassifiedAssets: 13205581,
    depreciableAssets: 33,
  },
  {
    lat: 37.7909427,
    lng: -122.4084994,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "5053451",
    valueOfReclassifiedAssets: 4055439,
    depreciableAssets: 0,
  },
  {
    lat: 37.7909427,
    lng: -122.4084994,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "956532",
    valueOfReclassifiedAssets: 767625,
    depreciableAssets: 0,
  },
  {
    lat: 37.7909427,
    lng: -122.4084994,
    squareFootage: "2017",
    propertyType: "Office",
    totalCosts: "6734955",
    valueOfReclassifiedAssets: 5404860,
    depreciableAssets: 0,
  },
  {
    lat: 32.9087285,
    lng: -96.7386946,
    squareFootage: "431982",
    propertyType: "Multifamily",
    totalCosts: "3712800",
    valueOfReclassifiedAssets: 1183753,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "431982",
    propertyType: "Multifamily",
    totalCosts: "32268600",
    valueOfReclassifiedAssets: 10288198,
    depreciableAssets: 38,
  },
  {
    lat: 29.6813519,
    lng: -95.2005729,
    squareFootage: "44754",
    propertyType: "Multifamily",
    totalCosts: "2355423",
    valueOfReclassifiedAssets: 636671,
    depreciableAssets: 33,
  },
  {
    lat: 29.6813519,
    lng: -95.2005729,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "34649",
    valueOfReclassifiedAssets: 7997,
    depreciableAssets: 0,
  },
  {
    lat: 40.6189813,
    lng: -74.3176876,
    squareFootage: "106289",
    propertyType: "Office",
    totalCosts: "15728517",
    valueOfReclassifiedAssets: 2548710,
    depreciableAssets: 19,
  },
  {
    lat: 34.7791669,
    lng: -92.3468195,
    squareFootage: "116739",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 1828859,
    depreciableAssets: 35,
  },
  {
    lat: 34.631685,
    lng: -98.5062007,
    squareFootage: "252814",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1612207,
    depreciableAssets: 22,
  },
  {
    lat: 32.7457134,
    lng: -96.8458204,
    squareFootage: "124724",
    propertyType: "Multifamily",
    totalCosts: "13295789",
    valueOfReclassifiedAssets: 3586473,
    depreciableAssets: 31,
  },
  {
    lat: 30.87419,
    lng: -83.3468784,
    squareFootage: "97491",
    propertyType: "Multifamily",
    totalCosts: "4050000",
    valueOfReclassifiedAssets: 755721,
    depreciableAssets: 21,
  },
  {
    lat: 30.7913218,
    lng: -81.71305219999999,
    squareFootage: "26896",
    propertyType: "Multifamily",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 299812,
    depreciableAssets: 30,
  },
  {
    lat: 30.3776024,
    lng: -81.5870604,
    squareFootage: "25840",
    propertyType: "Multifamily",
    totalCosts: "1735000",
    valueOfReclassifiedAssets: 449988,
    depreciableAssets: 31,
  },
  {
    lat: 30.7913218,
    lng: -81.71305219999999,
    squareFootage: "44416",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 635146,
    depreciableAssets: 31,
  },
  {
    lat: 30.7913218,
    lng: -81.71305219999999,
    squareFootage: "84304",
    propertyType: "Multifamily",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 949361,
    depreciableAssets: 27,
  },
  {
    lat: 32.3405671,
    lng: -86.2615689,
    squareFootage: "415468",
    propertyType: "Multifamily",
    totalCosts: "20010354",
    valueOfReclassifiedAssets: 6678474,
    depreciableAssets: 34,
  },
  {
    lat: 41.0029504,
    lng: -89.13401549999999,
    squareFootage: "23692",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4323052",
    valueOfReclassifiedAssets: 1236163,
    depreciableAssets: 31,
  },
  {
    lat: 42.0361546,
    lng: -88.314212,
    squareFootage: "25242",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9441382",
    valueOfReclassifiedAssets: 2855618,
    depreciableAssets: 33,
  },
  {
    lat: 41.3484412,
    lng: -89.2181911,
    squareFootage: "24454",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3688344",
    valueOfReclassifiedAssets: 1169880,
    depreciableAssets: 38,
  },
  {
    lat: 36.1484862,
    lng: -86.9523954,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "14250000",
    valueOfReclassifiedAssets: 3817952,
    depreciableAssets: 31,
  },
  {
    lat: 38.9538595,
    lng: -94.6192114,
    squareFootage: "84890",
    propertyType: "Specialty",
    totalCosts: "5186650",
    valueOfReclassifiedAssets: 1053099,
    depreciableAssets: 23,
  },
  {
    lat: 41.4329234,
    lng: -82.2202951,
    squareFootage: "32904",
    propertyType: "Multifamily",
    totalCosts: "755000",
    valueOfReclassifiedAssets: 170803,
    depreciableAssets: 27,
  },
  {
    lat: 26.621043,
    lng: -81.8333656,
    squareFootage: "19250",
    propertyType: "Industrial",
    totalCosts: "1222381",
    valueOfReclassifiedAssets: 128145,
    depreciableAssets: 13,
  },
  {
    lat: 35.0276125,
    lng: -89.78491269999999,
    squareFootage: "13804",
    propertyType: "Retail",
    totalCosts: "3514000",
    valueOfReclassifiedAssets: 1056051,
    depreciableAssets: 35,
  },
  {
    lat: 39.747363,
    lng: -105.1083542,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1984379",
    valueOfReclassifiedAssets: 219921,
    depreciableAssets: 35,
  },
  {
    lat: 39.3252511,
    lng: -76.71633589999999,
    squareFootage: "38200",
    propertyType: "Industrial",
    totalCosts: "5183676",
    valueOfReclassifiedAssets: 418364,
    depreciableAssets: 15,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "12900",
    propertyType: "Retail",
    totalCosts: "6074592",
    valueOfReclassifiedAssets: 1752518,
    depreciableAssets: 35,
  },
  {
    lat: 36.3262595,
    lng: -80.3435908,
    squareFootage: "3997",
    propertyType: "Specialty",
    totalCosts: "870000",
    valueOfReclassifiedAssets: 277593,
    depreciableAssets: 38,
  },
  {
    lat: 28.0964047,
    lng: -81.9207423,
    squareFootage: "9375",
    propertyType: "Retail",
    totalCosts: "1941205",
    valueOfReclassifiedAssets: 276257,
    depreciableAssets: 49,
  },
  {
    lat: 28.0964047,
    lng: -81.9207423,
    squareFootage: "5022",
    propertyType: "Specialty",
    totalCosts: "3037612",
    valueOfReclassifiedAssets: 626777,
    depreciableAssets: 48,
  },
  {
    lat: 41.5567064,
    lng: -73.0449551,
    squareFootage: "82125",
    propertyType: "Multifamily",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 998837,
    depreciableAssets: 18,
  },
  {
    lat: 30.3928623,
    lng: -87.7172616,
    squareFootage: "61200",
    propertyType: "Multifamily",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 867237,
    depreciableAssets: 23,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "640510",
    propertyType: "Multifamily",
    totalCosts: "40000000",
    valueOfReclassifiedAssets: 8126409,
    depreciableAssets: 22,
  },
  {
    lat: 30.2949194,
    lng: -81.63638689999999,
    squareFootage: "163616",
    propertyType: "Multifamily",
    totalCosts: "14100000",
    valueOfReclassifiedAssets: 2634424,
    depreciableAssets: 22,
  },
  {
    lat: 40.9129534,
    lng: -72.3429525,
    squareFootage: "2500",
    propertyType: "Multifamily",
    totalCosts: "1287456",
    valueOfReclassifiedAssets: 341617,
    depreciableAssets: 33,
  },
  {
    lat: 36.0564654,
    lng: -80.33245140000001,
    squareFootage: "208224",
    propertyType: "Multifamily",
    totalCosts: "13263646",
    valueOfReclassifiedAssets: 2667959,
    depreciableAssets: 24,
  },
  {
    lat: 36.0097372,
    lng: -80.2878794,
    squareFootage: "308000",
    propertyType: "Multifamily",
    totalCosts: "16154440",
    valueOfReclassifiedAssets: 3000134,
    depreciableAssets: 22,
  },
  {
    lat: 36.0564654,
    lng: -80.33245140000001,
    squareFootage: "264198",
    propertyType: "Multifamily",
    totalCosts: "16664581",
    valueOfReclassifiedAssets: 3414443,
    depreciableAssets: 23,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "289272",
    propertyType: "Multifamily",
    totalCosts: "16822114",
    valueOfReclassifiedAssets: 3514242,
    depreciableAssets: 23,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "289272",
    propertyType: "Multifamily",
    totalCosts: "14917333",
    valueOfReclassifiedAssets: 3116321,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "55500",
    propertyType: "Industrial",
    totalCosts: "3333975",
    valueOfReclassifiedAssets: 467995,
    depreciableAssets: 19,
  },
  {
    lat: 39.2536087,
    lng: -77.8710285,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2509807",
    valueOfReclassifiedAssets: 2136181,
    depreciableAssets: 35,
  },
  {
    lat: 38.8910932,
    lng: -76.94702199999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10858179",
    valueOfReclassifiedAssets: 8144027,
    depreciableAssets: 32,
  },
  {
    lat: 38.6679123,
    lng: -77.2060241,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8595143",
    valueOfReclassifiedAssets: 7485832,
    depreciableAssets: 23,
  },
  {
    lat: 38.3056063,
    lng: -77.58486649999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6971008",
    valueOfReclassifiedAssets: 6561207,
    depreciableAssets: 11,
  },
  {
    lat: 39.047958,
    lng: -77.4816693,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10366947",
    valueOfReclassifiedAssets: 8338996,
    depreciableAssets: 22,
  },
  {
    lat: 39.0455425,
    lng: -76.90668289999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10507872",
    valueOfReclassifiedAssets: 8609465,
    depreciableAssets: 23,
  },
  {
    lat: 38.7996136,
    lng: -77.20889869999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12697121",
    valueOfReclassifiedAssets: 10599451,
    depreciableAssets: 28,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9971208",
    valueOfReclassifiedAssets: 2617836,
    depreciableAssets: 30,
  },
  {
    lat: 38.9091011,
    lng: -77.5275463,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9317494",
    valueOfReclassifiedAssets: 7659525,
    depreciableAssets: 29,
  },
  {
    lat: 38.9658112,
    lng: -77.4816693,
    squareFootage: "100185",
    propertyType: "Industrial",
    totalCosts: "17420000",
    valueOfReclassifiedAssets: 3417269,
    depreciableAssets: 23,
  },
  {
    lat: 38.9586422,
    lng: -77.22901929999999,
    squareFootage: "94892",
    propertyType: "Industrial",
    totalCosts: "21700000",
    valueOfReclassifiedAssets: 4641102,
    depreciableAssets: 25,
  },
  {
    lat: 39.9559288,
    lng: -75.1574567,
    squareFootage: "21923",
    propertyType: "Multifamily",
    totalCosts: "30750000",
    valueOfReclassifiedAssets: 4358385,
    depreciableAssets: 16,
  },
  {
    lat: 34.1220545,
    lng: -118.2935891,
    squareFootage: "66606",
    propertyType: "Multifamily",
    totalCosts: "8756649",
    valueOfReclassifiedAssets: 1262018,
    depreciableAssets: 17,
  },
  {
    lat: 34.1220545,
    lng: -118.2935891,
    squareFootage: "66606",
    propertyType: "Multifamily",
    totalCosts: "26678895",
    valueOfReclassifiedAssets: 3618818,
    depreciableAssets: 17,
  },
  {
    lat: 39.0587452,
    lng: -94.5985613,
    squareFootage: "8112",
    propertyType: "Multifamily",
    totalCosts: "750000",
    valueOfReclassifiedAssets: 130494,
    depreciableAssets: 20,
  },
  {
    lat: 34.1482691,
    lng: -118.4338345,
    squareFootage: "16260",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 729183,
    depreciableAssets: 14,
  },
  {
    lat: 40.6967435,
    lng: -74.16509909999999,
    squareFootage: "12700",
    propertyType: "Industrial",
    totalCosts: "782657",
    valueOfReclassifiedAssets: 50910,
    depreciableAssets: 7,
  },
  {
    lat: 37.4470062,
    lng: -77.665066,
    squareFootage: "6389",
    propertyType: "Office",
    totalCosts: "3893000",
    valueOfReclassifiedAssets: 946794,
    depreciableAssets: 29,
  },
  {
    lat: 35.6703562,
    lng: -81.36194019999999,
    squareFootage: "9100",
    propertyType: "Retail",
    totalCosts: "1360000",
    valueOfReclassifiedAssets: 497729,
    depreciableAssets: 43,
  },
  {
    lat: 43.1530599,
    lng: -77.6364299,
    squareFootage: "9256",
    propertyType: "Retail",
    totalCosts: "2165000",
    valueOfReclassifiedAssets: 349422,
    depreciableAssets: 19,
  },
  {
    lat: 39.2804946,
    lng: -94.6347041,
    squareFootage: "332024",
    propertyType: "Multifamily",
    totalCosts: "22720400",
    valueOfReclassifiedAssets: 6292192,
    depreciableAssets: 31,
  },
  {
    lat: 33.6148122,
    lng: -84.6688348,
    squareFootage: "159720",
    propertyType: "Multifamily",
    totalCosts: "10809600",
    valueOfReclassifiedAssets: 2517488,
    depreciableAssets: 26,
  },
  {
    lat: 40.1267952,
    lng: -74.0711359,
    squareFootage: "19664",
    propertyType: "Office",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 373868,
    depreciableAssets: 17,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "321840",
    propertyType: "Multifamily",
    totalCosts: "35000000",
    valueOfReclassifiedAssets: 4369327,
    depreciableAssets: 15,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "183114",
    propertyType: "Multifamily",
    totalCosts: "21500000",
    valueOfReclassifiedAssets: 3051689,
    depreciableAssets: 16,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "183248",
    propertyType: "Multifamily",
    totalCosts: "25000000",
    valueOfReclassifiedAssets: 3304154,
    depreciableAssets: 16,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "100548",
    propertyType: "Multifamily",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 1103046,
    depreciableAssets: 13,
  },
  {
    lat: 40.24488040000001,
    lng: -74.0593872,
    squareFootage: "274185",
    propertyType: "Multifamily",
    totalCosts: "45500000",
    valueOfReclassifiedAssets: 5294979,
    depreciableAssets: 14,
  },
  {
    lat: 40.2450268,
    lng: -74.7863698,
    squareFootage: "101220",
    propertyType: "Multifamily",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 765428,
    depreciableAssets: 14,
  },
  {
    lat: 36.0604347,
    lng: -95.9213656,
    squareFootage: "17001",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 348036,
    depreciableAssets: 14,
  },
  {
    lat: 27.905178,
    lng: -82.7158302,
    squareFootage: "341919",
    propertyType: "Multifamily",
    totalCosts: "55750000",
    valueOfReclassifiedAssets: 14041005,
    depreciableAssets: 30,
  },
  {
    lat: 32.8362389,
    lng: -97.2329255,
    squareFootage: "262233",
    propertyType: "Multifamily",
    totalCosts: "23750000",
    valueOfReclassifiedAssets: 8894331,
    depreciableAssets: 44,
  },
  {
    lat: 32.8721517,
    lng: -96.99609249999999,
    squareFootage: "80000",
    propertyType: "Multifamily",
    totalCosts: "8900000",
    valueOfReclassifiedAssets: 2573029,
    depreciableAssets: 34,
  },
  {
    lat: 41.8642989,
    lng: -87.8205864,
    squareFootage: "64918",
    propertyType: "Industrial",
    totalCosts: "5865939",
    valueOfReclassifiedAssets: 2014117,
    depreciableAssets: 40,
  },
  {
    lat: 25.9964301,
    lng: -97.5788975,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 3648821,
    depreciableAssets: 41,
  },
  {
    lat: 29.4854054,
    lng: -98.4513272,
    squareFootage: "20965",
    propertyType: "Retail",
    totalCosts: "1695497",
    valueOfReclassifiedAssets: 375078,
    depreciableAssets: 25,
  },
  {
    lat: 29.4854054,
    lng: -98.4513272,
    squareFootage: "21247",
    propertyType: "Office",
    totalCosts: "1470584",
    valueOfReclassifiedAssets: 234505,
    depreciableAssets: 21,
  },
  {
    lat: 29.5857075,
    lng: -98.4129396,
    squareFootage: "46900",
    propertyType: "Industrial",
    totalCosts: "931875",
    valueOfReclassifiedAssets: 858523,
    depreciableAssets: 19,
  },
  {
    lat: 29.5060908,
    lng: -98.577596,
    squareFootage: "26996",
    propertyType: "Retail",
    totalCosts: "1615000",
    valueOfReclassifiedAssets: 344273,
    depreciableAssets: 25,
  },
  {
    lat: 38.8697746,
    lng: -106.9814332,
    squareFootage: "2475",
    propertyType: "Multifamily",
    totalCosts: "1276742",
    valueOfReclassifiedAssets: 176945,
    depreciableAssets: 19,
  },
  {
    lat: 29.9173682,
    lng: -98.70407499999999,
    squareFootage: "14874",
    propertyType: "Retail",
    totalCosts: "1096030",
    valueOfReclassifiedAssets: 299527,
    depreciableAssets: 35,
  },
  {
    lat: 29.86706,
    lng: -98.4184224,
    squareFootage: "24215",
    propertyType: "Office",
    totalCosts: "1975092",
    valueOfReclassifiedAssets: 1784743,
    depreciableAssets: 20,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "556000",
    propertyType: "Multifamily",
    totalCosts: "33300000",
    valueOfReclassifiedAssets: 8664916,
    depreciableAssets: 30,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3324279",
    valueOfReclassifiedAssets: 2662868,
    depreciableAssets: 54,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1612133",
    valueOfReclassifiedAssets: 1031760,
    depreciableAssets: 58,
  },
  {
    lat: 37.5374438,
    lng: -77.5275463,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2266484",
    valueOfReclassifiedAssets: 1534082,
    depreciableAssets: 58,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "125490",
    propertyType: "Multifamily",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 1580139,
    depreciableAssets: 26,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1003373",
    valueOfReclassifiedAssets: 646133,
    depreciableAssets: 52,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1111475",
    valueOfReclassifiedAssets: 713691,
    depreciableAssets: 50,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "551328",
    valueOfReclassifiedAssets: 352211,
    depreciableAssets: 50,
  },
  {
    lat: 37.4759039,
    lng: -77.590597,
    squareFootage: "212425",
    propertyType: "Multifamily",
    totalCosts: "19200000",
    valueOfReclassifiedAssets: 4238797,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "691980",
    valueOfReclassifiedAssets: 469149,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2553799",
    valueOfReclassifiedAssets: 1731426,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "572456",
    valueOfReclassifiedAssets: 388114,
    depreciableAssets: 0,
  },
  {
    lat: 40.7930239,
    lng: -74.3235539,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "35618723",
    valueOfReclassifiedAssets: 29849170,
    depreciableAssets: 18,
  },
  {
    lat: 27.9363828,
    lng: -82.37773349999999,
    squareFootage: "38540",
    propertyType: "Retail",
    totalCosts: "5175000",
    valueOfReclassifiedAssets: 1845631,
    depreciableAssets: 42,
  },
  {
    lat: 28.0563834,
    lng: -82.4427769,
    squareFootage: "66517",
    propertyType: "Retail",
    totalCosts: "6075000",
    valueOfReclassifiedAssets: 1965015,
    depreciableAssets: 38,
  },
  {
    lat: 37.6164453,
    lng: -77.4816693,
    squareFootage: "165030",
    propertyType: "Multifamily",
    totalCosts: "20791759",
    valueOfReclassifiedAssets: 5251190,
    depreciableAssets: 31,
  },
  {
    lat: 37.6164453,
    lng: -77.4816693,
    squareFootage: "78902",
    propertyType: "Retail",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 351571,
    depreciableAssets: 28,
  },
  {
    lat: 30.3732514,
    lng: -97.8395151,
    squareFootage: "35955",
    propertyType: "Office",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 2615098,
    depreciableAssets: 26,
  },
  {
    lat: 39.3252511,
    lng: -76.71633589999999,
    squareFootage: "31644",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11709623",
    valueOfReclassifiedAssets: 5532163,
    depreciableAssets: 24,
  },
  {
    lat: 39.0554966,
    lng: -76.8144298,
    squareFootage: "55524",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "25076947",
    valueOfReclassifiedAssets: 13935040,
    depreciableAssets: 28,
  },
  {
    lat: 39.3416271,
    lng: -76.68169619999999,
    squareFootage: "32662",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12440000",
    valueOfReclassifiedAssets: 7377264,
    depreciableAssets: 26,
  },
  {
    lat: 39.3342834,
    lng: -76.5314817,
    squareFootage: "49463",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "39520245",
    valueOfReclassifiedAssets: 21081977,
    depreciableAssets: 31,
  },
  {
    lat: 34.0470832,
    lng: -118.2965121,
    squareFootage: "4158",
    propertyType: "Multifamily",
    totalCosts: "674994",
    valueOfReclassifiedAssets: 99399,
    depreciableAssets: 27,
  },
  {
    lat: 34.007889,
    lng: -118.2585096,
    squareFootage: "4352",
    propertyType: "Multifamily",
    totalCosts: "874504",
    valueOfReclassifiedAssets: 132193,
    depreciableAssets: 28,
  },
  {
    lat: 42.8680259,
    lng: -71.37569119999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11549372",
    valueOfReclassifiedAssets: 10078398,
    depreciableAssets: 23,
  },
  {
    lat: 33.9387862,
    lng: -118.3608046,
    squareFootage: "4248",
    propertyType: "Multifamily",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 269461,
    depreciableAssets: 25,
  },
  {
    lat: 42.2494514,
    lng: -71.1804988,
    squareFootage: "115823",
    propertyType: "Hotel",
    totalCosts: "26125000",
    valueOfReclassifiedAssets: 5134338,
    depreciableAssets: 29,
  },
  {
    lat: 42.5357932,
    lng: -70.9665682,
    squareFootage: "137798",
    propertyType: "Hotel",
    totalCosts: "16958334",
    valueOfReclassifiedAssets: 3069092,
    depreciableAssets: 26,
  },
  {
    lat: 42.347372,
    lng: -71.53678599999999,
    squareFootage: "111610",
    propertyType: "Hotel",
    totalCosts: "11916666",
    valueOfReclassifiedAssets: 2492189,
    depreciableAssets: 27,
  },
  {
    lat: 35.0848894,
    lng: -106.6468148,
    squareFootage: "186150",
    propertyType: "Hotel",
    totalCosts: "24300000",
    valueOfReclassifiedAssets: 5051200,
    depreciableAssets: 22,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "19425",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4620193",
    valueOfReclassifiedAssets: 1229863,
    depreciableAssets: 29,
  },
  {
    lat: 41.3308893,
    lng: -94.028678,
    squareFootage: "44998",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2353024",
    valueOfReclassifiedAssets: 635278,
    depreciableAssets: 29,
  },
  {
    lat: 36.1046869,
    lng: -79.8128649,
    squareFootage: "4113",
    propertyType: "Retail",
    totalCosts: "3871631",
    valueOfReclassifiedAssets: 420499,
    depreciableAssets: 31,
  },
  {
    lat: 47.1643833,
    lng: -122.5619529,
    squareFootage: "3024",
    propertyType: "Multifamily",
    totalCosts: "1627875",
    valueOfReclassifiedAssets: 671270,
    depreciableAssets: 48,
  },
  {
    lat: 47.1643833,
    lng: -122.5619529,
    squareFootage: "3024",
    propertyType: "Multifamily",
    totalCosts: "1687875",
    valueOfReclassifiedAssets: 696012,
    depreciableAssets: 48,
  },
  {
    lat: 37.961954,
    lng: -87.4703029,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 3965432,
    depreciableAssets: 22,
  },
  {
    lat: 45.68944949999999,
    lng: -122.5829013,
    squareFootage: "2618",
    propertyType: "Specialty",
    totalCosts: "1167848",
    valueOfReclassifiedAssets: 444229,
    depreciableAssets: 45,
  },
  {
    lat: 35.0535496,
    lng: -80.82116959999999,
    squareFootage: "29070",
    propertyType: "Office",
    totalCosts: "12444700",
    valueOfReclassifiedAssets: 3785616,
    depreciableAssets: 36,
  },
  {
    lat: 26.5101373,
    lng: -81.9425701,
    squareFootage: "77810",
    propertyType: "Retail",
    totalCosts: "11497845",
    valueOfReclassifiedAssets: 2506668,
    depreciableAssets: 26,
  },
  {
    lat: 32.7802521,
    lng: -96.7788461,
    squareFootage: "6000",
    propertyType: "Retail",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 258422,
    depreciableAssets: 17,
  },
  {
    lat: 41.0411194,
    lng: -73.541744,
    squareFootage: "179626",
    propertyType: "Multifamily",
    totalCosts: "50000000",
    valueOfReclassifiedAssets: 6841412,
    depreciableAssets: 16,
  },
  {
    lat: 26.1897731,
    lng: -80.1037721,
    squareFootage: "5054",
    propertyType: "Multifamily",
    totalCosts: "906750",
    valueOfReclassifiedAssets: 267150,
    depreciableAssets: 32,
  },
  {
    lat: 26.2474478,
    lng: -80.09260139999999,
    squareFootage: "13246",
    propertyType: "Multifamily",
    totalCosts: "1250925",
    valueOfReclassifiedAssets: 309070,
    depreciableAssets: 32,
  },
  {
    lat: 26.1531223,
    lng: -80.1233175,
    squareFootage: "17422",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 592498,
    depreciableAssets: 40,
  },
  {
    lat: 39.186097,
    lng: -77.20027449999999,
    squareFootage: "23674",
    propertyType: "Office",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 392283,
    depreciableAssets: 29,
  },
  {
    lat: 33.1593743,
    lng: -96.68058839999999,
    squareFootage: "2450",
    propertyType: "Office",
    totalCosts: "700000",
    valueOfReclassifiedAssets: 139350,
    depreciableAssets: 23,
  },
  {
    lat: 41.4437991,
    lng: -87.7221872,
    squareFootage: "94750",
    propertyType: "Multifamily",
    totalCosts: "8304000",
    valueOfReclassifiedAssets: 1705192,
    depreciableAssets: 24,
  },
  {
    lat: 41.4830183,
    lng: -87.68276759999999,
    squareFootage: "253015",
    propertyType: "Multifamily",
    totalCosts: "17400000",
    valueOfReclassifiedAssets: 2808903,
    depreciableAssets: 19,
  },
  {
    lat: 41.7019228,
    lng: -87.7787929,
    squareFootage: "87996",
    propertyType: "Multifamily",
    totalCosts: "6678000",
    valueOfReclassifiedAssets: 1221924,
    depreciableAssets: 21,
  },
  {
    lat: 37.1353668,
    lng: -87.1821709,
    squareFootage: "88523",
    propertyType: "Multifamily",
    totalCosts: "1063800",
    valueOfReclassifiedAssets: 258430,
    depreciableAssets: 29,
  },
  {
    lat: 37.1353668,
    lng: -87.1821709,
    squareFootage: "88523",
    propertyType: "Multifamily",
    totalCosts: "2482200",
    valueOfReclassifiedAssets: 603002,
    depreciableAssets: 29,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "54000",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 6372041,
    depreciableAssets: 58,
  },
  {
    lat: 41.0670166,
    lng: -73.6182979,
    squareFootage: "104210",
    propertyType: "Office",
    totalCosts: "35080713",
    valueOfReclassifiedAssets: 5847066,
    depreciableAssets: 20,
  },
  {
    lat: 45.7805621,
    lng: -108.5067071,
    squareFootage: "32227",
    propertyType: "Office",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1245336,
    depreciableAssets: 18,
  },
  {
    lat: 35.3515611,
    lng: -119.0576298,
    squareFootage: "17104",
    propertyType: "Office",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 513609,
    depreciableAssets: 21,
  },
  {
    lat: 40.9094443,
    lng: -74.0799469,
    squareFootage: "218492",
    propertyType: "Office",
    totalCosts: "4616654",
    valueOfReclassifiedAssets: 931906,
    depreciableAssets: 24,
  },
  {
    lat: 27.9765043,
    lng: -82.74280569999999,
    squareFootage: "99746",
    propertyType: "Hotel",
    totalCosts: "7775000",
    valueOfReclassifiedAssets: 3313847,
    depreciableAssets: 50,
  },
  {
    lat: 33.6496252,
    lng: -117.9190418,
    squareFootage: "3400",
    propertyType: "Industrial",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 429966,
    depreciableAssets: 30,
  },
  {
    lat: 33.6496252,
    lng: -117.9190418,
    squareFootage: "4360",
    propertyType: "Industrial",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 560897,
    depreciableAssets: 30,
  },
  {
    lat: 32.9956666,
    lng: -117.2646723,
    squareFootage: "6722",
    propertyType: "Specialty",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 3470301,
    depreciableAssets: 88,
  },
  {
    lat: 42.6340869,
    lng: -73.87719009999999,
    squareFootage: "224586",
    propertyType: "Multifamily",
    totalCosts: "2815824",
    valueOfReclassifiedAssets: 786957,
    depreciableAssets: 33,
  },
  {
    lat: 43.2108851,
    lng: -77.9510209,
    squareFootage: "33024",
    propertyType: "Multifamily",
    totalCosts: "4835000",
    valueOfReclassifiedAssets: 1548729,
    depreciableAssets: 38,
  },
  {
    lat: 42.9819148,
    lng: -75.96954649999999,
    squareFootage: "143984",
    propertyType: "Multifamily",
    totalCosts: "11011000",
    valueOfReclassifiedAssets: 2316049,
    depreciableAssets: 24,
  },
  {
    lat: 42.6340869,
    lng: -73.87719009999999,
    squareFootage: "113850",
    propertyType: "Multifamily",
    totalCosts: "9109000",
    valueOfReclassifiedAssets: 1711272,
    depreciableAssets: 23,
  },
  {
    lat: 43.1085295,
    lng: -76.1841701,
    squareFootage: "160116",
    propertyType: "Multifamily",
    totalCosts: "13620000",
    valueOfReclassifiedAssets: 3121103,
    depreciableAssets: 27,
  },
  {
    lat: 42.734263,
    lng: -73.5829689,
    squareFootage: "135238",
    propertyType: "Multifamily",
    totalCosts: "11860000",
    valueOfReclassifiedAssets: 1555559,
    depreciableAssets: 15,
  },
  {
    lat: 43.0915814,
    lng: -77.6421577,
    squareFootage: "138429",
    propertyType: "Multifamily",
    totalCosts: "9265000",
    valueOfReclassifiedAssets: 1903446,
    depreciableAssets: 25,
  },
  {
    lat: 43.1462926,
    lng: -76.2226032,
    squareFootage: "139074",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1208897,
    depreciableAssets: 23,
  },
  {
    lat: 32.8296623,
    lng: -96.8511814,
    squareFootage: "20093",
    propertyType: "Office",
    totalCosts: "6625000",
    valueOfReclassifiedAssets: 1745805,
    depreciableAssets: 37,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11202728",
    valueOfReclassifiedAssets: 9688410,
    depreciableAssets: 14,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "36752794",
    valueOfReclassifiedAssets: 32292101,
    depreciableAssets: 12,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "23930650",
    valueOfReclassifiedAssets: 21549423,
    depreciableAssets: 10,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "35000",
    propertyType: "Multifamily",
    totalCosts: "5086900",
    valueOfReclassifiedAssets: 4381291,
    depreciableAssets: 15,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9040467",
    valueOfReclassifiedAssets: 8178356,
    depreciableAssets: 11,
  },
  {
    lat: 31.2677027,
    lng: -92.4653598,
    squareFootage: "40203",
    propertyType: "Multifamily",
    totalCosts: "1292239",
    valueOfReclassifiedAssets: 422248,
    depreciableAssets: 37,
  },
  {
    lat: 31.2677027,
    lng: -92.4653598,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3365920",
    valueOfReclassifiedAssets: 2473794,
    depreciableAssets: 35,
  },
  {
    lat: 32.6905101,
    lng: -97.1305536,
    squareFootage: "190390",
    propertyType: "Multifamily",
    totalCosts: "17550000",
    valueOfReclassifiedAssets: 4491055,
    depreciableAssets: 30,
  },
  {
    lat: 41.9648232,
    lng: -83.6986568,
    squareFootage: "40800",
    propertyType: "Industrial",
    totalCosts: "1460000",
    valueOfReclassifiedAssets: 362830,
    depreciableAssets: 29,
  },
  {
    lat: 41.7084764,
    lng: -83.53900019999999,
    squareFootage: "99750",
    propertyType: "Industrial",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 486449,
    depreciableAssets: 23,
  },
  {
    lat: 39.370859,
    lng: -76.6701475,
    squareFootage: "42704",
    propertyType: "Office",
    totalCosts: "9892801",
    valueOfReclassifiedAssets: 1249611,
    depreciableAssets: 14,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 339983,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "23348",
    propertyType: "Multifamily",
    totalCosts: "10752000",
    valueOfReclassifiedAssets: 2751003,
    depreciableAssets: 30,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "36072",
    propertyType: "Multifamily",
    totalCosts: "8623000",
    valueOfReclassifiedAssets: 1722615,
    depreciableAssets: 23,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "67600",
    propertyType: "Multifamily",
    totalCosts: "17991000",
    valueOfReclassifiedAssets: 2904826,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "26244",
    propertyType: "Multifamily",
    totalCosts: "9475000",
    valueOfReclassifiedAssets: 1663889,
    depreciableAssets: 20,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "18664",
    propertyType: "Multifamily",
    totalCosts: "6020000",
    valueOfReclassifiedAssets: 1239189,
    depreciableAssets: 24,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "14916",
    propertyType: "Multifamily",
    totalCosts: "4791000",
    valueOfReclassifiedAssets: 927697,
    depreciableAssets: 23,
  },
  {
    lat: 40.6310753,
    lng: -73.9976946,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "8048000",
    valueOfReclassifiedAssets: 1224345,
    depreciableAssets: 18,
  },
  {
    lat: 36.8400852,
    lng: -76.1493891,
    squareFootage: "619670",
    propertyType: "Multifamily",
    totalCosts: "50750000",
    valueOfReclassifiedAssets: 11885066,
    depreciableAssets: 27,
  },
  {
    lat: 37.5621276,
    lng: -77.3668965,
    squareFootage: "31870",
    propertyType: "Multifamily",
    totalCosts: "49500000",
    valueOfReclassifiedAssets: 5445888,
    depreciableAssets: 13,
  },
  {
    lat: 36.1857974,
    lng: -79.8184684,
    squareFootage: "196850",
    propertyType: "Multifamily",
    totalCosts: "18700000",
    valueOfReclassifiedAssets: 3625343,
    depreciableAssets: 23,
  },
  {
    lat: 32.8621631,
    lng: -96.8726295,
    squareFootage: "148900",
    propertyType: "Multifamily",
    totalCosts: "13400000",
    valueOfReclassifiedAssets: 3789812,
    depreciableAssets: 33,
  },
  {
    lat: 39.9795244,
    lng: -75.145782,
    squareFootage: "20264",
    propertyType: "Industrial",
    totalCosts: "2134500",
    valueOfReclassifiedAssets: 153457,
    depreciableAssets: 9,
  },
  {
    lat: 35.1927931,
    lng: -89.8692203,
    squareFootage: "944293",
    propertyType: "Multifamily",
    totalCosts: "52600000",
    valueOfReclassifiedAssets: 17647590,
    depreciableAssets: 38,
  },
  {
    lat: 38.8630091,
    lng: -94.6760395,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9770978",
    valueOfReclassifiedAssets: 8302418,
    depreciableAssets: 16,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "20620",
    propertyType: "Specialty",
    totalCosts: "7660375",
    valueOfReclassifiedAssets: 1758507,
    depreciableAssets: 27,
  },
  {
    lat: 33.5754008,
    lng: -84.5327038,
    squareFootage: "307418",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 5935675,
    depreciableAssets: 0,
  },
  {
    lat: 29.6245936,
    lng: -95.3207069,
    squareFootage: "260438",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 4267311,
    depreciableAssets: 0,
  },
  {
    lat: 33.7994803,
    lng: -84.1909841,
    squareFootage: "189486",
    propertyType: "Multifamily",
    totalCosts: "12750000",
    valueOfReclassifiedAssets: 3616841,
    depreciableAssets: 0,
  },
  {
    lat: 33.7597692,
    lng: -84.17516479999999,
    squareFootage: "336640",
    propertyType: "Multifamily",
    totalCosts: "22350000",
    valueOfReclassifiedAssets: 6424020,
    depreciableAssets: 0,
  },
  {
    lat: 30.4862944,
    lng: -87.19213599999999,
    squareFootage: "210644",
    propertyType: "Multifamily",
    totalCosts: "17800000",
    valueOfReclassifiedAssets: 4794240,
    depreciableAssets: 0,
  },
  {
    lat: 33.6832181,
    lng: -84.44877199999999,
    squareFootage: "576605",
    propertyType: "Multifamily",
    totalCosts: "32562000",
    valueOfReclassifiedAssets: 8429301,
    depreciableAssets: 0,
  },
  {
    lat: 32.2255867,
    lng: -90.15418000000001,
    squareFootage: "243760",
    propertyType: "Multifamily",
    totalCosts: "14100000",
    valueOfReclassifiedAssets: 3460059,
    depreciableAssets: 0,
  },
  {
    lat: 29.6813519,
    lng: -95.2005729,
    squareFootage: "119586",
    propertyType: "Multifamily",
    totalCosts: "7550000",
    valueOfReclassifiedAssets: 1690895,
    depreciableAssets: 26,
  },
  {
    lat: 29.6526666,
    lng: -95.1953554,
    squareFootage: "157062",
    propertyType: "Multifamily",
    totalCosts: "12150000",
    valueOfReclassifiedAssets: 3088266,
    depreciableAssets: 30,
  },
  {
    lat: 29.71601119999999,
    lng: -95.2005729,
    squareFootage: "150060",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3244570,
    depreciableAssets: 32,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "46972",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4624842",
    valueOfReclassifiedAssets: 858845,
    depreciableAssets: 20,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "42619",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3417897",
    valueOfReclassifiedAssets: 708094,
    depreciableAssets: 22,
  },
  {
    lat: 30.2259488,
    lng: -97.7145152,
    squareFootage: "37864",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4140640",
    valueOfReclassifiedAssets: 864697,
    depreciableAssets: 22,
  },
  {
    lat: 29.39781439999999,
    lng: -98.7371039,
    squareFootage: "42973",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3535387",
    valueOfReclassifiedAssets: 698820,
    depreciableAssets: 21,
  },
  {
    lat: 39.2632524,
    lng: -76.4910093,
    squareFootage: "30641",
    propertyType: "Retail",
    totalCosts: "4356264",
    valueOfReclassifiedAssets: 3581189,
    depreciableAssets: 21,
  },
  {
    lat: 35.3447622,
    lng: -94.3667907,
    squareFootage: "62117",
    propertyType: "Retail",
    totalCosts: "9444675",
    valueOfReclassifiedAssets: 2149005,
    depreciableAssets: 27,
  },
  {
    lat: 42.3358116,
    lng: -83.3842808,
    squareFootage: "90537",
    propertyType: "Retail",
    totalCosts: "14720981",
    valueOfReclassifiedAssets: 2604315,
    depreciableAssets: 21,
  },
  {
    lat: 32.6786908,
    lng: -97.3354531,
    squareFootage: "202681",
    propertyType: "Multifamily",
    totalCosts: "4926002",
    valueOfReclassifiedAssets: 1291839,
    depreciableAssets: 29,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "46972",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4683202",
    valueOfReclassifiedAssets: 684425,
    depreciableAssets: 16,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "42619",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3463450",
    valueOfReclassifiedAssets: 571786,
    depreciableAssets: 18,
  },
  {
    lat: 30.168207,
    lng: -97.8177601,
    squareFootage: "38423",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5226514",
    valueOfReclassifiedAssets: 857801,
    depreciableAssets: 6,
  },
  {
    lat: 30.2259488,
    lng: -97.7145152,
    squareFootage: "37864",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4193862",
    valueOfReclassifiedAssets: 702307,
    depreciableAssets: 7,
  },
  {
    lat: 29.5330493,
    lng: -98.78116449999999,
    squareFootage: "2019",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2604104",
    valueOfReclassifiedAssets: 474667,
    depreciableAssets: 20,
  },
  {
    lat: 29.39781439999999,
    lng: -98.7371039,
    squareFootage: "42973",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3582187",
    valueOfReclassifiedAssets: 569782,
    depreciableAssets: 17,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "203192",
    propertyType: "Multifamily",
    totalCosts: "360135",
    valueOfReclassifiedAssets: 77896,
    depreciableAssets: 24,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "203192",
    propertyType: "Multifamily",
    totalCosts: "1111410",
    valueOfReclassifiedAssets: 240396,
    depreciableAssets: 24,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "203192",
    propertyType: "Multifamily",
    totalCosts: "379215",
    valueOfReclassifiedAssets: 82024,
    depreciableAssets: 24,
  },
  {
    lat: 33.473501,
    lng: -84.5012435,
    squareFootage: "203192",
    propertyType: "Multifamily",
    totalCosts: "21999240",
    valueOfReclassifiedAssets: 4758407,
    depreciableAssets: 24,
  },
  {
    lat: 35.9980929,
    lng: -94.08999109999999,
    squareFootage: "40287",
    propertyType: "Multifamily",
    totalCosts: "1901829",
    valueOfReclassifiedAssets: 557461,
    depreciableAssets: 37,
  },
  {
    lat: 34.17630630000001,
    lng: -84.2910759,
    squareFootage: "12151",
    propertyType: "Specialty",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 1027961,
    depreciableAssets: 37,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "682941",
    valueOfReclassifiedAssets: 544995,
    depreciableAssets: 64,
  },
  {
    lat: 25.8207159,
    lng: -80.1819268,
    squareFootage: "2033",
    propertyType: "Retail",
    totalCosts: "1450000",
    valueOfReclassifiedAssets: 222168,
    depreciableAssets: 31,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "124108",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5302247",
    valueOfReclassifiedAssets: 1220307,
    depreciableAssets: 27,
  },
  {
    lat: 28.8687867,
    lng: -95.94097099999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1610632",
    valueOfReclassifiedAssets: 627976,
    depreciableAssets: 41,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "98780",
    propertyType: "Office",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 2583190,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5779190",
    valueOfReclassifiedAssets: 1892973,
    depreciableAssets: 69,
  },
  {
    lat: 38.8163486,
    lng: -104.8512993,
    squareFootage: "12988",
    propertyType: "Multifamily",
    totalCosts: "1680000",
    valueOfReclassifiedAssets: 401621,
    depreciableAssets: 28,
  },
  {
    lat: 38.8413423,
    lng: -104.8162784,
    squareFootage: "17240",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 652963,
    depreciableAssets: 32,
  },
  {
    lat: 38.7048818,
    lng: -90.3465971,
    squareFootage: "244915",
    propertyType: "Multifamily",
    totalCosts: "25900000",
    valueOfReclassifiedAssets: 6996420,
    depreciableAssets: 30,
  },
  {
    lat: 38.6044507,
    lng: -90.5588787,
    squareFootage: "151800",
    propertyType: "Multifamily",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 4048907,
    depreciableAssets: 28,
  },
  {
    lat: 38.9071042,
    lng: -94.8728093,
    squareFootage: "310920",
    propertyType: "Multifamily",
    totalCosts: "43000000",
    valueOfReclassifiedAssets: 8372327,
    depreciableAssets: 22,
  },
  {
    lat: 32.9533477,
    lng: -97.1467072,
    squareFootage: "7221",
    propertyType: "Retail",
    totalCosts: "854249",
    valueOfReclassifiedAssets: 212103,
    depreciableAssets: 35,
  },
  {
    lat: 37.79593620000001,
    lng: -122.4000032,
    squareFootage: "54787",
    propertyType: "Office",
    totalCosts: "29440605",
    valueOfReclassifiedAssets: 23552484,
    depreciableAssets: 100,
  },
  {
    lat: 37.79593620000001,
    lng: -122.4000032,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6063240",
    valueOfReclassifiedAssets: 5160003,
    depreciableAssets: 78,
  },
  {
    lat: 37.7909427,
    lng: -122.4084994,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2614790",
    valueOfReclassifiedAssets: 1227769,
    depreciableAssets: 59,
  },
  {
    lat: 37.8059887,
    lng: -122.4099154,
    squareFootage: "28586",
    propertyType: "Office",
    totalCosts: "31771200",
    valueOfReclassifiedAssets: 2532911,
    depreciableAssets: 10,
  },
  {
    lat: 37.8059887,
    lng: -122.4099154,
    squareFootage: "32722",
    propertyType: "Multifamily",
    totalCosts: "26900000",
    valueOfReclassifiedAssets: 2109279,
    depreciableAssets: 10,
  },
  {
    lat: 40.5989331,
    lng: -106.8178377,
    squareFootage: "65358",
    propertyType: "Retail",
    totalCosts: "12383101",
    valueOfReclassifiedAssets: 1953636,
    depreciableAssets: 19,
  },
  {
    lat: 36.8129075,
    lng: -119.7469472,
    squareFootage: "78290",
    propertyType: "Multifamily",
    totalCosts: "20653769",
    valueOfReclassifiedAssets: 4189118,
    depreciableAssets: 24,
  },
  {
    lat: 29.7597385,
    lng: -95.2632201,
    squareFootage: "90731",
    propertyType: "Multifamily",
    totalCosts: "8788276",
    valueOfReclassifiedAssets: 2490730,
    depreciableAssets: 31,
  },
  {
    lat: 41.4774192,
    lng: -90.9438468,
    squareFootage: "75000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4165687",
    valueOfReclassifiedAssets: 828176,
    depreciableAssets: 22,
  },
  {
    lat: 40.8029914,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2958019",
    valueOfReclassifiedAssets: 2361771,
    depreciableAssets: 36,
  },
  {
    lat: 40.64423379999999,
    lng: -91.5789994,
    squareFootage: "41250",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1170000",
    valueOfReclassifiedAssets: 917138,
    depreciableAssets: 26,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "13300",
    propertyType: "Multifamily",
    totalCosts: "7125000",
    valueOfReclassifiedAssets: 820407,
    depreciableAssets: 13,
  },
  {
    lat: 39.7312095,
    lng: -104.9826965,
    squareFootage: "12771",
    propertyType: "Multifamily",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 786138,
    depreciableAssets: 24,
  },
  {
    lat: 39.73274600000001,
    lng: -104.9710123,
    squareFootage: "11917",
    propertyType: "Multifamily",
    totalCosts: "2632500",
    valueOfReclassifiedAssets: 534801,
    depreciableAssets: 23,
  },
  {
    lat: 33.6289952,
    lng: -112.1370601,
    squareFootage: "70000",
    propertyType: "Retail",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 2093189,
    depreciableAssets: 32,
  },
  {
    lat: 29.6549449,
    lng: -95.2841177,
    squareFootage: "53922",
    propertyType: "Multifamily",
    totalCosts: "3950000",
    valueOfReclassifiedAssets: 1197913,
    depreciableAssets: 36,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "206800",
    propertyType: "Multifamily",
    totalCosts: "22140000",
    valueOfReclassifiedAssets: 4245573,
    depreciableAssets: 23,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "206800",
    propertyType: "Multifamily",
    totalCosts: "9697320",
    valueOfReclassifiedAssets: 1859561,
    depreciableAssets: 23,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "206800",
    propertyType: "Multifamily",
    totalCosts: "3646458",
    valueOfReclassifiedAssets: 699246,
    depreciableAssets: 23,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "206800",
    propertyType: "Multifamily",
    totalCosts: "6688494",
    valueOfReclassifiedAssets: 1282588,
    depreciableAssets: 23,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "206800",
    propertyType: "Multifamily",
    totalCosts: "2107728",
    valueOfReclassifiedAssets: 404179,
    depreciableAssets: 23,
  },
  {
    lat: 42.6292743,
    lng: -87.8746146,
    squareFootage: "354000",
    propertyType: "Industrial",
    totalCosts: "29300000",
    valueOfReclassifiedAssets: 3519423,
    depreciableAssets: 14,
  },
  {
    lat: 32.3822577,
    lng: -86.1914604,
    squareFootage: "172600",
    propertyType: "Multifamily",
    totalCosts: "11400000",
    valueOfReclassifiedAssets: 3069154,
    depreciableAssets: 31,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "306628",
    propertyType: "Multifamily",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 3456011,
    depreciableAssets: 31,
  },
  {
    lat: 29.3037971,
    lng: -98.40745729999999,
    squareFootage: "130610",
    propertyType: "Multifamily",
    totalCosts: "7450000",
    valueOfReclassifiedAssets: 1808093,
    depreciableAssets: 28,
  },
  {
    lat: 35.1377209,
    lng: -90.19633639999999,
    squareFootage: "135620",
    propertyType: "Multifamily",
    totalCosts: "3382725",
    valueOfReclassifiedAssets: 801800,
    depreciableAssets: 28,
  },
  {
    lat: 35.1377209,
    lng: -90.19633639999999,
    squareFootage: "135620",
    propertyType: "Multifamily",
    totalCosts: "466755",
    valueOfReclassifiedAssets: 110634,
    depreciableAssets: 28,
  },
  {
    lat: 35.1377209,
    lng: -90.19633639999999,
    squareFootage: "135620",
    propertyType: "Multifamily",
    totalCosts: "1700520",
    valueOfReclassifiedAssets: 403071,
    depreciableAssets: 28,
  },
  {
    lat: 41.6881608,
    lng: -72.7865518,
    squareFootage: "18624",
    propertyType: "Multifamily",
    totalCosts: "1125000",
    valueOfReclassifiedAssets: 143245,
    depreciableAssets: 16,
  },
  {
    lat: 36.0592679,
    lng: -86.67060769999999,
    squareFootage: "144469",
    propertyType: "Retail",
    totalCosts: "12100000",
    valueOfReclassifiedAssets: 3423133,
    depreciableAssets: 32,
  },
  {
    lat: 36.849734,
    lng: -76.097201,
    squareFootage: "175731",
    propertyType: "Retail",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 1756723,
    depreciableAssets: 20,
  },
  {
    lat: 28.2621197,
    lng: -82.37773349999999,
    squareFootage: "471279",
    propertyType: "Retail",
    totalCosts: "62700000",
    valueOfReclassifiedAssets: 11482785,
    depreciableAssets: 22,
  },
  {
    lat: 35.5910505,
    lng: -80.8765205,
    squareFootage: "39550",
    propertyType: "Industrial",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 774250,
    depreciableAssets: 29,
  },
  {
    lat: 35.0849752,
    lng: -80.5216184,
    squareFootage: "138400",
    propertyType: "Industrial",
    totalCosts: "3317172",
    valueOfReclassifiedAssets: 280245,
    depreciableAssets: 10,
  },
  {
    lat: 32.3782774,
    lng: -96.7708138,
    squareFootage: "2017",
    propertyType: "Retail",
    totalCosts: "14000000",
    valueOfReclassifiedAssets: 2630947,
    depreciableAssets: 22,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1572000",
    valueOfReclassifiedAssets: 587925,
    depreciableAssets: 44,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "8390",
    propertyType: "Specialty",
    totalCosts: "3135000",
    valueOfReclassifiedAssets: 637978,
    depreciableAssets: 24,
  },
  {
    lat: 33.9127807,
    lng: -118.3520389,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17347016",
    valueOfReclassifiedAssets: 15038262,
    depreciableAssets: 16,
  },
  {
    lat: 34.2103962,
    lng: -118.1239717,
    squareFootage: "14641",
    propertyType: "Retail",
    totalCosts: "4384729",
    valueOfReclassifiedAssets: 829071,
    depreciableAssets: 22,
  },
  {
    lat: 34.2103962,
    lng: -118.1239717,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "615271",
    valueOfReclassifiedAssets: 212809,
    depreciableAssets: 41,
  },
  {
    lat: 35.8487812,
    lng: -80.2990246,
    squareFootage: "60750",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 1017129,
    depreciableAssets: 35,
  },
  {
    lat: 40.7071412,
    lng: -73.82867329999999,
    squareFootage: "56100",
    propertyType: "Multifamily",
    totalCosts: "13320000",
    valueOfReclassifiedAssets: 1921128,
    depreciableAssets: 17,
  },
  {
    lat: 40.7071412,
    lng: -73.82867329999999,
    squareFootage: "105000",
    propertyType: "Multifamily",
    totalCosts: "22680000",
    valueOfReclassifiedAssets: 3318826,
    depreciableAssets: 18,
  },
  {
    lat: 40.7989068,
    lng: -74.1885825,
    squareFootage: "571560",
    propertyType: "Multifamily",
    totalCosts: "130700000",
    valueOfReclassifiedAssets: 20662839,
    depreciableAssets: 19,
  },
  {
    lat: 40.8235952,
    lng: -74.1533563,
    squareFootage: "140408",
    propertyType: "Multifamily",
    totalCosts: "30700000",
    valueOfReclassifiedAssets: 4779504,
    depreciableAssets: 18,
  },
  {
    lat: 40.8333184,
    lng: -74.0123851,
    squareFootage: "151596",
    propertyType: "Multifamily",
    totalCosts: "32100000",
    valueOfReclassifiedAssets: 3693343,
    depreciableAssets: 14,
  },
  {
    lat: 40.8333184,
    lng: -74.0123851,
    squareFootage: "206252",
    propertyType: "Multifamily",
    totalCosts: "45600000",
    valueOfReclassifiedAssets: 5198874,
    depreciableAssets: 14,
  },
  {
    lat: 40.7989068,
    lng: -74.1885825,
    squareFootage: "56736",
    propertyType: "Multifamily",
    totalCosts: "11900000",
    valueOfReclassifiedAssets: 1245957,
    depreciableAssets: 12,
  },
  {
    lat: 40.8333184,
    lng: -74.0123851,
    squareFootage: "119400",
    propertyType: "Multifamily",
    totalCosts: "37300000",
    valueOfReclassifiedAssets: 5428808,
    depreciableAssets: 17,
  },
  {
    lat: 40.9020267,
    lng: -74.0623244,
    squareFootage: "84000",
    propertyType: "Multifamily",
    totalCosts: "21700000",
    valueOfReclassifiedAssets: 4012053,
    depreciableAssets: 22,
  },
  {
    lat: 42.4279422,
    lng: -83.3682539,
    squareFootage: "52730",
    propertyType: "Retail",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 3004857,
    depreciableAssets: 41,
  },
  {
    lat: 41.0411194,
    lng: -73.541744,
    squareFootage: "21840",
    propertyType: "Specialty",
    totalCosts: "4892045",
    valueOfReclassifiedAssets: 1659052,
    depreciableAssets: 36,
  },
  {
    lat: 39.7380371,
    lng: -105.0265195,
    squareFootage: "118480",
    propertyType: "Multifamily",
    totalCosts: "29338495",
    valueOfReclassifiedAssets: 5732929,
    depreciableAssets: 22,
  },
  {
    lat: 40.804574,
    lng: -96.6157192,
    squareFootage: "90080",
    propertyType: "Multifamily",
    totalCosts: "7985412",
    valueOfReclassifiedAssets: 1610412,
    depreciableAssets: 23,
  },
  {
    lat: 34.319379,
    lng: -83.81547909999999,
    squareFootage: "143742",
    propertyType: "Multifamily",
    totalCosts: "5843487",
    valueOfReclassifiedAssets: 1771259,
    depreciableAssets: 32,
  },
  {
    lat: 42.3316674,
    lng: -89.1515476,
    squareFootage: "148044",
    propertyType: "Multifamily",
    totalCosts: "10510465",
    valueOfReclassifiedAssets: 2213167,
    depreciableAssets: 22,
  },
  {
    lat: 34.8505552,
    lng: -82.39399999999999,
    squareFootage: "231753",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 3492184,
    depreciableAssets: 19,
  },
  {
    lat: 33.7220258,
    lng: -84.201528,
    squareFootage: "48384",
    propertyType: "Multifamily",
    totalCosts: "3696000",
    valueOfReclassifiedAssets: 1246831,
    depreciableAssets: 39,
  },
  {
    lat: 32.6601786,
    lng: -83.666758,
    squareFootage: "135862",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 941508,
    depreciableAssets: 22,
  },
  {
    lat: 32.6601786,
    lng: -83.666758,
    squareFootage: "50550",
    propertyType: "Multifamily",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 505912,
    depreciableAssets: 23,
  },
  {
    lat: 33.0039673,
    lng: -97.2257894,
    squareFootage: "16356",
    propertyType: "Retail",
    totalCosts: "2479501",
    valueOfReclassifiedAssets: 2179381,
    depreciableAssets: 28,
  },
  {
    lat: 40.7645239,
    lng: -73.96243270000001,
    squareFootage: "258785",
    propertyType: "Multifamily",
    totalCosts: "67500000",
    valueOfReclassifiedAssets: 16201923,
    depreciableAssets: 28,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "54319",
    propertyType: "Multifamily",
    totalCosts: "32411550",
    valueOfReclassifiedAssets: 2971695,
    depreciableAssets: 12,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "10889",
    propertyType: "Multifamily",
    totalCosts: "4910529",
    valueOfReclassifiedAssets: 768719,
    depreciableAssets: 19,
  },
  {
    lat: 32.4800392,
    lng: -87.0424393,
    squareFootage: "49540",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10050000",
    valueOfReclassifiedAssets: 5922569,
    depreciableAssets: 45,
  },
  {
    lat: 33.4480108,
    lng: -86.9248483,
    squareFootage: "45666",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12300000",
    valueOfReclassifiedAssets: 7014162,
    depreciableAssets: 46,
  },
  {
    lat: 32.1395316,
    lng: -88.22664340000001,
    squareFootage: "55048",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 4384256,
    depreciableAssets: 46,
  },
  {
    lat: 32.265915,
    lng: -86.2260131,
    squareFootage: "53299",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9365000",
    valueOfReclassifiedAssets: 5554192,
    depreciableAssets: 45,
  },
  {
    lat: 30.48763749999999,
    lng: -87.8746146,
    squareFootage: "28372",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4035000",
    valueOfReclassifiedAssets: 2323943,
    depreciableAssets: 46,
  },
  {
    lat: 41.3278772,
    lng: -73.69482339999999,
    squareFootage: "195403",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "46950000",
    valueOfReclassifiedAssets: 11600673,
    depreciableAssets: 29,
  },
  {
    lat: 36.2722491,
    lng: -86.7114545,
    squareFootage: "65621",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 7070462,
    depreciableAssets: 33,
  },
  {
    lat: 40.7881249,
    lng: -73.19396119999999,
    squareFootage: "18601",
    propertyType: "Specialty",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 332130,
    depreciableAssets: 13,
  },
  {
    lat: 41.425548,
    lng: -81.82790109999999,
    squareFootage: "22920",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3944620",
    valueOfReclassifiedAssets: 1068106,
    depreciableAssets: 32,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8583830",
    valueOfReclassifiedAssets: 7749886,
    depreciableAssets: 23,
  },
  {
    lat: 40.0958615,
    lng: -75.1720488,
    squareFootage: "59443",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 1839036,
    depreciableAssets: 27,
  },
  {
    lat: 40.8663111,
    lng: -73.6241855,
    squareFootage: "114759",
    propertyType: "Multifamily",
    totalCosts: "20674564",
    valueOfReclassifiedAssets: 4672173,
    depreciableAssets: 27,
  },
  {
    lat: 36.27037990000001,
    lng: -86.2767995,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "533648",
    valueOfReclassifiedAssets: 233545,
    depreciableAssets: 0,
  },
  {
    lat: 36.1482458,
    lng: -86.73660819999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "499843",
    valueOfReclassifiedAssets: 300578,
    depreciableAssets: 0,
  },
  {
    lat: 36.1482458,
    lng: -86.73660819999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "242479",
    valueOfReclassifiedAssets: 71706,
    depreciableAssets: 0,
  },
  {
    lat: 36.1482458,
    lng: -86.73660819999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "811191",
    valueOfReclassifiedAssets: 250429,
    depreciableAssets: 0,
  },
  {
    lat: 40.8902916,
    lng: -72.9578676,
    squareFootage: "12786",
    propertyType: "Specialty",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 577557,
    depreciableAssets: 23,
  },
  {
    lat: 40.8441592,
    lng: -73.1408613,
    squareFootage: "111737",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "42000000",
    valueOfReclassifiedAssets: 5201676,
    depreciableAssets: 15,
  },
  {
    lat: 26.3510294,
    lng: -80.15402259999999,
    squareFootage: "82000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "26359802",
    valueOfReclassifiedAssets: 7673232,
    depreciableAssets: 34,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1640361",
    valueOfReclassifiedAssets: 1198184,
    depreciableAssets: 0,
  },
  {
    lat: 38.8565647,
    lng: -77.0967357,
    squareFootage: "95130",
    propertyType: "Multifamily",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1767861,
    depreciableAssets: 25,
  },
  {
    lat: 38.8845126,
    lng: -77.0938583,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "19402021",
    valueOfReclassifiedAssets: 6977845,
    depreciableAssets: 67,
  },
  {
    lat: 38.8845126,
    lng: -77.0938583,
    squareFootage: "336910",
    propertyType: "Multifamily",
    totalCosts: "44991973",
    valueOfReclassifiedAssets: 8809156,
    depreciableAssets: 23,
  },
  {
    lat: 32.7433629,
    lng: -96.8887202,
    squareFootage: "85490",
    propertyType: "Multifamily",
    totalCosts: "4187169",
    valueOfReclassifiedAssets: 1179200,
    depreciableAssets: 31,
  },
  {
    lat: 44.3139974,
    lng: -93.29861059999999,
    squareFootage: "59128",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7759123",
    valueOfReclassifiedAssets: 1828697,
    depreciableAssets: 26,
  },
  {
    lat: 44.3683222,
    lng: -100.3510108,
    squareFootage: "24278",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3435622",
    valueOfReclassifiedAssets: 1094305,
    depreciableAssets: 35,
  },
  {
    lat: 44.9666491,
    lng: -97.09551689999999,
    squareFootage: "25633",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2295000",
    valueOfReclassifiedAssets: 798396,
    depreciableAssets: 39,
  },
  {
    lat: 44.3651175,
    lng: -97.1359377,
    squareFootage: "14528",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "562500",
    valueOfReclassifiedAssets: 237315,
    depreciableAssets: 47,
  },
  {
    lat: 43.2940787,
    lng: -98.352656,
    squareFootage: "28096",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 386774,
    depreciableAssets: 37,
  },
  {
    lat: 44.0219841,
    lng: -103.4049445,
    squareFootage: "19844",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3427818",
    valueOfReclassifiedAssets: 1378351,
    depreciableAssets: 45,
  },
  {
    lat: 44.84769379999999,
    lng: -97.77163739999999,
    squareFootage: "15484",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "400000",
    valueOfReclassifiedAssets: 159574,
    depreciableAssets: 44,
  },
  {
    lat: 45.4261077,
    lng: -98.11292560000001,
    squareFootage: "22839",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1040000",
    valueOfReclassifiedAssets: 376733,
    depreciableAssets: 40,
  },
  {
    lat: 45.4731325,
    lng: -99.00053609999999,
    squareFootage: "25663",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "839704",
    valueOfReclassifiedAssets: 287051,
    depreciableAssets: 38,
  },
  {
    lat: 44.5675659,
    lng: -97.2005806,
    squareFootage: "22544",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1421000",
    valueOfReclassifiedAssets: 504080,
    depreciableAssets: 39,
  },
  {
    lat: 44.0219841,
    lng: -103.4049445,
    squareFootage: "33133",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 1072342,
    depreciableAssets: 30,
  },
  {
    lat: 44.14003109999999,
    lng: -103.1971067,
    squareFootage: "21184",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1887623",
    valueOfReclassifiedAssets: 637420,
    depreciableAssets: 38,
  },
  {
    lat: 44.8642333,
    lng: -98.58314969999999,
    squareFootage: "24661",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "837500",
    valueOfReclassifiedAssets: 300368,
    depreciableAssets: 40,
  },
  {
    lat: 44.14003109999999,
    lng: -103.1971067,
    squareFootage: "25806",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4169309",
    valueOfReclassifiedAssets: 1431157,
    depreciableAssets: 38,
  },
  {
    lat: 43.7427308,
    lng: -97.394882,
    squareFootage: "24618",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "768213",
    valueOfReclassifiedAssets: 310473,
    depreciableAssets: 45,
  },
  {
    lat: 44.3107829,
    lng: -96.79223549999999,
    squareFootage: "48046",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3261732",
    valueOfReclassifiedAssets: 1077032,
    depreciableAssets: 36,
  },
  {
    lat: 44.3139974,
    lng: -93.29861059999999,
    squareFootage: "17750",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "817808",
    valueOfReclassifiedAssets: 122287,
    depreciableAssets: 16,
  },
  {
    lat: 44.9851993,
    lng: -103.8307232,
    squareFootage: "67510",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4881505",
    valueOfReclassifiedAssets: 1222559,
    depreciableAssets: 28,
  },
  {
    lat: 44.481587,
    lng: -103.9319295,
    squareFootage: "47930",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5926185",
    valueOfReclassifiedAssets: 1595795,
    depreciableAssets: 30,
  },
  {
    lat: 42.1335205,
    lng: -87.9187711,
    squareFootage: "64457",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8315000",
    valueOfReclassifiedAssets: 1561500,
    depreciableAssets: 20,
  },
  {
    lat: 42.1335205,
    lng: -87.9187711,
    squareFootage: "64457",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8315000",
    valueOfReclassifiedAssets: 1498139,
    depreciableAssets: 20,
  },
  {
    lat: 42.0290522,
    lng: -88.1730427,
    squareFootage: "83942",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11685000",
    valueOfReclassifiedAssets: 2013022,
    depreciableAssets: 19,
  },
  {
    lat: 44.4453916,
    lng: -98.1775638,
    squareFootage: "46765",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1271680,
    depreciableAssets: 35,
  },
  {
    lat: 34.0499095,
    lng: -118.0478826,
    squareFootage: "20701",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9053500",
    valueOfReclassifiedAssets: 2874293,
    depreciableAssets: 35,
  },
  {
    lat: 33.78659,
    lng: -117.8750976,
    squareFootage: "50877",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17236272",
    valueOfReclassifiedAssets: 4339895,
    depreciableAssets: 28,
  },
  {
    lat: 33.9041552,
    lng: -117.9307584,
    squareFootage: "22445",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1903010,
    depreciableAssets: 31,
  },
  {
    lat: 34.9339263,
    lng: -120.2051096,
    squareFootage: "21481",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9800000",
    valueOfReclassifiedAssets: 2945773,
    depreciableAssets: 33,
  },
  {
    lat: 41.75798959999999,
    lng: -88.29476749999999,
    squareFootage: "55815",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1674667,
    depreciableAssets: 26,
  },
  {
    lat: 41.75798959999999,
    lng: -88.29476749999999,
    squareFootage: "67503",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10176234",
    valueOfReclassifiedAssets: 2206649,
    depreciableAssets: 24,
  },
  {
    lat: 39.1629772,
    lng: -84.4540213,
    squareFootage: "33720",
    propertyType: "Multifamily",
    totalCosts: "785000",
    valueOfReclassifiedAssets: 207451,
    depreciableAssets: 31,
  },
  {
    lat: 41.3517324,
    lng: -72.0349647,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9588000",
    valueOfReclassifiedAssets: 5221595,
    depreciableAssets: 65,
  },
  {
    lat: 39.6682515,
    lng: -105.096661,
    squareFootage: "202333",
    propertyType: "Multifamily",
    totalCosts: "19478136",
    valueOfReclassifiedAssets: 4651422,
    depreciableAssets: 30,
  },
  {
    lat: 36.6644676,
    lng: -76.5950555,
    squareFootage: "271840",
    propertyType: "Multifamily",
    totalCosts: "32779968",
    valueOfReclassifiedAssets: 6826196,
    depreciableAssets: 26,
  },
  {
    lat: 47.9990269,
    lng: -122.1914014,
    squareFootage: "123858",
    propertyType: "Multifamily",
    totalCosts: "22752100",
    valueOfReclassifiedAssets: 4020036,
    depreciableAssets: 21,
  },
  {
    lat: 37.3579781,
    lng: -79.1760161,
    squareFootage: "248134",
    propertyType: "Multifamily",
    totalCosts: "22260000",
    valueOfReclassifiedAssets: 4779843,
    depreciableAssets: 25,
  },
  {
    lat: 33.7779488,
    lng: -84.5746243,
    squareFootage: "288360",
    propertyType: "Multifamily",
    totalCosts: "20071361",
    valueOfReclassifiedAssets: 4369764,
    depreciableAssets: 28,
  },
  {
    lat: 27.9385015,
    lng: -82.7401087,
    squareFootage: "321440",
    propertyType: "Multifamily",
    totalCosts: "77719855",
    valueOfReclassifiedAssets: 17333119,
    depreciableAssets: 28,
  },
  {
    lat: 39.7845048,
    lng: -104.9797753,
    squareFootage: "48636",
    propertyType: "Multifamily",
    totalCosts: "23536081",
    valueOfReclassifiedAssets: 4431407,
    depreciableAssets: 24,
  },
  {
    lat: 33.7779488,
    lng: -84.5746243,
    squareFootage: "282960",
    propertyType: "Multifamily",
    totalCosts: "21491988",
    valueOfReclassifiedAssets: 5210692,
    depreciableAssets: 30,
  },
  {
    lat: 38.8370963,
    lng: -76.9239727,
    squareFootage: "329565",
    propertyType: "Multifamily",
    totalCosts: "52990243",
    valueOfReclassifiedAssets: 11833416,
    depreciableAssets: 25,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "142456",
    propertyType: "Multifamily",
    totalCosts: "15200000",
    valueOfReclassifiedAssets: 4110670,
    depreciableAssets: 31,
  },
  {
    lat: 32.9241691,
    lng: -96.8672668,
    squareFootage: "208773",
    propertyType: "Multifamily",
    totalCosts: "25500000",
    valueOfReclassifiedAssets: 8555095,
    depreciableAssets: 38,
  },
  {
    lat: 39.2635174,
    lng: -76.6239423,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3724408",
    valueOfReclassifiedAssets: 3167230,
    depreciableAssets: 0,
  },
  {
    lat: 41.3559092,
    lng: -83.12200740000002,
    squareFootage: "69134",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 474623,
    depreciableAssets: 28,
  },
  {
    lat: 39.2444348,
    lng: -84.5327038,
    squareFootage: "50525",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12663000",
    valueOfReclassifiedAssets: 10018232,
    depreciableAssets: 29,
  },
  {
    lat: 35.2035849,
    lng: -79.4591378,
    squareFootage: "34511",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 2079784,
    depreciableAssets: 41,
  },
  {
    lat: 41.4470451,
    lng: -81.9207423,
    squareFootage: "95192",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8250000",
    valueOfReclassifiedAssets: 1732142,
    depreciableAssets: 25,
  },
  {
    lat: 41.4124518,
    lng: -84.14351359999999,
    squareFootage: "52240",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1918447",
    valueOfReclassifiedAssets: 491875,
    depreciableAssets: 27,
  },
  {
    lat: 28.5775287,
    lng: -81.4388858,
    squareFootage: "73728",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 3569073,
    depreciableAssets: 36,
  },
  {
    lat: 28.3040861,
    lng: -81.422404,
    squareFootage: "397469",
    propertyType: "Multifamily",
    totalCosts: "50973615",
    valueOfReclassifiedAssets: 16664699,
    depreciableAssets: 34,
  },
  {
    lat: 36.8653194,
    lng: -76.0507949,
    squareFootage: "368276",
    propertyType: "Multifamily",
    totalCosts: "20126898",
    valueOfReclassifiedAssets: 4496116,
    depreciableAssets: 34,
  },
  {
    lat: 27.9082595,
    lng: -82.2909146,
    squareFootage: "266240",
    propertyType: "Multifamily",
    totalCosts: "2420138",
    valueOfReclassifiedAssets: 0,
    depreciableAssets: 0,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "10023",
    propertyType: "Multifamily",
    totalCosts: "1900000",
    valueOfReclassifiedAssets: 227747,
    depreciableAssets: 14,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5320",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 147207,
    depreciableAssets: 9,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "5320",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 148447,
    depreciableAssets: 9,
  },
  {
    lat: 40.7787902,
    lng: -73.9065883,
    squareFootage: "3700",
    propertyType: "Multifamily",
    totalCosts: "1502500",
    valueOfReclassifiedAssets: 127889,
    depreciableAssets: 11,
  },
  {
    lat: 40.9413697,
    lng: -72.6505957,
    squareFootage: "5357",
    propertyType: "Office",
    totalCosts: "1545499",
    valueOfReclassifiedAssets: 1373667,
    depreciableAssets: 29,
  },
  {
    lat: 40.693029,
    lng: -73.859549,
    squareFootage: "3802",
    propertyType: "Multifamily",
    totalCosts: "850000",
    valueOfReclassifiedAssets: 107442,
    depreciableAssets: 16,
  },
  {
    lat: 42.3250492,
    lng: -72.6411923,
    squareFootage: "208744",
    propertyType: "Multifamily",
    totalCosts: "43000000",
    valueOfReclassifiedAssets: 8699885,
    depreciableAssets: 26,
  },
  {
    lat: 41.8394458,
    lng: -72.7333604,
    squareFootage: "259878",
    propertyType: "Multifamily",
    totalCosts: "53325000",
    valueOfReclassifiedAssets: 9298793,
    depreciableAssets: 20,
  },
  {
    lat: 30.2293526,
    lng: -93.2131232,
    squareFootage: "134844",
    propertyType: "Retail",
    totalCosts: "23370993",
    valueOfReclassifiedAssets: 6587808,
    depreciableAssets: 36,
  },
  {
    lat: 38.2191978,
    lng: -85.6177891,
    squareFootage: "35568",
    propertyType: "Multifamily",
    totalCosts: "1386000",
    valueOfReclassifiedAssets: 258695,
    depreciableAssets: 23,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "4400",
    propertyType: "Retail",
    totalCosts: "3281621",
    valueOfReclassifiedAssets: 854903,
    depreciableAssets: 29,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 366780,
    depreciableAssets: 38,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "12699",
    propertyType: "Retail",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 2601029,
    depreciableAssets: 27,
  },
  {
    lat: 40.8376318,
    lng: -73.92128579999999,
    squareFootage: "10696",
    propertyType: "Retail",
    totalCosts: "11714143",
    valueOfReclassifiedAssets: 2179714,
    depreciableAssets: 20,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "22700",
    propertyType: "Multifamily",
    totalCosts: "6473860",
    valueOfReclassifiedAssets: 1136425,
    depreciableAssets: 19,
  },
  {
    lat: 40.8187386,
    lng: -73.9271644,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "1161324",
    valueOfReclassifiedAssets: 392604,
    depreciableAssets: 38,
  },
  {
    lat: 40.8687887,
    lng: -73.8977693,
    squareFootage: "12699",
    propertyType: "Retail",
    totalCosts: "11540003",
    valueOfReclassifiedAssets: 2764675,
    depreciableAssets: 27,
  },
  {
    lat: 37.0193161,
    lng: -94.5005808,
    squareFootage: "19995",
    propertyType: "Multifamily",
    totalCosts: "1772010",
    valueOfReclassifiedAssets: 323595,
    depreciableAssets: 22,
  },
  {
    lat: 39.6527963,
    lng: -79.9472502,
    squareFootage: "11528",
    propertyType: "Multifamily",
    totalCosts: "927990",
    valueOfReclassifiedAssets: 244131,
    depreciableAssets: 32,
  },
  {
    lat: 39.6527963,
    lng: -79.9472502,
    squareFootage: "13081",
    propertyType: "Specialty",
    totalCosts: "1658000",
    valueOfReclassifiedAssets: 365495,
    depreciableAssets: 25,
  },
  {
    lat: 40.8354565,
    lng: -74.48773059999999,
    squareFootage: "99285",
    propertyType: "Office",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 929869,
    depreciableAssets: 21,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11173259",
    valueOfReclassifiedAssets: 10269245,
    depreciableAssets: 16,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "119626",
    propertyType: "Office",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1841115,
    depreciableAssets: 19,
  },
  {
    lat: 39.7312603,
    lng: -75.5451379,
    squareFootage: "210088",
    propertyType: "Office",
    totalCosts: "16550000",
    valueOfReclassifiedAssets: 1748957,
    depreciableAssets: 12,
  },
  {
    lat: 37.8352892,
    lng: -94.33596349999999,
    squareFootage: "127896",
    propertyType: "Office",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 2330856,
    depreciableAssets: 21,
  },
  {
    lat: 40.037745,
    lng: -75.5393155,
    squareFootage: "91190",
    propertyType: "Office",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 2504361,
    depreciableAssets: 19,
  },
  {
    lat: 39.9358189,
    lng: -74.90918529999999,
    squareFootage: "37600",
    propertyType: "Office",
    totalCosts: "13700000",
    valueOfReclassifiedAssets: 2456996,
    depreciableAssets: 21,
  },
  {
    lat: 40.012545,
    lng: -75.14870069999999,
    squareFootage: "196432",
    propertyType: "Industrial",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 376920,
    depreciableAssets: 8,
  },
  {
    lat: 40.0358382,
    lng: -75.174734,
    squareFootage: "51367",
    propertyType: "Industrial",
    totalCosts: "3630000",
    valueOfReclassifiedAssets: 634822,
    depreciableAssets: 0,
  },
  {
    lat: 39.9863531,
    lng: -75.1019942,
    squareFootage: "48250",
    propertyType: "Industrial",
    totalCosts: "3595000",
    valueOfReclassifiedAssets: 545293,
    depreciableAssets: 17,
  },
  {
    lat: 39.9915379,
    lng: -75.21873459999999,
    squareFootage: "52929",
    propertyType: "Industrial",
    totalCosts: "9075000",
    valueOfReclassifiedAssets: 1397605,
    depreciableAssets: 18,
  },
  {
    lat: 39.7379842,
    lng: -86.15991489999999,
    squareFootage: "154846",
    propertyType: "Industrial",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 2977083,
    depreciableAssets: 35,
  },
  {
    lat: 26.3582748,
    lng: -81.7458845,
    squareFootage: "44997",
    propertyType: "Industrial",
    totalCosts: "3850000",
    valueOfReclassifiedAssets: 932564,
    depreciableAssets: 29,
  },
  {
    lat: 32.7049103,
    lng: -96.8726295,
    squareFootage: "40560",
    propertyType: "Industrial",
    totalCosts: "2725000",
    valueOfReclassifiedAssets: 341805,
    depreciableAssets: 15,
  },
  {
    lat: 32.5135255,
    lng: -96.7708138,
    squareFootage: "105555",
    propertyType: "Industrial",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 867393,
    depreciableAssets: 15,
  },
  {
    lat: 29.64645269999999,
    lng: -95.4935119,
    squareFootage: "65800",
    propertyType: "Industrial",
    totalCosts: "2850000",
    valueOfReclassifiedAssets: 524399,
    depreciableAssets: 22,
  },
  {
    lat: 30.05801529999999,
    lng: -94.1820961,
    squareFootage: "102000",
    propertyType: "Industrial",
    totalCosts: "3625000",
    valueOfReclassifiedAssets: 817518,
    depreciableAssets: 27,
  },
  {
    lat: 29.5662952,
    lng: -95.6142558,
    squareFootage: "110720",
    propertyType: "Industrial",
    totalCosts: "12150000",
    valueOfReclassifiedAssets: 1862697,
    depreciableAssets: 18,
  },
  {
    lat: 29.7935588,
    lng: -82.25832969999999,
    squareFootage: "76532",
    propertyType: "Industrial",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 788309,
    depreciableAssets: 29,
  },
  {
    lat: 42.3986045,
    lng: -114.5613507,
    squareFootage: "146174",
    propertyType: "Industrial",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 786588,
    depreciableAssets: 20,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "18115",
    propertyType: "Retail",
    totalCosts: "23523182",
    valueOfReclassifiedAssets: 4371288,
    depreciableAssets: 23,
  },
  {
    lat: 42.026064,
    lng: -87.7271123,
    squareFootage: "42790",
    propertyType: "Office",
    totalCosts: "4481325",
    valueOfReclassifiedAssets: 641498,
    depreciableAssets: 16,
  },
  {
    lat: 42.026064,
    lng: -87.7271123,
    squareFootage: "53611",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15620259",
    valueOfReclassifiedAssets: 3572932,
    depreciableAssets: 27,
  },
  {
    lat: 40.0503732,
    lng: -86.4592091,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9881039",
    valueOfReclassifiedAssets: 8131220,
    depreciableAssets: 31,
  },
  {
    lat: 41.831793,
    lng: -87.94328370000001,
    squareFootage: "173053",
    propertyType: "Office",
    totalCosts: "8420000",
    valueOfReclassifiedAssets: 1285531,
    depreciableAssets: 18,
  },
  {
    lat: 33.43806010000001,
    lng: -117.0087877,
    squareFootage: "6000",
    propertyType: "Industrial",
    totalCosts: "935519",
    valueOfReclassifiedAssets: 252383,
    depreciableAssets: 27,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "11556",
    propertyType: "Multifamily",
    totalCosts: "5247081",
    valueOfReclassifiedAssets: 4485808,
    depreciableAssets: 15,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "5252",
    propertyType: "Multifamily",
    totalCosts: "1749027",
    valueOfReclassifiedAssets: 1520940,
    depreciableAssets: 13,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "131455",
    propertyType: "Office",
    totalCosts: "89000000",
    valueOfReclassifiedAssets: 9111737,
    depreciableAssets: 12,
  },
  {
    lat: 42.2781628,
    lng: -71.4358733,
    squareFootage: "38400",
    propertyType: "Multifamily",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 945348,
    depreciableAssets: 21,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8085748",
    valueOfReclassifiedAssets: 6641760,
    depreciableAssets: 25,
  },
  {
    lat: 39.7574433,
    lng: -84.2094347,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18197509",
    valueOfReclassifiedAssets: 14255633,
    depreciableAssets: 31,
  },
  {
    lat: 37.5044419,
    lng: -77.46446069999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10975279",
    valueOfReclassifiedAssets: 7626761,
    depreciableAssets: 37,
  },
  {
    lat: 25.8105183,
    lng: -80.2042436,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18014102",
    valueOfReclassifiedAssets: 15333094,
    depreciableAssets: 20,
  },
  {
    lat: 42.4081356,
    lng: -122.9615521,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "17149102",
    valueOfReclassifiedAssets: 14491893,
    depreciableAssets: 21,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "7780",
    propertyType: "Specialty",
    totalCosts: "2350000",
    valueOfReclassifiedAssets: 483623,
    depreciableAssets: 25,
  },
  {
    lat: 42.1341459,
    lng: -72.5707614,
    squareFootage: "208496",
    propertyType: "Multifamily",
    totalCosts: "35920000",
    valueOfReclassifiedAssets: 7621088,
    depreciableAssets: 22,
  },
  {
    lat: 42.1156917,
    lng: -72.6062464,
    squareFootage: "300740",
    propertyType: "Multifamily",
    totalCosts: "40080000",
    valueOfReclassifiedAssets: 8355888,
    depreciableAssets: 22,
  },
  {
    lat: 29.6600034,
    lng: -95.2292777,
    squareFootage: "114113",
    propertyType: "Multifamily",
    totalCosts: "1420904",
    valueOfReclassifiedAssets: 501202,
    depreciableAssets: 37,
  },
  {
    lat: 41.6985831,
    lng: -86.8822445,
    squareFootage: "204752",
    propertyType: "Multifamily",
    totalCosts: "6672358",
    valueOfReclassifiedAssets: 1645606,
    depreciableAssets: 26,
  },
  {
    lat: 40.2518754,
    lng: -74.7629661,
    squareFootage: "97776",
    propertyType: "Multifamily",
    totalCosts: "10595203",
    valueOfReclassifiedAssets: 2055226,
    depreciableAssets: 20,
  },
  {
    lat: 41.9995834,
    lng: -71.51304449999999,
    squareFootage: "128970",
    propertyType: "Multifamily",
    totalCosts: "7508649",
    valueOfReclassifiedAssets: 2372939,
    depreciableAssets: 33,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "4042",
    propertyType: "Specialty",
    totalCosts: "1257554",
    valueOfReclassifiedAssets: 586254,
    depreciableAssets: 55,
  },
  {
    lat: 41.5214566,
    lng: -81.6418656,
    squareFootage: "89546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7642445",
    valueOfReclassifiedAssets: 3583427,
    depreciableAssets: 21,
  },
  {
    lat: 40.2397291,
    lng: -84.5012435,
    squareFootage: "41081",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7768600",
    valueOfReclassifiedAssets: 2347810,
    depreciableAssets: 33,
  },
  {
    lat: 39.9475712,
    lng: -83.7730237,
    squareFootage: "42243",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3585716",
    valueOfReclassifiedAssets: 1091650,
    depreciableAssets: 33,
  },
  {
    lat: 39.816412,
    lng: -84.0061661,
    squareFootage: "32815",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3514052",
    valueOfReclassifiedAssets: 1071066,
    depreciableAssets: 33,
  },
  {
    lat: 38.8500753,
    lng: -83.91089680000002,
    squareFootage: "26360",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4366328",
    valueOfReclassifiedAssets: 1462312,
    depreciableAssets: 37,
  },
  {
    lat: 41.4889702,
    lng: -83.01465259999999,
    squareFootage: "29448",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3052034",
    valueOfReclassifiedAssets: 1114508,
    depreciableAssets: 41,
  },
  {
    lat: 42.758734,
    lng: -84.44877199999999,
    squareFootage: "24378",
    propertyType: "Multifamily",
    totalCosts: "640000",
    valueOfReclassifiedAssets: 175779,
    depreciableAssets: 33,
  },
  {
    lat: 41.3266814,
    lng: -72.9637728,
    squareFootage: "3908",
    propertyType: "Multifamily",
    totalCosts: "450000",
    valueOfReclassifiedAssets: 66404,
    depreciableAssets: 17,
  },
  {
    lat: 41.3266814,
    lng: -72.9637728,
    squareFootage: "2423",
    propertyType: "Multifamily",
    totalCosts: "279000",
    valueOfReclassifiedAssets: 47121,
    depreciableAssets: 20,
  },
  {
    lat: 42.00925840000001,
    lng: -71.2280234,
    squareFootage: "91388",
    propertyType: "Multifamily",
    totalCosts: "5921039",
    valueOfReclassifiedAssets: 2432769,
    depreciableAssets: 66,
  },
  {
    lat: 30.3889868,
    lng: -97.6710889,
    squareFootage: "46472",
    propertyType: "Retail",
    totalCosts: "11950000",
    valueOfReclassifiedAssets: 2395705,
    depreciableAssets: 29,
  },
  {
    lat: 33.0067086,
    lng: -96.99609249999999,
    squareFootage: "17040",
    propertyType: "Retail",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1086470,
    depreciableAssets: 31,
  },
  {
    lat: 33.6134652,
    lng: -84.38574419999999,
    squareFootage: "276856",
    propertyType: "Multifamily",
    totalCosts: "10075200",
    valueOfReclassifiedAssets: 1655000,
    depreciableAssets: 19,
  },
  {
    lat: 26.268255,
    lng: -98.1884949,
    squareFootage: "71738",
    propertyType: "Multifamily",
    totalCosts: "3033244",
    valueOfReclassifiedAssets: 961990,
    depreciableAssets: 39,
  },
  {
    lat: 31.2763668,
    lng: -86.2361744,
    squareFootage: "40665",
    propertyType: "Multifamily",
    totalCosts: "1234623",
    valueOfReclassifiedAssets: 283482,
    depreciableAssets: 27,
  },
  {
    lat: 30.2371159,
    lng: -85.6537627,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6158901",
    valueOfReclassifiedAssets: 5246023,
    depreciableAssets: 27,
  },
  {
    lat: 32.8473553,
    lng: -91.2506002,
    squareFootage: "36000",
    propertyType: "Multifamily",
    totalCosts: "1071676",
    valueOfReclassifiedAssets: 326102,
    depreciableAssets: 36,
  },
  {
    lat: 41.7967461,
    lng: -87.92857769999999,
    squareFootage: "40675",
    propertyType: "Multifamily",
    totalCosts: "1471191",
    valueOfReclassifiedAssets: 449550,
    depreciableAssets: 36,
  },
  {
    lat: 34.6425797,
    lng: -120.3896651,
    squareFootage: "32883",
    propertyType: "Multifamily",
    totalCosts: "9792776",
    valueOfReclassifiedAssets: 932606,
    depreciableAssets: 12,
  },
  {
    lat: 41.6735366,
    lng: -83.624201,
    squareFootage: "84302",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12133677",
    valueOfReclassifiedAssets: 1121086,
    depreciableAssets: 9,
  },
  {
    lat: 34.0664817,
    lng: -118.3520389,
    squareFootage: "22702",
    propertyType: "Multifamily",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 957939,
    depreciableAssets: 15,
  },
  {
    lat: 42.3652917,
    lng: -71.0646337,
    squareFootage: "7755",
    propertyType: "Multifamily",
    totalCosts: "5543500",
    valueOfReclassifiedAssets: 1166971,
    depreciableAssets: 25,
  },
  {
    lat: 42.353068,
    lng: -71.0765188,
    squareFootage: "5328",
    propertyType: "Office",
    totalCosts: "1597200",
    valueOfReclassifiedAssets: 172422,
    depreciableAssets: 12,
  },
  {
    lat: 42.3593008,
    lng: -71.1270268,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "1725491",
    valueOfReclassifiedAssets: 299772,
    depreciableAssets: 20,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "660",
    propertyType: "Retail",
    totalCosts: "1580000",
    valueOfReclassifiedAssets: 186198,
    depreciableAssets: 14,
  },
  {
    lat: 42.3593008,
    lng: -71.1270268,
    squareFootage: "4750",
    propertyType: "Multifamily",
    totalCosts: "2155551",
    valueOfReclassifiedAssets: 260878,
    depreciableAssets: 14,
  },
  {
    lat: 42.3028239,
    lng: -71.1864397,
    squareFootage: "18360",
    propertyType: "Office",
    totalCosts: "3554565",
    valueOfReclassifiedAssets: 478691,
    depreciableAssets: 19,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3458125",
    valueOfReclassifiedAssets: 3150267,
    depreciableAssets: 11,
  },
  {
    lat: 34.1342261,
    lng: -116.1211016,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1467411",
    valueOfReclassifiedAssets: 1286284,
    depreciableAssets: 16,
  },
  {
    lat: 42.3463503,
    lng: -71.1626756,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1116482",
    valueOfReclassifiedAssets: 971145,
    depreciableAssets: 0,
  },
  {
    lat: 33.4366655,
    lng: -111.9403254,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "30595758",
    valueOfReclassifiedAssets: 23352090,
    depreciableAssets: 29,
  },
  {
    lat: 34.7394328,
    lng: -76.745195,
    squareFootage: "19369",
    propertyType: "Multifamily",
    totalCosts: "830000",
    valueOfReclassifiedAssets: 157471,
    depreciableAssets: 22,
  },
  {
    lat: 34.7394328,
    lng: -76.745195,
    squareFootage: "39200",
    propertyType: "Multifamily",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 250563,
    depreciableAssets: 22,
  },
  {
    lat: 41.743111,
    lng: -72.70380469999999,
    squareFootage: "273392",
    propertyType: "Multifamily",
    totalCosts: "15750000",
    valueOfReclassifiedAssets: 2787729,
    depreciableAssets: 20,
  },
  {
    lat: 40.8524941,
    lng: -96.6584666,
    squareFootage: "270404",
    propertyType: "Specialty",
    totalCosts: "9148589",
    valueOfReclassifiedAssets: 2427565,
    depreciableAssets: 31,
  },
  {
    lat: 40.8524941,
    lng: -96.6584666,
    squareFootage: "270404",
    propertyType: "Specialty",
    totalCosts: "5275502",
    valueOfReclassifiedAssets: 1399847,
    depreciableAssets: 31,
  },
  {
    lat: 33.459458,
    lng: -111.642224,
    squareFootage: "148903",
    propertyType: "Multifamily",
    totalCosts: "27000000",
    valueOfReclassifiedAssets: 7479891,
    depreciableAssets: 32,
  },
  {
    lat: 40.2345124,
    lng: -74.0035709,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "765630",
    valueOfReclassifiedAssets: 586845,
    depreciableAssets: 38,
  },
  {
    lat: 35.6204185,
    lng: -106.1345705,
    squareFootage: "61306",
    propertyType: "Industrial",
    totalCosts: "13575000",
    valueOfReclassifiedAssets: 5150323,
    depreciableAssets: 45,
  },
  {
    lat: 38.668585,
    lng: -121.1481284,
    squareFootage: "65408",
    propertyType: "Industrial",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 3292534,
    depreciableAssets: 33,
  },
  {
    lat: 26.5386936,
    lng: -80.0814292,
    squareFootage: "75293",
    propertyType: "Industrial",
    totalCosts: "1010703",
    valueOfReclassifiedAssets: 227034,
    depreciableAssets: 29,
  },
  {
    lat: 32.6330772,
    lng: -97.15209259999999,
    squareFootage: "67705",
    propertyType: "Industrial",
    totalCosts: "5433706",
    valueOfReclassifiedAssets: 1168124,
    depreciableAssets: 24,
  },
  {
    lat: 27.966866,
    lng: -80.7879407,
    squareFootage: "126278",
    propertyType: "Industrial",
    totalCosts: "14809406",
    valueOfReclassifiedAssets: 3414219,
    depreciableAssets: 28,
  },
  {
    lat: 28.0298719,
    lng: -80.6049374,
    squareFootage: "64020",
    propertyType: "Industrial",
    totalCosts: "5700741",
    valueOfReclassifiedAssets: 1613445,
    depreciableAssets: 33,
  },
  {
    lat: 36.849734,
    lng: -76.097201,
    squareFootage: "57275",
    propertyType: "Industrial",
    totalCosts: "6915609",
    valueOfReclassifiedAssets: 1140680,
    depreciableAssets: 19,
  },
  {
    lat: 30.4570672,
    lng: -97.68194299999999,
    squareFootage: "64412",
    propertyType: "Industrial",
    totalCosts: "7576896",
    valueOfReclassifiedAssets: 1934011,
    depreciableAssets: 29,
  },
  {
    lat: 29.7706038,
    lng: -95.69839789999999,
    squareFootage: "87506",
    propertyType: "Industrial",
    totalCosts: "4736412",
    valueOfReclassifiedAssets: 4137294,
    depreciableAssets: 18,
  },
  {
    lat: 29.67734269999999,
    lng: -95.71945219999999,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "7048395",
    valueOfReclassifiedAssets: 6631473,
    depreciableAssets: 14,
  },
  {
    lat: 39.7542465,
    lng: -104.7871004,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5848806",
    valueOfReclassifiedAssets: 4905561,
    depreciableAssets: 29,
  },
  {
    lat: 39.4626087,
    lng: -104.7754307,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8440486",
    valueOfReclassifiedAssets: 7038125,
    depreciableAssets: 25,
  },
  {
    lat: 44.4524167,
    lng: -105.582806,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7678040",
    valueOfReclassifiedAssets: 2155239,
    depreciableAssets: 94,
  },
  {
    lat: 48.1409274,
    lng: -101.3134847,
    squareFootage: "2400",
    propertyType: "Industrial",
    totalCosts: "14334366",
    valueOfReclassifiedAssets: 2665742,
    depreciableAssets: 58,
  },
  {
    lat: 39.7047822,
    lng: -82.605118,
    squareFootage: "2316",
    propertyType: "Specialty",
    totalCosts: "18264053",
    valueOfReclassifiedAssets: 8791576,
    depreciableAssets: 94,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "2560",
    propertyType: "Multifamily",
    totalCosts: "24675000",
    valueOfReclassifiedAssets: 12189928,
    depreciableAssets: 58,
  },
  {
    lat: 39.5761957,
    lng: -105.0674317,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3642365",
    valueOfReclassifiedAssets: 3232288,
    depreciableAssets: 29,
  },
  {
    lat: 26.5386936,
    lng: -80.0814292,
    squareFootage: "75293",
    propertyType: "Industrial",
    totalCosts: "5106000",
    valueOfReclassifiedAssets: 1146961,
    depreciableAssets: 29,
  },
  {
    lat: 32.5671309,
    lng: -97.4165053,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "4615614",
    valueOfReclassifiedAssets: 993241,
    depreciableAssets: 26,
  },
  {
    lat: 30.0442264,
    lng: -95.2162283,
    squareFootage: "36540",
    propertyType: "Industrial",
    totalCosts: "1850360",
    valueOfReclassifiedAssets: 439141,
    depreciableAssets: 28,
  },
  {
    lat: 30.0442264,
    lng: -95.2162283,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "844755",
    valueOfReclassifiedAssets: 689134,
    depreciableAssets: 61,
  },
  {
    lat: 33.4525494,
    lng: -111.7674282,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "20622500",
    valueOfReclassifiedAssets: 13267970,
    depreciableAssets: 76,
  },
  {
    lat: 39.9556241,
    lng: -75.1647529,
    squareFootage: "4435",
    propertyType: "Multifamily",
    totalCosts: "8858580",
    valueOfReclassifiedAssets: 817809,
    depreciableAssets: 11,
  },
  {
    lat: 39.9556241,
    lng: -75.1647529,
    squareFootage: "30565",
    propertyType: "Multifamily",
    totalCosts: "10241420",
    valueOfReclassifiedAssets: 1579430,
    depreciableAssets: 18,
  },
  {
    lat: 41.6339479,
    lng: -87.7418842,
    squareFootage: "91960",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10618051",
    valueOfReclassifiedAssets: 2339219,
    depreciableAssets: 24,
  },
  {
    lat: 38.1081439,
    lng: -87.913867,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6439420",
    valueOfReclassifiedAssets: 2432403,
    depreciableAssets: 42,
  },
  {
    lat: 41.7440856,
    lng: -87.7714136,
    squareFootage: "53611",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7310743",
    valueOfReclassifiedAssets: 1385071,
    depreciableAssets: 20,
  },
  {
    lat: 43.0165459,
    lng: -88.29476749999999,
    squareFootage: "73467",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1629110",
    valueOfReclassifiedAssets: 341541,
    depreciableAssets: 25,
  },
  {
    lat: 42.736631,
    lng: -87.86479609999999,
    squareFootage: "85135",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10054072",
    valueOfReclassifiedAssets: 2233606,
    depreciableAssets: 27,
  },
  {
    lat: 41.47019890000001,
    lng: -87.32149079999999,
    squareFootage: "89323",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 5975506,
    depreciableAssets: 24,
  },
  {
    lat: 40.9692854,
    lng: -74.74541119999999,
    squareFootage: "45048",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "18583853",
    valueOfReclassifiedAssets: 6618634,
    depreciableAssets: 39,
  },
  {
    lat: 41.1009158,
    lng: -74.69858909999999,
    squareFootage: "121260",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "63321610",
    valueOfReclassifiedAssets: 18863014,
    depreciableAssets: 33,
  },
  {
    lat: 39.2015955,
    lng: -94.53150289999999,
    squareFootage: "29328",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4019563",
    valueOfReclassifiedAssets: 1210841,
    depreciableAssets: 32,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1812858",
    valueOfReclassifiedAssets: 1613467,
    depreciableAssets: 12,
  },
  {
    lat: 29.6814047,
    lng: -95.0494602,
    squareFootage: "18882",
    propertyType: "Retail",
    totalCosts: "3330269",
    valueOfReclassifiedAssets: 730309,
    depreciableAssets: 31,
  },
  {
    lat: 29.8120015,
    lng: -94.9558696,
    squareFootage: "9788",
    propertyType: "Retail",
    totalCosts: "3699304",
    valueOfReclassifiedAssets: 644382,
    depreciableAssets: 30,
  },
  {
    lat: 30.31499299999999,
    lng: -98.92453429999999,
    squareFootage: "116672",
    propertyType: "Multifamily",
    totalCosts: "9800000",
    valueOfReclassifiedAssets: 2587153,
    depreciableAssets: 31,
  },
  {
    lat: 33.0243206,
    lng: -96.6745042,
    squareFootage: "85534",
    propertyType: "Multifamily",
    totalCosts: "8450000",
    valueOfReclassifiedAssets: 1942995,
    depreciableAssets: 28,
  },
  {
    lat: 47.6719353,
    lng: -116.7076758,
    squareFootage: "48000",
    propertyType: "Multifamily",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 1170019,
    depreciableAssets: 32,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "33668",
    propertyType: "Multifamily",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 558063,
    depreciableAssets: 25,
  },
  {
    lat: 32.9985237,
    lng: -96.84046,
    squareFootage: "258475",
    propertyType: "Multifamily",
    totalCosts: "33650000",
    valueOfReclassifiedAssets: 8831121,
    depreciableAssets: 31,
  },
  {
    lat: 35.7698044,
    lng: -78.78162189999999,
    squareFootage: "280760",
    propertyType: "Multifamily",
    totalCosts: "37500000",
    valueOfReclassifiedAssets: 11636643,
    depreciableAssets: 37,
  },
  {
    lat: 35.8861371,
    lng: -78.64554749999999,
    squareFootage: "293576",
    propertyType: "Multifamily",
    totalCosts: "43120000",
    valueOfReclassifiedAssets: 14354551,
    depreciableAssets: 39,
  },
  {
    lat: 40.1528606,
    lng: -74.8448648,
    squareFootage: "234500",
    propertyType: "Multifamily",
    totalCosts: "26000000",
    valueOfReclassifiedAssets: 5026093,
    depreciableAssets: 22,
  },
  {
    lat: 35.9530958,
    lng: -78.9571069,
    squareFootage: "139860",
    propertyType: "Multifamily",
    totalCosts: "18750000",
    valueOfReclassifiedAssets: 6078196,
    depreciableAssets: 38,
  },
  {
    lat: 32.7612759,
    lng: -117.0735241,
    squareFootage: "110000",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 924651,
    depreciableAssets: 21,
  },
  {
    lat: 40.7340752,
    lng: -73.12905959999999,
    squareFootage: "147165",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "56134196",
    valueOfReclassifiedAssets: 11608480,
    depreciableAssets: 25,
  },
  {
    lat: 40.6812166,
    lng: -73.7772032,
    squareFootage: "27000",
    propertyType: "Office",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 487105,
    depreciableAssets: 13,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "28411182",
    valueOfReclassifiedAssets: 21581730,
    depreciableAssets: 24,
  },
  {
    lat: 40.3038147,
    lng: -74.0887576,
    squareFootage: "100272",
    propertyType: "Office",
    totalCosts: "15907500",
    valueOfReclassifiedAssets: 2955314,
    depreciableAssets: 22,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "181064",
    propertyType: "Office",
    totalCosts: "26050000",
    valueOfReclassifiedAssets: 4954108,
    depreciableAssets: 22,
  },
  {
    lat: 35.2557159,
    lng: -80.82670639999999,
    squareFootage: "2400",
    propertyType: "Specialty",
    totalCosts: "2820000",
    valueOfReclassifiedAssets: 706256,
    depreciableAssets: 29,
  },
  {
    lat: 35.8774409,
    lng: -78.5547283,
    squareFootage: "15108",
    propertyType: "Industrial",
    totalCosts: "2525000",
    valueOfReclassifiedAssets: 534683,
    depreciableAssets: 25,
  },
  {
    lat: 40.7860123,
    lng: -74.0094471,
    squareFootage: "14616",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 407280,
    depreciableAssets: 11,
  },
  {
    lat: 40.7860123,
    lng: -74.0094471,
    squareFootage: "21000",
    propertyType: "Office",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 725265,
    depreciableAssets: 13,
  },
  {
    lat: 40.7860123,
    lng: -74.0094471,
    squareFootage: "4583",
    propertyType: "Specialty",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 628496,
    depreciableAssets: 50,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3334617",
    valueOfReclassifiedAssets: 669204,
    depreciableAssets: 33,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "3031",
    propertyType: "Retail",
    totalCosts: "2118600",
    valueOfReclassifiedAssets: 339053,
    depreciableAssets: 20,
  },
  {
    lat: 34.0448583,
    lng: -118.4484367,
    squareFootage: "3031",
    propertyType: "Retail",
    totalCosts: "910074",
    valueOfReclassifiedAssets: 145644,
    depreciableAssets: 20,
  },
  {
    lat: 47.8962246,
    lng: -122.1853637,
    squareFootage: "9600",
    propertyType: "Multifamily",
    totalCosts: "1000952",
    valueOfReclassifiedAssets: 218955,
    depreciableAssets: 32,
  },
  {
    lat: 34.0295114,
    lng: -118.2848199,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1567617",
    valueOfReclassifiedAssets: 857418,
    depreciableAssets: 91,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4609524",
    valueOfReclassifiedAssets: 2745515,
    depreciableAssets: 91,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6309883",
    valueOfReclassifiedAssets: 2918917,
    depreciableAssets: 19,
  },
  {
    lat: 33.5528023,
    lng: -84.4015075,
    squareFootage: "96202",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 1377849,
    depreciableAssets: 35,
  },
  {
    lat: 33.6958943,
    lng: -84.238417,
    squareFootage: "108518",
    propertyType: "Multifamily",
    totalCosts: "3849999",
    valueOfReclassifiedAssets: 1127187,
    depreciableAssets: 34,
  },
  {
    lat: 33.6832181,
    lng: -84.44877199999999,
    squareFootage: "154208",
    propertyType: "Multifamily",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 2541702,
    depreciableAssets: 33,
  },
  {
    lat: 29.4895776,
    lng: -98.385532,
    squareFootage: "164314",
    propertyType: "Multifamily",
    totalCosts: "13050000",
    valueOfReclassifiedAssets: 3508845,
    depreciableAssets: 31,
  },
  {
    lat: 29.5516834,
    lng: -98.49522280000001,
    squareFootage: "111456",
    propertyType: "Multifamily",
    totalCosts: "7814743",
    valueOfReclassifiedAssets: 1735493,
    depreciableAssets: 28,
  },
  {
    lat: 34.1058378,
    lng: -80.92077370000001,
    squareFootage: "269451",
    propertyType: "Multifamily",
    totalCosts: "23200000",
    valueOfReclassifiedAssets: 7902445,
    depreciableAssets: 36,
  },
  {
    lat: 30.6809949,
    lng: -88.1047287,
    squareFootage: "261600",
    propertyType: "Multifamily",
    totalCosts: "28550000",
    valueOfReclassifiedAssets: 12027313,
    depreciableAssets: 43,
  },
  {
    lat: 34.4854334,
    lng: -117.4203974,
    squareFootage: "13014",
    propertyType: "Retail",
    totalCosts: "6061733",
    valueOfReclassifiedAssets: 1159991,
    depreciableAssets: 35,
  },
  {
    lat: 35.3770063,
    lng: -81.0644263,
    squareFootage: "10156",
    propertyType: "Retail",
    totalCosts: "3285000",
    valueOfReclassifiedAssets: 1089840,
    depreciableAssets: 39,
  },
  {
    lat: 44.7138218,
    lng: -85.7051116,
    squareFootage: "42564",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 2785801,
    depreciableAssets: 40,
  },
  {
    lat: 40.9300511,
    lng: -74.156292,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2892017",
    valueOfReclassifiedAssets: 2625417,
    depreciableAssets: 51,
  },
  {
    lat: 34.2942772,
    lng: -118.8829816,
    squareFootage: "253478",
    propertyType: "Specialty",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 3016352,
    depreciableAssets: 16,
  },
  {
    lat: 33.5114334,
    lng: -112.0685027,
    squareFootage: "78746",
    propertyType: "Office",
    totalCosts: "7381375",
    valueOfReclassifiedAssets: 1296326,
    depreciableAssets: 27,
  },
  {
    lat: 40.5971482,
    lng: -74.6283316,
    squareFootage: "25514",
    propertyType: "Office",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1358720,
    depreciableAssets: 23,
  },
  {
    lat: 40.5377063,
    lng: -74.8507131,
    squareFootage: "12240",
    propertyType: "Multifamily",
    totalCosts: "3299363",
    valueOfReclassifiedAssets: 891785,
    depreciableAssets: 33,
  },
  {
    lat: 32.717251,
    lng: -97.4435441,
    squareFootage: "115622",
    propertyType: "Multifamily",
    totalCosts: "10550000",
    valueOfReclassifiedAssets: 3341091,
    depreciableAssets: 37,
  },
  {
    lat: 40.8126648,
    lng: -73.3590802,
    squareFootage: "24000",
    propertyType: "Retail",
    totalCosts: "1521310",
    valueOfReclassifiedAssets: 515185,
    depreciableAssets: 37,
  },
  {
    lat: 33.120724,
    lng: -97.1574784,
    squareFootage: "12274",
    propertyType: "Specialty",
    totalCosts: "2754409",
    valueOfReclassifiedAssets: 1072781,
    depreciableAssets: 46,
  },
  {
    lat: 40.0408389,
    lng: -75.4257325,
    squareFootage: "7479",
    propertyType: "Office",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 423491,
    depreciableAssets: 27,
  },
  {
    lat: 32.9458766,
    lng: -97.27607599999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "39810",
    valueOfReclassifiedAssets: 23886,
    depreciableAssets: 0,
  },
  {
    lat: 32.9458766,
    lng: -97.27607599999999,
    squareFootage: "5000",
    propertyType: "Retail",
    totalCosts: "1684682",
    valueOfReclassifiedAssets: 334372,
    depreciableAssets: 24,
  },
  {
    lat: 33.0787106,
    lng: -96.91018059999999,
    squareFootage: "6800",
    propertyType: "Retail",
    totalCosts: "2141650",
    valueOfReclassifiedAssets: 397801,
    depreciableAssets: 22,
  },
  {
    lat: 32.4084693,
    lng: -97.8068851,
    squareFootage: "8024",
    propertyType: "Retail",
    totalCosts: "2111025",
    valueOfReclassifiedAssets: 533731,
    depreciableAssets: 30,
  },
  {
    lat: 32.9464268,
    lng: -97.0713572,
    squareFootage: "2017",
    propertyType: "Retail",
    totalCosts: "3567282",
    valueOfReclassifiedAssets: 833887,
    depreciableAssets: 28,
  },
  {
    lat: 32.2556612,
    lng: -95.3207069,
    squareFootage: "7600",
    propertyType: "Office",
    totalCosts: "1202554",
    valueOfReclassifiedAssets: 394452,
    depreciableAssets: 34,
  },
  {
    lat: 32.2556612,
    lng: -95.3207069,
    squareFootage: "54920",
    propertyType: "Industrial",
    totalCosts: "1378182",
    valueOfReclassifiedAssets: 211683,
    depreciableAssets: 17,
  },
  {
    lat: 32.3986023,
    lng: -95.4149003,
    squareFootage: "11049",
    propertyType: "Retail",
    totalCosts: "950000",
    valueOfReclassifiedAssets: 253213,
    depreciableAssets: 28,
  },
  {
    lat: 32.2556612,
    lng: -95.3207069,
    squareFootage: "14126",
    propertyType: "Retail",
    totalCosts: "2273054",
    valueOfReclassifiedAssets: 878768,
    depreciableAssets: 42,
  },
  {
    lat: 32.2556612,
    lng: -95.3207069,
    squareFootage: "20313",
    propertyType: "Office",
    totalCosts: "3670000",
    valueOfReclassifiedAssets: 1030518,
    depreciableAssets: 31,
  },
  {
    lat: 47.25483149999999,
    lng: -120.8083774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "911790",
    valueOfReclassifiedAssets: 712110,
    depreciableAssets: 24,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "9697",
    propertyType: "Multifamily",
    totalCosts: "2309997",
    valueOfReclassifiedAssets: 273566,
    depreciableAssets: 14,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "7560",
    propertyType: "Multifamily",
    totalCosts: "1815917",
    valueOfReclassifiedAssets: 222056,
    depreciableAssets: 15,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "6956",
    propertyType: "Multifamily",
    totalCosts: "2619194",
    valueOfReclassifiedAssets: 177400,
    depreciableAssets: 8,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "6400",
    propertyType: "Multifamily",
    totalCosts: "2252165",
    valueOfReclassifiedAssets: 199297,
    depreciableAssets: 11,
  },
  {
    lat: 38.2315738,
    lng: -122.4693688,
    squareFootage: "45920",
    propertyType: "Retail",
    totalCosts: "5596054",
    valueOfReclassifiedAssets: 1360844,
    depreciableAssets: 31,
  },
  {
    lat: 37.9768028,
    lng: -120.3666099,
    squareFootage: "40600",
    propertyType: "Retail",
    totalCosts: "2561456",
    valueOfReclassifiedAssets: 1921092,
    depreciableAssets: 100,
  },
  {
    lat: 41.904735,
    lng: -87.8598861,
    squareFootage: "255565",
    propertyType: "Industrial",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 141421,
    depreciableAssets: 6,
  },
  {
    lat: 41.8929153,
    lng: -87.63591249999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "534053",
    valueOfReclassifiedAssets: 68053,
    depreciableAssets: 0,
  },
  {
    lat: 41.8929153,
    lng: -87.63591249999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "444464",
    valueOfReclassifiedAssets: 142825,
    depreciableAssets: 0,
  },
  {
    lat: 41.9348537,
    lng: -87.87952299999999,
    squareFootage: "17768",
    propertyType: "Office",
    totalCosts: "642213",
    valueOfReclassifiedAssets: 74358,
    depreciableAssets: 14,
  },
  {
    lat: 41.9348537,
    lng: -87.87952299999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "175032",
    valueOfReclassifiedAssets: 155262,
    depreciableAssets: 40,
  },
  {
    lat: 41.9348537,
    lng: -87.87952299999999,
    squareFootage: "30375",
    propertyType: "Office",
    totalCosts: "1160889",
    valueOfReclassifiedAssets: 144507,
    depreciableAssets: 14,
  },
  {
    lat: 38.9627306,
    lng: -76.854799,
    squareFootage: "190211",
    propertyType: "Retail",
    totalCosts: "30250000",
    valueOfReclassifiedAssets: 6288890,
    depreciableAssets: 25,
  },
  {
    lat: 28.3040861,
    lng: -81.422404,
    squareFootage: "82444",
    propertyType: "Retail",
    totalCosts: "16784200",
    valueOfReclassifiedAssets: 4920504,
    depreciableAssets: 38,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "80085",
    propertyType: "Retail",
    totalCosts: "27650000",
    valueOfReclassifiedAssets: 2742198,
    depreciableAssets: 13,
  },
  {
    lat: 40.4861552,
    lng: -74.45185,
    squareFootage: "31000",
    propertyType: "Industrial",
    totalCosts: "4109526",
    valueOfReclassifiedAssets: 811746,
    depreciableAssets: 25,
  },
  {
    lat: 40.7135097,
    lng: -73.9859414,
    squareFootage: "7425",
    propertyType: "Multifamily",
    totalCosts: "6543498",
    valueOfReclassifiedAssets: 619347,
    depreciableAssets: 10,
  },
  {
    lat: 37.5439282,
    lng: -77.44061339999999,
    squareFootage: "26987",
    propertyType: "Office",
    totalCosts: "3375000",
    valueOfReclassifiedAssets: 397244,
    depreciableAssets: 14,
  },
  {
    lat: 39.7878214,
    lng: -74.3352858,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "577000",
    valueOfReclassifiedAssets: 498192,
    depreciableAssets: 18,
  },
  {
    lat: 39.7878214,
    lng: -74.3352858,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24223304",
    valueOfReclassifiedAssets: 21769568,
    depreciableAssets: 28,
  },
  {
    lat: 29.38527629999999,
    lng: -94.9247074,
    squareFootage: "55790",
    propertyType: "Multifamily",
    totalCosts: "3234000",
    valueOfReclassifiedAssets: 619704,
    depreciableAssets: 23,
  },
  {
    lat: 26.142147,
    lng: -80.1763467,
    squareFootage: "67200",
    propertyType: "Multifamily",
    totalCosts: "9600000",
    valueOfReclassifiedAssets: 1827077,
    depreciableAssets: 22,
  },
  {
    lat: 25.8909628,
    lng: -80.1819268,
    squareFootage: "15122",
    propertyType: "Retail",
    totalCosts: "2122525",
    valueOfReclassifiedAssets: 550693,
    depreciableAssets: 34,
  },
  {
    lat: 25.8909628,
    lng: -80.1819268,
    squareFootage: "2271",
    propertyType: "Retail",
    totalCosts: "389936",
    valueOfReclassifiedAssets: 60663,
    depreciableAssets: 20,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "28100",
    propertyType: "Multifamily",
    totalCosts: "3412009",
    valueOfReclassifiedAssets: 804244,
    depreciableAssets: 27,
  },
  {
    lat: 40.6515882,
    lng: -73.93304289999999,
    squareFootage: "9500",
    propertyType: "Office",
    totalCosts: "3749782",
    valueOfReclassifiedAssets: 379540,
    depreciableAssets: 17,
  },
  {
    lat: 35.15900680000001,
    lng: -106.5760857,
    squareFootage: "31061",
    propertyType: "Office",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 780718,
    depreciableAssets: 28,
  },
  {
    lat: 36.3058572,
    lng: -93.9674371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4580894",
    valueOfReclassifiedAssets: 4009830,
    depreciableAssets: 22,
  },
  {
    lat: 41.743111,
    lng: -72.70380469999999,
    squareFootage: "229102",
    propertyType: "Office",
    totalCosts: "16800000",
    valueOfReclassifiedAssets: 1854020,
    depreciableAssets: 13,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "42216",
    propertyType: "Multifamily",
    totalCosts: "15750000",
    valueOfReclassifiedAssets: 1736103,
    depreciableAssets: 13,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "22434",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 991734,
    depreciableAssets: 13,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "44622",
    propertyType: "Multifamily",
    totalCosts: "21500000",
    valueOfReclassifiedAssets: 2531267,
    depreciableAssets: 13,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "47730",
    propertyType: "Multifamily",
    totalCosts: "29000000",
    valueOfReclassifiedAssets: 2789182,
    depreciableAssets: 11,
  },
  {
    lat: 40.8032131,
    lng: -73.95258249999999,
    squareFootage: "20500",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 1418614,
    depreciableAssets: 15,
  },
  {
    lat: 37.6346973,
    lng: -120.9704234,
    squareFootage: "22096",
    propertyType: "Specialty",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2306471,
    depreciableAssets: 24,
  },
  {
    lat: 30.5622896,
    lng: -97.54638849999999,
    squareFootage: "4263",
    propertyType: "Retail",
    totalCosts: "1613000",
    valueOfReclassifiedAssets: 599638,
    depreciableAssets: 43,
  },
  {
    lat: 37.7199247,
    lng: -122.1689284,
    squareFootage: "21488",
    propertyType: "Specialty",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 614866,
    depreciableAssets: 22,
  },
  {
    lat: 39.5963318,
    lng: -86.0835415,
    squareFootage: "55463",
    propertyType: "Office",
    totalCosts: "5450000",
    valueOfReclassifiedAssets: 1099781,
    depreciableAssets: 24,
  },
  {
    lat: 38.70360050000001,
    lng: -90.6488505,
    squareFootage: "58339",
    propertyType: "Retail",
    totalCosts: "4531854",
    valueOfReclassifiedAssets: 1191253,
    depreciableAssets: 32,
  },
  {
    lat: 38.6206914,
    lng: -90.44080369999999,
    squareFootage: "26989",
    propertyType: "Retail",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1086630,
    depreciableAssets: 28,
  },
  {
    lat: 38.7492726,
    lng: -77.57340479999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5619716",
    valueOfReclassifiedAssets: 1424215,
    depreciableAssets: 29,
  },
  {
    lat: 39.2800675,
    lng: -78.2248291,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4159052",
    valueOfReclassifiedAssets: 3338299,
    depreciableAssets: 45,
  },
  {
    lat: 39.2800675,
    lng: -78.2248291,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1067799",
    valueOfReclassifiedAssets: 882727,
    depreciableAssets: 42,
  },
  {
    lat: 34.0279895,
    lng: -118.495155,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9049000",
    valueOfReclassifiedAssets: 6077044,
    depreciableAssets: 37,
  },
  {
    lat: 33.9425519,
    lng: -80.9539475,
    squareFootage: "236478",
    propertyType: "Multifamily",
    totalCosts: "8686821",
    valueOfReclassifiedAssets: 2474214,
    depreciableAssets: 31,
  },
  {
    lat: 32.9221152,
    lng: -80.0367259,
    squareFootage: "2018",
    propertyType: "Multifamily",
    totalCosts: "12180954",
    valueOfReclassifiedAssets: 3093419,
    depreciableAssets: 29,
  },
  {
    lat: 34.2453437,
    lng: -82.1387233,
    squareFootage: "122200",
    propertyType: "Multifamily",
    totalCosts: "4305444",
    valueOfReclassifiedAssets: 1285602,
    depreciableAssets: 33,
  },
  {
    lat: 32.9305786,
    lng: -80.0031833,
    squareFootage: "158804",
    propertyType: "Multifamily",
    totalCosts: "10075988",
    valueOfReclassifiedAssets: 2675983,
    depreciableAssets: 29,
  },
  {
    lat: 34.4796903,
    lng: -81.84429340000001,
    squareFootage: "50192",
    propertyType: "Multifamily",
    totalCosts: "4575000",
    valueOfReclassifiedAssets: 1214209,
    depreciableAssets: 32,
  },
  {
    lat: 35.1289167,
    lng: -81.71305219999999,
    squareFootage: "92223",
    propertyType: "Multifamily",
    totalCosts: "4909237",
    valueOfReclassifiedAssets: 1580041,
    depreciableAssets: 36,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "163700",
    propertyType: "Multifamily",
    totalCosts: "12178648",
    valueOfReclassifiedAssets: 3713023,
    depreciableAssets: 34,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "117952",
    propertyType: "Multifamily",
    totalCosts: "9111278",
    valueOfReclassifiedAssets: 2776075,
    depreciableAssets: 34,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "132822",
    propertyType: "Multifamily",
    totalCosts: "5673871",
    valueOfReclassifiedAssets: 1026610,
    depreciableAssets: 20,
  },
  {
    lat: 34.9306384,
    lng: -82.0189155,
    squareFootage: "128668",
    propertyType: "Multifamily",
    totalCosts: "7214839",
    valueOfReclassifiedAssets: 1868398,
    depreciableAssets: 28,
  },
  {
    lat: 34.9838584,
    lng: -82.33433749999999,
    squareFootage: "46163",
    propertyType: "Multifamily",
    totalCosts: "4081764",
    valueOfReclassifiedAssets: 1167674,
    depreciableAssets: 32,
  },
  {
    lat: 32.9221152,
    lng: -80.0367259,
    squareFootage: "71184",
    propertyType: "Multifamily",
    totalCosts: "7185011",
    valueOfReclassifiedAssets: 1850020,
    depreciableAssets: 29,
  },
  {
    lat: 33.2300079,
    lng: -87.5939487,
    squareFootage: "90122",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15703233",
    valueOfReclassifiedAssets: 6453280,
    depreciableAssets: 31,
  },
  {
    lat: 41.9320859,
    lng: -88.0020589,
    squareFootage: "20000",
    propertyType: "Industrial",
    totalCosts: "1520000",
    valueOfReclassifiedAssets: 389012,
    depreciableAssets: 30,
  },
  {
    lat: 32.6651539,
    lng: -97.0821162,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "16600000",
    valueOfReclassifiedAssets: 1551640,
    depreciableAssets: 11,
  },
  {
    lat: 33.7414395,
    lng: -84.25948609999999,
    squareFootage: "114456",
    propertyType: "Multifamily",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1240078,
    depreciableAssets: 31,
  },
  {
    lat: 42.999243,
    lng: -89.5686271,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13535276",
    valueOfReclassifiedAssets: 11912815,
    depreciableAssets: 18,
  },
  {
    lat: 42.7539447,
    lng: -73.78308609999999,
    squareFootage: "68139",
    propertyType: "Hotel",
    totalCosts: "3600000",
    valueOfReclassifiedAssets: 1203772,
    depreciableAssets: 39,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "205193",
    propertyType: "Office",
    totalCosts: "99210523",
    valueOfReclassifiedAssets: 10880833,
    depreciableAssets: 13,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "205193",
    propertyType: "Office",
    totalCosts: "10331137",
    valueOfReclassifiedAssets: 1133059,
    depreciableAssets: 13,
  },
  {
    lat: 33.5829386,
    lng: -84.3279093,
    squareFootage: "250836",
    propertyType: "Office",
    totalCosts: "28301970",
    valueOfReclassifiedAssets: 3575608,
    depreciableAssets: 16,
  },
  {
    lat: 29.9631614,
    lng: -95.509247,
    squareFootage: "205332",
    propertyType: "Industrial",
    totalCosts: "27300000",
    valueOfReclassifiedAssets: 4808996,
    depreciableAssets: 22,
  },
  {
    lat: 29.8265052,
    lng: -95.46729619999999,
    squareFootage: "45161",
    propertyType: "Industrial",
    totalCosts: "7824241",
    valueOfReclassifiedAssets: 1078353,
    depreciableAssets: 17,
  },
  {
    lat: 29.9161683,
    lng: -95.5302337,
    squareFootage: "41788",
    propertyType: "Industrial",
    totalCosts: "5126660",
    valueOfReclassifiedAssets: 970864,
    depreciableAssets: 23,
  },
  {
    lat: 29.9161683,
    lng: -95.5302337,
    squareFootage: "105250",
    propertyType: "Industrial",
    totalCosts: "7679505",
    valueOfReclassifiedAssets: 778836,
    depreciableAssets: 12,
  },
  {
    lat: 33.447458,
    lng: -84.17516479999999,
    squareFootage: "171000",
    propertyType: "Industrial",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 2152469,
    depreciableAssets: 32,
  },
  {
    lat: 33.9253024,
    lng: -84.38574419999999,
    squareFootage: "15000",
    propertyType: "Industrial",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 856654,
    depreciableAssets: 21,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "102400",
    propertyType: "Industrial",
    totalCosts: "9132639",
    valueOfReclassifiedAssets: 1681956,
    depreciableAssets: 22,
  },
  {
    lat: 39.3282373,
    lng: -84.5536678,
    squareFootage: "212000",
    propertyType: "Industrial",
    totalCosts: "9861020",
    valueOfReclassifiedAssets: 1605069,
    depreciableAssets: 19,
  },
  {
    lat: 35.1241195,
    lng: -90.0443217,
    squareFootage: "100000",
    propertyType: "Industrial",
    totalCosts: "1353957",
    valueOfReclassifiedAssets: 191788,
    depreciableAssets: 16,
  },
  {
    lat: 33.5756821,
    lng: -83.8260884,
    squareFootage: "73500",
    propertyType: "Industrial",
    totalCosts: "2080510",
    valueOfReclassifiedAssets: 129819,
    depreciableAssets: 7,
  },
  {
    lat: 33.6584347,
    lng: -83.6986568,
    squareFootage: "101436",
    propertyType: "Industrial",
    totalCosts: "3766543",
    valueOfReclassifiedAssets: 590138,
    depreciableAssets: 19,
  },
  {
    lat: 33.6584347,
    lng: -83.6986568,
    squareFootage: "50000",
    propertyType: "Industrial",
    totalCosts: "1856610",
    valueOfReclassifiedAssets: 241422,
    depreciableAssets: 15,
  },
  {
    lat: 33.6584347,
    lng: -83.6986568,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "2227933",
    valueOfReclassifiedAssets: 169630,
    depreciableAssets: 9,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "154000",
    propertyType: "Industrial",
    totalCosts: "4141836",
    valueOfReclassifiedAssets: 466480,
    depreciableAssets: 13,
  },
  {
    lat: 35.0938053,
    lng: -90.12142349999999,
    squareFootage: "75527",
    propertyType: "Industrial",
    totalCosts: "1263622",
    valueOfReclassifiedAssets: 133368,
    depreciableAssets: 12,
  },
  {
    lat: 35.0938053,
    lng: -90.12142349999999,
    squareFootage: "104573",
    propertyType: "Industrial",
    totalCosts: "441388",
    valueOfReclassifiedAssets: 35955,
    depreciableAssets: 9,
  },
  {
    lat: 35.0938053,
    lng: -90.12142349999999,
    squareFootage: "109955",
    propertyType: "Industrial",
    totalCosts: "1442998",
    valueOfReclassifiedAssets: 107079,
    depreciableAssets: 9,
  },
  {
    lat: 35.0277217,
    lng: -90.1401381,
    squareFootage: "162000",
    propertyType: "Industrial",
    totalCosts: "1295346",
    valueOfReclassifiedAssets: 133082,
    depreciableAssets: 12,
  },
  {
    lat: 35.0277217,
    lng: -90.1401381,
    squareFootage: "36653",
    propertyType: "Industrial",
    totalCosts: "802648",
    valueOfReclassifiedAssets: 109369,
    depreciableAssets: 16,
  },
  {
    lat: 29.8265052,
    lng: -95.46729619999999,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "4769785",
    valueOfReclassifiedAssets: 835307,
    depreciableAssets: 21,
  },
  {
    lat: 33.8507937,
    lng: -84.21734029999999,
    squareFootage: "54000",
    propertyType: "Industrial",
    totalCosts: "2436242",
    valueOfReclassifiedAssets: 301212,
    depreciableAssets: 14,
  },
  {
    lat: 39.2858588,
    lng: -84.5327038,
    squareFootage: "98338",
    propertyType: "Industrial",
    totalCosts: "4883529",
    valueOfReclassifiedAssets: 1280636,
    depreciableAssets: 31,
  },
  {
    lat: 39.3282373,
    lng: -84.5536678,
    squareFootage: "44011",
    propertyType: "Industrial",
    totalCosts: "2351900",
    valueOfReclassifiedAssets: 479799,
    depreciableAssets: 24,
  },
  {
    lat: 33.6392536,
    lng: -83.985008,
    squareFootage: "34000",
    propertyType: "Industrial",
    totalCosts: "1201255",
    valueOfReclassifiedAssets: 195681,
    depreciableAssets: 19,
  },
  {
    lat: 33.58605730000001,
    lng: -84.0378894,
    squareFootage: "36750",
    propertyType: "Industrial",
    totalCosts: "1676163",
    valueOfReclassifiedAssets: 238982,
    depreciableAssets: 17,
  },
  {
    lat: 33.58605730000001,
    lng: -84.0378894,
    squareFootage: "18000",
    propertyType: "Industrial",
    totalCosts: "895342",
    valueOfReclassifiedAssets: 147520,
    depreciableAssets: 20,
  },
  {
    lat: 33.58605730000001,
    lng: -84.0378894,
    squareFootage: "18600",
    propertyType: "Industrial",
    totalCosts: "895342",
    valueOfReclassifiedAssets: 177071,
    depreciableAssets: 23,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "80000",
    propertyType: "Industrial",
    totalCosts: "2775000",
    valueOfReclassifiedAssets: 290548,
    depreciableAssets: 12,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "113680",
    propertyType: "Industrial",
    totalCosts: "3576304",
    valueOfReclassifiedAssets: 527751,
    depreciableAssets: 17,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "100000",
    propertyType: "Industrial",
    totalCosts: "3109830",
    valueOfReclassifiedAssets: 559760,
    depreciableAssets: 21,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "79318",
    propertyType: "Industrial",
    totalCosts: "1030036",
    valueOfReclassifiedAssets: 110438,
    depreciableAssets: 13,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "86700",
    propertyType: "Industrial",
    totalCosts: "2902508",
    valueOfReclassifiedAssets: 551133,
    depreciableAssets: 23,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "78300",
    propertyType: "Industrial",
    totalCosts: "2980254",
    valueOfReclassifiedAssets: 538614,
    depreciableAssets: 21,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "36630",
    propertyType: "Industrial",
    totalCosts: "1710406",
    valueOfReclassifiedAssets: 360043,
    depreciableAssets: 25,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "65400",
    propertyType: "Industrial",
    totalCosts: "2717146",
    valueOfReclassifiedAssets: 579638,
    depreciableAssets: 25,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "161200",
    propertyType: "Industrial",
    totalCosts: "5267382",
    valueOfReclassifiedAssets: 638988,
    depreciableAssets: 16,
  },
  {
    lat: 33.9957883,
    lng: -84.46976629999999,
    squareFootage: "130253",
    propertyType: "Office",
    totalCosts: "9455315",
    valueOfReclassifiedAssets: 2945626,
    depreciableAssets: 39,
  },
  {
    lat: 35.0278019,
    lng: -90.0093266,
    squareFootage: "69906",
    propertyType: "Industrial",
    totalCosts: "2130691",
    valueOfReclassifiedAssets: 326696,
    depreciableAssets: 19,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "74506",
    propertyType: "Industrial",
    totalCosts: "2329769",
    valueOfReclassifiedAssets: 220130,
    depreciableAssets: 12,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "143293",
    propertyType: "Industrial",
    totalCosts: "2485039",
    valueOfReclassifiedAssets: 160595,
    depreciableAssets: 8,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "113175",
    propertyType: "Industrial",
    totalCosts: "4081393",
    valueOfReclassifiedAssets: 458485,
    depreciableAssets: 14,
  },
  {
    lat: 35.005666,
    lng: -89.84113819999999,
    squareFootage: "36484",
    propertyType: "Industrial",
    totalCosts: "1308679",
    valueOfReclassifiedAssets: 194647,
    depreciableAssets: 19,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "48750",
    propertyType: "Industrial",
    totalCosts: "1344085",
    valueOfReclassifiedAssets: 127223,
    depreciableAssets: 12,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "51077",
    propertyType: "Industrial",
    totalCosts: "1799293",
    valueOfReclassifiedAssets: 233296,
    depreciableAssets: 16,
  },
  {
    lat: 34.9481276,
    lng: -89.83037929999999,
    squareFootage: "90000",
    propertyType: "Industrial",
    totalCosts: "2905450",
    valueOfReclassifiedAssets: 312592,
    depreciableAssets: 13,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15444105",
    valueOfReclassifiedAssets: 1854328,
    depreciableAssets: 15,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4289722",
    valueOfReclassifiedAssets: 578796,
    depreciableAssets: 17,
  },
  {
    lat: 32.97602940000001,
    lng: -96.7386946,
    squareFootage: "327888",
    propertyType: "Industrial",
    totalCosts: "12100000",
    valueOfReclassifiedAssets: 1680005,
    depreciableAssets: 17,
  },
  {
    lat: 35.0277217,
    lng: -90.1401381,
    squareFootage: "88643",
    propertyType: "Industrial",
    totalCosts: "1704279",
    valueOfReclassifiedAssets: 321764,
    depreciableAssets: 24,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5002125",
    valueOfReclassifiedAssets: 4221104,
    depreciableAssets: 0,
  },
  {
    lat: 33.8640647,
    lng: -118.1766294,
    squareFootage: "8086",
    propertyType: "Specialty",
    totalCosts: "1358900",
    valueOfReclassifiedAssets: 266045,
    depreciableAssets: 25,
  },
  {
    lat: 34.0295114,
    lng: -118.2848199,
    squareFootage: "5386",
    propertyType: "Multifamily",
    totalCosts: "529528",
    valueOfReclassifiedAssets: 99645,
    depreciableAssets: 25,
  },
  {
    lat: 34.0880507,
    lng: -118.2965121,
    squareFootage: "3787",
    propertyType: "Multifamily",
    totalCosts: "1175000",
    valueOfReclassifiedAssets: 262967,
    depreciableAssets: 30,
  },
  {
    lat: 39.9340605,
    lng: -74.5228916,
    squareFootage: "14000",
    propertyType: "Multifamily",
    totalCosts: "850000",
    valueOfReclassifiedAssets: 109544,
    depreciableAssets: 15,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "9136",
    propertyType: "Multifamily",
    totalCosts: "509613",
    valueOfReclassifiedAssets: 409648,
    depreciableAssets: 22,
  },
  {
    lat: 34.0295114,
    lng: -118.2848199,
    squareFootage: "5386",
    propertyType: "Multifamily",
    totalCosts: "1575000",
    valueOfReclassifiedAssets: 296380,
    depreciableAssets: 25,
  },
  {
    lat: 34.0489277,
    lng: -118.3403506,
    squareFootage: "5630",
    propertyType: "Multifamily",
    totalCosts: "926527",
    valueOfReclassifiedAssets: 763107,
    depreciableAssets: 23,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "29514",
    propertyType: "Multifamily",
    totalCosts: "1345555",
    valueOfReclassifiedAssets: 186951,
    depreciableAssets: 20,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "22470",
    propertyType: "Multifamily",
    totalCosts: "727726",
    valueOfReclassifiedAssets: 48173,
    depreciableAssets: 15,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "22470",
    propertyType: "Multifamily",
    totalCosts: "2147274",
    valueOfReclassifiedAssets: 192641,
    depreciableAssets: 15,
  },
  {
    lat: 34.0741548,
    lng: -118.3724915,
    squareFootage: "33992",
    propertyType: "Multifamily",
    totalCosts: "1551816",
    valueOfReclassifiedAssets: 186979,
    depreciableAssets: 16,
  },
  {
    lat: 34.0741548,
    lng: -118.3724915,
    squareFootage: "33992",
    propertyType: "Multifamily",
    totalCosts: "16938957",
    valueOfReclassifiedAssets: 1068813,
    depreciableAssets: 16,
  },
  {
    lat: 34.1423899,
    lng: -118.4571974,
    squareFootage: "26910",
    propertyType: "Multifamily",
    totalCosts: "4284097",
    valueOfReclassifiedAssets: 336922,
    depreciableAssets: 13,
  },
  {
    lat: 34.1423899,
    lng: -118.4571974,
    squareFootage: "26910",
    propertyType: "Multifamily",
    totalCosts: "856283",
    valueOfReclassifiedAssets: 57568,
    depreciableAssets: 13,
  },
  {
    lat: 40.6722134,
    lng: -73.93892129999999,
    squareFootage: "8040",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 375365,
    depreciableAssets: 14,
  },
  {
    lat: 40.98128270000001,
    lng: -80.2878794,
    squareFootage: "16450",
    propertyType: "Industrial",
    totalCosts: "1550000",
    valueOfReclassifiedAssets: 348813,
    depreciableAssets: 27,
  },
  {
    lat: 42.9362643,
    lng: -83.57096399999999,
    squareFootage: "58500",
    propertyType: "Industrial",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 1285502,
    depreciableAssets: 55,
  },
  {
    lat: 42.1055794,
    lng: -87.74680710000001,
    squareFootage: "59950",
    propertyType: "Industrial",
    totalCosts: "3275000",
    valueOfReclassifiedAssets: 785939,
    depreciableAssets: 29,
  },
  {
    lat: 32.4498466,
    lng: -83.7411631,
    squareFootage: "72200",
    propertyType: "Retail",
    totalCosts: "11400000",
    valueOfReclassifiedAssets: 3723676,
    depreciableAssets: 40,
  },
  {
    lat: 29.5929013,
    lng: -98.5254159,
    squareFootage: "51057",
    propertyType: "Industrial",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 3020000,
    depreciableAssets: 100,
  },
  {
    lat: 40.743503,
    lng: -111.5468301,
    squareFootage: "77569",
    propertyType: "Industrial",
    totalCosts: "9250000",
    valueOfReclassifiedAssets: 1874734,
    depreciableAssets: 25,
  },
  {
    lat: 38.58001840000001,
    lng: -121.4627576,
    squareFootage: "74547",
    propertyType: "Industrial",
    totalCosts: "9825000",
    valueOfReclassifiedAssets: 8051575,
    depreciableAssets: 100,
  },
  {
    lat: 41.1400096,
    lng: -104.8201078,
    squareFootage: "100000",
    propertyType: "Industrial",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 1413616,
    depreciableAssets: 24,
  },
  {
    lat: 40.5417839,
    lng: -105.044052,
    squareFootage: "103500",
    propertyType: "Industrial",
    totalCosts: "10450000",
    valueOfReclassifiedAssets: 2103776,
    depreciableAssets: 22,
  },
  {
    lat: 38.2672631,
    lng: -122.1064668,
    squareFootage: "76024",
    propertyType: "Industrial",
    totalCosts: "12105000",
    valueOfReclassifiedAssets: 2335357,
    depreciableAssets: 23,
  },
  {
    lat: 38.3286205,
    lng: -121.9359339,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9545000",
    valueOfReclassifiedAssets: 1072335,
    depreciableAssets: 13,
  },
  {
    lat: 38.9627306,
    lng: -76.854799,
    squareFootage: "6792",
    propertyType: "Retail",
    totalCosts: "2285783",
    valueOfReclassifiedAssets: 1992761,
    depreciableAssets: 30,
  },
  {
    lat: 39.201384,
    lng: -76.83749949999999,
    squareFootage: "160817",
    propertyType: "Office",
    totalCosts: "25600000",
    valueOfReclassifiedAssets: 6113183,
    depreciableAssets: 28,
  },
  {
    lat: 39.201384,
    lng: -76.83749949999999,
    squareFootage: "81067",
    propertyType: "Office",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 6187725,
    depreciableAssets: 26,
  },
  {
    lat: 35.1724135,
    lng: -78.8722341,
    squareFootage: "80600",
    propertyType: "Industrial",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 273502,
    depreciableAssets: 12,
  },
  {
    lat: 36.0955555,
    lng: -79.4379412,
    squareFootage: "101400",
    propertyType: "Industrial",
    totalCosts: "3458325",
    valueOfReclassifiedAssets: 332216,
    depreciableAssets: 11,
  },
  {
    lat: 34.8693395,
    lng: -81.9207423,
    squareFootage: "33630",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 581066,
    depreciableAssets: 24,
  },
  {
    lat: 38.013412,
    lng: -84.485507,
    squareFootage: "25352",
    propertyType: "Multifamily",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 171400,
    depreciableAssets: 19,
  },
  {
    lat: 34.02912269999999,
    lng: -81.00368209999999,
    squareFootage: "31000",
    propertyType: "Multifamily",
    totalCosts: "768000",
    valueOfReclassifiedAssets: 174867,
    depreciableAssets: 27,
  },
  {
    lat: 40.50259490000001,
    lng: -85.39627689999999,
    squareFootage: "54920",
    propertyType: "Specialty",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 679260,
    depreciableAssets: 61,
  },
  {
    lat: 40.6483744,
    lng: -73.9800645,
    squareFootage: "755183",
    propertyType: "Specialty",
    totalCosts: "75500000",
    valueOfReclassifiedAssets: 11831666,
    depreciableAssets: 18,
  },
  {
    lat: 33.6958943,
    lng: -84.238417,
    squareFootage: "272852",
    propertyType: "Multifamily",
    totalCosts: "13500000",
    valueOfReclassifiedAssets: 3394121,
    depreciableAssets: 30,
  },
  {
    lat: 33.4306545,
    lng: -86.7919009,
    squareFootage: "181132",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 3770699,
    depreciableAssets: 35,
  },
  {
    lat: 28.0563834,
    lng: -82.4427769,
    squareFootage: "109224",
    propertyType: "Multifamily",
    totalCosts: "6640000",
    valueOfReclassifiedAssets: 1740450,
    depreciableAssets: 31,
  },
  {
    lat: 33.7026678,
    lng: -84.38574419999999,
    squareFootage: "211250",
    propertyType: "Multifamily",
    totalCosts: "10226500",
    valueOfReclassifiedAssets: 2563119,
    depreciableAssets: 30,
  },
  {
    lat: 28.01574,
    lng: -82.5510469,
    squareFootage: "322016",
    propertyType: "Multifamily",
    totalCosts: "35500000",
    valueOfReclassifiedAssets: 9521877,
    depreciableAssets: 31,
  },
  {
    lat: 28.1537767,
    lng: -82.4156829,
    squareFootage: "356600",
    propertyType: "Multifamily",
    totalCosts: "58500000",
    valueOfReclassifiedAssets: 11815621,
    depreciableAssets: 23,
  },
  {
    lat: 26.1889227,
    lng: -80.1763467,
    squareFootage: "483072",
    propertyType: "Multifamily",
    totalCosts: "102900000",
    valueOfReclassifiedAssets: 20999394,
    depreciableAssets: 24,
  },
  {
    lat: 38.494606,
    lng: -90.45967209999999,
    squareFootage: "132279",
    propertyType: "Industrial",
    totalCosts: "14050000",
    valueOfReclassifiedAssets: 2934191,
    depreciableAssets: 25,
  },
  {
    lat: 34.8920374,
    lng: -82.269193,
    squareFootage: "55977",
    propertyType: "Industrial",
    totalCosts: "9199448",
    valueOfReclassifiedAssets: 3824455,
    depreciableAssets: 50,
  },
  {
    lat: 39.20319480000001,
    lng: -76.675922,
    squareFootage: "155911",
    propertyType: "Hotel",
    totalCosts: "34000000",
    valueOfReclassifiedAssets: 8788578,
    depreciableAssets: 30,
  },
  {
    lat: 41.1796788,
    lng: -80.75469799999999,
    squareFootage: "28668",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1689058",
    valueOfReclassifiedAssets: 663928,
    depreciableAssets: 43,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "71568",
    propertyType: "Multifamily",
    totalCosts: "875000",
    valueOfReclassifiedAssets: 733749,
    depreciableAssets: 17,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "71568",
    propertyType: "Multifamily",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 1090142,
    depreciableAssets: 17,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "71568",
    propertyType: "Multifamily",
    totalCosts: "507000",
    valueOfReclassifiedAssets: 72750,
    depreciableAssets: 17,
  },
  {
    lat: 34.1577811,
    lng: -84.87763919999999,
    squareFootage: "34765",
    propertyType: "Hotel",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 1191140,
    depreciableAssets: 43,
  },
  {
    lat: 40.6358803,
    lng: -105.0089888,
    squareFootage: "5016",
    propertyType: "Retail",
    totalCosts: "3389163",
    valueOfReclassifiedAssets: 965154,
    depreciableAssets: 33,
  },
  {
    lat: 40.5293254,
    lng: -105.1375908,
    squareFootage: "6634",
    propertyType: "Retail",
    totalCosts: "626639",
    valueOfReclassifiedAssets: 223022,
    depreciableAssets: 40,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "194825",
    propertyType: "Multifamily",
    totalCosts: "18750000",
    valueOfReclassifiedAssets: 3971984,
    depreciableAssets: 25,
  },
  {
    lat: 32.5989444,
    lng: -96.85654269999999,
    squareFootage: "169164",
    propertyType: "Multifamily",
    totalCosts: "21025000",
    valueOfReclassifiedAssets: 6266309,
    depreciableAssets: 35,
  },
  {
    lat: 38.9340074,
    lng: -75.3878525,
    squareFootage: "235005",
    propertyType: "Specialty",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 412622,
    depreciableAssets: 20,
  },
  {
    lat: 25.9868744,
    lng: -80.2153998,
    squareFootage: "33600",
    propertyType: "Industrial",
    totalCosts: "4502714",
    valueOfReclassifiedAssets: 954594,
    depreciableAssets: 24,
  },
  {
    lat: 26.2778269,
    lng: -80.109357,
    squareFootage: "22500",
    propertyType: "Industrial",
    totalCosts: "2540918",
    valueOfReclassifiedAssets: 425633,
    depreciableAssets: 19,
  },
  {
    lat: 26.142147,
    lng: -80.1763467,
    squareFootage: "14716",
    propertyType: "Industrial",
    totalCosts: "1228130",
    valueOfReclassifiedAssets: 230735,
    depreciableAssets: 21,
  },
  {
    lat: 26.7712632,
    lng: -80.0702555,
    squareFootage: "16855",
    propertyType: "Industrial",
    totalCosts: "10183843",
    valueOfReclassifiedAssets: 849278,
    depreciableAssets: 10,
  },
  {
    lat: 26.7712632,
    lng: -80.0702555,
    squareFootage: "21800",
    propertyType: "Industrial",
    totalCosts: "2004787",
    valueOfReclassifiedAssets: 245504,
    depreciableAssets: 14,
  },
  {
    lat: 40.3362872,
    lng: -74.43298519999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14311681",
    valueOfReclassifiedAssets: 12514481,
    depreciableAssets: 39,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6266567",
    valueOfReclassifiedAssets: 4922976,
    depreciableAssets: 33,
  },
  {
    lat: 35.6684779,
    lng: -88.7301525,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8992728",
    valueOfReclassifiedAssets: 8196255,
    depreciableAssets: 20,
  },
  {
    lat: 33.2872209,
    lng: -111.9403254,
    squareFootage: "29379",
    propertyType: "Hotel",
    totalCosts: "5225000",
    valueOfReclassifiedAssets: 1003192,
    depreciableAssets: 22,
  },
  {
    lat: 29.9956304,
    lng: -95.27889259999999,
    squareFootage: "75000",
    propertyType: "Industrial",
    totalCosts: "7272919",
    valueOfReclassifiedAssets: 1581163,
    depreciableAssets: 26,
  },
  {
    lat: 29.86738769999999,
    lng: -95.593239,
    squareFootage: "30000",
    propertyType: "Office",
    totalCosts: "7500000",
    valueOfReclassifiedAssets: 2410232,
    depreciableAssets: 37,
  },
  {
    lat: 39.3342834,
    lng: -76.5314817,
    squareFootage: "118419",
    propertyType: "Multifamily",
    totalCosts: "5290862",
    valueOfReclassifiedAssets: 1160498,
    depreciableAssets: 26,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "251860",
    propertyType: "Multifamily",
    totalCosts: "22402407",
    valueOfReclassifiedAssets: 5525570,
    depreciableAssets: 28,
  },
  {
    lat: 39.1655524,
    lng: -84.66071439999999,
    squareFootage: "184010",
    propertyType: "Multifamily",
    totalCosts: "5880283",
    valueOfReclassifiedAssets: 1348755,
    depreciableAssets: 27,
  },
  {
    lat: 39.1655524,
    lng: -84.66071439999999,
    squareFootage: "184010",
    propertyType: "Multifamily",
    totalCosts: "4282602",
    valueOfReclassifiedAssets: 982297,
    depreciableAssets: 27,
  },
  {
    lat: 36.0091763,
    lng: -80.0199562,
    squareFootage: "110000",
    propertyType: "Multifamily",
    totalCosts: "7150000",
    valueOfReclassifiedAssets: 1686972,
    depreciableAssets: 28,
  },
  {
    lat: 43.23236379999999,
    lng: -77.4472495,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "21605000",
    valueOfReclassifiedAssets: 6071135,
    depreciableAssets: 33,
  },
  {
    lat: 43.23236379999999,
    lng: -77.4472495,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "8195000",
    valueOfReclassifiedAssets: 2302844,
    depreciableAssets: 33,
  },
  {
    lat: 29.68936699999999,
    lng: -95.442645,
    squareFootage: "162018",
    propertyType: "Multifamily",
    totalCosts: "2020357",
    valueOfReclassifiedAssets: 474600,
    depreciableAssets: 28,
  },
  {
    lat: 39.4246225,
    lng: -76.30583279999999,
    squareFootage: "116316",
    propertyType: "Multifamily",
    totalCosts: "10866100",
    valueOfReclassifiedAssets: 2613442,
    depreciableAssets: 28,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "120946",
    propertyType: "Multifamily",
    totalCosts: "996476",
    valueOfReclassifiedAssets: 205371,
    depreciableAssets: 25,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "120946",
    propertyType: "Multifamily",
    totalCosts: "870716",
    valueOfReclassifiedAssets: 179451,
    depreciableAssets: 25,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "120946",
    propertyType: "Multifamily",
    totalCosts: "11771521",
    valueOfReclassifiedAssets: 2426072,
    depreciableAssets: 25,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "83136",
    propertyType: "Multifamily",
    totalCosts: "630518",
    valueOfReclassifiedAssets: 124855,
    depreciableAssets: 23,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "83136",
    propertyType: "Multifamily",
    totalCosts: "721586",
    valueOfReclassifiedAssets: 142888,
    depreciableAssets: 23,
  },
  {
    lat: 41.5532772,
    lng: -72.0823688,
    squareFootage: "83136",
    propertyType: "Multifamily",
    totalCosts: "8524205",
    valueOfReclassifiedAssets: 1687961,
    depreciableAssets: 23,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "187423",
    propertyType: "Multifamily",
    totalCosts: "20960743",
    valueOfReclassifiedAssets: 5716743,
    depreciableAssets: 32,
  },
  {
    lat: 39.295848,
    lng: -76.4215988,
    squareFootage: "187423",
    propertyType: "Multifamily",
    totalCosts: "2113248",
    valueOfReclassifiedAssets: 576358,
    depreciableAssets: 32,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "88750",
    propertyType: "Multifamily",
    totalCosts: "1014129",
    valueOfReclassifiedAssets: 224270,
    depreciableAssets: 26,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "88750",
    propertyType: "Multifamily",
    totalCosts: "1580976",
    valueOfReclassifiedAssets: 349627,
    depreciableAssets: 26,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "88750",
    propertyType: "Multifamily",
    totalCosts: "1813431",
    valueOfReclassifiedAssets: 401032,
    depreciableAssets: 26,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "122188",
    propertyType: "Multifamily",
    totalCosts: "3288857",
    valueOfReclassifiedAssets: 746402,
    depreciableAssets: 27,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "92000",
    propertyType: "Multifamily",
    totalCosts: "2216574",
    valueOfReclassifiedAssets: 637458,
    depreciableAssets: 34,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "145000",
    propertyType: "Multifamily",
    totalCosts: "2447040",
    valueOfReclassifiedAssets: 595041,
    depreciableAssets: 29,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "122188",
    propertyType: "Multifamily",
    totalCosts: "3416756",
    valueOfReclassifiedAssets: 775428,
    depreciableAssets: 27,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "92000",
    propertyType: "Multifamily",
    totalCosts: "2302667",
    valueOfReclassifiedAssets: 662216,
    depreciableAssets: 34,
  },
  {
    lat: 37.1541965,
    lng: -76.5372625,
    squareFootage: "145000",
    propertyType: "Multifamily",
    totalCosts: "2573128",
    valueOfReclassifiedAssets: 625702,
    depreciableAssets: 29,
  },
  {
    lat: 33.6392536,
    lng: -83.985008,
    squareFootage: "3960",
    propertyType: "Specialty",
    totalCosts: "314232",
    valueOfReclassifiedAssets: 203692,
    depreciableAssets: 0,
  },
  {
    lat: 33.6392536,
    lng: -83.985008,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "310600",
    valueOfReclassifiedAssets: 124698,
    depreciableAssets: 55,
  },
  {
    lat: 39.1045785,
    lng: -94.58952939999999,
    squareFootage: "116630",
    propertyType: "Multifamily",
    totalCosts: "29803835",
    valueOfReclassifiedAssets: 6125926,
    depreciableAssets: 24,
  },
  {
    lat: 40.7686973,
    lng: -73.9918181,
    squareFootage: "536719",
    propertyType: "Hotel",
    totalCosts: "420000000",
    valueOfReclassifiedAssets: 19109909,
    depreciableAssets: 5,
  },
  {
    lat: 30.40029639999999,
    lng: -86.4895463,
    squareFootage: "19000",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 507595,
    depreciableAssets: 25,
  },
  {
    lat: 40.0669532,
    lng: -74.8390162,
    squareFootage: "183340",
    propertyType: "Industrial",
    totalCosts: "15550000",
    valueOfReclassifiedAssets: 1217741,
    depreciableAssets: 9,
  },
  {
    lat: 41.24118199999999,
    lng: -77.0010648,
    squareFootage: "4500",
    propertyType: "Retail",
    totalCosts: "1250000",
    valueOfReclassifiedAssets: 140230,
    depreciableAssets: 14,
  },
  {
    lat: 27.8585758,
    lng: -82.33976349999999,
    squareFootage: "144935",
    propertyType: "Multifamily",
    totalCosts: "23500000",
    valueOfReclassifiedAssets: 6579766,
    depreciableAssets: 33,
  },
  {
    lat: 35.4121916,
    lng: -89.7567693,
    squareFootage: "200961",
    propertyType: "Multifamily",
    totalCosts: "20250000",
    valueOfReclassifiedAssets: 4154830,
    depreciableAssets: 24,
  },
  {
    lat: 32.3035838,
    lng: -106.6055534,
    squareFootage: "152304",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10775000",
    valueOfReclassifiedAssets: 2703810,
    depreciableAssets: 29,
  },
  {
    lat: 35.7312824,
    lng: -79.7960523,
    squareFootage: "36736",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 2139040,
    depreciableAssets: 36,
  },
  {
    lat: 35.7312824,
    lng: -79.7960523,
    squareFootage: "36736",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7550000",
    valueOfReclassifiedAssets: 2593451,
    depreciableAssets: 36,
  },
  {
    lat: 40.8811561,
    lng: -74.1416127,
    squareFootage: "52577",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8628000",
    valueOfReclassifiedAssets: 1979111,
    depreciableAssets: 25,
  },
  {
    lat: 47.24570730000001,
    lng: -122.4773432,
    squareFootage: "25804",
    propertyType: "Specialty",
    totalCosts: "3529000",
    valueOfReclassifiedAssets: 1152010,
    depreciableAssets: 36,
  },
  {
    lat: 38.9280687,
    lng: -80.82116959999999,
    squareFootage: "21782",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10533000",
    valueOfReclassifiedAssets: 4189043,
    depreciableAssets: 41,
  },
  {
    lat: 39.63594519999999,
    lng: -80.7879407,
    squareFootage: "39697",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3846856,
    depreciableAssets: 38,
  },
  {
    lat: 39.63594519999999,
    lng: -80.7879407,
    squareFootage: "39697",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20635000",
    valueOfReclassifiedAssets: 7694088,
    depreciableAssets: 38,
  },
  {
    lat: 37.7050703,
    lng: -122.1235054,
    squareFootage: "28635",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 1834145,
    depreciableAssets: 26,
  },
  {
    lat: 39.9311729,
    lng: -104.9739333,
    squareFootage: "117420",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14500000",
    valueOfReclassifiedAssets: 3350272,
    depreciableAssets: 25,
  },
  {
    lat: 35.5057603,
    lng: -108.7600963,
    squareFootage: "17629",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 2089834,
    depreciableAssets: 43,
  },
  {
    lat: 36.7471661,
    lng: -108.214028,
    squareFootage: "30698",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 2902222,
    depreciableAssets: 32,
  },
  {
    lat: 30.0118752,
    lng: -95.4463322,
    squareFootage: "202486",
    propertyType: "Multifamily",
    totalCosts: "22400000",
    valueOfReclassifiedAssets: 5940891,
    depreciableAssets: 32,
  },
  {
    lat: 29.8265052,
    lng: -95.46729619999999,
    squareFootage: "340580",
    propertyType: "Multifamily",
    totalCosts: "29600000",
    valueOfReclassifiedAssets: 7636532,
    depreciableAssets: 31,
  },
  {
    lat: 38.9027081,
    lng: -95.2162283,
    squareFootage: "39823",
    propertyType: "Retail",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1318037,
    depreciableAssets: 33,
  },
  {
    lat: 42.8410449,
    lng: -78.7362845,
    squareFootage: "88938",
    propertyType: "Office",
    totalCosts: "4975000",
    valueOfReclassifiedAssets: 1788910,
    depreciableAssets: 40,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "65693",
    propertyType: "Multifamily",
    totalCosts: "3450000",
    valueOfReclassifiedAssets: 908292,
    depreciableAssets: 31,
  },
  {
    lat: 32.8449965,
    lng: -96.9799755,
    squareFootage: "69680",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1774636,
    depreciableAssets: 38,
  },
  {
    lat: 34.00974600000001,
    lng: -118.4659576,
    squareFootage: "113331",
    propertyType: "Multifamily",
    totalCosts: "5758771",
    valueOfReclassifiedAssets: 1449243,
    depreciableAssets: 30,
  },
  {
    lat: 32.7476492,
    lng: -97.09248989999999,
    squareFootage: "55493",
    propertyType: "Multifamily",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 1539184,
    depreciableAssets: 34,
  },
  {
    lat: 40.5106594,
    lng: -74.6472522,
    squareFootage: "16547",
    propertyType: "Office",
    totalCosts: "1225599",
    valueOfReclassifiedAssets: 162451,
    depreciableAssets: 17,
  },
  {
    lat: 41.0461965,
    lng: -74.8039205,
    squareFootage: "35752",
    propertyType: "Office",
    totalCosts: "6852213",
    valueOfReclassifiedAssets: 1097910,
    depreciableAssets: 20,
  },
  {
    lat: 36.8638688,
    lng: -119.7715563,
    squareFootage: "5760",
    propertyType: "Retail",
    totalCosts: "2050000",
    valueOfReclassifiedAssets: 343358,
    depreciableAssets: 23,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "131455",
    propertyType: "Office",
    totalCosts: "89000000",
    valueOfReclassifiedAssets: 9111737,
    depreciableAssets: 12,
  },
  {
    lat: 35.7830899,
    lng: -83.91089680000002,
    squareFootage: "9180",
    propertyType: "Multifamily",
    totalCosts: "800000",
    valueOfReclassifiedAssets: 222917,
    depreciableAssets: 31,
  },
  {
    lat: 39.7215173,
    lng: -75.7487779,
    squareFootage: "193572",
    propertyType: "Multifamily",
    totalCosts: "39300000",
    valueOfReclassifiedAssets: 7706908,
    depreciableAssets: 23,
  },
  {
    lat: 35.5692059,
    lng: -82.6159271,
    squareFootage: "383179",
    propertyType: "Multifamily",
    totalCosts: "64500000",
    valueOfReclassifiedAssets: 16474045,
    depreciableAssets: 31,
  },
  {
    lat: 34.9041714,
    lng: -82.11695499999999,
    squareFootage: "247734",
    propertyType: "Multifamily",
    totalCosts: "30250000",
    valueOfReclassifiedAssets: 6677276,
    depreciableAssets: 26,
  },
  {
    lat: 34.9041714,
    lng: -82.11695499999999,
    squareFootage: "246280",
    propertyType: "Multifamily",
    totalCosts: "29800000",
    valueOfReclassifiedAssets: 7625819,
    depreciableAssets: 30,
  },
  {
    lat: 40.6137101,
    lng: -80.0560239,
    squareFootage: "460514",
    propertyType: "Multifamily",
    totalCosts: "61025000",
    valueOfReclassifiedAssets: 10295857,
    depreciableAssets: 20,
  },
  {
    lat: 40.6195067,
    lng: -73.9859414,
    squareFootage: "15080",
    propertyType: "Office",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 2628104,
    depreciableAssets: 39,
  },
  {
    lat: 31.2483723,
    lng: -85.37562489999999,
    squareFootage: "42680",
    propertyType: "Industrial",
    totalCosts: "2449524",
    valueOfReclassifiedAssets: 828729,
    depreciableAssets: 37,
  },
  {
    lat: 41.89332539999999,
    lng: -87.7812524,
    squareFootage: "18000",
    propertyType: "Multifamily",
    totalCosts: "2055000",
    valueOfReclassifiedAssets: 368556,
    depreciableAssets: 22,
  },
  {
    lat: 40.75368539999999,
    lng: -73.9991637,
    squareFootage: "7050",
    propertyType: "Office",
    totalCosts: "9180000",
    valueOfReclassifiedAssets: 843109,
    depreciableAssets: 10,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "39312",
    propertyType: "Multifamily",
    totalCosts: "7614290",
    valueOfReclassifiedAssets: 1016837,
    depreciableAssets: 16,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "36390",
    propertyType: "Multifamily",
    totalCosts: "9847915",
    valueOfReclassifiedAssets: 1316638,
    depreciableAssets: 16,
  },
  {
    lat: 36.1568627,
    lng: -80.2432839,
    squareFootage: "22920",
    propertyType: "Multifamily",
    totalCosts: "1225000",
    valueOfReclassifiedAssets: 260966,
    depreciableAssets: 27,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "288819",
    propertyType: "Multifamily",
    totalCosts: "7100000",
    valueOfReclassifiedAssets: 1327866,
    depreciableAssets: 22,
  },
  {
    lat: 30.1643797,
    lng: -81.7458845,
    squareFootage: "73965",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1116020,
    depreciableAssets: 27,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "136029",
    propertyType: "Multifamily",
    totalCosts: "7788000",
    valueOfReclassifiedAssets: 1719668,
    depreciableAssets: 26,
  },
  {
    lat: 30.1643797,
    lng: -81.7458845,
    squareFootage: "60000",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1471810,
    depreciableAssets: 31,
  },
  {
    lat: 35.3466196,
    lng: -89.9626799,
    squareFootage: "146182",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1209924,
    depreciableAssets: 24,
  },
  {
    lat: 34.447771,
    lng: -93.0776568,
    squareFootage: "53500",
    propertyType: "Multifamily",
    totalCosts: "2044439",
    valueOfReclassifiedAssets: 464055,
    depreciableAssets: 27,
  },
  {
    lat: 35.3459327,
    lng: -97.47059349999999,
    squareFootage: "50470",
    propertyType: "Multifamily",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 343645,
    depreciableAssets: 22,
  },
  {
    lat: 39.6199688,
    lng: -75.50437610000002,
    squareFootage: "58400",
    propertyType: "Multifamily",
    totalCosts: "2633841",
    valueOfReclassifiedAssets: 592190,
    depreciableAssets: 28,
  },
  {
    lat: 41.0714692,
    lng: -85.1012477,
    squareFootage: "391632",
    propertyType: "Multifamily",
    totalCosts: "7898801",
    valueOfReclassifiedAssets: 2059518,
    depreciableAssets: 27,
  },
  {
    lat: 42.4362108,
    lng: -82.9340217,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "22056362",
    valueOfReclassifiedAssets: 6340909,
    depreciableAssets: 36,
  },
  {
    lat: 42.5988654,
    lng: -82.8802133,
    squareFootage: "48801",
    propertyType: "Multifamily",
    totalCosts: "2243915",
    valueOfReclassifiedAssets: 481464,
    depreciableAssets: 25,
  },
  {
    lat: 42.295963,
    lng: -83.3735966,
    squareFootage: "119140",
    propertyType: "Multifamily",
    totalCosts: "10650000",
    valueOfReclassifiedAssets: 2869805,
    depreciableAssets: 32,
  },
  {
    lat: 42.5345798,
    lng: -82.9447782,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2830997,
    depreciableAssets: 30,
  },
  {
    lat: 42.464947,
    lng: -83.0039075,
    squareFootage: "252910",
    propertyType: "Multifamily",
    totalCosts: "18950000",
    valueOfReclassifiedAssets: 5272697,
    depreciableAssets: 33,
  },
  {
    lat: 41.3788177,
    lng: -81.52672820000001,
    squareFootage: "496748",
    propertyType: "Multifamily",
    totalCosts: "36480000",
    valueOfReclassifiedAssets: 9583186,
    depreciableAssets: 30,
  },
  {
    lat: 42.3787402,
    lng: -83.1970511,
    squareFootage: "35000",
    propertyType: "Multifamily",
    totalCosts: "1533000",
    valueOfReclassifiedAssets: 303226,
    depreciableAssets: 24,
  },
  {
    lat: 42.3552033,
    lng: -83.2131206,
    squareFootage: "18200",
    propertyType: "Multifamily",
    totalCosts: "935000",
    valueOfReclassifiedAssets: 210334,
    depreciableAssets: 26,
  },
  {
    lat: 42.4224755,
    lng: -83.1916937,
    squareFootage: "22750",
    propertyType: "Multifamily",
    totalCosts: "1182000",
    valueOfReclassifiedAssets: 239260,
    depreciableAssets: 24,
  },
  {
    lat: 38.9043608,
    lng: -104.7637619,
    squareFootage: "22312",
    propertyType: "Office",
    totalCosts: "4166069",
    valueOfReclassifiedAssets: 766462,
    depreciableAssets: 22,
  },
  {
    lat: 42.613659,
    lng: -73.7360175,
    squareFootage: "249002",
    propertyType: "Office",
    totalCosts: "20250000",
    valueOfReclassifiedAssets: 2813592,
    depreciableAssets: 16,
  },
  {
    lat: 40.7645239,
    lng: -73.96243270000001,
    squareFootage: "17000",
    propertyType: "Multifamily",
    totalCosts: "7575000",
    valueOfReclassifiedAssets: 356733,
    depreciableAssets: 5,
  },
  {
    lat: 40.7700703,
    lng: -73.9580246,
    squareFootage: "13285",
    propertyType: "Multifamily",
    totalCosts: "8125000",
    valueOfReclassifiedAssets: 479480,
    depreciableAssets: 7,
  },
  {
    lat: 40.8147803,
    lng: -73.90805809999999,
    squareFootage: "51000",
    propertyType: "Multifamily",
    totalCosts: "8612500",
    valueOfReclassifiedAssets: 1430051,
    depreciableAssets: 19,
  },
  {
    lat: 32.470217,
    lng: -84.9505398,
    squareFootage: "138000",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 515599,
    depreciableAssets: 27,
  },
  {
    lat: 35.2585957,
    lng: -97.47059349999999,
    squareFootage: "73208",
    propertyType: "Retail",
    totalCosts: "6001632",
    valueOfReclassifiedAssets: 1225982,
    depreciableAssets: 24,
  },
  {
    lat: 32.4389231,
    lng: -99.7790227,
    squareFootage: "43852",
    propertyType: "Retail",
    totalCosts: "4612239",
    valueOfReclassifiedAssets: 1308106,
    depreciableAssets: 34,
  },
  {
    lat: 33.47561,
    lng: -101.9233964,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1660926",
    valueOfReclassifiedAssets: 1634052,
    depreciableAssets: 23,
  },
  {
    lat: 33.47561,
    lng: -101.9233964,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1277037",
    valueOfReclassifiedAssets: 1245230,
    depreciableAssets: 29,
  },
  {
    lat: 32.0555791,
    lng: -102.2319048,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1574484",
    valueOfReclassifiedAssets: 1495865,
    depreciableAssets: 23,
  },
  {
    lat: 35.041539,
    lng: -85.1583064,
    squareFootage: "100368",
    propertyType: "Retail",
    totalCosts: "7285688",
    valueOfReclassifiedAssets: 1533868,
    depreciableAssets: 25,
  },
  {
    lat: 33.5424277,
    lng: -101.8606603,
    squareFootage: "99070",
    propertyType: "Multifamily",
    totalCosts: "3553142",
    valueOfReclassifiedAssets: 879132,
    depreciableAssets: 27,
  },
  {
    lat: 32.7320972,
    lng: -96.9907197,
    squareFootage: "17024",
    propertyType: "Multifamily",
    totalCosts: "972304",
    valueOfReclassifiedAssets: 274996,
    depreciableAssets: 33,
  },
  {
    lat: 33.5479366,
    lng: -101.9176916,
    squareFootage: "106910",
    propertyType: "Multifamily",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 998446,
    depreciableAssets: 30,
  },
  {
    lat: 29.9905486,
    lng: -95.4044268,
    squareFootage: "100408",
    propertyType: "Multifamily",
    totalCosts: "10350000",
    valueOfReclassifiedAssets: 3172849,
    depreciableAssets: 36,
  },
  {
    lat: 30.2966026,
    lng: -97.9701846,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5520086",
    valueOfReclassifiedAssets: 4742737,
    depreciableAssets: 0,
  },
  {
    lat: 30.2966026,
    lng: -97.9701846,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1928559",
    valueOfReclassifiedAssets: 1137108,
    depreciableAssets: 49,
  },
  {
    lat: 40.5971482,
    lng: -74.6283316,
    squareFootage: "66000",
    propertyType: "Office",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 787440,
    depreciableAssets: 18,
  },
  {
    lat: 40.6867556,
    lng: -74.5580454,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "74550000",
    valueOfReclassifiedAssets: 12858962,
    depreciableAssets: 21,
  },
  {
    lat: 35.92399169999999,
    lng: -95.8775964,
    squareFootage: "171444",
    propertyType: "Retail",
    totalCosts: "32026615",
    valueOfReclassifiedAssets: 7560018,
    depreciableAssets: 27,
  },
  {
    lat: 39.320726,
    lng: -77.87674419999999,
    squareFootage: "139047",
    propertyType: "Retail",
    totalCosts: "36345487",
    valueOfReclassifiedAssets: 16189268,
    depreciableAssets: 49,
  },
  {
    lat: 42.06909599999999,
    lng: -88.20228759999999,
    squareFootage: "37800",
    propertyType: "Office",
    totalCosts: "2950000",
    valueOfReclassifiedAssets: 685042,
    depreciableAssets: 28,
  },
  {
    lat: 42.1303943,
    lng: -88.02163340000001,
    squareFootage: "32676",
    propertyType: "Retail",
    totalCosts: "4675000",
    valueOfReclassifiedAssets: 710165,
    depreciableAssets: 18,
  },
  {
    lat: 42.05779889999999,
    lng: -88.11449429999999,
    squareFootage: "5382",
    propertyType: "Retail",
    totalCosts: "2475000",
    valueOfReclassifiedAssets: 787523,
    depreciableAssets: 37,
  },
  {
    lat: 42.05779889999999,
    lng: -88.11449429999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6750525",
    valueOfReclassifiedAssets: 4531656,
    depreciableAssets: 0,
  },
  {
    lat: 41.5132913,
    lng: -87.962884,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3032465",
    valueOfReclassifiedAssets: 2918863,
    depreciableAssets: 27,
  },
  {
    lat: 41.7547889,
    lng: -87.81075609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4322826",
    valueOfReclassifiedAssets: 4249327,
    depreciableAssets: 15,
  },
  {
    lat: 41.9965452,
    lng: -88.314212,
    squareFootage: "5332",
    propertyType: "Industrial",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 4204145,
    depreciableAssets: 68,
  },
  {
    lat: 42.01136169999999,
    lng: -88.0020589,
    squareFootage: "126200",
    propertyType: "Office",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 909828,
    depreciableAssets: 21,
  },
  {
    lat: 41.8144472,
    lng: -87.81075609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "645862",
    valueOfReclassifiedAssets: 547433,
    depreciableAssets: 2,
  },
  {
    lat: 41.79061129999999,
    lng: -87.86479609999999,
    squareFootage: "6254",
    propertyType: "Specialty",
    totalCosts: "720000",
    valueOfReclassifiedAssets: 139022,
    depreciableAssets: 22,
  },
  {
    lat: 44.9810012,
    lng: -89.7192132,
    squareFootage: "40347",
    propertyType: "Hotel",
    totalCosts: "9450000",
    valueOfReclassifiedAssets: 1810205,
    depreciableAssets: 28,
  },
  {
    lat: 32.9696102,
    lng: -96.3319253,
    squareFootage: "9012",
    propertyType: "Retail",
    totalCosts: "3515000",
    valueOfReclassifiedAssets: 1239778,
    depreciableAssets: 42,
  },
  {
    lat: 40.8144014,
    lng: -73.4772682,
    squareFootage: "69262",
    propertyType: "Office",
    totalCosts: "11777000",
    valueOfReclassifiedAssets: 2401484,
    depreciableAssets: 24,
  },
  {
    lat: 36.8653194,
    lng: -76.0507949,
    squareFootage: "368276",
    propertyType: "Multifamily",
    totalCosts: "68097073",
    valueOfReclassifiedAssets: 19671544,
    depreciableAssets: 34,
  },
  {
    lat: 39.6261313,
    lng: -75.8766333,
    squareFootage: "65668",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13800000",
    valueOfReclassifiedAssets: 4186418,
    depreciableAssets: 34,
  },
  {
    lat: 40.6945036,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4230281",
    valueOfReclassifiedAssets: 3704710,
    depreciableAssets: 12,
  },
  {
    lat: 42.435511,
    lng: -76.5257007,
    squareFootage: "56175",
    propertyType: "Multifamily",
    totalCosts: "3180000",
    valueOfReclassifiedAssets: 967113,
    depreciableAssets: 36,
  },
  {
    lat: 42.2078684,
    lng: -73.9124674,
    squareFootage: "48508",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1300564,
    depreciableAssets: 21,
  },
  {
    lat: 36.6617693,
    lng: -80.254435,
    squareFootage: "54426",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5167767",
    valueOfReclassifiedAssets: 1418414,
    depreciableAssets: 31,
  },
  {
    lat: 36.6617693,
    lng: -80.254435,
    squareFootage: "51954",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1727214",
    valueOfReclassifiedAssets: 411562,
    depreciableAssets: 26,
  },
  {
    lat: 37.1069309,
    lng: -80.6842271,
    squareFootage: "73919",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5970069",
    valueOfReclassifiedAssets: 1272194,
    depreciableAssets: 24,
  },
  {
    lat: 39.7716518,
    lng: -86.4086061,
    squareFootage: "48465",
    propertyType: "Office",
    totalCosts: "4020000",
    valueOfReclassifiedAssets: 938809,
    depreciableAssets: 27,
  },
  {
    lat: 32.631572,
    lng: -97.27607599999999,
    squareFootage: "268012",
    propertyType: "Multifamily",
    totalCosts: "40188468",
    valueOfReclassifiedAssets: 11598880,
    depreciableAssets: 34,
  },
  {
    lat: 38.5947311,
    lng: -77.3381855,
    squareFootage: "68436",
    propertyType: "Hotel",
    totalCosts: "8200000",
    valueOfReclassifiedAssets: 1751355,
    depreciableAssets: 25,
  },
  {
    lat: 37.6101767,
    lng: -122.4806894,
    squareFootage: "11910",
    propertyType: "Multifamily",
    totalCosts: "3004367",
    valueOfReclassifiedAssets: 434075,
    depreciableAssets: 16,
  },
  {
    lat: 36.5128173,
    lng: -87.2419299,
    squareFootage: "11620",
    propertyType: "Office",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 885045,
    depreciableAssets: 42,
  },
  {
    lat: 42.5792379,
    lng: -71.3052383,
    squareFootage: "12472",
    propertyType: "Office",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 177189,
    depreciableAssets: 23,
  },
  {
    lat: 33.5479366,
    lng: -101.9176916,
    squareFootage: "119650",
    propertyType: "Multifamily",
    totalCosts: "5440000",
    valueOfReclassifiedAssets: 1164703,
    depreciableAssets: 25,
  },
  {
    lat: 47.1616994,
    lng: -122.5019796,
    squareFootage: "23600",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 710852,
    depreciableAssets: 27,
  },
  {
    lat: 47.2111983,
    lng: -122.4144869,
    squareFootage: "23850",
    propertyType: "Multifamily",
    totalCosts: "3795000",
    valueOfReclassifiedAssets: 1227777,
    depreciableAssets: 38,
  },
  {
    lat: 37.75646649999999,
    lng: -97.4056928,
    squareFootage: "20336",
    propertyType: "Multifamily",
    totalCosts: "1682120",
    valueOfReclassifiedAssets: 446202,
    depreciableAssets: 31,
  },
  {
    lat: 32.8276924,
    lng: -96.679851,
    squareFootage: "193520",
    propertyType: "Multifamily",
    totalCosts: "15048000",
    valueOfReclassifiedAssets: 3391231,
    depreciableAssets: 25,
  },
  {
    lat: 35.3819836,
    lng: -97.76340189999999,
    squareFootage: "7020",
    propertyType: "Office",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 412024,
    depreciableAssets: 32,
  },
  {
    lat: 32.7891446,
    lng: -97.1736385,
    squareFootage: "65005",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 875232,
    depreciableAssets: 29,
  },
  {
    lat: 34.2667473,
    lng: -117.8545867,
    squareFootage: "78656",
    propertyType: "Specialty",
    totalCosts: "21100000",
    valueOfReclassifiedAssets: 4268184,
    depreciableAssets: 32,
  },
  {
    lat: 41.6648036,
    lng: -87.79108910000001,
    squareFootage: "92845",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16671080",
    valueOfReclassifiedAssets: 5312041,
    depreciableAssets: 33,
  },
  {
    lat: 41.6648036,
    lng: -87.79108910000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4942457",
    valueOfReclassifiedAssets: 3843998,
    depreciableAssets: 30,
  },
  {
    lat: 40.110052,
    lng: -88.14865700000001,
    squareFootage: "133000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9660000",
    valueOfReclassifiedAssets: 1704967,
    depreciableAssets: 21,
  },
  {
    lat: 39.8319859,
    lng: -88.8838834,
    squareFootage: "71250",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4301779",
    valueOfReclassifiedAssets: 1296053,
    depreciableAssets: 35,
  },
  {
    lat: 39.8319859,
    lng: -88.8838834,
    squareFootage: "71250",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2371521",
    valueOfReclassifiedAssets: 582284,
    depreciableAssets: 29,
  },
  {
    lat: 39.3312245,
    lng: -88.4597673,
    squareFootage: "47160",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3626822",
    valueOfReclassifiedAssets: 976680,
    depreciableAssets: 31,
  },
  {
    lat: 41.5092744,
    lng: -87.74680710000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13962868",
    valueOfReclassifiedAssets: 11183116,
    depreciableAssets: 22,
  },
  {
    lat: 37.8153893,
    lng: -89.0274915,
    squareFootage: "45600",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5034959",
    valueOfReclassifiedAssets: 1517436,
    depreciableAssets: 35,
  },
  {
    lat: 40.6941107,
    lng: -89.41746409999999,
    squareFootage: "47809",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3827985",
    valueOfReclassifiedAssets: 1255875,
    depreciableAssets: 39,
  },
  {
    lat: 36.7378524,
    lng: -90.4879916,
    squareFootage: "37932",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6030682",
    valueOfReclassifiedAssets: 2472300,
    depreciableAssets: 43,
  },
  {
    lat: 39.1322873,
    lng: -89.4553088,
    squareFootage: "28063",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6277554",
    valueOfReclassifiedAssets: 2082209,
    depreciableAssets: 33,
  },
  {
    lat: 38.7888018,
    lng: -90.2901815,
    squareFootage: "36686",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5113737",
    valueOfReclassifiedAssets: 1892123,
    depreciableAssets: 37,
  },
  {
    lat: 39.1122495,
    lng: -90.3371889,
    squareFootage: "42976",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5812682",
    valueOfReclassifiedAssets: 2350913,
    depreciableAssets: 41,
  },
  {
    lat: 38.4744782,
    lng: -89.99067339999999,
    squareFootage: "51562",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3561982",
    valueOfReclassifiedAssets: 1257933,
    depreciableAssets: 37,
  },
  {
    lat: 37.7702341,
    lng: -89.02510219999999,
    squareFootage: "37939",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3420913",
    valueOfReclassifiedAssets: 1128948,
    depreciableAssets: 33,
  },
  {
    lat: 38.6270647,
    lng: -88.9222277,
    squareFootage: "31052",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5325339",
    valueOfReclassifiedAssets: 1866977,
    depreciableAssets: 36,
  },
  {
    lat: 39.12488949999999,
    lng: -88.57585739999999,
    squareFootage: "30114",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5854347",
    valueOfReclassifiedAssets: 2028769,
    depreciableAssets: 34,
  },
  {
    lat: 40.1017582,
    lng: -88.2753144,
    squareFootage: "28540",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1657556",
    valueOfReclassifiedAssets: 545577,
    depreciableAssets: 35,
  },
  {
    lat: 41.6103357,
    lng: -88.4210006,
    squareFootage: "23403",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3844119",
    valueOfReclassifiedAssets: 1261605,
    depreciableAssets: 34,
  },
  {
    lat: 38.70064929999999,
    lng: -88.1096118,
    squareFootage: "30949",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5395874",
    valueOfReclassifiedAssets: 1838560,
    depreciableAssets: 35,
  },
  {
    lat: 37.8678824,
    lng: -88.96053649999999,
    squareFootage: "13027",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1745724",
    valueOfReclassifiedAssets: 623006,
    depreciableAssets: 35,
  },
  {
    lat: 38.5375177,
    lng: -90.0653341,
    squareFootage: "32052",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5536942",
    valueOfReclassifiedAssets: 2022749,
    depreciableAssets: 36,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7300000",
    valueOfReclassifiedAssets: 1864360,
    depreciableAssets: 30,
  },
  {
    lat: 32.7762864,
    lng: -96.6958939,
    squareFootage: "26227",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 894382,
    depreciableAssets: 35,
  },
  {
    lat: 26.7619563,
    lng: -80.1037721,
    squareFootage: "65770",
    propertyType: "Office",
    totalCosts: "22666754",
    valueOfReclassifiedAssets: 6446196,
    depreciableAssets: 33,
  },
  {
    lat: 29.9824128,
    lng: -95.4620545,
    squareFootage: "209583",
    propertyType: "Multifamily",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 3777378,
    depreciableAssets: 33,
  },
  {
    lat: 32.7863301,
    lng: -96.79625279999999,
    squareFootage: "175468",
    propertyType: "Multifamily",
    totalCosts: "27965625",
    valueOfReclassifiedAssets: 23094975,
    depreciableAssets: 19,
  },
  {
    lat: 42.3410008,
    lng: -89.0847949,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1605607",
    valueOfReclassifiedAssets: 1197564,
    depreciableAssets: 39,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "256900",
    valueOfReclassifiedAssets: 215381,
    depreciableAssets: 0,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "4960",
    propertyType: "Multifamily",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 635284,
    depreciableAssets: 24,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "12320",
    propertyType: "Multifamily",
    totalCosts: "4317000",
    valueOfReclassifiedAssets: 494305,
    depreciableAssets: 14,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "8400",
    propertyType: "Multifamily",
    totalCosts: "2630000",
    valueOfReclassifiedAssets: 416716,
    depreciableAssets: 19,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "8900",
    propertyType: "Multifamily",
    totalCosts: "2874000",
    valueOfReclassifiedAssets: 403114,
    depreciableAssets: 17,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "8900",
    propertyType: "Multifamily",
    totalCosts: "3150000",
    valueOfReclassifiedAssets: 447436,
    depreciableAssets: 17,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "5915",
    propertyType: "Multifamily",
    totalCosts: "1183000",
    valueOfReclassifiedAssets: 132350,
    depreciableAssets: 13,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "15000",
    propertyType: "Multifamily",
    totalCosts: "4946000",
    valueOfReclassifiedAssets: 766788,
    depreciableAssets: 18,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "15000",
    propertyType: "Multifamily",
    totalCosts: "4480000",
    valueOfReclassifiedAssets: 674272,
    depreciableAssets: 17,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "8390",
    propertyType: "Multifamily",
    totalCosts: "4180000",
    valueOfReclassifiedAssets: 534957,
    depreciableAssets: 15,
  },
  {
    lat: 40.8173411,
    lng: -73.9433299,
    squareFootage: "4256",
    propertyType: "Multifamily",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 340453,
    depreciableAssets: 16,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "14500",
    propertyType: "Retail",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 597072,
    depreciableAssets: 12,
  },
  {
    lat: 43.0968443,
    lng: -76.0507949,
    squareFootage: "71200",
    propertyType: "Office",
    totalCosts: "18000000",
    valueOfReclassifiedAssets: 3692707,
    depreciableAssets: 22,
  },
  {
    lat: 42.4966195,
    lng: -83.2880591,
    squareFootage: "93400",
    propertyType: "Multifamily",
    totalCosts: "6054103",
    valueOfReclassifiedAssets: 1788446,
    depreciableAssets: 37,
  },
  {
    lat: 33.723039,
    lng: -84.3436881,
    squareFootage: "164920",
    propertyType: "Multifamily",
    totalCosts: "17780000",
    valueOfReclassifiedAssets: 5228501,
    depreciableAssets: 35,
  },
  {
    lat: 42.4966195,
    lng: -83.2880591,
    squareFootage: "235614",
    propertyType: "Multifamily",
    totalCosts: "16585200",
    valueOfReclassifiedAssets: 4103316,
    depreciableAssets: 29,
  },
  {
    lat: 42.4966195,
    lng: -83.2880591,
    squareFootage: "191748",
    propertyType: "Multifamily",
    totalCosts: "14014800",
    valueOfReclassifiedAssets: 2707623,
    depreciableAssets: 23,
  },
  {
    lat: 42.5190114,
    lng: -83.0092803,
    squareFootage: "113565",
    propertyType: "Office",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1133901,
    depreciableAssets: 25,
  },
  {
    lat: 27.4011673,
    lng: -82.3994214,
    squareFootage: "3610",
    propertyType: "Specialty",
    totalCosts: "3494324",
    valueOfReclassifiedAssets: 1701951,
    depreciableAssets: 66,
  },
  {
    lat: 27.4933783,
    lng: -82.37773349999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3382925",
    valueOfReclassifiedAssets: 2189619,
    depreciableAssets: 51,
  },
  {
    lat: 32.7749487,
    lng: -117.0146736,
    squareFootage: "18734",
    propertyType: "Office",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 531889,
    depreciableAssets: 16,
  },
  {
    lat: 25.7564662,
    lng: -80.2711588,
    squareFootage: "54916",
    propertyType: "Office",
    totalCosts: "14602500",
    valueOfReclassifiedAssets: 1536937,
    depreciableAssets: 12,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "43604",
    propertyType: "Office",
    totalCosts: "18550000",
    valueOfReclassifiedAssets: 2028190,
    depreciableAssets: 13,
  },
  {
    lat: 33.4767802,
    lng: -117.1970557,
    squareFootage: "44424",
    propertyType: "Office",
    totalCosts: "8250000",
    valueOfReclassifiedAssets: 1002211,
    depreciableAssets: 19,
  },
  {
    lat: 32.9184763,
    lng: -117.1382404,
    squareFootage: "28533",
    propertyType: "Office",
    totalCosts: "3540000",
    valueOfReclassifiedAssets: 383658,
    depreciableAssets: 16,
  },
  {
    lat: 33.052083,
    lng: -117.2793685,
    squareFootage: "34328",
    propertyType: "Office",
    totalCosts: "11073940",
    valueOfReclassifiedAssets: 1015575,
    depreciableAssets: 14,
  },
  {
    lat: 41.98250650000001,
    lng: -73.9888797,
    squareFootage: "116744",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "37666691",
    valueOfReclassifiedAssets: 14324462,
    depreciableAssets: 21,
  },
  {
    lat: 40.59880769999999,
    lng: -73.9447994,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "45464305",
    valueOfReclassifiedAssets: 40775714,
    depreciableAssets: 10,
  },
  {
    lat: 29.240991,
    lng: -81.06994619999999,
    squareFootage: "95500",
    propertyType: "Multifamily",
    totalCosts: "9725000",
    valueOfReclassifiedAssets: 1435929,
    depreciableAssets: 18,
  },
  {
    lat: 29.240991,
    lng: -81.06994619999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1716593",
    valueOfReclassifiedAssets: 876547,
    depreciableAssets: 61,
  },
  {
    lat: 38.6289223,
    lng: -90.33013419999999,
    squareFootage: "40000",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 381029,
    depreciableAssets: 22,
  },
  {
    lat: 41.8642989,
    lng: -87.8205864,
    squareFootage: "7200",
    propertyType: "Multifamily",
    totalCosts: "701703",
    valueOfReclassifiedAssets: 148020,
    depreciableAssets: 23,
  },
  {
    lat: 41.9225138,
    lng: -87.7517295,
    squareFootage: "9964",
    propertyType: "Multifamily",
    totalCosts: "1222695",
    valueOfReclassifiedAssets: 238751,
    depreciableAssets: 27,
  },
  {
    lat: 28.6777437,
    lng: -81.19679579999999,
    squareFootage: "554973",
    propertyType: "Retail",
    totalCosts: "12465533",
    valueOfReclassifiedAssets: 2704098,
    depreciableAssets: 26,
  },
  {
    lat: 35.4811981,
    lng: -97.6602365,
    squareFootage: "63197",
    propertyType: "Industrial",
    totalCosts: "1688022",
    valueOfReclassifiedAssets: 422629,
    depreciableAssets: 30,
  },
  {
    lat: 35.582936,
    lng: -97.5138947,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "267004",
    valueOfReclassifiedAssets: 232519,
    depreciableAssets: 15,
  },
  {
    lat: 35.582936,
    lng: -97.5138947,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "267004",
    valueOfReclassifiedAssets: 128103,
    depreciableAssets: 15,
  },
  {
    lat: 30.6784935,
    lng: -88.3433623,
    squareFootage: "35290",
    propertyType: "Industrial",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1148355,
    depreciableAssets: 27,
  },
  {
    lat: 35.4168123,
    lng: -99.41171969999999,
    squareFootage: "25160",
    propertyType: "Industrial",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 1120337,
    depreciableAssets: 50,
  },
  {
    lat: 35.7312824,
    lng: -79.7960523,
    squareFootage: "89219",
    propertyType: "Retail",
    totalCosts: "7205000",
    valueOfReclassifiedAssets: 2410450,
    depreciableAssets: 37,
  },
  {
    lat: 34.0644032,
    lng: -81.1085744,
    squareFootage: "190797",
    propertyType: "Office",
    totalCosts: "12666000",
    valueOfReclassifiedAssets: 2954329,
    depreciableAssets: 25,
  },
  {
    lat: 34.1985119,
    lng: -118.4980744,
    squareFootage: "22288",
    propertyType: "Industrial",
    totalCosts: "3275000",
    valueOfReclassifiedAssets: 239610,
    depreciableAssets: 11,
  },
  {
    lat: 41.6305694,
    lng: -87.82550069999999,
    squareFootage: "5650",
    propertyType: "Retail",
    totalCosts: "1846219",
    valueOfReclassifiedAssets: 285107,
    depreciableAssets: 25,
  },
  {
    lat: 34.2249609,
    lng: -118.6556478,
    squareFootage: "22704",
    propertyType: "Industrial",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 696501,
    depreciableAssets: 21,
  },
  {
    lat: 40.14866689999999,
    lng: -83.528342,
    squareFootage: "20261",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 362871,
    depreciableAssets: 42,
  },
  {
    lat: 35.1633874,
    lng: -101.900579,
    squareFootage: "139517",
    propertyType: "Multifamily",
    totalCosts: "6094750",
    valueOfReclassifiedAssets: 1464916,
    depreciableAssets: 29,
  },
  {
    lat: 29.5837324,
    lng: -95.033851,
    squareFootage: "113480",
    propertyType: "Multifamily",
    totalCosts: "13100000",
    valueOfReclassifiedAssets: 3582243,
    depreciableAssets: 32,
  },
  {
    lat: 42.3364553,
    lng: -71.0349193,
    squareFootage: "3416",
    propertyType: "Retail",
    totalCosts: "1301133",
    valueOfReclassifiedAssets: 1150077,
    depreciableAssets: 15,
  },
  {
    lat: 40.1594511,
    lng: -76.6181655,
    squareFootage: "36000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2535585",
    valueOfReclassifiedAssets: 581617,
    depreciableAssets: 25,
  },
  {
    lat: 37.3598283,
    lng: -121.9814354,
    squareFootage: "13800",
    propertyType: "Multifamily",
    totalCosts: "5355052",
    valueOfReclassifiedAssets: 1060145,
    depreciableAssets: 30,
  },
  {
    lat: 41.4531581,
    lng: -82.00801400000002,
    squareFootage: "17761",
    propertyType: "Office",
    totalCosts: "1980000",
    valueOfReclassifiedAssets: 1641296,
    depreciableAssets: 0,
  },
  {
    lat: 33.5829386,
    lng: -84.3279093,
    squareFootage: "434260",
    propertyType: "Retail",
    totalCosts: "45257438",
    valueOfReclassifiedAssets: 13020138,
    depreciableAssets: 33,
  },
  {
    lat: 40.7071498,
    lng: -74.0021019,
    squareFootage: "16000",
    propertyType: "Office",
    totalCosts: "19500000",
    valueOfReclassifiedAssets: 16575000,
    depreciableAssets: 100,
  },
  {
    lat: 36.0809667,
    lng: -115.2244853,
    squareFootage: "12612",
    propertyType: "Retail",
    totalCosts: "3400000",
    valueOfReclassifiedAssets: 2806145,
    depreciableAssets: 36,
  },
  {
    lat: 25.6950625,
    lng: -80.4048306,
    squareFootage: "65945",
    propertyType: "Office",
    totalCosts: "13230000",
    valueOfReclassifiedAssets: 914741,
    depreciableAssets: 8,
  },
  {
    lat: 39.071601,
    lng: -119.8468043,
    squareFootage: "7000",
    propertyType: "Retail",
    totalCosts: "1652708",
    valueOfReclassifiedAssets: 384899,
    depreciableAssets: 29,
  },
  {
    lat: 32.7476492,
    lng: -97.09248989999999,
    squareFootage: "42850",
    propertyType: "Industrial",
    totalCosts: "2075000",
    valueOfReclassifiedAssets: 690372,
    depreciableAssets: 36,
  },
  {
    lat: 36.88064,
    lng: -86.4794359,
    squareFootage: "32800",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 683242,
    depreciableAssets: 24,
  },
  {
    lat: 40.0532987,
    lng: -75.23040379999999,
    squareFootage: "11050",
    propertyType: "Retail",
    totalCosts: "907983",
    valueOfReclassifiedAssets: 85216,
    depreciableAssets: 12,
  },
  {
    lat: 40.0532987,
    lng: -75.23040379999999,
    squareFootage: "11050",
    propertyType: "Retail",
    totalCosts: "1081448",
    valueOfReclassifiedAssets: 101496,
    depreciableAssets: 12,
  },
  {
    lat: 35.9980929,
    lng: -94.08999109999999,
    squareFootage: "2112",
    propertyType: "Multifamily",
    totalCosts: "479000",
    valueOfReclassifiedAssets: 118559,
    depreciableAssets: 29,
  },
  {
    lat: 34.0631451,
    lng: -118.4367551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5400000",
    valueOfReclassifiedAssets: 1576050,
    depreciableAssets: 39,
  },
  {
    lat: 40.7245083,
    lng: -73.4298073,
    squareFootage: "4760",
    propertyType: "Office",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 364149,
    depreciableAssets: 29,
  },
  {
    lat: 38.8218452,
    lng: -77.15426839999999,
    squareFootage: "65610",
    propertyType: "Retail",
    totalCosts: "29250000",
    valueOfReclassifiedAssets: 4885524,
    depreciableAssets: 26,
  },
  {
    lat: 38.9451658,
    lng: -77.0622028,
    squareFootage: "1950",
    propertyType: "Retail",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 243236,
    depreciableAssets: 14,
  },
  {
    lat: 38.9457197,
    lng: -77.2979782,
    squareFootage: "40833",
    propertyType: "Retail",
    totalCosts: "6555750",
    valueOfReclassifiedAssets: 351682,
    depreciableAssets: 26,
  },
  {
    lat: 42.339904,
    lng: -71.0898892,
    squareFootage: "3408",
    propertyType: "Multifamily",
    totalCosts: "3575000",
    valueOfReclassifiedAssets: 278093,
    depreciableAssets: 13,
  },
  {
    lat: 40.3635408,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13740111",
    valueOfReclassifiedAssets: 12274649,
    depreciableAssets: 16,
  },
  {
    lat: 33.6226176,
    lng: -86.64599439999999,
    squareFootage: "4436",
    propertyType: "Specialty",
    totalCosts: "1301000",
    valueOfReclassifiedAssets: 611023,
    depreciableAssets: 55,
  },
  {
    lat: 33.6226176,
    lng: -86.64599439999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1734331",
    valueOfReclassifiedAssets: 245024,
    depreciableAssets: 23,
  },
  {
    lat: 33.6226176,
    lng: -86.64599439999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3553522",
    valueOfReclassifiedAssets: 3328773,
    depreciableAssets: 41,
  },
  {
    lat: 35.9613014,
    lng: -83.1863358,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "255387",
    valueOfReclassifiedAssets: 191796,
    depreciableAssets: 30,
  },
  {
    lat: 36.5421738,
    lng: -82.10606829999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1772664",
    valueOfReclassifiedAssets: 1331777,
    depreciableAssets: 29,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "274104",
    propertyType: "Multifamily",
    totalCosts: "14600000",
    valueOfReclassifiedAssets: 3379853,
    depreciableAssets: 28,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "103500",
    propertyType: "Multifamily",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 1623410,
    depreciableAssets: 31,
  },
  {
    lat: 36.147526,
    lng: -95.85121,
    squareFootage: "133201",
    propertyType: "Multifamily",
    totalCosts: "7050000",
    valueOfReclassifiedAssets: 1960547,
    depreciableAssets: 33,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "127176",
    propertyType: "Multifamily",
    totalCosts: "8243000",
    valueOfReclassifiedAssets: 2257407,
    depreciableAssets: 32,
  },
  {
    lat: 36.0613546,
    lng: -95.9515399,
    squareFootage: "377536",
    propertyType: "Multifamily",
    totalCosts: "27000000",
    valueOfReclassifiedAssets: 7820396,
    depreciableAssets: 34,
  },
  {
    lat: 36.147526,
    lng: -95.85121,
    squareFootage: "295400",
    propertyType: "Multifamily",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 3611746,
    depreciableAssets: 30,
  },
  {
    lat: 36.1933771,
    lng: -95.9092754,
    squareFootage: "67510",
    propertyType: "Multifamily",
    totalCosts: "2709000",
    valueOfReclassifiedAssets: 635654,
    depreciableAssets: 28,
  },
  {
    lat: 36.1036376,
    lng: -95.97268319999999,
    squareFootage: "82110",
    propertyType: "Multifamily",
    totalCosts: "5757000",
    valueOfReclassifiedAssets: 1780039,
    depreciableAssets: 36,
  },
  {
    lat: 30.7819709,
    lng: -84.0378894,
    squareFootage: "127777",
    propertyType: "Multifamily",
    totalCosts: "8600000",
    valueOfReclassifiedAssets: 2568142,
    depreciableAssets: 35,
  },
  {
    lat: 36.1426113,
    lng: -80.33245140000001,
    squareFootage: "112378",
    propertyType: "Multifamily",
    totalCosts: "21371579",
    valueOfReclassifiedAssets: 7055651,
    depreciableAssets: 35,
  },
  {
    lat: 40.755322,
    lng: -73.9932872,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "63500969",
    valueOfReclassifiedAssets: 54812009,
    depreciableAssets: 15,
  },
  {
    lat: 40.8723987,
    lng: -73.8713099,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "30474508",
    valueOfReclassifiedAssets: 27118419,
    depreciableAssets: 15,
  },
  {
    lat: 40.8197331,
    lng: -74.0035709,
    squareFootage: "236558",
    propertyType: "Multifamily",
    totalCosts: "43000000",
    valueOfReclassifiedAssets: 4950220,
    depreciableAssets: 13,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "42720",
    propertyType: "Multifamily",
    totalCosts: "4614146",
    valueOfReclassifiedAssets: 734403,
    depreciableAssets: 19,
  },
  {
    lat: 40.9203906,
    lng: -74.00063279999999,
    squareFootage: "22386",
    propertyType: "Multifamily",
    totalCosts: "3939905",
    valueOfReclassifiedAssets: 3439783,
    depreciableAssets: 18,
  },
  {
    lat: 40.8248063,
    lng: -74.10931409999999,
    squareFootage: "53325",
    propertyType: "Multifamily",
    totalCosts: "6430000",
    valueOfReclassifiedAssets: 838459,
    depreciableAssets: 17,
  },
  {
    lat: 36.0595159,
    lng: -95.8858873,
    squareFootage: "105000",
    propertyType: "Multifamily",
    totalCosts: "8170599",
    valueOfReclassifiedAssets: 1261777,
    depreciableAssets: 20,
  },
  {
    lat: 35.4485209,
    lng: -97.6602365,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1041069",
    valueOfReclassifiedAssets: 886453,
    depreciableAssets: 37,
  },
  {
    lat: 33.6275483,
    lng: -112.0595603,
    squareFootage: "17160",
    propertyType: "Retail",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 935179,
    depreciableAssets: 34,
  },
  {
    lat: 45.6762725,
    lng: -122.6570795,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 281285,
    depreciableAssets: 37,
  },
  {
    lat: 29.938885,
    lng: -95.39919069999999,
    squareFootage: "212509",
    propertyType: "Multifamily",
    totalCosts: "23350000",
    valueOfReclassifiedAssets: 5452437,
    depreciableAssets: 28,
  },
  {
    lat: 34.2745511,
    lng: -84.5012435,
    squareFootage: "55693",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10616300",
    valueOfReclassifiedAssets: 3592911,
    depreciableAssets: 37,
  },
  {
    lat: 34.2783099,
    lng: -83.8791074,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11159530",
    valueOfReclassifiedAssets: 8832384,
    depreciableAssets: 36,
  },
  {
    lat: 40.9245036,
    lng: -74.41738769999999,
    squareFootage: "21500",
    propertyType: "Industrial",
    totalCosts: "2394000",
    valueOfReclassifiedAssets: 459445,
    depreciableAssets: 23,
  },
  {
    lat: 38.9498082,
    lng: -76.944141,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9276000",
    valueOfReclassifiedAssets: 8054232,
    depreciableAssets: 28,
  },
  {
    lat: 41.9203468,
    lng: -87.6433139,
    squareFootage: "10000",
    propertyType: "Specialty",
    totalCosts: "2380918",
    valueOfReclassifiedAssets: 441854,
    depreciableAssets: 22,
  },
  {
    lat: 41.8339435,
    lng: -87.79108910000001,
    squareFootage: "10432",
    propertyType: "Office",
    totalCosts: "2486045",
    valueOfReclassifiedAssets: 617943,
    depreciableAssets: 29,
  },
  {
    lat: 40.890298,
    lng: -74.74541119999999,
    squareFootage: "48000",
    propertyType: "Industrial",
    totalCosts: "1981662",
    valueOfReclassifiedAssets: 367454,
    depreciableAssets: 22,
  },
  {
    lat: 34.7787365,
    lng: -84.9193082,
    squareFootage: "1827",
    propertyType: "Retail",
    totalCosts: "1815126",
    valueOfReclassifiedAssets: 331382,
    depreciableAssets: 22,
  },
  {
    lat: 32.95172480000001,
    lng: -80.1986649,
    squareFootage: "2066",
    propertyType: "Specialty",
    totalCosts: "2485436",
    valueOfReclassifiedAssets: 1082397,
    depreciableAssets: 52,
  },
  {
    lat: 32.5662435,
    lng: -92.02731899999999,
    squareFootage: "6211",
    propertyType: "Retail",
    totalCosts: "1054507",
    valueOfReclassifiedAssets: 260560,
    depreciableAssets: 31,
  },
  {
    lat: 32.5729402,
    lng: -85.58179109999999,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "1323000",
    valueOfReclassifiedAssets: 321719,
    depreciableAssets: 30,
  },
  {
    lat: 29.94513229999999,
    lng: -93.9113634,
    squareFootage: "86911",
    propertyType: "Multifamily",
    totalCosts: "5360000",
    valueOfReclassifiedAssets: 878416,
    depreciableAssets: 34,
  },
  {
    lat: 33.620038,
    lng: -84.5327038,
    squareFootage: "460975",
    propertyType: "Multifamily",
    totalCosts: "19650480",
    valueOfReclassifiedAssets: 4646063,
    depreciableAssets: 28,
  },
  {
    lat: 31.8586994,
    lng: -106.5466221,
    squareFootage: "150283",
    propertyType: "Multifamily",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 1581204,
    depreciableAssets: 29,
  },
  {
    lat: 33.8363126,
    lng: -84.38574419999999,
    squareFootage: "87102",
    propertyType: "Multifamily",
    totalCosts: "16138000",
    valueOfReclassifiedAssets: 3531530,
    depreciableAssets: 26,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "203934",
    propertyType: "Multifamily",
    totalCosts: "18150000",
    valueOfReclassifiedAssets: 4796139,
    depreciableAssets: 31,
  },
  {
    lat: 41.1024994,
    lng: -85.1220031,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6829880",
    valueOfReclassifiedAssets: 5480662,
    depreciableAssets: 24,
  },
  {
    lat: 33.2412809,
    lng: -96.80294889999999,
    squareFootage: "2563",
    propertyType: "Office",
    totalCosts: "630000",
    valueOfReclassifiedAssets: 113789,
    depreciableAssets: 21,
  },
  {
    lat: 33.2412809,
    lng: -96.80294889999999,
    squareFootage: "2853",
    propertyType: "Office",
    totalCosts: "445000",
    valueOfReclassifiedAssets: 191605,
    depreciableAssets: 50,
  },
  {
    lat: 32.8329317,
    lng: -97.14401459999999,
    squareFootage: "122752",
    propertyType: "Multifamily",
    totalCosts: "12320000",
    valueOfReclassifiedAssets: 4160464,
    depreciableAssets: 39,
  },
  {
    lat: 32.8162802,
    lng: -97.1898025,
    squareFootage: "191331",
    propertyType: "Multifamily",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 5306907,
    depreciableAssets: 37,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "39925",
    propertyType: "Multifamily",
    totalCosts: "8350000",
    valueOfReclassifiedAssets: 776215,
    depreciableAssets: 12,
  },
  {
    lat: 38.683571,
    lng: -85.8281458,
    squareFootage: "187823",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 896547,
    depreciableAssets: 21,
  },
  {
    lat: 42.0039336,
    lng: -87.7344988,
    squareFootage: "9780",
    propertyType: "Office",
    totalCosts: "973463",
    valueOfReclassifiedAssets: 141799,
    depreciableAssets: 17,
  },
  {
    lat: 36.3840171,
    lng: -94.21283369999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 19380067,
    depreciableAssets: 33,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "174252",
    propertyType: "Office",
    totalCosts: "32300000",
    valueOfReclassifiedAssets: 6049470,
    depreciableAssets: 22,
  },
  {
    lat: 31.3299729,
    lng: -89.41746409999999,
    squareFootage: "145371",
    propertyType: "Multifamily",
    totalCosts: "7475000",
    valueOfReclassifiedAssets: 1768348,
    depreciableAssets: 28,
  },
  {
    lat: 28.976066,
    lng: -95.6037465,
    squareFootage: "15953",
    propertyType: "Multifamily",
    totalCosts: "1016000",
    valueOfReclassifiedAssets: 229001,
    depreciableAssets: 27,
  },
  {
    lat: 34.0748887,
    lng: -118.3082034,
    squareFootage: "15575",
    propertyType: "Multifamily",
    totalCosts: "7375000",
    valueOfReclassifiedAssets: 1872220,
    depreciableAssets: 34,
  },
  {
    lat: 28.37512,
    lng: -80.7380716,
    squareFootage: "47030",
    propertyType: "Multifamily",
    totalCosts: "3275000",
    valueOfReclassifiedAssets: 729097,
    depreciableAssets: 26,
  },
  {
    lat: 25.8558416,
    lng: -80.1819268,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14851412",
    valueOfReclassifiedAssets: 13286127,
    depreciableAssets: 13,
  },
  {
    lat: 40.9413523,
    lng: -73.8713099,
    squareFootage: "18933",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 299494,
    depreciableAssets: 10,
  },
  {
    lat: 32.7011609,
    lng: -97.3354531,
    squareFootage: "13984",
    propertyType: "Multifamily",
    totalCosts: "2050000",
    valueOfReclassifiedAssets: 659562,
    depreciableAssets: 38,
  },
  {
    lat: 32.737615,
    lng: -97.38407289999999,
    squareFootage: "18293",
    propertyType: "Multifamily",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 790394,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "584445",
    propertyType: "Retail",
    totalCosts: "3162105",
    valueOfReclassifiedAssets: 900145,
    depreciableAssets: 33,
  },
  {
    lat: 37.7100771,
    lng: -77.57340479999999,
    squareFootage: "785089",
    propertyType: "Office",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2383165,
    depreciableAssets: 30,
  },
  {
    lat: 41.459605,
    lng: -75.6731734,
    squareFootage: "18560",
    propertyType: "Retail",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 447569,
    depreciableAssets: 29,
  },
  {
    lat: 41.655593,
    lng: -81.444379,
    squareFootage: "105000",
    propertyType: "Industrial",
    totalCosts: "2131419",
    valueOfReclassifiedAssets: 321741,
    depreciableAssets: 21,
  },
  {
    lat: 40.248276,
    lng: -85.6640365,
    squareFootage: "108662",
    propertyType: "Multifamily",
    totalCosts: "3250000",
    valueOfReclassifiedAssets: 676099,
    depreciableAssets: 25,
  },
  {
    lat: 36.1129513,
    lng: -95.7826597,
    squareFootage: "169472",
    propertyType: "Multifamily",
    totalCosts: "8419740",
    valueOfReclassifiedAssets: 2590001,
    depreciableAssets: 37,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "180415",
    propertyType: "Multifamily",
    totalCosts: "9823530",
    valueOfReclassifiedAssets: 3112099,
    depreciableAssets: 37,
  },
  {
    lat: 38.992935,
    lng: -94.55212739999999,
    squareFootage: "347457",
    propertyType: "Multifamily",
    totalCosts: "15350000",
    valueOfReclassifiedAssets: 3800249,
    depreciableAssets: 29,
  },
  {
    lat: 38.9546734,
    lng: -94.5985613,
    squareFootage: "32898",
    propertyType: "Multifamily",
    totalCosts: "2134615",
    valueOfReclassifiedAssets: 430325,
    depreciableAssets: 24,
  },
  {
    lat: 38.9546734,
    lng: -94.5985613,
    squareFootage: "32792",
    propertyType: "Multifamily",
    totalCosts: "2365385",
    valueOfReclassifiedAssets: 417623,
    depreciableAssets: 21,
  },
  {
    lat: 35.1322706,
    lng: -106.4994889,
    squareFootage: "422854",
    propertyType: "Multifamily",
    totalCosts: "43000000",
    valueOfReclassifiedAssets: 11112133,
    depreciableAssets: 30,
  },
  {
    lat: 39.282939,
    lng: -94.5727598,
    squareFootage: "568279",
    propertyType: "Multifamily",
    totalCosts: "20500000",
    valueOfReclassifiedAssets: 4124581,
    depreciableAssets: 23,
  },
  {
    lat: 39.282939,
    lng: -94.5727598,
    squareFootage: "2019",
    propertyType: "Multifamily",
    totalCosts: "38500000",
    valueOfReclassifiedAssets: 6677516,
    depreciableAssets: 20,
  },
  {
    lat: 33.4767802,
    lng: -117.1970557,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "678542",
    valueOfReclassifiedAssets: 458933,
    depreciableAssets: 0,
  },
  {
    lat: 30.1098349,
    lng: -95.5827334,
    squareFootage: "48896",
    propertyType: "Multifamily",
    totalCosts: "3450000",
    valueOfReclassifiedAssets: 1030529,
    depreciableAssets: 34,
  },
  {
    lat: 29.8760239,
    lng: -95.5249863,
    squareFootage: "55680",
    propertyType: "Multifamily",
    totalCosts: "3650000",
    valueOfReclassifiedAssets: 1082147,
    depreciableAssets: 35,
  },
  {
    lat: 42.3691884,
    lng: -83.3682539,
    squareFootage: "39905",
    propertyType: "Industrial",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 284696,
    depreciableAssets: 18,
  },
  {
    lat: 39.3614334,
    lng: -76.5892774,
    squareFootage: "111884",
    propertyType: "Retail",
    totalCosts: "18180000",
    valueOfReclassifiedAssets: 3805461,
    depreciableAssets: 25,
  },
  {
    lat: 44.0431493,
    lng: -123.2259181,
    squareFootage: "102240",
    propertyType: "Industrial",
    totalCosts: "6697585",
    valueOfReclassifiedAssets: 761044,
    depreciableAssets: 14,
  },
  {
    lat: 25.9739925,
    lng: -97.33005299999999,
    squareFootage: "2020",
    propertyType: "Industrial",
    totalCosts: "2564569",
    valueOfReclassifiedAssets: 416047,
    depreciableAssets: 19,
  },
  {
    lat: 35.5057603,
    lng: -108.7600963,
    squareFootage: "48202",
    propertyType: "Retail",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1307218,
    depreciableAssets: 30,
  },
  {
    lat: 32.3243856,
    lng: -95.29979589999999,
    squareFootage: "98306",
    propertyType: "Multifamily",
    totalCosts: "6075000",
    valueOfReclassifiedAssets: 1239194,
    depreciableAssets: 31,
  },
  {
    lat: 32.9696102,
    lng: -96.3319253,
    squareFootage: "45000",
    propertyType: "Industrial",
    totalCosts: "3340400",
    valueOfReclassifiedAssets: 1143539,
    depreciableAssets: 37,
  },
  {
    lat: 38.9052763,
    lng: -76.98158769999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "93418657",
    valueOfReclassifiedAssets: 79321996,
    depreciableAssets: 16,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "416098",
    propertyType: "Multifamily",
    totalCosts: "12280027",
    valueOfReclassifiedAssets: 2664580,
    depreciableAssets: 23,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "416098",
    propertyType: "Multifamily",
    totalCosts: "19935529",
    valueOfReclassifiedAssets: 4249261,
    depreciableAssets: 23,
  },
  {
    lat: 27.5073732,
    lng: -82.63754019999999,
    squareFootage: "226716",
    propertyType: "Multifamily",
    totalCosts: "30918212",
    valueOfReclassifiedAssets: 8672007,
    depreciableAssets: 33,
  },
  {
    lat: 30.2949194,
    lng: -81.63638689999999,
    squareFootage: "134718",
    propertyType: "Multifamily",
    totalCosts: "8226951",
    valueOfReclassifiedAssets: 2170622,
    depreciableAssets: 30,
  },
  {
    lat: 34.6354859,
    lng: -82.92864279999999,
    squareFootage: "134700",
    propertyType: "Multifamily",
    totalCosts: "8817422",
    valueOfReclassifiedAssets: 2281067,
    depreciableAssets: 29,
  },
  {
    lat: 32.590481,
    lng: -83.624201,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "443557",
    valueOfReclassifiedAssets: 385425,
    depreciableAssets: 25,
  },
  {
    lat: 32.590481,
    lng: -83.624201,
    squareFootage: "109902",
    propertyType: "Multifamily",
    totalCosts: "7861452",
    valueOfReclassifiedAssets: 1912025,
    depreciableAssets: 26,
  },
  {
    lat: 33.4647333,
    lng: -82.0733985,
    squareFootage: "159456",
    propertyType: "Multifamily",
    totalCosts: "10437756",
    valueOfReclassifiedAssets: 2272049,
    depreciableAssets: 27,
  },
  {
    lat: 32.5371089,
    lng: -84.9297206,
    squareFootage: "367939",
    propertyType: "Multifamily",
    totalCosts: "32635769",
    valueOfReclassifiedAssets: 9190185,
    depreciableAssets: 33,
  },
  {
    lat: 35.8906718,
    lng: -84.3331694,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6356072",
    valueOfReclassifiedAssets: 4762421,
    depreciableAssets: 35,
  },
  {
    lat: 28.0473767,
    lng: -82.3611066,
    squareFootage: "273860",
    propertyType: "Multifamily",
    totalCosts: "54250000",
    valueOfReclassifiedAssets: 11192141,
    depreciableAssets: 25,
  },
  {
    lat: 39.3539395,
    lng: -87.4009165,
    squareFootage: "679718",
    propertyType: "Retail",
    totalCosts: "14600000",
    valueOfReclassifiedAssets: 3212352,
    depreciableAssets: 26,
  },
  {
    lat: 35.9980959,
    lng: -96.13149279999999,
    squareFootage: "304257",
    propertyType: "Retail",
    totalCosts: "4819425",
    valueOfReclassifiedAssets: 3804986,
    depreciableAssets: 27,
  },
  {
    lat: 40.7702489,
    lng: -82.57268049999999,
    squareFootage: "201170",
    propertyType: "Retail",
    totalCosts: "7568060",
    valueOfReclassifiedAssets: 1657572,
    depreciableAssets: 26,
  },
  {
    lat: 33.9025669,
    lng: -84.25421949999999,
    squareFootage: "204538",
    propertyType: "Multifamily",
    totalCosts: "15900000",
    valueOfReclassifiedAssets: 3652302,
    depreciableAssets: 27,
  },
  {
    lat: 33.9851868,
    lng: -84.238417,
    squareFootage: "558753",
    propertyType: "Multifamily",
    totalCosts: "51200000",
    valueOfReclassifiedAssets: 12879624,
    depreciableAssets: 30,
  },
  {
    lat: 33.9025669,
    lng: -84.25421949999999,
    squareFootage: "560524",
    propertyType: "Multifamily",
    totalCosts: "63500000",
    valueOfReclassifiedAssets: 18261176,
    depreciableAssets: 34,
  },
  {
    lat: 33.9957883,
    lng: -84.46976629999999,
    squareFootage: "180652",
    propertyType: "Multifamily",
    totalCosts: "18300000",
    valueOfReclassifiedAssets: 4868913,
    depreciableAssets: 31,
  },
  {
    lat: 34.0586259,
    lng: -84.38574419999999,
    squareFootage: "503364",
    propertyType: "Multifamily",
    totalCosts: "57000000",
    valueOfReclassifiedAssets: 15498394,
    depreciableAssets: 32,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "185244",
    propertyType: "Multifamily",
    totalCosts: "20600000",
    valueOfReclassifiedAssets: 5110737,
    depreciableAssets: 30,
  },
  {
    lat: 33.2195187,
    lng: -97.1898025,
    squareFootage: "143400",
    propertyType: "Multifamily",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 4703425,
    depreciableAssets: 32,
  },
  {
    lat: 29.8296506,
    lng: -95.6668306,
    squareFootage: "134072",
    propertyType: "Multifamily",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 3027253,
    depreciableAssets: 29,
  },
  {
    lat: 33.526533,
    lng: -94.1309067,
    squareFootage: "157942",
    propertyType: "Multifamily",
    totalCosts: "5175000",
    valueOfReclassifiedAssets: 1359233,
    depreciableAssets: 31,
  },
  {
    lat: 34.2249609,
    lng: -118.6556478,
    squareFootage: "17376",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1171807,
    depreciableAssets: 33,
  },
  {
    lat: 34.1482691,
    lng: -118.4338345,
    squareFootage: "30561",
    propertyType: "Multifamily",
    totalCosts: "7700000",
    valueOfReclassifiedAssets: 1350061,
    depreciableAssets: 25,
  },
  {
    lat: 34.2252697,
    lng: -118.442596,
    squareFootage: "13298",
    propertyType: "Multifamily",
    totalCosts: "4450000",
    valueOfReclassifiedAssets: 782929,
    depreciableAssets: 25,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "36730",
    propertyType: "Multifamily",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 2109337,
    depreciableAssets: 32,
  },
  {
    lat: 34.2249609,
    lng: -118.6556478,
    squareFootage: "14090",
    propertyType: "Multifamily",
    totalCosts: "4710000",
    valueOfReclassifiedAssets: 1130126,
    depreciableAssets: 34,
  },
  {
    lat: 42.4966195,
    lng: -83.2880591,
    squareFootage: "252978",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 2384653,
    depreciableAssets: 23,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1301717",
    valueOfReclassifiedAssets: 980418,
    depreciableAssets: 0,
  },
  {
    lat: 40.9087956,
    lng: -73.83308439999999,
    squareFootage: "5250",
    propertyType: "Multifamily",
    totalCosts: "750000",
    valueOfReclassifiedAssets: 133457,
    depreciableAssets: 21,
  },
  {
    lat: 40.9087956,
    lng: -73.83308439999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29840",
    valueOfReclassifiedAssets: 19480,
    depreciableAssets: 0,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "266398",
    propertyType: "Multifamily",
    totalCosts: "19000000",
    valueOfReclassifiedAssets: 3925923,
    depreciableAssets: 23,
  },
  {
    lat: 45.4796668,
    lng: -122.5146061,
    squareFootage: "5",
    propertyType: "Multifamily",
    totalCosts: "11125000",
    valueOfReclassifiedAssets: 3211773,
    depreciableAssets: 34,
  },
  {
    lat: 36.0802326,
    lng: -86.8320743,
    squareFootage: "75413",
    propertyType: "Office",
    totalCosts: "12600000",
    valueOfReclassifiedAssets: 2178173,
    depreciableAssets: 20,
  },
  {
    lat: 35.1212908,
    lng: -89.8551818,
    squareFootage: "172060",
    propertyType: "Office",
    totalCosts: "19731000",
    valueOfReclassifiedAssets: 3417614,
    depreciableAssets: 20,
  },
  {
    lat: 39.0041504,
    lng: -77.03917539999999,
    squareFootage: "11250",
    propertyType: "Office",
    totalCosts: "2042680",
    valueOfReclassifiedAssets: 233562,
    depreciableAssets: 16,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1044755",
    valueOfReclassifiedAssets: 929532,
    depreciableAssets: 18,
  },
  {
    lat: 44.28092729999999,
    lng: -72.61950759999999,
    squareFootage: "16705",
    propertyType: "Office",
    totalCosts: "600000",
    valueOfReclassifiedAssets: 101896,
    depreciableAssets: 20,
  },
  {
    lat: 44.2019646,
    lng: -72.50846779999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "550000",
    valueOfReclassifiedAssets: 362218,
    depreciableAssets: 0,
  },
  {
    lat: 44.3364912,
    lng: -72.7551182,
    squareFootage: "197767",
    propertyType: "Industrial",
    totalCosts: "8250000",
    valueOfReclassifiedAssets: 2395804,
    depreciableAssets: 34,
  },
  {
    lat: 40.60349830000001,
    lng: -73.7183639,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 916840,
    depreciableAssets: 32,
  },
  {
    lat: 40.3749332,
    lng: -74.49359129999999,
    squareFootage: "121361",
    propertyType: "Industrial",
    totalCosts: "10350000",
    valueOfReclassifiedAssets: 1592832,
    depreciableAssets: 19,
  },
  {
    lat: 34.050751,
    lng: -118.3841777,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9288184",
    valueOfReclassifiedAssets: 7486206,
    depreciableAssets: 20,
  },
  {
    lat: 33.8537662,
    lng: -84.7837717,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12885679",
    valueOfReclassifiedAssets: 2596162,
    depreciableAssets: 23,
  },
  {
    lat: 30.5099431,
    lng: -84.3647199,
    squareFootage: "118840",
    propertyType: "Multifamily",
    totalCosts: "10532005",
    valueOfReclassifiedAssets: 2599880,
    depreciableAssets: 29,
  },
  {
    lat: 40.8029914,
    lng: -74.2531465,
    squareFootage: "24360",
    propertyType: "Retail",
    totalCosts: "2792377",
    valueOfReclassifiedAssets: 436372,
    depreciableAssets: 23,
  },
  {
    lat: 32.97602940000001,
    lng: -96.7386946,
    squareFootage: "104415",
    propertyType: "Multifamily",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 2860909,
    depreciableAssets: 30,
  },
  {
    lat: 32.94464350000001,
    lng: -85.9100089,
    squareFootage: "27176",
    propertyType: "Multifamily",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 307649,
    depreciableAssets: 29,
  },
  {
    lat: 35.5821649,
    lng: -88.8455037,
    squareFootage: "41416",
    propertyType: "Multifamily",
    totalCosts: "875000",
    valueOfReclassifiedAssets: 202556,
    depreciableAssets: 28,
  },
  {
    lat: 35.5821649,
    lng: -88.8455037,
    squareFootage: "74025",
    propertyType: "Multifamily",
    totalCosts: "1900000",
    valueOfReclassifiedAssets: 423260,
    depreciableAssets: 26,
  },
  {
    lat: 35.4053803,
    lng: -88.6916315,
    squareFootage: "46472",
    propertyType: "Multifamily",
    totalCosts: "1494000",
    valueOfReclassifiedAssets: 355201,
    depreciableAssets: 28,
  },
  {
    lat: 35.2571148,
    lng: -89.3606298,
    squareFootage: "43175",
    propertyType: "Multifamily",
    totalCosts: "955000",
    valueOfReclassifiedAssets: 251690,
    depreciableAssets: 31,
  },
  {
    lat: 36.2942119,
    lng: -88.28504199999999,
    squareFootage: "33871",
    propertyType: "Multifamily",
    totalCosts: "1220000",
    valueOfReclassifiedAssets: 287885,
    depreciableAssets: 28,
  },
  {
    lat: 36.1970612,
    lng: -89.5308903,
    squareFootage: "38800",
    propertyType: "Multifamily",
    totalCosts: "1056000",
    valueOfReclassifiedAssets: 285402,
    depreciableAssets: 32,
  },
  {
    lat: 33.6523096,
    lng: -86.7114545,
    squareFootage: "57552",
    propertyType: "Multifamily",
    totalCosts: "1580000",
    valueOfReclassifiedAssets: 352343,
    depreciableAssets: 26,
  },
  {
    lat: 33.389869,
    lng: -86.9523954,
    squareFootage: "46825",
    propertyType: "Multifamily",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 301155,
    depreciableAssets: 32,
  },
  {
    lat: 33.4480108,
    lng: -86.9248483,
    squareFootage: "31775",
    propertyType: "Multifamily",
    totalCosts: "950000",
    valueOfReclassifiedAssets: 235741,
    depreciableAssets: 27,
  },
  {
    lat: 32.4569381,
    lng: -88.22664340000001,
    squareFootage: "54835",
    propertyType: "Multifamily",
    totalCosts: "1550000",
    valueOfReclassifiedAssets: 449995,
    depreciableAssets: 30,
  },
  {
    lat: 33.6431187,
    lng: -87.8156715,
    squareFootage: "45798",
    propertyType: "Multifamily",
    totalCosts: "1650000",
    valueOfReclassifiedAssets: 481374,
    depreciableAssets: 30,
  },
  {
    lat: 33.5675257,
    lng: -86.8922723,
    squareFootage: "114868",
    propertyType: "Multifamily",
    totalCosts: "2570000",
    valueOfReclassifiedAssets: 566391,
    depreciableAssets: 26,
  },
  {
    lat: 40.6488134,
    lng: -73.5535234,
    squareFootage: "17714",
    propertyType: "Office",
    totalCosts: "10585813",
    valueOfReclassifiedAssets: 1333605,
    depreciableAssets: 16,
  },
  {
    lat: 31.9708128,
    lng: -81.15865149999999,
    squareFootage: "51949",
    propertyType: "Hotel",
    totalCosts: "4485191",
    valueOfReclassifiedAssets: 1559778,
    depreciableAssets: 41,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "19833",
    propertyType: "Office",
    totalCosts: "2301042",
    valueOfReclassifiedAssets: 374380,
    depreciableAssets: 19,
  },
  {
    lat: 40.9292038,
    lng: -74.70444250000001,
    squareFootage: "105000",
    propertyType: "Industrial",
    totalCosts: "1675000",
    valueOfReclassifiedAssets: 156694,
    depreciableAssets: 11,
  },
  {
    lat: 47.1616994,
    lng: -122.5019796,
    squareFootage: "19884",
    propertyType: "Multifamily",
    totalCosts: "2350000",
    valueOfReclassifiedAssets: 481207,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "47253",
    propertyType: "Multifamily",
    totalCosts: "1475000",
    valueOfReclassifiedAssets: 385408,
    depreciableAssets: 30,
  },
  {
    lat: 38.9274061,
    lng: -76.9729472,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2839429",
    valueOfReclassifiedAssets: 1594330,
    depreciableAssets: 40,
  },
  {
    lat: 40.7750791,
    lng: -73.9932872,
    squareFootage: "2114",
    propertyType: "Multifamily",
    totalCosts: "5811766",
    valueOfReclassifiedAssets: 4940001,
    depreciableAssets: 100,
  },
  {
    lat: 38.9684867,
    lng: -95.37301760000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 1996778,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "303650",
    valueOfReclassifiedAssets: 119766,
    depreciableAssets: 74,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "305150",
    valueOfReclassifiedAssets: 159585,
    depreciableAssets: 70,
  },
  {
    lat: 48.0563616,
    lng: -122.1492224,
    squareFootage: "77457",
    propertyType: "Hotel",
    totalCosts: "17400000",
    valueOfReclassifiedAssets: 3378018,
    depreciableAssets: 23,
  },
  {
    lat: 39.5116069,
    lng: -76.6990172,
    squareFootage: "128675",
    propertyType: "Office",
    totalCosts: "42289891",
    valueOfReclassifiedAssets: 5698139,
    depreciableAssets: 17,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "90000",
    propertyType: "Office",
    totalCosts: "7200000",
    valueOfReclassifiedAssets: 758026,
    depreciableAssets: 12,
  },
  {
    lat: 34.0664817,
    lng: -118.3520389,
    squareFootage: "4804",
    propertyType: "Multifamily",
    totalCosts: "1724811",
    valueOfReclassifiedAssets: 374499,
    depreciableAssets: 25,
  },
  {
    lat: 35.3810878,
    lng: -97.51930929999999,
    squareFootage: "179095",
    propertyType: "Multifamily",
    totalCosts: "23000000",
    valueOfReclassifiedAssets: 3494084,
    depreciableAssets: 17,
  },
  {
    lat: 32.6614911,
    lng: -97.1467072,
    squareFootage: "17925",
    propertyType: "Retail",
    totalCosts: "3010000",
    valueOfReclassifiedAssets: 871924,
    depreciableAssets: 34,
  },
  {
    lat: 40.6843079,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12952074",
    valueOfReclassifiedAssets: 10839625,
    depreciableAssets: 19,
  },
  {
    lat: 38.8095735,
    lng: -90.3465971,
    squareFootage: "30768",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "3104052",
    valueOfReclassifiedAssets: 778025,
    depreciableAssets: 30,
  },
  {
    lat: 40.7496608,
    lng: -75.0260667,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1735000",
    valueOfReclassifiedAssets: 424194,
    depreciableAssets: 29,
  },
  {
    lat: 41.8940762,
    lng: -87.8205864,
    squareFootage: "84155",
    propertyType: "Multifamily",
    totalCosts: "10550000",
    valueOfReclassifiedAssets: 2433729,
    depreciableAssets: 27,
  },
  {
    lat: 27.8219579,
    lng: -82.7401087,
    squareFootage: "71910",
    propertyType: "Multifamily",
    totalCosts: "8448000",
    valueOfReclassifiedAssets: 2160981,
    depreciableAssets: 30,
  },
  {
    lat: 28.2333343,
    lng: -82.7023383,
    squareFootage: "443430",
    propertyType: "Multifamily",
    totalCosts: "9633482",
    valueOfReclassifiedAssets: 1469799,
    depreciableAssets: 18,
  },
  {
    lat: 28.6820373,
    lng: -81.26289609999999,
    squareFootage: "186284",
    propertyType: "Multifamily",
    totalCosts: "32500000",
    valueOfReclassifiedAssets: 10302145,
    depreciableAssets: 37,
  },
  {
    lat: 28.0307138,
    lng: -82.3994214,
    squareFootage: "286992",
    propertyType: "Multifamily",
    totalCosts: "43000000",
    valueOfReclassifiedAssets: 9370081,
    depreciableAssets: 25,
  },
  {
    lat: 33.159946,
    lng: -117.2852467,
    squareFootage: "163237",
    propertyType: "Industrial",
    totalCosts: "15040264",
    valueOfReclassifiedAssets: 13973499,
    depreciableAssets: 20,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "25000",
    propertyType: "Multifamily",
    totalCosts: "924147",
    valueOfReclassifiedAssets: 263287,
    depreciableAssets: 32,
  },
  {
    lat: 34.5294947,
    lng: -82.6267345,
    squareFootage: "4000",
    propertyType: "Multifamily",
    totalCosts: "133101",
    valueOfReclassifiedAssets: 34471,
    depreciableAssets: 29,
  },
  {
    lat: 41.4836446,
    lng: -81.806039,
    squareFootage: "57765",
    propertyType: "Multifamily",
    totalCosts: "2360000",
    valueOfReclassifiedAssets: 361137,
    depreciableAssets: 18,
  },
  {
    lat: 42.8525821,
    lng: -70.94279139999999,
    squareFootage: "38368",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11150000",
    valueOfReclassifiedAssets: 2567831,
    depreciableAssets: 27,
  },
  {
    lat: 41.3469288,
    lng: -72.9047136,
    squareFootage: "54436",
    propertyType: "Office",
    totalCosts: "6250000",
    valueOfReclassifiedAssets: 1103255,
    depreciableAssets: 19,
  },
  {
    lat: 35.5379452,
    lng: -97.62108029999999,
    squareFootage: "22912",
    propertyType: "Retail",
    totalCosts: "4756000",
    valueOfReclassifiedAssets: 984495,
    depreciableAssets: 23,
  },
  {
    lat: 41.3395325,
    lng: -72.6978931,
    squareFootage: "16591",
    propertyType: "Retail",
    totalCosts: "3444000",
    valueOfReclassifiedAssets: 862703,
    depreciableAssets: 28,
  },
  {
    lat: 35.0734065,
    lng: -106.5760857,
    squareFootage: "246252",
    propertyType: "Multifamily",
    totalCosts: "18500000",
    valueOfReclassifiedAssets: 6632094,
    depreciableAssets: 42,
  },
  {
    lat: 41.3052226,
    lng: -72.92686259999999,
    squareFootage: "17650",
    propertyType: "Office",
    totalCosts: "1075000",
    valueOfReclassifiedAssets: 97332,
    depreciableAssets: 10,
  },
  {
    lat: 41.3052226,
    lng: -72.92686259999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1016000",
    valueOfReclassifiedAssets: 835025,
    depreciableAssets: 0,
  },
  {
    lat: 41.3212912,
    lng: -72.9283391,
    squareFootage: "15750",
    propertyType: "Office",
    totalCosts: "1358732",
    valueOfReclassifiedAssets: 202111,
    depreciableAssets: 18,
  },
  {
    lat: 41.3212912,
    lng: -72.9283391,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11014",
    valueOfReclassifiedAssets: 8173,
    depreciableAssets: 32,
  },
  {
    lat: 41.3212912,
    lng: -72.9283391,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1573930",
    valueOfReclassifiedAssets: 1167923,
    depreciableAssets: 32,
  },
  {
    lat: 41.3212912,
    lng: -72.9283391,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "257239",
    valueOfReclassifiedAssets: 190883,
    depreciableAssets: 32,
  },
  {
    lat: 40.11873509999999,
    lng: -74.7395591,
    squareFootage: "183107",
    propertyType: "Industrial",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 880281,
    depreciableAssets: 40,
  },
  {
    lat: 39.8817088,
    lng: -74.95594799999999,
    squareFootage: "32400",
    propertyType: "Industrial",
    totalCosts: "1890000",
    valueOfReclassifiedAssets: 132301,
    depreciableAssets: 8,
  },
  {
    lat: 36.1568627,
    lng: -80.2432839,
    squareFootage: "72226",
    propertyType: "Multifamily",
    totalCosts: "2070000",
    valueOfReclassifiedAssets: 603003,
    depreciableAssets: 31,
  },
  {
    lat: 36.1426113,
    lng: -80.33245140000001,
    squareFootage: "74624",
    propertyType: "Multifamily",
    totalCosts: "2280000",
    valueOfReclassifiedAssets: 599610,
    depreciableAssets: 29,
  },
  {
    lat: 37.2705776,
    lng: -79.9409555,
    squareFootage: "2800",
    propertyType: "Retail",
    totalCosts: "1410000",
    valueOfReclassifiedAssets: 158372,
    depreciableAssets: 17,
  },
  {
    lat: 32.6059744,
    lng: -117.0441009,
    squareFootage: "50120",
    propertyType: "Multifamily",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 2633221,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "133382",
    valueOfReclassifiedAssets: 116490,
    depreciableAssets: 77,
  },
  {
    lat: 39.2245423,
    lng: -120.0954076,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 835295,
    depreciableAssets: 37,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "532599",
    valueOfReclassifiedAssets: 467301,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "76270",
    valueOfReclassifiedAssets: 33041,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "29375",
    valueOfReclassifiedAssets: 21783,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24981",
    valueOfReclassifiedAssets: 17523,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "839415",
    valueOfReclassifiedAssets: 819629,
    depreciableAssets: 6,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "471772",
    valueOfReclassifiedAssets: 415325,
    depreciableAssets: 13,
  },
  {
    lat: 34.0489277,
    lng: -118.3403506,
    squareFootage: "6078",
    propertyType: "Specialty",
    totalCosts: "2269200",
    valueOfReclassifiedAssets: 696592,
    depreciableAssets: 37,
  },
  {
    lat: 34.0489277,
    lng: -118.3403506,
    squareFootage: "6078",
    propertyType: "Multifamily",
    totalCosts: "2508000",
    valueOfReclassifiedAssets: 769898,
    depreciableAssets: 37,
  },
  {
    lat: 34.2765778,
    lng: -119.1681373,
    squareFootage: "37368",
    propertyType: "Multifamily",
    totalCosts: "9625000",
    valueOfReclassifiedAssets: 2209026,
    depreciableAssets: 27,
  },
  {
    lat: 32.8256427,
    lng: -117.1558867,
    squareFootage: "78355",
    propertyType: "Multifamily",
    totalCosts: "32400000",
    valueOfReclassifiedAssets: 9359437,
    depreciableAssets: 34,
  },
  {
    lat: 38.2315738,
    lng: -122.4693688,
    squareFootage: "27000",
    propertyType: "Multifamily",
    totalCosts: "8375000",
    valueOfReclassifiedAssets: 2466559,
    depreciableAssets: 35,
  },
  {
    lat: 34.1799855,
    lng: -118.4601175,
    squareFootage: "97370",
    propertyType: "Multifamily",
    totalCosts: "4122919",
    valueOfReclassifiedAssets: 1032859,
    depreciableAssets: 29,
  },
  {
    lat: 39.9493801,
    lng: -75.145782,
    squareFootage: "50172",
    propertyType: "Multifamily",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 3912350,
    depreciableAssets: 21,
  },
  {
    lat: 38.5947311,
    lng: -77.3381855,
    squareFootage: "37824",
    propertyType: "Retail",
    totalCosts: "20417870",
    valueOfReclassifiedAssets: 6636952,
    depreciableAssets: 39,
  },
  {
    lat: 39.3514892,
    lng: -76.37530459999999,
    squareFootage: "209851",
    propertyType: "Retail",
    totalCosts: "13675427",
    valueOfReclassifiedAssets: 2367222,
    depreciableAssets: 20,
  },
  {
    lat: 41.8929153,
    lng: -87.63591249999999,
    squareFootage: "43411",
    propertyType: "Office",
    totalCosts: "9660000",
    valueOfReclassifiedAssets: 891731,
    depreciableAssets: 11,
  },
  {
    lat: 41.9403795,
    lng: -87.65318049999999,
    squareFootage: "18520",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 413840,
    depreciableAssets: 23,
  },
  {
    lat: 41.8929153,
    lng: -87.63591249999999,
    squareFootage: "80000",
    propertyType: "Office",
    totalCosts: "18960000",
    valueOfReclassifiedAssets: 2449586,
    depreciableAssets: 15,
  },
  {
    lat: 46.0600227,
    lng: -112.490614,
    squareFootage: "30049",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1438947",
    valueOfReclassifiedAssets: 426127,
    depreciableAssets: 35,
  },
  {
    lat: 48.4106094,
    lng: -114.3352492,
    squareFootage: "38333",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4111277",
    valueOfReclassifiedAssets: 1517400,
    depreciableAssets: 44,
  },
  {
    lat: 33.6301328,
    lng: -117.8721676,
    squareFootage: "12058",
    propertyType: "Specialty",
    totalCosts: "4037500",
    valueOfReclassifiedAssets: 719267,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "235038",
    valueOfReclassifiedAssets: 168999,
    depreciableAssets: 85,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "879964",
    valueOfReclassifiedAssets: 368492,
    depreciableAssets: 51,
  },
  {
    lat: 40.6639181,
    lng: -73.9154069,
    squareFootage: "3198",
    propertyType: "Multifamily",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 174100,
    depreciableAssets: 23,
  },
  {
    lat: 41.4790857,
    lng: -82.09518,
    squareFootage: "251805",
    propertyType: "Multifamily",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 3106925,
    depreciableAssets: 19,
  },
  {
    lat: 31.8586994,
    lng: -106.5466221,
    squareFootage: "210964",
    propertyType: "Multifamily",
    totalCosts: "12650000",
    valueOfReclassifiedAssets: 2916033,
    depreciableAssets: 28,
  },
  {
    lat: 29.9473469,
    lng: -95.0390536,
    squareFootage: "75784",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 1371303,
    depreciableAssets: 31,
  },
  {
    lat: 41.8809434,
    lng: -72.2600655,
    squareFootage: "45000",
    propertyType: "Multifamily",
    totalCosts: "3625000",
    valueOfReclassifiedAssets: 964067,
    depreciableAssets: 32,
  },
  {
    lat: 41.9043315,
    lng: -72.16530759999999,
    squareFootage: "52800",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 972786,
    depreciableAssets: 28,
  },
  {
    lat: 29.422658,
    lng: -98.48699049999999,
    squareFootage: "10077",
    propertyType: "Specialty",
    totalCosts: "3225000",
    valueOfReclassifiedAssets: 934900,
    depreciableAssets: 34,
  },
  {
    lat: 28.4630493,
    lng: -81.46085599999999,
    squareFootage: "87015",
    propertyType: "Multifamily",
    totalCosts: "10283500",
    valueOfReclassifiedAssets: 3130089,
    depreciableAssets: 36,
  },
  {
    lat: 34.7486859,
    lng: -84.9921549,
    squareFootage: "159150",
    propertyType: "Multifamily",
    totalCosts: "10268000",
    valueOfReclassifiedAssets: 2928478,
    depreciableAssets: 32,
  },
  {
    lat: 32.0371137,
    lng: -81.1747498,
    squareFootage: "78300",
    propertyType: "Multifamily",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1333994,
    depreciableAssets: 28,
  },
  {
    lat: 30.87419,
    lng: -83.3468784,
    squareFootage: "148626",
    propertyType: "Multifamily",
    totalCosts: "12969000",
    valueOfReclassifiedAssets: 3336049,
    depreciableAssets: 30,
  },
  {
    lat: 31.3262391,
    lng: -81.5377013,
    squareFootage: "107352",
    propertyType: "Multifamily",
    totalCosts: "9400000",
    valueOfReclassifiedAssets: 2699949,
    depreciableAssets: 34,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "8094",
    propertyType: "Retail",
    totalCosts: "6333381",
    valueOfReclassifiedAssets: 917922,
    depreciableAssets: 17,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "3360",
    propertyType: "Specialty",
    totalCosts: "3685767",
    valueOfReclassifiedAssets: 511776,
    depreciableAssets: 16,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "2860",
    propertyType: "Retail",
    totalCosts: "2549302",
    valueOfReclassifiedAssets: 348481,
    depreciableAssets: 15,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "3012",
    propertyType: "Retail",
    totalCosts: "4113681",
    valueOfReclassifiedAssets: 328381,
    depreciableAssets: 9,
  },
  {
    lat: 27.6164159,
    lng: -97.286868,
    squareFootage: "71432",
    propertyType: "Multifamily",
    totalCosts: "8649380",
    valueOfReclassifiedAssets: 2497460,
    depreciableAssets: 33,
  },
  {
    lat: 47.24570730000001,
    lng: -122.4773432,
    squareFootage: "10384",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 253148,
    depreciableAssets: 19,
  },
  {
    lat: 32.824634,
    lng: -96.6317461,
    squareFootage: "84944",
    propertyType: "Multifamily",
    totalCosts: "9634378",
    valueOfReclassifiedAssets: 2675941,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "43665",
    propertyType: "Multifamily",
    totalCosts: "8602185",
    valueOfReclassifiedAssets: 1397048,
    depreciableAssets: 18,
  },
  {
    lat: 41.5460323,
    lng: -72.6505957,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4347122",
    valueOfReclassifiedAssets: 4270325,
    depreciableAssets: 46,
  },
  {
    lat: 37.9573836,
    lng: -121.2883857,
    squareFootage: "50180",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 742759,
    depreciableAssets: 35,
  },
  {
    lat: 39.9518394,
    lng: -82.7886395,
    squareFootage: "44000",
    propertyType: "Multifamily",
    totalCosts: "1325000",
    valueOfReclassifiedAssets: 261769,
    depreciableAssets: 23,
  },
  {
    lat: 39.9556925,
    lng: -82.9340217,
    squareFootage: "65100",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 368051,
    depreciableAssets: 22,
  },
  {
    lat: 33.5106684,
    lng: -112.029752,
    squareFootage: "70656",
    propertyType: "Multifamily",
    totalCosts: "11403659",
    valueOfReclassifiedAssets: 3490122,
    depreciableAssets: 36,
  },
  {
    lat: 32.7476492,
    lng: -97.09248989999999,
    squareFootage: "25912",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14031155",
    valueOfReclassifiedAssets: 6225335,
    depreciableAssets: 28,
  },
  {
    lat: 47.7393027,
    lng: -122.3435335,
    squareFootage: "6260",
    propertyType: "Multifamily",
    totalCosts: "1023750",
    valueOfReclassifiedAssets: 849991,
    depreciableAssets: 21,
  },
  {
    lat: 47.2945159,
    lng: -122.2192678,
    squareFootage: "32607",
    propertyType: "Retail",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 2592502,
    depreciableAssets: 26,
  },
  {
    lat: 34.2400436,
    lng: -118.5155901,
    squareFootage: "8062",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 366650,
    depreciableAssets: 20,
  },
  {
    lat: 34.0512519,
    lng: -84.7211074,
    squareFootage: "1845",
    propertyType: "Retail",
    totalCosts: "1126062",
    valueOfReclassifiedAssets: 410754,
    depreciableAssets: 66,
  },
  {
    lat: 39.7909447,
    lng: -84.21207,
    squareFootage: "218716",
    propertyType: "Retail",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 1015501,
    depreciableAssets: 22,
  },
  {
    lat: 35.2557159,
    lng: -80.82670639999999,
    squareFootage: "113899",
    propertyType: "Office",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1714868,
    depreciableAssets: 24,
  },
  {
    lat: 30.1261952,
    lng: -95.46729619999999,
    squareFootage: "12295",
    propertyType: "Specialty",
    totalCosts: "2942435",
    valueOfReclassifiedAssets: 471433,
    depreciableAssets: 31,
  },
  {
    lat: 29.5044684,
    lng: -95.70892409999999,
    squareFootage: "14867",
    propertyType: "Specialty",
    totalCosts: "4678700",
    valueOfReclassifiedAssets: 1212923,
    depreciableAssets: 31,
  },
  {
    lat: 29.4043236,
    lng: -98.5034561,
    squareFootage: "12086",
    propertyType: "Multifamily",
    totalCosts: "1493427",
    valueOfReclassifiedAssets: 1309410,
    depreciableAssets: 20,
  },
  {
    lat: 29.4043236,
    lng: -98.5034561,
    squareFootage: "53020",
    propertyType: "Multifamily",
    totalCosts: "3466984",
    valueOfReclassifiedAssets: 2896336,
    depreciableAssets: 22,
  },
  {
    lat: 40.4352418,
    lng: -99.389511,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1368727",
    valueOfReclassifiedAssets: 1062107,
    depreciableAssets: 25,
  },
  {
    lat: 33.999677,
    lng: -117.3675233,
    squareFootage: "5820",
    propertyType: "Multifamily",
    totalCosts: "1375000",
    valueOfReclassifiedAssets: 308142,
    depreciableAssets: 28,
  },
  {
    lat: 41.7332684,
    lng: -93.63191309999999,
    squareFootage: "45504",
    propertyType: "Multifamily",
    totalCosts: "3050000",
    valueOfReclassifiedAssets: 757598,
    depreciableAssets: 29,
  },
  {
    lat: 39.7312603,
    lng: -75.5451379,
    squareFootage: "67000",
    propertyType: "Hotel",
    totalCosts: "20400000",
    valueOfReclassifiedAssets: 6359975,
    depreciableAssets: 34,
  },
  {
    lat: 42.36606159999999,
    lng: -71.0482911,
    squareFootage: "92800",
    propertyType: "Multifamily",
    totalCosts: "6947686",
    valueOfReclassifiedAssets: 1724442,
    depreciableAssets: 29,
  },
  {
    lat: 39.9111323,
    lng: -75.4927278,
    squareFootage: "28446",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11575000",
    valueOfReclassifiedAssets: 3222401,
    depreciableAssets: 33,
  },
  {
    lat: 40.6418428,
    lng: -76.0507949,
    squareFootage: "56858",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19500000",
    valueOfReclassifiedAssets: 4743168,
    depreciableAssets: 28,
  },
  {
    lat: 40.6418428,
    lng: -76.0507949,
    squareFootage: "67143",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "16500000",
    valueOfReclassifiedAssets: 3796626,
    depreciableAssets: 27,
  },
  {
    lat: 40.1498909,
    lng: -75.1253492,
    squareFootage: "59681",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23500000",
    valueOfReclassifiedAssets: 5925426,
    depreciableAssets: 30,
  },
  {
    lat: 29.422658,
    lng: -98.48699049999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "28233689",
    valueOfReclassifiedAssets: 24814255,
    depreciableAssets: 14,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "19296",
    propertyType: "Specialty",
    totalCosts: "31100000",
    valueOfReclassifiedAssets: 3034786,
    depreciableAssets: 11,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1646425",
    valueOfReclassifiedAssets: 1448777,
    depreciableAssets: 0,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "69314",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19350000",
    valueOfReclassifiedAssets: 4578583,
    depreciableAssets: 28,
  },
  {
    lat: 38.7020005,
    lng: -75.0961549,
    squareFootage: "1150",
    propertyType: "Multifamily",
    totalCosts: "810000",
    valueOfReclassifiedAssets: 668018,
    depreciableAssets: 0,
  },
  {
    lat: 43.1467071,
    lng: -75.17788530000001,
    squareFootage: "127232",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11384557",
    valueOfReclassifiedAssets: 2295344,
    depreciableAssets: 23,
  },
  {
    lat: 28.01574,
    lng: -82.5510469,
    squareFootage: "400500",
    propertyType: "Multifamily",
    totalCosts: "42150000",
    valueOfReclassifiedAssets: 10782034,
    depreciableAssets: 30,
  },
  {
    lat: 36.7578013,
    lng: -76.2131474,
    squareFootage: "81318",
    propertyType: "Office",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 2393644,
    depreciableAssets: 27,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "269297",
    propertyType: "Office",
    totalCosts: "58500000",
    valueOfReclassifiedAssets: 10718518,
    depreciableAssets: 21,
  },
  {
    lat: 29.240991,
    lng: -81.06994619999999,
    squareFootage: "108800",
    propertyType: "Multifamily",
    totalCosts: "9350000",
    valueOfReclassifiedAssets: 1609285,
    depreciableAssets: 20,
  },
  {
    lat: 32.706669,
    lng: -96.84046,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "6450000",
    valueOfReclassifiedAssets: 2076662,
    depreciableAssets: 37,
  },
  {
    lat: 32.706669,
    lng: -96.84046,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "5557833",
    valueOfReclassifiedAssets: 1789416,
    depreciableAssets: 37,
  },
  {
    lat: 26.5386936,
    lng: -80.0814292,
    squareFootage: "20940",
    propertyType: "Office",
    totalCosts: "3700000",
    valueOfReclassifiedAssets: 641666,
    depreciableAssets: 21,
  },
  {
    lat: 32.2299039,
    lng: -80.8765205,
    squareFootage: "20538",
    propertyType: "Retail",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 367545,
    depreciableAssets: 30,
  },
  {
    lat: 34.6152142,
    lng: -86.5602614,
    squareFootage: "8077",
    propertyType: "Retail",
    totalCosts: "545000",
    valueOfReclassifiedAssets: 177247,
    depreciableAssets: 38,
  },
  {
    lat: 36.2684007,
    lng: -115.2956118,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8657651",
    valueOfReclassifiedAssets: 8443412,
    depreciableAssets: 34,
  },
  {
    lat: 33.1837961,
    lng: -97.1305536,
    squareFootage: "184114",
    propertyType: "Multifamily",
    totalCosts: "21550000",
    valueOfReclassifiedAssets: 6098808,
    depreciableAssets: 36,
  },
  {
    lat: 32.757305,
    lng: -97.14132219999999,
    squareFootage: "193394",
    propertyType: "Multifamily",
    totalCosts: "17904883",
    valueOfReclassifiedAssets: 4797844,
    depreciableAssets: 33,
  },
  {
    lat: 32.7811446,
    lng: -97.0175879,
    squareFootage: "132463",
    propertyType: "Multifamily",
    totalCosts: "8755337",
    valueOfReclassifiedAssets: 2654343,
    depreciableAssets: 34,
  },
  {
    lat: 30.5684965,
    lng: -98.19942759999999,
    squareFootage: "5000",
    propertyType: "Retail",
    totalCosts: "1025255",
    valueOfReclassifiedAssets: 303324,
    depreciableAssets: 35,
  },
  {
    lat: 33.5494383,
    lng: -84.20679919999999,
    squareFootage: "9014",
    propertyType: "Retail",
    totalCosts: "910000",
    valueOfReclassifiedAssets: 243823,
    depreciableAssets: 30,
  },
  {
    lat: 40.7047738,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3365793",
    valueOfReclassifiedAssets: 3010642,
    depreciableAssets: 12,
  },
  {
    lat: 34.06170609999999,
    lng: -118.2789735,
    squareFootage: "19036",
    propertyType: "Multifamily",
    totalCosts: "4798298",
    valueOfReclassifiedAssets: 802407,
    depreciableAssets: 22,
  },
  {
    lat: 34.0664817,
    lng: -118.3520389,
    squareFootage: "2697",
    propertyType: "Multifamily",
    totalCosts: "1687215",
    valueOfReclassifiedAssets: 513256,
    depreciableAssets: 43,
  },
  {
    lat: 40.6723782,
    lng: -74.2208673,
    squareFootage: "15814",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 363283,
    depreciableAssets: 20,
  },
  {
    lat: 32.3035838,
    lng: -106.6055534,
    squareFootage: "26764",
    propertyType: "Retail",
    totalCosts: "3363026",
    valueOfReclassifiedAssets: 723942,
    depreciableAssets: 28,
  },
  {
    lat: 42.9904057,
    lng: -112.4332314,
    squareFootage: "18000",
    propertyType: "Retail",
    totalCosts: "2808656",
    valueOfReclassifiedAssets: 490809,
    depreciableAssets: 23,
  },
  {
    lat: 30.3790174,
    lng: -86.3427458,
    squareFootage: "2289",
    propertyType: "Multifamily",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 458827,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "154887",
    valueOfReclassifiedAssets: 132860,
    depreciableAssets: 0,
  },
  {
    lat: 32.2165669,
    lng: -110.8195292,
    squareFootage: "127900",
    propertyType: "Multifamily",
    totalCosts: "1979661",
    valueOfReclassifiedAssets: 607111,
    depreciableAssets: 39,
  },
  {
    lat: 38.5890819,
    lng: -121.4084731,
    squareFootage: "4925",
    propertyType: "Office",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 318537,
    depreciableAssets: 28,
  },
  {
    lat: 28.4630493,
    lng: -81.46085599999999,
    squareFootage: "9957",
    propertyType: "Office",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 275196,
    depreciableAssets: 22,
  },
  {
    lat: 36.4722803,
    lng: -87.3612205,
    squareFootage: "42000",
    propertyType: "Multifamily",
    totalCosts: "2823774",
    valueOfReclassifiedAssets: 523688,
    depreciableAssets: 22,
  },
  {
    lat: 36.5128173,
    lng: -87.2419299,
    squareFootage: "43680",
    propertyType: "Multifamily",
    totalCosts: "1374000",
    valueOfReclassifiedAssets: 264851,
    depreciableAssets: 21,
  },
  {
    lat: 36.4722803,
    lng: -87.3612205,
    squareFootage: "12800",
    propertyType: "Multifamily",
    totalCosts: "705944",
    valueOfReclassifiedAssets: 165754,
    depreciableAssets: 27,
  },
  {
    lat: 39.8001396,
    lng: -75.4519516,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13603984",
    valueOfReclassifiedAssets: 8245894,
    depreciableAssets: 87,
  },
  {
    lat: 31.8964712,
    lng: -106.4288088,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "535659",
    valueOfReclassifiedAssets: 450310,
    depreciableAssets: 17,
  },
  {
    lat: 41.5721298,
    lng: -81.521241,
    squareFootage: "88000",
    propertyType: "Multifamily",
    totalCosts: "4127757",
    valueOfReclassifiedAssets: 678300,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "13565",
    propertyType: "Multifamily",
    totalCosts: "4418591",
    valueOfReclassifiedAssets: 927341,
    depreciableAssets: 25,
  },
  {
    lat: 40.078872,
    lng: -82.9447782,
    squareFootage: "91552",
    propertyType: "Multifamily",
    totalCosts: "6485901",
    valueOfReclassifiedAssets: 1325764,
    depreciableAssets: 23,
  },
  {
    lat: 33.1174921,
    lng: -97.0175879,
    squareFootage: "159379",
    propertyType: "Multifamily",
    totalCosts: "20000000",
    valueOfReclassifiedAssets: 4598766,
    depreciableAssets: 27,
  },
  {
    lat: 25.5734243,
    lng: -80.3380213,
    squareFootage: "58994",
    propertyType: "Office",
    totalCosts: "3503159",
    valueOfReclassifiedAssets: 506132,
    depreciableAssets: 24,
  },
  {
    lat: 25.8909628,
    lng: -80.1819268,
    squareFootage: "51324",
    propertyType: "Multifamily",
    totalCosts: "5123346",
    valueOfReclassifiedAssets: 1226313,
    depreciableAssets: 32,
  },
  {
    lat: 26.142147,
    lng: -80.1763467,
    squareFootage: "32223",
    propertyType: "Retail",
    totalCosts: "2972805",
    valueOfReclassifiedAssets: 779911,
    depreciableAssets: 35,
  },
  {
    lat: 26.7712632,
    lng: -80.0702555,
    squareFootage: "64497",
    propertyType: "Office",
    totalCosts: "7561243",
    valueOfReclassifiedAssets: 1415091,
    depreciableAssets: 26,
  },
  {
    lat: 32.3395031,
    lng: -110.9864294,
    squareFootage: "19154",
    propertyType: "Retail",
    totalCosts: "2725000",
    valueOfReclassifiedAssets: 580026,
    depreciableAssets: 25,
  },
  {
    lat: 32.6675165,
    lng: -95.4568133,
    squareFootage: "32237",
    propertyType: "Multifamily",
    totalCosts: "1766413",
    valueOfReclassifiedAssets: 438851,
    depreciableAssets: 29,
  },
  {
    lat: 27.8756643,
    lng: -82.6537455,
    squareFootage: "138799",
    propertyType: "Office",
    totalCosts: "18200000",
    valueOfReclassifiedAssets: 2387060,
    depreciableAssets: 15,
  },
  {
    lat: 35.8409242,
    lng: -78.62285039999999,
    squareFootage: "10100",
    propertyType: "Retail",
    totalCosts: "2686679",
    valueOfReclassifiedAssets: 797489,
    depreciableAssets: 35,
  },
  {
    lat: 36.1683449,
    lng: -115.2659777,
    squareFootage: "63128",
    propertyType: "Retail",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 2447406,
    depreciableAssets: 41,
  },
  {
    lat: 36.2137322,
    lng: -115.1770586,
    squareFootage: "73455",
    propertyType: "Retail",
    totalCosts: "3249274",
    valueOfReclassifiedAssets: 905967,
    depreciableAssets: 33,
  },
  {
    lat: 36.2137322,
    lng: -115.1770586,
    squareFootage: "73455",
    propertyType: "Retail",
    totalCosts: "1233463",
    valueOfReclassifiedAssets: 343915,
    depreciableAssets: 33,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "72084",
    propertyType: "Multifamily",
    totalCosts: "11209169",
    valueOfReclassifiedAssets: 1769929,
    depreciableAssets: 19,
  },
  {
    lat: 33.9052071,
    lng: -83.3148018,
    squareFootage: "6777",
    propertyType: "Retail",
    totalCosts: "933333",
    valueOfReclassifiedAssets: 289143,
    depreciableAssets: 37,
  },
  {
    lat: 33.4165524,
    lng: -86.681253,
    squareFootage: "1758",
    propertyType: "Retail",
    totalCosts: "948000",
    valueOfReclassifiedAssets: 494715,
    depreciableAssets: 62,
  },
  {
    lat: 40.84413869999999,
    lng: -73.8977693,
    squareFootage: "12160",
    propertyType: "Multifamily",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 142928,
    depreciableAssets: 9,
  },
  {
    lat: 40.7119031,
    lng: -73.9660683,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "43264038",
    valueOfReclassifiedAssets: 38501103,
    depreciableAssets: 11,
  },
  {
    lat: 42.569557,
    lng: -70.94279139999999,
    squareFootage: "12436",
    propertyType: "Industrial",
    totalCosts: "700000",
    valueOfReclassifiedAssets: 111805,
    depreciableAssets: 22,
  },
  {
    lat: 30.2949194,
    lng: -81.63638689999999,
    squareFootage: "103718",
    propertyType: "Multifamily",
    totalCosts: "10800000",
    valueOfReclassifiedAssets: 3375217,
    depreciableAssets: 35,
  },
  {
    lat: 31.9626279,
    lng: -102.3807553,
    squareFootage: "255248",
    propertyType: "Multifamily",
    totalCosts: "39000000",
    valueOfReclassifiedAssets: 9252619,
    depreciableAssets: 28,
  },
  {
    lat: 42.4573354,
    lng: -71.0616623,
    squareFootage: "28884",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9611719",
    valueOfReclassifiedAssets: 2600421,
    depreciableAssets: 30,
  },
  {
    lat: 40.9845896,
    lng: -74.1063776,
    squareFootage: "36708",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "23538281",
    valueOfReclassifiedAssets: 9039838,
    depreciableAssets: 31,
  },
  {
    lat: 29.4895776,
    lng: -98.385532,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "6175000",
    valueOfReclassifiedAssets: 1786681,
    depreciableAssets: 34,
  },
  {
    lat: 32.9241691,
    lng: -96.8672668,
    squareFootage: "125578",
    propertyType: "Multifamily",
    totalCosts: "10825000",
    valueOfReclassifiedAssets: 2935459,
    depreciableAssets: 31,
  },
  {
    lat: 33.5754008,
    lng: -84.5327038,
    squareFootage: "48300",
    propertyType: "Multifamily",
    totalCosts: "3870000",
    valueOfReclassifiedAssets: 1228360,
    depreciableAssets: 38,
  },
  {
    lat: 41.1522215,
    lng: -83.4003037,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2503849",
    valueOfReclassifiedAssets: 1962016,
    depreciableAssets: 86,
  },
  {
    lat: 40.6815619,
    lng: -73.9785952,
    squareFootage: "7000",
    propertyType: "Multifamily",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 1404615,
    depreciableAssets: 22,
  },
  {
    lat: 32.9678449,
    lng: -96.8887202,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1204764",
    valueOfReclassifiedAssets: 1153413,
    depreciableAssets: 23,
  },
  {
    lat: 38.8687274,
    lng: -78.6341996,
    squareFootage: "9026",
    propertyType: "Retail",
    totalCosts: "1639092",
    valueOfReclassifiedAssets: 261539,
    depreciableAssets: 21,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5496441",
    valueOfReclassifiedAssets: 4450499,
    depreciableAssets: 22,
  },
  {
    lat: 47.3394747,
    lng: -122.2667661,
    squareFootage: "7756",
    propertyType: "Multifamily",
    totalCosts: "1081692",
    valueOfReclassifiedAssets: 238562,
    depreciableAssets: 37,
  },
  {
    lat: 47.445215,
    lng: -120.1593725,
    squareFootage: "55440",
    propertyType: "Hotel",
    totalCosts: "13625000",
    valueOfReclassifiedAssets: 11449634,
    depreciableAssets: 21,
  },
  {
    lat: 36.147526,
    lng: -95.85121,
    squareFootage: "97000",
    propertyType: "Multifamily",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 1114995,
    depreciableAssets: 32,
  },
  {
    lat: 39.0013395,
    lng: -92.3128326,
    squareFootage: "31900",
    propertyType: "Industrial",
    totalCosts: "1783000",
    valueOfReclassifiedAssets: 379219,
    depreciableAssets: 25,
  },
  {
    lat: 35.9980959,
    lng: -96.13149279999999,
    squareFootage: "215440",
    propertyType: "Multifamily",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 642848,
    depreciableAssets: 23,
  },
  {
    lat: 32.1772542,
    lng: -110.7956878,
    squareFootage: "147177",
    propertyType: "Multifamily",
    totalCosts: "11800000",
    valueOfReclassifiedAssets: 3520050,
    depreciableAssets: 35,
  },
  {
    lat: 32.2680738,
    lng: -110.9923904,
    squareFootage: "111960",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 2935489,
    depreciableAssets: 31,
  },
  {
    lat: 33.5349253,
    lng: -112.1847509,
    squareFootage: "264064",
    propertyType: "Multifamily",
    totalCosts: "34300000",
    valueOfReclassifiedAssets: 10925755,
    depreciableAssets: 38,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "613458",
    valueOfReclassifiedAssets: 508644,
    depreciableAssets: 17,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "9264",
    propertyType: "Multifamily",
    totalCosts: "1638383",
    valueOfReclassifiedAssets: 181012,
    depreciableAssets: 16,
  },
  {
    lat: 40.7282702,
    lng: -73.9506774,
    squareFootage: "2020",
    propertyType: "Multifamily",
    totalCosts: "566552",
    valueOfReclassifiedAssets: 460552,
    depreciableAssets: 100,
  },
  {
    lat: 32.8276924,
    lng: -96.679851,
    squareFootage: "199448",
    propertyType: "Multifamily",
    totalCosts: "18365000",
    valueOfReclassifiedAssets: 5174185,
    depreciableAssets: 32,
  },
  {
    lat: 34.9155618,
    lng: -78.9175088,
    squareFootage: "19800",
    propertyType: "Multifamily",
    totalCosts: "1240000",
    valueOfReclassifiedAssets: 263432,
    depreciableAssets: 25,
  },
  {
    lat: 39.8383777,
    lng: -74.9325683,
    squareFootage: "50916",
    propertyType: "Office",
    totalCosts: "1556973",
    valueOfReclassifiedAssets: 1392235,
    depreciableAssets: 23,
  },
  {
    lat: 42.0301291,
    lng: -87.68276759999999,
    squareFootage: "31132",
    propertyType: "Multifamily",
    totalCosts: "4825000",
    valueOfReclassifiedAssets: 883674,
    depreciableAssets: 20,
  },
  {
    lat: 40.7699016,
    lng: -73.8272029,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1312780",
    valueOfReclassifiedAssets: 613864,
    depreciableAssets: 0,
  },
  {
    lat: 33.8511616,
    lng: -98.544636,
    squareFootage: "103965",
    propertyType: "Multifamily",
    totalCosts: "5355000",
    valueOfReclassifiedAssets: 1632002,
    depreciableAssets: 35,
  },
  {
    lat: 40.6019636,
    lng: -74.4818698,
    squareFootage: "12000",
    propertyType: "Retail",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 278074,
    depreciableAssets: 28,
  },
  {
    lat: 36.5604306,
    lng: -87.4405786,
    squareFootage: "12854",
    propertyType: "Multifamily",
    totalCosts: "824000",
    valueOfReclassifiedAssets: 167358,
    depreciableAssets: 24,
  },
  {
    lat: 40.930976,
    lng: -74.519706,
    squareFootage: "25708",
    propertyType: "Multifamily",
    totalCosts: "1236000",
    valueOfReclassifiedAssets: 256219,
    depreciableAssets: 24,
  },
  {
    lat: 39.7069307,
    lng: -104.9564084,
    squareFootage: "328986",
    propertyType: "Multifamily",
    totalCosts: "41969736",
    valueOfReclassifiedAssets: 8128222,
    depreciableAssets: 23,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "494106",
    propertyType: "Multifamily",
    totalCosts: "105797208",
    valueOfReclassifiedAssets: 18420252,
    depreciableAssets: 20,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "494106",
    propertyType: "Multifamily",
    totalCosts: "105797208",
    valueOfReclassifiedAssets: 18420252,
    depreciableAssets: 20,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "494106",
    propertyType: "Multifamily",
    totalCosts: "105797208",
    valueOfReclassifiedAssets: 18420252,
    depreciableAssets: 20,
  },
  {
    lat: 36.0955555,
    lng: -79.4379412,
    squareFootage: "185450",
    propertyType: "Multifamily",
    totalCosts: "24400000",
    valueOfReclassifiedAssets: 5611839,
    depreciableAssets: 27,
  },
  {
    lat: 41.7165144,
    lng: -73.9242251,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4510587",
    valueOfReclassifiedAssets: 2269064,
    depreciableAssets: 52,
  },
  {
    lat: 41.90460119999999,
    lng: -71.8689917,
    squareFootage: "36801",
    propertyType: "Multifamily",
    totalCosts: "1348476",
    valueOfReclassifiedAssets: 190906,
    depreciableAssets: 17,
  },
  {
    lat: 42.219002,
    lng: -72.1534606,
    squareFootage: "9836",
    propertyType: "Multifamily",
    totalCosts: "3700000",
    valueOfReclassifiedAssets: 2352883,
    depreciableAssets: 26,
  },
  {
    lat: 29.9486953,
    lng: -95.4410924,
    squareFootage: "31693",
    propertyType: "Retail",
    totalCosts: "784322",
    valueOfReclassifiedAssets: 183093,
    depreciableAssets: 28,
  },
  {
    lat: 26.1889227,
    lng: -80.1763467,
    squareFootage: "119339",
    propertyType: "Office",
    totalCosts: "17650000",
    valueOfReclassifiedAssets: 2875751,
    depreciableAssets: 19,
  },
  {
    lat: 39.7713425,
    lng: -86.15737089999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "37000000",
    valueOfReclassifiedAssets: 5932611,
    depreciableAssets: 18,
  },
  {
    lat: 40.1732512,
    lng: -76.9988669,
    squareFootage: "32672",
    propertyType: "Multifamily",
    totalCosts: "2730000",
    valueOfReclassifiedAssets: 728938,
    depreciableAssets: 30,
  },
  {
    lat: 40.2821249,
    lng: -76.7221082,
    squareFootage: "90080",
    propertyType: "Multifamily",
    totalCosts: "6200000",
    valueOfReclassifiedAssets: 1515917,
    depreciableAssets: 25,
  },
  {
    lat: 42.9297842,
    lng: -74.1474846,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12347926",
    valueOfReclassifiedAssets: 8946864,
    depreciableAssets: 31,
  },
  {
    lat: 41.0670166,
    lng: -73.6182979,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 631150,
    depreciableAssets: 37,
  },
  {
    lat: 35.3902982,
    lng: -82.36688699999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 958068,
    depreciableAssets: 0,
  },
  {
    lat: 39.8817088,
    lng: -74.95594799999999,
    squareFootage: "180604",
    propertyType: "Industrial",
    totalCosts: "13580000",
    valueOfReclassifiedAssets: 2460845,
    depreciableAssets: 21,
  },
  {
    lat: 42.9996568,
    lng: -70.9784562,
    squareFootage: "36546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "134000000",
    valueOfReclassifiedAssets: 34302818,
    depreciableAssets: 30,
  },
  {
    lat: 42.9996568,
    lng: -70.9784562,
    squareFootage: "36546",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "134000000",
    valueOfReclassifiedAssets: 34302818,
    depreciableAssets: 30,
  },
  {
    lat: 41.5254822,
    lng: -72.7688226,
    squareFootage: "54550",
    propertyType: "Multifamily",
    totalCosts: "2525000",
    valueOfReclassifiedAssets: 468995,
    depreciableAssets: 22,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "686503",
    propertyType: "Office",
    totalCosts: "118556514",
    valueOfReclassifiedAssets: 11351674,
    depreciableAssets: 11,
  },
  {
    lat: 39.9531865,
    lng: -75.1749671,
    squareFootage: "296000",
    propertyType: "Office",
    totalCosts: "53077901",
    valueOfReclassifiedAssets: 5309808,
    depreciableAssets: 11,
  },
  {
    lat: 39.9682609,
    lng: -75.1749671,
    squareFootage: "1080000",
    propertyType: "Office",
    totalCosts: "222196694",
    valueOfReclassifiedAssets: 27347041,
    depreciableAssets: 14,
  },
  {
    lat: 41.4513221,
    lng: -81.7021049,
    squareFootage: "7000",
    propertyType: "Retail",
    totalCosts: "920000",
    valueOfReclassifiedAssets: 181059,
    depreciableAssets: 23,
  },
  {
    lat: 32.5145763,
    lng: -90.13077969999999,
    squareFootage: "4500",
    propertyType: "Retail",
    totalCosts: "3036332",
    valueOfReclassifiedAssets: 1182681,
    depreciableAssets: 46,
  },
  {
    lat: 33.6946684,
    lng: -91.7538817,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "919000",
    valueOfReclassifiedAssets: 418498,
    depreciableAssets: 54,
  },
  {
    lat: 35.669371,
    lng: -95.26844369999999,
    squareFootage: "7000",
    propertyType: "Retail",
    totalCosts: "925000",
    valueOfReclassifiedAssets: 254698,
    depreciableAssets: 32,
  },
  {
    lat: 41.1876338,
    lng: -74.0828839,
    squareFootage: "44173",
    propertyType: "Industrial",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1451650,
    depreciableAssets: 26,
  },
  {
    lat: 41.4769163,
    lng: -81.57609450000001,
    squareFootage: "207775",
    propertyType: "Multifamily",
    totalCosts: "9063330",
    valueOfReclassifiedAssets: 1448088,
    depreciableAssets: 18,
  },
  {
    lat: 31.8964712,
    lng: -106.4288088,
    squareFootage: "207775",
    propertyType: "Multifamily",
    totalCosts: "9517107",
    valueOfReclassifiedAssets: 1737881,
    depreciableAssets: 22,
  },
  {
    lat: 39.9644541,
    lng: -75.145782,
    squareFootage: "12600",
    propertyType: "Industrial",
    totalCosts: "4250000",
    valueOfReclassifiedAssets: 941144,
    depreciableAssets: 25,
  },
  {
    lat: 33.1376528,
    lng: -96.85654269999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11086059",
    valueOfReclassifiedAssets: 3222742,
    depreciableAssets: 36,
  },
  {
    lat: 40.9411487,
    lng: -74.2531465,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "26418687",
    valueOfReclassifiedAssets: 24433505,
    depreciableAssets: 17,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "27550",
    propertyType: "Multifamily",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 210473,
    depreciableAssets: 14,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "2015",
    propertyType: "Multifamily",
    totalCosts: "640000",
    valueOfReclassifiedAssets: 126891,
    depreciableAssets: 24,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4584",
    propertyType: "Multifamily",
    totalCosts: "1025000",
    valueOfReclassifiedAssets: 135304,
    depreciableAssets: 15,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4470",
    propertyType: "Multifamily",
    totalCosts: "575000",
    valueOfReclassifiedAssets: 84008,
    depreciableAssets: 17,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4960",
    propertyType: "Multifamily",
    totalCosts: "1240000",
    valueOfReclassifiedAssets: 200285,
    depreciableAssets: 19,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "6578",
    propertyType: "Multifamily",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 206540,
    depreciableAssets: 19,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "5829",
    propertyType: "Multifamily",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 533135,
    depreciableAssets: 37,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "3275",
    propertyType: "Multifamily",
    totalCosts: "605000",
    valueOfReclassifiedAssets: 64545,
    depreciableAssets: 12,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "1654",
    propertyType: "Multifamily",
    totalCosts: "415000",
    valueOfReclassifiedAssets: 75086,
    depreciableAssets: 21,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "3581",
    propertyType: "Multifamily",
    totalCosts: "1295000",
    valueOfReclassifiedAssets: 296785,
    depreciableAssets: 26,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "1960",
    propertyType: "Multifamily",
    totalCosts: "490000",
    valueOfReclassifiedAssets: 81248,
    depreciableAssets: 19,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4270",
    propertyType: "Multifamily",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 164775,
    depreciableAssets: 19,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "5148",
    propertyType: "Multifamily",
    totalCosts: "895000",
    valueOfReclassifiedAssets: 141238,
    depreciableAssets: 19,
  },
  {
    lat: 40.8400532,
    lng: -74.20032309999999,
    squareFootage: "3394",
    propertyType: "Multifamily",
    totalCosts: "745000",
    valueOfReclassifiedAssets: 82504,
    depreciableAssets: 13,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4560",
    propertyType: "Multifamily",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 182092,
    depreciableAssets: 21,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "4472",
    propertyType: "Multifamily",
    totalCosts: "825000",
    valueOfReclassifiedAssets: 99300,
    depreciableAssets: 14,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "2494",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 90544,
    depreciableAssets: 17,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "2860",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 87948,
    depreciableAssets: 16,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "3538",
    propertyType: "Multifamily",
    totalCosts: "805000",
    valueOfReclassifiedAssets: 112228,
    depreciableAssets: 16,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "3634",
    propertyType: "Multifamily",
    totalCosts: "670000",
    valueOfReclassifiedAssets: 85979,
    depreciableAssets: 15,
  },
  {
    lat: 40.7564795,
    lng: -74.2208673,
    squareFootage: "44112",
    propertyType: "Multifamily",
    totalCosts: "8700000",
    valueOfReclassifiedAssets: 1419597,
    depreciableAssets: 19,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "3540",
    propertyType: "Multifamily",
    totalCosts: "730000",
    valueOfReclassifiedAssets: 80159,
    depreciableAssets: 13,
  },
  {
    lat: 40.9541697,
    lng: -74.0652616,
    squareFootage: "82288",
    propertyType: "Office",
    totalCosts: "16000000",
    valueOfReclassifiedAssets: 3194680,
    depreciableAssets: 23,
  },
  {
    lat: 40.9541697,
    lng: -74.0652616,
    squareFootage: "82288",
    propertyType: "Office",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 798670,
    depreciableAssets: 23,
  },
  {
    lat: 40.7793504,
    lng: -74.511172,
    squareFootage: "4044",
    propertyType: "Office",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 183323,
    depreciableAssets: 13,
  },
  {
    lat: 40.6805029,
    lng: -73.96243270000001,
    squareFootage: "4050",
    propertyType: "Multifamily",
    totalCosts: "5100000",
    valueOfReclassifiedAssets: 4711264,
    depreciableAssets: 9,
  },
  {
    lat: 32.7891591,
    lng: -96.82170169999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1315448",
    valueOfReclassifiedAssets: 661723,
    depreciableAssets: 52,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "247980",
    propertyType: "Multifamily",
    totalCosts: "31163580",
    valueOfReclassifiedAssets: 6593034,
    depreciableAssets: 25,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "300817",
    propertyType: "Multifamily",
    totalCosts: "31812600",
    valueOfReclassifiedAssets: 6520823,
    depreciableAssets: 24,
  },
  {
    lat: 41.6607514,
    lng: -74.7102958,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3200462",
    valueOfReclassifiedAssets: 1728804,
    depreciableAssets: 64,
  },
  {
    lat: 39.9793657,
    lng: -75.1253492,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4568661",
    valueOfReclassifiedAssets: 4116998,
    depreciableAssets: 12,
  },
  {
    lat: 39.9793657,
    lng: -75.1253492,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2090845",
    valueOfReclassifiedAssets: 1884142,
    depreciableAssets: 12,
  },
  {
    lat: 38.7470186,
    lng: -90.2526163,
    squareFootage: "106068",
    propertyType: "Multifamily",
    totalCosts: "4615000",
    valueOfReclassifiedAssets: 1038387,
    depreciableAssets: 26,
  },
  {
    lat: 41.5364934,
    lng: -81.57609450000001,
    squareFootage: "260250",
    propertyType: "Multifamily",
    totalCosts: "6635000",
    valueOfReclassifiedAssets: 1117394,
    depreciableAssets: 20,
  },
  {
    lat: 32.6604239,
    lng: -96.76010570000001,
    squareFootage: "340813",
    propertyType: "Multifamily",
    totalCosts: "16700000",
    valueOfReclassifiedAssets: 4332307,
    depreciableAssets: 31,
  },
  {
    lat: 38.65788,
    lng: -90.2854839,
    squareFootage: "143844",
    propertyType: "Multifamily",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 668105,
    depreciableAssets: 22,
  },
  {
    lat: 39.9721714,
    lng: -82.8694464,
    squareFootage: "337729",
    propertyType: "Multifamily",
    totalCosts: "12412500",
    valueOfReclassifiedAssets: 2007123,
    depreciableAssets: 19,
  },
  {
    lat: 40.0397682,
    lng: -75.145782,
    squareFootage: "41000",
    propertyType: "Multifamily",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 673534,
    depreciableAssets: 20,
  },
  {
    lat: 30.36173479999999,
    lng: -81.69663059999999,
    squareFootage: "104705",
    propertyType: "Multifamily",
    totalCosts: "7375000",
    valueOfReclassifiedAssets: 1739709,
    depreciableAssets: 28,
  },
  {
    lat: 40.3145207,
    lng: -88.14865700000001,
    squareFootage: "200000",
    propertyType: "Multifamily",
    totalCosts: "4550000",
    valueOfReclassifiedAssets: 981813,
    depreciableAssets: 25,
  },
  {
    lat: 40.0149445,
    lng: -75.0903154,
    squareFootage: "56280",
    propertyType: "Multifamily",
    totalCosts: "2268000",
    valueOfReclassifiedAssets: 370745,
    depreciableAssets: 20,
  },
  {
    lat: 39.99550720000001,
    lng: -75.1720488,
    squareFootage: "58354",
    propertyType: "Multifamily",
    totalCosts: "3020000",
    valueOfReclassifiedAssets: 483781,
    depreciableAssets: 19,
  },
  {
    lat: 32.9259646,
    lng: -97.2329255,
    squareFootage: "14946",
    propertyType: "Office",
    totalCosts: "4390261",
    valueOfReclassifiedAssets: 1303073,
    depreciableAssets: 35,
  },
  {
    lat: 33.9930471,
    lng: -118.153228,
    squareFootage: "146942",
    propertyType: "Office",
    totalCosts: "32000000",
    valueOfReclassifiedAssets: 3525737,
    depreciableAssets: 14,
  },
  {
    lat: 38.9197046,
    lng: -94.2846246,
    squareFootage: "103769",
    propertyType: "Office",
    totalCosts: "39000000",
    valueOfReclassifiedAssets: 3016513,
    depreciableAssets: 9,
  },
  {
    lat: 34.0895186,
    lng: -118.3315838,
    squareFootage: "36132",
    propertyType: "Multifamily",
    totalCosts: "15500000",
    valueOfReclassifiedAssets: 1800489,
    depreciableAssets: 15,
  },
  {
    lat: 34.1012181,
    lng: -118.325739,
    squareFootage: "20590",
    propertyType: "Multifamily",
    totalCosts: "12400000",
    valueOfReclassifiedAssets: 2600970,
    depreciableAssets: 26,
  },
  {
    lat: 34.1384626,
    lng: -118.8946309,
    squareFootage: "117018",
    propertyType: "Office",
    totalCosts: "55000000",
    valueOfReclassifiedAssets: 7287134,
    depreciableAssets: 16,
  },
  {
    lat: 35.2018872,
    lng: -101.8948754,
    squareFootage: "50706",
    propertyType: "Office",
    totalCosts: "20741564",
    valueOfReclassifiedAssets: 3552912,
    depreciableAssets: 12,
  },
  {
    lat: 36.0733691,
    lng: -79.8240715,
    squareFootage: "27145",
    propertyType: "Retail",
    totalCosts: "1525000",
    valueOfReclassifiedAssets: 320549,
    depreciableAssets: 25,
  },
  {
    lat: 27.8756643,
    lng: -82.6537455,
    squareFootage: "16755",
    propertyType: "Office",
    totalCosts: "1048273",
    valueOfReclassifiedAssets: 147636,
    depreciableAssets: 19,
  },
  {
    lat: 28.8700376,
    lng: -82.669947,
    squareFootage: "14926",
    propertyType: "Retail",
    totalCosts: "4850000",
    valueOfReclassifiedAssets: 1122110,
    depreciableAssets: 28,
  },
  {
    lat: 40.4933734,
    lng: -74.5228916,
    squareFootage: "18040",
    propertyType: "Office",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 297496,
    depreciableAssets: 22,
  },
  {
    lat: 31.0691358,
    lng: -97.6656625,
    squareFootage: "145870",
    propertyType: "Multifamily",
    totalCosts: "7600000",
    valueOfReclassifiedAssets: 1890386,
    depreciableAssets: 29,
  },
  {
    lat: 43.5692371,
    lng: -83.8685072,
    squareFootage: "28000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4038706",
    valueOfReclassifiedAssets: 1060195,
    depreciableAssets: 29,
  },
  {
    lat: 43.05005269999999,
    lng: -83.528342,
    squareFootage: "42397",
    propertyType: "Multifamily",
    totalCosts: "6058060",
    valueOfReclassifiedAssets: 1891811,
    depreciableAssets: 35,
  },
  {
    lat: 35.3922378,
    lng: -97.4273195,
    squareFootage: "127000",
    propertyType: "Multifamily",
    totalCosts: "6665000",
    valueOfReclassifiedAssets: 1625477,
    depreciableAssets: 29,
  },
  {
    lat: 35.0938365,
    lng: -78.9627624,
    squareFootage: "108000",
    propertyType: "Multifamily",
    totalCosts: "4850000",
    valueOfReclassifiedAssets: 1274032,
    depreciableAssets: 31,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "10160",
    propertyType: "Multifamily",
    totalCosts: "4310000",
    valueOfReclassifiedAssets: 638669,
    depreciableAssets: 17,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "9240",
    propertyType: "Multifamily",
    totalCosts: "4650000",
    valueOfReclassifiedAssets: 604207,
    depreciableAssets: 15,
  },
  {
    lat: 42.75026099999999,
    lng: -87.81075609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "392224",
    valueOfReclassifiedAssets: 111311,
    depreciableAssets: 0,
  },
  {
    lat: 43.9657221,
    lng: -121.2196398,
    squareFootage: "14360",
    propertyType: "Office",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 695072,
    depreciableAssets: 20,
  },
  {
    lat: 38.9374808,
    lng: -77.0852258,
    squareFootage: "109971",
    propertyType: "Multifamily",
    totalCosts: "42250000",
    valueOfReclassifiedAssets: 6627350,
    depreciableAssets: 19,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "13945",
    propertyType: "Multifamily",
    totalCosts: "1716897",
    valueOfReclassifiedAssets: 176088,
    depreciableAssets: 12,
  },
  {
    lat: 39.9795244,
    lng: -75.145782,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25548529",
    valueOfReclassifiedAssets: 21436020,
    depreciableAssets: 19,
  },
  {
    lat: 37.41089270000001,
    lng: -122.2880726,
    squareFootage: "11725",
    propertyType: "Retail",
    totalCosts: "6011750",
    valueOfReclassifiedAssets: 987034,
    depreciableAssets: 20,
  },
  {
    lat: 39.73274600000001,
    lng: -104.9710123,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4850000",
    valueOfReclassifiedAssets: 741343,
    depreciableAssets: 23,
  },
  {
    lat: 37.86373760000001,
    lng: -122.2682245,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "39004799",
    valueOfReclassifiedAssets: 33773959,
    depreciableAssets: 16,
  },
  {
    lat: 35.1633874,
    lng: -101.900579,
    squareFootage: "22080",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 475461,
    depreciableAssets: 34,
  },
  {
    lat: 35.1633874,
    lng: -101.900579,
    squareFootage: "22080",
    propertyType: "Multifamily",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 560868,
    depreciableAssets: 35,
  },
  {
    lat: 41.9427194,
    lng: -71.0616623,
    squareFootage: "36695",
    propertyType: "Office",
    totalCosts: "7929583",
    valueOfReclassifiedAssets: 1893671,
    depreciableAssets: 30,
  },
  {
    lat: 40.3038147,
    lng: -74.0887576,
    squareFootage: "45410",
    propertyType: "Office",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 1548599,
    depreciableAssets: 18,
  },
  {
    lat: 39.9311729,
    lng: -75.0260667,
    squareFootage: "142047",
    propertyType: "Retail",
    totalCosts: "29712667",
    valueOfReclassifiedAssets: 4786424,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8491302",
    valueOfReclassifiedAssets: 8353365,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6989669",
    valueOfReclassifiedAssets: 6727139,
    depreciableAssets: 21,
  },
  {
    lat: 40.6007956,
    lng: -73.9065883,
    squareFootage: "51000",
    propertyType: "Office",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 1453011,
    depreciableAssets: 13,
  },
  {
    lat: 40.6274394,
    lng: -74.0946311,
    squareFootage: "73115",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "42500000",
    valueOfReclassifiedAssets: 10261837,
    depreciableAssets: 27,
  },
  {
    lat: 42.5330662,
    lng: -96.5036433,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1473661",
    valueOfReclassifiedAssets: 482830,
    depreciableAssets: 49,
  },
  {
    lat: 42.5330662,
    lng: -96.5036433,
    squareFootage: "1875",
    propertyType: "Office",
    totalCosts: "3793575",
    valueOfReclassifiedAssets: 1552014,
    depreciableAssets: 49,
  },
  {
    lat: 42.5330662,
    lng: -96.5036433,
    squareFootage: "730",
    propertyType: "Industrial",
    totalCosts: "836338",
    valueOfReclassifiedAssets: 291244,
    depreciableAssets: 42,
  },
  {
    lat: 42.4584705,
    lng: -96.4396888,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1271667",
    valueOfReclassifiedAssets: 502532,
    depreciableAssets: 50,
  },
  {
    lat: 31.8403844,
    lng: -105.92299,
    squareFootage: "1400",
    propertyType: "Retail",
    totalCosts: "2779254",
    valueOfReclassifiedAssets: 2034730,
    depreciableAssets: 83,
  },
  {
    lat: 42.8945981,
    lng: -112.3540741,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2604751",
    valueOfReclassifiedAssets: 1162440,
    depreciableAssets: 52,
  },
  {
    lat: 33.6071829,
    lng: -111.9403254,
    squareFootage: "39425",
    propertyType: "Office",
    totalCosts: "3564151",
    valueOfReclassifiedAssets: 681682,
    depreciableAssets: 22,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "143444",
    propertyType: "Multifamily",
    totalCosts: "16500000",
    valueOfReclassifiedAssets: 3400472,
    depreciableAssets: 24,
  },
  {
    lat: 34.8742328,
    lng: -82.33433749999999,
    squareFootage: "149759",
    propertyType: "Multifamily",
    totalCosts: "17750000",
    valueOfReclassifiedAssets: 4134346,
    depreciableAssets: 27,
  },
  {
    lat: 35.1660032,
    lng: -80.7934798,
    squareFootage: "44059",
    propertyType: "Multifamily",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 1002224,
    depreciableAssets: 27,
  },
  {
    lat: 36.1199761,
    lng: -83.9214896,
    squareFootage: "44585",
    propertyType: "Multifamily",
    totalCosts: "3035000",
    valueOfReclassifiedAssets: 814656,
    depreciableAssets: 32,
  },
  {
    lat: 35.9017424,
    lng: -78.7532884,
    squareFootage: "298245",
    propertyType: "Multifamily",
    totalCosts: "51500000",
    valueOfReclassifiedAssets: 11710320,
    depreciableAssets: 27,
  },
  {
    lat: 40.0388765,
    lng: -82.8748301,
    squareFootage: "5848",
    propertyType: "Office",
    totalCosts: "1405000",
    valueOfReclassifiedAssets: 1194250,
    depreciableAssets: 100,
  },
  {
    lat: 33.3786293,
    lng: -84.8984775,
    squareFootage: "138476",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3397229,
    depreciableAssets: 34,
  },
  {
    lat: 36.0765176,
    lng: -115.1533426,
    squareFootage: "104765",
    propertyType: "Office",
    totalCosts: "33000000",
    valueOfReclassifiedAssets: 4706582,
    depreciableAssets: 15,
  },
  {
    lat: 36.0765176,
    lng: -115.1533426,
    squareFootage: "93560",
    propertyType: "Office",
    totalCosts: "30750000",
    valueOfReclassifiedAssets: 4588510,
    depreciableAssets: 16,
  },
  {
    lat: 34.2801972,
    lng: -118.4980744,
    squareFootage: "26816",
    propertyType: "Multifamily",
    totalCosts: "7483894",
    valueOfReclassifiedAssets: 1456901,
    depreciableAssets: 29,
  },
  {
    lat: 35.352762,
    lng: -86.1955169,
    squareFootage: "24850",
    propertyType: "Multifamily",
    totalCosts: "1150000",
    valueOfReclassifiedAssets: 360818,
    depreciableAssets: 35,
  },
  {
    lat: 35.5239664,
    lng: -86.0733498,
    squareFootage: "46464",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 702758,
    depreciableAssets: 31,
  },
  {
    lat: 40.0851765,
    lng: -74.0593872,
    squareFootage: "10800",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 174157,
    depreciableAssets: 19,
  },
  {
    lat: 40.0876046,
    lng: -74.1122506,
    squareFootage: "48000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14038987",
    valueOfReclassifiedAssets: 3696940,
    depreciableAssets: 30,
  },
  {
    lat: 40.8597746,
    lng: -74.12986839999999,
    squareFootage: "36992",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12891788",
    valueOfReclassifiedAssets: 3051151,
    depreciableAssets: 26,
  },
  {
    lat: 40.0207099,
    lng: -74.2061931,
    squareFootage: "72389",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7584839",
    valueOfReclassifiedAssets: 5793896,
    depreciableAssets: 31,
  },
  {
    lat: 40.7448741,
    lng: -73.91981609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "605982",
    valueOfReclassifiedAssets: 412639,
    depreciableAssets: 0,
  },
  {
    lat: 40.7448741,
    lng: -73.91981609999999,
    squareFootage: "6800",
    propertyType: "Multifamily",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 528709,
    depreciableAssets: 25,
  },
  {
    lat: 40.7448741,
    lng: -73.91981609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "769885",
    valueOfReclassifiedAssets: 601478,
    depreciableAssets: 24,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "2190",
    propertyType: "Multifamily",
    totalCosts: "3035000",
    valueOfReclassifiedAssets: 365196,
    depreciableAssets: 34,
  },
  {
    lat: 32.4545928,
    lng: -93.70797449999999,
    squareFootage: "10650",
    propertyType: "Specialty",
    totalCosts: "2123015",
    valueOfReclassifiedAssets: 507889,
    depreciableAssets: 32,
  },
  {
    lat: 39.0839994,
    lng: -77.1527813,
    squareFootage: "101021",
    propertyType: "Office",
    totalCosts: "10211135",
    valueOfReclassifiedAssets: 1865265,
    depreciableAssets: 27,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "11634",
    propertyType: "Office",
    totalCosts: "17810276",
    valueOfReclassifiedAssets: 1144313,
    depreciableAssets: 20,
  },
  {
    lat: 38.9274061,
    lng: -76.9729472,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3164884",
    valueOfReclassifiedAssets: 2635857,
    depreciableAssets: 19,
  },
  {
    lat: 38.9502497,
    lng: -77.01614359999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13331042",
    valueOfReclassifiedAssets: 11302530,
    depreciableAssets: 17,
  },
  {
    lat: 38.9502497,
    lng: -77.01614359999999,
    squareFootage: "17800",
    propertyType: "Multifamily",
    totalCosts: "2843532",
    valueOfReclassifiedAssets: 678685,
    depreciableAssets: 30,
  },
  {
    lat: 38.9502497,
    lng: -77.01614359999999,
    squareFootage: "19065",
    propertyType: "Multifamily",
    totalCosts: "868557",
    valueOfReclassifiedAssets: 122776,
    depreciableAssets: 19,
  },
  {
    lat: 29.3845708,
    lng: -95.2475518,
    squareFootage: "106528",
    propertyType: "Multifamily",
    totalCosts: "9245000",
    valueOfReclassifiedAssets: 2395255,
    depreciableAssets: 31,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "301490",
    valueOfReclassifiedAssets: 256227,
    depreciableAssets: 49,
  },
  {
    lat: 34.7438027,
    lng: -86.6157527,
    squareFootage: "400120",
    propertyType: "Multifamily",
    totalCosts: "12500000",
    valueOfReclassifiedAssets: 3268825,
    depreciableAssets: 31,
  },
  {
    lat: 34.7438027,
    lng: -86.6157527,
    squareFootage: "53420",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 167431,
    depreciableAssets: 30,
  },
  {
    lat: 34.7438027,
    lng: -86.6157527,
    squareFootage: "56986",
    propertyType: "Hotel",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 353901,
    depreciableAssets: 42,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "3964",
    propertyType: "Specialty",
    totalCosts: "3780354",
    valueOfReclassifiedAssets: 1095381,
    depreciableAssets: 32,
  },
  {
    lat: 26.1889227,
    lng: -80.1763467,
    squareFootage: "118486",
    propertyType: "Multifamily",
    totalCosts: "651180",
    valueOfReclassifiedAssets: 131139,
    depreciableAssets: 21,
  },
  {
    lat: 36.1026614,
    lng: -95.88287509999999,
    squareFootage: "89637",
    propertyType: "Office",
    totalCosts: "2650000",
    valueOfReclassifiedAssets: 866984,
    depreciableAssets: 39,
  },
  {
    lat: 40.7199785,
    lng: -84.15406589999999,
    squareFootage: "139694",
    propertyType: "Multifamily",
    totalCosts: "900512",
    valueOfReclassifiedAssets: 717478,
    depreciableAssets: 26,
  },
  {
    lat: 31.6995406,
    lng: -89.13725269999999,
    squareFootage: "88000",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 925985,
    depreciableAssets: 37,
  },
  {
    lat: 40.037745,
    lng: -75.5393155,
    squareFootage: "30150",
    propertyType: "Office",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 937912,
    depreciableAssets: 22,
  },
  {
    lat: 40.2284948,
    lng: -75.30623260000002,
    squareFootage: "115644",
    propertyType: "Office",
    totalCosts: "5500000",
    valueOfReclassifiedAssets: 941258,
    depreciableAssets: 18,
  },
  {
    lat: 40.2284948,
    lng: -75.30623260000002,
    squareFootage: "114433",
    propertyType: "Retail",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 628479,
    depreciableAssets: 22,
  },
  {
    lat: 35.0277847,
    lng: -89.9346659,
    squareFootage: "146000",
    propertyType: "Multifamily",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 1179110,
    depreciableAssets: 33,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "155292",
    valueOfReclassifiedAssets: 105774,
    depreciableAssets: 65,
  },
  {
    lat: 26.1916102,
    lng: -81.7021049,
    squareFootage: "195184",
    propertyType: "Multifamily",
    totalCosts: "34650000",
    valueOfReclassifiedAssets: 8542032,
    depreciableAssets: 29,
  },
  {
    lat: 45.5140983,
    lng: -122.4228701,
    squareFootage: "286618",
    propertyType: "Multifamily",
    totalCosts: "62800000",
    valueOfReclassifiedAssets: 15826993,
    depreciableAssets: 26,
  },
  {
    lat: 40.7443091,
    lng: -73.9418603,
    squareFootage: "22800",
    propertyType: "Industrial",
    totalCosts: "9500000",
    valueOfReclassifiedAssets: 355645,
    depreciableAssets: 4,
  },
  {
    lat: 40.7506343,
    lng: -73.8477874,
    squareFootage: "20000",
    propertyType: "Retail",
    totalCosts: "3821545",
    valueOfReclassifiedAssets: 3191371,
    depreciableAssets: 18,
  },
  {
    lat: 40.87347,
    lng: -73.8272029,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6112316",
    valueOfReclassifiedAssets: 5886052,
    depreciableAssets: 36,
  },
  {
    lat: 41.1319017,
    lng: -96.0573302,
    squareFootage: "24726",
    propertyType: "Multifamily",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 339383,
    depreciableAssets: 24,
  },
  {
    lat: 39.2593487,
    lng: -94.3770704,
    squareFootage: "65880",
    propertyType: "Multifamily",
    totalCosts: "4110000",
    valueOfReclassifiedAssets: 857611,
    depreciableAssets: 25,
  },
  {
    lat: 45.4501529,
    lng: -122.8849111,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "76821",
    valueOfReclassifiedAssets: 28066,
    depreciableAssets: 0,
  },
  {
    lat: 45.68944949999999,
    lng: -122.5829013,
    squareFootage: "57500",
    propertyType: "Multifamily",
    totalCosts: "7252000",
    valueOfReclassifiedAssets: 1723239,
    depreciableAssets: 28,
  },
  {
    lat: 45.4940477,
    lng: -122.7323946,
    squareFootage: "17290",
    propertyType: "Multifamily",
    totalCosts: "3285600",
    valueOfReclassifiedAssets: 562170,
    depreciableAssets: 21,
  },
  {
    lat: 45.4923824,
    lng: -122.8029665,
    squareFootage: "20472",
    propertyType: "Multifamily",
    totalCosts: "2672232",
    valueOfReclassifiedAssets: 572612,
    depreciableAssets: 25,
  },
  {
    lat: 36.2684007,
    lng: -115.2956118,
    squareFootage: "30172",
    propertyType: "Office",
    totalCosts: "9050000",
    valueOfReclassifiedAssets: 2125761,
    depreciableAssets: 27,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "15760",
    propertyType: "Multifamily",
    totalCosts: "1944444",
    valueOfReclassifiedAssets: 475385,
    depreciableAssets: 35,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "18903",
    propertyType: "Multifamily",
    totalCosts: "3055556",
    valueOfReclassifiedAssets: 711011,
    depreciableAssets: 31,
  },
  {
    lat: 47.9990269,
    lng: -122.1914014,
    squareFootage: "4535",
    propertyType: "Multifamily",
    totalCosts: "690000",
    valueOfReclassifiedAssets: 119467,
    depreciableAssets: 22,
  },
  {
    lat: 46.7417817,
    lng: -122.9490589,
    squareFootage: "4680",
    propertyType: "Multifamily",
    totalCosts: "310000",
    valueOfReclassifiedAssets: 77227,
    depreciableAssets: 29,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "217375",
    valueOfReclassifiedAssets: 116901,
    depreciableAssets: 51,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "255100",
    valueOfReclassifiedAssets: 246857,
    depreciableAssets: 5,
  },
  {
    lat: 47.9990269,
    lng: -122.1914014,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "22506",
    valueOfReclassifiedAssets: 6456,
    depreciableAssets: 0,
  },
  {
    lat: 46.7417817,
    lng: -122.9490589,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "77449",
    valueOfReclassifiedAssets: 48626,
    depreciableAssets: 44,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "141041",
    valueOfReclassifiedAssets: 95783,
    depreciableAssets: 32,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "69194",
    valueOfReclassifiedAssets: 43767,
    depreciableAssets: 0,
  },
  {
    lat: 39.3744588,
    lng: -76.745195,
    squareFootage: "3912",
    propertyType: "Office",
    totalCosts: "616724",
    valueOfReclassifiedAssets: 94903,
    depreciableAssets: 17,
  },
  {
    lat: 36.1258713,
    lng: -95.85121,
    squareFootage: "154586",
    propertyType: "Multifamily",
    totalCosts: "8250000",
    valueOfReclassifiedAssets: 2438798,
    depreciableAssets: 35,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "82064",
    propertyType: "Office",
    totalCosts: "8500000",
    valueOfReclassifiedAssets: 1720254,
    depreciableAssets: 24,
  },
  {
    lat: 33.4960213,
    lng: -84.3804888,
    squareFootage: "203646",
    propertyType: "Multifamily",
    totalCosts: "13460710",
    valueOfReclassifiedAssets: 3531759,
    depreciableAssets: 31,
  },
  {
    lat: 33.5213067,
    lng: -84.3226488,
    squareFootage: "115600",
    propertyType: "Multifamily",
    totalCosts: "9299047",
    valueOfReclassifiedAssets: 2407444,
    depreciableAssets: 30,
  },
  {
    lat: 33.7344959,
    lng: -84.1118458,
    squareFootage: "249920",
    propertyType: "Multifamily",
    totalCosts: "24510642",
    valueOfReclassifiedAssets: 4461397,
    depreciableAssets: 21,
  },
  {
    lat: 33.7220258,
    lng: -84.201528,
    squareFootage: "186084",
    propertyType: "Multifamily",
    totalCosts: "17223366",
    valueOfReclassifiedAssets: 3027042,
    depreciableAssets: 21,
  },
  {
    lat: 25.6085924,
    lng: -80.3380213,
    squareFootage: "432770",
    propertyType: "Multifamily",
    totalCosts: "42092550",
    valueOfReclassifiedAssets: 9689868,
    depreciableAssets: 26,
  },
  {
    lat: 25.6085924,
    lng: -80.3380213,
    squareFootage: "223601",
    propertyType: "Multifamily",
    totalCosts: "18941649",
    valueOfReclassifiedAssets: 2143835,
    depreciableAssets: 13,
  },
  {
    lat: 34.0971415,
    lng: -117.2940637,
    squareFootage: "14894",
    propertyType: "Office",
    totalCosts: "1375271",
    valueOfReclassifiedAssets: 195374,
    depreciableAssets: 18,
  },
  {
    lat: 39.03173750000001,
    lng: -104.9622498,
    squareFootage: "107044",
    propertyType: "Industrial",
    totalCosts: "11601236",
    valueOfReclassifiedAssets: 3089420,
    depreciableAssets: 29,
  },
  {
    lat: 40.7322535,
    lng: -73.9874105,
    squareFootage: "16683",
    propertyType: "Industrial",
    totalCosts: "2987710",
    valueOfReclassifiedAssets: 785808,
    depreciableAssets: 33,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "23845",
    propertyType: "Industrial",
    totalCosts: "4723597",
    valueOfReclassifiedAssets: 2056046,
    depreciableAssets: 60,
  },
  {
    lat: 47.2019421,
    lng: -122.4780244,
    squareFootage: "24693",
    propertyType: "Industrial",
    totalCosts: "3829584",
    valueOfReclassifiedAssets: 1483594,
    depreciableAssets: 54,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "8460",
    propertyType: "Industrial",
    totalCosts: "4398534",
    valueOfReclassifiedAssets: 2155628,
    depreciableAssets: 65,
  },
  {
    lat: 47.10795479999999,
    lng: -122.3114361,
    squareFootage: "15486",
    propertyType: "Industrial",
    totalCosts: "5395529",
    valueOfReclassifiedAssets: 1991067,
    depreciableAssets: 47,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "24048",
    propertyType: "Industrial",
    totalCosts: "4503857",
    valueOfReclassifiedAssets: 1957270,
    depreciableAssets: 61,
  },
  {
    lat: 47.230393,
    lng: -122.5426928,
    squareFootage: "31694",
    propertyType: "Industrial",
    totalCosts: "4422081",
    valueOfReclassifiedAssets: 997182,
    depreciableAssets: 30,
  },
  {
    lat: 35.3352529,
    lng: -80.7990185,
    squareFootage: "106715",
    propertyType: "Industrial",
    totalCosts: "9425460",
    valueOfReclassifiedAssets: 5567248,
    depreciableAssets: 70,
  },
  {
    lat: 38.1673072,
    lng: -85.49941919999999,
    squareFootage: "55628",
    propertyType: "Industrial",
    totalCosts: "5165045",
    valueOfReclassifiedAssets: 2492727,
    depreciableAssets: 63,
  },
  {
    lat: 43.415378,
    lng: -70.7356619,
    squareFootage: "33820",
    propertyType: "Industrial",
    totalCosts: "1050000",
    valueOfReclassifiedAssets: 122861,
    depreciableAssets: 29,
  },
  {
    lat: 40.7734855,
    lng: -73.12905959999999,
    squareFootage: "159070",
    propertyType: "Industrial",
    totalCosts: "28236219",
    valueOfReclassifiedAssets: 4903692,
    depreciableAssets: 19,
  },
  {
    lat: 40.7360384,
    lng: -73.2411506,
    squareFootage: "79560",
    propertyType: "Industrial",
    totalCosts: "10444559",
    valueOfReclassifiedAssets: 2262620,
    depreciableAssets: 25,
  },
  {
    lat: 42.7376689,
    lng: -84.5169757,
    squareFootage: "57622",
    propertyType: "Industrial",
    totalCosts: "3906619",
    valueOfReclassifiedAssets: 3418713,
    depreciableAssets: 24,
  },
  {
    lat: 40.7787902,
    lng: -73.9065883,
    squareFootage: "175200",
    propertyType: "Industrial",
    totalCosts: "76093660",
    valueOfReclassifiedAssets: 66524633,
    depreciableAssets: 14,
  },
  {
    lat: 41.7216048,
    lng: -71.2577233,
    squareFootage: "98153",
    propertyType: "Industrial",
    totalCosts: "9064406",
    valueOfReclassifiedAssets: 1773540,
    depreciableAssets: 21,
  },
  {
    lat: 32.5554376,
    lng: -84.8255097,
    squareFootage: "65175",
    propertyType: "Industrial",
    totalCosts: "4908121",
    valueOfReclassifiedAssets: 953755,
    depreciableAssets: 21,
  },
  {
    lat: 32.2425929,
    lng: -81.0533854,
    squareFootage: "85925",
    propertyType: "Industrial",
    totalCosts: "6389418",
    valueOfReclassifiedAssets: 1515927,
    depreciableAssets: 25,
  },
  {
    lat: 41.3755722,
    lng: -73.45337789999999,
    squareFootage: "83300",
    propertyType: "Industrial",
    totalCosts: "7788236",
    valueOfReclassifiedAssets: 2641880,
    depreciableAssets: 37,
  },
  {
    lat: 41.3755722,
    lng: -73.45337789999999,
    squareFootage: "68088",
    propertyType: "Industrial",
    totalCosts: "6397438",
    valueOfReclassifiedAssets: 1685325,
    depreciableAssets: 30,
  },
  {
    lat: 34.7692466,
    lng: -82.2365981,
    squareFootage: "98533",
    propertyType: "Industrial",
    totalCosts: "8471643",
    valueOfReclassifiedAssets: 1554647,
    depreciableAssets: 22,
  },
  {
    lat: 25.9003878,
    lng: -80.1484407,
    squareFootage: "2017",
    propertyType: "Industrial",
    totalCosts: "3823817",
    valueOfReclassifiedAssets: 406821,
    depreciableAssets: 18,
  },
  {
    lat: 41.1795084,
    lng: -73.8536683,
    squareFootage: "45560",
    propertyType: "Industrial",
    totalCosts: "6722971",
    valueOfReclassifiedAssets: 1893159,
    depreciableAssets: 36,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "60020",
    propertyType: "Industrial",
    totalCosts: "6895401",
    valueOfReclassifiedAssets: 871226,
    depreciableAssets: 14,
  },
  {
    lat: 43.3298149,
    lng: -87.913867,
    squareFootage: "66290",
    propertyType: "Industrial",
    totalCosts: "7888556",
    valueOfReclassifiedAssets: 1127534,
    depreciableAssets: 17,
  },
  {
    lat: 42.7539447,
    lng: -73.78308609999999,
    squareFootage: "87540",
    propertyType: "Industrial",
    totalCosts: "10186827",
    valueOfReclassifiedAssets: 1413415,
    depreciableAssets: 20,
  },
  {
    lat: 40.69978340000001,
    lng: -73.7860276,
    squareFootage: "105988",
    propertyType: "Industrial",
    totalCosts: "26191823",
    valueOfReclassifiedAssets: 2202179,
    depreciableAssets: 17,
  },
  {
    lat: 40.830167,
    lng: -74.12986839999999,
    squareFootage: "76846",
    propertyType: "Industrial",
    totalCosts: "9150381",
    valueOfReclassifiedAssets: 1101735,
    depreciableAssets: 14,
  },
  {
    lat: 39.2817753,
    lng: -76.69324379999999,
    squareFootage: "59475",
    propertyType: "Industrial",
    totalCosts: "4015904",
    valueOfReclassifiedAssets: 682554,
    depreciableAssets: 27,
  },
  {
    lat: 40.5417839,
    lng: -105.044052,
    squareFootage: "78025",
    propertyType: "Industrial",
    totalCosts: "9206359",
    valueOfReclassifiedAssets: 2438221,
    depreciableAssets: 29,
  },
  {
    lat: 33.9346756,
    lng: -84.59557319999999,
    squareFootage: "98966",
    propertyType: "Specialty",
    totalCosts: "5038403",
    valueOfReclassifiedAssets: 4806896,
    depreciableAssets: 23,
  },
  {
    lat: 34.8742328,
    lng: -82.33433749999999,
    squareFootage: "75037",
    propertyType: "Industrial",
    totalCosts: "9152387",
    valueOfReclassifiedAssets: 1706839,
    depreciableAssets: 21,
  },
  {
    lat: 42.5235097,
    lng: -70.89523369999999,
    squareFootage: "214065",
    propertyType: "Office",
    totalCosts: "69741244",
    valueOfReclassifiedAssets: 8816743,
    depreciableAssets: 14,
  },
  {
    lat: 32.23063640000001,
    lng: -80.7325287,
    squareFootage: "67250",
    propertyType: "Industrial",
    totalCosts: "14090285",
    valueOfReclassifiedAssets: 8935957,
    depreciableAssets: 71,
  },
  {
    lat: 35.56714230000001,
    lng: -82.5437614,
    squareFootage: "102924",
    propertyType: "Industrial",
    totalCosts: "12746221",
    valueOfReclassifiedAssets: 2768255,
    depreciableAssets: 26,
  },
  {
    lat: 40.9370006,
    lng: -74.1181234,
    squareFootage: "62867",
    propertyType: "Industrial",
    totalCosts: "6816686",
    valueOfReclassifiedAssets: 291783,
    depreciableAssets: 23,
  },
  {
    lat: 25.8728864,
    lng: -81.6692532,
    squareFootage: "24858",
    propertyType: "Industrial",
    totalCosts: "2442305",
    valueOfReclassifiedAssets: 395900,
    depreciableAssets: 23,
  },
  {
    lat: 25.839203,
    lng: -80.2823063,
    squareFootage: "128950",
    propertyType: "Industrial",
    totalCosts: "16612967",
    valueOfReclassifiedAssets: 2684487,
    depreciableAssets: 20,
  },
  {
    lat: 34.1421988,
    lng: -77.91674569999999,
    squareFootage: "220755",
    propertyType: "Industrial",
    totalCosts: "26400715",
    valueOfReclassifiedAssets: 4050565,
    depreciableAssets: 18,
  },
  {
    lat: 34.1421988,
    lng: -77.91674569999999,
    squareFootage: "20900",
    propertyType: "Industrial",
    totalCosts: "3164554",
    valueOfReclassifiedAssets: 859537,
    depreciableAssets: 33,
  },
  {
    lat: 34.3023653,
    lng: -77.9852853,
    squareFootage: "87030",
    propertyType: "Industrial",
    totalCosts: "9528297",
    valueOfReclassifiedAssets: 2005627,
    depreciableAssets: 23,
  },
  {
    lat: 34.2577465,
    lng: -78.0880129,
    squareFootage: "79590",
    propertyType: "Industrial",
    totalCosts: "5896476",
    valueOfReclassifiedAssets: 1255042,
    depreciableAssets: 26,
  },
  {
    lat: 32.23063640000001,
    lng: -80.7325287,
    squareFootage: "60000",
    propertyType: "Industrial",
    totalCosts: "8991720",
    valueOfReclassifiedAssets: 2424961,
    depreciableAssets: 31,
  },
  {
    lat: 40.7734855,
    lng: -73.12905959999999,
    squareFootage: "159070",
    propertyType: "Industrial",
    totalCosts: "28236219",
    valueOfReclassifiedAssets: 4882063,
    depreciableAssets: 19,
  },
  {
    lat: 29.1469427,
    lng: -95.67735119999999,
    squareFootage: "36514",
    propertyType: "Multifamily",
    totalCosts: "2200000",
    valueOfReclassifiedAssets: 616294,
    depreciableAssets: 33,
  },
  {
    lat: 29.8942825,
    lng: -97.9810845,
    squareFootage: "85962",
    propertyType: "Multifamily",
    totalCosts: "8450000",
    valueOfReclassifiedAssets: 2218105,
    depreciableAssets: 31,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "114213",
    propertyType: "Multifamily",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 1146743,
    depreciableAssets: 30,
  },
  {
    lat: 42.6823182,
    lng: -73.8477874,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "16669242",
    valueOfReclassifiedAssets: 11746656,
    depreciableAssets: 30,
  },
  {
    lat: 42.6823182,
    lng: -73.8477874,
    squareFootage: "116158",
    propertyType: "Hotel",
    totalCosts: "12350000",
    valueOfReclassifiedAssets: 2654100,
    depreciableAssets: 25,
  },
  {
    lat: 39.7393313,
    lng: -104.8629746,
    squareFootage: "74232",
    propertyType: "Multifamily",
    totalCosts: "10075822",
    valueOfReclassifiedAssets: 1309254,
    depreciableAssets: 16,
  },
  {
    lat: 40.0005378,
    lng: -105.2077798,
    squareFootage: "7292",
    propertyType: "Multifamily",
    totalCosts: "2757773",
    valueOfReclassifiedAssets: 491828,
    depreciableAssets: 28,
  },
  {
    lat: 39.9583587,
    lng: -75.1953934,
    squareFootage: "3702",
    propertyType: "Multifamily",
    totalCosts: "1018031",
    valueOfReclassifiedAssets: 129137,
    depreciableAssets: 15,
  },
  {
    lat: 39.9583587,
    lng: -75.1953934,
    squareFootage: "4620",
    propertyType: "Multifamily",
    totalCosts: "1183577",
    valueOfReclassifiedAssets: 163735,
    depreciableAssets: 17,
  },
  {
    lat: 33.69856499999999,
    lng: -84.54318669999999,
    squareFootage: "87140",
    propertyType: "Multifamily",
    totalCosts: "5410000",
    valueOfReclassifiedAssets: 1371533,
    depreciableAssets: 30,
  },
  {
    lat: 33.6958943,
    lng: -84.238417,
    squareFootage: "108678",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1776203,
    depreciableAssets: 30,
  },
  {
    lat: 33.7234034,
    lng: -84.47501369999999,
    squareFootage: "127211",
    propertyType: "Multifamily",
    totalCosts: "5174000",
    valueOfReclassifiedAssets: 1333599,
    depreciableAssets: 30,
  },
  {
    lat: 33.6392536,
    lng: -83.985008,
    squareFootage: "69258",
    propertyType: "Multifamily",
    totalCosts: "7170000",
    valueOfReclassifiedAssets: 1823795,
    depreciableAssets: 30,
  },
  {
    lat: 30.3291747,
    lng: -87.39099569999999,
    squareFootage: "220762",
    propertyType: "Multifamily",
    totalCosts: "13125000",
    valueOfReclassifiedAssets: 2985055,
    depreciableAssets: 26,
  },
  {
    lat: 33.9448436,
    lng: -83.4323375,
    squareFootage: "104748",
    propertyType: "Multifamily",
    totalCosts: "7700000",
    valueOfReclassifiedAssets: 1643241,
    depreciableAssets: 25,
  },
  {
    lat: 33.5528023,
    lng: -84.4015075,
    squareFootage: "76366",
    propertyType: "Multifamily",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 797060,
    depreciableAssets: 22,
  },
  {
    lat: 33.6832181,
    lng: -84.44877199999999,
    squareFootage: "43782",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 484303,
    depreciableAssets: 25,
  },
  {
    lat: 39.160078,
    lng: -94.53150289999999,
    squareFootage: "77600",
    propertyType: "Multifamily",
    totalCosts: "5350000",
    valueOfReclassifiedAssets: 1193254,
    depreciableAssets: 26,
  },
  {
    lat: 34.8742328,
    lng: -82.33433749999999,
    squareFootage: "131986",
    propertyType: "Multifamily",
    totalCosts: "9455175",
    valueOfReclassifiedAssets: 2462275,
    depreciableAssets: 33,
  },
  {
    lat: 30.2775931,
    lng: -81.7239979,
    squareFootage: "159453",
    propertyType: "Multifamily",
    totalCosts: "10784705",
    valueOfReclassifiedAssets: 2451399,
    depreciableAssets: 27,
  },
  {
    lat: 34.99474410000001,
    lng: -85.2256635,
    squareFootage: "117485",
    propertyType: "Multifamily",
    totalCosts: "6592683",
    valueOfReclassifiedAssets: 1313344,
    depreciableAssets: 21,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "172912",
    propertyType: "Multifamily",
    totalCosts: "16216189",
    valueOfReclassifiedAssets: 3292108,
    depreciableAssets: 23,
  },
  {
    lat: 29.6577485,
    lng: -82.3479016,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "200485",
    valueOfReclassifiedAssets: 85641,
    depreciableAssets: 59,
  },
  {
    lat: 33.4551831,
    lng: -81.7568253,
    squareFootage: "95004",
    propertyType: "Multifamily",
    totalCosts: "7729749",
    valueOfReclassifiedAssets: 2004276,
    depreciableAssets: 35,
  },
  {
    lat: 36.174626,
    lng: -94.182119,
    squareFootage: "156099",
    propertyType: "Industrial",
    totalCosts: "5050000",
    valueOfReclassifiedAssets: 420277,
    depreciableAssets: 10,
  },
  {
    lat: 39.0533891,
    lng: -76.9758274,
    squareFootage: "432000",
    propertyType: "Multifamily",
    totalCosts: "85635000",
    valueOfReclassifiedAssets: 20541623,
    depreciableAssets: 29,
  },
  {
    lat: 27.8435655,
    lng: -82.70773539999999,
    squareFootage: "234774",
    propertyType: "Multifamily",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 6673323,
    depreciableAssets: 29,
  },
  {
    lat: 27.9046811,
    lng: -82.7616822,
    squareFootage: "113100",
    propertyType: "Multifamily",
    totalCosts: "13400000",
    valueOfReclassifiedAssets: 2640844,
    depreciableAssets: 26,
  },
  {
    lat: 28.5775287,
    lng: -81.4388858,
    squareFootage: "166608",
    propertyType: "Multifamily",
    totalCosts: "13050000",
    valueOfReclassifiedAssets: 2582253,
    depreciableAssets: 26,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "377000",
    propertyType: "Multifamily",
    totalCosts: "16892000",
    valueOfReclassifiedAssets: 8247919,
    depreciableAssets: 65,
  },
  {
    lat: 32.7762864,
    lng: -96.6958939,
    squareFootage: "185146",
    propertyType: "Multifamily",
    totalCosts: "7659000",
    valueOfReclassifiedAssets: 1717409,
    depreciableAssets: 27,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "62520",
    propertyType: "Multifamily",
    totalCosts: "5850000",
    valueOfReclassifiedAssets: 1540908,
    depreciableAssets: 31,
  },
  {
    lat: 32.8621631,
    lng: -96.8726295,
    squareFootage: "244946",
    propertyType: "Multifamily",
    totalCosts: "23700000",
    valueOfReclassifiedAssets: 7096995,
    depreciableAssets: 35,
  },
  {
    lat: 32.8237425,
    lng: -96.9584922,
    squareFootage: "95084",
    propertyType: "Multifamily",
    totalCosts: "9100000",
    valueOfReclassifiedAssets: 2843976,
    depreciableAssets: 37,
  },
  {
    lat: 32.9174964,
    lng: -96.679851,
    squareFootage: "85168",
    propertyType: "Multifamily",
    totalCosts: "7080000",
    valueOfReclassifiedAssets: 1856563,
    depreciableAssets: 29,
  },
  {
    lat: 32.8778842,
    lng: -96.64952199999999,
    squareFootage: "76450",
    propertyType: "Multifamily",
    totalCosts: "4880000",
    valueOfReclassifiedAssets: 1146780,
    depreciableAssets: 28,
  },
  {
    lat: 33.852666,
    lng: -80.3547286,
    squareFootage: "13376",
    propertyType: "Industrial",
    totalCosts: "1661207",
    valueOfReclassifiedAssets: 636995,
    depreciableAssets: 42,
  },
  {
    lat: 43.1029061,
    lng: -82.49693309999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 1507283,
    depreciableAssets: 27,
  },
  {
    lat: 33.027733,
    lng: -96.6103778,
    squareFootage: "14500",
    propertyType: "Retail",
    totalCosts: "6500000",
    valueOfReclassifiedAssets: 2246736,
    depreciableAssets: 41,
  },
  {
    lat: 33.1044628,
    lng: -96.642433,
    squareFootage: "8218",
    propertyType: "Retail",
    totalCosts: "3660000",
    valueOfReclassifiedAssets: 1029762,
    depreciableAssets: 33,
  },
  {
    lat: 32.99409,
    lng: -97.3138554,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 684986,
    depreciableAssets: 33,
  },
  {
    lat: 33.2045596,
    lng: -97.06059979999999,
    squareFootage: "22106",
    propertyType: "Multifamily",
    totalCosts: "2626000",
    valueOfReclassifiedAssets: 522583,
    depreciableAssets: 24,
  },
  {
    lat: 32.5989444,
    lng: -96.85654269999999,
    squareFootage: "112144",
    propertyType: "Hotel",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1675532,
    depreciableAssets: 31,
  },
  {
    lat: 38.7492726,
    lng: -77.57340479999999,
    squareFootage: "158082",
    propertyType: "Retail",
    totalCosts: "78500000",
    valueOfReclassifiedAssets: 19283077,
    depreciableAssets: 29,
  },
  {
    lat: 39.0129178,
    lng: -77.39560039999999,
    squareFootage: "188109",
    propertyType: "Retail",
    totalCosts: "44800000",
    valueOfReclassifiedAssets: 13391207,
    depreciableAssets: 35,
  },
  {
    lat: 34.2864053,
    lng: -118.3111262,
    squareFootage: "10780",
    propertyType: "Multifamily",
    totalCosts: "2152760",
    valueOfReclassifiedAssets: 540629,
    depreciableAssets: 33,
  },
  {
    lat: 34.208195,
    lng: -118.3987842,
    squareFootage: "44421",
    propertyType: "Industrial",
    totalCosts: "3905332",
    valueOfReclassifiedAssets: 410385,
    depreciableAssets: 14,
  },
  {
    lat: 34.3023759,
    lng: -118.3695699,
    squareFootage: "37193",
    propertyType: "Industrial",
    totalCosts: "6600000",
    valueOfReclassifiedAssets: 1478336,
    depreciableAssets: 26,
  },
  {
    lat: 34.1658804,
    lng: -118.3637264,
    squareFootage: "1500",
    propertyType: "Retail",
    totalCosts: "1067000",
    valueOfReclassifiedAssets: 133003,
    depreciableAssets: 17,
  },
  {
    lat: 34.1658804,
    lng: -118.3637264,
    squareFootage: "5000",
    propertyType: "Retail",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 238947,
    depreciableAssets: 11,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "18936",
    propertyType: "Office",
    totalCosts: "2571433",
    valueOfReclassifiedAssets: 2145836,
    depreciableAssets: 20,
  },
  {
    lat: 34.3023759,
    lng: -118.3695699,
    squareFootage: "20717",
    propertyType: "Industrial",
    totalCosts: "2441593",
    valueOfReclassifiedAssets: 467683,
    depreciableAssets: 21,
  },
  {
    lat: 34.1615135,
    lng: -118.3403506,
    squareFootage: "5877",
    propertyType: "Retail",
    totalCosts: "966300",
    valueOfReclassifiedAssets: 50254,
    depreciableAssets: 7,
  },
  {
    lat: 34.1615135,
    lng: -118.3403506,
    squareFootage: "5877",
    propertyType: "Retail",
    totalCosts: "2805118",
    valueOfReclassifiedAssets: 781311,
    depreciableAssets: 38,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "13838",
    propertyType: "Multifamily",
    totalCosts: "3258363",
    valueOfReclassifiedAssets: 560047,
    depreciableAssets: 24,
  },
  {
    lat: 34.2053511,
    lng: -118.3286614,
    squareFootage: "6050",
    propertyType: "Retail",
    totalCosts: "1625000",
    valueOfReclassifiedAssets: 155376,
    depreciableAssets: 13,
  },
  {
    lat: 34.1366259,
    lng: -118.3987842,
    squareFootage: "1900",
    propertyType: "Specialty",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 267650,
    depreciableAssets: 40,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "124382",
    propertyType: "Multifamily",
    totalCosts: "7900000",
    valueOfReclassifiedAssets: 1850141,
    depreciableAssets: 27,
  },
  {
    lat: 33.5282033,
    lng: -112.2205183,
    squareFootage: "120880",
    propertyType: "Multifamily",
    totalCosts: "7949688",
    valueOfReclassifiedAssets: 2733876,
    depreciableAssets: 38,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "260128",
    propertyType: "Multifamily",
    totalCosts: "28675000",
    valueOfReclassifiedAssets: 8085513,
    depreciableAssets: 31,
  },
  {
    lat: 32.7099886,
    lng: -96.6745042,
    squareFootage: "218804",
    propertyType: "Multifamily",
    totalCosts: "16800000",
    valueOfReclassifiedAssets: 5077815,
    depreciableAssets: 33,
  },
  {
    lat: 38.9156487,
    lng: -77.0737149,
    squareFootage: "1300",
    propertyType: "Retail",
    totalCosts: "1231561",
    valueOfReclassifiedAssets: 91816,
    depreciableAssets: 18,
  },
  {
    lat: 38.9875145,
    lng: -77.0737149,
    squareFootage: "5561",
    propertyType: "Office",
    totalCosts: "2505018",
    valueOfReclassifiedAssets: 1998513,
    depreciableAssets: 100,
  },
  {
    lat: 38.9052763,
    lng: -76.98158769999999,
    squareFootage: "2124",
    propertyType: "Office",
    totalCosts: "1733582",
    valueOfReclassifiedAssets: 125428,
    depreciableAssets: 11,
  },
  {
    lat: 41.1362185,
    lng: -81.4828198,
    squareFootage: "86210",
    propertyType: "Retail",
    totalCosts: "9900000",
    valueOfReclassifiedAssets: 2436942,
    depreciableAssets: 29,
  },
  {
    lat: 33.5784347,
    lng: -117.1617685,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "36495129",
    valueOfReclassifiedAssets: 33354329,
    depreciableAssets: 14,
  },
  {
    lat: 39.7909447,
    lng: -84.21207,
    squareFootage: "35032",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "1895974",
    valueOfReclassifiedAssets: 551062,
    depreciableAssets: 34,
  },
  {
    lat: 39.8833139,
    lng: -95.2475518,
    squareFootage: "14789",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "121038",
    valueOfReclassifiedAssets: 31678,
    depreciableAssets: 29,
  },
  {
    lat: 39.0063606,
    lng: -94.7381013,
    squareFootage: "53900",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15619590",
    valueOfReclassifiedAssets: 2796639,
    depreciableAssets: 21,
  },
  {
    lat: 39.1476313,
    lng: -95.9621106,
    squareFootage: "27234",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2546754",
    valueOfReclassifiedAssets: 750242,
    depreciableAssets: 35,
  },
  {
    lat: 35.2146586,
    lng: -80.8599193,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1065000",
    valueOfReclassifiedAssets: 369559,
    depreciableAssets: 41,
  },
  {
    lat: 35.2413572,
    lng: -80.7103532,
    squareFootage: "183594",
    propertyType: "Retail",
    totalCosts: "16875561",
    valueOfReclassifiedAssets: 4696753,
    depreciableAssets: 32,
  },
  {
    lat: 35.2413572,
    lng: -80.7103532,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1643309",
    valueOfReclassifiedAssets: 1336357,
    depreciableAssets: 23,
  },
  {
    lat: 35.2413572,
    lng: -80.7103532,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1479124",
    valueOfReclassifiedAssets: 1152600,
    depreciableAssets: 24,
  },
  {
    lat: 35.2413572,
    lng: -80.7103532,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2681206",
    valueOfReclassifiedAssets: 2620594,
    depreciableAssets: 16,
  },
  {
    lat: 35.2146586,
    lng: -80.8599193,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "632665",
    valueOfReclassifiedAssets: 619715,
    depreciableAssets: 11,
  },
  {
    lat: 35.195314,
    lng: -80.7491563,
    squareFootage: "139995",
    propertyType: "Multifamily",
    totalCosts: "13812256",
    valueOfReclassifiedAssets: 3691188,
    depreciableAssets: 30,
  },
  {
    lat: 35.195314,
    lng: -80.7491563,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "705277",
    valueOfReclassifiedAssets: 649141,
    depreciableAssets: 15,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "99280",
    propertyType: "Office",
    totalCosts: "22000000",
    valueOfReclassifiedAssets: 2712925,
    depreciableAssets: 24,
  },
  {
    lat: 41.0461965,
    lng: -74.8039205,
    squareFootage: "40000",
    propertyType: "Multifamily",
    totalCosts: "5505000",
    valueOfReclassifiedAssets: 1149554,
    depreciableAssets: 24,
  },
  {
    lat: 35.7085641,
    lng: -97.4165053,
    squareFootage: "35312",
    propertyType: "Retail",
    totalCosts: "4147000",
    valueOfReclassifiedAssets: 1096485,
    depreciableAssets: 31,
  },
  {
    lat: 36.7597999,
    lng: -111.3798928,
    squareFootage: "64367",
    propertyType: "Hotel",
    totalCosts: "13634211",
    valueOfReclassifiedAssets: 2780451,
    depreciableAssets: 24,
  },
  {
    lat: 38.3437823,
    lng: -122.7011951,
    squareFootage: "55387",
    propertyType: "Hotel",
    totalCosts: "12927125",
    valueOfReclassifiedAssets: 3057625,
    depreciableAssets: 31,
  },
  {
    lat: 40.3635408,
    lng: -74.0887576,
    squareFootage: "4832",
    propertyType: "Retail",
    totalCosts: "1090666",
    valueOfReclassifiedAssets: 982281,
    depreciableAssets: 24,
  },
  {
    lat: 40.3635408,
    lng: -74.0887576,
    squareFootage: "11064",
    propertyType: "Multifamily",
    totalCosts: "1938962",
    valueOfReclassifiedAssets: 1691617,
    depreciableAssets: 0,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "477902",
    valueOfReclassifiedAssets: 406208,
    depreciableAssets: 0,
  },
  {
    lat: 40.3635408,
    lng: -74.0887576,
    squareFootage: "4953",
    propertyType: "Retail",
    totalCosts: "990000",
    valueOfReclassifiedAssets: 185518,
    depreciableAssets: 22,
  },
  {
    lat: 40.5702869,
    lng: -74.54046939999999,
    squareFootage: "7405",
    propertyType: "Retail",
    totalCosts: "600470",
    valueOfReclassifiedAssets: 92429,
    depreciableAssets: 19,
  },
  {
    lat: 40.5702869,
    lng: -74.54046939999999,
    squareFootage: "7405",
    propertyType: "Retail",
    totalCosts: "600472",
    valueOfReclassifiedAssets: 61848,
    depreciableAssets: 12,
  },
  {
    lat: 40.8786991,
    lng: -73.4062342,
    squareFootage: "13161",
    propertyType: "Multifamily",
    totalCosts: "1479492",
    valueOfReclassifiedAssets: 1141018,
    depreciableAssets: 25,
  },
  {
    lat: 41.0529585,
    lng: -73.53879909999999,
    squareFootage: "23848",
    propertyType: "Multifamily",
    totalCosts: "3324050",
    valueOfReclassifiedAssets: 786541,
    depreciableAssets: 27,
  },
  {
    lat: 29.4854054,
    lng: -98.4513272,
    squareFootage: "258446",
    propertyType: "Multifamily",
    totalCosts: "8280196",
    valueOfReclassifiedAssets: 2127641,
    depreciableAssets: 30,
  },
  {
    lat: 29.4854054,
    lng: -98.4513272,
    squareFootage: "258446",
    propertyType: "Multifamily",
    totalCosts: "33019804",
    valueOfReclassifiedAssets: 8484619,
    depreciableAssets: 30,
  },
  {
    lat: 27.3681789,
    lng: -82.54563739999999,
    squareFootage: "66430",
    propertyType: "Multifamily",
    totalCosts: "5990000",
    valueOfReclassifiedAssets: 1305879,
    depreciableAssets: 26,
  },
  {
    lat: 37.9817391,
    lng: -84.485507,
    squareFootage: "208458",
    propertyType: "Multifamily",
    totalCosts: "13150000",
    valueOfReclassifiedAssets: 2644019,
    depreciableAssets: 24,
  },
  {
    lat: 32.9087285,
    lng: -96.7386946,
    squareFootage: "139689",
    propertyType: "Multifamily",
    totalCosts: "16750000",
    valueOfReclassifiedAssets: 4878877,
    depreciableAssets: 34,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "966652",
    valueOfReclassifiedAssets: 805898,
    depreciableAssets: 0,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "322287",
    valueOfReclassifiedAssets: 268691,
    depreciableAssets: 0,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "522087",
    valueOfReclassifiedAssets: 435264,
    depreciableAssets: 0,
  },
  {
    lat: 40.6788319,
    lng: -73.9506774,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "941490",
    valueOfReclassifiedAssets: 784920,
    depreciableAssets: 0,
  },
  {
    lat: 37.9923606,
    lng: -84.375233,
    squareFootage: "184427",
    propertyType: "Office",
    totalCosts: "2973524",
    valueOfReclassifiedAssets: 675431,
    depreciableAssets: 26,
  },
  {
    lat: 37.9923606,
    lng: -84.375233,
    squareFootage: "85538",
    propertyType: "Office",
    totalCosts: "1484533",
    valueOfReclassifiedAssets: 401748,
    depreciableAssets: 32,
  },
  {
    lat: 39.6779549,
    lng: -83.91089680000002,
    squareFootage: "14551",
    propertyType: "Office",
    totalCosts: "1076681",
    valueOfReclassifiedAssets: 213513,
    depreciableAssets: 24,
  },
  {
    lat: 31.8305871,
    lng: -81.6254283,
    squareFootage: "329399",
    propertyType: "Multifamily",
    totalCosts: "25975000",
    valueOfReclassifiedAssets: 7698734,
    depreciableAssets: 33,
  },
  {
    lat: 41.1926318,
    lng: -80.0758425,
    squareFootage: "32832",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 557097,
    depreciableAssets: 33,
  },
  {
    lat: 41.156024,
    lng: -80.4660256,
    squareFootage: "124400",
    propertyType: "Multifamily",
    totalCosts: "5600000",
    valueOfReclassifiedAssets: 1260693,
    depreciableAssets: 26,
  },
  {
    lat: 40.9845896,
    lng: -74.1063776,
    squareFootage: "3961",
    propertyType: "Multifamily",
    totalCosts: "1550000",
    valueOfReclassifiedAssets: 365428,
    depreciableAssets: 27,
  },
  {
    lat: 40.9845896,
    lng: -74.1063776,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2556961",
    valueOfReclassifiedAssets: 2141719,
    depreciableAssets: 33,
  },
  {
    lat: 40.7388429,
    lng: -112.1072528,
    squareFootage: "3950",
    propertyType: "Specialty",
    totalCosts: "776395",
    valueOfReclassifiedAssets: 280283,
    depreciableAssets: 43,
  },
  {
    lat: 41.6070997,
    lng: -70.910096,
    squareFootage: "9180",
    propertyType: "Retail",
    totalCosts: "731674",
    valueOfReclassifiedAssets: 106407,
    depreciableAssets: 17,
  },
  {
    lat: 33.6630087,
    lng: -84.75244819999999,
    squareFootage: "4979",
    propertyType: "Specialty",
    totalCosts: "816931",
    valueOfReclassifiedAssets: 340403,
    depreciableAssets: 49,
  },
  {
    lat: 33.89777370000001,
    lng: -118.1649291,
    squareFootage: "58555",
    propertyType: "Specialty",
    totalCosts: "8231801",
    valueOfReclassifiedAssets: 900485,
    depreciableAssets: 13,
  },
  {
    lat: 40.8876273,
    lng: -74.04763779999999,
    squareFootage: "108000",
    propertyType: "Multifamily",
    totalCosts: "10000000",
    valueOfReclassifiedAssets: 889904,
    depreciableAssets: 10,
  },
  {
    lat: 27.767874,
    lng: -97.4002872,
    squareFootage: "40694",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6800000",
    valueOfReclassifiedAssets: 2321443,
    depreciableAssets: 37,
  },
  {
    lat: 39.0542102,
    lng: -77.1197521,
    squareFootage: "13725",
    propertyType: "Office",
    totalCosts: "626415",
    valueOfReclassifiedAssets: 81975,
    depreciableAssets: 18,
  },
  {
    lat: 39.0542102,
    lng: -77.1197521,
    squareFootage: "13725",
    propertyType: "Office",
    totalCosts: "2712776",
    valueOfReclassifiedAssets: 355007,
    depreciableAssets: 18,
  },
  {
    lat: 33.02087160000001,
    lng: -96.7386946,
    squareFootage: "94643",
    propertyType: "Multifamily",
    totalCosts: "12565181",
    valueOfReclassifiedAssets: 3168722,
    depreciableAssets: 30,
  },
  {
    lat: 33.4366655,
    lng: -111.9403254,
    squareFootage: "28601",
    propertyType: "Multifamily",
    totalCosts: "3827802",
    valueOfReclassifiedAssets: 3229430,
    depreciableAssets: 30,
  },
  {
    lat: 35.3301529,
    lng: -80.7325287,
    squareFootage: "42481",
    propertyType: "Retail",
    totalCosts: "9943000",
    valueOfReclassifiedAssets: 3119902,
    depreciableAssets: 37,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "7850",
    propertyType: "Multifamily",
    totalCosts: "7418000",
    valueOfReclassifiedAssets: 709956,
    depreciableAssets: 11,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "4230",
    propertyType: "Retail",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 314637,
    depreciableAssets: 16,
  },
  {
    lat: 39.6529824,
    lng: -86.9423801,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 1140175,
    depreciableAssets: 53,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "282490",
    propertyType: "Office",
    totalCosts: "29550000",
    valueOfReclassifiedAssets: 8397243,
    depreciableAssets: 33,
  },
  {
    lat: 29.240991,
    lng: -81.06994619999999,
    squareFootage: "108800",
    propertyType: "Multifamily",
    totalCosts: "9350000",
    valueOfReclassifiedAssets: 1915089,
    depreciableAssets: 24,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "43000",
    propertyType: "Industrial",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 906696,
    depreciableAssets: 16,
  },
  {
    lat: 41.44780009999999,
    lng: -74.0887576,
    squareFootage: "40000",
    propertyType: "Industrial",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 308310,
    depreciableAssets: 16,
  },
  {
    lat: 40.7093358,
    lng: -73.9565551,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1301717",
    valueOfReclassifiedAssets: 980418,
    depreciableAssets: 0,
  },
  {
    lat: 35.9046545,
    lng: -86.7718017,
    squareFootage: "146067",
    propertyType: "Industrial",
    totalCosts: "12200000",
    valueOfReclassifiedAssets: 4014463,
    depreciableAssets: 38,
  },
  {
    lat: 40.6795939,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4439139",
    valueOfReclassifiedAssets: 3837685,
    depreciableAssets: 15,
  },
  {
    lat: 40.7734855,
    lng: -73.12905959999999,
    squareFootage: "22000",
    propertyType: "Industrial",
    totalCosts: "2424650",
    valueOfReclassifiedAssets: 2206219,
    depreciableAssets: 26,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "954599",
    valueOfReclassifiedAssets: 793690,
    depreciableAssets: 27,
  },
  {
    lat: 31.2591019,
    lng: -100.3048897,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "16418379",
    valueOfReclassifiedAssets: 4208667,
    depreciableAssets: 30,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "4801",
    propertyType: "Office",
    totalCosts: "6236000",
    valueOfReclassifiedAssets: 4913573,
    depreciableAssets: 0,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "60800",
    propertyType: "Multifamily",
    totalCosts: "4510000",
    valueOfReclassifiedAssets: 1084153,
    depreciableAssets: 28,
  },
  {
    lat: 40.1982911,
    lng: -75.5393155,
    squareFootage: "28750",
    propertyType: "Retail",
    totalCosts: "8181484",
    valueOfReclassifiedAssets: 2211654,
    depreciableAssets: 32,
  },
  {
    lat: 36.8655961,
    lng: -87.4885984,
    squareFootage: "37970",
    propertyType: "Multifamily",
    totalCosts: "1210000",
    valueOfReclassifiedAssets: 283195,
    depreciableAssets: 28,
  },
  {
    lat: 33.0026132,
    lng: -79.99759159999999,
    squareFootage: "53236",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 1117113,
    depreciableAssets: 35,
  },
  {
    lat: 26.3003637,
    lng: -80.1819268,
    squareFootage: "69936",
    propertyType: "Retail",
    totalCosts: "10100000",
    valueOfReclassifiedAssets: 3607526,
    depreciableAssets: 42,
  },
  {
    lat: 40.8267932,
    lng: -74.3176876,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "8422393",
    valueOfReclassifiedAssets: 8141538,
    depreciableAssets: 33,
  },
  {
    lat: 39.42052959999999,
    lng: -76.79135579999999,
    squareFootage: "423720",
    propertyType: "Multifamily",
    totalCosts: "50000000",
    valueOfReclassifiedAssets: 11091256,
    depreciableAssets: 26,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "432128",
    propertyType: "Multifamily",
    totalCosts: "11200000",
    valueOfReclassifiedAssets: 3327249,
    depreciableAssets: 33,
  },
  {
    lat: 32.6711817,
    lng: -96.8726295,
    squareFootage: "312180",
    propertyType: "Multifamily",
    totalCosts: "22100000",
    valueOfReclassifiedAssets: 4779333,
    depreciableAssets: 25,
  },
  {
    lat: 32.3782774,
    lng: -96.7708138,
    squareFootage: "98319",
    propertyType: "Multifamily",
    totalCosts: "8305000",
    valueOfReclassifiedAssets: 1944694,
    depreciableAssets: 28,
  },
  {
    lat: 32.9117167,
    lng: -96.9907197,
    squareFootage: "163664",
    propertyType: "Multifamily",
    totalCosts: "30010000",
    valueOfReclassifiedAssets: 8539189,
    depreciableAssets: 33,
  },
  {
    lat: 40.7758541,
    lng: -74.16509909999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "70500000",
    valueOfReclassifiedAssets: 8001688,
    depreciableAssets: 13,
  },
  {
    lat: 40.7329808,
    lng: -74.0711359,
    squareFootage: "192937",
    propertyType: "Office",
    totalCosts: "31000000",
    valueOfReclassifiedAssets: 5143141,
    depreciableAssets: 18,
  },
  {
    lat: 33.5690908,
    lng: -112.1728283,
    squareFootage: "109962",
    propertyType: "Multifamily",
    totalCosts: "19750000",
    valueOfReclassifiedAssets: 4744221,
    depreciableAssets: 28,
  },
  {
    lat: 40.58681139999999,
    lng: -74.69858909999999,
    squareFootage: "12800",
    propertyType: "Multifamily",
    totalCosts: "765000",
    valueOfReclassifiedAssets: 638016,
    depreciableAssets: 30,
  },
  {
    lat: 40.7350747,
    lng: -74.17390569999999,
    squareFootage: "16000",
    propertyType: "Multifamily",
    totalCosts: "807400",
    valueOfReclassifiedAssets: 87364,
    depreciableAssets: 14,
  },
  {
    lat: 40.0441439,
    lng: -82.9609096,
    squareFootage: "108992",
    propertyType: "Multifamily",
    totalCosts: "1009044",
    valueOfReclassifiedAssets: 305238,
    depreciableAssets: 34,
  },
  {
    lat: 39.9576567,
    lng: -82.9662859,
    squareFootage: "65360",
    propertyType: "Multifamily",
    totalCosts: "665042",
    valueOfReclassifiedAssets: 236756,
    depreciableAssets: 40,
  },
  {
    lat: 40.5090949,
    lng: -74.27661859999999,
    squareFootage: "14000",
    propertyType: "Multifamily",
    totalCosts: "780666",
    valueOfReclassifiedAssets: 146107,
    depreciableAssets: 20,
  },
  {
    lat: 38.9583005,
    lng: -104.7812654,
    squareFootage: "45450",
    propertyType: "Multifamily",
    totalCosts: "7550000",
    valueOfReclassifiedAssets: 2281907,
    depreciableAssets: 35,
  },
  {
    lat: 40.6986257,
    lng: -73.6241855,
    squareFootage: "49952",
    propertyType: "Multifamily",
    totalCosts: "8800000",
    valueOfReclassifiedAssets: 1519130,
    depreciableAssets: 20,
  },
  {
    lat: 41.1139452,
    lng: -74.04763779999999,
    squareFootage: "46765",
    propertyType: "Multifamily",
    totalCosts: "2461386",
    valueOfReclassifiedAssets: 2174510,
    depreciableAssets: 22,
  },
  {
    lat: 35.7482425,
    lng: -78.5547283,
    squareFootage: "129602",
    propertyType: "Multifamily",
    totalCosts: "13350000",
    valueOfReclassifiedAssets: 3715669,
    depreciableAssets: 33,
  },
  {
    lat: 33.9025669,
    lng: -84.25421949999999,
    squareFootage: "123915",
    propertyType: "Multifamily",
    totalCosts: "10600000",
    valueOfReclassifiedAssets: 2955955,
    depreciableAssets: 33,
  },
  {
    lat: 35.2528422,
    lng: -81.1526976,
    squareFootage: "154901",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 3344359,
    depreciableAssets: 30,
  },
  {
    lat: 35.1825962,
    lng: -80.6548882,
    squareFootage: "140000",
    propertyType: "Multifamily",
    totalCosts: "28650000",
    valueOfReclassifiedAssets: 6315321,
    depreciableAssets: 26,
  },
  {
    lat: 35.5692059,
    lng: -82.6159271,
    squareFootage: "86792",
    propertyType: "Multifamily",
    totalCosts: "8574151",
    valueOfReclassifiedAssets: 2596185,
    depreciableAssets: 35,
  },
  {
    lat: 40.7860123,
    lng: -74.0094471,
    squareFootage: "3951",
    propertyType: "Retail",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 263124,
    depreciableAssets: 19,
  },
  {
    lat: 34.7389669,
    lng: -82.4536115,
    squareFootage: "56050",
    propertyType: "Office",
    totalCosts: "4175000",
    valueOfReclassifiedAssets: 662506,
    depreciableAssets: 19,
  },
  {
    lat: 35.3202223,
    lng: -80.8875861,
    squareFootage: "120250",
    propertyType: "Industrial",
    totalCosts: "4765000",
    valueOfReclassifiedAssets: 685098,
    depreciableAssets: 17,
  },
  {
    lat: 35.3810878,
    lng: -97.51930929999999,
    squareFootage: "204532",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2536133,
    depreciableAssets: 33,
  },
  {
    lat: 35.5135639,
    lng: -97.5788975,
    squareFootage: "173354",
    propertyType: "Multifamily",
    totalCosts: "9000000",
    valueOfReclassifiedAssets: 2092315,
    depreciableAssets: 27,
  },
  {
    lat: 35.5587725,
    lng: -97.6385368,
    squareFootage: "333149",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 2927334,
    depreciableAssets: 29,
  },
  {
    lat: 33.4306545,
    lng: -86.7919009,
    squareFootage: "147225",
    propertyType: "Multifamily",
    totalCosts: "8300000",
    valueOfReclassifiedAssets: 2279837,
    depreciableAssets: 32,
  },
  {
    lat: 33.4702383,
    lng: -86.80696979999999,
    squareFootage: "299528",
    propertyType: "Multifamily",
    totalCosts: "14700000",
    valueOfReclassifiedAssets: 4276153,
    depreciableAssets: 34,
  },
  {
    lat: 33.1225986,
    lng: -87.55936129999999,
    squareFootage: "286732",
    propertyType: "Multifamily",
    totalCosts: "20700000",
    valueOfReclassifiedAssets: 4088013,
    depreciableAssets: 23,
  },
  {
    lat: 30.2002897,
    lng: -92.0763253,
    squareFootage: "223522",
    propertyType: "Multifamily",
    totalCosts: "11400000",
    valueOfReclassifiedAssets: 2200046,
    depreciableAssets: 23,
  },
  {
    lat: 29.9842255,
    lng: -91.851343,
    squareFootage: "175066",
    propertyType: "Multifamily",
    totalCosts: "6216000",
    valueOfReclassifiedAssets: 1114426,
    depreciableAssets: 21,
  },
  {
    lat: 29.9104337,
    lng: -90.0513246,
    squareFootage: "278592",
    propertyType: "Multifamily",
    totalCosts: "16965000",
    valueOfReclassifiedAssets: 3011160,
    depreciableAssets: 21,
  },
  {
    lat: 25.8909628,
    lng: -80.1819268,
    squareFootage: "15600",
    propertyType: "Retail",
    totalCosts: "1915000",
    valueOfReclassifiedAssets: 266716,
    depreciableAssets: 16,
  },
  {
    lat: 25.8558416,
    lng: -80.1819268,
    squareFootage: "10800",
    propertyType: "Retail",
    totalCosts: "1475470",
    valueOfReclassifiedAssets: 1126142,
    depreciableAssets: 26,
  },
  {
    lat: 25.8558416,
    lng: -80.1819268,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "595190",
    valueOfReclassifiedAssets: 552056,
    depreciableAssets: 9,
  },
  {
    lat: 40.8786991,
    lng: -73.4062342,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1884434",
    valueOfReclassifiedAssets: 1556734,
    depreciableAssets: 34,
  },
  {
    lat: 41.0229118,
    lng: -73.99475629999999,
    squareFootage: "2653",
    propertyType: "Multifamily",
    totalCosts: "628500",
    valueOfReclassifiedAssets: 101329,
    depreciableAssets: 21,
  },
  {
    lat: 41.8925085,
    lng: -87.61616959999999,
    squareFootage: "3075",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 252071,
    depreciableAssets: 20,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "875000",
    valueOfReclassifiedAssets: 141814,
    depreciableAssets: 20,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "650000",
    valueOfReclassifiedAssets: 92958,
    depreciableAssets: 17,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "775000",
    valueOfReclassifiedAssets: 135110,
    depreciableAssets: 21,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "1000000",
    valueOfReclassifiedAssets: 171724,
    depreciableAssets: 20,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "3776",
    propertyType: "Multifamily",
    totalCosts: "1450000",
    valueOfReclassifiedAssets: 227296,
    depreciableAssets: 18,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "6420",
    propertyType: "Multifamily",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 358716,
    depreciableAssets: 17,
  },
  {
    lat: 41.920133,
    lng: -87.7094504,
    squareFootage: "4927",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 410427,
    depreciableAssets: 19,
  },
  {
    lat: 44.0219841,
    lng: -103.4049445,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "725000",
    valueOfReclassifiedAssets: 121172,
    depreciableAssets: 19,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "2680",
    propertyType: "Multifamily",
    totalCosts: "825000",
    valueOfReclassifiedAssets: 138421,
    depreciableAssets: 19,
  },
  {
    lat: 42.3695431,
    lng: -71.0200613,
    squareFootage: "7785",
    propertyType: "Multifamily",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 191863,
    depreciableAssets: 13,
  },
  {
    lat: 38.8178384,
    lng: -76.9354979,
    squareFootage: "245450",
    propertyType: "Multifamily",
    totalCosts: "26400811",
    valueOfReclassifiedAssets: 5389833,
    depreciableAssets: 23,
  },
  {
    lat: 38.8178384,
    lng: -76.9354979,
    squareFootage: "125390",
    propertyType: "Multifamily",
    totalCosts: "16636405",
    valueOfReclassifiedAssets: 2207827,
    depreciableAssets: 15,
  },
  {
    lat: 32.8621631,
    lng: -96.8726295,
    squareFootage: "45608",
    propertyType: "Multifamily",
    totalCosts: "5350000",
    valueOfReclassifiedAssets: 1414900,
    depreciableAssets: 28,
  },
  {
    lat: 41.737618,
    lng: -72.6683333,
    squareFootage: "142284",
    propertyType: "Multifamily",
    totalCosts: "10039871",
    valueOfReclassifiedAssets: 1376256,
    depreciableAssets: 16,
  },
  {
    lat: 29.6526666,
    lng: -95.1953554,
    squareFootage: "74195",
    propertyType: "Office",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 2845478,
    depreciableAssets: 27,
  },
  {
    lat: 29.6526666,
    lng: -95.1953554,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2822181",
    valueOfReclassifiedAssets: 2299286,
    depreciableAssets: 25,
  },
  {
    lat: 33.7349075,
    lng: -118.3140488,
    squareFootage: "191424",
    propertyType: "Multifamily",
    totalCosts: "62900000",
    valueOfReclassifiedAssets: 15985893,
    depreciableAssets: 30,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "76174",
    propertyType: "Industrial",
    totalCosts: "3689014",
    valueOfReclassifiedAssets: 414842,
    depreciableAssets: 12,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "239023",
    propertyType: "Industrial",
    totalCosts: "9982038",
    valueOfReclassifiedAssets: 1309001,
    depreciableAssets: 15,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "87974",
    propertyType: "Industrial",
    totalCosts: "4882518",
    valueOfReclassifiedAssets: 400033,
    depreciableAssets: 9,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "125027",
    propertyType: "Industrial",
    totalCosts: "5859022",
    valueOfReclassifiedAssets: 930181,
    depreciableAssets: 18,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "114385",
    propertyType: "Industrial",
    totalCosts: "10578790",
    valueOfReclassifiedAssets: 1423133,
    depreciableAssets: 15,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "61644",
    propertyType: "Industrial",
    totalCosts: "2951189",
    valueOfReclassifiedAssets: 409327,
    depreciableAssets: 16,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "56160",
    propertyType: "Industrial",
    totalCosts: "4437633",
    valueOfReclassifiedAssets: 514197,
    depreciableAssets: 13,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "59904",
    propertyType: "Industrial",
    totalCosts: "2929489",
    valueOfReclassifiedAssets: 279797,
    depreciableAssets: 11,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "25624",
    propertyType: "Industrial",
    totalCosts: "1524419",
    valueOfReclassifiedAssets: 208638,
    depreciableAssets: 16,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "22847",
    propertyType: "Industrial",
    totalCosts: "1312845",
    valueOfReclassifiedAssets: 285716,
    depreciableAssets: 25,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "69279",
    propertyType: "Industrial",
    totalCosts: "3461137",
    valueOfReclassifiedAssets: 412569,
    depreciableAssets: 13,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "65520",
    propertyType: "Industrial",
    totalCosts: "3363487",
    valueOfReclassifiedAssets: 497143,
    depreciableAssets: 17,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "69027",
    propertyType: "Industrial",
    totalCosts: "4231484",
    valueOfReclassifiedAssets: 732417,
    depreciableAssets: 20,
  },
  {
    lat: 35.5358639,
    lng: -97.7416703,
    squareFootage: "121536",
    propertyType: "Industrial",
    totalCosts: "8381772",
    valueOfReclassifiedAssets: 2594923,
    depreciableAssets: 34,
  },
  {
    lat: 35.5358639,
    lng: -97.7416703,
    squareFootage: "310000",
    propertyType: "Industrial",
    totalCosts: "18884890",
    valueOfReclassifiedAssets: 2429264,
    depreciableAssets: 14,
  },
  {
    lat: 35.4209352,
    lng: -97.6656625,
    squareFootage: "115488",
    propertyType: "Industrial",
    totalCosts: "7921525",
    valueOfReclassifiedAssets: 1724613,
    depreciableAssets: 25,
  },
  {
    lat: 35.4495749,
    lng: -97.55722310000002,
    squareFootage: "65438",
    propertyType: "Industrial",
    totalCosts: "3960235",
    valueOfReclassifiedAssets: 597029,
    depreciableAssets: 17,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "63800",
    propertyType: "Multifamily",
    totalCosts: "6575000",
    valueOfReclassifiedAssets: 1551642,
    depreciableAssets: 34,
  },
  {
    lat: 37.3790223,
    lng: -76.7567368,
    squareFootage: "217398",
    propertyType: "Multifamily",
    totalCosts: "26250000",
    valueOfReclassifiedAssets: 6978418,
    depreciableAssets: 31,
  },
  {
    lat: 36.8643566,
    lng: -75.9985693,
    squareFootage: "64000",
    propertyType: "Multifamily",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1421653,
    depreciableAssets: 25,
  },
  {
    lat: 37.0362625,
    lng: -76.4736602,
    squareFootage: "41750",
    propertyType: "Multifamily",
    totalCosts: "3200000",
    valueOfReclassifiedAssets: 895821,
    depreciableAssets: 33,
  },
  {
    lat: 36.8974486,
    lng: -76.1435915,
    squareFootage: "446265",
    propertyType: "Multifamily",
    totalCosts: "52150000",
    valueOfReclassifiedAssets: 15521831,
    depreciableAssets: 35,
  },
  {
    lat: 36.7358202,
    lng: -76.02758589999999,
    squareFootage: "134000",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 1711910,
    depreciableAssets: 25,
  },
  {
    lat: 31.58887099999999,
    lng: -110.2117373,
    squareFootage: "94000",
    propertyType: "Multifamily",
    totalCosts: "4059166",
    valueOfReclassifiedAssets: 943590,
    depreciableAssets: 27,
  },
  {
    lat: 31.8372294,
    lng: -97.54638849999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1747990",
    valueOfReclassifiedAssets: 1132128,
    depreciableAssets: 37,
  },
  {
    lat: 32.8695051,
    lng: -96.78955739999999,
    squareFootage: "3723",
    propertyType: "Multifamily",
    totalCosts: "968321",
    valueOfReclassifiedAssets: 219355,
    depreciableAssets: 30,
  },
  {
    lat: 29.7049806,
    lng: -98.06834300000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "773201",
    valueOfReclassifiedAssets: 669026,
    depreciableAssets: 32,
  },
  {
    lat: 34.0390107,
    lng: -118.2672801,
    squareFootage: "24322",
    propertyType: "Industrial",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 154125,
    depreciableAssets: 12,
  },
  {
    lat: 34.0295114,
    lng: -118.2848199,
    squareFootage: "29151",
    propertyType: "Industrial",
    totalCosts: "2680000",
    valueOfReclassifiedAssets: 114053,
    depreciableAssets: 8,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "12400",
    propertyType: "Office",
    totalCosts: "1993182",
    valueOfReclassifiedAssets: 488959,
    depreciableAssets: 38,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "6745",
    propertyType: "Office",
    totalCosts: "971472",
    valueOfReclassifiedAssets: 114603,
    depreciableAssets: 20,
  },
  {
    lat: 33.8863314,
    lng: -118.2935891,
    squareFootage: "10638",
    propertyType: "Office",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 160999,
    depreciableAssets: 11,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "9037",
    propertyType: "Office",
    totalCosts: "2180141",
    valueOfReclassifiedAssets: 363393,
    depreciableAssets: 26,
  },
  {
    lat: 33.9756107,
    lng: -118.2234229,
    squareFootage: "7879",
    propertyType: "Retail",
    totalCosts: "2581500",
    valueOfReclassifiedAssets: 447520,
    depreciableAssets: 23,
  },
  {
    lat: 34.319379,
    lng: -83.81547909999999,
    squareFootage: "66029",
    propertyType: "Hotel",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 923587,
    depreciableAssets: 28,
  },
  {
    lat: 29.3845708,
    lng: -95.2475518,
    squareFootage: "121380",
    propertyType: "Multifamily",
    totalCosts: "5776528",
    valueOfReclassifiedAssets: 1515065,
    depreciableAssets: 29,
  },
  {
    lat: 32.9368872,
    lng: -96.6317461,
    squareFootage: "14329",
    propertyType: "Multifamily",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 436683,
    depreciableAssets: 29,
  },
  {
    lat: 35.7830899,
    lng: -83.91089680000002,
    squareFootage: "42826",
    propertyType: "Retail",
    totalCosts: "2884050",
    valueOfReclassifiedAssets: 652193,
    depreciableAssets: 26,
  },
  {
    lat: 35.6194254,
    lng: -84.1224036,
    squareFootage: "6236",
    propertyType: "Office",
    totalCosts: "950000",
    valueOfReclassifiedAssets: 138113,
    depreciableAssets: 17,
  },
  {
    lat: 35.6194254,
    lng: -84.1224036,
    squareFootage: "8640",
    propertyType: "Retail",
    totalCosts: "157000",
    valueOfReclassifiedAssets: 28274,
    depreciableAssets: 24,
  },
  {
    lat: 39.6527963,
    lng: -79.9472502,
    squareFootage: "43200",
    propertyType: "Multifamily",
    totalCosts: "29200000",
    valueOfReclassifiedAssets: 8304038,
    depreciableAssets: 33,
  },
  {
    lat: 40.8288377,
    lng: -73.9374517,
    squareFootage: "18879",
    propertyType: "Multifamily",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 1819045,
    depreciableAssets: 13,
  },
  {
    lat: 33.4366655,
    lng: -111.9403254,
    squareFootage: "148530",
    propertyType: "Multifamily",
    totalCosts: "26750000",
    valueOfReclassifiedAssets: 7123031,
    depreciableAssets: 31,
  },
  {
    lat: 36.1933771,
    lng: -95.9092754,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "24336452",
    valueOfReclassifiedAssets: 3578987,
    depreciableAssets: 16,
  },
  {
    lat: 39.9793657,
    lng: -75.1253492,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3880800",
    valueOfReclassifiedAssets: 3185516,
    depreciableAssets: 20,
  },
  {
    lat: 29.6249386,
    lng: -98.4293891,
    squareFootage: "48150",
    propertyType: "Retail",
    totalCosts: "5509901",
    valueOfReclassifiedAssets: 4763923,
    depreciableAssets: 32,
  },
  {
    lat: 37.7561438,
    lng: -122.4325682,
    squareFootage: "2684",
    propertyType: "Multifamily",
    totalCosts: "2220000",
    valueOfReclassifiedAssets: 204354,
    depreciableAssets: 12,
  },
  {
    lat: 39.8300502,
    lng: -105.0352855,
    squareFootage: "2019",
    propertyType: "Retail",
    totalCosts: "2800000",
    valueOfReclassifiedAssets: 928938,
    depreciableAssets: 39,
  },
  {
    lat: 37.5178022,
    lng: -122.2900288,
    squareFootage: "11985",
    propertyType: "Multifamily",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 593634,
    depreciableAssets: 18,
  },
  {
    lat: 41.0125894,
    lng: -73.6830521,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1607341",
    valueOfReclassifiedAssets: 304103,
    depreciableAssets: 22,
  },
  {
    lat: 40.9905542,
    lng: -73.8713099,
    squareFootage: "3600",
    propertyType: "Multifamily",
    totalCosts: "1498450",
    valueOfReclassifiedAssets: 1279934,
    depreciableAssets: 16,
  },
  {
    lat: 41.4896627,
    lng: -73.9712488,
    squareFootage: "8000",
    propertyType: "Multifamily",
    totalCosts: "2300974",
    valueOfReclassifiedAssets: 312766,
    depreciableAssets: 16,
  },
  {
    lat: 39.9645049,
    lng: -86.0223611,
    squareFootage: "38659",
    propertyType: "Retail",
    totalCosts: "4452447",
    valueOfReclassifiedAssets: 1074741,
    depreciableAssets: 47,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1078195",
    valueOfReclassifiedAssets: 973938,
    depreciableAssets: 0,
  },
  {
    lat: 26.8598894,
    lng: -80.08701549999999,
    squareFootage: "35044",
    propertyType: "Retail",
    totalCosts: "8670000",
    valueOfReclassifiedAssets: 2996304,
    depreciableAssets: 41,
  },
  {
    lat: 26.1351973,
    lng: -80.1177335,
    squareFootage: "6044",
    propertyType: "Multifamily",
    totalCosts: "1810000",
    valueOfReclassifiedAssets: 504988,
    depreciableAssets: 33,
  },
  {
    lat: 47.583863,
    lng: -122.3406748,
    squareFootage: "21560",
    propertyType: "Industrial",
    totalCosts: "5650000",
    valueOfReclassifiedAssets: 736542,
    depreciableAssets: 15,
  },
  {
    lat: 33.5162652,
    lng: -96.6745042,
    squareFootage: "87400",
    propertyType: "Multifamily",
    totalCosts: "4800000",
    valueOfReclassifiedAssets: 1253311,
    depreciableAssets: 30,
  },
  {
    lat: 33.0215727,
    lng: -96.5143087,
    squareFootage: "29488",
    propertyType: "Multifamily",
    totalCosts: "1545000",
    valueOfReclassifiedAssets: 308463,
    depreciableAssets: 23,
  },
  {
    lat: 32.8798071,
    lng: -96.75475220000001,
    squareFootage: "25508",
    propertyType: "Multifamily",
    totalCosts: "1679218",
    valueOfReclassifiedAssets: 1360799,
    depreciableAssets: 23,
  },
  {
    lat: 34.965225,
    lng: -81.5596427,
    squareFootage: "72600",
    propertyType: "Multifamily",
    totalCosts: "2790000",
    valueOfReclassifiedAssets: 825675,
    depreciableAssets: 35,
  },
  {
    lat: 32.8112934,
    lng: -97.27607599999999,
    squareFootage: "77030",
    propertyType: "Multifamily",
    totalCosts: "6705000",
    valueOfReclassifiedAssets: 1574002,
    depreciableAssets: 27,
  },
  {
    lat: 40.794065,
    lng: -73.9271644,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6579013",
    valueOfReclassifiedAssets: 5608624,
    depreciableAssets: 18,
  },
  {
    lat: 37.8824054,
    lng: -122.026918,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "36672893",
    valueOfReclassifiedAssets: 30529521,
    depreciableAssets: 21,
  },
  {
    lat: 39.2593487,
    lng: -94.3770704,
    squareFootage: "5702",
    propertyType: "Retail",
    totalCosts: "1222000",
    valueOfReclassifiedAssets: 221216,
    depreciableAssets: 21,
  },
  {
    lat: 33.3519301,
    lng: -111.8210866,
    squareFootage: "7729",
    propertyType: "Retail",
    totalCosts: "579600",
    valueOfReclassifiedAssets: 142944,
    depreciableAssets: 29,
  },
  {
    lat: 33.3519301,
    lng: -111.8210866,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "483322",
    valueOfReclassifiedAssets: 394566,
    depreciableAssets: 23,
  },
  {
    lat: 27.9474417,
    lng: -97.2005806,
    squareFootage: "25776",
    propertyType: "Multifamily",
    totalCosts: "1450000",
    valueOfReclassifiedAssets: 344522,
    depreciableAssets: 28,
  },
  {
    lat: 41.69992260000001,
    lng: -91.5886966,
    squareFootage: "21008",
    propertyType: "Office",
    totalCosts: "2620000",
    valueOfReclassifiedAssets: 458088,
    depreciableAssets: 20,
  },
  {
    lat: 41.69992260000001,
    lng: -91.5886966,
    squareFootage: "19182",
    propertyType: "Office",
    totalCosts: "2624000",
    valueOfReclassifiedAssets: 628947,
    depreciableAssets: 31,
  },
  {
    lat: 41.6613587,
    lng: -91.4918235,
    squareFootage: "26093",
    propertyType: "Office",
    totalCosts: "3974000",
    valueOfReclassifiedAssets: 809599,
    depreciableAssets: 26,
  },
  {
    lat: 41.6597963,
    lng: -91.60809739999999,
    squareFootage: "50859",
    propertyType: "Multifamily",
    totalCosts: "4563940",
    valueOfReclassifiedAssets: 750153,
    depreciableAssets: 19,
  },
  {
    lat: 41.5999808,
    lng: -93.6750005,
    squareFootage: "36400",
    propertyType: "Multifamily",
    totalCosts: "5087000",
    valueOfReclassifiedAssets: 1158410,
    depreciableAssets: 24,
  },
  {
    lat: 41.6113703,
    lng: -91.50150090000001,
    squareFootage: "100150",
    propertyType: "Multifamily",
    totalCosts: "6987000",
    valueOfReclassifiedAssets: 1502239,
    depreciableAssets: 24,
  },
  {
    lat: 41.6597963,
    lng: -91.60809739999999,
    squareFootage: "57030",
    propertyType: "Retail",
    totalCosts: "5866539",
    valueOfReclassifiedAssets: 1347654,
    depreciableAssets: 31,
  },
  {
    lat: 34.1423899,
    lng: -118.4571974,
    squareFootage: "9568",
    propertyType: "Specialty",
    totalCosts: "1754662",
    valueOfReclassifiedAssets: 1358391,
    depreciableAssets: 29,
  },
  {
    lat: 39.5099753,
    lng: -105.336536,
    squareFootage: "14347",
    propertyType: "Industrial",
    totalCosts: "1516000",
    valueOfReclassifiedAssets: 353018,
    depreciableAssets: 27,
  },
  {
    lat: 31.3511246,
    lng: -103.6362715,
    squareFootage: "1440",
    propertyType: "Specialty",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 1867664,
    depreciableAssets: 91,
  },
  {
    lat: 40.2240869,
    lng: -74.0887576,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5574000",
    valueOfReclassifiedAssets: 2181106,
    depreciableAssets: 80,
  },
  {
    lat: 39.2804946,
    lng: -94.6347041,
    squareFootage: "86815",
    propertyType: "Multifamily",
    totalCosts: "4785629",
    valueOfReclassifiedAssets: 1137936,
    depreciableAssets: 26,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "142248",
    propertyType: "Office",
    totalCosts: "96069811",
    valueOfReclassifiedAssets: 10554753,
    depreciableAssets: 13,
  },
  {
    lat: 40.7930239,
    lng: -74.3235539,
    squareFootage: "12753",
    propertyType: "Multifamily",
    totalCosts: "780000",
    valueOfReclassifiedAssets: 172066,
    depreciableAssets: 26,
  },
  {
    lat: 40.7930239,
    lng: -74.3235539,
    squareFootage: "12753",
    propertyType: "Multifamily",
    totalCosts: "805853",
    valueOfReclassifiedAssets: 177769,
    depreciableAssets: 26,
  },
  {
    lat: 37.7877522,
    lng: -122.4382307,
    squareFootage: "6576",
    propertyType: "Multifamily",
    totalCosts: "3382064",
    valueOfReclassifiedAssets: 429492,
    depreciableAssets: 16,
  },
  {
    lat: 28.2687237,
    lng: -81.1416691,
    squareFootage: "2000",
    propertyType: "Specialty",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 2685123,
    depreciableAssets: 78,
  },
  {
    lat: 28.6311026,
    lng: -80.8433145,
    squareFootage: "5416",
    propertyType: "Specialty",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 1738702,
    depreciableAssets: 84,
  },
  {
    lat: 28.3872999,
    lng: -80.8322428,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1890000",
    valueOfReclassifiedAssets: 543010,
    depreciableAssets: 32,
  },
  {
    lat: 28.3872999,
    lng: -80.8322428,
    squareFootage: "3690",
    propertyType: "Retail",
    totalCosts: "4000000",
    valueOfReclassifiedAssets: 1680213,
    depreciableAssets: 46,
  },
  {
    lat: 28.470872,
    lng: -80.6992633,
    squareFootage: "10185",
    propertyType: "Retail",
    totalCosts: "4840000",
    valueOfReclassifiedAssets: 1170641,
    depreciableAssets: 27,
  },
  {
    lat: 35.472468,
    lng: -80.8875861,
    squareFootage: "300000",
    propertyType: "Multifamily",
    totalCosts: "62263250",
    valueOfReclassifiedAssets: 12995401,
    depreciableAssets: 24,
  },
  {
    lat: 32.99409,
    lng: -97.3138554,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3428007",
    valueOfReclassifiedAssets: 3096971,
    depreciableAssets: 24,
  },
  {
    lat: 38.5824933,
    lng: -121.4941738,
    squareFootage: "153288",
    propertyType: "Specialty",
    totalCosts: "46993560",
    valueOfReclassifiedAssets: 5700452,
    depreciableAssets: 12,
  },
  {
    lat: 38.5824933,
    lng: -121.4941738,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15248034",
    valueOfReclassifiedAssets: 9703536,
    depreciableAssets: 39,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25803822",
    valueOfReclassifiedAssets: 18090995,
    depreciableAssets: 31,
  },
  {
    lat: 32.6168755,
    lng: -116.9970153,
    squareFootage: "221396",
    propertyType: "Multifamily",
    totalCosts: "41300000",
    valueOfReclassifiedAssets: 8498629,
    depreciableAssets: 24,
  },
  {
    lat: 32.6168755,
    lng: -116.9970153,
    squareFootage: "221396",
    propertyType: "Multifamily",
    totalCosts: "41223674",
    valueOfReclassifiedAssets: 7116998,
    depreciableAssets: 20,
  },
  {
    lat: 32.6168755,
    lng: -116.9970153,
    squareFootage: "221396",
    propertyType: "Multifamily",
    totalCosts: "41223674",
    valueOfReclassifiedAssets: 8497562,
    depreciableAssets: 24,
  },
  {
    lat: 37.6737239,
    lng: -122.1007867,
    squareFootage: "123864",
    propertyType: "Multifamily",
    totalCosts: "29860656",
    valueOfReclassifiedAssets: 24623499,
    depreciableAssets: 27,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7269930",
    valueOfReclassifiedAssets: 5549566,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6730392",
    valueOfReclassifiedAssets: 5184744,
    depreciableAssets: 24,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "13804887",
    valueOfReclassifiedAssets: 10322101,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "26450920",
    valueOfReclassifiedAssets: 20200656,
    depreciableAssets: 25,
  },
  {
    lat: 41.8131441,
    lng: -71.4596201,
    squareFootage: "82000",
    propertyType: "Multifamily",
    totalCosts: "11560604",
    valueOfReclassifiedAssets: 2266412,
    depreciableAssets: 21,
  },
  {
    lat: 41.8131441,
    lng: -71.4596201,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "9259656",
    valueOfReclassifiedAssets: 6474011,
    depreciableAssets: 36,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "15101276",
    valueOfReclassifiedAssets: 11683294,
    depreciableAssets: 26,
  },
  {
    lat: 38.5824933,
    lng: -121.4941738,
    squareFootage: "153288",
    propertyType: "Specialty",
    totalCosts: "46000000",
    valueOfReclassifiedAssets: 5574153,
    depreciableAssets: 12,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6082075",
    valueOfReclassifiedAssets: 5767512,
    depreciableAssets: 6,
  },
  {
    lat: 40.1313166,
    lng: -87.6285099,
    squareFootage: "59136",
    propertyType: "Multifamily",
    totalCosts: "7624731",
    valueOfReclassifiedAssets: 1584424,
    depreciableAssets: 21,
  },
  {
    lat: 42.0646736,
    lng: -87.93838219999999,
    squareFootage: "70700",
    propertyType: "Multifamily",
    totalCosts: "15624731",
    valueOfReclassifiedAssets: 3259986,
    depreciableAssets: 22,
  },
  {
    lat: 42.0646736,
    lng: -87.93838219999999,
    squareFootage: "63050",
    propertyType: "Multifamily",
    totalCosts: "14819791",
    valueOfReclassifiedAssets: 3143261,
    depreciableAssets: 23,
  },
  {
    lat: 41.9003099,
    lng: -87.63338710000001,
    squareFootage: "185220",
    propertyType: "Multifamily",
    totalCosts: "54123590",
    valueOfReclassifiedAssets: 6797670,
    depreciableAssets: 16,
  },
  {
    lat: 41.9013087,
    lng: -87.68276759999999,
    squareFootage: "73700",
    propertyType: "Multifamily",
    totalCosts: "35465484",
    valueOfReclassifiedAssets: 6461025,
    depreciableAssets: 22,
  },
  {
    lat: 36.8400852,
    lng: -76.1493891,
    squareFootage: "149388",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20150000",
    valueOfReclassifiedAssets: 4328695,
    depreciableAssets: 24,
  },
  {
    lat: 38.2493627,
    lng: -122.0399969,
    squareFootage: "61644",
    propertyType: "Specialty",
    totalCosts: "34011778",
    valueOfReclassifiedAssets: 9683193,
    depreciableAssets: 30,
  },
  {
    lat: 32.8959754,
    lng: -97.3624598,
    squareFootage: "161252",
    propertyType: "Multifamily",
    totalCosts: "15180000",
    valueOfReclassifiedAssets: 4601438,
    depreciableAssets: 33,
  },
  {
    lat: 32.7476771,
    lng: -97.2113605,
    squareFootage: "114549",
    propertyType: "Multifamily",
    totalCosts: "9970000",
    valueOfReclassifiedAssets: 2872922,
    depreciableAssets: 32,
  },
  {
    lat: 30.0118752,
    lng: -95.4463322,
    squareFootage: "95074",
    propertyType: "Multifamily",
    totalCosts: "7067000",
    valueOfReclassifiedAssets: 1990919,
    depreciableAssets: 33,
  },
  {
    lat: 30.0314279,
    lng: -95.5302337,
    squareFootage: "2017",
    propertyType: "Multifamily",
    totalCosts: "14055000",
    valueOfReclassifiedAssets: 3904971,
    depreciableAssets: 32,
  },
  {
    lat: 34.0683231,
    lng: -118.4069666,
    squareFootage: "207432",
    propertyType: "Specialty",
    totalCosts: "193000000",
    valueOfReclassifiedAssets: 24485552,
    depreciableAssets: 16,
  },
  {
    lat: 33.0603809,
    lng: -96.73334299999999,
    squareFootage: "340451",
    propertyType: "Multifamily",
    totalCosts: "62000000",
    valueOfReclassifiedAssets: 18721094,
    depreciableAssets: 35,
  },
  {
    lat: 25.7862401,
    lng: -80.2349196,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "32253354",
    valueOfReclassifiedAssets: 30573841,
    depreciableAssets: 8,
  },
  {
    lat: 40.7366138,
    lng: -74.0094471,
    squareFootage: "3243",
    propertyType: "Multifamily",
    totalCosts: "6159178",
    valueOfReclassifiedAssets: 5235302,
    depreciableAssets: 100,
  },
  {
    lat: 30.4587407,
    lng: -90.13077969999999,
    squareFootage: "20000",
    propertyType: "Specialty",
    totalCosts: "4650000",
    valueOfReclassifiedAssets: 889815,
    depreciableAssets: 21,
  },
  {
    lat: 25.76528,
    lng: -80.1847167,
    squareFootage: "1964",
    propertyType: "Multifamily",
    totalCosts: "2510537",
    valueOfReclassifiedAssets: 1770860,
    depreciableAssets: 0,
  },
  {
    lat: 45.26599,
    lng: -111.25312,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4069739",
    valueOfReclassifiedAssets: 659722,
    depreciableAssets: 18,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4494857",
    valueOfReclassifiedAssets: 3794949,
    depreciableAssets: 16,
  },
  {
    lat: 40.7859464,
    lng: -73.97418739999999,
    squareFootage: "5009",
    propertyType: "Multifamily",
    totalCosts: "5228000",
    valueOfReclassifiedAssets: 4864849,
    depreciableAssets: 8,
  },
  {
    lat: 34.9837306,
    lng: -89.99067339999999,
    squareFootage: "13627",
    propertyType: "Retail",
    totalCosts: "6995922",
    valueOfReclassifiedAssets: 1954006,
    depreciableAssets: 31,
  },
  {
    lat: 43.4294845,
    lng: -71.5842643,
    squareFootage: "13630",
    propertyType: "Retail",
    totalCosts: "7573529",
    valueOfReclassifiedAssets: 2932007,
    depreciableAssets: 43,
  },
  {
    lat: 35.2077035,
    lng: -94.21283369999999,
    squareFootage: "19700",
    propertyType: "Retail",
    totalCosts: "3528000",
    valueOfReclassifiedAssets: 675090,
    depreciableAssets: 22,
  },
  {
    lat: 34.6476577,
    lng: -114.2865991,
    squareFootage: "21930",
    propertyType: "Retail",
    totalCosts: "4710570",
    valueOfReclassifiedAssets: 1273999,
    depreciableAssets: 32,
  },
  {
    lat: 43.10566559999999,
    lng: -77.7938376,
    squareFootage: "14820",
    propertyType: "Retail",
    totalCosts: "4750000",
    valueOfReclassifiedAssets: 1283701,
    depreciableAssets: 31,
  },
  {
    lat: 44.0469084,
    lng: -71.1313381,
    squareFootage: "14000",
    propertyType: "Retail",
    totalCosts: "5955882",
    valueOfReclassifiedAssets: 2391520,
    depreciableAssets: 44,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "2717",
    propertyType: "Multifamily",
    totalCosts: "5047853",
    valueOfReclassifiedAssets: 4557853,
    depreciableAssets: 100,
  },
  {
    lat: 40.6681669,
    lng: -73.9800645,
    squareFootage: "6536",
    propertyType: "Multifamily",
    totalCosts: "2291584",
    valueOfReclassifiedAssets: 377844,
    depreciableAssets: 16,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "12648",
    propertyType: "Multifamily",
    totalCosts: "3095458",
    valueOfReclassifiedAssets: 398232,
    depreciableAssets: 13,
  },
  {
    lat: 28.7108495,
    lng: -81.3509416,
    squareFootage: "55000",
    propertyType: "Industrial",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 350197,
    depreciableAssets: 13,
  },
  {
    lat: 28.7108495,
    lng: -81.3509416,
    squareFootage: "21000",
    propertyType: "Office",
    totalCosts: "1300000",
    valueOfReclassifiedAssets: 182622,
    depreciableAssets: 17,
  },
  {
    lat: 28.7405957,
    lng: -81.27390729999999,
    squareFootage: "52000",
    propertyType: "Industrial",
    totalCosts: "2287229",
    valueOfReclassifiedAssets: 405172,
    depreciableAssets: 23,
  },
  {
    lat: 41.4789363,
    lng: -81.7404134,
    squareFootage: "8630",
    propertyType: "Multifamily",
    totalCosts: "901225",
    valueOfReclassifiedAssets: 193569,
    depreciableAssets: 25,
  },
  {
    lat: 29.0620369,
    lng: -95.4882679,
    squareFootage: "69000",
    propertyType: "Multifamily",
    totalCosts: "5325000",
    valueOfReclassifiedAssets: 1358021,
    depreciableAssets: 30,
  },
  {
    lat: 31.8063114,
    lng: -99.05710889999999,
    squareFootage: "113307",
    propertyType: "Multifamily",
    totalCosts: "4547163",
    valueOfReclassifiedAssets: 1326045,
    depreciableAssets: 32,
  },
  {
    lat: 33.4329528,
    lng: -111.8449346,
    squareFootage: "67320",
    propertyType: "Multifamily",
    totalCosts: "7400000",
    valueOfReclassifiedAssets: 1914626,
    depreciableAssets: 31,
  },
  {
    lat: 33.3922515,
    lng: -94.1309067,
    squareFootage: "38508",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 547363,
    depreciableAssets: 32,
  },
  {
    lat: 32.8162802,
    lng: -97.1898025,
    squareFootage: "121999",
    propertyType: "Multifamily",
    totalCosts: "10600000",
    valueOfReclassifiedAssets: 3427202,
    depreciableAssets: 35,
  },
  {
    lat: 41.3104378,
    lng: -73.1467619,
    squareFootage: "454846",
    propertyType: "Industrial",
    totalCosts: "3387404",
    valueOfReclassifiedAssets: 3124186,
    depreciableAssets: 14,
  },
  {
    lat: 41.3104378,
    lng: -73.1467619,
    squareFootage: "454846",
    propertyType: "Industrial",
    totalCosts: "5081106",
    valueOfReclassifiedAssets: 4686280,
    depreciableAssets: 14,
  },
  {
    lat: 41.3104378,
    lng: -73.1467619,
    squareFootage: "454846",
    propertyType: "Industrial",
    totalCosts: "19759858",
    valueOfReclassifiedAssets: 18224421,
    depreciableAssets: 14,
  },
  {
    lat: 40.0223314,
    lng: -74.95594799999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "6782554",
    valueOfReclassifiedAssets: 6292503,
    depreciableAssets: 13,
  },
  {
    lat: 40.0223314,
    lng: -74.95594799999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3108370",
    valueOfReclassifiedAssets: 2823807,
    depreciableAssets: 20,
  },
  {
    lat: 39.8667532,
    lng: -75.05235340000002,
    squareFootage: "931682",
    propertyType: "Industrial",
    totalCosts: "45183188",
    valueOfReclassifiedAssets: 5921554,
    depreciableAssets: 15,
  },
  {
    lat: 41.418055,
    lng: -73.4769458,
    squareFootage: "23143",
    propertyType: "Office",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 586052,
    depreciableAssets: 21,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "10960",
    propertyType: "Specialty",
    totalCosts: "2100920",
    valueOfReclassifiedAssets: 534144,
    depreciableAssets: 28,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "9300",
    propertyType: "Office",
    totalCosts: "1406420",
    valueOfReclassifiedAssets: 310399,
    depreciableAssets: 26,
  },
  {
    lat: 25.9109586,
    lng: -80.30459669999999,
    squareFootage: "95228",
    propertyType: "Industrial",
    totalCosts: "12150000",
    valueOfReclassifiedAssets: 2022210,
    depreciableAssets: 20,
  },
  {
    lat: 47.6614244,
    lng: -122.2683743,
    squareFootage: "48000",
    propertyType: "Multifamily",
    totalCosts: "19039060",
    valueOfReclassifiedAssets: 15478760,
    depreciableAssets: 19,
  },
  {
    lat: 41.39015759999999,
    lng: -87.32149079999999,
    squareFootage: "65964",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "37000000",
    valueOfReclassifiedAssets: 8579397,
    depreciableAssets: 27,
  },
  {
    lat: 43.0756836,
    lng: -76.1493891,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1815686",
    valueOfReclassifiedAssets: 1134882,
    depreciableAssets: 44,
  },
  {
    lat: 32.7433629,
    lng: -96.8887202,
    squareFootage: "137895",
    propertyType: "Multifamily",
    totalCosts: "11000000",
    valueOfReclassifiedAssets: 2937477,
    depreciableAssets: 32,
  },
  {
    lat: 27.8219579,
    lng: -82.7401087,
    squareFootage: "197778",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 5268317,
    depreciableAssets: 29,
  },
  {
    lat: 39.0718795,
    lng: -94.9143239,
    squareFootage: "21059",
    propertyType: "Retail",
    totalCosts: "1800000",
    valueOfReclassifiedAssets: 399651,
    depreciableAssets: 23,
  },
  {
    lat: 39.1052447,
    lng: -94.5392361,
    squareFootage: "33631",
    propertyType: "Retail",
    totalCosts: "3270000",
    valueOfReclassifiedAssets: 767070,
    depreciableAssets: 24,
  },
  {
    lat: 38.9752926,
    lng: -94.46967649999999,
    squareFootage: "34622",
    propertyType: "Retail",
    totalCosts: "2550000",
    valueOfReclassifiedAssets: 709160,
    depreciableAssets: 29,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "176270",
    propertyType: "Multifamily",
    totalCosts: "15773867",
    valueOfReclassifiedAssets: 14258912,
    depreciableAssets: 11,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "75042",
    propertyType: "Multifamily",
    totalCosts: "9870782",
    valueOfReclassifiedAssets: 8487238,
    depreciableAssets: 15,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "42270",
    propertyType: "Multifamily",
    totalCosts: "5278925",
    valueOfReclassifiedAssets: 4494210,
    depreciableAssets: 15,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5891500",
    valueOfReclassifiedAssets: 5146628,
    depreciableAssets: 14,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "151686",
    propertyType: "Multifamily",
    totalCosts: "13472500",
    valueOfReclassifiedAssets: 11497790,
    depreciableAssets: 15,
  },
  {
    lat: 40.6470923,
    lng: -73.9536163,
    squareFootage: "93378",
    propertyType: "Multifamily",
    totalCosts: "15725000",
    valueOfReclassifiedAssets: 14148391,
    depreciableAssets: 11,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "22132",
    propertyType: "Multifamily",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 662657,
    depreciableAssets: 13,
  },
  {
    lat: 40.6648278,
    lng: -73.9565551,
    squareFootage: "68400",
    propertyType: "Multifamily",
    totalCosts: "9775000",
    valueOfReclassifiedAssets: 8854600,
    depreciableAssets: 9,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "27870",
    propertyType: "Multifamily",
    totalCosts: "3468893",
    valueOfReclassifiedAssets: 2626786,
    depreciableAssets: 25,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "27306",
    propertyType: "Multifamily",
    totalCosts: "5217444",
    valueOfReclassifiedAssets: 3744363,
    depreciableAssets: 29,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "44154",
    propertyType: "Multifamily",
    totalCosts: "7590172",
    valueOfReclassifiedAssets: 6440027,
    depreciableAssets: 16,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "41016",
    propertyType: "Multifamily",
    totalCosts: "4335363",
    valueOfReclassifiedAssets: 3770487,
    depreciableAssets: 14,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "37506",
    propertyType: "Multifamily",
    totalCosts: "4881326",
    valueOfReclassifiedAssets: 3967257,
    depreciableAssets: 19,
  },
  {
    lat: 40.8270209,
    lng: -73.9506774,
    squareFootage: "107701",
    propertyType: "Multifamily",
    totalCosts: "17323110",
    valueOfReclassifiedAssets: 15132281,
    depreciableAssets: 13,
  },
  {
    lat: 40.8409822,
    lng: -73.9447994,
    squareFootage: "74064",
    propertyType: "Multifamily",
    totalCosts: "9748124",
    valueOfReclassifiedAssets: 8044043,
    depreciableAssets: 18,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "41000",
    propertyType: "Multifamily",
    totalCosts: "10262870",
    valueOfReclassifiedAssets: 1274769,
    depreciableAssets: 15,
  },
  {
    lat: 40.6986772,
    lng: -73.9859414,
    squareFootage: "41952",
    propertyType: "Multifamily",
    totalCosts: "8587220",
    valueOfReclassifiedAssets: 801912,
    depreciableAssets: 11,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "58670",
    propertyType: "Hotel",
    totalCosts: "11150000",
    valueOfReclassifiedAssets: 2705065,
    depreciableAssets: 29,
  },
  {
    lat: 38.7470186,
    lng: -90.2526163,
    squareFootage: "213363",
    propertyType: "Multifamily",
    totalCosts: "5925000",
    valueOfReclassifiedAssets: 1569945,
    depreciableAssets: 31,
  },
  {
    lat: 39.8399354,
    lng: -85.9815339,
    squareFootage: "157580",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 2809789,
    depreciableAssets: 31,
  },
  {
    lat: 38.992935,
    lng: -94.55212739999999,
    squareFootage: "100000",
    propertyType: "Multifamily",
    totalCosts: "2041000",
    valueOfReclassifiedAssets: 441827,
    depreciableAssets: 25,
  },
  {
    lat: 38.7479435,
    lng: -90.2113571,
    squareFootage: "120671",
    propertyType: "Multifamily",
    totalCosts: "4340000",
    valueOfReclassifiedAssets: 909853,
    depreciableAssets: 24,
  },
  {
    lat: 38.7469318,
    lng: -90.2901815,
    squareFootage: "237354",
    propertyType: "Multifamily",
    totalCosts: "2093289",
    valueOfReclassifiedAssets: 554769,
    depreciableAssets: 31,
  },
  {
    lat: 38.7470186,
    lng: -90.2526163,
    squareFootage: "434696",
    propertyType: "Multifamily",
    totalCosts: "10500000",
    valueOfReclassifiedAssets: 2347733,
    depreciableAssets: 27,
  },
  {
    lat: 28.8700376,
    lng: -82.669947,
    squareFootage: "14544",
    propertyType: "Multifamily",
    totalCosts: "555950",
    valueOfReclassifiedAssets: 91627,
    depreciableAssets: 20,
  },
  {
    lat: 28.7654134,
    lng: -82.4536115,
    squareFootage: "2290",
    propertyType: "Multifamily",
    totalCosts: "136700",
    valueOfReclassifiedAssets: 25429,
    depreciableAssets: 22,
  },
  {
    lat: 28.7654134,
    lng: -82.4536115,
    squareFootage: "4580",
    propertyType: "Multifamily",
    totalCosts: "273300",
    valueOfReclassifiedAssets: 50156,
    depreciableAssets: 22,
  },
  {
    lat: 30.6301452,
    lng: -84.2278796,
    squareFootage: "104000",
    propertyType: "Retail",
    totalCosts: "3500000",
    valueOfReclassifiedAssets: 1264943,
    depreciableAssets: 43,
  },
  {
    lat: 31.5343579,
    lng: -97.19519129999999,
    squareFootage: "53052",
    propertyType: "Multifamily",
    totalCosts: "3100000",
    valueOfReclassifiedAssets: 752979,
    depreciableAssets: 29,
  },
  {
    lat: 30.4649282,
    lng: -84.38574419999999,
    squareFootage: "19470",
    propertyType: "Multifamily",
    totalCosts: "1395000",
    valueOfReclassifiedAssets: 249482,
    depreciableAssets: 21,
  },
  {
    lat: 39.6751269,
    lng: -104.9680914,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "108837382",
    valueOfReclassifiedAssets: 11008452,
    depreciableAssets: 12,
  },
  {
    lat: 40.782392,
    lng: -74.0711359,
    squareFootage: "207600",
    propertyType: "Office",
    totalCosts: "29887773",
    valueOfReclassifiedAssets: 26325606,
    depreciableAssets: 17,
  },
  {
    lat: 40.8140369,
    lng: -73.9374517,
    squareFootage: "69803",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "25000000",
    valueOfReclassifiedAssets: 7993613,
    depreciableAssets: 37,
  },
  {
    lat: 40.8620476,
    lng: -74.3939339,
    squareFootage: "94374",
    propertyType: "Office",
    totalCosts: "21050000",
    valueOfReclassifiedAssets: 3253230,
    depreciableAssets: 18,
  },
  {
    lat: 40.8135401,
    lng: -74.3646122,
    squareFootage: "140705",
    propertyType: "Office",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 1287047,
    depreciableAssets: 19,
  },
  {
    lat: 43.03149560000001,
    lng: -85.58179109999999,
    squareFootage: "47680",
    propertyType: "Industrial",
    totalCosts: "4926199",
    valueOfReclassifiedAssets: 815661,
    depreciableAssets: 25,
  },
  {
    lat: 38.9583005,
    lng: -104.7812654,
    squareFootage: "115260",
    propertyType: "Office",
    totalCosts: "7293306",
    valueOfReclassifiedAssets: 1494966,
    depreciableAssets: 24,
  },
  {
    lat: 39.5835785,
    lng: -104.8571368,
    squareFootage: "58950",
    propertyType: "Office",
    totalCosts: "15642750",
    valueOfReclassifiedAssets: 3200869,
    depreciableAssets: 28,
  },
  {
    lat: 39.5897283,
    lng: -105.0031457,
    squareFootage: "41030",
    propertyType: "Office",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 605203,
    depreciableAssets: 26,
  },
  {
    lat: 39.8494377,
    lng: -86.26664629999999,
    squareFootage: "293488",
    propertyType: "Multifamily",
    totalCosts: "22813599",
    valueOfReclassifiedAssets: 5916374,
    depreciableAssets: 31,
  },
  {
    lat: 29.6124152,
    lng: -95.1901384,
    squareFootage: "207611",
    propertyType: "Multifamily",
    totalCosts: "13904107",
    valueOfReclassifiedAssets: 3075096,
    depreciableAssets: 26,
  },
  {
    lat: 30.5327462,
    lng: -87.2120599,
    squareFootage: "126744",
    propertyType: "Multifamily",
    totalCosts: "6566988",
    valueOfReclassifiedAssets: 1663863,
    depreciableAssets: 30,
  },
  {
    lat: 30.6809949,
    lng: -88.1047287,
    squareFootage: "122984",
    propertyType: "Multifamily",
    totalCosts: "7590060",
    valueOfReclassifiedAssets: 1942815,
    depreciableAssets: 30,
  },
  {
    lat: 40.1014036,
    lng: -82.89636039999999,
    squareFootage: "91584",
    propertyType: "Multifamily",
    totalCosts: "7524954",
    valueOfReclassifiedAssets: 1919295,
    depreciableAssets: 30,
  },
  {
    lat: 33.4730218,
    lng: -86.9173327,
    squareFootage: "192863",
    propertyType: "Multifamily",
    totalCosts: "11975186",
    valueOfReclassifiedAssets: 3570324,
    depreciableAssets: 35,
  },
  {
    lat: 31.2290578,
    lng: -92.3912362,
    squareFootage: "112440",
    propertyType: "Multifamily",
    totalCosts: "5522249",
    valueOfReclassifiedAssets: 1504218,
    depreciableAssets: 32,
  },
  {
    lat: 39.7988249,
    lng: -85.9815339,
    squareFootage: "237440",
    propertyType: "Multifamily",
    totalCosts: "14522378",
    valueOfReclassifiedAssets: 3425369,
    depreciableAssets: 28,
  },
  {
    lat: 41.6862153,
    lng: -86.1446493,
    squareFootage: "36000",
    propertyType: "Multifamily",
    totalCosts: "1634632",
    valueOfReclassifiedAssets: 350557,
    depreciableAssets: 25,
  },
  {
    lat: 30.2102306,
    lng: -81.7458845,
    squareFootage: "160093",
    propertyType: "Multifamily",
    totalCosts: "9135000",
    valueOfReclassifiedAssets: 2521861,
    depreciableAssets: 32,
  },
  {
    lat: 30.5327462,
    lng: -87.2120599,
    squareFootage: "38016",
    propertyType: "Multifamily",
    totalCosts: "2805291",
    valueOfReclassifiedAssets: 1030482,
    depreciableAssets: 43,
  },
  {
    lat: 32.3029712,
    lng: -88.59517509999999,
    squareFootage: "79624",
    propertyType: "Multifamily",
    totalCosts: "6002809",
    valueOfReclassifiedAssets: 1971893,
    depreciableAssets: 38,
  },
  {
    lat: 41.6862153,
    lng: -86.1446493,
    squareFootage: "309570",
    propertyType: "Multifamily",
    totalCosts: "29092862",
    valueOfReclassifiedAssets: 8466012,
    depreciableAssets: 34,
  },
  {
    lat: 29.7299827,
    lng: -94.9870491,
    squareFootage: "170838",
    propertyType: "Multifamily",
    totalCosts: "9888097",
    valueOfReclassifiedAssets: 2079519,
    depreciableAssets: 24,
  },
  {
    lat: 41.620939,
    lng: -83.75178509999999,
    squareFootage: "252056",
    propertyType: "Multifamily",
    totalCosts: "20086316",
    valueOfReclassifiedAssets: 5379281,
    depreciableAssets: 32,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "235115",
    propertyType: "Multifamily",
    totalCosts: "19593540",
    valueOfReclassifiedAssets: 5443366,
    depreciableAssets: 33,
  },
  {
    lat: 33.5009356,
    lng: -91.07777229999999,
    squareFootage: "61904",
    propertyType: "Multifamily",
    totalCosts: "4462457",
    valueOfReclassifiedAssets: 1529472,
    depreciableAssets: 40,
  },
  {
    lat: 41.47019890000001,
    lng: -87.32149079999999,
    squareFootage: "301868",
    propertyType: "Multifamily",
    totalCosts: "24487326",
    valueOfReclassifiedAssets: 5981558,
    depreciableAssets: 29,
  },
  {
    lat: 41.3683407,
    lng: -82.10764139999999,
    squareFootage: "165400",
    propertyType: "Multifamily",
    totalCosts: "8511987",
    valueOfReclassifiedAssets: 1866417,
    depreciableAssets: 26,
  },
  {
    lat: 39.9647732,
    lng: -83.0844532,
    squareFootage: "302798",
    propertyType: "Multifamily",
    totalCosts: "21586500",
    valueOfReclassifiedAssets: 5547013,
    depreciableAssets: 30,
  },
  {
    lat: 39.7912709,
    lng: -84.3226488,
    squareFootage: "222884",
    propertyType: "Multifamily",
    totalCosts: "8156755",
    valueOfReclassifiedAssets: 1723001,
    depreciableAssets: 25,
  },
  {
    lat: 40.8571957,
    lng: -89.68162079999999,
    squareFootage: "220073",
    propertyType: "Multifamily",
    totalCosts: "17143607",
    valueOfReclassifiedAssets: 3541604,
    depreciableAssets: 25,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "355050",
    propertyType: "Multifamily",
    totalCosts: "21581533",
    valueOfReclassifiedAssets: 6723979,
    depreciableAssets: 37,
  },
  {
    lat: 41.5544029,
    lng: -81.4828198,
    squareFootage: "294550",
    propertyType: "Multifamily",
    totalCosts: "13127545",
    valueOfReclassifiedAssets: 3050525,
    depreciableAssets: 28,
  },
  {
    lat: 30.6804215,
    lng: -88.06564449999999,
    squareFootage: "95845",
    propertyType: "Multifamily",
    totalCosts: "6100509",
    valueOfReclassifiedAssets: 1749401,
    depreciableAssets: 33,
  },
  {
    lat: 32.32781360000001,
    lng: -88.8070886,
    squareFootage: "96335",
    propertyType: "Multifamily",
    totalCosts: "5736216",
    valueOfReclassifiedAssets: 1844563,
    depreciableAssets: 38,
  },
  {
    lat: 30.4686832,
    lng: -81.6692532,
    squareFootage: "106204",
    propertyType: "Multifamily",
    totalCosts: "8214232",
    valueOfReclassifiedAssets: 2862225,
    depreciableAssets: 41,
  },
  {
    lat: 41.610156,
    lng: -81.4992884,
    squareFootage: "223922",
    propertyType: "Multifamily",
    totalCosts: "10417925",
    valueOfReclassifiedAssets: 2404211,
    depreciableAssets: 27,
  },
  {
    lat: 32.3673036,
    lng: -90.17759459999999,
    squareFootage: "143830",
    propertyType: "Multifamily",
    totalCosts: "8758264",
    valueOfReclassifiedAssets: 2508808,
    depreciableAssets: 34,
  },
  {
    lat: 39.02066680000001,
    lng: -84.1962563,
    squareFootage: "127152",
    propertyType: "Multifamily",
    totalCosts: "8707500",
    valueOfReclassifiedAssets: 2111230,
    depreciableAssets: 28,
  },
  {
    lat: 29.8942825,
    lng: -97.9810845,
    squareFootage: "177243",
    propertyType: "Multifamily",
    totalCosts: "22721174",
    valueOfReclassifiedAssets: 6909336,
    depreciableAssets: 36,
  },
  {
    lat: 31.3321055,
    lng: -94.8313257,
    squareFootage: "190230",
    propertyType: "Multifamily",
    totalCosts: "19965752",
    valueOfReclassifiedAssets: 5596407,
    depreciableAssets: 33,
  },
  {
    lat: 36.1192532,
    lng: -94.0388918,
    squareFootage: "246368",
    propertyType: "Multifamily",
    totalCosts: "12319110",
    valueOfReclassifiedAssets: 3076173,
    depreciableAssets: 29,
  },
  {
    lat: 39.6400784,
    lng: -84.17516479999999,
    squareFootage: "300551",
    propertyType: "Multifamily",
    totalCosts: "22312500",
    valueOfReclassifiedAssets: 5926547,
    depreciableAssets: 31,
  },
  {
    lat: 35.2258075,
    lng: -89.9159785,
    squareFootage: "185120",
    propertyType: "Multifamily",
    totalCosts: "7368971",
    valueOfReclassifiedAssets: 2339451,
    depreciableAssets: 38,
  },
  {
    lat: 32.3673036,
    lng: -90.17759459999999,
    squareFootage: "199136",
    propertyType: "Multifamily",
    totalCosts: "12891174",
    valueOfReclassifiedAssets: 4200593,
    depreciableAssets: 39,
  },
  {
    lat: 35.0497486,
    lng: -89.8598619,
    squareFootage: "212000",
    propertyType: "Multifamily",
    totalCosts: "9852891",
    valueOfReclassifiedAssets: 2975689,
    depreciableAssets: 35,
  },
  {
    lat: 32.2537549,
    lng: -90.27139439999999,
    squareFootage: "112800",
    propertyType: "Multifamily",
    totalCosts: "5575402",
    valueOfReclassifiedAssets: 1670353,
    depreciableAssets: 36,
  },
  {
    lat: 30.4305062,
    lng: -84.25421949999999,
    squareFootage: "114663",
    propertyType: "Multifamily",
    totalCosts: "5664000",
    valueOfReclassifiedAssets: 1737167,
    depreciableAssets: 36,
  },
  {
    lat: 31.7145848,
    lng: -94.65536790000002,
    squareFootage: "156939",
    propertyType: "Multifamily",
    totalCosts: "18640961",
    valueOfReclassifiedAssets: 5274492,
    depreciableAssets: 33,
  },
  {
    lat: 35.2785177,
    lng: -93.1428341,
    squareFootage: "150737",
    propertyType: "Multifamily",
    totalCosts: "9956072",
    valueOfReclassifiedAssets: 2830610,
    depreciableAssets: 33,
  },
  {
    lat: 39.68016739999999,
    lng: -86.1242881,
    squareFootage: "77240",
    propertyType: "Multifamily",
    totalCosts: "6494985",
    valueOfReclassifiedAssets: 1493701,
    depreciableAssets: 27,
  },
  {
    lat: 40.96734989999999,
    lng: -85.1375645,
    squareFootage: "159896",
    propertyType: "Multifamily",
    totalCosts: "8764439",
    valueOfReclassifiedAssets: 2312717,
    depreciableAssets: 31,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "36000",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 476071,
    depreciableAssets: 15,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "33832",
    propertyType: "Multifamily",
    totalCosts: "2603916",
    valueOfReclassifiedAssets: 344390,
    depreciableAssets: 16,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "26284",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 457188,
    depreciableAssets: 19,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "17327",
    propertyType: "Multifamily",
    totalCosts: "1850000",
    valueOfReclassifiedAssets: 318580,
    depreciableAssets: 20,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "16808",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 448646,
    depreciableAssets: 23,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "30000",
    propertyType: "Multifamily",
    totalCosts: "2700000",
    valueOfReclassifiedAssets: 562453,
    depreciableAssets: 25,
  },
  {
    lat: 40.716493,
    lng: -74.23554039999999,
    squareFootage: "9651",
    propertyType: "Multifamily",
    totalCosts: "1350000",
    valueOfReclassifiedAssets: 228205,
    depreciableAssets: 20,
  },
  {
    lat: 39.7215173,
    lng: -75.7487779,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "18098000",
    valueOfReclassifiedAssets: 13204371,
    depreciableAssets: 33,
  },
  {
    lat: 42.7539447,
    lng: -73.78308609999999,
    squareFootage: "629405",
    propertyType: "Retail",
    totalCosts: "73700000",
    valueOfReclassifiedAssets: 22620466,
    depreciableAssets: 36,
  },
  {
    lat: 42.4274971,
    lng: -71.10920120000002,
    squareFootage: "287570",
    propertyType: "Industrial",
    totalCosts: "25000000",
    valueOfReclassifiedAssets: 1620357,
    depreciableAssets: 8,
  },
  {
    lat: 32.9892663,
    lng: -80.109357,
    squareFootage: "179726",
    propertyType: "Multifamily",
    totalCosts: "15138700",
    valueOfReclassifiedAssets: 5056304,
    depreciableAssets: 37,
  },
  {
    lat: 33.9290987,
    lng: -84.46976629999999,
    squareFootage: "163900",
    propertyType: "Multifamily",
    totalCosts: "6630758",
    valueOfReclassifiedAssets: 1737793,
    depreciableAssets: 34,
  },
  {
    lat: 34.319379,
    lng: -83.81547909999999,
    squareFootage: "196680",
    propertyType: "Multifamily",
    totalCosts: "8602704",
    valueOfReclassifiedAssets: 2770929,
    depreciableAssets: 36,
  },
  {
    lat: 36.0882182,
    lng: -95.8459341,
    squareFootage: "203583",
    propertyType: "Multifamily",
    totalCosts: "8200000",
    valueOfReclassifiedAssets: 2358787,
    depreciableAssets: 32,
  },
  {
    lat: 40.821948,
    lng: -74.21206289999999,
    squareFootage: "22120",
    propertyType: "Multifamily",
    totalCosts: "2900000",
    valueOfReclassifiedAssets: 313297,
    depreciableAssets: 13,
  },
  {
    lat: 40.6702816,
    lng: -75.329557,
    squareFootage: "258541",
    propertyType: "Retail",
    totalCosts: "41925767",
    valueOfReclassifiedAssets: 7663247,
    depreciableAssets: 22,
  },
  {
    lat: 42.1335205,
    lng: -87.9187711,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "68508911",
    valueOfReclassifiedAssets: 60258582,
    depreciableAssets: 22,
  },
  {
    lat: 41.1411152,
    lng: -74.1357407,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25480002",
    valueOfReclassifiedAssets: 22599676,
    depreciableAssets: 14,
  },
  {
    lat: 37.3172783,
    lng: -79.8912813,
    squareFootage: "120460",
    propertyType: "Retail",
    totalCosts: "13550000",
    valueOfReclassifiedAssets: 1989623,
    depreciableAssets: 25,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "78438",
    propertyType: "Office",
    totalCosts: "17598949",
    valueOfReclassifiedAssets: 15131424,
    depreciableAssets: 14,
  },
  {
    lat: 29.5370503,
    lng: -95.1953554,
    squareFootage: "19231",
    propertyType: "Retail",
    totalCosts: "3698113",
    valueOfReclassifiedAssets: 1156885,
    depreciableAssets: 36,
  },
  {
    lat: 29.5450583,
    lng: -95.1379946,
    squareFootage: "82328",
    propertyType: "Retail",
    totalCosts: "10815423",
    valueOfReclassifiedAssets: 2519337,
    depreciableAssets: 28,
  },
  {
    lat: 35.5135639,
    lng: -97.5788975,
    squareFootage: "45404",
    propertyType: "Multifamily",
    totalCosts: "4498750",
    valueOfReclassifiedAssets: 1078239,
    depreciableAssets: 28,
  },
  {
    lat: 36.105232,
    lng: -80.2042436,
    squareFootage: "461857",
    propertyType: "Office",
    totalCosts: "28116015",
    valueOfReclassifiedAssets: 1615079,
    depreciableAssets: 7,
  },
  {
    lat: 40.7064363,
    lng: -74.0094562,
    squareFootage: "1130423",
    propertyType: "Office",
    totalCosts: "198546495",
    valueOfReclassifiedAssets: 165509157,
    depreciableAssets: 18,
  },
  {
    lat: 40.8923269,
    lng: -73.9418603,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "10492595",
    valueOfReclassifiedAssets: 9530239,
    depreciableAssets: 16,
  },
  {
    lat: 40.7999209,
    lng: -73.96831019999999,
    squareFootage: "42186",
    propertyType: "Multifamily",
    totalCosts: "28101173",
    valueOfReclassifiedAssets: 2732597,
    depreciableAssets: 11,
  },
  {
    lat: 40.6190629,
    lng: -73.96537150000002,
    squareFootage: "77000",
    propertyType: "Multifamily",
    totalCosts: "17767559",
    valueOfReclassifiedAssets: 1660793,
    depreciableAssets: 11,
  },
  {
    lat: 40.6285158,
    lng: -73.9447994,
    squareFootage: "63000",
    propertyType: "Multifamily",
    totalCosts: "15846742",
    valueOfReclassifiedAssets: 2885125,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "71000",
    propertyType: "Multifamily",
    totalCosts: "18007663",
    valueOfReclassifiedAssets: 2097381,
    depreciableAssets: 13,
  },
  {
    lat: 40.6212119,
    lng: -74.03294989999999,
    squareFootage: "57036",
    propertyType: "Multifamily",
    totalCosts: "16086846",
    valueOfReclassifiedAssets: 1626168,
    depreciableAssets: 12,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "121680",
    propertyType: "Multifamily",
    totalCosts: "28028095",
    valueOfReclassifiedAssets: 2766668,
    depreciableAssets: 11,
  },
  {
    lat: 40.7154518,
    lng: -73.7919103,
    squareFootage: "70625",
    propertyType: "Multifamily",
    totalCosts: "16263095",
    valueOfReclassifiedAssets: 1896520,
    depreciableAssets: 14,
  },
  {
    lat: 40.99263819999999,
    lng: -73.78308609999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "23967238",
    valueOfReclassifiedAssets: 19117380,
    depreciableAssets: 22,
  },
  {
    lat: 35.2508232,
    lng: -81.5815776,
    squareFootage: "46020",
    propertyType: "Multifamily",
    totalCosts: "1655000",
    valueOfReclassifiedAssets: 370990,
    depreciableAssets: 23,
  },
  {
    lat: 35.3301866,
    lng: -81.0202533,
    squareFootage: "4474",
    propertyType: "Multifamily",
    totalCosts: "240000",
    valueOfReclassifiedAssets: 50112,
    depreciableAssets: 24,
  },
  {
    lat: 35.4127478,
    lng: -81.40591859999999,
    squareFootage: "11400",
    propertyType: "Multifamily",
    totalCosts: "606750",
    valueOfReclassifiedAssets: 133445,
    depreciableAssets: 26,
  },
  {
    lat: 32.9087285,
    lng: -96.7386946,
    squareFootage: "229410",
    propertyType: "Multifamily",
    totalCosts: "20640449",
    valueOfReclassifiedAssets: 4854731,
    depreciableAssets: 28,
  },
  {
    lat: 32.9087285,
    lng: -96.7386946,
    squareFootage: "183100",
    propertyType: "Multifamily",
    totalCosts: "29459550",
    valueOfReclassifiedAssets: 7951757,
    depreciableAssets: 31,
  },
  {
    lat: 40.8971509,
    lng: -73.9065883,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4700000",
    valueOfReclassifiedAssets: 3713594,
    depreciableAssets: 23,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5502743",
    valueOfReclassifiedAssets: 5016748,
    depreciableAssets: 0,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4020574",
    valueOfReclassifiedAssets: 3618391,
    depreciableAssets: 0,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1455194",
    valueOfReclassifiedAssets: 1144928,
    depreciableAssets: 0,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "983692",
    valueOfReclassifiedAssets: 830798,
    depreciableAssets: 0,
  },
  {
    lat: 40.7038704,
    lng: -74.0138541,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3264839",
    valueOfReclassifiedAssets: 2781818,
    depreciableAssets: 0,
  },
  {
    lat: 34.6939733,
    lng: -86.61071059999999,
    squareFootage: "95322",
    propertyType: "Office",
    totalCosts: "4646500",
    valueOfReclassifiedAssets: 891547,
    depreciableAssets: 23,
  },
  {
    lat: 33.449373,
    lng: -86.8671989,
    squareFootage: "59957",
    propertyType: "Industrial",
    totalCosts: "8550000",
    valueOfReclassifiedAssets: 2363563,
    depreciableAssets: 33,
  },
  {
    lat: 36.3699666,
    lng: -86.72151749999999,
    squareFootage: "35876",
    propertyType: "Office",
    totalCosts: "4370353",
    valueOfReclassifiedAssets: 757029,
    depreciableAssets: 32,
  },
  {
    lat: 36.1023342,
    lng: -86.64599439999999,
    squareFootage: "80360",
    propertyType: "Office",
    totalCosts: "14783749",
    valueOfReclassifiedAssets: 2445646,
    depreciableAssets: 18,
  },
  {
    lat: 40.77254850000001,
    lng: -74.21206289999999,
    squareFootage: "16842",
    propertyType: "Multifamily",
    totalCosts: "1750000",
    valueOfReclassifiedAssets: 266929,
    depreciableAssets: 18,
  },
  {
    lat: 40.7680056,
    lng: -74.2326058,
    squareFootage: "31800",
    propertyType: "Multifamily",
    totalCosts: "3350000",
    valueOfReclassifiedAssets: 595923,
    depreciableAssets: 21,
  },
  {
    lat: 40.61374379999999,
    lng: -74.4232507,
    squareFootage: "33951",
    propertyType: "Multifamily",
    totalCosts: "5512500",
    valueOfReclassifiedAssets: 902262,
    depreciableAssets: 20,
  },
  {
    lat: 39.1040382,
    lng: -94.57018029999999,
    squareFootage: "39078",
    propertyType: "Multifamily",
    totalCosts: "2250000",
    valueOfReclassifiedAssets: 440479,
    depreciableAssets: 23,
  },
  {
    lat: 36.0613546,
    lng: -95.9515399,
    squareFootage: "14298",
    propertyType: "Multifamily",
    totalCosts: "2450000",
    valueOfReclassifiedAssets: 461725,
    depreciableAssets: 22,
  },
  {
    lat: 36.0882182,
    lng: -95.8459341,
    squareFootage: "6836",
    propertyType: "Multifamily",
    totalCosts: "1325000",
    valueOfReclassifiedAssets: 363017,
    depreciableAssets: 33,
  },
  {
    lat: 30.2175453,
    lng: -97.8558357,
    squareFootage: "136951",
    propertyType: "Specialty",
    totalCosts: "23296261",
    valueOfReclassifiedAssets: 5882810,
    depreciableAssets: 30,
  },
  {
    lat: 27.8222933,
    lng: -97.5788975,
    squareFootage: "291844",
    propertyType: "Multifamily",
    totalCosts: "33870000",
    valueOfReclassifiedAssets: 10222324,
    depreciableAssets: 32,
  },
  {
    lat: 27.8222933,
    lng: -97.5788975,
    squareFootage: "291844",
    propertyType: "Multifamily",
    totalCosts: "33750000",
    valueOfReclassifiedAssets: 10184193,
    depreciableAssets: 32,
  },
  {
    lat: 27.8222933,
    lng: -97.5788975,
    squareFootage: "291844",
    propertyType: "Multifamily",
    totalCosts: "33750000",
    valueOfReclassifiedAssets: 10184193,
    depreciableAssets: 32,
  },
  {
    lat: 29.4651184,
    lng: -98.6765618,
    squareFootage: "180718",
    propertyType: "Multifamily",
    totalCosts: "20150000",
    valueOfReclassifiedAssets: 5039221,
    depreciableAssets: 30,
  },
  {
    lat: 30.2966026,
    lng: -97.9701846,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "11312157",
    valueOfReclassifiedAssets: 10577785,
    depreciableAssets: 24,
  },
  {
    lat: 36.1857974,
    lng: -79.8184684,
    squareFootage: "306153",
    propertyType: "Multifamily",
    totalCosts: "21597807",
    valueOfReclassifiedAssets: 4023127,
    depreciableAssets: 21,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "128907",
    propertyType: "Multifamily",
    totalCosts: "34100000",
    valueOfReclassifiedAssets: 9508033,
    depreciableAssets: 33,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "277620",
    propertyType: "Multifamily",
    totalCosts: "10035498",
    valueOfReclassifiedAssets: 2718702,
    depreciableAssets: 30,
  },
  {
    lat: 35.9980929,
    lng: -94.08999109999999,
    squareFootage: "154554",
    propertyType: "Multifamily",
    totalCosts: "2409681",
    valueOfReclassifiedAssets: 482939,
    depreciableAssets: 24,
  },
  {
    lat: 35.9980929,
    lng: -94.08999109999999,
    squareFootage: "154554",
    propertyType: "Multifamily",
    totalCosts: "5890319",
    valueOfReclassifiedAssets: 1180515,
    depreciableAssets: 24,
  },
  {
    lat: 40.8803247,
    lng: -73.9095279,
    squareFootage: "115800",
    propertyType: "Multifamily",
    totalCosts: "22922372",
    valueOfReclassifiedAssets: 3526227,
    depreciableAssets: 16,
  },
  {
    lat: 39.9289165,
    lng: -77.7108686,
    squareFootage: "82865",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21775109",
    valueOfReclassifiedAssets: 5595145,
    depreciableAssets: 25,
  },
  {
    lat: 39.9737098,
    lng: -75.35287790000001,
    squareFootage: "78127",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15264087",
    valueOfReclassifiedAssets: 3373098,
    depreciableAssets: 26,
  },
  {
    lat: 27.890814,
    lng: -82.50234640000001,
    squareFootage: "59674",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "15865000",
    valueOfReclassifiedAssets: 4602022,
    depreciableAssets: 31,
  },
  {
    lat: 25.783312,
    lng: -80.1484407,
    squareFootage: "65059",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "30750000",
    valueOfReclassifiedAssets: 6901500,
    depreciableAssets: 26,
  },
  {
    lat: 27.9711651,
    lng: -82.6969408,
    squareFootage: "50200",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 4290300,
    depreciableAssets: 29,
  },
  {
    lat: 27.8674465,
    lng: -82.7185282,
    squareFootage: "39639",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6000000",
    valueOfReclassifiedAssets: 1641367,
    depreciableAssets: 37,
  },
  {
    lat: 27.8674465,
    lng: -82.7185282,
    squareFootage: "45710",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 3608057,
    depreciableAssets: 34,
  },
  {
    lat: 34.5156085,
    lng: -84.9609464,
    squareFootage: "26234",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 1527933,
    depreciableAssets: 40,
  },
  {
    lat: 34.7046413,
    lng: -84.7315563,
    squareFootage: "37276",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "14699200",
    valueOfReclassifiedAssets: 4352213,
    depreciableAssets: 35,
  },
  {
    lat: 33.7709887,
    lng: -84.29633919999999,
    squareFootage: "27786",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "2570600",
    valueOfReclassifiedAssets: 812200,
    depreciableAssets: 37,
  },
  {
    lat: 33.6148122,
    lng: -84.6688348,
    squareFootage: "30543",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8421200",
    valueOfReclassifiedAssets: 3030545,
    depreciableAssets: 43,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "20461",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9096600",
    valueOfReclassifiedAssets: 3147116,
    depreciableAssets: 41,
  },
  {
    lat: 34.1968731,
    lng: -84.75244819999999,
    squareFootage: "30516",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "9818800",
    valueOfReclassifiedAssets: 3008728,
    depreciableAssets: 36,
  },
  {
    lat: 33.7994803,
    lng: -84.1909841,
    squareFootage: "61184",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "20993600",
    valueOfReclassifiedAssets: 5701114,
    depreciableAssets: 32,
  },
  {
    lat: 33.9052071,
    lng: -83.3148018,
    squareFootage: "260250",
    propertyType: "Multifamily",
    totalCosts: "19800000",
    valueOfReclassifiedAssets: 5642982,
    depreciableAssets: 30,
  },
  {
    lat: 40.658801,
    lng: -74.1063776,
    squareFootage: "22234",
    propertyType: "Office",
    totalCosts: "2194920",
    valueOfReclassifiedAssets: 321306,
    depreciableAssets: 19,
  },
  {
    lat: 39.6788037,
    lng: -86.0835415,
    squareFootage: "9358",
    propertyType: "Retail",
    totalCosts: "1375000",
    valueOfReclassifiedAssets: 257966,
    depreciableAssets: 22,
  },
  {
    lat: 29.8260177,
    lng: -95.7510475,
    squareFootage: "2020",
    propertyType: "Industrial",
    totalCosts: "94000000",
    valueOfReclassifiedAssets: 10400288,
    depreciableAssets: 12,
  },
  {
    lat: 33.4366655,
    lng: -111.9403254,
    squareFootage: "1491",
    propertyType: "Specialty",
    totalCosts: "5200000",
    valueOfReclassifiedAssets: 2602774,
    depreciableAssets: 59,
  },
  {
    lat: 33.4660406,
    lng: -112.0118669,
    squareFootage: "3390",
    propertyType: "Specialty",
    totalCosts: "6724000",
    valueOfReclassifiedAssets: 2750608,
    depreciableAssets: 53,
  },
  {
    lat: 33.4329528,
    lng: -111.8449346,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2750000",
    valueOfReclassifiedAssets: 196608,
    depreciableAssets: 76,
  },
  {
    lat: 33.4040693,
    lng: -112.029752,
    squareFootage: "420",
    propertyType: "Specialty",
    totalCosts: "4500000",
    valueOfReclassifiedAssets: 2486034,
    depreciableAssets: 74,
  },
  {
    lat: 33.4335164,
    lng: -111.7256936,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1400000",
    valueOfReclassifiedAssets: 999969,
    depreciableAssets: 88,
  },
  {
    lat: 33.4438042,
    lng: -112.1310986,
    squareFootage: "437",
    propertyType: "Specialty",
    totalCosts: "2086000",
    valueOfReclassifiedAssets: 699481,
    depreciableAssets: 77,
  },
  {
    lat: 33.4438042,
    lng: -112.1310986,
    squareFootage: "952",
    propertyType: "Multifamily",
    totalCosts: "1616000",
    valueOfReclassifiedAssets: 629247,
    depreciableAssets: 75,
  },
  {
    lat: 33.5349253,
    lng: -112.1847509,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "3300000",
    valueOfReclassifiedAssets: 1261315,
    depreciableAssets: 53,
  },
  {
    lat: 33.3091946,
    lng: -111.8210866,
    squareFootage: "517",
    propertyType: "Specialty",
    totalCosts: "3900000",
    valueOfReclassifiedAssets: 1058763,
    depreciableAssets: 68,
  },
  {
    lat: 32.2680738,
    lng: -110.9923904,
    squareFootage: "2775",
    propertyType: "Specialty",
    totalCosts: "500000",
    valueOfReclassifiedAssets: 134856,
    depreciableAssets: 52,
  },
  {
    lat: 33.3091946,
    lng: -111.8210866,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5675000",
    valueOfReclassifiedAssets: 1992742,
    depreciableAssets: 67,
  },
  {
    lat: 33.3091946,
    lng: -111.8210866,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4300000",
    valueOfReclassifiedAssets: 1862560,
    depreciableAssets: 82,
  },
  {
    lat: 33.3091946,
    lng: -111.8210866,
    squareFootage: "187",
    propertyType: "Specialty",
    totalCosts: "900000",
    valueOfReclassifiedAssets: 114966,
    depreciableAssets: 36,
  },
  {
    lat: 33.3762931,
    lng: -112.0357137,
    squareFootage: "1114",
    propertyType: "Specialty",
    totalCosts: "4400000",
    valueOfReclassifiedAssets: 2280751,
    depreciableAssets: 73,
  },
  {
    lat: 33.445974,
    lng: -111.8032005,
    squareFootage: "5994",
    propertyType: "Multifamily",
    totalCosts: "6100000",
    valueOfReclassifiedAssets: 2688084,
    depreciableAssets: 51,
  },
  {
    lat: 33.4040693,
    lng: -112.029752,
    squareFootage: "380",
    propertyType: "Specialty",
    totalCosts: "3125000",
    valueOfReclassifiedAssets: 1727238,
    depreciableAssets: 79,
  },
  {
    lat: 40.6024346,
    lng: -73.762495,
    squareFootage: "125000",
    propertyType: "Multifamily",
    totalCosts: "33000000",
    valueOfReclassifiedAssets: 3651229,
    depreciableAssets: 13,
  },
  {
    lat: 36.1005816,
    lng: -95.92512110000001,
    squareFootage: "94537",
    propertyType: "Multifamily",
    totalCosts: "8000000",
    valueOfReclassifiedAssets: 1956707,
    depreciableAssets: 29,
  },
  {
    lat: 29.9791272,
    lng: -99.14561529999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5570000",
    valueOfReclassifiedAssets: 4480517,
    depreciableAssets: 34,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "114585",
    propertyType: "Multifamily",
    totalCosts: "11150000",
    valueOfReclassifiedAssets: 3162364,
    depreciableAssets: 29,
  },
  {
    lat: 45.4081316,
    lng: -122.8033845,
    squareFootage: "64200",
    propertyType: "Multifamily",
    totalCosts: "10200000",
    valueOfReclassifiedAssets: 2810712,
    depreciableAssets: 39,
  },
  {
    lat: 45.4923824,
    lng: -122.8029665,
    squareFootage: "61787",
    propertyType: "Multifamily",
    totalCosts: "7700000",
    valueOfReclassifiedAssets: 1751381,
    depreciableAssets: 32,
  },
  {
    lat: 45.4618042,
    lng: -122.7999141,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "425713",
    valueOfReclassifiedAssets: 147026,
    depreciableAssets: 66,
  },
  {
    lat: 45.4501529,
    lng: -122.8849111,
    squareFootage: "72085",
    propertyType: "Multifamily",
    totalCosts: "525000",
    valueOfReclassifiedAssets: 129137,
    depreciableAssets: 35,
  },
  {
    lat: 45.4501529,
    lng: -122.8849111,
    squareFootage: "72085",
    propertyType: "Multifamily",
    totalCosts: "9975000",
    valueOfReclassifiedAssets: 2453606,
    depreciableAssets: 35,
  },
  {
    lat: 30.616559,
    lng: -96.31197429999999,
    squareFootage: "10500",
    propertyType: "Retail",
    totalCosts: "2400000",
    valueOfReclassifiedAssets: 588424,
    depreciableAssets: 35,
  },
  {
    lat: 32.7272701,
    lng: -97.0767365,
    squareFootage: "110634",
    propertyType: "Multifamily",
    totalCosts: "10165563",
    valueOfReclassifiedAssets: 2768010,
    depreciableAssets: 30,
  },
  {
    lat: 40.0807404,
    lng: -74.2061931,
    squareFootage: "5073",
    propertyType: "Specialty",
    totalCosts: "2920000",
    valueOfReclassifiedAssets: 1618437,
    depreciableAssets: 60,
  },
  {
    lat: 39.5123182,
    lng: -76.18996609999999,
    squareFootage: "165050",
    propertyType: "Multifamily",
    totalCosts: "5401693",
    valueOfReclassifiedAssets: 4344158,
    depreciableAssets: 25,
  },
  {
    lat: 39.1125485,
    lng: -84.16461629999999,
    squareFootage: "174245",
    propertyType: "Multifamily",
    totalCosts: "11475485",
    valueOfReclassifiedAssets: 9173352,
    depreciableAssets: 29,
  },
  {
    lat: 40.0851765,
    lng: -74.0593872,
    squareFootage: "31412",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "8879103",
    valueOfReclassifiedAssets: 6457973,
    depreciableAssets: 31,
  },
  {
    lat: 37.0538822,
    lng: -76.39845369999999,
    squareFootage: "62856",
    propertyType: "Office",
    totalCosts: "5260054",
    valueOfReclassifiedAssets: 651993,
    depreciableAssets: 15,
  },
  {
    lat: 39.8522951,
    lng: -74.88579890000001,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "591500",
    valueOfReclassifiedAssets: 431923,
    depreciableAssets: 0,
  },
  {
    lat: 39.8522951,
    lng: -74.88579890000001,
    squareFootage: "100000",
    propertyType: "Office",
    totalCosts: "4787893",
    valueOfReclassifiedAssets: 837596,
    depreciableAssets: 21,
  },
  {
    lat: 41.89322,
    lng: -88.7686382,
    squareFootage: "304704",
    propertyType: "Industrial",
    totalCosts: "6440673",
    valueOfReclassifiedAssets: 1218325,
    depreciableAssets: 22,
  },
  {
    lat: 32.7229925,
    lng: -97.15209259999999,
    squareFootage: "122222",
    propertyType: "Multifamily",
    totalCosts: "11750000",
    valueOfReclassifiedAssets: 3669004,
    depreciableAssets: 32,
  },
  {
    lat: 32.7722013,
    lng: -97.0767365,
    squareFootage: "209612",
    propertyType: "Multifamily",
    totalCosts: "26800000",
    valueOfReclassifiedAssets: 7691048,
    depreciableAssets: 30,
  },
  {
    lat: 32.9985237,
    lng: -96.84046,
    squareFootage: "163135",
    propertyType: "Multifamily",
    totalCosts: "20200000",
    valueOfReclassifiedAssets: 5678212,
    depreciableAssets: 33,
  },
  {
    lat: 29.6451476,
    lng: -95.5249863,
    squareFootage: "61331",
    propertyType: "Multifamily",
    totalCosts: "5116795",
    valueOfReclassifiedAssets: 1373424,
    depreciableAssets: 31,
  },
  {
    lat: 29.6549449,
    lng: -95.2841177,
    squareFootage: "227848",
    propertyType: "Multifamily",
    totalCosts: "18368000",
    valueOfReclassifiedAssets: 4116701,
    depreciableAssets: 27,
  },
  {
    lat: 29.6451476,
    lng: -95.5249863,
    squareFootage: "134296",
    propertyType: "Multifamily",
    totalCosts: "11119200",
    valueOfReclassifiedAssets: 2551785,
    depreciableAssets: 27,
  },
  {
    lat: 29.6771668,
    lng: -95.587986,
    squareFootage: "280253",
    propertyType: "Multifamily",
    totalCosts: "26750000",
    valueOfReclassifiedAssets: 6352515,
    depreciableAssets: 28,
  },
  {
    lat: 34.1147313,
    lng: -118.3637264,
    squareFootage: "9148",
    propertyType: "Multifamily",
    totalCosts: "3222982",
    valueOfReclassifiedAssets: 424455,
    depreciableAssets: 22,
  },
  {
    lat: 34.1873985,
    lng: -118.3900204,
    squareFootage: "20184",
    propertyType: "Multifamily",
    totalCosts: "3767579",
    valueOfReclassifiedAssets: 633405,
    depreciableAssets: 28,
  },
  {
    lat: 39.2123057,
    lng: -84.4015075,
    squareFootage: "39840",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "5813926",
    valueOfReclassifiedAssets: 2465541,
    depreciableAssets: 30,
  },
  {
    lat: 39.1540971,
    lng: -84.485507,
    squareFootage: "42000",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "10586074",
    valueOfReclassifiedAssets: 3669717,
    depreciableAssets: 27,
  },
  {
    lat: 37.1996252,
    lng: -95.77212039999999,
    squareFootage: "214860",
    propertyType: "Multifamily",
    totalCosts: "13715595",
    valueOfReclassifiedAssets: 4001641,
    depreciableAssets: 31,
  },
  {
    lat: 32.6905101,
    lng: -97.1305536,
    squareFootage: "71216",
    propertyType: "Multifamily",
    totalCosts: "6801000",
    valueOfReclassifiedAssets: 1783333,
    depreciableAssets: 31,
  },
  {
    lat: 35.0074096,
    lng: -81.22985299999999,
    squareFootage: "69975",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "45000000",
    valueOfReclassifiedAssets: 9853946,
    depreciableAssets: 25,
  },
  {
    lat: 31.148223,
    lng: -97.6765157,
    squareFootage: "138360",
    propertyType: "Multifamily",
    totalCosts: "7085819",
    valueOfReclassifiedAssets: 1712497,
    depreciableAssets: 25,
  },
  {
    lat: 29.7183467,
    lng: -95.4306141,
    squareFootage: "390245",
    propertyType: "Industrial",
    totalCosts: "65772895",
    valueOfReclassifiedAssets: 10819020,
    depreciableAssets: 19,
  },
  {
    lat: 33.090285,
    lng: -117.0499859,
    squareFootage: "9900",
    propertyType: "Industrial",
    totalCosts: "2090000",
    valueOfReclassifiedAssets: 269253,
    depreciableAssets: 15,
  },
  {
    lat: 32.7476492,
    lng: -97.09248989999999,
    squareFootage: "3000",
    propertyType: "Retail",
    totalCosts: "923250",
    valueOfReclassifiedAssets: 279990,
    depreciableAssets: 36,
  },
  {
    lat: 41.98250650000001,
    lng: -73.9888797,
    squareFootage: "198029",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "21339080",
    valueOfReclassifiedAssets: 4200346,
    depreciableAssets: 22,
  },
  {
    lat: 42.68433760000001,
    lng: -73.7301331,
    squareFootage: "73575",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "7985526",
    valueOfReclassifiedAssets: 1801694,
    depreciableAssets: 25,
  },
  {
    lat: 30.7138131,
    lng: -81.931657,
    squareFootage: "130938",
    propertyType: "Multifamily",
    totalCosts: "7000000",
    valueOfReclassifiedAssets: 1991599,
    depreciableAssets: 33,
  },
  {
    lat: 30.2906907,
    lng: -81.57061089999999,
    squareFootage: "40000",
    propertyType: "Multifamily",
    totalCosts: "9460000",
    valueOfReclassifiedAssets: 2628582,
    depreciableAssets: 33,
  },
  {
    lat: 34.2563043,
    lng: -117.1558867,
    squareFootage: "1000",
    propertyType: "Retail",
    totalCosts: "49400000",
    valueOfReclassifiedAssets: 7788391,
    depreciableAssets: 21,
  },
  {
    lat: 38.54676570000001,
    lng: -122.8197329,
    squareFootage: "130160",
    propertyType: "Retail",
    totalCosts: "18048466",
    valueOfReclassifiedAssets: 3179513,
    depreciableAssets: 25,
  },
  {
    lat: 38.6198298,
    lng: -121.5198716,
    squareFootage: "89548",
    propertyType: "Retail",
    totalCosts: "15011467",
    valueOfReclassifiedAssets: 12902375,
    depreciableAssets: 26,
  },
  {
    lat: 41.904735,
    lng: -87.8598861,
    squareFootage: "41925",
    propertyType: "Retail",
    totalCosts: "3142657",
    valueOfReclassifiedAssets: 2831323,
    depreciableAssets: 18,
  },
  {
    lat: 33.9414796,
    lng: -118.0712981,
    squareFootage: "28542",
    propertyType: "Industrial",
    totalCosts: "6080000",
    valueOfReclassifiedAssets: 585093,
    depreciableAssets: 13,
  },
  {
    lat: 40.08652379999999,
    lng: -77.03341790000002,
    squareFootage: "153088",
    propertyType: "Retail",
    totalCosts: "24000000",
    valueOfReclassifiedAssets: 6415711,
    depreciableAssets: 31,
  },
  {
    lat: 37.9817391,
    lng: -84.485507,
    squareFootage: "135924",
    propertyType: "Multifamily",
    totalCosts: "8150000",
    valueOfReclassifiedAssets: 1897006,
    depreciableAssets: 26,
  },
  {
    lat: 47.6614244,
    lng: -122.2683743,
    squareFootage: "7049",
    propertyType: "Multifamily",
    totalCosts: "4122800",
    valueOfReclassifiedAssets: 785477,
    depreciableAssets: 22,
  },
  {
    lat: 36.7017377,
    lng: -95.8775964,
    squareFootage: "224195",
    propertyType: "Multifamily",
    totalCosts: "13250000",
    valueOfReclassifiedAssets: 3594648,
    depreciableAssets: 32,
  },
  {
    lat: 36.0428053,
    lng: -95.8881542,
    squareFootage: "362000",
    propertyType: "Multifamily",
    totalCosts: "7125830",
    valueOfReclassifiedAssets: 1444819,
    depreciableAssets: 24,
  },
  {
    lat: 42.5357932,
    lng: -70.9665682,
    squareFootage: "15521",
    propertyType: "Multifamily",
    totalCosts: "2811440",
    valueOfReclassifiedAssets: 469668,
    depreciableAssets: 18,
  },
  {
    lat: 30.0220021,
    lng: -95.42242999999999,
    squareFootage: "30690",
    propertyType: "Multifamily",
    totalCosts: "14388560",
    valueOfReclassifiedAssets: 2383950,
    depreciableAssets: 20,
  },
  {
    lat: 33.9290987,
    lng: -84.46976629999999,
    squareFootage: "227072",
    propertyType: "Multifamily",
    totalCosts: "25786000",
    valueOfReclassifiedAssets: 4257988,
    depreciableAssets: 19,
  },
  {
    lat: 33.7971137,
    lng: -84.3804888,
    squareFootage: "16981",
    propertyType: "Multifamily",
    totalCosts: "6700000",
    valueOfReclassifiedAssets: 899024,
    depreciableAssets: 16,
  },
  {
    lat: 43.1156039,
    lng: -89.5308903,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2425250",
    valueOfReclassifiedAssets: 1293647,
    depreciableAssets: 0,
  },
  {
    lat: 41.620939,
    lng: -83.75178509999999,
    squareFootage: "60771",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11006224",
    valueOfReclassifiedAssets: 3040226,
    depreciableAssets: 28,
  },
  {
    lat: 42.2164077,
    lng: -83.2720082,
    squareFootage: "83540",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "19587766",
    valueOfReclassifiedAssets: 5201996,
    depreciableAssets: 27,
  },
  {
    lat: 42.0039336,
    lng: -87.7344988,
    squareFootage: "71524",
    propertyType: "Office",
    totalCosts: "4250000",
    valueOfReclassifiedAssets: 595974,
    depreciableAssets: 17,
  },
  {
    lat: 42.758734,
    lng: -84.44877199999999,
    squareFootage: "154000",
    propertyType: "Multifamily",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 2693776,
    depreciableAssets: 31,
  },
  {
    lat: 42.32182359999999,
    lng: -71.08840359999999,
    squareFootage: "25000",
    propertyType: "Multifamily",
    totalCosts: "5250000",
    valueOfReclassifiedAssets: 521368,
    depreciableAssets: 12,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "5328464",
    valueOfReclassifiedAssets: 4559800,
    depreciableAssets: 21,
  },
  {
    lat: 35.1825962,
    lng: -80.6548882,
    squareFootage: "158212",
    propertyType: "Multifamily",
    totalCosts: "9700000",
    valueOfReclassifiedAssets: 1238785,
    depreciableAssets: 16,
  },
  {
    lat: 42.758734,
    lng: -84.44877199999999,
    squareFootage: "154000",
    propertyType: "Multifamily",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 2340378,
    depreciableAssets: 27,
  },
  {
    lat: 47.7393027,
    lng: -122.3435335,
    squareFootage: "172903",
    propertyType: "Multifamily",
    totalCosts: "14710000",
    valueOfReclassifiedAssets: 1636336,
    depreciableAssets: 13,
  },
  {
    lat: 32.0371137,
    lng: -81.1747498,
    squareFootage: "110083",
    propertyType: "Multifamily",
    totalCosts: "6400000",
    valueOfReclassifiedAssets: 1033827,
    depreciableAssets: 18,
  },
  {
    lat: 34.319379,
    lng: -83.81547909999999,
    squareFootage: "81790",
    propertyType: "Multifamily",
    totalCosts: "5900000",
    valueOfReclassifiedAssets: 1040231,
    depreciableAssets: 21,
  },
  {
    lat: 31.3262391,
    lng: -81.5377013,
    squareFootage: "58499",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 877291,
    depreciableAssets: 19,
  },
  {
    lat: 39.8580556,
    lng: -104.9564084,
    squareFootage: "170210",
    propertyType: "Multifamily",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 4012303,
    depreciableAssets: 23,
  },
  {
    lat: 30.5059229,
    lng: -91.1113216,
    squareFootage: "109910",
    propertyType: "Multifamily",
    totalCosts: "4612500",
    valueOfReclassifiedAssets: 1068059,
    depreciableAssets: 30,
  },
  {
    lat: 39.5781244,
    lng: -74.5697617,
    squareFootage: "64840",
    propertyType: "Specialty",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 296341,
    depreciableAssets: 23,
  },
  {
    lat: 39.5781244,
    lng: -74.5697617,
    squareFootage: "57500",
    propertyType: "Hotel",
    totalCosts: "2710000",
    valueOfReclassifiedAssets: 662466,
    depreciableAssets: 26,
  },
  {
    lat: 47.1547274,
    lng: -122.4554287,
    squareFootage: "14500",
    propertyType: "Multifamily",
    totalCosts: "959523",
    valueOfReclassifiedAssets: 211066,
    depreciableAssets: 31,
  },
  {
    lat: 32.2546522,
    lng: -110.9447027,
    squareFootage: "21301",
    propertyType: "Multifamily",
    totalCosts: "6465793",
    valueOfReclassifiedAssets: 1951277,
    depreciableAssets: 36,
  },
  {
    lat: 39.80429470000001,
    lng: -105.0206757,
    squareFootage: "30700",
    propertyType: "Specialty",
    totalCosts: "4225000",
    valueOfReclassifiedAssets: 1091883,
    depreciableAssets: 30,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "3180",
    propertyType: "Multifamily",
    totalCosts: "7350000",
    valueOfReclassifiedAssets: 2071571,
    depreciableAssets: 31,
  },
  {
    lat: 43.9147417,
    lng: -76.097201,
    squareFootage: "149565",
    propertyType: "Multifamily",
    totalCosts: "5000000",
    valueOfReclassifiedAssets: 1117511,
    depreciableAssets: 25,
  },
  {
    lat: 38.4803833,
    lng: -82.6537455,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "12494437",
    valueOfReclassifiedAssets: 10771329,
    depreciableAssets: 14,
  },
  {
    lat: 36.1426113,
    lng: -80.33245140000001,
    squareFootage: "197415",
    propertyType: "Multifamily",
    totalCosts: "6414619",
    valueOfReclassifiedAssets: 1767441,
    depreciableAssets: 31,
  },
  {
    lat: 40.7629831,
    lng: -74.0211989,
    squareFootage: "32720",
    propertyType: "Multifamily",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 267978,
    depreciableAssets: 14,
  },
  {
    lat: 40.7629831,
    lng: -74.0211989,
    squareFootage: "31540",
    propertyType: "Multifamily",
    totalCosts: "1550000",
    valueOfReclassifiedAssets: 231232,
    depreciableAssets: 16,
  },
  {
    lat: 40.773694,
    lng: -74.0270745,
    squareFootage: "13560",
    propertyType: "Multifamily",
    totalCosts: "2600000",
    valueOfReclassifiedAssets: 546536,
    depreciableAssets: 23,
  },
  {
    lat: 30.4587407,
    lng: -90.13077969999999,
    squareFootage: "19235",
    propertyType: "Retail",
    totalCosts: "3640000",
    valueOfReclassifiedAssets: 1052261,
    depreciableAssets: 34,
  },
  {
    lat: 28.8887495,
    lng: -81.2408689,
    squareFootage: "131365",
    propertyType: "Retail",
    totalCosts: "13333333",
    valueOfReclassifiedAssets: 3777018,
    depreciableAssets: 33,
  },
  {
    lat: 28.8887495,
    lng: -81.2408689,
    squareFootage: "131365",
    propertyType: "Retail",
    totalCosts: "370663",
    valueOfReclassifiedAssets: 105000,
    depreciableAssets: 33,
  },
  {
    lat: 33.6805008,
    lng: -96.7279918,
    squareFootage: "87122",
    propertyType: "Multifamily",
    totalCosts: "5750000",
    valueOfReclassifiedAssets: 1416143,
    depreciableAssets: 27,
  },
  {
    lat: 33.6805008,
    lng: -96.7279918,
    squareFootage: "175656",
    propertyType: "Multifamily",
    totalCosts: "23425000",
    valueOfReclassifiedAssets: 7371012,
    depreciableAssets: 37,
  },
  {
    lat: 34.0820025,
    lng: -83.94266979999999,
    squareFootage: "10400",
    propertyType: "Retail",
    totalCosts: "5775000",
    valueOfReclassifiedAssets: 1924326,
    depreciableAssets: 40,
  },
  {
    lat: 29.64645269999999,
    lng: -95.4935119,
    squareFootage: "41596",
    propertyType: "Multifamily",
    totalCosts: "3000000",
    valueOfReclassifiedAssets: 677808,
    depreciableAssets: 25,
  },
  {
    lat: 29.6491178,
    lng: -95.1379946,
    squareFootage: "1900",
    propertyType: "Retail",
    totalCosts: "1588921",
    valueOfReclassifiedAssets: 422699,
    depreciableAssets: 51,
  },
  {
    lat: 29.0183936,
    lng: -96.7065914,
    squareFootage: "21702",
    propertyType: "Retail",
    totalCosts: "2902909",
    valueOfReclassifiedAssets: 1053880,
    depreciableAssets: 37,
  },
  {
    lat: 40.7160913,
    lng: -74.07407289999999,
    squareFootage: "46275",
    propertyType: "Multifamily",
    totalCosts: "9800000",
    valueOfReclassifiedAssets: 1168126,
    depreciableAssets: 14,
  },
  {
    lat: 47.6683603,
    lng: -122.3769582,
    squareFootage: "30200",
    propertyType: "Multifamily",
    totalCosts: "4397166",
    valueOfReclassifiedAssets: 747337,
    depreciableAssets: 19,
  },
  {
    lat: 47.6015566,
    lng: -122.3323351,
    squareFootage: "19140",
    propertyType: "Multifamily",
    totalCosts: "4114331",
    valueOfReclassifiedAssets: 480252,
    depreciableAssets: 16,
  },
  {
    lat: 47.6152474,
    lng: -122.3497446,
    squareFootage: "21130",
    propertyType: "Multifamily",
    totalCosts: "6179122",
    valueOfReclassifiedAssets: 156215,
    depreciableAssets: 10,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "26044",
    propertyType: "Multifamily",
    totalCosts: "2648285",
    valueOfReclassifiedAssets: 278388,
    depreciableAssets: 18,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "26044",
    propertyType: "Multifamily",
    totalCosts: "1863608",
    valueOfReclassifiedAssets: 195902,
    depreciableAssets: 18,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "26044",
    propertyType: "Multifamily",
    totalCosts: "2101286",
    valueOfReclassifiedAssets: 255189,
    depreciableAssets: 18,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "130000",
    propertyType: "Industrial",
    totalCosts: "4975519",
    valueOfReclassifiedAssets: 696325,
    depreciableAssets: 16,
  },
  {
    lat: 39.9571056,
    lng: -83.1273705,
    squareFootage: "226000",
    propertyType: "Industrial",
    totalCosts: "8649481",
    valueOfReclassifiedAssets: 1454401,
    depreciableAssets: 20,
  },
  {
    lat: 33.5083316,
    lng: -112.0565795,
    squareFootage: "140815",
    propertyType: "Multifamily",
    totalCosts: "26500000",
    valueOfReclassifiedAssets: 6564994,
    depreciableAssets: 29,
  },
  {
    lat: 36.127028,
    lng: -79.8856824,
    squareFootage: "345666",
    propertyType: "Multifamily",
    totalCosts: "49000000",
    valueOfReclassifiedAssets: 12940701,
    depreciableAssets: 31,
  },
  {
    lat: 33.514162,
    lng: -112.1251372,
    squareFootage: "61021",
    propertyType: "Multifamily",
    totalCosts: "8150000",
    valueOfReclassifiedAssets: 2380379,
    depreciableAssets: 34,
  },
  {
    lat: 33.514162,
    lng: -112.1251372,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1341210",
    valueOfReclassifiedAssets: 850550,
    depreciableAssets: 40,
  },
  {
    lat: 33.4329528,
    lng: -111.8449346,
    squareFootage: "89147",
    propertyType: "Multifamily",
    totalCosts: "10600000",
    valueOfReclassifiedAssets: 1918148,
    depreciableAssets: 21,
  },
  {
    lat: 38.9797389,
    lng: -77.004626,
    squareFootage: "2717",
    propertyType: "Retail",
    totalCosts: "1272191",
    valueOfReclassifiedAssets: 488057,
    depreciableAssets: 45,
  },
  {
    lat: 47.5745802,
    lng: -122.4047843,
    squareFootage: "17678",
    propertyType: "Multifamily",
    totalCosts: "9526638",
    valueOfReclassifiedAssets: 1712341,
    depreciableAssets: 21,
  },
  {
    lat: 45.5205043,
    lng: -122.707349,
    squareFootage: "13649",
    propertyType: "Multifamily",
    totalCosts: "6836878",
    valueOfReclassifiedAssets: 1278304,
    depreciableAssets: 21,
  },
  {
    lat: 47.6329523,
    lng: -122.2891887,
    squareFootage: "22467",
    propertyType: "Multifamily",
    totalCosts: "17635415",
    valueOfReclassifiedAssets: 3535244,
    depreciableAssets: 25,
  },
  {
    lat: 47.6683603,
    lng: -122.3769582,
    squareFootage: "21795",
    propertyType: "Multifamily",
    totalCosts: "11027510",
    valueOfReclassifiedAssets: 2337977,
    depreciableAssets: 24,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "53351",
    propertyType: "Multifamily",
    totalCosts: "20511658",
    valueOfReclassifiedAssets: 14163370,
    depreciableAssets: 21,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2666214",
    valueOfReclassifiedAssets: 2067874,
    depreciableAssets: 30,
  },
  {
    lat: 47.3165043,
    lng: -122.3223975,
    squareFootage: "107965",
    propertyType: "Retail",
    totalCosts: "12050999",
    valueOfReclassifiedAssets: 1331958,
    depreciableAssets: 21,
  },
  {
    lat: 47.6150436,
    lng: -122.1717577,
    squareFootage: "21467",
    propertyType: "Office",
    totalCosts: "7829747",
    valueOfReclassifiedAssets: 887902,
    depreciableAssets: 23,
  },
  {
    lat: 47.6688298,
    lng: -122.1923875,
    squareFootage: "9000",
    propertyType: "Multifamily",
    totalCosts: "3140518",
    valueOfReclassifiedAssets: 2613941,
    depreciableAssets: 21,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "15800",
    propertyType: "Industrial",
    totalCosts: "21000000",
    valueOfReclassifiedAssets: 3019841,
    depreciableAssets: 17,
  },
  {
    lat: 47.6701193,
    lng: -122.1182369,
    squareFootage: "40000",
    propertyType: "Retail",
    totalCosts: "12366853",
    valueOfReclassifiedAssets: 1154080,
    depreciableAssets: 14,
  },
  {
    lat: 47.6084921,
    lng: -122.336407,
    squareFootage: "9802",
    propertyType: "Office",
    totalCosts: "2663545",
    valueOfReclassifiedAssets: 182540,
    depreciableAssets: 15,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "9943",
    propertyType: "Office",
    totalCosts: "2402268",
    valueOfReclassifiedAssets: 303536,
    depreciableAssets: 23,
  },
  {
    lat: 47.6288591,
    lng: -122.3456919,
    squareFootage: "6000",
    propertyType: "Specialty",
    totalCosts: "2811958",
    valueOfReclassifiedAssets: 1684958,
    depreciableAssets: 100,
  },
  {
    lat: 40.7043156,
    lng: -73.92128579999999,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1703628",
    valueOfReclassifiedAssets: 1511585,
    depreciableAssets: 12,
  },
  {
    lat: 35.4811981,
    lng: -97.6602365,
    squareFootage: "131528",
    propertyType: "Multifamily",
    totalCosts: "5414792",
    valueOfReclassifiedAssets: 1240212,
    depreciableAssets: 27,
  },
  {
    lat: 29.740582,
    lng: -95.4515725,
    squareFootage: "220902",
    propertyType: "Multifamily",
    totalCosts: "9250000",
    valueOfReclassifiedAssets: 2182264,
    depreciableAssets: 28,
  },
  {
    lat: 33.087882,
    lng: -96.0891032,
    squareFootage: "220900",
    propertyType: "Multifamily",
    totalCosts: "21700000",
    valueOfReclassifiedAssets: 4526777,
    depreciableAssets: 25,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "13565",
    propertyType: "Multifamily",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1045021,
    depreciableAssets: 25,
  },
  {
    lat: 40.7275043,
    lng: -73.9800645,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "7957654",
    valueOfReclassifiedAssets: 6761544,
    depreciableAssets: 16,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14854829",
    valueOfReclassifiedAssets: 12992668,
    depreciableAssets: 14,
  },
  {
    lat: 40.7217861,
    lng: -74.0094471,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "14225269",
    valueOfReclassifiedAssets: 12442028,
    depreciableAssets: 14,
  },
  {
    lat: 39.0038878,
    lng: -77.1053673,
    squareFootage: "7800",
    propertyType: "Office",
    totalCosts: "1303486",
    valueOfReclassifiedAssets: 168123,
    depreciableAssets: 15,
  },
  {
    lat: 37.16547,
    lng: -77.4931403,
    squareFootage: "491300",
    propertyType: "Multifamily",
    totalCosts: "14200000",
    valueOfReclassifiedAssets: 2974413,
    depreciableAssets: 25,
  },
  {
    lat: 34.5294947,
    lng: -82.6267345,
    squareFootage: "80000",
    propertyType: "Multifamily",
    totalCosts: "5350000",
    valueOfReclassifiedAssets: 1594289,
    depreciableAssets: 36,
  },
  {
    lat: 33.9052071,
    lng: -83.3148018,
    squareFootage: "233850",
    propertyType: "Multifamily",
    totalCosts: "17000000",
    valueOfReclassifiedAssets: 3764226,
    depreciableAssets: 26,
  },
  {
    lat: 33.6580771,
    lng: -84.38574419999999,
    squareFootage: "209580",
    propertyType: "Multifamily",
    totalCosts: "11250000",
    valueOfReclassifiedAssets: 3042869,
    depreciableAssets: 32,
  },
  {
    lat: 25.8127791,
    lng: -80.2377078,
    squareFootage: "50400",
    propertyType: "Multifamily",
    totalCosts: "7560000",
    valueOfReclassifiedAssets: 1767246,
    depreciableAssets: 28,
  },
  {
    lat: 25.7870967,
    lng: -80.2042436,
    squareFootage: "25890",
    propertyType: "Multifamily",
    totalCosts: "4394052",
    valueOfReclassifiedAssets: 921676,
    depreciableAssets: 25,
  },
  {
    lat: 25.7870967,
    lng: -80.2042436,
    squareFootage: "8185",
    propertyType: "Multifamily",
    totalCosts: "2095309",
    valueOfReclassifiedAssets: 510788,
    depreciableAssets: 29,
  },
  {
    lat: 25.7870967,
    lng: -80.2042436,
    squareFootage: "8411",
    propertyType: "Multifamily",
    totalCosts: "1664022",
    valueOfReclassifiedAssets: 378203,
    depreciableAssets: 27,
  },
  {
    lat: 25.7870967,
    lng: -80.2042436,
    squareFootage: "26887",
    propertyType: "Multifamily",
    totalCosts: "5676252",
    valueOfReclassifiedAssets: 1281103,
    depreciableAssets: 27,
  },
  {
    lat: 25.7870967,
    lng: -80.2042436,
    squareFootage: "5000",
    propertyType: "Office",
    totalCosts: "306947",
    valueOfReclassifiedAssets: 42117,
    depreciableAssets: 17,
  },
  {
    lat: 41.0901834,
    lng: -73.541744,
    squareFootage: "5858",
    propertyType: "Office",
    totalCosts: "2100000",
    valueOfReclassifiedAssets: 538140,
    depreciableAssets: 30,
  },
  {
    lat: 38.1422302,
    lng: -85.6743082,
    squareFootage: "23700",
    propertyType: "Multifamily",
    totalCosts: "1500000",
    valueOfReclassifiedAssets: 479949,
    depreciableAssets: 38,
  },
  {
    lat: 38.2191978,
    lng: -85.6177891,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "60430",
    valueOfReclassifiedAssets: 47788,
    depreciableAssets: 77,
  },
  {
    lat: 41.8269891,
    lng: -73.1585626,
    squareFootage: "49346",
    propertyType: "Multifamily",
    totalCosts: "4575000",
    valueOfReclassifiedAssets: 765221,
    depreciableAssets: 20,
  },
  {
    lat: 41.8269891,
    lng: -73.1585626,
    squareFootage: "26916",
    propertyType: "Multifamily",
    totalCosts: "2675000",
    valueOfReclassifiedAssets: 638898,
    depreciableAssets: 28,
  },
  {
    lat: 41.9357632,
    lng: -73.111356,
    squareFootage: "62734",
    propertyType: "Multifamily",
    totalCosts: "5150000",
    valueOfReclassifiedAssets: 700947,
    depreciableAssets: 16,
  },
  {
    lat: 41.5528933,
    lng: -73.0759446,
    squareFootage: "88854",
    propertyType: "Multifamily",
    totalCosts: "7250000",
    valueOfReclassifiedAssets: 1176474,
    depreciableAssets: 19,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "45212",
    propertyType: "Multifamily",
    totalCosts: "3800000",
    valueOfReclassifiedAssets: 623300,
    depreciableAssets: 19,
  },
  {
    lat: 41.6750889,
    lng: -72.92243289999999,
    squareFootage: "10285",
    propertyType: "Multifamily",
    totalCosts: "600000",
    valueOfReclassifiedAssets: 118350,
    depreciableAssets: 23,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "91105",
    propertyType: "Multifamily",
    totalCosts: "12000000",
    valueOfReclassifiedAssets: 2848897,
    depreciableAssets: 27,
  },
  {
    lat: 40.3038147,
    lng: -74.0887576,
    squareFootage: "89775",
    propertyType: "Hotel",
    totalCosts: "23500000",
    valueOfReclassifiedAssets: 14020385,
    depreciableAssets: 28,
  },
  {
    lat: 25.9502474,
    lng: -80.1930859,
    squareFootage: "183828",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "11500000",
    valueOfReclassifiedAssets: 1859684,
    depreciableAssets: 19,
  },
  {
    lat: 28.3040861,
    lng: -81.422404,
    squareFootage: "65084",
    propertyType: "Industrial",
    totalCosts: "4100000",
    valueOfReclassifiedAssets: 814198,
    depreciableAssets: 22,
  },
  {
    lat: 28.1434318,
    lng: -82.33433749999999,
    squareFootage: "180800",
    propertyType: "Industrial",
    totalCosts: "8540747",
    valueOfReclassifiedAssets: 2005487,
    depreciableAssets: 27,
  },
  {
    lat: 39.9559288,
    lng: -75.1574567,
    squareFootage: "119500",
    propertyType: "Industrial",
    totalCosts: "5575000",
    valueOfReclassifiedAssets: 1200429,
    depreciableAssets: 24,
  },
  {
    lat: 41.5139193,
    lng: -81.6747295,
    squareFootage: "647264",
    propertyType: "Office",
    totalCosts: "54000000",
    valueOfReclassifiedAssets: 4329517,
    depreciableAssets: 9,
  },
  {
    lat: 39.2963369,
    lng: -76.62105389999999,
    squareFootage: "360282",
    propertyType: "Office",
    totalCosts: "26850000",
    valueOfReclassifiedAssets: 3535389,
    depreciableAssets: 19,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2868863",
    valueOfReclassifiedAssets: 2045927,
    depreciableAssets: 0,
  },
  {
    lat: 33.4660406,
    lng: -112.0118669,
    squareFootage: "54679",
    propertyType: "Multifamily",
    totalCosts: "6900000",
    valueOfReclassifiedAssets: 1708158,
    depreciableAssets: 30,
  },
  {
    lat: 33.4986286,
    lng: -111.9224398,
    squareFootage: "30202",
    propertyType: "Multifamily",
    totalCosts: "1795860",
    valueOfReclassifiedAssets: 474707,
    depreciableAssets: 32,
  },
  {
    lat: 33.4986286,
    lng: -111.9224398,
    squareFootage: "30202",
    propertyType: "Multifamily",
    totalCosts: "778140",
    valueOfReclassifiedAssets: 205689,
    depreciableAssets: 32,
  },
  {
    lat: 33.4986286,
    lng: -111.9224398,
    squareFootage: "30202",
    propertyType: "Multifamily",
    totalCosts: "2768700",
    valueOfReclassifiedAssets: 731861,
    depreciableAssets: 32,
  },
  {
    lat: 33.4986286,
    lng: -111.9224398,
    squareFootage: "30202",
    propertyType: "Multifamily",
    totalCosts: "1257300",
    valueOfReclassifiedAssets: 332347,
    depreciableAssets: 32,
  },
  {
    lat: 33.4986286,
    lng: -111.9224398,
    squareFootage: "30202",
    propertyType: "Multifamily",
    totalCosts: "6600000",
    valueOfReclassifiedAssets: 1755253,
    depreciableAssets: 32,
  },
  {
    lat: 33.5651587,
    lng: -112.1370601,
    squareFootage: "118312",
    propertyType: "Multifamily",
    totalCosts: "17575000",
    valueOfReclassifiedAssets: 4084353,
    depreciableAssets: 27,
  },
  {
    lat: 33.379424,
    lng: -111.8747445,
    squareFootage: "66966",
    propertyType: "Multifamily",
    totalCosts: "13000000",
    valueOfReclassifiedAssets: 3196135,
    depreciableAssets: 29,
  },
  {
    lat: 34.8742328,
    lng: -82.33433749999999,
    squareFootage: "119734",
    propertyType: "Industrial",
    totalCosts: "5225000",
    valueOfReclassifiedAssets: 1105795,
    depreciableAssets: 25,
  },
  {
    lat: 28.7571267,
    lng: -81.3509416,
    squareFootage: "3069",
    propertyType: "Retail",
    totalCosts: "2290000",
    valueOfReclassifiedAssets: 457154,
    depreciableAssets: 23,
  },
  {
    lat: 36.0323222,
    lng: -78.8269385,
    squareFootage: "67413",
    propertyType: "Office",
    totalCosts: "17050000",
    valueOfReclassifiedAssets: 3226414,
    depreciableAssets: 23,
  },
  {
    lat: 39.20502279999999,
    lng: -94.70706159999999,
    squareFootage: "30364",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 470414,
    depreciableAssets: 28,
  },
  {
    lat: 41.4789363,
    lng: -81.7404134,
    squareFootage: "33003",
    propertyType: "Multifamily",
    totalCosts: "2000000",
    valueOfReclassifiedAssets: 391590,
    depreciableAssets: 23,
  },
  {
    lat: 36.1523805,
    lng: -86.7893889,
    squareFootage: "123611",
    propertyType: "Multifamily",
    totalCosts: "33650000",
    valueOfReclassifiedAssets: 7664251,
    depreciableAssets: 27,
  },
  {
    lat: 36.1635337,
    lng: -86.7816953,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "25711500",
    valueOfReclassifiedAssets: 5718326,
    depreciableAssets: 26,
  },
  {
    lat: 39.4827862,
    lng: -77.3439283,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2951791",
    valueOfReclassifiedAssets: 2497157,
    depreciableAssets: 37,
  },
  {
    lat: 39.0451663,
    lng: -77.03917539999999,
    squareFootage: "8792",
    propertyType: "Office",
    totalCosts: "2634652",
    valueOfReclassifiedAssets: 274351,
    depreciableAssets: 15,
  },
  {
    lat: 41.9485328,
    lng: -88.09496089999999,
    squareFootage: "10000",
    propertyType: "Specialty",
    totalCosts: "1852083",
    valueOfReclassifiedAssets: 246569,
    depreciableAssets: 17,
  },
  {
    lat: 40.1539406,
    lng: -88.3433623,
    squareFootage: "8015",
    propertyType: "Specialty",
    totalCosts: "1307829",
    valueOfReclassifiedAssets: 229660,
    depreciableAssets: 25,
  },
  {
    lat: 40.1539406,
    lng: -88.3433623,
    squareFootage: "8015",
    propertyType: "Specialty",
    totalCosts: "20035",
    valueOfReclassifiedAssets: 3518,
    depreciableAssets: 25,
  },
  {
    lat: 34.2041173,
    lng: -84.17516479999999,
    squareFootage: "9512",
    propertyType: "Specialty",
    totalCosts: "2714206",
    valueOfReclassifiedAssets: 338780,
    depreciableAssets: 22,
  },
  {
    lat: 35.1274285,
    lng: -80.8599193,
    squareFootage: "374011",
    propertyType: "Multifamily",
    totalCosts: "43120000",
    valueOfReclassifiedAssets: 12773273,
    depreciableAssets: 35,
  },
  {
    lat: 35.890231,
    lng: -78.9175088,
    squareFootage: "249994",
    propertyType: "Multifamily",
    totalCosts: "33750000",
    valueOfReclassifiedAssets: 10119305,
    depreciableAssets: 35,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "241128",
    propertyType: "Industrial",
    totalCosts: "548522",
    valueOfReclassifiedAssets: 511979,
    depreciableAssets: 29,
  },
  {
    lat: 35.1009909,
    lng: -106.5701927,
    squareFootage: "117072",
    propertyType: "Multifamily",
    totalCosts: "14740605",
    valueOfReclassifiedAssets: 2976273,
    depreciableAssets: 24,
  },
  {
    lat: 37.6536947,
    lng: -121.6739371,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "33980072",
    valueOfReclassifiedAssets: 29129616,
    depreciableAssets: 25,
  },
  {
    lat: 40.5860069,
    lng: -73.9418603,
    squareFootage: "4000",
    propertyType: "Retail",
    totalCosts: "1100000",
    valueOfReclassifiedAssets: 65466,
    depreciableAssets: 7,
  },
  {
    lat: 40.74727,
    lng: -73.9800645,
    squareFootage: "7150",
    propertyType: "Multifamily",
    totalCosts: "6050000",
    valueOfReclassifiedAssets: 843643,
    depreciableAssets: 16,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "5351",
    propertyType: "Multifamily",
    totalCosts: "858046",
    valueOfReclassifiedAssets: 816144,
    depreciableAssets: 6,
  },
  {
    lat: 40.6009233,
    lng: -73.977126,
    squareFootage: "5351",
    propertyType: "Multifamily",
    totalCosts: "858046",
    valueOfReclassifiedAssets: 791579,
    depreciableAssets: 8,
  },
  {
    lat: 34.583875,
    lng: -82.79941939999999,
    squareFootage: "23200",
    propertyType: "Multifamily",
    totalCosts: "1725000",
    valueOfReclassifiedAssets: 462679,
    depreciableAssets: 32,
  },
  {
    lat: "",
    lng: "",
    squareFootage: "9864",
    propertyType: "Specialty",
    totalCosts: "3001839",
    valueOfReclassifiedAssets: 723676,
    depreciableAssets: 32,
  },
  {
    lat: 39.9721149,
    lng: -76.6528225,
    squareFootage: "134606",
    propertyType: "Hotel",
    totalCosts: "4900000",
    valueOfReclassifiedAssets: 1305999,
    depreciableAssets: 31,
  },
  {
    lat: 42.2751923,
    lng: -89.6628111,
    squareFootage: "52235",
    propertyType: "Nursing Homes/Senior Living",
    totalCosts: "6028725",
    valueOfReclassifiedAssets: 1112034,
    depreciableAssets: 19,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "1500",
    propertyType: "Retail",
    totalCosts: "550000",
    valueOfReclassifiedAssets: 123028,
    depreciableAssets: 26,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "20000",
    propertyType: "Specialty",
    totalCosts: "1600000",
    valueOfReclassifiedAssets: 179204,
    depreciableAssets: 14,
  },
  {
    lat: 25.9823108,
    lng: -80.1484407,
    squareFootage: "9615",
    propertyType: "Specialty",
    totalCosts: "1200000",
    valueOfReclassifiedAssets: 143870,
    depreciableAssets: 15,
  },
  {
    lat: 26.0181766,
    lng: -80.1596041,
    squareFootage: "14919",
    propertyType: "Industrial",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 224620,
    depreciableAssets: 11,
  },
  {
    lat: 35.4355991,
    lng: -97.51930929999999,
    squareFootage: "137579",
    propertyType: "Retail",
    totalCosts: "2642189",
    valueOfReclassifiedAssets: 650402,
    depreciableAssets: 26,
  },
  {
    lat: 32.6614911,
    lng: -97.1467072,
    squareFootage: "176766",
    propertyType: "Retail",
    totalCosts: "19276147",
    valueOfReclassifiedAssets: 5035161,
    depreciableAssets: 33,
  },
  {
    lat: 32.6614911,
    lng: -97.1467072,
    squareFootage: "9500",
    propertyType: "Retail",
    totalCosts: "1035965",
    valueOfReclassifiedAssets: 391334,
    depreciableAssets: 48,
  },
  {
    lat: 32.6614911,
    lng: -97.1467072,
    squareFootage: "184427",
    propertyType: "Retail",
    totalCosts: "36200828",
    valueOfReclassifiedAssets: 6647204,
    depreciableAssets: 33,
  },
  {
    lat: 32.6614911,
    lng: -97.1467072,
    squareFootage: "49857",
    propertyType: "Retail",
    totalCosts: "5436854",
    valueOfReclassifiedAssets: 1597857,
    depreciableAssets: 37,
  },
  {
    lat: 29.6610688,
    lng: -98.5830907,
    squareFootage: "4340",
    propertyType: "Specialty",
    totalCosts: "3833911",
    valueOfReclassifiedAssets: 841926,
    depreciableAssets: 28,
  },
  {
    lat: 29.6610688,
    lng: -98.5830907,
    squareFootage: "93188",
    propertyType: "Retail",
    totalCosts: "7312925",
    valueOfReclassifiedAssets: 1733113,
    depreciableAssets: 29,
  },
  {
    lat: 29.5857075,
    lng: -98.4129396,
    squareFootage: "3400",
    propertyType: "Retail",
    totalCosts: "7260865",
    valueOfReclassifiedAssets: 2844488,
    depreciableAssets: 48,
  },
  {
    lat: 40.6903213,
    lng: -73.9271644,
    squareFootage: "26052",
    propertyType: "Multifamily",
    totalCosts: "4888630",
    valueOfReclassifiedAssets: 3341859,
    depreciableAssets: 33,
  },
  {
    lat: 33.9734553,
    lng: -117.3263893,
    squareFootage: "301394",
    propertyType: "Specialty",
    totalCosts: "63100000",
    valueOfReclassifiedAssets: 16547744,
    depreciableAssets: 31,
  },
  {
    lat: 34.0127473,
    lng: -117.1617685,
    squareFootage: "449552",
    propertyType: "Multifamily",
    totalCosts: "130600000",
    valueOfReclassifiedAssets: 42750122,
    depreciableAssets: 38,
  },
  {
    lat: 34.2570374,
    lng: -118.4279933,
    squareFootage: "4285",
    propertyType: "Specialty",
    totalCosts: "1044581",
    valueOfReclassifiedAssets: 900881,
    depreciableAssets: 30,
  },
  {
    lat: 33.4154744,
    lng: -117.5848025,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1025820",
    valueOfReclassifiedAssets: 762227,
    depreciableAssets: 0,
  },
  {
    lat: 34.6946278,
    lng: -118.1473771,
    squareFootage: "85265",
    propertyType: "Multifamily",
    totalCosts: "8650000",
    valueOfReclassifiedAssets: 1855235,
    depreciableAssets: 26,
  },
  {
    lat: 33.7806646,
    lng: -118.1503026,
    squareFootage: "3798",
    propertyType: "Multifamily",
    totalCosts: "1500180",
    valueOfReclassifiedAssets: 373618,
    depreciableAssets: 33,
  },
  {
    lat: 33.8640647,
    lng: -118.1766294,
    squareFootage: "9280",
    propertyType: "Multifamily",
    totalCosts: "2500000",
    valueOfReclassifiedAssets: 625145,
    depreciableAssets: 30,
  },
  {
    lat: 34.0127473,
    lng: -117.1617685,
    squareFootage: "146220",
    propertyType: "Multifamily",
    totalCosts: "36750000",
    valueOfReclassifiedAssets: 10611579,
    depreciableAssets: 36,
  },
  {
    lat: 33.7844997,
    lng: -118.1971031,
    squareFootage: "4320",
    propertyType: "Multifamily",
    totalCosts: "1475000",
    valueOfReclassifiedAssets: 493595,
    depreciableAssets: 40,
  },
  {
    lat: 34.6946278,
    lng: -118.1473771,
    squareFootage: "85265",
    propertyType: "Multifamily",
    totalCosts: "8650000",
    valueOfReclassifiedAssets: 1971187,
    depreciableAssets: 26,
  },
  {
    lat: 34.2037124,
    lng: -118.4571974,
    squareFootage: "16359",
    propertyType: "Multifamily",
    totalCosts: "4200000",
    valueOfReclassifiedAssets: 1417144,
    depreciableAssets: 40,
  },
  {
    lat: 34.0578814,
    lng: -118.3096648,
    squareFootage: "30020",
    propertyType: "Multifamily",
    totalCosts: "6215000",
    valueOfReclassifiedAssets: 802406,
    depreciableAssets: 20,
  },
  {
    lat: 34.0895186,
    lng: -118.3315838,
    squareFootage: "6333",
    propertyType: "Multifamily",
    totalCosts: "2300000",
    valueOfReclassifiedAssets: 291099,
    depreciableAssets: 19,
  },
  {
    lat: 40.7177351,
    lng: -111.8985922,
    squareFootage: "60318",
    propertyType: "Retail",
    totalCosts: "5124359",
    valueOfReclassifiedAssets: 991446,
    depreciableAssets: 24,
  },
  {
    lat: 40.7177351,
    lng: -111.8985922,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "410880",
    valueOfReclassifiedAssets: 182366,
    depreciableAssets: 0,
  },
  {
    lat: 40.7060732,
    lng: -111.7376178,
    squareFootage: "45143",
    propertyType: "Retail",
    totalCosts: "2074948",
    valueOfReclassifiedAssets: 409121,
    depreciableAssets: 25,
  },
  {
    lat: 40.7060732,
    lng: -111.7376178,
    squareFootage: "45143",
    propertyType: "Retail",
    totalCosts: "948522",
    valueOfReclassifiedAssets: 187021,
    depreciableAssets: 25,
  },
  {
    lat: 40.7060732,
    lng: -111.7376178,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2790009",
    valueOfReclassifiedAssets: 2050525,
    depreciableAssets: 73,
  },
  {
    lat: 40.70786390000001,
    lng: -111.8508966,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "203127",
    valueOfReclassifiedAssets: 163399,
    depreciableAssets: 76,
  },
  {
    lat: 40.70786390000001,
    lng: -111.8508966,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1614470",
    valueOfReclassifiedAssets: 1377064,
    depreciableAssets: 85,
  },
  {
    lat: 40.8621792,
    lng: -73.88601,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "4350000",
    valueOfReclassifiedAssets: 965609,
    depreciableAssets: 28,
  },
  {
    lat: 38.2586184,
    lng: -85.5457689,
    squareFootage: "90300",
    propertyType: "Multifamily",
    totalCosts: "8447389",
    valueOfReclassifiedAssets: 2048761,
    depreciableAssets: 27,
  },
  {
    lat: 33.9516114,
    lng: -118.3875775,
    squareFootage: "10000",
    propertyType: "Multifamily",
    totalCosts: "4600000",
    valueOfReclassifiedAssets: 623128,
    depreciableAssets: 18,
  },
  {
    lat: 34.0895186,
    lng: -118.3315838,
    squareFootage: "7233",
    propertyType: "Specialty",
    totalCosts: "3430000",
    valueOfReclassifiedAssets: 407305,
    depreciableAssets: 16,
  },
  {
    lat: 33.9734553,
    lng: -117.3263893,
    squareFootage: "213292",
    propertyType: "Multifamily",
    totalCosts: "53500000",
    valueOfReclassifiedAssets: 14961373,
    depreciableAssets: 35,
  },
  {
    lat: 40.7060732,
    lng: -111.7376178,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "2790009",
    valueOfReclassifiedAssets: 2051756,
    depreciableAssets: 70,
  },
  {
    lat: 40.70786390000001,
    lng: -111.8508966,
    squareFootage: "0",
    propertyType: "Specialty",
    totalCosts: "1614470",
    valueOfReclassifiedAssets: 1377089,
    depreciableAssets: 85,
  },
  {
    lat: 34.1423899,
    lng: -118.4571974,
    squareFootage: "11400",
    propertyType: "Multifamily",
    totalCosts: "3143802",
    valueOfReclassifiedAssets: 683404,
    depreciableAssets: 28,
  },
  {
    lat: 34.0470832,
    lng: -118.2965121,
    squareFootage: "13974",
    propertyType: "Multifamily",
    totalCosts: "2862236",
    valueOfReclassifiedAssets: 598671,
    depreciableAssets: 26,
  },
  {
    lat: 34.0309972,
    lng: -118.319894,
    squareFootage: "5088",
    propertyType: "Multifamily",
    totalCosts: "1700000",
    valueOfReclassifiedAssets: 301948,
    depreciableAssets: 22,
  },
];
