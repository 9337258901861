/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { RightArrow } from '../ui/icons';

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin: 24px auto 0;
  max-width: 640px;
  position: relative;
  padding: 32px 24px;
  z-index: 5000;
  width: 100%;
  h3 {
    font-family: Syne;
    font-size: 24px;
    margin-top: 0;
    max-width: 500px;
  }
  p {
    line-height: 1.71;
    margin-top: 24px;
    max-width: 500px;
  }
  @media (min-width: 768px) {
    margin: 40px auto 0;
    padding: 56px 64px 70px;
    h3 {
      font-size: 32px;
      max-width: 330px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (min-width: 1024px) {
    padding: 56px 118px 69px;
    margin-bottom: 148px;
  }
  @media (min-width: 1440px) {
    max-width: 754px;
  }
`;

const Button = styled(NavLink)`
  align-items: center;
  background-color: #0827c7;
  border-radius: 32px;
  box-shadow: 0 21px 40px -15px rgba(19, 21, 50, 0.39);
  color: #ffffff;
  display: inline-flex;
  font-weight: bold;
  height: 64px;
  margin-top: 24px;
  padding: 0 40px;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;
const Arrow = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    padding-left: 25px;
  }
`;

export default function QuoteCard({ styles }) {
  return (
    <Card>
      <h3>Ready to improve your cash flow?</h3>
      <p>Find out how much with a no cost feasibility analysis today.</p>
      <Button to='/contact-us'>
        Get your free quote
        <Arrow>
          <RightArrow />
        </Arrow>
      </Button>
    </Card>
  );
}
