/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { fadeIn, fadeOut } from './fade';
import { BackgroundImage, PlusIconEightStyles, bubble, PageWrap } from './allStepsStyles';
import { NumeroFive } from './svg/NumberCircle';
import { PlusIcon8 } from './svg/plusIcons';
import { SiteVisit } from './svg/largeIllustrations';
import { StandardImage } from '../ui/Image.jsx';
import Step from './Step';

export default function StepOne({ refFive }) {
  const sectionRefFive = useRef(null);

  const intersection = useIntersection(sectionRefFive, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });
  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInFive') : fadeOut('.fadeInFive');
  return (
    <PageWrap ref={sectionRefFive}>
      <BackgroundImage>
        <StandardImage className='fadeInFive' image='specs/background5_ynhobq.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroFive />}
        blue
        currentRef={refFive}
        illustraion={<SiteVisit />}
        header='Site Visit'
        subText='One of our qualified engineers will come to the property to get exact measurements, pictures and a real feel for the details and story of the building. This is where our engineers’ vast experience on all property types can really make a difference. Small nuances in the construction and layout can translate into big differences in the amounts of depreciation that can be accelerated.'
      />
      <PlusIconEightStyles>
        <PlusIcon8 />
      </PlusIconEightStyles>
    </PageWrap>
  );
}
