import React from "react";
import { Root, NPVIcon, TaxIcon, EngagementIcon, Motif } from "./footerStyles";

export default function Footer() {
  return (
    <Root>
      <div className="frameWrapper">
        <div className="bgcDesign">
          <Motif className="layer1Icon" />
        </div>
      </div>
      <div className="content_wrap">
        <div className="footer_header">Here's what's included in your preliminary analysis for cost segregation:</div>
        <div className="inner_wrap">
          <div className="frameDiv">
            <div className="circle">
              <NPVIcon />
            </div>
            <div className="npvNetPresentContainer">
              <p className="npv">NPV</p>
              <p className="netPresentValueAnalysisSh">
                (net present value) analysis showing the powerful time-value of your money
              </p>
            </div>
          </div>
          <div className="frameDiv">
            <div className="circle">
              <TaxIcon />
            </div>
            <div className="npvNetPresentContainer">
              <p className="npv">TAX SAVINGS</p>
              <p className="netPresentValueAnalysisSh">estimated tax savings overview</p>
            </div>
          </div>
          <div className="frameDiv">
            <div className="circle">
              <EngagementIcon />
            </div>
            <div className="engagementLetterShowingContainer">
              <p className="npv">ENGAGEMENT LETTER</p>
              <p className="netPresentValueAnalysisSh">
                showing your study costs and authorizes us to perform your study when returned to us
              </p>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
