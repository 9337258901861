/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  mainWrap,
  quoteIcon,
  innerContent,
  exeprtStyle,
  testimonialTextWrap,
  profileImageStyle,
  arrowStyle,
} from './testimonialStyles';
import { LeftArrow, RightBlueArrow } from '../ui/icons';
import Image from '../ui/Image';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 64,
        height: 64,
        backgroundColor: '#f2f2f2f2',
        right: 300,
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <RightBlueArrow />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 64,
        height: 64,
        backgroundColor: '#f2f2f2f2',
        left: 300,
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  );
}

function PersonSlide({ info }) {
  const { title, name, image, position, company, testimonial, styles } = info;
  return (
    <div css={[mainWrap, styles]}>
      <div css={innerContent}>
        <div css={testimonialTextWrap}>
          <span css={quoteIcon}>„</span>
          <p css={exeprtStyle}>{title}</p>
          <div>{testimonial}</div>
        </div>
        <div className='profile-section'>
          <Image image={image} styles={profileImageStyle} />
          <div>
            <p css={{ fontWeight: 'bold' }}>{name}</p>
            <span css={{ fontSize: 12, maxWidth: 180, margin: '8px auto 0' }}>
              {company}
              <br />
              {position}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

class SimpleSlider extends Component {
  render() {
    var settings = {
      infinite: true,
      pauseOnHover: false,
      dots: true,
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 1000,
      nextArrow: <NextArrow css={arrowStyle} />,
      prevArrow: <PrevArrow css={arrowStyle} />,
    };
    const { list } = this.props;
    return (
      <Slider {...settings}>
        {list.map((i) => (
          <PersonSlide info={i} key={i.name} />
        ))}
      </Slider>
    );
  }
}

export default function Testimonial({ testimonials }) {
  return (
    <div css={{ paddingBottom: 32, marginBottom: 32 }}>
      <SimpleSlider list={testimonials} />
    </div>
  );
}
