import React, { useState, useEffect } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';


import {
  DeleteIcon,
  DropzoneWrap,
  IndividualStepWrap,
  StepHeader,
  SubmitButton,
  PreviousNextWrap,
  RightArrow,
  SecondaryButtonStyle,
  UploadedFilesWrap,
  AddPropButtonWrap,
} from './styles';
import DropzoneFile from './Dropzone';
import { propertyTypesList } from './mockData';

const PropertyTypes = {
  'Apartment Building': 809760031,
  'Cabin': 809760036,
  'Hotel/Motel': 809760007,
  'Mobile Home Park': 809760017,
  'Multi-Family': 809760000,
  'Nursing Home': 809760028,
  'Office Building': 809760011,
  'Self Storage': 809760020,
  'Single Family': 809760025
}

const multiUnitTypes = [
  PropertyTypes['Apartment Building'],
  PropertyTypes['Multi-Family'],
  PropertyTypes['Office Building']
]

const npvTypes = [
  {
    value: 809760000,
    label: 'Purchase',
  },
  {
    value: 809760001,
    label: 'Renovation',
  },
  {
    value: 809760002,
    label: 'New Construction',
  },
  {
    value: -1,
    label: 'Purchase and Renovation',
  },
];

const muiOverride = {
  ".MuiFormLabel-root[data-shrink='false']": { marginTop: '-5px' },
};

const integerInputProps = {
  inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' }
}

export default function PropertyDetails({ request, setRequest, nextStep, prevStep }) {
  const propertyIndex = request.properties.length - 1;
  const property = request.properties[propertyIndex];

  const propertyType = property.propertyType;
  const npvType = property.npvType;
  const isMobileHomePark = propertyType === PropertyTypes['Mobile Home Park'];

  const [files, setFiles] = useState(property.files ?? []);
  const [constructionFiles, setConstructionFiles] = useState(
    property.constructionBudgetDoc ? [property.constructionBudgetDoc] : [],
  );
  const disableSubmit = property.npvType == null || (property.npvType !== 809760000 && property.constructionBudgetDoc == null)

  useEffect(() => {
    setRequest((draft) => {
      draft.properties[propertyIndex].files = files;
    });
  }, [files]);

  const onClickPrevious = () => {
    setRequest((draft) => {
      draft.properties = draft.properties.slice(0, -1)
    });
    prevStep();
  }

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (constructionFiles === undefined) return;
    setRequest((draft) => {
      draft.properties[propertyIndex].constructionBudgetDoc =
        constructionFiles.length >= 1 ? constructionFiles[0] : null;
    });
  }, [constructionFiles]);

  const removeConstructionFile = (file) => {
    const newConstructionFiles = [...constructionFiles];
    newConstructionFiles.splice(newConstructionFiles.indexOf(file), 1);
    setConstructionFiles(newConstructionFiles);
  };

  const handleChange = (name, value) => {
    if (value === 'true' || value === 'false') {
      value = value === 'true';
    }
    const newProperties = request.properties.map((p, i) => {
      if (i < propertyIndex) {
        return p;
      }
      if (value === '') {
        value = null;
      }
      return {
        ...p,
        [name]: value,
      };
    });
    setRequest((draft) => {
      draft.properties = newProperties;
    });
  };

  const handleFieldChange = (event) => {
    let { name, value } = event.target;
    handleChange(name, value);
  };

  const handleIntegerChange = (event) => {
    let { name, value } = event.target;
    value = value.replace(/[^0-9]/g, '')
    handleChange(name, value === '' ? null : parseInt(value, 10));
  };

  const handleConstructionBudgetChange = (values) => {
    const { floatValue } = values;
    handleChange('constructionBudget', floatValue);
  };

  const onDateUpdate = (field, newDate) => {
    if (new Date(newDate) instanceof Date && !isNaN(newDate)) {
      const newProperties = request.properties.map((p, i) => {
        if (i < propertyIndex) {
          return p;
        }
        return {
          ...p,
          [field]: new Date(newDate),
        };
      });
      setRequest((draft) => {
        draft.properties = newProperties;
      });
    }
  };

  return (
    <IndividualStepWrap>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StepHeader>{`Property Details - ${property.addressLine1}`}</StepHeader>
        <div className='section_header'>Property Type</div>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Type</InputLabel>
              <Select
                className='select_'
                name='propertyType'
                value={propertyType ?? ''}
                label='Select Type'
                onChange={handleFieldChange}
                size='small'
              >
                {propertyTypesList.map((property) => (
                  <MenuItem key={property.label} value={property.value}>
                    {property.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name='buildingOrClubhouseSquareFootage'
              fullWidth
              size='small'
              label={isMobileHomePark ? 'Clubhouse Square Footage' : 'Building Square Footage'}
              value={property.buildingOrClubhouseSquareFootage ?? ''}
              InputProps={integerInputProps}
              onChange={handleIntegerChange}
            />
          </Grid>
          {/* ------------ CABIN ----------- */}
          {propertyType === PropertyTypes['Cabin'] && (
            <>
              <Grid item xs={12}>
                <FormControl size='small'>
                  <FormLabel className='custom_labels'>
                    Is this property part of a HOA or PUD?
                  </FormLabel>
                  <RadioGroup
                    name='hoa'
                    row
                    value={property.hoa ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel className='custom_labels'>
                    Was this property purchased fully furnished?
                  </FormLabel>
                  <RadioGroup
                    name='furnished'
                    size='small'
                    row
                    value={property.furnished ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          {/* ------------ SINGLE FAMILY HOME ----------- */}
          {propertyType === PropertyTypes['Single Family'] && (
            <>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel className='custom_labels'>
                    Is this property part of a HOA or PUD?
                  </FormLabel>
                  <RadioGroup
                    name='hoa'
                    size='small'
                    row
                    value={property.hoa ?? ''}
                    tabIndex={1}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel className='custom_labels'>
                    Was this property purchased fully furnished?
                  </FormLabel>
                  <RadioGroup
                    name='furnished'
                    size='small'
                    row
                    value={property.furnished ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel className='custom_labels'>
                    Is this property a short term rental?
                  </FormLabel>
                  <RadioGroup
                    name='shortTermRental'
                    size='small'
                    row
                    value={property.shortTermRental ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {property.shortTermRental && (
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel className='custom_labels' style={{ marginBottom: '8px' }}>
                      Please include a link to Airbnb listing:
                    </FormLabel>
                    <textarea
                      className='comments-field'
                      name='airBnbLink'
                      rows='5'
                      value={property.airBnbLink ?? ''}
                      onChange={handleFieldChange}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          {/* ------------ APARTMENT BUILDING ----------- */}
          {/* ------------ MULTI FAMILY ----------- */}
          {/* ------------ OFFICE BUILDING ----------- */}

          {multiUnitTypes.includes(propertyType) && (
            <>
              <Grid item xs={6}>
                <TextField
                  name='unitsBedsOrRooms'
                  fullWidth
                  size='small'
                  label='Number of Units'
                  value={property.unitsBedsOrRooms ?? ''}
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
            </>
          )}

          {/* ------------ HOTEL/MOTEL ----------- */}
          {propertyType === PropertyTypes['Hotel/Motel'] && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Number of Rooms'
                  name='unitsBedsOrRooms'
                  value={property.unitsBedsOrRooms ?? ''}
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
            </>
          )}

          {/* ------------ NURSING HOME ----------- */}
          {propertyType === PropertyTypes['Nursing Home'] && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Number of Beds'
                  name='unitsBedsOrRooms'
                  value={property.unitsBedsOrRooms ?? ''}
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
            </>
          )}

          {/* ------------ MOBILE HOME PARK ----------- */}
          {propertyType === PropertyTypes['Mobile Home Park'] && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Number of POH Homes'
                  value={property.pohHomes ?? ''}
                  name='pohHomes'
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Number of TOH Homes'
                  value={property.tohHomes ?? ''}
                  name='tohHomes'
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Number of Pads'
                  value={property.pads ?? ''}
                  name='pads'
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size='small'
                  label='Land Square Footage'
                  value={property.landSquareFootage ?? ''}
                  name='landSquareFootage'
                  InputProps={integerInputProps}
                  onChange={handleIntegerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel className='custom_labels'>
                    Select your water and sewage type:
                  </FormLabel>
                  <RadioGroup
                    name='parkOwnedSewage'
                    size='small'
                    row
                    value={property.parkOwnedSewage ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size='small' />}
                      label='Park-Owned'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size='small' />}
                      label='City-Owned'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* ----------------- PURCHASE, RENOVATION, NEW CONSTRUCTION ----------------- */}
        <div className='section_header mt5'>Purchase and Reno</div>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid
            item
            xs={12}
            sm={npvType === 1 || npvType === 2 ? 6 : 12}
          >
            <FormControl fullWidth size='small'>
              <InputLabel>Please Select One *</InputLabel>
              <Select
                className='select_'
                label='Please Select One *'
                size='small'
                value={property.npvType ?? ''}
                name='npvType'
                onChange={handleFieldChange}
              >
                {npvTypes.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Purchase
          No condition or more info needed
        */}

          {/* Renovation  OR  New CONSTRUCTION */}
          {(npvType === 809760001 || npvType === 809760002) && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size='small'>
                  <NumericFormat
                    fullWidth
                    size='small'
                    label='Construction Budget'
                    placeholder='Construction Budget'
                    value={property.constructionBudget ?? ''}
                    name='constructionBudget'
                    onValueChange={handleConstructionBudgetChange}
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix='$'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div className='dropzone-title'>
                  Please upload a detailed construction budget or AIA doc. *
                </div>
                {constructionFiles.length < 1 && (
                  <DropzoneFile
                    files={constructionFiles}
                    maxFiles={1}
                    setFiles={setConstructionFiles}
                  />
                )}
                {constructionFiles.length >= 1 && (
                  <UploadedFilesWrap>
                    {constructionFiles.map((file, index) => (
                      <div key={index} className='row'>
                        <DeleteIcon onClick={() => removeConstructionFile(file)} />
                        <div>{file.fileName}</div>
                      </div>
                    ))}
                  </UploadedFilesWrap>
                )}
              </Grid>
            </>
          )}

          {npvType === -1 && (
            <>
              <Grid item xs={12} sm={6}>
                <NumericFormat
                  fullWidth
                  size='small'
                  label='Construction Budget'
                  placeholder='Construction Budget'
                  value={property.constructionBudget ?? ''}
                  name='constructionBudget'
                  onValueChange={handleConstructionBudgetChange}
                  customInput={TextField}
                  thousandSeparator={true}
                  prefix='$'
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={muiOverride}>
                <DesktopDatePicker
                  label='Purchase Date'
                  size='small'
                  value={property.purchaseDate ? dayjs(property.purchaseDate) : ''}
                  onChange={(date) => onDateUpdate('purchaseDate', date)}
                  sx={
                    !property.purchaseDate && {
                      '& .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e1e3e6',
                      },
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={muiOverride}>
                <DesktopDatePicker
                  label='Renovation Start Date'
                  size='small'
                  value={property.renoStartDate ? dayjs(property.renoStartDate) : ''}
                  onChange={(date) => onDateUpdate('renoStartDate', date)}
                  sx={
                    !property.renoStartDate && {
                      '& .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e1e3e6',
                      },
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={muiOverride}>
                <DesktopDatePicker
                  label='Est. Renovation Complete Date'
                  size='small'
                  value={property.renoEndDate ? dayjs(property.renoEndDate) : ''}
                  onChange={(date) => onDateUpdate('renoEndDate', date)}
                  sx={
                    !property.renoEndDate && {
                      '& .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e1e3e6',
                      },
                    }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ marginTop: '16px' }}>
                  <FormLabel className='custom_labels'>
                    Was the property placed into service prior to beginning of renovation?
                  </FormLabel>
                  <RadioGroup
                    name='placedInServicePriorToReno'
                    size='small'
                    row
                    value={property.placedInServicePriorToReno ?? ''}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel value={true} control={<Radio size='small' />} label='Yes' />
                    <FormControlLabel value={false} control={<Radio size='small' />} label='No' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div className='dropzone-title'>
                  For renovations or new construction - please upload detailed construction budget
                  or AIA doc.
                </div>
                {constructionFiles.length < 1 && (
                  <DropzoneFile
                    files={constructionFiles}
                    maxFiles={1}
                    setFiles={setConstructionFiles}
                  />
                )}
                {constructionFiles.length >= 1 && (
                  <UploadedFilesWrap>
                    {constructionFiles.map((file, index) => (
                      <div key={index} className='row'>
                        <DeleteIcon onClick={() => removeConstructionFile(file)} />
                        <div>{file.fileName}</div>
                      </div>
                    ))}
                  </UploadedFilesWrap>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item sm={12}>
          <div className='dropzone-title mb1 mt3'>
            If you have an appraisal, survey or OM, please attach.
          </div>
          <DropzoneWrap>
            {files.length < 5 && <DropzoneFile files={files} maxFiles={5} setFiles={setFiles} />}{' '}
          </DropzoneWrap>
          {files.length >= 1 && (
            <UploadedFilesWrap>
              {files.map((file, index) => (
                <div className='row' key={index}>
                  <DeleteIcon onClick={() => removeFile(file)} />
                  <div>{file.fileName}</div>
                </div>
              ))}
            </UploadedFilesWrap>
          )}
        </Grid>
        <AddPropButtonWrap>
          <SecondaryButtonStyle disabled={disableSubmit} onClick={prevStep}>
            + Add Property
          </SecondaryButtonStyle>
        </AddPropButtonWrap>
        <PreviousNextWrap>
          <div className='prev-direction' onClick={onClickPrevious}>
            <RightArrow />
            <span>Previous</span>
          </div>
          <div className='df aic gap2'>
            <SubmitButton disabled={disableSubmit} text='Continue' onClick={nextStep} />
          </div>
        </PreviousNextWrap>
      </LocalizationProvider>
    </IndividualStepWrap>
  );
}
