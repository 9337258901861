import styled from '@emotion/styled';

export const Root = styled.div`
  background-color: #f7f7f7;
  height: 100%;
  padding: 64px 0 67px;
  position: relative;
  width: 100%;
  @media (min-width: 1024px) {
    background-color: #fff;
    padding-bottom: 0;
  }
  @media (min-width: 1440px) {
    margin-top: 90px;
  }
`;

export const Header = styled.h1`
  @media (max-width: 1439px) {
    display: none;
  }
  @media (min-width: 1440px) {
    background-color: #f7f7f7;
    border-right: 1px solid #e7ebef;
    font-size: 64px;
    padding-bottom: 20px;
    padding-top: 172px;
    text-align: center;
    max-width: calc(100% - 286px);
  }
  @media (min-width: 1920px) {
    max-width: calc(100% - 309px);
  }
`;
export const Circle = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }
  @media (min-width: 1440px) {
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 39px 0 rgba(48, 52, 72, 0.06);
    cursor: pointer;
    display: flex;
    height: 121px;
    justify-content: center;
    position: absolute;
    left: calc(50% - 156px);
    transform: translateX(-50%);
    top: 0px;
    width: 121px;
    z-index: 500;
    svg {
      height: 24px;
      transform: rotate(90deg);
      width: 24px;
    }
  }
`;
export const bubble = {
  '@media(max-width: 1439px)': {
    display: 'none',
  },
  '@media(min-width: 1440px)': {
    border: '1px solid rgba(216, 216, 216, 0.8)',
    borderRadius: '50%',
    height: 844,
    position: 'absolute',
    right: 46,
    width: 844,
    zIndex: 10,
  },
  '@media(min-width: 1920px)': {
    right: 100,
  },
};

export const BackgroundImage = styled.div({
  '@media(max-width: 1023px)': {
    display: 'none',
  },
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  left: '50%',
  maxWidth: 2050,
  position: 'absolute',
  transform: 'translateX(-50%)',
  width: '100%',
  img: {
    '@media(min-width: 768px)': {
      opacity: 0.5,
      position: 'absolute',
      transform: 'scale(0.80)',
      width: '100%',
      willChange: 'auto',
      zIndex: 3,
    },
    '@media(min-width: 1024px)': {
      maxWidth: 2050,
    },
  },
});

export const Newman = styled.div`
  background-color: #f7f7f7;
  position: relative;
  will-change: auto, scroll-position;
  scroll-snap-type: y mandatory;
  @media (min-width: 1024px) {
    width: 100%;
    background-color: #f7f7f7;
    max-width: calc(100% - 230px);
  }
  @media (min-width: 1440px) {
    max-width: calc(100% - 286px);
  }
  @media (min-width: 1920px) {
    max-width: calc(100% - 309px);
  }
`;

export const PageWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  position: relative;
  scroll-snap-align: center;
`;

const PlusIconBase = styled.div`
  display: none;
  position: absolute;
  z-index: 120;
  @media (min-width: 1920px) {
    display: block;
  }
`;

export const PlusIconThreeStyles = styled(PlusIconBase)`
  @media (min-width: 1920px) {
    top: 150px;
    left: 200px;
  }
`;
export const PlusIconFourStyles = styled(PlusIconBase)`
  @media (min-width: 1440px) {
    position: absolute;
    bottom: 80px;
    right: 34px;
    display: block;
  }
  @media (min-width: 1920px) {
    right: 270px;
  }
`;
export const PlusIconFiveStyles = styled(PlusIconBase)`
  @media (min-width: 1920px) {
    bottom: 120px;
    right: 230px;
  }
`;
export const PlusIconSixStyles = styled(PlusIconBase)`
  @media (min-width: 1440px) {
    bottom: 160px;
    right: 68px;
    display: block;
  }
  @media (min-width: 1920px) {
    bottom: 180px;
    right: 298px;
  }
`;
export const PlusIconSevenStyles = styled(PlusIconBase)`
  @media (min-width: 1920px) {
    bottom: 160px;
    right: 264px;
  }
`;
export const PlusIconEightStyles = styled(PlusIconBase)`
  @media (min-width: 1440px) {
    display: block;
    bottom: 0px;
    right: 100px;
  }
  @media (min-width: 1920px) {
    bottom: 80px;
    right: 330px;
  }
`;
export const PlusIconNineStyles = styled(PlusIconBase)`
  @media (min-width: 1920px) {
    bottom: 130px;
    right: 270px;
  }
`;
export const PlusIconTenStyles = styled(PlusIconBase)`
  @media (min-width: 1440px) {
    top: 300px;
    right: 113px;
    display: block;
  }
  @media (min-width: 1920px) {
    right: 343px;
    top: 350px;
  }
`;
export const PlusIconElevenStyles = styled(PlusIconBase)`
  @media (min-width: 1440px) {
    display: block;
    top: 520px;
    left: 24px;
  }
  @media (min-width: 1920px) {
    left: 167px;
    top: 630px;
  }
`;
export const MotifStyles = styled.div`
  @media (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    position: absolute;
    bottom: -145px;
    right: 106px;
    z-index: 1;
  }
`;
