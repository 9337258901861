/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import {
  BackgroundImage,
  PlusIconTenStyles,
  PlusIconElevenStyles,
  bubble,
  MotifStyles,
  PageWrap,
} from './allStepsStyles';
import { PlusIcon10, PlusIcon11, Motif } from './svg/plusIcons';
import Step from './Step';
import { StudyDelivered } from './svg/largeIllustrations';
import QuoteCard from './QuoteCard';
import { fadeIn, fadeOut } from './fade';
import { NumeroSeven } from './svg/NumberCircle';
import { StandardImage } from '../ui/Image.jsx';

export default function StepOne({ refSeven }) {
  const sectionRefSeven = useRef(null);

  const intersection = useIntersection(sectionRefSeven, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });
  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInSeven') : fadeOut('.fadeInSeven');
  return (
    <PageWrap ref={sectionRefSeven}>
      <BackgroundImage>
        <StandardImage className='fadeInSeven' image='specs/background7_w9qgyv.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroSeven />}
        blue
        currentRef={refSeven}
        illustraion={<StudyDelivered />}
        header='Study Delivered'
        subText='Our team of accountants and tax specialists take all the collected information and produce a detailed, comprehensive cost segregation study and its accompanying depreciation schedule, ready to be inserted directly into your tax return.'
      >
        <QuoteCard />
      </Step>
      <PlusIconTenStyles>
        <PlusIcon10 />
      </PlusIconTenStyles>
      <PlusIconElevenStyles>
        <PlusIcon11 />
      </PlusIconElevenStyles>
      <MotifStyles>
        <Motif />
      </MotifStyles>
    </PageWrap>
  );
}
