/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Root, Header, OuterStep, lineStyles } from './stepsStyles';
import { MapLine } from '../ui/icons';

import { NumeroOne, NumeroTwo, NumeroThree, NumeroFour } from '../process/svg/NumberCircle';

export default function Steps() {
  return (
    <Root>
      <Header className='syne'>We handle the process from start to finish</Header>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: -60,
          zIndex: 500,
        }}
      >
        <div css={{ position: 'relative' }}>
          <Step
            text='No-Cost Feasibility Analysis'
            number={<NumeroOne />}
            mobileColor='#55dcac'
            borderMobile='8px 0 0 0'
            color='#55dcac'
            border='8px 0 0 8px'
          />
          <span css={lineStyles}>
            <MapLine />
          </span>
        </div>
        <Step
          text='Property Tour'
          number={<NumeroTwo />}
          mobileColor='#53ecb5'
          borderMobile='0 8px 0 0'
          color='#53ecb5'
        />
        <Step
          text='report'
          number={<NumeroThree />}
          mobileColor='#53ecb5'
          borderMobile='0 0 0 8px'
          color='#55dcac'
        />
        <StepFour
          text='Tax Return'
          number={<NumeroFour />}
          mobileColor='#0827c7'
          borderMobile='0 0 8px 0'
          color='#0827c7'
          border='0 8px 8px 0'
        />
      </div>
    </Root>
  );
}

function Step({ text, number, mobileColor, color, borderMobile, border }) {
  return (
    <OuterStep
      css={{
        '@media(max-width: 767px)': {
          backgroundColor: mobileColor,
          borderRadius: borderMobile,
        },
        '@media(min-width: 768px)': {
          backgroundColor: color,
          borderRadius: border,
        },
      }}
    >
      <p>{text}</p>
      <div className='number-svg'>{number}</div>
    </OuterStep>
  );
}
function StepFour({ text, number, mobileColor, color, borderMobile, border }) {
  return (
    <OuterStep
      css={{
        '@media(max-width: 767px)': {
          backgroundColor: mobileColor,
          borderRadius: borderMobile,
        },
        '@media(min-width: 768px)': {
          backgroundColor: color,
          borderRadius: border,
        },
      }}
      borderCircle
    >
      <p>{text}</p>
      <div className='number-svg'>{number}</div>
    </OuterStep>
  );
}
