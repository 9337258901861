export const wrapS = {
  alignItems: 'center',
  backgroundColor: '#fff',
  display: 'flex',
  height: 64,
  justifyContent: 'space-between',
  padding: '0 24px',
  position: 'relative',
  zIndex: '500',
  '& .site-logo-image': { maxWidth: 90 },
  '@media(max-width: 1023px)': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    padding: '0 32px',
    height: 72,
  },
  '@media(min-width: 1260px)': {
    height: 100,
    padding: '0 64px',
    '& .site-logo-image': { maxWidth: 120 },
  },
};

export const menuS = {
  display: 'none',
  maxWidth: 1600,
  position: 'relative',
  '@media(min-width: 1024px)': {
    display: 'flex',
    alignItems: 'center',
    '& .desktop': { display: 'none' },
  },
  '@media(min-width: 1260px)': {
    '& .desktop': { display: 'inline-flex' },
  },
  '@media(min-width: 1600px)': {
    '& .desktop': { display: 'inline-flex' },
  },
};
export const sectionWrap = {
  alignItems: 'center',
  borderRadius: '8px 8px 0 0',
  display: 'flex',
  fontWeight: 600,
  textTransform: 'uppercase',
  position: 'relative',
  transition: 'all .2s',
  width: '100%',
  '> svg': { marginLeft: 6 },
  '@media(min-width: 1024px)': {
    padding: '12px 12px',
    fontSize: 11,
  },
  '@media(min-width: 1260px)': {
    padding: '24px 24px',
    fontSize: 14,
    '> svg': { marginLeft: 10 },
  },
  '@media(min-width: 1440px)': {
    padding: '24px 40px',
  },
};

export const itemDropdownWrap = {
  fontSize: 12,
  fontWeight: 500,
  fontFamily: 'Ubuntu',
  '& .arrow-icon': {
    transition: 'all .2s',
  },
  '& .menu-dropdown_wrap': {
    backgroundColor: '#fbfbfb',
    borderRadius: '0 8px 8px 8px',
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 40,
    transition: 'all .2s',
    width: 300,
    a: {
      color: '#929292',
      fontSize: 12,
      '@media(min-width: 1260px)': {
        fontSize: 14,
      },
      fontWeight: 600,
      paddingLeft: 20,
      paddingRight: 20,

      ':first-of-type': {
        marginTop: 20,
      },
      ':last-of-type': {
        marginBottom: 20,
      },
      '.wrapper': {
        padding: 20,
        width: 350,
        height: 100,
        fontSize: 13,
        display: 'flex',
        gap: 20,
        '.text': {
          lineHeight: 1.5,
          'div:first-of-type': {
            color: '#000',
            textTransform: 'uppercase',
            fontSize: 12,
          },
          'div:last-of-type': {
            marginTop: 8,
            fontSize: 11,
          },
        },
        '@media(min-width: 1260px)': {
          fontSize: 13.5,
        },
        '&:hover': {
          // color: "#333",
          backgroundColor: '#F3F4F8',
          borderRadius: '4px',
        },
      },
      display: 'flex',
      alignItems: 'center',
      minHeight: '100px',
      // borderBottom: "1px solid #efefef",
      // ":last-of-type": { borderBottom: "none" },
    },
  },
  '&:hover': {
    '& .dropdown-header': {
      backgroundColor: '#fbfbfb',
      cursor: 'default',
    },
    '& .menu-dropdown_wrap': {
      transition: 'all .2s',
    },
    '& .arrow-icon': {
      transition: 'all .2s',
      transform: 'rotate(-180deg)',
    },
  },
  '@media(min-width: 1260px)': {
    fontSize: 14,
    '& .menu-dropdown_wrap': {
      top: 57,
      width: 350,
      a: {
        // paddingLeft: 40,
        // paddingRight: 40,
      },
    },
  },
};

export const quoteButton = {
  fontSize: 11,
  height: 40,
  marginLeft: 16,
  marginTop: 0,
  minWidth: 0,
  padding: '0 16px',
  width: 140,
  '@media(max-width: 1023px)': { display: 'none' },
  '@media(min-width: 1260px)': {
    padding: '0 16px',
    fontSize: 14,
    width: 160,
  },
  '@media(min-width: 1360px)': { height: 40 },
  '@media(min-width: 1440px)': { height: 40, minWidth: 160 },
};

export const myCircle = {
  width: 27,
  height: 27,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#55DCAC',
  svg: {
    width: 15,
    height: 15,
  },
};
