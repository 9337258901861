export const testimonialOne = [
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Jonathan Tratt',
    image: '/specs/testimonials/Jonathan_Tratt_spvcrm.jpg',
    position: 'Past President, NAIOP ',
    company: 'Principal, Tratt Properties;',
    testimonial: `“Madison SPECS is efficient, reliable, and easy to work with, and I would recommend them to everyone I know. Cost segregation is a NO-brainer for people investing in real estate; it sells itself.”`,
  },
  {
    title: '"They’ve made us an extraordinary amount of money"',
    name: 'Max Sharkansky',
    image: '/specs/testimonials/Max_Sharkansky_y3zsh5.jpg',
    position: 'Managing Partner',
    company: 'Trion Properties ',
    testimonial: `“Madison SPECS has made us an extraordinary amount of money. Madison isn’t just a vendor. Madison is a partner.”`,
  },
  {
    title: '"They helped me save $37,818 on this year’s taxes”"',
    name: 'Mike Dymski',
    image: '/specs/testimonials/Mike_Dymski_cfi7ep.jpg',
    company: 'Maximus Properties LLC',
    testimonial: `“Yonah helped me save $37,818 on 2017 taxes by recommending and performing a cost segregation study. With strategic multifamily rehab and the $2,500 de minimus safe harbor plus cost segregation, taxes on my real estate have been non-existent for a few years (and that includes offsetting large capital gains from the sale of property).”`,
  },
];

export const testimonialTwo = [
  {
    title: '"No better cost segregation firm"',
    name: 'Ben Bonner',
    image: '/specs/testimonials/Ben_Bonner_ws9ldq.jpg',
    company: 'Magnolia Capital Investments ',
    testimonial: `“The Madison SPECS team is always quick to respond, proactive in their dealings, and professionally persistent. I have not encountered a cost segregation firm any better. Highest of recommendations!”`,
  },
];
export const testimonialThree = [
  {
    title: '"Their fees were well under some other bids"',
    name: 'Jeff Greenberg',
    image: '/specs/testimonials/Jeff_Greenberg_oqtmai.jpg',
    company: 'Synergetic Investment Group, LLC',
    testimonial: `“Madison SPECS recently completed cost segregation on two of my properties, and I am very pleased. Their fees were well under some other bids that I have received — and they performed exactly as promised.”`,
  },
  {
    title: '"They’ve done all our cost segregation for years"',
    name: 'Peter J. Bonnell',
    position: 'Principal',
    image: '/specs/testimonials/Pete-Bonnell1_200x200_v1dvep.jpg',
    company: 'Urban Investment Partners',
    testimonial: `“Madison does all of our cost segregation and has for years. The team is great to work with, knowledgeable, our CPAs are happy, and the price is right — we have bid them out against other firms!”`,
  },
  {
    title: '"Worth every penny...we will use them for all our projects"',
    name: 'Sam Grooms',
    company: 'WhiteHaven Capital',
    image: 'specs/sam-groom_crppi8.webp',
    testimonial: `“Getting a cost segregation report from Madison SPECS was worth every penny! Their prices are very reasonable, without sacrificing quality or time, which is extremely rare. We will definitely use them for all of our projects.”`,
  },
];
export const testimonialFour = [
  {
    title: '"A phenomenal job"',
    name: 'Jason Gordon',
    position: 'CPA',
    company: 'S. Gordon Corporation',
    image: 'specs/jason-gordon_cvvki8.jpg',
    testimonial: `“I’ve seen all kinds of cost segregation products. Madison SPECS did a phenomenal job—explaining the process to clients who didn't understand it, and then offering excellent value in terms of pricing and results.
        ”`,
  },
  {
    title: '"Madison SPECS has been the perfect fit"',
    name: 'Barrett Penan',
    image: '/specs/testimonials/Barrett_Penan_rogsvl.png',
    position: 'Principal and Co-founder',
    company: 'PENAN & SCOTT, P.C.',
    testimonial: `“Madison SPECS has completed numerous cost segregation studies for us, including complex mixed-use developments and 1031 exchange projects. Madison SPECS has a long-term solid record of professionalism, quick turnaround and competitive pricing. As a CPA, Madison SPECS has been the perfect fit.”`,
  },
  {
    title: '"We know we can rely on Madison SPECS to deliver"',
    name: 'Brandon Hall',
    image: '/specs/testimonials/Brandon_Hall_x4femw.png',
    position: 'CEO',
    company: 'The Real Estate CPA',
    testimonial: `“Madison SPECS has been an asset to our firm. Their team is always responsive and they have been great to work with, especially in helping us navigate the new complexities thanks to the Tax Cuts and Job Act.”`,
  },
];
export const testimonialFive = [
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Abdul Mozayeni',
    image: '/specs/testimonials/Jonathan_Tratt_spvcrm.jpg',
    company: 'Abco Realty and Investments Inc.',
    testimonial: `“I was very satisfied by your excellent and high quality work. You did the best. I give you the highest rating and will recommend to others”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Dean Joseph',
    position: 'Investor',
    image: '/specs/testimonials/Dean_Joseph_exgxdl.jpg',
    testimonial: `"All the team of Madison has proved to be great team players, professional, effective, and our company are very satisfied with the results.”
    `,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Alon Carmeli',
    company: 'Los Angeles',
    image: '/specs/testimonials/Alon_Carmelli_f6ohqh.jpg',
    testimonial: `"As a developer we rebuild and renovate older properties. Madison SPECS has successfully completed numerous cost segregation studies for a number of projects. They have proven their professionalism and I definitely recommend their services to all of my friends.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Gary A. Taylor',
    company: 'Gary A. Taylor Investment Company',
    image: '/specs/testimonials/Gary_A._Taylor_tnzulh.jpg',
    testimonial: `"Madison SPECS, LLC has performed many cost segregation studies for our company.  We are very pleased with the professionalism and thoroughness of each report. They deliver a quality product in a timely manner. I would highly recommend Madison SPECS, LLC for your next cost segregation study.”`,
  },

  {
    title: '"Cost segregation is a no brainer"',
    name: 'Elliot Zemel',
    image: '/specs/testimonials/Elliot_Zemel_jxkjkp.jpg',
    position: 'CEO',
    company: 'Himel Capital',
    testimonial: `“I have used Madison SPECS for my cost segregation reports a number of times.  Their process is simple, reliable, and fast.  I’m a repeat customer and recommend their services.”`,
  },

  {
    title: '"Cost segregation is a no brainer"',
    image: '/specs/testimonials/Isaac_Dole_uq4xgf.jpg',
    name: 'Isaac Dole',
    company: 'Birchwood Healthcare Partners',
    testimonial: `“Madison SPECS has been a great partner to Birchwood as we have grown our portfolio.  Their responsiveness and engagement has been appreciated.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Gianluca Barreca',
    position: 'Asset Manager',
    company: 'The Frankforter Group',
    image: '/specs/testimonials/Jonathan_Tratt_spvcrm.jpg',
    testimonial: `"Great team over at Madison SPECS.  Always responsive, cooperative and very professional. Looking forward to doing business with the team in the future for our next cost segregation project.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Dino Pierce',
    image: '/specs/testimonials/Dino_Pierce_mublfw.jpg',
    testimonial: `"Yonah Weiss, Business Director at Madison SPECS, has always been willing to help, educate, add value, and lead with integrity.
    Madison SPECS has been on board as the cost segregation experts of choice for our Multifamily (Apartment Community) Syndications.
    ”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Steven Levy',
    position: 'Vice President',
    company: 'Tax & Accounting Services',
    image: '/specs/testimonials/Jonathan_Tratt_spvcrm.jpg',
    testimonial: `"I have used Madison Cost Seg for many of my clients. I highly recommend the firm. They are extremely professional and always available for questions.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Brian Burke',
    position: 'CEO',
    image: '/specs/testimonials/Brian_Burke_dulijb.jpg',
    company: 'Praxis Capital, Inc.',
    testimonial: `"Looking for a cost segregation firm, Yonah reached out to me, and I’m glad he did. We are always looking for vendors who add value to our investors’ bottom line. Cost Segregation is one of those things you wish took care of itself, but Madison SPECS made the process so easy it seems like it did. They had a quick turn around, and were very responsive. Great firm. Great people. Great Service.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Moshe Golden',
    position: 'CPA',
    image: '/specs/testimonials/Moshe_Golden_qrjjg9.jpg',
    company: 'PKF O’Connor Davies LLP',
    testimonial: `"We have a client that recently used Yonah Weiss and Madison SPECS for a cost seg study on a warehouse they purchased in Illinois.  The client and I were quite impressed by the process, the timing and the overall tax savings.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Yechiel Lehrfield',
    position: 'Controller',
    image: '/specs/testimonials/Yechiel_Lehrfield_azwnjf.jpg',
    company: 'Arch Companies',
    testimonial: `“Arch Companies utilized Madison Specs’s services to perform cost seg studies on several of our properties. Madison Specs proved to be professional, reliable and knowledgeable throughout the process.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Casey Smith',
    position: 'Director',
    image: '/specs/testimonials/Casey_Smith_lycfcp.jpg',
    company: 'Conquest Housing',
    testimonial: `"We have been working with Madison Specs for several years on our cost segregation needs.  Their entire team has always been professional and delivered our reports in a timely, organized fashion.  I would highly recommend their services to anyone.”`,
  },
  {
    title: '"Cost segregation is a no brainer"',
    name: 'Wolf Ausch',
    image: '/specs/testimonials/Wolf_Ausch_kzyick.jpg',
    company: 'Wolf Ausch PC',
    testimonial: `"We have been working with Madison Specs for over 10 years on many projects and have been very impressed with their knowledge, efficiency as well as reasonable prices. Leo Rosenbaum, specifically, goes out of his way to accommodate many of our clients. He takes command and does his homework thus saving our firm as well as the clients many hours of work. We hope for the same in the future as we continue to help our clients save tax utilizing their wonderful services.”`,
  },
];
