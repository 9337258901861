/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useState } from 'react';

import {
  FeasabilitySmall,
  AccountantInputSmall,
  DocumentGatheringSmall,
  TheBeginningSmall,
  ProposalSmall,
  SiteVisitSmall,
  StudyDeliveredSmall,
} from './svg/small-illustrations';

import { Root, HeaderText, CirclesWrap, Step, StepsText, IconCircle, DashedLine, noOverflow } from './topStepperStyles';

const clickSections = {
  theBeginning: 'TheBeginning',
  feasibilityAnalysis: 'FeasibilityAnalysis',
  proposal: 'Proposal',
  documentGathering: 'DocumentGathering',
  siteVisit: 'SiteVisit',
  accountantInput: 'AccountantInput',
  studyDelivered: 'StudyDelivered',
};

export default function TopStepper({ onSectionClick }) {
  const [current, setCurrent] = useState('TheBeginning');
  const currentSection = (str) => {
    onSectionClick(str);
    setCurrent(str);
    setCurrentBlue('');
  };
  const [currentBlue, setCurrentBlue] = useState('');
  const currentBlueSection = (str) => {
    onSectionClick(str);
    setCurrentBlue(str);
    setCurrent('');
  };
  return (
    <Root>
      <Global styles={noOverflow} />
      <HeaderText>
        <div>Getting Started</div>
        <div>The Study</div>
      </HeaderText>
      <CirclesWrap>
        <StepsText>Steps</StepsText>
        <Step current={'TheBeginning' === current} onClick={() => currentSection(clickSections.theBeginning)}>
          <DashedLine
            className='dashed-line_steps'
            styles={{
              path: { stroke: '#55dcac' },
              '@media(min-width: 768px)': { display: 'none' },
            }}
          />
          <IconCircle current={'TheBeginning' === current}>
            <TheBeginningSmall maxWidth='26' />
          </IconCircle>
          <p>1. The beginning</p>
        </Step>
        <Step
          current={'FeasibilityAnalysis' === current}
          onClick={() => currentSection(clickSections.feasibilityAnalysis)}
        >
          <DashedLine className='dashed-line_steps' green />
          <IconCircle current={'FeasibilityAnalysis' === current}>
            <FeasabilitySmall maxWidth='26' />
          </IconCircle>
          <p>2. Feasibility Analysis</p>
        </Step>
        <Step current={'Proposal' === current} onClick={() => currentSection(clickSections.proposal)}>
          <DashedLine className='dashed-line_steps' green />
          <IconCircle current={'Proposal' === current}>
            <ProposalSmall maxWidth='18' />
          </IconCircle>
          <p>3. Proposal</p>
        </Step>
        <Step
          currentBlue={'DocumentGathering' === currentBlue}
          onClick={() => currentBlueSection(clickSections.documentGathering)}
        >
          <DashedLine className='dashed-line_steps' />
          <IconCircle blue currentBlue={'DocumentGathering' === currentBlue}>
            <DocumentGatheringSmall maxWidth='24' />
          </IconCircle>
          <p>4. Document Gathering</p>
        </Step>
        <Step currentBlue={'SiteVisit' === currentBlue} onClick={() => currentBlueSection(clickSections.siteVisit)}>
          <DashedLine className='dashed-line_steps' />
          <IconCircle blue currentBlue={'SiteVisit' === currentBlue}>
            <SiteVisitSmall maxWidth='32' />
          </IconCircle>
          <p>5. Site Visit</p>
        </Step>
        <Step
          currentBlue={'AccountantInput' === currentBlue}
          onClick={() => currentBlueSection(clickSections.accountantInput)}
        >
          <DashedLine className='dashed-line_steps' />
          <IconCircle blue currentBlue={'AccountantInput' === currentBlue}>
            <AccountantInputSmall maxWidth='24' />
          </IconCircle>
          <p>6. Accountant Input</p>
        </Step>
        <Step
          currentBlue={'StudyDelivered' === currentBlue}
          onClick={() => currentBlueSection(clickSections.studyDelivered)}
        >
          <DashedLine className='dashed-line_steps' />
          <IconCircle blue currentBlue={'StudyDelivered' === currentBlue}>
            <StudyDeliveredSmall maxWidth='24' />
          </IconCircle>
          <p>7. Study Delivered</p>
        </Step>
        <div
          css={{
            paddingLeft: 24,
            '@media(min-width: 768px)': {
              display: 'none',
            },
          }}
        ></div>
      </CirclesWrap>
    </Root>
  );
}
