import React from 'react';
import {
  AudioJack,
  Appliances,
  AsphaltConcrete,
  BarberStation,
  Billboards,
  Bridge,
  Buses,
  Cabinetry,
  Carpet,
  CCTV,
  Electric,
  EmergencyGenerator,
  Fencing,
  Furniture,
  GreaseInterceptor,
  Handrails,
  HospitalBed,
  HospitalFurniture,
  LandscapingStone,
  LightPoleFixture,
  Lockers,
  MechanicalSecurityDoor,
  OutdoorLighting,
  OutdoorPipes,
  PalletRacks,
  PartitionWall,
  RetainingWall,
  RoboticPalletMover,
  SeatingBooth,
  SpecialHVAC,
  StripedBox,
  StormWater,
  SwimmingPool,
  TruckDock,
  WalkinFridge,
  WallPanels,
  Wardrobe,
  WashingMachine,
  WindTurbine,
  Wiring,
  WoodFlooring,
  PlateIcon,
  TreeIcon,
  SQFT,
} from './svg/assetIcons';
import { XIcon } from '../../components/ui/icons';

export const studiesList = [
  {
    image: 'warehouse-min_du8101',
    storiesCount: '7',
    propertyType: 'Distribution Center',
    city: 'Jackson, TN',
    state: 'TN',
    squareFeet: '217,000 SF',
    buildings: 'Distribution Center',
    yearOne: {
      before: 9622,
      after: 60192,
    },
    yearTwo: {
      before: 230574,
      after: 587052,
    },
    yearThree: {
      before: 230574,
      after: 455942,
    },
    yearFour: {
      before: 230574,
      after: 374272,
    },
    yearFive: {
      before: 230574,
      after: 344331,
    },
    yearSix: {
      before: 230574,
      after: 326192,
    },
    purchaseRehab: 'New Construction',
    purchasePrice: '$9M',
    fiveYearPercent: '9%',
    fifteenYearPercent: '11%',
    sixYearSaving: '$381,000',
    afterBonus: '$778,000',
    title: '217,000 SF Distribution Center',
    topAssets: [
      {
        title: 'Asphalt and Concrete Paving',
        value: '$883,000',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Vinyl and Carpet Flooring',
        value: '$140,000',
        icon: <Carpet />,
      },
      {
        title: 'Dock Equipment',
        value: '$131,000',
        icon: <TruckDock />,
      },
      {
        title: 'Lockers',
        value: '$168,000',
        icon: <Lockers />,
      },
      {
        title: 'Emergency Generator',
        value: '$44,000',
        icon: <EmergencyGenerator />,
      },
    ],
    storySubHeader: 'We’ve Got You Covered',
    story: `Our client had a seven-property portfolio
    spread over four states that needed cost
    seg studies done just three weeks before
    he had to get his K1s out to his investors.
    Thankfully, our in-house engineers are
    strategically placed across the country,
    and we were able to schedule them all
    out at the same time and gather all the
    information needed to complete the
    studies well within the client’s time frame.`,
    storyAuthor: 'Moshe, SPECS Director of Operations',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083933/specs-svg/Warehouse_72-Sand-Pebble-Dr_xrcglk.svg',
  },
  {
    image: 'Multifamily-3.1_pikwny',
    storiesCount: '5',
    propertyType: 'Multifamily',
    city: 'San Angelo, TX',
    state: 'TX',
    squareFeet: '173,588 SF',
    buildings: '10 Buildings',
    units: '120 Units',
    yearOne: {
      before: 148069,
      after: 311371,
    },
    yearTwo: {
      before: 507426,
      after: 1526214,
    },
    yearThree: {
      before: 507426,
      after: 1100335,
    },
    yearFour: {
      before: 507426,
      after: 840523,
    },
    yearFive: {
      before: 507426,
      after: 761602,
    },
    yearSix: {
      before: 507426,
      after: 700676,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$16.4 M',
    fiveYearPercent: '20%',
    fifteenYearPercent: '10%',
    sixYearSaving: '$997,000',
    afterBonus: '$1.79 M',
    title: '120 Unit Multifamily',
    storySubHeader: 'Whatever It Takes',
    story: `As a Business Development rep, I always
    have to be on my toes. You just never
    know what the day will bring. My
    prospect was interested in cost seg but
    was just too busy to focus and finalize.
    He mentioned that he was having a two hour stopover that day in Dallas, so I
    headed to the airport with all the
    paperwork and met him in the lounge.
    With no distractions, we were able to
    review everything, and he signed the
    engagement letter right there in the
    airport.`,
    storyAuthor: 'Brian, SPECS Sales Rep',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083936/specs-svg/1426-Western-Court2_rbharm.svg',
    topAssets: [
      {
        title: 'Vinyl Tiles',
        value: '253,000 SF',
        icon: <WoodFlooring />,
      },
      {
        title: 'Carpeting',
        value: '52,000 SF',
        icon: <Carpet />,
      },
      {
        title: 'Telephone/Computer Wiring',
        value: '174,000 SF',
        icon: <Wiring />,
      },
      {
        title: 'Asphalt Paving',
        value: '175,000',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Landscaping Stone',
        value: '29,000 SF',
        icon: <LandscapingStone />,
      },
    ],
  },
  {
    image: 'distribution-center_ggo3as',
    storiesCount: '3',
    propertyType: 'Distribution Center',
    city: 'Waterbury, VT',
    state: 'VT',
    squareFeet: '197,767 SF',
    buildings: 'Single Buiding',
    yearOne: {
      before: 7503,
      after: 78911,
    },
    yearTwo: {
      before: 178801,
      after: 685196,
    },
    yearThree: {
      before: 178801,
      after: 494657,
    },
    yearFour: {
      before: 178801,
      after: 376718,
    },
    yearFive: {
      before: 178801,
      after: 334776,
    },
    yearSix: {
      before: 178801,
      after: 310013,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '8.25M',
    fiveYearPercent: '17%',
    fifteenYearPercent: '17%',
    sixYearSaving: '$532,318',
    afterBonus: '$1,029,093',
    title: '3 Story, 250,000 SF Distribution Center',
    topAssets: [
      {
        title: 'Concrete and Asphalt Paving',
        value: '1,546,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Emergency Generators',
        value: '2',
        icon: <EmergencyGenerator />,
      },
      {
        title: 'Lockers',
        value: '300',
        icon: <Lockers />,
      },
      {
        title: 'Robotic Pallet Mover',
        value: '1',
        icon: <RoboticPalletMover />,
      },
      {
        title: 'Pallet Racks',
        value: '3,920 SF ',
        icon: <PalletRacks />,
      },
    ],
    storySubHeader: 'Just the Facts',
    story: `All clients are busy. I understand that. It’s
    part of our job to stay patient and work
    with them on their terms, and they are
    always appreciative. But this particular
    one was extremely pressured and over
    his head. He knew that he had to get us
    the basic information for us to complete
    the study but just couldn’t focus. We
    made everything as clear and simple as
    possible so his involvement was limited to
    the absolute minimal necessities, and we
    handled the rest. It all worked out well
    and he made sure to find the time to
    thank us at the end.`,
    storyAuthor: 'Carol, SPECS Study Processor',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083935/specs-svg/distribution-center_ifgftj.svg',
  },
  {
    image: 'Retail_15440_N._7th_St_step2_fpbgod',
    storiesCount: '1',
    propertyType: 'Retail',
    city: 'Phoenix, AZ',
    state: 'AZ',
    squareFeet: '17,600 SF',
    buildings: '',
    yearOne: {
      before: 62287,
      after: 167249,
    },
    yearTwo: {
      before: 71074,
      after: 276110,
    },
    yearThree: {
      before: 71074,
      after: 193971,
    },
    yearFour: {
      before: 71074,
      after: 143741,
    },
    yearFive: {
      before: 71074,
      after: 139264,
    },
    yearSix: {
      before: 71074,
      after: 105264,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$3.3 M',
    fiveYearPercent: '22%',
    fifteenYearPercent: '12%',
    sixYearSaving: '$239,000',
    afterBonus: '$393,000',
    title: '17,600 SF Retail',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '32,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Barber Stations',
        value: '15',
        icon: <BarberStation />,
      },
      {
        title: 'Custom Cabinets',
        value: '74 Sets',
        icon: <Cabinetry />,
      },
      {
        title: 'Floating Wood Flooring',
        value: '2,500 SF',
        icon: <WoodFlooring />,
      },
    ],
    story: `While scanning the interior of the
    warehouse I noticed some loose wires
    hanging through the high ceiling. I asked
    the building owner about them and he
    said that they were attached to a
    complete set of solar panels on the roof.
    They had never been hooked up so he
    assumed that they weren’t depreciable
    and never mentioned them in our
    previous discussion. The truth was that
    they were completely depreciable even
    though they weren’t operable. Because of
    them we were able to greatly increase the
    percentage of 5-year property and add a
    huge boost to his upfront tax savings.`,
    storySubHeader: 'Through the Roof Savings',
    storyAuthor: 'Bob, SPECS Engineer',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083936/specs-svg/Retail_15440_N._7th_St-primary_jnjhdq.svg',
  },
  {
    image: 'medical-office_vkojcy',
    storiesCount: '3',
    propertyType: 'Medical',
    city: 'Keller, TX',
    state: 'TX',
    squareFeet: '15,000 SF',
    buildings: '',
    yearOne: {
      before: 91938,
      after: 199216,
    },
    yearTwo: {
      before: 95681,
      after: 297502,
    },
    yearThree: {
      before: 95681,
      after: 266435,
    },
    yearFour: {
      before: 68681,
      after: 181485,
    },
    yearFive: {
      before: 95861,
      after: 175270,
    },
    yearSix: {
      before: 95681,
      after: 141146,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$4.4 M',
    fiveYearPercent: '13%',
    fifteenYearPercent: '22%',
    sixYearSaving: '$255,000',
    afterBonus: '$546,500',
    title: '15,000 SF Medical Office',
    topAssets: [
      {
        title: 'Concrete and Asphalt Paving',
        value: '53,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Retaining Wall',
        value: '819 SF',
        icon: <RetainingWall />,
      },
      {
        title: 'Outdoor Lighting Units',
        value: '6',
        icon: <OutdoorLighting />,
      },
      {
        title: 'Carpet and Vinyl Flooring',
        value: '14,700 SF',
        icon: <Carpet />,
      },
    ],
    storySubHeader: 'Crunching the Numbers',
    story: `Our client came to us with a new office
    building purchase that was the back end
    of a 1031 Exchange. He was looking at a
    big tax bill, even after the Exchange, and
    was relying on the cost seg study to add
    significant depreciation to offset
    additional income and be able to knock
    down the bill. The challenge we had was
    that the lowered tax basis of the property
    because of the Exchange also decreased
    the value of the cost seg. We worked with
    our colleagues at Madison 1031 to really
    understand all of the numbers involved
    and, together, we came up with enough
    additional depreciation that it greatly
    lowered the tax bill and added cash flow
    to his pocket.`,
    storyAuthor: 'Charles, SPECS Tax Expert',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083933/specs-svg/Medical-Office_816-Keller-Parkway_iijr3i.svg',
  },
  {
    image: 'nursing-home_mfsint',
    storiesCount: '8',
    propertyType: 'Nursing Home',
    city: 'Cleveland, OH',
    state: 'OH',
    squareFeet: '184,000 SF',
    buildings: '175 Beds',
    yearOne: {
      before: 112007,
      after: 209161,
    },
    yearTwo: {
      before: 141458,
      after: 354304,
    },
    yearThree: {
      before: 141458,
      after: 260676,
    },
    yearFour: {
      before: 141458,
      after: 204175,
    },
    yearFive: {
      before: 141458,
      after: 198914,
    },
    yearSix: {
      before: 141458,
      after: 165247,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$7.6 M',
    fiveYearPercent: '18%',
    fifteenYearPercent: '3%',
    sixYearSaving: '$227,000',
    afterBonus: '$337,000',
    title: '175 Bed Nursing Home',
    topAssets: [
      {
        title: 'Handrails',
        value: '3,260',
        icon: <Handrails />,
      },
      {
        title: 'Commercial Washing Machines',
        value: '3',
        icon: <WashingMachine />,
      },
      {
        title: 'Hospital Beds',
        value: '175',
        icon: <HospitalBed />,
      },
      {
        title: 'Built-In Wardrobes',
        value: '240',
        icon: <Wardrobe />,
      },
      {
        title: 'Vinyl Tile',
        value: '81,000 SF',
        icon: <WoodFlooring />,
      },
      {
        title: 'Asphalt Parking',
        value: '4,700 SF',
        icon: <AsphaltConcrete />,
      },
    ],
    storySubHeader: 'White Glove Service',
    story: `Buying my first property was a huge
    learning experience. Every step of the
    way had to be done carefully, and I was
    excited and relieved when the transaction
    was finally completed. I had heard that I
    should have a cost seg study done at that
    point, but I was very unfamiliar with the
    process. The concept sounded great but,
    being a new and small player, I was afraid
    that I wouldn’t get attention, and might be
    overcharged as well. Mark took the time
    and explained the whole process and
    what to expect. He kept in touch from
    start to finish to make sure I understood
    what was happening and to answer any
    questions that came up. The price was
    very reasonable and we got great results.`,
    storyAuthor: 'Jim, Property Owner',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083933/specs-svg/Nursing-Home_6606-Carnegie-Ave_ak888f.svg',
  },
  {
    image: 'self-storage-10612-beaver-dam_d52ffe',
    storiesCount: '10',
    propertyType: 'Storage',
    city: 'Cockeysville, MD',
    state: 'MD',
    squareFeet: '110,000 SF',
    buildings: 'Single building, 3 Stories',
    units: '711 Units',
    yearOne: {
      before: 140855,
      after: 9410,
    },
    yearTwo: {
      before: 561094,
      after: 225491,
    },
    yearThree: {
      before: 486653,
      after: 225491,
    },
    yearFour: {
      before: 411742,
      after: 225491,
    },
    yearFive: {
      before: 336846,
      after: 225491,
    },
    yearSix: {
      before: 261533,
      after: 225491,
    },
    purchaseRehab: 'New Construction',
    purchasePrice: '$8.4 M',
    fiveYearPercent: '15%',
    fifteenYearPercent: '3%',
    sixYearSaving: '$279,000',
    afterBonus: '$474,000',
    title: '711 Unit Self Storage',
    topAssets: [
      {
        title: 'Special Purpose HVAC',
        value: '$199,000 ',
        icon: <SpecialHVAC />,
      },
      {
        title: 'Demountable Partitions',
        value: '$569,000',
        icon: <PartitionWall />,
      },
      {
        title: 'Security System',
        value: '$124,000',
        icon: <CCTV />,
      },
      {
        title: 'Asphalt Paving',
        value: '$92,000',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Fencing',
        value: '$81,000',
        icon: <Fencing />,
      },
    ],
    story: `I knew that my properties had performed
      really well over the past year, but it was
      still a shock when my accountant
      presented me with my upcoming tax bill.
      There were only a few days left before I
      had to file and I didn’t have anywhere
      near the liquidity to pay it up. My
      accountant got in touch with SPECS to
      see if they could help. Even though it was
      in the heat of tax filing season, their
      engineers and tax experts were able to
      turn around my cost seg study and,
      utilizing 100% Bonus Depreciation, get me more than
      enough depreciation to offset my
      upcoming payment.`,
    storyAuthor: 'Jack, Property Investor',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083933/specs-svg/Self-Storage_10612-Beaver-Dam-rd_qmmztl.svg',
  },
  {
    image: 'nursing-home-2_tn0r43',
    storiesCount: '9',
    propertyType: 'Nursing Home',
    city: 'Valatie, NY',
    state: 'NY',
    squareFeet: '212,000 SF',
    buildings: '236 Beds',
    yearOne: {
      before: 867336,
      after: 7015830,
    },
    yearTwo: {
      before: 1298120,
      after: 858663,
    },
    yearThree: {
      before: 1223700,
      after: 858663,
    },
    yearFour: {
      before: 1148380,
      after: 858663,
    },
    yearFive: {
      before: 1073860,
      after: 858663,
    },
    yearSix: {
      before: 998020,
      after: 858663,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$26.5 M',
    fiveYearPercent: '24%',
    fifteenYearPercent: '2%',
    sixYearSaving: '$1.7M',
    afterBonus: '2.6M',
    title: '236 Bed Skilled Nursing Facility',
    topAssets: [
      {
        title: 'Buses',
        value: '3',
        icon: <Buses />,
      },
      {
        title: 'Asphalt Paving',
        value: '70,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Vinyl Flooring',
        value: '82,000 SF',
        icon: <WoodFlooring />,
      },
      {
        title: 'Sets of Custom Cabinets',
        value: '257',
        icon: <Cabinetry />,
      },
      {
        title: 'Sets of Hospital Furniture',
        value: '240',
        icon: <HospitalFurniture />,
      },
    ],
    storySubHeader: 'Picture Perfect',
    story: `I showed up to the property thinking that
    it was going to be a simple site tour of a
    recently purchased assisted living facility.
    That changed quickly when I noticed
    some recently laid tiles in one of the
    wings. After inquiring, I was told that
    there had been some damage from a
    burst pipe and the new owner decided to
    rehab the whole area. This was a
    potential windfall because I suspected
    that there was probably a lot of remaining
    value in the original structure that had
    been removed. From a depreciation
    perspective, all of that could be written
    off immediately. The challenge was that
    there was no documentation listing what
    the assets were before they were pulled
    out. Luckily, they were able to pull up
    many pictures that we used to
    reconstruct what had been there, and we
    ended up adding an additional $75,000 in
    assets that could be depreciated up front.`,
    storyAuthor: 'Steve, SPECS Engineer',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/v1628083936/specs-svg/snf-3230-Church-St_w4i6ri.svg',
  },
  {
    image: 'bank_sdoe3c',
    storiesCount: '4',
    propertyType: 'Warehouse',
    city: 'Rockledge, FL',
    state: 'FL',
    squareFeet: '186,682 SF',
    buildings: '',
    yearOne: {
      before: 75072,
      after: 271150,
    },
    yearTwo: {
      before: 105819,
      after: 434188,
    },
    yearThree: {
      before: 105819,
      after: 304023,
    },
    yearFour: {
      before: 105819,
      after: 224177,
    },
    yearFive: {
      before: 105819,
      after: 219477,
    },
    yearSix: {
      before: 105819,
      after: 159282,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$5.2 M',
    fiveYearPercent: '24%',
    fifteenYearPercent: '15%',
    sixYearSaving: '$398,277',
    afterBonus: '$668,075',
    title: '186,000 SF Warehouse/Office Facility',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '230,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Light Poles and Fixtures',
        value: '78',
        icon: <LightPoleFixture />,
      },
      {
        title: 'Pedestrian Bridges',
        value: '580 SF',
        icon: <Bridge />,
      },
      {
        title: 'Carpet Tiles',
        value: '5,877 SY',
        icon: <WoodFlooring />,
      },
      {
        title: 'Tilt-Up Wall Panels',
        value: '7,200 SF',
        icon: <WallPanels />,
      },
    ],
    storySubHeader: 'On the Same Page',
    story: `Many of my clients have been with me for
    years and trust me for everything
    relating to their taxes. I was very hesitant
    to outsource any work involved with their
    filings. After getting on the phone with
    Chuck from SPECS, I changed my tune
    entirely. As a fellow CPA, it was like
    talking to a colleague and I realized that
    he really knew what he was talking about.
    And when he told me that the SPECS’ inhouse CPAs would also prepare the Form
    3115 once the study was completed, I was
    sold. They have become a true partner in
    all my clients’ cost seg projects.`,
    storyAuthor: 'William, CPA',
  },
  {
    image: 'office_vfnoxe',
    storiesCount: '2',
    propertyType: 'Office',
    city: 'Frederick, MD',
    state: 'MD',
    squareFeet: '82,000 SF',
    buildings: 'Single Building',
    units: '4 Stories',
    yearOne: {
      before: 62240,
      after: 128994,
    },
    yearTwo: {
      before: 135585,
      after: 397300,
    },
    yearThree: {
      before: 135585,
      after: 293116,
    },
    yearFour: {
      before: 135585,
      after: 229428,
    },
    yearFive: {
      before: 135585,
      after: 213360,
    },
    yearSix: {
      before: 135585,
      after: 191164,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$6.2 M',
    fiveYearPercent: '15%',
    fifteenYearPercent: '8%',
    sixYearSaving: '$278,000',
    afterBonus: '$467,000',
    title: '82,000 SF Office',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '128,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'CCTV and Camera Units',
        value: '13',
        icon: <CCTV />,
      },
      {
        title: 'Carpet Tiles',
        value: '88,000 SF',
        icon: <Carpet />,
      },
      {
        title: 'Drainage Pipes',
        value: '1,800 LF',
        icon: <OutdoorPipes />,
      },
    ],
  },
  {
    image: 'hotel-winery-min_tih6kl',
    storiesCount: '',
    propertyType: 'Hotel/Winery',
    city: 'Egg Harbor City, TX',
    state: 'NJ',
    squareFeet: '63,000 SF',
    buildings: '50 Rooms plus Banquet Hall and Winery on 231 Acres of Land',
    yearOne: {
      before: 1311,
      after: 6728,
    },
    yearTwo: {
      before: 31409,
      after: 159422,
    },
    yearThree: {
      before: 31409,
      after: 111822,
    },
    yearFour: {
      before: 31409,
      after: 82217,
    },
    yearFive: {
      before: 31409,
      after: 69426,
    },
    yearSix: {
      before: 31409,
      after: 65962,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$1.725 M',
    fiveYearPercent: '2%',
    fifteenYearPercent: '25%',
    sixYearSaving: '$130,000',
    afterBonus: '$161,185',
    foo: '30% Land Allocation',
    title: 'Banquet Hall on a 230 Acre Winery',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '356,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'American and French Wine Barrels',
        value: '62',
        icon: <XIcon />,
      },
      {
        title: 'Pieces of Restaurant Furniture',
        value: '1,500',
        icon: <Furniture />,
      },
      {
        title: 'Commercial Refrigerators',
        value: '8',
        icon: <XIcon />,
      },
      {
        title: 'Vineyard',
        value: '116,488 SF',
        icon: <XIcon />,
      },
    ],
  },
  {
    image: 'retail-500-baychester_eoq1ah',
    storiesCount: 'retail-500-baychester.svg',
    propertyType: 'Retail',
    city: 'Bronx, NY',
    state: 'NY',
    squareFeet: '10,400 SF',
    buildings: '',
    yearOne: {
      before: 236419,
      after: 1105919,
    },
    yearTwo: {
      before: 246313,
      after: 1749362,
    },
    yearThree: {
      before: 246313,
      after: 1129577,
    },
    yearFour: {
      before: 246313,
      after: 753027,
    },
    yearFive: {
      before: 246313,
      after: 740444,
    },
    yearSix: {
      before: 246313,
      after: 457198,
    },
    purchaseRehab: 'New Construction',
    purchasePrice: '$9.6 M',
    fiveYearPercent: '49%',
    fifteenYearPercent: '17%',
    sixYearSaving: '$1.8 M',
    afterBonus: '$2.7 M',
    foo: 'Foundation, electric and one section of the mast were constructed to house a large, commercial wind turbine. Also has two large electronic billboards.',
    title: '10,400 SF Retail',
    topAssets: [
      {
        title: 'Wind Turbine ($3.4 M)',
        value: '1',
        icon: <WindTurbine />,
      },
      {
        title: 'Large Digital Billboards ($1.6 M)',
        value: '2',
        icon: <Billboards />,
      },
      {
        title: 'Asphalt and Concrete Paving',
        value: '$281,000',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Stormwater System',
        value: '$61,000',
        icon: <StormWater />,
      },
    ],
  },
  {
    image: 'self-storage-341-gateway_ipdce3',
    storiesCount: '',
    propertyType: 'Storage',
    city: 'Winchester, VA',
    state: 'VA',
    squareFeet: '77,600 SF',
    buildings: '3 Buildings',
    units: '616 Units',
    yearOne: {
      before: 40052,
      after: 237523,
    },
    yearTwo: {
      before: 106638,
      after: 419063,
    },
    yearThree: {
      before: 106638,
      after: 304311,
    },
    yearFour: {
      before: 106638,
      after: 232539,
    },
    yearFive: {
      before: 106638,
      after: 224683,
    },
    yearSix: {
      before: 106638,
      after: 170334,
    },
    purchaseRehab: 'New Construction',
    purchasePrice: '$4.2 M',
    fiveYearPercent: '2%',
    fifteenYearPercent: '25%',
    sixYearSaving: '$400,000',
    afterBonus: '$784,000',
    title: '616 Unit Self Storage',
    topAssets: [
      {
        title: 'Mechanical Security Doors',
        value: '399 ($392,000)',
        icon: <MechanicalSecurityDoor />,
      },
      {
        title: 'Demountable Metal Partition Walls',
        value: '40,000 SF',
        icon: <PartitionWall />,
      },
      {
        title: 'Stormwater System',
        value: '$124,000 ',
        icon: <OutdoorPipes />,
      },
      {
        title: 'Asphalt Paving',
        value: '$259,000',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Retaining Wall',
        value: '$331,000',
        icon: <RetainingWall />,
      },
    ],
  },
  {
    image: 'multifamily-1-min_srdvrr',
    storiesCount: '',
    propertyType: 'Multifamily',
    city: 'Edmond, OK',
    state: 'OK',
    squareFeet: '96,152 SF',
    buildings: '14 buildings',
    units: '100 Units',
    yearOne: {
      before: 251578,
      after: 251578,
    },
    yearTwo: {
      before: 203601,
      after: 564342,
    },
    yearThree: {
      before: 203616,
      after: 371491,
    },
    yearFour: {
      before: 203616,
      after: 296546,
    },
    yearFive: {
      before: 203616,
      after: 289989,
    },
    yearSix: {
      before: 203616,
      after: 239279,
    },
    purchaseRehab: 'Lookback',
    purchasePrice: '$6.2 M',
    fiveYearPercent: '16%',
    fifteenYearPercent: '9%',
    sixYearSaving: '$297,000',
    afterBonus: '$455,022',
    title: '100 Unit Multifamily',
    topAssets: [
      {
        title: 'Asphalt and Concrete Paving',
        value: '122,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Special Purpose Electric and Plumbing',
        value: '100 Units',
        icon: <Electric />,
      },
      {
        title: 'Swimming Pool',
        value: '1',
        icon: <SwimmingPool />,
      },
      {
        title: 'Cabinetry',
        value: '2,250 LF',
        icon: <Cabinetry />,
      },
      {
        title: 'Commercial Laundry Units',
        value: '14',
        icon: <Appliances />,
      },
      {
        title: 'Flooring',
        value: '74,000 SF',
        icon: <WoodFlooring />,
      },
    ],
  },
  {
    image: 'restaurant_b56p3x',
    storiesCount: '',
    propertyType: 'Restaurant',
    city: 'Phoenix, AZ',
    state: 'AZ',
    squareFeet: '3,361 SF',
    buildings: 'Stand-Alone Restaurant',
    yearOne: {
      before: 62287,
      after: 167249,
    },
    yearTwo: {
      before: 71074,
      after: 276110,
    },
    yearThree: {
      before: 71074,
      after: 193971,
    },
    yearFour: {
      before: 71074,
      after: 143741,
    },
    yearFive: {
      before: 71074,
      after: 139264,
    },
    yearSix: {
      before: 71074,
      after: 105264,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$2 M',
    fiveYearPercent: '24%',
    fifteenYearPercent: '25%',
    sixYearSaving: '$128,705',
    afterBonus: '$294,820',
    title: 'Fast Food Restaurant',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '16,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Seating Booths',
        value: '60',
        icon: <SeatingBooth />,
      },
      {
        title: 'Walk-In Fridge',
        value: '1',
        icon: <WalkinFridge />,
      },
      {
        title: 'Light Poles and Fixtures',
        value: '6',
        icon: <LightPoleFixture />,
      },
      {
        title: 'Grease Interceptor',
        value: '1',
        icon: <GreaseInterceptor />,
      },
    ],
  },
  {
    image: 'bank_sdoe3c',
    storiesCount: '',
    propertyType: 'Bank',
    city: 'Nashville, TN',
    state: 'TN',
    squareFeet: '5,275 SF',
    buildings: 'One Story Building',
    yearOne: {
      before: 42738,
      after: 89633,
    },
    yearTwo: {
      before: 53900,
      after: 163929,
    },
    yearThree: {
      before: 53900,
      after: 125645,
    },
    yearFour: {
      before: 53900,
      after: 101379,
    },
    yearFive: {
      before: 53900,
      after: 96269,
    },
    yearSix: {
      before: 53900,
      after: 81053,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$3.2 M',
    fiveYearPercent: '12%',
    fifteenYearPercent: '21%',
    sixYearSaving: '$135,000',
    afterBonus: '$297,000',
    title: 'One story building',
    topAssets: [
      {
        title: 'Asphalt Paving',
        value: '16,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Retaining Wall',
        value: '2,800 SF',
        icon: <RetainingWall />,
      },
      {
        title: 'Security Vault Door',
        value: '1',
        icon: <MechanicalSecurityDoor />,
      },
      {
        title: 'Extra Large Billboard Sign',
        value: '1',
        icon: <Billboards />,
      },
    ],
  },
  {
    image: 'auto-dealership_pbkl1c',
    storiesCount: '',
    propertyType: 'Auto Dealership',
    city: 'Dallas, TX',
    state: 'TX',
    squareFeet: '13,985 SF',
    buildings: 'Stand Alone Building',
    yearOne: {
      before: 9777,
      after: 64779,
    },
    yearTwo: {
      before: 234986,
      after: 611050,
    },
    yearThree: {
      before: 234286,
      after: 497620,
    },
    yearFour: {
      before: 234286,
      after: 422923,
    },
    yearFive: {
      before: 234286,
      after: 388535,
    },
    yearSix: {
      before: 234286,
      after: 364224,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$10.75 M',
    fiveYearPercent: '7%',
    fifteenYearPercent: '25%',
    sixYearSaving: '$449,000',
    afterBonus: '$1,221,000',
    title: 'One Story Auto Dealership',
    topAssets: [
      {
        title: 'Concrete Paving',
        value: '87,000 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Retaining Walls',
        value: '4,000 SF',
        icon: <RetainingWall />,
      },
      {
        title: 'Light Poles and Fixtures',
        value: '14',
        icon: <LightPoleFixture />,
      },
    ],
  },
  {
    image: 'multifamily-2_sveuqh',
    storiesCount: '',
    propertyType: 'Multifamily',
    city: 'Garland, TX',
    state: 'TX',
    squareFeet: '60,330 SF',
    buildings: '59 Units',
    yearOne: {
      before: 17399,
      after: 74398,
    },
    yearTwo: {
      before: 139038,
      after: 447502,
    },
    yearThree: {
      before: 139038,
      after: 314321,
    },
    yearFour: {
      before: 139038,
      after: 233824,
    },
    yearFive: {
      before: 139038,
      after: 210762,
    },
    yearSix: {
      before: 139038,
      after: 193228,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$4.5 M',
    fiveYearPercent: '23%',
    fifteenYearPercent: '5%',
    sixYearSaving: '$298,000',
    afterBonus: '$461,500',
    title: '59 Unit Multifamily',
    topAssets: [
      {
        title: 'Custom Cabinets',
        value: '1,389',
        icon: <Cabinetry />,
      },
      {
        title: 'Concrete and Asphalt Paving',
        value: '40,500 SF',
        icon: <AsphaltConcrete />,
      },
      {
        title: 'Flooring',
        value: '51,000 SF',
        icon: <WoodFlooring />,
      },
      {
        title: 'CCTV Units',
        value: '14',
        icon: <CCTV />,
      },
    ],
  },
  {
    image: 'golf-course_tquqpk',
    storiesCount: '',
    propertyType: 'Golf Course',
    city: 'Columbus, OH',
    state: 'OH',
    squareFeet: '8,180 SF Clubhouse',
    units: '18-Hole',
    // graphImg: 'https://res.cloudinary.com/dyuuf6gor/image/upload/v1715701029/specs/golf-chart2_vuhud9.png',
    graphImg:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/Monosnap_madison_case_study_2024-05-15_10-04-42_oknwqw.png',
    yearOne: {
      before: 1000,
      after: 1800000,
    },
    yearTwo: {
      before: 5000,
      after: 60000,
    },
    yearThree: {
      before: 5000,
      after: 60000,
    },
    yearFour: {
      before: 5000,
      after: 60000,
    },
    yearFive: {
      before: 5000,
      after: 60000,
    },
    yearSix: {
      before: 5000,
      after: 60000,
    },
    purchaseRehab: 'Purchase',
    purchasePrice: '$2.8 M',
    fiveYearPercent: '20%',
    fifteenYearPercent: '64%',
    sixYearSaving: '84%',
    afterBonus: '$1,988,535',
    title: '18-hole golf course',
    tileThree: true,
    tileThreePerecent: true,
    story: `Because of the sheer size of a golf course, walking in to do a study can be daunting. Fortunately, I love to golf, so this was a challenge I eagerly accepted. Between chatting about golf and the best way to tackle the course, I also got detailed information and costs for each piece of specialized equipment on the course by calling up a facility manager who specializes in purchasing golf equipment. Doing a golf cart drive-through instead of a walkthrough of the full 146 acre course, examining the different landscaping and water features, was a fun way to wrap up the tour!`,
    storyAuthor: 'Michael, Engineer',
    storyIllustration:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs-svg/golf-course_tquqpk.svg',
    // storySubHeader: 'Every Study Has a Story',
    topAssets: [
      {
        title: 'Golf Course Maintenance Equipment',
        value: '1,078,481',
        icon: <StripedBox />,
      },
      {
        title: 'Kitchen Equipment',
        value: '336,441',
        icon: <PlateIcon />,
      },
      {
        title: 'Parking Lot & Golf Cart Track Paving',
        value: '291,040 SF',
        icon: <SQFT />,
      },
      {
        title: 'Specialized Irrigation System',
        value: '1,205,700',
        icon: <AudioJack />,
      },
      {
        title: 'Golf Course Landscaping',
        value: '6,385,869 SF',
        icon: <TreeIcon />,
      },
    ],
  },
];

export function filterList(type) {
  const filtered = studiesList.filter((study) => study.propertyType === type);
  return filtered;
}

export const multifamily = filterList('Multifamily');
export const nursingHome = filterList('Nursing Home');
export const office = filterList('Office');
export const retail = filterList('Retail');
export const warehouse = filterList('Warehouse');
export const distributionCenter = filterList('Distribution Center');
export const storage = filterList('Storage');
export const restaurant = filterList('Restaurant');
export const autoDealer = filterList('Auto Dealership');
export const bank = filterList('Bank');
export const medical = filterList('Medical');
export const golfCourse = filterList('Golf Course');
