import styled from '@emotion/styled';

export const Root = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 60px auto;
  max-width: 1440px;
  .personQuote {
    font-family: Syne;
    font-size: 33px;
    line-height: 40px;
    color: #0318C6;
  }
  .sectionText {
    font-weight: 500;
    line-height: 28px;
    font-size: 18px;
    font-family: Ubuntu, sans-serif;
    p:last-of-type {
      margin-top: 32px;
      font-weight: 300;
    }
  }
  .mission-section, .clients-section, .expertise-section, .technology-section, .team-section, .relationships-section, .cpas-section, .madison-section {
    margin-bottom: 80px;
  }

  .mission-section h1, .expertise-section h2, .technology-section h2, .team-section h2, .relationships-section h2, .cpas-section h2, .madison-section h2 {
    color: #0318C6; 
    font-size: 84px;
    /* font-weight: 700; */
    line-height: 100px;
    font-family: Syne;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
`;
