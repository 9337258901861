/** @jsx jsx */
import { jsx } from '@emotion/core';

import TopAssets from './TopAssets';
import { Root, GraphWrap } from './twoStyles';
import { PlusThree, PlusBunch } from './illustrations';
import Graph from './Graph';

export default function SectionTwo({ study }) {
  return (
    <div css={{ backgroundColor: '#fbfbfb' }}>
      <Root>
        <GraphSection study={study} />
        <TopAssets assets={study.topAssets} />
        <PlusThree className='plus-three' />
      </Root>
    </div>
  );
}

function GraphSection({ study }) {
  return (
    <GraphWrap>
      <div className='more-section-header'>Before & After</div>
      <div className='comp-graph'>
        <Graph study={study} />
      </div>
    </GraphWrap>
  );
}
