/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import { Fragment } from 'react';

import SectionOne from '../components/smartTour/SectionOne';
import SectionTwo from '../components/smartTour/SectionTwo';
import BlueBox from '../components/smartTour/BlueBox';
import SmartKit from '../components/smartTour/SmartKit';
import Process from '../components/smartTour/Process';
import LastSection from '../components/home/LastSection';

export const innerContent = {
  maxWidth: 768,
  margin: '0 auto',
  '@media(min-width: 1024px)': { maxWidth: 1024 },
  '@media(min-width: 1440px)': { maxWidth: 1440 },
  '@media(min-width: 1920px)': { maxWidth: 1920 },
};

export default function SmartTour() {
  return (
    <Fragment>
      <Global
        styles={css`
          .smart-tour-wrap {
            display: none;
          }
        `}
      />
      <div>
        <Helmet>
          <title>Madison SPECS | Smart Tour</title>
          <meta name='description' content='Remote Cost Segregation for Maximum Safety and Results' />
        </Helmet>
        <SectionOne />
        <div css={innerContent}>
          <SectionTwo />
          <BlueBox />
          <SmartKit />
          <Process />
        </div>
        <LastSection />
      </div>
    </Fragment>
  );
}
