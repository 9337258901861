/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const pdfWrap = {
  padding: '32px 20px',
  display: 'flex',
  justifyContent: 'center',
  img: {
    boxShadow: '0 17px 46px -7px rgba(0, 0, 0, 0.1)',
    maxWidth: 920,
    margin: '0 auto',
    width: '100%',
    height: '100%',
  },
  '@media(min-width: 768px)': {
    padding: '50px 20px',
  },
};

export default function WhyMadisonSpecs() {
  return (
    <Fragment>
      <Helmet>
        <title>Madison Specs | Why Madison Specs</title>
        <meta name='description' content='Why Choose Madison Specs' />
      </Helmet>
      <div css={pdfWrap}>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1599599165/specs/Why_Choose_SPECS-01_fhimuk.svg'
          alt=''
        />
      </div>
    </Fragment>
  );
}
