/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Motif } from '../ui/icons';
import Image from '../ui/Image';

import TopSection from '../map/TopSection';

import { mainWrap, motifStyles, mapSection, textSection, header } from './mapStyles';

export default function Map() {
  return (
    <div css={mainWrap}>
      <div css={motifStyles}>
        <Motif />
      </div>
      <div css={textSection}>
        <p css={header} className='syne'>
          Cost segregation in every single state
        </p>
        <span>
          Our in-house engineers spread across the country have performed studies on every property type and in all 50
          states. It’s that local presence, and deep expertise, that is a trademark of a Madison SPECS on-site visit and
          cost seg study.
        </span>
        <TopSection
          orStyles={{
            position: 'relative',
            top:0,
            marginTop:30,
            button:{width:'100%',},
            '@media(min-width: 768px)': { zIndex:50,display: 'flex',flexDirection:'column', input:{width:345}, button:{maxWidth:345, marginLeft:0, marginTop:16}},
            '@media(min-width: 1024px)': { flexDirection:'row', input:{width:330}, button:{marginLeft:16, marginTop:0} },
            '@media(min-width: 1260px)': { bottom: 48, marginTop:35,
              left: -74,
              zIndex: 10,
              width: 581,
              input: { width: 387, height: 64, boxShadow: '0 21px 40px -15px rgba(19, 21, 50, 0.39)' },
              button: { height: 64 },},
          }}
        />
        {/* <p>Search buildings we’ve represented in your city:</p> */}
      </div>
      <div css={mapSection}>
        <div className='fifty-states'>
          <span className='syne'>50</span>
          <p>states</p>
        </div>
        <Image image='/specs/map-graphic_oezuoi.png' />
      </div>
    </div>
  );
}
