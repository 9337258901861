/** @jsx jsx */
import { jsx } from '@emotion/core';
import LazyLoad from 'react-lazyload';

export default function Image({ image, styles, className, alt, offset, height }) {
  return (
    <LazyLoad height={height || 300} offset={offset || 500} once>
      <img
        sizes='(max-width: 1400px) 100vw, 1400px'
        srcSet={`
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_500/${image} 500w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_767/${image} 767w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1023/${image} 1023w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1259/${image} 1259w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1439/${image} 1439w,
      `}
        src={`https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/${image}`}
        alt={alt}
        css={styles}
        className={className}
      />
    </LazyLoad>
  );
}

// no lazyloading on this component
export function StandardImage({ image, styles, className, alt, loading }) {
  return (
    <img
      sizes='(max-width: 1400px) 100vw, 1400px'
      srcSet={`
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_500/${image} 500w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_767/${image} 767w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1023/${image} 1023w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1259/${image} 1259w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1439/${image} 1439w,
      `}
      src={`https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/${image}`}
      alt={alt}
      css={styles}
      className={className}
      loading={loading}
    />
  );
}

export function PlainImage({ image, styles, alt, offset, height }) {
  return (
    <LazyLoad height={height || 300} offset={offset || 500} once>
      <img
        sizes='(max-width: 1400px) 100vw, 1400px'
        srcSet={`
        ${image} 500w,
        ${image} 767w,
        ${image} 1023w,
        ${image} 1259w,
        ${image} 1439w,
      `}
        src={image}
        alt={alt}
        css={[{ width: '100%' }, styles]}
      />
    </LazyLoad>
  );
}
