/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { fadeIn, fadeOut } from './fade';
import { BackgroundImage, PlusIconFiveStyles, bubble, PageWrap } from './allStepsStyles';
import { FeasibilityAnalysis } from './svg/largeIllustrations';
import { NumeroTwo } from './svg/NumberCircle';
import { PlusIcon5 } from './svg/plusIcons';
import { StandardImage } from '../ui/Image.jsx';

import Step from './Step';

export default function StepOne({ refTwo }) {
  const sectionRefTwo = useRef(null);

  const intersection = useIntersection(sectionRefTwo, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  intersection && intersection.intersectionRatio > 0.4 ? fadeIn('.fadeInTwo') : fadeOut('.fadeInTwo');

  return (
    <PageWrap ref={sectionRefTwo}>
      <BackgroundImage>
        <StandardImage className='fadeInTwo' image='specs/background2_qbjqxl.png' alt='' loading='lazy' />
      </BackgroundImage>
      <div css={bubble} />
      <Step
        number={<NumeroTwo />}
        currentRef={refTwo}
        illustraion={<FeasibilityAnalysis />}
        header='Feasibility Analysis'
        subText='Using our experience over thousands of studies on every property type, we give an estimate of the savings that can be expected through a full cost segregation study. While quite accurate, we stay just conservative enough that clients usually get even better results on the final study.'
      />
      <PlusIconFiveStyles>
        <PlusIcon5 />
      </PlusIconFiveStyles>
    </PageWrap>
  );
}
