import React, { useState, useEffect } from 'react';

import { Lightbulb, ThumbsUp, AwardIcon } from './icons';
import { blue, green, yellow } from './questionsList';
import {
  Root,
  QuestionListWrap,
  InnerWrap,
  SelectedAnswer,
  AnswerIllustration,
  TabsWrap,
  Tabber,
  Responsive,
} from './categoryDesktopStyles';

export default function CategoriesSections() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [storedSelectedCategory, setStoredSelectedCategory] = useState(selectedCategory);

  const handleTabClick = (index) => {
    setSelectedCategory(index);
    setStoredSelectedCategory(index);
  };

  const categoryData = [
    {
      title: (
        <>
          I'm new to
          <br />
          cost segregation
        </>
      ),
      headerTitle: 'newbie',
      icon: <Lightbulb />,
      questionList: blue,
      className: 'blue',
    },
    {
      title: "I've done this once before",
      headerTitle: 'novice',
      icon: <ThumbsUp />,
      questionList: green,
      className: 'green',
    },
    {
      title: 'I’m well-versed in cost segregation',
      headerTitle: 'expert',
      icon: <AwardIcon />,
      questionList: yellow,
      className: 'orange',
    },
  ];

  return (
    <>
      <Responsive id='tabsToDesktop'>
        <TabsWrap selectedIndex={storedSelectedCategory}>
          {categoryData.map((category, index) => (
            <Tabber
              key={index}
              index={index}
              selectedCategory={selectedCategory}
              onClick={() => handleTabClick(index)}
              className='syne'
            >
              {category.headerTitle}
            </Tabber>
          ))}
        </TabsWrap>

        <CategorySection
          questionList={categoryData[selectedCategory].questionList}
          className={categoryData[selectedCategory].className}
          title={categoryData[selectedCategory].title}
          icon={categoryData[selectedCategory].icon}
        />
      </Responsive>
    </>
  );
}

function CategorySection({ title, icon, questionList, styles, className, selected }) {
  const [selectedQuestion, setSelectedQuestion] = useState(questionList[0]);

  useEffect(() => {
    setSelectedQuestion(questionList[0]);
  }, [questionList]);

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
  };

  return (
    <Root style={styles} tabIndex='0' selected={selected}>
      <div className='cat_title'>{title}</div>
      <InnerWrap className={className}>
        <QuestionListWrap>
          {questionList.map((question) => (
            <li
              key={question.id}
              onClick={() => handleQuestionClick(question)}
              className={
                question === selectedQuestion ? 'active question-list-item' : 'question-list-item'
              }
              tabIndex={1}
            >
              {question.svg}
              <div>{question.title}</div>
            </li>
          ))}
        </QuestionListWrap>
        {selectedQuestion && (
          <div style={{ position: 'relative', width: 'calc(100% - 320px)' }}>
            <SelectedAnswer className='answer_override'>
              <div>{selectedQuestion.title}</div>
              <p>{selectedQuestion.description}</p>
            </SelectedAnswer>
          </div>
        )}
        {selectedQuestion && (
          <AnswerIllustration
            src={`https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/glossary/${selectedQuestion.image}.png`}
            alt=''
          />
        )}
      </InnerWrap>
    </Root>
  );
}
