export const innerWrap = {
  backgroundColor: '#fff',
  boxShadow: '0 21px 73px -6px rgba(19, 21, 50, 0.09)',
  margin: '0 auto',
  padding: '84px 0 40px 32px',
  position: 'relative',
  width: 327,
  zIndex: 100,
  '@media(min-width: 768px)': {
    marginLeft: 94,
  },
  '@media(min-width: 1024px)': {
    marginLeft: 70,
  },
  '@media(min-width: 1260px)': {
    padding: '65px 0 25px 32px',
    width: 445,
  },
};
export const wrapper = {
  position: 'relative',
  zIndex: '75',
  '@media(min-width: 768px)': {
    marginTop: 300,
  },
  '@media(min-width: 1024px)': {
    marginTop: 87,
  },
  '@media(min-width: 1260px)': {
    marginTop: 118,
  },
};

export const testimonialOverrides = {
  position: 'relative',
  bottom: 160,
  zIndex: '100',
  '@media(min-width: 1023px)': { display: 'none' },
};

export const quote = {
  fontSize: 18,
  lineHeight: 1.56,
  maxWidth: 275,
  '@media(min-width: 1260px)': {
    maxWidth: 350,
  },
};
export const imageStyles = {
  borderRadius: '50%',
  height: 104,
  left: 32,
  overflow: 'hidden',
  position: 'absolute',
  top: -50,
  width: 104,
  zIndex: 50,
  '@media(min-width: 1260px)': {
    left: 40,
  },
};
export const smallLine = {
  borderTop: '1px solid #d8d8d8',
  left: -32,
  position: 'absolute',
  top: 16,
  width: 16,
};
export const bigLine = {
  borderTop: '1px solid #d8d8d8',
  position: 'absolute',
  right: 0,
  top: 16,
  width: 119,
  '@media(min-width: 1260px)': {
    width: 220,
  },
};
export const logoStyles = {
  marginTop: 16,
  maxWidth: 264,
};
export const textWrap = {
  fontSize: 24,
  marginBottom: 42,
  maxWidth: 237,
  '@media(min-width: 768px)': {
    display: 'flex',
    fontSize: 32,
    maxWidth: 559,
  },
  '@media(min-width: 1024px)': {
    marginTop: 218,
  },
  '@media(min-width: 1260px)': {
    fontSize: 48,
    marginTop: 131,
    maxWidth: 563,
  },
};
export const iconStyles = {
  alignItems: 'center',
  display: 'flex',
  fontSize: 18,
};
export const circleStyles = {
  backgroundColor: '##D8D8D8',
  height: 857,
  position: 'absolute',
  top: 150,
  width: 375,
  zIndex: 55,
};
export const outerOffices = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 90,
  '@media(min-width: 768px)': {
    marginLeft: 32,
    marginTop: 16,
  },
  '@media(min-width: 1024px)': {
    marginBottom: 40,
  },
  '@media(min-width: 1260px)': {
    marginLeft: 170,
    marginTop: 0,
    maxWidth: 730,
    zIndex: 60,
  },
};
export const offices = {
  fontSize: 18,
  fontWeight: 'bold',
  maxWidth: 144,
  '@media(min-width: 1260px)': {
    fontSize: 24,
    maxWidth: 239,
  },
};
export const mapStyles = {
  bottom: 65,
  position: 'relative',
  zIndex: 202,
  '@media(min-width: 768px)': {
    bottom: 100,
    left: -252,
    maxWidth: 600,
    position: 'absolute',
    width: '100%',
    zIndex: 15555,
  },
  '@media(min-width: 1024px)': {
    bottom: 24,
  },
  '@media(min-width: 1260px)': {
    left: 37,
    zIndex: 60,
  },
  '@media(min-width: 1440px)': { bottom: 40, maxWidth: 797 },
};

export const branchWrap = {
  marginTop: 40,
  '@media(min-width: 1024px)': { marginRight: 29 },
};

export const addressStyles = {
  width: 160,
  marginTop: 16,
  lineHeight: 2,
  '@media(min-width: 768px)': {
    width: 176,
  },
  '@media(min-width: 1260px)': { fontSize: 16 },
};

export const lovetohearMessage = {
  position: 'relative',
  top: -60,
  padding: '0px 24px',
  zIndex: '68',
  '@media(min-width: 768px)': { paddingLeft: 94 },
  '@media(min-width: 1024px)': { top: 0 },
  '@media(min-width: 1440px)': { paddingLeft: 415, top: 0 },
};

export const contactDetailStyles = {
  '@media(min-width: 768px)': { display: 'flex', alignItems: 'flex-start' },
  '@media(min-width: 1260px)': { marginTop: 92 },
};

export const goodCompany = {
  lineHeight: 2.29,
  marginTop: 32,
  '@media(min-width: 1260px)': {
    marginTop: 28,
  },
};

export const clientImagesWrap = {
  img: { marginRight: 40, filter: 'grayscale(100%)', maxWidth: 55, opacity: 0.5, paddingTop: 10 },
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
};
