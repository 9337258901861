/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { PlainImage } from '../ui/Image';
import { TaxReturn } from './large-illustrations';
import { UnitedStatesMap } from './large-illustrations';
import { Wrapper, HeaderOne, ParagraphOne, HeaderTwo, InnerWrap, InnerWrapTwo, imgWrap } from './sectionTwoStyles';

export default function SectionTwo() {
  return (
    <Wrapper>
      <InnerWrap>
        <HeaderOne className='syne'>
          Engineering
          <br /> Expertise
        </HeaderOne>
        <div css={imgWrap}>
          <TaxReturn />
        </div>
        <ParagraphOne>
          <div>
            The Smart Tour is performed by a qualified SPECS off-site engineer who, with the assistance of an on-site
            employee, utilizes video and digital equipment to capture the nuances of the property’s components.
          </div>
          <div>
            Remotely, the engineer collects and provides all the critical information needed to achieve the maximum
            results. This avoids the need to enter and move around the property, creating close contact with employees
            and tenants in the process.
          </div>
        </ParagraphOne>
      </InnerWrap>
      <InnerWrapTwo>
        <HeaderTwo className='syne'>
          A Progressive
          <br /> Process
        </HeaderTwo>
        <div css={{ gridArea: 'b' }}>
          <UnitedStatesMap />
        </div>
        <span css={{ gridArea: 'c' }}>
          Every Smart Study is the direct product of the years of collective cost seg experience of the 60+ in-house
          Madison SPECS professionals.
        </span>
        <div css={{ gridArea: 'd' }}>
          Having walked through hundreds of every property type gives them an inner perspective to capturing every
          possible depreciable asset that can be accelerated. This all-encompassing knowledge allows for a foolproof
          process, whether in person or remotely.
        </div>
      </InnerWrapTwo>
    </Wrapper>
  );
}
