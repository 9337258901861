import styled from '@emotion/styled';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 0.1s;
  z-index: 50;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const OuterBox = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 21px 45px -9px rgba(19, 21, 50, 0.09);
  overflow: hidden;
  margin-top: 32px;
  width: 327px;
  :first-of-type {
    margin-top: -50px;
  }
  @media (min-width: 768px) {
    margin-left: 16px;
    :first-of-type {
      margin-top: -180px;
      margin-left: 0;
    }
    :nth-of-type(2) {
      margin-top: -50px;
    }
  }
  @media (max-width: 1023px) {
    :last-of-type {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    margin-left: 40px;
    width: 278px;
    :first-of-type {
      margin-top: -290px;
    }
    :last-of-type {
      margin-top: -190px;
    }
    :nth-of-type(2) {
      margin-top: -90px;
    }
  }
  @media (min-width: 1440px) {
    margin-left: 40px;
    width: 322px;
    :first-of-type {
      margin-top: -250px;
    }
    :last-of-type {
      margin-top: -160px;
    }
    :nth-of-type(2) {
      margin-top: -65px;
      left: 25%;
    }
  }
  @media (min-width: 1920px) {
    margin-left: 40px;
    width: 338px;
    :first-of-type {
      margin-top: -250px;
    }
    :last-of-type {
      margin-top: -160px;
    }
    :nth-of-type(2) {
      margin-top: -50px;
    }
  }
`;

export const Featured = styled.div`
  align-items: center;
  display: flex;
  padding: 16px 24px;
  span {
    color: #55dcac;
    font-weight: bold;
    padding-left: 16px;
  }
  border-bottom: 1px solid #e9e9e9;
  @media (min-width: 1440px) {
    padding: 24px 32px;
  }
`;

export const InnerBox = styled.div`
  padding: 16px 24px 85px 16px;
  @media (min-width: 1440px) {
    padding: 24px 32px 85px 24px;
  }
`;
export const description = {
  color: '#b5b5b5',
  fontSize: 10,
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
export const propertyName = {
  fontSize: 24,
  lineHeight: 1.33,
  maxWidth: 220,
  paddingTop: 8,
  '@media(min-width: 1440px)': {
    fontSize: 32,
    maxWidth: 280,
  },
};

export const titles = {
  color: '#b5b5b5',
  fontSize: 10,
  fontWeight: 'bold',
  paddingBottom: 8,
  paddingTop: 16,
  textTransform: 'uppercase',
  '@media(min-width: 1024px)': {
    paddingTop: 24,
  },
};
export const BottomPart = styled.div`
  background-color: #f3f5f6;
  background-image: linear-gradient(to bottom, #f3f5f6, var(--white));
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  @media (min-width: 1024px) {
    height: 140px;
  }
`;
export const imageStyles = {
  margin: '0 auto',
  position: 'absolute',
  top: -40,
  maxWidth: 180,
  width: '100%',
  '@media(min-width: 1024px)': {
    maxWidth: 220,
  },
  '@media(min-width: 1440px)': {
    // width: 'auto',
    // height: '100%',
    // maxHeight: 200,
    maxWidth: 236,
  },
};
