/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

import CallButton from '../ui/CallButton';
import Image from '../ui/Image';
import { useEffect } from 'react';

const overlay = {
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: '1000000000',
};

const innerWrap = {
  backgroundColor: '#fff',
  borderRadius: 4,
  boxShadow: '0 17px 46px -7px rgba(0, 0, 0, 0.5);',
  maxWidth: 920,
  padding: '32px 24px',
  position: 'relative',
  textAlign: 'center',
  width: '80%',
  marginTop: 54,
  h3: {
    fontSize: 24,
    fontWeight: '300',
    margin: '64px auto 0',
    maxWidth: 350,
  },
  p: { lineHeight: 1.56, maxWidth: 528, margin: '48px auto 0' },
  '@media(min-width: 1024px)': {
    h3: {
      marginTop: 80,
      fontSize: 48,
    },
  },
  '@media(min-width: 1440px)': {
    height: 576,
    h3: {
      marginTop: 126,
      maxWidth: 350,
    },
    p: { marginTop: 56, fontSize: 18 },
  },
};

const imageWrap = {
  display: 'flex',
  justifyContent: 'center',
  left: '50%',
  maxWidth: 180,
  position: 'absolute',
  top: -100,
  transform: 'translateX(-50%)',
  width: '100%',
  zIndex: '15',
  maxHeight: 313,
  '@media(min-width: 1024px)': {
    maxWidth: 251,
    top: -140,
  },
};

const backgroundImagesWrap = {
  img: {
    position: 'absolute',
    width: '90%',
    maxWidth: 764,
    right: 0,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export default function TransitionModal({ onClick }) {
  useEffect(() => {
    setTimeout(() => {
      onClick();
    }, 5500);
  }, [onClick]);
  return (
    <div css={overlay}>
      <Global
        styles={{
          body: { overflow: 'hidden' },
          html: { overflow: 'hidden' },
        }}
      />
      <div css={innerWrap}>
        <div css={imageWrap}>
          <Image image='specs/modal-icon_d3evnx.svg' styles={{ maxWidth: 250, width: '100%' }} />
        </div>
        <div css={backgroundImagesWrap}>
          <Image image='specs/oval_hjwsxt.svg' styles={{ maxWidth: 764 }} />
          <Image image='specs/plus-group_olm4mp.svg' styles={{ maxWidth: 764 }} />
        </div>
        <div css={{ position: 'relative', zIndex: '20' }}>
          <h3 className='bold syne'>We're almost done……</h3>
          <p className='mt3'>
            Please excuse the redirect to our old site as we continue to upgrade the full user experience and all pages
            at our new website. Check back soon to see it live.
          </p>
          <CallButton
            onClick={onClick}
            text='Proceed'
            styles={{
              minWidth: 0,
              margin: '40px auto 0',
              '@media(min-width: 768px)': { margin: '56px auto 0', height: 64 },
            }}
          />
        </div>
      </div>
    </div>
  );
}
