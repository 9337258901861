/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Motif } from '../ui/icons';
import { wrapS, outerWrapper, motifWrap, imageWrap, motifWrap2, buttonStyles } from './sectionOneStyles';
import { mainWrap } from '../ui/CallButton';
import { PlainImage } from '../ui/Image';
import mainIlustration from '../ui/svg/main-illustration.svg';

export default function SectionOne({ styles, noButton }) {
  return (
    <div css={[wrapS, styles]}>
      <div css={outerWrapper}>
        <div css={motifWrap}>
          <Motif />
        </div>
        <h1 className='syne'>Reduce this year’s tax burden with Cost Segregation</h1>
        <p>A powerful income tax strategy for real estate owners</p>
        {!noButton && (
          <a css={[mainWrap, buttonStyles]} href='#explanation-section'>
            Learn More
          </a>
        )}
        <div css={imageWrap}>
          <PlainImage image={mainIlustration} alt='' />
        </div>
        <div css={motifWrap2}>
          <Motif />
        </div>
      </div>
    </div>
  );
}
