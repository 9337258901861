import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export default function FormCompleted() {
  return (
    <Root>
      <CircleCheckIcon className="circle_icon" />
      <div>
        Thank you for completing the
        <br />
        3115 questionnaire.
      </div>
      <div>Your submission has been received</div>
      <NavLink to="/">
        <div className="homeward">
          <span>Back to Home</span>
          <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.2285 5.38681L7.86627 5.38681L5.83382 7.82598C5.63578 8.06318 5.66823 8.41619 5.90543 8.61367C6.14319 8.81171 6.49564 8.77926 6.69312 8.54206L9.49033 5.18542C9.51214 5.15912 9.52333 5.12835 9.53956 5.09926C9.55298 5.07576 9.56921 5.05562 9.57928 5.02989C9.60445 4.96556 9.61956 4.89786 9.61956 4.82961C9.61956 4.82905 9.62012 4.82793 9.62012 4.82737C9.62012 4.82681 9.61956 4.82569 9.61956 4.82514C9.61956 4.75688 9.60445 4.68919 9.57928 4.62486C9.56921 4.59912 9.55298 4.57898 9.53956 4.55548C9.52333 4.52639 9.51214 4.49562 9.49033 4.46933L6.69312 1.11269C6.58235 0.980099 6.42347 0.911287 6.26347 0.911287C6.13704 0.911287 6.01005 0.953805 5.90543 1.04108C5.66823 1.23856 5.63578 1.59157 5.83382 1.82877L7.86627 4.26793L1.2285 4.26793C0.919694 4.26793 0.669064 4.51856 0.669064 4.82737C0.669064 5.13619 0.919694 5.38681 1.2285 5.38681Z"
              fill="#56DBAC"
            />
          </svg>
        </div>
      </NavLink>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  max-width: 1124px;
  border-radius: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 24px 140px;
  > div {
    font-size: 22px;
    line-height: 22px;
    position: relative;
    z-index: 12;
    font-weight: 500;
    :first-of-type {
      font-family: 'Syne';
      font-style: normal;
      font-weight: 300;
      font-size: 39.3514px;
      line-height: 39px;
      color: #000000;
      margin-bottom: 54px;
      text-align: center;
    }
  }
  .circle_icon {
    position: relative;
    bottom: -36px;
  }
  .homeward {
    align-items: center;
    border-radius: 37.9021px;
    border: 1.2634px solid #56dbac;
    color: #56dbac;
    cursor: pointer;
    display: flex;
    font-size: 15.7925px;
    font-weight: 400;
    height: 56px;
    justify-content: center;
    line-height: 25px;
    margin-top: 116px;
    padding: 0px 24px;
    transition: all 0.15s ease-in-out;
    span {
      margin-right: 16px;
    }
    :hover {
      border-color: #44dbac;
      color: #44dbac;
    }
  }
`;

function CircleCheckIcon({ className }) {
  return (
    <svg
      width="225"
      height="225"
      viewBox="0 0 225 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_143_674)">
        <path
          d="M202.501 110.741C202.318 107.847 202.114 104.96 201.953 102.066C201.594 95.6321 206.163 90.4764 212.594 90.0269C218.407 89.6265 223.658 94.1851 224.304 100.212C230.609 158.701 191.107 212.076 133.407 223.012C71.7293 234.7 12.5747 193.631 1.687 131.574C-8.23772 74.9674 26.4777 19.9062 82.1601 4.34104C109.559 -3.32215 136.212 -0.772435 161.895 11.4985C166.541 13.7181 169.163 18.1362 168.72 22.6737C168.249 27.5132 165.178 31.3062 160.574 32.6267C157.467 33.5188 154.543 32.9358 151.654 31.545C141.181 26.5088 130.146 23.5798 118.52 22.8282C80.2904 20.3558 45.0057 41.8844 29.7953 77.0184C7.90751 127.57 36.9858 186.375 90.4822 199.742C141.547 212.497 192.133 179.534 201.13 127.521C202.086 122.007 202.304 116.374 202.866 110.797C202.747 110.783 202.62 110.769 202.501 110.755V110.741Z"
          fill="#56DBAC"
        />
        <path
          d="M112.348 119.766C113.642 118.235 114.26 117.371 115.005 116.627C144.934 86.6902 174.891 56.775 204.799 26.8176C207.343 24.2749 210.183 22.4838 213.817 22.5891C218.392 22.7226 221.921 24.8298 223.868 29.0653C225.794 33.2586 225.246 37.2693 222.413 40.9288C221.879 41.6172 221.232 42.2283 220.607 42.8464C187.564 75.8803 154.5 108.9 121.479 141.962C117.964 145.474 114 147.314 109.143 145.629C107.238 144.968 105.39 143.676 103.935 142.243C93.0543 131.51 82.272 120.686 71.4758 109.869C66.5275 104.91 66.2393 98.0199 70.7307 93.4333C75.3697 88.6991 82.251 88.9028 87.3609 93.9882C95.0224 101.609 102.656 109.244 110.275 116.907C110.95 117.582 111.414 118.46 112.355 119.773L112.348 119.766Z"
          fill="#56DBAC"
        />
      </g>
      <defs>
        <clipPath id="clip0_143_674">
          <rect width="225" height="225" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
