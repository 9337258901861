import styled from '@emotion/styled';

export const Root = styled.div({
  backgroundColor: '#f2f2f2',
  color: '#000',
  margin: '50px 24px 0',
  padding: '0 20px',
  paddingTop: 60,
  position: 'relative',
  textAlign: 'center',
  '& .section-header__title': {
    color: '#000',
    left: '50%',
    position: 'absolute',
    top: -16,
    transform: 'translateX(-50%)',
    width: 200,
    zIndex: '5',
    '@media(min-width: 1024px)': {
      width: 314,
      top: -22,
    },
    '@media(min-width: 1260px)': {
      fontSize: 48,
      width: 340,
      top: -28,
    },
  },
  '@media(min-width: 768px)': {
    maxWidth: 768,
    paddingLeft: 32,
    height: 930,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 1024,
    margin: '72px 56px 0px',
    paddingLeft: 40,
    height: 1020,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1260,
    margin: '120px 80px 0px',
    paddingLeft: 80,
    height: 1080,
  },
  '@media(min-width: 1440px)': {
    height: 1320,
    maxWidth: 1640,
    paddingLeft: 0,
    marginTop: 200,
  },
  '@media(min-width: 1920px)': {
    maxWidth: 1920,
  },
});

export const gradient = {
  backgroundImage: 'linear-gradient(63deg, rgba(229, 229, 250, 0) 63%, #c6c6f3 116%)',
  height: '100%',
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
};

export const motifS = {
  height: 148,
  position: 'absolute',
  right: 16,
  top: 0,
  width: 147,
  '@media(min-width: 1024px)': {
    right: 65,
    path: { fillOpacity: '1' },
    svg: {
      height: 168,
      width: 184,
      '@media(min-width: 1440px)': {
        height: 358,
        width: 442,
      },
    },
  },
  '@media(min-width: 1440px)': {
    height: 358,
    right: 50,
    width: 442,
  },
};
export const header = {
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'center',
  },
};
export const subHeader = {
  color: '#000',
  fontSize: 18,
  lineHeight: 1.56,
  marginBottom: 20,
  marginTop: 20,
  position: 'relative',
  zIndex: '1',
  '@media(min-width: 768px)': {
    maxWidth: 484,
  },
  '@media(min-width: 1024px)': {
    fontSize: 20,
    lineHeight: 1.4,
  },
  '@media(min-width: 1260px)': {
    fontSize: 24,
    lineHeight: 1.5,
    marginTop: 50,
    maxWidth: 700,
  },
  '@media(min-width: 1440px)': {
    fontSize: 32,
    marginTop: 64,
    maxWidth: 881,
  },
};

export const BulletsWrap = styled.div({
  position: 'relative',
  '@media(min-width: 768px)': {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 768,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 1024,
  },
  '@media(min-width: 1260px)': {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1260,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 1280,
  },
  '@media(min-width: 1920px)': {
    margin: '0 auto',
    maxWidth: 1600,
  },
});

export const BulletWrap = styled.div({
  fontSize: 18,
  fontWeight: 900,
  lineHeight: 1.56,
  marginTop: 40,
  '@media(min-width: 768px)': {
    alignItems: 'center',
    display: 'flex',
    marginRight: 28,
    ':nth-of-type(even)': {
      p: {
        width: 192,
        '@media(min-width: 1024px)': { width: 240 },
        '@media(min-width: 1260px)': { width: 270 },
        '@media(min-width: 1440px)': { width: 350 },
      },
    },
    '@media(min-width: 1440px)': { marginTop: 80 },
  },
  '@media(min-width: 1024px)': {
    ':nth-of-type(even)': { marginLeft: 90 },
    '@media(min-width: 1260px)': {
      ':nth-of-type(even)': { marginLeft: 0 },
    },
  },
  p: {
    color: '#000',
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: 261,
    '@media(min-width: 768px)': {
      fontSize: 16,
      lineHeight: 1.75,
      marginLeft: 24,
      textAlign: 'left',
      width: 160,
    },
    '@media(min-width: 1024px)': {
      fontSize: 18,
      lineHeight: 1.56,
      marginLeft: 32,
      width: 170,
    },
    '@media(min-width: 1260px)': {
      fontSize: 20,
      width: 330,
    },
    '@media(min-width: 1440px)': {
      fontSize: 24,
      lineHeight: 1.1,
      maxWidth: 330,
    },
  },
  '> div': {
    border: '1px solid rgba(164, 186, 178, 0.36)',
    borderRadius: '50%',
    height: 96,
    margin: '0 auto 16px',
    padding: 16,
    width: 96,
    zIndex: '20',
    position: 'relative',
    '@media(min-width: 768px)': {
      margin: '0 auto',
    },
    '@media(min-width: 1024px)': {
      height: 112,
      width: 112,
    },
    '@media(min-width: 1440px)': {
      height: 144,
      padding: 24,
      width: 144,
    },
    div: {
      alignItems: 'center',
      backgroundColor: '#55dcac',
      borderRadius: '50%',
      boxShadow: '0 24px 30px -15px rgba(124, 217, 175, 0.91)',
      display: 'flex',
      height: 64,
      justifyContent: 'center',
      width: 64,
      img: { height: 32, width: 32 },
      '@media(min-width: 1024px)': {
        height: 80,
        width: 80,
      },
      '@media(min-width: 1440px)': {
        alignItems: 'center',
        display: 'flex',
        height: 96,
        justifyContent: 'center',
        width: 96,
      },
    },
  },
});

export const buttetThreePlus = {
  position: 'relative',
  zIndex: 500,
  '@media(min-width: 1024px)': { marginLeft: 90 },
  '@media(min-width: 1260px)': { marginLeft: 0 },
};

export const imageCircleWrap = {
  alignItems: 'center',
  backgroundColor: '#55dcac',
  borderRadius: '50%',
  display: 'flex',
  height: 64,
  justifyContent: 'center',
  width: 64,
  img: { height: 32, width: 32 },
};
export const innerPart = {
  '@media(min-width: 768px)': {
    display: 'flex',
    marginTop: 64,
  },
  '@media(min-width: 1024px)': {
    alignItems: 'center',
    marginTop: 30,
  },
  '@media(min-width: 1440px)': { marginLeft: 80 },
  '@media(min-width: 1920px)': { marginLeft: 280 },
};
export const textSection = {
  marginBottom: -30,
  textAlign: 'left',
  '@media(min-width: 768px)': { maxWidth: 315 },
  div: {
    color: '#000',
    fontSize: 18,
    lineHeight: 1.56,
    marginTop: 56,
    '@media(min-width: 768px)': {
      marginTop: 0,
    },
    '@media(min-width: 1024px)': {
      fontSize: 20,
      lineHeight: 1.4,
      width: 373,
    },
    '@media(min-width: 1260px)': {
      fontSize: 22,
      width: 415,
    },
    '@media(min-width: 1440px)': {
      fontSize: 24,
      width: 540,
    },
  },
  p: {
    color: '#000',
    lineHeight: 2,
    marginTop: 16,
    '@media(min-width: 768px)': {
      fontSize: 14,
    },
    '@media(min-width: 1024px)': {
      fontSize: 16,
      marginTop: 24,
      width: 360,
    },
    '@media(min-width: 1260px)': {
      fontSize: 18,
      width: 400,
    },
    '@media(min-width: 1440px)': { width: 530 },
  },
};

export const plusGreenStyles = {
  position: 'absolute',
  right: 30,
  top: -42,
  '@media(min-width: 768px)': {
    height: 145,
    top: -86,
    width: 92,
    right: 0,
  },
  '@media(min-width: 1024px)': {
    right: -92,
    top: -55,
  },
  '@media(min-width: 1440px)': {
    height: 212,
    right: -130,
    top: -110,
    width: 133,
  },
  '@media(min-width: 1920px)': {
    right: -351,
    top: 69,
  },
};
export const plus4Styles = {
  '@media(max-width: 1439px)': { display: 'none' },
  '@media(min-width: 1440px)': {
    height: 165,
    left: -60,
    position: 'absolute',
    top: -50,
    width: 83,
  },
};
export const plusGreenStyles2 = {
  '@media(max-width: 1439px)': { display: 'none' },
  '@media(min-width: 1440px)': {
    height: 152,
    position: 'absolute',
    right: -200,
    top: 90,
    width: 100,
  },
};

export const plusWrap = {
  position: 'relative',
  '& div': {
    height: 179,
    left: 24,
    position: 'absolute',
    top: -48,
    width: 279,
    '@media(min-width: 768px)': {
      left: -20,
      top: 0,
    },
  },
  '@media(min-width: 1440px)': { display: 'none' },
};
export const plus3Styles = {
  '@media(max-width: 1439px)': { display: 'none' },
  '@media(min-width: 1024px)': {
    position: 'relative',
    '& div': {
      height: 406,
      left: -80,
      position: 'absolute',
      top: -20,
      width: 632,
    },
  },
};
export const plusGreenWrap = {
  margin: 'auto',
  maxWidth: 279,
  position: 'relative',
  transform: 'translateY(98px)',
  zIndex: 1,
  '@media(min-width: 1024px)': { margin: '0 0 0 100px' },
  '@media(min-width: 1260px)': { margin: 'auto' },
};
export const imageStyles = {
  '@media(min-width: 768px)': { width: 330 },
  '@media(min-width: 1024px)': { width: 435 },
  '@media(min-width: 1440px)': { width: 480 },
};
export const plusStyles = {
  '@media(max-width: 768px)': {
    height: 188,
    left: 44,
    path: { fill: '#fff' },
    position: 'absolute',
    top: -16,
    width: 242,
    zIndex: -1,
  },
  '@media(min-width: 768px)': { display: 'none' },
};
export const plusStyles2 = {
  zIndex: -20,
  '@media(max-width: 767px)': { display: 'none' },
  '@media(min-width: 768px)': {
    bottom: -138,
    position: 'absolute',
    zIndex: 1,
    left: 34,
    maxWidth: 194,
    path: { fillOpacity: '1' },
  },
  '@media(min-width: 1024px)': {
    bottom: -125,
    left: 40,
  },
  '@media(min-width: 1440px)': {
    bottom: 0,
    left: 176,
    maxWidth: 312,
    width: '100%',
    zIndex: -50,
  },
  '@media(min-width: 1920px)': {
    maxWidth: 407,
    left: 433,
    bottom: -169,
  },
};
export const imageBulletWrap = {
  left: -12,
  position: 'absolute',
  top: 156,
  '@media(min-width: 768px)': { top: 175 },
  '@media(min-width: 1024px)': { top: 260 },
};

export const recentTaxWrap = { position: 'relative' };

export const circleDocStyles = {
  position: 'absolute',
  width: '100%',
  '@media(min-width: 768px)': {
    height: 500,
    top: -70,
    zIndex: -50,
  },
  '@media(min-width: 1024px)': { height: 540 },
  '@media(min-width: 1440px)': { height: 758 },
  '@media(min-width: 1880px)': { height: 710 },
};

export const ribbonStyles = {
  position: 'absolute',
  right: 'calc(50% - 16px)',
  top: -8,
  '@media(min-width: 768px)': {
    right: 16,
    top: -14,
  },
  '@media(min-width: 1260px)': { display: 'none' },
};
export const bigRibbonStyles = {
  '@media(max-width: 1259px)': { display: 'none' },
  '@media(min-width: 1260px)': {
    position: 'absolute',
    right: 24,
    top: -20,
  },
};
export const greenPlus2Styles = {
  left: 'calc(50% - 30px)',
  marginTop: -43,
  position: 'absolute',
  '@media(min-width: 768px)': { display: 'none' },
};

export const recentTaxInfo = {
  fontSize: 18,
  lineHeight: 1.56,
  marginBottom: 42,
  '@media(min-width: 768px)': {
    marginBottom: 32,
    maxWidth: 480,
  },
  '@media(min-width: 1024px)': {
    fontSize: 20,
    lineHeight: 1.4,
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
    lineHeight: 1.6,
    maxWidth: 570,
  },
  '@media(min-width: 1440px)': {
    fontSize: 24,
    maxWidth: 588,
  },
};
export const greenFourStyles = {
  '@media(max-width: 767px)': { display: 'none' },
  '@media(min-width: 768px)': {
    left: 22,
    position: 'absolute',
    top: 50,
    zIndex: 1,
  },
  '@media(min-width: 1024px)': { left: 113 },
  '@media(min-width: 1440px)': {
    left: 200,
    top: 90,
  },
  '@media(min-width: 1920px)': {
    height: 219,
    left: 500,
    width: 134,
  },
};
export const recentTaxInfoWrap = {
  backgroundColor: '#fff',
  boxShadow: '0 21px 73px -6px rgba(19, 21, 50, 0.09)',
  padding: '88px 24px 52px 24px;',
  position: 'relative',
  '@media(min-width: 768px)': {
    margin: '0 auto',
    padding: '64px 48px 72px 48px',
    width: 576,
  },
  '@media(min-width: 1024px)': { width: 656 },
  '@media(min-width: 1260px)': {
    padding: 72,
    width: 732,
  },
};

export const bonusInfo = {
  color: '#000',
  fontSize: 14,
  lineHeight: 2,
  '@media(min-width: 1024px)': {
    fontSize: 16,
  },
  '@media(min-width: 1260px)': {
    fontSize: 18,
    lineHeight: 1.7,
    width: 600,
  },
};

export const outerWrap = {
  padding: '0 24px',
  position: 'relative',
};

export const innerWrap = {
  padding: '147px 0px',
  position: 'relative',
  zIndex: 5000,
  '@media(min-width: 1440px)': {
    paddingTop: 227,
  },
};
