/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import SectionOne from "../components/about/SectionOne";
import WhyDoCS from "../components/about/WhyDoCS";
import WhoCanPerform from "../components/about/WhoCanPerform";
import WhenToDo from "../components/about/WhenToDo";
import WhatType from "../components/about/WhatType";
import LastSection from "../components/home/LastSection";
import { Motif, Motif2, MotifThree } from "../components/ui/icons";
import { CirclesTwo } from "../components/ui/svg/about-us/icons";

import {
  topBar,
  motifStyles,
  mainWrap,
  circlesImageTwoS,
  motifWrap,
} from "./aboutUsStyles";

const TopBar = () => {
  return (
    <div css={topBar}>
      <span>
        <Motif />
      </span>
      <div css={motifStyles}>
        <Motif2 />
      </div>
    </div>
  );
};

export default function AboutUs() {
  return (
    <Fragment>
      <Helmet>
        <title>Madison SPECS | About Cost Seg</title>
        <meta
          name="description"
          content="The Why, Who, When and What of Cost Segregation"
        />
      </Helmet>
      <TopBar />
      <div css={mainWrap}>
        <CirclesTwo css={circlesImageTwoS} />
        <div css={motifWrap}>
          <MotifThree />
        </div>
        <SectionOne />
        <WhyDoCS />
        <WhoCanPerform />
        <WhenToDo />
        <WhatType />
      </div>
      <div css={{ "@media(min-width: 768px)": { marginTop: 80 } }}>
        <LastSection />
      </div>
    </Fragment>
  );
}
