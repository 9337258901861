import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-color: #f2f2f2;
  background-image: linear-gradient(19deg, rgba(229, 229, 250, 0) 13%, #c6c6f3 123%);
  @media (min-width: 768px) {
    background-image: linear-gradient(38deg, rgba(229, 229, 250, 0) 49%, #c6c6f3 131%);
  }
  @media (min-width: 1440px) {
    background-image: linear-gradient(55deg, rgba(229, 229, 250, 0) 72%, #c6c6f3 135%);
  }
  h1 {
    font-size: 40px;
    @media (min-width: 768px) {
      max-width: 480px;
    }
    @media (min-width: 1024px) {
      font-size: 49px;
      max-width: 700px;
    }
    @media (min-width: 1440px) {
      font-size: 64px;
      max-width: 750px;
    }
  }
  p {
    font-size: 18px;
    line-height: 1.56;
    max-width: 248px;
    padding-top: 15px;
    @media (min-width: 768px) {
      max-width: 305px;
    }
    @media (min-width: 1024px) {
      font-size: 20px;
      line-height: 1.85;
      max-width: 333px;
      padding-top: 30px;
    }
    @media (min-width: 1440px) {
      font-size: 24px;
      max-width: 640px;
    }
  }
`;

export const InnerWrap = styled.div`
  height: 552px;
  margin: 0 auto;
  max-width: 680px;
  padding: 74px 20px 0 39px;
  position: relative;
  z-index: 5000;
  @media (min-width: 768px) {
    padding-bottom: 50px;
    padding-top: 50px;
    max-width: 768px;
    height: 354px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
    padding: 77px 0 90px 94px;
    height: 520px;
  }
  @media (min-width: 1440px) {
    max-width: 1440px;
    height: 510px;
    padding: 120px 100px 100px 150px;
  }
  @media (min-width: 1920px) {
    height: 600px;
    max-width: 1920px;
    padding: 171px 0 110px 212px;
  }
`;

export const imageStyles = {
  margin: 'auto',
  maxWidth: 400,
  width: '100%',
  '@media(min-width: 768px)': {
    height: 248,
    margin: 0,
    position: 'absolute',
    right: 34,
    top: 159,
    maxWidth: 365,
  },
  '@media(min-width: 1024px)': {
    height: 365,
    top: 215,
    maxWidth: 536,
  },
  '@media(min-width: 1440px)': {
    right: 60,
  },
  '@media(min-width: 1920px)': {
    height: 540,
    top: 120,
    maxWidth: 793,
    right: 100,
  },
};
export const MotifSmall = styled.div`
  position: absolute;
  right: -150px;
  top: -60px;
  @media (min-width: 768px) {
    right: 0;
    top: -64px;
  }
  @media (min-width: 1024px) {
    right: -80px;
    top: -44px;
  }
  @media (min-width: 1440px) {
    right: 53px;
    width: 398px;
    top: -84px;
  }
`;
export const MotifStyles = styled.div`
  bottom: -28px;
  left: -10px;
  position: absolute;
  z-index: -2;
  path {
    fill-opacity: 1;
  }
  svg {
    transform: rotate(180deg);
  }
  @media (min-width: 768px) {
    left: 15px;
    bottom: -54px;
  }
  @media (min-width: 1024px) {
    left: 37px;
    bottom: -64px;
    width: 452px;
  }
  @media (min-width: 1440px) {
    left: 85px;
    bottom: -50px;
    width: 600px;
  }
  @media (min-width: 1920px) {
    left: 119px;
    bottom: -90px;
    width: 708px;
  }
`;
