/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Component } from 'react';
import { Collapse } from 'react-collapse';

import {
  Root,
  OuterTopic,
  iconStyles,
  QuestionStyles,
  QuestionWrap,
  ParStyles,
  ClosedQuestion,
  CollapseOpen,
  MotifWrap,
  plusMinus,
  openIcon,
  InnerWrap
} from './topicNavigationMobileStyles';
import {
  cSBasics,
  cSPricing,
  cSAccountants,
  cSTaxQuestions
} from './questionsList';
import {
  DownCaret,
  CoinsIconFaq,
  RefundIcon,
  PeopleIcon,
  PaperIcon,
  MinusIcon,
  BlueMotif
} from '../ui/icons';

export default class TopicNavigationMobile extends Component {
  state = {
    topicExpandedStates: [false, false, false, false]
  };

  componentDidUpdate = prevProps => {
    if (prevProps.topicOpen !== this.props.topicOpen) {
      const { topicExpandedStates } = this.state;
      this.setState({
        topicExpandedStates: topicExpandedStates.map(_ => false)
      });
    }
  };

  toggleTopicExpansion = index => {
    let { topicExpandedStates } = this.state;
    if (topicExpandedStates[index]) {
      //if one being clicked is already open, set all to false so that it gets closed
      topicExpandedStates = topicExpandedStates.map(_ => false);
    } else {
      //if a different one is being clicked, set only THAT one to be true so that all others close
      topicExpandedStates = this.state.topicExpandedStates.map(
        (_, i) => i === index
      );
    }
    this.setState({ topicExpandedStates });
  };
  render() {
    const [
      oneExpanded,
      twoExpanded,
      threeExpanded,
      fourExpanded
    ] = this.state.topicExpandedStates;
    return (
      <Root>
        <Topic
          icon={<CoinsIconFaq />}
          topic='Cost Seg Basics'
          color='#0827c7'
          isOpen={oneExpanded}
          handleOpen={() => this.toggleTopicExpansion(0)}
        >
          {cSBasics.map((i, index) => (
            <QuestionBox key={index} question={i.question} par={i.answer} />
          ))}
        </Topic>
        <Topic
          icon={<RefundIcon />}
          topic='Cost Seg Pricing and Timing'
          color='#55dcac'
          isOpen={twoExpanded}
          handleOpen={() => this.toggleTopicExpansion(1)}
        >
          {cSPricing.map((i, index) => (
            <QuestionBox key={index} question={i.question} par={i.answer} />
          ))}
        </Topic>
        <Topic
          icon={<PeopleIcon />}
          topic='Accountants and Cost Seg'
          color='#f74720'
          isOpen={threeExpanded}
          handleOpen={() => this.toggleTopicExpansion(2)}
        >
          {cSAccountants.map((i, index) => (
            <QuestionBox key={index} question={i.question} par={i.answer} />
          ))}
        </Topic>
        <Topic
          icon={<PaperIcon />}
          topic='Cost Seg and Tax Questions'
          color='#fcbd2c'
          isOpen={fourExpanded}
          handleOpen={() => this.toggleTopicExpansion(3)}
          styles={fourExpanded && { borderBottom: '1px solid #e7e7eb' }}
          headerStyles={{
            boxShadow: fourExpanded
              ? '0 8px 16px 0 rgba(223, 223, 229, 0.35)'
              : 'none'
          }}
        >
          {cSTaxQuestions.map((i, index) => (
            <QuestionBox key={index} question={i.question} par={i.answer} />
          ))}
        </Topic>
      </Root>
    );
  }
}
function Topic({
  icon,
  topic,
  color,
  handleOpen,
  isOpen,
  children,
  styles,
  headerStyles
}) {
  return (
    <div css={styles}>
      <OuterTopic onClick={handleOpen} isOpen={isOpen} css={headerStyles}>
        <InnerWrap>
          <div css={[iconStyles, { backgroundColor: color }]}>{icon}</div>
          <div css={{ fontWeight: 500, fontSize: 16 }}>{topic}</div>
        </InnerWrap>
        <DownCaret
          css={[
            { transition: 'all .2s', cursor: 'pointer' },
            isOpen && {
              transform: 'rotate(-180deg)',
              path: { ':last-of-type': { fill: '#0827c7' } }
            }
          ]}
        />
      </OuterTopic>
      <Collapse isOpened={isOpen}>
        <CollapseOpen>{children}</CollapseOpen>
      </Collapse>
    </div>
  );
}

function QuestionBox({ question, par }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      css={{
        marginBottom: 16,
        width: 'calc(100% - 64px)',
        '@media(min-width: 768px)': {
          width: 'calc(100% - 128px)'
        }
      }}
      onClick={() => setOpen(!isOpen)}
    >
      <QuestionWrap>
        <ClosedQuestion>
          <div css={[plusMinus, isOpen && openIcon]}>
            <MinusIcon />
            <MinusIcon />
          </div>
          <div css={{ marginLeft: 16, width: 'calc(100% - 16px)' }}>
            <QuestionStyles className='syne'>{question}</QuestionStyles>
          </div>
        </ClosedQuestion>
        <Collapse isOpened={isOpen}>
          {isOpen && (
            <MotifWrap>
              <BlueMotif />
            </MotifWrap>
          )}
          <ParStyles>{par}</ParStyles>
        </Collapse>
      </QuestionWrap>
    </div>
  );
}
