import styled from '@emotion/styled';

export const Root = styled.div`
  /* padding-left: 24px; */
`;

export const Header = styled.h1`
  font-size: 24px;
  text-align: center;
  margin: 0 auto 34px;
  width: 302px;
  @media (min-width: 768px) {
    margin: 62px auto 48px;
  }
  @media (min-width: 1024px) {
    font-size: 32px;
    width: 403px;
  }
`;
export const OuterStep = styled.div`
  width: 163px;
  height: 172px;
  padding: 32px 16px 32px 20px;
  font-size: 14px;
  @media (min-width: 768px) {
    height: 258px;
    width: 172px;
    padding: 50px 12px 82px 10px;
  }
  @media (min-width: 1024px) {
    height: 322px;
    width: 228px;
    padding: 72px 30px 118px 28px;
  }
  @media (min-width: 1440px) {
    height: 354px;
    width: 275px;
    padding: 88px 53px 120px 31px;
  }
  @media (min-width: 1920px) {
    height: 354px;
    width: 300px;
    padding: 88px 53px 120px 31px;
  }
  :first-of-type p {
    padding-bottom: 12px;
    @media (min-width: 768px) {
      padding-bottom: 32px;
    }
    @media (min-width: 1024px) {
      padding-bottom: 24px;
    }
  }
  p {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    padding-bottom: 25px;
    @media (min-width: 768px) {
      padding-bottom: 51px;
      line-height: 1.7;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
  .number-svg svg {
    max-height: 13px;
    width: auto;
  }
  .number-svg svg path {
    fill: #0827c7;
  }
  div {
    color: #0827c7;
    background-color: #fff;
    width: 56px;
    height: 56px;
    box-shadow: ${(props) =>
      props.borderCircle ? '0 24px 30px -15px #1029ab' : '0 24px 30px -15px rgba(124, 217, 175, 0.91)'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    ::before {
      content: '';
      border-radius: 50%;
      height: 96px;
      width: 96px;
      position: absolute;
      padding: 20px;
      border: ${(props) => props.borderCircle && '4px solid #93ffd9'};
      @media (min-width: 1024px) {
        border: ${(props) => props.borderCircle && '6px solid #93ffd9'};
      }
      @media (min-width: 1440px) {
        height: 112px;
        width: 112px;
      }
    }
    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 1440px) {
      height: 72px;
      width: 72px;
      font-size: 24px;
    }
  }
`;

export const lineStyles = {
  '@media(max-width: 767px)': {
    display: 'none',
  },
  '@media(min-width: 768px)': {
    svg: { width: 480, height: 'auto' },
    position: 'absolute',
    top: 138,
    left: 114,
  },
  '@media(min-width: 1024px)': {
    svg: { width: 670 },
    top: 170,
    left: 150,
  },
  '@media(min-width: 1440px)': {
    svg: { width: 820 },
    top: 195,
  },
  '@media(min-width: 1920px)': {
    svg: { width: 850 },
  },
};
