export const outerWrap = {
  padding: '32px 0',
  maxWidth: 1000,
  '@media(min-width: 768px)': {
    margin: 'auto',
    maxWidth: 503,
    '& .slick-slider.slick-initialized': {
      padding: '56px 0',
    },
    '& .slick-dots.slick-dots': {},
  },
  '@media(min-width: 1024px)': {
    maxWidth: 700,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 825,
    '& .slick-dots.slick-dots': {
      bottom: -65,
    },
  },
};
export const mainWrap = {
  position: 'relative',
  textAlign: 'center',
  zIndex: '5',
};

export const nextArrowStyles = {
  right: '-104px !important',
  '@media(max-width: 767px)': { display: 'none !important' },
  '@media(min-width: 1024px)': { right: '-135px !important' },
  '@media(min-width: 1440px)': { right: '-157px !important' },
  '@media(min-width: 1920px)': { right: '-207px !important' },
};
export const prevArrowStyles = {
  left: '-104px !important',
  '@media(max-width: 767px)': { display: 'none !important' },
  '@media(min-width: 1024px)': { left: '-135px !important' },
  '@media(min-width: 1440px)': { left: '-157px !important' },
  '@media(min-width: 1920px)': { left: '-207px !important' },
};

export const arrowCircleStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 64,
  height: 64,
  backgroundColor: '#f2f2f2f2',
  borderRadius: '50%',
  cursor: 'pointer',
};
