import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Root = styled.div`
  padding: 32px 24px 104px;
  background: #fbfbfb;
  position: relative;
  z-index: 5;
  .building-illus {
    margin: auto;
    display: block;
  }
  .huge-motif {
    display: none;
  }
  .sticky-header {
    position: absolute;
    bottom: 0px;
  }
  h1 {
    font-size: 40px;
    position: relative;
    z-index: 5;
  }
  @media (min-width: 768px) {
    padding: 64px 32px 48px;
    min-height: 410px;
    h1 {
      font-size: 48px;
    }
  }
  @media (min-width: 1024px) {
    padding-top: 62px;
    font-size: 56px;
    margin-top: 10px;
    min-height: 482px;
    .huge-motif {
      display: block;
      position: absolute;
      left: 26px;
      z-index: -1;
      width: 487px;
      height: auto;
      width: auto;
      top: 0px;
      overflow: hidden;
      max-height: 100%;
      svg {
        max-width: 487px;
        position: relative;
        top: 8px;
      }
    }
  }
  @media (min-width: 1440px) {
    padding: 72px 130px 118px 120px;
    h1 {
      font-size: 72px;
      margin-top: 24px;
    }
    .huge-motif {
      width: 708px;
      left: -112px;
      overflow: hidden;
      svg {
        max-width: 708px;
      }
      /* top: -51px; */
    }
  }
  @media (min-width: 1920px) {
    height: 590px;
    h1 {
      margin-top: 48px;
    }
    .huge-motif {
      left: 125px;
      bottom: -10px;
      svg {
        top: 0;
      }
    }
  }
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 100px;
  z-index: 150;
  left: 120px;
  > div {
    position: absolute;
    left: 24px;
    top: -104px;
    width: 100%;
  }
  @media (min-width: 768px) {
    top: 116px;
  }
  @media (min-width: 1024px) {
    top: 136px;
    margin-left: 8px;
  }
  @media (min-width: 1440px) {
    margin-left: 0;
    > div {
      left: 116px;
      top: -120px;
    }
  }
  @media (min-width: 1920px) {
    > div {
      left: calc(50% - 60px);
      transform: translateX(-50%);
    }
  }
`;

export const ContentWrap = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      max-width: 344px;
    }
    .building-illus {
      height: auto;
      margin: 0;
      max-width: 251px;
      position: relative;
      z-index: 6;
    }
  }
  @media (min-width: 1024px) {
    align-items: flex-start;
    > div {
      max-width: 360px;
    }
    .building-illus {
      margin-right: 122px;
      max-width: 350px;
      width: 100%;
      top: -20px;
    }
  }
  @media (min-width: 1440px) {
    > div {
      max-width: 525px;
    }
    .building-illus {
      max-width: 480px;
      margin-right: 0;
      top: -2px;
    }
  }
  @media (min-width: 1920px) {
    max-width: 1200px;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;
  }
`;

export const SubheaderText = styled.div`
  font-size: 18px;
  line-height: 40px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-right: 8px;
  position: relative;
  z-index: 5;
  @media (min-width: 768px) {
    line-height: 28px;
    margin-bottom: 24px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
    margin-top: 24px;
    padding-right: 12px;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  height: 66px;
  padding-top: 16px;
  padding-bottom: 2px;
  transition: all 0.16s;
  width: calc(100vw - 48px);
  svg {
    transform: ${(props) => props.isOpen && 'rotate(180deg)'};
  }
  .mobile-dropdown {
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 80px;
    border-radius: 8px;
    padding: 0 16px;
    overflow: hidden;
    box-shadow: 0px 21px 40px rgba(85, 110, 97, 0.388576);
    transition: all 0.15s;
    height: ${(props) => (props.isOpen ? '378px' : '0px')};
    a {
      display: flex;
      height: 32px;
      align-items: center;
      :first-of-type {
        margin-top: 16px;
      }
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DropdownButton = styled.div`
  align-items: center;
  background: #55dcac;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(124, 217, 175, 0.5);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  position: relative;
  svg {
    max-width: 16px;
    position: absolute;
    right: 24px;
  }
`;

export const TabWrap = styled.div`
  display: none;
  .tab-hide {
    display: none;
  }
  .active-nav-link {
    background-color: #55dcac;
    color: #fff;
    cursor: default;
    :hover {
      background-color: #55dcac;
      color: #fff;
    }
  }
  .more-tab {
    color: #55dcac;
    position: relative;
    :active {
      transform: none;
    }
  }
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    height: 64px;
    margin-left: 10px;
  }
  @media (min-width: 1024px) {
    margin-top: 0px;
    .tab-see {
      display: flex;
    }
    .landscape-hide {
      display: none;
    }
  }
  @media (min-width: 1440px) {
    margin-top: 0;
    .tab-desktop {
      display: flex;
    }
    .desktop-hide {
      display: none;
    }
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
    max-width: 1200px;
  }
`;

export const Tab = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 21px 40px rgb(0, 0, 0, 0.1);
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  height: 56px;
  line-height: 1em;
  margin-right: 16px;
  padding: 0 24px;
  text-transform: uppercase;
  transition: all 0.3s;
  white-space: nowrap;
  :hover {
    color: #55dcac;
    svg path {
      fill: #55dcac;
    }
  }
  :active {
    transform: scale(0.95);
  }
  @media (min-width: 1440px) {
    padding: 0 24px;
  }
`;

export const TabLink = Tab.withComponent(NavLink);

export const plusWrap = {
  display: 'none',
  '@media(min-width: 1024px)': {
    position: 'absolute',
    right: 48,
    bottom: 140,
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'space-between',
    'svg:last-of-type': {
      alignSelf: 'flex-end',
    },
  },
  '@media(min-width: 1440px)': {
    right: 20,
    bottom: 200,
  },
  '@media(min-width: 1920px)': {
    right: 210,
  },
};

export const DropDownster = styled.div`
  color: #000;
  font-size: 12px;
  overflow: hidden;
  overflow: ${(props) => (props.isOpen ? 'initial' : 'hidden')};
  position: absolute;
  right: 0px;
  top: 72px;
  transition: all 0.1s;
  height: ${(props) => (props.isOpen ? '234px' : '0px')};
  > div {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 21px 40px #e0e0e3;
    padding: 4px 0;
    white-space: nowrap;
    a {
      align-items: center;
      display: flex;
      height: 32px;
      padding: 0px 16px;
      transition: all 0.13s;
      :first-of-type {
        margin-top: 0;
      }
      :hover {
        background-color: rgba(240, 240, 240, 0.6);
      }
    }
  }
  @media (min-width: 1024px) {
    height: ${(props) => (props.isOpen ? '168px' : '0px')};
    > div div {
      padding: 0 24px;
      height: 40px;
    }
  }
  @media (min-width: 1440px) {
    height: ${(props) => (props.isOpen ? '136px' : '0px')};
  }
`;

export const MobileMotif = styled.div`
  position: absolute;
  top: 0px;
  width: 144px;
  right: 10px;
  svg {
    width: 100%;
    height: auto;
  }
  @media (min-width: 768px) {
    width: 249px;
    right: 48px;
  }
  @media (min-width: 1024px) {
    overflow: hidden;
    svg {
      position: relative;
      top: -40px;
    }
  }
  @media (min-width: 1440px) {
    width: 398px;
    right: 53px;
    svg {
      top: -96px;
    }
  }
`;

export function PlusIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='mr1'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 13.9999C4.7 13.9999 2 11.2999 2 7.99994C2 4.69994 4.7 1.99994 8 1.99994C11.3 1.99994 14 4.69994 14 7.99994C14 11.2999 11.3 13.9999 8 13.9999ZM7 6.99985V3.99985H9V6.99985H12V8.99985H9V11.9998H7V8.99985H4V6.99985H7Z'
        fill='#55DCAC'
      />
    </svg>
  );
}

export function DownCarot() {
  return (
    <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.12 0.453369L8 6.56004L1.88 0.453369L0 2.33337L8 10.3334L16 2.33337L14.12 0.453369Z'
        fill='white'
      />
    </svg>
  );
}
