/** @jsx jsx */
import { jsx } from '@emotion/core';

import { PulseIcon, DollarIcon, ChartIcon } from '../ui/icons';

import {
  outerWrap,
  iconCircle,
  pulseStyles,
  dollarStyles,
  chartStyles,
  costSegregationStyles,
  numberStyles,
  yearStyles,
} from './historyStyles';
import Image from '../ui/Image';

export default function () {
  return (
    <div css={outerWrap}>
      {/* <Circles /> */}
      <Image image='/specs/circles-graphic_xchrt6.png' />
      <div css={costSegregationStyles}>
        <div>Cost segregation studies to date</div>
        <div css={numberStyles} className='syne'>
          30,263
        </div>
      </div>
      <div css={yearStyles}>
        Years
        <div css={[numberStyles, { marginTop: 28 }]} className='syne'>
          17+
        </div>
      </div>
      <div css={[iconCircle, pulseStyles]}>
        <PulseIcon />
      </div>
      <div css={[iconCircle, dollarStyles]}>
        <DollarIcon />
      </div>
      <div css={[iconCircle, chartStyles]}>
        <ChartIcon />
      </div>
    </div>
  );
}
