/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import {
  OuterBox,
  Featured,
  InnerBox,
  description,
  propertyName,
  titles,
  imageStyles,
  Root,
  BottomPart,
} from './propertyCardsStyles';
import { AddressContext } from './Address';

import { StarIcon } from '../ui/icons';
import imageOne from '../ui/svg/map/multifamily-nursing-home.svg';
import imageTwo from '../ui/svg/map/Retail_2.svg';
import imageThree from '../ui/svg/map/self-storage.svg';

export default function MapSection() {
  const { addressSearched } = useContext(AddressContext);
  return (
    !addressSearched && (
      <Root className='prop-card-wrap'>
        <PropertyBox
          property='San Angelo TX - 174,000 SQ.FT Multifamily'
          propertyValue='$16.4 M'
          taxScales='30%'
          acceleratedDep='$4,920,000'
          image={imageOne}
        />
        <PropertyBox
          property='Bronx NY - 10,400 SQ.FT Retail'
          propertyValue='$9.6 M'
          taxScales='66%'
          acceleratedDep='$6,336,000'
          image={imageTwo}
        />
        <PropertyBox
          property='Winchester VA - 77,600 SQ.FT Self Storage'
          propertyValue='$4.2 M'
          taxScales='45%'
          acceleratedDep='$1,890,000'
          image={imageThree}
        />
      </Root>
    )
  );
}

export function PropertyBox({ property, propertyValue, taxScales, acceleratedDep, image }) {
  return (
    <OuterBox>
      <Featured>
        <StarIcon />
        <span>Featured Property</span>
      </Featured>
      <InnerBox>
        <div css={description}>Property Description</div>
        <div className='syne' css={propertyName}>
          {property}
        </div>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div css={titles}>Property value</div>
            <div css={{ fontSize: 16, fontWeight: 'bold' }}>{propertyValue}</div>
          </div>
          <div>
            <div css={titles}>Percent of Depreciable Assets</div>
            <div css={{ fontSize: 16, fontWeight: 'bold' }}>{taxScales}</div>
          </div>
        </div>
        <div css={titles}>Value of Reclassified Assets</div>
        <div css={{ fontWeight: 'bold', fontSize: 24, color: '#0827c7' }}>{acceleratedDep}</div>
      </InnerBox>
      <BottomPart>
        <img src={image} alt='' css={imageStyles} />
      </BottomPart>
    </OuterBox>
  );
}
