import styled from '@emotion/styled';

export const backgroundGradient = {
  '@media(min-width: 1440px)': {
    '&:before': {
      content: '""',
      position: 'absolute',
      // background: 'linear-gradient(21.55deg, rgba(229, 229, 250, 0.0001) 17.89%, #c6c6f3 124.43%)',
      width: '100vw',
      height: '100%',
    },
  },
};

export const maxWidth = { maxWidth: 1440, margin: '0 auto', position: 'relative' };

export const Header = styled.div`
  background: linear-gradient(21.55deg, rgba(229, 229, 250, 0.0001) 17.89%, #c6c6f3 124.43%);
  margin: 0 auto;
  max-width: 1440px;
  min-height: 304px;
  padding: 48px 24px;
  position: relative;
  .building-illus {
    bottom: -82px;
    left: 50%;
    max-height: 174px;
    width: auto;
    position: absolute;
    transform: translateX(-50%);
    display: block;
  }
  @media (min-width: 768px) {
    padding: 56px 32px;
    .building-illus {
      bottom: -14px;
      height: auto;
      left: initial;
      max-height: 200px;
      right: 142px;
      transform: initial;
    }
  }
  @media (min-width: 1024px) {
    height: 360px;
    padding-top: 72px;
    .building-illus {
      max-height: 240px;
    }
  }
  @media (min-width: 1440px) {
    background: transparent;
    height: 682px;
    padding: 56px 120px;
    .building-illus {
      max-height: 333px;
      right: initial;
      left: 232px;
      bottom: -48px;
    }
  }
`;

export const StudyNumber = styled.div`
  color: #0841cd;
  font-family: Syne;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  @media (min-width: 1440px) {
    font-size: 16px;
    line-height: 34px;
  }
`;
export const HeaderText = styled.div`
  color: #000;
  font-family: Syne;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  margin-top: 16px;
  position: relative;
  z-index: 15;
  @media (min-width: 768px) {
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 48px;
    margin-top: 40px;
    max-width: 300px;
  }
  @media (min-width: 1024px) {
    font-size: 48px;
    max-width: 380px;
  }
  @media (min-width: 1440px) {
    font-size: 64px;
    line-height: 72px;
    margin-top: 0;
    max-width: 488px;
  }
`;

export const Motif = styled.svg`
  height: auto;
  max-width: 144px;
  position: absolute;
  right: 44px;
  top: 0px;
  @media (min-width: 768px) {
    max-width: 249px;
  }
  @media (min-width: 1440px) {
    max-width: 358px;
    right: 8px;
  }
`;

export const TileSection = styled.div`
  background-color: #fff;
  font-weight: 300;
  max-width: 1440px;
  padding: 120px 24px 40px;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 56px 32px;
  }
  @media (min-width: 1440px) {
    background-color: transparent;
    max-width: 385px;
    padding: 0px;
    position: absolute;
    right: 120px;
    top: 200px;
  }
`;
export const Tile = styled.div`
  background-color: #57e5b2;
  border-radius: 8px;
  color: white;
  display: flex;
  margin-top: 12px;
  min-height: 120px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .icon-circle {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 20px 39px rgba(13, 46, 34, 0.10678);
    display: flex;
    height: 40px;
    justify-content: center;
    left: -20px;
    position: absolute;
    width: 40px;
    svg {
      max-width: 24px;
      max-height: 24px;
    }
  }
  :nth-of-type(even) {
    background-color: #203fde;
    > div {
      background-color: #0827c7;
    }
  }
  .tile-title {
    font-size: 16px;
    font-weight: normal;
    margin-top: 6px;
  }
  @media (min-width: 768px) {
    height: 160px;
    max-width: calc(50% - 8px);
    .icon-circle {
      height: 48px;
      left: -24px;
      width: 48px;
    }
    .tile-title {
      margin-top: 8px;
    }
    :nth-of-type(3n) {
      order: 4;
    }
    :nth-of-type(4n) {
      order: 2;
    }
  }
  @media (min-width: 1024px) {
    height: 168px;
    .icon-circle {
      height: 56px;
      left: -28px;
      width: 56px;
    }
    .tile-title {
      margin-top: 16px;
    }
  }
  @media (min-width: 1440px) {
    height: 160px;
    max-width: 100%;
    width: 100%;
    .icon-circle {
      height: 80px;
      left: -40px;
      width: 80px;
      svg {
        max-height: 32px;
        max-width: 32px;
      }
    }
    .tile-title {
      margin-top: 8px;
    }
    :nth-of-type(3n) {
      order: 3;
    }
    :nth-of-type(4n) {
      order: 4;
    }
  }
`;

export const amountStyles = {
  fontSize: 28,
  fontFamily: 'Syne',
  '@media(min-width: 768px)': {
    fontSize: 40,
    lineHeight: '56px',
    marginTop: -16,
  },
};

export const percentStyles = {
  fontSize: 40,
  '@media(min-width: 768px)': {
    fontSize: 48,
  },
};

export const InnerTile = styled.div`
  align-items: flex-start;
  background-color: #55dcac;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 70px;
  padding-left: 48px;
  position: relative;
  width: calc(100% - 70px);
  @media (min-width: 768px) {
    margin-left: 72px;
    br {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    margin-left: 104px;
    padding-left: 60px;
  }
  @media (min-width: 1440px) {
    margin-left: 72px;
    padding-left: 72px;
  }
`;

export const HelpCircle = styled.svg`
  max-height: 12px;
  max-width: 12px;
  position: absolute;
  right: 24px;
  top: 24px;
  @media (min-width: 768px) {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    right: 16px;
    top: 16px;
    width: 16px;
  }
`;
export const InfoBulletsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto 0;
  max-width: 1440px;
  padding: 0 28px 56px;
  > div {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    margin-top: 24px;
    width: 50%;
    :nth-of-type(even) {
      padding-left: 8px;
    }
    span:first-of-type {
      margin-right: 8px;
      width: 20px;
      svg {
        max-width: 16px;
      }
    }
  }
  @media (min-width: 768px) {
    justify-content: space-around;
    margin: 20px auto 0;
    padding: 0px 80px 36px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    > div {
      margin-top: 32px;
      width: 100%;
      font-size: 16px;
      :nth-of-type(even) {
        padding-left: 0px;
      }
      span:first-of-type {
        margin-right: 0;
        width: 40px;
        svg {
          max-width: revert;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    margin-top: 4px;
    padding-bottom: 80px;
  }
  @media (min-width: 1440px) {
    margin-left: 120px;
    max-width: 700px;
    padding: 71px 0 170px;

  }
`;
export const ThreePlusCluster = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 10;
  }
  @media (min-width: 1440px) {
    right: 600px;
    top: 230px;
  }
`;

export const TwoPlusCluster = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    left: 390px;
    position: absolute;
    top: 70px;
    z-index: 10;
  }
  @media (min-width: 1440px) {
    left: 60px;
    top: 330px;
  }
`;
