import React from 'react';
import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1440px;
  justify-content: center;
  margin: 0px auto; 
  padding: 40px 24px 60px;
  .hoverEffect {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 376px;
    overflow: hidden;
    img {
      border-radius: 10px;
      position: absolute;
      width: auto;
      height: 100%;
      max-height: 340px;
      transition: opacity 1.2s ease 0s;
      :last-of-type {
        opacity: 0;
      }
    }
    :hover {
      img {
        :first-of-type {
          opacity: 0;
        }
        :last-of-type {
          opacity: 1;
        }
      
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 60px;
    gap: 56px;
    .hoverEffect {
      min-height: 376px;
      height: auto;
    }
  }
`;
export const PersonWrap = styled.div`
  text-align: center;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  background-color:  rgba(245, 245, 245, 0.5);
  justify-content: space-between;
  padding: 16px;
  border-radius: 16px;
  div {
    font-weight: 600;
    font-size: 20px;
    margin-top: 24px;
  }
  @media (min-width: 1024px) {
    max-width: 400px;
    padding: 24px;
    
  }
`;

export default function LeadershipImages() {
  return (
    <Root>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/eli-low_j4uoji.png'
          alt=''
        />
        <div>Eli Loebenberg</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/mosh-beck_xuzxxn.png'
          alt=''
        />
        <div>Moshe Becker</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/sru-dav_m0trfd.png'
          alt=''
        />
        <div>Sruly Davidowitz</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/chuk-tov_gramca.png'
          alt=''
        />
        <div>Chuck Tover</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/tzi-waid_u3xleq.png'
          alt=''
        />
        <div>Tziviah Waidenbaum</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/ephr-bar_vei5bh.png'
          alt=''
        />
        <div>Ephraim Baruch</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/mosh-hild_cqtmrq.png'
          alt=''
        />
        <div>Moshe Hildeshaim</div>
      </PersonWrap>
      <PersonWrap>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/yit-gold_xvzu4t.png'
          alt=''
        />
        <div>Yitz Goldman</div>
      </PersonWrap>
      <PersonWrap className='hoverEffect'>
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1023/specs/selnaktata_oz4a68.jpg'
          alt='xdscsc'
        />
        <img
          src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1023/specs/selnakatata2_iq3pe8.jpg'
          alt='cdsc'
        />
      </PersonWrap>
    </Root>
  );
}
