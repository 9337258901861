import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 90px;
  }
  @media (min-width: 1440px) {
    margin-top: 110px;
  }
  @media (min-width: 1920px) {
    margin-top: 86px;
  }
`;

export const OuterWrap = styled.div`
  background-color: #55dcac;
  border-radius: 8px;
  color: #fff;
  height: 200px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 218px;
  @media (min-width: 768px) {
    margin-left: 16px;
  
    :first-of-type {
      margin-left: 0;
    }
  }
  @media (min-width: 1024px) {
    margin-left: 24px;
    width: 288px;
    height: 144px;
  }
  @media (min-width: 1440px) {
    width: 350px;
    height: 176px;
  }
  @media (min-width: 1920px) {
    width: 384px;
  }
`;

export const iconWrapper = {
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '50%',
  boxShadow: '0 20px 39px 0 rgba(13, 46, 34, 0.11)',
  display: 'flex',
  height: 56,
  justifyContent: 'center',
  left: 81,
  position: 'absolute',
  top: 16,
  width: 56,
  '@media(min-width: 1024px)': {
    width: 64,
    height: 64,
    left: 32,
    top: 40,
  },
  '@media(min-width: 1440px)': {
    height: 80,
    width: 80,
    top: 48,
    svg: {
      height: 32,
      width: 32,
    },
  },
};
export const numberStyles = {
  fontSize: 24,
  paddingTop: 44,
  '@media(min-width: 1024px)': {
    fontSize: 32,
    paddingTop: 36,
    paddingLeft: 120,
    textAlign: 'left',
  },
  '@media(min-width: 1440px)': {
    fontSize: 48,
    paddingLeft: 136,
  },
  '@media(min-width: 1920px)': {
    paddingLeft: 144,
  },
};

export const textStyles = {
  maxWidth: 143,
  lineHeight: 1.14,
  paddingTop: 8,
  margin: 'auto',
  paddingBottom: 40,
  '@media(min-width: 1024px)': {
    paddingLeft: 120,
    textAlign: 'left',
    maxWidth: 266,
    margin: 0,
  },
  '@media(min-width: 1440px)': {
    fontSize: 16,
    lineHeight: 1.5,
    maxWidth: 330,
    paddingLeft: 136,
  },
  '@media(min-width: 1920px)': {
    paddingLeft: 144,
  },
};
