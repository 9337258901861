export const mainWrap = {
  '@media(min-width: 768px)': { margin: 'auto', maxWidth: 768 },
  '@media(min-width: 1024px)': { maxWidth: 1024 },
  '@media(min-width: 1260px)': { maxWidth: 1260 },
  '@media(min-width: 1440px)': { maxWidth: 1440 },
  '@media(min-width: 1920px)': { maxWidth: 1920 },
};
export const topBar = {
  backgroundColor: '#f2f2f2',
  backgroundImage: 'linear-gradient(86deg, rgba(229, 229, 250, 0) 25%, #c6c6f3 124%)',
  display: 'flex',
  height: 110,
  justifyContent: 'flex-end',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 20,
  span: {
    height: 139,
    marginRight: 24,
    maxWidth: 185,
    width: 164,
  },
  '@media(min-width: 768px)': { height: 93 },
  '@media(min-width: 1024px)': {
    height: 115,
    span: {
      height: 190,
      maxWidth: 237,
      width: 237,
    },
  },

  '@media(min-width: 1260px)': {
    height: 164,
    span: {
      height: 230,
      maxWidth: 250,
      width: 250,
    },
  },
  '@media(min-width: 1440px)': {
    span: {
      height: 300,
      marginRight: 53,
      maxWidth: 350,
      width: 350,
    },
  },
};

export const motifStyles = {
  '@media(max-width: 1023px)': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    height: 250,
    left: 26,
    position: 'absolute',
    width: 487,
    zIndex: 5000,
  },
  '@media(min-width: 1260px)': {
    height: 250,
    left: 170,
  },
  '@media(min-width: 1440px)': {
    height: 190,
  },
};

export const circlesImageS = {
  display: 'none',
  '@media(min-width: 768px)': {
    display: 'block',
    position: 'absolute',
    right: 0,
    top: 56,
    width: 500,
    maxWidth: 500,
    height: 'auto',
  },
  '@media(min-width: 1024px)': {
    right: 0,
    top: -100,
    width: 532,
    display: 'none',
  },
  '@media(min-width: 1440px)': {
    right: 0,
    top: -370,
    width: 970,
  },
};

export const circlesImageTwoS = {
  display: 'none',
  '@media(min-width: 768px)': {
    display: 'block',
    height: 760,
    position: 'absolute',
    right: -260,
    top: -230,
    width: 'auto',
  },
  '@media(min-width: 1024px)': {
    right: -210,
    top: -145,
    height: 760,
  },
  '@media(min-width: 1260px)': {
    height: 880,
    top: -165,
    right: -165,
  },
  '@media(min-width: 1440px)': {
    height: 1220,
    right: -477,
    top: -305,
  },
  '@media(min-width: 1920px)': {
    height: 1450,
    right: -550,
    top: -625,
  },
};

export const motifWrap = {
  display: 'none',
  '@media(min-width: 1440px)': {
    display: 'block',
    left: 0,
    position: 'absolute',
    top: 850,
    svg: { width: '100%', maxWidth: 624, height: 'auto' },
  },
  '@media(min-width: 1920px)': {
    top: 670,
    svg: { maxWidth: 708 },
  },
};
