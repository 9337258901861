/** @jsx jsx */
import { jsx } from '@emotion/core';

import { StepWrap, StepCard, CardNumber, CardIllustration } from './stepStyle';

export default function Step({ number, illustraion, header, subText, children, currentRef, blue }) {
  return (
    <StepWrap id={header.split(' ').join('')} ref={currentRef}>
      <StepCard>
        <div className='df aic jcsb'>
          <CardNumber blue={blue}>
            <div>{number}</div>
          </CardNumber>
          <CardIllustration>{illustraion}</CardIllustration>
        </div>
        <h3>{header}</h3>
        <p>{subText}</p>
      </StepCard>
      {children}
    </StepWrap>
  );
}
