import styled from '@emotion/styled';

export const Root = styled.div`
  background: white;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  min-height: 457px;
  position: relative;
  text-align: center;
  @media (min-width: 768px) {
    background-color: #f7f7f7;
    min-height: 140px;
    height: 256px;
    margin-bottom: 32px;
    overflow: auto;
    overflow: visible;
    padding: 32px;
    position: relative;
  }
  @media (min-width: 1024px) {
    background-color: #f2f2f2;
    background-image: linear-gradient(56deg, rgba(229, 229, 250, 0) 73%, #c6c6f3 135%);
  }
  @media (min-width: 1440px) {
    margin-bottom: 140px;
    height: 512px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 190px;
    padding-left: 300px;
  }
`;
export const motifStyles = {
  '@media(max-width: 767px)': {
    display: 'none',
  },
  '@media(min-width: 768px)': {
    path: { fillOpacity: '1' },
    svg: { transform: 'rotate(180deg)' },
    position: 'absolute',
    bottom: -80,
    width: 354,
    zIndex: 1,
    left: 72,
  },
  '@media(min-width: 1024px)': {
    left: 126,
  },
  '@media(min-width: 1440px)': {
    width: 593,
    left: -110,
    bottom: -140,
  },
  '@media(min-width: 1920px)': {
    left: 125,
    top: 96,
    width: 708,
  },
};
export const TextWrap = styled.div`
  max-width: 456px;
  padding-left: 40px;
  padding-right: 32px;
  position: absolute;
  text-align: left;
  top: 200px;
  z-index: 500;
  h1 {
    font-size: 40px;
    font-family: Syne;
    max-width: 240px;
  }
  @media (min-width: 768px) {
    max-width: 510px;
    position: absolute;
    top: 140px;
    h1 {
      padding-top: 0;
    }
    br {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    left: 91px;
    padding-left: 0;
  }
  @media (min-width: 1440px) {
    h1 {
      font-size: 64px;
      max-width: 361px;
    }
  }
  @media (min-width: 1920px) {
    left: 360px;
  }
  p {
    line-height: 1.71;
    margin-top: 16px;
    @media (min-width: 768px) {
      max-width: 457px;
    }
    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 2.22;
      margin-top: 24px;
      :first-of-type {
        margin-top: 43px;
      }
    }
  }
`;

export const HeroImage = styled.div`
  width: 100%;
  z-index: 1;
  .cool-dude,
  .banner-hero-mobile,
  svg {
    position: absolute;
  }
  img {
    width: 100%;
  }
  .banner-hero-tab,
  .banner-hero-tab-land,
  .banner-hero-desktop {
    display: none;
  }
  .banner-hero-mobile {
    position: relative;
  }
  .cool-dude {
    max-width: 224px;
    width: 100%;
    right: -44px;
    bottom: 118px;
  }
  @media (min-width: 768px) {
    position: absolute;
    right: -2px;
    top: -95px;
    width: 304px;
    .banner-hero-mobile {
      display: none;
    }
    .banner-hero-tab {
      display: block;
      width: 100%;
    }
  }
  @media (min-width: 1024px) {
    width: 479px;
    right: 17px;
    .banner-hero-tab {
      display: none;
    }
    .banner-hero-tab-land {
      display: block;
    }
  }
  @media (min-width: 1440px) {
    width: 893px;
    top: -160px;
    right: 0;
    .banner-hero-desktop {
      display: block;
    }
    .banner-hero-tab-land {
      display: none;
    }
  }
  @media (min-width: 1920px) {
    right: 176px;
    z-index: 500;
    width: 957px;
  }
`;
export const motif2Styles = {
  '@media(max-width: 1919px)': {
    display: 'none',
  },
  '@media(min-width: 1920px)': {
    position: 'absolute',
    right: 53,
    top: -70,
    width: 398,
  },
};
export const PlusIconOneStyles = styled.div`
  @media (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    position: absolute;
    left: 124px;
    bottom: -200px;
    z-index: 900;
  }
`;
export const PlusIconTwoStyles = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }
  @media (min-width: 1440px) {
    position: absolute;
    right: -115px;
    bottom: -300px;
    z-index: 900;
  }
  @media (min-width: 1920px) {
    right: 113px;
  }
`;
