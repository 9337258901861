/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';

import { Root, OuterTopic, iconStyles, MotifWrap } from './topicNavigationStyles';
import { CoinsIconFaq, RefundIcon, PeopleIcon, PaperIcon, BlueMotif } from '../ui/icons';

const clickSections = {
  costSegBasics: 'CostSegBasics',
  costSegPricingandTiming: 'CostSegPricingandTiming',
  accountantsandCostSeg: 'AccountantsandCostSeg',
  costSegandTaxQuestions: 'CostSegandTaxQuestions',
};
export default function TopicNavigation({ onSectionClick }) {
  const [current, setCurrent] = useState('CostSegBasics');
  const currentSection = (str) => {
    onSectionClick(str);
    setCurrent(str);
  };

  return (
    <Root>
      <Topic
        icon={<CoinsIconFaq />}
        topic='Cost Seg Basics'
        color='#0827c7'
        current={'CostSegBasics' === current}
        onClick={() => currentSection(clickSections.costSegBasics)}
      />
      <Topic
        icon={<RefundIcon />}
        topic={
          <span>
            Cost Seg Pricing
            <br />
            and Timing
          </span>
        }
        color='#55dcac'
        current={'CostSegPricingandTiming' === current}
        onClick={() => currentSection(clickSections.costSegPricingandTiming)}
      />
      <Topic
        icon={<PeopleIcon />}
        topic={
          <span>
            Accountants
            <br />
            and Cost Seg
          </span>
        }
        color='#f74720'
        current={'AccountantsandCostSeg' === current}
        onClick={() => currentSection(clickSections.accountantsandCostSeg)}
      />
      <Topic
        icon={<PaperIcon />}
        topic={
          <span>
            Cost Seg and
            <br />
            Tax Questions
          </span>
        }
        color='#fcbd2c'
        current={'CostSegandTaxQuestions' === current}
        onClick={() => currentSection(clickSections.costSegandTaxQuestions)}
      />
    </Root>
  );
}
function Topic({ icon, topic, color, onClick }) {
  return (
    <>
      <OuterTopic onClick={onClick}>
        <MotifWrap>
          <BlueMotif />
        </MotifWrap>
        <div css={[iconStyles, { backgroundColor: color }]}>{icon}</div>
        <p css={{ fontWeight: 500, fontSize: 14, lineHeight: 1.71 }}>{topic}</p>
      </OuterTopic>
    </>
  );
}
