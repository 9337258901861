import styled from '@emotion/styled';

export const Root = styled.div`
  position: relative;
  z-index: 5;
`;
export const ZoomIn = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 8px 9px 0 rgba(19, 21, 50, 0.04);
  background-color: #ffffff;
  top: 61px;
  right: 40px;
  position: absolute;
  z-index: 50;
  cursor: pointer;
`;
export const ZoomOut = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 8px 9px 0 rgba(19, 21, 50, 0.04);
  background-color: #ffffff;
  top: 109px;
  right: 40px;
  position: absolute;
  z-index: 50;
  cursor: pointer;
`;

export const pinStyles = {
  backgroundImage:
    'radial-gradient(circle at center, rgb(255 255 255 / 0%) 6%, rgb(30 32 53 / 15%) 9%, rgb(30 32 53 / 35%) 98.74%)',
  padding: '50vh 50vw',
  boxSizing: 'content-box',
  display: 'inline-flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100vw + 3000px)',
  height: 'calc(100vh + 3000px)',
  zIndex: 4000,
  pointerEvents: 'none',
};

export const mapWrapStyles = {
  maxHeight: 500,
  '& * ': {
    cursor: 'auto !important',
  },
  '& .cluster': {
    borderRadius: '100%',
    zIndex: '-1 !important',
  },
  '& .cluster.cluster-0': {
    boxShadow: '0 9px 10px 0 rgba(78, 85, 129, 0.18)',
    width: '36px !important',
    height: '36px !important',
  },
  '& .cluster.cluster-0 div': {
    width: '36px !important',
    height: '36px !important',
    lineHeight: '36px !important',
  },
  '& .cluster.cluster-0 span': {
    color: '#0827c7',
    fontSize: 12,
    lineHeight: 1.33,
  },
  '& .cluster.cluster-1': {
    boxShadow: '0 9px 10px 0 rgba(129, 103, 78, 0.18)',
    width: '36px !important',
    height: '36px !important',
  },
  '& .cluster.cluster-1 div': {
    width: '36px !important',
    height: '36px !important',
    lineHeight: '36px !important',
  },
  '& .cluster.cluster-1 span': {
    color: '#ae7133',
    fontSize: 12,
    lineHeight: 1.33,
  },
  '& .cluster.cluster-2': {
    boxShadow: '0 9px 10px 0 rgba(117, 72, 63, 0.18)',
    width: '42px !important',
    height: '42px !important',
  },
  '& .cluster.cluster-2 div': {
    width: '42px !important',
    height: '42px !important',
    lineHeight: '42px !important',
  },
  '& .cluster.cluster-2 span': {
    color: '#b01813',
    fontSize: 12,
    lineHeight: 1.33,
  },
  '& .cluster.cluster-3': {
    boxShadow: '0 9px 10px 0 rgba(129, 78, 83, 0.18)',
    width: '46px !important',
    height: '46px !important',
  },
  '& .cluster.cluster-3 div': {
    width: '46px !important',
    height: '46px !important',
    lineHeight: '46px !important',
  },
  '& .cluster.cluster-3 span': {
    color: '#630f4d',
    fontSize: 12,
    lineHeight: 1.33,
  },
  '& .cluster.cluster-0 img': {
    width: 36,
    clip: 'rect(0px, 36px, 36px, 0px) !important',
  },
  '& .cluster.cluster-1 img': {
    width: 36,
    clip: 'rect(0px, 36px, 36px, 0px) !important',
  },
  '& .cluster.cluster-2 img': {
    width: 42,
    clip: 'rect(0px, 42px, 42px, 0px) !important',
  },
  '& .cluster.cluster-3 img': {
    width: 46,
    clip: 'rect(0px, 46px, 46px, 0px) !important',
  },
  '@media(min-width: 1024px)': {
    maxHeight: 660,
  },
  '@media(min-width: 1440px)': {
    maxHeight: 798,
  },
  '@media(min-width: 1920px)': {
    maxHeight: 1040,
  },
};

export const mapOptions = {
  disableDefaultUI: true,
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  styles: [
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'on',
        },
        { color: '#e1ecf1' },
      ],
    },
    {
      featureType: 'Administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        { color: '#5974ec' },
      ],
    },
    {
      featureType: 'Administrative.Province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        { color: '#c5c5c6' },
      ],
    },
    {
      featureType: 'Administrative.Country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        { color: '#8598ed' },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
      ],
    },
  ],
};
