import React from 'react';
import styled from '@emotion/styled';

export const wrapper = {
  backgroundColor: '#f2f2f2',
  backgroundImage: 'linear-gradient(67deg, rgba(229, 229, 250, 0) 48%, #c6c6f3 117%)',
  height: 1290,
  padding: '48px 24px',
  position: 'relative',
  zIndex: 100,
  '.form-submitted': {
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 21px 73px -6px rgba(19,21,50,0.09)',
    display: 'flex',
    fontSize: 18,
    fontWeight: 500,
    justifyContent: 'center',
    lineHeight: '1.56',
    margin: '72px auto 0',
    maxWidth: 450,
    padding: '48px 32px 32px',
    position: 'relative',
    textAlign: 'center',
    'span:last-child': {
      display: 'block',
      fontSize: 14,
      marginTop: 16,
    },
    '.checkWrap': {
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '50%',
      display: 'flex',
      height: 56,
      justifyContent: 'center',
      left: '50%',
      position: 'absolute',
      top: -28,
      transform: 'translateX(-50%)',
      width: 56,
    },
    svg: {
      height: '100%',
      width: '100%',
    },
  },
  '@media(min-width: 768px)': {
    height: 736,
    padding: '60px 94px',
  },
  '@media(min-width: 1024px)': {
    display: 'flex',
    justifyContent: 'center',
  },
  '@media(min-width: 1260px)': {
    backgroundImage: 'linear-gradient(43deg, rgba(229, 229, 250, 0) 58%, #c6c6f3 132%)',
    height: 920,
    paddingTop: 120,
    '.form-submitted': {
      fontSize: 24,
      marginTop: 82,
      mawWidth: 580,
      padding: '48px 40px 40px',
      width: '100%',
      'span:last-child': {
        fontSize: 18,
        marginTop: 24,
      },
      '.checkWrap': {
        height: 64,
        top: -32,
        width: 64,
      },
    },
  },
};
export const cardStyles = {
  '@media(max-width: 1024px)': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    display: 'block',
  },
};
export const header = {
  fontSize: 32,
  position: 'relative',
  zIndex: 100,
  '@media(min-width: 768px)': {
    fontSize: 40,
  },
  '@media(min-width: 1024px)': {
    fontSize: 48,
  },
  '@media(min-width: 1260px)': {
    fontSize: 64,
  },
};

export const par = {
  fontSize: 18,
  lineHeight: 1.56,
  marginBottom: 36,
  marginTop: 16,
  maxWidth: 309,
  position: 'relative',
  zIndex: 100,
  '@media(min-width: 768px)': {
    fontSize: 16,
    lineHeight: 1.75,
    maxWidth: 456,
  },
  '@media(min-width: 1024px)': {
    fontSize: 20,
    lineHeight: 1.4,
  },
  '@media(min-width: 1260px)': {
    fontSize: 24,
    lineHeight: 1.6,
    maxWidth: 657,
  },
};
export const formStyles = {
  '@media(min-width: 768px)': {
    display: 'flex',
    position: 'relative',
    zIndex: 100,
  },
};
export const fieldStyles = {
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '0 21px 45px -23px rgba(19, 21, 50, 0.2)',
  color: '#333',
  display: 'flex',
  height: 48,
  marginBottom: 16,
  paddingLeft: 24,
  position: 'relative',
  width: 327,
  zIndex: 100,
  '::placeholder': {
    color: '#a9a9a9',
  },
  '@media(min-width: 768px)': {
    width: 278,
  },
  '@media(min-width: 1024px)': {
    width: 245,
  },
  '@media(min-width: 1260px)': {
    width: 315,
  },
};

export const textArea = {
  alignItems: 'flex-start',
  height: 176,
  paddingTop: 16,
  resize: 'none',
  '@media(min-width: 768px)': {
    width: 579,
  },
  '@media(min-width: 1024px)': {
    width: 515,
  },
  '@media(min-width: 1260px)': {
    height: 160,
    position: 'relative',
    width: 655,
    zIndex: 100,
  },
};
export const buttonS = {
  alignItems: 'center',
  backgroundColor: '#0827c7',
  borderRadius: '8px',
  boxShadow: '0 21px 40px -15px rgba(19, 21, 50, 0.39)',
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 600,
  height: 48,
  justifyContent: 'space-between',
  overflow: 'hidden',
  position: 'relative',
  textAlign: 'center',
  textTransform: 'uppercase',
  transition: 'all .2s',
  width: 327,
  zIndex: 100,
  '@media(min-width: 768px)': {
    margin: 0,
  },
  '@media(min-width: 1260px)': {
    fontSize: 14,
    height: 64,
    justifyContent: 'center',
    marginTop: 48,
    width: 240,
  },
};

export const dropdown = {
  outline: 'none',
  border: 'none',
  color: '#a9a9a9',
  cursor: 'pointer',
};

export const addressfield = {
  maxWidth: 579,
  '@media(min-width: 768px)': {
    width: '100%',
  },
  '@media(min-width: 1024px)': {
    width: '100%',
    maxWidth: '100%',
  },
  '@media(min-width: 1260px)': {
    width: '100%',
  },
};

export const buttonArrow = {
  alignItems: 'center',
  backgroundColor: '#0827c7',
  display: 'inline-flex',
  height: 48,
  justifyContent: 'center',
  width: 56,
};
export const motifStyles = {
  position: 'absolute',
  right: 24,
  top: 0,
  width: 164,
  zIndex: 50,
  '@media(min-width: 1260px)': {
    top: -80,
    width: 398,
  },
  '@media(min-width: 1440px)': {
    right: 53,
  },
};
export const motifBottomStyles = {
  svg: { transform: 'rotate(180deg)' },
  bottom: 0,
  left: 0,
  position: 'absolute',
  width: 367,
  zIndex: 50,
  '@media(min-width: 1024px)': {
    left: 56,
  },
  '@media(min-width: 1260px)': {
    bottom: 0,
    left: 25,
    width: 512,
    '@media(min-width: 1440px)': {
      left: 125,
    },
  },
};

export const multiColumn = {
  // border: '1px solid red',
  '@media (min-width: 768px)': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: 16,
    columnGap: 24,
    maxWidth: '580px',
    marginBottom: 16,
  },
  '@media (min-width: 1260px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 20,
    maxWidth: '658px',
    justifyContent: 'space-between',
  },
};

export const fieldStylesThree = {
  ...fieldStyles,
  '@media(min-width: 768px)': {
    width: '100%',
    marginBottom: 0,
  },
  '@media(min-width: 1024px)': {
    width: 245,
  },
  '@media(min-width: 1260px)': {
    width: 'auto',
  },
};

export const AddressWrap = styled.div`
  position: relative;
  /* display: none; */

  .dropdown_wrap {
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    left: 0;
    padding: 12px 0;
    position: absolute;
    top: calc(100% + 8px);
    z-index: 10000;
    width: 100%;
    ul {
      list-style: none;
      position: relative;
      > svg {
        position: absolute;
        z-index: 121;
        top: 3px;
        right: 16px;
        cursor: pointer;
        width: 14px;
        height: 14px;
        :hover {
          fill: #0927c7;
        }
      }
    }
    li {
      cursor: pointer;
      line-height: 1.4;
      transition: all 0.1s;
      font-weight: 400;
      font-size: 15px;
      margin: 0.25rem 0;       
      padding: 4px 16px;
      :hover {
        background-color: #f3f4f8;
        background-color: rgb(243, 244, 248);
        color: #0927c7;
      }
    }
  }
  @media (min-width: 768px) {
    width: 100%;
    max-width: 580px;
    input {
      width: 100%;
    }
  }
  @media(min-width: 1260px) {
    max-width: 658px;
  }
`;
