import styled from '@emotion/styled';

export const StepWrap = styled.div`
  padding: 0 32px;
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0 64px;
  }
  @media (min-width: 1024px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
  }
  @media (min-width: 1920px) {
    max-height: 100vh;
  }
`;

export const StepCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 24px;
  max-width: 640px;
  padding-right: 32px;
  padding: 32px 24px;
  position: relative;
  width: 100%;
  z-index: 5000;
  h3 {
    font-family: Syne;
    font-size: 24px;
    margin-top: 25px;
  }
  p {
    line-height: 1.71;
    margin-top: 8px;
    max-width: 330px;
  }
  @media (min-width: 768px) {
    margin: 94px auto 0;
    padding: 115px 64px 64px;
    h3 {
      font-size: 48px;
      max-width: 374px;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
      line-height: 1.78;
      max-width: 470px;
    }
  }
  @media (min-width: 1024px) {
    margin: 0;
    max-width: 640px;
    padding: 116px 88px;
    z-index: 20;
    h3 {
      max-width: 578px;
    }
    p {
      margin-top: 48px;
      max-width: 578px;
    }
  }
  @media (min-width: 1440px) {
    margin: 0 auto;
    max-width: 754px;
    padding-left: 118px;
  }
  @media (min-width: 1920px) {
    h3 {
      max-width: 400px;
    }
    p {
      min-width: 475px;
      max-width: 475px;
    }
  }
`;

export const CardNumber = styled.div`
  align-items: center;
  border-radius: 50%;
  border: solid 1px #f1eaea;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 80px;
  div {
    align-items: center;
    background-color: #55dcac;
    background-color: ${(props) => props.blue && '#0b2ac7'};
    border-radius: 50%;
    box-shadow: 0 24px 30px -15px #999da0;
    color: #fff;
    display: flex;
    font-family: Syne;
    font-size: 32px;
    font-weight: 300;
    justify-content: center;
    line-height: 32px;
    height: 48px;
    width: 48px;
  }
  svg {
    max-height: 46%;
    width: auto;
  }
  @media (min-width: 768px) {
    left: 40px;
    position: absolute;
    top: -54px;
    height: 108px;
    width: 108px;
    div {
      height: 64px;
      width: 64px;
    }
  }
  @media (min-width: 1024px) {
    left: 65px;
  }
  @media (min-width: 1440px) {
    left: 100px;
  }
`;

export const CardIllustration = styled.div`
  display: flex;
  align-items: flex-end;
  svg {
    height: 100%;
    width: 100%;
    max-width: 80px;
  }
  @media (min-width: 768px) {
    position: absolute;
    top: -50px;
    right: 50px;
    svg {
      max-height: 170px;
      max-width: unset;
    }
  }
`;
