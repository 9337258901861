import styled from '@emotion/styled';

export const Root = styled.div`
  background-color: #fbfbfb;
  padding: 48px 24px;
  position: relative;
  @media (min-width: 768px) {
    padding: 60px 40px 49px 40px;
  }
  @media (min-width: 1024px) {
    padding: 80px 40px 49px 56px;
  }
  @media (min-width: 1440px) {
    padding: 120px 40px 49px 170px;
  }
`;
export const MotifBig = styled.div`
  left: 26px;
  position: absolute;
  top: -50px;
  z-index: 1;
  svg path {
    fill: #f6f6f6;
    fill-opacity: 1;
  }
  svg {
    height: 100%;
    transform: rotate(180deg);
    width: 487px;
  }
  @media (min-width: 1440px) {
    left: 85px;
    top: 0;
    svg {
      height: 100%;
      width: 596px;
    }
  }
  @media (min-width: 1920px) {
    left: 125px;
    top: 0;
    svg {
      height: 100%;
      width: 708px;
    }
  }
`;
export const MotifStyles = styled.div`
  position: absolute;
  right: 24px;
  top: -180px;
  z-index: 1;
  svg: {
    height: 139px;
    width: 164px;
  }
  @media (min-width: 768px) {
    height: 160px;
    right: 0;
    width: 216px;
  }
  @media (min-width: 1440px) {
    svg: {
      height: 362px;
      right: 0;
      width: 346px;
    }
  }
`;
export const InnerWrap = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    justify-content: center;
  }
`;
export const Header = styled.h1`
  font-size: 32px;
  line-height: 1.38;
  position: relative;
  z-index: 500;
  @media (min-width: 768px) {
    font-size: 40px;
    max-width: 322px;
  }
  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 1;
    max-width: 386px;
  }
  @media (min-width: 1440px) {
    font-size: 64px;
    line-height: 1.25;
    max-width: 620px;
  }
`;
export const Par = styled.p`
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 100px;
  padding-top: 16px;
  position: relative;
  z-index: 500;
  @media (min-width: 768px) {
    max-width: 315px;
    margin-left: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 1.4;
    margin-left: 113px;
    margin-top: 20px;
    max-width: 413px;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 1.67;
    margin-left: 72px;
    max-width: 424px;
  }
  @media (min-width: 1920px) {
    margin-left: 78px;
    max-width: 504px;
  }
`;
export const SearchSection = styled.div`
  bottom: -10px;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  z-index: 50;
`;
export const SearchWrap = styled.div`
  position: absolute;
  display: ${(props) => props.customButton && 'flex'};
  span {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 20px;
    @media (min-width: 1440px) {
      top: 28px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;
export const AllProperties = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  /* top: 20px; */
  @media (max-width: 767px) {
    /* div {
      display: none;
    } */
  }
  @media (min-width: 1440px) {
    /* top: 28px; */
  }
`;
export const SearchField = styled.input`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 21px 45px -23px rgba(19, 21, 50, 0.34);
  color: #000;
  display: flex;
  height: 56px;
  padding-left: 48px;
  width: 100%;
  ::placeholder {
    color: #a9a9a9;
  }
  @media (min-width: 768px) {
    width: 536px;
    box-shadow: 0 21px 45px -23px rgba(19, 21, 50, 0.2);
  }
  @media (min-width: 1024px) {
    width: 736px;
  }
  @media (min-width: 1440px) {
    height: 72px;
    width: 675px;
  }
`;
export const SearchIconStyles = styled.div`
  left: 16px;
  position: absolute;
  display: flex;
  align-self: auto;
  svg {
    max-width: 16px;
    height: auto;
  }
`;
export const DropdownWrap = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 21px 45px -23px rgba(19, 21, 50, 0.34);
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.dropdownOpen ? '280px' : '0px')};
  overflow: hidden;
  padding: 0 16px;
  position: absolute;
  right: -30px;
  top: 52px;
  transition: height 0.2s;
  width: 236px;
  z-index: 108;
  span {
    display: none;
  }
`;
export const Option = styled.div`
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  text-align: left;
  :hover {
    background-color: #f2f2f2;
  }
  :first-of-type {
    margin-top: 16px;
  }
`;
export const buttonStyles = {
  backgroundColor: '#55dcac',
  boxShadow: '0 21px 40px -15px rgba(124, 217, 175, 0.56)',
  display: 'flex',
  fontSize: 12,
  height: 56,
  justifyContent: 'center',
  marginTop: 16,
  width: 327,
  svg: {
    marginLeft: 16,
  },
  '@media(min-width: 768px)': {
    marginLeft: 24,
    marginTop: 0,
    maxWidth: 128,
    minWidth: 128,
  },
  '@media(min-width: 1440px)': {
    height: 72,
    minWidth: 176,
  },
};
