import styled from '@emotion/styled';

export const Root = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
  @media (min-width: 1024px) {
    bottom: -50px;
    display: flex;
    justify-content: center;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    transform: translate(-50%);
    width: calc(100% - 180px);
    z-index: 55;
  }
  @media (min-width: 1440px) {
    width: calc(100% - 240px);
  }
  @media (min-width: 1920px) {
    width: calc(100% - 500px);
  }
`;

export const OuterTopic = styled.div`
  align-items: center;
  background-color: #fff;
  box-shadow: 0 17px 46px -7px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: 112px;
  margin-right: 6px;
  overflow: hidden;
  padding-left: 24px;
  position: relative;
  text-transform: none;
  width: 207px;
  width: calc(25% - 6px);
  z-index: 500;
  :last-of-type {
    margin-right: 0;
  }
  p {
    /* max-width: 103px; */
  }
  div,
  p {
    position: relative;
    z-index: 10;
  }
  :hover {
    background-color: #0827c7;
    p {
      color: #fff;
    }
    div {
      background-color: #fff;
      svg g {
        fill: #0827c7;
      }
    }
    span {
      svg g {
        fill: #fff;
        fill-opacity: 0.2;
      }
    }
  }
  @media (min-width: 1440px) {
    margin-right: 24px;
    width: calc(100% - 24px);
    padding-left: 32px;
    p {
      font-size: 16px;
      max-width: 150px;
    }
  }
`;

export const iconStyles = {
  borderRadius: '50%',
  height: 48,
  width: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 16,
};

export const MotifWrap = styled.span`
  position: absolute;
  top: 0;
  right: 16px;
  width: 175px;
  z-index: 0;
  svg g {
    fill: #fafafa;
    fill-opacity: 1;
  }
  @media (min-width: 1440px) {
    right: -50px;
  }
`;
