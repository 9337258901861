import React from 'react';
import {
  PlanetCoreWrap,
  ExpandedCircleWrap,
  GreenPlus,
  Universe,
  CloseIcon,
  Yarmulke,
} from './catergoryCardStyles';

export default function CategoryCard({ i, close }) {
  return (
    <Universe>
      <PlanetCoreWrap onClick={close}>
        <CloseIcon className='closeIcon' onClick={close} />
        <ExpandedCircleWrap>
          <Yarmulke className='kippah' />
          <GreenPlus className='plusOne' />
          <GreenPlus className='plusTwo' />
          <GreenPlus className='plusThree' />
          <GreenPlus className='plusFour' />
          <div className='innerContent'>
            <div className='mini-desc'>{i.description}</div>
            <div className='bgc-title'>{i.title}</div>
            <div className='personsListWrap'>
              {i.items.map((item, index) => (
                <div key={index} className='personWrap'>
                  <div>{item.one}</div>
                  <div>{item.two}</div>
                </div>
              ))}
            </div>
          </div>
        </ExpandedCircleWrap>
      </PlanetCoreWrap>
    </Universe>
  );
}
