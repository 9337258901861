import styled from '@emotion/styled';

export const Root = styled.div`
  background-image: linear-gradient(13deg, rgba(229, 229, 250, 0) 3%, #c6c6f3 121%);
  position: relative;
  @media (min-width: 768px) {
    background-color: #f2f2f2;
    background-image: linear-gradient(36deg, rgba(229, 229, 250, 0) 46%, #c6c6f3 130%);
    padding-bottom: 87px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    padding: 90px 0 150px;
  }
  @media (min-width: 1920px) {
    padding-top: 127px;
  }
`;

export const MawWidth = styled.div`
  margin: 0 auto;
  @media (min-width: 768px) {
    width: calc(100% - 124px);
  }
  @media (min-width: 1024px) {
    width: calc(100% - 180px);
  }
  @media (min-width: 1440px) {
    width: calc(100% - 240px);
  }
  @media (min-width: 1920px) {
    width: calc(100% - 500px);
  }
`;
export const InnerWrap = styled.div`
  align-items: center;
  display: flex;
  padding: 68px 16px 35px 28px;
  position: relative;
  z-index: 50;
  @media (min-width: 768px) {
    padding: 50px 0 56px;
  }
  @media (min-width: 1024px) {
    padding: 45px 0 40px;
  }
`;
export const MotifStyles = styled.div`
  position: absolute;
  top: -70px;
  width: 100%;
  z-index: 1;
  @media (min-width: 768px) {
    max-width: 398px;
    right: 23px;
  }
  @media (min-width: 1024px) {
    right: 68px;
  }
  @media (min-width: 1440px) {
    right: 60px;
  }
  @media (min-width: 1440px) {
    right: 53px;
  }
`;
export const SubHeader = styled.h3`
  color: #0827c7;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
  }
`;
export const Header = styled.h1`
  font-size: 40px;
  line-height: 1.2;
  max-width: 215px;
  padding-top: 8px;
  @media (min-width: 768px) {
    max-width: 340px;
    padding-top: 16px;
  }
  @media (min-width: 1024px) {
    max-width: 532px;
  }
  @media (min-width: 1440px) {
    font-size: 64px;
    /* max-width: 672px; */
  }
`;
export const ImageStyles = styled.div`
  @media (min-width: 768px) {
    svg {
      height: auto;
      position: absolute;
      right: 0px;
      top: 16px;
      width: 187px;
      @media (min-width: 1024px) {
        right: 38px;
        top: -46px;
        width: 200px;
      }
      @media (min-width: 1440px) {
        right: 62px;
        width: 240px;
      }
      @media (min-width: 1920px) {
        right: 50px;
        width: 265px;
      }
    }
  }
`;
export const SearchWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  :focus-within {
    g g {
      fill: #0827c7;
    }
  }
`;
export const SearchField = styled.input`
  align-items: center;
  background-color: #fff;
  box-shadow: 0 21px 40px -15px rgba(19, 21, 50, 0.05);
  color: #000;
  display: flex;
  height: 48px;
  width: 100%;
  padding-left: 64px;
  ::placeholder {
    color: #a9a9a9;
  }
  @media (min-width: 768px) {
    height: 64px;
    width: 340px;
    border-radius: 8px;
    box-shadow: 0 21px 40px -15px rgba(19, 21, 50, 0.05);
  }
  @media (min-width: 1024px) {
    width: 424px;
  }
  @media (min-width: 1920px) {
    width: 588px;
    height: 72px;
  }
`;
export const SearchIconStyles = styled.div`
  position: absolute;
  left: 32px;
  display: flex;
  align-items: center;
  svg {
    height: 16px;
    width: auto;
  }
`;

export const buttonStyles = {
  '@media(max-width: 767px)': {
    display: 'none',
  },
  '@media(min-width: 768px)': {
    width: 148,
    height: 64,
    marginLeft: 16,
    marginTop: 0,
    fontSize: 14,
  },
  '@media(min-width: 1920px)': {
    height: 72,
    minWidth: 180,
  },
};
export const ResultsWrap = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 32px 32px 32px;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 100px;
    padding: 20px 0px 32px;
    align-items: stretch;
  }
`;
export const InnerResults = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 16px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 0;
    padding-bottom: 16px;
  }
`;

export const results = {
  fontSize: 14,
  fontWeight: 400,
};
export const Message = styled.div`
  letter-spacing: 0.7;
  padding: 40px 24px;
  @media (min-width: 768px) {
    padding: 50px 50px 0px 0px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }
  div {
    font-size: 16px;
    margin-top: 16px;
    a {
      color: #0827c7;
      text-decoration: underline;
    }
  }
`;

export const clear = {
  color: '#0727c7',
  cursor: 'pointer',
  fontWeight: 400,
};
