import styled from '@emotion/styled';

export const Root = styled.div`
  padding: 48px 24px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding: 48px 32px 64px;
  }
  @media (min-width: 1024px) {
    padding: 48px 48px 72px;
  }
  @media (min-width: 1440px) {
    padding: 48px 32px 80px;
  }
`;

export const Button = styled.div`
  opacity: ${props => props.show ? 1 : 0};
  align-items: center;
  background-color: #0827c7;
  border-radius: 8px;
  box-shadow: 0px 21px 40px rgba(19, 21, 50, 0.388576);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 48px;
  max-width: 144px;
  padding: 0 24px;
  text-transform: uppercase;
  width: 100%;
  .prev-next {
    display: none;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  span {
    font-weight: bold;
    text-transform: uppercase;
  }
  svg {
    max-width: 16px;
  }
  :first-of-type {
    margin-right: 40px;
    svg {
      transform: rotate(-180deg);
    }
  }
  @media (min-width: 768px) {
    height: 56px;
    max-width: 300px;
    justify-content: space-between;
    span {
      font-size: 14px;
    }
    > div {
      width: auto;
      color: #b9c2ec;
    }
    .prev-next {
      display: block;
    }
    svg {
      margin-right: 24px;
    }
    :last-of-type {
      svg {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }
  @media (min-width: 1440px) {
    height: 64px;
  }
`;
