export const outerWrap = {
  position: 'relative',
  '@media(min-width: 1260px)': {
    maxWidth: 1351,
    margin: '0 auto'
  },
};
export const iconCircle = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: '#55dcac',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 24px 30px -15px rgba(124, 217, 175, 0.91)'
};
export const pulseStyles = {
  position: 'absolute',
  left: 102,
  top: -20,
  '@media(min-width: 1024px)': {
    top: 120,
    height: 64,
    width: 64,
    left: 60
  },
  '@media(min-width: 1260px)': {
   top:172,
   left:80
  },
};
export const dollarStyles = {
  position: 'absolute',
  right: 43,
  top: 34,
  width: 20,
  height: 20,
  '@media(min-width: 768px)': {
    width: 40,
    height: 40,
    svg: { height: 16, width: 16 },
    top: 0,
    right: 56
  },
  '@media(min-width: 1024px)': {
    height: 56,
    width: 56,
    top: 90,
    right: 60
  },
  '@media(min-width: 1260px)': {
   top:130,
   right:70,
   height: 72,
   width: 72,
  },
};
export const chartStyles = {
  position: 'absolute',
  height: 64,
  width: 64,
  top: 150,
  right: 106,
  '@media(min-width: 768px)': {
    height: 72,
    width: 72,
    right: 266,
    top: 180
  },
  '@media(min-width: 1024px)': {
    top: 300,
    right: 240
  },
  '@media(min-width: 1260px)': {
   top:320,
   right:490,
   height: 64,
   width: 64,
  },
};
export const costSegregationStyles = {
  position: 'absolute',
  top: 50,
  left: 68,
  width: 125,
  lineHeight: 1.25,
  fontSize: 16,
  '@media(min-width: 768px)': {
    left: 190
  },
  '@media(min-width: 1024px)': {
    top: 90,
    left: 265,
    fontSize: 20,
    width: 156
  },
  '@media(min-width: 1260px)': {
   width: '100%',
   fontSize: 24,
   top:80,
   left:350
  },
};
export const numberStyles = {
  fontSize: 32,
  marginTop: 8,
  '@media(min-width: 768px)': {
    fontSize: 56
  },
  '@media(min-width: 1024px)': {
    fontSize: 64
  },
  '@media(min-width: 1260px)': {
   fontSize: 96,
   marginTop: 32,
  },
};
export const yearStyles = {
  position: 'absolute',
  top: 50,
  right: 93,
  fontSize: 16,
  lineHeight: 1.25,
  '@media(min-width: 768px)': {
    right: 167
  },
  '@media(min-width: 1024px)': {
    top: 90,
    right: 270,
    fontSize: 20,
    width: 156
  },
  '@media(min-width: 1260px)': {
   fontSize: 24,
   top:80,
   right:350
  },
};
