/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  content,
  sectionOneWrap,
  sectionTwoWrap,
  treeLgStyles,
  treeSmStyles,
  buildingStyles,
  buildingTextS,
} from './sectionOneStyles';
import { TreeLg, TreeSm } from '../ui/svg/about-us/icons';
import { ReactComponent as BuildingIllus } from '../ui/svg/about-us/about-cs-main.svg';
import { ReactComponent as FloorIllus } from '../ui/svg/about-us/about-sc.svg';

export default function SectionOne() {
  return (
    <div>
      <div css={content}>
        <h1 className='page-header__title'>About Cost Segregation</h1>
        <div css={sectionOneWrap}>
          <div css={buildingStyles}>
            <BuildingIllus />
            <TreeLg css={treeLgStyles} />
            <TreeSm css={treeSmStyles} />
          </div>
          <div css={buildingTextS}>
            <p>
              Cost Segregation is the IRS approved process of accelerating depreciation on a Real Estate asset by
              separating non-structural personal assets and land improvements from real property assets.
            </p>
            <p>
              In general, buildings can be depreciated over either a 27.5 year (residential properties) or a 39 year
              (commercial properties) period based on their classification as Section 1250 property, but certain
              categories of assets within a building can be depreciated more quickly, over five, seven, or 15 years due
              to their reclassification as Section 1245 property.
            </p>
          </div>
        </div>
        <div css={sectionTwoWrap}>
          <div className='floorIllus-image'>
            <FloorIllus />
          </div>
          <div className='non-structural-text-wrap'>
            <p>
              These include non-structural personal assets, land improvements, leasehold improvements and indirect
              construction costs, when applicable. Separating these faster depreciating assets into their proper
              categories allows for the frontloading of the appropriate tax deductions, lowering upfront payments and
              increasing cash flow.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
