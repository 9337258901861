import React from 'react';

export function MilkyWay({ ...props }) {
  return (
    <svg
      fill='none'
      height='1150'
      viewBox='0 0 1162 1150'
      width='1162'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='572.664' cy='575.485' r='416.382' stroke='#dcdcdc' />
      <circle cx='574.78' cy='574.78' r='574.28' stroke='#dcdcdc' />
      <path
        clipRule='evenodd'
        d='m102.582 366.332c-.122-1.651-1.5-2.952-3.1817-2.952-1.7618 0-3.19 1.428-3.19 3.19v12.76h-12.76l-.2381.009c-1.6506.122-2.9519 1.499-2.9519 3.181 0 1.762 1.4282 3.19 3.19 3.19h12.76v12.76l.0087.238c.1218 1.651 1.4995 2.952 3.1813 2.952 1.7617 0 3.1897-1.428 3.1897-3.19v-12.76h12.76l.238-.009c1.651-.122 2.952-1.499 2.952-3.181 0-1.762-1.428-3.19-3.19-3.19h-12.76v-12.76z'
        fill='#55dcac'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='m1126.18 731.372c-.12-1.651-1.5-2.952-3.18-2.952-1.76 0-3.19 1.428-3.19 3.19v12.76h-12.76l-.24.009c-1.65.122-2.95 1.499-2.95 3.181 0 1.762 1.43 3.19 3.19 3.19h12.76v12.76l.01.238c.12 1.651 1.5 2.952 3.18 2.952 1.76 0 3.19-1.428 3.19-3.19v-12.76h12.76l.24-.009c1.65-.121 2.95-1.499 2.95-3.181 0-1.762-1.43-3.19-3.19-3.19h-12.76v-12.76z'
        fill='#55dcac'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='m258.021 954.452c-.121-1.651-1.499-2.952-3.181-2.952-1.762 0-3.19 1.428-3.19 3.19v12.76h-12.76l-.238.009c-1.65.121-2.952 1.499-2.952 3.181 0 1.762 1.428 3.19 3.19 3.19h12.76v12.76l.009.238c.122 1.651 1.499 2.952 3.181 2.952 1.762 0 3.19-1.428 3.19-3.19v-12.76h12.76l.238-.009c1.651-.122 2.952-1.499 2.952-3.181 0-1.762-1.428-3.19-3.19-3.19h-12.76v-12.76z'
        fill='#55dcac'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='m867.021 228.292c-.122-1.651-1.499-2.952-3.181-2.952-1.762 0-3.19 1.428-3.19 3.19v12.76h-12.76l-.238.009c-1.651.121-2.952 1.499-2.952 3.181 0 1.762 1.428 3.19 3.19 3.19h12.76v12.76l.009.238c.121 1.651 1.499 2.952 3.181 2.952 1.762 0 3.19-1.428 3.19-3.19v-12.76h12.76l.238-.009c1.651-.122 2.952-1.499 2.952-3.181 0-1.762-1.428-3.19-3.19-3.19h-12.76v-12.76z'
        fill='#55dcac'
        fillRule='evenodd'
      />
      <circle cx='780.32' cy='932.36' fill='#0318c6' r='23.2' />
      <circle cx='278.04' cy='282.76' fill='#0318c6' r='23.2' />
      <circle cx='238.48' cy='815.48' fill='#55dcac' r='32.48' />
      <path
        d='m226.393 818.979c10.518.782 19.844-5.911 22.664-15.643'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <path
        d='m245.787 804.857 3.27-1.521 1.524 3.262'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <path
        d='m226.38 823.112c.17.011.34.021.51.021m0 0c1.046.046 2.077.026 3.092-.052v3.908h-3.092z'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <path
        d='m236.167 821.873v5.116h-3.092v-4.319c1.056-.206 2.087-.473 3.092-.797z'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <path
        d='m242.351 818.911v8.078h-3.092v-6.345c1.072-.509 2.108-1.085 3.092-1.733z'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <path
        d='m248.536 813.24v13.749h-3.093v-10.453c1.124-1.003 2.16-2.103 3.093-3.296z'
        stroke='#fff'
        strokmiterlimit='10'
        strokeWidth='2'
      />
      <circle cx='1024.16' cy='931.16' fill='#55dcac' r='59.16' />
      <g stroke='#fff' strokmiterlimit='10' strokeWidth='2'>
        <path d='m1019.13 937.577-14.81 14.809' />
        <path d='m1017.15 953.373c1.64 0 2.96-1.326 2.96-2.962s-1.32-2.962-2.96-2.962-2.96 1.326-2.96 2.962 1.32 2.962 2.96 2.962z' />
        <path d='m1006.29 942.513c1.64 0 2.96-1.326 2.96-2.962 0-1.635-1.32-2.961-2.96-2.961-1.63 0-2.96 1.326-2.96 2.961 0 1.636 1.33 2.962 2.96 2.962z' />
        <path d='m1036.9 946.462v-20.732h7.89l-17.77-17.77-17.77 17.77h7.9v7.898' />
        <path d='m1036.9 950.411v-1.974' />
        <path d='m1036.9 952.386v1.974' />
        <path d='m1027.02 932.641v14.808' />
        <path d='m1027.02 949.424v1.974' />
      </g>
    </svg>
  );
}

export function SmallSolarSystem({ ...props }) {
  return (
    <svg
      width='733'
      height='733'
      viewBox='0 0 733 733'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d_30_180)'>
        <circle cx='366.5' cy='366.5' r='346' stroke='#DCDCDC' />
        <circle cx='366.5' cy='366.5' r='213' stroke='#DCDCDC' shapeRendering='crispEdges' />
      </g>
      <defs>
        <filter
          id='filter0_d_30_180'
          x='0'
          y='0'
          width='733'
          height='733'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_30_180' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_30_180'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_30_180'
          x='133'
          y='133'
          width='467'
          height='467'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_30_180' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_30_180'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}

export function SolidMotif({ ...props }) {
  return (
    <svg
      width='224'
      height='462'
      viewBox='0 0 224 462'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0509483 366.7H221.642L200.516 392.878L200.397 393.03V438.358H22.9777V393.199H0.0509483V461.097L223.323 461.114V343.944H1.71526L22.8588 317.428L22.9777 317.276V272.37H200.397V318.439H223.323V249.614H202.061L223.323 222.946V122.363L22.9098 25.3524V0H0L0.0509483 366.7ZM122.922 223.097L123.041 222.945V99.1331L200.398 136.589V249.613H101.779L122.922 223.097ZM22.8585 223.098L22.9774 222.946V54.8683L22.9604 50.6878L100.113 88.0422V249.613H1.71497L22.8585 223.098Z'
        fill='#BFC9DD'
        fillOpacity='0.3'
      />
    </svg>
  );
}

export function LargeMotif({ ...props }) {
  return (
    <svg
      width='928'
      height='1087'
      viewBox='0 0 928 1087'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_35_986'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='-30'
        y='0'
        width='958'
        height='1087'
      >
        <rect x='-30' width='958' height='1087' fill='url(#paint0_linear_35_986)' />
      </mask>
      <g mask='url(#mask0_35_986)'>
        <path
          opacity='0.542085'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M-20 1322.18H789.52L715.168 1415.87L714.75 1416.42V1578.64H90.3511V1417.02H9.66402V1660.02L795.437 1660.08V1240.74H15.5213L89.9327 1145.85L90.3511 1145.3V984.59H714.75V1149.47H795.437V903.147H720.607L824 807.709V426.529L119.597 78.0374V-16H-20V1322.18ZM472.316 815.121V791.184V422.689L676.181 524.129V911.446H366.237L472.316 815.121ZM96.0525 814.44L132.992 813.899L127.741 268.331L127.682 253.412L330.475 354.494V909.062H22.9153L96.0525 814.44Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_35_986'
          x1='449'
          y1='0'
          x2='449'
          y2='1087'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#D9D9D9' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Carot() {
  return (
    <svg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19.3999 0.999942L10.6999 11.4399L1.9999 0.999942' stroke='white' strokeWidth='3' />
    </svg>
  );
}

export function LargeMotifMobile({ ...props }) {
  return (
    <svg
      width='235'
      height='389'
      viewBox='0 0 235 389'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M233.218 288.558L210.983 316.304L210.849 316.46V364.492H24.1728V316.638H0.0445582V388.586H235V264.441H1.80461L24.0614 236.339L24.1951 236.183V188.596H210.872V237.408H235V164.479H212.632L235 136.221V29.6454L24.106 -73.1446V-100H0L0.0445582 288.558H233.218ZM129.352 136.399L129.486 136.243V5.03905L210.894 44.721V164.479H107.096L129.352 136.377V136.399ZM24.0614 136.399L24.1951 136.243V-46.2891L105.358 -6.71855V164.502H1.80461L24.0614 136.399Z'
        fill='url(#paint0_linear_64_1976)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_64_1976'
          x1='117.5'
          y1='-100'
          x2='117.5'
          y2='388.586'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function LinkedIn({ ...props }) {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_30_300)'>
        <path
          d='M9.68477 12.8528H1.65477C1.29827 12.8528 1.00977 13.1424 1.00977 13.4992V39.3531C1.00977 39.7104 1.29877 39.9995 1.65477 39.9995H9.68477C10.0413 39.9995 10.3298 39.7099 10.3298 39.3531V13.4992C10.3298 13.1419 10.0408 12.8528 9.68477 12.8528Z'
          fill='#9C9C9C'
        />
        <path
          d='M5.67278 0C2.75078 0 0.373779 2.37974 0.373779 5.30467C0.373779 8.22961 2.75078 10.6113 5.67278 10.6113C8.59478 10.6113 10.9673 8.23061 10.9673 5.30467C10.9673 2.37974 8.59228 0 5.67278 0Z'
          fill='#9C9C9C'
        />
        <path
          d='M30.1097 12.2098C26.8847 12.2098 24.5002 13.5994 23.0542 15.1784V13.4992C23.0542 13.1419 22.7652 12.8528 22.4092 12.8528H14.7192C14.3627 12.8528 14.0742 13.1424 14.0742 13.4992V39.3531C14.0742 39.7103 14.3632 39.9995 14.7192 39.9995H22.7317C23.0882 39.9995 23.3767 39.7098 23.3767 39.3531V26.5614C23.3767 22.2509 24.5452 20.5717 27.5432 20.5717C30.8087 20.5717 31.0682 23.2637 31.0682 26.7834V39.3536C31.0682 39.7108 31.3572 40 31.7132 40H39.7287C40.0852 40 40.3737 39.7103 40.3737 39.3536V25.1724C40.3737 18.7628 39.1542 12.2103 30.1097 12.2103V12.2098Z'
          fill='#9C9C9C'
        />
      </g>
      <defs>
        <clipPath id='clip0_30_300'>
          <rect width='40' height='40' fill='white' transform='translate(0.373779)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Twitter({ ...props }) {
  return (
    <svg
      width='45'
      height='40'
      viewBox='0 0 45 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_30_304)'>
        <path
          d='M35.2251 0H42.0108L27.1862 16.9438L44.6262 40H30.9701L20.2752 26.0164L8.03756 40H1.24759L17.1033 21.8773L0.373779 0H14.3755L24.0436 12.7813L35.2251 0ZM32.8431 35.9378H36.6037L12.3323 3.84875H8.29772L32.8431 35.9386V35.9378Z'
          fill='#9C9C9C'
        />
      </g>
      <defs>
        <clipPath id='clip0_30_304'>
          <rect width='44.2524' height='40' fill='white' transform='translate(0.373779)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AstroidBelt({ ...props }) {
  return (
    <svg
      width='353'
      height='513'
      viewBox='0 0 353 513'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.2438 347.582C47.1579 346.418 46.1862 345.5 45 345.5C43.7574 345.5 42.75 346.507 42.75 347.75V356.75H33.75L33.5821 356.756C32.4179 356.842 31.5 357.814 31.5 359C31.5 360.243 32.5074 361.25 33.75 361.25H42.75V370.25L42.7562 370.418C42.8421 371.582 43.8138 372.5 45 372.5C46.2426 372.5 47.25 371.493 47.25 370.25V361.25H56.25L56.4179 361.244C57.5821 361.158 58.5 360.186 58.5 359C58.5 357.757 57.4926 356.75 56.25 356.75H47.25V347.75L47.2438 347.582Z'
        fill='#55DCAC'
      />
      <circle cx='29' cy='47' r='29' fill='#55DCAC' />
      <path
        d='M18.2073 50.1239C27.5983 50.8222 35.9255 44.846 38.443 36.1575'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M35.5238 37.5155L38.4429 36.1575L39.804 39.07'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M18.1961 53.8145C18.348 53.8237 18.4998 53.8329 18.6516 53.8329M18.6516 53.8329C19.5857 53.8742 20.506 53.8559 21.4125 53.787V57.2762H18.6516V53.8329Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M26.9343 52.7081V57.2762H24.1735V53.4197C25.1168 53.2361 26.037 52.9974 26.9343 52.7081Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M32.456 50.0636V57.2761H29.6952V51.6108C30.6523 51.1563 31.5772 50.6421 32.456 50.0636Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M37.9777 44.9998V57.2762H35.2169V47.9426C36.22 47.0474 37.1449 46.0649 37.9777 44.9998Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M330.461 108.092C330.339 106.441 328.962 105.14 327.28 105.14C325.518 105.14 324.09 106.568 324.09 108.33V121.09H311.33L311.092 121.099C309.441 121.221 308.14 122.598 308.14 124.28C308.14 126.042 309.568 127.47 311.33 127.47H324.09V140.23L324.099 140.468C324.221 142.119 325.598 143.42 327.28 143.42C329.042 143.42 330.47 141.992 330.47 140.23V127.47H343.23L343.468 127.461C345.119 127.339 346.42 125.962 346.42 124.28C346.42 122.518 344.992 121.09 343.23 121.09H330.47V108.33L330.461 108.092Z'
        fill='#55DCAC'
      />
      <circle cx='289.874' cy='422.874' r='36.8738' fill='#55DCAC' />
      <path
        d='M286.735 426.874L277.505 436.104'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M285.505 436.719C286.524 436.719 287.351 435.892 287.351 434.873C287.351 433.853 286.524 433.027 285.505 433.027C284.485 433.027 283.659 433.853 283.659 434.873C283.659 435.892 284.485 436.719 285.505 436.719Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M278.736 429.95C279.756 429.95 280.582 429.124 280.582 428.104C280.582 427.085 279.756 426.258 278.736 426.258C277.717 426.258 276.89 427.085 276.89 428.104C276.89 429.124 277.717 429.95 278.736 429.95Z'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M297.812 432.411V419.49H302.734L291.658 408.414L280.582 419.49H285.505V424.412'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path d='M297.812 434.873V433.642' stroke='white' strokeWidth='2' strokeMiterlimit='10' />
      <path d='M297.812 436.104V437.334' stroke='white' strokeWidth='2' strokeMiterlimit='10' />
      <path d='M291.658 423.797V433.027' stroke='white' strokeWidth='2' strokeMiterlimit='10' />
      <path d='M291.658 434.258V435.488' stroke='white' strokeWidth='2' strokeMiterlimit='10' />
      <circle cx='42' cy='498' r='15' fill='#0318C6' />
      <circle cx='259' cy='15' r='15' fill='#0318C6' />
    </svg>
  );
}
