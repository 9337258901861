export const wrapper = {
  backgroundColor: '#f4f4f4',
  overflow: 'hidden',
  padding: '224px 0px 50px 0px',
  position: 'relative',
  '@media(min-width: 768px)': {
    padding: '134px 0 30px 0',
  },
  '@media(min-width: 1440px)': {
    padding: '180px 0 120px 0',
  },
};
export const outerWrap = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: 32,
  position: 'relative',
  textAlign: 'center',
  zIndex: 50,
  '@media(min-width: 768px)': {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 120,
    width: '100%',
    textAlign: 'left',
  },
  '@media(min-width: 1024px)': {
    justifyContent: 'center',
    paddingLeft: 185,
    paddingTop: 40,
  },
  '@media(min-width: 1440px)': {
    // paddingTop: 0
  },
};
export const header = {
  fontSize: 24,
  textAlign: 'center',
  maxWidth: 299,
  '@media(min-width: 768px)': {
    textAlign: 'left',
    maxWidth: 260,
  },
  '@media(min-width: 1024px)': {
    fontSize: 32,
    maxWidth: 352,
  },
  '@media(min-width: 1260px)': {
    marginLeft: 75,
    maxWidth: 387,
  },
  '@media(min-width: 1920px)': {
    marginLeft: 275,
  },
};
export const innerHeader = {
  fontSize: 14,
  lineHeight: 1.71,
  maxWidth: 262,
  margin: '8px auto 0',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    textAlign: 'left',
    maxwidth: 278,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 560,
    width: 490,
    paddingLeft: 0,
    paddingTop: 12,
  },
  '@media(min-width: 1260px)': {
    fontSize: 18,
    marginLeft: 75,
    marginTop: 16,
  },
  '@media(min-width: 1920px)': {
    marginLeft: 275,
  },
};
export const motifStyles = {
  svg: { transform: 'rotate(180deg)' },
  height: '100%',
  left: -60,
  maxWidth: 295,
  path: { fillOpacity: '1' },
  position: 'absolute',
  bottom: -120,
  width: '100%',
  '@media(min-width: 768px)': {
    left: 62,
    maxWidth: 361,
    top: 0,
  },
  '@media(min-width: 1024px)': {
    left: 104,
    top: -1,
  },
  '@media(min-width: 1260px)': {
    left: 170,
    maxWidth: 507,
  },
  '@media(min-width: 1440px)': {
    left: 108,
    maxWidth: 600,
    height: 500,
  },
  '@media(min-width: 1920px)': {
    left: 133,
  },
};
export const arrowStyles = {
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  boxShadow: '0 0 39px 0 rgba(48, 52, 72, 0.06)',
  display: 'flex',
  height: 64,
  justifyContent: 'center',
  left: '42%',
  position: 'absolute',
  top: 110,
  width: 64,
  '::before': {
    border: '1px solid white',
    borderRadius: '50%',
    content: '" "',
    height: 88,
    padding: 12,
    position: 'absolute',
    width: 88,
  },
  '@media(min-width: 768px)': {
    height: 80,
    left: '45%',
    top: 20,
    width: 80,
    zIndex: 500,
    '::before': {
      border: '1px solid white',
      borderRadius: '50%',
      content: '" "',
      height: 112,
      padding: 16,
      position: 'absolute',
      width: 112,
    },
  },
  '@media(min-width: 1024px)': {
    height: 96,
    left: '45.5%',
    width: 96,
    '::before': {
      height: 128,
      width: 128,
    },
  },
  '@media(min-width: 1440px)': {
    height: 107,
    left: '46.5%',
    width: 107,
    '::before': {
      height: 143,
      padding: 18,
      width: 143,
    },
  },
  '@media(min-width: 1920px)': {
    left: '47.5%',
  },
};
export const buttonsWrap = {
  a: { width: 187, height: 48, fontSize: 12 },
  margin: '32px auto 0',
  '@media(min-width: 768px)': {
    marginTop: -70,
  },
  '@media(min-width: 1024px)': {
    marginTop: 30,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    a: {
      paddingRight: 40,
      width: 282,
    },
  },
  '@media(min-width: 1440px)': {
    marginLeft: 80,
    paddingLeft: 0,
    a: { width: 270, height: 64, fontSize: 14 },
  },
  '@media(min-width: 1920px)': {
    marginLeft: 130,
  },
};
